import { fetcher } from '@bp/data-access';

import { AlertFilter } from '../types/AlertFilter';
import { PreviewResponse } from '../types/PreviewReponse';
import { baseUrl, getBaseUrl, responseConfig } from './constants';

type FilterRequest = Partial<Omit<AlertFilter, 'created_on' | 'updated_on' | 'condition'>>;

export function getAlertFilter(filterId: string): Promise<AlertFilter> {
  if (!filterId) return Promise.reject();
  return fetcher({
    requestConfig: {
      url: `${baseUrl}/${filterId}`,
      method: 'GET',
    },
    responseConfig,
  });
}

export function getAlertFilters(onlyActive?: boolean): Promise<AlertFilter[]> {
  return fetcher({
    requestConfig: {
      url: `${getBaseUrl}?active=${onlyActive}`,
      method: 'GET',
    },
    responseConfig,
  }).then((res: AlertFilter[]): AlertFilter[] => res || [] as AlertFilter[]);
}

export function updateAlertFilter(filter: FilterRequest): Promise<void> {
  return fetcher({
    requestConfig: {
      url: `${baseUrl}/${filter.id}`,
      method: 'PATCH',
      data: filter,
    },
    responseConfig,
  });
}

export function deleteAlertFilter(filterId: string): Promise<void> {
  return fetcher({
    requestConfig: {
      url: `${baseUrl}/${filterId}`,
      method: 'DELETE',
    },
    responseConfig,
  });
}

export function createAlertFilter(filter: FilterRequest): Promise<AlertFilter> {
  return fetcher({
    requestConfig: {
      url: baseUrl,
      method: 'POST',
      data: filter,
    },
    responseConfig,
  });
}

export function getPreviewFilter(condition: object): Promise<PreviewResponse> {
  return fetcher({
    requestConfig: {
      url: '/resources/v2.0/preview/plans',
      method: 'POST',
      data: { condition },
    },
    responseConfig,
  });
}
