import { hot } from 'react-hot-loader';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsListScreen from 'react/layout/settings/SettingsListScreen';
import SettingsListEmptyState from 'react/layout/settings/components/SettingsListEmptyState';
import { DropdownOptionType } from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import get from 'lodash/get';
import { loadEnrichedUsers } from 'react/common/enriched_users/actions';
import provideStore from 'react/utils/provideStore';
import actions from '../../actions';
import selectors from '../../selectors';
import UserModal from '../../modals/UserModal';
import ListItem from './list/ListItem';
import SortTooltip from './SortTooltip';
import { SORT_MODES, SortShape, ANY_OPTION_VALUE } from '../constants';
import FiltersComponent from './filters/FiltersComponents';

class UserManagement extends React.PureComponent {
  componentDidMount() {
    if (!this.props.users) {
      this.props.loadEnrichedUsers();
    }
    if (!this.props.roles) {
      this.props.loadRoles();
    }
  }

  render() {
    const {
      users,
      filterText,
      updateFilterText,
      isLoading,
      sortMode,
      updateSort,
      filtersDropDownState,
      filtersDropDownItems,
      updateFiltersDropDowns,
      featureToggles,
    } = this.props;

    const settingsSortingFT = get(featureToggles, 'frontier_settings_sorting_and_filters', false);
    const filterDDItems = settingsSortingFT ? filtersDropDownItems : undefined;

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsListScreen
          loading={isLoading}
          items={users}
          noItemsTitle="No Users Added Yet"
          createItemProps={{
            modal: { title: 'Invite a User', titleIcon: 'bp-icon-invite', component: UserModal },
            itemName: 'user',
            buttonText: 'Invite a user',
            tileText: 'Invite a user',
          }}
          listItem={ListItem}
          listItemProp="user"
          permissionName="users"
          headerName="User Management"
          filterPlaceholderText="Find Users..."
          updateFilterText={updateFilterText}
          filterText={filterText}
          sortModes={SORT_MODES}
          sortMode={sortMode}
          sortTooltip={SortTooltip()}
          updateSort={updateSort}
          renderFilterRowElement={() =>
            filterDDItems && filtersDropDownState ? (
              <FiltersComponent
                filtersDropDownState={filtersDropDownState}
                onChange={updateFiltersDropDowns}
                data={filterDDItems}
              />
            ) : null
          }
          renderNoResultsByFilters={() =>
            filterText ||
            Object.values(filtersDropDownState).some(
              (dropDownState) => dropDownState.value !== ANY_OPTION_VALUE
            ) ? (
              <SettingsListEmptyState
                title="No Matches Found"
                subtitle="Try refining your query and search filters"
              />
            ) : null
          }
        />
      </ThemeProvider>
    );
  }
}

UserManagement.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool.isRequired,
  loadRoles: PropTypes.func.isRequired,
  loadEnrichedUsers: PropTypes.func.isRequired,
  updateFilterText: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  updateSort: PropTypes.func.isRequired,
  sortMode: SortShape.isRequired,
  updateFiltersDropDowns: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    tagType: DropdownOptionType,
    status: DropdownOptionType,
  }),
  filtersDropDownItems: PropTypes.shape({
    tagType: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }),
  featureToggles: PropTypes.shape({}).isRequired,
};

UserManagement.defaultProps = {
  users: undefined,
  roles: undefined,
  filterText: '',
  filtersDropDownState: undefined,
  filtersDropDownItems: undefined,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    users: selectors.getUsers(state),
    filterText: selectors.getFilterText(state),
    sortMode: selectors.getSortMode(state),
    filtersDropDownState: selectors.getFiltersDropDownState(state),
    filtersDropDownItems: selectors.getFiltersDropDownItems(state),
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadRoles: () => dispatch(actions.getRoles()),
    loadEnrichedUsers: () => dispatch(loadEnrichedUsers()),
    updateFilterText: (filterText) => dispatch(actions.updateFilterText(filterText)),
    updateSort: (payload) => dispatch(actions.updateSort(payload)),
    updateFiltersDropDowns: (filterState) => dispatch(actions.updateFiltersDropDowns(filterState)),
  };
}

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(UserManagement))
);
