import React from 'react';
import PropTypes from 'prop-types';
import LogoutButton from './LogoutButton';
import { getRandomId } from './helpers';
import {
  HorizontalLayout,
  Content,
  Title,
  Description,
  Steps,
  Step,
  Note,
  CodeSpan,
  Badge,
  SSOForm,
} from '../../components';

const formId = getRandomId();

const OneLoginInstructions = ({
  isConfigurationSent,
  configureProvider,
  organization,
  updateAndLogout,
}) => (
  <div>
    <HorizontalLayout>
      <Badge>2</Badge>
      <Content>
        <Title>Add the BigPanda App to OneLogin</Title>
        <Steps>
          <Step>Log in to your OneLogin Admin account.</Step>
          <Step>
            In OneLogin, go to <strong>Apps &gt; Add Apps.</strong>
          </Step>
          <Step>
            Search for <CodeSpan canBeCopied>BigPanda</CodeSpan>, click on the result, and click{' '}
            <strong>Save.</strong>
          </Step>
          <Step>
            Under the <strong>Configuration</strong> tab, enter{' '}
            <CodeSpan canBeCopied>{organization}</CodeSpan>.
          </Step>
          <Step>
            Under the <strong>SSO</strong> tab, select <strong>SHA-256</strong> as the{' '}
            <strong>SAML Signature Algorithm.</strong>
          </Step>
          <Step>
            Under <strong>Users</strong>, ensure that the users that will access BigPanda with SSO
            appear and that their Email (SAML NameID) is their BigPanda username.
          </Step>
          <Step>
            Click <strong>Save.</strong>
          </Step>
        </Steps>
      </Content>
    </HorizontalLayout>
    <HorizontalLayout>
      <Badge>3</Badge>
      <Content>
        <Title>Configure OneLogin Information in BigPanda</Title>
        <Steps>
          <Step>
            <div>From the SSO tab in OneLogin:</div>
            <Steps>
              <Step>
                Copy the value of the <CodeSpan>Issuer URL</CodeSpan> attribute and paste it into{' '}
                <strong>Issuer ID</strong>.
              </Step>
              <Step>
                Copy the value of the <CodeSpan>SAML 2.0 Endpoint (HTTP)</CodeSpan> and paste it
                into <strong>SAML 2.0 Endpoint (HTTP)</strong>.
              </Step>
              <Step>
                Copy the value of the <CodeSpan>X509Certificate</CodeSpan> into your preferred
                editor. Remove white spaces, BEGIN CERTIFICATE, END CERTIFICATE, and paste the
                result into <strong>X.509 Certificate</strong>.
              </Step>
            </Steps>
          </Step>
          <Step>
            Click <strong>Configure OneLogin.</strong>
          </Step>
        </Steps>
        <SSOForm providerName="OneLogin" onSubmit={configureProvider} id={formId} />
      </Content>
    </HorizontalLayout>
    <HorizontalLayout>
      <Badge>4</Badge>
      <Content>
        <Title>Validate and Enable the Integration</Title>
        <Description>
          Before enabling the OneLogin integration for your entire organization, test it with your
          own account.
        </Description>
        <Steps>
          <Step>
            Click <strong>Logout and Validate.</strong>
          </Step>
          <Step>
            Log in to BigPanda with OneLogin SSO. Be sure to leave the BigPanda password field
            blank.
          </Step>
          <Step>
            If your login was successful, return to this page and click{' '}
            <strong>Activate SSO</strong> to enable SSO for everybody in your organization.
          </Step>
        </Steps>
        <Note>
          Note: Until you enable the integration for the entire organization, users (including you)
          can log in to BigPanda with their original BigPanda passwords. Once enabled, they will be
          required to use OneLogin&apos;s authentication.
        </Note>

        <LogoutButton updateAndLogout={updateAndLogout} disabled={!isConfigurationSent} />
      </Content>
    </HorizontalLayout>
  </div>
);

OneLoginInstructions.propTypes = {
  configureProvider: PropTypes.func.isRequired,
  updateAndLogout: PropTypes.func.isRequired,
  isConfigurationSent: PropTypes.bool.isRequired,
  organization: PropTypes.string.isRequired,
};

export default OneLoginInstructions;
