angular
  .module('bigpanda.backend.services')
  .factory('ActivityFeedBackendService', ActivityFeedBackendService);

function ActivityFeedBackendService($http) {
  function getActivityEvents(id) {
    return $http.get(`/web/incidents/${id}/activity`).then((res) => res.data.item);
  }

  return {
    getActivityEvents: getActivityEvents,
  };
}
