angular.module('bp.widgets').directive('bpTagsInputAutofocus', tagsInputAutofocus);

function tagsInputAutofocus($timeout) {
  return {
    link: link,
  };

  function link(scope, element) {
    const input = element.find('input');
    $timeout(() => {
      input[0].focus();
    });
  }
}
