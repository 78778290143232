import isAllVersions from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/isAllVersions';
import { UnifiedTag } from '../types';

const getTagsOfSourceSystem = (
  tagsArray: UnifiedTag[] = [],
  integration = '',
): UnifiedTag[] => tagsArray.filter(
  ({ sourceSystems = [] }) => !!sourceSystems.some(({ parentSourceSystem }) => isAllVersions(parentSourceSystem, integration)),
);

export default getTagsOfSourceSystem;
