import { BamNotificationStatusOptions } from '@bp/bam';
import {
  map, pipe, uniq, uniqBy,
} from 'lodash/fp';
import get from 'lodash/get';
import identity from 'lodash/identity';
import partition from 'lodash/partition';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import { batchActions } from 'redux-batched-actions';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';

import * as autoshareAPI from '../../../../../app/common/endpoints/auto-share-rules';
import { createContact } from '../../../../../app/common/endpoints/contacts';
import { actionTypes as enrichedUsersActionTypes } from '../../../../../app/react/common/enriched_users';
import { loadEnrichedUsers } from '../../../../../app/react/common/enriched_users/actions';
import { actionTypes as integrationsActionTypes } from '../../../../../app/react/common/integrations';
import { loadIntegrations } from '../../../../../app/react/common/integrations/actions';
import { showFailureMessage, showSuccessMessage } from '../../../../../app/react/layout/settings/feedback';
import envActions from '../../../../../app/react/modules/settings/roles_management/roles_list/actions';
import envActionTypes from '../../../../../app/react/modules/settings/roles_management/roles_list/actionTypes';
import { selectors as FTSelectors } from '../../../../../app/react/user/feature_toggles';
import onboardingDictionary from '../../../onboarding/src/dictionary';
import { completeStep } from '../../../onboarding/src/endpoints/onboarding';
import { shouldAllowOnboarding } from '../../../onboarding/src/helpers/shouldAllowOnboarding';
import * as integrationsAPI from '../api/integrations-api';
import {
  DROPDOWN_FILTER_STATUS_ITEMS,
  DROPDOWN_FILTERS_ALL_ITEM_OPTION,
  EMAIL_PATTERN,
  RULE_CONTACTS_PATH,
  RULES_CONTACT_TYPES,
} from '../core/constants';
import utils from '../core/utils';
import actions from './actions';
import actionTypes from './actionTypes';

const showSuccessMessageForRule = (action, status = BamNotificationStatusOptions.ACTION_SUCCESS) => showSuccessMessage('AutoShare', action, status);
const showFailureMessageForRule = (action) => showFailureMessage('AutoShare', action);

const ruleFilterDropDown = (text, value, phoneOrEmail = false, isContact = false) => {
  const ruleName = isContact && phoneOrEmail ? `${phoneOrEmail} (${text})` : text;

  return {
    text: ruleName,
    value,
    key: value,
  };
};

const loadAutoShareRules = (action$) => action$
  .ofType(actionTypes.LOAD_AUTO_SHARE_RULES)
  .map(() => batchActions([
    envActions.loadEnvironments(),
    loadIntegrations(),
    loadEnrichedUsers(),
    actions.loadRules(),
  ]));

const loadRules = (action$) => action$.ofType(actionTypes.LOAD_RULES).mergeMap(async () => {
  try {
    window.location.href = '/v2/settings/auto-share';
    const rules = await autoshareAPI.getMany();
    return actions.loadRulesSuccess(rules);
  } catch (e) {
    showFailureMessage('load');
    return actions.loadAutoShareRulesFailure();
  }
});

const formatContact = (usersMap) => (contact) => {
  const user = usersMap.get(contact.bigpanda_user_id) || { username: '' };
  const name = contact && contact.name ? contact.name : user.username;
  const { email, _id: contactId, phone_number: phoneNumber } = contact || {};
  const contactDetails = pickBy(
    {
      contactId,
      name,
      email,
      phoneNumber,
    },
    identity,
  );

  return { ...user, ...contactDetails };
};

const formatAutoShareRules = (action$) => Observable.zip(
  action$.ofType(envActionTypes.LOAD_ENVIRONMENTS_SUCCESS),
  action$.ofType(integrationsActionTypes.LOAD_INTEGRATIONS_SUCCESS),
  action$.ofType(enrichedUsersActionTypes.LOAD_ENRICHED_USERS_SUCCESS),
  action$.ofType(actionTypes.LOAD_RULES_SUCCESS),
  (
    { payload: environments },
    { payload: integrations },
    { payload: enrichedUsers },
    { payload: rules },
  ) => {
    const environmentsById = environments.reduce(
      (environmentsMap, env) => environmentsMap.set(env.old_id, env),
      new Map(),
    );
    const integrationsByTargetSystem = integrations.reduce(
      (accum, integration) => accum.set(integration.target_system_id, integration),
      new Map(),
    );
    const usersById = enrichedUsers.reduce(
      (usersMap, user) => usersMap.set(user.id, user),
      new Map(),
    );

    const getRuleEnvironment = (rule) => environmentsById.get(get(rule, 'envId'));
    const getRuleSystem = (rule) => integrationsByTargetSystem.get(get(rule, 'targetSystemId'));

    const formattedRules = rules
      .filter((rule) => !get(rule, 'deleted') && getRuleEnvironment(rule) && getRuleSystem(rule))
      .map((rawRule) => {
        const rule = rawRule;
        rule.environment = getRuleEnvironment(rule) || {};
        rule.system = getRuleSystem(rule) || {};
        if (get(rule, RULE_CONTACTS_PATH)) {
          rule.params.contacts = rule.params.contacts.map(formatContact(usersById));
        }
        return rule;
      });

    return actions.loadAutoShareRulesSuccess(formattedRules);
  },
);

const contactsList = async (contacts) => {
  if (!contacts || !contacts.length) return [];
  const [existingContacts, contactsToCreate] = partition(contacts, (contact) => !!contact.id);
  const newContacts = await Promise.all(contactsToCreate.map(createContact));
  return [...existingContacts, ...newContacts];
};

const createAutoShareRule = function createAutoShareRule(action$, state$) {
  return action$
    .ofType(actionTypes.CREATE_AUTO_SHARE_RULE)
    .mergeMap(async ({ payload: rawPayload }) => {
      const payload = rawPayload;
      const state = state$.getState();
      const targetSystem = payload.targetSystemId.split('.')[0];

      try {
        if (integrationsAPI.supportedTargetSystem.includes(targetSystem)) {
          const integrations = get(state, 'common.integrations.integrations', []);
          const currentIntegration = integrations
            .find((int) => int.target_system_id === payload.targetSystemId);
          if (currentIntegration) {
            const { stream_id: appKey } = currentIntegration;
            const { envId } = payload;
            const outboundIntegration = await integrationsAPI.getIntegrations(appKey);
            const outboundIntegrationId = outboundIntegration[0].id;
            if (!outboundIntegrationId) {
              throw new Error('Integration doesn\'t exist');
            }
            const newAutoSharePayload = {
              environment: envId,
              active: true,
              integration_id: outboundIntegrationId,
            };
            await integrationsAPI.createAutoShareRule(newAutoSharePayload);
          }
        } else {
          const contacts = await contactsList(get(payload, RULE_CONTACTS_PATH));
          if (contacts.length) {
            payload.params.contacts = contacts;
          }
          if (get(payload, ['params', 'autoshare_options'])) {
            payload.autoshare_options = payload.params.autoshare_options;
            delete payload.params.autoshare_options;
          }
          await autoshareAPI.create(payload);
        }

        const userFeatureToggles = FTSelectors.getFeatureToggles(state$.getState());
        const isOnboarding = shouldAllowOnboarding(userFeatureToggles);
        if (isOnboarding) {
          completeStep(onboardingDictionary.onboardingSteps.autohShare);
        }
        showSuccessMessageForRule('added');
      } catch (e) {
        showFailureMessageForRule('add');
      }
      return actions.loadAutoShareRules();
    });
};

const updateAutoShareRule = function updateAutoShareRule(action$, state$) {
  return action$
    .ofType(actionTypes.UPDATE_AUTO_SHARE_RULE)
    .mergeMap(async ({ payload: rawPayload }) => {
      const payload = rawPayload;
      try {
        const contacts = await contactsList(get(payload, RULE_CONTACTS_PATH));
        if (contacts.length) payload.params.contacts = contacts;
        const state = state$.getState();
        const rules = get(state, 'modules.settings.autoShareRules.rules', []);
        const currentRule = rules.find((rule) => rule.id === payload.id);
        const targetSystem = currentRule.targetSystemId.split('.')[0];

        if (Object.hasOwn(payload, 'inactive')
          && integrationsAPI.supportedTargetSystem.includes(targetSystem)) {
          const integrationsApiRuleId = get(currentRule, 'params.autoshare_options.autoshare_rule_id');
          await integrationsAPI
            .toggleAutoShareRule(integrationsApiRuleId, !payload.inactive);
        }
        await autoshareAPI.update(payload);
        showSuccessMessageForRule('updated');
      } catch (e) {
        showFailureMessageForRule('update');
      }
      return actions.loadAutoShareRules();
    });
};

const deleteAutoShareRule = function deleteAutoShareRule(action$) {
  return action$.ofType(actionTypes.DELETE_AUTO_SHARE_RULE).mergeMap(async ({ rule }) => {
    try {
      const targetSystem = rule.targetSystemId.split('.')[0];
      if (integrationsAPI.supportedTargetSystem.includes(targetSystem)) {
        const integrationsApiRuleId = get(rule, 'params.autoshare_options.autoshare_rule_id');
        await integrationsAPI.deleteAutoShareRule(integrationsApiRuleId);
      } else {
        await autoshareAPI.remove(rule.id);
      }
      showSuccessMessageForRule('deleted');
    } catch (e) {
      showFailureMessageForRule('delete');
    }
    return actions.loadAutoShareRules();
  });
};

const createFiltersDropDownItems = function createFiltersDropDownItems(action$) {
  return action$.ofType(actionTypes.LOAD_AUTO_SHARE_RULES_SUCCESS).map(({ payload }) => {
    const arrangeRecipientsArray = (recipient) => {
      const recipientsContactsEmail = [];
      const recipientsContactsPhone = [];
      const recipientsPhone = [];
      const recipientsEmail = [];

      recipient.forEach(
        (autoShareRule) => get(autoShareRule, ['params', 'contacts'])
          && autoShareRule.params.contacts.forEach((contact) => {
            const { name } = contact;
            if (get(contact, '_id')) {
              if (autoShareRule.system.id === RULES_CONTACT_TYPES.email) {
                recipientsContactsEmail.push(
                  ruleFilterDropDown(name, name + contact.username, contact.username, true),
                );
              } else if (autoShareRule.system.id === RULES_CONTACT_TYPES.sms) {
                const { phone_number: phoneNumber } = contact;
                recipientsContactsPhone.push(
                  ruleFilterDropDown(name, name + phoneNumber, phoneNumber, true),
                );
              }
            } else if (EMAIL_PATTERN.test(name)) {
              recipientsEmail.push(ruleFilterDropDown(name, name));
            } else {
              recipientsPhone.push(ruleFilterDropDown(name, name));
            }
          }),
      );

      return uniqBy('text', [
        ...recipientsContactsEmail,
        ...recipientsEmail,
        ...recipientsContactsPhone,
        ...recipientsPhone,
      ]);
    };

    const sortForDropDown = (arr) => sortBy(arr, ['text']);

    const environmentItemsArray = sortForDropDown(
      pipe(
        map((ruleEnvironment) => ruleEnvironment.environment),
        uniq,
        map(({ name, _id }) => ruleFilterDropDown(utils.envName(name), _id)),
        Object.values,
      )(payload),
    );

    const channelItemsArray = sortForDropDown(
      pipe(
        map((ruleChannel) => ruleChannel.system),
        uniq,
        map((ruleChannelSystem) => ruleFilterDropDown(
          utils.systemDisplayText(ruleChannelSystem),
          ruleChannelSystem.system_id,
        )),
        Object.values,
      )(payload),
    );

    const filtersObj = {
      environment: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.environment, ...environmentItemsArray],
      channel: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.channel, ...channelItemsArray],
      recipients: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.recipients, ...arrangeRecipientsArray(payload)],
      status: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.status, ...DROPDOWN_FILTER_STATUS_ITEMS],
    };

    return actions.createFiltersDropDownItemsSuccess(filtersObj);
  });
};

export default combineEpics(
  loadAutoShareRules,
  loadRules,
  formatAutoShareRules,
  updateAutoShareRule,
  deleteAutoShareRule,
  createAutoShareRule,
  createFiltersDropDownItems,
);
