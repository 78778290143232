angular.module('bigpanda.reports').constant('TimeGranularityConfig', {
  day: {
    displayFormat: d3.time.format('%b %-d'),
    ticks: d3.time.day.utc,
    offsetObject: d3.time.hour,
    offset: 12,
  },
  week: {
    displayFormat: d3.time.format('%b %-d'),
    ticks: d3.time.monday.utc,
    offsetObject: d3.time.day,
    offset: 1,
  },
  month: {
    displayFormat: d3.time.format("%b '%y"),
    ticks: d3.time.month.utc,
    offsetObject: d3.time.week,
    offset: 1,
  },
});
