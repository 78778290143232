import oneloginLogo from './assets/img/OneLogin.svg';
import oktaLogo from './assets/img/Okta.svg';
import pingoneLogo from './assets/img/PingOne.svg';
import caLogo from './assets/img/CA.svg';
import entraLogo from './assets/img/EntraID.svg';

const SSOProviderTabs = [
  { name: 'onelogin', display: 'OneLogin', imgSrc: oneloginLogo },
  { name: 'okta', display: 'Okta', imgSrc: oktaLogo },
  { name: 'pingone', display: 'PingOne', imgSrc: pingoneLogo },
  { name: 'ca', display: 'CA Single Sign On', imgSrc: caLogo, featureToggle: true },
  { name: 'azure', display: 'Microsoft Entra ID', imgSrc: entraLogo },
];

export default SSOProviderTabs;
