import actionTypes from './actionTypes';

export function loadUser() {
  return { type: actionTypes.LOAD_USER };
}

export function loadUserSuccess(payload) {
  return { type: actionTypes.LOAD_USER_SUCCESS, payload: payload };
}

export function updateUser(payload) {
  return { type: actionTypes.UPDATE_USER, payload: payload };
}

export function updateUserSuccess(payload) {
  return { type: actionTypes.UPDATE_USER_SUCCESS, payload: payload };
}

export function updateUserFailure(payload) {
  return { type: actionTypes.UPDATE_USER_FAILURE, payload: payload };
}
