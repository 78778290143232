import {
  Date, DateFormat, HelpTooltip, Text,
} from '@bp/kung-fu';
import React, {
  memo, ReactNode, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';

import { SOURCE_INFO_FAILED_TEXT, SOURCE_INFO_LOADING_TEXT }
  from '../../../../../../../../app/angularjs/integrations/integrations.constants';
import FTSelectors from '../../../../../../../../app/react/user/feature_toggles/selectors';
import dic from '../../IncidentView.dic';
import { IHeaderSubtitle, ISourceInfo } from '../../types';
import { getToolTipText, isCorrelation } from '../logic';
import {
  CustomDivider, NameText, SourceTitleDescription, SourceTitleText, ValueText,
} from './HeaderSubtitle.style';

const SubTitleItem = ({ name, value }: { name: string; value: ReactNode }): React.ReactElement => (
  <Text>
    <NameText>{name}</NameText>
    <ValueText>{value}</ValueText>
  </Text>
);

const HeaderSubTitle = ({
  start,
  type,
  subtitle,
  breakdownSources,
  maintenanceTitle,
  numMaintenanceAlerts,
}: IHeaderSubtitle): React.ReactElement => {
  const [toolTipText, setToolTipText] = useState<ISourceInfo[]>([
    { title: breakdownSources.lastSystem, description: SOURCE_INFO_LOADING_TEXT } as ISourceInfo]);
  const featureToggles = useSelector(FTSelectors.getFeatureToggles)?.featureToggles;
  const useAutoResolveTooltip: boolean = featureToggles?.frontier_enable_auto_resolve_default || false;
  useEffect(() => {
    if (useAutoResolveTooltip) {
      const fetchData = async () => {
        try {
          setToolTipText(await getToolTipText(breakdownSources));
        } catch (e){
          setToolTipText([{ title: breakdownSources.lastSystem, description: SOURCE_INFO_FAILED_TEXT }]);
        }
      };
      fetchData();
    }
  }, []);

  return (
    <CustomDivider>
      {numMaintenanceAlerts > 0 && <SubTitleItem name="" value={maintenanceTitle} />}
      <SubTitleItem name={dic.created_title} value={<Date date={start * 1000} format={DateFormat.Relative} />} />
      {isCorrelation(type) && (
        <SubTitleItem name={dic.time_window_title} value={`${subtitle.window} ${dic.min_title}`} />
      )}
      <SubTitleItem
        name={dic.source_title}
        value={(
          <Text>
            {breakdownSources.otherSystems && (
              <Text textTransform="capitalize">
                {breakdownSources.otherSystems}
                <Text textTransform="capitalize" color={(p): string => p.theme.bp_gray_06}>
                  {dic.spaced_and_title}
                </Text>
              </Text>
            )}

            <Text textTransform="capitalize">{breakdownSources.lastSystem}</Text>
          </Text>
                  )}
      />
      {useAutoResolveTooltip
            && <HelpTooltip text={toolTipText.map((source) => (<SourceInfo key={source.title} source={source} />))} />}
    </CustomDivider>
  );
};

const SourceInfo = ({ source }: { source: ISourceInfo }): JSX.Element => (
  <Text>
    <SourceTitleText weight="bold" textTransform="capitalize">{`${source.title.trim()}:`}</SourceTitleText>
    <SourceTitleDescription textTransform="capitalize">{source.description}</SourceTitleDescription>
  </Text>
);

export default memo(HeaderSubTitle);
