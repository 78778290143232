import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { darkTheme, Badge, AllSizes } from '@bp/kung-fu';
import { BamDetails, BamActivityRecord, TimeFormats } from '@bp/bam';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { bpUserImg, bigPandaUserName } from 'common/constants';
import { RELATED_METADATA_COL_ID, tagKey } from '../Consts';
import ChangeCorrelationIndicator from './ChangeCorrelationIndicator/ChangeCorrelationIndicator';
import RelatedChangeActivityRecord from '../RelatedChangeActivityRecord/RelatedChangeActivityRecord';
import RelatedChangeActivityRecordTooltip from '../RelatedChangeActivityRecordTooltip/RelatedChangeActivityRecordTooltip';
import Comment from '../Comment';
import styles from './custom_details.scss';

const { momentOrEpochRelativeTimeFormat } = TimeFormats;
const colIdToComponent = {
  status: ({ value = '' }) => (
    <Badge size={AllSizes.MEDIUM} text={value} status={value.toLowerCase()} />
  ), // eslint-disable-line
};

const createTagsFromRow = (row, columns) =>
  columns.reduce((acc, col) => {
    if (col.id === RELATED_METADATA_COL_ID) return acc;
    const contentFunc = colIdToComponent[col.id] || col.tagContent;
    const value = get(row, col.id, get(row, tagKey(col.id)));
    if (value === undefined && contentFunc === undefined) return acc;
    const args = { value, row };
    const tagValue = contentFunc ? contentFunc(args) : col.cellContent(args);
    return [...acc, { tag: col.detailsTag || col.headerContent, value: tagValue }];
  }, []);

const getLastSuggestedByBPRcc = (relatedChangesHistory) =>
  relatedChangesHistory.find((relatedChange) => relatedChange.suggested_by_bigpanda);

const MlIndicatorActivityRecord = ({
  updated_at: updatedAt,
  comment,
  rich_comment: richComment,
  ...props
}) => (
  <div style={{ width: '320px' }}>
    <RelatedChangeActivityRecord
      suggestedByBP
      updatedAt={updatedAt}
      comment={comment}
      richComment={richComment}
      matchCertainty="SUSPECT"
    />
  </div>
);

const mlIndicator = (suggestedByAlgoRCC) => (
  <div className={styles.bannerWrapper}>
    <div className={styles.lineSeparator} />
    <div className={styles.bannerContent}>
      <BamDetails.BamDetailsBanner>
        <RelatedChangeActivityRecordTooltip
          trigger={<ChangeCorrelationIndicator />}
          position="bottom-start"
          content={<MlIndicatorActivityRecord {...suggestedByAlgoRCC} />}
        />
      </BamDetails.BamDetailsBanner>
    </div>
  </div>
);

const ChangesDetails = ({ row, columns, onClose, user, ...props }) => {
  const updatedAt = get(row, '_original.updated_at');
  const tags = createTagsFromRow(row, columns);
  const matchCertainty = get(row, 'relatedMetadata.match_certainty', '');
  const comment = get(row, 'relatedMetadata.comment', '');
  const richComment = get(row, 'relatedMetadata.rich_comment', '');
  const matchUpdated = get(row, 'relatedMetadata.updated_at');
  const suggestedByBp = get(row, 'relatedMetadata.suggested_by_bigpanda', false);
  const relatedMetadtaHistory = get(row, 'relatedMetadata.history');
  const suggestedByAlgoRCC =
    relatedMetadtaHistory && getLastSuggestedByBPRcc(relatedMetadtaHistory);

  return (
    <ThemeProvider theme={darkTheme}>
      <BamDetails {...props}>
        <BamDetails.BamDetailsHeader
          onClose={onClose}
          title="Change Details"
          subtext={{
            key: 'Last Modified',
            value: momentOrEpochRelativeTimeFormat(updatedAt),
          }}
        />
        <BamDetails.BamDetailsBody>
          {(user || suggestedByBp) && (
            <BamDetails.BamDetailsBanner>
              <BamActivityRecord
                quote
                name={suggestedByBp ? bigPandaUserName : user.name}
                avatarUrl={suggestedByBp ? bpUserImg : ''}
                date={matchUpdated}
                match={matchCertainty.toLowerCase()}
                title={`Marked as a ${capitalize(matchCertainty)}`}
                text={
                  <Comment
                    comment={comment}
                    richComment={richComment}
                    suggestedByBp={suggestedByBp}
                  />
                }
                email={user && user.email}
              />
            </BamDetails.BamDetailsBanner>
          )}
          {!suggestedByBp && suggestedByAlgoRCC && mlIndicator(suggestedByAlgoRCC)}
          <BamDetails.BamDetailsTags tags={tags} />
        </BamDetails.BamDetailsBody>
      </BamDetails>
    </ThemeProvider>
  );
};

const userPropType = PropTypes.shape({
  email: PropTypes.string,
  name: PropTypes.string,
});
ChangesDetails.propTypes = {
  row: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  user: userPropType,
};

ChangesDetails.defaultProps = {
  user: undefined,
};

MlIndicatorActivityRecord.propTypes = {
  updated_at: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  rich_comment: PropTypes.string.isRequired,
};

export default ChangesDetails;
