import { react2angular } from 'react2angular';
import AnalyticsView from '../../../../../workspaces/apps/analytics/src/components/AnalyticsView/AnalyticsView';

const routes = {};

routes.appAnalyticsView = {
  url: '/unified-analytics',
  views: {
    widget: {
      template: '<analytics-view style="height: 100%" store="store" ></analytics-view>',
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.updatePage('unified-analytics');
        $scope.store = $ngRedux;
      },
    },
  },
};
angular.module('bigpanda').component('analyticsView', react2angular(AnalyticsView, ['store']));

export default routes;
