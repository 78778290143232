import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import { Wrapper, Label, LabelValue, LabelGroup, ArrowIcon } from './SelectToggle.css';
import Chip from '../../Chip/Chip';
import { variants } from '../constants';

const SelectToggle = ({
  value,
  isOpen,
  getItemDisplayValue,
  selectedItem,
  selectedGroupValue,
  placeholder,
  disabled,
  error,
  variant,
  fullWidth,
  multiChoice,
  getLabelProps,
  getToggleButtonProps,
  ariaLabel,
}) => {
  const isFilterVariant = variant === variants.filter;
  const isPlaceHolder =
    !selectedItem || !get(selectedItem, 'value') || get(selectedItem, 'isAnyOption', false);

  return (
    <Wrapper
      {...getToggleButtonProps({ disabled })}
      isOpen={isOpen}
      error={error}
      isFilterVariant={isFilterVariant}
      fullWidth={fullWidth}
      type="button"
    >
      <Label
        role="listbox"
        aria-label={ariaLabel}
        isFilterVariant={isFilterVariant}
        multiChoice={multiChoice}
        {...getLabelProps()}
      >
        {multiChoice ? (
          <React.Fragment>
            {get(value, 'length') ? (
              map(value || [], (item) => <Chip key={item.value}>{getItemDisplayValue(item)}</Chip>)
            ) : (
              <LabelValue isFilterVariant={isFilterVariant} isPlaceholder>
                {placeholder}
              </LabelValue>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <LabelValue isFilterVariant={isFilterVariant} isPlaceholder={isPlaceHolder}>
              {get(selectedItem, 'isAnyOption')
                ? placeholder
                : getItemDisplayValue(selectedItem, { isToggleItem: true }) || placeholder}
            </LabelValue>
            {selectedGroupValue && <LabelGroup>({selectedGroupValue})</LabelGroup>}
          </React.Fragment>
        )}
      </Label>
      <ArrowIcon className="bp-icon bp-icon-arrow-down" isFilterVariant={isFilterVariant} />
    </Wrapper>
  );
};

SelectToggle.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isOpen: PropTypes.bool,
  getItemDisplayValue: PropTypes.func,
  selectedItem: PropTypes.shape({ value: PropTypes.string }),
  selectedGroupValue: PropTypes.string,
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(variants)),
  fullWidth: PropTypes.bool,
  multiChoice: PropTypes.bool,
  getLabelProps: PropTypes.func,
  getToggleButtonProps: PropTypes.func,
};

export default SelectToggle;
