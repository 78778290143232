import actionTypes from './actionTypes';

export const initialState = {
  merge: undefined,
  error: undefined,
  isMerging: false,
  isModalOpen: false,
  switching: false,
  openAddNote: false,
  annotation: '',
  incidents: [],
  sourceIncidents: [],
  destIncident: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        isMerging: false,
        openAddNote: false,
        annotation: '',
      };
    case actionTypes.SWITCH_DEST_INCIDENT_SUCCESS:
      return {
        ...state,
        sourceIncidents: action.payload.sourceIncidents,
        destIncident: action.payload.destIncident,
        switching: false,
      };
    case actionTypes.CREATE_MERGE:
      return {
        ...state,
        isMerging: true,
      };
    case actionTypes.CREATE_MERGE_SUCCESS:
      return {
        ...state,
        merge: action.payload,
        isMerging: false,
      };
    default:
      return state;
  }
}
