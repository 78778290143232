import { isNil } from 'lodash/fp';
import bpFetch from 'common/utils/http/fetcher';

const baseUrl = '/resources/v2.0';
const definitionsUrl = `${baseUrl}/incidents/tags/definitions`;

export function getIncidentLabel(id) {
  return bpFetch({ url: `${definitionsUrl}/${id}` }).then((res) => res.data);
}

export function getMultipleIncidentTagDefinitions(active = true, type = 'all') {
  return bpFetch({
    url: definitionsUrl,
    data: { active, type },
  }).then((res) => res.data);
}

export function createAiAnalysis(environmentId, incidentId) {
  return bpFetch({
    url: `${baseUrl}/environments/${environmentId}/incidents/${incidentId}/ai/analysis`,
    method: 'POST',
  }).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
}

export function createIncidentLabel(incidentLabelDefinition) {
  if (isNil(incidentLabelDefinition.name)) {
    return Promise.reject(new Error('name parameter must be defined'));
  }
  return bpFetch({
    url: definitionsUrl,
    method: 'POST',
    data: incidentLabelDefinition,
  }).then(
    (res) => res.data,
    (error) => {
      throw error.response.data.response;
    }
  );
}

export function updateIncidentLabel(id, incidentLabelDefinition) {
  if (isNil(id) || isNil(incidentLabelDefinition.name)) {
    return Promise.reject(new Error('id and name parameters must be defined'));
  }
  return bpFetch({
    url: `${definitionsUrl}/${id}`,
    method: 'PUT',
    data: incidentLabelDefinition,
  }).then(
    (res) => res.data,
    (error) => {
      throw error.response.data.response;
    }
  );
}

export function getLabelsForIncident(environmentId, incidentId) {
  return bpFetch({
    url: `${baseUrl}/environments/${environmentId}/incidents/${incidentId}/tags`,
  }).then((res) => res.data);
}

export function setTagValueForIncident(environmentId, incidentId, tagId, tagValue) {
  return bpFetch({
    url: `${baseUrl}/environments/${environmentId}/incidents/${incidentId}/tags`,
    method: 'POST',
    data: [
      {
        tag_id: tagId,
        tag_value: tagValue,
      },
    ],
  }).then((res) => res.data);
}

export function removeTagValueFromIncident(environmentId, incidentId, tagId) {
  return bpFetch({
    url: `${baseUrl}/environments/${environmentId}/incidents/${incidentId}/tags/${tagId}`,
    method: 'DELETE',
  }).then((res) => res.data);
}

export function deleteTagDefinition(id) {
  return bpFetch({ method: 'DELETE', url: `${definitionsUrl}/${id}` }).then((res) => res.data);
}
