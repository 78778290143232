import bpFetch from 'common/utils/http/fetcher';
import pickBy from 'lodash/pickBy';

const baseUrl = '/resources/v2.0/apikeys';

export function getApiKeys() {
  return bpFetch({ url: baseUrl, params: { full_user_data: true } }).then((res) => res.data.item);
}

export function getApiKey(apiKeyId) {
  return bpFetch({ url: `${baseUrl}/${apiKeyId}`, params: { full_user_data: true } }).then(
    (res) => res.data.item
  );
}

export function createApiKey({ associatedUserId, name, description }) {
  return bpFetch({
    url: baseUrl,
    method: 'POST',
    data: pickBy({
      user_id: associatedUserId,
      name: name,
      description: description,
      active: true,
    }),
  }).then((res) => res.data.item);
}

export function updateApiKey({ apiKeyId, associatedUserId, name, description, active }) {
  const data = {
    user_id: associatedUserId,
    name,
    description,
    active,
  };
  return bpFetch({
    url: `${baseUrl}/${apiKeyId}`,
    method: 'PATCH',
    data,
  }).then((res) => res.data.item);
}

export function deleteApiKey(apiKeyId) {
  return bpFetch({ url: `${baseUrl}/${apiKeyId}`, method: 'DELETE' }).then((res) => res.data.item);
}

export function restoreApiKey(apiKeyId) {
  return bpFetch({ url: `${baseUrl}/${apiKeyId}/restore`, method: 'PATCH' }).then(
    (res) => res.data.item
  );
}
