import { combineReducers } from 'redux';
import * as settings from './settings';
import * as feed from './feed';
import * as tags from './tags';
import * as changes from './changes';
import * as overview from './overview';
import * as integrations from './integrations';

const reducers = {
  [settings.name]: settings.reducer,
  [feed.name]: feed.reducer,
  [changes.name]: changes.reducer,
  [tags.name]: tags.reducer,
  [overview.name]: overview.reducer,
  [integrations.name]: integrations.reducer,
};
export default combineReducers(reducers);
