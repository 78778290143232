import { VBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const TableContainerWrapper = styled.div`
  height: 78vh;
  width: 100%;
  margin-top: 33px;


  @media only screen and (min-width: 1980px) {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TitleWrapper = styled.div`

  @media only screen and (min-width: 1980px) {
    width: 90%;
  }
`;

export const SubTitleWrapper = styled(VBox)`
  margin-top: 20px;
  gap: 6px;
  
  @media only screen and (min-width: 1980px) {
    width: 90%;
  }
`;
