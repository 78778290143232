import get from 'lodash/get';
import reject from 'lodash/reject';
import { AUTO_RESOLVE_TIME_OPTIONS } from './integrations.constants';

angular.module('bigpanda').controller('IntegrationsController', IntegrationsController);

function IntegrationsController(
  $scope,
  stateService,
  UserIntegrationsStore,
  pubSubService,
  UserIntegrationsUtils,
  PERMISSIONS_NAMES,
  Permissions,
  AvailableIntegrationsStore
) {
  const vm = this;
  const availableIntegrations = AvailableIntegrationsStore.getAvailableIntegrations().reduce(
    (acc, curr) => ({ ...acc, [curr.source_system]: curr }),
    {}
  );
  vm.search = '';
  vm.loadingRoute = true;
  vm.selectedId = selectedId;
  vm.shouldDisplayErrors = shouldDisplayErrors;
  vm.integrations = [];
  vm.invitePermissions = PERMISSIONS_NAMES.users.create;
  vm.createPermissions = PERMISSIONS_NAMES.integrations.create;
  vm.readPermissions = PERMISSIONS_NAMES.integrations.read;
  vm.getAutoResolveValue = getAutoResolveValue;

  vm.buttons = [
    {
      icon: 'bp-icon-trash',
      name: 'Delete',
      action: deleteIntegration,
      permission: PERMISSIONS_NAMES.integrations.delete,
    },
  ];
  vm.$onInit = onInit;

  function onInit() {
    window.location.replace('/v2/integrations');

    pubSubService.broadcast('currentTitleChanged', 'Integrations');
    $scope.updatePage('integrations');

    vm.hasPermissions = Permissions.isPermitted(vm.readPermissions);
    vm.isTroubleshootingFeatureEnabled = Permissions.hasFullAccess('integrations');
    if (!vm.hasPermissions) {
      return;
    }

    getUserIntegrations().then(() => {
      UserIntegrationsStore.subscribe($scope, { storeUpdated: getUserIntegrations });
      vm.loadingRoute = false;
    });
  }

  function getUserIntegrations() {
    return UserIntegrationsStore.getIntegrations(true).then((vmIntegrations) => {
      let integrations = reject(
        vmIntegrations,
        (vmIntegration) =>
          !vmIntegration.parent_source_system || !UserIntegrationsUtils.isVisible(vmIntegration)
      );
      integrations = integrations.map((vmIntegration) => {
        const imgUrl = `/assets/img/logos/${vmIntegration.parent_source_system.replace(
          ' ',
          ''
        )}.png`;
        vmIntegration.imgUrl = imgUrl;
        return vmIntegration;
      });
      vm.integrations = integrations;
    });
  }

  function deleteIntegration(integration, e) {
    pubSubService.broadcast('Intercom.showNewMessage', {
      message: `[${integration.system_id}] Please delete the following integration: ${integration.name} (ID: ${integration.stream_id})`,
    });
    e.stopPropagation();
  }

  function selectedId() {
    return stateService.getSelectedIntegrationId();
  }

  function shouldDisplayErrors(integration) {
    const integrationCategory = get(
      availableIntegrations[integration.parent_source_system],
      'category'
    );
    return (
      this.isTroubleshootingFeatureEnabled &&
      integrationCategory === 'monitoring' &&
      integration.last_day_error_count > 0
    );
  }

  function getAutoResolveValue(autoResolveTime) {
    return (AUTO_RESOLVE_TIME_OPTIONS.find((o) => o.value === String(autoResolveTime)) || {}).label;
  }
}
