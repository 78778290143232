import * as bpColors from '@bp/pastel/colors';

const getBorderColor = (error, focus) => {
  if (error) {
    return bpColors.bp_red;
  }
  if (focus) {
    return bpColors.bp_blue_light;
  }
  return '';
};

const creatableSelectCustomStyles = (error, focus) => {
  const borderColor = getBorderColor(error, focus);
  return {
    control: (base, state) => ({
      ...base,
      minHeight: '35px',
      maxHeight: '70px',
      maxWidth: '100%',
      overflowY: 'auto',
      fontSize: '12px',
      borderColor: borderColor || bpColors.bp_gray_04,
      boxShadow: error ? bpColors.bp_red : bpColors.bp_blue,
      ':hover': {
        borderColor: borderColor || bpColors.bp_gray_05,
        boxShadow: error ? bpColors.bp_red : bpColors.bp_gray_05,
      },
      borderRadius: 3,
      cursor: 'text',
      padding: '4px',
    }),
    input: (base, state) => ({
      color: error ? bpColors.bp_red : bpColors.bp_gray_08,
      fontSize: '12px',
      padding: '2px',
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: 0,
    }),
    multiValue: (base, state) => ({
      ...base,
      border: `1px solid ${bpColors.bp_gray_03}`,
      borerRadius: '3px',
      background: bpColors.bp_gray_01,
      height: '22px',
      marginRight: '4px',
      overflow: 'unset',
      ':hover': {
        background: bpColors.bp_white,
        color: bpColors.bp_gray_07,
        cursor: 'pointer',
      },
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      fontSize: '12px',
      display: 'inline-block',
      width: '100%',
      verticalAlign: 'middle',
      lineHeight: 'normal',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      padding: '1px 3px 0 0',
      ':hover': {
        cursor: 'pointer',
        background: bpColors.bp_gray_01,
        '>svg': {
          fill: bpColors.bp_gray_07,
        },
      },
      '>svg': {
        width: '15px',
        height: '15px',
        fill: bpColors.bp_gray_05,
      },
    }),
    option: (base, state) => ({
      ...base,
      padding: '0',
    }),
    menuPortal: (base, state) => ({
      ...base,
      zIndex: 99999,
    }),
    menu: (base, state) => ({
      ...base,
      marginTop: '2px',
      borderRadius: 3,
      border: `1px solid ${bpColors.bp_gray_04}`,
      boxShadow: '0 0 0 0 rgba(0,0,0,0.1), 0 4px 11px rgba(0,0,0,0.1)',
    }),
    placeholder: (base) => ({
      ...base,
      color: bpColors.bp_gray_04,
    }),
  };
};
export default creatableSelectCustomStyles;
