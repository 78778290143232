import { AllSizes, SCHBox, Text } from '@bp/kung-fu';
import startCase from 'lodash/startCase';
import React from 'react';

import { Tag } from '../../../types';

interface IProps {
  tag: Tag;
  className?: string;
}

export function AlertTagFormFunctionType({ tag, className }: IProps): JSX.Element {
  return (
    <SCHBox gap="5px" className={className}>
      <Text color={(p): string => p.theme.bp_gray_06} size={AllSizes.SMALL}>
        Function Type:
      </Text>
      <Text
        aria-label="alert tag function type"
        weight={tag?.type ? 'bold' : ''}
        color={(p): string => (tag?.type ? p.theme.bp_gray_07 : p.theme.bp_gray_04)}
        size={AllSizes.SMALL}
      >
        {tag?.type ? startCase(tag?.type) : 'No Function Type'}
      </Text>
    </SCHBox>
  );
}

export default AlertTagFormFunctionType;
