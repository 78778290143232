import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import {
  incidentTagTypes,
  incidentTagClosedListTypes,
} from '../settings/incident_labels/constants';

export const validateTextValue = (value) => (value && value.length > 256 ? 'Max 256 chars' : null); //eslint-disable-line

export const isInitialValueDifferent = (tagDefinition, nextValue, prevValue) => {
  switch (tagDefinition.type) {
    case incidentTagTypes.TEXT:
      return nextValue !== prevValue;
    case incidentTagTypes.MULTI_VALUE:
      return !isEqual(sortBy(nextValue), sortBy(prevValue));
    default:
      return false;
  }
};

export const isTagDefinitionClosedList = (tagDefinition) => {
  return [
    incidentTagClosedListTypes.CLOSED_LIST_VALUE_MULTI,
    incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE,
  ].includes(tagDefinition.type);
};
