import { HBox, VBox } from '@bp/kung-fu';
import find from 'lodash/find';
import map from 'lodash/map';
import React from 'react';

import { EnrichmentRule, Tag } from '../../../types';
import {
  BasicTitle, ConditionText, Icon, LineSeparator, MiddleValue, StyledHBox, Title, Value,
} from './style';

interface IProps {
  values: EnrichmentRule;
  tag: Tag;
  rule: EnrichmentRule;
}
export function MappingForm({ values, tag, rule }: IProps): JSX.Element {
  if (!values) {
    return null;
  }

  const enrichment = find(tag.enrichments, { id: rule.id });
  const sourceSystem = enrichment && map(enrichment.sourceSystems, 'display').join(', ');

  return (
    <VBox marginTop="-5px">
      <StyledHBox marginBottom="8px">
        <Title>Values:</Title>
        <HBox>
          <Value weight="bold">Various values</Value>
          <MiddleValue>from</MiddleValue>
          <Value>{values.config.name}</Value>
        </HBox>
      </StyledHBox>

      <StyledHBox marginBottom="8px">
        <Title>Source System:</Title>
        <Value>{sourceSystem}</Value>
      </StyledHBox>

      <StyledHBox marginBottom="18px">
        <Title>Condition:</Title>
        <ConditionText hasCondition={!!values.condition}>{values.condition || 'No condition'}</ConditionText>
      </StyledHBox>

      <LineSeparator />

      <StyledHBox marginTop="10px" marginBottom="11px">
        <Icon />
        <BasicTitle>Automatically created by mapping enrichment</BasicTitle>
      </StyledHBox>
    </VBox>
  );
}

export default MappingForm;
