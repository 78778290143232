import isEmpty from 'lodash/isEmpty';
angular.module('bigpanda').service('CustomTagPopup', CustomTagPopup);

function CustomTagPopup(
  BPQLService,
  QuerySourceGenerator,
  BpqlUtils,
  CorrelationActions,
  deepCloneObject
) {
  this.convertToCustomTagVm = convertToCustomTagVm;
  this.convertFromCustomTagVm = convertFromCustomTagVm;

  function convertToCustomTagVm(tag) {
    const description = { description: '' };
    const defaultTag = {
      sourceSystems: [{ value: null }],
      destination: null,
      source: null,
      type: 'extraction',
      tempalte: '$1',
      pattern: '',
      pureQuery: {},
      active: true,
      metadata: description,
    };

    const customTag = deepCloneObject.cloneDeep(tag || defaultTag);
    const hasQuery = !isEmpty(customTag.pureQuery);
    const metadata = customTag.metadata || description;

    return {
      active: customTag.active,
      integration: customTag.sourceSystems[0].value,
      destination: customTag.destination,
      source: customTag.source,
      type: customTag.type,
      pattern: customTag.pattern,
      template: customTag.template,
      id: customTag.id,
      hasDescription: metadata && !!metadata.description,
      query: hasQuery ? BPQLService.reverse(customTag.pureQuery) : '',
      metadata: metadata,
      hasQuery: hasQuery,
    };
  }

  function convertFromCustomTagVm(customTagVm, options = {}) {
    const query = BpqlUtils.getBpqlQuery(customTagVm.query);
    const customTag = {
      query: QuerySourceGenerator.generateQuery(query, [{ value: customTagVm.integration }]),
      active: options.forceActive || customTagVm.active,
      destination: customTagVm.destination.toLowerCase(),
      source: isExtractionTag(customTagVm) ? customTagVm.source : null,
      type: customTagVm.type,
      pattern: isExtractionTag(customTagVm) ? customTagVm.pattern : '',
      template: isExtractionTag(customTagVm) ? '$1' : customTagVm.template,
      id: options.edit ? customTagVm.id : undefined,
      metadata: customTagVm.metadata,
    };

    if (!options.isPreview) {
      const keyParams = ['destination', 'pattern', 'query', 'source', 'template', 'type'];
      return CorrelationActions.onlyIfUnique(customTag, options.customTags, keyParams);
    }
    return customTag;
  }

  function isExtractionTag(customTagVm) {
    return customTagVm.type === 'extraction';
  }
}
