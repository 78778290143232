export default {
  users: {
    create: 'users@create',
    read: 'users@read',
    update: 'users@update',
    delete: 'users@delete',
  },
  sso: {
    read: 'sso@read',
    update: 'sso@update',
  },
  integrations: {
    create: 'integrations@create',
    update: 'integrations@update',
    delete: 'integrations@delete',
    read: 'integrations@read',
  },
  analytics: {
    create: 'analytics@create',
    update: 'analytics@update',
    delete: 'analytics@delete',
    read: 'analytics@read',
  },
  environments: {
    create: 'environments@create',
    update: 'environments@update',
    delete: 'environments@delete',
  },
  notifications: {
    read: 'notifications@read',
    create: 'notifications@create',
    update: 'notifications@update',
    delete: 'notifications@delete',
  },
  tags: {
    create: 'custom_tags@create',
    read: 'custom_tags@read',
    update: 'custom_tags@update',
    delete: 'custom_tags@delete',
  },
  correlations: {
    create: 'correlations@create',
    read: 'correlations@read',
    update: 'correlations@update',
    delete: 'correlations@delete',
  },
  quotas: {
    read: 'quotas@read',
  },
  apiKeys: {
    create: 'apikeys@create',
    read: 'apikeys@read',
    update: 'apikeys@update',
    delete: 'apikeys@delete',
  },
  dashboards: {
    create: 'dashboards@create',
    read: 'dashboards@read',
    update: 'dashboards@update',
    delete: 'dashboards@delete',
  },
  roles: {
    create: 'roles@create',
    read: 'roles@read',
    update: 'roles@update',
    delete: 'roles@delete',
  },
  changes: {
    create: 'changes@create',
    read: 'changes@read',
    update: 'changes@update',
    delete: 'changes@delete',
  },
  plans: {
    create: 'plans@create',
    read: 'plans@read',
    update: 'plans@update',
    delete: 'plans@delete',
  },
  topology: {
    create: 'topology@create',
    read: 'topology@read',
    update: 'topology@update',
    delete: 'topology@delete',
  },
  search: {
    create: 'search@create',
    read: 'search@read',
    update: 'search@update',
    delete: 'search@delete',
  },
  tags_definition: {
    create: 'incident-tags-definitions@create',
    read: 'incident-tags-definitions@read',
    update: 'incident-tags-definitions@update',
    delete: 'incident-tags-definitions@delete',
  },
  enrichments: {
    create: 'enrichments@create',
    read: 'enrichments@read',
    update: 'enrichments@update',
    delete: 'enrichments@delete',
  },
  alert_view: {
    create: 'alert_view@create',
    read: 'alert_view@read',
    update: 'alert_view@update',
    delete: 'alert_view@delete',
  },
  incident_view: {
    create: 'incident_view@create',
    read: 'incident_view@read',
    update: 'incident_view@update',
    delete: 'incident_view@delete',
  },
  audit_logs: {
    create: 'audit_logs@create',
    read: 'audit_logs@read',
    update: 'audit_logs@update',
    delete: 'audit_logs@delete',
  },
};
