angular.module('bigpanda').directive('shareButton', shareButton);

function shareButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '?^^checkedIncidentsState',
    },
    scope: {
      incidentId: '<',
      disableShare: '<',
      existingSharesState: '@',
      context: '@',
      isActionsV2: '<',
    },
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
    templateUrl: 'overview/incidents/list/widgets/share_button/share_button',
  };

  function controller($scope, $timeout, $state, SharesStore) {
    const vm = this;

    vm.numberOfShares = 0;

    vm.isShareRelevant = !vm.disableShare || vm.numberOfShares > 0;
    vm.shareTestId = `Share_list_button`;

    vm.shareProps = {};

    vm.existingShares = existingShares;

    vm.$onInit = onInit;
    vm.$onChanges = updateShares;

    function onInit() {
      if (vm.disableShare) {
        vm.share = existingShares;
        vm.shareTooltip = null;
        vm.existingShareTooltip = null;
      } else {
        vm.share = share;
        vm.shareTooltip = 'Share Incident';
        vm.existingShareTooltip = 'Existing Shares';
      }

      // If we're not in the screen of the incidents list, we mock the controller so the flow of the class will work
      // as expected
      if (!vm.checkedIncidentsState) {
        vm.checkedIncidentsState = {
          subscribe: angular.noop,
          isAnythingChecked: () => false,
        };
      }

      updateShares();
      SharesStore.subscribe($scope, {
        storeUpdated: updateShares,
      });

      updateDisabled();
      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: updateDisabled,
      });
    }

    function updateShares() {
      SharesStore.getShares(vm.incidentId).then((shares) => {
        vm.shareProps.shared = shares && shares.length > 0;
        calculateNumberOfShares(shares);
      });
    }

    function updateDisabled() {
      vm.shareProps['bp-disabled'] = vm.checkedIncidentsState.isAnythingChecked();
    }

    function share() {
      $timeout(() => {
        $state.go(
          'app.overview.incidents.incident-info.share',
          {
            incidentId: vm.incidentId,
            context: vm.context,
          },
          { notify: false }
        );
      }, 10);
    }

    function existingShares() {
      $timeout(() => {
        $state.go(
          vm.existingSharesState,
          {
            incidentId: vm.incidentId,
          },
          { notify: false }
        );
      }, 10);
    }

    function calculateNumberOfShares(shares) {
      let count = 0;
      if (shares) {
        shares.forEach((share) => {
          if (share.escalatees && share.escalatees.length > 0) {
            count += share.escalatees.length;
          } else {
            count++;
          }
        });
      }
      vm.numberOfShares = count;
      vm.isShareRelevant = !vm.disableShare || vm.numberOfShares > 0;
      vm.shareProps['read-only-share'] = vm.disableShare;
      vm.shareProps['quick-action-button-with-border'] = vm.numberOfShares || !vm.disableShare;
    }
  }
}
