angular.module('bigpanda').filter('normalizeTagType', normalizeTagType);

function normalizeTagType() {
  return (tagType) => {
    if (angular.isString(tagType)) {
      if (tagType.length > 0 && tagType[0] == '_') {
        return tagType.slice(1);
      }
    }
    return tagType;
  };
}
