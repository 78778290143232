import React, { memo } from 'react';
import {
  Modal,
  ButtonVariants,
  TextArea,
  Checkbox,
  ThumbsDownIcon,
  VBox,
  useObject,
} from '@bp/kung-fu';
import PropTypes from 'prop-types';

import { Wrapper } from './style.css';
import dic from '../../dictionary';

const FeedbackModal = ({ onCloseModal, onActionClick, setReason, reason }) => {
  const [checkboxes, { updateKey: updateCheckbox }] = useObject({
    [dic.ai_this_isnt_helpful_title]: false,
    [dic.ai_this_isnt_true_title]: false,
    [dic.ai_this_is_right_but_not_helpful_title]: false,
  });

  return (
    <Modal
      headerProps={{
        title: dic.ai_feedback_summary_title,
        icon: <ThumbsDownIcon color={(p) => p.theme.bp_blue} />,
      }}
      controlledState
      footerProps={{
        actionButtonText: dic.ai_submit_title,
        onActionClick,
        actionButtonVariant: ButtonVariants.PRIMARY,
        isAsync: true,
      }}
      setControlledState={() => onCloseModal()}
      ariaLabelledby={dic.ai_feedback_summary_title}
      ariaDescribedby={dic.ai_feedback_summary_title}
      content={
        <Wrapper gap="20px">
          <TextArea
            description={dic.ai_optional}
            label={dic.ai_recreate_let_us_know_title}
            ariaLabel={dic.ai_recreate_let_us_know_title}
            expand
            rows={4}
            onChange={setReason}
            value={reason}
            name="reason"
          />
          <VBox gap="10px">
            {Object.keys(checkboxes).map((checkbox) => (
              <Checkbox
                key={checkbox}
                text={checkbox}
                ariaLabel={checkbox}
                name={checkbox}
                checked={checkboxes[checkbox]}
                disabled={false}
                onChange={() => updateCheckbox(checkbox, !checkboxes[checkbox])}
              />
            ))}
          </VBox>
        </Wrapper>
      }
    />
  );
};

FeedbackModal.propTypes = {
  onCloseModal: PropTypes.func,
  onActionClick: PropTypes.func,
  setReason: PropTypes.func,
  reason: PropTypes.string,
};

export default memo(FeedbackModal);
