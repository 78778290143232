import get from 'lodash/get';
import { combineEpics } from 'redux-observable';
import { map, compose, cloneDeep } from 'lodash/fp';
import { batchActions } from 'redux-batched-actions';
import * as incidentLabelsEndpoint from 'common/endpoints/incident-labels';
import { getContacts } from 'common/endpoints/contacts';
import { loadIntegrations } from 'react/common/integrations/actions';
import { loadEnrichedUsers } from 'react/common/enriched_users/actions';
import { loadCorrelationConfig } from 'react/common/correlation_config/actions';
import { loadAlertTags } from 'react/common/alert_tags/actions';
import correlationPatternsSelectors from '../correlation_patterns/selectors';
import { selectors as FTSelectors } from '../../../../../app/react/user/feature_toggles';
import actionTypes from './actionTypes';
import actions from './actions';
import selectors from './selectors';
import {
  replaceObjectInListById,
  mapIncidentLabelConfigOrderedList,
  addUsernameToIncidentLabel,
  configOrderedListItemLogic,
  addObjectToList,
} from './utils';
import {
  DROPDOWN_FILTERS_ALL_ITEM_OPTION,
  DROPDOWN_FILTER_STATUS_ITEMS,
  DROPDOWN_FILTER_TYPE_ITEMS,
} from './constants';
import { shouldAllowOnboarding } from '../../../../../workspaces/apps/onboarding/src/helpers/shouldAllowOnboarding';
import dic from '../../../../../workspaces/apps/onboarding/src/dictionary';
import completeOnboardingStep from '../../../../common/endpoints/onboarding';

const loadMultipleIncidentLabels = (action$, state$) =>
  action$.ofType(actionTypes.LOAD_MULTIPLE_INCIDENT_LABELS).flatMap(async () => {
    try {
      const [users, incidentLabels] = await Promise.all([
        getContacts(),
        incidentLabelsEndpoint.getMultipleIncidentTagDefinitions(true, 'Priority'),
      ]);
      const incidentLabelsEnriched = map(
        compose(
          addUsernameToIncidentLabel(users),
          mapIncidentLabelConfigOrderedList(configOrderedListItemLogic)
        )
      )(incidentLabels);
      return actions.loadMultipleIncidentLabelsSuccess(incidentLabelsEnriched);
    } catch (e) {
      return actions.loadMultipleIncidentLabelsFailure();
    }
  });

const updateIncidentLabel = (action$, state) =>
  action$.ofType(actionTypes.UPDATE_INCIDENT_LABEL).flatMap(async ({ payload: incidentLabel }) => {
    try {
      const currentState = state.getState();
      const { id } = incidentLabel;
      const mergedUpdatedIncidentLabel = {
        ...selectors.getIncidentLabel(currentState, id),
        ...incidentLabel,
      };
      const [users, updatedIncidentLabel] = await Promise.all([
        getContacts(),
        incidentLabelsEndpoint.updateIncidentLabel(id, mergedUpdatedIncidentLabel),
      ]);
      const updatedIncidentLabelWithUsers = addUsernameToIncidentLabel(users)(updatedIncidentLabel);
      return compose(
        actions.updateIncidentLabelSuccess,
        replaceObjectInListById(updatedIncidentLabelWithUsers),
        cloneDeep,
        selectors.getIncidentLabels
      )(currentState);
    } catch (e) {
      const errors = get(e, 'errors', [])[0];
      return actions.updateIncidentLabelFailure(errors);
    }
  });

const createIncidentLabel = (action$, state) =>
  action$.ofType(actionTypes.CREATE_INCIDENT_LABEL).flatMap(async ({ payload: incidentLabel }) => {
    try {
      const currentState = state.getState();
      const [users, createdIncidentLabel] = await Promise.all([
        getContacts(),
        incidentLabelsEndpoint.createIncidentLabel(incidentLabel),
      ]);
      const createdIncidentLabelWithUsers = addUsernameToIncidentLabel(users)(createdIncidentLabel);
      if (window.location.href.includes('incident-enrichment')) {
        window.location.href = `/#/app/settings/incident-enrichment/${createdIncidentLabelWithUsers.id}`;
      }
      const allowOnboarding = shouldAllowOnboarding(FTSelectors.getFeatureToggles(currentState));
      if (allowOnboarding) {
        completeOnboardingStep(dic.onboardingSteps.incident_enrichment);
      }
      return compose(
        actions.createIncidentLabelSuccess,
        addObjectToList(createdIncidentLabelWithUsers),
        cloneDeep,
        selectors.getIncidentLabels
      )(currentState);
    } catch (e) {
      const errors = get(e, 'errors', [])[0];
      return actions.createIncidentLabelFailure(errors);
    }
  });

const loadUnifiedTagsForIncidentLabel = (action$, state$) =>
  action$
    .ofType(actionTypes.LOAD_UNIFIED_TAGS_FOR_INCIDENT_LABEL)
    .map(() =>
      batchActions([
        loadCorrelationConfig(),
        loadIntegrations(),
        loadEnrichedUsers(),
        loadAlertTags(),
      ])
    );

const deleteTagDefinition = (action$, state) =>
  action$.ofType(actionTypes.DELETE_TAG_DEFINITION).flatMap(async ({ payload: { id } }) => {
    try {
      const currentState = state.getState();
      const deleteTagDefinition = await incidentLabelsEndpoint.deleteTagDefinition(id);
      const users = await getContacts();
      const updatedIncidentLabelWithUsers = addUsernameToIncidentLabel(users)(deleteTagDefinition);
      return compose(
        actions.deleteTagDefinitionSuccess,
        replaceObjectInListById(updatedIncidentLabelWithUsers),
        cloneDeep,
        selectors.getIncidentLabels
      )(currentState);
    } catch (e) {
      const errors = get(e, 'errors', [])[0];
      return actions.deleteTagDefinitionFailure(errors);
    }
  });

const createFiltersDropDownItems = (action$) =>
  action$.ofType(actionTypes.LOAD_MULTIPLE_INCIDENT_LABELS_SUCCESS).map(() =>
    actions.createFiltersDropDownItemsSuccess({
      tagType: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.tagType, ...DROPDOWN_FILTER_TYPE_ITEMS],
      status: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.status, ...DROPDOWN_FILTER_STATUS_ITEMS],
    })
  );

export default combineEpics(
  loadMultipleIncidentLabels,
  updateIncidentLabel,
  createIncidentLabel,
  loadUnifiedTagsForIncidentLabel,
  deleteTagDefinition,
  createFiltersDropDownItems
);
