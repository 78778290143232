import { Div } from '@bp/kung-fu';
import styled from 'styled-components';

const ROW_MULTIPLIER = 5.55;
const SINGLE_ROW_MULTIPLIER = 7.55;

export const TableWrapper = styled(Div)<{ rowsLength: number }>`
  height: ${(p) => (p.rowsLength === 1 ? p.rowsLength * SINGLE_ROW_MULTIPLIER : p.rowsLength * ROW_MULTIPLIER)}vh;
  width: 100vh;
  margin: 30px;
`;
