import { combineEpics } from 'redux-observable';
import * as meService from 'common/endpoints/me';
import { showFailureMessage, showSuccessMessage } from 'react/layout/settings/feedback';
import actionTypes from './actionTypes';
import welcomeTypes from '../welcome/actionTypes';
import * as actions from './actions';

const loadUser = (action$) =>
  action$
    .ofType(actionTypes.LOAD_USER, welcomeTypes.UPDATE_USER_NAME_PASSWORD_SUCCESS)
    .mergeMap(async () => {
      const user = await meService.getContact();
      return actions.loadUserSuccess(user);
    });

const updateUser = (action$) =>
  action$.ofType(actionTypes.UPDATE_USER).mergeMap(async ({ payload }) => {
    try {
      const user = await meService.updateContact(payload);
      showSuccessMessage('Name', 'changed');
      return actions.updateUserSuccess(user);
    } catch (error) {
      showFailureMessage('name', 'change');
      return actions.updateUserFailure(error.message);
    }
  });

export default combineEpics(loadUser, updateUser);
