import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import SettingsListItemActions from 'react/layout/settings/components/SettingsListItemActions';
import selectors from '../selectors';
import IncidentLabelModal from '../modals/IncidentLabelModal';
import { PROTECTED_TAG_IDS } from '../constants';
import actions from '../../incident_labels/actions';
import { AI_TAGS } from '../../../../../common/constants';

const IncidentLabelsActions = ({ incidentLabel, actionPermissions, deleteTagDefinition }) => {
  const { id, type } = incidentLabel;

  const isSystemGeneratedTag = AI_TAGS.includes(id);

  const messages = {
    edit: `Cannot Edit Incident Tag of Type '${type}'`,
    delete: `Cannot Delete Incident Tag of Type '${type}'`,
    duplicate: `Cannot Duplicate Incident Tag of Type '${type}'`,
  };

  const systemGeneratedTagsMessage = {
    edit: 'Cannot edit system-generated tag',
    delete: 'Cannot delete system-generated tag',
    duplicate: 'Cannot duplicate system-generated tag',
  };

  return (
    <SettingsListItemActions
      item={incidentLabel}
      modelName="Incident Tag"
      messages={isSystemGeneratedTag ? systemGeneratedTagsMessage : messages}
      editProps={{
        disabled: !actionPermissions.hasEditPermission || isSystemGeneratedTag,
        showIfDisabled: true,
        modalProps: { currentIncidentLabelId: id, mode: 'edit' },
        modal: IncidentLabelModal,
      }}
      duplicateProps={{
        disabled: PROTECTED_TAG_IDS.includes(id),
        showIfDisabled: true,
        modalProps: { currentIncidentLabelId: id, mode: 'duplicate' },
        modal: IncidentLabelModal,
      }}
      deleteProps={{
        disabled: !actionPermissions.hasDeletePermission || PROTECTED_TAG_IDS.includes(id),
        showIfDisabled: true,
        modalProps: {
          contentTitleType: 'string',
          content: 'Are you sure you want to delete this Incident Tag?',
          note: 'Note: This tag will be removed from all incidents.',
        },
        action: () => deleteTagDefinition({ id }),
      }}
    />
  );
};

IncidentLabelsActions.propTypes = {
  incidentLabel: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string }).isRequired,
  actionPermissions: PropTypes.shape({
    hasEditPermission: PropTypes.bool,
    hasDeletePermission: PropTypes.bool,
  }).isRequired,
  deleteTagDefinition: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    actionPermissions: selectors.getActionsPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteTagDefinition: (payload) => dispatch(actions.deleteTagDefinition(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(IncidentLabelsActions));
