import { hot } from 'react-hot-loader';
import { ThemeProvider } from 'styled-components';
import DocumentTitle from 'react-document-title';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'class-autobind';
import cn from 'classnames';
import { Spinner, AddIcon, darkTheme } from '@bp/kung-fu';
import { bp_blue } from '@bp/pastel/colors';
import { BamFilter, BamSortingMenu, BamCheckbox } from '@bp/bam';
import permissions from 'common/routing/permissions';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import styles from './settings_list_screen.scss';
import SettingsListEmptyState from './components/SettingsListEmptyState';
import SettingsList, { SortShape } from './components/SettingsList';

class SettingsListScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    autobind(this, SettingsListScreen.prototype);
    this.state = {
      isMobile: this.isMobileScreen(),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ isMobile: this.isMobileScreen() });
  }

  isMobileScreen = () => window.screen.width < 1010 || window.innerWidth < 1010;

  handleFilterInputChange(filterText) {
    this.props.updateFilterText(filterText);
  }

  handleFilterInputRemoveClick() {
    this.props.updateFilterText('');
  }

  handleSort(sortMode) {
    this.props.updateSort(sortMode);
  }

  renderNewItemModal = (canCreate, triggerProps, displayButtonText) => {
    if (canCreate && triggerProps.text) {
      const {
        modal: { component: CreateItemModal, ...modalProps },
      } = this.props.createItemProps;
      return (
        <CreateItemModal triggerProps={{ ...triggerProps, displayButtonText }} {...modalProps} />
      );
    }
  };

  renderCheckboxFilter = (checkBox) =>
    checkBox.label && (
      <div className={styles['checkbox-filter-container']}>
        <BamCheckbox label={checkBox.label} onClick={checkBox.filter} />
      </div>
    );

  renderLoader = () => (
    <div className={styles['pagination-loader']}>
      <Spinner color={bp_blue} />
    </div>
  );

  render() {
    const {
      createItemProps,
      enableConfigurationLabels,
      noItemsTitle,
      listItem,
      listItemProp,
      items,
      filterText,
      sortModes,
      sortMode,
      userPermissions,
      permissionName,
      showCreateButton,
      filterPlaceholderText,
      sortHeaderLink,
      sortTooltip,
      headerName,
      loading,
      hideSorting,
      paginationLoader,
      loadNext,
      renderNoResultsByFilters,
      renderFilterRowElement,
      renderSuggestionsRowElement,
      checkBox,
      listCustomActions,
      isAnyFilterApplied,
    } = this.props;

    const canCreate =
      showCreateButton &&
      permissions[permissionName] &&
      userPermissions.indexOf(permissions[permissionName].create) !== -1;

    const { buttonText, itemName, tileText, tooltipProps = {} } = createItemProps;

    const newItemButton = (shouldDisplayText = true) => (
      <div className={styles['modal-button']}>
        {this.renderNewItemModal(
          canCreate,
          {
            size: 'medium',
            icon: <AddIcon />,
            variant: 'primary',
            text: buttonText,
            disabled: loading || createItemProps.disabled,
            tooltipProps,
          },
          shouldDisplayText
        )}
      </div>
    );

    const itemsToRender = (items || []).filter((item) => !item.hidden);
    const hasResults = itemsToRender.length > 0;

    const noItemSubtitle = canCreate ? `Click '${buttonText}' to add your first ${itemName}` : null;
    const renderList = () => {
      if (hasResults) {
        return (
          <SettingsList
            listItemComponent={listItem}
            itemName={listItemProp}
            items={itemsToRender}
            sortMode={sortMode}
            enableConfigurationLabels={enableConfigurationLabels}
            lastTile={
              paginationLoader
                ? this.renderLoader()
                : this.renderNewItemModal(canCreate, { type: 'tile', text: tileText })
            }
            loadNext={loadNext}
          />
        );
      }

      const noResultByFiltersElement = renderNoResultsByFilters(filterText, isAnyFilterApplied);
      const noResultsDefaultElement = (
        <SettingsListEmptyState title={noItemsTitle} subtitle={noItemSubtitle}>
          {newItemButton()}
        </SettingsListEmptyState>
      );

      return noResultByFiltersElement || noResultsDefaultElement;
    };
    return (
      <ThemeProvider theme={darkTheme}>
        <DocumentTitle title={headerName}>
          <div className={cn('bam', styles['settings-screen-container'])}>
            <div className={styles['settings-screen']}>
              <div className={styles['settings-screen-header']}>
                <div className={styles['list-header']}>
                  <div className={styles['list-header-title']}>{headerName}</div>
                  {listCustomActions && (
                    <div className={styles['list-header-custom-actions']}>{listCustomActions}</div>
                  )}
                </div>
                {newItemButton(!this.state.isMobile)}
              </div>
              <div className={styles['header-separator']} />
              <div className={styles[hideSorting ? 'bp-no-filter' : 'bp-filter']}>
                <BamFilter
                  filterInputText={filterText}
                  onFilterInputChange={this.handleFilterInputChange}
                  onFilterInputRemoveClick={this.handleFilterInputRemoveClick}
                  placeholderText={filterPlaceholderText}
                />
                {sortModes && !hideSorting && (
                  <BamSortingMenu
                    tooltip={sortTooltip}
                    options={Object.values(sortModes)}
                    value={sortMode}
                    sortChanged={this.handleSort}
                    headerLink={sortHeaderLink}
                    aria-label={`${headerName} sorting`}
                  >
                    <i className="bp-icon-sort" />
                  </BamSortingMenu>
                )}
              </div>
              {renderFilterRowElement()}
              {this.renderCheckboxFilter(checkBox)}
              {renderSuggestionsRowElement()}
              <div className={styles['list-container']}>
                {loading && (
                  <div className={styles.loader}>
                    <Spinner color={bp_blue} expand />
                  </div>
                )}
                {!loading && renderList()}
              </div>
            </div>
          </div>
        </DocumentTitle>
      </ThemeProvider>
    );
  }
}

SettingsListScreen.propTypes = {
  enableConfigurationLabels: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any),
  updateFilterText: PropTypes.func.isRequired,
  noItemsTitle: PropTypes.string,
  createItemProps: PropTypes.shape({
    itemName: PropTypes.string,
    modal: PropTypes.shape({
      component: PropTypes.func,
      title: PropTypes.string,
      titleIcon: PropTypes.string,
      onCreate: PropTypes.func,
    }).isRequired,
    tileText: PropTypes.string,
    buttonText: PropTypes.string,
    disabled: PropTypes.bool,
    tooltipProps: PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
  listCustomActions: PropTypes.node,
  listItem: PropTypes.func.isRequired,
  listItemProp: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  updateSort: PropTypes.func,
  filterText: PropTypes.string,
  sortMode: SortShape,
  sortModes: PropTypes.arrayOf(SortShape),
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  permissionName: PropTypes.string.isRequired,
  showCreateButton: PropTypes.bool,
  headerName: PropTypes.string.isRequired,
  filterPlaceholderText: PropTypes.string.isRequired,
  sortHeaderLink: PropTypes.string,
  sortTooltip: PropTypes.element,
  hideSorting: PropTypes.bool,
  checkBox: PropTypes.shape({
    filter: PropTypes.func,
    label: PropTypes.string,
    tooltipContent: PropTypes.string,
  }),
  renderFilterRowElement: PropTypes.func,
  renderSuggestionsRowElement: PropTypes.func,
  renderNoResultsByFilters: PropTypes.func,
  loadNext: PropTypes.func,
  paginationLoader: PropTypes.bool,
  isAnyFilterApplied: PropTypes.bool,
};

SettingsListScreen.defaultProps = {
  enableConfigurationLabels: false,
  items: null,
  filterText: '',
  loading: false,
  showCreateButton: true,
  sortModes: undefined,
  sortMode: undefined,
  sortTooltip: undefined,
  updateSort: undefined,
  sortHeaderLink: undefined,
  noItemsTitle: '',
  hideSorting: false,
  checkBox: {
    filter: undefined,
    label: undefined,
    tooltipContent: undefined,
  },
  paginationLoader: false,
  loadNext: undefined,
  renderFilterRowElement: () => {},
  renderSuggestionsRowElement: () => {},
  renderNoResultsByFilters: (filterText, isAnyFilterApplied) =>
    filterText || isAnyFilterApplied ? <SettingsListEmptyState /> : null,
  createItemProps: {},
  isAnyFilterApplied: false,
};

function mapStateToProps(state) {
  return {
    userPermissions: permissionsSelectors.getPermissionsList(state),
  };
}

export default connect(mapStateToProps, null)(hot(module)(SettingsListScreen));
