import actionTypes from './actionTypes';
import { baseSettingInitialState, generateBaseSettingsReducer } from '../redux.utils';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';

export const initialState = {
  ...baseSettingInitialState,
  filtersDropDownState: { ...DROPDOWN_FILTERS_ALL_ITEM_OPTION },
  roles: undefined,
};

const baseReducer = generateBaseSettingsReducer(actionTypes, initialState);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ENRICH_API_KEYS_SUCCESS:
      return {
        ...state,
        apiKeys: action.payload,
        isLoading: false,
      };
    case actionTypes.LOAD_API_KEYS:
      return {
        ...state,
        isLoading: !state.apiKeys,
      };
    case actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS:
      return {
        ...state,
        filtersDropDownItems: {
          ...action.payload,
        },
      };

    case actionTypes.UPDATE_FILTERS_DROPDOWNS:
      return {
        ...state,
        filtersDropDownState: {
          ...state.filtersDropDownState,
          ...action.payload,
        },
      };
    default:
      return baseReducer(state, action);
  }
}
