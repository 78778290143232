import get from 'lodash/get';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import merge from 'lodash/merge';
import find from 'lodash/find';
import each from 'lodash/each';
import completeOnboardingStep from '../../../../app/common/endpoints/onboarding';
import dic from '../../../../workspaces/apps/onboarding/src/dictionary';

angular.module('bigpanda').service('EnvironmentsService', EnvironmentsService);
const fullAccessPermissionActions = [
  'Read',
  'Action@Read',
  'Action@Create',
  'Action@Update',
  'Action@Delete',
];
const readonlyPermissionActions = ['Read', 'Action@Read'];

function EnvironmentsService(
  $q,
  EnvironmentsBackendService,
  pubSubService,
  SiftService,
  StreamConnector,
  notificationService,
  FOLDERS,
  RolesBackendService,
  UserFeatureTogglesService,
  PERMISSIONS_NAMES,
  deepCloneObject,
  $ngRedux,
  $log
) {
  let streamInit = false;
  let envsCache = [];
  let Permissions;

  this.getEnvById = getEnvById;
  this.getEnvWithRoles = getEnvWithRoles;
  this.getBpqlEnv = getBpqlEnv;
  this.saveBpqlEnv = saveBpqlEnv;
  this.save = save;
  this.delete = remove;
  this.get = getEnvironments;
  this.convertSiftToBpql = convertSiftToBpql;
  this.convertBpqlToSift = convertBpqlToSift;
  this.setPermissions = (_Permissions) => (Permissions = _Permissions);
  this.getAllEnvId = getAllEnvId;
  this.getEnvId = getEnvId;
  initStream();

  return this;

  function mergeFolders(env) {
    const folders = [];

    each(FOLDERS, (folderConfig) => {
      const tmpFolder = find(env.folders, (folder) => folder.id === folderConfig.id);
      folders.push(merge(tmpFolder, folderConfig));
    });

    env.folders = folders;
  }

  function removeDuplicateFromReadOnly(fullAccessRoles = [], readOnlyRoles = []) {
    return readOnlyRoles.filter(
      (readOnlyRole) =>
        !fullAccessRoles.find((fullAccessRole) => fullAccessRole.key === readOnlyRole.key)
    );
  }

  function normalizeEnvironment(env) {
    setSiftFilter(env);
    mergeFolders(env);
    env.readOnlyRoles = removeDuplicateFromReadOnly(env.roles, env.read_only_roles);
    return env;
  }

  function convertSiftToBpql(filter) {
    if (!filter) return Promise.resolve('');
    return EnvironmentsBackendService.convertSiftToBpql(filter).then((res) => res);
  }

  function convertBpqlToSift(filter) {
    if (!filter) return Promise.resolve('');
    return EnvironmentsBackendService.convertBpqlToSift(filter).then((res) => res);
  }

  function createEnvironment(envData, isBpql) {
    const roleHasCreatePermissions = Permissions.get().some(
      (permission) => permission === PERMISSIONS_NAMES.roles.create
    );
    const featureToggle = UserFeatureTogglesService.getToggle('enable_global_auth');
    const featureAndPermission = featureToggle && roleHasCreatePermissions;

    envData.read_only_roles = removeDuplicateFromReadOnly(envData.roles, envData.read_only_roles);
    if (isBpql) {
      return EnvironmentsBackendService.saveNewAdvancedEnv(envData).then((res) => {
        return createResourceRoles({ ...envData, _id: res.id }, featureAndPermission);
      });
    }

    return EnvironmentsBackendService.createEnvironment(envData, featureAndPermission).then((res) =>
      createResourceRoles({ ...envData, _id: res.item._id }, featureAndPermission)
    );
  }

  function convertToRequestFormat(envData) {
    return {
      resource: {
        id: envData._id,
        resourceType: 'environments',
      },
      rolesActions: [
        {
          roles: (envData.roles || []).map((role) => ({
            ...role,
            _id: role.key,
          })),
          actions: fullAccessPermissionActions,
        },
        {
          roles: (envData.read_only_roles || []).map((role) => ({
            ...role,
            _id: role.key,
          })),
          actions: readonlyPermissionActions,
        },
      ],
    };
  }

  function createResourceRoles(envData, roleHasCreatePermissions) {
    if (!roleHasCreatePermissions) {
      return $q.when(envData);
    }
    return RolesBackendService.createFullResource(convertToRequestFormat(envData)).then(
      () => envData
    );
  }

  function editResourceRoles(envData, roleHasUpdatePermissions) {
    if (!roleHasUpdatePermissions) {
      return $q.when(envData);
    }
    return RolesBackendService.editFullResource(convertToRequestFormat(envData)).then(
      () => envData
    );
  }

  function updateEnvironment(envData, isBpql) {
    const roleHasUpdatePermissions = Permissions.get().some(
      (permission) => permission === PERMISSIONS_NAMES.roles.update
    );
    const featureToggle = UserFeatureTogglesService.getToggle('enable_global_auth');
    const featureAndPermission = featureToggle && roleHasUpdatePermissions;

    envData.read_only_roles = removeDuplicateFromReadOnly(envData.roles, envData.read_only_roles);
    if (envData.updateEnvRolesOnly && featureAndPermission) {
      return editResourceRoles({ ...envData }, featureAndPermission);
    }
    if (isBpql) {
      return EnvironmentsBackendService.updateAdvancedEnv(envData, featureAndPermission)
        .then((res) => res)
        .then((env) => editResourceRoles({ ...envData, _id: env.id }, featureAndPermission));
    }
    return EnvironmentsBackendService.updateEnvironment(envData, featureAndPermission)
      .then((res) => res.item)
      .then((env) => editResourceRoles({ ...envData, _id: env._id }, featureAndPermission));
  }

  function findEnvIndex(environments, envId) {
    return findIndex(environments, (env) => env._id === envId || env.old_id === envId);
  }

  async function getEnvWithRoles(envId) {
    const userHasReadPermissions = Permissions.get().some(
      (permission) => permission === PERMISSIONS_NAMES.roles.read
    );
    const featureToggle = UserFeatureTogglesService.getToggle('enable_global_auth');
    const featureAndPermission = featureToggle && userHasReadPermissions;

    let roles = [];
    if (featureAndPermission) {
      roles = await RolesBackendService.getEnvironmentRoles(envId);
    }
    return EnvironmentsBackendService.getEnvironment(envId, featureAndPermission)
      .then((env) => (featureAndPermission ? normalizeEnvRoles(roles, env) : env))
      .then(normalizeEnvironment);
  }

  function getBpqlEnv(envId) {
    return EnvironmentsBackendService.getAdvancedEnv(envId);
  }

  function saveBpqlEnv(env, roles, readOnlyRoles, updateEnvRolesOnly) {
    if (!env) return;

    const savedEnv = { ...env };
    savedEnv.roles = roles;
    savedEnv.read_only_roles = readOnlyRoles;

    if (env.id) {
      savedEnv.updateEnvRolesOnly = updateEnvRolesOnly;
      return updateEnvironment(savedEnv, true);
    }
    if (UserFeatureTogglesService.getToggle('is_onboarding_enable')) {
      completeOnboardingStep(dic.onboardingSteps.environment);
    }
    return createEnvironment(savedEnv, true);
  }

  function setSiftFilter(env) {
    env.filter = SiftService.replaceRegexInValues(
      angular.isObject(env.filter) ? env.filter : JSON.parse(env.filter),
      true
    );
  }

  function normalizeEnvRoles(roles, env) {
    const readOnlyRolesSet = new Set(readOnlyRoles);
    const readOnlyRoles = roles.filter(
      (role) =>
        (role.actions || []).filter((action) => readOnlyRolesSet.has(action)).length ===
        readOnlyRolesSet.size
    );
    const fullActionSet = new Set(fullAccessPermissionActions);
    const fullAccessRoles = roles.filter(
      (role) =>
        (role.actions || []).filter((action) => fullActionSet.has(action)).length ===
        fullActionSet.size
    );
    const roleMapper = (role) => ({
      key: role._id,
      name: role.name,
      users: role.users,
    });
    return Object.assign({}, env, {
      roles: fullAccessRoles.map(roleMapper),
      read_only_roles: readOnlyRoles.map(roleMapper),
    });
  }

  function initStream() {
    if (!streamInit) {
      streamInit = true;
      StreamConnector.on('environment', (stream) => {
        switch (stream.action) {
          case 'created':
            updateCache(stream.env);
            pubSubService.broadcast('Environment.Created', stream.user_id);
            break;
          case 'updated':
            updateCache(stream.env);
            pubSubService.broadcast('Environment.Updated', stream.env, stream.user_id, stream.name);
            break;
          case 'removed':
            removeFromCache(stream.env._id);
            pubSubService.broadcast(
              'Environment.Deleted',
              stream.env._id,
              stream.user_id,
              stream.name
            );
            break;
          default:
            break;
        }
      });

      UserFeatureTogglesService.register({
        persist: false,
        func: () => {
          const debounceInterval = UserFeatureTogglesService.getToggle('envy_refresh_debounce')
            ? 20000
            : 5000;
          const envyRefresh = debounce(
            () => {
              pubSubService.broadcast('Envy.refresh');
            },
            debounceInterval,
            { maxWait: 30000 }
          );

          StreamConnector.on('Envy', (stream) => {
            if (stream.action === 'refresh') {
              envyRefresh();
            }
          });
        },
      });
    }
  }

  function updateCache(env) {
    normalizeEnvironment(env);
    const envIndex = findEnvIndex(envsCache, env._id);
    if (envIndex > -1) {
      angular.extend(envsCache[envIndex], env);
    } else {
      envsCache.push(env);
    }
  }

  function removeFromCache(envId) {
    const envIndex = findEnvIndex(envsCache, envId);
    if (envIndex > -1) {
      envsCache.splice(envIndex, 1);
    }
  }

  function save(env, roles, readOnlyRoles, updateEnvRolesOnly) {
    const savedEnv = env;
    savedEnv.filter = JSON.stringify(env.filter);
    savedEnv.roles = roles;
    savedEnv.read_only_roles = readOnlyRoles;
    if (savedEnv._id) {
      savedEnv.updateEnvRolesOnly = updateEnvRolesOnly;

      return updateEnvironment(savedEnv);
    }
    if (UserFeatureTogglesService.getToggle('is_onboarding_enable')) {
      completeOnboardingStep(dic.onboardingSteps.environment);
    }
    return createEnvironment(savedEnv);
  }

  function remove(envId) {
    return EnvironmentsBackendService.deleteEnvironment(envId);
  }

  function getEnvById(envId, forceReload) {
    return getEnvironments(forceReload, false).then((environments) =>
      deepCloneObject.cloneDeep(environments[findEnvIndex(environments, envId)])
    );
  }

  function getEnvironments(forceReload, cloneObjects = true) {
    if (!forceReload && envsCache.length) {
      return $q.when(cloneObjects ? deepCloneObject.cloneDeep(envsCache) : envsCache);
    }

    return EnvironmentsBackendService.getEnvironments()
      .then((environments) => {
        environments.forEach((rawEnvironment) => normalizeEnvironment(rawEnvironment));
        envsCache = environments;
        return deepCloneObject.cloneDeep(envsCache);
      })
      .catch((error) => {
        if (error.status === 504) {
          notificationService.error('Unexpected error, we will try to reconnect soon...', {
            disableScreen: true,
            until: ['Envy.refresh'],
          });
        }

        return $q.reject();
      });
  }

  async function getAllEnvId() {
    try {
      const envId = get(
        $ngRedux.getState(),
        'modules.settings.rolesManagement.rolesList.allEnvironmentId'
      );
      if (envId) {
        return envId;
      }
      const environments = await getEnvironments();
      return get(find(environments, { name: 'All' }), '_id');
    } catch (e) {
      $log.error('Error while getting all environment id', e);
      return null;
    }
  }

  async function getEnvId(idOrOldId) {
    try {
      const environments = await getEnvironments();
      const found = find(
        environments,
        (environment) => environment._id === idOrOldId || environment.old_id === idOrOldId
      );
      if (!found) {
        $log.error('Error while getting environment id. Environment is not found.', idOrOldId);
        return idOrOldId;
      }

      if (!found._id) {
        $log.error('Error while getting environment id. Environment id is not found.', idOrOldId);
        return idOrOldId;
      }

      return found._id;
    } catch (e) {
      $log.error('Error while getting all environment id', e);
      return null;
    }
  }
}
