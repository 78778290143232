import get from 'lodash/get';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { Button, TroubleshootingIcon, darkTheme } from '@bp/kung-fu';
import { integrationPropType } from '../propTypes';
import styles from './TroubleshootingButton.scss';

const TroubleshootingButton = (props) => {
  const { integration } = props;
  const baseURL = `/v2/integrations/troubleshooting/${integration.system_id}`;

  return (
    <ThemeProvider theme={darkTheme}>
      <a href={baseURL} className={styles.link}>
        <Button
          variant="action-container"
          size="large"
          icon={
            <TroubleshootingIcon
              color={(p) => integration.last_day_error_count > 0 && p.theme.bp_red}
            />
          }
        >
          Troubleshooting
        </Button>
      </a>
    </ThemeProvider>
  );
};

TroubleshootingButton.propTypes = {
  integration: integrationPropType.isRequired,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  return {
    organization,
    user,
  };
};

export default connect(mapStateToProps, null)(hot(module)(TroubleshootingButton));
