import React from 'react';
import PropTypes from 'prop-types';
import { BamTile, BamToggle } from '@bp/bam';

const AutoShareTile = ({ inactive, canEdit, environmentName, toggleRule }) => {
  const ruleBamStatus = inactive ? 'off' : 'ok';
  const toggle = (
    <BamToggle
      label="Active:"
      labelPosition="left"
      checked={!inactive}
      color="green"
      big
      onClick={toggleRule}
    />
  );
  const wrappedToggle = canEdit ? toggle : null;

  return (
    <BamTile
      bamStatus={ruleBamStatus}
      headerRow={{ title: environmentName, toggle: wrappedToggle }}
    />
  );
};

AutoShareTile.propTypes = {
  inactive: PropTypes.bool,
  canEdit: PropTypes.bool.isRequired,
  environmentName: PropTypes.string.isRequired,
  toggleRule: PropTypes.func.isRequired,
};
AutoShareTile.defaultProps = {
  inactive: false,
};

export default AutoShareTile;
