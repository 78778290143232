import sortBy from 'lodash/sortBy';
import { hot } from 'react-hot-loader';
import React from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'class-autobind';
import styled from 'styled-components';
import classnames from 'classnames';
import { BamMenu } from '@bp/bam';
import permissions from 'common/routing/permissions';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { SettingsIcon, MapsIcon } from '@bp/kung-fu';
import { bp_gray_04, bp_gray_08 } from '@bp/pastel/colors';
import { handleTrackEvent } from '../menuIntercom';
import styles from './settings_menu.scss';

const SsoConfigurationIcon = styled(MapsIcon)`
margin-right: 10px;
`;

const createMenuOptionsForFeatureToggle = memoizeOne(
  (userPermissions, featureToggles, selectedSettingsPageId, isSsoSourceOrganization) => {
    if (!userPermissions && !featureToggles) {
      return [];
    }

    const ALERT_ENRICHMENT = {
      id: 'alertEnrichment',
      icon: { className: 'bp-icon-tag' },
      text: 'Alert Enrichment',
      permission: permissions.enrichments.read,
      hidden: !featureToggles.frontier_alert_enrichment,
      groupedSettingsModeOrder: 2,
      onClick: () => {
        window.location.href = featureToggles.frontier_alert_enrichment_v2
          ? '/v2/settings/alert-enrichment'
          : '/#/app/settings/alert-enrichment';
      },
    };

    const MAPPING_ENRICHMENT = {
      id: 'mappingEnrichment',
      icon: { className: 'bp-icon-file-document-text' },
      text: 'Enrichment Maps',
      groupedSettingsModeOrder: 2,
      permission: permissions.enrichments.read,
      hidden: !featureToggles.frontier_mapping_enrichment,
      onClick: () => {
        window.location.href = '/v2/settings/mapping-enrichment';
      },
    };

    const ALERT_FILTERING = {
      id: 'alertFiltering',
      icon: { className: 'bp-icon-alertfiltering' },
      text: 'Alert Filtering',
      permission: permissions.plans.read,
      hidden: featureToggles.frontier_disable_alert_filtering,
      groupedSettingsModeOrder: 2,
      onClick: () => {
        window.location.href = featureToggles.frontier_alert_filtering_v2
          ? '/v2/settings/alert-filtering'
          : '/#/app/settings/alert-filtering';
      },
    };

    const CORRELATION_PATTERNS = {
      id: 'correlationRules',
      icon: { className: 'bp-icon-flash' },
      text: 'Alert Correlation',
      permission: permissions.correlations.read,
      groupedSettingsModeOrder: 3,
      onClick: () => {
        window.location.href = '/v2/settings/alert-correlation';
      },
    };

    const INCIDENT_TAGS = {
      id: 'incidentLabels',
      icon: { className: 'bp-icon-label' },
      text: 'Incident Enrichment',
      permission: permissions.tags_definition.read,
      groupedSettingsModeOrder: 4,
      onClick: () => {
        window.location.href = '/v2/settings/incident-enrichment';
      },
    };

    const AUTO_SHARE = {
      id: 'rules',
      icon: { className: 'bp-icon-share' },
      text: 'AutoShare',
      permission: permissions.notifications.read,
      groupedSettingsModeOrder: 5,
      onClick: () => {
        window.location.href = '/v2/settings/auto-share';
      },
    };

    const DIVIDER_IMG = {
      img: 'assets/img/divider-dark.svg',
      hidden: !permissions.plans.read,
      groupedSettingsModeOrder: 6,
      className: styles['settings-divider-img'],
    };

    const MAINTENANCE_PLANS = {
      id: 'maintenancePlans',
      icon: { className: 'bp-icon-maintenance' },
      text: 'Planned Maintenance',
      permission: permissions.plans.read,
      groupedSettingsModeOrder: 7,
      onClick: () => {
        window.location.href = '/v2/settings/planned-maintenance';
      },
    };

    const USER_MANAGEMENT = {
      id: 'users',
      icon: { className: 'bp-icon-users' },
      text: 'User Management',
      permission: permissions.users.read,
      groupedSettingsModeOrder: 1,
      onClick: () => {
        window.location.href = '/#/app/settings/user_management';
      },
    };

    const ROLES_MANAGEMENT = {
      id: 'roles',
      icon: { className: 'bp-icon-lock-1' },
      text: 'Roles Management',
      permission: permissions.roles.read,
      groupedSettingsModeOrder: 2,
      onClick: () => {
        window.location.href = '/v2/settings/roles';
      },
    };

    const SSO = {
      id: 'sso',
      icon: { className: 'bp-icon-login' },
      text: 'Single Sign-on',
      permission: permissions.sso.read,
      groupedSettingsModeOrder: 3,
      hidden: !isSsoSourceOrganization,
      onClick: () => {
        window.location.href = '/#/app/settings/sso/';
      },
    };

    const SSO_CONFIGURATION = {
      id: 'ssoConfiguration',
      icon: <SsoConfigurationIcon fontSize="21px" color={bp_gray_08} />,
      text: 'SSO Configuration',
      permission: permissions.sso.read,
      groupedSettingsModeOrder: 4,
      hidden: !featureToggles.sso_jit_mapping_v2,
      onClick: () => {
        window.location.href = '/v2/settings/jit-sso';
      },
    };

    const API_KEYS = {
      id: 'apiKeys',
      icon: { className: 'bp-icon-key' },
      text: 'API Keys',
      permission: permissions.apiKeys.read,
      groupedSettingsModeOrder: 5,
      onClick: () => {
        window.location.href = featureToggles.frontier_api_keys_v2
          ? '/v2/settings/api_keys'
          : (window.location.href = '/#/app/settings/api_keys');
      },
    };
    const SHARING_QUOTAS = {
      id: 'quota',
      icon: { className: 'bp-icon-gauge' },
      text: 'Sharing Quotas',
      permission: permissions.quotas.read,
      groupedSettingsModeOrder: 2,
      onClick: () => {
        window.location.href = '/v2/settings/quota';
      },
    };
    const INTEGRATIONS = {
      id: 'integrations',
      icon: { className: 'bp-icon-integrations' },
      text: 'Integrations',
      permission: permissions.integrations.read,
      hidden: !featureToggles.frontier_integrations_lobby_react,
      groupedSettingsModeOrder: 12,
      onClick: () => {
        window.location.href = '/#/app/settings/integrations';
      },
    };

    const ALERT_VIEW = {
      id: 'alertView',
      icon: { className: 'bp-icon-text' },
      text: 'Alert View',
      groupedSettingsModeOrder: 1,
      permission: permissions.alert_view.read,
      onClick: () => {
        window.location.href = '/v2/settings/alert-view';
      },
    };

    const INCIDENT_VIEW = {
      id: 'incidentView',
      icon: { className: 'bp-icon-hamburger' },
      text: 'Incident Feed View',
      groupedSettingsModeOrder: 1,
      hidden: !featureToggles.incident_list_table_v2,
      permission: permissions.incident_view.read,
      onClick: () => {
        window.location.href = '/v2/settings/incident-view';
      },
    };

    const AUDIT_LOGS = {
      id: 'auditLogsView',
      icon: { className: 'bp-icon-multi-value' },
      text: 'Audit Log',
      groupedSettingsModeOrder: 1,
      permission: permissions.audit_logs.read,
      onClick: () => {
        window.location.href = '/#/app/settings/audit-logs';
      },
    };

    const ACCESS_MANAGEMENT = removeDisabledAndOrder(
      [INTEGRATIONS, API_KEYS, SSO, SSO_CONFIGURATION, ROLES_MANAGEMENT, USER_MANAGEMENT],
      'groupedSettingsModeOrder',
      userPermissions
    );

    const DATA_PROCESSING = removeDisabledAndOrder(
      [
        ALERT_ENRICHMENT,
        MAPPING_ENRICHMENT,
        ALERT_FILTERING,
        CORRELATION_PATTERNS,
        INCIDENT_TAGS,
        AUTO_SHARE,
        DIVIDER_IMG,
        MAINTENANCE_PLANS,
      ],
      'groupedSettingsModeOrder',
      userPermissions
    );

    const SYSTEM_PREFERENCES = removeDisabledAndOrder(
      [ALERT_VIEW, INCIDENT_VIEW],
      'groupedSettingsModeOrder',
      userPermissions
    );

    const ADMINISTRATION = removeDisabledAndOrder(
      [AUDIT_LOGS, SHARING_QUOTAS],
      'groupedSettingsModeOrder',
      userPermissions
    );

    return [
      {
        header: 'Data Processing',
        hidden: DATA_PROCESSING.length <= 1,
      },
      ...DATA_PROCESSING.map((obj) =>
        obj.img && !obj.hidden
          ? {
              ...obj,
              hidden:
                !isMaintenanceIncluded(DATA_PROCESSING) ||
                (isMaintenanceIncluded(DATA_PROCESSING) && DATA_PROCESSING.length < 3),
            }
          : obj
      ),
      {
        divider: true,
        className: styles['settings-menu-section-divider'],
        hidden: DATA_PROCESSING.length <= 1 || !ACCESS_MANAGEMENT.length,
      },
      {
        header: 'Access Management',
        hidden: !ACCESS_MANAGEMENT.length,
      },
      ...ACCESS_MANAGEMENT,
      {
        divider: true,
        className: styles['settings-menu-section-divider'],
        hidden: ACCESS_MANAGEMENT.length <= 1 || !SYSTEM_PREFERENCES.length,
      },
      {
        header: 'System Preferences',
        hidden: !SYSTEM_PREFERENCES.length,
      },
      ...SYSTEM_PREFERENCES,
      {
        divider: true,
        className: styles['settings-menu-section-divider'],
        hidden: !SYSTEM_PREFERENCES.length || !ADMINISTRATION.length,
      },
      {
        header: 'Administration',
        hidden: !ADMINISTRATION.length,
      },
      ...ADMINISTRATION,
    ]
      .filter((option) => !option.hidden)
      .map((option) => ({
        ...option,
        selected: option.id === selectedSettingsPageId,
        dataIntercomId: `topbar-${option.id}`,
      }));
  }
);

const removeDisabledAndOrder = (list, orderType = 'groupedSettingsModeOrder', userPermissions) => {
  return sortBy(list, orderType).filter(
    (action) =>
      !action.hidden && action.permission && userPermissions.indexOf(action.permission) > -1
  );
};

const isMaintenanceIncluded = (list) => list.some((obj) => obj.text === 'Planned Maintenance');

class SettingsMenu extends React.Component {
  constructor(props) {
    super(props);
    autobind(this, SettingsMenu.prototype);
  }

  render() {
    const {
      userPermissions,
      featureToggles,
      className,
      selectedSettingsPageId,
      isSsoSourceOrganization,
    } = this.props;
    const menuOptions = createMenuOptionsForFeatureToggle(
      userPermissions,
      featureToggles,
      selectedSettingsPageId,
      isSsoSourceOrganization
    );

    return (
      <div className={classnames(['bam', className])}>
        {menuOptions.length > 0 && (
          <BamMenu
            onClick={handleTrackEvent}
            options={menuOptions}
            isDark
            aria-label="Settings Menu"
            panelWidth="250px"
          >
            <SettingsIcon fontSize="21px" color={bp_gray_04} />
          </BamMenu>
        )}
      </div>
    );
  }
}

SettingsMenu.propTypes = {
  className: PropTypes.string.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  featureToggles: PropTypes.object.isRequired /* eslint-disable-line */,
  selectedSettingsPageId: PropTypes.string,
  isSsoSourceOrganization: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    featureToggles: featureTogglesSelectors.getFeatureToggles(state) || {},
    userPermissions: permissionsSelectors.getPermissionsList(state) || [],
  };
}

export default connect(mapStateToProps)(hot(module)(SettingsMenu));
