import {
  ISelectOption, useBoolean, useDebounce,
} from '@bp/kung-fu';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { getUsers } from '../../api/endpoints';
import { QueryKeys } from '../../constants';
import { UserUsersFilter } from './types';
import { transformUsers } from './utils';

const useUsersFilter = (): UserUsersFilter => {
  const [isLoading, setIsLoading] = useBoolean(true);
  const [options, setOptions] = useState<ISelectOption[]>();
  const [search, setSearch] = useState<string>('');

  const debounceSearch = useDebounce(search, 500);

  useQuery([QueryKeys.Users, { debounceSearch }], () => getUsers(debounceSearch), {
    onSuccess: (result) => {
      setOptions(transformUsers(result.data.items));
      setIsLoading.setFalse();
    },
  });

  const usersAsyncSelectCb = (inputValue: string): void => {
    setIsLoading.setTrue();
    setSearch(inputValue);
  };

  return {
    usersAsyncSelectCb,
    usersOption: options,
    usersIsLoading: isLoading,
  };
};

export default useUsersFilter;
