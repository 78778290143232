import isEqual from 'lodash/isEqual';
angular.module('bigpanda').service('bpUserIconService', bpUserIconService);
function bpUserIconService($q, $http, $filter, Config) {
  const cache = {};
  const requests = {};
  this.getUserIcon = getUserIcon;

  function getUserIcon(userName, size) {
    const gravatarExists = cache[userName];

    if (gravatarExists) {
      if (gravatarExists.iconUrl) {
        return $q.when(`${gravatarExists.iconUrl}&s=${size}`);
      } else if (
        new Date().getTime() - gravatarExists.expirationTime <
        Config.gravatarMissingIconCheckInterval
      ) {
        return $q.when(false);
      }
    }

    const deferred = $q.defer();

    if (requests[userName]) {
      return $q.when(requests[userName]);
    }

    $http.head(Config.gravatarUrl + $filter('gravatar')(userName)).then((res) => {
      if (isEqual(res.headers('last-modified'), 'Wed, 11 Jan 1984 08:00:00 GMT')) {
        cache[userName] = { iconUrl: null, expirationTime: new Date().getTime() };
        deferred.resolve(false);
      } else {
        const imgUrl = `${Config.gravatarUrl}${$filter('gravatar')(
          userName
        )}?d=${encodeURIComponent(Config.defaultImgLocation)}`;
        cache[userName] = { iconUrl: imgUrl, expirationTime: null };
        deferred.resolve(`${imgUrl}&s=${size}`);
      }
    });

    requests[userName] = deferred.promise;

    return requests[userName];
  }
}
