import map from 'lodash/map';
import isString from 'lodash/isString';
import _filter from 'lodash/filter';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
angular.module('bigpanda').filter('entityNormalizer', entityNormalizer);

function entityNormalizer($filter, TagsUtils, sourceBreakdownService, EntityTitleService) {
  return filter;

  function filter(rawEntity) {
    normalizeDate(rawEntity);
    normalizeTitle(rawEntity);

    rawEntity.duration = $filter('entityDuration')(rawEntity);
    rawEntity.status = normalizeStatus(rawEntity);
    rawEntity.id = rawEntity._id;

    const status = rawEntity.initial_status || 'critical';
    rawEntity.initialStatus = status.toLowerCase();
    rawEntity.sourceCap = sourceBreakdownService.findSystemName(rawEntity.source_system);
    rawEntity.hasStatusChange =
      rawEntity.start !== rawEntity.last_status_change_at ||
      rawEntity.status !== rawEntity.initialStatus;
    rawEntity.links = normalizeLinks(rawEntity);
    rawEntity.underMaintenance =
      rawEntity.maintenance_plan_ids != null && rawEntity.maintenance_plan_ids.length > 0;

    return rawEntity;
  }

  function isAcknowledged(rawEntity) {
    return !!TagsUtils.getTagValue(rawEntity, { type: 'acknowledged' }) || rawEntity.acknowledged;
  }

  function isMaintenance(rawEntity) {
    return !!TagsUtils.getTagValue(rawEntity, { type: '_maintenance' }) || rawEntity.maintenance;
  }

  function normalizeStatus(rawEntity) {
    if (isMaintenance(rawEntity)) {
      return 'maintenance';
    } else if (isAcknowledged(rawEntity)) {
      return 'acknowledged';
    }
    return rawEntity.status ? rawEntity.status.toLowerCase() : 'critical';
  }

  function normalizeDate(rawEntity) {
    const changeTime = rawEntity.last_status_change_at || rawEntity.changed;
    const momentDate = moment.unix(changeTime);
    rawEntity.lastChanged = momentDate.calendar();
    rawEntity.lastChangedTimestamp = changeTime;
    rawEntity.lastChangedDate = momentDate.format('MMM D');
    rawEntity.lastChangedTime = momentDate.format('h:mm a');
  }

  function normalizeTitle(rawEntity) {
    const entityMainProperties = EntityTitleService.getMainProperties([rawEntity]);
    rawEntity.title = getValue(rawEntity, entityMainProperties.primary);
    rawEntity.subtitle = getValue(rawEntity, entityMainProperties.secondary);
  }

  function getValue(rawEntity, fields) {
    return reduce(
      fields,
      (result, currentField) => {
        if (!result) {
          const tag = find(rawEntity.tags, (t) => t.type === currentField.name);
          return tag ? { type: currentField.name, value: tag.value } : null;
        }
        return null;
      },
      null
    );
  }

  function normalizeLinks(rawEntity) {
    const simpleUrlRegex = /^(http|https):\/\//i;
    const linkTags = _filter(
      rawEntity.tags,
      (tag) => tag && isString(tag.value) && tag.value.match(simpleUrlRegex)
    );
    return map(linkTags, (tag) => ({
      name: tag.type,
      value: tag.value,
    }));
  }
}
