import find from 'lodash/find';
angular.module('bp.widgets').directive('bpUserIcon', bpUserIcon);

const colorsCache = new Map();

function bpUserIcon(bpUserIconService, $q, pubSubService, ContactsStore) {
  return {
    restrict: 'E',
    scope: {
      username: '<',
      size: '<',
    },
    templateUrl: 'shared_ui/bp_user_icon/bp_user_icon',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
  };

  function controller() {
    const vm = this;
    const colors = [
      '#cd4e7e',
      '#ae801d',
      '#8f3900',
      '#e07401',
      '#23a7bb',
      '#9950e4',
      '#ff6271',
      '#508646',
      '#27ae96',
      '#9e1a4c',
    ];

    vm.placeHolderDisplay = true;
    vm.borderStyle = {
      height: vm.size,
      width: vm.size,
    };

    vm.$onInit = initIcon;
    vm.$onChanges = initIcon;

    function initIcon() {
      vm.placeHolderDisplay = true;

      if (vm.username) {
        bpUserIconService.getUserIcon(vm.username, vm.size).then((url) => {
          if (url) {
            vm.photoStyle = {
              height: vm.size,
              width: vm.size,
              'background-image': `url(${url})`,
            };
            vm.initials = null;
            vm.initialsAvailable = false;
            vm.placeHolderDisplay = false;
          } else {
            getInitials(vm.username).then((initials) => {
              vm.initials = initials;
              vm.initialsAvailable = true;
              vm.color = nameToColor(vm.initials);
              vm.initialsStyle = {
                height: vm.size,
                width: vm.size,
                'background-color': vm.color,
                'line-height': `${vm.size}px`,
                'font-size': `${vm.size / 2}px`,
              };
              vm.placeHolderDisplay = false;
            });
          }
        });
      }
    }

    function getInitials(email) {
      const deferred = $q.defer();
      ContactsStore.getContacts().then((contacts) => {
        const contact = find(contacts, { email: email });
        let name = contact && contact.name;
        if (!name) {
          name = email;
        }

        const names = name.split(' ');
        let initials = '';
        if (names.length < 2) {
          initials = name.substring(0, 2);
        } else {
          for (let i = 0; i < 2; i++) {
            initials += names[i].charAt(0);
          }
        }
        deferred.resolve(initials.toLowerCase());
      });
      return deferred.promise;
    }

    // Generate color
    function nameToColor(name) {
      if (!colorsCache.get(name)) {
        const hash = hashStr(name);
        const index = hash % colors.length;
        colorsCache.set(name, colors[index]);
      }

      return colorsCache.get(name);
    }

    // very simple hash
    function hashStr(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        hash += charCode;
      }
      return hash;
    }

    pubSubService.on('OrganizationService.usersChanged', initIcon);
  }
}
