angular.module('bigpanda').service('BpqlUtils', function ($log, BPQLService, AssigneeFilterStore) {
  this.getBpqlQuery = getBpqlQuery;
  this.formatBpqlQuery = formatBpqlQuery;

  function getBpqlQuery(query, validate) {
    let bpqlQuery = {};
    if (query) {
      try {
        const parser = BPQLService.buildParserFromGrammer('correlation');
        bpqlQuery = parser(query);
      } catch (e) {
        if (!validate) {
          $log.error('the bpql should have been validated already', e);
        }
        return {
          invalid: true,
          error: e,
        };
      }
    }
    return bpqlQuery;
  }

  function formatBpqlQuery(assignee, search) {
    let query = '';
    if (assignee) {
      query =
        assignee === AssigneeFilterStore.unassignedId ? 'assignee!=*' : `assignee=${assignee}`;
      if (search) {
        query += ` AND (${search})`;
      }
    } else {
      query = search;
    }
    return query;
  }
});
