import actionTypes from '../actionTypes';

const initialState = {
  list: undefined,
  granularPermissions: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        list: action.payload.permissions,
        granularPermissions: action.payload.granular_permissions,
        roles: action.payload.roles,
      };

    default:
      return state;
  }
}
