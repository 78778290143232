import moment from 'moment';
import { filterItems, filterItemsByDropDownFilters } from '../redux.utils';
import { ANY_OPTION_VALUE, API_KEYS_STATUSES } from './constants';

const getKeyStatus = ({ alive, active }) => {
  if (alive) {
    return active ? API_KEYS_STATUSES.active : API_KEYS_STATUSES.inactive;
  }
  return API_KEYS_STATUSES.error;
};

export const filterApiKeysByText = (filterText, apiKeysList) =>
  filterItems(apiKeysList, filterText, (apiKey) => [
    apiKey.name,
    apiKey.key_suffix,
    apiKey.user.name,
  ]);

export const formatTimestamp = (timestamp) =>
  timestamp && moment.unix(timestamp).format('MMM D, YYYY HH:MMa');

export const filterApiKeysByDropDown = (filtersDropDownState, apiKeysList) =>
  filterItemsByDropDownFilters(
    filtersDropDownState,
    {
      name: (item) => [item.user._id],
      status: (item) => [getKeyStatus(item)],
    },
    apiKeysList,
    ANY_OPTION_VALUE
  );
