import actionTypes from './actionTypes';

export function loadCorrelationConfig(payload, callback) {
  return { type: actionTypes.LOAD_CORRELATION_CONFIG, payload: payload, callback: callback };
}

export function loadCorrelationConfigSuccess(payload) {
  return { type: actionTypes.LOAD_CORRELATION_CONFIG_SUCCESS, payload: payload };
}

export function enrichCorrelationConfigItemsSuccess(payload) {
  return { type: actionTypes.ENRICH_CORRELATION_CONFIG_ITEMS_SUCCESS, payload: payload };
}

export function loadUnifiedTagsSuccess(payload) {
  return { type: actionTypes.LOAD_UNIFIED_TAGS_SUCCESS, payload: payload };
}
