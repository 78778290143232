angular.module('bigpanda').controller('QuotaRowController', ($scope) => {
  $scope.$watch(
    'target.datasetOptions.quota',
    () => {
      if ($scope.target) {
        $scope.target.quota = $scope.target.datasetOptions && $scope.target.datasetOptions.quota;
        if ($scope.target.quota) {
          $scope.target.quota.displayUsed = Math.min(
            $scope.target.quota.used,
            $scope.target.quota.available
          );
          $scope.target.quota.percent = Math.ceil(
            ($scope.target.quota.displayUsed / $scope.target.quota.available) * 100
          );
        }
      }
    },
    true
  );
});
