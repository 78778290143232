import uniqBy from 'lodash/uniqBy';
angular.module('bigpanda').service('EntityPersistencyStore', EntityPersistencyStore);

function EntityPersistencyStore(SubscribersService) {
  const subscribers = new SubscribersService();

  this.subscribe = subscribe;
  this.getPersistency = getPersistency;
  this.setPersistency = setPersistency;
  this.clearPersistency = clearPersistency;
  this.persistencyOn = persistencyOn;

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function getPersistency(keyName, property) {
    try {
      return JSON.parse(sessionStorage.getItem(`${keyName}_${property}`));
    } catch (e) {
      return undefined;
    }
  }

  function setPersistency(keyName, property, value) {
    const existPersistency = getPersistency(keyName, property);
    let newPersistency;

    if (existPersistency) {
      const mergedArray = uniqBy([...value, ...existPersistency], 'field');
      newPersistency = JSON.stringify(mergedArray);
    } else {
      newPersistency = JSON.stringify(value);
    }

    sessionStorage.setItem(`${keyName}_${property}`, newPersistency);
    subscribers.fireStoreUpdated();
  }

  function clearPersistency() {
    const keys = Object.keys(sessionStorage);
    keys.forEach((key) => {
      if (key.includes('bp_columns')) {
        sessionStorage.removeItem(key);
      }
    });
  }

  function persistencyOn(type) {
    const persistency = Object.keys(sessionStorage)
      .map((key) => key.includes(type))
      .filter((result) => result);

    return persistency.length > 0;
  }

  return this;
}
