angular.module('bigpanda').service('sourceBreakdownService', sourceBreakdownService);

function sourceBreakdownService(UserIntegrationsStore, SourceBreakdownGenerator) {
  this.findSystemName = findSystemName;
  this.getSourceBreakdownData = getSourceBreakdownData;
  this.isCrossSource = isCrossSource;

  function findSystemName(sourceSystem, incident) {
    const integrations = UserIntegrationsStore.getUnsafeMutableIntegrations();
    return SourceBreakdownGenerator.findSystemName(integrations, sourceSystem, incident);
  }

  function isCrossSource(entities) {
    const integrations = UserIntegrationsStore.getUnsafeMutableIntegrations();
    return SourceBreakdownGenerator.isCrossSource(integrations, entities);
  }

  function getSourceBreakdownData(entities, activeOnly) {
    const integrations = UserIntegrationsStore.getUnsafeMutableIntegrations();
    return SourceBreakdownGenerator.getSourceBreakdownData(integrations, entities, activeOnly);
  }
}
