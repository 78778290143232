angular.module('bigpanda').controller('IncidentResolveCtrl', IncidentResolveCtrl);

function IncidentResolveCtrl($scope, ResolveService, resolvedIncidentIds) {
  const vm = this;

  vm.incidentsIds = resolvedIncidentIds;

  const multiple = vm.incidentsIds.length > 1;

  $scope.dialogTitle = `Resolve Incident${multiple ? 's' : ''}`;
  $scope.titleIconClass = 'bp-icon-resolve-full';
  $scope.submitButtonCaption = 'Resolve';
  $scope.cancelButtonCaption = 'Cancel';
  $scope.annotationObject = {};
  $scope.saveButtonProductId = 'resolve_incident';

  $scope.preSave = () => {
    ResolveService.resolve(vm.incidentsIds, vm.annotationObject);
  };

  generateNote();

  function generateNote() {
    if (!multiple) {
      vm.noteText =
        'This incident will reopen automatically if the source system generates a related alert.';
    } else {
      vm.noteText =
        'Some of the incidents might reopen automatically if their source system generates a related alert.';
    }
  }
}
