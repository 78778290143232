import { combineEpics } from 'redux-observable';
import * as settings from './settings';
import * as feed from './feed';
import * as tags from './tags';
import * as changes from './changes';
import * as overview from './overview';
import * as integrations from './integrations';

export default combineEpics(
  settings.epic,
  feed.epic,
  tags.epic,
  changes.epic,
  overview.epic,
  integrations.epic
);
