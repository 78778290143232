import { fetcher } from '@bp/data-access';

import { IgetOrganizationOnboardingPlanResponse } from '../components/Drawer/types';

const onboardingUrl = '/resources/v2.0/onboarding';

export async function getOnboardingPlan(): Promise<IgetOrganizationOnboardingPlanResponse> {
  return fetcher({
    requestConfig: {
      url: `${onboardingUrl}/plan`,
    },
    responseConfig: { path: 'data.data' },
  });
}

export async function completeStep(name: string): Promise<IgetOrganizationOnboardingPlanResponse> {
  return fetcher({
    requestConfig: {
      url: `${onboardingUrl}/plan/step/${name}/complete`,
      method: 'POST',
    },
    responseConfig: { path: 'data.data' },
  });
}
