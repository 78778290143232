import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme, Badge, EditIcon } from '@bp/kung-fu';
import { BamEmptyMessage, BamTile } from '@bp/bam';
import PropTypes from 'prop-types';
import 'moment-precise-range-plugin';
import permissions from 'common/routing/permissions';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import selectors from '../selectors';
import IntegrationsModal from '../components/modal/IntegrationsModal';

const IntegrationsInfo = ({ integration, user, updatedBy, userPermissions }) => {
  if (!integration) {
    return (
      <BamEmptyMessage header="No integration selected">
        Select an integration to see details.
      </BamEmptyMessage>
    );
  }

  const canEdit = userPermissions.includes(permissions.integrations.update);

  const content = (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.SectionRow title="Status:">
        <Badge text={integration.status} status={integration.status} isCapitalized size="large" />
      </SettingsInfoScreen.SectionRow>
    </SettingsInfoScreen.Section>
  );

  const regularActionButtons = (
    <SettingsInfoScreen.Section>
      <IntegrationsModal
        triggerProps={{
          variant: 'action-color',
          size: 'large',
          text: 'Edit Integration',
          icon: <EditIcon />,
          disabled: !canEdit,
        }}
        title="Edit Integration"
        titleIcon="bp-icon-edit"
        initialValues={integration}
      />
    </SettingsInfoScreen.Section>
  );

  const actionButtons = regularActionButtons;

  const activity = (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.ActivityRow
        title="Created by:"
        name={user ? user.name || user.username : 'N/A'}
        value={selectors.formatDate(integration.created_at)}
      />
      {integration.updated_at && (
        <SettingsInfoScreen.ActivityRow
          title="Last changed by:"
          name={updatedBy ? updatedBy.name || updatedBy.username : 'N/A'}
          value={selectors.formatDate(integration.updated_at)}
        />
      )}
    </SettingsInfoScreen.Section>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <SettingsInfoScreen
        header={<BamTile bamStatus={integration.status} headerRow={{ title: integration.name }} />}
        content={content}
        actions={actionButtons}
        activity={activity}
      />
    </ThemeProvider>
  );
};

IntegrationsInfo.propTypes = {
  integration: PropTypes.object /* eslint-disable-line */,
  user: PropTypes.object /* eslint-disable-line */,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired /* eslint-disable-line */,
  updatedBy: PropTypes.object /* eslint-disable-line */,
};

export default IntegrationsInfo;
