import React from 'react';
import PropTypes from 'prop-types';
import { BamSearch, BamTimeFrameDropdown } from '@bp/bam';
import { AllSizes } from '@bp/kung-fu';
import STATIC_BPQL_TOOLTIP_DATA from './BqplTooltipHint';
import { STATIC_SEARCH_TOOLTIP_DATA } from '../Consts';
import QueryHelper from '../../../../../workspaces/apps/changes/src/components/QueryHelper';
import { ChangesSearchWrapper } from './RelatedChangesSearch.style';

const RelatedChangesSearch = (props) => {
  const {
    onChangeSearchText,
    onClearSearchText,
    onSearch,
    options,
    defaultOption,
    onTimeFrameChanged,
    defaultSearchInputText,
    errorMessage,
    disable,
    bpqlSearch,
    disableQueryHelper,
  } = props;
  const MIN_FILTER_LENGTH_SEARCH = 10;
  const tooltip = bpqlSearch ? STATIC_BPQL_TOOLTIP_DATA : STATIC_SEARCH_TOOLTIP_DATA;
  return !disableQueryHelper ? (
    <ChangesSearchWrapper>
      <BamTimeFrameDropdown
        style={{ borderRadius: '7px', height: '34px' }}
        options={options}
        defaultSelected={defaultOption}
        onChange={onTimeFrameChanged}
        withBorder
        disabled={disable}
        minimalOptionsLengthForSearch={MIN_FILTER_LENGTH_SEARCH}
      />
      <QueryHelper
        handleSearch={onSearch}
        triggerComponentProps={{ disabled: disable, size: AllSizes.MEDIUM }}
        error={errorMessage}
        {...props}
      />
    </ChangesSearchWrapper>
  ) : (
    <BamSearch
      defaultSearchInputText={defaultSearchInputText}
      onSearchInputChange={onChangeSearchText}
      onSearchInputRemoveClick={onClearSearchText}
      onSearchClick={onSearch}
      placeholder="Search for Root Cause Changes..."
      disabled={disable}
      errorMessage={errorMessage}
      action={
        <BamTimeFrameDropdown
          options={options}
          defaultSelected={defaultOption}
          onChange={onTimeFrameChanged}
          withBorder
          disabled={disable}
          minimalOptionsLengthForSearch={MIN_FILTER_LENGTH_SEARCH}
        />
      }
      tooltipHint={
        !disable && {
          ...tooltip,
        }
      }
    />
  );
};

RelatedChangesSearch.propTypes = {
  defaultSearchInputText: PropTypes.string,
  onChangeSearchText: PropTypes.func,
  onClearSearchText: PropTypes.func,
  onSearch: PropTypes.func,
  onTimeFrameChanged: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  defaultOption: PropTypes.shape({}),
  disable: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  bpqlSearch: PropTypes.bool.isRequired,
  disableQueryHelper: PropTypes.bool,
};

RelatedChangesSearch.defaultProps = {
  defaultSearchInputText: undefined,
  onChangeSearchText: undefined,
  onClearSearchText: undefined,
  onSearch: undefined,
  onTimeFrameChanged: undefined,
  options: undefined,
  defaultOption: undefined,
  disable: false,
  disableQueryHelper: false,
};

export default RelatedChangesSearch;
