import { react2angular } from 'react2angular';
import UserInfo from './components/UserInfo';

const routes = {};

routes.appSettingsUsersInfo = {
  url: '/:userId',
  views: {
    'master-detail-info@app.settings.user_management': {
      template:
        '<user-info class="react-settings-info" user-id="stateParams.userId" store="store" />',
      controller: ($scope, $ngRedux, $stateParams) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
      },
    },
  },
};

angular.module('bigpanda').component('userInfo', react2angular(UserInfo, ['userId', 'store']));

export default routes;
