angular.module('bigpanda').directive('investigatorResult', investigatorResult);

function investigatorResult() {
  return {
    restrict: 'E',
    scope: {
      incident: '<',
      highlightRange: '<',
    },
    templateUrl: 'investigator/result/investigator_result',
    controller: controller,
    controllerAs: 'investigatorResultVm',
    bindToController: true,
  };

  function controller(
    IncidentsService,
    sourceBreakdownService,
    SharesStore,
    stateService,
    Permissions
  ) {
    const vm = this;

    vm.crossSource = null;
    vm.entities = null;
    vm.showTimelines = null;
    vm.buttons = null;
    vm.underMaintenance = false;
    vm.buttonsStyle = 'hide-inactive-buttons';

    vm.$onInit = onInit;

    const stateParams = {
      currentEnvId: stateService.getSelectedEnvironmentId(),
    };

    vm.currentEnvActions = {
      update: Permissions.checkGranularPermission(
        'action@update',
        'environments',
        stateParams.currentEnvId
      ),
      read: Permissions.checkGranularPermission(
        'action@read',
        'environments',
        stateParams.currentEnvId
      ),
    };

    vm.updateButtonsStyle = updateButtonsStyle;

    function onInit() {
      vm.crossSource = sourceBreakdownService.isCrossSource(vm.incident.entities);
      IncidentsService.getIncidentEntities(vm.incident._id, 'full').then((entities) => {
        vm.entities = entities.entities;
        vm.showTimelines = true;
        vm.incident.underMaintenance = vm.entities.filter(
          (entity) => entity.maintenance_plan_ids && entity.maintenance_plan_ids.length
        ).length;
      });
      SharesStore.getShares(vm.incident.id).then((shares) => {
        const hasEditPermission = vm.currentEnvActions.update || vm.currentEnvActions.read;
        vm.buttons = createButtons({
          showCommentButton: hasEditPermission,
          showShareButton: shares && shares.length > 0,
          showSnoozeButton: hasEditPermission,
        });
      });
    }

    function createButtons({ showCommentButton, showShareButton, showSnoozeButton }) {
      return [
        createForwardButton(),
        ...(showCommentButton ? [createCommentButton()] : []),
        ...(showShareButton ? [createShareButton()] : []),
        ...(showSnoozeButton ? [createSnoozeButton()] : []),
      ];
    }

    function updateButtonsStyle({ expandButtons }) {
      vm.buttonsStyle = expandButtons ? '' : 'hide-inactive-buttons';
    }

    function createShareButton() {
      return {
        template: `
          <share-button
            incident-id="params.incident.id"
            existing-shares-state="app.investigator.shares-list"
            disable-share="true"
          />
        `,
        params: {
          incident: vm.incident,
        },
      };
    }

    function createForwardButton() {
      return {
        template: `
          <forward-button
            class="inactive-button"
            incident-id="params.incidentId"
            environment-id="params.environmentId"
          </forward-button>
        `,
        params: {
          incidentId: vm.incident.id,
          environmentId: stateParams.currentEnvId,
        },
      };
    }

    function createSnoozeButton() {
      return {
        template: `
          <snooze-button
            incident-id="params.incident.id"
            snooze-state="app.investigator.snooze"
            disable-click="${!vm.currentEnvActions.update}"
          </snooze-button>
        `,
        params: {
          incident: vm.incident,
          tooltip: 'Snooze',
        },
      };
    }

    function createCommentButton() {
      return {
        template: `
          <comment-button
            incident-id="params.incident.id",
            comment-state="app.investigator.comment"
            comment-disabled="${!vm.currentEnvActions.update}"
          />
        `,
        params: {
          incident: vm.incident,
          tooltip: 'View Comments',
        },
      };
    }
  }
}
