import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { compose, identity, curry } from 'lodash/fp';
import boolToLodashOrder from 'common/utils/boolToLodashOrder';
import { path, NAME_SORT_MODE, DEFAULT_SORT_MODE, ANY_OPTION_VALUE } from './constants';
import { generateBaseSelectors } from '../../redux.utils';
import { formatPermissionName, filterItemsByDropDownFilters } from './utils';
import { USER_NAME_SORT_MODE } from '../../user_management/users_list/constants';

const selectors = generateBaseSelectors(path);

const mapUsers = (role) => {
  if (role) {
    const usersFlat = role.usernames.map((user) => {
      return user.email;
    });
    return usersFlat;
  }
  return [];
};

const rolesFilterByDropDown = (filtersDropDownState, items) => {
  const dropDownRoles = filterItemsByDropDownFilters(
    filtersDropDownState,
    {
      permission: (item) => formatPermissionName(item),
      user: (item) => mapUsers(item),
    },
    items,
    ANY_OPTION_VALUE
  );

  return dropDownRoles;
};

selectors.getRoles = (
  state,
  { shouldFilterByText = true, shouldFilterByDropDownFilters = true, shouldSort = true } = {}
) => {
  const { roles, filterText } = get(state, path);
  if (!roles) {
    return undefined;
  }
  const filteredRoles = (filterText, roles) =>
    roles.filter((role) => {
      if (filterText) {
        return role.name.toLowerCase().includes(filterText.toLowerCase());
      }
      return true;
    });

  const sortItems = (sortMode, filteredRoles) => {
    const { field, ascending } = sortMode;
    let sorter = field;
    if (field === USER_NAME_SORT_MODE.field) {
      sorter = (user) => user[field].toLowerCase();
    }

    const { field: defaultField, ascending: defaultOrder } = NAME_SORT_MODE;

    const rolesWOField = filteredRoles.filter((role) => !role[field]);
    const rolesWField = filteredRoles.filter((role) => role[field]);

    const sortedRolesWOField = orderBy(
      rolesWOField,
      [defaultField],
      [defaultOrder].map(boolToLodashOrder)
    );

    const sortedRoles = orderBy(
      rolesWField,
      [sorter, defaultField],
      [ascending, defaultOrder].map(boolToLodashOrder)
    );
    return [...sortedRoles, ...sortedRolesWOField];
  };

  const settingsSortingFT = get(
    state,
    ['user', 'organization', 'feature_toggles', 'frontier_settings_sorting_and_filters'],
    false
  );

  const rolesItems = compose(
    settingsSortingFT
      ? curry(rolesFilterByDropDown)(selectors.getFiltersDropDownState(state))
      : identity,
    shouldSort ? curry(sortItems)(selectors.getSortMode(state)) : identity,
    shouldFilterByText ? curry(filteredRoles)(filterText) : identity
  )(roles);

  return rolesItems;
};

selectors.getInitialRoleValues = (state, roleId, isDuplicate) => {
  const { roles, permissionsAndEnvironments } = get(state, path);
  if (!roles || !roleId || !permissionsAndEnvironments) {
    return undefined;
  }

  const currentRole = roles.find((role) => role._id === roleId);
  if (!currentRole) {
    return undefined;
  }

  const availableEnvIds = permissionsAndEnvironments.environments.map((env) => env._id);

  const currentEnvPermissions = currentRole.permissions.filter(
    (permission) => permission.resource_type === 'environments'
  );
  const currentRoleSpecificEnvHash = currentEnvPermissions
    .filter((permission) => availableEnvIds.includes(permission.resource_id))
    .reduce((acc, permission) => {
      const resourceId = permission.resource_id;
      return {
        ...acc,
        ...{
          [resourceId]: permission.actions.reduce(
            (innerAcc, action) => ({
              ...innerAcc,
              [action]: true,
            }),
            acc[resourceId] || {}
          ),
        },
      };
    }, {});

  const currentEnvPermissionsValues = Object.entries(currentRoleSpecificEnvHash)
    .map(([envId, actionsHash]) => {
      if (
        actionsHash.Read &&
        actionsHash['Action@Create'] &&
        actionsHash['Action@Delete'] &&
        actionsHash['Action@Update'] &&
        actionsHash['Action@Read']
      ) {
        return `${envId}_Incident_Actions`;
      }
      if (actionsHash.Read) {
        return `${envId}_Read`;
      }
      return null;
    })
    .filter((permValue) => permValue);

  const currentGlobalEnvAccessPermissionsHash = currentEnvPermissions
    .filter((permission) => !permission.resource_id)
    .reduce(
      (acc, permission) => ({
        ...acc,
        ...permission.actions.reduce(
          (innerAcc, action) => ({
            ...innerAcc,
            [action]: true,
          }),
          acc
        ),
      }),
      {}
    );

  const currentGlobalEnvAccessPermissionsValues = [];
  const isEnvsIncidentActions = (permsissionsHash = {}) =>
    permsissionsHash.Read &&
    permsissionsHash['Action@Read'] &&
    permsissionsHash['Action@Update'] &&
    permsissionsHash['Action@Create'] &&
    permsissionsHash['Action@Delete'];

  const isEnvsFullAccess = (permsissionsHash = {}) =>
    permsissionsHash['Action@Create'] &&
    permsissionsHash['Action@Delete'] &&
    permsissionsHash['Action@Update'] &&
    permsissionsHash['Action@Read'] &&
    permsissionsHash.Read &&
    permsissionsHash.Create &&
    permsissionsHash.Update &&
    permsissionsHash.Delete;

  if (isEnvsFullAccess(currentGlobalEnvAccessPermissionsHash)) {
    currentGlobalEnvAccessPermissionsValues.push('Environments_Full_Access');
  } else if (isEnvsIncidentActions(currentGlobalEnvAccessPermissionsHash)) {
    currentGlobalEnvAccessPermissionsValues.push('Environments_Incident_Actions');
  } else if (
    currentGlobalEnvAccessPermissionsHash.Read &&
    currentGlobalEnvAccessPermissionsHash['Action@Read']
  ) {
    currentGlobalEnvAccessPermissionsValues.push('Environments_Read');
  }

  const formattedPermissionValues = formatPermissionName(currentRole);

  const name = `${isDuplicate ? 'Copy of ' : ''}${currentRole.name}`;

  return {
    users: currentRole.users,
    name: name,
    permissions: formattedPermissionValues,
    environments: [...currentEnvPermissionsValues, ...currentGlobalEnvAccessPermissionsValues],
  };
};

selectors.getPermissionsAndEnvironments = (state) => {
  const { permissionsAndEnvironments } = get(state, path);
  return permissionsAndEnvironments;
};
selectors.getEnvironments = (state) => get(state, `${path}.environments`);

selectors.getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;
selectors.getError = (state) => get(state, path).error;

export default selectors;
