import { BamInput } from '@bp/bam';
import { useDropDown } from '@bp/kung-fu';
import React, { SyntheticEvent } from 'react';
import { Field } from 'react-final-form';

import { HelpTooltip, HelpTooltipProps } from './HelpTooltip';
import { InputContainer, Subtitle } from './style';

export interface InputWithHelpTooltipProps {
  name: string;
  className?: string;
  title: string;
  hint: string;
  placeholder: string;
  fixedFont: boolean;
  validate: (value: string | number, allValues: object) => string | undefined;
  validateOnBlur: boolean;
  ariaLabel: string;
  onKeyPress: (e: SyntheticEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  value: string | undefined;
  docsLink?: string;
  subtitle?: JSX.Element;
  source?: string;
  isReservedSource?: boolean;
}

export function InputWithHelpTooltip({
  name,
  className,
  title,
  hint,
  placeholder,
  fixedFont,
  validate,
  validateOnBlur,
  ariaLabel,
  onKeyPress,
  onBlur,
  value,
  subtitle,
  ...helpTooltipProps
}: InputWithHelpTooltipProps & HelpTooltipProps): JSX.Element {
  const {
    register, Container, isOpen, setOpen,
  } = useDropDown();

  const shouldShowHelpTooltip = !value && isOpen;

  return (
    <>
      <InputContainer {...register()}>
        <Field
          autoComplete="off"
          name={name}
          component={BamInput}
          className={className}
          title={title}
          hint={hint}
          placeholder={placeholder}
          fixedFont={fixedFont}
          validate={validate}
          validateOnBlur={validateOnBlur}
          aria-label={ariaLabel}
          onKeyPress={(e: SyntheticEvent<HTMLInputElement>): void => {
            if (e?.currentTarget?.value) {
              setOpen(false);
            }

            onKeyPress(e);
          }}
          onBlur={onBlur}
        />
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </InputContainer>
      {shouldShowHelpTooltip && (
        <Container>
          <HelpTooltip {...helpTooltipProps} />
        </Container>
      )}
    </>
  );
}

export default InputWithHelpTooltip;
