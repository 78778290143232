import { path } from './constants';

const actionTypes: Record<string, string> = {
  UpdateFilterText: `${path}.UPDATE_FILTER_TEXT`,
  UpdateSortMode: `${path}.UPDATE_SORT_MODE`,
  LoadAlertFilters: `${path}.LOAD_ALERT_FILTERS`,
  LoadAlertFiltersSuccess: `${path}.LOAD_ALERT_FILTERS_SUCCESS`,
  LoadAlertFiltersFailure: `${path}.LOAD_ALERT_FILTERS_FAILURE`,
  CreateFiltersDropdownItemsSuccess: `${path}.CREATE_FILTERS_DROPDOWN_ITEMS_SUCCESS`,
  UpdateFilters: `${path}.UPDATE_FILTERS`,
  DeleteAlertFilter: `${path}.DELETE_ALERT_FILTER`,
  UpdateAlertFilter: `${path}.UPDATE_ALERT_FILTER`,
  CreateAlertFilter: `${path}.CREATE_ALERT_FILTER`,
};

export default actionTypes;
