import { BamBpqlInput } from '@bp/bam';
import {
  darkTheme, HelperType, IDataListObject, LabelIcon, QueryHelper, TagIcon, TextArea,
} from '@bp/kung-fu';
import get from 'lodash/get';
import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import actions from '../../../../../../app/react/modules/settings/incident_labels/actions';
import { selectors as permissionsSelectors } from '../../../../../../app/react/user/permissions';
import provideStore from '../../../../../../app/react/utils/provideStore';
import {
  ALERT_TAGS, DROPDOWMN_SHADOW, INCIDENT_TAGS, INCIDENT_TAGS_METADATA,
} from './constants';
import { QueryHelperWrapper } from './QueryHelper.style';
import { IQueryHelper } from './types/IQueryHelper';

function EnvironmentQueryHelper({
  triggerComponentProps,
  alertTags,
  tagsDefinitions,
  onChange,
  showIncidents,
  placeholder,
  shouldUseNamespace,
}: IQueryHelper): JSX.Element {
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  function validateQueryFilter(query: string): string | undefined {
    return query ? BamBpqlInput.helpers.BpqlInputValidator(query) : '';
  }

  const buildIncidentTagsQueryHelperData = (): IDataListObject => {
    const incidentTagsQueryHelperDataValues = [
      ...INCIDENT_TAGS_METADATA,
      ...tagsDefinitions.map(({ name, description, id }) => ({ title: name, description, id })),
    ];
    return {
      title: INCIDENT_TAGS,
      type: HelperType.INCIDENT_TAGS,
      disabled: false,
      icon: <LabelIcon />,
      values: incidentTagsQueryHelperDataValues,
    };
  };

  const buildAlertTagsQueryHelperData = (): IDataListObject => {
    const alertTagsQueryHelperDataValues = alertTags?.map(({ name, sourceSystems }) => ({
      title: name,
      description: get(sourceSystems, '0.parentDisplayName'),
    }));
    return (
      alertTags.length && {
        title: ALERT_TAGS,
        type: HelperType.ALERT_TAGS,
        disabled: false,
        icon: <TagIcon />,
        values: alertTagsQueryHelperDataValues,
      }
    );
  };

  const permissions = useSelector((state) => permissionsSelectors.getPermissionsList(state)) || [];
  useEffect(() => {
    if (permissions['incident-tags-definitions@read']) {
      dispatch(actions.loadMultipleIncidentLabels());
    }
  }, []);

  const incidentTagsQueryHelperData = useMemo(buildIncidentTagsQueryHelperData, [tagsDefinitions]);

  const alertTagsQueryHelperData = useMemo(buildAlertTagsQueryHelperData, [alertTags]);

  const queryHelperData = [
    ...(showIncidents ? [incidentTagsQueryHelperData] : []),
    ...(alertTags?.length ? [alertTagsQueryHelperData] : []),
  ];

  const handleOnChange = useCallback((query: string) => {
    setError(validateQueryFilter(query));
    onChange(query);
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <QueryHelperWrapper>
        <QueryHelper
          placeholder={placeholder}
          data={queryHelperData}
          onChange={handleOnChange}
          error={error}
          TriggerComponent={TextArea}
          triggerComponentProps={triggerComponentProps}
          dropDownShadow={DROPDOWMN_SHADOW}
          shouldUseNamespace={shouldUseNamespace}
        />
      </QueryHelperWrapper>
    </ThemeProvider>
  );
}
export default provideStore(memo(EnvironmentQueryHelper));
