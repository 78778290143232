angular.module('bigpanda').service('FeedModeStore', FeedModeStore);

function FeedModeStore(
  $log,
  SubscribersService,
  UserFeatureTogglesService,
  CustomizationsStore,
  notificationService
) {
  const subscribers = new SubscribersService();
  let feedMode = false;
  this.subscribe = subscribe;
  this.getFeedMode = getFeedMode;
  this.setFeedMode = setFeedMode;
  this.getToggle = getToggle;

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function getFeedMode() {
    return CustomizationsStore.getCustomizations()
      .then((customizations) => customizations.incidents.feed.live_mode)
      .catch((error) => {
        $log.error(`Failed getting user customizations: ${error}`);
        notificationService.error('Error updating stars', { error: error });
      });
  }

  function setFeedMode(newFeedMode) {
    feedMode = newFeedMode;
    CustomizationsStore.updateCustomizations({ incidents: { feed: { live_mode: newFeedMode } } })
      .then(subscribers.fireStoreUpdated)
      .catch((error) => {
        $log.error(`Failed updating user customizations: ${error.message}`);
        notificationService.error('Error updating feed mode', { error: error });
      });
  }

  function getToggle() {
    return UserFeatureTogglesService.getToggle('live_mode');
  }

  return this;
}
