import get from 'lodash/get';
angular.module('bigpanda').directive('assigneeFilterButton', assigneeFilterButton);

function assigneeFilterButton() {
  return {
    restrict: 'E',
    scope: {},
    controllerAs: 'vm',
    bindToController: true,
    controller: controller,
    templateUrl: 'overview/incidents/list/widgets/assignee_filter_button/assignee_filter_button',
  };

  function controller(
    $scope,
    $element,
    AssigneeFilterStore,
    ContactsStore,
    SortingStore,
    bpPanelSelectService,
    UserFeatureTogglesService,
    AssignPanelUtils
  ) {
    const vm = this;
    vm.context = { assignee: {} };
    vm.$onInit = onInit;
    vm.$onChanges = updateContext;
    vm.openSelector = openSelector;
    vm.isOpen = false;
    vm.isDisabled = true;
    vm.isSortedByPriority = false;
    vm.unassignedUser = {
      id: AssigneeFilterStore.unassignedId,
      bigpanda_user_id: AssigneeFilterStore.unassignedId,
      displayName: 'Unassigned',
      template:
        'overview/incidents/list/widgets/assignee_filter_button/assignee_filter_unassigned_option',
    };

    function onInit() {
      AssigneeFilterStore.subscribe({ storeUpdated: () => updateContext(vm.context) }, $scope);
      SortingStore.subscribe({ storeUpdated: updateSortBy }, $scope);
      ContactsStore.getContacts().then(() => (vm.isDisabled = false));
      updateContext(vm.context);
      updateSortBy();
    }

    async function openSelector() {
      if (!vm.isDisabled) {
        const userOptions = await AssignPanelUtils.getUserOptions();
        if (userOptions.length) {
          const users = [userOptions[0], vm.unassignedUser, ...userOptions.splice(1)];
          bpPanelSelectService.openSelector({
            element: $element,
            options: users,
            selectedId: get(vm.context.assignee, 'bigpanda_user_id'),
            filterKey: 'displayName',
            resetActionName: 'Clear filter',
            panelSize: 'medium',
            title: 'Filter by Assignee:',
            onSelect: onSelect,
            onOpenComplete: onMenuOpen,
            onDomRemoved: onMenuClose,
          });
        }
      }
    }

    async function onSelect(selectedUserId) {
      if (selectedUserId) {
        const user =
          selectedUserId === AssigneeFilterStore.unassignedId
            ? vm.unassignedUser
            : await ContactsStore.getContactByUserId(selectedUserId);
        AssigneeFilterStore.setAssignee(user);
      } else {
        AssigneeFilterStore.resetAssignee();
      }
    }

    function onMenuOpen() {
      vm.isOpen = true;
    }

    function onMenuClose() {
      vm.isOpen = false;
    }

    function updateContext(context) {
      context.assignee = AssigneeFilterStore.getAssignee();
    }

    function updateSortBy() {
      SortingStore.getSortBy().then((sortBy) => {
        vm.isSortedByPriority = sortBy === SortingStore.options.label_priority;
      });
    }
  }
}
