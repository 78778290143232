import { EditIcon, StatusEnum } from '@bp/kung-fu';
import React from 'react';

import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';
import { MaintenancePlan } from '../../../types/MaintenancePlan';
import MaintenanceModal from '../modal/MaintenanceModal';

interface IProps {
  plan: MaintenancePlan;
  canEdit?: boolean;
}

export function RegularActionButtons({ plan, canEdit }: IProps): JSX.Element {
  const doneTooltipContent = plan.status === StatusEnum.DONE && "Cannot Edit 'Done' Plan";
  const disabled = plan.status === StatusEnum.DONE || !canEdit;

  return (
    <SettingsInfoScreen.Section>
      <MaintenanceModal
        triggerProps={{
          variant: 'action-color',
          size: 'large',
          icon: <EditIcon />,
          text: 'Edit Plan',
          disabled,
          tooltipProps: {
            isActive: disabled,
            text: doneTooltipContent,
            placement: 'bottom-start',
          },
        }}
        title="Edit Plan"
        titleIcon="bp-icon-edit"
        initialValues={plan}
      />
    </SettingsInfoScreen.Section>
  );
}
