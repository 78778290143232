import get from 'lodash/get';
import { path } from './constants';

const getLabelDefinitions = (state) => get(state, `${path}.labelDefinitions`, []);

const selectors = {
  getLabelDefinitions,
};

export default selectors;
