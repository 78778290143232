import { ISelectOption } from '@bp/kung-fu';

export const checkValuesNotExist = (
  selectValues: ISelectOption[],
  items: ISelectOption[],
): string | undefined => {
  const itemsMap = items.reduce((acc, item) => {
    acc[item.text] = true;
    return acc;
  }, {});

  const notExistingValues = selectValues.filter(
    (selectValue) => selectValue?.text && !itemsMap[selectValue.text],
  );

  if (notExistingValues.length > 0) {
    return `${notExistingValues[0].text} is no longer part of the closed list.`;
  }

  return undefined;
};
