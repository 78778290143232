import get from 'lodash/get';
/* eslint-disable react/sort-comp */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { BamModal, BamEmptyMessage, BamTable } from '@bp/bam';

import actions from '../actions';
import { CUSTOM_DATES_RANGE, reqStatusType, RESULTS_PER_PAGE } from '../constants';
import selectors from '../selectors';
import { integrationPropType, troubleshootingDataPropType } from '../propTypes';
import TroubleshootingModalActions from '../TroubleshootingModalActions/TroubleshootingModalActions';
import TroubleshootingModalSearch from '../TroubleshootingModalSearch/TroubleshootingModalSearch';
import TroubleshootingPayload from '../TroubleshootingPayload/TroubleshootingPayload';
import columnsSettings from './columnsSettings';
import { getDateRange } from '../utils';
import styles from './TroubleshootingModal.scss';

class TroubleshootingModal extends React.Component {
  state = {
    columns: columnsSettings,
    selectedRow: null,
    currentSearch: '',
  };
  componentDidMount() {
    const { getLogsMetadata, integration } = this.props;
    window.location.href = `/v2/integrations/troubleshooting/${integration.system_id}`;
    this.getLogs({ reset: true });
    getLogsMetadata();
  }

  clearSelectedRow = () => this.setState({ selectedRow: null });

  refresh = () => {
    const { currentSearch } = this.state;
    this.clearSelectedRow();
    this.getLogs({ page: 1, search: currentSearch });
  };

  onFilterChange = (newFilters) => {
    const {
      troubleshootingData: { filtersDropDownState: currentFilters },
    } = this.props;
    const { currentSearch } = this.state;
    const updatedFilters = {
      ...currentFilters,
      ...newFilters,
    };
    this.clearSelectedRow();
    this.getLogs({
      updatedFilters,
      page: 1,
      search: currentSearch,
    });
  };

  onSearchInputChange = (query) => {
    this.setState({ currentSearch: query });
  };

  onSearchClick = () => {
    const { currentSearch } = this.state;
    this.clearSelectedRow();
    this.getLogs({
      search: currentSearch || '',
      page: 1,
    });
  };

  getLogs = ({ reset, updatedFilters, page, search } = {}) => {
    const {
      integration,
      getLogs,
      troubleshootingData: {
        filtersDropDownState: currentFilters,
        datePickerFrom,
        datePickerTo,
        page: currentPage,
      },
    } = this.props;
    const filters = updatedFilters || currentFilters;
    const { from: startDate, to: endDate } =
      filters.datesRange.value === CUSTOM_DATES_RANGE.value
        ? { from: datePickerFrom, to: datePickerTo }
        : getDateRange(filters.datesRange.value);
    getLogs({
      resourceId: integration.system_id,
      filtersDropDownState: filters,
      startDate,
      endDate,
      page: page || currentPage,
      per_page: RESULTS_PER_PAGE,
      search: search || '',
      reset: reset || false,
    });
  };

  onColumnsChanged = (columns) => {
    this.setState({ columns });
  };

  setSelectedRow = (rowInfo) => {
    this.setState({ selectedRow: rowInfo.row._original });
  };

  getTrProps = (rowInfo = {}) => {
    const { row } = rowInfo;
    return {
      role: 'row',
      'aria-label': 'troubleshooting log',
      className: cn(styles.row, {
        [styles['row-selected']]:
          get(this.state, 'selectedRow.log_id', NaN) === get(row, '_original.log_id'),
      }),
    };
  };

  getTableProps = () => ({ onScroll: this.onTableScroll });

  onTableScroll = (event) => {
    const scrollAheadDistance = 50;
    const {
      target: { scrollHeight, clientHeight, scrollTop },
    } = event;
    const {
      troubleshootingData: { data, total, page },
      isLoading,
    } = this.props;
    if (
      scrollHeight - clientHeight <= scrollTop + scrollAheadDistance &&
      !isLoading &&
      data.length < total
    ) {
      this.getLogs({ page: page + 1 });
    }
  };

  render() {
    const { integration, troubleshootingData, isLoading } = this.props;
    const { columns, selectedRow, currentSearch } = this.state;
    const { data, getRequestStatus } = troubleshootingData;
    const baseURL = `/v2/integrations`;
    return (
      <ThemeProvider theme={darkTheme}>
        <BamModal
          title="Troubleshooting"
          titleIcon="bp-icon-troubleshooting"
          fullscreen
          defaultOpen
          className={styles.modal}
          onClose={() => {
            window.location.href = baseURL;
          }}
        >
          <div className={styles.root}>
            <div className={styles.leftPane}>
              <div className={styles.systemId}>
                <div className={styles.systemId__label}>Integration ID: </div>
                <div className={styles.systemId__value}>{integration.system_id}</div>
              </div>
              <div className={styles.leftPane__content}>
                <TroubleshootingModalSearch
                  onSearchInputChange={this.onSearchInputChange}
                  onSearchClick={this.onSearchClick}
                  value={currentSearch}
                />
                <TroubleshootingModalActions
                  refresh={this.refresh}
                  onFilterChange={this.onFilterChange}
                />
                {getRequestStatus !== reqStatusType.FAILURE && (
                  <BamTable
                    id="troubleshootingModalTable"
                    data={data}
                    columns={columns}
                    emptyStateMessage="No matches in the last 7 days"
                    getTableProps={this.getTableProps}
                    getTrProps={(state, rowInfo) => this.getTrProps(rowInfo)}
                    loading={isLoading}
                    className={styles.table}
                    onColumnsChanged={this.onColumnsChanged}
                    onRowClicked={this.setSelectedRow}
                  />
                )}
              </div>
            </div>
            <div className={styles.rightPane}>
              {selectedRow !== null ? (
                <TroubleshootingPayload log={selectedRow} search={currentSearch} />
              ) : (
                <BamEmptyMessage header="Select an item">To view Raw Data</BamEmptyMessage>
              )}
            </div>
          </div>
        </BamModal>
      </ThemeProvider>
    );
  }
}

TroubleshootingModal.propTypes = {
  integration: integrationPropType.isRequired,
  troubleshootingData: troubleshootingDataPropType.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getLogs: PropTypes.func.isRequired,
  getLogsMetadata: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  const troubleshootingData = selectors.getTroubleshooting(state);
  const isLoading = selectors.getIsLoading(state);
  return {
    organization,
    user,
    troubleshootingData,
    isLoading,
  };
};

const mapDispatchToProps = {
  getLogs: actions.getLogs,
  getLogsMetadata: actions.getLogsMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(TroubleshootingModal));
