angular.module('bigpanda').directive('correlationSecondSettings', correlationSecondSettings);

function correlationSecondSettings() {
  return {
    restrict: 'E',
    scope: {
      item: '=',
    },
    templateUrl: 'settings/correlation/utils/form_components/correlation_form_secondary_settings',
    bindToController: true,
    controllerAs: 'secondSettingsVm',
    controller: angular.noop,
  };
}
