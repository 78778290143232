import pluralize from 'pluralize';
import extend from 'lodash/extend';
import last from 'lodash/last';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';

import { getMainProperties } from 'common/modules/entities/EntityTitleGenerator';
import { findTagByType, getTagValueFromEntities } from 'common/modules/tags/TagUtils';
import { isCrossSource } from './SourceBreakdownGenerator';

const DEFAULT_FIELD_NAMES = { singular: 'item', plural: 'items' };
const MAX_SUBTITLE_ITEMS = 10;

// eslint-disable-next-line import/prefer-default-export
export function getTitle(integrations, widthCalculator, incident, options, folder) {
  const folderEntities = getEntities(incident, folder);
  const entities = folderEntities.length ? folderEntities : incident.entities;

  const hasCorrelation = entities.length > 1 && !incident.manual_correlation;
  return hasCorrelation
    ? getCorrelationTitle(incident, entities, options, widthCalculator, integrations)
    : getDefaultTitle(entities);
}

function getEntities(incident, folder) {
  return folder
    ? incident.entities.filter(({ is_active: active }) => active === (folder !== 'resolved'))
    : incident.entities;
}

function getDefaultTitle(filteredEntities) {
  if (!filteredEntities.length) {
    return undefined;
  }

  const data = { type: 'no-correlation' };

  const { primary, secondary } = getMainProperties(filteredEntities);

  const titles = fetchTagsFromEntity(filteredEntities, primary);
  const subtitles = (secondary.length && fetchTagsFromEntity(filteredEntities, secondary)) || [];

  const primaryTagName = primary.length > 1 ? 'items' : formatName(primary[0].name, titles.length);
  const secondaryTagName =
    (secondary.length &&
      (secondary.length > 1 ? 'items' : formatName(secondary[0].name, subtitles.length))) ||
    '';

  data.noSubtitle = primary.length > 1 && secondary.length > 1;

  const titleValues = normalizeTitleLength(titles);
  const subtitleValues = normalizeTitleLength(subtitles);

  data.title = {
    key: extractKeyName(primaryTagName, titleValues),
    value: titleValues,
  };

  data.subtitle = {
    key: extractKeyName(secondaryTagName, subtitleValues),
    value: subtitleValues,
  };

  return data;
}

function getIncidentTitleConfig(incident = {}) {
  const log = getLessAggressiveMatcher(incident.matchers_log, 'offset');
  return getLessAggressiveMatcher(log.matchers, 'window');
}

function getLessAggressiveMatcher(matchers, type) {
  return last(sortBy(matchers, type));
}

function extractTitleFromFields(fields = [], tags) {
  let muteValue;

  fields.find((field) => {
    const fieldName = field.name || field;
    let tag = findTagByType(tags, fieldName);
    muteValue = tag && tag.value;
    if (!muteValue && fieldName && typeof fieldName === 'string' && fieldName.indexOf('_') === 0) {
      tag = findTagByType(tags, fieldName.replace('_', ''));
      muteValue = tag && tag.value;
    }

    return muteValue;
  });

  return muteValue;
}

const normalizeTitleLength = (items) =>
  items.length > MAX_SUBTITLE_ITEMS
    ? [...items.slice(0, MAX_SUBTITLE_ITEMS), `And ${items.length - MAX_SUBTITLE_ITEMS} more...`]
    : items;

const fetchTagsFromEntity = (filteredEntities, type) => {
  const filtered = filteredEntities.reduce((acc, entity) => {
    const titleFromFields = extractTitleFromFields(type, entity.tags);
    return titleFromFields ? [...acc, titleFromFields] : acc;
  }, []);

  return Array.from(new Set(filtered));
};

function fixLodashInPrefix(name) {
  if (name.indexOf('_') === 0) {
    return name.substr(1);
  }
  return name;
}

function formatName(name, length) {
  const formattedName = fixLodashInPrefix(name);

  if (length) {
    return pluralize(formattedName, length);
  }

  return {
    singular: formattedName,
    plural: pluralize(formattedName, 2),
  };
}

function extractKeyName(key = '', values) {
  if (typeof key === 'string') {
    return key;
  }

  return values.length > 1 ? key.plural : key.singular;
}

function getCorrelationTitle(incident, filteredEntities, options, widthCalculator, integrations) {
  const title = [];
  const leftover = [];
  let totalWidth = 0;

  const tagsConfig = getIncidentTitleConfig(incident);

  calcTitles(tagsConfig.tags).forEach((item, index) => {
    const { width, dynamicWidth, titleWidthFits } = calcTitleSectionWidth(
      index,
      options,
      item.value,
      item.key,
      totalWidth,
      widthCalculator
    );

    const itemWithNoShrink = extend(item, {
      noShrink: dynamicWidth < options.widthLimit.dynamicMin,
    });

    if (titleWidthFits || index === 0) {
      totalWidth += width;
      title.push(itemWithNoShrink);
    } else {
      leftover.push(itemWithNoShrink);
    }
  });

  return {
    type: 'correlation',
    title: title,
    subtitle: resolveSubtitle(filteredEntities, {
      tags: tagsConfig,
      items: leftover,
      crossSource: isCrossSource(integrations, incident.entities),
      itemType: {
        singular: 'more correlator',
        plural: 'more correlators',
      },
    }),
  };
}

function calcTitles(tags) {
  const tagsArray = Array.isArray(tags) ? tags : convertTagsFromOldFormat(tags);
  return tagsArray.map(({ type, value }) => ({
    key: { type: 'static', str: `${type}: ` },
    value: { type: 'dynamic', str: value },
  }));
}

function calcTitleSectionWidth(
  index,
  options,
  dynamicObject,
  staticObject,
  totalWidth,
  widthCalculator
) {
  const trueDynamicWidth = widthCalculator.getWidth(options.fontData, dynamicObject.str);
  const separatorWidth = index !== 0 ? widthCalculator.getWidth(options.fontData, '&bull;') : 0;
  const staticWidth = widthCalculator.getWidth(options.fontData, staticObject.str);
  const dynamicWidth = Math.min(trueDynamicWidth, options.widthLimit.dynamicMin);
  const width = separatorWidth + staticWidth + dynamicWidth;
  const titleWidthFits = totalWidth + width <= options.widthLimit.max;
  return { width, dynamicWidth, titleWidthFits };
}

// Due to an issue with mongo (not supporting key with '.') we needed to
// change the file format to an array but still support the old format
// This will map old format { key: value } to { type: key, value: tags[key] }
function convertTagsFromOldFormat(tags) {
  return Object.entries(tags).map(([key, value]) => ({ type: key, value: value }));
}

function resolveSubtitle(filteredEntities, options = {}) {
  let subtitleData;

  if (options.items && options.items.length) {
    subtitleData = handleMoreCorrelatorsSubtitle(options.itemType, options.items);
  } else if (options.crossSource) {
    subtitleData = handleCrossSourceSubtitle(options.tags, filteredEntities);
  } else {
    subtitleData = handleCorrelationSubtitle(options.tags, filteredEntities);
  }

  return {
    ...subtitleData,
    window: getTimeWindow(filteredEntities, 'start'),
    createdWindow: getTimeWindow(filteredEntities, 'created'),
  };
}

function handleCrossSourceSubtitle(tags, entities) {
  let mainProp;
  let item;

  const subtitleEntities = entities.slice(0, MAX_SUBTITLE_ITEMS);
  const items = [];
  const mainProps = [];
  subtitleEntities.forEach((entity) => {
    const { primary_property: primary, secondary_property: secondary } = entity;
    mainProp = isPrimaryInTitle(primary, tags) && secondary ? secondary : primary;
    item = mainProp && getTagValueFromEntities([entity], mainProp);
    if (item && items.indexOf(item[0]) < 0) {
      mainProps.push(mainProp);
      items.push(item[0]);
    }
  });

  const itemTypes = Array.from(new Set(mainProps.map(fixLodashInPrefix)));
  const itemType = itemTypes.length > 1 ? DEFAULT_FIELD_NAMES : formatName(itemTypes[0]);

  return {
    count: entities.length > MAX_SUBTITLE_ITEMS ? entities.length : items.length,
    itemType: items.length > 1 ? itemType.plural : itemType.singular,
    items: items,
  };
}

function handleCorrelationSubtitle(tags, entities) {
  const subtitleEntities = entities.slice(0, MAX_SUBTITLE_ITEMS);
  const { primary, secondary } = getMainProperties(subtitleEntities);

  let primaryInTitle;
  if (tags && primary.length === 1) {
    const valueToCheck = primary[0].name || primary[0];
    primaryInTitle = isPrimaryInTitle(valueToCheck, tags);
  }

  const mainTag = primaryInTitle && secondary && secondary.length ? secondary : primary;
  const itemType = getSubtitleType(entities, mainTag);
  const items = [];

  subtitleEntities.forEach((entity) => {
    const mainProp = mainTag[0].name || mainTag[0];
    const item = mainProp && getTagValueFromEntities([entity], mainProp);
    if (item && items.indexOf(item[0]) < 0) {
      items.push(item[0]);
    }
  });

  return {
    count: entities.length > MAX_SUBTITLE_ITEMS ? entities.length : items.length,
    itemType: items.length > 1 ? itemType.plural : itemType.singular,
    items: items,
  };
}

function handleMoreCorrelatorsSubtitle(itemType, items) {
  const itemsAsString = items.map(({ key, value }) => key.str + value.str);
  const pluralizedItemType = items.length > 1 ? itemType.plural : itemType.singular;
  return {
    count: items.length,
    itemType: pluralizedItemType,
    items: itemsAsString,
  };
}

function getSubtitleType(entities, fields) {
  if (fields.length > 1) {
    return DEFAULT_FIELD_NAMES;
  }

  const fieldName = fields[0].name || fields[0];
  return formatName(fieldName);
}

function isPrimaryInTitle(primaryProperty, tags) {
  const name = primaryProperty.name || primaryProperty;
  const nameOptions = formatName(name);

  const tagsArray = Array.isArray(tags.tags) ? tags.tags : convertTagsFromOldFormat(tags.tags);
  return tagsArray.some(({ type }) => type === nameOptions.singular || type === nameOptions.plural);
}

function getTimeWindow(filteredEntities, entityPropertyToGenerateTimeWindowBy) {
  const lastEntity = maxBy(filteredEntities, entityPropertyToGenerateTimeWindowBy);
  const firstEntity = minBy(filteredEntities, entityPropertyToGenerateTimeWindowBy);
  if (!firstEntity || !lastEntity) {
    return null;
  }
  return Math.ceil(
    (lastEntity[entityPropertyToGenerateTimeWindowBy] -
      firstEntity[entityPropertyToGenerateTimeWindowBy]) /
      60
  );
}
