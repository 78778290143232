import get from 'lodash/get';

import { path } from './constants';

const selectors = {
  getSSOCurrentState: (state) => get(state, path).ssoState,
  isConfigurationSent: (state) => get(state, path).configurationSent,
  getOrganizationName: (state) => get(state, 'user.organization.name'),
};

export default selectors;
