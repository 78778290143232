import { react2angular } from 'react2angular';
import { EditIntegrationButton } from '../../../../../workspaces/apps/incident-feed/src/components/integration-view/edit-integration-button';

angular
  .module('bigpanda')
  .component(
    'editIntegrationButton',
    react2angular(EditIntegrationButton, [
      'autoResolveConfig',
      'integration',
      'saveIntegrationName',
      'saveAutoResolveConfig',
    ])
  );
