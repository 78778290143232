import pull from 'lodash/pull';
angular.module('bigpanda').service('Permissions', Permissions);

function Permissions(PermPermissionStore, EnvironmentsService) {
  let permissionsArr = [];
  let granularPermissionsObj = {};
  let hasBasicPermissions = false;
  let globalAuthEnabled = false;

  this.clearPermissions = clearPermissions;
  this.isPermitted = isPermitted;
  this.hasFullAccess = hasFullAccess;
  this.get = get;
  this.init = init;
  this.checkBasicPermissions = checkBasicPermissions;

  this.checkGranularPermission = checkGranularPermission;
  EnvironmentsService.setPermissions(this);

  return this;

  function init(userPermissions, granularPermissions, featureToggles) {
    permissionsArr = userPermissions;
    granularPermissionsObj = granularPermissions;
    PermPermissionStore.defineManyPermissions(userPermissions, () => true);

    globalAuthEnabled = featureToggles && featureToggles.enable_global_auth;

    return EnvironmentsService.get().then((envs) => {
      hasBasicPermissions = !!envs.length;
    });
  }

  function get() {
    return permissionsArr;
  }

  function checkBasicPermissions() {
    return hasBasicPermissions;
  }

  function isPermitted(permission) {
    return permissionsArr.indexOf(permission) > -1;
  }

  function hasFullAccess(permission) {
    return (
      this.isPermitted(`${permission}@create`) &&
      this.isPermitted(`${permission}@read`) &&
      this.isPermitted(`${permission}@update`) &&
      this.isPermitted(`${permission}@delete`)
    );
  }

  function clearPermissions(permissionName) {
    if (!permissionName) {
      permissionsArr = [];
      PermPermissionStore.clearStore();
    } else {
      pull(permissionsArr, permissionName);
      PermPermissionStore.removePermissionDefinition(permissionName);
    }
  }

  function checkGranularExplicit(actionType, resourceType, resourceId) {
    return (
      granularPermissionsObj[resourceType] &&
      granularPermissionsObj[resourceType][resourceId] &&
      granularPermissionsObj[resourceType][resourceId].length &&
      granularPermissionsObj[resourceType][resourceId].includes(actionType)
    );
  }

  function checkGranularPermission(actionType, resourceType, resourceId) {
    const permitted =
      // check if globally permitted (wildcards)
      this.isPermitted(`${resourceType}@${actionType}`) ||
      // Enable if feature toggle is not on
      !globalAuthEnabled ||
      // or explicitly for this resource ID
      checkGranularExplicit(actionType, resourceType, resourceId);

    return Boolean(permitted);
  }
}
