import get from 'lodash/get';

angular.module('bigpanda').directive('resolveButton', resolveButton);

function resolveButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '^^checkedIncidentsState',
    },
    scope: {
      incidentId: '<',
      isActionsV2: '<',
    },
    templateUrl: 'overview/incidents/list/widgets/resolve_button/resolve_button',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($scope, $state, $timeout, IncidentsService, pubSubService) {
    const vm = this;

    vm.context = {};
    vm.resolveTooltip = 'Resolve incident';
    vm.resolveTestId = `Resolve_list_button`;

    vm.$onInit = onInit;
    vm.$onChanges = updateContext;

    function onInit() {
      updateContext().then(() => {
        vm.checkedIncidentsState.subscribe($scope, {
          storeUpdated: () => getIncident(vm.context),
        });
        pubSubService.on('Incidents.focusedIncidentUpdated', updateResolveProps, $scope);
      });
    }

    function updateContext() {
      vm.context = {
        incidentId: vm.incidentId,
        incident: {},
      };

      return getIncident(vm.context);
    }

    function getIncident(context) {
      return IncidentsService.getIncidentById(context.incidentId).then((incident) => {
        context.incident = incident;
        updateResolveProps(context);
      });
    }

    function updateResolveProps(context) {
      vm.resolveProps = {
        resolved: context.incident && context.incident.status === 'ok',
        'bp-disabled': isDisabled(),
      };

      vm.resolveTooltip = isDisabled() ? null : 'Resolve incident';
    }

    function isDisabled() {
      return (
        (vm.context.incident && !vm.context.incident.is_active) ||
        vm.checkedIncidentsState.isAnythingChecked()
      );
    }

    vm.resolve = () => {
      $timeout(() => {
        $state.go(
          'app.overview.incidents.incident-info.resolve',
          {
            incidentId: vm.context.incident.id,
          },
          { notify: false }
        );
      }, 10);
    };
  }
}
