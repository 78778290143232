import pick from 'lodash/pick';
import includes from 'lodash/includes';
import spread from 'lodash/spread';
import debounce from 'lodash/debounce';
import { connect, disconnect } from '@giantmachines/redux-websocket';

angular.module('bigpanda').controller('EnvironmentGroupsCtrl', EnvironmentGroupsCtrl);

function EnvironmentGroupsCtrl(
  $log,
  $q,
  $state,
  $scope,
  $interval,
  previousStateService,
  pubSubService,
  notificationService,
  stateService,
  EnvironmentGroupsStore,
  PERMISSIONS_NAMES,
  EnvironmentGroupConstants,
  EnvironmentsCountersStore,
  EnvironmentsService,
  CustomizationsStore,
  EnvironmentGroupsService,
  EnvironmentsUtils,
  localStorageService,
  $ngRedux,
  AuthService,
  Config,
  UserFeatureTogglesService
) {
  const vm = this;
  $scope.$on('$destroy', function () {
    $interval.cancel(groupsMetricsInterval);
  });
  vm.$onInit = onInit;

  vm.isHoozeEnabled = false;

  vm.favoriteEnvironmentIds = [];
  vm.groups = [];
  vm.environments = [];
  vm.selectedEnvironmentId = stateService.getSelectedEnvironmentId();
  vm.selectedGroupId = null;
  vm.EnvironmentGroupConstants = EnvironmentGroupConstants;

  vm.newEnvironment = newEnvironment;
  vm.refreshSelectedEnvironment = refreshSelectedEnvironment;
  vm.refreshGroups = refreshGroups;
  vm.newGroup = newGroup;
  vm.updateFilter = updateFilter;

  vm.createPermissions = [PERMISSIONS_NAMES.environments.create];
  // vm.groupUpdatePermissions = [PERMISSIONS_NAMES.groups.edit];
  // vm.groupDeletePermissions = [PERMISSIONS_NAMES.groups.delete];
  //
  let groupsMetricsInterval = null;

  let incidnetsToUpdateEnvironmentCounter = [];
  const updateCounters = debounce(() => {
    EnvironmentsCountersStore.updateCounters(
      incidnetsToUpdateEnvironmentCounter,
      vm.selectedEnvironmentId
    );
    incidnetsToUpdateEnvironmentCounter = [];
  }, 777);

  function subscribeToAllTheThings() {
    pubSubService.on('Environment.Created', () => refreshGroups(false), $scope);
    pubSubService.on('Environment.Updated', () => refreshGroups(false), $scope);
    pubSubService.on('Environment.Deleted', () => refreshGroups(false), $scope);
    pubSubService.on('Environment.Selected', () => selectedEnvrionment(), $scope);
    pubSubService.on('streamConnector.reconnected', () => refreshGroups(true), $scope);
    pubSubService.on(
      'incident.newData',
      (event, incident) => {
        incidnetsToUpdateEnvironmentCounter.push(incident);
        updateCounters();
      },
      $scope
    );

    const subscriberCallBack = {
      storeUpdated: () => refreshGroups(false),
    };

    EnvironmentGroupsStore.subscribe($scope, subscriberCallBack);
    CustomizationsStore.subscribe(subscriberCallBack, $scope);

    groupsMetricsInterval = $interval(sendGroupMetrics, 60 * 1000);

    vm.isHoozeEnabled = UserFeatureTogglesService.getToggle('hooze_for_ws');

    if (vm.isHoozeEnabled) {
      $ngRedux.dispatch(
        connect(getEnvironmentWebSocketUrl(vm.selectedEnvironmentId), Config.webSocketsPrefix)
      );
    }
  }

  function sendGroupMetrics() {
    if (!vm.groups || !vm.groups.length) {
      return;
    }

    const totalRenderedEnvironmentsCount = vm.groups.reduce(
      (acc, group) => acc + group.environmentIds.length,
      0
    );
  }

  function onInit() {
    return refreshGroups(true).then(subscribeToAllTheThings);
  }

  function refreshGroups(forceReload) {
    const groupsPromise = EnvironmentGroupsStore.getEnvironmentGroups(forceReload);
    const environmentsPromise = EnvironmentsService.get(forceReload).then((envs) =>
      envs.map((env) => ({
        ...env,
        ...{
          nameLower: env.name.toLowerCase(),
        },
      }))
    );
    const customizationsPromise = CustomizationsStore.getCustomizations(forceReload).catch(
      handleGetCustomizationsError
    );

    // We don't wait for this promise because we're not dependent on it, and it can run in parallel.
    refreshCounters(forceReload);

    return $q.all([environmentsPromise, customizationsPromise, groupsPromise]).then(spread(reload));
  }

  function reload(environments, customizations, groups) {
    if (environments && environments.length) {
      if (vm.filterString) {
        const lowerFilter = vm.filterString.toLowerCase();
        vm.environments = environments.filter((e) => e.nameLower.includes(lowerFilter));
      } else {
        vm.environments = environments;
      }
    }

    if (
      !vm.selectedEnvironmentId ||
      (!vm.environments.find((env) => env && env._id === vm.selectedEnvironmentId) &&
        !vm.filterString)
    ) {
      goToDefaultEnvironment();
    }

    applyCustomizations(customizations);
    buildGroupsObject(groups);
  }

  function goToDefaultEnvironment() {
    return EnvironmentsUtils.getDefaultEnv(vm.environments).then((env) => {
      $state.go(
        'app.overview.incidents.list',
        { environment: env._id, folder: 'active' },
        { reload: false }
      );
      const environmentGroup = vm.groups.find((group) => group.environmentIds.includes(env._id));
      refreshSelectedEnvironment(environmentGroup.id, env._id);
    });
  }

  function handleGetCustomizationsError(err) {
    $log.error(`Failed getting user customizations: ${err}`);
    notificationService.error('Error getting user customizations', { error: err });
    return $q.resolve({
      environments: {
        navigation_list: {
          favorites: [],
          hide_non_favorites: false,
        },
      },
    });
  }

  function refreshCounters(forceReload) {
    // This is important because when entering this controller, it might be after a change in groups/envs/counters,
    // and we need to make sure the store is updated (and as a result fires updates that should affect other components).
    EnvironmentsCountersStore.refreshCounters(forceReload);
  }

  function applyCustomizations(customizations) {
    vm.favoriteEnvironmentIds = vm.environments
      .filter((env) => includes(customizations.environments.navigation_list.favorites, env._id))
      .map((env) => env._id);
  }

  function buildGroupsObject(groups) {
    let environmentIdsWithGroups = [];

    vm.groups = [];

    groups.forEach((group) => {
      environmentIdsWithGroups.push(...group.environmentIds);

      // A group shall be displayed only if:
      // 1) It is not empty
      // 2) It is empty, but only because there are really no groups associated with it (as opposed to a group that's empty because
      // the user doesn't have permissions to its environments)
      const matchingEnvironments = vm.environments.filter((environment) =>
        group.environmentIds.includes(environment._id)
      );
      if (matchingEnvironments.length || !group.environmentIds.length) {
        vm.groups.push(group);
      }
    });

    addDefaultGroup(environmentIdsWithGroups);
    addFavoritesGroup();
    sortGroups();
    syncGroupsWithLocalStorage();

    const selectedGroup = vm.groups.find((group) =>
      group.environmentIds.includes(vm.selectedEnvironmentId)
    );
    vm.selectedGroupId = selectedGroup ? selectedGroup.id : null;
  }

  function sortGroups() {
    vm.groups.sort((a, b) => {
      let result = 0;

      if (a.isFavorite) {
        result = -1;
      } else if (b.isFavorite) {
        result = 1;
      } else if (a.environmentIds.length !== 0 && b.environmentIds.length === 0) {
        result = -1;
      } else if (a.environmentIds.length === 0 && b.environmentIds.length !== 0) {
        result = 1;
      } else if (a.id === EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_ID) {
        result = 1;
      } else if (b.id === EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_ID) {
        result = -1;
      } else if (a.nameLower > b.nameLower) {
        result = 1;
      } else if (b.nameLower > a.nameLower) {
        result = -1;
      }

      return result;
    });
  }

  function addFavoritesGroup() {
    if (!vm.favoriteEnvironmentIds.length || vm.filterString) {
      return;
    }

    const favoriteGroup = {
      id: EnvironmentGroupConstants.FAVORITES_GROUP_OPTION_ID,
      environmentIds: vm.favoriteEnvironmentIds,
      name: EnvironmentGroupConstants.FAVORITES_GROUP_OPTION_NAME,
      isFavorite: true,
    };

    vm.groups.unshift(favoriteGroup);
  }

  function newGroup() {
    EnvironmentGroupsStore.getEnvironmentGroups(true).then((environmentGroups) => {
      EnvironmentGroupsService.showEditModal(environmentGroups).then((newEnvironmentGroup) => {
        pubSubService.broadcast('environment.group.created', newEnvironmentGroup, 'New Group CTA');
      });
    });
  }

  function addDefaultGroup(environmentIdsWithGroups) {
    const environmentIds = vm.environments
      .filter((environment) => !includes(environmentIdsWithGroups, environment._id))
      .map((environment) => environment._id);

    // Don't show default group if it's empty.
    if (environmentIds && environmentIds.length) {
      vm.groups.push({
        id: EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_ID,
        environmentIds: environmentIds,
        name: EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_NAME,
      });
    }
  }

  function syncGroupsWithLocalStorage() {
    if (vm.filterString) return;

    let groupsState = localStorageService.get(EnvironmentGroupConstants.GROUPS_LOCAL_STORAGE_KEY);
    if (groupsState) {
      groupsState = pick(
        groupsState,
        vm.groups.map((group) => group.id)
      );
      localStorageService.set(EnvironmentGroupConstants.GROUPS_LOCAL_STORAGE_KEY, groupsState);
    }
  }

  function getEnvironmentWebSocketUrl(environmentId) {
    return `wss://${Config.webSocketBaseUrl.split('//')[1]}/ws/environments/${environmentId}`;
  }

  function refreshSelectedEnvironment(environmentGroupId, environmentId) {
    vm.selectedGroupId = environmentGroupId;

    const previous = `${vm.selectedEnvironmentId}`;
    vm.selectedEnvironmentId = environmentId;

    if (vm.isHoozeEnabled) {
      if (previous) {
        $ngRedux.dispatch(disconnect(Config.webSocketsPrefix));
      }
      $ngRedux.dispatch(
        connect(getEnvironmentWebSocketUrl(environmentId), Config.webSocketsPrefix)
      );
    }
  }

  function newEnvironment() {
    previousStateService.saveAndGoto($state.current, $state.params, 'app.environments');
  }

  const throttledRefreshGroup = debounce(() => refreshGroups(false), 600, {
    leading: false,
    trailing: true,
  });

  function updateFilter() {
    return throttledRefreshGroup();
  }

  function selectedEnvrionment() {
    vm.selectedEnvironmentId = stateService.getSelectedEnvironmentId();
    const selectedGroup = vm.groups.find((group) =>
      group.environmentIds.includes(vm.selectedEnvironmentId)
    );
    vm.selectedGroupId = selectedGroup ? selectedGroup.id : null;
  }
}
