angular.module('bigpanda').constant('EnvironmentGroupConstants', {
  ADD_NEW_GROUP_OPTION_ID: 'AddNewGroup',
  ADD_NEW_GROUP_OPTION_NAME: '+ Create a New Group',
  DEFAULT_GROUP_OPTION_ID: 'DefaultGroup',
  DEFAULT_GROUP_OPTION_NAME: 'General',
  DEFAULT_GROUP_OPTION_SUBTITLE: '(Default)',
  FAVORITES_GROUP_OPTION_ID: 'Starred',
  FAVORITES_GROUP_OPTION_NAME: 'Starred',
  GROUPS_LOCAL_STORAGE_KEY: 'groupsState',
  ALL_ENVIRONMENT_NAME: 'All',
});
