import PropTypes from 'prop-types';
import { path as prevPath } from '../constants';

export const name = 'usersList';
export const path = `${prevPath}.${name}`;

export const ANY_OPTION_VALUE = '-1';

export const CREATED_SORT_MODE = {
  text: 'Created',
  field: 'createdAt',
  ascending: false,
};
export const LAST_SEEN_SORT_MODE = {
  text: 'Last seen',
  field: 'lastLoginAt',
  ascending: false,
};
export const USER_NAME_SORT_MODE = {
  text: 'Full name',
  field: 'name',
  ascending: true,
  isDefault: true,
};
export const SORT_MODES = [USER_NAME_SORT_MODE, CREATED_SORT_MODE, LAST_SEEN_SORT_MODE];

export const DEFAULT_SORT_MODE = USER_NAME_SORT_MODE;

export const SortShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool,
});
