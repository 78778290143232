angular
  .module('bp.widgets')
  .directive(
    'bpSearchInput',
    ($timeout, SortingStore, LabelsStore, UserFeatureTogglesService, pubSubService) => {
      return {
        restrict: 'E',
        templateUrl: 'widgets/bp_search_input/bp_search_input',
        controllerAs: 'inputCtrl',
        bindToController: true,
        replace: true,
        scope: {
          searchValue: '=ngModel',
          placeholder: '@',
          search: '&',
          enableEmpty: '@',
          tooltipTemplate: '@',
          notification: '=',
          hideSearchArea: '=',
          disabled: '=',
        },
        controller: controller,
        link: link,
      };

      function controller($scope) {
        const vm = this;

        vm.$onInit = () => {
          vm.searchInstance = vm.search();
          vm.disableInput = () =>
            (!vm.searchValue && (!vm.enableEmpty || !vm.prevValue)) || vm.isSortedByPriority();
          vm.disableSearch = () => vm.isSortedByPriority() || vm.disabled;
          vm.disableButton = () => vm.isEmpty(vm.searchValue) && vm.isEmpty(vm.prevValue);
          vm.focus = () => {
            vm.inputFocused = true;
          };
          vm.unfocus = () => {
            vm.inputFocused = false;
          };

          vm.getTooltip = () => {
            if (vm.disabled) {
              return 'Search is currently unavailable for environments configured with incident tags';
            }

            if (vm.isSortedByPriority()) {
              return vm.disableSearchTooltip;
            }

            return '';
          };

          vm.isSortedByPriority = () => vm.sortBy === SortingStore.options.label_priority;
          vm.isEmpty = (value) => (value ? value.trim().length === 0 : true);

          vm.searchEmpty = () => {
            vm.notification = null;
            vm.searchValue = '';
            vm.unfocus();
            vm.submit();
          };

          vm.updateSortBy = () => {
            SortingStore.getSortBy().then((sortBy) => (vm.sortBy = sortBy));
          };
          vm.updatePriorityLabel = () => {
            const priorityTag = LabelsStore.getPriorityDefinition();
            vm.priorityTagName = priorityTag.name || 'Priority';
            vm.priorityTagNameLowerCase = vm.priorityTagName.toLowerCase();
            vm.disableSearchTooltip = `Filtering is unavailable if the incident feed is sorted by ${vm.priorityTagNameLowerCase}`;
          };

          vm.updateSortBy();
          vm.updatePriorityLabel();
          SortingStore.subscribe({ storeUpdated: vm.updateSortBy }, $scope);
          pubSubService.on('incidentTagsDefinitions.updated', vm.updatePriorityLabel, $scope);
        };
      }

      function link($scope, $element, $attributes, $controller) {
        $controller.submit = (searchObject) => {
          $controller.error = null;
          $controller.notification = null;
          $controller.prevValue = $controller.searchValue;
          $timeout(() => {
            try {
              $controller.searchInstance(searchObject);
            } catch (e) {
              $controller.error = e;
            }
          });
        };
      }
    }
  );
