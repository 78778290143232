import {
  IUseForm, Select, TextField, VBox,
} from '@bp/kung-fu';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import FTSelectors from '../../../../../../../app/react/user/feature_toggles/selectors';
import { getInterpolatedText } from '../../../../../alert-enrichment/src/helpers/get-interpolated-text';
import { AUTO_RESOLVE_TIME_OPTIONS, AutoResolveConfig, DONT_RESOLVE_OPTION } from '../constants';
import dictionary from '../dictionary';
import { Container, DropdownText, InputContainer } from './EditIntegrationModal.style';

interface IProps {
  register: IUseForm<AutoResolveConfig>['register'];
  integrationConfig:{ type:string };
}

const INTEGRATION_NAME_MAX_LENGTH = 35;

export const EditIntegrationModal = ({ register, integrationConfig }: IProps) => {
  // eslint-disable-next-line max-len
  const enableDontAutoResolve: boolean = useSelector(FTSelectors.getFeatureToggles)?.featureToggles?.frontier_enable_auto_resolve_dont_resolve || false;
  // eslint-disable-next-line max-len
  const autoResolveOptions = useMemo(() => [...AUTO_RESOLVE_TIME_OPTIONS, ...(enableDontAutoResolve ? DONT_RESOLVE_OPTION : [])], [enableDontAutoResolve]);

  const validateIntegrationName = (value: string): string => {
    if (value.length > INTEGRATION_NAME_MAX_LENGTH) {
      return getInterpolatedText(dictionary.edit_integration_modal_name_field_max_length_error, {
        max_val: INTEGRATION_NAME_MAX_LENGTH.toString(),
      });
    }

    return '';
  };

  return (
    <Container>

      <InputContainer>
        <TextField
          {...register('integrationConfigName', {
            validation: validateIntegrationName,
            type: 'change',
          })}
          label={dictionary.edit_integration_modal_name_field_label}
          width="400px"
          required
          isErrorAbsolute={false}
        />
      </InputContainer>
      { integrationConfig.type && integrationConfig.type === 'alert' ? (
        <VBox>
          <Select
            {...register('autoResolveTime')}
            options={autoResolveOptions}
            label={dictionary.edit_integration_modal_auto_resolve_field_label}
            helpTooltipProps={{
              text: dictionary.edit_integration_modal_auto_resolve_field_tooltip,
            }}
            width="400px"
          />
          <DropdownText>
            {dictionary.edit_integration_modal_auto_resolve_field_static_notice}
          </DropdownText>

        </VBox>
      ) : null }
    </Container>
  );
};
