import { groupBy, map, pipe, sortBy, uniqWith } from 'lodash/fp';
import { ANY_LABEL_FILTER_ITEM } from './constants';
import { getLabelInternalValue } from '../utils';

const filterItemsBySelectedValue = (selectedValue, items) => {
  if (!items || !selectedValue || selectedValue === ANY_LABEL_FILTER_ITEM.value) {
    return items;
  }
  return items.filter((item) => {
    const itemLabels = (item.labels || []).map(getLabelInternalValue);
    return itemLabels.includes(selectedValue);
  });
};

const convertLabelsToFilterItems = (labels) =>
  pipe(
    groupBy(({ group }) => group.toLowerCase()),
    map(uniqWith(({ value: v1 }, { value: v2 }) => v1.toLowerCase() === v2.toLowerCase())),
    map(sortBy('value')),
    map((values) => ({
      display: values[0].group,
      value: values[0].group,
      items: values.map(({ group, value }) => ({
        display: value,
        value: getLabelInternalValue({ group, value }),
      })),
    })),
    sortBy('group')
  )(labels);

export { convertLabelsToFilterItems, filterItemsBySelectedValue };
