import get from 'lodash/get';
import toPairs from 'lodash/toPairs';
import forEach from 'lodash/forEach';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import { datadogRum } from '@datadog/browser-rum';

angular.module('bigpanda').factory('bootstrap', [
  '$window',
  '$log',
  '$q',
  'PersonalSettingsStore',
  'pubSubService',
  'AuthService',
  'StreamConnector',
  'UserFeatureTogglesService',
  'Permissions',
  'Config',
  // to load (not dependant on user)
  'version',
  'rateLimitingService',
  boostrap,
]);

function boostrap(
  $window,
  $log,
  $q,
  PersonalSettingsStore,
  pubSubService,
  AuthService,
  StreamConnector,
  UserFeatureTogglesService,
  Permissions,
  Config
) {
  const modulesToLoadAfterUserLoaded = [
    'integrationGuideService',
    'IntercomLogic',
    'ExpiredService',
    'EnvironmentsService',
  ];

  const modulesToLoadAfterFeatureToggle = {
    // Format: 'toggle_name': [ 'ServiceName' ]
    // Format: 'toggle_name': [ 'ServiceName' ]
    // NOTE: You can use globs as described in the user.properties.service.js for the 'toggle_name'
  };

  // Local initializers
  const readyState = {
    userLoaded: false,
    backendDependantOnUserServiceLoaded: false,
    streamConnected: false,
    permissionsLoaded: false,
  };

  const bootstrap = {
    /** Keep data on original state in a temp variable so we can transition back once bootstrapping is finished * */
    completed: false,

    isReady: () => reduce(values(readyState), (result, item) => result && item),

    boot: () => {
      authenticateUser()
        .then(initStreamConnector)
        .then(loadUser, (err) => {
          const errorMsg = err ? err.message : '';
          $log.error(`Error while bootstraping ${errorMsg}`);
          AuthService.resetUser();
        });
    },
    getInjector: () => angular.element($window.document).injector(),
  };

  pubSubService.on('streamConnector.connected', () => {
    readyState.streamConnected = true;
    checkReadiness();
  });

  function authenticateUser() {
    if (AuthService.isAuth()) {
      return $q.when();
    }

    if (!!AuthService.getCookieToken() && !AuthService.getUrlAccessToken()) {
      AuthService.loadCredentials();
      return $q.when();
    }

    return AuthService.authenticateByToken();
  }

  function checkReadiness() {
    if (bootstrap.isReady() && !bootstrap.completed) {
      bootstrap.completed = true;
      pubSubService.broadcast('bootstrap.finished');
    }
  }

  function initBackendModulesDependsOnUser() {
    loadModules(modulesToLoadAfterUserLoaded);
    readyState.backendDependantOnUserServiceLoaded = true;
  }

  function loadModules(modules) {
    const injector = bootstrap.getInjector();

    // separate for tests
    if (injector) {
      forEach(modules, (moduleToLoad) => {
        if (injector.has(moduleToLoad)) {
          injector.get(moduleToLoad);
        }
      });
    }
  }

  function loadModulesByToggle(modulesByToggle) {
    forEach(toPairs(modulesByToggle), (pair) => {
      const toggle = pair[0];
      const modules = pair[1];

      if (UserFeatureTogglesService.getToggle(toggle)) {
        loadModules(modules);
      }
    });
  }

  function loadUser() {
    PersonalSettingsStore.getUser().then(
      (user) => {
        readyState.userLoaded = true;

        initializeDataDog(user);
        loadUserHelperServices(user);
        initBackendModulesDependsOnUser();
        loadWithToggles();
        initUserPermission(user);
        checkReadiness();
        sendDataToHeap(user);
      },
      (err) => {
        $log.error(`Unexpected error, cannot get user: ${err && (err.data || err.message)}`);
        AuthService.resetUser();
      }
    );
  }

  function initUserPermission(userData) {
    Permissions.init(
      userData.permissions,
      userData.granular_permissions,
      userData.featureToggles
    ).then(() => {
      readyState.permissionsLoaded = true;
      checkReadiness();
    });
  }

  function loadUserHelperServices(userData) {
    UserFeatureTogglesService.init(userData);
  }

  function loadWithToggles() {
    loadModulesByToggle(modulesToLoadAfterFeatureToggle);
  }

  function initStreamConnector() {
    return StreamConnector.connect();
  }

  function initializeDataDog(user) {
    const ddRUM = get(Config, 'ddRUM', {});
    const disableSessionRecording = get(
      user,
      ['featureToggles', 'frontier_disable_datadog_session_recording'],
      false
    );
    const trackInteractions =
      get(user, ['organization', 'config', 'record'], false) && !disableSessionRecording;
    let shouldActivateRum = true;
    try {
      const isCypress = navigator.userAgent.toLowerCase().indexOf('cypress') > -1;
      shouldActivateRum = isCypress ? Math.random() < ddRUM.botsRecordingPercentage : true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    if (
      shouldActivateRum &&
      !disableSessionRecording &&
      ddRUM.env &&
      ddRUM.env.indexOf('dev') === -1
    ) {
      datadogRum.init({
        applicationId: ddRUM.applicationId,
        clientToken: ddRUM.clientToken,
        site: 'datadoghq.eu',
        service: 'frontier',
        env: ddRUM.env,
        version: get(Config, 'version'),
        sampleRate: ddRUM.sampleRate,
        premiumSampleRate: ddRUM.premiumSampleRate,
        trackInteractions,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.setUser({
        id: user._id,
        email: user.username,
        org: user.organization.name,
        roles: user.roles.map((role) => role.key),
        permissions: user.permissions,
        open_feature_toggles: Object.entries(user.featureToggles)
          .filter(([_, value]) => !!value)
          .map(([name]) => name),
      });
      if (!disableSessionRecording) {
        datadogRum.startSessionReplayRecording();
      }
    }
  }

  function sendDataToHeap(user) {
    if (user.measure && window.hasOwnProperty('heap')) {
      window.heap.identify(user.username);
      window.heap.addUserProperties({
        id: user.username,
        email: user.username,
        account_name: user.organization.name,
        account_id: user.organization.name,
        roles: JSON.stringify(user.roles),
        permissions: JSON.stringify(user.permissions),
        feature_toggles: JSON.stringify(user.featureToggles),
      });
    }
  }

  window.addEventListener('error', (e) => {
    if (
      e &&
      (e.message === 'ResizeObserver loop limit exceeded' ||
        e.message === 'ResizeObserver loop completed with undelivered notifications.')
    ) {
      e.stopImmediatePropagation();
    }
  });

  return bootstrap;
}
