angular.module('bigpanda').filter('bpRegexHighlight', bpRegexHighlight);

function bpRegexHighlight($sce) {
  return (text, phrase) => {
    let result = text;

    if (phrase) {
      // Modify phrase regex so that it will capture before and after the original capture group
      const newRegex = phrase.replace(/^(.*)(\([^?][^)]+\))(.*)$/, '($1)$2($3)');
      const regex = new RegExp(`${newRegex}`, 'i');
      result = text.replace(regex, '$1<span class="highlighted">$2</span>$3');
    }

    return $sce.trustAsHtml(result);
  };
}
