import { BamModal } from '@bp/bam';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ModalTriggerButton from '../../../../../app/react/layout/settings/components/ModalTriggerButton';
import FTSelectors from '../../../../../app/react/user/feature_toggles/selectors';
import { AlertFilter } from '../types/AlertFilter';
import { TriggerProps } from '../types/TriggerProps';
import { AlertFilterFormBam } from './AlertFilterFormBam';
import { AlertFilterFormWrapper } from './AlertFilterFormWrapper';

interface IProps {
  triggerProps: TriggerProps;
  title: string;
  isNew?: boolean;
  isDuplicate?: boolean;
  filter?: AlertFilter;
  titleIcon: string | JSX.Element;
}

export function AlertFilterModal({
  triggerProps,
  title,
  isNew,
  isDuplicate,
  filter,
  titleIcon,
}: IProps): JSX.Element {
  const [modalIsOpen, setModalOpen] = useState(false);

  let formRef;
  const handleClose = (): void => formRef.close();
  const useKF: boolean = useSelector(FTSelectors.getFeatureToggles)?.featureToggles?.frontier_alert_filtering_use_kf || false;

  if (useKF) {
    return (
      <>
        {modalIsOpen
          && (
            <AlertFilterFormWrapper
              filter={filter}
              setModalOpen={setModalOpen}
              title={title}
              isNew={isNew}
              isDuplicate={isDuplicate}
            />
          )}
        <ModalTriggerButton {...triggerProps} onClick={(): void => setModalOpen(true)} />
      </>
    );
  }

  return (
    <BamModal
      ref={(r): void => { formRef = r; }}
      trigger={<ModalTriggerButton {...triggerProps} />}
      title={title}
      formMode
      autoFocus
      titleIcon={titleIcon}
      docsLink="https://docs.bigpanda.io/docs/alert-filtering"
      fullscreen
      data-testid="filter modal"
    >
      <AlertFilterFormBam close={handleClose} filter={filter} isNew={isNew} isDuplicate={isDuplicate} />
    </BamModal>
  );
}
