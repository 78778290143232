angular
  .module('bigpanda')
  .controller('IntegrationInstructionsController', IntegrationInstructionsController);

function IntegrationInstructionsController(
  $scope,
  UserFeatureTogglesService,
  stateService,
  UserIntegrationsStore,
  AvailableIntegrationsStore,
  Config,
  PersonalSettingsStore
) {
  const vm = this;
  const sourceSystem = stateService.getSelectedIntegrationId();

  $scope.updatePage('integrations');

  $scope.mailDomain = Config.mailDomain;
  $scope.webApiBaseUrl = Config.webApiBaseUrl;
  $scope.baseUrl = Config.baseUrl;
  $scope.integrationsApiBaseUrl = Config.integrationsApiBaseUrl;
  $scope.currentIntegration = AvailableIntegrationsStore.getAvailableIntegration(sourceSystem);

  const integrationPromise = UserIntegrationsStore.getIntegration(sourceSystem).then(
    (integration) => {
      $scope.appKey = (integration && integration.stream_id) || '<your app key>';
    }
  );
  /* --- All Ready - Start --- */

  PersonalSettingsStore.getUser().then((user) => {
    integrationPromise.then(() => {
      $scope.token = user.organization.api_token;
      $scope.shortToken = user.organization.api_token.substring(
        user.organization.api_token.length - 4
      );
      $scope.organization = user.organization.name.toLowerCase();
      $scope.sed = `sed s/{{TOKEN}}/${$scope.token}/ | sed s/{{APP_KEY}}/${$scope.appKey}/`;
      $scope.urlParams = `access_token=${$scope.token}&app_key=${$scope.appKey}`;
      $scope.email = `${$scope.organization}-${$scope.shortToken}-${$scope.appKey}@${$scope.mailDomain}`;
    });
  });
}
