import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';

const formatDisplayedPermissions = (permissions) => {
  if (!permissions) {
    return [];
  }
  const formattedPermissionHash = permissions.reduce((acc, permission) => {
    const resourceType = permission.resource_type;
    return {
      ...acc,
      ...{
        [resourceType]: permission.actions.reduce(
          (innerAcc, action) => ({
            ...innerAcc,
            [action]: true,
          }),
          acc[resourceType] || {}
        ),
      },
    };
  }, {});

  const readPermissions = ['Read'];
  const fullAccessPermissions = [...readPermissions, 'Create', 'Update', 'Delete'].sort();
  const formattedPermissionStrings = Object.entries(formattedPermissionHash).map(
    ([resourceType, actionsHash]) => {
      if (isEqual(Object.keys(actionsHash).sort(), fullAccessPermissions)) {
        return `${capitalize(resourceType)}_Full_Access`;
      }
      if (isEqual(Object.keys(actionsHash).sort(), readPermissions)) {
        return `${capitalize(resourceType)}_Read`;
      }
      return 'Nonstandard_Permission';
    }
  );
  return formattedPermissionStrings;
};

const formatDisplayedEnvironments = (envPermissions) => {
  if (!envPermissions) {
    return [];
  }
  const specificEnvsHash = envPermissions
    .filter((permission) => permission.resource_id)
    .reduce((acc, permission) => {
      const envName = permission.environmentName;
      return {
        ...acc,
        ...{
          [envName]: permission.actions.reduce(
            (innerAcc, action) => ({
              ...innerAcc,
              [action]: true,
            }),
            acc[envName] || {}
          ),
        },
      };
    }, {});

  const envReadPermissions = ['Read', 'Action@Read'].sort();
  const envIncidentPermissions = [
    'Read',
    'Action@Read',
    'Action@Update',
    'Action@Create',
    'Action@Delete',
  ].sort();
  const specificEnvsIncidentActionsPermissions = [
    ...envReadPermissions,
    'Action@Create',
    'Action@Update',
    'Action@Delete',
  ].sort();

  const formattedSpecificEnvStrings = Object.entries(specificEnvsHash).map(
    ([envName, actionsHash]) => {
      if (isEqual(Object.keys(actionsHash).sort(), specificEnvsIncidentActionsPermissions)) {
        return `${envName}_Incident_Actions`;
      }
      if (isEqual(Object.keys(actionsHash).sort(), envReadPermissions)) {
        return `${envName}_Read`;
      }
      return 'Nonstandard_Permission';
    }
  );

  const globalEnvHash = envPermissions
    .filter((permission) => !permission.resource_id)
    .reduce(
      (acc, permission) => ({
        ...acc,
        ...permission.actions.reduce(
          (innerAcc, action) => ({
            ...innerAcc,
            [action]: true,
          }),
          acc
        ),
      }),
      {}
    );

  const globalEnvFullAccessPermissions = [
    ...specificEnvsIncidentActionsPermissions,
    'Create',
    'Update',
    'Delete',
  ].sort();
  const formattedGlobalEnvStrings = [];

  if (isEqual(Object.keys(globalEnvHash).sort(), globalEnvFullAccessPermissions)) {
    formattedGlobalEnvStrings.push('Environments_Full_Access');
  }
  if (isEqual(Object.keys(globalEnvHash).sort(), envReadPermissions)) {
    formattedGlobalEnvStrings.push('Environments_Read');
  }
  if (isEqual(Object.keys(globalEnvHash).sort(), envIncidentPermissions)) {
    formattedGlobalEnvStrings.push('Environments_Incident_Actions');
  }
  if (!formattedGlobalEnvStrings.length && Object.keys(globalEnvHash).length) {
    formattedGlobalEnvStrings.push('Nonstandard_Permission');
  }

  return [...formattedGlobalEnvStrings, ...formattedSpecificEnvStrings];
};

export { formatDisplayedPermissions, formatDisplayedEnvironments };
