import styled from 'styled-components';
import * as colors from '@bp/pastel/colors';

const WelcomePanel = styled.div`
  width: 400px;
  padding: 25px 15px 40px;
  border-radius: 5px;
  background-color: ${colors.bp_white};
  font-size: 12px;
  border: 1px solid ${colors.bp_gray_03};
  margin-top: 67px;
`;

export default WelcomePanel;
