import { path } from './constants';
import { generateBaseSettingsActionPaths } from '../../redux.utils';

const actionTypes = generateBaseSettingsActionPaths(path);

actionTypes.LOAD_ROLES = `${path}.LOAD_ROLES`;
actionTypes.LOAD_ROLES_SUCCESS = `${path}.LOAD_ROLES_SUCCESS`;
actionTypes.LOAD_ENVIRONMENTS = `${path}.LOAD_ENVIRONMENTS`;
actionTypes.LOAD_ENVIRONMENTS_SUCCESS = `${path}.LOAD_ENVIRONMENTS_SUCCESS`;
actionTypes.LOAD_ROLES_FAILURE = `${path}.LOAD_ROLES_FAILURE`;
actionTypes.CREATE_OR_UPDATE_ROLE = `${path}.CREATE_OR_UPDATE_ROLE`;
actionTypes.DELETE_ROLE = `${path}.DELETE_ROLE`;
actionTypes.LOAD_PERMISSIONS = `${path}.LOAD_PERMISSIONS`;
actionTypes.LOAD_PERMISSIONS_SUCCESS = `${path}.LOAD_PERMISSIONS_SUCCESS`;
actionTypes.LOAD_PERMISSIONS_AND_ENVIRONMENTS = `${path}.LOAD_PERMISSIONS_AND_ENVIRONMENTS`;
actionTypes.LOAD_PERMISSIONS_AND_ENVIRONMENTS_SUCCESS = `${path}.LOAD_PERMISSIONS_AND_ENVIRONMENTS_SUCCESS`;
actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS = `${path}.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS`;
actionTypes.UPDATE_FILTERS_DROPDOWNS = `${path}.UPDATE_FILTERS_DROPDOWNS`;

export default actionTypes;
