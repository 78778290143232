import get from 'lodash/get';
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from './consts';

const UseUserManagement = () => {
  const dispatch = useDispatch();
  const loadUser = () => dispatch(actions.loadUser());
  const updateUser = (user) => dispatch(actions.updateUser(user));
  const personalSettings = useSelector((state) => get(state, selectors.personalSettings));

  useLayoutEffect(() => {
    if (!personalSettings.user) {
      loadUser();
    }
  }, []);

  return { ...personalSettings, updateUser };
};

export default UseUserManagement;
