import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React from 'react';
import { Tooltip } from '@bp/kung-fu';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Label } from 'semantic-ui-react';
import memoize from 'memoize-one';
import { Anchorize } from 'react/common/components/';
import { onKeyboardInteractionEvent } from 'react/helpers/on-keyboard-interaction-event';
import { path } from '../../constants';
import styles from './IncidentTagsGridMultiValueCell.scss';
import IncidentTagsGridMultiValueEditor from './IncidentTagsGridMultiValueEditor';
import { validateTextValue } from '../../utils';
import { constOffset } from '../IncidentTagsGridCell';

class IncidentTagsGridMultiValueCell extends React.Component {
  state = {
    isOverflowingX: false,
  };

  componentDidMount() {
    this.isEllipsisNeeded();
  }

  componentDidUpdate(prevProps, prevState) {
    const { gridWidth, truncate, initialValue } = this.props;
    if (
      truncate !== prevProps.truncate ||
      gridWidth !== prevProps.gridWidth ||
      !isEqual(sortBy(initialValue.tag_value), sortBy(prevProps.initialValue.tag_value))
    ) {
      this.isEllipsisNeeded();
    }
  }

  onSelectValueChange = (values) => {
    this.props.onValueChange(this.getLabels(values));
  };

  onKeyboardInteraction = memoize(onKeyboardInteractionEvent);

  getLabels = (creatableSelectValues) => creatableSelectValues.map((value) => value.label);

  isEllipsisNeeded() {
    const { handleIsValueEllipsis } = this.props;
    if (!isNil(this.watchModeRef.current)) {
      const { scrollWidth, offsetWidth } = this.watchModeRef.current;
      this.setState({ isOverflowingX: scrollWidth > offsetWidth + constOffset });
      handleIsValueEllipsis(scrollWidth, offsetWidth);
    }
  }

  normalizeToCreatableSelectValues = (values) =>
    values.map((value) => ({
      value: value,
      label: value.toString(),
      __isNew__: true,
    }));
  // eslint-disable-next-line react/sort-comp
  renderLabel = (text, index) => {
    const { truncate } = this.props;
    const contentClasses = cn(styles['multi-value-label'], styles['watch-mode'], styles.label, {
      [styles.truncate]: truncate,
    });
    return (
      <Label
        key={`${text}_${index}`}
        className={contentClasses}
        content={
          <Anchorize
            className={styles['tag-value']}
            input={text.toString().replace(/<(?!a)(?!\/a)/g, '&lt;')}
          />
        }
      />
    );
  };

  handleOnBlur = () => {
    const { onSubmit, closeEditor, initialValue, currValue } = this.props;
    const tagValue = get(initialValue, 'tag_value');
    !isEqual(sortBy(currValue), sortBy(tagValue)) ? onSubmit() : closeEditor();
  };

  renderAllLabelsTooltip = () => {
    const { initialValue } = this.props;
    const values = initialValue.tag_value || [];
    return (
      <ul className={styles.list_of_values}>
        {values.map((value, index) => (
          <li key={`${value}_${index.toString()}`}>{value.toString()}</li>
        ))}
      </ul>
    );
  };

  watchModeRef = React.createRef();

  render() {
    const { isOverflowingX } = this.state;
    const {
      editMode,
      openEditor,
      error,
      truncate,
      hasEditPermission,
      onValidationChange,
      initialValue,
      currValue,
    } = this.props;
    const tagValue = get(initialValue, 'tag_value');
    const initialSelectValues =
      tagValue !== undefined && tagValue !== null
        ? this.normalizeToCreatableSelectValues(tagValue)
        : [];
    const currSelectValues =
      currValue && currValue.length ? this.normalizeToCreatableSelectValues(currValue) : [];
    const hasValue = initialSelectValues.length;
    const shouldDisplayEllipsisButton = isOverflowingX && !editMode && truncate;
    const contentWrapper = cn(styles.contentWrapper);
    const contentClasses = cn(styles.value, {
      [styles['value-ellipsis']]: truncate,
      [styles['value-empty']]: !hasValue,
      [styles['value-readOnly']]: !hasEditPermission,
      [styles['value-ellipsisButton']]: shouldDisplayEllipsisButton,
    });
    const ellipsisButtonClasses = cn(styles.ellipsis_button);

    return editMode ? (
      <IncidentTagsGridMultiValueEditor
        onBlur={this.handleOnBlur}
        error={error}
        values={currSelectValues}
        onValidationChange={onValidationChange}
        validate={validateTextValue}
        onChange={this.onSelectValueChange}
      />
    ) : (
      <div className={contentWrapper}>
        <div
          ref={this.watchModeRef}
          className={contentClasses}
          onClick={openEditor}
          onKeyPress={this.onKeyboardInteraction(openEditor)}
          role="button"
          tabIndex={0}
        >
          <Tooltip
            isActive={hasValue}
            text={this.renderAllLabelsTooltip()}
            placement="bottom-start"
          >
            {hasValue ? (
              initialSelectValues.map((value, index) => this.renderLabel(value.label, index))
            ) : (
              <span> None </span>
            )}
          </Tooltip>
        </div>
        {shouldDisplayEllipsisButton && <div className={ellipsisButtonClasses}>...</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: get(state, 'user.organization'),
  user: get(state, 'layout.topbar.user'),
  tagsData: get(state, path),
});

IncidentTagsGridMultiValueCell.propTypes = {
  store: PropTypes.object.isRequired, //eslint-disable-line
  editMode: PropTypes.bool.isRequired,
  error: PropTypes.string,
  openEditor: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  closeEditor: PropTypes.func.isRequired,
  truncate: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  initialValue: PropTypes.shape({ tag_value: PropTypes.string }),
  handleIsValueEllipsis: PropTypes.func.isRequired,
  currValue: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/no-unused-prop-types
  gridWidth: PropTypes.string.isRequired,
};

IncidentTagsGridMultiValueCell.defaultProps = {
  error: null,
  initialValue: { tag_value: undefined },
  currValue: [],
};

export default connect(mapStateToProps, null)(hot(module)(IncidentTagsGridMultiValueCell));
