import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { BamTile } from '@bp/bam';
import BpTimeSince from 'common/utils/BpTimeSince';
import { connect } from 'react-redux';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import IncidentLabelsActions from '../../../actions/IncidentLabelsActions';
import {
  incidentTagsNameByType,
  tagDefinitionIconByType,
  incidentTagTypes,
} from '../../../constants';
import styles from './ListItem.scss';
import { CREATED_SORT_MODE, SortShape } from '../../constants';

const generateFirstRow = (incidentLabel, sortMode) => {
  const title = incidentLabel.name;
  const { updated_at: updatedAt, created_at: createdTime } = incidentLabel;
  const textParts = [];
  switch (incidentLabel.type) {
    case incidentTagTypes.PRIORITY:
      {
        const {
          config: { ordered_list },
        } = incidentLabel;
        const itemNames = [];
        ordered_list.forEach((item) => itemNames.push(item.display_name));
        textParts.push({
          value: itemNames.join(', '),
        });
      }
      break;
    case incidentTagTypes.TEXT:
    case incidentTagTypes.MULTI_VALUE:
      break;
  }

  const incidentLabelTime = sortMode === CREATED_SORT_MODE ? createdTime : updatedAt || createdTime;

  return {
    title,
    textParts,
    time: {
      title: sortMode === CREATED_SORT_MODE ? 'Created' : 'Updated',
      timestamp: BpTimeSince(incidentLabelTime),
    },
  };
};

const generateSecondRow = (incidentLabel) => {
  const { type, description } = incidentLabel;
  const textParts = [];
  textParts.push({ value: incidentTagsNameByType(type) });
  if (description) {
    textParts.push({ value: description });
  }
  const customPart = (
    <div className={styles.tagIcon}>
      <i className={`${tagDefinitionIconByType(type)}`} />
    </div>
  );
  const actionsBundle = <IncidentLabelsActions incidentLabel={incidentLabel} />;

  return {
    textParts,
    customParts: [customPart],
    actionsBundle,
  };
};

const ListItem = ({ incidentLabel, sortMode, ...props }) => {
  const handleClick = () => {
    window.location.href = `/#/app/settings/incident-enrichment/${incidentLabel.id}`;
  };

  return (
    <BamTile
      className="incident-label-tile"
      bamStatus={incidentLabel.active ? 'ok' : 'off'}
      firstRow={generateFirstRow(incidentLabel, sortMode)}
      secondRow={generateSecondRow(incidentLabel)}
      onClick={handleClick}
      inactive={!incidentLabel.active}
      {...props}
    />
  );
};

ListItem.propTypes = {
  incidentLabel: PropTypes.shape({ id: PropTypes.string, active: PropTypes.bool }).isRequired,
  sortMode: SortShape.isRequired,
  featureToggles: PropTypes.shape({
    react_grouped_settings_menu: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  featureToggles: featureTogglesSelectors.getFeatureToggles(state),
});

export default connect(mapStateToProps, null)(hot(module)(ListItem));
