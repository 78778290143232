import { BamNotificationStatusOptions, bamUnNotify } from '@bp/bam';
import BPQL from '@bp/bpql';
import get from 'lodash/get';
import map from 'lodash/map';
import { batchActions } from 'redux-batched-actions';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';

import { SYSTEM_GENERATED_USER_ID } from '../../../../app/common/constants';
import * as api from '../../../../app/common/endpoints/alert-enrichments';
import completeOnboardingStep from '../../../../app/common/endpoints/onboarding';
import { actionTypes as contactsActionType } from '../../../../app/react/common/contacts';
import { loadContacts } from '../../../../app/react/common/contacts/actions';
import { loadCorrelationConfig } from '../../../../app/react/common/correlation_config/actions';
import { actionTypes as enrichedUsersActionType } from '../../../../app/react/common/enriched_users';
import { loadEnrichedUsers } from '../../../../app/react/common/enriched_users/actions';
import {
  actionTypes as integrationsActionTypes,
  selectors as integrationsSelectors,
} from '../../../../app/react/common/integrations';
import { loadIntegrations } from '../../../../app/react/common/integrations/actions';
import { multipleSourcesToSourceNames } from '../../../../app/react/common/integrations/enricher';
import { getAllIntegrationOptions } from '../../../../app/react/common/integrations/utils';
import {
  showFailureMessage,
  showInProgressMessage,
  showSuccessMessage,
} from '../../../../app/react/layout/settings/feedback';
import { alertTagUrl, DROPDOWN_FILTERS_ALL_ITEM_OPTION, TAG_TYPE } from '../../../../app/react/modules/settings/alert_enrichment/constants';
import { isTagActive, tagTypeString } from '../../../../app/react/modules/settings/alert_enrichment/utils';
import { selectors as FTSelectors } from '../../../../app/react/user/feature_toggles';
import { splitCondition } from '../../alert-filtering/src/utils/splitCondition';
import dic from '../../onboarding/src/dictionary';
import { shouldAllowOnboarding } from '../../onboarding/src/helpers/shouldAllowOnboarding';
import actions from './actions';
import actionTypes from './actionTypes';
import getUniqueTags from './components/PreviewTags/utils/getUniqueTags';

const showSuccessMessageForTags = (
  title,
  action,
  status = BamNotificationStatusOptions.ACTION_SUCCESS,
  toasterId = null,
) => {
  if (toasterId) bamUnNotify(toasterId);
  setTimeout(() => showSuccessMessage(title, action, status), 500);
};
const showFailureMessageForTags = (action, toasterId = null) => {
  if (toasterId) bamUnNotify(toasterId);
  setTimeout(() => showFailureMessage('Alert Tag', action), 500);
};
const showFailureMessageForPrevew = (action) => showFailureMessage('Rules Preview', action);
const showInProgressMessageForTags = (
  title,
  action,
  status = BamNotificationStatusOptions.IN_PROGRESS,
) => showInProgressMessage(title, action, status);

const useTagOpRoutes = (state) => get(FTSelectors.getFeatureToggles(state), 'alert_enrichment_use_tag_operations', false);

const useAllTagsPreview = (state) => get(FTSelectors.getFeatureToggles(state), 'frontier_expose_current_preview_tag_values', false);

const getUserName = (userId, enrichedUsers, contacts) => {
  if (userId === SYSTEM_GENERATED_USER_ID) {
    return undefined;
  }
  const matchingUser = enrichedUsers.find((user) => user.id === userId)
    || contacts.find(({ bigpanda_user_id }) => bigpanda_user_id === userId);
  return matchingUser ? matchingUser.name : 'N/A';
};

const processQuery = (enrichment, integrations) => {
  const enrichmentItem = { ...enrichment };
  const sourceSystemsStrArr = enrichmentItem.config.selected_source_system?.split(',') || [];
  const sourceObj = splitCondition({ bpql: enrichmentItem.when, sourceSystems: sourceSystemsStrArr });
  if (sourceObj) {
    enrichmentItem.sourceObj = sourceObj;
    enrichmentItem.pureQuery = sourceObj.pureQuery;
    if (sourceObj.pureQuery && Object.keys(sourceObj.pureQuery).length > 0) {
      enrichmentItem.displayQuery = BPQL.reverse(sourceObj.pureQuery);
    }
    enrichmentItem.sourceSystems = multipleSourcesToSourceNames(sourceObj, integrations);
  }
  return enrichmentItem;
};

const parseTagQuery = (tags, integrations, enrichedUsers, contacts, useTagOps) => tags.map((tag, index) => {
  const enrichments = tag.enrichments.map((enrichment) => processQuery(enrichment, integrations));
  const sourceSystems = enrichments.flatMap(({ sourceSystems: s }) => s);
  const createdByUserName = getUserName(tag.created_by, enrichedUsers, contacts);
  const updatedByUserName = getUserName(tag.updated_by, enrichedUsers, contacts);
  return {
    ...tag,
    id: tag.name,
    order: index + 1,
    active: isTagActive(tag, useTagOps),
    type: tagTypeString(tag),
    enrichments,
    sourceSystems,
    created_by: createdByUserName,
    updated_by: updatedByUserName,
  };
});

const loadEnrichmentTags = (action$) => action$.ofType(actionTypes.LOAD_ENRICHMENT_TAGS).map(() => batchActions([
  loadCorrelationConfig(),
  loadEnrichedUsers(),
  loadContacts(),
  loadIntegrations(),
  actions.loadReservedTags(),
  actions.loadTags(),
]));

const loadReservedTags = (action$) => action$.ofType(actionTypes.LOAD_INTERNAL_TAGS).mergeMap(async () => {
  try {
    const payload = await api.getReservedTags();
    return actions.loadReservedTagsSuccess({
      reservedTags: payload.reservedTags.map((tag) => ({ ...tag, isReserved: true })),
      reservedTagsNames: payload.reservedTags.map(({ name }) => name),
    });
  } catch (ex) {
    showFailureMessageForTags('load');
    return undefined;
  }
});

const loadTags = (action$) => action$.ofType(actionTypes.LOAD_TAGS).mergeMap(async () => {
  try {
    const payload = await api.getTags();
    return actions.loadTagsSuccess(payload);
  } catch (ex) {
    showFailureMessageForTags('load');
    return undefined;
  }
});

const removeAlertTag = (action$, state$) => action$
  .ofType(actionTypes.REMOVE_ALERT_TAG)
  .mergeMap(async ({ payload: { rules: rules_ids, tagName } }) => {
    const toasterId = showInProgressMessageForTags('Alert Tag', 'Deleting');
    try {
      if (useTagOpRoutes(state$.getState())) {
        await api.deleteTag({ tagName });
      } else {
        await api.deleteRules({ rules_ids, tagName });
      }
      showSuccessMessageForTags('Alert Tag', 'deleted', undefined, toasterId);
    } catch (_) {
      showFailureMessageForTags('delete', toasterId);
    }
    return actions.loadTags();
  });

const updateTagsOrder = (action$, state$) => action$.ofType(actionTypes.UPDATE_ENRICHMENT_TAGS_ORDER).mergeMap(async ({ payload: tags }) => {
  const toasterId = showInProgressMessageForTags('Alert Tags order', 'Updating');
  try {
    const tagsNames = tags.map(({ name }) => name);
    const useTagOps = useTagOpRoutes(state$.getState());

    await api.updateTagsOrder({ alert_tags_order: tagsNames, useTagOps });
    showSuccessMessageForTags('Alert Tags order', 'updated', undefined, toasterId);
  } catch (e) {
    showFailureMessageForTags('order updated');
  }
  return actions.loadTags();
});

const parseTags = (action$, state$) => Observable.combineLatest(
  action$.ofType(actionTypes.LOAD_TAGS_SUCCESS),
  action$.ofType(integrationsActionTypes.LOAD_INTEGRATIONS_SUCCESS),
  action$.ofType(enrichedUsersActionType.LOAD_ENRICHED_USERS_SUCCESS),
  action$.ofType(contactsActionType.LOAD_CONTACTS_SUCCESS),
  (
    { payload: tags },
    { payload: integrations },
    { payload: enrichedUsers },
    { payload: contacts },
  ) => {
    try {
      const useTagOps = useTagOpRoutes(state$.getState());
      const enrichmentTags = parseTagQuery(
        tags,
        getAllIntegrationOptions(integrations),
        enrichedUsers,
        contacts,
        useTagOps,
      );
      return actions.loadEnrichmentTagsSuccess(enrichmentTags);
    } catch (ex) {
      showFailureMessageForTags('load');
      return undefined;
    }
  },
);

const filtersWithDefaultOption = (key, filters = []) => [
  DROPDOWN_FILTERS_ALL_ITEM_OPTION[key],
  ...filters,
];

const createFiltersDropDownItems = (action$, state$) => action$.ofType(actionTypes.LOAD_ENRICHMENT_TAGS_SUCCESS).map(() => {
  const typeItemsArray = filtersWithDefaultOption(
    'type',
    Object.entries(TAG_TYPE).map(([text, value]) => ({ key: value, text, value })),
  );

  const statusItemsArray = filtersWithDefaultOption('status', [
    { key: 'active', text: 'Active', value: 'true' },
    { key: 'inactive', text: 'Inactive', value: 'false' },
  ]);

  const sourceSystemObjects = integrationsSelectors
    .getAllIntegrationOptions(state$.getState())
    .map(({ value, display }) => ({ key: value, text: display, value }));

  const sourceItemsArray = filtersWithDefaultOption('source', [
    { text: 'All Systems', value: '*', key: '*' },
    ...sourceSystemObjects,
  ]);

  return actions.createFiltersDropDownItemsSuccess({
    source: sourceItemsArray,
    type: typeItemsArray,
    status: statusItemsArray,
  });
});

const createAlertTag = (action$, state$) => action$
  .ofType(actionTypes.CREATE_ALERT_TAG)
  .mergeMap(async ({ payload: { rules, metadata } }) => {
    const toasterId = showInProgressMessageForTags('Alert Tag', 'Creating');
    try {
      if (useTagOpRoutes(state$.getState())) {
        await api.postTag({ enrichments: rules, metadata, description: metadata.description });
      } else {
        await api.postRules({ rules });
      }
      if (shouldAllowOnboarding(FTSelectors.getFeatureToggles(state$.getState()))) {
        await completeOnboardingStep(dic.onboardingSteps.alert_enrichment);
      }
      showSuccessMessageForTags('Alert Tag', 'created', undefined, toasterId);
      if (
        window.location.href.includes('alert-enrichment')
          || window.location.href.includes('alert-correlation')
      ) {
        window.location.href = alertTagUrl(metadata.tagName);
      }
    } catch (_) {
      // TODO: make sure we display the correct message, if its duplication mode - AP-2140
      showFailureMessageForTags('create', toasterId);
    }
    return actions.loadTags();
  });

const updateAlertTagMetadata = (action$) => action$
  .ofType(actionTypes.UPDATE_ALERT_TAG_METADATA)
  .mergeMap(async ({ payload: { metadata } }) => {
    const toasterId = showInProgressMessageForTags('Alert Tag', 'Updating');
    try {
      await api.patchTagMetadata(metadata);
      showSuccessMessageForTags('Alert Tag', 'updated', undefined, toasterId);
    } catch (_) {
      showFailureMessageForTags('update', toasterId);
    }
    return actions.loadTags();
  });

const postRules = ({
  tagName, enrichments, useTagOps, description,
}) => (!useTagOps
  ? api.postRules({ rules: enrichments })
  : api.postTagRules({ metadata: { tagName }, enrichments, description }));

const updateRules = ({
  tagName, enrichments, useTagOps, description, active,
}) => (!useTagOps
  ? api.updateRules({ rules: enrichments })
  : api.patchTagRules({
    tagName, enrichments, description, active,
  }));

const deleteRules = ({ tagName, enrichments_ids, useTagOps }) => (!useTagOps
  ? api.deleteRules({ rules_ids: enrichments_ids })
  : api.deleteTagRules({ metadata: { tagName }, enrichments_ids }));

const updateAlertTag = (action$, state$) => action$
  .ofType(actionTypes.UPDATE_ALERT_TAG)
  .mergeMap(
    async ({
      payload: {
        tagName,
        oldTagName,
        rules: { toCreate = [], toUpdate = [], toDelete = [] } = {},
        rulesIds: enrichment_order = [],
        metadata: { description, active } = {},
      },
    }) => {
      const toasterId = showInProgressMessageForTags('Alert Tag', 'Updating');
      const useTagOps = useTagOpRoutes(state$.getState());

      try {
        if (toCreate.length) {
          const newRules = await postRules({
            tagName,
            enrichments: toCreate,
            useTagOps,
            description,
          });
          get(newRules, 'success', []).forEach(({ data: { id }, index }) => {
            const ruleIdIndex = enrichment_order.indexOf(toCreate[index].id);
            enrichment_order[ruleIdIndex] = id; // eslint-disable-line no-param-reassign
          });
        }

        const areChangesRequested = toUpdate.length || typeof description === 'string' || typeof active !== 'undefined';

        const updatePromise = areChangesRequested
          ? [updateRules({
            tagName, enrichments: toUpdate, useTagOps, description, active,
          })]
          : [];
        const deletePromise = toDelete.length
          ? [deleteRules({ tagName, enrichments_ids: toDelete, useTagOps })]
          : [];
        await Promise.all([...updatePromise, ...deletePromise]);
        if (enrichment_order.length) {
          await api.updateRulesOrder({
            tagName,
            enrichment_order,
            useTagOps,
          });
        }
        showSuccessMessageForTags('Alert Tag', 'updated', undefined, toasterId);
        if (
          window.location.href === window.location.origin + alertTagUrl(tagName)
            || window.location.href === window.location.origin + alertTagUrl(oldTagName)
        ) {
          window.location.href = alertTagUrl(tagName);
        }
      } catch (_) {
        showFailureMessageForTags('update', toasterId);
      }
      return actions.loadTags();
    },
  );

const updateRulesOrder = (action$, state$) => action$
  .ofType(actionTypes.UPDATE_RULES_ORDER)
  .mergeMap(async ({ payload: { tagName, rulesIds: enrichment_order } }) => {
    const useTagOps = useTagOpRoutes(state$.getState());

    try {
      await api.updateRulesOrder({ tagName, enrichment_order, useTagOps });
      showSuccessMessageForTags('Alert Tag', 'updated');
    } catch (_) {
      showFailureMessageForTags('update');
    }
    return actions.loadEnrichmentTags();
  });

const loadPreviewAlerts = (action$, state$) => action$
  .ofType(actionTypes.LOAD_PREVIEW_ALERTS)
  .mergeMap(async ({
    payload: {
      destination, timeframe, rules, isNew, rawRule,
    },
  }) => {
    try {
      const previewAlerts = await api.getPreview({
        data: { timeframe, rules },
        params: { use_preview_all: false, tag_name: destination },
      });
      const eventsToProcess = useAllTagsPreview(state$.getState())
        ? previewAlerts.events
        : previewAlerts;
      const events = map(
        eventsToProcess,
        ({ event, template_tags_and_values: referenceTags = [] }) => ({ ...event, referenceTags }),
      );
      const uniquePreviewTags = getUniqueTags(events, rawRule, destination);
      return actions.loadPreviewAlertsSuccess({
        isNew, destination, events, uniquePreviewTags,
      });
    } catch (ex) {
      showFailureMessageForPrevew('load');
      return actions.loadPreviewAlertsFailure();
    }
  });

export default combineEpics(
  loadEnrichmentTags,
  loadReservedTags,
  loadTags,
  parseTags,
  createFiltersDropDownItems,
  createAlertTag,
  removeAlertTag,
  updateRulesOrder,
  updateTagsOrder,
  loadPreviewAlerts,
  updateAlertTag,
  updateAlertTagMetadata,
);
