import PropTypes from 'prop-types';

const LABEL_SEPARATOR = {
  DISPLAYED: ': ',
  INTERNAL: ':',
};
const TITLE = 'Labels';

const LabelPropType = PropTypes.shape({
  group: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export { LabelPropType, LABEL_SEPARATOR, TITLE };
