angular.module('bigpanda.reports').directive('periscopeTab', periscopeTab);

function periscopeTab() {
  return {
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      periscopeUrl: '<',
    },
    templateUrl: 'reports/periscope_tab',
    controller: function periscopeTabController($element, $sce) {
      const vm = this;

      vm.$onChanges = onChanges;

      function onChanges() {
        vm.trustedPeriscopeUrl = $sce.trustAsResourceUrl(vm.periscopeUrl || '');
      }
    },
  };
}
