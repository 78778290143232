angular.module('bigpanda.backend.services').service('SnoozeBackendService', SnoozeBackendService);

function SnoozeBackendService($http) {
  function snooze(envId, ids, snoozeConfig) {
    return $http
      .post(`/web/environments/${envId}/incidents/snooze`, { ids: ids, params: snoozeConfig })
      .then((res) => res.data);
  }

  function unsnooze(envId, ids) {
    return $http
      .post(`/web/environments/${envId}/incidents/unsnooze`, { ids })
      .then((res) => res.data);
  }

  this.snooze = snooze;
  this.unsnooze = unsnooze;
}
