import { react2angular } from 'react2angular';
import IntegrationsIframe from './IntegrationsIframe';

angular
  .module('bigpanda')
  .component(
    'integrationsIframe',
    react2angular(IntegrationsIframe, [
      'handleValidMsg',
      'integrationUrl',
      'iframeOriginUrl',
      'isModalMode',
    ])
  );
