import { AllSizes, SCVBox, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const EmptyMessageText = styled(Text).attrs({
  size: AllSizes.LARGE,
})`
    color: ${(p) => p.theme.bp_gray_06};
`;

export const EmptyMessageHeaderText = styled(Text).attrs({
  size: AllSizes.LARGE,
})`
    text-align: center;
    display: block;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 0;
    text-transform: uppercase;
`;

export const EmptyMessageContainer = styled(SCVBox)`
    margin-top: 25%;
    width: 100%;
`;
