import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Label, RemoveIcon } from './Chip.css';

const Chip = ({ children, onRemoveClick }) => (
  <Wrapper>
    <Label>{children}</Label>
    {onRemoveClick && (
      <RemoveIcon className="bp-icon bp-icon-remove-delete-two" onClick={onRemoveClick} />
    )}
  </Wrapper>
);

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  onRemoveClick: PropTypes.func,
};

export default Chip;
