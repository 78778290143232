import { combineReducers } from 'redux';
import * as permissions from './permissions';
import * as organization from './organization';
import * as featureToggles from './feature_toggles';
import * as details from './details';

export default combineReducers({
  [permissions.name]: permissions.reducer,
  [organization.name]: organization.reducer,
  [featureToggles.name]: featureToggles.reducer,
  [details.name]: details.reducer,
});
