angular
  .module('bigpanda.backend.services')
  .service('AssignmentsBackendService', AssignmentsBackendService);

function AssignmentsBackendService($http) {
  this.multiAssign = multiAssign;
  this.multiUnassign = multiUnassign;
  this.getAssignments = getAssignments;

  function multiAssign(envId, params) {
    return $http.post(`/resources/v2.0/environments/${envId}/incidents/assign`, params);
  }

  function multiUnassign(envId, params) {
    return $http.post(`/resources/v2.0/environments/${envId}/incidents/unassign`, params);
  }

  function getAssignments(incidentIds) {
    return $http
      .post('/resources/v2.0/assignments', { ids: incidentIds })
      .then((res) => res.data.item);
  }
}
