angular.module('bigpanda').directive('foldersList', foldersList);

function foldersList() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      environment: '=',
      environmentCounters: '=',
    },
    bindToController: true,
    templateUrl: 'overview/folders/folders',
    controller: 'FoldersCtrl',
    controllerAs: 'foldersVm',
  };
}
