import includes from 'lodash/includes';
import escapeRegExp from 'lodash/escapeRegExp';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import _template from 'lodash/template';
// A directive for allowing complex auto completion inputs
//
// Parameters:
// - placeholder: An optional string to be used as the input's placeholder
// - input: An expression that will be bounded to the input's value
// - logic:
//    - refresh: A function that receives the input string up to the completion point and returns a promise. That
//               promise should return a list of elements that look like this:
//               {
//                 label: The string that is suggested as a completion
//                 description: A description that is shown alongside the label
//                 prefix: Optional string that marks the part of the label beginning that should be highlighted
//               }
//    - complete: A function that receives the current input string and the chosen label and returns the new input string
//    - submit: A function that's called when the user submits (enter/button click). If it throws an exception the
//              input will be in error state until another submit is attempted.
//    - templateTooltip: Optional path to template to be shown when the input is focused and empty
const Awesomplete = require('awesomplete');

angular.module('bp.widgets').directive('bpBpqlAutocomplete', bpBpqlAutocomplete);

function bpBpqlAutocomplete() {
  return {
    restrict: 'E',
    templateUrl: 'widgets/bp_bpql_autocomplete/bp_bpql_autocomplete',
    controllerAs: 'awesompleteCtrl',
    bindToController: true,
    scope: {
      allowSubmitWhenEmpty: '=',
      logic: '=',
      input: '=',
      hideSearch: '=',
      preventDefaultSubmit: '=',
      error: '=?',
      placeholder: '@',
      disabled: '=',
    },
    controller: controller,
  };

  function controller($element, $scope, $timeout) {
    const vm = this;

    function getCursorPosition() {
      return $input[0].selectionStart;
    }

    vm.mapMouseHighlightsToSuggestionSelection = (awesompleteInstance) => {
      $element.on('mousemove', (event) => {
        const index = angular.element(event.target).closest('.awesomplete li').index();
        if (index !== -1) {
          awesompleteInstance.goto(index);
        }
      });
    };

    const $input = $element.find('input');
    $input.val(vm.input);

    $scope.$watch(
      () => vm.disabled,
      () => {
        $input.prop('disabled', vm.disabled);
      }
    );

    // Simulate 2 way binding, since we can't use ng-model
    $scope.$watch(
      () => vm.input,
      () => {
        if (vm.input !== $input.val()) {
          $input.val(vm.input);
        }
      }
    );

    $input.prop('placeholder', vm.placeholder);

    // This is an optimization since generating the elements with Angular was noticeably laggy
    const template = _template(
      `<li aria-selected="false">
          <span class="suggestion">
            <!--<% forEach(phrases, function(phrase) { %>
              <% if (phrase === text) { %>
                --><mark><%- phrase %></mark><!--
              <% } else { %>
                --><span><%- phrase %></span><!--
              <% } %>
            <% }); %>-->
          </span><!--
          --><span class="description"><%- description %></span>
        </li>`
    );

    // TODO if we have angular 1.5.3 move stuff to the proper lifecycle functions
    const awesompleteInstance = new Awesomplete($input[0], {
      filter: () => true,
      replace: (text) => {
        const cursorPos = getCursorPosition();
        const full = $input.val();
        const before = full.substring(0, cursorPos);

        $scope.$apply(() => {
          const newText =
            vm.logic.complete(before, text.label) +
            (full.charAt(cursorPos) === ' ' ? '' : ' ') +
            full.substring(cursorPos);
          $input.val(newText);
        });
      },
      item: (item, input) => {
        const prefix = item.value.suggestion.prefix || input;
        let phrases;
        if (isEmpty(prefix)) {
          phrases = [item.label];
        } else {
          phrases = item.label.split(new RegExp(`(^${escapeRegExp(prefix)})`, 'gi'));
        }

        const div = document.createElement('div');
        div.innerHTML = template({
          phrases: phrases,
          text: prefix,
          description: item.value.suggestion.description,
          forEach,
        });
        return div.firstChild;
      },
      sort: (itemA, itemB) => itemA.value.index - itemB.value.index,
      minChars: 1,
      maxItems: 100,
      autoFirst: true,
    });

    $input.on('keydown change focus input', (e) => {
      if (vm.preventDefaultSubmit && e.keyCode === 13) {
        e.preventDefault();
      }
      $timeout(() => {
        vm.input = $input.val();

        const keyCodesToIgnore = [
          38, // Up arrow
          40, // Down arrow
          27, // Esc
          13,
        ]; // Enter
        if (e.type === 'keydown' && includes(keyCodesToIgnore, e.keyCode)) {
          return;
        }

        const inputBefore = vm.input;
        vm.logic.refresh(vm.input.substring(0, getCursorPosition()), e).then((suggestions) => {
          if (inputBefore !== vm.input) {
            return;
          }

          awesompleteInstance.list = suggestions.map((suggestion, index) => ({
            label: suggestion.label,
            value: { suggestion, index },
          }));

          if ($input.is(':focus')) {
            awesompleteInstance.evaluate();
          }
        });
      });

      return true;
    });

    vm.mapMouseHighlightsToSuggestionSelection(awesompleteInstance);

    $scope.$on('$destroy', () => {
      $input.off();
      $element.off();
    });

    let focused = false;

    $input.on('focus', () => {
      $scope.$apply(() => {
        focused = true;
      });
    });

    $input.on('blur', () => {
      $scope.$apply(() => {
        focused = false;
      });
    });

    vm.isFocused = () => focused;

    vm.disableButton = () => (!vm.input && !vm.allowSubmitWhenEmpty) || vm.disabled;

    vm.search = () => {
      try {
        vm.error = false;
        vm.logic.submit();
      } catch (err) {
        vm.error = true;
      }
    };
  }
}
