import React from 'react';
import { shape, string, func, arrayOf, objectOf } from 'prop-types';
import { BamCheckbox } from '@bp/bam';
import { getKeyFromTypes } from '../logic/logic';

const FilterCheckboxes = ({
  nodesAndLinks,
  defaultColors,
  tempColorsMap,
  typesMap,
  hiddenNodeGroups,
  onClick,
  hideInactive,
}) => {
  const filters = nodesAndLinks.nodes.reduce((acc, node) => {
    const key = getKeyFromTypes(typesMap, node.group);
    const color = defaultColors[key || node.group] || tempColorsMap[key || node.group];
    const normalizedColor = color && color.replace(/^bp-/, '');
    const toggleCheckbox = (filtered) => onClick({ filtered, node });
    const appearance = nodesAndLinks.nodes.filter((testNode) => {
      const inactive =
        hideInactive &&
        (testNode.status === 'acknowledged' ||
          testNode.status === 'ok' ||
          testNode.maintenance_plan_ids);
      return testNode.group === node.group && !inactive;
    }).length;
    const checkbox = (
      <BamCheckbox
        className="topology-checkbox"
        key={node.group}
        color={normalizedColor}
        label={node.group}
        counter={appearance}
        checked={!hiddenNodeGroups[node.group]}
        onClick={toggleCheckbox}
      />
    );
    return { ...acc, [node.group]: checkbox };
  }, {});

  return Object.values(filters);
};

FilterCheckboxes.propTypes = {
  nodesAndLinks: shape({}).isRequired,
  defaultColors: objectOf(string).isRequired,
  tempColorsMap: objectOf(string).isRequired,
  typesMap: objectOf(arrayOf(string)).isRequired,
  hiddenNodeGroups: shape({}).isRequired,
  onClick: func.isRequired,
};

export default FilterCheckboxes;
