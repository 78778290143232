import find from 'lodash/find';
import debounce from 'lodash/debounce';
import last from 'lodash/last';
import uniqueId from 'lodash/uniqueId';
angular.module('bigpanda').directive('dashboardAreaChart', dashboardAreaChart);

function dashboardAreaChart() {
  return {
    scope: {
      timeline: '<',
    },
    templateUrl: 'dashboard/dashboard_area_chart',
    restrict: 'E',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($element, $window, $scope, $timeout) {
    const vm = this;

    vm.$postLink = postLink;
    vm.$onDestroy = onDestroy;

    let svg;
    let x;
    let y;
    let area;
    let line;
    let timelineViewModel;
    let width;
    let chartContainer;
    const resizeListenerName = `resize.${uniqueId()}`;

    function postLink() {
      chartContainer = d3.select($element[0]).select('.dashboard-area-chart');

      // Wait for flex to settle on sizing before starting to render the chart
      $window.requestAnimationFrame(() => {
        $scope.$apply(() => {
          $scope.$watch(
            () => vm.timeline && last(vm.timeline),
            (newValue) => {
              if (newValue === undefined) {
                return;
              }

              timelineViewModel = vm.timeline.map((period, index) => ({
                x: index,
                count: period.count,
              }));

              if (initializeSvg()) {
                render();
              } else {
                // Sometimes the above requestAnimationFrame waiting for flex isn't enough and we have to wait some more
                $timeout(() => resize(), 1000);
              }
            },
            true
          );
        });
      });

      angular.element($window).on(
        resizeListenerName,
        debounce(() => $scope.$apply(resize), 50)
      );
    }

    function initializeSvg() {
      const rect = chartContainer[0][0].getBoundingClientRect();
      const margin = { top: 1, right: 0, bottom: 1, left: 0 };
      const displayedWidth = rect.width - margin.left - margin.right;
      // Hack explanation: sometimes when Chrome is taking longer to settle on flex sizing
      // it returns the full window width as the width of our elements, in that case we
      // don't render and wait for the next try
      if (width !== displayedWidth && displayedWidth !== $window.innerWidth) {
        width = displayedWidth;
        chartContainer.selectAll('svg *').remove();
        const height = rect.height - margin.top - margin.bottom;
        x = d3.scale.linear().range([0, displayedWidth]);
        y = d3.scale.linear().range([height, 0]);
        area = d3.svg
          .area()
          .interpolate('basis')
          .x((d) => x(d.x))
          .y1((d) => y(d.count));
        line = d3.svg
          .line()
          .interpolate('basis')
          .x((d) => x(d.x))
          .y((d) => y(d.count));
        svg = d3
          .select($element[0])
          .select('svg')
          .attr('width', rect.width)
          .attr('height', rect.height)
          .append('g')
          .attr('transform', `translate(${margin.left}, ${margin.top})`);

        svg.append('path').attr('class', 'area');
        svg.append('path').attr('class', 'line');

        return true;
      }

      return false;
    }

    function render() {
      x.domain(d3.extent(timelineViewModel, (d) => d.x));
      y.domain([0, maxScaleForTimeline(timelineViewModel)]);
      area.y0(y(0));

      const transition = svg.transition().duration(100).delay(0);
      transition.selectAll('path.area').attr('d', area(timelineViewModel));
      transition.selectAll('path.line').attr('d', line(timelineViewModel));
    }

    function resize() {
      if (initializeSvg()) {
        render();
      }
    }

    function maxScaleForTimeline(timeline) {
      const max = d3.max(timeline, (d) => d.count);
      const scales = [50, 100, 200, 500, 1000];
      return find(scales, (s) => max <= s) || max;
    }

    function onDestroy() {
      angular.element($window).off(resizeListenerName);
    }
  }
}
