import {
  AddIcon, IOption, Theme,
  Tooltip,
} from '@bp/kung-fu';
import React from 'react';

import ItemWrapper from './index.style';

function EnvRulePrioritySelectItem(props: IOption): JSX.Element {
  const {
    id, name,
  } = props;
  return (
    <ItemWrapper>
      <Tooltip text={`Order ID: ${id}`} placement="right">
        <span>{name}</span>
      </Tooltip>
      <AddIcon color={({ theme } : { theme: Theme }) : string => theme.bp_gray_05} />
    </ItemWrapper>
  );
}

export default EnvRulePrioritySelectItem;
