import React from 'react';
import PropTypes from 'prop-types';
import { SSODisableModal } from '../modals';
import { Content, HorizontalLayout, Title, Description } from './components';

const SSOPreValidation = ({ providerName, disableSSO }) => (
  <HorizontalLayout>
    <Content>
      <Title>Validate your {providerName} SSO integration.</Title>
      <Description>
        {`Log out and then log back in with your email and an empty password to use ${providerName} for authentication.
          After a successful login with ${providerName}, you will be able to activate Single Sign-on for your entire organization.`}
      </Description>
      <hr />
      <Title>Disable SSO</Title>
      <Description>Click below to clear your configuration settings and disable SSO.</Description>
      <SSODisableModal
        onAction={disableSSO}
        triggerProps={{
          variant: 'danger',
          size: 'medium',
          text: 'Disable SSO',
          name: 'Disable SSO',
        }}
      />
    </Content>
  </HorizontalLayout>
);

SSOPreValidation.propTypes = {
  providerName: PropTypes.string.isRequired,
  disableSSO: PropTypes.func.isRequired,
};

export default SSOPreValidation;
