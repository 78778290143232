angular.module('bigpanda').directive('advancedEditorRules', () => ({
  restrict: 'E',
  replace: true,
  scope: {
    onBpqlInputChange: '=',
    tags: '=',
    advancedEnvFilter: '=',
    setOriginalValue: '=',
    siftValue: '=',
    envName: '=',
  },
  bindToController: true,
  controllerAs: 'vm',
  templateUrl: 'overview/environments/editor/advanced_rules_editor/advanced_editor_rules',
  controller: 'AdvancedEditorRulesCtrl',
}));
