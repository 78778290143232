angular.module('bigpanda').directive('ca', ca);

function ca() {
  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'settings/sso/ca/ca_content',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($http, pubSubService) {
    const vm = this;

    vm.credentials = {};
    vm.caError = '';

    const formStatuses = {
      empty: { empty: true },
      failed: { message: 'Not enough permissions', failed: true },
      failed_locaSingleSignOnl: { message: 'All fields are mandatory', failed: true },
      success: { message: 'Done', success: true },
      inProgress: { message: 'Configuration In Progress', inProgress: true },
    };

    function validateBasic() {
      if (!vm.credentials.idPssoUrl || !vm.credentials.idPissuer || !vm.credentials.idPcert) {
        vm.status = formStatuses.failed_locaSingleSignOnl;
        return true;
      }
      return false;
    }

    vm.configure = () => {
      vm.status = formStatuses.empty;
      vm.caError = '';

      if (validateBasic()) {
        return;
      }

      vm.status = formStatuses.empty;
      vm.configureDisabled = true;

      vm.status = formStatuses.inProgress;

      $http.post('/web/organization/my/sso/ca/configure', vm.credentials).then(
        () => {
          vm.status = formStatuses.success;
          pubSubService.broadcast('SsoIntegrationCompleted', 'ca');
          vm.configureDisabled = false;
        },
        (err) => {
          vm.configureDisabled = false;
          vm.status = formStatuses.failed;
          vm.caError =
            err.data &&
            err.data.response &&
            err.data.response.errors &&
            err.data.response.errors[0];
        }
      );
    };
  }
}
