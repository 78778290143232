import { BamBpqlInput } from '@bp/bam';
import { Form, HBox } from '@bp/kung-fu';
import { Field } from 'react-final-form';
import styled from 'styled-components';

export const FormContent = styled(Form)`
  width: 550px;
  background-color: ${(p) => p.theme.bp_gray_01};
  flex-grow: 1;
`;

export const FooterLine = styled(HBox)`
  height: 25px;
  flex-grow: 1;
  padding: 0px 0px;
  white-space: nowrap;
  align-items: center;
  border-top: 1px solid #efefef;
  padding-top: 25px;
  border-top: solid 1px ${(p) => p.theme.bp_gray_03};
  background-color: ${(p) => p.backgroundColor};
  font-weight: normal;
`;

export const NameFormField = styled(Field)`
  margin-bottom: 24px!important;
`;

export const SourceSystemField = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const ConditionFormField = styled(BamBpqlInput)`
  margin-bottom: 23px!important;
`;

export const DescriptionWrapper = styled.div`
  box-sizing: border-box;
  height: 100px;
`;

export const Footer = styled(HBox)`
  width: 550px;
  height: 15px;
  flex-grow: 0;
  padding: 25px;
  white-space: nowrap;
  align-items: center;
  background-color: ${(p) => p.theme.bp_gray_01};
  border-top: 1px solid ${(p) => p.theme.bp_gray_03};
`;
