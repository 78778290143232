import { combineEpics } from 'redux-observable';
import * as ssoService from 'common/endpoints/sso';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { showFailureMessage, showSuccessMessage } from '../../../layout/settings/feedback';

const loadSSOState = (action$) =>
  action$.ofType(actionTypes.LOAD_SSO_STATE).mergeMap(() =>
    ssoService
      .loadSSOState()
      .then((data) => actions.loadSSOStateSuccess(data))
      .catch(actions.loadSSOStateFailure)
  );

const configureSSOProvider = (action$) =>
  action$.ofType(actionTypes.CONFIGURE_SSO_PROVIDER).mergeMap(({ payload }) =>
    ssoService
      .configureSSO(
        payload.provider,
        payload.IssuerIDInput,
        payload.EndPointInput,
        payload.CertificateInput,
        payload.disableRequestedAuthnContext
      )
      .then(() => {
        showSuccessMessage(`Configuration for '${payload.provider}'`, 'Sent');
        return actions.configureSSOProviderSuccess(payload);
      })
      .catch(actions.configureSSOProviderFailure)
  );

const updateSSO = (action$) =>
  action$.ofType(actionTypes.UPDATE_SSO).mergeMap(({ payload }) =>
    ssoService
      .updateSSO(payload.provider, payload.enabled, payload.status)
      .then(() =>
        actions.updateSSOSuccess({
          enabled: payload.enabled,
          status: payload.status,
          idP: payload.provider,
        })
      )
      .catch(actions.updateSSOFailure)
  );

const disableSSO = (action$) =>
  action$.ofType(actionTypes.DISABLE_SSO).mergeMap(() =>
    ssoService
      .disableSSO()
      .then(() => actions.disableSSOSuccess({}))
      .catch(actions.disableSSOFailure)
  );

export default combineEpics(loadSSOState, configureSSOProvider, updateSSO, disableSSO);
