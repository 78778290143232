import PropTypes from 'prop-types';
import { path as prevPath } from '../constants';

export const name = 'incidentLabelsList';
export const path = `${prevPath}.${name}`;

export const CREATED_SORT_MODE = {
  text: 'Created',
  field: 'created_at',
  ascending: false,
};
export const UPDATED_SORT_MODE = {
  text: 'Updated',
  field: 'updated_at',
  ascending: false,
  isDefault: true,
};
export const TAG_NAME_SORT_MODE = { text: 'Tag Name', field: 'name', ascending: true };
export const SORT_MODES = [UPDATED_SORT_MODE, TAG_NAME_SORT_MODE, CREATED_SORT_MODE];

export const DEFAULT_SORT_MODE = UPDATED_SORT_MODE;

export const SortShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool,
});
