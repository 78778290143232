import styled from 'styled-components';

export const Table = styled.table`
  width: fit-content;
`;

export const Td = styled.td`
  padding: 1rem;
  border: 1px solid ${(p) => p.theme.bp_gray_02};
  background: ${(p) => p.theme.bp_white};
`;

export const Th = styled.td`
  padding: 1rem;
  border: 1px solid ${(p) => p.theme.bp_gray_02};
  font-weight: bold;
  background: ${(p) => p.theme.bp_white};
`;

export const Pre = styled.pre`
  font-size: 1.2rem;
  color: ${(p) => p.theme.bp_gray_07};
`;
