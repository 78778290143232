import {
  AllSizes, Divider, Text, VBox,
} from '@bp/kung-fu';
import React from 'react';
import styled from 'styled-components';

import TagsDividerProps from './TagsDividerProps';

const Tag = styled(Text).attrs({
  size: AllSizes.LARGE,
  lineHeight: '22px',
})`
  font-weight:bold;
  color:${(p): string => p.theme.bp_gray_08}
`;

const DividerContainer = styled.div`
  margin-top: 5px;
`;

function TagsDivider({
  tags,
  timeWindow,
}: TagsDividerProps): JSX.Element {
  return (
    <VBox>
      <Tag>
        {tags.join(', ')}
      </Tag>
      <DividerContainer>
        <Divider>
          <Text size="large" color={(p): string => p.theme.bp_gray_07}>
            { `${timeWindow}m` }
          </Text>
          <Text size="large" color={(p): string => p.theme.bp_gray_07}>
            All Systems
          </Text>
        </Divider>
      </DividerContainer>
    </VBox>
  );
}

export default TagsDivider;
