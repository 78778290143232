import get from 'lodash/get';
import { combineEpics } from 'redux-observable';
import { pipe, concat, map, flatten, uniq } from 'lodash/fp';
import 'rxjs/add/observable/combineLatest';
import { selectors as integrationsSelectors } from 'react/common/integrations';
import { batchActions } from 'redux-batched-actions';
import { loadIntegrations } from 'react/common/integrations/actions';
import { loadCorrelationConfig } from 'react/common/correlation_config/actions';
import { generateMetadata } from 'react/common/correlation_config/utils';
import {
  createCorrelationPattern as createCorrelationPatternRequest,
  deleteCorrelationPattern as deleteCorrelationPatternRequest,
  fetchCorrelationPatterns as fetchCorrelationPatternsRequest,
  updateCorrelationPattern as updateCorrelationPatternRequest,
} from 'common/endpoints/correlation-config';
import { loadLabelDefinitions } from 'react/common/configuration_label_definitions';
import { loadEnrichedUsers } from 'react/common/enriched_users/actions';
import { actionTypes as correlationConfigActionTypes } from 'react/common/correlation_config';
import {
  showFailureMessage,
  showSuccessMessage,
  showInProgressMessage,
} from 'react/layout/settings/feedback';
import { BamNotificationStatusOptions, bamUnNotify } from '@bp/bam';
import actionTypes from './actionTypes';
import actions from './actions';
import { ATTRIBUTE_FILTERS_OPTIONS, DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';
import { DROPDOWN_FILTER_STATUS_ITEMS } from '../constants';
import selectors from './selectors';
import { convertOldCorrelationPatternToNew } from '../correlation_patterns/converters';
import { selectors as FTSelectors } from '../../../../../app/react/user/feature_toggles';
import { shouldAllowOnboarding } from '../../../../../workspaces/apps/onboarding/src/helpers/shouldAllowOnboarding';
import dic from '../../../../../workspaces/apps/onboarding/src/dictionary';
import completeOnboardingStep from '../../../../common/endpoints/onboarding';

const getNewCorrelationPattern = (state, payload) => {
  const { description, suggested, ...fields } = payload;
  const newCorrelationPattern = {
    ...fields,
    metadata: generateMetadata(fields, state.getState(), description, suggested),
  };
  return convertOldCorrelationPatternToNew(newCorrelationPattern);
};

const getUpdatedCorrelationPattern = async (state, payload) => {
  const { correlationPatterns: loadedCorrelationPatterns, id, description, ...fields } = payload;
  const correlationPatterns =
    loadedCorrelationPatterns || (await fetchCorrelationPatternsRequest());
  const updatedCorrelationPattern = correlationPatterns.find((i) => i.id === id);
  if (!updatedCorrelationPattern) {
    throw new Error(`Cannot update correlation pattern: id ${id} not found`);
  }
  Object.assign(updatedCorrelationPattern, fields);
  Object.assign(updatedCorrelationPattern, {
    metadata: generateMetadata(updatedCorrelationPattern, state.getState(), description),
  });

  return convertOldCorrelationPatternToNew(updatedCorrelationPattern);
};

const addCorrelationPattern = (action$, state$) => {
  let toasterId;
  const onSuccess = () => showSuccessMessageForPattern('added', toasterId);
  const onFailure = () => showFailureMessageForPattern('add', toasterId);
  return action$.ofType(actionTypes.ADD_CORRELATION_PATTERN).mergeMap(async ({ payload }) => {
    const state = state$.getState();
    toasterId = showInProgressMessageForPattern('Alert Correlation', 'Adding');
    const newCorrelationPattern = getNewCorrelationPattern(state$, payload);
    const addNewCorrelationPattern = async () => {
      const correlationPattern = await createCorrelationPatternRequest(newCorrelationPattern);
      const allowOnboarding = shouldAllowOnboarding(FTSelectors.getFeatureToggles(state));
      if (allowOnboarding) {
        completeOnboardingStep(dic.onboardingSteps.alert_correlation);
      }
      if (window.location.href.includes('alert-correlation')) {
        window.location.href = `/#/app/settings/alert-correlation/${correlationPattern.id}`;
      }
    };
    return executeRequest(addNewCorrelationPattern(), state$, onSuccess, onFailure);
  });
};

const deleteCorrelationPattern = (action$, state$) => {
  let toasterId;
  const onSuccess = () => showSuccessMessageForPattern('deleted', toasterId);
  const onFailure = () => showFailureMessageForPattern('delete', toasterId);
  return action$
    .ofType(actionTypes.DELETE_CORRELATION_PATTERN)
    .mergeMap(async ({ payload: id }) => {
      toasterId = showInProgressMessageForPattern('Alert Correlation', 'Deleting');
      return executeRequest(deleteCorrelationPatternRequest(id), state$, onSuccess, onFailure);
    });
};

const updateCorrelationPattern = (action$, state$) => {
  let toasterId;
  const onSuccess = () => showSuccessMessageForPattern('updated', toasterId);
  const onFailure = () => showFailureMessageForPattern('update', toasterId);
  return action$.ofType(actionTypes.UPDATE_CORRELATION_PATTERN).mergeMap(async ({ payload }) => {
    toasterId = showInProgressMessageForPattern('Alert Correlation', 'Updating');
    const updatedCorrelationPattern = await getUpdatedCorrelationPattern(state$, payload);
    return executeRequest(
      updateCorrelationPatternRequest(updatedCorrelationPattern),
      state$,
      onSuccess,
      onFailure
    );
  });
};

async function executeRequest(requestPromise, state$, onSuccess, onFailure) {
  const onFinish = (callback) => {
    const reloadLabels = selectors.areConfigurationLabelsEnabled(state$.getState());
    // As per BUG-691 - we need to delay reloading the config after changes.
    const loadCorrelationConfigAction = loadCorrelationConfig({ delay: true }, callback);
    return reloadLabels
      ? batchActions([loadLabelDefinitions(), loadCorrelationConfigAction])
      : loadCorrelationConfigAction;
  };

  try {
    await requestPromise;
    return onFinish(onSuccess);
  } catch (e) {
    return onFinish(onFailure);
  }
}

const loadCorrelationPatterns = (action$, state$) =>
  action$.ofType(actionTypes.LOAD_CORRELATION_PATTERNS).map(() => {
    return batchActions([
      loadCorrelationConfig(),
      loadIntegrations(),
      loadEnrichedUsers(),
      ...(selectors.areConfigurationLabelsEnabled(state$.getState())
        ? [loadLabelDefinitions()]
        : []),
    ]);
  });

const loadEnrichedCorrelationPatternsSuccess = (action$) =>
  action$
    .ofType(correlationConfigActionTypes.ENRICH_CORRELATION_CONFIG_ITEMS_SUCCESS)
    .mergeMap(async ({ payload }) => {
      const { correlationPatterns } = payload;
      const enrichedCorrelationPatterns = correlationPatterns.map((pattern) => ({
        ...pattern,
        tagNames: getPatternTagNames(pattern),
      }));
      return actions.loadCorrelationPatternsSuccess(enrichedCorrelationPatterns);
    });

const createFiltersDropDownItems = (action$, state$) =>
  action$.ofType(actionTypes.LOAD_CORRELATION_PATTERNS_SUCCESS).map(({ payload }) => {
    const tagItemsArray = pipe(
      map((correlationPatternItem) => correlationPatternItem.tagNames),
      flatten,
      uniq,
      map((tag) => ({ text: tag, value: tag, key: tag })),
      Object.values,
      concat([DROPDOWN_FILTERS_ALL_ITEM_OPTION.tag])
    )(payload);

    const sourceItemsArray = [
      DROPDOWN_FILTERS_ALL_ITEM_OPTION.source,
      { text: 'All Systems', value: '*', key: '*' },
    ].concat(
      integrationsSelectors
        .getIntegrationOptions(state$.getState())
        .map(({ value, display }) => ({ key: value, text: display, value }))
    );

    const attributeItemsArray = [DROPDOWN_FILTERS_ALL_ITEM_OPTION.attribute].concat(
      ATTRIBUTE_FILTERS_OPTIONS
    );

    const statusItemsArray = [DROPDOWN_FILTERS_ALL_ITEM_OPTION.status].concat(
      DROPDOWN_FILTER_STATUS_ITEMS
    );

    return actions.createFiltersDropDownItemsSuccess({
      tag: tagItemsArray,
      source: sourceItemsArray,
      attribute: attributeItemsArray,
      status: statusItemsArray,
    });
  });

const showInProgressMessageForPattern = (
  title,
  action,
  status = BamNotificationStatusOptions.IN_PROGRESS
) => showInProgressMessage(title, action, status);

const showSuccessMessageForPattern = (
  action,
  toasterId,
  status = BamNotificationStatusOptions.ACTION_SUCCESS
) => {
  bamUnNotify(toasterId);
  setTimeout(() => showSuccessMessage('Correlation pattern', action, status), 500);
};

const showFailureMessageForPattern = (action, toasterId) => {
  bamUnNotify(toasterId);
  setTimeout(() => showFailureMessage('Correlation pattern', action), 500);
};

const getPatternTagNames = (correlationPattern) =>
  correlationPattern.tags.map((tag) => (tag.indirect ? `@${tag.indirect}` : tag));

export default combineEpics(
  loadCorrelationPatterns,
  loadEnrichedCorrelationPatternsSuccess,
  createFiltersDropDownItems,
  addCorrelationPattern,
  updateCorrelationPattern,
  deleteCorrelationPattern
);
