import { identity, pipe } from 'lodash/fp';
import get from 'lodash/get';

import permissions from '../../../../../app/common/routing/permissions';
import { selectors as permissionsSelectors } from '../../../../../app/react/user/permissions';
import { path } from '../constants';
import { State } from '../reducer';
import { AlertFilter } from '../types/AlertFilter';
import { DropdownRecord, DropdownRecords } from '../types/DropdownOption';
import { Permissions } from '../types/Permissions';
import { DEFAULT_SORT_MODE } from '../types/SortModes';
import { filterByDropdowns } from './utils/filterByDropdowns';
import { filterByText } from './utils/filterByText';
import { sortFilters } from './utils/sortFilters';

interface FilterOptions {
  shouldFilterByText: boolean;
  shouldSort: boolean;
  shouldFilter: boolean;
}

const noFilterOpts: FilterOptions = {
  shouldFilterByText: false,
  shouldSort: false,
  shouldFilter: false,
};

const allFilterOpts: FilterOptions = {
  shouldFilterByText: true,
  shouldSort: true,
  shouldFilter: true,
};

const getFilterText = (state: State): State['filterText'] => get(state, path).filterText || '';

const getSortMode = (state: State): State['sortMode'] => get(state, path).sortMode || DEFAULT_SORT_MODE;

const getFilters = (state: State): DropdownRecord => get(state, path).filters;

const getAlertFilters = (
  state: State,
  {
    shouldFilterByText,
    shouldSort,
    shouldFilter,
  }: FilterOptions = allFilterOpts,
): State['alertFilters'] => {
  const alertFilters: AlertFilter[] = get(state, path).alertFilters || [];
  const filterAndSort = pipe(
    shouldFilterByText ? filterByText(getFilterText(state)) : identity,
    shouldSort ? sortFilters(getSortMode(state)) : identity,
    shouldFilter ? filterByDropdowns(getFilters(state)) : identity,
  );
  return filterAndSort(alertFilters);
};

const getAlertFilter = (state: State, filterId: string): AlertFilter | undefined => getAlertFilters(state, noFilterOpts).find(
  ({ id }: AlertFilter): boolean => id === filterId,
);

const getFilterItems = (state: State): DropdownRecords => get(state, path).filterItems;

const getActionsPermissions = (state: State): Permissions => {
  const userPermissions = permissionsSelectors.getPermissionsList(state);
  const hasEditPermission = userPermissions.includes(permissions.plans.update);
  const hasDeletePermission = userPermissions.includes(permissions.plans.delete);
  const hasDuplicatePermission = userPermissions.includes(permissions.plans.create);
  return {
    hasEditPermission,
    hasDeletePermission,
    hasDuplicatePermission,
  };
};

const isLoading = (state: State): boolean => get(state, path).isLoading || false;

export default {
  getFilterText,
  getSortMode,
  getAlertFilters,
  getAlertFilter,
  getFilterItems,
  getFilters,
  getActionsPermissions,
  isLoading,
};
