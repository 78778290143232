import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import includes from 'lodash/includes';
import difference from 'lodash/difference';
angular.module('bigpanda').service('IncidentsService', IncidentsService);
const {
  loadRelatedChanges,
  loadChanges,
  loadSearchChanges,
} = require('react/modules/changes/actions').default;

const DEFAULT_INCIDENTS_CACHE_SIZE = 5000;
function IncidentsService(
  $q,
  $filter,
  $cacheFactory,
  StreamConnector,
  pubSubService,
  IncidentsBackendService,
  notificationService,
  SortingStore,
  PromiseBatchService,
  $ngRedux,
  UserFeatureTogglesService
) {
  const incidents = {
    cache: $cacheFactory('incidentsCache', { capacity: DEFAULT_INCIDENTS_CACHE_SIZE }),
    streamInit: false,
  };
  const batchGetIncidentById = PromiseBatchService.batch(
    (...args) => IncidentsBackendService.getIncidentsByIds(...args).then((res) => res.item),
    (incidentId, incidentResult) => incidentId === incidentResult._id,
    200,
    (...args) => IncidentsBackendService.getIncidentById(...args).then((res) => res.item)
  );

  let getIncidentsPromise = $q.resolve();
  this.getIncidents = getIncidents;
  this.getIncidentsEditorPreview = getIncidentsEditorPreview;
  this.getIncidentById = getIncidentById;
  this.refetchIncidentById = refetchIncidentById;
  this.sort = sort;
  this.getAlertViewRules = getAlertViewRules;
  this.clearCache = clearCache;
  this.getIncidentEntities = getIncidentEntities;
  this.searchIncidents = searchIncidents;
  this.getIncidentChangesData = getIncidentChangesData;
  this.getRecentSearches = getRecentSearches;
  this.getAlertTags = getAlertTags;

  initStream();
  return this;

  function refetchIncidentById(incidentId) {
    return getIncidentByIdFromBackend(incidentId).then((incident) => {
      addToCache(incident);
      return incident;
    });
  }

  function getRecentSearches() {
    return IncidentsBackendService.getRecentSearches().then((res) => res.data);
  }

  function getIncidentEntities(incidentId, includeEvents) {
    return IncidentsBackendService.entities(incidentId, includeEvents);
  }

  function getIncidents(envId, folderId, lastChange, sortOption) {
    getIncidentsPromise = getIncidentsFromCacheOrBackend(envId, folderId, lastChange, sortOption);
    return getIncidentsPromise;
  }

  function getIncidentsFromCacheOrBackend(envId, folderId, lastChange, sortOption) {
    return IncidentsBackendService.getIncidents(envId, folderId, lastChange, sortOption)
      .then((res) => {
        addToCache(res.incidents.map((rawIncident) => $filter('incidentNormalizer')(rawIncident)));
        return res;
      })
      .catch((error) => {
        if (error.status === 504) {
          notificationService.error('Unexpected error, we will try to reconnect soon...', {
            disableScreen: true,
            until: ['Envy.refresh'],
          });
        }

        return $q.reject(error);
      });
  }

  function getIncidentsEditorPreview(payload) {
    return IncidentsBackendService.getPreviewIncidents(payload).then((res) => {
      res.incidents.forEach((rawIncident) => {
        $filter('incidentNormalizer')(rawIncident);
      });
      return res.incidents;
    });
  }

  function gotRawIncident(rawIncident) {
    if (!rawIncident) {
      return;
    }
    const incident = $filter('incidentNormalizer')(rawIncident);
    if (!incidents.cache.get(incident._id)) {
      addToCache(incident);
    } else {
      updateCache(incident);
    }

    pubSubService.broadcast('incident.newData', incident);
  }

  function initStream() {
    if (!incidents.streamInit) {
      incidents.streamInit = true;

      const isHoozeEnabled = UserFeatureTogglesService.getToggle('hooze_for_ws');
      if (isHoozeEnabled) {
        $ngRedux.connect(
          (state) => ({
            value: state.common.incidents.lastReceivedIncident,
          }),
          null
        )(({ value: lastReceivedIncident }) => gotRawIncident(lastReceivedIncident));
      } else {
        StreamConnector.on('Incident', gotRawIncident);
      }
    }
  }

  function addToCache(items) {
    const tmpIncidents = isArray(items) ? items : [items];
    forEach(tmpIncidents, (incident) => {
      incidents.cache.put(incident._id, angular.copy(incident));
    });
  }

  function clearCache() {
    incidents.cache.removeAll();
  }

  function updateCache(incident) {
    const prevIncident = incidents.cache.get(incident._id);
    if (!prevIncident) return incident;

    // This line is here as a patch until we refactor the incidents.service to a store.
    // After refactoring the snooze store we suddenly saw that if a snooze is removed, the next line (angular.extend)
    // will not remove the snooze_config on the previous incident in the cache, so we always remove it before updating
    // the cache
    delete prevIncident.snooze_config;

    incidents.cache.put(incident._id, angular.extend(prevIncident, incident));
    return incidents.cache.get(incident._id);
  }

  async function getIncidentById(incidentId, skipIncidentsCacheSync) {
    if (skipIncidentsCacheSync) {
      return getIncidentByIdFromCacheOrBackend(incidentId);
    }

    try {
      await getIncidentsPromise;
    } catch (e) {
      getIncidentsPromise = $q.resolve();
    }

    return getIncidentByIdFromCacheOrBackend(incidentId);
  }

  function getIncidentByIdFromBackend(incidentId) {
    return batchGetIncidentById(incidentId).then((res) => $filter('incidentNormalizer')(res));
  }

  function getIncidentByIdFromCacheOrBackend(incidentId) {
    const cachedIncident = incidents.cache.get(incidentId);
    if (cachedIncident) {
      return $q.when(cachedIncident);
    }
    return getIncidentByIdFromBackend(incidentId).then((incident) => {
      addToCache(incident);
      return incident;
    });
  }

  function sortByStatus(incident) {
    const statusCodes = {
      ok: 1,
      unknown: 2,
      warning: 3,
      critical: 4,
    };

    return statusCodes[incident.status];
  }

  function sortByPriority(incident) {
    return get(incident, 'incident_tags.itd_priority_1', -1);
  }

  function sortByAlerts(incident, folderId) {
    if (folderId === 'resolved') {
      return incident.entities.length;
    }
    return filter(incident.entities, (entity) => entity.is_active).length;
  }

  function sortByDuration(incident, folderId) {
    if (folderId === 'resolved') {
      return incident.end - incident.start;
    }
    return moment().unix() - incident.start;
  }

  function sort(partialIncidents, sortBy, folderId) {
    let sortParams = [];

    switch (sortBy) {
      case SortingStore.options.status:
        sortParams = [
          [(incident) => sortByStatus(incident), 'last_change'],
          ['desc', 'desc'],
        ];
        break;
      case SortingStore.options.start:
        sortParams = ['start', 'desc'];
        break;
      case SortingStore.options.duration:
        sortParams = [(incident) => sortByDuration(incident, folderId), 'desc'];
        break;
      case SortingStore.options.alerts:
        sortParams = [
          [(incident) => sortByAlerts(incident, folderId), 'last_change'],
          ['desc', 'desc'],
        ];
        break;
      case SortingStore.options.label_priority:
        sortParams = [
          [(incident) => sortByPriority(incident), 'last_change'],
          ['desc', 'desc'],
        ];
        break;
      default:
        sortParams = [
          ['last_change', '_id'],
          ['desc', 'desc'],
        ];
    }

    const data = orderBy(partialIncidents, ...sortParams);
    const sortedIncidents = map(data, (item) => item);

    return sortedIncidents;
  }

  function searchIncidents(envId, folderId, bpqlQuery, paging, sortBy, searchVal) {
    return IncidentsBackendService.searchIncidents(
      envId,
      folderId,
      bpqlQuery,
      paging,
      sortBy,
      searchVal
    );
  }

  function getAlertViewRules() {
    const cachedAlertViewRules = incidents.cache.get('alert_view_rules');
    if (cachedAlertViewRules) {
      return cachedAlertViewRules;
    }
    const alertViewRules = IncidentsBackendService.getAlertViewRules();
    incidents.cache.put('alert_view_rules', alertViewRules);
    return alertViewRules;
  }

  function getAlertTags(passedEntity, alertViewRules) {
    const firstRule = find(alertViewRules, (rule) => {
      return (
        rule.enable &&
        (rule.source_system.includes(passedEntity.source_system) ||
          rule.source_system.includes('*') ||
          rule.source_system.includes(`${passedEntity.source_system}.*`))
      );
    });
    const tags = passedEntity.tags.filter(
      (t) => t.value !== undefined && t.value !== null && t.value !== ''
    );
    if (!firstRule) {
      return null;
    }
    const nonCollapsedTags = tags.filter((tag) => includes(firstRule.tags, tag.type));
    const collapsedTags = difference(tags, nonCollapsedTags);
    return [nonCollapsedTags, collapsedTags];
  }

  function setLoader(rccOnly = false, showOnlyRccToggle) {
    if (showOnlyRccToggle) {
      return rccOnly ? loadRelatedChanges : loadSearchChanges;
    }
    return loadChanges;
  }

  function getIncidentChangesData({
    toggleChecked,
    bpqlQuery,
    useNextLink = false,
    nextLink,
    incident,
    searchText,
    endTimeFrame,
    startTimeFrame,
    showOnlyRccToggle,
  }) {
    const loadAction = setLoader(toggleChecked, showOnlyRccToggle);
    const search = bpqlQuery || searchText;
    const query = !useNextLink && { search, endTimeFrame, startTimeFrame };
    $ngRedux.dispatch(loadAction({ ...(query || { nextLink }), incidentId: incident.id }));
  }
}
