import React from 'react';
import { BamDropDown } from '@bp/bam';

import { path as prevPath } from '../constants';

export const name = 'troubleshooting';
export const path = `${prevPath}.${name}`;

export const reqStatusType = {
  NOT_INITIALIZED: -1,
  LOADING: 2,
  FAILURE: 0,
  SUCCESS: 1,
};

export const ANY_OPTION_VALUE = '-1';

export const LAST_7_DAYS = { value: 'LAST_7_DAYS', text: 'Last 7 days' };
export const LAST_24_HOURS = { value: 'LAST_24_HOURS', text: 'Last 24 hours' };
export const DIVIDER = { value: 'DIVIDER', as: () => <BamDropDown.Divider /> };
export const CUSTOM_DATES_RANGE = {
  value: 'CUSTOM_DATES_RANGE',
  text: 'Custom Dates Range...',
  icon: { component: <i className="bp-icon bp-icon-calendar" />, position: 'left' },
};

export const datesRangeOptions = [LAST_7_DAYS, LAST_24_HOURS, DIVIDER, CUSTOM_DATES_RANGE];

export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  description: { value: ANY_OPTION_VALUE, text: 'Any description' },
  datesRange: LAST_7_DAYS,
};

export const RESULTS_PER_PAGE = 100;
