import capitalize from 'lodash/capitalize';

import { TAG_TYPE } from '../../../../../../../app/react/modules/settings/alert_enrichment/constants';

const functionTypeOptions = [TAG_TYPE.Composition, TAG_TYPE.Extraction].map((type) => ({
  key: type,
  value: type,
  text: capitalize(type),
  display: capitalize(type),
}));

export default functionTypeOptions;
