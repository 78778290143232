import React, { useEffect, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { loadAlertTags } from '../../../../../../../app/react/common/alert_tags/actions';
import { selectors as correlationConfigSelectors } from '../../../../../../../app/react/common/correlation_config';
import selectors from '../../../../../../../app/react/modules/settings/alert_enrichment/selectors';
import { ExtendedRule, Tag } from '../../../../types';
import { DraggableRuleWrapper } from '../../DraggableRuleWrapper';
import { LoadPreview, UnifiedTag } from '../../RuleTypeForms/types';

interface IProps {
  rules: ExtendedRule[];
  onLoadPreview: LoadPreview;
  tag: Tag;
  forceFocus?: boolean;
  forceFocusIndex?: number;
  freeFocus: () => void | undefined;
  onValueChanged: (id: string) => void;
}

function RulesList({
  rules,
  onLoadPreview,
  tag,
  forceFocus,
  forceFocusIndex,
  freeFocus,
  onValueChanged,
}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const unifiedTags: UnifiedTag[] = useSelector(correlationConfigSelectors.getUnifiedTags) || [];
  const reservedTagsNames: string[] = useSelector(selectors.getReservedTagsNames);

  const unifiedTagsWithoutInternal: UnifiedTag[] = useMemo(
    () => unifiedTags.filter(({ name }) => !reservedTagsNames.includes(name)),
    [unifiedTags.length, reservedTagsNames],
  );

  useEffect(() => {
    if (!unifiedTags.length) {
      dispatch(loadAlertTags());
    }
  }, []);

  return (
    <FormSpy>
      {({ form: { getState }, values: { rules: rulesValues } }): JSX.Element => {
        const {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dirtyFields,
          touched,
          errors: { rules: errorRules },
        } = getState();

        return (
          <Droppable droppableId="alert tag rules">
            {(provided): JSX.Element => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {rules.map((rule, index) => (
                  <DraggableRuleWrapper
                    {...{
                      onLoadPreview,
                      rulesValues,
                      rule,
                      index,
                      dirtyFields,
                      touched,
                      errorRules,
                      tag,
                      onlyRule: rules.length <= 1,
                      isLastItem: index === rules.length - 1,
                      forceFocus,
                      forceFocusIndex,
                      freeFocus,
                      onValueChanged,
                      unifiedTags: unifiedTagsWithoutInternal,
                    }}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        );
      }}
    </FormSpy>
  );
}

export default RulesList;
