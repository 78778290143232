const { react2angular } = require('react2angular');
const BpqlWrapper = require('react/common/components/BpqlWrapper/BpqlWrapper').default;

angular
  .module('bigpanda')
  .component(
    'reactBpqlInput',
    react2angular(BpqlWrapper, [
      'store',
      'onBpqlChange',
      'value',
      'tags',
      'tagsDefinitions',
      'maxRows',
      'enableQueryHelper',
    ])
  );
