import { AllSizes, Text } from '@bp/kung-fu';
import moment from 'moment';
import React, { memo } from 'react';
import styled from 'styled-components';

import { MaintenancePlan } from '../../types/MaintenancePlan';
import { durationFormat } from '../utils/durationFormat';
import {
  days,
  formatOrdinal,
} from './form/constants';
import { EMaintenanceFrequency } from './form/types/EMaintenanceFrequency';

const ValueText = styled(Text)`
  line-height: unset;
`;

interface ITimeWindow {
  plan?: MaintenancePlan;
  isPlain?: boolean;
}

interface ITimeFrame {
  startTime: string;
  endTime: string;
  isPlain?: boolean;
}

function TimeFrame({
  startTime, endTime, isPlain,
}: ITimeFrame): JSX.Element {
  return (
    <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
      {startTime}
      {' '}
      -
      {' '}
      {endTime}
    </ValueText>
  );
}

const timeStr = (date): string => date.format('h:mma');
const monthStr = (date): string => date.format('MMM Do');

export default memo(({
  plan, isPlain,
}: ITimeWindow) => {
  if (!plan) return null;

  const {
    start, end, frequency, frequency_data: frequencyData,
  } = plan;

  const startDate = moment.unix(start);
  const endDate = moment.unix(end);

  if (frequency === EMaintenanceFrequency.Once) {
    if (isPlain) {
      return (
        <span>
          <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight="normal">
            {startDate.format('MMM D, h:mma')}
            {' '}
            -
            {' '}
            {endDate.format('MMM D, h:mma')}
          </ValueText>
        </span>
      );
    }

    return (
      <span>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
          {monthStr(startDate)}
        </ValueText>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE}>
          {' '}
          at
          {' '}
        </ValueText>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
          {timeStr(startDate)}
        </ValueText>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE}>
          {' '}
          until
          {' '}
        </ValueText>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
          {monthStr(endDate)}
        </ValueText>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE}>
          {' '}
          at
          {' '}
        </ValueText>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
          {timeStr(endDate)}
        </ValueText>
      </span>
    );
  }

  const startTime = moment.unix(start);
  const endTime = startTime.clone().add(frequencyData.duration, 'seconds');

  if (frequency === EMaintenanceFrequency.Daily) {
    return (
      <span>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
          Daily
        </ValueText>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE}>
          {' '}
          at
          {' '}
        </ValueText>
        <TimeFrame startTime={timeStr(startTime)} endTime={timeStr(endTime)} isPlain={isPlain} />
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE}>
          {' '}
          for
          {' '}
        </ValueText>
        <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
          {durationFormat(frequencyData.duration)}
        </ValueText>

      </span>
    );
  }

  const monthlyDate = (): string => {
    if (frequencyData.relativity) {
      return `${frequencyData.relativity.toLowerCase()} ${days[startDate.isoWeekday() - 1]}`;
    }
    return formatOrdinal(startDate.date());
  };

  return (
    <span>
      <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
        {frequency}
      </ValueText>
      <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE}>
        {' '}
        every
        {' '}
      </ValueText>
      <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
        {frequency === EMaintenanceFrequency.Weekly
          ? days[startDate.isoWeekday() - 1]
          : monthlyDate()}
      </ValueText>
      <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE}>
        {' '}
        at
        {' '}
      </ValueText>
      <TimeFrame startTime={timeStr(startTime)} endTime={timeStr(endTime)} isPlain={isPlain} />
      <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE}>
        {' '}
        for
        {' '}
      </ValueText>
      <ValueText size={isPlain ? AllSizes.MEDIUM : AllSizes.LARGE} weight={isPlain ? 'normal' : 'bold'}>
        {durationFormat(frequencyData.duration)}
      </ValueText>
    </span>
  );
});
