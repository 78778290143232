import { BamBpqlInput } from '@bp/bam';
import {
  darkTheme, HelperType, IDataListObject, LabelIcon, QueryHelper, TagIcon, TextArea,
} from '@bp/kung-fu';
import get from 'lodash/get';
import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import selectors from '../../../../../../app/react/modules/settings/incident_labels/selectors';
import { DROPDOWMN_SHADOW, INCIDENT_TAGS_METADATA } from './constants';
import text from './dictionary';
import { QueryHelperWrapper } from './QueryHelper.style';
import { IQueryHelper } from './types/IQueryHelper';

function IncidentEnrichmentQueryHelper({
  triggerComponentProps,
  alertTags = [],
  onChange,
  showIncidents,
  placeholder,
}: IQueryHelper): JSX.Element {
  const [error, setError] = useState('');
  const incidentTags = useSelector((state) => selectors.getIncidentLabels(state)) || [];

  function validateQueryFilter(query: string): string | undefined {
    return query ? BamBpqlInput.helpers.BpqlInputValidator(query) : '';
  }

  const buildIncidentTagsQueryHelperData = (): IDataListObject => {
    const incidentTagsQueryHelperDataValues = [
      ...INCIDENT_TAGS_METADATA(),
      ...incidentTags.map(({ name, description }) => ({ title: name, description })),
    ];
    return {
      title: text.incidents,
      type: HelperType.INCIDENT_TAGS,
      disabled: false,
      icon: <LabelIcon />,
      values: incidentTagsQueryHelperDataValues,
    };
  };

  const buildAlertTagsQueryHelperData = (): IDataListObject => {
    const alertTagsQueryHelperDataValues = alertTags.map(({ name, sourceSystems }) => ({
      title: name,
      description: get(sourceSystems, '0.parentDisplayName'),
    }));
    return (
      alertTags.length && {
        title: text.alert_tags,
        type: HelperType.ALERT_TAGS,
        disabled: false,
        icon: <TagIcon />,
        values: alertTagsQueryHelperDataValues,
      }
    );
  };

  const incidentTagsQueryHelperData = useMemo(buildIncidentTagsQueryHelperData, [incidentTags]);

  const alertTagsQueryHelperData = useMemo(buildAlertTagsQueryHelperData, [alertTags]);

  const queryHelperData = [
    ...(showIncidents ? [incidentTagsQueryHelperData] : []),
    ...(alertTags.length ? [alertTagsQueryHelperData] : []),
  ];

  const handleOnChange = useCallback((query: string) => {
    setError(validateQueryFilter(query));
    onChange(query);
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <QueryHelperWrapper>
        <QueryHelper
          placeholder={placeholder}
          data={queryHelperData}
          onChange={handleOnChange}
          error={error}
          TriggerComponent={TextArea}
          triggerComponentProps={triggerComponentProps}
          dropDownShadow={DROPDOWMN_SHADOW}
        />
      </QueryHelperWrapper>
    </ThemeProvider>
  );
}
export default memo(IncidentEnrichmentQueryHelper);
