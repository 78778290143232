import { DROPDOWN_FILTERS_ALL_ITEM_OPTION } from '../core/constants';
import actionTypes from './actionTypes';

export const initialState = {
  isLoading: false,
  filterText: '',
  rules: undefined,
  filtersDropDownState: { ...DROPDOWN_FILTERS_ALL_ITEM_OPTION },
};

export default function reducer(state = initialState, action = undefined) {
  switch (action.type) {
    case actionTypes.UPDATE_FILTER_TEXT:
      return {
        ...state,
        filterText: action.payload,
      };
    case actionTypes.LOAD_AUTO_SHARE_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rules: action.payload.filter(({ deleted }) => !deleted),
      };
    case actionTypes.LOAD_AUTO_SHARE_RULES_FAILURE:
      return {
        ...state,
        isLoading: false,
        rules: undefined,
      };
    case actionTypes.LOAD_AUTO_SHARE_RULES:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS:
      return {
        ...state,
        filtersDropDownItems: {
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_FILTERS_DROPDOWNS:
      return {
        ...state,
        filtersDropDownState: {
          ...state.filtersDropDownState,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
