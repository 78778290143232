/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@bp/kung-fu';
import { contactPropType } from '../../propTypes';
import styles from './RecipientsTooltip.scss';

const userPropertyBySystemType = (systemType = '') =>
  systemType.toLowerCase() === 'sms' ? 'phone_number' : 'email';

const RecipientsTooltip = ({ contacts, type }) => {
  const propertyName = userPropertyBySystemType(type);

  return (
    <div>
      <Tooltip
        placement="top-start"
        title="Recipients"
        text={
          <div className={styles.recipientsContent}>
            <ul>
              {contacts.map(({ bigpanda_user_id, name, [propertyName]: value }) => (
                <li key={value}>
                  <span>{bigpanda_user_id ? `${name} (${value})` : value}</span>
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <span>{contacts.map(({ name, [propertyName]: value }) => name || value).join(', ')}</span>
      </Tooltip>
    </div>
  );
};

RecipientsTooltip.propTypes = {
  contacts: PropTypes.arrayOf(contactPropType),
  type: PropTypes.string,
};

RecipientsTooltip.defaultProps = {
  contacts: [],
  type: undefined,
};

export default RecipientsTooltip;
