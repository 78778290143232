import { DiceIcon } from '@bp/kung-fu';
import React from 'react';

export const QUERY_FILTER_PLACEHOLDER = 'e.g. host=*.com';
export const INCIDENT_TAGS = 'Incidents';
export const ALERT_TAGS = 'Alert Tags';
export const RECENT_SEARCHES = 'Recent Searches';
export const DROPDOWMN_SHADOW = '-6px 2px 7px 0px rgba(0, 0, 0, 0.10)';

export const INCIDENT_TAGS_METADATA = [
  {
    title: 'assignee',
    description: 'The assigned user for the incident',
    example: ['assignee = derrick*', 'assignee = derrick@bigpanda.io'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'status',
    description: 'The current status of the incident (“Critical”, “Warning“, ”Unknown“, ”Ok“)',
    example: ['status = critical'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'source_system',
    description: 'The integration that sent an alert to BigPanda',
    example: ['source_system = nagios*', 'source_system IN [nagios*,new_relic*]'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
];
