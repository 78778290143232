import { IColumn, IRow } from '@bp/kung-fu';
import { Dispatch, SetStateAction } from 'react';

import { AuditLogFilter } from '../../components/types';

export enum CategoryType {
  correlation_pattern = 'correlation_pattern',
  environments = 'environments',
  auto_share_rules = 'auto_share_rules',
  users = 'users',
  roles = 'roles',
  jit_domains = 'jit_domains',
  jit_roles = 'jit_roles',
  api_keys = 'api_keys',
  sso_test = 'sso_test',
  sso_config = 'sso_config',
  tag = 'tag',
  tag_enrichments = 'tag_enrichments',
  tag_enrichments_order = 'tag_enrichments_order',
  mapping_enrichment = 'mapping enrichment',
  mapping_table_upload = 'mapping_table_upload',
  integrations = 'integrations',
  alert_filtering = 'alert_filtering',
}

export enum ActionType {
  create = 'create',
  update = 'update',
  delete = 'delete',
  init_state = 'init_state',
}

export enum ActionTypeFormatted {
  create = 'Create',
  update = 'Update',
  delete = 'Delete',
  init_state = 'Init State',
}

export enum ActorType {
  system = 'system-generated',
  user = 'user',
}

export interface AuditCategory {
  name: string;
  rowValue: string;
  expandRowValue: string;
}

export interface UseAuditTableResult {
  column: IColumn[];
  setColumn?: Dispatch<SetStateAction<IColumn[]>>;
  rows: IRow[];
  setRows?: Dispatch<SetStateAction<IRow[]>>;
  isLoading: boolean;
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  setFilters: Dispatch<SetStateAction<AuditLogFilter>>;
  filters: AuditLogFilter;
  isFiltering: boolean;
}

export interface AuditRow extends IRow {
  id: string;
  resourceId: string;
  user: User;
  context: Context;
  action: ActionTypeFormatted;
  category: AuditCategory;
  changedObject: string;
  object: Record<string, unknown>;
  date: number;
  children: Array<Record<string, string>>;
}

export interface AuditLog {
  actor: Actor;
  context: Context;
  timestamp: number;
  resource_id: string;
  resource_type: string;
  action_type: string;
  object?: Record<string, string | number | Array<string> | boolean | Record<string, string>>;
}

export interface AuditLogResponse {
  items: AuditLog[];
  total: number;
}

export interface Actor {
  type: ActorType;
  user?: User | null;
}
export interface User {
  name: string;
  id?: string;
  email?: string;
}
export interface Context {
  actor_access: ActorAccess;
}
export interface ActorAccess {
  ip_address: string | string[];
  user_agent: string;
}

export interface Environment {
  _id: string;
  updated_at: string,
  created_at: string,
  name: string,
  old_id: string,
  filter: Record<string, unknown>
}
