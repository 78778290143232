import actionTypes from './actionTypes';

const actions = {};

actions.loadChanges = ({ summaryOnly, ...payload }) => ({
  type: actionTypes.LOAD_CHANGES,
  payload,
  summaryOnly,
});
actions.loadRelatedChanges = ({ summaryOnly, ...payload }) => ({
  type: actionTypes.LOAD_RELATED_CHANGES,
  payload,
  summaryOnly,
});
actions.loadChangesFailed = (payload = {}) => ({ type: actionTypes.LOAD_CHANGES_FAILED, payload });
actions.loadChangesSuccessfully = (payload = {}) => ({
  type: actionTypes.LOAD_CHANGES_SUCCESSFULLY,
  payload,
});
actions.loadRelatedChangesSuccessfully = (payload = {}) => ({
  type: actionTypes.LOAD_RELATED_CHANGES_SUCCESSFULLY,
  payload,
});
actions.upsertChangesMetadata = (payload = {}) => ({
  type: actionTypes.UPSERT_CHANGES_METADATA,
  payload,
});
actions.upsertChangesMetadataSuccessfully = (payload = {}) => ({
  type: actionTypes.UPSERT_CHANGES_METADATA_SUCCESSFULLY,
  payload,
});
actions.upsertChangesMetadataFailed = (payload = {}) => ({
  type: actionTypes.UPSERT_CHANGES_METADATA_FAILED,
  payload,
});
actions.changeDateRange = (dateRange) => ({
  type: actionTypes.CHANGE_DATE_RANGE,
  payload: { dateRange },
});
actions.changeSearch = (search) => ({ type: actionTypes.CHANGE_SEARCH, payload: { search } });
actions.changeToggleValue = (value) => ({
  type: actionTypes.CHANGE_TOGGLE_VALUE,
  payload: { checked: value },
});
actions.initChanges = () => ({ type: actionTypes.INIT_CHANGES, payload: {} });
actions.loadSearchChanges = ({ summaryOnly, ...payload }) => ({
  type: actionTypes.LOAD_SEARCH_CHANGES,
  payload,
  summaryOnly,
});
actions.loadSearchChangesSuccessfully = ({ summaryOnly, ...payload }) => ({
  type: actionTypes.LOAD_SEARCH_CHANGES_SUCCESSFULLY,
  payload,
});

export default actions;
