const { cacheTillFinished } = require('common/utils/http/cache');

angular.module('bigpanda.backend.services').service('UsersBackendService', UsersBackendService);

function UsersBackendService($http) {
  this.get = cacheTillFinished(getUsers);
  this.invite = invite;
  this.getRoles = getRoles;

  function getUsers() {
    return $http.get('/web/users/list').then((res) => res.data.item);
  }

  function invite(username, name, phoneNumber, roles, context) {
    return $http
      .post('/web/users/invite', {
        username: username.toLowerCase(),
        name: name,
        phone_number: phoneNumber,
        context: context,
        roles: roles,
      })
      .then((res) => res.data.item);
  }

  function getRoles() {
    return $http.get('/web/roles/list').then((res) => res.data.item);
  }

  return this;
}
