import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';
import { hot } from 'react-hot-loader';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'class-autobind';
import classnames from 'classnames';
import { BamMenu, BamDropDown } from '@bp/bam';
import { Avatar, darkTheme } from '@bp/kung-fu';
import { ThemeProvider } from 'styled-components';
import { docsUrl, universityUrl, communityUrl } from 'common/config';
import { logout } from 'common/auth';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import * as actions from '../actions';
import selectors from '../selectors';
import { Header, VBox, UserName, UserEmail } from './MenuComponents.css';

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    autobind(this, UserMenu.prototype);
  }

  componentDidMount() {
    if (!this.props.user) {
      this.props.loadUser();
    }
  }

  onHeaderClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { user = { email: '', name: '' }, featureToggles, className } = this.props;
    const menuOptions = [
      {
        icon: { className: 'bp-icon-user' },
        text: 'Personal Settings',
        onClick: () => {
          window.location.href = '/v2/user-preferences';
        },
      },
      {
        icon: { className: 'bp-icon-bigpandau' },
        text: 'University',
        onClick: () => {
          window.open(universityUrl);
        },
      },
      {
        icon: { className: 'bp-icon-users' },
        text: 'Community',
        onClick: () => {
          window.open(communityUrl);
        },
      },
      {
        icon: { className: 'bp-icon-file-document-text' },
        text: 'Docs',
        onClick: () => {
          window.open(docsUrl);
        },
      },
      {
        divider: true,
      },
      {
        icon: { className: 'bp-icon-power-off' },
        text: 'Logout',
        onClick: logout,
      },
    ];

    return (
      <ThemeProvider theme={darkTheme}>
        <div className={classnames(['bam', className])}>
          <BamMenu
            options={menuOptions}
            isDark
            header={() => (
              <React.Fragment>
                <Header
                  role="button"
                  tabIndex={0}
                  aria-label="User menu details"
                  onClick={this.onHeaderClick}
                  onKeyDown={this.onHeaderClick}
                >
                  <Avatar email={user.email} name={user.name} size="large" />

                  <VBox>
                    <UserName>{startCase(toLower(user.name))}</UserName>
                    <UserEmail>{user.email}</UserEmail>
                  </VBox>
                </Header>

                <BamDropDown.Divider />
              </React.Fragment>
            )}
            aria-label="User Menu"
          >
            <Avatar email={user.email} name={user.name} size="medium" />
          </BamMenu>
        </div>
      </ThemeProvider>
    );
  }
}

UserMenu.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  loadUser: PropTypes.func.isRequired,
  featureToggles: PropTypes.object.isRequired /* eslint-disable-line */,
  className: PropTypes.string,
};

UserMenu.defaultProps = {
  user: undefined,
};

function mapStateToProps(state) {
  return {
    user: selectors.getUser(state),
    featureToggles: featureTogglesSelectors.getFeatureToggles(state) || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => dispatch(actions.loadUser()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(UserMenu));
