import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { BamPreview } from '@bp/bam';
import { getUniqueTagSamples } from 'common/endpoints/tags';
import { buildParserAndGenerateQuery } from 'common/modules/settings/QuerySourceGenerator';
import styles from './custom_tag_preview.scss';
import { extractTemplateToTokens } from '../../../../../common/formulas';
import CustomTagPreviewItems from './CustomTagPreviewItems';

class CustomTagPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      definitionsChanged: false,
      loading: false,
      hasLoadedPreview: false,
      items: null,
      typeOfItems: null,
    };
  }

  componentDidMount() {
    this.handleChange();
  }

  componentDidUpdate(prevProps) {
    this.handleChange(prevProps);
  }

  getRefreshButtonState = () => {
    const { validForPreview } = this.props;
    const { refreshButtonStates } = BamPreview.Header;
    const { hasLoadedPreview } = this.state;

    if (!hasLoadedPreview) {
      return refreshButtonStates.HIDDEN;
    } else if (!validForPreview) {
      return refreshButtonStates.ERROR;
    }

    return refreshButtonStates.VISIBLE;
  };

  haveDefinitionsChanged(prevProps = {}) {
    const { source, template, integration, type, displayQuery, validForPreview } = this.props;
    const displayQueryChanged = prevProps.displayQuery !== displayQuery;
    const extractionDataChanged = prevProps.source !== source || displayQueryChanged;
    const compositionDataChanged =
      prevProps.template !== template ||
      prevProps.integration !== integration ||
      displayQueryChanged;

    return (
      (!prevProps.validForPreview && validForPreview) ||
      (prevProps.type !== type && validForPreview) ||
      (type === 'extraction' && extractionDataChanged) ||
      (type === 'composition' && compositionDataChanged)
    );
  }

  handleChange(prevProps) {
    const { validForPreview } = this.props;
    const { typeOfItems } = this.state;

    if (this.haveDefinitionsChanged(prevProps)) {
      if (validForPreview && !typeOfItems) {
        this.loadPreview();
      } else {
        this.setState({ definitionsChanged: true });
      }
    }
  }

  loadPreview = async () => {
    this.setState({ definitionsChanged: false, loading: true });
    const { source, displayQuery, type, template } = this.props;
    const integration = this.props.integration || '*';
    try {
      let tags;
      if (type === 'extraction') {
        tags = [source.toLowerCase()];
      } else {
        tags = extractTemplateToTokens(template)
          .filter((token) => token.type === 'tag')
          .map((token) => token.value.toLowerCase());
      }

      const generatedQuery = buildParserAndGenerateQuery(displayQuery, [integration]);
      const res = await getUniqueTagSamples({
        tags: tags,
        bpql: { parsed_object: generatedQuery },
        source_system: integration,
        resultsAmount: 50,
      });
      let items;
      // Composition custom tags might not have actual tags.
      // In that case, if we get a result of at least 1, we'll want to show a preview of the template instead.

      // Tags composite tags were cut off at 0 index and not only never parsed or considered any other template matches,
      // it cut off anything after the first match
      // since we request no more than 50 tag samples, there is also no need to slice them off at the array level.
      if (res.tags && res.tags.length) {
        items = res.tags;
      } else if (type === 'composition' && !res.tags.length && res.total > 0) {
        items = [];
      } else {
        items = null;
      }
      this.setState({ loading: false, items: items, typeOfItems: type, hasLoadedPreview: true });
    } catch (e) {
      this.setState({ loading: false, items: null, typeOfItems: null });
    }
  };

  render() {
    const { type, validForPreview, patternValid, pattern, template } = this.props;
    const { definitionsChanged, loading, items, typeOfItems } = this.state;

    const previewItems = typeOfItems === type ? items : null;
    return (
      <div className={styles['custom-tag-preview-container']}>
        <BamPreview
          header={
            <BamPreview.Header
              description={`(${previewItems ? previewItems.length || 1 : 0} Random Matches)`}
              refreshButtonState={this.getRefreshButtonState()}
              onRefresh={this.loadPreview}
              refreshNotice={definitionsChanged ? 'Definitions Changed' : null}
              refreshError="Validate the form to refresh the preview"
            />
          }
          loading={loading}
          emptyPreviewHeader={typeOfItems ? 'No Matches' : 'Incidents Preview'}
          emptyPreviewMessage={
            typeOfItems
              ? 'Adjust form definitions to preview values from matching alerts'
              : 'Fill out the form to see values from matching alerts'
          }
        >
          {previewItems && (
            <CustomTagPreviewItems
              type={type}
              items={items}
              pattern={pattern}
              template={template}
              validForPreview={validForPreview}
              patternValid={patternValid}
            />
          )}
        </BamPreview>
      </div>
    );
  }
}

CustomTagPreview.propTypes = {
  source: PropTypes.string,
  template: PropTypes.string,
  integration: PropTypes.string,
  displayQuery: PropTypes.string,
  type: PropTypes.string.isRequired,
  pattern: PropTypes.string,
  validForPreview: PropTypes.bool.isRequired,
  patternValid: PropTypes.bool.isRequired,
};

CustomTagPreview.defaultProps = {
  source: null,
  template: null,
  integration: null,
  displayQuery: null,
  pattern: null,
};

export default hot(module)(CustomTagPreview);
