angular.module('bigpanda').controller('guideController', guideController);

function guideController(
  $scope,
  stateService,
  $state,
  UserFeatureTogglesService,
  Config,
  integrationGuideService,
  $timeout,
  $stateParams
) {
  const vm = this;
  vm.documentationTabsSubTitle = null;
  const activeTab = $stateParams.activeTab ? `&activeTab=${$stateParams.activeTab}` : '';
  let sourceSystemForTutorial = stateService.getSelectedGuideId();
  if (!sourceSystemForTutorial) {
    sourceSystemForTutorial = normalize(
      $scope.currentIntegration.source_system || stateService.getSelectedIntegrationId()
    );
    return $state.go(
      'app.integrationInfo.instructions.guide',
      { guide: sourceSystemForTutorial },
      { location: 'replace' }
    );
  } else if (!isTutorialMatchIntegration(sourceSystemForTutorial)) {
    sourceSystemForTutorial = normalize($scope.currentIntegration.source_system);
    return $state.go(
      'app.integrationInfo.instructions.guide',
      { guide: sourceSystemForTutorial },
      { location: 'replace' }
    );
  }

  vm.iframeOriginUrl = Config.integrationsIframeUrl;
  vm.integrationUrl = createIntegrationUrl();

  vm.handleIntegrationCreated = (val) => {
    $state.go('app.integrationInfo.instructions.guide', { integration: normalize(val) });
  };

  function isTutorialMatchIntegration(srcSystemForTutorial) {
    if ($scope.currentIntegration.guides) {
      let ret = false;
      $scope.currentIntegration.guides.forEach((guide) => {
        if (guide.name === srcSystemForTutorial) {
          ret = true;
        }
      });

      return ret;
    }
    return sourceSystemForTutorial === normalize($scope.currentIntegration.source_system);
  }

  function createIntegrationUrl() {
    const selectedGuide = stateService.getSelectedGuideId();
    const getSelectedIntegration = stateService.getSelectedIntegrationId();

    let url = `${Config.integrationsIframeUrl}/instructions/${selectedGuide}`;

    if (getSelectedIntegration && getSelectedIntegration !== selectedGuide) {
      url += `/${getSelectedIntegration}`;
    }

    url += `?cacheKey=${Date.now()}${activeTab}`;

    return url;
  }

  function normalize(sourceSystem) {
    return sourceSystem.replace(' ', '');
  }

  return null;
}
