import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HBox, Button } from '@bp/kung-fu';

const LogoutButton = ({ disabled, updateAndLogout }) => {
  return (
    <Wrapper>
      <Button variant="secondary" size="medium" onClick={updateAndLogout} disabled={disabled}>
        Logout and Validate
      </Button>
    </Wrapper>
  );
};

export default LogoutButton;

LogoutButton.propTypes = {
  updateAndLogout: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const Wrapper = styled(HBox)`
margin-top: 20px;
`;
