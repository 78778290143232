import values from 'lodash/values';
import extend from 'lodash/extend';
angular
  .module('bigpanda.reports')
  .controller(
    'ReportExportDialogController',
    function ($window, $document, pubSubService, ModalService, ReportSnapshotsBackendService) {
      const vm = this;

      vm.copyNotSupported = false;
      vm.generatedUrl = '';
      vm.linkCopied = false;
      vm.submitError = false;
      vm.submitFinished = false;
      vm.submitInProgress = false;

      vm.copyLink = copyLink;
      vm.generateLink = generateLink;
      vm.hide = ModalService.hide;

      function copyLink() {
        // See https://developers.google.com/web/updates/2015/04/cut-and-copy-commands?hl=en
        const document = $document[0];
        const linkEl = document.querySelector('.link-text');
        const selectionRange = document.createRange();

        $window.getSelection().removeAllRanges();

        linkEl.focus();
        selectionRange.selectNode(linkEl);
        $window.getSelection().addRange(selectionRange);

        try {
          const success = document.execCommand('copy');

          if (!success) {
            vm.copyNotSupported = true;
          } else {
            vm.linkCopied = true;
            $window.getSelection().removeAllRanges();
          }
        } catch (e) {
          vm.copyNotSupported = true;
        }
      }

      function generateLink() {
        vm.submitInProgress = true;
        vm.submitError = false;

        const overrides = {
          environment_id: vm.environment.id,
          timeframe: extend({}, vm.timeframePreset.timeframe),
          widgets: values(vm.widgets),
        };

        ReportSnapshotsBackendService.createSnapshot(vm.report._id, overrides).then(
          (url) => {
            vm.submitInProgress = false;

            pubSubService.broadcast(
              'reportWidgetsController.refreshReportSnapshots',
              vm.report._id
            );

            vm.generatedUrl = url;
            vm.submitFinished = true;
          },
          () => {
            vm.submitInProgress = false;
            vm.submitError = true;
          }
        );
      }
    }
  );
