import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import get from 'lodash/get';
import * as colors from '@bp/pastel/colors';
import cn from 'classnames';
import { Wrapper, Title, ErrorMessage } from './Combobox.css';
import { defaultItemToString, filterItems, getFlatItems } from '../Select/utils';
import { itemPropTypes } from '../Select/propTypes';
import { SelectMenu } from '../Select/components';

const Combobox = ({
  value,
  items,
  getItemDisplayValue,
  multiChoice,
  placeholder,
  title,
  fullWidth,
  metadata,
  error,
  disabled,
  onChange,
  inline,
  errorMessage,
  openToLeft,
  ariaLabel,
  openToTop,
}) => {
  const inputRef = React.useRef();
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(items);
  const selectedItem = allItems.find((item) => get(item, 'value') === value) || null;
  const selectedValue = get(selectedItem, 'value');

  const {
    getMenuProps,
    getItemProps,
    getInputProps,
    getComboboxProps,
    isOpen,
    openMenu,
    closeMenu,
    selectItem,
  } = useCombobox({
    items: getFlatItems(filteredItems),
    disabled,
    selectedItem,
    itemToString: getItemDisplayValue,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(get(selectedItem, 'value'), metadata, selectedItem);
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: getItemDisplayValue(selectedItem),
          };
        default:
          return changes;
      }
    },
    onInputValueChange: ({ inputValue }) => {
      const newFilteredItems = filterItems(items, getItemDisplayValue, inputValue);
      setFilteredItems(newFilteredItems);
    },
  });

  useEffect(() => {
    setAllItems(getFlatItems(items));
    const newFilteredItems = filterItems(items, getItemDisplayValue, selectedValue);
    setFilteredItems(newFilteredItems);
  }, [items]);

  return (
    <Wrapper disabled={disabled} fullWidth={fullWidth} aria-label={ariaLabel}>
      {title && <Title>{title}</Title>}
      <div
        {...getComboboxProps()}
        className="ui form bam-base-input-container"
        style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
      >
        <i
          className="bp-icon bp-icon-arrow-down"
          style={{
            fontSize: '16px',
            pointerEvents: 'none',
            color: colors.bp_gray_06,
            position: 'absolute',
            lineHeight: '0',
            paddingRight: '10px',
          }}
        />
        <input
          style={{
            padding: '0 30px 0 7px',
          }}
          disabled={disabled}
          placeholder={placeholder}
          aria-label={ariaLabel}
          className={cn('bam-base-input', { error })}
          {...getInputProps({
            onFocus: (e) => {
              e.target.select();
              openMenu();
            },
            ref: inputRef,
          })}
        />
      </div>
      <SelectMenu
        selectItem={selectItem}
        isOpen={isOpen}
        filteredItems={filteredItems}
        getItemDisplayValue={getItemDisplayValue}
        multiChoice={multiChoice}
        openToTop={openToTop}
        openToLeft={openToLeft}
        selectedValue={selectedValue}
        shouldShowSearch={false}
        getItemProps={getItemProps}
        getMenuProps={getMenuProps}
        inline={inline}
        closeMenu={closeMenu}
        onSelect={() => {
          inputRef.current.focus();
        }}
      />
      {!isOpen && error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

Combobox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...itemPropTypes,
      items: PropTypes.arrayOf(PropTypes.shape(itemPropTypes)),
    })
  ).isRequired,
  getItemDisplayValue: PropTypes.func,
  multiChoice: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  metadata: PropTypes.shape({}),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  errorMessage: PropTypes.string,
  openToLeft: PropTypes.bool,
  openToTop: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

Combobox.defaultProps = {
  getItemDisplayValue: defaultItemToString,
  fullWidth: false,
  errorMessage: null,
  error: false,
  openToTop: false,
  openToLeft: false,
  ariaLabel: null,
  disabled: false,
};

export default Combobox;
