import includes from 'lodash/includes';
import get from 'lodash/get';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import permissions from 'common/routing/permissions';
import { curry, identity, compose } from 'lodash/fp';
import orderBy from 'lodash/orderBy';
import boolToLodashOrder from 'common/utils/boolToLodashOrder';
import { filterItemsBySelectedValue } from 'react/common/components/ConfigurationLabels/ConfigurationLabelsFilter/utils';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { path, ANY_OPTION_VALUE } from './constants';
import { generateBaseSelectors, filterItems, filterItemsByDropDownFilters } from '../redux.utils';
import { DEFAULT_SORT_MODE } from './correlation_patterns_list/constants';

const correlationPatternsFilterByText = (text, items) =>
  filterItems(items, text, (correlationPattern) => [
    correlationPattern.metadata.suggested ? 'suggested' : null,
    ...correlationPattern.tagNames,
    correlationPattern.displayQuery,
    ...correlationPattern.sourceSystems.map((sourceSystem) => sourceSystem.display),
    correlationPattern.metadata.description,
  ]);

const correlationPatternsFilterByDropDown = (filtersDropDownState, items) =>
  filterItemsByDropDownFilters(
    filtersDropDownState,
    {
      tag: (item) => [...item.tagNames],
      source: (item) => item.sourceSystems.map((d) => d.value),
      attribute: (item) => [item.cross_source ? 'cross-source' : 'suggested'],
      status: (item) => [item.status],
    },
    items,
    ANY_OPTION_VALUE
  );

const correlationPatternsSort = ({ field, ascending }, items) => {
  if (!items) {
    return items;
  }
  const { field: defaultField, ascending: defaultOrder } = DEFAULT_SORT_MODE;

  return orderBy(items, [field, defaultField], [ascending, defaultOrder].map(boolToLodashOrder));
};

const selectors = generateBaseSelectors(path);

selectors.getActionsPermissions = (state) => {
  const userPermissions = permissionsSelectors.getPermissionsList(state);
  const hasEditPermission = userPermissions.includes(permissions.correlations.update);
  const hasDuplicatePermission = userPermissions.includes(permissions.correlations.create);
  const hasDeletePermission = userPermissions.includes(permissions.correlations.delete);
  return { hasEditPermission, hasDuplicatePermission, hasDeletePermission };
};

selectors.getCorrelationPatterns = (
  state,
  {
    shouldFilterByLabels = false,
    shouldFilterByText = true,
    shouldFilterByDropDownFilters = true,
    shouldSort = true,
  } = {}
) => {
  return compose(
    shouldSort ? curry(correlationPatternsSort)(selectors.getSortMode(state)) : identity,
    shouldFilterByDropDownFilters
      ? curry(correlationPatternsFilterByDropDown)(selectors.getFiltersDropDownState(state))
      : identity,
    shouldFilterByText
      ? curry(correlationPatternsFilterByText)(selectors.getFilterText(state))
      : identity,
    shouldFilterByLabels
      ? curry(filterItemsBySelectedValue)(selectors.getLabelsFilterSelectedItem(state))
      : identity
  )(get(state, path).correlationPatterns);
};

selectors.getCorrelationPattern = (state, id, { isLabelsFilterEnabled } = {}) => {
  const correlationPatterns = selectors.getCorrelationPatterns(state, {
    shouldFilterByLabels: isLabelsFilterEnabled,
  });
  if (!correlationPatterns || correlationPatterns.length === 0) {
    return null;
  }
  return correlationPatterns.filter((correlationPattern) => correlationPattern.id === id)[0];
};

selectors.areConfigurationLabelsEnabled = (state) => {
  const featureToggles = featureTogglesSelectors.getFeatureToggles(state) || {};
  return includes(featureToggles.enable_configuration_labels, 'correlation_patterns');
};

selectors.getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;

export default selectors;
