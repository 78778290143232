import set from 'lodash/set';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import indexOf from 'lodash/indexOf';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import { isTagTypeIsClosedListMap } from '../../../../../../workspaces/apps/incident-tags/src/IncidentTagsGridCellV2/constants';

angular.module('bigpanda').controller('EditorRulesCtrl', EditorRulesCtrl);

function EditorRulesCtrl($timeout, SiftService, pubSubService, UserFeatureTogglesService) {
  const vm = this;

  vm.removeRule = removeRule;
  vm.refreshFcn = refreshFcn;
  vm.andOperatorChanged = andOperatorChanged;
  vm.helpAnd = helpAnd;
  vm.removeAnd = removeAnd;
  vm.isAndValid = isAndValid;
  vm.addAnd = addAnd;
  vm.showOr = showOr;
  vm.showAddRule = showAddRule;
  vm.addRule = addRule;
  vm.setChecksAndRefresh = setChecksAndRefresh;
  vm.refresh = debounce(refreshFcn, 400, true);
  vm.onTagChange = onTagChange;
  vm.onValueChange = onValueChange;
  vm.onOperatorChange = onOperatorChange;
  const envWithIncidentTagsFT = UserFeatureTogglesService.getToggle('env_with_incident_tags');
  vm.envWithIncidentTagsFT = envWithIncidentTagsFT === null ? true : envWithIncidentTagsFT;
  const priorityTagId = 'itd_priority_1';
  const assigneeId = 'assignee';

  function refreshScrolling() {
    pubSubService.broadcast('BPScrollbar.ContentChanged');
  }

  function removeRule(rule) {
    const ruleIndex = vm.rules.indexOf(rule);
    vm.rules = filter(vm.rules, (ruleToFilter) => rule !== ruleToFilter);
    vm.incidentTagIsSelectedByRule.splice(ruleIndex, 1);
    vm.refresh();
  }

  function refreshFcn() {
    if (vm.isNotPreviewRefresh()) return;
    const editorFilter = JSON.stringify(SiftService.rulesToFilter(vm.rules, true, null));
    pubSubService.broadcast('editorPreview.refresh', editorFilter);
    refreshScrolling();
  }

  function andOperatorChanged(and) {
    if (!and.operator) {
      and.placeholder = '';
    } else {
      const placeHolderPerOperator = {
        $nin: 'host1.domain.com,host2.domain.com',
        $eq: 'host1.domain.com or host*.domain.com',
      };

      placeHolderPerOperator.$in = placeHolderPerOperator.$nin;
      placeHolderPerOperator.$ne = placeHolderPerOperator.$eq;

      and.placeholder = placeHolderPerOperator[and.operator];
      vm.refresh();
    }
  }

  function helpAnd(and) {
    const content =
      "When using the <b>'Equal To'</b> or <b>'Not Equal To'</b> operators, enter the value you want to match, <br/><b>E.g.</b> <i>'host1.domain.com'</i>.<br/> You can use an asterisk (*) as a wildcard. <br/><b>E.g.</b><br/> <i>'*.somehost.suffix'</i> or <i>'db-*.domain.com'</i>.<br/><br/>When using the <b>'In List'</b> or <b>'Not In List'</b> operators, enter a comma-separated list of the exact values to match. <br/><b>E.g.</b> <br/>'<i>host1.domain.com,host2.domain.com'</i><br/><br/>Wildcards are not supported for <b>'In'</b> and <b>'Not In'</b> operators.";
    const title = 'Filter value help';
    vm.showModal(title, content);
  }

  function removeAnd(and, rule) {
    rule.ands = filter(rule.ands, (an) => an !== and);
    const incidentTagIsSelected = rule.ands.some((and) => and.type !== 'ALERT_TAGS');
    const ruleIndex = vm.rules.indexOf(rule);
    set(vm.incidentTagIsSelectedByRule, [ruleIndex], incidentTagIsSelected);
    vm.refresh();
  }

  function isAndValid(and, rule) {
    if (and && and.check && and.operator && and.value) {
      if (and.type !== 'ALERT_TAGS') return true;
      const andCheck = normalizeCheck(and.check);
      const isCheckExists = rule.checks.find((check) => {
        if (!check || !check.value) {
          return false;
        }
        return check.value.toLowerCase() === andCheck.toLowerCase();
      });

      if (!isCheckExists) {
        and.check = null;
        return false;
      }
      return true;
    }
  }

  function normalizeCheck(check) {
    let normalizedCheck = check.toLowerCase();
    if (startsWithSingleUnderscore(normalizedCheck)) {
      normalizedCheck = normalizedCheck.substring(1);
    }
    return normalizedCheck;
  }

  function startsWithSingleUnderscore(str) {
    return str !== '_' && str.startsWith('_') && !str.startsWith('__');
  }

  function addAnd(rule) {
    rule.ands.push({
      check: null,
      placeholder: '',
      isPrioritySelected: false,
      isAssigneeSelected: false,
      operators: vm.operators,
      ruleTypeList: false,
      isClosedListSelected: false,
      closedListValues: rule.closedListValues,
      incidentTagType: rule.incidentTagType,
    });
  }

  function showOr(rule) {
    if (!vm.rules && vm.rules.length === 0) {
      return false;
    }

    let ruleIndex = indexOf(vm.rules, rule);
    if (ruleIndex === 0 && vm.rules.length === 1) {
      return rule.source;
    }

    return rule.source || vm.rules[++ruleIndex];
  }

  function showAddRule() {
    return vm.rules.length > 0 && vm.rules[vm.rules.length - 1].source;
  }

  function addRule() {
    vm.rules.push({
      warnings: true,
      acknowledged: false,
      source: null,
      ands: [],
      checks: [],
    });
    vm.incidentTagIsSelectedByRule.push(false);
  }

  function setChecksAndRefresh(rule) {
    vm.setChecks(rule);
    vm.refresh();
  }

  function compareTagChangesAndUpdate({
    ruleIndex,
    andIndex,
    isPrioritySelected,
    isAssigneeSelected,
    isClosedListSelected,
    closedListValues,
    incidentTagType,
    operators,
  }) {
    const prevRule = get(vm.rules, [ruleIndex, 'ands', andIndex]);

    const propertiesToCompare = {
      isPrioritySelected,
      isAssigneeSelected,
      isClosedListSelected,
      closedListValues,
    };

    const isRuleChanged = Object.entries(propertiesToCompare).some(
      ([key, value]) => prevRule[key] !== value
    );

    if (isRuleChanged) {
      const ruleValuesToUpdate = {
        incidentTagType,
        closedListValues,
        isClosedListSelected,
        isPrioritySelected,
        isAssigneeSelected,
        operators,
        value: null,
        operatorObject: null,
      };

      for (const [key, val] of Object.entries(ruleValuesToUpdate)) {
        set(vm.rules, [ruleIndex, 'ands', andIndex, key], val);
      }
    }
  }

  function onTagChange(tag, metadata) {
    const { ruleIndex, andIndex } = metadata || {};
    const isPrioritySelected = tag.id === priorityTagId;
    const isAssigneeSelected = tag.id === assigneeId;
    const isClosedListSelected = isTagTypeIsClosedListMap[tag.incidentTagType];

    const closedListValues = tag.closedListValues;
    let operators = vm.operators;

    if (isPrioritySelected || isClosedListSelected)
      operators = filter(vm.operators, (operator) => operator.type === 'list');

    if (!isUndefined(ruleIndex) && !isUndefined(andIndex)) {
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'tag'], { id: tag.id, type: tag.type });
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'check'], tag.id);
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'type'], tag.type);
      compareTagChangesAndUpdate({
        ruleIndex,
        andIndex,
        incidentTagType: tag.incidentTagType,
        isPrioritySelected,
        isAssigneeSelected,
        isClosedListSelected,
        closedListValues,
        operators,
      });

      if (tag.type !== 'ALERT_TAGS') {
        set(vm.incidentTagIsSelectedByRule, [ruleIndex], true);
      } else {
        const incidentTagIsSelected = get(vm.rules, [ruleIndex, 'ands']).some(
          (and) => and.type !== 'ALERT_TAGS'
        );
        set(vm.incidentTagIsSelectedByRule, [ruleIndex], incidentTagIsSelected);
      }
      $timeout(vm.refresh);
    }
  }

  function onValueChange(value, metadata, isAssignee = false) {
    const { ruleIndex, andIndex } = metadata || {};
    if (isAssignee) {
      const strValue = value.map((val) => val.id).join();
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'strValue'], strValue);
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'objValue'], value);
    }
    if (!isUndefined(ruleIndex) && !isUndefined(andIndex)) {
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'value'], value);
      $timeout(vm.refresh);
    }
  }

  function onOperatorChange(operator, metadata) {
    const { ruleIndex, andIndex } = metadata || {};
    const ruleTypeList = operator.type === 'list';
    if (!isUndefined(ruleIndex) && !isUndefined(andIndex)) {
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'ruleTypeList'], ruleTypeList);
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'operatorObject'], operator);
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'operator'], operator.id);

      const { isAssigneeSelected, strValue, objValue } = get(vm.rules, [
        ruleIndex,
        'ands',
        andIndex,
      ]);
      if (isAssigneeSelected) {
        const newValue = ruleTypeList ? objValue : strValue;
        set(vm.rules, [ruleIndex, 'ands', andIndex, 'value'], newValue);
      }

      const placeHolderPerOperator = {
        $in: 'host1.domain.com,host2.domain.com',
        $nin: 'host1.domain.com,host2.domain.com',
        $eq: 'host1.domain.com or host*.domain.com',
        $ne: 'host1.domain.com or host*.domain.com',
      };

      const placeholder = placeHolderPerOperator[operator.id];
      set(vm.rules, [ruleIndex, 'ands', andIndex, 'placeholder'], placeholder);

      $timeout(vm.refresh);
    }
  }

  $timeout(vm.refresh);
}
