import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import * as colors from '@bp/pastel/colors';

const Container = styled.div`
  display: flex;
  height: 100%;
  background-color: ${colors.bp_gray_01};
`;

const MainPane = styled.div`
  flex: 1;
`;

const Divider = styled.div`
  background-color: ${colors.bp_gray_02};
  width: 6px;
  display: block;
`;

const DetailsPane = styled.div`
  flex: 1.4;
  display: flex;
  justify-content: center;
`;

const SplitLayout = ({ mainPane, detailsPane }) => {
  return (
    <Container>
      <MainPane>{mainPane}</MainPane>
      <Divider />
      <DetailsPane>{detailsPane}</DetailsPane>
    </Container>
  );
};

SplitLayout.propTypes = {
  mainPane: PropTypes.node,
  detailsPane: PropTypes.node,
};

export default hot(module)(SplitLayout);
