import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './navigation_link.scss';

const NavigationLink = ({ children, className, href }) => (
  <a href={href} className={classnames(className, styles['navigation-link'])}>
    {children}
  </a>
);

NavigationLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default NavigationLink;
