import React from 'react';
import PropTypes, { string } from 'prop-types';
import get from 'lodash/get';
import { hot } from 'react-hot-loader';
import { EditIcon, DuplicateIcon } from '@bp/kung-fu';
import { BamConfirmModal, BamActionButtons } from '@bp/bam';
import upperFirst from 'lodash/upperFirst';

const shouldRenderAction = (actionProps) =>
  actionProps && (!actionProps.disabled || actionProps.showIfDisabled);

const SettingsListItemActions = ({
  item,
  modelName,
  children,
  editProps,
  duplicateProps,
  deleteProps,
  messages,
}) => {
  const { BamTileActionButtons } = BamActionButtons;

  const capitalizedModelName = upperFirst(modelName);

  const { modal: EditModal } = editProps || {};
  const { modal: DuplicateModal } = duplicateProps || {};
  const itemName = get(item, 'name', '');

  const renderEditActionButton = () => (
    <div aria-label={editProps.ariaLabel || `edit ${itemName}`} role="button">
      <EditModal
        triggerProps={{
          variant: 'action-container',
          size: 'medium',
          icon: <EditIcon />,
          disabled: editProps.disabled,
          tooltipProps: {
            isActive: true,
            text: editProps.disabled ? messages.edit : `Edit ${capitalizedModelName} details`,
            placement: 'bottom-end',
          },
        }}
        title={`Edit ${capitalizedModelName}`}
        titleIcon="bp-icon-edit"
        {...editProps.modalProps}
      />
    </div>
  );

  const renderDuplicateActionButton = () => {
    const {
      duplicate: disabledDuplicateTooltipText,
      enabledDuplicateTooltipText = `Duplicate ${capitalizedModelName}`,
    } = messages;

    return (
      <div aria-label={duplicateProps.ariaLabel || `duplicate ${itemName}`} role="button">
        <DuplicateModal
          triggerProps={{
            variant: 'action-container',
            icon: <DuplicateIcon />,
            disabled: duplicateProps.disabled,
            tooltipProps: {
              isActive: true,
              placement: 'bottom-end',
              text: duplicateProps.disabled
                ? disabledDuplicateTooltipText
                : enabledDuplicateTooltipText,
            },
          }}
          title={`Duplicate ${capitalizedModelName}`}
          titleIcon="bp-icon-duplicate"
          duplicate
          {...duplicateProps.modalProps}
        />
      </div>
    );
  };

  const renderDeleteActionButton = () => (
    <div aria-label={deleteProps.ariaLabel || `delete ${itemName}`} role="button">
      <BamConfirmModal
        icon="bp-icon-trash"
        headerTitle={`Delete ${capitalizedModelName}`}
        content={`Are you sure you want to delete this ${modelName}?`}
        contentTitle={item && item.name ? item.name : ''}
        contentTitleType={deleteProps.contentTitleType}
        buttonProps={{
          type: 'cta-action',
          disabled: deleteProps.disabled,
          iconClass: 'bp-icon-trash',
          tooltipText: deleteProps.disabled ? messages.delete : `Delete ${capitalizedModelName}`,
          tooltipPosition: 'bottom right',
        }}
        action={deleteProps.action}
        {...deleteProps.modalProps}
      />
    </div>
  );

  return (
    <BamTileActionButtons>
      {shouldRenderAction(editProps) && renderEditActionButton()}
      {shouldRenderAction(duplicateProps) && renderDuplicateActionButton()}
      {shouldRenderAction(deleteProps) && renderDeleteActionButton()}
      {children}
    </BamTileActionButtons>
  );
};

const ActionProps = PropTypes.shape({
  disabled: PropTypes.bool,
  showIfDisabled: PropTypes.bool,
  modalProps: PropTypes.shape({}),
  modal: PropTypes.func,
  action: PropTypes.func,
  contentTitleType: PropTypes.string,
  ariaLabel: PropTypes.string,
});

SettingsListItemActions.propTypes = {
  item: PropTypes.object.isRequired /* eslint-disable-line */,
  modelName: PropTypes.string.isRequired,
  editProps: ActionProps,
  duplicateProps: ActionProps,
  deleteProps: ActionProps,
  children: PropTypes.node,
  messages: PropTypes.object /* eslint-disable-line */,
};

SettingsListItemActions.defaultProps = {
  messages: {},
  editProps: null,
  duplicateProps: null,
  deleteProps: null,
  children: null,
};

export default hot(module)(SettingsListItemActions);
