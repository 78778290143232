import 'moment-precise-range-plugin';

import { BamEmptyMessage, BamTile } from '@bp/bam';
import { withReactQueryClient } from '@bp/data-access';
import { darkTheme, StatusEnum } from '@bp/kung-fu';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import permissions from '../../../../../app/common/routing/permissions';
import SettingsInfoScreen from '../../../../../app/react/layout/settings/SettingsInfoScreen';
import MaintenancePlansActions from '../../../../../app/react/modules/settings/maintenance_plans/actions/MaintenancePlansActions';
import selectors from '../../../../../app/react/modules/settings/maintenance_plans/maintenance_plan_info/selectors';
import { selectors as permissionsSelectors } from '../../../../../app/react/user/permissions';
import provideStore from '../../../../../app/react/utils/provideStore';
import { MaintenancePlan } from '../../types/MaintenancePlan';
import { User } from '../../types/User';
import { Activity } from './info/Activity';
import { InProgressActionButtons } from './info/InProgressActionButtons';
import { MaintenancePlanInfoContent } from './info/MaintenancePlanInfoContent';
import { RegularActionButtons } from './info/RegularActionButtons';

interface IProps {
  planId: string;
}

function MaintenancePlanInfo({ planId }: IProps): JSX.Element {
  const plan: MaintenancePlan = useSelector((state) => selectors.getPlanById(state, planId));
  if (!planId || !plan) {
    return (
      <BamEmptyMessage header="No plan selected">
        Select a maintenance plan to see details.
      </BamEmptyMessage>
    );
  }

  return <MaintenancePlanInfoWrapper planId={planId} />;
}

function MaintenancePlanInfoWrapper({ planId }: IProps): JSX.Element {
  const plan: MaintenancePlan = useSelector((state) => selectors.getPlanById(state, planId));
  const userPermissions: string[] = useSelector(permissionsSelectors.getPermissionsList);
  const user: User = useSelector((state) => selectors.getUserById(state, plan.created_by));
  const updatedBy: User = useSelector((state) => selectors.getUserById(state, plan.updated_by));

  const momentStart = moment.unix(plan.start).seconds(0);
  const momentEnd = moment.unix(plan.end).seconds(0);
  const endsIn = selectors.calcDiff(momentEnd, moment());

  const isInternalPlan = plan.maintenance_key.startsWith('MNT');
  const inProgress = plan.status === StatusEnum.INPROGRESS;
  const hasEditPermission = userPermissions.includes(permissions.plans.update);
  const isPlanEditable = plan.status === StatusEnum.PLANNED && isInternalPlan;
  const canEdit = hasEditPermission && isPlanEditable;
  const canStop = hasEditPermission && plan.status === StatusEnum.INPROGRESS;

  const actionButtons = inProgress && isInternalPlan
    ? <InProgressActionButtons plan={plan} endsIn={endsIn} canStop={canStop} />
    : <RegularActionButtons plan={plan} canEdit={canEdit} />;

  return (
    <ThemeProvider theme={darkTheme}>
      <SettingsInfoScreen
        topActions={<MaintenancePlansActions plan={plan} />}
        header={<BamTile bamStatus={plan.status} headerRow={{ title: plan.name }} />}
        content={(
          <MaintenancePlanInfoContent
            momentStart={momentStart}
            momentEnd={momentEnd}
            plan={plan}
          />
        )}
        actions={(
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {actionButtons}
          </>
        )}
        activity={<Activity user={user} plan={plan} updatedBy={updatedBy} />}
      />
    </ThemeProvider>
  );
}

export default provideStore(withReactQueryClient(MaintenancePlanInfo));
