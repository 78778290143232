import { path } from './constants';
import { generateBaseSettingsActionPaths } from '../redux.utils';

const actionTypes = generateBaseSettingsActionPaths(path);

actionTypes.UPDATE_USER = `${path}.UPDATE_USER`;
actionTypes.DELETE_USER = `${path}.DELETE_USER`;
actionTypes.REACTIVATE_USER = `${path}.REACTIVATE_USER`;
actionTypes.RESEND_USER_INVITATION = `${path}.RESEND_USER_INVITATION`;
actionTypes.RESEND_USER_INVITATION_COMPLETED = `${path}.RESEND_USER_INVITATION_COMPLETED`;
actionTypes.UPDATE_OR_INVITE_USER_SUCCESS = `${path}.UPDATE_OR_INVITE_USER_SUCCESS`;
actionTypes.UPDATE_OR_INVITE_USER_FAILURE = `${path}.UPDATE_OR_INVITE_USER_FAILURE`;
actionTypes.GET_ROLES = `${path}.GET_ROLES`;
actionTypes.GET_ROLES_SUCCESS = `${path}.GET_ROLES_SUCCESS`;
actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS = `${path}.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS`;
actionTypes.UPDATE_FILTERS_DROPDOWNS = `${path}.UPDATE_FILTERS_DROPDOWNS`;

export default actionTypes;
