import styled from 'styled-components';
import * as colors from '@bp/pastel/colors';

export const Wrapper = styled.div`
  height: 22px;
  margin: 4px 5px 1px 0;
  padding: 4px 7px 5px 7px;
  background: ${colors.bp_gray_01_5};
  border-radius: 3px;
  border: 1px solid ${colors.bp_gray_03};
  font-size: 12px;
`;

export const Label = styled.div``;

export const RemoveIcon = styled.i`
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: ${colors.bp_gray_05};
  &:hover {
    color: ${colors.bp_gray_07};
  }
`;
