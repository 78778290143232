import React from 'react';
import { hot } from 'react-hot-loader';
import { Field } from 'react-final-form';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import autobind from 'class-autobind';
import { BamForm, BamInput, BamMultiselect } from '@bp/bam';
import styles from '../roles_management.scss';
import { ENVIRONMENT_PERMISSIONS } from '../../constants';

class RoleForm extends React.Component {
  constructor(props) {
    super(props);

    autobind(this, RoleForm.prototype);
  }

  getEnvironmentPermissions() {
    const globalEnvironmentPermissions = ENVIRONMENT_PERMISSIONS.map((item) => ({
      key: item,
      text: item,
      value: item,
    }));
    const existingEnvironmentPermissions = this.props.permissionsAndEnvironments.environments.reduce(
      (acc, env) => [
        ...acc,
        {
          key: `${capitalize(env.name)}_Incident_Actions`,
          text: `${capitalize(env.name)}_Incident_Actions`,
          value: `${env._id}_Incident_Actions`,
        },
        {
          key: `${capitalize(env.name)}_Read`,
          text: `${capitalize(env.name)}_Read`,
          value: `${env._id}_Read`,
        },
      ],
      []
    );

    const concattedEnvironmentOptions = [
      ...globalEnvironmentPermissions,
      { isDivider: true },
      ...existingEnvironmentPermissions,
    ];
    return concattedEnvironmentOptions;
  }

  handleSubmit(values, form) {
    const state = form.getState();
    const {
      currentRoleId,
      duplicate,
      close,
      permissionsAndEnvironments,
      createOrUpdateRole,
    } = this.props;
    if (currentRoleId && !duplicate) {
      // Edit flow
      if (!state.dirty) {
        close();
        return;
      }
    }

    const createOrUpdatePayload = {
      role: { ...values },
      global: { ...permissionsAndEnvironments },
      dirtyFields: state.dirtyFields,
    };

    if (!duplicate) {
      createOrUpdatePayload.role._id = currentRoleId;
    }

    createOrUpdateRole(createOrUpdatePayload);
    close();
  }

  render() {
    const {
      roles,
      initialValues,
      permissionsAndEnvironments,
      users,
      currentRoleId,
      positiveButtonText,
      close,
    } = this.props;

    const validateName = (val) => {
      if (!val || val.length < 2) {
        return 'Enter a role name with at least two characters';
      }

      const sameRoleAccordingToName = roles.find((r) => r.name === val);
      if (sameRoleAccordingToName && sameRoleAccordingToName._id !== currentRoleId) {
        return 'A role with this name already exists';
      }
      return undefined;
    };

    return (
      <BamForm
        id="RoleInputForm"
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        positiveButton={{ text: positiveButtonText }}
        closeButton={{ text: 'Cancel', onClick: close }}
        scrollable
      >
        <Field
          name="name"
          aria-label="Role Name"
          component={BamInput}
          title="Role Name"
          placeholder=""
          autoFocus
          validate={validateName}
        />
        <Field
          name="users"
          component={BamMultiselect}
          options={users ? mapResourcesToSelectView(users) : []}
          title="Users"
          optional
          placeholder=""
          scrollToView
        />
        <div className={styles['header-separator']} />
        <Field
          name="permissions"
          component={BamMultiselect}
          options={
            permissionsAndEnvironments
              ? generatePermissionOptions(permissionsAndEnvironments.permissions)
              : []
          }
          title="Permissions"
          optional
          placeholder=""
          scrollToView
        />
        <Field
          name="environments"
          component={BamMultiselect}
          options={permissionsAndEnvironments ? this.getEnvironmentPermissions() : []}
          title="Environment Access"
          optional
          placeholder=""
          scrollToView
        />
      </BamForm>
    );
  }
}

const generatePermissionOptions = (permissions) => {
  const reducer = (arr, resourceType) => {
    const capitalizedResourceType = capitalize(resourceType);
    return [...arr, `${capitalizedResourceType}_Read`, `${capitalizedResourceType}_Full_Access`];
  };

  const resourceTypes = [
    ...new Set(permissions.filter(filterPermissions).map((item) => item.resource_type)),
  ];
  const permissionOpionsArray = resourceTypes.reduce(reducer, []);
  const uniquePermissionOptionsArray = [...new Set(permissionOpionsArray)];

  const permissionOptions = uniquePermissionOptionsArray.map((permissionOption) => ({
    key: permissionOption,
    text: permissionOption,
    value: permissionOption,
  }));

  return permissionOptions;
};

const mapResourcesToSelectView = (list, textProperty = 'name', filterFunc = () => true) => {
  if (!list) {
    return [];
  }
  return list.filter(filterFunc).map((listItem) => ({
    key: listItem._id,
    text: listItem[textProperty],
    value: listItem._id,
  }));
};

const filterPermissions = (item) => item.resource_type !== 'environments';

RoleForm.propTypes = {
  createOrUpdateRole: PropTypes.func.isRequired,
  currentRoleId: PropTypes.string,
  duplicate: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  permissionsAndEnvironments: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
  positiveButtonText: PropTypes.string,
  close: PropTypes.func.isRequired,
};

RoleForm.defaultProps = {
  currentRoleId: undefined,
  duplicate: false,
  users: undefined,
  roles: undefined,
  initialValues: undefined,
  permissionsAndEnvironments: undefined,
  positiveButtonText: null,
};

export default hot(module)(RoleForm);
