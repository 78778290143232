import map from 'lodash/map';
angular.module('bigpanda').controller('RuleController', RuleController);

function RuleController(vm, $q, ModalService, ContactsStore, $timeout) {
  // preSaveHooks lets custom target system params add a functionality that will run before save
  // we use it for saving new contacts for example
  let preSaveHooks = [];

  vm.errors = {};
  vm.fields = {};
  vm.currentState = {};
  vm.formHolder = {};
  vm.savePhase = null;
  vm.inProgress = false;

  vm.goToParent = goToParent;
  vm.preSave = preSave;
  vm.postSave = postSave;
  vm.registerPreSaveHook = registerPreSaveHook;
  vm.cancelRule = vm.goToParent;

  init();

  function init() {
    initContacts();
  }

  function goToParent() {
    return ModalService.hide();
  }

  function initContacts() {
    vm.contacts = [];
    ContactsStore.getContacts().then((contacts) => {
      vm.contacts = contacts;
    });
  }

  function registerPreSaveHook(hook) {
    preSaveHooks.push(hook);
  }

  function preSave() {
    vm.savePhase = 'saving';
    vm.enableButtons = false;
    vm.inProgress = true;

    return $q.all(map(preSaveHooks, (hook) => hook())).then(() => {
      preSaveHooks = [];
    });
  }

  function postSave(err) {
    if (err) {
      vm.savePhase = null;
      vm.enableButtons = true;
      vm.inProgress = false;
      return;
    }

    vm.savePhase = 'done';
    $timeout(vm.goToParent, 1000);
  }
}
