import trim from 'lodash/trim';

export const enum ValidationError {
  ExistingName = 'Name already exists',
  None = '',
}

export function uniqueNameValidator<ItemType>(
  items: ItemType[],
  uniqueProp: string,
  value: string,
  errorMsg: string = ValidationError.ExistingName,
): string {
  const trimmedValue = trim(value);

  const itemNames: string[] = items.map((item: ItemType): string => item[uniqueProp]?.toLowerCase());
  if (value && itemNames.includes(trimmedValue.toLowerCase())) {
    return errorMsg;
  }

  return ValidationError.None;
}
