import actionTypes from './actionTypes';
import { generateBaseSettingsActions } from '../redux.utils';

const actions = generateBaseSettingsActions(actionTypes);

actions.loadMultipleIncidentLabels = () => ({ type: actionTypes.LOAD_MULTIPLE_INCIDENT_LABELS });
actions.loadMultipleIncidentLabelsSuccess = (payload) => ({
  type: actionTypes.LOAD_MULTIPLE_INCIDENT_LABELS_SUCCESS,
  payload: payload,
});
actions.loadMultipleIncidentLabelsFailure = (payload) => ({
  type: actionTypes.LOAD_MULTIPLE_INCIDENT_LABELS_FAILURE,
  payload: payload,
});

actions.updateIncidentLabel = (payload) => ({
  type: actionTypes.UPDATE_INCIDENT_LABEL,
  payload: payload,
});
actions.updateIncidentLabelSuccess = (payload) => ({
  type: actionTypes.UPDATE_INCIDENT_LABEL_SUCCESS,
  payload: payload,
});
actions.updateIncidentLabelFailure = (payload) => ({
  type: actionTypes.UPDATE_INCIDENT_LABEL_FAILURE,
  payload: payload,
});

actions.createIncidentLabel = (payload) => ({
  type: actionTypes.CREATE_INCIDENT_LABEL,
  payload: payload,
});
actions.createIncidentLabelSuccess = (payload) => ({
  type: actionTypes.CREATE_INCIDENT_LABEL_SUCCESS,
  payload: payload,
});
actions.createIncidentLabelFailure = (payload) => ({
  type: actionTypes.CREATE_INCIDENT_LABEL_FAILURE,
  payload: payload,
});
actions.cancelLocalIncidentLabelChanges = (payload) => ({
  type: actionTypes.CANCEL_LOCAL_INCIDENT_LABEL_CHANGES,
  payload: payload,
});

actions.loadUnifiedTagsForIncidentLabel = (payload) => ({
  type: actionTypes.LOAD_UNIFIED_TAGS_FOR_INCIDENT_LABEL,
  payload: payload,
});
actions.deleteTagDefinition = (payload) => ({
  type: actionTypes.DELETE_TAG_DEFINITION,
  payload: payload,
});
actions.deleteTagDefinitionSuccess = (payload) => ({
  type: actionTypes.DELETE_TAG_DEFINITION_SUCCESS,
  payload: payload,
});
actions.deleteTagDefinitionFailure = (payload) => ({
  type: actionTypes.DELETE_TAG_DEFINITION_FAILURE,
  payload: payload,
});
actions.createFiltersDropDownItemsSuccess = (payload) => ({
  type: actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS,
  payload: payload,
});
actions.updateFiltersDropDowns = (payload) => ({
  type: actionTypes.UPDATE_FILTERS_DROPDOWNS,
  payload: payload,
});

export default actions;
