import { hot } from 'react-hot-loader';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsListScreen from 'react/layout/settings/SettingsListScreen';
import SettingsListEmptyState from 'react/layout/settings/components/SettingsListEmptyState';
import { SortShape } from 'react/layout/settings/components/SettingsList';
import { DropdownOptionType } from 'react/common/components/FiltersDropdown/FiltersDropdown';
import provideStore from 'react/utils/provideStore';
import actions from '../../actions';
import selectors from '../../selectors';
import CustomTagModal from '../../modals/CustomTagModal';
import ListItem from './list/ListItem';
import FiltersComponent from './filters/FiltersComponent';
import { SORT_MODES } from '../constants';
import { ANY_OPTION_VALUE } from '../../constants';
import SortTooltip from './SortTooltip';

class CustomTagsManagement extends React.Component {
  componentDidMount() {
    if (!this.props.customTags) {
      this.props.loadCustomTags();
    }
  }

  render() {
    const {
      customTags,
      updateFiltersDropDowns,
      filtersDropDownState,
      filtersDropDownItems,
      filterText,
      updateFilterText,
      isLoading,
      updateSort,
      sortMode,
    } = this.props;

    return (
      <React.Fragment>
        <SettingsListScreen
          loading={isLoading}
          items={customTags}
          noItemsTitle="No Custom Tag Added Yet"
          createItemProps={{
            modal: {
              title: 'Create a New Custom Tag',
              titleIcon: 'bp-icon-plus-icon',
              component: CustomTagModal,
            },
            itemName: 'Custom tag',
            buttonText: 'New Tag',
            tileText: 'Create New Custom tag',
          }}
          listItem={ListItem}
          listItemProp="customTag"
          permissionName="tags"
          headerName="Custom Tags"
          filterPlaceholderText="Find Custom Tags..."
          updateFilterText={updateFilterText}
          filterText={filterText}
          sortModes={SORT_MODES}
          sortMode={sortMode}
          sortTooltip={<SortTooltip />}
          updateSort={updateSort}
          renderFilterRowElement={() =>
            filtersDropDownItems && filtersDropDownState ? (
              <FiltersComponent
                filtersDropDownState={filtersDropDownState}
                onChange={updateFiltersDropDowns}
                data={filtersDropDownItems}
              />
            ) : null
          }
          renderNoResultsByFilters={() =>
            filterText ||
            Object.values(filtersDropDownState).some(
              (dropDownState) => dropDownState.value !== ANY_OPTION_VALUE
            ) ? (
              <SettingsListEmptyState
                title="No Matches Found"
                subtitle="Try refining your query and search fitlers"
              />
            ) : null
          }
        />
      </React.Fragment>
    );
  }
}

CustomTagsManagement.propTypes = {
  customTags: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  loadCustomTags: PropTypes.func.isRequired,
  updateFilterText: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  updateSort: PropTypes.func.isRequired,
  sortMode: SortShape.isRequired,
  updateFiltersDropDowns: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    source: DropdownOptionType,
    tagName: DropdownOptionType,
    type: DropdownOptionType,
    status: DropdownOptionType,
  }),
  filtersDropDownItems: PropTypes.shape({
    source: PropTypes.arrayOf(DropdownOptionType),
    tagName: PropTypes.arrayOf(DropdownOptionType),
    type: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }),
};

CustomTagsManagement.defaultProps = {
  customTags: undefined,
  filterText: '',
  filtersDropDownState: undefined,
  filtersDropDownItems: undefined,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    customTags: selectors.getCustomTags(state, true, true, true),
    filterText: selectors.getFilterText(state),
    sortMode: selectors.getSortMode(state),
    filtersDropDownState: selectors.getFiltersDropDownState(state),
    filtersDropDownItems: selectors.getFiltersDropDownItems(state),
  };
}

const mapDispatchToProps = {
  loadCustomTags: actions.loadCustomTags,
  updateFilterText: actions.updateFilterText,
  updateSort: actions.updateSort,
  updateFiltersDropDowns: actions.updateFiltersDropDowns,
};

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(CustomTagsManagement))
);
