require('angular-motion/dist/angular-motion.css');
require('angular-material/angular-material.css');
require('awesomplete/awesomplete.css');
require('select2/dist/css/select2.css');
require('ng-tags-input/build/ng-tags-input.css');
require('moment-timezone');
require('moment-duration-format');
require('pegjs');
require('d3-tip');
require('jquerycookie');
require('angular-md5/angular-md5.js');
require('angular-animate');
require('@uirouter/angularjs');
require('@uirouter/angularjs/lib/legacy/stateEvents.js');
require('angular-sanitize');
require('angular-strap');
require('angular-strap/dist/angular-strap.tpl.js');
require('angular-aria');
require('angular-material');
require('ng-tags-input');
require('ng-infinite-scroll');
require('@bp/bp-sift-wrapper');
require('intl-tel-input');
require('intl-tel-input/build/js/utils.js');
require('@bp/bpql');
require('socket.io-client');
require('awesomplete');
require('angular-permission');
require('@bp/icon-font');
require('@bp/bamboo');
require('@bp/bamboo/library/bamboo.css');
require('@bp/bam/library/bam.css');
require('angular-local-storage');
