import escape from 'lodash/escape';
const MAX_TITLE_LENGTH = 22;

function getTruncated(title) {
  if (title.length < MAX_TITLE_LENGTH - 3) {
    return title;
  }

  return `${title.substr(0, MAX_TITLE_LENGTH - 3)}...`;
}

function getErrorMessageTransform(title, operation) {
  return () =>
    `We couldn't <strong>${operation}</strong> ${getTruncated(escape(title), MAX_TITLE_LENGTH)}`;
}

function getProcessingMessage(title, operation) {
  return `${operation} ${getTruncated(`${escape(title)}...`)}`;
}

module.exports.getErrorMessageTransform = getErrorMessageTransform;
module.exports.getProcessingMessage = getProcessingMessage;
