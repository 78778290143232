angular.module('bigpanda').filter('since', since);

function since($filter) {
  return (time) => {
    if (!angular.isNumber(time) || Number.isNaN(time) || time < 0) {
      return 'n/a';
    }
    return sinceBpLogic(time);

    function getTimeDiff(timeStampUnix) {
      return moment().diff(moment.unix(timeStampUnix));
    }

    function checkTimeOverdue(seconds, days) {
      return moment.duration(getTimeDiff(seconds)).asDays() >= days;
    }

    function sinceBpLogic(timeUnix) {
      const dateFilter = $filter('date');
      const durationFilter = $filter('duration');
      let convertedTime;

      switch (true) {
        case checkTimeOverdue(timeUnix, 365):
          convertedTime = dateFilter(timeUnix * 1000, 'yyyy');
          break;
        case checkTimeOverdue(timeUnix, 7):
          convertedTime = dateFilter(timeUnix * 1000, 'MMM d');
          break;
        default:
          convertedTime = `${durationFilter(timeUnix, moment().unix())} ago`;
          break;
      }

      return convertedTime;
    }
  };
}
