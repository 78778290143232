import concat from 'lodash/concat';
import forEach from 'lodash/forEach';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
angular.module('bigpanda').controller('CreateEmailController', CreateEmailController);

function CreateEmailController($scope, ContactsStore) {
  $scope.contacts = angular.copy($scope.parentVm.fields.contacts);

  $scope.parentVm.registerPreSaveHook(() => {
    const newContacts = filter($scope.contacts, { newItem: true });
    const existingContacts = reject($scope.contacts, { newItem: true });

    // Notice that new contacts also has ids, that's because tags-input uses 'track by' internally, and you can't add
    // more than one new contact otherwise
    forEach(newContacts, (newContact) => {
      delete newContact.newItem;
      delete newContact._id;
    });

    return ContactsStore.createContacts(newContacts).then((newSavedContacts) => {
      $scope.parentVm.fields.contacts = concat(existingContacts, newSavedContacts);
    });
  });
}
