import actionTypes from './actionTypes';

const initialState = {
  correlationConfig: undefined,
  unifiedTags: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_CORRELATION_CONFIG_SUCCESS:
      return {
        ...state,
        correlationConfig: action.payload,
      };
    case actionTypes.LOAD_UNIFIED_TAGS_SUCCESS:
      return {
        ...state,
        unifiedTags: action.payload,
      };

    default:
      return state;
  }
}
