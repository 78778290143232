angular.module('bigpanda').constant('IntegrationsCategories', [
  {
    displayName: 'ALL',
    value: 'all',
  },
  {
    displayName: 'MONITORING',
    value: 'monitoring',
  },
  {
    displayName: 'COLLABORATION',
    value: 'Sharing & Ticket Management',
  },
  {
    displayName: 'CHANGES',
    value: 'changes',
  },
]);
