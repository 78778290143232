angular.module('bigpanda').service('VersionStore', VersionStore);

function VersionStore($window, $timeout) {
  let nextUpdateTime;

  this.setNeedsUpdate = setNeedsUpdate;
  this.updateVersion = updateVersion;

  return this;

  function setNeedsUpdate() {
    if (!nextUpdateTime) {
      nextUpdateTime = Math.floor(Math.random() * 60 * 60 * 1000); // less than 1 hour
    }
    $timeout(() => {
      $window.location.reload(true);
    }, nextUpdateTime);
  }

  function updateVersion() {
    if (nextUpdateTime) {
      $window.location.reload(true);
    }
  }
}
