angular.module('bigpanda').service('AssigneeFilterStore', AssigneeFilterStore);

function AssigneeFilterStore(SubscribersService) {
  const subscribers = new SubscribersService();
  let assignee = {};

  function subscribe(subscriber, scope) {
    subscribers.subscribe(scope, subscriber);
  }

  function getAssignee() {
    return assignee;
  }

  function setAssignee(newAssignee = {}) {
    assignee = newAssignee;
    subscribers.fireStoreUpdated();
  }

  function resetAssignee() {
    assignee = {};
    subscribers.fireStoreUpdated();
  }

  this.subscribe = subscribe;
  this.getAssignee = getAssignee;
  this.setAssignee = setAssignee;
  this.resetAssignee = resetAssignee;
  this.unassignedId = 'UNASSIGNED';

  return this;
}
