angular.module('bp.widgets').controller('bpPromptController', ($scope, ModalService) => {
  $scope.ok = function () {
    $scope.callback();
    ModalService.hide();
  };

  $scope.cancel = function () {
    ModalService.hide();
  };
});
