angular.module('bigpanda').directive('bpFormGlobalMsg', bpFormGlobalMsg);

function bpFormGlobalMsg() {
  return {
    restrict: 'E',
    scope: {
      title: '@',
      text: '@',
      dismiss: '&',
      approve: '&',
    },
    templateUrl: 'widgets/bp_form_global_msg/bp_form_global_msg',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller() {
    const vm = this;
    vm.onDismissAction = () => {
      vm.dismiss();
    };
    vm.onRefreshAction = () => {
      vm.approve();
    };
  }
}
