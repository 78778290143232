import { BamTable } from '@bp/bam';
import { Div, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const Subheader = styled(Div)`
  margin-bottom: 15px;
`;

export const TableContainer = styled(Div)`
    width: 100%;
    display: inline;
`;

export const PreviewTable = styled(BamTable)`
    padding: 0px 30px;
`;

export const SubheaderTitle = styled(Text)`
  color: ${(p): string => p.theme.bp_gray_06};
  margin-right: 5px;
`;
