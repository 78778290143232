import { hot } from 'react-hot-loader';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import autobind from 'class-autobind';
import pickBy from 'lodash/pickBy';
import {
  BamBaseModal,
  BamModalContent,
  BamModalHeader,
  BamModalActions,
  BamIncidentsBox,
  BamIncidentTile,
  BamActionButtons,
  BamTooltipAlert,
  BamAddNote,
} from '@bp/bam';
import { Spinner, darkTheme, Button, MergeIcon } from '@bp/kung-fu';
import { bp_blue } from '@bp/pastel/colors';
import { docsUrl as docsBaseUrl } from 'common/config';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../actions';
import styles from './add_note.scss';

const {
  BamTileCtaButton,
  BamTileActionButtons,
  BamCommentButton,
  BamShareButton,
} = BamActionButtons;

const SWITCH_TARGET_DELAY = 1000;

const updateSearchMode = (searchValue, search) => {
  if (searchValue) {
    search();
  }
};

const SpinnerComp = () => (
  <BamModalContent>
    <div className="is-merging" style={{ height: '362px' }}>
      <Spinner
        color={bp_blue}
        size="large"
        text="Please wait a few seconds while we merge your incidents..."
      />
    </div>
  </BamModalContent>
);

class MergeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      incidents: [],
      sourceIncidents: [],
      destIncident: {},
      annotation: '',
      switching: false,
      isModalOpen: false,
      isMerging: false,
      error: '',
    };

    autobind(this, MergeModal.prototype);
  }

  async openModal() {
    const incidents = await this.props.getIncidents();
    const totalEntities = incidents.reduce(
      (prev, { incident: { entities } }) => prev + entities.length,
      0
    );

    const correlationConfig = await this.props.getCorrelationConfig();
    const maxEntitiesInIncident = correlationConfig.max_entities_in_incident;

    const hasError = totalEntities > maxEntitiesInIncident;
    const errorMessage =
      hasError && `Merged incidents cannot contain more than ${maxEntitiesInIncident} alerts`;

    const [destIncident, ...sourceIncidents] = incidents;

    this.setState({
      incidents: incidents,
      destIncident: destIncident,
      sourceIncidents: sourceIncidents,
      error: errorMessage,
      isModalOpen: !hasError,
    });
  }

  async handleClick() {
    const { organization, envId, createMerge, getEnvById, searchValue, search } = this.props;
    const { name: organizationName } = organization;
    const { name: envName } = await getEnvById(envId);

    this.setState(() => ({ isMerging: true }));
    const payload = pickBy({
      sourceIncidentIds: this.state.sourceIncidents.map((incident) => incident.incident.id),
      destIncidentId: this.state.destIncident.incident.id,
      annotation: this.state.annotation,
      envId: envId,
      envName: envName,
      organization: organizationName,
    });

    createMerge(payload);
    /*
       We use timeout here because the product wanted a fake delay until the merge process is done in the pipeline.
       We will remove it when the websocket will be wired.
     */
    setTimeout(() => {
      this.reset();
      updateSearchMode(searchValue, search);
    }, 3000);
  }

  filterSourceIncidents(destIncident) {
    return this.state.incidents.filter(
      ({ incident }) => incident._id !== destIncident.incident._id
    );
  }

  reset() {
    this.setState(() => ({
      isModalOpen: false,
      isMerging: false,
      annotation: '',
    }));
  }
  close() {
    if (this.state.isMerging) return;
    this.reset();
  }

  open() {
    this.setState(() => ({ isModalOpen: true }));
  }

  useAsTargetHandler(destIncident) {
    this.setState(() => ({ switching: true }));
    setTimeout(() => {
      // setTimeout - Product instructions to create "Fake Delay"
      const sourceIncidents = this.filterSourceIncidents(destIncident);

      this.setState(() => ({
        sourceIncidents: sourceIncidents,
        destIncident: destIncident,
        switching: false,
      }));
    }, SWITCH_TARGET_DELAY);
  }

  addNoteOnChangeHandler(event) {
    this.setState({
      annotation: event.target.value,
    });
  }

  renderTargetIncident() {
    const incident = this.state.destIncident;
    const rightButtons = ({ comments, shares, snoozed }) => (
      <div className="bam-merge-modal-btns">
        <BamTileActionButtons>
          {comments && comments.length ? (
            <BamCommentButton comments={comments} disabled />
          ) : (
            <div />
          )}
          {shares && shares.length ? <BamShareButton shares={shares} disabled /> : <div />}
        </BamTileActionButtons>
      </div>
    );

    const renderTileOrSpinner = (switching, targetIncident) =>
      switching ? (
        <Spinner color={bp_blue} expand />
      ) : (
        targetIncident && (
          <BamIncidentTile incident={targetIncident} rightButtons={rightButtons(targetIncident)} />
        )
      );

    return (
      <div>
        <BamIncidentsBox title="Destination Incident">
          <div className="bam-target-incident-container">
            {renderTileOrSpinner(this.state.switching, incident)}
          </div>
        </BamIncidentsBox>
        <div className="bam-target-incident-arrow">
          <i className="bp-icon-up-arrow" />
        </div>
      </div>
    );
  }

  renderSourceIncidents() {
    const rightButtons = (incident) => {
      const { comments, shares } = incident;

      return (
        <div className="bam-merge-modal-btns">
          <BamTileActionButtons>
            {comments && comments.length ? (
              <BamCommentButton comments={comments} disabled />
            ) : (
              <div />
            )}
            {shares && shares.length ? <BamShareButton shares={shares} disabled /> : <div />}
            <BamTileCtaButton
              text="Use as Destination"
              onClick={() => this.useAsTargetHandler(incident)}
            />
          </BamTileActionButtons>
        </div>
      );
    };

    return this.state.sourceIncidents.map((incident) => (
      <BamIncidentTile
        incident={incident}
        rightButtons={rightButtons(incident)}
        key={incident.incident._id}
        disabled={this.state.switching}
      />
    ));
  }

  renderModalContent() {
    const docsLink = `${docsBaseUrl}/v1.0/docs/merge-incidents`;
    const helpTooltip = (
      <div>
        Merge the Source Incidents into the Destination Incident.
        <br />
        Note that actions (Comment, Assignee, Snooze, etc.) from the Source Incident won&apos;t be
        moved to the Destination Incident, but the Alerts history is preserved. Also, the new Merged
        Incident will be labeled &quot;Manual&quot; and will not receive new Alerts. <br />
        This operation is irreversible.
      </div>
    );

    return (
      <React.Fragment>
        <BamModalHeader
          helpTooltip={helpTooltip}
          docsLink={docsLink}
          close={this.close}
          title="Merge Incidents"
          icon="bp-icon-merge-title"
          size="huge"
        />
        <BamModalContent>
          <div className="bam-merge-modal-target-incident">{this.renderTargetIncident()}</div>
          <BamIncidentsBox title={`Source Incidents (${this.state.sourceIncidents.length})`}>
            <div className="bam-merge-modal-source-incidents">{this.renderSourceIncidents()}</div>
          </BamIncidentsBox>
          <div className={styles['add-note-container']}>
            <BamAddNote
              onChange={this.addNoteOnChangeHandler}
              value={this.state.annotation}
              description="(Explain why you merged those incidents)"
            />
          </div>
        </BamModalContent>
        <BamModalActions
          submitButton={{ text: 'Merge Incidents', onClick: this.handleClick }}
          closeButton={{ text: 'Cancel', onClick: this.close }}
        />
      </React.Fragment>
    );
  }

  render() {
    if (!this.state.destIncident) {
      return <div />;
    }

    const buttonDisabled = !(this.props.incidentsIds.length > 1);
    const buttonTooltip = buttonDisabled
      ? 'Select at least two Incidents to perform a Merge'
      : 'Merge selected Incidents';

    const mergeButton = () => (
      <Button
        variant="action-container"
        size="large"
        icon={<MergeIcon />}
        disabled={buttonDisabled}
        onClick={this.openModal}
        tooltipProps={{
          isActive: true,
          placement: 'bottom',
          text: buttonTooltip,
        }}
      >
        Merge
      </Button>
    );

    return (
      <ThemeProvider theme={darkTheme}>
        <div>
          {this.state.error ? (
            <BamTooltipAlert
              trigger={mergeButton()}
              content={this.state.error}
              position="bottom center"
              type="error"
              open={!!this.state.error}
              onUnmount={() => {
                this.setState({
                  error: '',
                });
              }}
            />
          ) : (
            mergeButton()
          )}

          <BamBaseModal
            open={this.state.isModalOpen}
            onClose={this.close}
            onMount={this.onOpen}
            className="bam-merge-modal"
          >
            {!this.state.isMerging ? this.renderModalContent() : <SpinnerComp />}
          </BamBaseModal>
        </div>
      </ThemeProvider>
    );
  }
}

MergeModal.propTypes = {
  envId: PropTypes.string.isRequired,
  createMerge: PropTypes.func.isRequired,
  getIncidents: PropTypes.func.isRequired,
  getCorrelationConfig: PropTypes.func.isRequired,
  getEnvById: PropTypes.func.isRequired,
  incidentsIds: PropTypes.arrayOf(PropTypes.string),
  organization: PropTypes.shape({ name: PropTypes.string }),
  searchValue: PropTypes.string,
  search: PropTypes.func,
};

MergeModal.defaultProps = {
  incidentsIds: [],
  organization: {},
};

function mapStateToProps({ user: { organization } = {} }) {
  return {
    organization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createMerge: (payload) => dispatch(actions.createMerge(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(MergeModal));
