angular.module('bigpanda').controller('SsoSettingsController', SsoSettingsController);

function SsoSettingsController(
  $scope,
  version,
  $state,
  stateService,
  $http,
  pubSubService,
  Config,
  SsoService,
  ModalService,
  PersonalSettingsStore,
  AuthService,
  UserFeatureTogglesService,
  $ngRedux
) {
  $scope.guideNumberStart = 1;
  $scope.finishLoading = false;
  $scope.identityProvidersGuides = [];
  $scope.testAndLogoutDisabled = true;
  $scope.store = $ngRedux;

  pubSubService.broadcast('currentTitleChanged', 'Single Sign-on Settings');
  pubSubService.broadcast('settingsPageChanged', 'sso');

  pubSubService.on('SsoIntegrationCompleted', (event, providerName) => {
    if ($scope.selectedProvider && providerName == $scope.selectedProvider.name) {
      $scope.testAndLogoutDisabled = false;
    }
  });

  PersonalSettingsStore.getUser().then((user) => {
    $scope.orgName = user.organization.name;

    var allIdPs = SsoService.getAllIdentityProviders();
    allIdPs.forEach((idP) => {
      if (idP.options && idP.options.featureToggle) {
        if (!user.featureToggles || !user.featureToggles[`sso/${idP.name}`]) {
          return;
        }
      }
      $scope.identityProvidersGuides.push(idP);
    });
  });

  function findIdP(id) {
    var idp = SsoService.findIdP(id);
    if (idp) {
      $scope.selectedProvider = idp;
    }
    return $scope.selectedProvider;
  }

  function loadContent() {
    if (stateService.getSelectedGuideId()) {
      findIdP(stateService.getSelectedGuideId());
    }
    if ($scope.selectedProvider) {
      $http
        .get(
          `/resources/sso-tutorials/${$scope.selectedProvider.name}.md?version=${version.version}`
        )
        .then((data) => {
          $scope.tutorialContent = data.data;
        });
    }
  }

  function updateSso(enabled, status) {
    return $http
      .put('/web/organization/my/sso', {
        enabled: enabled,
        idP: $scope.selectedProvider.name,
        status: status,
      })
      .then((res) => res.data);
  }

  function getSso() {
    // Force refresh if changed by the user in this session
    return $http.get('/web/organization/my/sso').then((res) => {
      const data = res.data && res.data.data ? res.data.data : {};

      if (data.idP) {
        $scope.orgStatus = data.status;
        findIdP(data.idP);
        if ($scope.selectedProvider) {
          $scope.chosenIdP = $scope.selectedProvider.display;
        }
      }

      $scope.finishLoading = true;
    });
  }

  reload();

  $scope.openTutorial = function (guideName) {
    $state.go('.', { guide: guideName });
  };

  $scope.testAndLogout = function () {
    updateSso(true, 'testing').then(logout);
  };

  $scope.enableAfterTested = function () {
    showPopup(
      'Activate SSO',
      'Are you sure? Clicking Yes will activate Single Sign-on for every user in your organization.',
      () => {
        updateSso(true, 'ok').then(reload);
      }
    );
  };

  function showPopup(header, title, callback) {
    var newScope = $scope.$new();
    newScope.header = header;
    newScope.title = title;
    newScope.callback = callback;

    ModalService.showModal({
      templateUrl: 'widgets/bp_prompt/bp_prompt',
      controller: 'bpPromptController',
      scope: newScope,
    });
  }

  $scope.disableSso = () => {
    showPopup(
      'Disable SSO',
      $scope.orgStatus === 'ok'
        ? 'Are you sure you want to disable SSO and require users to log in directly to BigPanda? Users may need to reset their passwords.'
        : 'Are you sure you want to disable SSO? You will need to re-enter your provider details to enable SSO for your organization.',
      () => {
        updateSso(false).then(reload);
      }
    );
  };

  function reload() {
    $scope.selectedProvider = null;
    $scope.orgStatus = null;
    $scope.chosenIdP = null;
    $scope.finishLoading = false;

    loadContent();
    getSso();
  }

  function logout() {
    var domain = Config.domain;
    if (domain == 'localhost') domain = null;
    $.removeCookie('sso_notice', { path: '/', domain: domain });

    AuthService.resetUser({ sso: true });
  }
  $scope.logoutSSO = logout;
}
