angular.module('bigpanda').directive('timelineAxis', timelineAxis);

function timelineAxis($window, pubSubService, D3Utils) {
  return {
    restrict: 'E',
    scope: {
      domain: '<',
      startDate: '<',
      endDate: '<',
      width: '<',
    },
    controller: controller,
    bindToController: true,
    controllerAs: 'vm',
  };

  function controller($scope, $element) {
    const vm = this;
    vm.$onChanges = onChanges;
    vm.$postLink = postLink;

    const HEIGHT = 60;
    const NOW = new Date();
    let initialized = false;
    let startLabelWidth = 130;
    let endLabelWidth = 130;
    const scale = D3Utils.getTimeScale(vm.domain, vm.width);
    const chart = d3.select($element[0]).append('svg').attr('height', HEIGHT);
    const endLabel = createStatusLabel('end');
    const startLabel = createStatusLabel('start');
    const axis = d3.svg
      .axis()
      .scale(scale)
      .tickFormat(d3.time.format('%b %e*%I:%M%p'))
      .tickPadding(7)
      .outerTickSize(0)
      .innerTickSize(10);

    function postLink() {
      $scope.$watch(
        () => vm.domain,
        (domain) => {
          if (domain) {
            let format;

            if (moment(domain[1]).diff(domain[0], 'days') > 4) {
              format = d3.time.format('%b %e, %I:%M%p');
              startLabelWidth = endLabelWidth = 130;
            } else {
              format = d3.time.format('%I:%M%p');
              startLabelWidth = endLabelWidth = 80;
            }

            scale.domain(domain).nice();
            startLabel.text(`S: ${format(vm.startDate)}`);

            if (vm.endDate) {
              endLabel.text(`E: ${format(vm.endDate)}`);
            } else {
              endLabel.text('NOW');
              endLabelWidth = 45;
            }

            update();
            initialized = true;
          }
        }
      );
    }

    function createStatusLabel(className) {
      return d3
        .select($element[0])
        .append('div')
        .attr('class', `marker-label ${className}`)
        .style('opacity', 0)
        .style('width', `${startLabelWidth}px`);
    }

    function update() {
      if (vm.width > 0) {
        scale.rangeRound([0, vm.width]);

        startLabel
          .style('width', `${startLabelWidth}px`)
          .style('left', `${scale(vm.startDate) - startLabelWidth / 2}px`)
          .transition()
          .duration(D3Utils.TRANSITION_DURATION)
          .style('opacity', 1);
        endLabel
          .style('width', `${endLabelWidth}px`)
          .style('left', `${scale(vm.endDate || NOW) - endLabelWidth / 2}px`)
          .transition()
          .duration(D3Utils.TRANSITION_DURATION)
          .style('opacity', 1);

        const ticks = chart
          .transition()
          .duration(D3Utils.TRANSITION_DURATION)
          .call(axis)
          .selectAll('.tick:not(.tick-hidden)')
          .filter((tick, i) => i % 2);
        chart.selectAll('.tick text').call((text) => text.each(wrap));

        if (vm.width < 483 && ticks.size() >= 3) {
          ticks.attr('class', 'tick tick-hidden');
        } else if (vm.width < 735 && ticks.size() >= 5) {
          ticks.attr('class', 'tick tick-hidden');
        } else if (ticks.size() >= 6) {
          ticks.attr('class', 'tick tick-hidden');
        }
      }

      function wrap() {
        const currText = d3.select(this);
        const splitText = currText.text().split('*');
        currText.text(null);
        currText.append('tspan').attr('class', 'date').attr('x', 0).text(splitText[0]);
        currText
          .append('tspan')
          .attr('class', 'time')
          .attr('x', 0)
          .attr('y', 42)
          .text(splitText[1]);
      }
    }

    function onChanges(changes) {
      if (changes.width && !changes.width.isFirstChange() && initialized) {
        update();
      }
    }
  }
}
