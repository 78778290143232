import { AllSizes, ButtonVariants } from '@bp/kung-fu';
import React from 'react';

import { StyledClearButton } from './ClearButton.style';
import { ClearButtonProps } from './types';

function ClearButton({ showButton, handleClick }: ClearButtonProps): JSX.Element {
  return showButton && (
  <StyledClearButton
    size={AllSizes.SMALL}
    variant={ButtonVariants.SECONDARY}
    onClick={handleClick}
    text="Clear All"
  />
  );
}

export default ClearButton;
