export const checkGranularExplicit = (granularPermissions, actionType, resourceType, resourceId) =>
  granularPermissions[resourceType] &&
  granularPermissions[resourceType][resourceId] &&
  granularPermissions[resourceType][resourceId].length &&
  granularPermissions[resourceType][resourceId].includes(actionType);

export const checkGranularPermission = (
  userPermissions,
  featureToggles,
  granularPermissions,
  actionType,
  resourceType,
  resourceId
) => {
  if (!userPermissions || !featureToggles || !granularPermissions) {
    return false;
  }
  const permitted =
    userPermissions.indexOf(`${resourceType}@${actionType}`) > -1 ||
    !featureToggles.enable_global_auth ||
    checkGranularExplicit(granularPermissions, actionType, resourceType, resourceId);
  return Boolean(permitted);
};
