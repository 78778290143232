import merge from 'lodash/merge';
import find from 'lodash/find';
angular.module('bigpanda').service('EnvironmentsCountersStore', EnvironmentsCountersStore);

function EnvironmentsCountersStore(
  $q,
  $log,
  EnvironmentsBackendService,
  SubscribersService,
  deepCloneObject,
  EnvironmentsUtils,
  $interval
) {
  let environmentsCountersCache = null;
  let ongoingCountersRequestPromise = null;

  this.getCounters = getCounters;
  this.updateCounters = updateCounters;
  this.subscribe = subscribe;
  this.getEnvFolderCounter = getEnvFolderCounter;
  this.refreshCounters = refreshCounters;
  this.getEnvFoldersCounters = getEnvFoldersCounters;

  const subscribers = new SubscribersService();

  $interval(refreshCounters, 15 * 1000);
  return this;

  function getCounters(forceReload, cloneObjects = true) {
    if (!forceReload && environmentsCountersCache) {
      return $q.when(
        cloneObjects
          ? deepCloneObject.cloneDeep(environmentsCountersCache)
          : environmentsCountersCache
      );
    }
    if (ongoingCountersRequestPromise) {
      return ongoingCountersRequestPromise.then((result) => deepCloneObject.cloneDeep(result));
    }

    ongoingCountersRequestPromise = EnvironmentsBackendService.getCounters()
      .then((allEnvCounters) => {
        const environmentsWithoutCounters = allEnvCounters || [];
        environmentsCountersCache = environmentsWithoutCounters.map(normalizeCounterImmutable);

        ongoingCountersRequestPromise = null;

        return deepCloneObject.cloneDeep(environmentsCountersCache);
      })
      .then((counters) => {
        subscribers.fireStoreUpdated();
        return counters;
      })
      .catch(() => {
        ongoingCountersRequestPromise = null;
      });

    return ongoingCountersRequestPromise;
  }

  function getEnvFolderCounter(envId, folderId) {
    return getCounters(false, false).then((allEnvCounters) =>
      EnvironmentsUtils.getFolderCounters(allEnvCounters, envId, folderId)
    );
  }

  function refreshCounters(forceReload) {
    getCounters(forceReload, false);
  }

  function getEnvFoldersCounters(forceReload) {
    return getCounters(forceReload, false).then((counters) =>
      counters.map((c) => {
        const folders = c.folders.map((folder) => ({ id: folder.id, counter: folder.counter }));
        return { id: c.id, folders: folders };
      })
    );
  }

  function increaseFoldersCounter(folders, envFolders) {
    folders.forEach((folderId) => {
      const folder = find(envFolders, { id: folderId });
      if (folder) {
        folder.counter++;
      }
    });
  }

  function decreaseFoldersCounter(folders, envFolders) {
    folders.forEach((folderId) => {
      const folder = find(envFolders, { id: folderId });
      if (folder && folder.counter > 0) {
        folder.counter--;
      } else if (folder && folder.counter <= 0) {
        $log.error('cannot decrease folder counter when its zero');
      }
    });
  }

  function envCountersHandler(envs, actionState, foldersRemoved, foldersAdded) {
    envs.forEach((envId) => {
      const environmentCounters = environmentsCountersCache
        ? find(environmentsCountersCache, { id: envId })
        : null;

      if (environmentCounters && angular.isDefined(environmentCounters)) {
        switch (actionState) {
          case 'add':
            increaseFoldersCounter(foldersAdded, environmentCounters.folders);
            break;
          case 'in':
            increaseFoldersCounter(foldersAdded, environmentCounters.folders);
            decreaseFoldersCounter(foldersRemoved, environmentCounters.folders);
            break;

          case 'remove':
            decreaseFoldersCounter(foldersRemoved, environmentCounters.folders);
            break;

          default:
            break;
        }

        normalizeCounter(environmentCounters);
      }
    });
  }

  function updateCounters(incidents, selectedEnv) {
    incidents.forEach((incident) => {
      if (!incident.env_changes || !incident.folder_changes) {
        $log.error('Incident must have env_changes && folder_changes');
        return;
      }

      const envAdded = incident.env_changes.added;
      const envIn = incident.env_changes.in;
      const envRemoved = incident.env_changes.removed;

      const foldersAdded = angular.copy(incident.folder_changes.added);
      const foldersIn = angular.copy(incident.folder_changes.in);
      const foldersRemoved = angular.copy(incident.folder_changes.removed);

      if (envIn.includes(selectedEnv)) {
        envCountersHandler([selectedEnv], 'in', foldersRemoved, foldersAdded);
      }

      if (envAdded.includes(selectedEnv)) {
        envCountersHandler([selectedEnv], 'add', [], merge(foldersAdded, foldersIn));
      }

      if (envRemoved.includes(selectedEnv)) {
        envCountersHandler([selectedEnv], 'remove', merge(foldersIn, foldersRemoved));
      }
    });

    subscribers.fireStoreUpdated();
  }

  function normalizeCounterImmutable(env) {
    if (!env.folders || !env.folders.length) {
      return env;
    }
    const activeFolder = env.folders.filter((folder) => folder.id === 'active');
    if (!activeFolder || activeFolder.length) {
      return env;
    }
    const { counter } = activeFolder[0];
    return {
      ...env,
      ...{
        counter,
      },
    };
  }

  function normalizeCounter(env) {
    if (env.folders && env.folders.length) {
      const activeFolder = find(env.folders, (folder) => folder.id === 'active');
      env.counter = activeFolder.counter;
    }
  }

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }
}
