import actionTypes from './actionTypes';
import { baseSettingInitialState, generateBaseSettingsReducer } from '../../redux.utils';

export const initialState = {
  ...baseSettingInitialState,
  integrations: undefined,
  error: undefined,
  isPaginating: false,
};

const baseReducer = generateBaseSettingsReducer(actionTypes, initialState);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_INTEGRATIONS:
      return {
        ...state,
        isLoading: false,
        isPaginating: true,
      };
    case actionTypes.LOAD_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        integrations: action.payload,
        isLoading: false,
        isPaginating: false,
      };

    default:
      return baseReducer(state, action);
  }
}
