import { Button, SCVBox, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const CustomSelectButton = styled(Button)`
    border-radius: 3px;
`;

export const CustomSelectWrapper = styled(SCVBox)`
  flex-direction: row;
  gap: 5px;
  color: ${(p) => p.theme.bp_gray_06};
  cursor: pointer;
`;

export const SelectText = styled(Text)<{ textWidth: string }>`
  display: block;
  width: ${(p) => p.textWidth}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
