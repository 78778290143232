import clone from 'lodash/clone';
angular.module('bigpanda').directive('bpSourceSystemDisplay', bpSourceSystemDisplay);

function bpSourceSystemDisplay() {
  return {
    scope: {
      entities: '<',
      showTooltip: '<',
      activeOnly: '<',
    },
    templateUrl: 'shared_ui/bp_source_system_display/bp_source_system_display',
    controllerAs: 'vm',
    bindToController: true,
    controller: controller,
  };

  function controller($scope, sourceBreakdownService) {
    const vm = this;
    const TOOLTIP_TEMPLATE = 'shared_ui/bp_source_system_display/bp_source_system_tooltip';

    $scope.$watch(
      () => sourceBreakdownService.getSourceBreakdownData(vm.entities, vm.activeOnly),
      updateData,
      true
    );

    init();

    function init() {
      vm.tooltipTemplate = vm.showTooltip ? TOOLTIP_TEMPLATE : '';
    }

    function updateData() {
      const breakdown = sourceBreakdownService.getSourceBreakdownData(vm.entities, vm.activeOnly);

      vm.systems = breakdown.systems;
      if (vm.systems.length > 1) {
        const parentSystems = clone(breakdown.parentSystems);
        vm.lastSystem = parentSystems.pop();
        vm.otherSystems = parentSystems.join(', ');
      } else {
        vm.lastSystem = breakdown.systems[0];
        vm.otherSystems = null;
      }
    }
  }
}
