import { withReactQueryClient } from '@bp/data-access';
import {
  darkTheme,
  EmptyMessage,
  Spinner,
  Text,
} from '@bp/kung-fu';
import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { selectors as integrationsSelectors } from '../../../../../../app/react/common/integrations';
import provideStore from '../../../../../../app/react/utils/provideStore';
import dictionary from '../../dictionary';
import { useAlertFilters } from '../../hooks/useAlertFilters';
import { AlertFilter } from '../../types/AlertFilter';
import { IntegrationOption } from '../../types/IntegrationOption';
import { Center } from './FilterDetailsPane.style';
import { FilterInfo } from './FilterInfo';

interface IProps {
  filterId: string;
}

function NoFilterMessage(): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <Center>
        <EmptyMessage header={dictionary.no_filter_selected}>
          <Text>
            {dictionary.select_filter_to_see_details}
          </Text>
        </EmptyMessage>
      </Center>
    </ThemeProvider>
  );
}

export function FilterDetailsPane({ filterId }: IProps): JSX.Element {
  const integrations: IntegrationOption[] = useSelector(integrationsSelectors.getIntegrationOptions);

  const {
    isLoading,
    isError,
    isFetching,
    alertFilters,
  } = useAlertFilters(
    { integrations },
    {
      shouldFilterByText: false,
      shouldSort: false,
      shouldFilter: false,
    },
  );
  const alertFilter = (alertFilters || []).find(({ id }) => id === filterId) as AlertFilter;

  if (isError || !alertFilter) {
    return (<NoFilterMessage />);
  }

  return (
    <ThemeProvider theme={darkTheme}>
      {(isLoading || isFetching)
        ? <Spinner />
        : <FilterInfo filter={alertFilter} />}
    </ThemeProvider>
  );
}

export default provideStore(withReactQueryClient(FilterDetailsPane));
