angular.module('bp.widgets').directive('bpToggleSwitch', () => {
  const bpToggleSwitch = {
    restrict: 'E',
    scope: {
      state: '=',
      label: '=',
      callback: '&',
    },
    templateUrl: 'widgets/bp_toggle_switch/bp_toggle_switch',
    link: link,
  };

  function link(scope) {
    scope.handleClick = function () {
      scope.callback({ scope });
    };
  }

  return bpToggleSwitch;
});
