import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import find from 'lodash/find';
angular.module('bigpanda').service('CorrelationRulesService', CorrelationRulesService);

function CorrelationRulesService(CorrelationConfigService) {
  this.getRules = getRules;
  this.getCorrelationRuleById = getCorrelationRuleById;
  this.getIncidentTitleConfig = getIncidentTitleConfig;

  this.addNewCorrelationRule = addNewCorrelationRule;
  this.editCorrelationRule = editCorrelationRule;
  this.removeCorrelationRule = removeCorrelationRule;
  this.toggleCorrelationRuleState = toggleCorrelationRuleState;

  function removeCorrelationRule(id) {
    return getRules({ force: true }).then((rules) =>
      CorrelationConfigService.removeItem(
        rules,
        id,
        'correlations.correlationRuleRemoved',
        'matching_rules'
      )
    );
  }

  function addNewCorrelationRule(rule) {
    return getRules().then((rules) =>
      CorrelationConfigService.addNewItem(
        rules,
        rule,
        'correlations.correlationRuleAdded',
        'matching_rules'
      )
    );
  }

  function editCorrelationRule(rule) {
    return getRules().then((rules) =>
      CorrelationConfigService.editItem(
        rules,
        rule,
        'correlations.correlationRuleModified',
        'matching_rules'
      )
    );
  }

  function toggleCorrelationRuleState(rule) {
    return CorrelationConfigService.checkForConfigChanges().then(() =>
      getRules().then((rules) =>
        CorrelationConfigService.toggleItemState(
          rules,
          rule,
          'correlations.correlationRuleToggled',
          'matching_rules'
        )
      )
    );
  }

  function getIncidentTitleConfig(rawIncident = {}) {
    const log = getLessAggressiveMatcher(rawIncident.matchers_log, 'offset');
    return getLessAggressiveMatcher(log.matchers, 'window');
  }

  function getRules(opts) {
    return CorrelationConfigService.getConfig(opts).then((conf) => {
      const rules = conf ? conf.matching_rules : [];
      CorrelationConfigService.enrichConfig(rules);
      return rules;
    });
  }

  function getCorrelationRuleById(id) {
    return getRules().then((rules) => {
      if (rules) {
        return find(rules, { id });
      }
      return null;
    });
  }

  function getLessAggressiveMatcher(matchers, type) {
    return last(sortBy(matchers, type));
  }
}
