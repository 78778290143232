import isObject from 'lodash/isObject';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { Button, RefreshIcon } from '@bp/kung-fu';

import pluralize from 'common/utils/utils';

import TroubleshootingModalFilters from '../TroubleshootingModalFilters/TroubleshootingModalFilters';
import { reqStatusType } from '../constants';
import selectors from '../selectors';
import { troubleshootingDataPropType } from '../propTypes';
import styles from './TroubleshootingModalActions.scss';

const TroubleshootingModalActions = (props) => {
  const { troubleshootingData, isLoading, refresh, onFilterChange } = props;
  const { total, getRequestStatus } = troubleshootingData;
  const totalValue = isObject(total) ? get(total, 'value', 0) : total;
  return (
    <div className={styles.root}>
      <div className={styles.summary}>
        {getRequestStatus !== reqStatusType.FAILURE ? (
          <React.Fragment>
            <span className={styles.summary__count}>
              {totalValue} {pluralize(totalValue, 'Matching event')} found{' '}
            </span>
            <TroubleshootingModalFilters onFilterChange={onFilterChange} />
          </React.Fragment>
        ) : (
          <div>An unknown error occurred</div>
        )}
      </div>
      <Button
        variant="action-color"
        size="large"
        icon={<RefreshIcon />}
        onClick={refresh}
        disabled={isLoading}
        isLoading={isLoading}
      >
        Refresh
      </Button>
    </div>
  );
};

TroubleshootingModalActions.propTypes = {
  troubleshootingData: troubleshootingDataPropType.isRequired,
  isLoading: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  const troubleshootingData = selectors.getTroubleshooting(state);
  const isLoading = selectors.getIsLoading(state);
  return {
    organization,
    user,
    troubleshootingData,
    isLoading,
  };
};

export default connect(mapStateToProps, null)(hot(module)(TroubleshootingModalActions));
