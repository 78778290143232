import styled from 'styled-components';
import { bp_gray_03, bp_white } from '@bp/pastel/colors';

const HBox = styled.div`
display: flex;
`;

const VBox = styled(HBox)`
flex-direction: column;
align-items: center;
`;

export const Wrapper = styled(VBox)`
gap: 20px;
`;

export const Header = styled(HBox)`

`;

export const Container = styled(VBox)`
justify-content: center;
width: 500px;
height: 234px; 
background-color: ${bp_white}; 
border: 1px solid ${bp_gray_03};
border-radius: 3px;
gap: 8px;
`;

export const MainText = styled.span`
font-size: 20px;
font-weight: bold;
`;

export const SubLine = styled(HBox)`
font-size: 13px;
gap: 4px;
`;

export const SubText = styled.span`

`;

export const Logo = styled.img`
height: 38px;
`;

export const Link = styled.a`

`;
