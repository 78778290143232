import bpFetch from 'common/utils/http/fetcher';

export async function getUser(headers = {}) {
  return (await bpFetch({ url: '/web/me', headers })).data;
}

export function getContact() {
  return bpFetch({ url: '/web/me/contact' }).then((res) => res.data);
}

export function updateContact(payload) {
  return bpFetch({
    url: '/web/me/contact',
    method: 'PATCH',
    data: payload,
  }).then((res) => res.data);
}

export function changePassword(data) {
  return bpFetch({
    url: '/web/me/changePassword',
    method: 'POST',
    data,
  });
}
