const { getProcessingMessage } = require('angularjs/utils/notification/message.transforms.js');

angular.module('bigpanda').controller('MultiCommentPopupController', MultiCommentPopupController);

function MultiCommentPopupController($scope, notificationService) {
  const vm = this;

  angular.extend($scope, {
    dialogTitle: 'Comment',
    titleIconClass: 'bp-icon-comment',
    noButtons: true,
  });

  vm.saveComments = (commentsSaver) => {
    $scope.save();

    const numOfIncidents = `${vm.incidentIds.length} ${
      vm.incidentIds.length === 1 ? 'incident' : 'incidents'
    }`;

    return notificationService
      .notify(getProcessingMessage(numOfIncidents, 'Commenting on'), { undo: () => {} })
      .then((res) => {
        if (res.code === 'Undo') {
          return null;
        }
        return commentsSaver();
      })
      .catch((err) => {
        const serverError =
          err.data &&
          err.data.response &&
          err.data.response.errors &&
          err.data.response.errors.join(', ');
        const defaultError = 'Something went wrong. Please try again';
        notificationService.error(`Failed commenting - ${serverError || defaultError}`);
      });
  };
}
