import clone from 'lodash/clone';
import {
  changeSelectedTag,
  deleteSelectedTag,
  onIncidentTagsUpdated,
  updateContext,
  updateIncidentTagDeleteButtonDisabled,
  updateContextWithPriorityTag,
} from './incident.info.title.logic';
angular.module('bigpanda').directive('incidentInfoTitle', incidentInfoTitle);

function incidentInfoTitle() {
  return {
    scope: {
      incident: '<',
      toggleFullScreen: '&',
      isMultiple: '@',
      clickDisabled: '=?',
    },
    templateUrl: 'overview/incidents/info/incident_info_title',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(
    $scope,
    $element,
    $state,
    $timeout,
    $ngRedux,
    pubSubService,
    sourceBreakdownService,
    IncidentTitleService,
    stateService,
    Permissions,
    LabelsStore,
    $stateParams,
    UserFeatureTogglesService
  ) {
    const vm = this;
    const envId = stateService.getSelectedEnvironmentId();

    vm.reactRightPane = true;
    vm.priorityProps = {
      changeSelectedTag: (tagText, tagValue, name, email) =>
        changeSelectedTag(vm, LabelsStore, tagValue, name, email),
      deleteSelectedTag: () => deleteSelectedTag(vm, LabelsStore),
      incidentId: stateService.getSelectedIncidentId(),
      envId: envId,
    };
    vm.openLifecycle = openLifecycle;
    vm.store = $ngRedux;
    vm.showSmallButton = false;
    vm.titleTooltip = 'overview/incidents/info/incident_title_tooltip';
    vm.currentEnvActions = {
      delete: Permissions.checkGranularPermission('action@delete', 'environments', envId),
      update: Permissions.checkGranularPermission('action@update', 'environments', envId),
    };
    vm.isDeleteIncidentTagDisabled = true;
    vm.incidentFullScreen = $stateParams.incidentfullscreen;

    init();

    function init() {
      if (!vm.checkedIncidentsState) {
        vm.checkedIncidentsState = {
          subscribe: angular.noop,
          isAnythingChecked: () => false,
        };
      }

      $scope.$watch('vm.deleteDisabled', () => onIncidentTagsUpdated(vm, LabelsStore));
      pubSubService.on('tabs.resizing', shouldShowSmallButton, $scope);
      pubSubService.on(
        'incidentTagsDefinitions.updated',
        () => onIncidentTagsUpdated(vm, LabelsStore),
        $scope
      );

      onIncidentTagsUpdated(vm, LabelsStore);

      $scope.$watch(
        () => vm.incident,
        () => {
          vm.breakdownSources = breakdownSources();
          vm.titleData = getTitle();
          updateIncidentTagDeleteButtonDisabled(vm, LabelsStore);
        }
      );

      $scope.$watch(
        () => vm.incident,
        (incident) => {
          vm.incident.numMaintenanceAlerts = incident.entities.filter(
            (e) => e.maintenance_plan_ids && e.maintenance_plan_ids.length > 0
          ).length;

          vm.incident.maintenanceTitle =
            vm.incident.numMaintenanceAlerts > 0 &&
            vm.incident.numMaintenanceAlerts ===
              vm.incident.entities.filter((e) => e.is_active).length
              ? `${vm.incident.numMaintenanceAlerts} (All)`
              : vm.incident.numMaintenanceAlerts;
        },
        true
      );

      updateContext(vm, LabelsStore).then(() => {
        LabelsStore.subscribe($scope, {
          storeUpdated: () => updateContextWithPriorityTag(vm, vm.priorityProps, LabelsStore),
        });
      });

      vm.isDisabled = isClickDisabled();
      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: () => {
          const selectedIncidents = vm.checkedIncidentsState.getCheckedIncidentIds();
          vm.isDisabled = isClickDisabled();
          if (selectedIncidents.length) {
            vm.incidentIds = selectedIncidents;
          }
        },
      });

      // We have to use a timeout here, because we want to ensure the element is rendered and has dimensions.
      $timeout(() => {
        shouldShowSmallButton();
      });
    }

    function shouldShowSmallButton() {
      vm.showSmallButton = $element.width() < 450;
    }

    function openLifecycle() {
      pubSubService.broadcast('IncidentLifecycle.open');
      $state.go('.lifecycle');
    }

    function breakdownSources() {
      const breakdown = sourceBreakdownService.getSourceBreakdownData(
        vm.incident.entities,
        vm.incident.activeOnly
      );
      const systems = breakdown.systems;

      if (systems.length > 1) {
        const parentSystems = clone(breakdown.parentSystems);
        return {
          lastSystem: systems.pop(),
          otherSystems: systems.join(', '),
        };
      }

      return {
        lastSystem: breakdown.systems[0],
        otherSystems: null,
      };
    }

    function getTitle() {
      return IncidentTitleService.getTitle(vm.incident, {
        fontData: '',
        widthLimit: {
          max: 500,
          dynamicMin: 50,
        },
      });
    }

    function isClickDisabled() {
      return (vm.checkedIncidentsState.isAnythingChecked() || vm.clickDisabled) && !vm.isMultiple;
    }
  }
}
