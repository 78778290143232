import * as actionTypes from './actionTypes';

export const initialState = {
  isSaving: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isSaving: false,
        error: null,
      };
    case actionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
