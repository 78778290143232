angular.module('bigpanda').filter('lastUpdated', lastUpdated);

function lastUpdated() {
  return (time) => {
    if (time) {
      const momentTime = moment(time * 1000);
      if (isTimestampBeforeAYearAgo(momentTime)) {
        return momentTime.year();
      } else if (isTimestampBeforeYesterday(momentTime)) {
        return momentTime.format('MMM D');
      }
      return momentTime.format('h:mm a');
    }

    function isTimestampBeforeYesterday(momentTime) {
      return momentTime.isBefore(moment().subtract(1, 'days'));
    }

    function isTimestampBeforeAYearAgo(momentTime) {
      return momentTime.isBefore(moment().subtract(1, 'year'));
    }
  };
}
