import { Checkbox, HBox, HelpTooltip } from '@bp/kung-fu';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import selectors from '../../../../../../../app/react/modules/settings/alert_enrichment/selectors';
import { selectors as FTSelectors } from '../../../../../../../app/react/user/feature_toggles';
import { Tag } from '../../../../types';
import { MAX_NUM_OF_RULES, MAX_NUM_OF_TAGS, MAX_RULES_FOR_TAG } from '../../../constants/limits';
import texts from '../../../dictionary';
import { getInterpolatedText } from '../../../helpers/get-interpolated-text';
import { shouldUseTagOperations } from '../../../helpers/should-use-tag-operations';
import { WarningNotice } from '../WarningNotice';
import { Container, FooterLine } from './AlertTagFormFooter.style';

interface IProps {
  editMode: boolean;
  rules: {
    id: Tag['enrichments'][0]['id'],
    type: Tag['enrichments'][0]['type'],
  }[]
}

export function AlertTagFormFooter({ editMode, rules }: IProps):JSX.Element {
  const creationMode = !editMode;
  const totalNumOfTags = useSelector(selectors.getTotalTags);
  const totalNumOfRules = useSelector(selectors.getTotalRules);
  const useTagOperations = shouldUseTagOperations(
    useSelector(FTSelectors.getFeatureToggles),
  );

  const totalNumOfTagsWarningMessage = useMemo(
    () => getInterpolatedText(texts.create_tag_modal__maximum_num_of_tags_warning_message, {
      totalNumOfTags: MAX_NUM_OF_TAGS.toLocaleString(),
    }),
    [],
  );

  const hasPassedMaxNumOfRulesForThisTag = rules.length > MAX_RULES_FOR_TAG;
  const hasPassedMaxNumOfTotalRules = totalNumOfRules + rules.length > MAX_NUM_OF_RULES;
  const hasPassedRulesLimit = hasPassedMaxNumOfRulesForThisTag || hasPassedMaxNumOfTotalRules;

  const totalNumOfRulesWarningMessage = useMemo(
    () => getInterpolatedText(texts.edit_tag_modal__maximum_num_of_rules_warning_message, {
      totalNumOfRules: (hasPassedMaxNumOfRulesForThisTag
        ? MAX_RULES_FOR_TAG
        : MAX_NUM_OF_RULES
      ).toLocaleString(),
    }),
    [hasPassedMaxNumOfRulesForThisTag, hasPassedMaxNumOfTotalRules],
  );

  if (!useTagOperations) {
    return null;
  }

  const hasPassedTagsLimit = totalNumOfTags + 1 > MAX_NUM_OF_TAGS;

  return (
    <Container>
      {(creationMode && hasPassedTagsLimit) && (
        <FooterLine backgroundColor={(p): string => p.theme.bp_notification_yellow}>
          <WarningNotice text={totalNumOfTagsWarningMessage} />
        </FooterLine>
      )}

      {(editMode && hasPassedRulesLimit) && (
        <FooterLine backgroundColor={(p): string => p.theme.bp_notification_yellow}>
          <WarningNotice text={totalNumOfRulesWarningMessage} />
        </FooterLine>
      )}

      {creationMode && (
      <FooterLine backgroundColor={(p): string => p.theme.bp_gray_01}>
        <Field
          name="active"
          render={({ input }): JSX.Element => (
            <Checkbox
              text={texts.mark_tag_as_inactive_checkbox__text}
              checked={!input.value}
              name="active"
              ariaLabel={texts.mark_tag_as_inactive_checkbox__aria_label}
              onChange={(e): void => {
                input.onChange(!e.currentTarget.checked);
              }}
            />
          )}
        />
        <HBox marginStart="6px">
          <HelpTooltip text={texts.mark_tag_as_inactive_checkbox__tooltip_text} />
        </HBox>
      </FooterLine>
      )}
    </Container>
  );
}
