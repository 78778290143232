import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, pipe, sortBy } from 'lodash/fp';
import { TextSeparator } from 'react/common/components/TextSeparator';
import { LabelPropType } from '../constants';
import variants from './constants';
import { LabelGroup, LabelValues } from './ConfigurationLabelsRow.css';

function ConfigurationLabelsGroup({ variant, group, values }) {
  return (
    <LabelGroup variant={variant}>
      {group}: <LabelValues variant={variant}>{values.join(', ')}</LabelValues>
    </LabelGroup>
  );
}

ConfigurationLabelsGroup.propTypes = {
  group: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  variant: PropTypes.oneOf(Object.values(variants)).isRequired,
};

function ConfigurationLabelsRow({ labels, variant }) {
  const groupedLabels = pipe(
    groupBy(({ group }) => group.toLowerCase()),
    map(sortBy('value')),
    map((values) => ({
      group: values[0].group,
      values: values.map(({ value }) => value),
    })),
    sortBy('group')
  )(labels);

  const groups = groupedLabels.reduce(
    (res, { group, values }) => [
      ...res,
      <ConfigurationLabelsGroup
        group={group}
        values={values}
        variant={variant}
        key={`group${group}`}
      />,
      <TextSeparator key={`sep${group}`} />,
    ],
    []
  );
  return <span>{groups.slice(0, -1)}</span>;
}

ConfigurationLabelsRow.propTypes = {
  labels: PropTypes.arrayOf(LabelPropType),
  variant: PropTypes.oneOf(Object.values(variants)),
};

ConfigurationLabelsRow.defaultProps = {
  labels: [],
  variant: variants.normal,
};

export default ConfigurationLabelsRow;
