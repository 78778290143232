import filter from 'lodash/filter';
angular.module('bp.widgets').directive('bpTimeframePicker', () => {
  return {
    bindToController: true,
    controller: controller,
    controllerAs: 'timeframePickerController',
    restrict: 'E',
    scope: {
      selectedPreset: '=?',
      presets: '=?',
      includeTime: '@?',
      disableCondition: '=ngDisabled',
      dark: '@',
      hideIconArrowDown: '=',
    },
    templateUrl: 'widgets/bp_timeframe_picker/bp_timeframe_picker',
  };

  function controller($element, $filter, $timeout, pubSubService, deepCloneObject) {
    let vm = this;

    vm.presets = vm.presets || [
      {
        timeframe: { type: 'relative', interval: '/d' },
        title: 'Today',
        getStartMoment: function (end) {
          return moment(end || undefined).startOf('day');
        },
      },
      {
        timeframe: { type: 'relative', interval: '/w' },
        title: 'Last Week',
        getStartMoment: function (end) {
          return moment(end || undefined).startOf('isoweek');
        },
      }, // isoweek starts weeks on mondays
      {
        timeframe: { type: 'relative', interval: '/M' },
        title: 'Last Month',
        getStartMoment: function (end) {
          return moment(end || undefined).startOf('month');
        },
      },
      {
        timeframe: { type: 'relative', interval: '-7d/d' },
        title: 'Last 7 Days',
        getStartMoment: function (end) {
          return moment(end || undefined)
            .subtract(7, 'days')
            .startOf('day');
        },
      },
      {
        timeframe: { type: 'relative', interval: '-30d/d' },
        title: 'Last 30 Days',
        getStartMoment: function (end) {
          return moment(end || undefined)
            .subtract(30, 'days')
            .startOf('day');
        },
      },
      // Notice that the style always assumes that if you have an absolute timeframe, it's going to be the last one in
      // the list. It seemed like a waste of time to write code that validates it, so I just left it as a comment
      // If for some reason you need to put the absolute timeframe in a different place, just make sure to override
      // the style
      { timeframe: { type: 'absolute', interval: 'custom' }, title: 'Pick Date Range...' },
    ];

    vm.customPreset = null;
    vm.customPresetOpen = false;

    vm.select = select;
    vm.saveCustomDate = saveCustomDate;
    vm.cancelCustomDate = cancelCustomDate;
    vm.validateCustomPreset = validateCustomPreset;
    vm.customTimeframe = null;
    vm.$onInit = init;

    function init() {
      vm.presets.forEach(addFullTitle);
      vm.selectedPreset = vm.selectedPreset || vm.presets[4];
      addFullTitle(vm.selectedPreset);
      vm.tempPreset = vm.selectedPreset; // temporary preset to prevent empty *Custom Timeframe*

      // This module doesn't support dynamic presets, it means that when the timeframe picker is initialized, we expect
      // all presets to be set already and not changed afterwards
      const absolutePresets = getAllAbsolutePresets();
      if (absolutePresets.length > 1) {
        console.warn(
          'There is more than one absolute preset in the timeframe picker, we might get issues...'
        );
      }
      vm.customPreset = absolutePresets[0];
      pubSubService.on('investigator.resetTimeFrame', () => {
        vm.tempPreset = vm.presets[4];
      });
    }

    function getAllAbsolutePresets() {
      return filter(vm.presets, (preset) => preset.timeframe.type == 'absolute');
    }

    function validateCustomPreset() {
      const startTime = new Date(vm.customTimeframe.start);
      const endTime = new Date(vm.customTimeframe.end);

      return !(moment(startTime).isValid() && moment(endTime).isValid() && endTime - startTime > 0);
    }

    function select(value) {
      if (value.timeframe.type === 'absolute') {
        vm.customTimeframe = deepCloneObject.cloneDeep(vm.customPreset.timeframe);
        vm.customPresetOpen = true;
      } else {
        vm.selectedPreset = vm.tempPreset;
      }
    }

    function saveCustomDate() {
      vm.customPresetOpen = false;

      if (!vm.includeTime && vm.customTimeframe.end) {
        vm.customTimeframe.end = moment(vm.customTimeframe.end).endOf('day').toDate();
      }

      vm.selectedPreset = vm.customPreset;
      vm.tempPreset = vm.selectedPreset;
      vm.selectedPreset.timeframe.start = vm.customTimeframe.start;
      vm.selectedPreset.timeframe.end = vm.customTimeframe.end;

      addFullTitle(vm.selectedPreset);
    }

    function cancelCustomDate() {
      vm.customPresetOpen = false;
      addFullTitle(vm.selectedPreset);
      vm.tempPreset = vm.selectedPreset; // revert to last choice
      $timeout(() => $element.find('.dropdown-trigger').trigger('click')); // reopen dropdown
    }

    function addFullTitle(preset) {
      let fullTitle;
      fullTitle = preset.title;
      if (preset.timeframe.type === 'relative' || !!preset.timeframe.start) {
        fullTitle = `${fullTitle} <span class="secondary-text">(${$filter('timeframePresetRange')(
          preset,
          null,
          vm.includeTime
        )})</span>`;
      }
      if (preset.timeframe.type === 'absolute') {
        fullTitle = `<i class="bp-icon-calendar"></i>${fullTitle}`;
      }

      preset.fullTitle = fullTitle;
    }
  }
});
