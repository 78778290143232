angular.module('bigpanda').directive('multipleCommentButton', multipleCommentButton);

function multipleCommentButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '^^checkedIncidentsState',
    },
    scope: {
      commentState: '@',
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: controller,
    templateUrl: 'overview/incidents/list/widgets/comment_button/multiple_comment_button',
  };

  function controller($scope, $state, ModalService) {
    const vm = this;

    vm.showComments = showComments;

    init();

    function init() {
      $scope.$on('$destroy', () => {
        ModalService.hide();
      });
    }

    function showComments() {
      ModalService.showModal({
        templateUrl: 'overview/incidents/actions/comment/popup/multi_comment_popup',
        controller: 'MultiCommentPopupController',
        controllerAs: 'vm',
        bindToController: true,
        locals: { incidentIds: vm.checkedIncidentsState.getCheckedIncidentIds() },
      }).then(() => {
        vm.checkedIncidentsState.clear();
      });
    }
  }
}
