import get from 'lodash/get';

import { path } from './constants';

const selectors = {
  getUpdateRequestState: (state) => get(state, path).updateRequestStatus,
  getIncidentTags: (state) => get(state, path).incidentTagsData || [],
};

export default selectors;
