import { HBox } from '@bp/kung-fu';
import { Field } from 'react-final-form';
import styled from 'styled-components';

export const CompositionForm = styled(HBox)`
    justify-content: flex-start;
`;

export const SourceSystem = styled.div`
    margin-right: 10px;
    width: 180px;
`;

export const IntegrationField = styled(Field)`
    margin-right: 10px;
    width: 180px;
`;

export const IntegrationFieldv2 = styled(Field)`
    margin-right: 10px;
    width: 140px;
    margin-bottom: 20px;
`;
