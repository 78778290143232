import { BamModal } from '@bp/bam';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalTriggerButton from '../../../../../../app/react/layout/settings/components/ModalTriggerButton';
import actions from '../../../../../../app/react/modules/settings/maintenance_plans/maintenance_plans_list/actions';
import selectors from '../../../../../../app/react/modules/settings/maintenance_plans/maintenance_plans_list/selectors';
import { selectors as featureTogglesSelectors } from '../../../../../../app/react/user/feature_toggles';
import { FeatureToggles } from '../../../types/FeatureToggles';
import { MaintenancePlan } from '../../../types/MaintenancePlan';
import MaintenanceForm from '../form/MaintenanceForm';
import RecurringMaintenanceForm from '../form/RecurringMaintenanceForm';
import { DispatchPayload } from './types/DispatchPayload';
import { MaintenanceModalProps } from './types/MaintenanceModalProps';

export function MaintenanceModal(props: MaintenanceModalProps): JSX.Element {
  const dispatch = useDispatch();

  const plans: MaintenancePlan[] = useSelector(selectors.getPlans);
  const showDonePlans: boolean = useSelector(selectors.showDonePlans);
  const featureToggles: FeatureToggles = useSelector(featureTogglesSelectors.getFeatureToggles);

  const createOrUpdatePlan = (payload: DispatchPayload): void => dispatch(
    actions.createOrUpdatePlan(payload),
  );
  const stopPlanNow = (payload: DispatchPayload): void => dispatch(
    actions.stopPlan(payload),
  );
  const updatePlanEnd = (payload: DispatchPayload): void => dispatch(
    actions.updatePlanEnd(payload),
  );

  let ref = null;

  const handleClose = (): void => {
    ref.close();
  };

  const {
    initialValues,
    triggerProps,
    stopPlan,
    editTimeWindow,
    duplicate,
    titleIcon,
    title,
  } = props;

  const disableQueryHelper = featureToggles?.disable_query_helper || false;
  const recurringMaintenance = featureToggles?.frontier_recurring_maintenance_plans || false;

  const onMountModal = (): void => {
    if (!plans) {
      dispatch(actions.loadPlans());
    }
  };

  let positiveButtonAction: string;
  let positiveButtonProductId: string;
  if (stopPlan) {
    positiveButtonAction = '\vStop\v';
  } else if (editTimeWindow) {
    positiveButtonAction = 'Update Time Window';
  } else if (duplicate) {
    positiveButtonAction = 'Duplicate Plan';
  } else if (initialValues && initialValues.id) {
    positiveButtonAction = 'Update Plan';
    positiveButtonProductId = 'update_maintenance_plan';
  } else {
    positiveButtonAction = 'Create Plan';
    positiveButtonProductId = 'create_maintenance_plan';
  }

  const isEdit = !!initialValues?.id;
  const isNew = !(isEdit || duplicate);
  return (
    <BamModal
      ref={(r): void => {
        ref = r;
      }}
      style={{ overflow: !disableQueryHelper ? 'visible' : 'auto', width: '535px' }}
      trigger={<ModalTriggerButton {...triggerProps} />}
      titleIcon={titleIcon}
      title={title}
      onMount={onMountModal}
      size={stopPlan ? 'mini' : 'small'}
      formMode
      showDonePlans
      autoFocus
    >
      {recurringMaintenance
        ? (
          <RecurringMaintenanceForm
            initialValues={initialValues}
            createOrUpdatePlan={createOrUpdatePlan}
            editTimeWindow={editTimeWindow}
            stopPlan={stopPlan}
            stopPlanNow={stopPlanNow}
            updatePlanEnd={updatePlanEnd}
            duplicate={duplicate}
            positiveButtonText={positiveButtonAction}
            positiveButtonProductId={positiveButtonProductId}
            close={handleClose}
            isEdit={isEdit}
            isNew={isNew}
          />
        )
        : (
          <MaintenanceForm
            initialValues={initialValues}
            createOrUpdatePlan={createOrUpdatePlan}
            editTimeWindow={editTimeWindow}
            stopPlan={stopPlan}
            stopPlanNow={stopPlanNow}
            updatePlanEnd={updatePlanEnd}
            duplicate={duplicate}
            showDonePlans={showDonePlans}
            positiveButtonText={positiveButtonAction}
            positiveButtonProductId={positiveButtonProductId}
            close={handleClose}
          />
        )}
    </BamModal>
  );
}

export default MaintenanceModal;
