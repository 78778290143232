import { selectors as permissionsSelectors } from 'react/user/permissions';
import permissions from 'common/routing/permissions';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { pipe, curry, identity } from 'lodash/fp';
import boolToLodashOrder from 'common/utils/boolToLodashOrder';
import { path } from './constants';
import { generateBaseSelectors, filterItems, filterItemsByDropDownFilters } from '../redux.utils';
import { DEFAULT_SORT_MODE, ANY_OPTION_VALUE } from './custom_tags_list/constants';

const customTagsFilterByText = (text, items) =>
  filterItems(items, text, (customTag) => [
    customTag.destination,
    customTag.template,
    customTag.source,
    customTag.pattern,
    customTag.displayQuery,
    ...(customTag.sourceSystems || []).map((t) => t.display),
    customTag.metadata.description,
  ]);

const customTagsFilterByDropDown = (filtersDropDownState, items) =>
  filterItemsByDropDownFilters(
    filtersDropDownState,
    {
      tagName: (item) => [item.destination],
      status: (item) => [item.status],
      type: (item) => [item.type],
      source: (item) => item.sourceSystems.map((d) => d.value),
    },
    items,
    ANY_OPTION_VALUE
  );

const customTagsSort = ({ field, ascending }, items) => {
  if (!items) {
    return items;
  }
  const { field: defaultField, ascending: defaultOrder } = DEFAULT_SORT_MODE;
  return orderBy(items, [field, defaultField], [ascending, defaultOrder].map(boolToLodashOrder));
};

const selectors = generateBaseSelectors(path);

selectors.getActionsPermissions = (state) => {
  const userPermissions = permissionsSelectors.getPermissionsList(state);
  const hasEditPermission = userPermissions.includes(permissions.tags.update);
  const hasDuplicatePermission = userPermissions.includes(permissions.tags.create);
  const hasDeletePermission = userPermissions.includes(permissions.tags.delete);
  return { hasEditPermission, hasDuplicatePermission, hasDeletePermission };
};

selectors.getCustomTags = (
  state,
  shouldFilterByText = true,
  shouldFilterByDropDownFilters = true,
  shouldSort = true
) =>
  pipe(
    shouldFilterByText ? curry(customTagsFilterByText)(selectors.getFilterText(state)) : identity,
    shouldFilterByDropDownFilters
      ? curry(customTagsFilterByDropDown)(selectors.getFiltersDropDownState(state))
      : identity,
    shouldSort ? curry(customTagsSort)(selectors.getSortMode(state)) : identity
  )(get(state, path).customTags);

selectors.getCustomTag = (state, id) => {
  const customTags = selectors.getCustomTags(state);
  if (!customTags || customTags.length === 0) {
    return null;
  }
  return customTags.filter((customTag) => customTag.id === id)[0];
};
selectors.getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;

export default selectors;
