angular
  .module('bigpanda')
  .controller('SplitDestinationActivityEventCtrl', SplitDestinationActivityEventCtrl);

function SplitDestinationActivityEventCtrl($scope, $stateParams) {
  $scope.event.envId = $stateParams.environment;
  $scope.event.folder = $stateParams.folder;
  $scope.event.sourceIncidentId = $scope.event._source.params.sourceIncidentId;
  $scope.event.entityIdsCount = $scope.event._source.params.entityIds.length;
  $scope.event.plural = $scope.event._source.params.entityIds.length > 1 ? 's' : '';
  $scope.event.annotation = $scope.event._source.params.annotation;
}
