import { LinkIcon, TextArea, useForm } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import React, { createRef, useEffect } from 'react';

import { ModalFormWrapper } from './IncidentTagsGridCellV2.style';

function IncidentTagsGridCellV2Text(props) {
  const { tagValue, updateParent, tagLabel } = props;
  const {
    state, register, errors, updateField,
  } = useForm({ textValue: tagValue });

  const textAreaRef = createRef();

  useEffect(() => {
    updateParent(state.textValue, errors.textValue);
  }, [state.textValue, errors.textValue]);

  const textFieldValidation = (value) => {
    if (value.length > 400) {
      return 'Max 400 chars';
    }
    return null;
  };

  const handleClick = () => {
    const position = textAreaRef.current.selectionStart;
    const newVal = [
      state.textValue.slice(0, position),
      '[Text|http://example.com]',
      state.textValue.slice(position),
    ].join('');
    updateField('textValue', newVal);
  };

  return (
    <ModalFormWrapper>
      <TextArea
        ref={textAreaRef}
        {...register('textValue', { validation: textFieldValidation, type: 'blur' })}
        label={`${tagLabel}:`}
        value={state.textValue}
        width="420px"
        rows="2"
        autoFocus
        action={{
          icon: <LinkIcon />,
          onClick: handleClick,
          ariaLabel: 'Add link icon',
        }}
      />
    </ModalFormWrapper>
  );
}

IncidentTagsGridCellV2Text.propTypes = {
  tagValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  updateParent: PropTypes.func.isRequired,
  tagLabel: PropTypes.string.isRequired,
};

export default IncidentTagsGridCellV2Text;
