import { combineEpics } from 'redux-observable';
import { getIntegrations as getIntegrationsRequest } from 'common/endpoints/integrations';
import { showFailureMessage } from 'react/layout/settings/feedback';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { extendDisplayedIntegrations } from './utils';

const showFailureMessageForIntegrations = (action) => showFailureMessage('Integrations', action);

const loadIntegrations = (action$) =>
  action$.ofType(actionTypes.LOAD_INTEGRATIONS).mergeMap(async () => {
    try {
      const integrations = await getIntegrationsRequest();
      const displayedIntegrations = extendDisplayedIntegrations(integrations);
      return actions.loadIntegrationsSuccess(displayedIntegrations);
    } catch (ex) {
      showFailureMessageForIntegrations('load');
    }
  });

export default combineEpics(loadIntegrations);
