angular.module('bigpanda').service('EnvironmentMenu', EnvironmentMenu);

function EnvironmentMenu(
  $rootScope,
  $state,
  $log,
  pubSubService,
  notificationService,
  ModalService,
  CompatibilityService,
  EnvironmentsService,
  previousStateService,
  RulesService,
  Permissions,
  CustomizationsUtils,
  CustomizationsStore,
  PERMISSIONS_NAMES,
  UserFeatureTogglesService
) {
  this.getContent = getContent;

  const actions = {
    edit: {
      id: 'edit',
      label: 'Edit',
      exe: editEnvironment,
      icon: 'bp-icon-edit',
      permission: PERMISSIONS_NAMES.environments.update,
    },
    duplicate: {
      id: 'duplicate',
      label: 'Duplicate',
      exe: duplicateEnvironment,
      icon: 'bp-icon-duplicate',
      permission: PERMISSIONS_NAMES.environments.create,
    },
    delete: {
      id: 'delete',
      label: 'Delete',
      exe: deleteEnvironment,
      icon: 'bp-icon-trash',
      permission: PERMISSIONS_NAMES.environments.delete,
    },
    favorite: {
      id: 'favorite',
      label: 'Unstar',
      exe: unfavoriteEnvironment,
      icon: 'bp-icon-star-hollow',
      appliesForAll: true,
    },
    notFavorite: {
      label: 'Star',
      exe: favoriteEnvironment,
      icon: 'bp-icon-star-full',
      appliesForAll: true,
    },
  };

  function getContent(environment, isFavorite, refreshFcn) {
    const content = [];

    const getBound = (action) =>
      Object.assign({}, action, {
        exe: action.exe.bind(null, environment, refreshFcn),
      });

    if (environment.name !== 'All') {
      [actions.edit, actions.duplicate, actions.delete].forEach((actionFcn) =>
        content.push(getBound(actionFcn))
      );
    } else if (UserFeatureTogglesService.getToggle('enable_global_auth')) {
      content.push(getBound(actions.edit));
    }

    if (isFavorite) {
      content.push(getBound(actions.favorite));
    } else {
      content.push(getBound(actions.notFavorite));
    }

    return content;
  }

  function editEnvironment(env) {
    previousStateService.resetPreviousState();
    previousStateService.saveAndGoto($state.current, $state.params, 'app.environments', {
      environmentId: env._id,
    });
  }

  function duplicateEnvironment(env) {
    previousStateService.saveAndGoto($state.current, $state.params, 'app.environments', {
      dupEnvironmentId: env._id,
    });
  }

  async function deleteEnvironment(env) {
    if (!Permissions.isPermitted(PERMISSIONS_NAMES.environments.delete)) return;

    const scope = $rootScope.$new();
    scope.env = env;

    scope.delete = () => {
      EnvironmentsService.delete(env._id).then(() => pubSubService.broadcast('Envy.refresh'));
      ModalService.hide();
    };

    scope.hide = ModalService.hide;

    const modalParams = {
      show: true,
      keyboard: CompatibilityService.canClosePopupsWithEscape(),
      templateUrl: 'overview/environments/actions/environment_delete',
      scope: scope,
    };

    if (!CompatibilityService.canClosePopupsWithBackdropClick()) {
      modalParams.backdrop = 'static';
    }
    return ModalService.showModal(modalParams);
  }

  function favoriteEnvironment(env) {
    updateFavorites((favorites) => CustomizationsUtils.addFavorite(favorites, env, 'EnvMenu'));
  }

  function unfavoriteEnvironment(env) {
    updateFavorites((favorites) => CustomizationsUtils.deleteFavorite(favorites, env, 'EnvMenu'));
  }

  function updateFavorites(updaterFunc) {
    CustomizationsStore.getCustomizations()
      .then((customizations) => {
        const favorites = updaterFunc(customizations.environments.navigation_list.favorites);
        return CustomizationsStore.updateCustomizations({
          environments: { navigation_list: { favorites: favorites } },
        });
      })
      .catch((err) => {
        $log.error(`Failed updating user customizations: ${err}`);
        notificationService.error('Error updating stars', { error: err });
      });
  }
}
