import { BamFiltersPlaceHolder } from '@bp/bam';
import React from 'react';

import FiltersDropdown from '../../../../../../app/react/common/components/FiltersDropdown/FiltersDropdown';
import { ANY_OPTION_VALUE, DROPDOWN_FILTERS_ALL_ITEM_OPTION } from '../../../../../../app/react/modules/settings/alert_enrichment/constants';
import { DropdownOption } from '../../../../alert-filtering/src/types/DropdownOption';
import dictionary from '../../dictionary';

interface IProps {
  filters: Record<string, DropdownOption>;
  onChange: (payload: object) => void;
  data: Record<string, DropdownOption[]>;
}

export function Filters({ filters, onChange, data }: IProps): JSX.Element {
  const {
    status: { value: statusValue, text: statusText },
    type: { value: typeValue, text: typeText },
    source: { value: sourceValue, text: sourceText },
  } = filters;

  const dropdownElements = [
    <FiltersDropdown
      data={data.status}
      selectedValue={statusValue}
      selectedText={statusText}
      defaultText={dictionary.rule_details_status}
      onChange={(e, selection): void => onChange({ status: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
      aria-label="status filter"
    />,
    <FiltersDropdown
      data={data.type}
      selectedValue={typeValue}
      selectedText={typeText}
      defaultText={dictionary.rule_details_type}
      onChange={(e, selection): void => onChange({ type: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
      aria-label="type filter"
    />,
    <FiltersDropdown
      data={data.source}
      selectedValue={sourceValue}
      selectedText={sourceText}
      defaultText={dictionary.rule_details_source}
      onChange={(e, selection): void => onChange({ source: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
      aria-label="source filter"
    />,
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={[typeValue, sourceValue, statusValue].some(
        (v) => v !== ANY_OPTION_VALUE,
      )}
      onClearButtonClick={(): void => {
        onChange({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
      }}
    />
  );
}

export default Filters;
