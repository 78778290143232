import extend from 'lodash/extend';
angular.module('bigpanda').factory('MetricsLog', MetricsLog);

function MetricsLog($http, $log) {
  return {
    info: (eventName, data) => {
      if (eventName && typeof eventName === 'string') {
        const postData = extend({}, { eventName: eventName }, { params: data });
        $http.post('/web/metrics/log', postData);
      } else {
        $log.error('eventName param must be a string');
      }
    },
  };
}
