angular.module('bigpanda.reports').filter(
  'eventCompression',
  () =>
    function (events, incidents) {
      var compression = Math.round(((events - incidents) / events) * 10000) / 100;
      if (compression < 0 || !compression) {
        compression = 0;
      }

      return compression;
    }
);
