/* eslint-disable max-len */
export default {
  add_description__label: 'Add description',
  add_description__description_text: '(optional)',
  add_description__aria_label: 'Add description',
  add_description__error_message: 'Tag description cannot exceed {{maxLength}} characters',
  tag_name_input_field__label: 'Tag Name',
  tag_name_input_field__aria_label: 'tag name',
  tag_name_input_field__placeholder: 'e.g. cluster',
  tag_name_disabled_tooltip_text: 'Existing Tag name used as the Tag’s ID across the system and cannot be changed',
  tag_name_info_tooltip_line1: 'Tag name is used as ID and cannot be updated later',
  tag_name_info_tooltip_line2: 'Mapping items will be mapped to this tag according to the tag name',
  tag_name_info_tooltip_line2_learn_more: 'Learn more',
  tag_name_learn_more_link: 'https://docs.bigpanda.io/docs/alert-enrichment#mapping-enrichment',
  active_tag_activation_toggle_tooltip_text: 'Mark as Inactive',
  inactive_tag_activation_toggle_tooltip_text: 'Mark as Active',
  tag_activation_toggle_aria_label: 'Toggle tag activation state',
  mark_tag_as_inactive_checkbox__text: "Create as 'Inactive'",
  mark_tag_as_inactive_checkbox__aria_label: 'Create new tag as inactive',
  mark_tag_as_inactive_checkbox__tooltip_text: 'Save the Alert Tag definition without activating it',
  create_tag_modal__maximum_num_of_tags_warning_message:
        'Cannot add a new item - maximum number of items for a Tag has been reached ({{totalNumOfTags}})',
  edit_tag_modal__maximum_num_of_rules_warning_message:
        'Cannot add a new item - maximum number of items has been reached ({{totalNumOfRules}})',
  create_tag_lobby_toast_limit_reached_toast__title: 'Failed to create new Alert tag',
  edit_tag_lobby_toast_limit_reached_toast__title: 'Failed to edit existing Alert tag',
  create_tag_lobby_toast_tags_limit_reached_toast__message:
        'you have reached to the maximum number of tags ({{totalNumOfTags}})',
  edit_tag_lobby_toast_rules_limit_reached_toast__message:
        'you have reached to the maximum number of items ({{totalNumOfRules}})',
  incidents: 'Incidents',
  alert_tags: 'Alert Tags',
  recent_searches: 'Recent Searches',
  multi_type_condition_query_helper_trigger_component_props_title: 'Condition',
  multi_type_condition_query_helper_trigger_component_props_description: '(optional)',
  multi_type_condition_query_helper_placeholder: 'e.g. host = acme*',
  multi_type_condition_query_helper__dropdown_option1_title: 'assignee',
  multi_type_condition_query_helper__dropdown_option2_title: 'status',
  multi_type_condition_query_helper__dropdown_option3_title: 'source_system',
  multi_type_condition_query_helper__dropdown_option1_description: 'The assigned user for the incident',
  multi_type_condition_query_helper__dropdown_option2_description:
        'The current status of the incident (“Critical”, “Warning“, ”Unknown“, ”Ok“)',
  multi_type_condition_query_helper__dropdown_option3_description: 'The integration that sent an alert to BigPanda',
  multi_type_condition_query_helper__dropdown_option1_example1: 'assignee = derrick*',
  multi_type_condition_query_helper__dropdown_option1_example2: 'assignee = derrick@bigpanda.io',
  multi_type_condition_query_helper__dropdown_option2_example1: 'status = critical',
  multi_type_condition_query_helper__dropdown_option3_example1: 'source_system = nagios*',
  multi_type_condition_query_helper__dropdown_option3_example2: 'source_system IN [nagios*,new_relic*]',
  lobby__duplicate_or_delete_mapping_tag:
        'Tags containing mapping enrichment items only cannot be deleted or duplicated from this screen. Use the mapping enrichment API to update these tags',
  lobby__delete_multi_type_with_mapping_rules:
        'Tags containing mapping enrichment items cannot be deleted from this screen. Use the mapping enrichment API to delete these tags',
  lobby__duplicate_multi_type_tag_with_mapping:
        'Duplicating tags containing mapping enrichment items will only duplicate Extraction and Composition items. Use the mapping enrichment API to add mapping items to the duplicated tag',
  rule_summary__disabled_mapping_rule_tooltip_text:
        'Mapping enrichment items cannot be deleted or duplicated from this screen. Use the mapping enrichment API to update these items.',
  lobby__duplicate_tag_error: "Cannot duplicate this Alert Tag '{{tagName}}'",
  lobby__edit_tag_error: "Cannot edit this Alert Tag '{{tagName}}'",
  items_contain_different_values_from_map: 'This items contains various different values from the map {{name}}',
  rule_details_status: 'Status',
  rule_details_value: 'Value',
  rule_details_source: 'Source',
  rule_details_condition: 'Condition',
  rule_details_note: 'Note',
  rule_details_function: 'Function',
  rule_details_item_title: 'Item Details',
  rule_details_type: 'Type',
};
/* eslint-enable max-len */
