import React from 'react';
import PropTypes from 'prop-types';
import styles from './tabs_bar.scss';
import SingleTab from './SingleTab';
import singleTabPropType from './props/singleTabPropTypes';

class TabsBar extends React.Component {
  state = {
    selectedValue: '',
  };

  onTabClick = (clickedValue) => {
    this.setState({
      selectedValue: clickedValue.name,
    });
    this.props.onTabClick(clickedValue);
  };

  render() {
    const { selectedValue } = this.state;
    const { tabs, isFixed, fixedHeight, fixedWidth, height, selectedTab } = this.props;

    return (
      <div className={styles.tabsBar}>
        {tabs.map((singleTabObj) => (
          <SingleTab
            key={singleTabObj.name}
            tabObj={singleTabObj}
            isSelected={singleTabObj.name === (selectedTab || selectedValue)}
            onClick={this.onTabClick}
            isFixed={isFixed}
            height={height}
            fixedHeight={fixedHeight}
            fixedWidth={fixedWidth}
          />
        ))}
      </div>
    );
  }
}

TabsBar.propTypes = {
  tabs: PropTypes.arrayOf(singleTabPropType).isRequired,
  onTabClick: PropTypes.func.isRequired,
  isFixed: PropTypes.bool,
  height: PropTypes.number,
  fixedHeight: PropTypes.number,
  fixedWidth: PropTypes.number,
  selectedTab: PropTypes.string.isRequired,
};

TabsBar.defaultProps = {
  isFixed: undefined,
  height: undefined,
  fixedHeight: undefined,
  fixedWidth: undefined,
};

export default TabsBar;
