import { HBox, SBHBox, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const ButtonWrapper = styled(SBHBox)`
margin-top: 20px;
`;

export const TagType = styled(Text)`
padding-bottom: 10px;
padding-right: 20px;
width: 25%;
min-width: 25%;
height: 31px;
`;

export const TagValue = styled(HBox)`
padding-bottom: 10px;
width: calc(75% - 20px);

& * {
white-space: pre-wrap;
word-break: break-all;
}
`;

export const StyledHBox = styled(HBox)`
margin-top: ${({ index }) => (index ? '0' : '5px')};
`;
