const ngRedux = require('ng-redux').default;

require('./config.module');
require('./runtime.module');
require('./notification.module');
require('./frontend.services.module');

module.exports = angular.module(
  'bigpanda', // eslint-disable-line angular/module-setter
  [
    'bigpanda.runtime',
    'bigpanda.cookies',
    'bigpanda.utils',
    'bigpanda.Config',
    'bigpanda.frontend.services',
    'bigpanda.backend.services',
    'bigpanda.reports',

    /* --- Widgets --- */
    'bp.widgets',
    'bp.notification',

    /* --- Libraries --- */

    ngRedux,
    'ui.router',
    'ui.router.state.events',
    'BPSiftWrapper',
    'DisplayCommon',
    'angular-md5',
    'ngAnimate',
    'ngSanitize',
    'mgcrea.ngStrap',
    'ngMaterial',
    'ngTagsInput',
    'BPQL',
    'ReduxSubscriber',
    'infinite-scroll',
    'permission',
    'permission.ui',
    'bamboo.library',
    'LocalStorageModule',
    'Axios',
  ]
);
