import { darkTheme, HBox } from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { BaseText, MapName } from './MappingValueTableRow.css';

export function MappingValueTableRow({ mapName }: { mapName: string }): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <HBox>
        <BaseText>Various Values</BaseText>
        <MapName>
          (
          {mapName}
          )
        </MapName>
      </HBox>
    </ThemeProvider>
  );
}

export default MappingValueTableRow;
