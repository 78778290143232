import find from 'lodash/find';
angular.module('bigpanda.backend.services').factory('SsoService', () => {
  var identityProviders = [
    { name: 'onelogin', display: 'OneLogin', imgSrc: '/assets/img/logos/onelogin.png' },
    { name: 'okta', display: 'Okta', imgSrc: '/assets/img/logos/okta.png' },
    { name: 'pingone', display: 'PingOne', imgSrc: '/assets/img/logos/pingone.png' },
    { name: 'azure', display: 'Azure AD', imgSrc: '/assets/img/AzureAD.svg' },
    {
      name: 'ca',
      display: 'CA Single Sign On',
      imgSrc: 'assets/img/logos/ca.png',
      options: { featureToggle: true },
    },
  ];

  function getAllIdentityProviders() {
    return identityProviders;
  }

  function findIdP(id) {
    return find(identityProviders, { name: id });
  }

  return {
    getAllIdentityProviders: getAllIdentityProviders,
    findIdP: findIdP,
  };
});
