import { BamPreview } from '@bp/bam';
import { Text } from '@bp/kung-fu';
import React from 'react';

import { TextSeparator } from '../../../../../../app/react/common/components';
import { getInterpolatedText } from '../../../../alert-enrichment/src/helpers/get-interpolated-text';
import dictionary from '../../dictionary';
import { IntegrationOption } from '../../types/IntegrationOption';
import { PreviewState } from '../../types/PreviewState';
import { Subheader, SubheaderTitle } from './FilterPreview.styles';
import { FilterPreviewTable } from './FilterPreviewTable';

interface IProps {
  canPreview: boolean;
  getPreviewFilters: () => void;
  previewState: PreviewState;
  isLoading: boolean;
  isFormChanged: boolean;
  sourceSystems: IntegrationOption[];
}

export function FilterPreview({
  canPreview, getPreviewFilters, previewState, isLoading, isFormChanged, sourceSystems,
}: IProps): JSX.Element {
  const { refreshButtonStates } = BamPreview.Header;
  const buttonState = (canPreview && !isLoading) ? refreshButtonStates.VISIBLE : refreshButtonStates.DISABLED;
  const buttonText = previewState?.hasPreviewed ? dictionary.refresh : dictionary.preview_filtered_alerts;
  const refreshNotice = isFormChanged ? dictionary.definition_changed : '';

  return (
    <BamPreview
      header={(
        <BamPreview.Header
          onRefresh={getPreviewFilters}
          refreshButtonText={buttonText}
          refreshButtonState={buttonState}
          refreshNotice={refreshNotice}
        />
      )}
      subheader={previewState?.events
        && (
        <Subheader>
          <Text weight="bold">
            {getInterpolatedText(dictionary.filter_preview_subheader, { limit: `${previewState.events.length}` })}
          </Text>
          <TextSeparator />
          <SubheaderTitle>
            {dictionary.source_systems}
            :
          </SubheaderTitle>
          <Text>
            {sourceSystems.map(({ display }) => display).join(', ')}
          </Text>
        </Subheader>
        )}
      loading={isLoading}
      emptyPreviewHeader={previewState?.events ? 'No Matches' : dictionary.alert_filter_preview}
      emptyPreviewMessage={
        previewState?.events
          ? 'Adjust form definitions to preview matching alerts'
          : dictionary.empty_preview_message
      }
      unpadded
    >
      {previewState?.events?.length > 0
      && <FilterPreviewTable alerts={previewState.events} conditionFields={previewState.condition_fields} />}
    </BamPreview>
  );
}
