import { BamDropdownField } from '@bp/bam';
import React from 'react';

import ruleFieldName from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/ruleFieldName';
import { sourceSystemsOptions } from '../../../../../../../app/react/modules/settings/alert_enrichment/utils';
import {
  validateSource,
  validateSourceSystems,
} from '../../../../../../../app/react/modules/settings/alert_enrichment/validation.utils';
import { IntegrationOption, LoadPreview } from '../types';
import { SourceSystemField, SourceTagField } from './Extraction.style';

interface IProps {
  ruleId: string;
  integrationOptions: IntegrationOption[];
  sourceSystemOptions: IntegrationOption[];
  integration: string;
  loadPreview: LoadPreview;
}

export function ExtractionFields({
  ruleId,
  integrationOptions,
  sourceSystemOptions,
  integration,
  loadPreview,
}: IProps): JSX.Element {
  return (
    <>
      <SourceSystemField
        name={ruleFieldName(ruleId, 'integration')}
        validate={validateSourceSystems}
        validateOnBlur
        component={BamDropdownField}
        title="Source System"
        options={sourceSystemsOptions(integrationOptions)}
        placeholder="Select source..."
      />
      <SourceTagField
        name={ruleFieldName(ruleId, 'source')}
        component={BamDropdownField}
        title="Source Tag"
        options={sourceSystemOptions}
        limitDisplayedItems={100}
        placeholder="Select tag..."
        validate={validateSource}
        validateOnBlur
        disabled={!integration}
        onBlur={loadPreview}
      />
    </>
  );
}

export default ExtractionFields;
