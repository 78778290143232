import React from 'react';
import { BamForm, BamInput, BamModal, BamFieldTitle } from '@bp/bam';
import {
  HBox,
  Text,
  WarningIcon,
  EditIcon,
  darkTheme,
  Button,
  HelpTooltip,
  PermissionsIcon,
  AllSizes,
} from '@bp/kung-fu';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { incidentTagTypes } from '../../../settings/incident_labels/constants';
import IncidentTagsUpdateMultiValue from './IncidentTagsUpdateMultiValue';
import styles from './IncidentTagsUpdateModal.scss';

const IncidentTagsUpdateModal = (props) => {
  const { tagDefinition, onValueChange, onSubmit, cellValue, error, onValidationChange } = props;

  let ref;
  const handleClose = () => {
    ref && ref.close();
  };

  const handleSubmit = (values, form) => {
    onValueChange(values.tagValue);
    onSubmit(values.tagValue);
    handleClose();
  };

  const getInputField = () => {
    switch (tagDefinition.type) {
      case incidentTagTypes.TEXT:
        return <Field name="tagValue" component={BamInput} title={tagDefinition.name} />;
      case incidentTagTypes.MULTI_VALUE:
        return (
          <Field
            name="tagValue"
            render={() => (
              <div>
                <BamFieldTitle title={tagDefinition.name} />
                <IncidentTagsUpdateMultiValue
                  cellValue={cellValue}
                  onValidationChange={onValidationChange}
                  error={error}
                  onValueChange={onValueChange}
                />
              </div>
            )}
          />
        );
      default:
        return null;
    }
  };

  return tagDefinition.canManualInput ? (
    <BamModal
      ref={(r) => {
        ref = r;
      }}
      trigger={
        <div>
          <Button
            icon={<EditIcon />}
            variant="action-default"
            tooltipProps={{
              isActive: true,
              text: "Edit tag's value",
              placement: 'bottom',
            }}
          />
        </div>
      }
      formMode
      position="bottom"
      title="Edit Incident Tag"
      titleIcon="bp-icon-edit"
    >
      <BamForm
        id="BamInputForm"
        initialValues={{ tagValue: cellValue }}
        onSubmit={handleSubmit}
        positiveButton={{ text: 'Update' }}
        closeButton={{ text: 'Cancel', onClick: handleClose }}
        closeModal={handleClose}
      >
        {getInputField()}
        <div className={styles.noteLine}>
          <WarningIcon size="xx-large" color={darkTheme.bp_gray_05} />
          <Text size="small" color={darkTheme.bp_gray_07}>
            Manual change stops the automatic updates
          </Text>
          <HBox marginStart="5px">
            <HelpTooltip text="By default, incident tags are automatically updated as new and updated alerts enter BigPanda. Manually changing tag data stops these automatic updates." />
          </HBox>
        </div>
      </BamForm>
    </BamModal>
  ) : (
    <Button
      variant="action-default"
      icon={<PermissionsIcon />}
      disabled
      size={AllSizes.LARGE}
      tooltipProps={{
        isActive: true,
        text: 'Read-only',
        placement: 'bottom',
      }}
    />
  );
};

IncidentTagsUpdateModal.propTypes = {
  tagDefinition: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    canManualInput: PropTypes.bool,
  }).isRequired,
  onValueChange: PropTypes.func,
  onSubmit: PropTypes.func,
  cellValue: PropTypes.string,
  onValidationChange: PropTypes.func,
  error: PropTypes.string,
};

export default IncidentTagsUpdateModal;
