import { react2angular } from 'react2angular';
import AutoShareRules from './AutoShareWrapper';
import AutoShareRuleDetailsPane from './auto_share_rule_info/components/AutoShareRuleDetailsPane';

angular
  .module('bigpanda')
  .component(
    'autoShareRuleDetailsPane',
    react2angular(AutoShareRuleDetailsPane, ['ruleId', 'store'])
  );
angular.module('bigpanda').component('autoShareRules', react2angular(AutoShareRules, ['store']));

export { name } from './constants';
export { default as reducer } from '../../../../../workspaces/apps/auto-share/src/store/reducer';
export { default as epic } from '../../../../../workspaces/apps/auto-share/src/store/epic';
