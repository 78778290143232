import { BamTile, TimeFormats } from '@bp/bam';
import { darkTheme, EditIcon } from '@bp/kung-fu';
import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';
import AlertEnrichmentActionButtons from '../../../../../../app/react/modules/settings/alert_enrichment/action_buttons/AlertEnrichmentActionButtons';
import AlertTagModal from '../../../../../../app/react/modules/settings/alert_enrichment/modals/AlertTagModal';
import selectors from '../../../../../../app/react/modules/settings/alert_enrichment/selectors';
import { Tag } from '../../../types';
import { TagActivationToggle } from '../Lobby/TagInfoTile/TagActivationToggle';
import TagInfoContent from './TagInfoContent';
import UserActivity from './UserActivity';

const { momentOrEpochRelativeTimeFormat } = TimeFormats;

interface IProps {
  tag: Tag;
}

function TagInfo({ tag }: IProps): JSX.Element {
  const { hasEditPermission } = useSelector(selectors.getActionsPermissions);

  return (
    <ThemeProvider theme={darkTheme}>
      <SettingsInfoScreen
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        topActions={<AlertEnrichmentActionButtons tag={tag} />}
        header={(
          <BamTile
            bamStatus={tag.active ? 'ok' : 'off'}
            headerRow={{
              title: tag.name,
              toggle: <TagActivationToggle active={tag.active} tagName={tag.name} />,
            }}
          />
        )}
        content={<TagInfoContent tag={tag} />}
        actions={
        hasEditPermission && (
          <SettingsInfoScreen.Section>
            <AlertTagModal
              triggerProps={{
                variant: 'action-color',
                size: 'large',
                text: 'Edit Alert Tag',
                icon: <EditIcon />,
              }}
              title="Edit Alert Tag"
              titleIcon="bp-icon-edit"
              tag={tag}
            />
          </SettingsInfoScreen.Section>
        )
      }
        activity={(
          <SettingsInfoScreen.Section>
            <UserActivity
              title="Created by:"
              username={tag.created_by}
              date={momentOrEpochRelativeTimeFormat(tag.created_at)}
            />
            {tag.updated_at && (
            <UserActivity
              title="Updated by:"
              username={tag.updated_by}
              date={momentOrEpochRelativeTimeFormat(tag.updated_at)}
            />
            )}
          </SettingsInfoScreen.Section>
      )}
      />
    </ThemeProvider>
  );
}

export default TagInfo;
