import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BamInput } from '@bp/bam';
import { ValidationError } from '../../../../../../common/components';
import './sso_form_input.scss';

const SSOFormInput = ({ input, meta, title }) => {
  const inputClassName = classnames({
    'SSOFormInput--error': !!meta.submitError,
  });
  const errorMessages = [meta.submitError];
  const displayError = (errorLine) =>
    errorLine ? (
      <ValidationError className="SSOFormValidation" key={errorLine} active="true">
        {errorLine}
      </ValidationError>
    ) : null;

  return (
    <div className="SSOFormInput">
      <BamInput
        baseProps={{ type: 'text', className: inputClassName }}
        input={input}
        title={title}
        aria-label={title}
        id={input.name}
      />
      {errorMessages.map(displayError)}
    </div>
  );
};

SSOFormInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default SSOFormInput;
