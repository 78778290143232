angular.module('bp.widgets').directive('bpBindHtmlCompile', bpBindHtmlCompile);

function bpBindHtmlCompile($compile) {
  return {
    restrict: 'A',
    scope: {
      bpBindHtmlCompile: '<',
      params: '<',
    },
    link: function (scope, element) {
      scope.$watch('bpBindHtmlCompile', (value) => {
        element.html(value);
        $compile(element.contents())(scope);
      });
    },
  };
}
