import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Spinner, Avatar, darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import provideStore from '../../../utils/provideStore';
import bpRoundLogo from './assets/bp-circle-logo.svg';
import { HorizontalRule, WelcomeForm } from './components';
import WelcomePanel from './components/WelcomePanel';
import styles from './welcome.scss';
import selectors from './selectors';
import {
  loadWelcomeUserData as actionLoadWelcomeUserData,
  updateUserNamePassword as actionUpdateUserNamePassword,
} from './actions';

const Welcome = (props) => {
  const { pubSubBroadcastUserNameChanged, success: redirectOnSuccess } = props;
  const [updatedUserName, setUpdatedUserName] = useState(null);
  const dispatch = useDispatch();
  const loadWelcomeUserData = () => dispatch(actionLoadWelcomeUserData());
  const updateUserNamePassword = (validatedValues) =>
    dispatch(actionUpdateUserNamePassword(validatedValues));

  useEffect(() => {
    loadWelcomeUserData();
  }, []);

  const { configurationSent, userEmail, userName, userOrganizationName } = useSelector((state) => ({
    configurationSent: selectors.configurationSent(state),
    userEmail: selectors.userEmail(state),
    userName: selectors.userName(state),
    userOrganizationName: selectors.userOrganizationName(state),
  }));

  useEffect(() => {
    if (configurationSent) {
      pubSubBroadcastUserNameChanged(updatedUserName);
      redirectOnSuccess();
    }
  }, [configurationSent]);

  const userIconName = userName || '';

  const userIconEmail = userEmail || '';

  const formSubmit = (validatedValues) => {
    setUpdatedUserName(validatedValues.name);
    updateUserNamePassword(validatedValues);
  };

  const SpinnerComp = () => (
    <div style={{ height: '70%' }}>
      <Spinner />
    </div>
  );

  const PanelContent = () => (
    <div>
      <div role="table">
        <div className={styles.emailRow} role="row">
          <span className={styles.emailLabel} role="cell">
            Email:
          </span>
          <span role="cell">{userEmail}</span>
        </div>
        <div className={styles.companyRow} role="row">
          <span className={styles.companyLabel} role="cell">
            Org.:
          </span>
          <span role="cell">{userOrganizationName}</span>
        </div>
      </div>
      <HorizontalRule />
      <WelcomeForm onSubmit={formSubmit} />
    </div>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <WelcomePanel className="bam">
        <div role="table">
          <div className={styles.logoRow} role="row">
            <img src={bpRoundLogo} alt="Big Panda Logo" className={styles.logoImage} />
          </div>
          <h1 className={styles['modal-header']}>Welcome to BigPanda</h1>
          <HorizontalRule className={styles.headerHR} />
          <div className={styles.gravatarRow} role="row">
            <div className={styles.gravatarLabel} role="cell">
              Avatar:
            </div>
            <Avatar email={userIconEmail} name={userIconName} size="small" />
            <a
              href="http://gravatar.com"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.gravatarLink}
            >
              Edit
            </a>
            <div className={styles.gravatarText}>(at gravatar.com)</div>
          </div>
        </div>
        {userOrganizationName && userEmail ? <PanelContent /> : <SpinnerComp />}
      </WelcomePanel>
    </ThemeProvider>
  );
};

Welcome.propTypes = {
  pubSubBroadcastUserNameChanged: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
};

export default provideStore(Welcome);
