import React from 'react';

const SortTooltip = () => (
  <div>
    <div>
      <b>Updated</b>
      <p>Most recently updated on top.</p>
    </div>
    <div>
      <b>Time window</b>
      <p>Shortest time window on top.</p>
    </div>
    <div>
      <b>Created</b>
      <p>Most recently created on top.</p>
    </div>
  </div>
);

export default SortTooltip;
