import { AllSizes, InfoIcon, Text } from '@bp/kung-fu';
import React from 'react';

import { text } from '../../../../../../constants';
import { InitStateWrapper } from './InitStateRow.style';

function InitStateRow(): JSX.Element {
  return (
    <InitStateWrapper>
      <InfoIcon size={AllSizes.XXLARGE} color={(p): string => p.theme.bp_gray_07} />
      <Text weight="700">{text.initStateExpanded.header}</Text>
      <Text>{text.initStateExpanded.content}</Text>
    </InitStateWrapper>
  );
}

export default InitStateRow;
