import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@bp/kung-fu';
import styles from '../../topbar.scss';

const OrgStatusBadge = ({ orgType, orgName, orgColor }) => (
  <div className={styles['multi-org-menu']}>
    <Badge text={orgType} size="medium" color={(p) => p.theme[orgColor]} title={orgName} />
  </div>
);

OrgStatusBadge.propTypes = {
  orgType: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  orgColor: PropTypes.string.isRequired,
};

export default OrgStatusBadge;
