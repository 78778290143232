import { Div, HelpTooltip } from '@bp/kung-fu';
import React from 'react';

import pluralize from '../../../../../../../app/common/utils/utils';
import {
  BulletContainer,
  Container,
  Counter,
  Subtitle,
  Title,
} from './RulesHeader.style';

interface IProps {
  numOfRules: number;
}

export function RulesHeader({ numOfRules }: IProps): JSX.Element {
  return (
    <Container>
      <Title>Automatic Enrichment</Title>
      <Counter>
        (
        {numOfRules}
        {' '}
        {pluralize(numOfRules, 'item')}
        )
      </Counter>
      <BulletContainer>•</BulletContainer>
      <Subtitle>
        First matching item will be applied
      </Subtitle>
      <Div display="inline-block" marginStart="5px">
        <HelpTooltip text="Automatic Enrichment applies the value of the first item which meets the conditions" />
      </Div>
    </Container>
  );
}
