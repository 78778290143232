import { UnifiedTag } from '../types';
import getTagsOfSourceSystem from './getTagsOfSourceSystem';

function tagsMatchingSourceSystem(integration: string, defaultValues: UnifiedTag[]): UnifiedTag[] {
  if (!integration) return [];
  if (integration === '*') return defaultValues;
  return getTagsOfSourceSystem(defaultValues, integration);
}

export default tagsMatchingSourceSystem;
