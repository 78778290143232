import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import reject from 'lodash/reject';
import map from 'lodash/map';

angular.module('bigpanda').service('UserIntegrationsStore', UserIntegrationsStore);

function UserIntegrationsStore(
  $q,
  UserIntegrationsBackendService,
  StreamConnector,
  pubSubService,
  SubscribersService
) {
  this.getIntegrations = getIntegrations;
  this.getIntegration = getIntegration;
  this.subscribe = subscribe;
  this.getImmediateIntegrations = getImmediateIntegrations;
  this.suggestNewIntegration = suggestNewIntegration;
  this.addErrorsToIntegrationCache = addErrorsToIntegrationCache;
  this.getUnsafeMutableIntegrations = getUnsafeMutableIntegrations;

  let integrations;
  const subscribers = new SubscribersService();

  function subscribe(scope, callbacks) {
    subscribers.subscribe(scope, callbacks);
  }

  StreamConnector.on('newIntegration', handleIntegrationSocketUpdates);
  pubSubService.on('streamConnector.reconnected', refreshIntegrations);

  function handleIntegrationSocketUpdates(rawIntegration) {
    addIntegrationToCache(rawIntegration);
    subscribers.fireStoreUpdated();
  }

  function refreshIntegrations() {
    getIntegrations(true).then(subscribers.fireStoreUpdated);
  }

  function addIntegrationToCache(rawIntegration) {
    const newIntegration = normalizeIntegration(rawIntegration);
    let exists = false;
    integrations = map(integrations, (integration) => {
      if (integration.system_id === newIntegration.system_id) {
        exists = true;
        return newIntegration;
      }
      return integration;
    });
    if (!exists) {
      integrations.push(newIntegration);
    }
    integrations.sort(sortByTime);
    return newIntegration;
  }

  function normalizeIntegration(rawIntegration) {
    const integration = angular.extend({}, rawIntegration);
    if (integration.target_system_id) {
      integration.type = 'target';
      integration.system_id = integration.target_system_id;
      integration.parent_source_system = integration.parent_system_id;
    } else {
      integration.system_id = integration.source_system;
    }
    integration.state =
      integration.type === 'target' || integration.last_event ? 'active' : 'pending';
    integration.createdTimestamp = moment(integration.created_at).unix();
    return integration;
  }

  function sortByTime(a, b) {
    if (b.createdTimestamp === a.createdTimestamp) {
      return b._id < a._id ? 1 : -1;
    }
    return a.createdTimestamp - b.createdTimestamp;
  }

  function getImmediateIntegrations() {
    return angular.copy(integrations);
  }

  function getUnsafeMutableIntegrations() {
    return integrations;
  }

  function getIntegrations(forceLoad) {
    if (integrations && !forceLoad) {
      return $q.when(getImmediateIntegrations());
    }
    return UserIntegrationsBackendService.getIntegrations().then((rawIntegrations) => {
      integrations = reject(rawIntegrations, (rawIntegration) => rawIntegration.ignore);
      integrations = map(integrations, (rawIntegration) => normalizeIntegration(rawIntegration));
      integrations.sort(sortByTime);
      return angular.copy(integrations);
    });
  }

  function getIntegration(systemId) {
    return getIntegrations().then((integrations) => {
      let integration = find(integrations, { system_id: systemId });
      if (integration) {
        return angular.extend({}, integration);
      }
      return UserIntegrationsBackendService.getIntegration(systemId).then((rawIntegration) => {
        if (rawIntegration) {
          integration = addIntegrationToCache(rawIntegration);
          return angular.extend({}, integration);
        }
        return null;
      });
    });
  }

  function suggestNewIntegration(integrationName) {
    return UserIntegrationsBackendService.suggestNewIntegration(integrationName);
  }

  function addErrorsToIntegrationCache(source_system, count) {
    const index = findIndex(integrations, { source_system });
    if (index > -1) {
      integrations[index].last_week_error_count = count;
    }
  }
}
