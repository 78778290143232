import {
  HBox, SCVBox, Text, VBox,
} from '@bp/kung-fu';
import styled from 'styled-components';

export const RowDetailsContextWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.bp_gray_02};
  margin-top: 25px;
`;

export const RowContextHeaderWrapper = styled(SCVBox)`
  flex-direction: row;
  gap: 4px;
  padding: 15px;
  border-bottom: 1px solid ${(p) => p.theme.bp_gray_01_5};
`;

export const UserNameText = styled(Text)`
  margin-left: 5px;
  text-transform: capitalize;
  font-weight: bold;
`;

export const RowContextDetailsWrapper = styled(HBox)`
  padding: 19px 15px 15px 15px;
`;

export const ContextDetailsColumn = styled(VBox)`
  gap: 19px;
`;

export const ContextDetailsValueColumn = styled(VBox)`
  margin-left: 50px;
  gap: 19px;
`;
