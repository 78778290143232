/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import * as colors from '@bp/pastel/colors';

export const Wrapper = styled.button`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  color: ${colors.bp_gray_08};
  border-width: 1px;
  border-color: ${({ error, isOpen }) =>
    error ? colors.bp_red : isOpen ? colors.bp_blue_light : colors.bp_gray_04};
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  justify-content: space-between;
  ${({ error }) =>
    !error &&
    `&:focus {
      border-color: ${colors.bp_blue_light} !important;
    }`}
  ${({ isFilterVariant }) =>
    isFilterVariant
      ? css`
    align-content: center;
    background: transparent;
    border-style: none;
    margin: 0 10px;
    padding: 5px 0;
    `
      : css`
    align-content: stretch;
    background: ${colors.bp_white};
    border-style: solid;
    height: 34px;
    margin: 0;
    padding: 9px 9px 9px 8px;
  `}
  &:hover {
    background: ${colors.bp_white};
    border-color: ${({ error, isOpen }) =>
      error ? colors.bp_red : isOpen ? colors.bp_blue_light : colors.bp_gray_05};
  }
`;

export const ArrowIcon = styled.i`
  color: ${colors.bp_gray_06};
  ${({ isFilterVariant }) =>
    isFilterVariant
      ? css`
    line-height: 0.8px;
  `
      : css`
    font-size: 16px;
    height: 16px;
    line-height: 16px;
  `};
`;

export const Label = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  flex-wrap: ${({ multiChoice }) => (multiChoice ? 'wrap' : 'nowrap')};
`;

export const LabelValue = styled.span`
  font-family: Helvetica;
  font-weight: ${({ isFilterVariant, isPlaceholder }) =>
    isFilterVariant && !isPlaceholder ? 'bold' : 'normal'};
  color: ${({ isFilterVariant, isPlaceholder }) => {
    if (isFilterVariant) {
      return isPlaceholder ? colors.bp_gray_06 : colors.bp_gray_08;
    }
    return isPlaceholder ? colors.bp_gray_04 : colors.bp_gray_08;
  }};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 3px;
  ${({ isFilterVariant }) =>
    isFilterVariant &&
    css`
    &:hover {
      color: dimgray;
    }
  `}
`;

export const LabelGroup = styled.span`
  font-family: Helvetica;
  margin: 0 4px;
  color: ${colors.bp_gray_05};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
