import { path } from './constants';
import { generateBaseSettingsActionPaths } from '../redux.utils';

const actionTypes = generateBaseSettingsActionPaths(path);

actionTypes.LOAD_CORRELATION_PATTERNS = `${path}.LOAD_CORRELATION_PATTERNS`;
actionTypes.LOAD_CORRELATION_PATTERNS_SUCCESS = `${path}.LOAD_CORRELATION_PATTERNS_SUCCESS`;
actionTypes.ADD_CORRELATION_PATTERN = `${path}.ADD_CORRELATION_PATTERN`;
actionTypes.UPDATE_CORRELATION_PATTERN = `${path}.UPDATE_CORRELATION_PATTERN`;
actionTypes.DELETE_CORRELATION_PATTERN = `${path}.DELETE_CORRELATION_PATTERN`;

export default actionTypes;
