d3.tip = require('d3-tip');

angular
  .module('bigpanda.reports')
  .factory('D3ComboBarchartFactory', [
    '$filter',
    '$window',
    'TimeGranularityConfig',
    'D3Utils',
    D3ComboBarchartFactory,
  ]);

function D3ComboBarchartFactory($filter, $window, TimeGranularityConfig, D3Utils) {
  return function () {
    return new D3ComboBarchart($filter, $window, TimeGranularityConfig, D3Utils);
  };
}

function D3ComboBarchart($filter, $window, TimeGranularityConfig, D3Utils) {
  var self = this;

  var linesAnimationDuration = 750,
    linesAnimationDelay = 100,
    circlesAnimationDelay = (linesAnimationDuration + linesAnimationDelay) * 0.8,
    circlesAnimationDuration = 250;

  self.margins = {
    desktop: {
      top: 50,
      bottom: 56,
      left: 70,
      right: 70,
    },
    mobile: {
      top: 50,
      bottom: 56,
      left: 65,
      right: 30,
    },
  };

  var mttrLineBottomPadding = 15;

  self.timestampScale = d3.scale.ordinal();
  self.durationScale = d3.time.scale.utc();
  self.incidentCountScale = d3.scale.linear();

  self.durationAxis = d3.svg
    .axis()
    .innerTickSize(0)
    .outerTickSize(0)
    .tickPadding(17)
    .orient('left');
  self.volumeAxis = d3.svg.axis().innerTickSize(0).outerTickSize(0).tickPadding(17).orient('right');
  self.timeAxis = d3.svg.axis().innerTickSize(0).outerTickSize(0).tickPadding(17).orient('bottom');

  self.incidentsDurationLine = d3.svg.line();
  self.zeroCountsLine = d3.svg.line();

  var commasFormatter = d3.format(',');

  self.pointTooltip = d3.tip().attr('class', 'd3-tip').attr('id', 'combo-barchart-tooltip');

  function durationFormatter(maxDuration) {
    var baseUnits;
    if (maxDuration < 60) {
      baseUnits = 'seconds';
    } else if (maxDuration < 60 * 60) {
      baseUnits = 'minutes';
    } else if (maxDuration < 60 * 60 * 24) {
      baseUnits = 'hours';
    } else {
      baseUnits = 'days';
    }

    return function (seconds) {
      var format = '';

      switch (baseUnits) {
        case 'seconds':
          format = 's[s]';
          break;
        case 'minutes':
          if (seconds % 60 === 0) {
            format = 'm[m]';
          } else {
            format = 'm[m] s[s]';
          }
          break;
        case 'hours':
          if (seconds % (60 * 60) === 0) {
            format = 'h[h]';
          } else {
            format = 'h[h] m[m]';
          }
          break;
        case 'days':
          if (seconds % (60 * 60 * 24) === 0) {
            format = 'd[d]';
          } else {
            format = 'd[d] h[h]';
          }
          break;
      }

      return moment.duration(seconds, 'seconds').format(format);
    };
  }

  self.render = function render(selection, layoutName) {
    selection.each(function (data) {
      // Scales update
      self.timestampScale.domain(data.map(self.timestampValue));

      self.timeAxis.scale(self.timestampScale);
      self.timeAxis.tickFormat((d) => $filter('granularityBasedFormat')(self.timeGranularity, d));

      var maxIncidentCount = d3.max(data.map(self.incidentCountValue)) || 1000,
        maxDuration = d3.max(data.map(self.durationValue)) || 60 * 60 * 2 * 1000; // 2 hours in milliseconds

      self.durationScale.domain([0, maxDuration * 1.2]);
      self.incidentCountScale.domain([0, maxIncidentCount * 1.1]);

      var tickFormatter = durationFormatter(maxDuration / 1000);
      self.durationAxis
        .scale(self.durationScale)
        .tickFormat((d) => tickFormatter(d.getTime() / 1000));

      self.volumeAxis
        .scale(self.incidentCountScale)
        .ticks(6)
        .tickFormat((d) => commasFormatter(d));

      var svg = d3.select(this).select('svg').selectAll('g.chart-container').data([data]);

      // Chart component creation
      var gEnter = svg.enter().append('g').attr('class', 'chart-container');

      var barsContainer = svg.selectAll('g.bars').data([data]);
      barsContainer.enter().append('g').attr('class', 'bars');

      var bars = barsContainer.selectAll('rect').data((d) => d);
      bars.enter().append('rect');

      var lineContainer = svg.selectAll('g.line').data([data]);
      lineContainer.enter().append('g').attr('class', 'line').append('path');

      gEnter.append('g').attr('class', 'point-highlighter').append('line').attr('opacity', 0);

      var circlesContainer = svg.selectAll('g.circles').data([data]);
      circlesContainer.enter().append('g').attr('class', 'circles');

      var circles = circlesContainer.selectAll('circle').data((d) => d);
      circles.enter().append('circle');

      gEnter.append('g').attr('class', 'time-axis axis');
      gEnter.append('g').attr('class', 'volume-axis axis');
      gEnter.append('g').attr('class', 'volume-axis-extension').append('line');
      gEnter.append('g').attr('class', 'duration-axis axis');
      gEnter.append('g').attr('class', 'duration-axis-extension').append('line');
      gEnter.append('g').attr('class', 'legend');

      var outlineCircles = gEnter.append('g').attr('class', 'tooltip-outline-circles');
      outlineCircles
        .append('circle')
        .attr('class', 'colored-outline-circle')
        .attr('r', 10)
        .attr('opacity', 0);
      outlineCircles
        .append('circle')
        .attr('class', 'white-outline-circle')
        .attr('r', 7)
        .attr('opacity', 0);

      gEnter.append('g').attr('class', 'legend');
      gEnter
        .select('.legend')
        .append('g')
        .attr('class', 'legend-bullets')
        .append('rect')
        .attr('class', 'mttr-bullet')
        .attr('y', -10)
        .attr('width', 10)
        .attr('height', 10);
      gEnter
        .select('.legend-bullets')
        .append('rect')
        .attr('class', 'volume-bullet')
        .attr('y', 7.3)
        .attr('width', 10)
        .attr('height', 10);
      gEnter
        .select('.legend')
        .append('g')
        .attr('class', 'legend-labels')
        .attr('transform', 'translate(17, 0)')
        .append('text')
        .text('MTTR');
      gEnter.select('.legend-labels').append('text').attr('y', 17).text('Incidents');

      svg.call(self.pointTooltip);

      // Data-dependant updates
      self.zeroCountsLine
        .x((d) => self.timestampScale(self.timestampValue(d)) + self.timestampScale.rangeBand() / 2)
        .y((d) => self.durationScale(0));

      self.incidentsDurationLine
        .x((d) => self.timestampScale(self.timestampValue(d)) + self.timestampScale.rangeBand() / 2)
        .y((d) => self.durationScale(self.durationValue(d)));

      d3.select(this).call(self.resize, layoutName);

      circles
        .attr('r', 0)
        .transition()
        .delay(circlesAnimationDelay)
        .duration(circlesAnimationDuration)
        .attr('r', 5);
      circles.exit().remove();

      lineContainer
        .select('path')
        .attr('d', self.zeroCountsLine)
        .transition()
        .delay(linesAnimationDelay)
        .duration(linesAnimationDuration)
        .attr('d', self.incidentsDurationLine);

      bars
        .attr('opacity', 0)
        .transition()
        .delay(linesAnimationDelay)
        .duration(linesAnimationDuration)
        .attr('opacity', 1);
    });
  };

  self.resize = function (selection, layoutName) {
    selection.each(function (data) {
      var dimensions = d3.select(this).node().getBoundingClientRect();
      var svgWidthWithMargins = dimensions.width,
        svgHeightWithMargins = dimensions.height;

      if (svgWidthWithMargins <= 0 || svgHeightWithMargins <= 0) {
        return;
      }

      return d3
        .select(this)
        .call(self[`${layoutName}Layout`], svgWidthWithMargins, svgHeightWithMargins);
    });
  };

  self.desktopLayout = function desktopLayout(selection, widthWithMargins, heightWithMargins) {
    selection.each(function (data) {
      var width = widthWithMargins - (self.margins.desktop.left + self.margins.desktop.right),
        height = heightWithMargins - (self.margins.desktop.top + self.margins.desktop.bottom);

      self.timestampScale.rangeRoundBands([0, width], 0.05, 0.05);
      self.incidentCountScale.range([height - mttrLineBottomPadding, 0]);
      self.durationScale.range([height - mttrLineBottomPadding, 0]);

      var svg = d3
        .select(this)
        .select('svg')
        .attr('width', width + (self.margins.desktop.left + self.margins.desktop.right))
        .attr('height', height + (self.margins.desktop.top + self.margins.desktop.bottom))
        .select('g.chart-container')
        .attr('transform', `translate(${self.margins.desktop.left},${self.margins.desktop.top})`);

      svg
        .select('.legend')
        .attr('opacity', 1)
        .attr('transform', `translate(${width - 60}, -30)`);

      self.timeAxis.tickValues(D3Utils.fitTickValues(self.timestampScale.domain(), 55, width));
      svg.select('.time-axis').attr('transform', `translate(0, ${height})`).call(self.timeAxis);

      svg
        .select('.volume-axis')
        .attr('transform', `translate(${width}, 0)`)
        .attr('opacity', 1)
        .call(self.volumeAxis);
      svg
        .select('.volume-axis-extension line')
        .attr('opacity', 1)
        .attr('x1', width)
        .attr('x2', width)
        .attr('y1', height - mttrLineBottomPadding)
        .attr('y2', height);

      self.durationAxis.ticks(6);
      svg.select('.duration-axis').call(self.durationAxis);
      svg
        .select('.duration-axis-extension line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', height - mttrLineBottomPadding)
        .attr('y2', height);

      svg.selectAll('g.line').select('path').attr('d', self.incidentsDurationLine);

      svg
        .selectAll('g.circles')
        .selectAll('circle')
        .attr(
          'cx',
          (d) => self.timestampScale(self.timestampValue(d)) + self.timestampScale.rangeBand() / 2
        )
        .attr('cy', (d) => self.durationScale(self.durationValue(d)))
        .on('mouseover', null)
        .on('mouseout', null)
        .on('mouseover', function (d) {
          self.pointTooltip
            .offset([-10, 0])
            .attr('class', 'd3-tip animate')
            .html((d) => {
              var mttrSpan = `<span class="mttr-tooltip">MTTR: ${$filter('reportsDuration')(
                self.durationValue(d) / 1000
              )}</span>`;
              var incidentCountSpan = `<span class="count-tooltip">${commasFormatter(
                self.incidentCountValue(d)
              )}${self.incidentCountValue(d) === 1 ? ' incident' : ' incidents'}</span>`;

              return mttrSpan + incidentCountSpan;
            })
            .show(d);

          svg
            .select('.tooltip-outline-circles .colored-outline-circle')
            .attr('style', `stroke: ${$window.getComputedStyle(d3.select(this).node()).fill}`);
          svg
            .selectAll('.tooltip-outline-circles circle')
            .attr('cx', d3.select(this).attr('cx'))
            .attr('cy', d3.select(this).attr('cy'))
            .transition()
            .duration(circlesAnimationDuration)
            .attr('opacity', 1);

          var x1, x2;
          x1 = x2 = parseFloat(d3.select(this).attr('cx'));
          var y1 = self.durationScale.range()[0] + mttrLineBottomPadding,
            y2 = self.durationScale.range()[1];

          svg
            .select('.point-highlighter line')
            .attr('x1', x1)
            .attr('x2', x2)
            .attr('y1', y1)
            .attr('y2', y2)
            .transition()
            .duration(circlesAnimationDuration)
            .attr('opacity', 1);
        })
        .on('mouseout', (d) => {
          self.pointTooltip.attr('class', 'd3-tip').hide();

          svg
            .selectAll('.tooltip-outline-circles circle')
            .transition()
            .duration(circlesAnimationDuration)
            .attr('opacity', 0);

          svg
            .select('.point-highlighter line')
            .transition()
            .duration(circlesAnimationDuration)
            .attr('opacity', 0);
        });

      svg
        .selectAll('g.bars')
        .selectAll('rect')
        .attr('opacity', 1)
        .attr('x', (d) => self.timestampScale(self.timestampValue(d)))
        .attr('y', (d) => self.incidentCountScale(self.incidentCountValue(d)))
        .attr('width', self.timestampScale.rangeBand)
        .attr(
          'height',
          (d) =>
            height - mttrLineBottomPadding - self.incidentCountScale(self.incidentCountValue(d))
        );
    });
  };

  self.mobileLayout = function mobileLayout(selection, widthWithMargins, heightWithMargins) {
    selection.each(function (data) {
      var width = widthWithMargins - (self.margins.mobile.left + self.margins.mobile.right),
        height = heightWithMargins - (self.margins.mobile.top + self.margins.mobile.bottom);

      self.timestampScale.rangeRoundBands([0, width], 0.05, 0.05);
      self.incidentCountScale.range([height, 0]);
      self.durationScale.range([height - mttrLineBottomPadding, 0]);

      var svg = d3
        .select(this)
        .select('svg')
        .attr('width', width + (self.margins.mobile.left + self.margins.mobile.right))
        .attr('height', height + (self.margins.mobile.top + self.margins.mobile.bottom))
        .select('g.chart-container')
        .attr('transform', `translate(${self.margins.mobile.left},${self.margins.mobile.top})`);

      svg.select('.legend').attr('opacity', 0);

      self.timeAxis.tickValues(D3Utils.fitTickValues(self.timestampScale.domain(), 55, width));
      svg.select('.time-axis').attr('transform', `translate(0, ${height})`).call(self.timeAxis);

      svg.selectAll('.volume-axis').attr('opacity', 0);
      svg.selectAll('.volume-axis-extension line').attr('opacity', 0);

      self.durationAxis.ticks(4);
      svg.select('.duration-axis').call(self.durationAxis);
      svg
        .select('.duration-axis-extension line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', height - mttrLineBottomPadding)
        .attr('y2', height);

      svg.selectAll('g.line').select('path').attr('d', self.incidentsDurationLine);

      svg
        .selectAll('g.circles')
        .selectAll('circle')
        .attr(
          'cx',
          (d) => self.timestampScale(self.timestampValue(d)) + self.timestampScale.rangeBand() / 2
        )
        .attr('cy', (d) => self.durationScale(self.durationValue(d)))
        .on('mouseover', null)
        .on('mouseout', null);

      svg.selectAll('g.bars').selectAll('rect').attr('opacity', 0);
    });
  };

  function accessor(name, context) {
    return function (_) {
      if (!arguments.length) {
        return context[name];
      }

      context[name] = _;
      return context;
    };
  }

  self.timestamp = accessor('timestampValue', self);
  self.duration = accessor('durationValue', self);
  self.incidentCount = accessor('incidentCountValue', self);
  self.setTimeGranularity = accessor('timeGranularity', self);
}
