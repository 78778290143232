import styled, { keyframes } from 'styled-components';
import { HBox } from '@bp/kung-fu';

const loading = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-10px);
  }
`;

const DotFlashingAnimation = keyframes`
    0% {
      background-color: ${(p) => p.theme.bp_black};
    }
    50%, 100% {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;

const DotSize = '5px';
const DotWidth = '5px';

export const BouncingWrapper = styled(HBox)`
  margin-top: 10px;
  margin-inline-start: 10px;
`;

export const DotsWrapper = styled(HBox)`
  margin-inline-start: 10px;
`;

export const BouncingLoading = styled(HBox)`
  width: ${DotWidth};
  height: ${DotSize};;
  margin: 1px 1px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.bp_black};
  opacity: 1;
  animation: ${loading} 0.25s infinite alternate;
  animation-delay: ${(p) => (p.delay ? p.delay : '0s')};
`;

export const DotFlashing = styled(HBox)`
    position: relative;
    width: ${DotWidth};
    height: ${DotSize};
    border-radius: 3px;
    background-color: ${(p) => p.theme.bp_black};
    color: ${(p) => p.theme.bp_black};
    animation: ${DotFlashingAnimation} 0.5s infinite linear alternate;
    animation-delay: 0.2s;
  
  

  &::before {
    left: -8.5px;
    border-radius: 3px;
    width: ${DotWidth};
    height: ${DotSize};
    background-color: ${(p) => p.theme.bp_black};
    color: ${(p) => p.theme.bp_black};
    animation: ${DotFlashingAnimation} 0.5s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 8.5px;
    width: ${DotWidth};
    height: ${DotSize};
    border-radius: 3px;
    background-color: ${(p) => p.theme.bp_black};
    color: ${(p) => p.theme.bp_black};
    animation: ${DotFlashingAnimation}  0.5s infinite alternate;
    animation-delay: 0.25s;
  }

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
`;
