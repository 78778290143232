import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import autobind from 'class-autobind';
import get from 'lodash/get';
import { BamModal } from '@bp/bam';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import actions from './../../../maintenance_plans_list/actions';
import selectors from '../../../maintenance_plans_list/selectors';
import MaintenanceForm from '../form/MaintenanceForm';

class MaintenanceModal extends React.Component {
  constructor(props) {
    super(props);

    autobind(this, MaintenanceModal.prototype);
  }

  handleClose = () => {
    this.ref.close();
  };

  render() {
    const { plans, initialValues, triggerProps, featureToggles } = this.props;
    const disableQueryHelper = get(featureToggles, 'disable_query_helper', false);

    const onMountModal = () => {
      if (!plans) {
        this.props.loadPlans();
      }
    };
    let positiveButtonAction;
    if (this.props.stopPlan) {
      positiveButtonAction = '\vStop\v';
    } else if (this.props.editTimeWindow) {
      positiveButtonAction = 'Update Time Window';
    } else if (this.props.duplicate) {
      positiveButtonAction = 'Duplicate Plan';
    } else if (initialValues && initialValues.id) {
      positiveButtonAction = 'Update Plan';
    } else {
      positiveButtonAction = 'Create Plan';
    }

    return (
      <BamModal
        ref={(r) => {
          this.ref = r;
        }}
        style={{ overflow: !disableQueryHelper ? 'visible' : 'auto' }}
        trigger={<ModalTriggerButton {...triggerProps} />}
        titleIcon={this.props.titleIcon}
        title={this.props.title}
        onMount={onMountModal}
        size={this.props.stopPlan ? 'mini' : 'small'}
        formMode
        showDonePlans
        autoFocus
      >
        <MaintenanceForm
          {...this.props}
          positiveButtonText={positiveButtonAction}
          close={this.handleClose}
        />
      </BamModal>
    );
  }
}

MaintenanceModal.propTypes = {
  createOrUpdatePlan: PropTypes.func.isRequired,
  duplicate: PropTypes.bool,
  plans: PropTypes.arrayOf(PropTypes.object),
  loadPlans: PropTypes.func.isRequired,
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string,
  titleIcon: PropTypes.string.isRequired,
  editTimeWindow: PropTypes.bool,
  stopPlan: PropTypes.bool,
  scrollable: PropTypes.bool,
  showDonePlans: PropTypes.bool.isRequired,
  featureToggles: PropTypes.shape({
    disable_query_helper: PropTypes.bool,
  }).isRequired,
};

MaintenanceModal.defaultProps = {
  duplicate: false,
  plans: undefined,
  initialValues: undefined,
  title: '',
  editTimeWindow: undefined,
  stopPlan: undefined,
  scrollable: true,
};

function mapStateToProps(state) {
  return {
    plans: selectors.getPlans(state),
    showDonePlans: selectors.showDonePlans(state),
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadPlans: () => dispatch(actions.loadPlans()),
    createOrUpdatePlan: (payload) => dispatch(actions.createOrUpdatePlan(payload)),
    stopPlanNow: (payload) => dispatch(actions.stopPlan(payload)),
    updatePlanEnd: (payload) => dispatch(actions.updatePlanEnd(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(MaintenanceModal));
