import filter from 'lodash/filter';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';

module.exports = angular
  .module('bigpanda.frontend.services', [])
  .factory('EntitiesService', () => {
    function filterActiveEntities(entities) {
      return filter(entities, (e) => e.status !== 'ok' && !e.underMaintenance);
    }
    function filterResolvedEntities(entities) {
      return filter(entities, { status: 'ok' });
    }
    function filterMaintenanceEntities(entities) {
      return filter(entities, { underMaintenance: true });
    }

    const folderTypes = {
      active: { name: 'active', filter: filterActiveEntities, active: true },
      unhandled: { name: 'unhandled', filter: filterActiveEntities, active: true },
      shared: { name: 'shared', filter: filterActiveEntities, active: true },
      snoozed: { name: 'snoozed', filter: filterActiveEntities, active: true },
      maintenance: { name: 'maintenance', filter: filterMaintenanceEntities },
      resolved: { name: 'resolved', filter: filterResolvedEntities },
    };
    const folderTypesArr = Object.values(folderTypes);

    const activeFolderTypes = filter(folderTypes, (e) => e.active);

    const isActiveFolder = (folderName) =>
      filter(activeFolderTypes, (e) => e.name === folderName).length;

    function calcIncidentFolder(incident, stateFolder) {
      const folderChanges = incident.folder_changes;
      if (folderChanges && folderChanges.in && folderChanges.in.length) {
        return find(folderTypesArr, (folderByOrder) =>
          folderChanges.in.includes(folderByOrder.name)
        ).name;
      }
      if (folderChanges && folderChanges.added && folderChanges.added[0] === 'maintenance') {
        return 'maintenance';
      }
      if (
        (folderChanges && folderChanges.added && folderChanges.added[0] === 'resolved') ||
        !incident.is_active
      ) {
        return 'resolved';
      }
      return stateFolder;
    }

    function calcIncidentFolderAndType(incident, stateFolder) {
      const currentFolder = calcIncidentFolder(incident, stateFolder);
      const incidentType = capitalize(
        isActiveFolder(currentFolder) ? folderTypes.active.name : currentFolder
      );
      return { currentFolder, incidentType };
    }

    const filterEntitiesByFolder = (entities, folder) =>
      folderTypes[folder].filter(entities).length;

    return {
      filterActiveEntities: filterActiveEntities,
      filterResolvedEntities: filterResolvedEntities,
      filterMaintenanceEntities: filterMaintenanceEntities,
      calcIncidentFolderAndType: calcIncidentFolderAndType,
      folderTypes: folderTypes,
      activeFolderTypes: activeFolderTypes,
      filterEntitiesByFolder: filterEntitiesByFolder,
    };
  })
  .factory('TabsService', () => {
    const tabsList = {
      alerts: {
        name: 'Alerts',
        id: 'alerts',
      },
      topology: {
        name: 'Topology',
        id: 'topology',
      },
      changes: {
        name: 'Changes',
        id: 'changes',
      },
      activity: {
        name: 'Activity',
        id: 'activity',
      },
      overview: {
        name: 'Overview',
        id: 'overview',
      },
    };

    return {
      tabsList: tabsList,
    };
  })
  .factory('ActivitiesService', () => {
    const filterStatusChanges = ({ event_type: eventType }) =>
      [
        'incident#reopen',
        'incident#become-flapping',
        'incident#closed',
        'incident#stopped-flapping',
        'incident#entities-auto-resolve',
      ].indexOf(eventType) === -1;

    return {
      filterStatusChanges: filterStatusChanges,
    };
  });
