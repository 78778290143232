import get from 'lodash/get';
angular
  .module('bigpanda.reports')
  .controller(
    'ReportsController',
    (
      $scope,
      pubSubService,
      Permissions,
      UserFeatureTogglesService,
      PersonalSettingsStore,
      PERMISSIONS_NAMES
    ) => {
      $scope.updatePage('reports');
      if (Permissions.isPermitted(PERMISSIONS_NAMES.analytics.read)) {
        if (UserFeatureTogglesService.getToggle('periscope_tab')) {
          $scope.showPeriscopeTab = true;
          PersonalSettingsStore.getUser().then((user) => {
            $scope.periscopeUrl = get(user, 'organization.config.periscope_url');
          });
        } else {
          $scope.hasPermissions = Permissions.checkBasicPermissions();
        }
      }
      pubSubService.broadcast('currentTitleChanged', 'Analytics');
    }
  );
