module.exports = angular.module('bigpanda.TypesFaqConfigProvider', []).constant('TypesFaqConfig', {
  typesFaq: [
    {
      faq_type: 'agent',
      faqs: [
        {
          question: 'Is the BigPanda agent secure?',
          answer:
            'Yes, we’ve designed the agent to be secure and non-intrusive. The installer uses signed ' +
            'deb/rpm packages. The agent itself runs as a custom non-root user and it communicates with BigPanda via SSL.',
        },
        {
          question: 'How do I uninstall the agent?',
          answer:
            'RHEL / CentOS Distributions:<br>yum remove bigpanda-agent<br><br>Ubuntu / Debian Distributions:' +
            '<br>apt-get remove bigpanda-agent',
        },
        {
          question: "Where can I find the agent's log files?",
          answer:
            'The log files are located at /var/log/bigpanda/ <br><br>BigPanda log files are rotated when they ' +
            'reach 10MB, and only 3 versions are stored at any given time.',
        },
        {
          question: 'How do I install multiple BigPanda agents?',
          answer:
            'You only need one agent per Nagios / Zabbix / etc master. If you have multiple masters, you need ' +
            'to create a separate integration (use different app keys) for each master.',
        },
        {
          question: 'What happens if my internet connection is down?',
          answer:
            'BigPanda will not miss alerts, even if your connection is down. The BigPanda agent intelligently ' +
            'retries sending your alerts, until it’s successful. Once the connection is revived, you will see the events in BigPanda.',
        },
        {
          question: 'Can I run the agent behind a proxy server?',
          answer:
            "Yes. Simply follow <a href='https://docs.bigpanda.io/docs/big-panda-agent#section-bigpanda-agent-behind-a-proxy-server' target='_blank'>" +
            'this tutorial</a> to configure the agent to work behind a proxy.',
        },
        {
          question: 'How do I add the EPEL repo for CentOS5?',
          answer:
            'In shell, run: <br><br>$ wget http://dl.fedoraproject.org/pub/epel/5/x86_64/epel-release-5-4.noarch.rpm<br>' +
            '$ sudo rpm -Uvh epel-release*.rpm',
        },
      ],
    },
  ],
});
