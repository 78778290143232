import get from 'lodash/get';
import { path, NAME_SORT_MODE, DEFAULT_SORT_MODE } from './constants';
import { generateBaseSelectors } from '../../redux.utils';

const selectors = generateBaseSelectors(path);

const getFilteredPlans = (plans, filterText) => {
  const lowerCaseTextFilter = filterText.toLowerCase();
  return plans.filter((plan) => plan.lowerCasedName.includes(lowerCaseTextFilter));
};

selectors.getPlans = (state) => {
  const { plans, filterText } = get(state, path);
  if (!plans) {
    return undefined;
  }

  const filteredPlans = filterText ? getFilteredPlans(plans, filterText) : plans;
  const { field, ascending } = selectors.getSortMode(state);
  const { field: defaultField, ascending: defaultOrder } = NAME_SORT_MODE;
  return filteredPlans;
};

selectors.getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;
selectors.getError = (state) => get(state, path).error;
selectors.getLink = (state) => get(state, path).link;
selectors.isPaginating = (state) => get(state, path).isPaginating;
selectors.showDonePlans = (state) => get(state, path).showDonePlans;

export default selectors;
