import uniq from 'lodash/uniq';
import map from 'lodash/map';
import filter from 'lodash/filter';
angular.module('bigpanda').service('CorrelationCompletionLogic', CorrelationCompletionLogic);

function CorrelationCompletionLogic(TagsService, BPQLCompletionLogicService) {
  const BPQL_TOOLTIP_TEMPLATE = 'shared_ui/bp_bpql_tooltip/bp_bpql_tooltip';

  this.getLogic = getLogic;
  this.autocompleateTagsFieldChange = autocompleateTagsFieldChange;
  this.autocompleateSourceFieldChange = autocompleateSourceFieldChange;

  function getLogic(fieldChangeCallback) {
    return {
      submit: () => {},
      refresh: (text, event) => {
        const completion = BPQLCompletionLogicService.validCompletions(text);
        if (fieldChangeCallback && event.type !== 'focus') {
          fieldChangeCallback(text);
        }
        return autocompleateTagsFieldChange(completion.tag);
      },
      complete: (string, completion) => {
        const completions = BPQLCompletionLogicService.validCompletions(string);
        return string.substring(0, string.length - (completions.tag || '').length) + completion;
      },
      tooltipTemplate: BPQL_TOOLTIP_TEMPLATE,
    };
  }

  function autocompleateTagsFieldChange(text) {
    return TagsService.getTags().then((rawTags) => {
      const filteredTags = filter(rawTags, (tag) => match(tag.name, text));
      return map(filteredTags, (tag) => ({
        label: tag.name,
        description: uniq(tag.sourceSystems.map((s) => s.parentDisplayName)).join(', '),
        prefix: text,
      }));
    });
  }

  function autocompleateSourceFieldChange(text, integrations) {
    const filteredSources = filter(integrations, (int) => match(int.display, text));
    return map(filteredSources, (source) => ({
      display: source.display,
      value: source.value,
      prefix: text,
    }));
  }

  function match(name, text) {
    return typeof text === 'string' && name.toLowerCase().indexOf(text.toLowerCase()) > -1;
  }
}
