import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserLoginOrganizations } from 'common/endpoints/users';
import get from 'lodash/get';

const QUERY_KEYS = {
  USER_ORGANIZATIONS: 'userOrganizations',
};
const useGetUserOrganizations = () => {
  const queryClient = useQueryClient();
  const organizations = queryClient.getQueryData([QUERY_KEYS.USER_ORGANIZATIONS]);
  const shouldShowLoader = organizations === undefined;

  const { data = [], isLoading, isError, isFetching } = useQuery(
    [QUERY_KEYS.USER_ORGANIZATIONS],
    () => getUserLoginOrganizations(),
    { refetchOnWindowFocus: false, staleTime: Infinity }
  );
  if (shouldShowLoader && (isLoading || isFetching || isError)) {
    return {
      organizations: [],
      isLoading,
      isError,
      isFetching,
    };
  }
  const responseData = data.data;
  return {
    isMultiOrg: responseData.items && responseData.items.length > 1,
    isLoading: shouldShowLoader && isLoading,
    isFetching: shouldShowLoader && isFetching,
    isError,
    organizations: responseData ? responseData.items : [],
    ssoSourceOrganizationId: get(responseData.items[0], 'ssoSourceOrganizationId', null),
  };
};

/* eslint import/prefer-default-export: 0 */
export { useGetUserOrganizations };
