import { combineReducers } from 'redux';
import * as layout from './layout';
import * as modules from './modules';
import * as common from './common';
import * as user from './user';

export default combineReducers({
  [layout.name]: layout.reducer,
  [modules.name]: modules.reducer,
  [common.name]: common.reducer,
  [user.name]: user.reducer,
});
