import filter from 'lodash/filter';
angular.module('bigpanda').filter('incidentNormalizer', incidentNormalizer);

function incidentNormalizer($filter) {
  const formatter = $filter('formatDateYearFilter');
  const sinceFormatter = $filter('since');

  return (rawIncident) => {
    rawIncident.lastChanged = formatter(rawIncident.last_change);
    rawIncident._v = rawIncident.__v; // Use the moongose version
    rawIncident.consolidated =
      rawIncident.entities && filter(rawIncident.entities, { is_active: true }).length > 1;
    rawIncident.lastChangeSince = sinceFormatter(rawIncident.last_change);
    rawIncident.status = rawIncident.status.toLowerCase();
    rawIncident.acknowledged =
      (rawIncident.zeroImpact && !rawIncident.is_active) || rawIncident.status === 'acknowledged';
    rawIncident.maintenance =
      (rawIncident.zeroImpact && !rawIncident.is_active) || rawIncident.status === 'maintenance';

    return rawIncident;
  };
}
