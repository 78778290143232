angular.module('bigpanda.backend.services').service('ResolveBackendService', ResolveBackendService);

function ResolveBackendService($http) {
  function resolve(ids, annotation, environment) {
    return $http
      .post(`/web/environments/${environment._id}/incidents/resolve`, {
        ids: ids,
        annotation: annotation && annotation.annotation,
        environment: environment.name,
      })
      .then((res) => res.data);
  }

  this.resolve = resolve;
}
