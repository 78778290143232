import React, { useEffect, useState } from 'react';
import { withReactQueryClient } from '@bp/data-access';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import { onboarding } from '@bp/onboarding';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { hot } from 'react-hot-loader';
import classnames from 'classnames';
import get from 'lodash/get';
import provideStore from '../../utils/provideStore';
import { NavigationBar, SettingsMenu, UserMenu, OrganizationMenu } from './components';
import styles from './topbar.scss';
import selectors from './selectors';
import { loadUserData } from '../../user/actions';
import { setIntercomSettings } from './menuIntercom';
import { selectors as permissionsSelectors } from '../../user/permissions';
import { useGetUserOrganizations } from './components/organizations_menu/useGetUserOrganizations';

const isUserAdmin = (roles) => {
  return roles && roles.some((role) => role.key === 'admin' || role.key === 'superadmin');
};

const TopBar = ({
  organization,
  loadUserData,
  currentRouteName,
  navigateToFeed,
  organizationType: { orgType, orgClass, orgColor },
  toggleSideNav,
  toggleSearch,
  featureToggles,
  selectedSettingsPageId,
}) => {
  const isOnboardingEnable = featureToggles.is_onboarding_enable;
  const roles = useSelector(permissionsSelectors.getRoles);
  if (!organization) {
    loadUserData();
  }
  const isMobileScreen = () => window.screen.width < 1010 || window.innerWidth < 1010;
  const { isMultiOrg, ssoSourceOrganizationId } = useGetUserOrganizations();

  useEffect(() => {
    if (!window.location.hash.includes('/app/topology')) {
      setIntercomSettings(window.location.pathname);
    }
  }, []);
  const organizationName = get(organization, 'name', '');
  const organizationId = get(organization, '_id', '');
  const displayName = get(organization, 'display_name', '') || organizationName;

  const isSsoSourceOrganization = ssoSourceOrganizationId
    ? ssoSourceOrganizationId === organizationId
    : true;

  const topBarClass = classnames(styles.topbar, {
    [styles['with-org']]: !!orgType,
  });

  const orgStatusBarClass = classnames(styles['org-status-bar'], orgClass);

  const userSettingsClass = classnames(styles['user-and-settings'], {
    [styles['user-menus-with-multi-org']]: !!isMultiOrg,
    [styles['user-menus-with-org']]: !!orgType,
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={topBarClass}>
        {orgType && <div className={orgStatusBarClass} />}
        <div className={styles['menu-block']}>
          <NavigationBar
            currentRouteName={currentRouteName}
            toggleSideNav={toggleSideNav}
            toggleSearch={toggleSearch}
            navigateToFeed={navigateToFeed}
          />
          {isOnboardingEnable && isUserAdmin(roles) && !isMobileScreen() && (
            <onboarding.Onboarding hasOrgType={orgType} />
          )}
          <div className={userSettingsClass}>
            {organizationName && (
              <OrganizationMenu
                orgName={organizationName}
                orgColor={orgColor}
                orgType={orgType}
                displayName={displayName}
              />
            )}
            <SettingsMenu
              className={styles['settings-menu']}
              selectedSettingsPageId={selectedSettingsPageId}
              isSsoSourceOrganization={isSsoSourceOrganization}
            />
            <UserMenu className={styles['user-menu']} />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

TopBar.propTypes = {
  currentRouteName: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string,
  }),
  organizationType: PropTypes.shape({
    orgType: PropTypes.string,
    orgClass: PropTypes.string,
    orgColor: PropTypes.string,
  }),
  loadUserData: PropTypes.func.isRequired,
  navigateToFeed: PropTypes.func.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  selectedSettingsPageId: PropTypes.string.isRequired,
  featureToggles: PropTypes.shape({
    is_onboarding_enable: PropTypes.bool,
  }),
};

const mapStateToProps = (state) => ({
  featureToggles: featureTogglesSelectors.getFeatureToggles(state) || {},
  organization: selectors.getOrganization(state),
  organizationType: selectors.getOrganizationType(state),
});
const mapDispatchToProps = {
  loadUserData,
};

export default withReactQueryClient(
  provideStore(connect(mapStateToProps, mapDispatchToProps)(hot(module)(TopBar)))
);
