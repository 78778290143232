import DisplayCommon from '@bp/display-common';

export function findSystemName(integrations, sourceSystem, incident?) {
  const serverIntegration = getImmediateSpecificIntegration(integrations, sourceSystem);
  return DisplayCommon.findSystemName(sourceSystem, incident, serverIntegration);
}

export function isCrossSource(integrations, entities) {
  const breakdown = getSourceBreakdownData(integrations, entities);
  return breakdown.systems.length > 1;
}

export function getSourceBreakdownData(integrations, entities, activeOnly?) {
  const breakdown = getBreakdown(entities, activeOnly);
  return {
    systems: getSystems(integrations, breakdown),
    parentSystems: breakdown.map(({ parent }) => {
      if (parent.split('.')[0] === 'api') {
        return findSystemName(integrations, parent);
      }
      return parent;
    }),
  };
}

function getImmediateSpecificIntegration(integrations, sourceSystem) {
  return (integrations || []).find(({ system_id: systemId }) => systemId === sourceSystem);
}

function getBreakdown(entities, activeOnly = false) {
  return DisplayCommon.findSourceSystems({ entities }, activeOnly);
}

function getSystems(integrations, sourceBreakdown) {
  return Array.from(
    new Set([].concat(...sourceBreakdown.map(({ sources }) => sources))),
  ).map((source) => findSystemName(integrations, source));
}
