import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react/common/components/Select/Select';
import { variants } from 'react/common/components/Select/constants';
import { ANY_LABEL_FILTER_ITEM, INPUT_PLACEHOLDER } from './constants';
import { LabelPropType } from '../constants';
import { convertLabelsToFilterItems } from './utils';
import Wrapper from './ConfigurationLabelsFilter.css';

const getLabelDisplayValue = (label, { isToggleItem = false } = {}) =>
  get(label, 'value') === ANY_LABEL_FILTER_ITEM.value && isToggleItem
    ? INPUT_PLACEHOLDER
    : get(label, 'display');

const ConfigurationLabelsFilter = ({ allLabels, selectedLabel, onSelectedLabelChanged }) => {
  const sortedGroupedLabels = [ANY_LABEL_FILTER_ITEM, ...convertLabelsToFilterItems(allLabels)];
  return (
    <Wrapper data-api-id="configuration-labels-filter">
      <Select
        disabled={isEmpty(allLabels)}
        error={false}
        getItemDisplayValue={getLabelDisplayValue}
        items={sortedGroupedLabels}
        onChange={onSelectedLabelChanged}
        placeholder={INPUT_PLACEHOLDER}
        value={selectedLabel}
        variant={variants.filter}
      />
    </Wrapper>
  );
};

ConfigurationLabelsFilter.propTypes = {
  allLabels: PropTypes.arrayOf(LabelPropType),
  onSelectedLabelChanged: PropTypes.func,
  selectedLabel: PropTypes.string,
};

ConfigurationLabelsFilter.defaultProps = {
  allLabels: [],
  onSelectedLabelChanged: () => undefined,
  selectedLabel: undefined,
};

export default ConfigurationLabelsFilter;
