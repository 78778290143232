import { CVBox, SCHBox, Text } from '@bp/kung-fu';
import React from 'react';
import styled from 'styled-components';

import SuggestionMetricsProps from './SuggestionMetricsProps';

const Container = styled(SCHBox)`
  border: 1px solid ${(p): string => p.theme.bp_gray_03};
  background-color: ${(p): string => p.theme.bp_gray_01};
  margin-inline-start: 25px;
  margin-top: 25px;
  margin-bottom: 29px;
  border-radius: 4px;
`;

const CoverageContainer = styled(CVBox)`
  height: 94px;
  width: 146px;
`;

const CoverageValueText = styled(Text)`
  font-size: 24px;
  color: ${(p): string => p.theme.bp_blue_dark};
  font-weight: bold;
`;

const CoverageDescriptionText = styled(Text).attrs({
  size: 'small',
})`
  color: ${(p): string => p.theme.bp_gray_07};
  width: 120px;
  text-align: center;
`;

const Separator = styled.div`
  border-left: 2px solid ${(p): string => p.theme.bp_gray_02};
  height: 80%;
`;

const ReasonContainer = styled(Text)`
  padding-left: 14px;
  padding-right: 57px;
`;

const ReasonText = styled(Text)`
  color: ${(p): string => p.theme.bp_gray_07};
  text-align: center;
`;

function SuggestionMetrics({
  coverage,
  reason,
}: SuggestionMetricsProps): JSX.Element {
  return (
    <Container>
      <CoverageContainer>
        <CoverageValueText>
          {`${Math.ceil(coverage * 100)}%`}
        </CoverageValueText>
        <CoverageDescriptionText>
          Expected Incident Coverage
        </CoverageDescriptionText>
      </CoverageContainer>
      <Separator />
      <ReasonContainer>
        <ReasonText>
          {reason}
        </ReasonText>
      </ReasonContainer>
    </Container>
  );
}

export default SuggestionMetrics;
