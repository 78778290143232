angular.module('bigpanda').directive('checkedIncidentsState', checkedIncidentsState);

function checkedIncidentsState() {
  return {
    controller: controller,
  };

  function controller(SubscribersService, pubSubService) {
    const vm = this;
    let checkedIncidentIds = new Set();
    let lastCheckedIds = [];
    const subscribers = new SubscribersService();

    vm.subscribe = subscribe;
    vm.getCheckedIncidentIds = getCheckedIncidentIds;
    vm.check = check;
    vm.checkMulti = checkMulti;
    vm.uncheck = uncheck;
    vm.uncheckMulti = uncheckMulti;
    vm.clear = clear;
    vm.isChecked = isChecked;
    vm.getLastCheckedId = getLastCheckedId;
    vm.getSecondLastCheckedId = getSecondLastCheckedId;
    vm.isAnythingChecked = isAnythingChecked;

    function subscribe(scope, subscriber) {
      subscribers.subscribe(scope, subscriber);
    }

    function getCheckedIncidentIds() {
      return Array.from(checkedIncidentIds);
    }

    function isAnythingChecked() {
      return getCheckedIncidentIds().length > 0;
    }

    function check(incidentId) {
      if (!checkedIncidentIds.has(incidentId)) {
        checkedIncidentIds.add(incidentId);
        pushLastChecked(incidentId);
        fireStoreUpdated();
      }
    }

    function uncheck(incidentId) {
      if (checkedIncidentIds.has(incidentId)) {
        checkedIncidentIds.delete(incidentId);
        pushLastChecked(incidentId);
        fireStoreUpdated();
      }
    }

    function checkMulti(incidentIds) {
      let changed = false;
      incidentIds.forEach((incidentId) => {
        changed = changed || !checkedIncidentIds.has(incidentId);
        checkedIncidentIds.add(incidentId);
      });
      if (changed) {
        fireStoreUpdated();
      }
    }

    function uncheckMulti(incidentIds) {
      let changed = false;
      incidentIds.forEach((incidentId) => {
        changed = changed || checkedIncidentIds.has(incidentId);
        checkedIncidentIds.delete(incidentId);
      });
      if (changed) {
        fireStoreUpdated();
      }
    }

    function clear() {
      uncheckMulti(getCheckedIncidentIds());
    }

    function isChecked(incidentId) {
      return checkedIncidentIds.has(incidentId);
    }

    function fireStoreUpdated() {
      subscribers.fireStoreUpdated();
      pubSubService.broadcast('IncidentList.incident.checked', {
        checkedCount: getCheckedIncidentIds().length,
      });
    }

    function pushLastChecked(incidentId) {
      lastCheckedIds.unshift(incidentId);
      if (lastCheckedIds > 2) {
        lastCheckedIds.pop();
      }
    }

    function getLastCheckedId() {
      return lastCheckedIds[0];
    }

    function getSecondLastCheckedId() {
      return lastCheckedIds[1];
    }
  }
}
