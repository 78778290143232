import { path as prevPath } from '../constants';

export const name = 'userManagement';
export const path = `${prevPath}.${name}`;
export const pending = 'pending';

export const isUserPending = (user) => user.cls === pending;
export const getUserName = (user) => user.name || user.email;

export const ANY_OPTION_VALUE = '-1';

export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  roles: { value: ANY_OPTION_VALUE, text: 'Any Role' },
  phone: { value: ANY_OPTION_VALUE, text: 'Any Phone Number' },
  status: { value: ANY_OPTION_VALUE, text: 'Any Status' },
};

export const USER_PHONES_FILTER_OPTIONS = {
  exist: 'true',
  notExist: 'false',
};

export const USER_STATUSES = {
  active: 'active',
  pending: 'pending',
};

export const DROPDOWN_FILTER_STATUS_ITEMS = [
  {
    key: USER_STATUSES.active,
    text: 'Active',
    value: USER_STATUSES.active,
  },
  {
    key: USER_STATUSES.pending,
    text: 'Pending',
    value: USER_STATUSES.pending,
  },
];

export const DROPDOWN_FILTER_PHONE_ITEMS = [
  {
    key: USER_PHONES_FILTER_OPTIONS.exist,
    text: 'Has phone number',
    value: USER_PHONES_FILTER_OPTIONS.exist,
  },
  {
    key: USER_PHONES_FILTER_OPTIONS.notExist,
    text: 'No phone number',
    value: USER_PHONES_FILTER_OPTIONS.notExist,
  },
];
