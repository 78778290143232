import React from 'react';
import { VBox, Text, UserunassignedCIcon, darkTheme, AllSizes } from '@bp/kung-fu';
import styled, { ThemeProvider } from 'styled-components';

const IconBox = styled(VBox)`
  width: 27px;
  height: 27px;
  line-height: 27px;
  font-size: 13.5px;
  border-radius: 3px;
  margin-right: 8px;
  background: ${(p) => p.theme.bp_gray_03};
  border: 1px solid ${(p) => p.theme.bp_gray_04};
  color: ${(p) => p.theme.bp_gray_07};
`;

function UnassignedAvatar() {
  return (
    <ThemeProvider theme={darkTheme}>
      <VBox>
        <UserunassignedCIcon />
      </VBox>
    </ThemeProvider>
  );
}

export default UnassignedAvatar;
