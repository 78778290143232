import find from 'lodash/find';
angular
  .module('bigpanda.reports')
  .controller('ReportEditPopoverController', ReportEditPopoverController);

function ReportEditPopoverController(
  $scope,
  $state,
  stateService,
  ReportsService,
  ModalService,
  pubSubService,
  PERMISSIONS_NAMES
) {
  const vm = this;
  vm.reportId = $scope.reportId;
  vm.reportEditPopoverPermissions = PERMISSIONS_NAMES.analytics;
  init();

  pubSubService.on(
    'ReportSettingsRename.dataLoaded',
    (event, data) => {
      if (data._id == vm.reportId) {
        return ReportsService.getReport(data._id).then((report) => {
          report.title = data.title;
          report.description = data.description;

          return ReportsService.updateReport(data._id).then(() => {
            if (stateService.getSelectedReportId() === data._id) {
              pubSubService.broadcast('currentTitleChanged', `Analytics - ${report.title}`);
            }
          });
        });
      }
    },
    $scope
  );

  function showDialog(template, locals) {
    $scope.hidePopover();
    locals.data = Object.assign({}, vm.report);

    ModalService.showModal({
      templateUrl: template,
      controller: 'ReportSettingsDialogController',
      locals: locals,
      bindToController: true,
    });
  }

  vm.showReportRenameDialog = function () {
    showDialog('reports/detail/report_settings_dialog/report_settings_dialog', {
      dialogTitle: 'Edit Report',
      submitButtonCaption: 'Save Report',
      cancelButtonCaption: 'Cancel',
      titleIconClass: 'bp-icon-edit',
      eventPrefix: 'ReportSettingsRename',
    });
  };

  vm.showReportDuplicateDialog = function () {
    showDialog('reports/detail/report_settings_dialog/report_settings_dialog', {
      dialogTitle: 'Duplicate Report',
      submitButtonCaption: 'Save New Report',
      cancelButtonCaption: 'Cancel',
      titleIconClass: 'bp-icon-duplicate',
      eventPrefix: 'ReportSettingsDuplicate',
    });
  };

  vm.showReportDeleteDialog = function () {
    showDialog(
      'reports/detail/report_delete_confirmation_dialog/report_delete_confirmation_dialog',
      {
        dialogTitle: 'Delete Report',
        submitButtonCaption: 'Delete Report',
        cancelButtonCaption: 'Cancel',
        titleIconClass: 'bp-icon-trash',
        eventPrefix: 'ReportSettingsDelete',
        dialogType: 'deletion-dialog',
      }
    );
  };

  pubSubService.on(
    'ReportSettingsDuplicate.dataLoaded',
    (event, data) => {
      if (data._id == vm.reportId) {
        var reportOverrides = {
          title: data.title,
          description: data.description,
        };

        return ReportsService.cloneReport(data._id, reportOverrides);
      }
    },
    $scope
  );

  pubSubService.on(
    'ReportSettingsDelete.dataLoaded',
    (event, data) => {
      if (data._id == vm.reportId) {
        return ReportsService.deleteReport(data._id).then(() => {
          if (stateService.getSelectedReportId() === data._id) {
            ReportsService.getReports().then((reports) => {
              vm.reports = reports;
              var defaultReportId = find(reports, { system: true });
              if (defaultReportId) {
                defaultReportId = defaultReportId._id;
              }

              $state.go('.', { reportId: defaultReportId });
            });
          }
        });
      }
    },
    $scope
  );

  function init() {
    vm.reportPromise = ReportsService.getReport(vm.reportId).then((report) => {
      vm.report = report;
      return report;
    });
  }
}
