import moment from 'moment';

import { IRelativity } from '../../types/MaintenancePlan';
import { days, formatOrdinal } from '../components/form/constants';
import { EMaintenanceFrequency } from '../components/form/types/EMaintenanceFrequency';
import { durationFormat } from './durationFormat';

interface ITimeWindow {
  start: number;
  end: number;
  frequency: string;
  frequency_data?: {
    duration?: number;
    relativity?: IRelativity;
    next_run: number;
  };
}

interface ITimeFrame {
  startTime: string;
  endTime: string;
  isPlain?: boolean;
}

const timeFrame = ({ startTime, endTime }: ITimeFrame): string => `${startTime} - ${endTime}`;

const timeStr = (date): string => date.format('h:mma');
const monthStr = (date): string => date.format('MMM Do');

export default (({
  start,
  end,
  frequency,
  frequency_data: frequencyData,
}: ITimeWindow): string => {
  const startDate = moment.unix(start);
  const endDate = moment.unix(end);

  if (!frequency || frequency === EMaintenanceFrequency.Once) {
    return `${monthStr(startDate)} at ${timeStr(startDate)} until ${monthStr(endDate)} at ${timeStr(endDate)}`;
  }

  const startTime = moment.unix(start);
  const endTime = startTime.clone().add(frequencyData.duration, 'seconds');

  if (frequency === EMaintenanceFrequency.Daily) {
    return `Daily at ${timeFrame({ startTime: timeStr(startTime), endTime: timeStr(endTime) })} for ${durationFormat(frequencyData.duration)}`;
  }

  const monthlyDate = (): string => {
    if (frequencyData.relativity) {
      return `${frequencyData.relativity.toLowerCase()} ${days[startDate.isoWeekday() - 1]}`;
    }
    return formatOrdinal(startDate.date());
  };

  return `${frequency} every ${frequency === EMaintenanceFrequency.Weekly ? days[startDate.isoWeekday() - 1] : monthlyDate()} at ${timeFrame({ startTime: timeStr(startTime), endTime: timeStr(endTime) })} for ${durationFormat(frequencyData.duration)}`;
});
