import { darkTheme, ISelectOption, Select } from '@bp/kung-fu';
import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import EnvRuleTagSelectItem from '../EnvRuleTagSelectItem';
import { IEnvRuleTagSelectItem } from '../EnvRuleTagSelectItem/types';
import SelectWrapper from './index.style';
import { IEnvRuleTagSelect } from './types';

const getSelectedValue = (
  items: ISelectOption[],
  selected: IEnvRuleTagSelectItem,
): Partial<IEnvRuleTagSelectItem> => {
  if (items?.length > 0) {
    const type = selected.type === 'INCIDENT_METADATA' ? 'INCIDENT_TAGS' : selected.type;
    const tags = (items).find((option) => option.value === type);
    const tagSelected = tags.options.find((tag) => tag.id === selected.id);
    return { id: tagSelected.id, text: `${tagSelected.text} (${tags.label})` };
  }

  return null;
};

function EnvRuleTagSelect(props: IEnvRuleTagSelect): JSX.Element {
  const {
    selected,
    items = [],
    metadata,
    onChange,
  } = props;

  const selectedValue = useMemo(
    () => (selected && getSelectedValue(items, selected)),
    [items, selected],
  );
  return (
    <ThemeProvider theme={darkTheme}>
      <SelectWrapper>
        <Select
          name={`ruleIndex_${metadata.ruleIndex}_andIndex_${metadata.andIndex}`}
          value={selectedValue}
          options={items}
          updateField={(name, value): void => onChange(value, metadata)}
          placeholder="Select tag..."
          filterPlaceholder="Type to filter.."
          optionComponent={EnvRuleTagSelectItem}
          isFilterable
          width="270px"
        />
      </SelectWrapper>
    </ThemeProvider>
  );
}

export default EnvRuleTagSelect;
