import partial from 'lodash/partial';
import last from 'lodash/last';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

angular.module('bigpanda').directive('timeline', timeline);

function timeline($mdPanel, D3Utils, IncidentsService, UserFeatureTogglesService) {
  return {
    restrict: 'E',
    scope: {
      domain: '<',
      highlightDomain: '<',
      startDate: '<',
      endDate: '<',
      data: '<',
      width: '<',
    },
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($scope, $element) {
    const vm = this;
    vm.$onChanges = onChanges;
    vm.$postLink = postLink;
    vm.$onInit = init;

    const CIRCLES_PADDING = 5;
    const CIRCLE_RADIUS = 5;
    const LINE_HEIGHT = 3;
    const HEIGHT = 45;
    const NOW = new Date();
    let chart;
    let row;
    let scale;
    let points;
    let alertViewRules;

    function init() {
      chart = d3.select($element[0]).append('svg').attr('height', HEIGHT).attr('width', vm.width);
      row = d3.select(
        chart
          .selectAll('.timeline-row')
          .data([vm.data])
          .enter()
          .append('g')
          .attr('class', 'timeline-row')
          .node()
      );
      scale = D3Utils.getTimeScale(vm.domain, vm.width);
      points = filter(
        vm.data.points,
        (point) =>
          new Date(point.start * 1000) >= vm.startDate &&
          (!vm.endDate || !point.end || new Date(point.end * 1000) <= vm.endDate)
      );
      points = reduce(points, setPointPosition, []);

      IncidentsService.getAlertViewRules().then((rules) => {
        alertViewRules = rules;
      });

      resize(0);
    }

    function setPointPosition(result, point) {
      const position = scale(new Date(point.start * 1000));
      const prev = last(result);

      // Param reassignment required here for d3 to transition elements with animation
      if (!prev || position - prev.position > CIRCLES_PADDING) {
        point.position = position; // eslint-disable-line no-param-reassign
      } else {
        point.position = prev.position + CIRCLES_PADDING; // eslint-disable-line no-param-reassign
      }

      result.push(point);
      return result;
    }

    function onChanges(changes) {
      if (changes.width.isFirstChange()) {
        return;
      }

      if (changes.width) {
        resize(changes.width.previousValue);
      }
    }

    function postLink() {
      $scope.$watch(() => vm.data, update);
      $scope.$watch(
        () => vm.domain,
        (domain, prevDomain) => {
          if (domain && (!prevDomain || domain[0] !== prevDomain[0])) {
            scale.domain(domain).nice();
            transition();
          }
        },
        true
      );
    }

    function resize(oldWidth) {
      if (vm.width > 0 && vm.width !== oldWidth) {
        chart.attr('width', vm.width);
        scale.rangeRound([0, vm.width]);
        points = reduce(points, setPointPosition, []);
        transition();
      }
    }

    function transition() {
      const transitionRow = row.transition().duration(D3Utils.TRANSITION_DURATION);

      if (vm.highlightDomain) {
        const start = scale(Math.max(scale.domain()[0], vm.highlightDomain[0]));
        const highlightWidth = scale(vm.highlightDomain[1]) - start;
        transitionRow
          .selectAll('.highlight')
          .attr('x', start)
          .attr('width', highlightWidth)
          .attr('stroke-dasharray', `${HEIGHT}, ${highlightWidth}`)
          .attr('stroke-dashoffset', HEIGHT);
      }

      let markerPosition = scale(vm.startDate);
      transitionRow
        .selectAll('.start-marker')
        .attr('x1', markerPosition)
        .attr('x2', markerPosition);
      markerPosition = scale(vm.endDate || NOW);
      transitionRow.selectAll('.end-marker').attr('x1', markerPosition).attr('x2', markerPosition);
      transitionRow
        .selectAll('.line')
        .attr('x', (d) => d.position)
        .attr('width', (d, i) => getLineWidth(d, i, points[i + 1]));
      transitionRow.selectAll('.circle').attr('cx', (d) => d.position);
      transitionRow.selectAll('.inner-circle').attr('cx', (d) => d.position);
    }

    function getLineWidth(d, i, next) {
      let lineWidth = 0;

      if (!next && d.ongoing) {
        lineWidth = scale(NOW) - d.position;
      } else {
        lineWidth = scale(new Date(d.end * 1000)) - d.position;
      }

      return lineWidth > 0 ? lineWidth : 0;
    }

    function update() {
      if (vm.highlightDomain) {
        const start = scale(Math.max(scale.domain()[0], vm.highlightDomain[0]));
        const highlightWidth = scale(vm.highlightDomain[1]) - start;

        row
          .append('rect')
          .attr('class', 'highlight')
          .attr('height', HEIGHT)
          .style('fill', '#dcf0fa')
          .style('opacity', 0)
          .attr('y', 0)
          .attr('x', start)
          .attr('width', highlightWidth)
          .attr('stroke', '#009AEF')
          .attr('stroke-dasharray', `${HEIGHT}, ${highlightWidth}`)
          .attr('stroke-dashoffset', HEIGHT)
          .transition()
          .duration(D3Utils.TRANSITION_DURATION)
          .style('opacity', 0.5);
      }

      addMarker('end-marker', scale(vm.endDate || NOW));
      addMarker('start-marker', scale(vm.startDate));

      row
        .selectAll('.line')
        .data(points)
        .enter()
        .append('rect')
        .attr('class', 'line')
        .attr('height', 0)
        .style('fill', (d) => d.color)
        .attr('y', HEIGHT / 2 - LINE_HEIGHT / 2)
        .attr('x', (d) => d.position)
        .attr('width', (d, i) => getLineWidth(d, i, points[i + 1]))
        .transition()
        .duration(D3Utils.TRANSITION_DURATION)
        .attr('height', LINE_HEIGHT);

      // Points are reversed in order to properly draw them - earlier events overlap later events
      const circlesContainer = row
        .selectAll('.circle-container')
        .data(points.slice().reverse())
        .enter()
        .append('g')
        .attr('class', 'circle-container');

      const circles = circlesContainer
        .append('circle')
        .attr('class', 'circle')
        .attr('r', 0)
        .attr('cy', HEIGHT / 2)
        .attr('cx', (d) => d.position)
        .style('fill', (d) => d.color)
        .style('cursor', 'pointer')
        .attr('stroke', 'white')
        .attr('stroke-width', 1)
        .on('click', click)
        .on('mouseover', focus)
        .on('mouseout', unfocus)
        .transition()
        .duration(D3Utils.TRANSITION_DURATION)
        .attr('r', CIRCLE_RADIUS);

      const innerCircles = circlesContainer
        .append('circle')
        .attr('class', 'inner-circle')
        .attr('r', 0)
        .attr('cy', HEIGHT / 2)
        .attr('cx', (d) => d.position)
        .style('fill', 'white')
        .attr('cy', HEIGHT / 2);

      function addMarker(className, markerPosition) {
        row
          .append('line')
          .attr('class', className)
          .attr('y1', 0)
          .attr('y2', HEIGHT)
          .attr('stroke', '#c7c7c7')
          .attr('stroke-dasharray', 2)
          .attr('stroke-width', 2)
          .style('opacity', 0)
          .attr('x1', markerPosition)
          .attr('x2', markerPosition)
          .transition()
          .duration(D3Utils.TRANSITION_DURATION)
          .style('opacity', 1);
      }

      let panel;
      let prevIndex;

      function click(data, i) {
        if (i !== prevIndex) {
          openPanel();
        }

        function openPanel() {
          prevIndex = i;
          const clickedEntity = d3.select(circles[0][i]);
          const innerCircle = d3.select(innerCircles[0][i]);
          const position = $mdPanel.newPanelPosition().relativeTo(clickedEntity[0]);
          const offset = angular.element(clickedEntity[0]).offset();
          let xAlignment;
          let yAlignment;
          let panelClass = 'timeline-event-details-panel';
          clickedEntity
            .transition()
            .duration(300)
            .attr('r', CIRCLE_RADIUS + 2);
          innerCircle.transition().duration(300).attr('r', 2);

          if (offset.left < 650) {
            xAlignment = $mdPanel.xPosition.ALIGN_START;
            panelClass += ' start';
          } else {
            xAlignment = $mdPanel.xPosition.ALIGN_END;
            panelClass += ' end';
          }

          if (angular.element(window).height() - offset.top < 325) {
            yAlignment = $mdPanel.yPosition.ABOVE;
            panelClass += ' top';
          } else {
            yAlignment = $mdPanel.yPosition.BELOW;
            panelClass += ' bottom';
          }

          position.addPanelPosition(xAlignment, yAlignment);

          panel = $mdPanel.create({
            templateUrl: 'shared_ui/bp_event_details/bp_event_details',
            clickOutsideToClose: true,
            escapeToClose: true,
            onDomRemoved: partial(unselectEntity, prevIndex),
            zIndex: 150,
            propagateContainerEvents: true,
            controllerAs: 'vm',
            position: position,
            panelClass: panelClass,
            controller: panelController,
          });

          panel.open();

          function panelController() {
            const panelVm = this;
            // Previous and next must account for events being reversed
            panelVm.prev = () => panel.close().then(() => click(null, i + 1));
            panelVm.next = () => panel.close().then(() => click(null, i - 1));
            panelVm.close = () => panel.close();

            // Points are reversed in order to properly draw them - earlier events overlap later events
            panelVm.event = getEventDetailsFromEntity(points[points.length - i - 1]);
            panelVm.maintenanceTooltip = {
              template: 'overview/entities/maintenance_tooltip/maintenance_tooltip',
              plans: panelVm.event.plans,
            };
            panelVm.eventCount = points.length;
            panelVm.index = i;
          }

          function getEventDetailsFromEntity(passedEntity) {
            // non-empty tags only
            let tags = passedEntity.tags.filter(
              (t) => t.value !== undefined && t.value !== null && t.value !== ''
            );
            let collapsedTags = [];

            const alertTags = IncidentsService.getAlertTags(passedEntity, alertViewRules);
            if (alertTags) {
              tags = alertTags[0];
              collapsedTags = alertTags[1];
            }

            return {
              id: passedEntity._id,
              source: passedEntity.source,
              status: passedEntity.status.toLowerCase(),
              underMaintenance: passedEntity.underMaintenance,
              created: moment.unix(passedEntity.start).calendar(),
              lastEvent: null, // no 'lastEvent' field should be shown here
              tags: tags,
              collapsedTags: collapsedTags,
              plans: passedEntity.plans,
            };
          }
        }

        if (d3.event) {
          d3.event.stopPropagation();
        }
      }

      function focus(data, i) {
        d3.select(circles[0][i])
          .transition()
          .duration(300)
          .attr('r', CIRCLE_RADIUS + 2);
      }

      function unfocus(data, i) {
        if (i !== prevIndex) {
          d3.select(circles[0][i]).transition().duration(300).attr('r', CIRCLE_RADIUS);
        }
      }

      function unselectEntity(index) {
        d3.select(circles[0][index])
          .transition()
          .duration(D3Utils.TRANSITION_DURATION)
          .attr('r', CIRCLE_RADIUS);
        d3.select(innerCircles[0][index])
          .transition()
          .duration(D3Utils.TRANSITION_DURATION)
          .attr('r', 0);
        prevIndex = -1;
      }

      $scope.$on('$destroy', () => {
        if (panel) {
          panel.close().then(() => panel.destroy());
        }
      });
    }
  }
}
