import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { tagPropType } from '../propTypes';
import SettingsListItemActions from '../../../../layout/settings/components/SettingsListItemActions';
import AlertTagModal from '../modals/AlertTagModal';
import selectors from '../selectors';
import actions from '../actions';
import isMapping from '../modals/utils/isMapping';
import { hasMapping } from '../utils';
import { selectors as FTSelectors } from '../../../../user/feature_toggles';
import { shouldUseTagOperations } from '../../../../../../workspaces/apps/alert-enrichment/src/helpers/should-use-tag-operations';
import { useDuplicateTagButton } from '../../../../../../workspaces/apps/alert-enrichment/src/hooks/useDuplicateTagButton';
import { getDuplicatedTag } from '../../../../../../workspaces/apps/alert-enrichment/src/helpers/get-duplicated-tag';
import texts from '../../../../../../workspaces/apps/alert-enrichment/src/dictionary';
import { getInterpolatedText } from '../../../../../../workspaces/apps/alert-enrichment/src/helpers/get-interpolated-text';

const getDuplicationWarning = (tagName, isMappingTag, isTagContainsMappingRules) => {
  if (isMappingTag) {
    return texts.lobby__duplicate_or_delete_mapping_tag;
  }

  if (isTagContainsMappingRules) {
    return texts.lobby__duplicate_multi_type_tag_with_mapping;
  }

  return getInterpolatedText(texts.lobby__duplicate_tag_error, { tagName });
};

const AlertEnrichmentActionButtons = ({
  tag,
  actionPermissions: { hasEditPermission },
  removeAlertTag,
  useTagOperations,
  allTags,
}) => {
  const { name } = tag;
  const isTagContainsMappingRules = hasMapping(tag.enrichments);
  const isMappingTag = isMapping(tag.type);
  const deleteNote = isTagContainsMappingRules
    ? "The tag itself won't be deleted since it contains mapping rules. After updating the matching map, that tag will be deleted."
    : 'All the enrichment items inside this tag will be deleted.';

  const isMappingOrContainsMappingRules =
    useTagOperations && (isTagContainsMappingRules || isMappingTag);
  const duplicateAllowed = useTagOperations && hasEditPermission;
  const deleteDisabled = isMappingTag || isMappingOrContainsMappingRules || !hasEditPermission;
  const { shouldOpenTagDuplicationModal } = useDuplicateTagButton({ tag });
  const duplicatedTag = getDuplicatedTag(tag, allTags);

  const messages = {
    edit: getInterpolatedText(texts.lobby__edit_tag_error, { tagName: name }),
    duplicate: getDuplicationWarning(name, isMappingTag, isTagContainsMappingRules),
    enabledDuplicateTooltipText:
      useTagOperations && isTagContainsMappingRules
        ? texts.lobby__duplicate_multi_type_tag_with_mapping
        : undefined,
    delete: isMappingTag
      ? texts.lobby__duplicate_or_delete_mapping_tag
      : texts.lobby__delete_multi_type_with_mapping_rules,
  };

  return (
    <SettingsListItemActions
      item={tag}
      modelName="Alert tag"
      messages={messages}
      editProps={{
        disabled: !hasEditPermission,
        modalProps: {
          tag,
          title: 'Edit Alert tag',
        },
        modal: AlertTagModal,
      }}
      deleteProps={{
        disabled: deleteDisabled,
        showIfDisabled: isMappingOrContainsMappingRules,
        action: () => removeAlertTag({ rules: map(tag.enrichments, 'id'), tagName: name }),
        modalProps: {
          contentTitleType: 'string',
          contentTitle: tag.name,
          content: 'Are you sure you want to delete this Alert tag?',
          note: `Note: ${deleteNote}`,
        },
      }}
      duplicateProps={
        duplicateAllowed && {
          modalProps: {
            tag: duplicatedTag,
            title: 'Duplicate Alert Tag',
            openCondition: shouldOpenTagDuplicationModal,
          },
          disabled: !duplicateAllowed || !duplicatedTag,
          showIfDisabled: true,
          modal: AlertTagModal,
        }
      }
    />
  );
};

AlertEnrichmentActionButtons.propTypes = {
  tag: tagPropType.isRequired,
  actionPermissions: PropTypes.shape({
    hasEditPermission: PropTypes.bool,
  }).isRequired,
  removeAlertTag: PropTypes.func.isRequired,
  useTagOperations: PropTypes.bool,
  allTags: PropTypes.arrayOf(tagPropType).isRequired,
};

function mapStateToProps(state) {
  return {
    actionPermissions: selectors.getActionsPermissions(state),
    useTagOperations: shouldUseTagOperations(FTSelectors.getFeatureToggles(state)),
    allTags: selectors.getTags(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeAlertTag: (enrichments) => dispatch(actions.removeAlertTag(enrichments)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertEnrichmentActionButtons);
