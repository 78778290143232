import { ISelectOption } from '@bp/kung-fu';
import uniqBy from 'lodash/uniqBy';

import { anyChangeObject } from '../../constants';
import { AuditRow } from '../useAuditTable/types';

export function mapChangeObjectDefaults(auditLogs: AuditRow[]): ISelectOption[] {
  if (!Array.isArray(auditLogs)) {
    return auditLogs;
  }
  const defaults = uniqBy(
    auditLogs.map((log) => ({ id: log.resourceId, text: log.changedObject })),
    (log) => log.id,
  ).slice(0, 10);
  return [anyChangeObject, ...defaults];
}
