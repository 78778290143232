import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { ssoLoginUrls } from '../../../common/constants';

angular.module('bigpanda').controller('IncidentValidatorController', IncidentValidatorController);

function IncidentValidatorController(
  $state,
  $stateParams,
  EnvironmentsService,
  IncidentValidatorService,
  notificationService,
  IncidentsService,
  IncidentsSearchFolderService,
  pubSubService,
  PersonalSettingsStore
) {
  let params = cloneDeep($stateParams);
  if (params.incidentId && params.incidentId.includes('&contact=')) {
    const incidentId = params.incidentId.split('&')[0];
    params.incidentId = incidentId;
  }

  if (params.folder === 'search' && params.incidentId) {
    return IncidentsSearchFolderService.searchFolder(
      params.returnTo,
      params.environment,
      params.incidentId
    ).then((resParams) => {
      params = resParams;
      findFolderAndValidate();
      pubSubService.broadcast('folder.changed', params.folder);
    }, goToDefault);
  }

  function findFolderAndValidate() {
    return EnvironmentsService.getEnvById(params.environment).then((env) => {
      if (angular.isDefined(env)) {
        const folder = find(env.folders, { id: params.folder });
        validate(folder);
      }
    });
  }

  function validate(folder) {
    if (!folder) {
      goToDefault();
      notificationService.error(
        `Couldn't find folder with id ${params.folder} in '${params.environment}' environment`,
        null
      );
      return;
    }

    IncidentsService.getIncidentById(params.incidentId, true)
      .then((incident) => {
        incident ? completeValidation() : errorBadIncident(folder, incident);
      })
      .catch(() => {
        errorBadIncident(folder);
      });
  }

  async function completeValidation() {
    const user = await PersonalSettingsStore.getUser();
    const orgName = get(user, ['organization', 'name']);
    IncidentValidatorService.setValidation(true);
    $state.go(params.returnTo, {
      incidentId: params.incidentId,
      environment: params.environment,
      folder: params.folder,
      incidentfullscreen: params.incidentfullscreen,
      target: params.target,
      org: Object.keys(ssoLoginUrls).includes(orgName) ? orgName : undefined,
    });
  }

  function goToDefault() {
    $state.go('app.overview.incidents.list', {
      environment: params.environment,
      folder: 'active',
    });
  }

  function errorBadIncident(folder) {
    goToDefault();
    notificationService.error(
      `Couldn't find incident with id ${params.incidentId} in '${folder.name}' folder`,
      null
    );
  }

  findFolderAndValidate();
}
