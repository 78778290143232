const permissions = require('../../common/routing/permissions').default;
const correlationRuleRouteData = require('./settings/correlation/correlation.rule.route.data');
const customTagRouteData = require('./settings/correlation/custom.tag.route.data');

const routes = {};

routes.bootstrap = {
  url: '/b',
  templateUrl: 'bootstrap/bootstrap',
  controller: 'BootstrapController',
  controllerAs: 'bootstrapCtrl',
};

routes.app = {
  url: '/app',
  data: {
    onRootStart: (StateValidatorService, toState, toParams) =>
      StateValidatorService.getAppValidatorService().validateApp(toState, toParams),
  },
  templateUrl: 'layout/page/abstract-page',
  controller: 'AppContainerController',
};

routes.appDefault = {
  url: '/default',
  views: {
    'default-widget': {
      templateUrl: 'routing/default_state',
      controllerAs: 'bootstrapCtrl',
      controller: 'DefaultStateController',
    },
  },
};

routes.appWelcome = {
  url: '/welcome',
  views: {
    widget: {
      templateUrl: 'welcome/welcome',
      controller: 'WelcomeController',
      controllerAs: 'vm',
      bindToController: true,
    },
  },
};

routes.appWelcomeV2 = {
  onEnter: () => (window.location.href = '/v2/welcome'),
};

routes.appChangePassword = {
  url: '/changepassword',
  views: {
    widget: {
      templateUrl: 'user/change_password',
      controller: 'ChangeUserPasswordCtrl',
      controllerAs: 'ChangeUserPasswordVm',
      bindToController: true,
    },
  },
  data: {
    getOrigin: () => 'reset',
  },
};

routes.appChangePasswordApp = {
  url: '/changepass',
  views: {
    widget: {
      templateUrl: 'user/change_password',
      controller: 'ChangeUserPasswordCtrl',
      controllerAs: 'ChangeUserPasswordVm',
      bindToController: true,
    },
  },
  data: {
    getOrigin: () => 'app',
  },
};

routes.appFullpage = {
  url: '/fullpage',
  views: {
    widget: {
      controller: 'FullPageController',
      templateUrl: 'common/fullpage/fullpage',
    },
  },
};

routes.appFullpageInstallIntegration = {
  url: '/install/:integration?signed_request',
  views: {
    fullpage: {
      templateUrl: 'integrations/integration_install/integration_install',
      controller: 'IntegrationInstallController',
      controllerAs: 'vm',
    },
  },
  data: {
    permissions: {
      only: [permissions.integrations.create],
      redirectTo: 'app.integrations',
    },
  },
};

routes.appIntegrations = {
  url: '/integrations',
  views: {
    widget: {
      templateUrl: 'integrations/integrations',
      controller: 'IntegrationsController',
      controllerAs: 'vm',
    },
    'master-detail-info@app.integrations': {
      templateUrl: 'integrations/integrations_information_center/integrations_information_center',
      controller: 'IntegrationsInformationCenterController',
      controllerAs: 'vm',
    },
  },
};

routes.appIntegrationsIntegrationInfo = {
  url: '/status/:integration',
  views: {
    'master-detail-info@app.integrations': {
      templateUrl: 'integrations/integration_info/status/integration_status',
      controller: 'IntegrationStatusController',
      controllerAs: 'vm',
    },
  },
};

routes.appIntegrationsIntegrationInfoTroubleshooting = {
  url: '/status/:integration/troubleshooting',
  views: {
    'master-detail-info@app.integrations': {
      templateUrl: 'integrations/integration_info/status/integration_status',
      controller: 'IntegrationStatusController',
      controllerAs: 'vm',
    },
  },
};

routes.appIntegrationsSelection = {
  url: '/selections',
  onEnter: (ModalService, $state) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'integrations/selection/integrations_selection',
      controller: 'IntegrationsSelectionController',
      controllerAs: 'integrationSelectionVm',
      bindToController: true,
      fullscreen: true,
      onShowing: ($scope, $element) => {
        $element.addClass('integrations-selection-modal');
      },
    }).finally(() => {
      if ($state.current.name === 'app.integrations.selection') {
        $state.go('app.integrations');
      }
    });
  },
  data: {
    permissions: {
      only: [permissions.integrations.create],
      redirectTo: 'app.integrations',
    },
  },
};

routes.appIntegrationsWelcome = {
  url: '/welcome',
  onEnter: (ModalService) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'integrations/welcome_sequence/welcome_sequence',
      controller: 'WelcomeSequenceController',
      controllerAs: 'vm',
      escapeToClose: false,
      clickOutsideToClose: false,
    });
  },
  data: {
    permissions: {
      only: [permissions.integrations.create],
      redirectTo: 'app.integrations',
    },
  },
};

routes.appIntegrationInfo = {
  url: '/integrations/:integration',
  views: {
    widget: {
      templateUrl: 'integrations/integration_info/integration_info',
      controller: 'IntegrationInfoController',
      controllerAs: 'vm',
    },
  },
  abstract: true,
};

routes.appIntegrationInfoInstructions = {
  url: '/instructions?activeTab',
  views: {
    'integration-info-view': {
      templateUrl: 'integrations/integration_info/instructions/integration_instructions',
      controller: 'IntegrationInstructionsController',
    },
  },
  params: {
    activeTab: null,
  },
  data: {
    permissions: {
      only: [permissions.integrations.create],
      redirectTo: 'app.integrations',
    },
  },
};

routes.appIntegrationInfoInstructionsGuide = {
  url: '/:guide',
  views: {
    'integration-info-guide-view': {
      templateUrl: 'integrations/integration_info/instructions/guides/guide',
      controller: 'guideController',
      controllerAs: 'vm',
    },
  },
  data: {
    permissions: {
      only: [permissions.integrations.create],
      redirectTo: 'app.integrations',
    },
  },
};

routes.appMobile = {
  url: '/mobile/integrations',
  views: {
    widget: {
      templateUrl: 'integrations/mobile_notification/firsttime_mobile',
      controller: 'FirsttimeMobileCtrl',
    },
  },
};

routes.preview = {
  url: '/preview?t&print',
  templateUrl: 'preview/preview',
  controller: 'PreviewController',
  abstract: false,
};

routes.previewIncident = {
  url: '/incident/:incidentId?source&escalation&contact',
  views: {
    widget: {
      templateUrl: 'preview/incident/incident',
      controller: 'IncidentPreviewController',
    },
  },
};

routes.appSettings = {
  url: '/settings',
  views: {
    widget: {
      templateUrl: 'settings/settings',
      controller: 'SettingsCtrl',
      controllerAs: 'settingsVm',
    },
  },
};

routes.appSettingsQuota = {
  url: '/quota',
  views: {
    'settings-page-view': {
      templateUrl: 'settings/quota/quota_settings',
      controller: 'QuotaSettingsController',
    },
  },
  data: {
    permissions: {
      only: [permissions.quotas.read],
      redirectTo: 'app.settings.personal',
    },
  },
};

routes.appSettingsTags = {
  url: '/tags',
  views: {
    'settings-page-view': {
      templateUrl: 'settings/correlation/custom_tags/custom_tags_settings',
      controller: 'CustomTagsSettingsCtrl',
      controllerAs: 'tagsVm',
    },
    'master-detail-info@app.settings.tags': {
      templateUrl: 'settings/correlation/custom_tags/info/custom_tag_info_empty',
    },
  },
  data: {
    permissions: {
      only: [permissions.tags.read],
      redirectTo: 'app.settings.personal',
    },
  },
};

routes.appSettingsTagsNew = {
  url: '/new',
  onEnter: (
    ModalService,
    UserIntegrationsStore,
    UserIntegrationsUtils,
    TagsService,
    CorrelationConfigService
  ) => {
    'ngInject';

    customTagRouteData.onEnterNew(
      ModalService,
      UserIntegrationsStore,
      UserIntegrationsUtils,
      TagsService,
      CorrelationConfigService
    );
  },
  params: { new: true },
  data: {
    permissions: {
      only: [permissions.tags.create],
      redirectTo: 'app.settings.tags',
    },
  },
};

routes.appSettingsTagsInfo = {
  url: '/:tagId',
  views: {
    'master-detail-info': {
      templateUrl: 'settings/correlation/custom_tags/info/custom_tag_info',
      controller: 'CustomTagInfoCtrl',
      controllerAs: 'tagInfoVm',
    },
  },
  resolve: {
    resolvedTag: ($stateParams, CustomTagsService, CorrelationConfigService) => {
      'ngInject';

      return CorrelationConfigService.checkForConfigChanges().then(() =>
        CustomTagsService.getTagById($stateParams.tagId)
      );
    },
  },
};

routes.appSettingsTagsInfoEdit = {
  url: '/edit',
  onEnter: (
    $stateParams,
    ModalService,
    UserIntegrationsStore,
    UserIntegrationsUtils,
    CustomTagsService,
    TagsService,
    CorrelationConfigService,
    CorrelationActions
  ) => {
    'ngInject';

    customTagRouteData.onEnterEdit(
      $stateParams,
      ModalService,
      UserIntegrationsStore,
      UserIntegrationsUtils,
      CustomTagsService,
      TagsService,
      CorrelationConfigService,
      CorrelationActions
    );
  },
  params: { edit: true },
  data: {
    permissions: {
      only: [permissions.tags.update],
      redirectTo: 'app.settings.tags',
    },
  },
};

routes.appSettingsTagsInfoDuplicate = {
  url: '/duplicate',
  onEnter: (
    $stateParams,
    ModalService,
    UserIntegrationsStore,
    UserIntegrationsUtils,
    CustomTagsService,
    TagsService,
    CorrelationConfigService,
    CorrelationActions
  ) => {
    'ngInject';

    customTagRouteData.onEnterEdit(
      $stateParams,
      ModalService,
      UserIntegrationsStore,
      UserIntegrationsUtils,
      CustomTagsService,
      TagsService,
      CorrelationConfigService,
      CorrelationActions
    );
  },
  params: { duplicate: true },
  data: {
    permissions: {
      only: [permissions.tags.create],
      redirectTo: 'app.settings.tags',
    },
  },
};

routes.appSettingsCorrelations = {
  onEnter: (TagsService) => {
    'ngInject';

    // Calling getTags with true forces tags cache refresh
    // This will ensure high performance in Tags Input autocomplete
    TagsService.getTags(true);
  },
  url: '/correlations',
  views: {
    'settings-page-view': {
      templateUrl: 'settings/correlation/correlation_rules/correlation_rules_settings',
      controller: 'CorrelationRulesSettingsCtrl',
      controllerAs: 'correlationsVm',
    },
    'master-detail-info@app.settings.correlations': {
      templateUrl: 'settings/correlation/correlation_rules/info/correlation_rule_info_empty',
    },
  },
  data: {
    permissions: {
      only: [permissions.correlations.read],
      redirectTo: 'app.settings.personal',
    },
  },
};

routes.appSettingsCorrelationsNew = {
  url: '/new',
  onEnter: (
    ModalService,
    UserIntegrationsStore,
    UserIntegrationsUtils,
    CorrelationConfigService
  ) => {
    'ngInject';

    correlationRuleRouteData.onEnterNew(
      ModalService,
      UserIntegrationsStore,
      UserIntegrationsUtils,
      CorrelationConfigService
    );
  },
  params: { new: true },
  data: {
    permissions: {
      only: [permissions.correlations.create],
      redirectTo: 'app.settings.correlations',
    },
  },
};

routes.appSettingsCorrelationsInfo = {
  url: '/:correlationId',
  views: {
    'master-detail-info': {
      templateUrl: 'settings/correlation/correlation_rules/info/correlation_rule_info',
      controller: 'CorrelationRuleInfoCtrl',
      controllerAs: 'correlationInfoVm',
    },
  },
  resolve: {
    resolvedCorrelation: ($stateParams, CorrelationRulesService, CorrelationConfigService) => {
      'ngInject';

      return CorrelationConfigService.checkForConfigChanges().then(() =>
        CorrelationRulesService.getCorrelationRuleById($stateParams.correlationId)
      );
    },
  },
};

routes.appSettingsCorrelationsInfoEdit = {
  url: '/edit',
  onEnter: (
    $stateParams,
    ModalService,
    UserIntegrationsStore,
    UserIntegrationsUtils,
    CorrelationRulesService,
    CorrelationConfigService,
    CorrelationActions
  ) => {
    'ngInject';

    correlationRuleRouteData.onEnterEdit(
      $stateParams,
      ModalService,
      UserIntegrationsStore,
      UserIntegrationsUtils,
      CorrelationRulesService,
      CorrelationConfigService,
      CorrelationActions
    );
  },
  params: { edit: true },
  data: {
    permissions: {
      only: [permissions.correlations.update],
      redirectTo: 'app.settings.correlations',
    },
  },
};

routes.appSettingsCorrelationsInfoDuplicate = {
  url: '/duplicate',
  onEnter: (
    $stateParams,
    ModalService,
    UserIntegrationsStore,
    UserIntegrationsUtils,
    CorrelationRulesService,
    CorrelationConfigService,
    CorrelationActions
  ) => {
    'ngInject';

    correlationRuleRouteData.onEnterEdit(
      $stateParams,
      ModalService,
      UserIntegrationsStore,
      UserIntegrationsUtils,
      CorrelationRulesService,
      CorrelationConfigService,
      CorrelationActions
    );
  },
  params: { duplicate: true },
  data: {
    permissions: {
      only: [permissions.correlations.create],
      redirectTo: 'app.settings.correlations',
    },
  },
};

routes.appSettingsUserManagementInvite = {
  url: '/invite',
  onEnter: (ModalService, $state) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'settings/user_management/invite/user_invite',
      controller: 'UserInviteController',
      controllerAs: 'inviteVm',
      onRemoving: () => $state.go('^'),
      bindToController: true,
    }).then((userId) => {
      if (userId) {
        $state.go('app.settings.user_management.info', { userId });
      }
    });
  },
};

routes.appSettingsSso = {
  url: '/sso/:guide',
  views: {
    'settings-page-view': {
      templateUrl: 'settings/sso/sso_settings',
      controller: 'SsoSettingsController',
    },
  },
  data: {
    permissions: {
      only: [permissions.sso.read],
      redirectTo: 'app.settings.personal',
    },
  },
};

routes.appSettingsRules = {
  url: '/rules',
  views: {
    'settings-page-view': {
      templateUrl: 'settings/rules/rules',
      controller: 'RulesController',
    },
  },
  data: {
    permissions: {
      only: [permissions.notifications.read],
      redirectTo: 'app.settings.personal',
    },
  },
};

routes.appSettingsRulesNew = {
  url: '/new',
  onEnter: (ModalService, $state) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'settings/rules/info/new_rule',
      controller: 'NewRuleController',
      controllerAs: 'RuleVm',
    }).finally(() => $state.go('^'));
  },
  data: {
    permissions: {
      only: [permissions.notifications.create],
      redirectTo: 'app.settings.rules',
    },
  },
};

routes.appSettingsRulesView = {
  url: '/:rule',
  views: {
    'master-detail-info@app.settings.rules': {
      templateUrl: 'settings/rules/info/view_rule',
      controller: 'RulesController',
    },
  },
  data: {
    permissions: {
      only: [permissions.notifications.update],
      redirectTo: 'app.settings.rules',
    },
  },
};

routes.appEnvironments = {
  url: '/environments?environmentId&dupEnvironmentId',
  views: {
    widget: {
      templateUrl: 'overview/environments/editor/environment_editor',
      controller: 'EnvironmentEditorCtrl',
      controllerAs: 'environmentEditorCtrl',
    },
  },
  data: {
    permissions: {
      only: [permissions.environments.create, permissions.environments.update],
      redirectTo: 'app.overview.incidents.list',
    },
  },
};

routes.appOverview = {
  url: '/overview',
  abstract: true,
  views: {
    widget: {
      templateUrl: 'overview/overview',
      controller: 'overviewCtrl',
      controllerAs: 'vm',
    },
  },
};

routes.appOverviewIncidents = {
  url: '/:environment/:folder/incidents?fullscreen&tour',
  params: {
    fullscreen: null,
    tour: null,
  },
  abstract: true,
  views: {
    incidents: {
      template: '<incidents checked-incidents-state></incidents>',
    },
  },
};

routes.appOverviewIncidentsList = {
  url: '',
  views: {
    incident: {
      templateUrl: 'overview/incidents/info/empty_info',
      controller: 'EmptyInfoCtrl',
      controllerAs: 'emptyInfoCtrl',
    },
  },
};

routes.appOverviewIncidentsValidate = {
  url: '/v/:incidentId?returnTo&target&incidentfullscreen',
  views: {
    incident: {
      controller: 'IncidentValidatorController',
    },
  },
};

routes.appOverviewIncidentsIncidentInfo = {
  url: '/:incidentId?incidentfullscreen&org',
  params: {
    incidentfullscreen: null,
  },
  data: {
    // redirect to the validator state which check if the incident exists in the incident list.
    onStart: (StateValidatorService, toState, toParams) =>
      StateValidatorService.getIncidentValidatorService().validateIncident(
        'app.overview.incidents.validate',
        toState.name,
        toParams.folder,
        toParams.incidentId,
        toParams.target,
        toParams.environment,
        toParams.bp_source,
        toParams.incidentfullscreen
      ),
    onSuccess: (StateValidatorService) =>
      StateValidatorService.getIncidentValidatorService().setValidation(false),
  },
  views: {
    incident: {
      templateUrl: 'overview/incidents/info/incident_info',
      controller: 'IncidentInfoCtrl',
      controllerAs: 'incidentInfoVm',
      bindToController: true,
    },
  },
  resolve: {
    resolvedIncident: ($stateParams, IncidentsService) => {
      'ngInject';

      return IncidentsService.getIncidentById($stateParams.incidentId, true);
    },
  },
};

routes.appOverviewIncidentsIncidentInfoLifecycle = {
  url: '/timeline',
  onEnter: (ModalService, $state) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'incident_lifecycle/incident_lifecycle',
      controller: 'IncidentLifecycleCtrl',
      controllerAs: 'lifecycleVm',
      fullscreen: true,
      onShowing: ($scope, $element) => {
        $element.addClass('lifecycle-modal-container');
      },
    }).finally(() => $state.go('^', {}, { notify: false }));
  },
};

routes.appOverviewIncidentsIncidentInfoShare = {
  url: '/share?context',
  params: {
    context: null,
  },
  onEnter: (ModalService, $state, $q, $mdMedia, $transition$) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'overview/incidents/actions/share/share_popup',
      controller: 'SharePopupController',
      controllerAs: 'vm',
      bindToController: true,
      fullscreen: $mdMedia('max-width: 600px'),
      locals: { context: $transition$.params().context },
      resolve: {
        resolvedIncidentIds: () => [$transition$.params().incidentId],
      },
    }).finally(() => $state.go('^', {}, { notify: false }));
  },
};

routes.appOverviewIncidentsIncidentInfoSharesList = {
  url: '/share-list',
  onEnter: (ModalService, $state, $transition$) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'overview/incidents/actions/share/shares-list/shares_list',
      controller: 'SharesListController',
      controllerAs: 'vm',
      bindToController: true,
      locals: { incidentId: $transition$.params().incidentId },
    }).finally(() => $state.go('^', {}, { notify: false }));
  },
};

routes.appOverviewIncidentsIncidentInfoComment = {
  url: '/comment',
  onEnter: (ModalService, $state, $mdMedia, $stateParams) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'overview/incidents/actions/comment/popup/comment_popup',
      controller: 'CommentPopupController',
      controllerAs: 'vm',
      fullscreen: $mdMedia('max-width: 600px'),
      bindToController: true,
      locals: { incidentId: $stateParams.incidentId },
    }).finally(() => $state.go('^', {}, { notify: false }));
  },
};

routes.appOverviewIncidentsIncidentInfoSnooze = {
  url: '/snooze',
  onEnter: (ModalService, $state, $mdMedia) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'overview/incidents/actions/snooze/snooze_popup',
      controller: 'SnoozePopupController',
      controllerAs: 'vm',
      fullscreen: $mdMedia('max-width: 600px'),
      bindToController: true,
      resolve: {
        resolvedIncidentIds: [
          'stateService',
          (stateService) => [stateService.getSelectedIncidentId()],
        ],
      },
    }).finally(() => $state.go('^', {}, { notify: false }));
  },
};

routes.appOverviewIncidentsIncidentInfoResolve = {
  url: '/resolve',
  onEnter: (ModalService, $state, $mdMedia) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'overview/incidents/actions/resolve/resolve_popup',
      controller: 'IncidentResolveCtrl',
      controllerAs: 'incidentResolveVm',
      bindToController: true,
      fullscreen: $mdMedia('max-width: 600px'),
      resolve: {
        resolvedIncidentIds: [
          'stateService',
          (stateService) => [stateService.getSelectedIncidentId()],
        ],
      },
    }).finally(() => $state.go('^'));
  },
};

routes.appInvestigator = {
  url: '/investigator?query&environment&source&from&to&timeframe&sort',
  reloadOnSearch: false,
  views: {
    widget: {
      templateUrl: 'investigator/investigator',
      controller: 'InvestigatorCtrl',
      controllerAs: 'vm',
    },
  },
};

routes.appInvestigatorSharesList = {
  url: '/:incidentId/shares-list',
  onEnter: (ModalService, $state, $transition$) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'overview/incidents/actions/share/shares-list/shares_list',
      controller: 'SharesListController',
      controllerAs: 'vm',
      bindToController: true,
      locals: { incidentId: $transition$.params().incidentId },
    }).finally(() => $state.go('^', {}, { notify: false }));
  },
};

routes.appInvestigatorComment = {
  url: '/:incidentId/comment',
  onEnter: (ModalService, $state, $stateParams) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'overview/incidents/actions/comment/popup/comment_popup',
      controller: 'CommentPopupController',
      controllerAs: 'vm',
      bindToController: true,
      locals: { incidentId: $stateParams.incidentId },
    }).finally(() => $state.go('^', {}, { notify: false }));
  },
};

routes.appInvestigatorSnooze = {
  url: '/:incidentId/snooze',
  onEnter: (ModalService, $state, $transition$) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'overview/incidents/actions/snooze/snooze_popup',
      controller: 'SnoozePopupController',
      controllerAs: 'vm',
      bindToController: true,
      resolve: {
        resolvedIncidentIds: () => [$transition$.params().incidentId],
      },
    }).finally(() => $state.go('^', {}, { notify: false }));
  },
};

routes.appReports = {
  abstract: true,
  url: '/reports',
  views: {
    widget: {
      templateUrl: 'reports/reports',
      controller: 'ReportsController',
    },
  },
};

routes.appReportsDetail = {
  abstract: true,
  url: '/detail',
  views: {
    content: {
      templateUrl: 'reports/detail/detail',
    },
  },
};

routes.appReportsDetailShowReport = {
  url: '/:reportId',
  views: {
    list: {
      templateUrl: 'reports/detail/reports_list/reports_list',
      controller: 'ReportsListController',
      controllerAs: 'reportsListController',
    },
    report: {
      templateUrl: 'reports/detail/report_widgets/report_widgets',
      controller: 'ReportWidgetsController',
      controllerAs: 'reportWidgetsController',
    },
  },
};

routes.previewReport = {
  url: '/report/:reportSnapshotId',
  views: {
    widget: {
      templateUrl: 'preview/report/report_preview',
      controller: 'ReportPreviewController',
      controllerAs: 'reportPreviewController',
    },
  },
};

routes.appDashboards = {
  url: '/dashboards?dark&fullscreen',
  params: {
    dark: null,
    fullscreen: null,
  },
  views: {
    widget: {
      templateUrl: 'dashboard/dashboards',
      controller: 'DashboardsCtrl',
      controllerAs: 'vm',
    },
  },
};

routes.appDashboardsNew = {
  url: '/new',
  onEnter: (ModalService, $transition$) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'dashboard/dashboard_editor_popup',
      controller: 'DashboardEditorPopupController',
      controllerAs: 'vm',
      fullscreen: true,
      clickOutsideToClose: false,
      escapeToClose: false,
      onShowing: (scope, element) => {
        element.addClass('dashboard-editor-popup');
      },
      bindToController: true,
      locals: {
        status: 'new',
        dashboardId: $transition$.params().dashboardId,
      },
    });
  },
  params: { status: 'new' },
};

routes.appDashboardsDisplay = {
  url: '/:dashboardId',
  templateUrl: 'dashboard/dashboard_display',
  controller: 'DashboardDisplayController',
  controllerAs: 'vm',
};

routes.appDashboardsDisplayEdit = {
  url: '/edit',
  onEnter: (ModalService, $transition$) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'dashboard/dashboard_editor_popup',
      controller: 'DashboardEditorPopupController',
      controllerAs: 'vm',
      fullscreen: true,
      clickOutsideToClose: false,
      escapeToClose: false,
      onShowing: (scope, element) => {
        element.addClass('dashboard-editor-popup');
      },
      bindToController: true,
      locals: {
        status: 'edit',
        dashboardId: $transition$.params().dashboardId,
      },
    });
  },
  params: { status: 'edit' },
};

routes.appDashboardsDisplayDuplicate = {
  url: '/duplicate',
  onEnter: (ModalService, $transition$) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'dashboard/dashboard_editor_popup',
      controller: 'DashboardEditorPopupController',
      controllerAs: 'vm',
      fullscreen: true,
      clickOutsideToClose: false,
      escapeToClose: false,
      onShowing: (scope, element) => {
        element.addClass('dashboard-editor-popup');
      },
      bindToController: true,
      locals: {
        status: 'duplicate',
        dashboardId: $transition$.params().dashboardId,
      },
    });
  },
  params: { status: 'duplicate' },
};

routes.appDashboardsDisplayDelete = {
  url: '/delete',
  onEnter: (ModalService) => {
    'ngInject';

    ModalService.showModal({
      templateUrl: 'dashboard/dashboard_delete',
      controller: 'DashboardDeleteController',
      controllerAs: 'vm',
      clickOutsideToClose: false,
      escapeToClose: false,
    });
  },
};

routes.appTopology = {
  url: '/topology?incidentId',
  params: {
    incidentId: null,
  },
  views: {
    widget: {
      templateUrl: 'topology/topology',
      controller: 'TopologyController',
      controllerAs: 'vm',
      bindToController: true,
    },
  },
};

module.exports = routes;
