const { react2angular } = require('react2angular');
const {
  default: QueryHelper,
} = require('../../../../workspaces/apps/unified-search/src/components/QueryHelper');

angular
  .module('bp.widgets')
  .component(
    'bpUnifiedSearchQueryHelper',
    react2angular(QueryHelper, [
      'store',
      'handleSearch',
      'incidentTags',
      'error',
      'getRecentSearches',
      'disabled',
      'useIncidentTag',
    ])
  );
angular.module('bp.widgets').directive('unifiedSearchQueryHelper', unifiedSearchQueryHelper);

function unifiedSearchQueryHelper() {
  return {
    restrict: 'E',
    scope: {
      environmentId: '=',
      incident: '=',
      tagDefinitions: '=',
      notification: '=',
      disabled: '=',
    },
    templateUrl: 'widgets/bp_unified_search_query_helper/bp_unified_search_query_helper',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(
    $ngRedux,
    pubSubService,
    BPQLService,
    BpqlUtils,
    LabelsStore,
    $scope,
    stateService,
    InvestigatorService,
    UserFeatureTogglesService
  ) {
    const vm = this;
    const envId = stateService.getSelectedEnvironmentId();

    vm.$onInit = () => {
      getMultipleIncidentTagDefinitions();
      vm.store = $ngRedux;
      vm.handleSearch = search;
      vm.getRecentSearches = InvestigatorService.getRecentSearches;
      vm.incidentsSearchNotification = null;
      updateValues();
      pubSubService.on('incidentTagsDefinitions.updated', updateValues, $scope);
      vm.useIncidentTag = UserFeatureTogglesService.getToggle('enable_namespace_for_search');
    };

    function updateValues() {
      vm.incidentTags = LabelsStore.getDefinitions() || [];
    }

    function getMultipleIncidentTagDefinitions() {
      if (envId != null) {
        return LabelsStore.getMultipleIncidentTagDefinitions(envId);
      }
    }

    function search(searchValue) {
      let bpqlQuery = '';
      let bpqlQueryString = '';
      const parser = BPQLService.buildParserFromGrammer();
      try {
        bpqlQueryString = BpqlUtils.formatBpqlQuery(vm.assignee, searchValue);
        bpqlQuery = parser(bpqlQueryString);
        vm.incidentsSearchNotification = null;
      } catch (e) {
        e.queryText = searchValue;
        vm.incidentsSearchNotification = {
          type: 'error',
          msg: e.message,
          cls: 'error',
        };
        throw e;
      }

      pubSubService.broadcast('investigator.queryHelperSearch', searchValue);
    }
  }
}
