import { AllSizes, Text } from '@bp/kung-fu';
import React from 'react';
import styled from 'styled-components';

import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';

const Separator = styled(Text)`
  margin: 0px 5px;
`;

interface IProps {
  title: string;
  username?: string;
  date: string;
}

export function UserActivity({ title, username, date }: IProps): JSX.Element {
  return (
    <SettingsInfoScreen.ActivityRow
      title={title}
      value={(
        <>
          {username && (
          <>
            <Text weight="bold" size={AllSizes.SMALL}>{username}</Text>
            <Separator size={AllSizes.SMALL}>•</Separator>
          </>
          )}
          {username && <Text>{date}</Text>}
          {!username && <Text weight="bold" size={AllSizes.SMALL}>{date}</Text>}
        </>
    )}
    />
  );
}

export default UserActivity;
