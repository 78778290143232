import { Button, ButtonVariants, SwitchIcon } from '@bp/kung-fu';
import * as colors from '@bp/pastel/colors';
import result from 'lodash/result';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import integrationsSelectors from '../../../../../../app/react/common/integrations/selectors';
import actions from '../../../../../../app/react/modules/settings/alert_enrichment/actions';
import initPreviewColumns, {
  getReferenceTagNames,
  normalizeName,
} from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/initPreviewColumns';
import selectors from '../../../../../../app/react/modules/settings/alert_enrichment/selectors';
import { TagType } from '../../../types';
import { ContentPreview } from '../Modal/ContentPreview';
import { Alert, PreviewRule, TagValue } from '../PreviewTags/types';
import { PreviewHeader } from './AlertTagPreview.style';
import AlertTagPreviewContent from './AlertTagPreviewContent';
import AlertTagPreviewSubheader from './AlertTagPreviewSubheader';

interface IProps {
  tagName: string;
  loadPreview: (id: string) => void;
}

export function AlertTagPreview({ tagName, loadPreview }: IProps): JSX.Element {
  const dispatch = useDispatch();
  useEffect(() => function cleanup() {
    dispatch(actions.clearPreviewAlerts());
  }, []);

  const integrations = useSelector(integrationsSelectors.getAllIntegrationOptions);
  const previewAlerts: Alert[] | undefined = useSelector(selectors.getPreviewAlerts);
  const previewLoading: boolean = useSelector(selectors.getPreviewLoading);
  const previewRule: PreviewRule | undefined = useSelector(selectors.getPreviewRule);
  const uniquePreviewTags: TagValue[] | undefined = useSelector(selectors.getUniquePreviewTags);

  const [columnWidths, setColumnWidths] = useState({});
  const [columns, setColumns] = useState(
    initPreviewColumns({
      previewAlerts,
      previewRule,
      tagName,
      integrations,
      columnWidths,
    }),
  );
  const [tagsView, setTagsView] = useState(false);

  useEffect(() => {
    if (!previewAlerts) {
      setColumns([]);
    } else if (!columns.length) {
      setColumns(
        initPreviewColumns({
          previewAlerts,
          previewRule,
          tagName,
          integrations,
          columnWidths: previewRule ? columnWidths[previewRule.id] : {},
        }),
      );
      setColumnWidths(previewRule && columnWidths[previewRule.id] ? columnWidths : {});
    }
  }, [previewAlerts, previewRule]);

  const onColumnsChanged = (curColumns): void => {
    if (!previewRule) {
      return;
    }

    const updatedColumnWidths = {
      [previewRule.id]: curColumns.reduce(
        (acc, { id, width }) => ({
          ...acc,
          [id]: width,
        }),
        {},
      ),
    };
    setColumns(curColumns);
    setColumnWidths(updatedColumnWidths);
  };

  const getTagStyle = ({
    columnId, primaryTagColor, referenceTagColor, additionalStyles = {},
  }): object => {
    const { source, type } = (previewRule || {});
    const referenceTagNames = getReferenceTagNames(previewAlerts);
    const isReference: boolean = type === TagType.Composition && referenceTagNames.includes(columnId);
    if (
      normalizeName(columnId) === normalizeName(tagName)
      || columnId.toLowerCase() === result(source, 'toLowerCase', '')
      || isReference
    ) {
      const backgroundColor = normalizeName(columnId) === normalizeName(tagName) ? primaryTagColor : referenceTagColor;
      return {
        style: {
          backgroundColor,
          ...additionalStyles,
        },
      };
    }
    return {};
  };

  const getColumnHeaderStyle = (columnId: string): object => getTagStyle({
    columnId,
    primaryTagColor: colors.bp_soft_purple,
    referenceTagColor: colors.bp_gray_02,
    additionalStyles: { color: colors.bp_gray_07 },
  });

  const getColumnStyle = (columnId: string): object => getTagStyle({
    columnId,
    primaryTagColor: colors.bp_pale_purple,
    referenceTagColor: colors.bp_gray_01,
  });

  const toggleView = (): void => {
    setTagsView(!tagsView);

    if (previewRule) {
      loadPreview(previewRule.id);
    }
  };

  const viewText: string = tagsView ? 'Switch to alerts view' : 'Switch to tags view';

  return (
    <ContentPreview
      header={(
        <PreviewHeader>
          <Button
            icon={<SwitchIcon />}
            onClick={toggleView}
            variant={ButtonVariants.LINK}
            disabled={!previewAlerts || !previewAlerts.length}
          >
            {viewText}
          </Button>
        </PreviewHeader>
      )}
      subheader={(
        <AlertTagPreviewSubheader
          previewRule={previewRule}
          previewAlerts={previewAlerts}
          integrations={integrations}
          tagsView={tagsView}
          uniquePreviewTags={uniquePreviewTags}
          previewLoading={previewLoading}
        />
      )}
      loading={previewLoading}
      emptyPreviewHeader={previewAlerts ? 'No matches' : 'No preview yet'}
      emptyPreviewMessage={
        previewAlerts
          ? 'Adjust form definitions to preview values from matching alerts'
          : 'Fill out the form to see values from matching alerts'
      }
      unpadded
    >
      {previewAlerts && (
        <AlertTagPreviewContent
          previewRule={previewRule}
          tagsView={tagsView}
          uniquePreviewTags={uniquePreviewTags}
          columns={columns}
          previewAlerts={previewAlerts}
          onColumnsChanged={onColumnsChanged}
          getColumnStyle={getColumnStyle}
          getColumnHeaderStyle={getColumnHeaderStyle}
        />
      )}
    </ContentPreview>
  );
}

export default AlertTagPreview;
