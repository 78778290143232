import bpFetch from 'common/utils/http/fetcher';
import { cacheTillFinished } from 'common/utils/http/cache';

const permissionsRoute = '/resources/v2.0/permissions';

export function createPermission(permission) {
  return bpFetch({
    url: permissionsRoute,
    method: 'POST',
    data: permission,
  }).then((res) => res.data.createdPermission);
}

export const getPermissions = cacheTillFinished(() => {
  return bpFetch({ url: permissionsRoute }).then((res) => res.data);
});
