import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/combineLatest';
import { BamNotificationStatusOptions } from '@bp/bam';
import { ignoreElements, Observable } from 'rxjs';
import { concat, map, pipe, uniqBy, filter } from 'lodash/fp';
import {
  getApiKeys as getApiKeysRequest,
  updateApiKey as updateApiKeyRequest,
  deleteApiKey as deleteApiKeyRequest,
  restoreApiKey as restoreApiKeyRequest,
} from 'common/endpoints/api-keys';
import { showSuccessMessage, showFailureMessage } from 'react/layout/settings/feedback';
import { actionTypes as commonUserActionTypes } from 'react/common/enriched_users';
import actionTypes from './actionTypes';
import actions from './actions';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION, DROPDOWN_FILTER_STATUS_ITEMS } from './constants';

const showSuccessMessageForApiKey = (
  action,
  status = BamNotificationStatusOptions.ACTION_SUCCESS,
  actionButton
) => showSuccessMessage('API key', action, status, actionButton);

export const showFailureMessageForApiKey = (action) => showFailureMessage('API key', action);

const getApiKeyStatus = (apiKey, alive) => {
  if (!apiKey.active) {
    return { status: 'off', statusText: 'Inactive' };
  }
  return alive
    ? { status: 'ok', statusText: 'Active' }
    : { status: 'critical', statusText: 'Error' };
};

const loadApiKeys = (action$) =>
  action$.ofType(actionTypes.LOAD_API_KEYS).mergeMap(async () => {
    const apiKeys = await getApiKeysRequest();
    return actions.loadApiKeysSuccess(apiKeys);
  });

const createFiltersDropDownItems = (action$) =>
  action$.ofType(actionTypes.ENRICH_API_KEYS_SUCCESS).map(({ payload }) => {
    const userNamesArray = pipe(
      filter((apiKey) => !apiKey.user.deleted && apiKey.user._id && apiKey.user.name),
      map((apiKey) => apiKey.user),
      uniqBy((user) => user._id),
      map((user) => ({ text: user.name, value: user._id, key: user._id })),
      Object.values,
      concat([DROPDOWN_FILTERS_ALL_ITEM_OPTION.name])
    )(payload);
    return actions.createFiltersDropDownItemsSuccess({
      name: [...userNamesArray],
      status: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.status, ...DROPDOWN_FILTER_STATUS_ITEMS],
    });
  });

const enrichAPIKeys = (action$) =>
  Observable.combineLatest(
    action$.ofType(actionTypes.LOAD_API_KEYS_SUCCESS),
    action$.ofType(commonUserActionTypes.LOAD_ENRICHED_USERS_SUCCESS),
    ({ payload: apiKeys }, { payload: enrichedUsers }) => {
      if (!apiKeys) {
        return ignoreElements();
      }

      const enrichedApiKeys = apiKeys.map((apiKey) => {
        let associatedUser = enrichedUsers.find((user) => user.id === apiKey.user_id);
        const alive = associatedUser != null;
        associatedUser = {
          name: apiKey.user.name || apiKey.name.username,
          deleted: apiKey.user.deleted,
          ...associatedUser,
        };

        const createdByUser = enrichedUsers.find((user) => user.id === apiKey.created_by);
        const updatedByUser = apiKey.updated_by
          ? enrichedUsers.find((user) => user.id === apiKey.updated_by)
          : enrichedUsers.find((user) => user.id === apiKey.created_by);
        const updatedAt = apiKey.updated_at ? apiKey.updated_at : apiKey.created_at;
        return {
          ...apiKey,
          user: associatedUser,
          alive: alive,
          createdByUser: createdByUser,
          updatedByUser: updatedByUser,
          updated_at: updatedAt,
          ...getApiKeyStatus(apiKey, alive),
        };
      });

      return actions.enrichApiKeysSuccess(enrichedApiKeys);
    }
  );

const updateApiKey = (action$) =>
  action$.ofType(actionTypes.UPDATE_API_KEY).mergeMap(async ({ payload }) => {
    try {
      await updateApiKeyRequest({ ...payload });
      showSuccessMessageForApiKey('updated');
    } catch (e) {
      showFailureMessageForApiKey('update');
    }
    return actions.loadApiKeys();
  });

const deleteApiKey = (action$, state$) =>
  action$.ofType(actionTypes.DELETE_API_KEY).mergeMap(async ({ payload: apiKeyId }) => {
    try {
      await deleteApiKeyRequest(apiKeyId);
      showSuccessMessageForApiKey('deleted', BamNotificationStatusOptions.DELETE_SUCCESS, {
        text: 'Undo',
        onClick: () => state$.dispatch(actions.restoreApiKey(apiKeyId)),
      });
    } catch (e) {
      showFailureMessageForApiKey('delete');
    }
    return actions.loadApiKeys();
  });

const restoreApiKey = (action$) =>
  action$.ofType(actionTypes.RESTORE_API_KEY).mergeMap(async ({ payload: apiKeyId }) => {
    try {
      await restoreApiKeyRequest(apiKeyId);
      showSuccessMessageForApiKey('restored');
    } catch (e) {
      showFailureMessageForApiKey('restore');
    }
    return actions.loadApiKeys();
  });

export default combineEpics(
  loadApiKeys,
  enrichAPIKeys,
  updateApiKey,
  deleteApiKey,
  restoreApiKey,
  createFiltersDropDownItems
);
