angular
  .module('bigpanda.backend.services')
  .service('SessionTimeoutWarningService', SessionTimeoutWarning);

function SessionTimeoutWarning(
  $rootScope,
  $interval,
  $http,
  $timeout,
  ModalService,
  cookieStore,
  Config
) {
  const pollInterval = 60 * 1000;
  let warningTimer;
  let timeoutPromise;
  this.warningDisplayed = false;
  this.start = start;
  this.renewToken = renewToken;
  this.startReoccurringTokenRenewal = startReoccurringTokenRenewal;
  this.stopReoccurringTokenRenewal = stopReoccurringTokenRenewal;
  this.resetWarningDisplay = resetWarningDisplay;

  function renewToken() {
    return $http.post('/web/renewToken').then((res) => res.data);
  }

  function startReoccurringTokenRenewal(scope) {
    timeoutPromise = $timeout(() => renewToken(), pollInterval);
    timeoutPromise.then(startReoccurringTokenRenewal);

    scope.$on('$destroy', () => {
      stopReoccurringTokenRenewal();
    });
  }

  function stopReoccurringTokenRenewal() {
    $timeout.cancel(timeoutPromise);
  }

  function resetWarningDisplay() {
    this.warningDisplayed = false;
  }

  function showWarningExpiryModal() {
    this.warningDisplayed = true;
    const scope = $rootScope.$new();
    angular.extend(scope, {
      dialogTitle: 'Session Timeout',
      titleIconClass: 'bp-icon-clock',
      noButtons: true,
      noCloseBtn: true,
    });

    const modalParams = {
      scope: scope,
      escapeToClose: false,
      clickOutsideToClose: false,
      templateUrl: 'session/warning/session.warning',
      controller: 'SessionWarningCtrl',
      controllerAs: 'vm',
    };

    ModalService.showModal(modalParams);
  }

  function getCookieSuffix() {
    return Config.cookiesSuffix ? `_${Config.cookiesSuffix}` : '';
  }

  function start() {
    const sessionWarningUnix = parseInt(cookieStore.getCookie(`sw${getCookieSuffix()}`));
    const self = this;

    if (warningTimer) {
      $interval.cancel(warningTimer);
    }

    warningTimer = $interval(() => {
      const updatedSessionWarningTime = parseInt(cookieStore.getCookie(`sw${getCookieSuffix()}`));
      $interval.cancel(warningTimer);
      return updatedSessionWarningTime > sessionWarningUnix
        ? start.call(self)
        : showWarningExpiryModal.call(self);
    }, (sessionWarningUnix - moment().unix()) * 1000);
  }

  return this;
}
