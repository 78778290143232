export default {
  gettingStartedButtonText: 'Get Started',
  drawerAriaDescribedby: 'On Boarding Drawer',
  drawerAriaLabelledby: 'On Boarding Drawer',
  drawerHeaderDescription: 'Everything you need to start monitoring incidents',
  drawerHeaderTitle: 'Get started',
  maturity_levels: {
    starter: 'starter',
    advanced: 'advanced',
  },
  onboardingSteps: {
    environment: 'Environment creation',
    alert_enrichment: 'Enrich your alerts',
    alert_filtering: 'Alert filtering',
    alert_correlation: 'Correlation patterns',
    incident_enrichment: 'Incident enrichment',
    autohShare: 'Autoshare',
    correlation_patterns_basic: 'Correlation patterns basic',
    environment_basic: 'Environment creation basic',
  },
};
