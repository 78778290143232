import BPQL from '@bp/bpql';
import last from 'lodash/last';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

export function generateQuery(pureQuery, sourceSystems = [], sourceTags) {
  const sourceSystemQueries = map(sourceSystems, (sourceSystem) =>
    getSourceQuery(sourceSystem.value)
  );
  return joinQueryAndSystem(pureQuery, sourceSystemQueries, sourceTags);
}

function getSourceQuery(sourceSystem) {
  if (!sourceSystem) throw new Error('Source system cannot be undefined');
  if (sourceSystem === '*') {
    return { type: 'regex', value: '*' };
  } else if (last(sourceSystem) === '*') {
    return { type: 'regex', value: `${sourceSystem}` };
  }
  return sourceSystem;
}

function joinQueryAndSystem(pureQuery, sourceSystemQueries, sourceTags) {
  if (isEmpty(pureQuery) && !sourceTags) {
    return { IN: ['source_system', sourceSystemQueries] };
  } else if (isEmpty(pureQuery) && sourceTags) {
    const sourceTagRegex = sourceTags.map((tag) => ({ '=': [tag, { type: 'regex', value: '*' }] }));
    return {
      AND: [{ IN: ['source_system', sourceSystemQueries] }, ...sourceTagRegex],
    };
  }

  const sourceTagRegex = sourceTags
    ? sourceTags.map((tag) => ({ '=': [tag, { type: 'regex', value: '*' }] }))
    : [];
  return {
    AND: [pureQuery, { IN: ['source_system', sourceSystemQueries] }, ...sourceTagRegex],
  };
}

export function buildParserAndGenerateQuery(displayQuery, sourceSystems, sourceTags) {
  try {
    const query = displayQuery ? BPQL.buildParserFromGrammer('correlation')(displayQuery) : {};
    return generateQuery(
      query,
      sourceSystems.map((sourceSystem) => ({ value: sourceSystem })),
      sourceTags
    );
  } catch (e) {
    return null;
  }
}
