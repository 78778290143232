import React from 'react';
import PropTypes from 'prop-types';
import { Button, PlusThickIcon } from '@bp/kung-fu';

const AddItemButton = ({ onClick, text, ariaLabel, forwardedRef, disabled, tooltipProps }) => {
  return (
    <Button
      variant="add-button"
      onClick={onClick}
      ariaLabel={ariaLabel}
      icon={<PlusThickIcon />}
      expand
      ref={forwardedRef}
      disabled={disabled}
      tooltipProps={tooltipProps}
    >
      {text}
    </Button>
  );
};

AddItemButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  ariaLabel: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  disabled: PropTypes.bool,
  tooltipProps: PropTypes.shape({
    isActive: PropTypes.bool,
    text: PropTypes.string,
  }),
};

AddItemButton.defaultProps = {
  text: 'Add Item',
};

export default AddItemButton;
