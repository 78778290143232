import {
  BamNotificationStatusOptions,
  bamUnNotify,
} from '@bp/bam';

import { showFailureMessage, showInProgressMessage, showSuccessMessage } from '../../../../../app/react/layout/settings/feedback';

export const showSuccessMessageForFilters = (
  action: string,
  toasterId: string,
  status = BamNotificationStatusOptions.ACTION_SUCCESS,
): void => {
  if (toasterId) bamUnNotify(toasterId);
  setTimeout(() => showSuccessMessage('Alert Filter', action, status), 500);
};

export const showFailureMessageForFilters = (action: string, toasterId?: string): void => {
  if (toasterId) bamUnNotify(toasterId);
  setTimeout(() => showFailureMessage('Alert Filter', action), 500);
};

export const showInProgressMessageForFilters = (
  action: string,
  status = BamNotificationStatusOptions.IN_PROGRESS,
): string => showInProgressMessage('Alert Filter', action, status);
