import PropTypes from 'prop-types';
import { path as prevPath } from '../constants';

export const name = 'correlationPatternsList';
export const path = `${prevPath}.${name}`;
export const CREATED_SORT_MODE = {
  text: 'Created',
  field: 'metadata.created_time',
  ascending: false,
};
export const UPDATED_SORT_MODE = {
  text: 'Updated',
  field: 'metadata.updated_time',
  ascending: false,
  isDefault: true,
};
export const TIME_WINDOW_SORT_MODE = { text: 'Time Window', field: 'window', ascending: true };
export const SORT_MODES = [UPDATED_SORT_MODE, TIME_WINDOW_SORT_MODE, CREATED_SORT_MODE];

export const DEFAULT_SORT_MODE = UPDATED_SORT_MODE;

export const SortShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool,
});
