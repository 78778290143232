const flattenObjectKeys = (filter) => {
  const keys = [];
  const flattenObjectKeysRecursive = (object) => {
    Object.keys(object).forEach((key) => {
      keys.push(key);
      const value = object[key];
      if (typeof value === 'object') {
        flattenObjectKeysRecursive(value);
      }
    });
  };

  flattenObjectKeysRecursive(filter);

  return keys;
};

const isEnvironmentFilteredByIncidentTags = (environment, searchWithIncidentTagsFt) => {
  if (environment && !searchWithIncidentTagsFt) {
    const filterKeys = flattenObjectKeys(environment.filter);
    return filterKeys.some((key) => /^incident\./.test(key));
  }

  return false;
};

export default isEnvironmentFilteredByIncidentTags;
