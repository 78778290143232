angular.module('bigpanda').controller('DefaultStateController', DefaultStateController);

function DefaultStateController(
  $state,
  PersonalSettingsStore,
  BrowserCompatibilityService,
  previousStateService,
  UserIntegrationsStore,
  UserIntegrationsUtils,
  FullPageService,
  BootstrapUtilsService,
  $location,
  UserFeatureTogglesService
) {
  const vm = this;

  vm.incidentFullScreen = $location.search().incidentfullscreen;

  vm.isIncidentsState = BootstrapUtilsService.isIncidentsState();

  PersonalSettingsStore.getUser().then((user) => {
    let prevState = previousStateService.getPreviousState();
    let fullpage = false;
    if (prevState && prevState.name) {
      fullpage = FullPageService.isInFullPage(prevState.name);
    }

    if (fullpage) {
      return previousStateService.goToPreviousState();
    } else if (user.state === 'initial') {
      // First Login
      return $state.go('app.welcome.v2');
    }
    UserIntegrationsStore.getIntegrations()
      .then((integrations) =>
        determineDefaultState(
          user,
          prevState,
          UserIntegrationsUtils.hasActiveIntegrations(integrations)
        )
      )
      .catch(() => determineDefaultState(user, prevState, true));
  });

  function determineDefaultState(user, prevState, hasActiveIntegrations) {
    if (!hasActiveIntegrations && BrowserCompatibilityService.isMobile()) {
      return $state.go('app.mobile');
    }
    const state = user.state;

    if (state === 'welcome') {
      PersonalSettingsStore.updateUser({ state: 'valid' });
    }

    previousStateService.goToPreviousState();
  }
}
