angular.module('bigpanda.backend.services').factory('WidgetsBackendService', ($http) => {
  return {
    getWidgets: getWidgets,
    getMultipleWidgets: getMultipleWidgets,
    getWidget: getWidget,
    createWidget: createWidget,
    updateWidget: updateWidget,
    deleteWidget: deleteWidget,
  };

  function getWidgets() {
    return $http.get('/web/widgets').then((res) => res.data.item.widgets);
  }

  function getMultipleWidgets(ids) {
    return $http.get(`/web/widgets/${ids.join(',')}`).then((res) => res.data.item.widgets);
  }

  function getWidget(id) {
    return $http.get(`/web/widgets/${id}`).then((res) => res.data.item.widget);
  }

  function createWidget(widget) {
    return $http.post('/web/widgets', widget).then((res) => res.data.item.id);
  }

  function updateWidget(id, widgetData) {
    return $http.patch(`/web/widgets/${id}`, widgetData).then((res) => res.data);
  }

  function deleteWidget(id) {
    return $http.delete(`/web/widgets/${id}`).then((res) => res.data);
  }
});
