import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './Collapsible.scss';

const ENTER_KEY_CODE = 13;
const BACKSPACE_KEY_CODE = 32;

class Collapsible extends React.PureComponent {
  constructor(props) {
    super(props);
    this.summary = React.createRef();
    this.state = {
      focus: false,
      hover: false,
      open: props.open !== undefined ? props.open : props.defaultOpen,
    };
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.summary.current.focus();
    }
  }

  componentDidUpdate() {
    if (this.props.forceFocus) {
      this.summary.current.focus();
      this.props.freeFocus();
    }
  }

  onHover = ({ type }) => this.setState({ hover: type === 'pointerover' });

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  onToggleOpen = () => {
    const { disabled, onToggle } = this.props;
    if (disabled) return;
    const nextOpen = !this.getOpen();
    onToggle(nextOpen);
    this.setState({ open: nextOpen });
  };

  onToggleOpenKeyboardInteraction = (event) => {
    if (this.isEnterEvent(event) || this.isSpaceEvent(event)) {
      this.onToggleOpen();
    }
  };

  getOpen = () => {
    const { open: propsOpen } = this.props;
    const { open: stateOpen } = this.state;
    return propsOpen !== undefined ? propsOpen : stateOpen;
  };

  isEnterEvent = (event) => event.keyCode === ENTER_KEY_CODE;

  isSpaceEvent = (event) => event.keyCode === BACKSPACE_KEY_CODE;

  hoverOut = () => this.setState({ hover: false });

  preventDefault = (e) => e.preventDefault();

  render() {
    const {
      summaryRenderer,
      arrowRenderer,
      children,
      className,
      disabled,
      'aria-label': ariaLabel,
    } = this.props;
    const { hover, focus } = this.state;
    const open = this.getOpen();
    const detailsClasses = cn([styles.details, className], {
      [styles['details-collapsed']]: !open,
    });
    const arrowClasses = cn(
      'bp-icon bp-icon-arrow-down-thick',
      styles['details-summary-icon-arrow'],
      {
        [styles['details-summary-icon-arrow--closed']]: !open,
        [styles['details-summary-icon-arrow--disabled']]: disabled,
      }
    );
    const defaultArrow = (
      <i
        className={arrowClasses}
        onClick={this.onToggleOpen}
        onKeyDown={this.onToggleOpenKeyboardInteraction}
        role="button"
        aria-label="expand"
        tabIndex={0}
      />
    );

    return (
      <details className={detailsClasses} open={open} aria-label={ariaLabel}>
        <summary
          ref={this.summary}
          className={styles['details-summary']}
          onClick={this.preventDefault}
          onPointerOver={this.onHover}
          onPointerLeave={this.onHover}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          role="button"
          aria-expanded={open}
          aria-label={ariaLabel}
        >
          <span className={styles['details-summary-context']}>
            {arrowRenderer({
              defaultArrow,
              hover,
              focus,
              open,
              onToggle: this.onToggleOpen,
            })}
          </span>
          <div className={styles['details-summary-content']}>
            {summaryRenderer({ open, hover, focus, hoverOut: this.hoverOut })}
          </div>
        </summary>
        <div className={styles['details-content']}>{open && children}</div>
      </details>
    );
  }
}

Collapsible.propTypes = {
  summaryRenderer: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  arrowRenderer: PropTypes.func,
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
  'aria-label': PropTypes.string,
  forceFocus: PropTypes.bool,
  freeFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
};

Collapsible.defaultProps = {
  className: undefined,
  open: undefined,
  arrowRenderer: ({ defaultArrow }) => defaultArrow,
  onToggle: () => {},
  defaultOpen: false,
  disabled: false,
  'aria-label': undefined,
  forceFocus: false,
  freeFocus: () => {},
  autoFocus: false,
};

export default Collapsible;
