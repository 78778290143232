angular.module('bigpanda').controller('DashboardDeleteController', DashboardDeleteController);

function DashboardDeleteController($state, $stateParams, DashboardsStore, ModalService) {
  const vm = this;
  init();
  vm.delete = deleteDashboard;
  vm.hide = hide;

  function deleteDashboard() {
    DashboardsStore.deleteDashboard($stateParams.dashboardId).then(() => {
      ModalService.hide();
      $state.go('app.dashboards');
    });
  }

  function hide() {
    ModalService.hide();
    $state.go('app.dashboards.display', { dashboardId: $stateParams.dashboardId });
  }

  function init() {
    DashboardsStore.getDashboardById($stateParams.dashboardId).then((dashboard) => {
      vm.dashboardName = dashboard.name;
    });
  }
}
