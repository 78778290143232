import { darkTheme } from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { EnrichmentRule, RuleType, Tag } from '../../../types';
import { MappingForm } from '../MappingForm';
import MultiTypeForm from '../RuleTypeForms/MultiType';
import { LoadPreview, UnifiedTag } from '../RuleTypeForms/types';
import styles from './RuleForm.scss';

interface IProps {
  rule: EnrichmentRule;
  index: number;
  unifiedTags: UnifiedTag[];
  isNewOrDuplicate: boolean;
  onLoadPreview: LoadPreview;
  funcType: RuleType;
  values: EnrichmentRule;
  tag: Tag;
}

function RuleForm(props: IProps): JSX.Element {
  const {
    rule: { type },
  } = props;

  return (
    <ThemeProvider theme={darkTheme}>
      {type === RuleType.Mapping ? (
        <MappingForm {...props} />
      ) : (
        <div className={styles['rule-form-content']}>
          <MultiTypeForm {...props} />
        </div>
      )}
    </ThemeProvider>
  );
}

export default RuleForm;
