import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Avatar, Badge, AllSizes } from '@bp/kung-fu';
import { BamTile } from '@bp/bam';
import BpTimeSince from 'common/utils/BpTimeSince';
import UserManagementActions from '../../../actions/UserManagementActions';
import { isUserPending } from '../../../constants';
import {
  CREATED_SORT_MODE,
  LAST_SEEN_SORT_MODE,
  SortShape,
  USER_NAME_SORT_MODE,
} from '../../constants';

const generateFirstRow = (user, sortMode) => {
  const { lastLoginAtTimeStamp, createdTimestamp, name } = user;

  let timeObject = {
    title: '',
    timestamp: '',
  };

  switch (sortMode) {
    case USER_NAME_SORT_MODE:
    case LAST_SEEN_SORT_MODE:
      timeObject.title = LAST_SEEN_SORT_MODE.text;
      timeObject.timestamp = BpTimeSince(lastLoginAtTimeStamp);
      break;
    case CREATED_SORT_MODE:
      timeObject.title = CREATED_SORT_MODE.text;
      timeObject.timestamp = BpTimeSince(createdTimestamp);
      break;
  }

  return {
    title: name,
    time: timeObject,
    customParts: isUserPending(user) && [
      <Badge status={user.cls} text={user.stateText} size={AllSizes.SMALL} isCapitalized />,
    ],
  };
};

const generateSecondRow = (user) => ({
  textParts: [{ label: 'Roles', value: user.roles.map((r) => r.name) }],
  actionsBundle: <UserManagementActions user={user} />,
});

const ListItem = ({ user, sortMode, ...props }) => {
  const handleClick = () => {
    window.location.href = `/#/app/settings/user_management/${user.id}`;
  };

  return (
    <BamTile
      coverImage={<Avatar name={user.name} email={user.email} size="large" />}
      bamStatus={user.cls}
      firstRow={generateFirstRow(user, sortMode)}
      secondRow={generateSecondRow(user)}
      onClick={handleClick}
      inactive={!user.active}
      {...props}
    />
  );
};

ListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    cls: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  sortMode: SortShape.isRequired,
};

export default hot(module)(ListItem);
