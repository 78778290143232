angular.module('bigpanda').factory('IntercomLogic', IntercomLogic);

function IntercomLogic(
  $q,
  IntercomInitService,
  PersonalSettingsStore,
  pubSubService,
  Config,
  $interval,
  $location
) {
  let enabled = false;
  if (!Config.intercomAppId || $location.$$path === '/app/topology') {
    return {}; // Don't continue if no intercom id
  }

  $q.all({
    user: PersonalSettingsStore.getUser(),
    contact: PersonalSettingsStore.getContact(),
  }).then((result) => {
    enabled = result.user.intercom_user_hash;

    if (!enabled) {
      return;
    }
    const { user, contact } = result;
    const { organization } = user;
    const createdAt = user.createdAt || user.firstLoginAt || undefined;

    IntercomInitService.boot({
      email: contact.email,
      name: contact.name || contact.email,
      created_at: moment(createdAt).unix(),
      user_id: user._id.toString(),
      company: {
        company_id: organization._id.toString(),
        name: organization.name,
      },
      user_hash: user.intercom_user_hash,
    }).then(() => {
      pubSubService.broadcast('intercomLogic.initialized');
    });
  });

  function track(event, properties) {
    if (!Config.intercomAppId || !enabled) {
      return; // Don't continue if no intercom id.
    }

    IntercomInitService.track(event, properties);
  }

  function update(properties) {
    if (!Config.intercomAppId || !enabled) {
      return; // Don't continue if no intercom id.
    }

    $interval(
      () => {
        IntercomInitService.update(properties);
      },
      2000,
      1
    );
  }

  function newView() {
    track('User View');
  }

  function newAction() {
    track('User Action');
  }

  /**
   * Catch the name change
   */
  pubSubService.on('UserService.nameChanged', (event, data) => {
    IntercomInitService.update({ name: data.name });
  });

  // Views
  pubSubService.on('incidentsList.incidentSelected', () => {
    newView('IncidentSelected');
  });

  pubSubService.on('IncidentLifecycle.open', () => {
    newView('IncidentLifecycleClicked');
  });

  // Actions
  pubSubService.on('incident.shared', () => {
    newAction('IncidentEscalated');
  });

  pubSubService.on('incident.resolved', () => {
    newAction('IncidentResolved');
  });

  pubSubService.on('incident.snoozed', () => {
    newAction('IncidentSnoozed');
  });

  pubSubService.on('Intercom.show', () => {
    if (!enabled) {
      return;
    }
    IntercomInitService.show();
  });

  pubSubService.on('Intercom.showNewMessage', (event, data) => {
    if (!enabled) {
      return;
    }
    IntercomInitService.showNewMessage((data && data.message) || '');
  });

  pubSubService.on('Intercom.hide', () => {
    if (!enabled) {
      return;
    }
    IntercomInitService.hide();
  });

  pubSubService.on('Intercom.update', (event, data) => {
    update({ page: data.page });
  });

  return {
    track: track,
    update: update,
  };
}
