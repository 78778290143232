import { react2angular } from 'react2angular';
import AlertEnrichmentList from './components/AlertEnrichmentList';

const template = `<section class="bp-master-detail-view">
    <alert-enrichment-list class="left-panel" store="store" ></alert-enrichment-list>
    <div class="spacer"></div>
    <div ui-view="master-detail-info" class="master-detail-info" ></div>
  </section>`;

const routes = {
  appSettingsAlertEnrichment: {
    url: '/alert-enrichment',
    views: {
      'settings-page-view': {
        template,
        controller: ($scope, $ngRedux, UserFeatureTogglesService) => {
          'ngInject';

          if (!UserFeatureTogglesService.getToggle('frontier_alert_enrichment')) {
            window.location.href = '/#';
          }

          $scope.store = $ngRedux;
        },
      },
      'master-detail-info@app.settings.alert_enrichment': {
        template: '<tag-details-pane class="react-settings-info" store="store" />',
        controller: ($scope, $ngRedux, UserFeatureTogglesService) => {
          'ngInject';

          if (!UserFeatureTogglesService.getToggle('frontier_alert_enrichment')) {
            window.location.href = '/#';
          }

          $scope.store = $ngRedux;
        },
      },
    },
    onEnter: ($transitions, pubSubService) => {
      'ngInject';

      $transitions.onSuccess({ to: 'app.settings.alert_enrichment' }, () => {
        pubSubService.broadcast('settingsPageChanged', 'alertEnrichment');
      });
    },
  },
};

angular.module('bigpanda').component('alertEnrichmentList', react2angular(AlertEnrichmentList));

export default routes;
