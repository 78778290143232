import { combineEpics } from 'redux-observable';

import * as dataPipeLogsEndpoint from 'common/endpoints/data-pipelogs';

import actionTypes from './actionTypes';
import actions from './actions';
import { ANY_OPTION_VALUE } from './constants';

const getLogs = (action$) =>
  action$
    .ofType(actionTypes.GET_LOGS)
    .mergeMap(
      ({
        payload: { resourceId, startDate, endDate, page, per_page, filtersDropDownState, search },
      }) => {
        const { description } = filtersDropDownState;
        const filters =
          description.value === ANY_OPTION_VALUE ? '' : { message: description.value };
        return dataPipeLogsEndpoint
          .getLogs({ resourceId, startDate, endDate, page, per_page, filters, search })
          .then((data) => actions.getLogsSuccess(data))
          .catch(actions.getLogsFailure);
      }
    );

const getLogsMetadata = (action$) =>
  action$.ofType(actionTypes.GET_LOGS_METADATA).mergeMap(() => {
    return dataPipeLogsEndpoint
      .getLogsMetadata()
      .then((data) => actions.getLogsMetadataSuccess(data))
      .catch(actions.getLogsMetadataFailure);
  });

export default combineEpics(getLogs, getLogsMetadata);
