import { AllSizes, Toggle, Tooltip } from '@bp/kung-fu';
import React from 'react';
import { Field } from 'react-final-form';

import isMapping from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/isMapping';
import ruleFieldName from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/ruleFieldName';
import { EnrichmentRule } from '../../../types';

const getActivationToggleTooltipText = (isOn: boolean): string => (isOn ? 'Mark as Inactive' : 'Mark as Active');

const DISABLED_MAPPING_ELEMENT_TOOLTIP_TEXT = 'Tags created by mapping enrichment rules cannot be deleted or duplicated from this screen.'
+ 'Go to the related map to update the definitions';

interface IProps {
  rule: EnrichmentRule;
  isTagTypeDetermined: boolean;
}

export function RuleActivationToggle({ rule, isTagTypeDetermined }: IProps): JSX.Element {
  const isActivationToggleEnabled = isTagTypeDetermined && !isMapping(rule.type);

  return (
    <Field
      name={ruleFieldName(rule.id, 'active')}
      render={({ input }): JSX.Element => (
        <Tooltip
          placement="bottom"
          text={
            isMapping(rule.type)
              ? DISABLED_MAPPING_ELEMENT_TOOLTIP_TEXT
              : getActivationToggleTooltipText(input.value)
          }
        >
          {/* Collapsible, my parent, blocks some of the event handlers by using preventDefault.
            For that reason, I had to catch the click event in an hacky way.
            */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e: any): void => {
              if (!isActivationToggleEnabled) {
                return;
              }

              e.target.value = !input.value;
              input.onChange(e);
            }}
          >
            <Toggle
              name={ruleFieldName(rule.id, 'active')}
              text="Active:"
              checked={input.value}
              disabled={!isActivationToggleEnabled}
              size={AllSizes.SMALL}
            />
          </span>
        </Tooltip>
      )}
      subscription={{
        value: true,
        dirty: true,
        touched: true,
      }}
    />
  );
}

export default RuleActivationToggle;
