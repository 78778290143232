import React from 'react';
import PropTypes from 'prop-types';
import { BamCheckbox } from '@bp/bam';
import LogoutButton from './LogoutButton';
import { getRandomId } from './helpers';
import { HorizontalLayout, Content, Title, Badge, SSOForm, Steps, Step } from '../../components';

const formId = getRandomId();

class caInstructions extends React.PureComponent {
  state = {
    disabledRequestedAuthnContext: false,
  };

  setRequestedAuthnContext = (isDisabled) => {
    this.setState({ disabledRequestedAuthnContext: isDisabled });
  };

  additionalCheckboxField = () => (
    <BamCheckbox label="Disable RequestedAuthnContext" onClick={this.setRequestedAuthnContext} />
  );

  configureProvider = (values) => {
    this.props.configureProvider({
      ...values,
      disableRequestedAuthnContext: this.state.disabledRequestedAuthnContext,
    });
  };

  render() {
    const { updateAndLogout, isConfigurationSent } = this.props;
    return (
      <div>
        <HorizontalLayout>
          <Badge>2</Badge>
          <Content>
            <Title>Upload CA Single Sign On Information to BigPanda</Title>
            <Steps>
              <Step>Copy the relevant values to the following form:</Step>
            </Steps>
            <SSOForm
              providerName="CA Single Sign On"
              onSubmit={this.configureProvider}
              id={formId}
              renderAdditionalFields={this.additionalCheckboxField}
            />

            <LogoutButton updateAndLogout={updateAndLogout} disabled={!isConfigurationSent} />
          </Content>
        </HorizontalLayout>
      </div>
    );
  }
}

caInstructions.propTypes = {
  configureProvider: PropTypes.func.isRequired,
  updateAndLogout: PropTypes.func.isRequired,
  isConfigurationSent: PropTypes.bool.isRequired,
};

export default caInstructions;
