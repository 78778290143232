import actionTypes from './actionTypes';

const actions = {
  getIncidentTags: (payload) => ({ type: actionTypes.GET_INCIDENT_TAGS, payload: payload }),
  getIncidentTagsSuccess: (payload) => ({
    type: actionTypes.GET_INCIDENT_TAGS_SUCCESS,
    payload: payload,
  }),
  getIncidentTagsFailure: (payload) => ({
    type: actionTypes.GET_INCIDENT_TAGS_FAILURE,
    payload: payload,
  }),
  setTagValueForIncident: (payload) => ({
    type: actionTypes.SET_TAG_VALUE_FOR_INCIDENT,
    payload: payload,
  }),
  resetLoadingForIncident: (payload) => ({
    type: actionTypes.RESET_LOADING_FOR_INCIDENT,
    payload: payload,
  }),
  setTagValueForIncidentSuccess: (payload) => ({
    type: actionTypes.SET_TAG_VALUE_FOR_INCIDENT_SUCCESS,
    payload: payload,
  }),
  setTagValueForIncidentFailure: (payload) => ({
    type: actionTypes.SET_TAG_VALUE_FOR_INCIDENT_FAILURE,
    payload: payload,
  }),
  removeTagValueFromIncident: (payload) => ({
    type: actionTypes.REMOVE_TAG_VALUE_FROM_INCIDENT,
    payload: payload,
  }),
  removeTagValueFromIncidentSuccess: (payload) => ({
    type: actionTypes.REMOVE_TAG_VALUE_FROM_INCIDENT_SUCCESS,
    payload: payload,
  }),
  removeTagValueFromIncidentFailure: (payload) => ({
    type: actionTypes.REMOVE_TAG_VALUE_FROM_INCIDENT_FAILURE,
    payload: payload,
  }),
  setEditedTagId: (payload) => ({ type: actionTypes.SET_EDITED_TAG_ID, payload }),
  createAiAnalysis: (payload) => ({
    type: actionTypes.CREATE_AI_ANALYSIS,
    payload,
  }),
  createAiAnalysisSuccess: (payload) => ({
    type: actionTypes.CREATE_AI_ANALYSIS_SUCCESS,
    payload,
  }),
  createAiAnalysisFailure: (payload) => ({
    type: actionTypes.CREATE_AI_ANALYSIS_FAILURE,
    payload,
  }),
};

export default actions;
