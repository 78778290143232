import capitalize from 'lodash/capitalize';
const { bigPandaUserName } = require('common/constants');

angular
  .module('bigpanda')
  .controller('ManualCorrelationEventController', ManualCorrelationEventController);

function ManualCorrelationEventController($scope, UsersStore) {
  $scope.change = $scope.event._source.params.change;
  $scope.matchCertainty = capitalize($scope.change.relatedMetadata.matchCertainty);
  const suggestedByBP = $scope.change.relatedMetadata.suggested_by_bigpanda;
  if (!suggestedByBP) {
    UsersStore.getUserById($scope.event._source.params.userId).then((user) => {
      $scope.event.performer = user.name;
    });
  } else {
    $scope.event.performer = bigPandaUserName;
  }
}
