import pick from 'lodash/pick';
require('jquerycookie');

module.exports = angular.module('bigpanda.cookies', []).factory('cookieStore', cookieStore);

function cookieStore() {
  return {
    getCookie: (name, json) => {
      const value = $.cookie(name);

      if (typeof value === 'undefined' || value === null) {
        return null;
      }

      return json ? JSON.parse(value) : value;
    },

    removeCookie: (name, path, domain) => {
      $.removeCookie(name, { path: path, domain: domain });
    },

    putCookie: (name, value, json, properties) => {
      const relevantProperties = pick(properties, ['path', 'domain', 'expires', 'secure']);
      $.cookie(name, json ? JSON.stringify(value) : value, relevantProperties);
    },
  };
}
