angular
  .module('bigpanda.reports')
  .controller('IncidentToggleController', function IncidentToggleController($scope) {
    var vm = this;
    vm.includeFlapping = !$scope.data.states
      ? true
      : $scope.data.states.indexOf('NOT flapping') === -1;

    vm.toggleChanged = function () {
      if (!$scope.data.states) {
        $scope.data.states = [];
      }
      if (!vm.includeFlapping) {
        if ($scope.data.states.indexOf('NOT flapping') === -1) {
          $scope.data.states.push('NOT flapping');
        }
      } else {
        var position = $scope.data.states.indexOf('NOT flapping');
        if (position !== -1) {
          $scope.data.states.splice(position, 1);
        }
      }
    };
  });
