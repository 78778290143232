import React from 'react';

import { TAG_TYPE } from '../../../../../../../app/react/modules/settings/alert_enrichment/constants';
import { EnrichmentRule } from '../../../../types';
import CompositionForm from '../Composition';
import ExtractionForm from '../Extraction';
import { LoadPreview, UnifiedTag } from '../types';

interface IProps {
  type: string;
  onLoadPreview: LoadPreview;
  rule: EnrichmentRule;
  unifiedTags: UnifiedTag[];
}

export function FormByType({
  type, onLoadPreview, rule, unifiedTags,
}: IProps): JSX.Element {
  return type.toLowerCase() === TAG_TYPE.Extraction ? (
    <ExtractionForm
      rule={rule}
      onLoadPreview={onLoadPreview}
      unifiedTags={unifiedTags}
    />
  ) : (
    <CompositionForm rule={rule} onLoadPreview={onLoadPreview} />
  );
}

export default FormByType;
