export default {
  matched_patterns_label: 'Matched Patterns',
  matched_patterns_title: 'Matched Correlation Patterns',
  matched_patterns_docs_url: 'https://docs.bigpanda.io/docs/alert-correlation-logic',
  matched_patterns_modal_title: 'All alerts in this incident are correlated together based on these patterns.',
  view_matched_patterns_title: 'View Pattern',
  m_title: 'm',
  learn_more_title: 'Learn more',
  no_matched_patterns_tooltip: 'The alerts of this incident did not match any active Correlation Patterns.',
  active_matched_patterns_tooltip: 'These alerts were clustered based on 1 or more matched correlation patterns.',
  manual_matched_patterns_tooltip: 'The alerts of this incident have been manually correlated',
};
