import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/combineLatest';
import { getIntegrations } from 'common/endpoints/integrations';
import { extendDisplayedIntegrations } from 'react/common/integrations/utils';
import actionTypes from './actionTypes';
import actions from './actions';

const loadIntegrations = (action$) =>
  action$.ofType(actionTypes.LOAD_INTEGRATIONS).mergeMap(async () => {
    const rawIntegrations = await getIntegrations();

    return actions.loadIntegrationsSuccess(extendDisplayedIntegrations(rawIntegrations));
  });

export default combineEpics(loadIntegrations);
