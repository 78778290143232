import assign from 'lodash/assign';
const serverConfig = require('server-config');

const normalizedServerConfig = Object.entries(serverConfig).reduce((config, [key, value]) => {
  config[key.endsWith('mailDomain') ? 'mailDomain' : key] = value;
  return config;
}, {});

module.exports = assign(
  {},
  {
    analyticsEnabled: false,
    defaultImgLocation: 'https://s3-us-west-1.amazonaws.com/bp-app/generic-user-icon.png',
    debug: true,
    gravatarUrl: 'https://secure.gravatar.com/avatar/',
    doubleClickDifference: 500,
    gravatarMissingIconCheckInterval: 60000,
    organizationsCheckInterval: 60000,
    disconnectedNotificationTimeout: 7000,
    webSocketsReconnectTimeout: 5000,
    defaultState: 'app.default',
    intercomAppId: '3wnijal4',
    docsUrl: 'https://docs.bigpanda.io',
    universityUrl: `${serverConfig.webApiBaseUrl}/login/bp-university`,
    communityUrl: `${serverConfig.webApiBaseUrl}/login/bp-community`,
    heapanalyticsId: '2565409037',
    webSocketsPrefix: 'WS::ENVIRONMENTS',
  },
  normalizedServerConfig
);
