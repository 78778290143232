import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BamInput } from '@bp/bam';
import { ValidationError } from '../../../../../common/components';
import './welcome_form_input.scss';

const WelcomeFormInput = ({ input, meta, title }) => {
  const inputClassName = classnames({
    'WelcomeFormInput--error': !!meta.submitError,
  });

  return (
    <div className="WelcomeFormInput">
      <BamInput
        baseProps={{ type: 'text', className: inputClassName }}
        title={title}
        aria-label={title}
        input={input}
        placeholder="e.g. John Doe"
      />
      {!!meta.submitError && (
        <ValidationError active={!!meta.submitError}>{meta.submitError}</ValidationError>
      )}
    </div>
  );
};

WelcomeFormInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default WelcomeFormInput;
