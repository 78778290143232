import actionTypes from './actionTypes';
import { reqStatusType, DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';
import { baseSettingInitialState, generateBaseSettingsReducer } from '../redux.utils';

export const initialState = {
  ...baseSettingInitialState,
  updateRequestStatus: reqStatusType.NOT_INITIALIZED,
  loadRequestStatus: reqStatusType.NOT_INITIALIZED,
  incidentLabelsData: undefined,
  formErrors: undefined,
  isLoadingForSelector: false,
  filtersDropDownState: { ...DROPDOWN_FILTERS_ALL_ITEM_OPTION },
};

const baseReducer = generateBaseSettingsReducer(actionTypes, initialState);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_MULTIPLE_INCIDENT_LABELS:
      return {
        ...state,
        isLoading: true,
        loadRequestStatus: reqStatusType.LOADING,
      };
    case actionTypes.LOAD_MULTIPLE_INCIDENT_LABELS_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadRequestStatus: reqStatusType.FAILURE,
        incidentLabelsData: null,
      };
    case actionTypes.LOAD_MULTIPLE_INCIDENT_LABELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadRequestStatus: reqStatusType.SUCCESS,
        incidentLabelsData: action.payload,
      };
    case actionTypes.UPDATE_INCIDENT_LABEL:
      return {
        ...state,
        isLoading: false,
        isLoadingForSelector: true,
        updateRequestStatus: reqStatusType.LOADING,
        formErrors: action.payload,
      };
    case actionTypes.UPDATE_INCIDENT_LABEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingForSelector: false,
        updateRequestStatus: reqStatusType.SUCCESS,
        incidentLabelsData: action.payload,
        formErrors: undefined,
      };
    case actionTypes.UPDATE_INCIDENT_LABEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        updateRequestStatus: reqStatusType.FAILURE,
        formErrors: action.payload,
      };
    case actionTypes.CREATE_INCIDENT_LABEL:
      return {
        ...state,
        isLoading: false,
        updateRequestStatus: reqStatusType.LOADING,
      };
    case actionTypes.CREATE_INCIDENT_LABEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateRequestStatus: reqStatusType.CREATE_SUCCESS,
        incidentLabelsData: action.payload,
        formErrors: undefined,
      };
    case actionTypes.CREATE_INCIDENT_LABEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        updateRequestStatus: reqStatusType.FAILURE,
        formErrors: action.payload,
      };
    case actionTypes.DELETE_TAG_DEFINITION:
      return {
        ...state,
        isLoading: false,
        updateRequestStatus: reqStatusType.LOADING,
      };
    case actionTypes.DELETE_TAG_DEFINITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateRequestStatus: reqStatusType.DELETE_SUCCESS,
        incidentLabelsData: action.payload.filter((tag) => !tag.deleted),
        formErrors: undefined,
      };
    case actionTypes.DELETE_TAG_DEFINITION_FAILURE:
      return {
        ...state,
        isLoading: false,
        updateRequestStatus: reqStatusType.DELETE_FAILURE,
        formErrors: action.payload,
      };
    case actionTypes.CANCEL_LOCAL_INCIDENT_LABEL_CHANGES:
      return {
        ...state,
        isLoading: false,
        editedIncidentLabelData: null,
        formErrors: undefined,
        updateRequestStatus: reqStatusType.NOT_INITIALIZED,
      };

    case actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS:
      return {
        ...state,
        filtersDropDownItems: {
          ...action.payload,
        },
      };

    case actionTypes.UPDATE_FILTERS_DROPDOWNS:
      return {
        ...state,
        filtersDropDownState: {
          ...state.filtersDropDownState,
          ...action.payload,
        },
      };

    default:
      return baseReducer(state, action);
  }
}
