import PropTypes from 'prop-types';

const contactPropType = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  bigpanda_user_id: PropTypes.string,
});

const systemPropType = PropTypes.shape({
  name: PropTypes.string,
  parent_system_id: PropTypes.string,
  system_id: PropTypes.string,
});

const environmentPropType = PropTypes.shape({
  name: PropTypes.string,
  old_id: PropTypes.string,
});

const paramsPropType = PropTypes.shape({
  contacts: PropTypes.arrayOf(contactPropType),
});

const userPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const rulePropType = PropTypes.shape({
  inactive: PropTypes.bool,
  deleted: PropTypes.bool,
  params: paramsPropType,
  id: PropTypes.string,
  envId: PropTypes.string,
  targetSystemId: PropTypes.string,
  system: systemPropType,
  environment: environmentPropType,
});

export {
  rulePropType,
  contactPropType,
  systemPropType,
  environmentPropType,
  paramsPropType,
  userPropType,
};
