/* eslint-disable no-case-declarations */
import actionTypes from './actionTypes';
import { reqStatusType, DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';
import { normalizeLog, getDateRange } from './utils';

const defaultRange = DROPDOWN_FILTERS_ALL_ITEM_OPTION.datesRange.value;
const { from: initialFrom, to: initialTo } = getDateRange(defaultRange);

export const initialState = {
  data: [],
  total: 0,
  descriptions: [],
  page: 1,
  filtersDropDownState: { ...DROPDOWN_FILTERS_ALL_ITEM_OPTION },
  datePickerState: false,
  datePickerFrom: initialFrom,
  datePickerTo: initialTo,
  getRequestStatus: reqStatusType.NOT_INITIALIZED,
  metadataRequestStatus: reqStatusType.NOT_INITIALIZED,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LOGS:
      const { reset, filtersDropDownState, page } = action.payload;
      const basicState = {
        datePickerState: false,
        getRequestStatus: reqStatusType.LOADING,
      };
      return reset
        ? {
            ...initialState,
            ...basicState,
          }
        : {
            ...state,
            ...basicState,
            data: page === 1 ? [] : state.data,
            page,
            filtersDropDownState,
          };
    case actionTypes.GET_LOGS_SUCCESS:
      const { data, total } = action.payload;
      return {
        ...state,
        data: [...state.data, ...data.map(normalizeLog)],
        total,
        datePickerState: false,
        getRequestStatus: reqStatusType.SUCCESS,
      };
    case actionTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        data: [],
        total: 0,
        page: 1,
        datePickerState: false,
        getRequestStatus: reqStatusType.FAILURE,
      };
    case actionTypes.GET_LOGS_METADATA:
      return {
        ...state,
        descriptions: [],
        metadataRequestStatus: reqStatusType.LOADING,
      };
    case actionTypes.GET_LOGS_METADATA_SUCCESS:
      const { distinctMessages } = action.payload;
      return {
        ...state,
        descriptions: [
          DROPDOWN_FILTERS_ALL_ITEM_OPTION.description,
          ...distinctMessages.map((value) => ({
            value: value,
            text: value,
          })),
        ],
        metadataRequestStatus: reqStatusType.SUCCESS,
      };
    case actionTypes.GET_LOGS_METADATA_FAILURE:
      return {
        ...state,
        descriptions: [],
        metadataRequestStatus: reqStatusType.FAILURE,
      };
    case actionTypes.SHOW_DATE_PICKER:
      return {
        ...state,
        datePickerState: true,
      };
    case actionTypes.HIDE_DATE_PICKER:
      return {
        ...state,
        datePickerState: false,
      };
    case actionTypes.SET_CUSTOM_DATE_RANGE:
      return {
        ...state,
        datePickerFrom: action.payload.from,
        datePickerTo: action.payload.to,
      };
    default:
      return state;
  }
}
