/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import anchorme from 'anchorme';
import DOMPurify from 'dompurify';

const linkOptions = (anchorClassName) => ({
  options: {
    attributes: {
      target: '_blank',
      class: anchorClassName,
      rel: 'noreferrer noopener nofollow',
    },
  },
});

const dangerouslySetInnerHTML = (input, anchorClassName) => ({
  dangerouslySetInnerHTML: {
    __html: DOMPurify.sanitize(anchorme({ input, ...linkOptions(anchorClassName) }), {
      ALLOWED_ATTR: ['href', 'rel', 'target'],
    }),
  },
});

const Content = ({ breakLines, input, anchorClassName }) =>
  (input || '').split('\n').map((val, idx) => (
    <React.Fragment>
      <span key={val} {...dangerouslySetInnerHTML(val, anchorClassName)} />
      <br />
    </React.Fragment>
  ));

const Anchorize = ({ className, input, anchorClassName, breakLines, Wrapper }) =>
  Wrapper ? (
    <Wrapper {...(breakLines ? {} : dangerouslySetInnerHTML(input, anchorClassName))}>
      {breakLines && (
        <Content breakLines={breakLines} input={input} anchorClassName={anchorClassName} />
      )}
    </Wrapper>
  ) : (
    <span
      className={className}
      {...(breakLines ? {} : dangerouslySetInnerHTML(input, anchorClassName))}
    >
      {breakLines && (
        <Content breakLines={breakLines} input={input} anchorClassName={anchorClassName} />
      )}
    </span>
  );

Anchorize.propTypes = {
  className: PropTypes.string,
  anchorClassName: PropTypes.string,
  input: PropTypes.node.isRequired,
  breakLines: PropTypes.bool,
  Wrapper: PropTypes.node,
};

Anchorize.defaultProps = {
  className: undefined,
  anchorClassName: undefined,
};

export default Anchorize;
