angular.module('bp.widgets').directive('customTagRegexValidator', ($q, CustomTagsService) => ({
  require: 'ngModel',
  link: (scope, elm, attrs, ctrl) => {
    ctrl.$asyncValidators.customTagRegexValidator = (modelValue) => {
      ctrl.customTagRegexError = undefined;
      return CustomTagsService.validateTagRegex(modelValue)
        .then(() => true)
        .catch(() => {
          ctrl.customTagRegexError = 'Invalid regex, please recheck syntax';
          return $q.reject(ctrl.customTagRegexError);
        });
    };
  },
}));
