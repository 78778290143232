import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import permissions from '../../../../app/common/routing/permissions';
import SettingsListItemActions from '../../../../app/react/layout/settings/components/SettingsListItemActions';
import actions from '../../../../app/react/modules/settings/maintenance_plans/maintenance_plans_list/actions';
import { selectors as permissionsSelectors } from '../../../../app/react/user/permissions';
import MaintenanceModal from '../maintenance_plan_info/components/modal/MaintenanceModal';
import { semanticPlanStatus } from '../maintenance_plan_info/utils/semanticPlanStatus';
import { MaintenancePlan } from '../types/MaintenancePlan';

interface IProps {
  plan: MaintenancePlan;
}

function MaintenancePlansActions({ plan }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const userPermissions: string[] = useSelector(permissionsSelectors.getPermissionsList) || [];

  const removePlan = (): void => {
    dispatch(actions.deletePlan(plan.id));
  };
  const isInternalPlan = plan.maintenance_key.startsWith('MNT');
  const inProgress = plan.status === 'in progress';

  const hasEditPermission = userPermissions.includes(permissions.plans.update);
  const isPlanEditable = plan.status === 'planned' && isInternalPlan;
  const canEdit = hasEditPermission && isPlanEditable;

  const hasDeletePermission = userPermissions.includes(permissions.plans.delete);
  const isPlanDeletable = !inProgress && isInternalPlan;
  const canDelete = hasDeletePermission && isPlanDeletable;

  const hasCreatePermission = userPermissions.includes(permissions.plans.create);
  const canDuplicate = hasCreatePermission && isInternalPlan;

  const messages = {
    edit: `Cannot Edit ${semanticPlanStatus(plan.status, !isInternalPlan)} Plan '${plan.name}'`,
    duplicate: `Cannot Duplicate ${semanticPlanStatus(plan.status, !isInternalPlan)} Plan '${plan.name}'`,
    delete: `Cannot Delete ${semanticPlanStatus(plan.status, !isInternalPlan)} Plan '${plan.name}'`,
  };

  return (
    <SettingsListItemActions
      item={plan}
      messages={messages}
      modelName="plan"
      editProps={{
        disabled: !canEdit,
        showIfDisabled: hasEditPermission,
        modalProps: { initialValues: plan },
        modal: MaintenanceModal,
      }}
      duplicateProps={{
        disabled: !canDuplicate,
        showIfDisabled: hasCreatePermission,
        modalProps: { initialValues: plan },
        modal: MaintenanceModal,
      }}
      deleteProps={{
        disabled: !canDelete,
        showIfDisabled: hasDeletePermission,
        contentTitleType: 'string',
        action: removePlan,
      }}
    />
  );
}

export default MaintenancePlansActions;
