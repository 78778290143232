angular.module('bigpanda').service('EnvironmentGroupsService', EnvironmentGroupsService);

function EnvironmentGroupsService(
  $rootScope,
  $q,
  ModalService,
  EnvironmentGroupsStore,
  CompatibilityService
) {
  this.showEditModal = showEditModal;
  this.showDeleteModal = showDeleteModal;

  function showEditModal(environmentGroups, currentGroup) {
    const deferred = $q.defer();

    // The onSuccess method is used here, instead of just waiting on the ModalService promise,
    // because that promise will be resolved only after the modal's close animation, and we want to react here more instantly.
    // The onSuccess method will be called instantly upon closing the modal, before the animation.
    const modalParams = {
      templateUrl: 'overview/environments/groups/environment_group_popup',
      controller: 'EnvironmentsGroupPopupCtrl',
      controllerAs: 'EnvironmentsGroupVm',
      locals: {
        environmentGroup: currentGroup,
        allGroups: environmentGroups,
        onSuccess: (environmentGroup) => deferred.resolve(environmentGroup),
      },
    };

    // We can't do the same trick of the onSuccess with the cancel flow,
    // so we settle for the standard way of waiting for the modal's promise rejection.
    ModalService.showModal(modalParams).catch(() => deferred.reject());

    return deferred.promise;
  }

  function showDeleteModal(environmentGroup) {
    const scope = $rootScope.$new();
    scope.environmentGroup = environmentGroup;

    scope.delete = () => {
      EnvironmentGroupsStore.remove(environmentGroup.id);
      ModalService.hide();
    };

    scope.hide = ModalService.hide;

    const modalParams = {
      show: true,
      keyboard: CompatibilityService.canClosePopupsWithEscape(),
      templateUrl: 'overview/environments/groups/environment_group_delete',
      scope: scope,
    };

    if (!CompatibilityService.canClosePopupsWithBackdropClick()) {
      modalParams.backdrop = 'static';
    }

    return ModalService.showModal(modalParams);
  }
}
