import { react2angular } from 'react2angular';
import CorrelationPatternInfo from './components/CorrelationPatternInfo';

const routes = {};

routes.appSettingsAlertCorrelationInfoReact = {
  url: '/:correlationPatternId',
  views: {
    'master-detail-info@app.settings.alertcorrelation': {
      template:
        '<correlation-pattern-info class="react-settings-info" correlation-pattern-id="stateParams.correlationPatternId" store="store" />',
      controller: ($scope, $ngRedux, $stateParams) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
      },
    },
  },
};

angular
  .module('bigpanda')
  .component(
    'correlationPatternInfo',
    react2angular(CorrelationPatternInfo, ['correlationPatternId', 'store'])
  );

export default routes;
