import { Div, Text } from '@bp/kung-fu';
import React from 'react';

function SortTooltip(): JSX.Element {
  return (
    <Div>
      <Div>
        <Text weight="bold">Execution order</Text>
        <p>First to be executed on top.</p>
      </Div>
      <Div>
        <Text weight="bold">Execution order - descending</Text>
        <p>Last to be executed on top.</p>
      </Div>
      <Div>
        <Text weight="bold">Updated</Text>
        <p>Most recently updated on top.</p>
      </Div>
      <Div>
        <Text weight="bold">Created</Text>
        <p>Most recently created on top.</p>
      </Div>
      <Div>
        <Text weight="bold">NumTexter of Items</Text>
        <p>Most enrichment rules on top.</p>
      </Div>
      <Div>
        <Text weight="bold">Tag Name</Text>
        <p>Ascending alphaTextetical order.</p>
      </Div>
    </Div>
  );
}

export default SortTooltip;
