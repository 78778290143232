import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
angular.module('bigpanda').controller('DashboardsCtrl', DashboardsCtrl);

function DashboardsCtrl(
  $scope,
  $transitions,
  $state,
  $q,
  DashboardsStore,
  TopbarService,
  CustomizationsStore,
  DashboardsSortingUtils,
  Permissions,
  PERMISSIONS_NAMES,
  UserFeatureTogglesService
) {
  const vm = this;

  vm.showSidebar = showSidebar;
  vm.toggleNonFavs = toggleNonFavs;
  vm.hasPermissions = Permissions.isPermitted(PERMISSIONS_NAMES.dashboards.read);
  vm.loading = false;

  vm.$onInit = init;

  function init() {
    vm.isV2Dashboard = true;
    if (vm.isV2Dashboard) {
      window.location.href = '/v2/dashboards';
      return;
    }
    $scope.updatePage('dashboard');
    if (!vm.hasPermissions) {
      return;
    }

    DashboardsStore.subscribe($scope, {
      storeUpdated: fetchDashboards,
    });

    CustomizationsStore.subscribe(
      {
        storeUpdated: fetchCustomizations,
      },
      $scope
    );

    $transitions.onSuccess({ to: 'app.dashboards' }, loadDashboardsAndCustomizations);
    loadDashboardsAndCustomizations();

    $scope.$on('$destroy', () => {
      TopbarService.setShowTopbar(true);
    });
  }

  function loadDashboardsAndCustomizations() {
    $q.all([fetchDashboards(), fetchCustomizations()]).then(() => {
      if (!isEmpty(vm.dashboards) && !$state.params.dashboardId) {
        const sortedDashboards = DashboardsSortingUtils.sort(
          vm.dashboards,
          vm.dashboardCustomizations
        );
        const defaultDashboard =
          first(sortedDashboards.favoriteDashboards) ||
          first(sortedDashboards.nonFavoriteDashboards);
        $state.transitionTo(
          'app.dashboards.display',
          Object.assign({}, $state.params, { dashboardId: defaultDashboard._id })
        );
      }
    });

    vm.darkMode = !!$state.params.dark;
    vm.fullscreen = !!$state.params.fullscreen;

    TopbarService.setShowTopbar(!vm.fullscreen);
  }

  function fetchDashboards() {
    vm.loading = true;
    return DashboardsStore.getDashboards().then((dashboards) => {
      vm.loading = false;
      vm.dashboards = dashboards || [];
    });
  }

  function fetchCustomizations() {
    CustomizationsStore.getCustomizations().then((customizations) => {
      vm.dashboardCustomizations = customizations.dashboards;
    });
  }

  function showSidebar() {
    return TopbarService.shouldShowTopbar();
  }

  function toggleNonFavs(hide) {
    vm.dashboardCustomizations.navigation_list.hide_non_favorites = hide;

    CustomizationsStore.updateCustomizations({ dashboards: vm.dashboardCustomizations });
  }
}
