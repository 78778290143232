import { hot } from 'react-hot-loader';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import SettingsListScreen from 'react/layout/settings/SettingsListScreen';
import SettingsListEmptyState from 'react/layout/settings/components/SettingsListEmptyState';
import provideStore from 'react/utils/provideStore';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { DropdownOptionType } from 'react/common/components/FiltersDropdown/FiltersDropdown';
import actions from '../../actions';
import selectors from '../../selectors';
import IncidentLabelModal from '../../modals/IncidentLabelModal';
import ListItem from './list/ListItem';
import IncidentLabelsLoadNotification from '../../notifications/IncidentLabelsLoadNotification';
import IncidentLabelsUpdateNotification from '../../notifications/IncidentLabelsUpdateNotification';
import SortTooltip from './SortTooltip';
import { SORT_MODES, SortShape } from '../constants';
import FiltersComponent from './FiltersComponent';
import { ANY_OPTION_VALUE } from '../../constants';

class IncidentLabelsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incidentLabels: props.incidentLabels,
    };
  }

  componentDidMount() {
    window.location.href = '/v2/settings/incident-enrichment';

    const { incidentLabels, loadMultipleIncidentLabels } = this.props;

    if (!incidentLabels) {
      loadMultipleIncidentLabels();
    }
  }
  static getDerivedStateFromProps({ incidentLabels, isLoadingForSelector, ...props }, state) {
    return incidentLabels && !isLoadingForSelector ? { incidentLabels: incidentLabels } : null;
  }

  render() {
    const {
      filterText,
      updateFilterText,
      isLoading,
      updateSort,
      sortMode,
      featureToggles,
      filtersDropDownState,
      filtersDropDownItems,
      updateFiltersDropDowns,
    } = this.props;

    const { incidentLabels } = this.state;

    const settingsSortingFT = get(featureToggles, 'frontier_settings_sorting_and_filters', false);
    const sortModes = settingsSortingFT ? SORT_MODES : undefined;
    const filterDDItems = settingsSortingFT ? filtersDropDownItems : undefined;

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsListScreen
          loading={isLoading}
          items={incidentLabels}
          noItemsTitle="No Incident Tag Added Yet"
          createItemProps={{
            modal: {
              title: 'New Incident Tag',
              titleIcon: 'bp-icon-plus-icon',
              component: IncidentLabelModal,
              mode: 'create',
            },
            itemName: 'Incident Tag',
            buttonText: 'New Tag',
            tileText: 'Create New Incident Tag',
          }}
          listItem={ListItem}
          listItemProp="incidentLabel"
          headerName="Incident Enrichment"
          filterPlaceholderText="Find Incident Tags..."
          updateFilterText={updateFilterText}
          sortModes={sortModes}
          sortMode={sortMode}
          sortTooltip={SortTooltip()}
          updateSort={updateSort}
          filterText={filterText}
          permissionName="tags_definition"
          renderFilterRowElement={() =>
            filterDDItems && filtersDropDownState ? (
              <FiltersComponent
                filtersDropDownState={filtersDropDownState}
                onChange={updateFiltersDropDowns}
                data={filterDDItems}
              />
            ) : null
          }
          renderNoResultsByFilters={() =>
            filterText ||
            Object.values(filtersDropDownState).some(
              (dropDownState) => dropDownState.value !== ANY_OPTION_VALUE
            ) ? (
              <SettingsListEmptyState
                title="No Matches Found"
                subtitle="Try refining your query and search filters"
              />
            ) : null
          }
        />
        <IncidentLabelsLoadNotification />
        <IncidentLabelsUpdateNotification />
      </ThemeProvider>
    );
  }
}

IncidentLabelsManagement.propTypes = {
  incidentLabels: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  loadMultipleIncidentLabels: PropTypes.func.isRequired,
  updateFilterText: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  featureToggles: PropTypes.shape({
    react_grouped_settings_menu: PropTypes.bool,
    frontier_incident_enrichment_v2: PropTypes.bool,
  }).isRequired,
  updateSort: PropTypes.func.isRequired,
  sortMode: SortShape.isRequired,
  updateFiltersDropDowns: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    tagType: DropdownOptionType,
    status: DropdownOptionType,
  }),
  filtersDropDownItems: PropTypes.shape({
    tagType: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }),
};

IncidentLabelsManagement.defaultProps = {
  incidentLabels: undefined,
  filterText: '',
  filtersDropDownState: undefined,
  filtersDropDownItems: undefined,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    incidentLabels: selectors.getIncidentLabels(state),
    filterText: selectors.getFilterText(state),
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
    isLoadingForSelector: selectors.getIsLoading(state),
    sortMode: selectors.getSortMode(state),
    filtersDropDownState: selectors.getFiltersDropDownState(state),
    filtersDropDownItems: selectors.getFiltersDropDownItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadMultipleIncidentLabels: () => dispatch(actions.loadMultipleIncidentLabels()),
    updateFilterText: (filterText) => dispatch(actions.updateFilterText(filterText)),
    updateSort: (payload) => dispatch(actions.updateSort(payload)),
    updateFiltersDropDowns: (filterState) => dispatch(actions.updateFiltersDropDowns(filterState)),
  };
}

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(IncidentLabelsManagement))
);
