import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import SettingsListItemActions from 'react/layout/settings/components/SettingsListItemActions';
import actions from '../actions';
import selectors from '../selectors';
import UserModal from '../modals/UserModal';
import UserManagmentResendInvitation from './UserManagmentResendInvitation';
import { getUserName } from '../constants';

const UserManagementActions = ({ user, deleteUser, actionPermissions }) => {
  const removeUser = () => deleteUser(user.id);

  const userName = getUserName(user);

  const messages = {
    edit: `Cannot Edit User '${userName}'`,
    duplicate: `Cannot Duplicate User '${userName}'`,
    delete: `Cannot Delete User '${userName}'`,
  };

  return (
    <SettingsListItemActions
      item={user}
      modelName="user"
      messages={messages}
      editProps={{
        disabled: !actionPermissions.hasEditPermission,
        modalProps: { currentUserId: user.id, title: 'Edit User Details' },
        modal: UserModal,
      }}
      deleteProps={{
        disabled: !actionPermissions.canDelete,
        showIfDisabled: actionPermissions.hasDeletePermission,
        action: removeUser,
        modalProps: {
          contentTitleType: 'string',
          content: 'Are you sure you want to delete this user?',
        },
      }}
    >
      {actionPermissions.canInvite && (
        <div className="always-visible">
          <UserManagmentResendInvitation user={user} />
        </div>
      )}
    </SettingsListItemActions>
  );
};

UserManagementActions.propTypes = {
  user: PropTypes.object.isRequired /* eslint-disable-line */,
  deleteUser: PropTypes.func.isRequired,
  actionPermissions: PropTypes.shape({
    hasEditPermission: PropTypes.bool,
    hasDeletePermission: PropTypes.bool,
    canDelete: PropTypes.bool,
    canInvite: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    actionPermissions: selectors.getActionsPermissions(state, ownProps.user),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteUser: (userId) => dispatch(actions.deleteUser(userId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(UserManagementActions));
