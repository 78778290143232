import {
  AllSizes, Avatar, ICustomSelect, SCHBox, Text,
} from '@bp/kung-fu';
import React from 'react';

import { ArrowDown, AssignIcon, TriggerButton } from './AssignTrigger.style';

const AssignTrigger = ({
  requiredProps, text, hasValue, ...props
}: ICustomSelect) => {
  const { value } = props.selectProps;
  const isUserSelected = (hasValue && value?.assigneeName);
  return (
    <TriggerButton {...requiredProps}>
      <SCHBox gap="4px">
        {isUserSelected && <Avatar name={value.assigneeName} email={value.assigneeEmail} size={AllSizes.XXSMALL} />}
        {isUserSelected ? <Text>{value.assigneeName}</Text> : <AssignIcon />}
        <ArrowDown size={AllSizes.LARGE} />
      </SCHBox>
    </TriggerButton>
  );
};

export default AssignTrigger;
