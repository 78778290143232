import isEmpty from 'lodash/isEmpty';
angular.module('bigpanda').controller('CorrelationRulesSettingsCtrl', CorrelationRulesSettingsCtrl);

function CorrelationRulesSettingsCtrl(
  $scope,
  stateService,
  pubSubService,
  CorrelationRulesService,
  CorrelationActions,
  CorrelationRuleActions,
  PERMISSIONS_NAMES,
  CorrelationConfigService
) {
  const vm = this;

  pubSubService.broadcast('currentTitleChanged', 'Correlation Patterns');
  pubSubService.broadcast('settingsPageChanged', 'correlationRules');

  vm.search = '';
  vm.timeStampTitle = 'Updated:';
  vm.query = null;
  vm.loadingRoute = true;
  vm.correlations = [];
  vm.selectedId = stateService.getSelectedCorrelationId;
  vm.buttons = CorrelationRuleActions.buttons;
  vm.getSourceSystems = CorrelationRuleActions.getSourceSystems;
  vm.getQuery = getQuery;
  vm.hasQuery = hasQuery;
  vm.createPermissions = [PERMISSIONS_NAMES.correlations.create];
  init();

  function init() {
    CorrelationConfigService.subscribe($scope, { storeUpdated: () => getRules() });
    getRules({ force: true });
  }

  function getRules(opts) {
    CorrelationRulesService.getRules(opts).then(onRulesRecived, escapeRoute);
  }

  function getQuery(correlationRule) {
    return CorrelationActions.getQueryDisplayVal(correlationRule.pureQuery);
  }

  function hasQuery(correlationRule) {
    return correlationRule.pureQuery && !isEmpty(correlationRule.pureQuery);
  }

  function onRulesRecived(correlationRules) {
    if (!correlationRules) {
      escapeRoute();
    }
    vm.correlations = correlationRules;
    vm.loadingRoute = false;
  }

  function escapeRoute() {
    CorrelationActions.escapeRoute('Could not find Correlation Patterns', 'app.settings.personal');
  }
}
