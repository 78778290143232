import React from 'react';
import PropTypes from 'prop-types';
import { extractTemplateToTokens } from '../../../../common/formulas';

const TemplateToTokens = ({ template }) => (
  <React.Fragment>
    {extractTemplateToTokens(template).map(({ value, type, prefix, postfix }, idx) => {
      const key = `${value}-${idx}`;
      return type === 'tag' ? (
        <span key={key}>
          {prefix}
          <b>{value}</b>
          {postfix}
        </span>
      ) : (
        <span key={key}>{value}</span>
      );
    })}
  </React.Fragment>
);

TemplateToTokens.propTypes = {
  template: PropTypes.string,
};

export default TemplateToTokens;
