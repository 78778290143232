import Cookies from 'js-cookie';
import config from 'common/config';

export function getAuthTokenFromCookie() {
  const cookieSuffix = getCookiesSuffix();
  return Cookies.get(`to${cookieSuffix}`);
}

export function getAuthTokenFromQuery() {
  try {
    return new URLSearchParams(window.location.href.split('?')[1]).get('access_token');
  } catch (e) {
    // only for execution out of browser where we don't have URLSearchParams (tests etc...)
  }
}

export function logout() {
  window.location.href = config.logoutUrl;
}

function getCookiesSuffix() {
  return config.cookiesSuffix ? `_${config.cookiesSuffix}` : '';
}
