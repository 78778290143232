angular.module('bigpanda').service('FullscreenStore', FullscreenStore);

function FullscreenStore(SubscribersService) {
  const subscribers = new SubscribersService();
  let fullscreen;

  this.subscribe = subscribe;
  this.getFullscreen = getFullscreen;
  this.setFullscreenState = setFullscreenState;

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function setFullscreenState(fullscreenState) {
    fullscreen = fullscreenState;
    subscribers.fireStoreUpdated();
  }

  function getFullscreen() {
    return fullscreen;
  }

  return this;
}
