import {
  AllSizes,
  Button, ButtonVariants, darkTheme, SnoozeIcon,
} from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

interface ISnoozeButton {
  disabled?: boolean,
  tooltipText?: string,
  isSnoozed?: boolean
}

const SnoozeButton = ({ disabled, tooltipText, isSnoozed }: ISnoozeButton) => (
  <ThemeProvider theme={darkTheme}>
    <Button
      size={AllSizes.LARGE}
      variant={ButtonVariants.ACTION_CONTAINER}
      icon={<SnoozeIcon color={(p) => (isSnoozed ? p.theme.bp_orange : undefined)} />}
      disabled={disabled}
      ariaLabel="Snooze incident"
      tooltipProps={{
        isActive: true,
        text: tooltipText,
        placement: 'bottom',
      }}
    />
  </ThemeProvider>
);

export default SnoozeButton;
