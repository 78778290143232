angular.module('bigpanda.utils').service('pubSubService', function ($rootScope, $log) {
  var self = this;

  function getCallArgs(args) {
    return Array.prototype.slice.call(args);
  }

  this.broadcast = function (name, args) {
    // Take the given arguments of the function and pass them along to the rootScope's function.
    return $rootScope.$emit.apply($rootScope, getCallArgs(arguments));
  };

  this.on = function (name, callback, scope) {
    var deregisterFn = $rootScope.$on(name, function () {
      // Don't bubble up exception, the default $exceptionHandler only logs and changing behavior
      // for the entire app isn't the desired option

      try {
        // Keep the original call args - also keep the context, but in reality, angular uses null
        callback.apply(this, getCallArgs(arguments));
      } catch (e) {
        var errorMsg = e ? e.message : '';
        $log.error(`Unexpected error during publishing of: ${name} - ${errorMsg}`, e);

        // broadcast the event to let stack holders know about the error
        // Support both specific error notification and generic error
        // Prevent endless loop of error inside handling errors :)
        if (name != 'pubSubService.error') {
          self.broadcast(`pubSubService.error_${name}`, e);
          self.broadcast('pubSubService.error', { event: name, error: e });
        }
      }
    });

    if (scope) {
      scope.$on('$destroy', () => {
        deregisterFn();
      });
    }

    return deregisterFn;
  };
});
