import capitalize from 'lodash/capitalize';

export const formatPermissionName = (currentRole) => {
  const formattedPermissionHash = currentRole.permissions
    .filter((permission) => permission.resource_type !== 'environments')
    .reduce((acc, permission) => {
      const resourceType = permission.resource_type;
      return {
        ...acc,
        ...{
          [resourceType]: permission.actions.reduce(
            (innerAcc, action) => ({
              ...innerAcc,
              [action]: true,
            }),
            acc[resourceType] || {}
          ),
        },
      };
    }, {});

  return Object.entries(formattedPermissionHash)
    .map(([resourceType, actionsHash]) => {
      if (actionsHash.Read && actionsHash.Create && actionsHash.Update && actionsHash.Delete) {
        return `${capitalize(resourceType)}_Full_Access`;
      }
      if (actionsHash.Read) {
        return `${capitalize(resourceType)}_Read`;
      }
      return null;
    })
    .filter((permValue) => permValue);
};

export const filterItemsByDropDownFilters = (
  dropDownState,
  dropDownItemDataMapping,
  items,
  noFilterActionValue = '-1',
  allEnvsActionValue = '-99'
) => {
  if (!items) return undefined;
  if (!dropDownState || !dropDownItemDataMapping) return items;
  const actionableDropDownStateKeys = Object.keys(dropDownState).filter(
    (key) => dropDownState[key].value !== noFilterActionValue
  );

  return items.filter((item) =>
    actionableDropDownStateKeys.every((key) => {
      if (dropDownState[key] && dropDownState[key].value === allEnvsActionValue) {
        return item.allEnvironmentsAccess;
      }
      return (
        dropDownItemDataMapping[key] &&
        dropDownItemDataMapping[key](item).some(
          (d) => d && d.toLowerCase() === dropDownState[key].value.toLowerCase()
        )
      );
    })
  );
};

export const formatUserDisplayName = ({ username, email }) => `${username} (${email})`;
