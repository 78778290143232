import React from 'react';
import PropTypes from 'prop-types';
import { BamForm } from '@bp/bam';
import { connect } from 'react-redux';
import cn from 'classnames';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { selectors as correlationConfigSelectors } from 'react/common/correlation_config';
import get from 'lodash/get';
import PriorityForm from './partials/PriorityForm';
import { getActiveLevels } from './partials/PriorityForm.utils';
import { getEnrichmentConfigForUpdate } from './IncidentLabelForm.utils';
import TextForm from './partials/TextForm';
import actions from '../actions';
import selectors from '../selectors';
import styles from './IncidentLabelForm.scss';
import IncidentLabelPreview from './IncidentLabelPreview';
import { incidentTagTypes, incidentTagModalActionTypes, reqStatusType } from '../constants';

class IncidentLabelForm extends React.Component {
  state = {
    incidentLabel: this.props.incidentLabel,
    initialIncidentLabel: this.props.incidentLabel,
    isItemsLengthError: false,
    errorMessage: '',
  };

  componentDidMount() {
    const { unifiedTags, loadUnifiedTagsForIncidentLabel } = this.props;
    if (!unifiedTags) {
      loadUnifiedTagsForIncidentLabel();
    }
  }

  onCancel = (close) => {
    this.props.cancelLocalIncidentLabelChanges();
    close();
  };

  getFormContent = () => {
    const { incidentLabel } = this.state;
    switch (incidentLabel.type) {
      case incidentTagTypes.PRIORITY:
        return (
          <PriorityForm
            {...this.props}
            incidentLabel={incidentLabel}
            displayErrorContent={this.displayErrorContent}
            closeErrorMessage={() => this.closeErrorMessage()}
          />
        );
      case incidentTagTypes.TEXT:
      case incidentTagTypes.MULTI_VALUE:
        return (
          <TextForm
            {...this.props}
            incidentLabel={incidentLabel}
            displayErrorContent={this.displayErrorContent}
            closeErrorMessage={() => this.closeErrorMessage()}
          />
        );
    }
  };

  handleSubmit = (values, form) => {
    const { type } = values;
    try {
      switch (type) {
        case incidentTagTypes.PRIORITY:
          return this.handlePrioritySubmit(values, form);
        case incidentTagTypes.TEXT:
        case incidentTagTypes.MULTI_VALUE:
          return this.handleTextSubmit(values, form);
        default:
          null;
      }
      this.props.close();
    } catch (e) {}
  };

  handlePrioritySubmit = (values, form) => {
    const {
      currentIncidentLabelId: id,
      updateIncidentLabel,
      featureToggles,
      mode,
      close,
    } = this.props;

    if (
      (id && mode === incidentTagModalActionTypes.EDIT && form.getState().dirty) ||
      mode === incidentTagModalActionTypes.DUPLICATE ||
      (incidentTagModalActionTypes.CREATE && form.getState().dirty)
    ) {
      const {
        config,
        name,
        type,
        description,
        active,
        enrichment_config,
        canManualInput = false,
      } = values;
      const allowTagsManualInputFT = get(featureToggles, 'allow_tags_manual_input', true);
      const enableManualInput = allowTagsManualInputFT ? !!canManualInput : true;
      const enrichmentConfig = getEnrichmentConfigForUpdate(enrichment_config);
      const activeLevels = getActiveLevels({ config });
      if (Object.keys(activeLevels).length) {
        updateIncidentLabel({
          id,
          config,
          name,
          type,
          description,
          active,
          canManualInput: enableManualInput,
          enrichment_config: enrichmentConfig,
        });
      }
    } else if (mode === incidentTagModalActionTypes.EDIT) {
      this.props.cancelLocalIncidentLabelChanges();
      close();
    }
  };

  handleTextSubmit = (values, form) => {
    const {
      currentIncidentLabelId: id,
      updateIncidentLabel,
      createIncidentLabel,
      mode,
      featureToggles,
      close,
    } = this.props;
    if (form.getState().dirty || mode === incidentTagModalActionTypes.DUPLICATE) {
      const { name, type, description, active, enrichment_config, canManualInput } = values;
      const enrichmentConfig = getEnrichmentConfigForUpdate(enrichment_config);
      const allowTagsManualInputFT = get(featureToggles, 'allow_tags_manual_input', true);
      const enableManualInput = allowTagsManualInputFT ? !!canManualInput : true;
      switch (mode) {
        case incidentTagModalActionTypes.CREATE: {
          const incidentLabel = {
            name,
            type,
            description,
            active,
            canManualInput: enableManualInput,
            enrichment_config: enrichmentConfig,
          };
          createIncidentLabel(incidentLabel);
          break;
        }
        case incidentTagModalActionTypes.DUPLICATE: {
          const incidentLabel = {
            name,
            type,
            description,
            canManualInput: enableManualInput,
            active: true,
            enrichment_config: enrichmentConfig,
          };
          createIncidentLabel(incidentLabel);
          break;
        }
        case incidentTagModalActionTypes.EDIT: {
          const incidentLabel = {
            id,
            name,
            canManualInput: enableManualInput,
            type,
            description,
            active,
            enrichment_config: enrichmentConfig,
          };
          updateIncidentLabel(incidentLabel);
          break;
        }
      }
    } else if (mode === incidentTagModalActionTypes.EDIT) {
      this.props.cancelLocalIncidentLabelChanges();
      close();
    }
  };

  displayErrorContent = (msg) => {
    this.setState({ isItemsLengthError: true, errorMessage: msg });
  };

  updateLocalIncidentLabel = (newIncidentForm) => {
    this.setState({ incidentLabel: newIncidentForm });
  };

  closeErrorMessage() {
    this.setState({ isItemsLengthError: false, errorMessage: '' });
  }

  render() {
    const { close, positiveButtonText, positiveButtonProductId, featureToggles } = this.props;
    const { initialIncidentLabel, isItemsLengthError, errorMessage } = this.state;
    const isPreviewFormulaFTOpen = get(featureToggles, 'preview_formula', false);
    const disableQueryHelper = get(featureToggles, 'disable_query_helper', false);

    const id = 'IncidentLabelForm';
    return (
      <div className={styles[`${styles.form} ${!disableQueryHelper && '-with-query-helper'}`]}>
        <BamForm
          id={id}
          onSubmit={this.handleSubmit}
          onChange={this.updateLocalIncidentLabel}
          initialValues={initialIncidentLabel}
          rightPane={isPreviewFormulaFTOpen && <IncidentLabelPreview />}
          positiveButton={{
            text: positiveButtonText,
            'data-product-id': positiveButtonProductId,
          }}
          closeButton={{
            text: 'Cancel',
            onClick: () => this.onCancel(close),
          }}
          subscription={{
            submiting: true,
          }}
          mutators={{
            splice: ([name, index], state, { changeValue }) => {
              changeValue(state, name, (oldValue) => [
                ...oldValue.slice(0, index),
                ...oldValue.slice(index + 1),
              ]);
            },
            push: ([name, value], state, { changeValue }) => {
              changeValue(state, name, (oldValue) =>
                oldValue ? oldValue.concat([value]) : [value]
              );
            },
            update: ([name, value], state, { changeValue }) => {
              changeValue(state, name, () => value);
            },
          }}
        >
          {this.getFormContent()}
          {isItemsLengthError && (
            <div className={styles['error-message']}>
              <i className={cn(styles['warning-icon'], 'bp-icon-warning')} />
              <span className={styles['error-message-content']}>{errorMessage}</span>
            </div>
          )}
        </BamForm>
      </div>
    );
  }
}

IncidentLabelForm.propTypes = {
  updateIncidentLabel: PropTypes.func.isRequired,
  createIncidentLabel: PropTypes.func.isRequired,
  cancelLocalIncidentLabelChanges: PropTypes.func.isRequired,
  currentIncidentLabelId: PropTypes.string,
  positiveButtonText: PropTypes.string,
  positiveButtonProductId: PropTypes.string,
  incidentLabel: PropTypes.shape({
    //eslint-disable-line
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
    type: PropTypes.oneOfType(incidentTagTypes),
  }),
  close: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(incidentTagModalActionTypes),
  updateRequestStatus: PropTypes.oneOf(reqStatusType),
  formErrors: PropTypes.shape({}),
  unifiedTags: PropTypes.arrayOf(PropTypes.shape({})),
  loadUnifiedTagsForIncidentLabel: PropTypes.func.isRequired,
  featureToggles: PropTypes.shape({
    allow_tags_manual_input: PropTypes.bool,
  }).isRequired,
};

IncidentLabelForm.defaultProps = {
  currentIncidentLabelId: undefined,
  incidentLabel: undefined,
  positiveButtonText: undefined,
  positiveButtonProductId: undefined,
  mode: undefined,
  updateRequestStatus: undefined,
  formErrors: {},
  unifiedTags: undefined,
};

function mapStateToProps(state) {
  return {
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
    updateRequestStatus: selectors.getUpdateRequestState(state),
    formErrors: selectors.getFormErrors(state),
    unifiedTags: correlationConfigSelectors.getUnifiedTags(state),
  };
}

const mapDispatchToProps = {
  loadMultipleIncidentLabels: actions.loadMultipleIncidentLabels,
  cancelLocalIncidentLabelChanges: actions.cancelLocalIncidentLabelChanges,
  loadUnifiedTagsForIncidentLabel: actions.loadUnifiedTagsForIncidentLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentLabelForm);
