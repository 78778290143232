import { AxiosRequestConfig } from 'axios';
import get from 'lodash/get';

import bpFetch from '../../../../../../app/common/utils/http/fetcher';

export { fetcher, FetcherConfig };

const defaultRequestConfig: Required<RequestConfig> = {
  url: undefined,
  method: 'GET',
  params: undefined,
  data: undefined,
  headers: undefined,
};

type RequestConfig = Pick<AxiosRequestConfig, 'url' | 'method' | 'params' | 'data' | 'headers'>;
type ResponseConfig = { path: string | string[] };
type FetcherConfig = {
  requestConfig: RequestConfig;
  responseConfig: ResponseConfig;
};

const fetcher = <T = unknown>({ requestConfig, responseConfig }: FetcherConfig): Promise<T> => (
  bpFetch({ ...defaultRequestConfig, ...requestConfig })
    .then((res) => get(res, responseConfig.path))
);
