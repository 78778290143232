import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { ElementType, PureComponent } from 'react';

interface QueryClientProps {
  queryClient?: QueryClient;
}

const defaultQueryClient = new QueryClient();
// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
export default function withReactQueryClient(
  TopLevelComponent: ElementType,
  queryClientInstance?: QueryClient,
) {
  const queryClient = queryClientInstance || defaultQueryClient;
  return class ProvidedTopLevelComponent extends PureComponent<QueryClientProps> {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
    render() {
      const { queryClient: queryClientProp } = this.props;
      return (
        <QueryClientProvider client={queryClientProp || queryClient}>
          <TopLevelComponent {...this.props} />
          <ReactQueryDevtools />
        </QueryClientProvider>
      );
    }
  };
}
