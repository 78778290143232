const Favico = require('favico.js');

angular.module('bigpanda.utils').service('faviconService', faviconService);

function faviconService($window) {
  const favico = new Favico({
    animation: 'none',
  });

  function badge(num) {
    favico.badge(num);
  }
  function image(imagePath) {
    const imageElement = $window.document.createElement('img');
    imageElement.src = imagePath;
    favico.image(imageElement);
  }
  function reset() {
    favico.reset();
  }

  this.badge = badge;
  this.image = image;
  this.reset = reset;
}
