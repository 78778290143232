import {
  AddNote,
  darkTheme,
  IUseForm,
  TextArea,
  TextField,
  VBox,
} from '@bp/kung-fu';
import { useQueryClient } from '@tanstack/react-query';
import trim from 'lodash/trim';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { loadAlertTags } from '../../../../../app/react/common/alert_tags/actions';
import alertTagsSelectors from '../../../../../app/react/common/alert_tags/selectors';
import QueryHelper from '../../../alert-enrichment/src/components/QueryHelper/QueryHelper';
import { QueryKeys } from '../api/constants';
import dictionary from '../dictionary';
import { AlertFilter } from '../types/AlertFilter';
import { FilterForm } from '../types/FilterForm';
import { FormContent } from './style';
import { uniqueNameValidator } from './validators/uniqueNameValidator';

interface IProps {
  register: IUseForm<FilterForm>['register'];
  updateField: IUseForm<FilterForm>['updateField'];
  condition?: string;
}

function ConditonText(props): JSX.Element {
  return <TextArea rows={10} {...props} />;
}

export function AlertFilterForm({ register, updateField, condition }: IProps): JSX.Element {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const alertTags: object[] = useSelector(alertTagsSelectors.getDisplayAlertTags);
  const alertFilters = queryClient.getQueryData<AlertFilter[]>([QueryKeys.AlertFilter]) || [];

  const validateFilterName = (value: string): string => {
    const trimmedValue = trim(value);
    if (!trimmedValue.length) return dictionary.name_is_required;
    return uniqueNameValidator<AlertFilter>(alertFilters, 'name', value);
  };

  useEffect(() => {
    dispatch(loadAlertTags());
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <FormContent>
        <VBox gap="24px" padding="28px">
          <TextField
            {...register('name', {
              validation: validateFilterName,
              type: 'change',
            })}
            label={dictionary.filter_name}
            required
            placeholder={dictionary.enter_a_name_placeholder}
            width="200px"
          />
          <QueryHelper
            onChange={(val: string): void => updateField('condition', val)}
            alertTags={alertTags}
            TriggerComponent={ConditonText}
            triggerComponentProps={{
              title: dictionary.condition,
              value: condition,
            }}
          />
          <AddNote
            {...register('description')}
            label={dictionary.add_description}
          />
        </VBox>
      </FormContent>
    </ThemeProvider>
  );
}
