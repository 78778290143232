import get from 'lodash/get';
import { combineEpics } from 'redux-observable';
import * as meService from 'common/endpoints/me';
import actionTypes from './actionTypes';
import welcomeTypes from '../../modules/settings/welcome/actionTypes';
import * as actions from './actions';
import { getCustomizations } from '../../../common/endpoints/customizations';

const loadUser = (action$) =>
  action$
    .ofType(actionTypes.LOAD_USER, welcomeTypes.UPDATE_USER_NAME_PASSWORD_SUCCESS)
    .mergeMap(async () => {
      const user = await meService.getContact();
      return actions.loadUserSuccess(user);
    });

const loadCustomizations = (action$) =>
  action$.ofType(actionTypes.LOAD_CUSTOMIZATIONS).mergeMap(() =>
    getCustomizations()
      .then((data) => {
        const customizations = get(data, ['item', '0', 'customizations']);

        if (customizations) {
          return actions.loadCustomizationsSuccess(customizations);
        }
        return undefined;
      })
      .catch(actions.loadCustomizationsFailure)
  );

export default combineEpics(loadUser, loadCustomizations);
