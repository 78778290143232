import { path } from './constants';
import { generateBaseSettingsActionPaths } from '../redux.utils';

const actionTypes = generateBaseSettingsActionPaths(path);

actionTypes.LOAD_CUSTOM_TAGS = `${path}.LOAD_CUSTOM_TAGS`;
actionTypes.LOAD_CUSTOM_TAGS_SUCCESS = `${path}.LOAD_CUSTOM_TAGS_SUCCESS`;
actionTypes.ADD_CUSTOM_TAG = `${path}.ADD_CUSTOM_TAG`;
actionTypes.UPDATE_CUSTOM_TAG = `${path}.UPDATE_CUSTOM_TAG`;
actionTypes.DELETE_CUSTOM_TAG = `${path}.DELETE_CUSTOM_TAG`;

export default actionTypes;
