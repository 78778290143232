const MAX_NUM_OF_PRIMARY_LENGTH = 32;
const MAX_NUM_OF_TOTAL_LENGTH = 45;

function fetchPrimarySecondaryFromEntity(entities) {
  const longestPrimaryLength = getLongestPrimaryLength(entities);
  const primarySecondaryEntitiesData = entities.reduce((acc, entity) => {
    const primary = entity.primary_property_value;
    const primaryLength = primary.length;

    const primarySlicedOrPadded = getPrimarySlicedOrPadded(
      primary,
      primaryLength,
      longestPrimaryLength
    );
    const secondarySliced = getSecondarySliced(
      entity.secondary_property_value,
      primarySlicedOrPadded.length
    );
    return [...acc, ` • ${primarySlicedOrPadded}  • ${secondarySliced}`];
  }, []);
  return primarySecondaryEntitiesData;
}

function getLongestPrimaryLength(entities) {
  return Math.max(...entities.map((entity) => entity.primary_property_value.length));
}

function getPrimarySlicedOrPadded(primary, primaryLength, longestPrimaryLength) {
  if (primaryLength > MAX_NUM_OF_PRIMARY_LENGTH) {
    return `${primary.slice(0, MAX_NUM_OF_PRIMARY_LENGTH)}...`;
  } else if (primaryLength < longestPrimaryLength) {
    return primary + '\xa0\xa0'.repeat(longestPrimaryLength - primaryLength);
  }
  return primary;
}

function getSecondarySliced(secondary, primarySlicedLength) {
  const secondaryLength = secondary.length;
  if (secondaryLength + primarySlicedLength > MAX_NUM_OF_TOTAL_LENGTH) {
    return `${secondary.slice(0, MAX_NUM_OF_TOTAL_LENGTH - primarySlicedLength)}...`;
  }
  return secondary;
}

export default fetchPrimarySecondaryFromEntity;
