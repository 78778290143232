angular.module('bigpanda').directive('assignButton', assignButton);

function assignButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '?^^checkedIncidentsState',
    },
    scope: {
      clickDisabled: '=?',
      incidentId: '<',
      buttonSize: '@',
      panelSize: '@',
      isActionsV2: '<',
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: controller,
    templateUrl: 'overview/incidents/list/widgets/assign_button/assign_button',
  };

  function controller(
    $scope,
    $q,
    $element,
    AssignmentsStore,
    PersonalSettingsStore,
    ContactsStore,
    AssignPanelUtils,
    bpPanelSelectService
  ) {
    const vm = this;
    vm.context = null;
    vm.tooltipTemplate = 'overview/incidents/list/widgets/assign_button/assign_tooltip';
    vm.buttonClass = {};

    vm.$onInit = onInit;
    vm.$onChanges = updateContext;
    vm.openSelector = openSelector;
    vm.onSelect = onSelect;
    vm.assignTestId = 'Assign_list_button';
    vm.userList = [];

    function onInit() {
      if (vm.isActionsV2) {
        AssignPanelUtils.getUserOptions().then((data) => {
          vm.userList = data;
        });
      }

      // If we're not in the screen of the incidents list, we mock the controller so the flow of the class will work
      // as expected
      if (!vm.checkedIncidentsState) {
        vm.checkedIncidentsState = {
          subscribe: angular.noop,
          isAnythingChecked: () => false,
        };
      }

      updateContext().then(() => {
        AssignmentsStore.subscribe($scope, {
          storeUpdated: () => getAssignment(vm.context),
        });
      });

      updateButtonClass(vm.context);
      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: () => updateButtonClass(vm.context),
      });
    }

    function updateContext() {
      // We need change context on the fly because of the virtual repeat, the same directive is reused with new data.
      vm.context = {
        incidentId: vm.incidentId,
        assignment: {},
      };

      return getAssignment(vm.context);
    }

    function openSelector() {
      if (!isClickDisabled()) {
        AssignPanelUtils.getUserOptions().then((userOptions) =>
          bpPanelSelectService.openSelector({
            element: $element,
            options: userOptions,
            selectedId: vm.context.assignment.assignee,
            filterKey: 'displayName',
            resetActionName: 'Unassign',
            panelSize: vm.panelSize,
            onSelect: onItemSelect,
            incidentId: vm.context.incidentId,
          })
        );
      }
    }

    function onItemSelect(selectedUserId, incidentId) {
      if (selectedUserId) {
        AssignmentsStore.assign(incidentId, selectedUserId);
      } else {
        AssignmentsStore.unassign(vm.context.incidentId);
      }
    }

    function onSelect(selectedUserId) {
      if (selectedUserId) {
        AssignmentsStore.assign(vm.context.incidentId, selectedUserId);
      } else {
        AssignmentsStore.unassign(vm.context.incidentId);
      }
    }

    function getAssignment(context) {
      // Mutation of the sent context is required to make sure we edit the original context in case the vm one is replaced on scroll.
      return AssignmentsStore.getAssignment(context.incidentId)
        .then((assignment) => {
          context.assignment = assignment;

          if (assignment.assignee) {
            return configureAssignee(assignment, context);
          }

          return assignment;
        })
        .catch(() => {
          context.assignment = {};
        })
        .finally(() => updateButtonClass(context));
    }

    function configureAssignee(assignment, context) {
      return $q
        .all([
          ContactsStore.getContactByUserId(assignment.assignee),
          ContactsStore.getContactByUserId(assignment.assigner),
          PersonalSettingsStore.getContact(),
        ])
        .then((contactsResult) => {
          const assignee = contactsResult[0];
          const assigner = contactsResult[1];
          const currentContact = contactsResult[2];

          context.assignment.assignerName = assigner.name || assigner.email;
          context.assignment.assigneeEmail = assignee.email;
          context.assignment.assigneeName = assignee.name || assignee.email;
          context.assignment.me = currentContact.bigpanda_user_id === assignment.assignee;
          context.assignment.timestamp = formatDate(context.assignment.updated_at);
        });
    }

    function formatDate(date) {
      const momentDate = moment(date);
      return {
        date: momentDate.format('MMM D'),
        hour: momentDate.format('h:mm a'),
      };
    }

    function isClickDisabled() {
      return vm.checkedIncidentsState.isAnythingChecked() || vm.clickDisabled;
    }

    function updateButtonClass(context) {
      vm.buttonClass = {
        'bp-disabled': vm.checkedIncidentsState.isAnythingChecked(),
        'click-disabled': isClickDisabled(),
        [vm.buttonSize]: vm.buttonSize,
        assigned: context.assignment.assigneeName,
      };
      vm.assignRelevant = !vm.clickDisabled || context.assignment.assigneeName;
    }
  }
}
