import get from 'lodash/get';
const {
  colorsNameHexObj,
  defaultColor,
} = require('react/modules/settings/incident_labels/constants');

angular.module('bigpanda').directive('priorityDropdown', priorityDropdown);

function priorityDropdown() {
  return {
    restrict: 'E',
    templateUrl: 'overview/incidents/list/widgets/labels/priority_dropdown',
    require: {
      checkedIncidentsState: '?^^checkedIncidentsState',
    },
    scope: {
      incidentId: '<',
      isMultiple: '@',
      clickDisabled: '=?',
      deleteDisabled: '=?',
      fromList: '=?',
      tileChecked: '=?',
      showNewDropdown: '=?',
    },
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(
    $scope,
    $q,
    $ngRedux,
    $sce,
    stateService,
    LabelsStore,
    pubSubService,
    Permissions,
    UserFeatureTogglesService
  ) {
    const vm = this;

    vm.context = null;
    vm.$onInit = init;
    vm.store = $ngRedux;
    vm.$onChanges = updateContext;
    vm.envId = stateService.getSelectedEnvironmentId();
    const allowTagsManualInputFT = UserFeatureTogglesService.getToggle('allow_tags_manual_input');
    vm.hasNewGridFT = allowTagsManualInputFT === null ? true : allowTagsManualInputFT;

    vm.currentEnvActions = {
      update: Permissions.checkGranularPermission('action@update', 'environments', vm.envId),
    };

    vm.trustedHtml = trustedHtml;

    function trustedHtml(plainText) {
      return $sce.trustAsHtml(plainText);
    }

    vm.isTagEnabled = false;
    vm.tagId = null;
    vm.list = [];
    vm.listObj = {};
    vm.changeSelectedTag = changeSelectedTag;
    vm.deleteSelectedTag = deleteSelectedTag;

    function init() {
      if (!vm.checkedIncidentsState) {
        vm.checkedIncidentsState = {
          subscribe: angular.noop,
          isAnythingChecked: () => false,
        };
      }

      $scope.$watch('vm.deleteDisabled', onIncidentTagsUpdated);

      onIncidentTagsUpdated();
      pubSubService.on('incidentTagsDefinitions.updated', onIncidentTagsUpdated, $scope);

      updateContext().then(() => {
        LabelsStore.subscribe($scope, {
          storeUpdated: () => updateContextWithPriorityTag(vm.context),
        });
      });

      vm.incidentIds = [vm.context.incidentId];
      vm.isDisabled = isClickDisabled();
      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: () => {
          const selectedIncidents = vm.checkedIncidentsState.getCheckedIncidentIds();
          vm.isDisabled = isClickDisabled();
          if (selectedIncidents.length) {
            vm.incidentIds = selectedIncidents;
          }
        },
      });
    }

    function onIncidentTagsUpdated() {
      const priorityTag = LabelsStore.getPriorityDefinition();
      vm.priorityTag = priorityTag;
      if (!priorityTag.id) return;

      vm.tagId = priorityTag.id;
      vm.isTagEnabled = isTagEnabled(priorityTag);
      vm.list = get(priorityTag, 'config.ordered_list', []).map(
        ({ display_name: displayName, color, order_id: orderId, active }) => ({
          click: `vm.changeSelectedTag("${displayName}", ${orderId})`,
          text: trustedHtml(`
          <i class="entity-value status background" style="background: ${
            colorsNameHexObj[color]
              ? colorsNameHexObj[color].value
              : colorsNameHexObj[defaultColor].value
          };"></i>
          <span class="tag-name">${displayName}</span>
        `),
          color: color,
          orderId: orderId,
          active: active,
        })
      );
      vm.listObj = vm.list.reduce((acc, item) => {
        acc[item.orderId] = item;
        return acc;
      }, {});
      vm.list = vm.list.filter((item) => item.active);
      if (!vm.deleteDisabled) {
        vm.list.push({ divider: true });
        vm.list.push({
          action: 'delete',
          click: 'vm.deleteSelectedTag()',
          text: trustedHtml(`
            <span class="delete-option">
                <i class="bp-icon-priority-remove"/>
                <span>Remove</span>
            </span>`),
        });
      }
      updateContext();
    }

    function isTagEnabled(tag) {
      return !!(tag.active && vm.currentEnvActions.update);
    }

    function isClickDisabled() {
      return (vm.checkedIncidentsState.isAnythingChecked() || vm.clickDisabled) && !vm.isMultiple;
    }

    function updateContext() {
      // We need change context on the fly because of the virtual repeat, the same directive is reused with new data.
      vm.context = {
        incidentId: vm.incidentId,
        label: {},
      };
      return updateContextWithPriorityTag(vm.context);
    }

    function changeSelectedTag(tagText, tagValue, name = null, email = null) {
      let payload;
      if (vm.isMultiple) {
        payload = {
          tags: [{ tag_id: vm.tagId, tag_value: tagValue }],
          incident_ids: vm.checkedIncidentsState.getCheckedIncidentIds(),
        };
        LabelsStore.setBatchIncidentTags(vm.context.incidentId, payload);
      } else if (vm.context.label.value !== tagValue) {
        payload = {
          tag_id: vm.tagId,
          tag_value: tagValue,
          name,
          email,
        };
        LabelsStore.setIncidentTag(vm.context.incidentId, payload);
      }
    }

    function deleteSelectedTag() {
      if (vm.isMultiple) {
        const payload = {
          incident_ids: vm.checkedIncidentsState.getCheckedIncidentIds(),
        };
        LabelsStore.deleteMultipleIncidentsTag(vm.tagId, payload);
      } else {
        LabelsStore.deleteIncidentTag(vm.context.incidentId, vm.tagId);
      }
    }

    function updateContextWithPriorityTag(context) {
      if (!context.incidentId) {
        return Promise.resolve(true);
      }
      if (!vm.fromList) {
        LabelsStore.setCurrentIncident(context.incidentId);
      }
      // Mutation of the sent context is required to make sure we edit the original context in case the vm one is replaced on scroll.
      return LabelsStore.getIncidentTags(context.incidentId)
        .then((res) => {
          const { labels } = res;
          const label = (labels || []).find(({ id }) => id === vm.tagId);
          if (!label) {
            context.label = {};
            return;
          }
          const tagValue = label.value;
          const tagText = vm.listObj[tagValue].text;

          context.label = {
            value: tagValue,
            text: tagText,
          };
        })
        .catch(() => {
          context.label = {};
        });
    }
  }
}
