import {
  Div, Text,
} from '@bp/kung-fu';
import React from 'react';

import { text } from '../../../../../../constants';
import UserAvatar from '../../../UserAvatar';
import { RowText } from '../../RowDetails.style';
import { RowDetailsSectionProps } from '../../types';
import {
  ContextDetailsColumn,
  ContextDetailsValueColumn,
  RowContextDetailsWrapper,
  RowContextHeaderWrapper,
  RowDetailsContextWrapper,
  UserNameText,
} from './RowDetailsContext.style';

function RowDetailsContext({ rowData }: RowDetailsSectionProps): JSX.Element {
  const { user, context } = rowData;

  const renderUserName = (): string => `${user.name}`;

  const renderUserAction = (): string => {
    const { action, category } = rowData;
    return `${action.toLowerCase()}d this ${category.expandRowValue}`;
  };

  if (!user || !context) return <Div />;

  return (
    <RowDetailsContextWrapper>
      <RowContextHeaderWrapper>
        <UserAvatar userName={user.name} />
        <UserNameText>{renderUserName()}</UserNameText>
        <Text>{renderUserAction()}</Text>
      </RowContextHeaderWrapper>
      <RowContextDetailsWrapper>
        <ContextDetailsColumn>
          <RowText>{user.email && `${text.email}:`}</RowText>
          {context.actor_access?.ip_address
              && (
              <RowText>
                {`${text.ipAddress}:`}
              </RowText>
              )}

          {context.actor_access?.user_agent
              && (
              <RowText>
                {`${text.userAgent}:`}
              </RowText>
              )}

        </ContextDetailsColumn>
        <ContextDetailsValueColumn>
          <Text>{user.email}</Text>
          {context?.actor_access?.ip_address && (
          <Text>{context.actor_access.ip_address}</Text>
          )}
          {context?.actor_access?.ip_address && (
          <Text>{context.actor_access.user_agent}</Text>
          )}
        </ContextDetailsValueColumn>
      </RowContextDetailsWrapper>
    </RowDetailsContextWrapper>
  );
}

export default RowDetailsContext;
