angular.module('bigpanda').directive('editorPreview', editorPreview);

function editorPreview() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      isValid: '=',
      showInvalidPreview: '=',
      currentEnvType: '=',
      isBpqlValid: '=',
      isIncidentTagIsSelected: '=',
      rules: '=',
    },
    bindToController: true,
    templateUrl: 'overview/environments/editor/preview/editor_preview',
    controller: 'EditorPreviewCtrl',
    controllerAs: 'editorPreviewVm',
  };
}
