import styled from 'styled-components';
import { Modal, VBox } from '@bp/kung-fu';

export const Wrapper = styled(VBox)`
width: 600px;
padding: 30px 25px;
background-color: ${(p) => p.theme.bp_gray_01};
`;

export const ModalWrapper = styled(Modal)`
background-color: whitesmoke;
color: aliceblue;
`;
