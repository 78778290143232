import bpFetch from 'common/utils/http/fetcher';
import { convertNewCorrelationPatternToOld } from '../../react/modules/settings/correlation_patterns/converters';

// TODO(BPA-6042): Once alert enrichment tags are released, refactor all code related to
//  correlation config, i.e. fetchers, epic, etc.
export async function getCorrelationConfig() {
  const [correlationPatterns, customTags, correlationAlgorithmData] = await Promise.all([
    fetchCorrelationPatterns(),
    fetchCustomTags(),
    fetchCorrelationAlgorithmData(),
  ]);
  return {
    transformations: customTags,
    matching_rules: correlationPatterns,
    ...correlationAlgorithmData,
  };
}

export function createCorrelationPattern(correlationPattern) {
  return bpFetch({
    url: '/web/correlation_patterns',
    method: 'POST',
    data: correlationPattern,
  }).then((res) => res.data.item);
}

export function createCustomTag(customTag) {
  return bpFetch({
    url: '/web/custom_tags',
    method: 'POST',
    data: customTag,
  }).then((res) => res.data.item);
}

export function deleteCorrelationPattern(id) {
  return bpFetch({
    url: `/web/correlation_patterns/${id}`,
    method: 'DELETE',
  }).then((res) => res.data.item);
}

export function deleteCustomTag(id) {
  return bpFetch({
    url: `/web/custom_tags/${id}`,
    method: 'DELETE',
  }).then((res) => res.data.item);
}

export function fetchCorrelationAlgorithmData() {
  return bpFetch({ url: '/web/correlation_algorithm_data', params: { rnd: Math.random() } }).then(
    (res) => res.data.item
  );
}

export function fetchCorrelationPatterns() {
  return bpFetch({ url: '/web/correlation_patterns', params: { rnd: Math.random() } }).then((res) =>
    (res.data.item || []).map(convertNewCorrelationPatternToOld)
  );
}

export function fetchCustomTags() {
  return bpFetch({ url: '/web/custom_tags', params: { rnd: Math.random() } }).then(
    (res) => res.data.item
  );
}

export function updateCorrelationPattern(correlationPattern) {
  return bpFetch({
    url: `/web/correlation_patterns/${correlationPattern.id}`,
    method: 'PUT',
    data: correlationPattern,
  }).then((res) => res.data.item);
}

export function updateCustomTag(customTag) {
  return bpFetch({
    url: `/web/custom_tags/${customTag.id}`,
    method: 'PUT',
    data: customTag,
  }).then((res) => res.data.item);
}

export function createPreview(conf, start, end, events, correlations) {
  return bpFetch({
    url: '/web/correlation/preview',
    method: 'POST',
    data: conf,
    params: { start, end, events, correlations },
  }).then((res) => res.data);
}

export function getPreview(id) {
  return bpFetch({ url: `/web/correlation/preview/${id}` }).then((res) => res.data);
}

export function deletePreview(id) {
  return bpFetch({ url: `/web/correlation/preview/${id}`, method: 'DELETE' }).then(
    (res) => res.data
  );
}
