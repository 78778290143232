angular.module('bigpanda').controller('IncidentLifecycleCtrl', IncidentLifecycleCtrl);

function IncidentLifecycleCtrl(
  $scope,
  $stateParams,
  IncidentsService,
  ModalService,
  pubSubService,
  sourceBreakdownService
) {
  const vm = this;
  let loadedOnce = false;
  vm.close = ModalService.hide;

  function init() {
    if (!loadedOnce) {
      loadedOnce = true;

      IncidentsService.getIncidentById($stateParams.incidentId).then((incident) => {
        vm.crossSource = sourceBreakdownService.isCrossSource(incident.entities);
        vm.incident = incident;
        IncidentsService.getIncidentEntities(incident.id, 'full').then((entities) => {
          vm.entities = entities.entities;
        });
      });
    }
  }

  pubSubService.on('entitiesList.dataLoaded', init, $scope);
}
