import React from 'react';
import { Text, AllSizes } from '@bp/kung-fu';
import { List, ListItem, Bullet } from './PasswordTooltip.css';

const PasswordTooltip = () => {
  return (
    <List>
      <ListItem>
        <Bullet /> <Text size={AllSizes.SMALL}>At least 8 characters</Text>
      </ListItem>
      <ListItem>
        <Bullet /> <Text size={AllSizes.SMALL}>At least 1 lowercase letter</Text>
      </ListItem>
      <ListItem>
        <Bullet /> <Text size={AllSizes.SMALL}>At least 1 uppercase letter</Text>
      </ListItem>
      <ListItem>
        <Bullet /> <Text size={AllSizes.SMALL}>At least 1 numeric or special character</Text>
      </ListItem>
    </List>
  );
};

export default PasswordTooltip;
