import { SCHBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const InitStateWrapper = styled(SCHBox)`
    background-color: ${(p) => p.theme.bp_blue_selected};
    height: 48px;
    color: ${(p) => p.theme.bp_gray_06};
    gap: 5px;
    padding-left: 50px;
`;
