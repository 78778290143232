angular.module('bigpanda').service('IncidentTimestampService', IncidentTimestampService);

function IncidentTimestampService(IncidentTimestampGenerator, SortingStore) {
  this.getIncidentTimestamp = getIncidentTimestamp;

  function getIncidentTimestamp(incident, folderId) {
    return SortingStore.getSortBy().then((sortBy) =>
      IncidentTimestampGenerator.getIncidentTimestamp(incident, folderId, sortBy)
    );
  }
}
