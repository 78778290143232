import find from 'lodash/find';
angular.module('bigpanda.utils').service('TextWidthService', function ($log) {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()[]{}<>,.~/\\"+-=_|;:\'?• ·';

  var fontsWidth = [];

  function calcFontWidth(chars, font) {
    const fontWidth = {};
    let defaultWidth = 0;
    const $container = $('<span/>').css({
      visibility: 'hidden',
      position: 'absolute',
      whiteSpace: 'pre',
      font,
    });
    $('body').append($container);
    for (let i = 0; i < chars.length; ++i) {
      const char = chars[i];
      const width = $container.text(char).width();

      if (width > defaultWidth) {
        defaultWidth = width;
      }
      fontWidth[char] = width;
    }
    fontWidth.default = defaultWidth;
    $container.detach();
    return fontWidth;
  }

  function findFontWidth(font) {
    return find(fontsWidth, { font: font });
  }

  this.init = function (font) {
    var fontWidth = { font: font, charWidth: calcFontWidth(chars, font) };
    fontsWidth.push(fontWidth);
    return fontWidth;
  };

  this.calcTextWidth = function (font, text) {
    var dict = findFontWidth(font);
    if (!dict) {
      dict = this.init(font);
    }
    var width = 0;
    for (var i = 0; i < text.length; ++i) {
      var char = text[i];
      width += dict.charWidth[char] || dict.charWidth.default;
      if (!dict.charWidth[char]) {
        $log.error(`failed to calcTextWidth: dictionary dosen't contains: ${char}`);
      }
    }
    return width;
  };
});
