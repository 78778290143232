const sendToOptions = [
  {
    display: 'Send to',
    items: [
      {
        display: 'Top of the list',
        value: 0,
      },
      {
        display: 'Bottom of the list',
        value: 1,
      },
    ],
  },
];

export default sendToOptions;
