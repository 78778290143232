import { ResetIcon } from '@bp/kung-fu';

import { incidentTagClosedListTypes, incidentTagTypes } from '../../../../../app/react/modules/settings/incident_labels/constants';

export const allowMultiTypeMap = {
  [incidentTagTypes.TEXT]: false,
  [incidentTagTypes.MULTI_VALUE]: true,
  [incidentTagTypes.PRIORITY]: false,
  [incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE]: false,
  [incidentTagClosedListTypes.CLOSED_LIST_VALUE_MULTI]: true,
};

export const isTagTypeIsClosedListMap = {
  [incidentTagTypes.TEXT]: false,
  [incidentTagTypes.MULTI_VALUE]: false,
  [incidentTagTypes.PRIORITY]: false,
  [incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE]: true,
  [incidentTagClosedListTypes.CLOSED_LIST_VALUE_MULTI]: true,
};

export const removeOption = { id: 'Remove', text: 'Remove', Icon: ResetIcon };
