import get from 'lodash/get';
import { path } from './constants';
import { getAllIntegrationOptions, getIntegrationOptions } from './utils';
const selectors = {};

selectors.getIntegrations = (state) => get(state, path).integrations;
selectors.getIntegrationOptions = (state) => {
  const integrations = selectors.getIntegrations(state);
  return getIntegrationOptions(integrations);
};
selectors.getAllIntegrationOptions = (state) => {
  const integrations = selectors.getIntegrations(state);
  return getAllIntegrationOptions(integrations);
};

export default selectors;
