import { AllSizes, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const BaseText = styled(Text).attrs({ size: AllSizes.SMALL })`
    color: ${(p) => p.theme.bp_gray_08};
`;

export const MapName = styled(BaseText)`
    margin-left: 4px;
`;
