angular.module('bigpanda.backend.services').factory('ReportsBackendService', ($http) => {
  return {
    getReports: getReports,
    getReport: getReport,
    getSnapshotMetadata: getSnapshotMetadata,
    createReport: createReport,
    updateReport: updateReport,
    deleteReport: deleteReport,
  };

  function getReports() {
    return $http.get('/web/reports').then((res) => res.data.item.reports);
  }

  function getReport(id) {
    return $http.get(`/web/reports/${id}`).then((res) => res.data.item.report);
  }

  function createReport(report) {
    return $http.post('/web/reports', report).then((res) => res.data.item.id);
  }

  function updateReport(id, reportData) {
    return $http.patch(`/web/reports/${id}`, reportData).then((res) => res.data);
  }

  function deleteReport(id) {
    return $http.delete(`/web/reports/${id}`).then((res) => res.data);
  }

  function getSnapshotMetadata(id) {
    return $http.get(`/web/reports/${id}/snapshots`).then((res) => res.data.item.snapshots);
  }
});
