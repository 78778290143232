import { HBox } from '@bp/kung-fu';
import styled from 'styled-components';

import { Collapsible } from '../../../../../../../app/react/common/components';

export const Container = styled(HBox)`
  margin-bottom: ${({ isOpen }) => (isOpen ? '10px' : '5px')};
`;

export const StyledCollapsible = styled(Collapsible)`
  border: ${({ hasError, theme }) => hasError && `1px solid ${theme.bp_red}`};
  width: 100%;
`;
