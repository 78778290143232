import {
  AllSizes, darkTheme, PrioritySelect, useForm,
} from '@bp/kung-fu';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { ThemeProvider, withTheme } from 'styled-components';

import { bpUserImg } from '../../../../../app/common/constants';
import { colorsNameHexObj } from '../../../../../app/react/modules/settings/incident_labels/constants';
import incidentTagsSelectors from '../../../../../app/react/modules/tags/selectors';
import { selectors as getUserSelectors } from '../../../../../app/react/user/details';
import PriorityWrapper from './PriorityDropdown.style';

function PriorityDropdown({
  priorityTags,
  updatePriorityTag,
  removePriorityTag,
  priorityValue,
  fromList,
}) {
  const priorityTagId = 'itd_priority_1';
  const incidentTagsData = useSelector(incidentTagsSelectors.getIncidentTags);
  const currentUser = useSelector(getUserSelectors.getUser);

  const priorityTagValue = incidentTagsData.find((tag) => tag.tag_id === priorityTagId);

  const { state, register, updateField } = useForm({
    priority: '',
  });

  const priorityOptions = useMemo(
    () => get(priorityTags, 'config.ordered_list', [])
      .filter((tag) => tag.active)
      .map((tag) => {
        const priorityColor = get(colorsNameHexObj[tag.color], 'value', colorsNameHexObj.red);
        return {
          statusColor: priorityColor,
          color: priorityColor,
          text: tag.display_name,
          value: tag.order_id,
          id: tag.display_name,
        };
      }),
    [priorityTags],
  );

  useEffect(() => {
    const value = priorityOptions.find((tag) => tag.value === priorityValue);
    updateField('priority', value);
  }, [priorityTags, incidentTagsData, priorityValue]);

  const updateNewValue = (name, value) => {
    if (!value) {
      removePriorityTag();
      updateField(name, null);
    } else if (!value || value.value !== get(state, 'priority.value', null)) {
      updatePriorityTag(value.text, value.value, currentUser.name, currentUser.email);
      updateField(name, value || '');
    }
  };

  const userData = useMemo(
    () => (priorityTagValue && !fromList
      ? {
        date: new Date(priorityTagValue.timestamp || Date.now()).toISOString(),
        email: priorityTagValue.email,
        isManual: priorityTagValue.name,
        name: priorityTagValue.name || 'BigPanda',
        url: priorityTagValue.email ? null : bpUserImg,
        label: 'Priority',
      }
      : null),
    [priorityTagValue, incidentTagsData, fromList],
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <PriorityWrapper fromList={fromList} aria-label="PrioritySelect dropdown main" data-product-id="change_incident_priority2">
        <PrioritySelect
          {...register('priority')}
          options={priorityOptions}
          updateField={updateNewValue}
          user={userData}
          isActive={priorityTags.canManualInput}
          size={fromList ? AllSizes.SMALL : AllSizes.LARGE}
        />
      </PriorityWrapper>
    </ThemeProvider>
  );
}

PriorityDropdown.propTypes = {
  priorityTags: PropTypes.shape({
    active: PropTypes.bool,
    canManualInput: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    config: PropTypes.shape({
      ordered_list: PropTypes.arrayOf(
        PropTypes.shape({
          active: PropTypes.bool,
          color: PropTypes.string,
          description: PropTypes.string,
          display_name: PropTypes.string,
          order_id: PropTypes.number,
        }),
      ),
    }),
  }),
  updatePriorityTag: PropTypes.func.isRequired,
  removePriorityTag: PropTypes.func.isRequired,
  environmentId: PropTypes.string.isRequired,
  incidentId: PropTypes.string,
  priorityValue: PropTypes.number,
  fromList: PropTypes.bool,
};

PriorityDropdown.defaultProps = {
  incidentId: '',
  priorityValue: null,
  priorityTags: {},
  fromList: true,
};

export default hot(module)(withTheme(PriorityDropdown));
