import { nonShowTypes } from './organization.type.constant';

angular.module('bigpanda').controller('OrganizationDataCtrl', OrganizationDataCtrl);

function OrganizationDataCtrl($scope) {
  const vm = this;
  $scope.init = (org) => {
    vm.orgType = org.orgType;
    vm.orgName = org.orgName;
    vm.nonShowTypes = nonShowTypes;
    vm.accountType =
      vm.orgType === 'pov' ? `${vm.capitalize(vm.orgType)}` : `${vm.capitalizeFirst(vm.orgType)}`;
  };

  vm.capitalizeFirst = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  vm.capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.toUpperCase();
  };
}
