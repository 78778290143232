import inRange from 'lodash/inRange';
import { combineEpics } from 'redux-observable';
import { BamNotificationStatusOptions } from '@bp/bam';
import * as environmentsService from 'common/endpoints/environments';
import pluralize from 'common/utils/utils';
import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  showFailureMessage,
  showSuccessMessage,
  showInProgressMessageUndo,
} from '../../layout/settings/feedback';

const createMerge = (action$) =>
  action$.ofType(actionTypes.CREATE_MERGE).mergeMap(async ({ payload }) => {
    const merge = await environmentsService.createMerge(
      {
        source_incident_ids: payload.sourceIncidentIds,
        annotation: payload.annotation,
        environment_name: payload.envName,
      },
      payload.envId,
      payload.destIncidentId
    );
    return actions.createMergeSuccess(merge);
  });

const createSplit = (action$) =>
  action$.ofType(actionTypes.CREATE_SPLIT).mergeMap(async ({ payload }) => {
    const split = await environmentsService.createSplit(
      {
        entity_ids: payload.entityIds,
        annotation: payload.annotation,
        environment_name: payload.envName,
      },
      payload.envId,
      payload.incidentId
    );
    return actions.createSplitSuccess(split);
  });

function getAlertsString(numOfAlerts) {
  return numOfAlerts + pluralize(numOfAlerts, ` Alert`);
}

function getAlertsStringWithResolveEnding(numOfAlerts) {
  return `${getAlertsString(numOfAlerts)} to resolve...`;
}

const showSuccessMessageForBatchResolve = (
  title,
  action,
  status = BamNotificationStatusOptions.ACTION_SUCCESS
) => {
  setTimeout(
    () =>
      showSuccessMessage(title, action, status, '', `and operation will be completed soon`, true),
    500
  );
};

async function sendResolveBatchEntitiesRequest(payload) {
  const numOfAlerts = payload.ids.length;
  try {
    const entitiesBatchResolveResponse = await environmentsService.createEntitiesBatchResolve(
      {
        ids: payload.ids,
        comments: payload.comments,
        environment: payload.environmentName,
      },
      payload.environmentId
    );
    if (inRange(entitiesBatchResolveResponse.status, 200, 204)) {
      showSuccessMessageForBatchResolve(getAlertsString(numOfAlerts), 'Resolved', undefined);
    } else {
      showFailureMessageForBatchResolve(`Resolve`, numOfAlerts);
    }
  } catch (_) {
    showFailureMessageForBatchResolve(`Resolve`, numOfAlerts);
  }
}

function runEntitiesBatchResolveWithUndo(
  title,
  action,
  status = BamNotificationStatusOptions.UNDO,
  data
) {
  showInProgressMessageUndo(
    title,
    action,
    status,
    {
      text: 'Undo',
    },
    sendResolveBatchEntitiesRequest,
    data
  );
}

const showFailureMessageForBatchResolve = (action, numOfAlerts) => {
  setTimeout(() => showFailureMessage(getAlertsString(numOfAlerts), action), 500);
};

const createEntitiesBatchResolve = (action$) =>
  action$.ofType(actionTypes.CREATE_ENTITIES_BATCH_RESOLVE).mergeMap(async ({ payload }) => {
    runEntitiesBatchResolveWithUndo(
      getAlertsStringWithResolveEnding(payload.ids.length),
      'Scheduling',
      BamNotificationStatusOptions.UNDO,
      payload
    );
    return actions.createEntitiesBatchResolveSuccess();
  });

export default combineEpics(createMerge, createSplit, createEntitiesBatchResolve);
