import sortBy from 'lodash/sortBy';
angular.module('bigpanda').service('SettingsService', SettingsService);

function SettingsService(PERMISSIONS_NAMES, UserFeatureTogglesService, Permissions) {
  const vm = this;

  vm.settingsPages = settingsPages;

  const removeDisabledAndOrder = (list, orderType = 'groupedSettingsModeOrder') => {
    const permissions = Permissions.get();
    return sortBy(list, orderType)
      .filter((setting) => !setting.disabled)
      .filter(
        (setting) =>
          !setting.permissions ||
          permissions.some((permission) => setting.permissions.includes(permission))
      );
  };

  function settingsPages(isPersonalSettings) {
    const USERS = {
      id: 'users',
      icon: 'bp-icon-users',
      label: 'User Management',
      navigateTo: 'app.settings.user_management',
      permissions: [PERMISSIONS_NAMES.users.read],
      groupedSettingsModeOrder: 1,
      isReact: true,
      hasAPI: false,
    };

    const ROLES = {
      id: 'roles',
      icon: 'bp-icon-lock-1',
      label: 'Roles Management',
      navigateTo: '/v2/settings/roles',
      permissions: [PERMISSIONS_NAMES.roles.read],
      groupedSettingsModeOrder: 2,
      isReact: true,
      isFrontierV2: true,
      hasAPI: false,
    };

    const RULES = {
      id: 'rules',
      icon: 'bp-icon-share',
      label: 'AutoShare',
      navigateTo: '/v2/settings/auto-share',
      permissions: [PERMISSIONS_NAMES.notifications.read],
      groupedSettingsModeOrder: 5,
      isFrontierV2: true,
      isReact: true,
      hasAPI: false,
    };

    const isAlertEnrichmentV2Enabled = UserFeatureTogglesService.getToggle(
      'frontier_alert_enrichment_v2'
    );
    const ALERT = {
      id: 'alertEnrichment',
      label: 'Alert Enrichment',
      navigateTo: isAlertEnrichmentV2Enabled
        ? '/v2/settings/alert-enrichment'
        : 'app.settings.alert_enrichment',
      icon: 'bp-icon-tag',
      groupedSettingsModeOrder: 2,
      disabled: !UserFeatureTogglesService.getToggle('frontier_alert_enrichment'),
      permissions: [PERMISSIONS_NAMES.enrichments.read],
      isReact: true,
      hasAPI: false,
      isFrontierV2: isAlertEnrichmentV2Enabled,
    };

    const isMappingEnrichmentEnabled = UserFeatureTogglesService.getToggle(
      'frontier_mapping_enrichment'
    );
    const MAPPING_ENRICHMENT = {
      id: 'mappingEnrichment',
      label: 'Enrichment Maps',
      navigateTo: '/v2/settings/mapping-enrichment',
      icon: 'bp-icon-file-document-text',
      groupedSettingsModeOrder: 2,
      disabled: !isMappingEnrichmentEnabled,
      permissions: [PERMISSIONS_NAMES.enrichments.read],
      isReact: true,
      hasAPI: false,
      isFrontierV2: true,
    };

    const isAlertFilteringV2Enabled = UserFeatureTogglesService.getToggle(
      'frontier_alert_filtering_v2'
    );
    const FILTERING = {
      id: 'alertFiltering',
      label: 'Alert Filtering',
      navigateTo: isAlertFilteringV2Enabled
        ? '/v2/settings/alert-filtering'
        : 'app.settings.alert_filtering',
      icon: 'bp-icon-alertfiltering',
      groupedSettingsModeOrder: 2,
      disabled: UserFeatureTogglesService.getToggle('frontier_disable_alert_filtering'),
      permissions: [PERMISSIONS_NAMES.plans.read],
      isReact: true,
      hasAPI: false,
      isFrontierV2: isAlertFilteringV2Enabled,
    };

    const isAlertCorrelationV2Enabled = UserFeatureTogglesService.getToggle(
      'frontier_correlation_v2'
    );

    const isApiKeysV2Enabled = UserFeatureTogglesService.getToggle('frontier_api_keys_v2');

    const CORRELATION_RULES = {
      id: 'correlationRules',
      icon: 'bp-icon-flash',
      label: 'Alert Correlation',
      navigateTo: '/v2/settings/alert-correlation',
      groupedSettingsModeOrder: 3,
      permissions: [PERMISSIONS_NAMES.correlations.read],
      isReact: true,
      hasAPI: true,
      isFrontierV2: true,
    };

    const INCIDENT_LABELS = {
      id: 'incidentLabels',
      label: 'Incident Enrichment',
      navigateTo: '/v2/settings/incident-enrichment',
      icon: 'bp-icon-label',
      groupedSettingsModeOrder: 4,
      permissions: [PERMISSIONS_NAMES.tags_definition.read],
      isReact: true,
      hasAPI: false,
      isFrontierV2: true,
    };

    const MAINTENANCE_PLANS = {
      id: 'maintenancePlans',
      icon: 'bp-icon-maintenance',
      label: 'Planned Maintenance',
      navigateTo: '/v2/settings/planned-maintenance',
      groupedSettingsModeOrder: 6,
      permissions: [PERMISSIONS_NAMES.plans.read],
      isReact: true,
      hasAPI: false,
      isFrontierV2: true,
    };

    const QUATA = {
      id: 'quota',
      icon: 'bp-icon-gauge',
      label: 'Sharing Quotas',
      navigateTo: '/v2/settings/quota',
      groupedSettingsModeOrder: 2,
      permissions: [PERMISSIONS_NAMES.quotas.read],
      isReact: true,
      hasAPI: false,
      isFrontierV2: true,
    };

    const API_KEYS = {
      id: 'apiKeys',
      icon: 'bp-icon-key',
      label: 'API Keys',
      navigateTo: isApiKeysV2Enabled ? '/v2/settings/api_keys' : 'app.settings.api_keys',
      groupedSettingsModeOrder: 5,
      permissions: [PERMISSIONS_NAMES.apiKeys.read],
      isReact: true,
      hasAPI: false,
      isFrontierV2: isApiKeysV2Enabled,
    };

    const isSsoV2Enabled = UserFeatureTogglesService.getToggle('frontier_sso_v2');
    const SSO = {
      id: 'sso',
      icon: 'bp-icon-login',
      label: 'Single Sign-on',
      navigateTo: isSsoV2Enabled ? '/v2/settings/sso' : 'app.settings.sso',
      params: { guide: '' },
      permissions: [PERMISSIONS_NAMES.sso.read],
      groupedSettingsModeOrder: 3,
      isReact: true,
      hasAPI: false,
      isFrontierV2: isSsoV2Enabled,
    };
    const isSsoConfigurationEnabled = UserFeatureTogglesService.getToggle('sso_jit_mapping_v2');

    const SSO_CONFIGURATION = {
      id: 'ssoConfiguration',
      icon: 'bp-icon-maps',
      label: 'SSO Configuration',
      navigateTo: 'v2/settings/jit-sso',
      disabled: !isSsoConfigurationEnabled,
      params: { guide: '' },
      permissions: [PERMISSIONS_NAMES.sso.read],
      groupedSettingsModeOrder: 4,
      isReact: true,
      hasAPI: false,
      isFrontierV2: true,
    };

    const INTEGRATIONS_MANAGMENT = {
      id: 'integrationsManagement',
      icon: 'bp-icon-integrations',
      label: 'Integrations',
      navigateTo: 'app.settings.integrations',
      groupedSettingsModeOrder: 12,
      disabled: !UserFeatureTogglesService.getToggle('frontier_integrations_lobby_react'),
      permissions: [PERMISSIONS_NAMES.integrations.read],
      isReact: true,
      hasAPI: false,
    };

    const DIVIDER_IMG = {
      id: 'divider',
      groupedSettingsModeOrder: 6,
      iconSource: 'assets/img/divider.svg',
      isReact: true,
      hasAPI: false,
      isDivider: true,
      disabled: ![PERMISSIONS_NAMES.quotas.read],
    };

    const ALERT_VIEW = {
      id: 'alertView',
      icon: 'bp-icon-alertview',
      label: 'Alert View',
      navigateTo: '/v2/settings/alert-view',
      groupedSettingsModeOrder: 1,
      isReact: true,
      hasAPI: false,
      permissions: [PERMISSIONS_NAMES.alert_view.read],
      isFrontierV2: true,
    };

    const isIncidentViewEnabled = UserFeatureTogglesService.getToggle('incident_list_table_v2');

    const INCIDENT_VIEW = {
      id: 'incidentView',
      icon: 'bp-icon-fullpage',
      label: 'Incident Feed View',
      navigateTo: '/v2/settings/incident-view',
      groupedSettingsModeOrder: 1,
      disabled: !isIncidentViewEnabled,
      isReact: true,
      hasAPI: false,
      permissions: [PERMISSIONS_NAMES.incident_view.read],
      isFrontierV2: true,
    };

    const AUDIT_LOG_VIEW = {
      id: 'auditLogsView',
      icon: 'bp-icon-multi-value',
      label: 'Audit Log',
      navigateTo: 'app.settings.audit_logs',
      groupedSettingsModeOrder: 1,
      isReact: true,
      hasAPI: false,
      permissions: [PERMISSIONS_NAMES.audit_logs.read],
    };

    const DATA_PIPELINE = [
      ALERT,
      MAPPING_ENRICHMENT,
      FILTERING,
      CORRELATION_RULES,
      INCIDENT_LABELS,
      RULES,
      DIVIDER_IMG,
      MAINTENANCE_PLANS,
    ];
    const USER_PREMISSIONS = [
      USERS,
      ROLES,
      SSO,
      SSO_CONFIGURATION,
      API_KEYS,
      INTEGRATIONS_MANAGMENT,
    ];

    const SYSTEM_PREFERENCES = [ALERT_VIEW, INCIDENT_VIEW];

    const ADMINISTRATION = [AUDIT_LOG_VIEW, QUATA];

    if (isPersonalSettings) {
      return null;
    }

    return [
      {
        title: 'Data Processing',
        list: removeDisabledAndOrder(DATA_PIPELINE),
      },
      {
        title: 'Access Management',
        list: removeDisabledAndOrder(USER_PREMISSIONS),
      },
      {
        title: 'System Preferences',
        list: removeDisabledAndOrder(SYSTEM_PREFERENCES),
      },
      {
        title: 'Administration',
        list: removeDisabledAndOrder(ADMINISTRATION),
      },
    ];
  }
}
