import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { showFailureMessage, showSuccessMessage } from 'react/layout/settings/feedback';
import { reqStatusType } from '../constants';
import selectors from '../selectors';

const showNotificationMessage = (updateRequestStatus) => {
  switch (updateRequestStatus) {
    case reqStatusType.SUCCESS:
      showSuccessMessage('Incident Tag', 'updated');
      break;
    case reqStatusType.FAILURE:
      showFailureMessage('Incident Tag', 'update');
      break;
    default:
      break;
  }
};

class IncidentTagsGridNotification extends React.Component {
  componentDidUpdate() {
    const { updateRequestStatus } = this.props;
    if (!isNil(updateRequestStatus)) {
      showNotificationMessage(updateRequestStatus);
    }
  }

  render() {
    return null;
  }
}

IncidentTagsGridNotification.propTypes = {
  updateRequestStatus: PropTypes.oneOf(Object.values(reqStatusType)).isRequired,
};
function mapStateToProps(state) {
  return {
    updateRequestStatus: selectors.getUpdateRequestState(state),
  };
}

export default connect(mapStateToProps, null)(hot(module)(IncidentTagsGridNotification));
