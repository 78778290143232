import {
  AllSizes, Button, ButtonVariants, HBox, secondsToMinutes, Text, VBox,
} from '@bp/kung-fu';
import React, { useMemo } from 'react';

import dictionary from './dictionary';
import { ButtonWrapper, TileWrapper } from './matched-patterns.style';
import { ICorrelationPattern } from './types';

export default ({ correlationPattern, index }: { correlationPattern: ICorrelationPattern, index: number }) => {

  const { id, tags, window: timeWindow } = correlationPattern;

  const tagsMapArr = useMemo(() => tags.map((tag) => tag.type), [tags]);
  const tagsMap = useMemo(() => tagsMapArr.join(', '), [tagsMapArr]);

  const onClick = () => window.open(`/v2/settings/alert-correlation/${id}`, '_blank');

  return (
    <TileWrapper>
      <HBox gap="0.4rem">
        <Text weight="bold" size={AllSizes.LARGE} color={(p) => p.theme.bp_gray_05}>{`${index + 1}.`}</Text>
        <VBox gap="0.4rem">
          <Text weight="bold" size={AllSizes.LARGE} color={(p) => p.theme.bp_gray_08}>{tagsMap}</Text>
          <Text color={(p) => p.theme.bp_gray_07}>{`${secondsToMinutes(timeWindow)}${dictionary.m_title}`}</Text>
        </VBox>
      </HBox>
      <ButtonWrapper>
        <Button variant={ButtonVariants.PRIMARY} size={AllSizes.SMALL} onClick={onClick}>
          {dictionary.view_matched_patterns_title}
        </Button>
      </ButtonWrapper>
    </TileWrapper>
  );
};
