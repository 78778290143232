import { validatePasswordWithText } from 'react/common/validations/common';
import createValidator from '../../../common/validations';
import allValidationStrategy from '../../../common/validations/strategies/all';

const minimalNameLength = 2;
const maximalNameLength = 100;

const validators = [
  {
    name: 'name',
    validate: ({ name }) => {
      if (!(name && name.trim().length)) {
        return 'Full name is mandatory';
      } else if (name.length > maximalNameLength || name.length < minimalNameLength) {
        return 'Full name is invalid';
      }
      return null;
    },
  },
  {
    name: 'newPassword',
    validate: ({ newPassword }) => validatePasswordWithText(newPassword),
  },
];

export default createValidator(allValidationStrategy, validators);
