import {
  AllSizes, SCHBox, Text, WarningIcon,
} from '@bp/kung-fu';
import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled(WarningIcon)`
    margin-inline-end: 5px;
`;

export function WarningNotice({ text }: { text: string }): JSX.Element {
  return (
    <SCHBox>
      <StyledIcon size={AllSizes.XXLARGE} color={(p): string => p.theme.bp_red} />
      <Text size={AllSizes.SMALL} color={(p): string => p.theme.bp_gray_07}>
        {text}
      </Text>
    </SCHBox>
  );
}
