import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
angular.module('bigpanda.utils').service('StateParametersService', StateParametersService);

function StateParametersService($rootScope, $stateParams, $location, $filter) {
  return StateParametersServiceInstance;

  function StateParametersServiceInstance() {
    const urlParamTypes = {
      timeframe: 1,
      date: 2,
      text: 3,
    };

    this.urlParamTypes = urlParamTypes;
    this.watchUrlParams = watchUrlParams;
    this.updateUrlParams = updateUrlParams;
    this.destroy = destroy;

    let destroyWatcher;
    let prevPickedStateParams;
    let handledUrlParams;

    function watchUrlParams(controllerUrlParams, callback) {
      handledUrlParams = controllerUrlParams;
      destroyWatcher = $rootScope.$on('$stateChangeSuccess', () => {
        updateStateParams(callback);
      });

      updateStateParams(callback);
    }

    function updateStateParams(callback) {
      const pickedStateParams = pick($stateParams, keys(handledUrlParams));

      if (!isEqual(pickedStateParams, prevPickedStateParams)) {
        callback(updateScopeParams());
      }
      prevPickedStateParams = pickedStateParams;
    }

    function destroy() {
      destroyWatcher();
    }

    function updateUrlParams(newParamsObj) {
      const urlParamsObject = scopeParamsToUrlParams(newParamsObj);

      if (!isEqual($stateParams, urlParamsObject)) {
        $location.search(urlParamsObject);
      }
    }

    function updateScopeParams() {
      const currentUrlParams = $stateParams;
      const scopeParams = {};

      forEach(currentUrlParams, (value, key) => {
        const paramType = handledUrlParams[key];

        if (paramType === urlParamTypes.timeframe) {
          if (value) {
            scopeParams[key] = $filter('timeframePickerObjectFilter')(value);
          }
        } else if (paramType === urlParamTypes.date) {
          if (value) {
            const from = currentUrlParams.from;
            const to = currentUrlParams.to;
            scopeParams.timeframe = $filter('timeframePickerObjectFilter')('custom', from, to);
          }
        } else {
          scopeParams[key] = value;
        }
      });

      return scopeParams;
    }

    function scopeParamsToUrlParams(scopeParams) {
      const urlParamsObject = {};

      return reduce(
        scopeParams,
        (result, value, key) => {
          const paramType = handledUrlParams[key];
          if (paramType === urlParamTypes.timeframe) {
            const timeframe = value.timeframe;
            result.from = timeframe.start ? timeframe.start.getTime().toString() : null;
            result.to = timeframe.end ? timeframe.end.getTime().toString() : null;
            result.timeframe = timeframe.interval !== 'custom' ? timeframe.interval : null;
          } else {
            result[key] = value || null;
          }
          return result;
        },
        urlParamsObject
      );
    }
  }
}
