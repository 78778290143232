import forEach from 'lodash/forEach';
angular.module('bigpanda').filter('duration', () => (time, tillTime, full) => {
  if (!angular.isNumber(time) || Number.isNaN(time) || time <= 0) {
    return '-';
  }

  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_DAY = 86400;
  const TIME_DICT = {
    days: { singular: 'day', plural: 'days', short: 'd' },
    hours: { singular: 'hour', plural: 'hours', short: 'h' },
    minutes: { singular: 'minute', plural: 'minutes', short: 'm' },
  };

  const duration = getDurationDiff(time, tillTime);
  const displayTimeArr = getTimeForDisplay(makeTimeObject(duration));

  if (displayTimeArr.length === 0) {
    return full ? '< 1 minute' : '< 1m';
  }
  return full ? displayTimeArr.join(' : ') : displayTimeArr[0].replace(' ', '');

  function getDurationDiff(timeStampUnix, tillTimeUnix) {
    let since;

    if (
      tillTimeUnix &&
      angular.isNumber(timeStampUnix) &&
      !Number.isNaN(timeStampUnix) &&
      timeStampUnix > 0
    ) {
      since = moment.unix(tillTimeUnix).diff(moment.unix(timeStampUnix)) / 1000;
    } else {
      since = moment().diff(moment.unix(timeStampUnix)) / 1000;
    }

    return since;
  }

  function makeTimeObject(seconds) {
    const calc = full ? Math.floor : Math.round;
    return {
      days: calculateTime(seconds, SECONDS_IN_DAY, calc),
      hours: calculateTime(seconds, SECONDS_IN_HOUR, calc),
      minutes: calculateTime(seconds, SECONDS_IN_MINUTE, calc),
    };
  }

  function calculateTime(seconds, timeWindow, calc) {
    return seconds >= timeWindow ? calc(seconds / timeWindow) : null;
  }

  function getTimeForDisplay(timeObj) {
    const timeArr = [];
    forEach(timeObj, (value, key) => {
      if (value) {
        const displayText = getDisplayValue(TIME_DICT[key], value);
        timeArr.push(`${value} ${displayText}`);
      }
    });
    return timeArr;
  }

  function getDisplayValue(dict, value) {
    if (!full) {
      return dict.short;
    }
    return value > 1 ? dict.plural : dict.singular;
  }
});
