import {
  Badge, darkTheme, HelpTooltip, SCHBox, StatusEnum,
} from '@bp/kung-fu';
import get from 'lodash/get';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { Anchorize } from '../../../../../../app/react/common/components';
import DetailsTable from '../../../../../../app/react/common/components/DetailsTable/DetailsTable';
import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';
import isMapping from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/isMapping';
import { selectors as FTSelectors } from '../../../../../../app/react/user/feature_toggles';
import { EnrichmentRule, Tag } from '../../../types';
import { getEnrichmentColumns } from '../../helpers/getEnrichmentColumns';
import { shouldUseTagOperations } from '../../helpers/should-use-tag-operations';
import EnrichmentRuleDetails from './EnrichmentRuleDetails';
import styles from './TagInfoContent.scss';
import { DotWrapper, FunctionTypeText, MappingText } from './TagInfoContent.style';

export interface IProps {
  tag: Tag;
}

const getHelpTooltipText = (enrichments: EnrichmentRule[]): string => `Automatically created based on uploaded Enrichment maps:${enrichments.map(
  (e) => `
• ${get(e, 'config.name')}`,
)}`.replace(/,/g, '');

export function TagInfoContent({
  tag: {
    active, type, order, enrichments, description,
  },
}: IProps): JSX.Element {
  const statusText = active ? 'active' : 'inactive';
  const ruleBamStatus = active ? StatusEnum.OK : StatusEnum.OFF;

  const setRowColor = useCallback(
    (state, rowInfo) => ({
      className: !get(rowInfo, ['original', 'active']) ? styles['inactive-cell'] : '',
    }),
    [],
  );

  const useTagOperations = shouldUseTagOperations(
    useSelector(FTSelectors.getFeatureToggles),
  );

  const columns = getEnrichmentColumns(type);

  return (
    <ThemeProvider theme={darkTheme}>
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.SectionRow title="Status:">
          <Badge text={statusText} status={ruleBamStatus} isCapitalized size="large" />
        </SettingsInfoScreen.SectionRow>

        <SettingsInfoScreen.SectionRow title="Function Type:">
          <SCHBox>
            <FunctionTypeText>{type}</FunctionTypeText>
            {isMapping(type) && (
              <>
                <DotWrapper>•</DotWrapper>
                <MappingText>Automatically created</MappingText>
                <HelpTooltip text={getHelpTooltipText(enrichments)} maxWidth="349px" />
              </>
            )}
          </SCHBox>
        </SettingsInfoScreen.SectionRow>

        <SettingsInfoScreen.SectionRow title="Execution Order:">
          <SCHBox gap="5px">
            {`#${order}`}
            <HelpTooltip text="The order of the tag in the execution plan" />
          </SCHBox>
        </SettingsInfoScreen.SectionRow>

        {(useTagOperations && description) && (
        <SettingsInfoScreen.SectionRow title="Description:">
          <Anchorize input={description} breakLines />
        </SettingsInfoScreen.SectionRow>
        )}

        <DetailsTable
          key={columns.length} // force re-render once the number of columns will be change
          title="Automatic Enrichment"
          counter={`(${enrichments.length} item${enrichments.length > 1 ? 's' : ''})`}
          items={enrichments.map((enrichment, index) => ({ index: `#${index + 1}`, ...enrichment }))}
          columnsSettings={columns}
          secondaryTitle="First matching item will be applied"
          helperContent="Automatic Enrichment applies the value of the first item which meets the conditions"
          DetailsPopup={EnrichmentRuleDetails}
          getTdProps={setRowColor}
        />
      </SettingsInfoScreen.Section>
    </ThemeProvider>
  );
}

export default TagInfoContent;
