import { react2angular } from 'react2angular';
import BpReactSelect from 'react/common/components/Select/Select';

angular
  .module('bigpanda')
  .component(
    'bpReactSelect',
    react2angular(BpReactSelect, [
      'value',
      'items',
      'getLabelDisplayValue',
      'multiChoice',
      'placeholder',
      'title',
      'variant',
      'fullWidth',
      'error',
      'disabled',
      'metadata',
      'onChange',
    ])
  );
