const {
  getProcessingMessage,
  getErrorMessageTransform,
} = require('angularjs/utils/notification/message.transforms.js');
const {
  setTagValueForIncidentSuccess,
  resetLoadingForIncident,
} = require('react/modules/tags/actions').default;
const unionBy = require('lodash/unionBy');

angular.module('bigpanda').service('LabelsStore', LabelsStore);

function LabelsStore(
  $q,
  $log,
  $ngRedux,
  LabelsBackendService,
  StreamConnector,
  SubscribersService,
  PromiseBatchService,
  deepCloneObject,
  UserFeatureTogglesService,
  pubSubService,
  BeforeUnloadService,
  notificationService,
  IncidentsService,
  execution,
  stateService
) {
  this.subscribe = subscribe;
  this.setIncidentTag = setIncidentTag;
  this.setBatchIncidentTags = setBatchIncidentTags;
  this.getIncidentTags = getIncidentTags;
  this.setCurrentIncident = setCurrentIncident;
  this.getMultipleIncidentTagDefinitions = getMultipleIncidentTagDefinitions;
  this.getDefinitions = getDefinitions;
  this.getDefinitionById = getDefinitionById;
  this.getPriorityDefinition = getPriorityDefinition;
  this.deleteIncidentTag = deleteIncidentTag;
  this.deleteMultipleIncidentsTag = deleteMultipleIncidentsTag;

  const incidentTagsCache = {};
  const subscribers = new SubscribersService();
  const getBatchIncidentTags = PromiseBatchService.batch(
    LabelsBackendService.getBatchIncidentTags,
    (incidentId, res) => res.incident_id === incidentId,
    200
  );
  let definitions = null;
  let currentIncident = null;

  pubSubService.on('incident.newData', incidentChanged);

  function incidentChanged(event, incident) {
    if (!stateService.getSelectedEnvironmentId()) {
      return;
    }
    const { id: incidentId, incident_tags: incidentTags } = incident;
    const labels = Object.keys(incidentTags).map((tagId) => ({
      id: tagId,
      value: incidentTags[tagId],
    }));
    updateCache(incidentId, {
      incident_id: incidentId,
      labels: labels,
    });
    updateIncidentTagsRedux(labels, incidentId);
    subscribers.fireStoreUpdated();
  }

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function setIncidentTag(incidentId, payload) {
    resetLoadingIncidentTagRedux();
    return LabelsBackendService.setIncidentTag(incidentId, payload).then(() => {
      const { tag_id: id, tag_value: value, name, email } = payload || {};
      const { labels: labelsCached } = incidentTagsCache[incidentId] || {};
      const mergedLabels = unionBy([{ id, value, name, email }], labelsCached, 'id');
      updateCache(incidentId, { incident_id: incidentId, labels: mergedLabels });
      updateIncidentTagsRedux(mergedLabels, incidentId);
      subscribers.fireStoreUpdated();
    });
  }

  function setBatchIncidentTags(currentIncidentId, payload) {
    const { incident_ids: ids, tags } = payload;
    BeforeUnloadService.start();

    if (!ids.length || !tags || !tags.length) {
      $log.error('LabelsStore - got empty request to setBatchIncidentTags');
      return;
    }

    $q.all(ids.map((id) => IncidentsService.getIncidentById(id))).then((incidents) => {
      if (ids.length !== incidents.length) {
        $log.error(`LabelsStore - missing incidents when querying: ${ids}`);
        return;
      }

      const title = `${incidents.length} incident${incidents.length > 1 ? 's' : ''}`;
      let operation = 'Changing multiple tags for';
      if (tags && tags.length === 1) {
        const { tag_id: tagId } = tags[0] || {};
        const tagDef = getDefinitionById(tagId);
        const tagName = (tagDef.name || 'tag').toLowerCase();
        operation = `Changing the ${tagName} for`;
      }

      notificationService
        .notify(getProcessingMessage(title, operation), { undo })
        .then((res) => {
          if (res.code === 'Undo') {
            return;
          }
          execution.runRetry(
            () =>
              LabelsBackendService.setBatchIncidentTags(payload).then(() => {
                const { incident_ids: incidentIds } = payload;
                incidentIds.forEach((incidentId) => {
                  const { labels: labelsCached } = incidentTagsCache[incidentId] || {};
                  const labelsPayload = payload.tags.map(({ tag_id: id, tag_value: value }) => ({
                    id,
                    value,
                  }));
                  const mergedLabels = unionBy(labelsPayload, labelsCached, 'id');
                  updateCache(incidentId, {
                    incident_id: incidentId,
                    labels: mergedLabels,
                  });
                  if (currentIncidentId === incidentId) {
                    updateIncidentTagsRedux(mergedLabels, incidentId);
                  }
                });
                subscribers.fireStoreUpdated();
              }),
            undo,
            {
              messageTransform: getErrorMessageTransform(title, 'Set Incident Tags'),
            }
          );
        })
        .finally(() => {
          BeforeUnloadService.end();
        });

      function undo() {}
    });
  }

  function getIncidentTags(incidentId, forceReload) {
    if (!forceReload && incidentTagsCache[incidentId]) {
      return $q.when(deepCloneObject.cloneDeep(incidentTagsCache[incidentId]));
    }

    return getBatchIncidentTags(incidentId)
      .then((res) => {
        const { labels } = res;
        const retVal = { incident_id: incidentId, labels };
        updateCache(incidentId, retVal);
        return retVal;
      })
      .catch(() => {
        updateCache(incidentId, { incident_id: incidentId, labels: [] });
        return incidentTagsCache[incidentId];
      });
  }

  function updateCache(incidentId, update) {
    const { labels } = update || {};
    incidentTagsCache[incidentId] = { incident_id: incidentId, labels };
  }

  function updateIncidentTagsRedux(labels, incidentId) {
    if (currentIncident && currentIncident === incidentId) {
      const incidentTagsForState = labels.map((tagValue) => ({
        tag_id: tagValue.id,
        incident_id: incidentId,
        tag_value: tagValue.value,
        name: tagValue.name,
        email: tagValue.email,
      }));
      $ngRedux.dispatch(setTagValueForIncidentSuccess(incidentTagsForState));
    }
  }

  function resetLoadingIncidentTagRedux() {
    $ngRedux.dispatch(resetLoadingForIncident());
  }

  function deleteFromCache(incidentId, tagId) {
    const { labels } = incidentTagsCache[incidentId] || {};
    const labelsUpdated = labels.filter(({ id }) => id !== tagId);
    incidentTagsCache[incidentId] = { incident_id: incidentId, labels: labelsUpdated };
  }

  function getMultipleIncidentTagDefinitions(environmentId) {
    return LabelsBackendService.getMultipleIncidentTagDefinitions(environmentId)
      .then((data) => {
        definitions = data;
        if (definitions && definitions.length) {
          pubSubService.broadcast('incidentTagsDefinitions.updated');
        }
        return definitions;
      })
      .catch(() => []);
  }

  function getDefinitions() {
    return definitions;
  }

  function getDefinitionById(id) {
    return definitions && definitions.find((def) => def.id === id);
  }

  function getPriorityDefinition() {
    return (definitions && definitions.find((def) => def.type === 'Priority')) || {};
  }

  function deleteIncidentTag(incidentId, tagId) {
    resetLoadingIncidentTagRedux();
    return LabelsBackendService.deleteIncidentTag(incidentId, tagId).then(() => {
      deleteFromCache(incidentId, tagId);
      subscribers.fireStoreUpdated();
      $ngRedux.dispatch(setTagValueForIncidentSuccess({}));
    });
  }

  function deleteMultipleIncidentsTag(tagId, payload) {
    resetLoadingIncidentTagRedux();
    return LabelsBackendService.deleteMultipleIncidentsTag(tagId, payload).then(() => {
      const { incident_ids: incidentIds } = payload;
      incidentIds.forEach((incidentId) => deleteFromCache(incidentId, tagId));
      subscribers.fireStoreUpdated();
    });
  }

  function setCurrentIncident(currentIncidentId) {
    currentIncident = currentIncidentId;
  }
}
