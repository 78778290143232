import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import { BamModal } from '@bp/bam';
import usersSelectors from 'react/common/users/selectors';
import { selectors as userSelectors } from 'react/user/details';
import actions from '../actions';
import selectors from '../selectors';
import ApiKeyForm from './ApiKeyForm';

class ApiKeyModal extends React.Component {
  handleClose = () => {
    this.ref.close();
  };

  render() {
    const { titleIcon, title, triggerProps } = this.props;
    return (
      <BamModal
        ref={(r) => {
          this.ref = r;
        }}
        trigger={<ModalTriggerButton {...triggerProps} />}
        titleIcon={titleIcon}
        title={title}
        docsLink="https://docs.bigpanda.io/docs/api-key-management"
        onMount={this.onMountModal}
        formMode
        autoFocus
        style={{ width: 450 }}
      >
        <ApiKeyForm {...this.props} close={this.handleClose} />
      </BamModal>
    );
  }
}

ApiKeyModal.propTypes = {
  titleIcon: PropTypes.string.isRequired,
  updateApiKey: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  currentApiKeyId: PropTypes.string,
  apiKey: PropTypes.shape({}),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  currentUserId: PropTypes.string,
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string,
};

ApiKeyModal.defaultProps = {
  currentApiKeyId: undefined,
  apiKey: undefined,
  users: undefined,
  currentUserId: undefined,
  title: '',
  onCreate: null,
};

function mapStateToProps(state, ownProps) {
  return {
    apiKey: selectors.getApiKey(state, ownProps.currentApiKeyId),
    users: usersSelectors.getUsers(state),
    currentUserId: userSelectors.getUserId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateApiKey: (apiKey) => dispatch(actions.updateApiKey(apiKey)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(ApiKeyModal));
