import { BamTile } from '@bp/bam';
import { HBox } from '@bp/kung-fu';
import React from 'react';
import styled from 'styled-components';

import BpTimeSince from '../../../../../../app/common/utils/BpTimeSince';
import dictionary from '../../dictionary';
import { AlertFilter } from '../../types/AlertFilter';
import { SortMode } from '../../types/SortMode';
import { CREATED_SORT_MODE } from '../../types/SortModes';
import alertFilterUrl from '../../utils/alertFilterUrl';
import { filterStatus } from '../../utils/filterStatus';
import { AlertFilteringActionButtons } from './AlertFilteringActionButtons';

const Tile = styled(BamTile)`
  width: 100%;
`;

interface IProps {
  filter: AlertFilter;
  sortMode: SortMode;
}

const generateFirstRow = (filter: AlertFilter, sortMode: SortMode): object => ({
  title: filter.name,
  time: sortMode === CREATED_SORT_MODE
    ? { title: dictionary.created, timestamp: BpTimeSince(filter.created_on) }
    : { title: dictionary.updated, timestamp: BpTimeSince(filter.updated_on) },
});

const generateSecondRow = (filter: AlertFilter): object => {
  const textParts = [
    { label: dictionary.systems, value: filter?.sourceSystems?.map(({ display }) => display).join(', ') },
  ];

  return {
    textParts,
    actionsBundle: <AlertFilteringActionButtons filter={filter} />,
  };
};

export function ListItem({ filter, sortMode, ...rest }: IProps): JSX.Element {
  const handleClick = (): void => {
    window.location.href = alertFilterUrl(filter.id);
  };

  return (
    <HBox role="listitem" aria-label={`${filter.name} plan`} data-testid={`${filter.name} plan listitem`}>
      <Tile
        bamStatus={filterStatus(filter).color}
        firstRow={generateFirstRow(filter, sortMode)}
        secondRow={generateSecondRow(filter)}
        onClick={handleClick}
        inactive={!filter.active}
        {...rest}
      />
    </HBox>
  );
}
