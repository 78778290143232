import { react2angular } from 'react2angular';
import PersonalSettings from './components/PersonalSettings';

const routes = {};

// Unuesed, rename to appSettingsPersonal to use
routes.appSettingsPersonal = {
  url: '/personal',
  views: {
    'settings-page-view': {
      template: '<personal-settings store="store" class="personal-settings-card"/>',
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
  },
  onEnter: ($transitions, pubSubService) => {
    'ngInject';

    $transitions.onSuccess({ to: 'app.settings.personal' }, () => {
      pubSubService.broadcast('settingsPageChanged', 'personal');
    });
  },
};

angular.module('bigpanda').component('personalSettings', react2angular(PersonalSettings));

export default routes;
