import {
  CheckIcon, IOption, Theme, Tooltip,
} from '@bp/kung-fu';
import React from 'react';

import ItemWrapper from './index.style';

function EnvRuleTagSelectItem(props: IOption): JSX.Element {
  const {
    text, id, type, isSelected,
  } = props;
  const display = type !== 'ALERT_TAGS' ? `incident.${id}` : id;

  return (
    <ItemWrapper>
      <Tooltip text={display} placement="top">
        <span>{text}</span>
      </Tooltip>
      {isSelected && <CheckIcon color={({ theme }: { theme: Theme }): string => theme.bp_blue} />}
    </ItemWrapper>
  );
}

export default EnvRuleTagSelectItem;
