import sumBy from 'lodash/sumBy';
import actionTypes from './actionTypes';
import { ANY_OPTION_VALUE } from './constants';

export const initialState = {
  isLoading: false,
  filterText: '',
  tags: undefined,
  draggedItems: undefined,
  filtersDropDownItems: {
    status: [],
    type: [],
    source: [],
  },
  filters: {
    status: { value: ANY_OPTION_VALUE, text: 'Any Status' },
    source: { value: ANY_OPTION_VALUE, text: 'Any Source' },
    type: { value: ANY_OPTION_VALUE, text: 'Any Type' },
  },
  previewAlerts: undefined,
  previewLoading: false,
  previewRule: undefined,
  currentTag: undefined,
  uniquePreviewTags: undefined,
  reservedTags: undefined,
  reservedTagsNames: undefined,
  totalNumOfRules: 0,
};

const shouldSetPreviewAlerts = (
  { isNew, destination, events: previewAlerts, uniquePreviewTags },
  { currentTag }
) => (isNew || destination === currentTag ? { previewAlerts, uniquePreviewTags } : {});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS:
      return {
        ...state,
        filtersDropDownItems: {
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_FILTER_TEXT:
      return {
        ...state,
        filterText: action.payload,
      };
    case actionTypes.LOAD_ENRICHMENT_TAGS:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOAD_ENRICHMENT_TAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tags: action.payload,
        totalNumOfRules: Array.isArray(action.payload)
          ? sumBy(action.payload, ({ enrichments = [] }) => enrichments.length)
          : state.totalNumOfRules,
      };
    case actionTypes.UPDATE_SORT:
      return {
        ...state,
        sortMode: action.payload,
      };
    case actionTypes.UPDATE_FILTERS_DROPDOWNS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case actionTypes.SET_DRAGGED_ITEMS:
      return {
        ...state,
        draggedItems: action.payload,
      };
    case actionTypes.LOAD_PREVIEW_ALERTS:
      return {
        ...state,
        previewRule: action.payload.rawRule,
        previewAlerts: undefined,
        uniquePreviewTags: undefined,
        previewLoading: true,
      };
    case actionTypes.LOAD_PREVIEW_ALERTS_SUCCESS:
      return {
        ...state,
        previewLoading: false,
        ...shouldSetPreviewAlerts(action.payload, state),
      };
    case actionTypes.LOAD_PREVIEW_ALERTS_FAILURE:
      return {
        ...state,
        previewLoading: false,
      };
    case actionTypes.CLEAR_PREVIEW_ALERTS:
      return {
        ...state,
        previewLoading: false,
        previewAlerts: undefined,
        uniquePreviewTags: undefined,
        previewRule: undefined,
      };
    case actionTypes.SET_CURRENT_TAG:
      return {
        ...state,
        previewLoading: false,
        previewAlerts: undefined,
        uniquePreviewTags: undefined,
        currentTag: action.payload,
      };
    case actionTypes.LOAD_RESERVED_TAGS_SUCCESS:
      return {
        ...state,
        reservedTags: action.payload.reservedTags,
        reservedTagsNames: action.payload.reservedTagsNames,
      };
    default:
      return state;
  }
}
