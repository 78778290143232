import { IColumn } from '@bp/kung-fu';

import {
  ActionColumn, DateColumn, UserColumn,
} from '../../components/AuditLogTable/components/Columns';

const columnDefaultSize = 200;

export const COUNT_PER_PAGE = 50;

export const columnData: IColumn[] = [
  {
    cellRenderer: UserColumn,
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'User',
    width: columnDefaultSize + 75,
    key: 'user',
  },
  {
    cellRenderer: ActionColumn,
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Action',
    width: columnDefaultSize - 25,
    key: 'action',
  },
  {
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Category',
    width: columnDefaultSize - 20,
    key: 'category.rowValue',
  },
  {
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Object Name',
    width: columnDefaultSize + 25,
    key: 'changedObject',
  },
  {
    cellRenderer: DateColumn,
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Date',
    width: columnDefaultSize,
    key: 'date',
  },
] as IColumn[];

export const EnvAllName = 'All';

export const MapCategoryRowName: Record<string, string> = {
  correlation_pattern: 'Alert Correlation',
  auto_share_rules: 'AutoShare',
  environments: 'Environment',
  users: 'User Management',
  roles: 'Roles',
  jit_domains: 'JIT Provisioning',
  jit_roles: 'SAML Attribute Mapping',
  api_keys: 'API Keys',
  sso_test: 'SSO Test',
  sso_config: 'SSO Configuration',
  tag: 'Tag',
  tags_order: 'Tags Order',
  tag_enrichments: 'Tag Enrichments',
  tag_enrichments_order: 'Tag Enrichments Order',
  mapping_enrichment: 'Mapping Enrichment',
  mapping_table_upload: 'Mapping Table Upload',
  integrations: 'Integrations',
  alert_filtering: 'Alert Filtering',
  maintenacne_plan: 'Maintenance Plan',
};

export const MapCategoryExpandRowName: Record<string, string> = {
  correlation_pattern: 'Correlation Pattern',
  auto_share_rules: 'AutoShare',
  environments: 'Environment',
  users: 'User',
  roles: 'Role',
  jit_domains: 'JIT Provisioning',
  jit_roles: 'SAML Attribute Mapping',
  api_keys: 'API Key',
  sso_test: 'SSO Test',
  sso_config: 'SSO Configuration',
  tag: 'Tag',
  tag_order: 'Tag Order',
  tag_enrichments: 'Tag Enrichments',
  tag_enrichments_order: 'Tag Enrichments Order',
  mapping_enrichment: 'Mapping Enrichment',
  mapping_table_upload: 'Mapping Table Upload',
  integrations: 'Integrations',
  alert_filtering: 'Alert Filtering',
  maintenacne_plan: 'Maintenance Plan',
};
