import { RuleType, Tag } from '../../types';

/**
 * this function should prepare a cloned tag, specifically for tag duplication action
 * which enabled in the alert-enrichment lobby, for each tag
 */
export const getDuplicatedTag = (tag: Tag, allTags: Tag[]): Tag | undefined => {
  const filteredRules = (tag.enrichments || []).map((rule) => rule.type !== RuleType.Mapping && rule).filter(Boolean);

  if (!filteredRules.length) {
    return undefined;
  }

  return {
    ...tag,
    name: `copy of ${tag.name}`,
    enrichments: filteredRules,
    order: allTags.length + 1,
  };
};
