import actionTypes from './actionTypes';
import { baseSettingInitialState, generateBaseSettingsReducer } from '../../redux.utils';

export const initialState = {
  ...baseSettingInitialState,
  plans: undefined,
  error: undefined,
  isPaginating: false,
  showDonePlans: true,
};

const transformPlans = (currentPlans = [], plans) => [
  ...currentPlans,
  ...plans.map((plan) => ({
    ...plan,
    ...{ lowerCasedName: plan.name.toLowerCase() },
  })),
];

const parseLink = (linkHeader) =>
  linkHeader && linkHeader.split('next=')[1].split('>')[0].split('&')[0];

const baseReducer = generateBaseSettingsReducer(actionTypes, initialState);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_PLANS:
      return {
        ...state,
        isLoading: false,
        isPaginating: true,
      };
    case actionTypes.LOAD_PLANS_SUCCESS:
      return {
        ...state,
        plans: transformPlans(state.plans, action.payload.data ? action.payload.data : []),
        link: parseLink(action.payload.link),
        isLoading: false,
        isPaginating: false,
        showDonePlans: action.payload.showDonePlans,
      };
    case actionTypes.LOAD_INITIAL_PLANS:
      return {
        ...state,
        plans: [],
        isLoading: true,
        showDonePlans: action.payload.active,
      };

    default:
      return baseReducer(state, action);
  }
}
