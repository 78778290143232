import permissions from 'common/routing/permissions';
import get from 'lodash/get';
import { compose, curry, identity } from 'lodash/fp';
import flatMap from 'lodash/flatMap';
import { filterItems, filterItemsByDropDownFilters, generateBaseSelectors } from '../redux.utils';
import {
  path,
  ANY_OPTION_VALUE,
  RULE_CONTACTS_PATH,
  RULES_CONTACT_TYPES,
  RULES_STATUSES,
} from './constants';

const mapUsers = (autoShareRule, contacts) => {
  if (contacts) {
    const contactsFlat = contacts.map((contact) => {
      const contactPhone = get(contact, ['phone_number'], '');
      const contactEmail = get(contact, ['email'], '');
      const contactName = get(contact, ['name'], '');
      const contactId = get(contact, ['_id']);

      if (!contactId) {
        return contactName;
      } else if (autoShareRule.system.id === RULES_CONTACT_TYPES.email) {
        return contactName + contactEmail;
      } else if (autoShareRule.system.id === RULES_CONTACT_TYPES.sms) {
        return contactName + contactPhone;
      }
    });

    return contactsFlat;
  }
  return [];
};

const autoShareRulesFilterByDropDown = (filtersDropDownState, items) => {
  return filterItemsByDropDownFilters(
    filtersDropDownState,
    {
      environment: (item) => [item.environment._id],
      channel: (item) => [item.system.system_id],
      recipients: (item) => mapUsers(item, get(item, RULE_CONTACTS_PATH)),
      status: (item) => [item.inactive ? RULES_STATUSES.inactive : RULES_STATUSES.active],
    },
    items,
    ANY_OPTION_VALUE
  );
};

const contactsFilterInfo = (rule) => {
  const contacts = get(rule, ['params', 'contacts'], []);
  return flatMap(contacts, (contact) => [contact.name, contact.email, contact.phone_number]);
};

const autShareRulesFilterByText = (text, items) =>
  filterItems(items, text, (rule) =>
    [rule.system.name, rule.environment.name].concat(contactsFilterInfo(rule))
  );

const selectors = {
  ...generateBaseSelectors(path),
  getFilterText: (state) => get(state, path).filterText,
  isLoading: (state) => get(state, path).isLoading,
  getFiltersDropDownState: (state) => get(state, path).filtersDropDownState,
  getActionsPermissions: (state) => {
    const userPermissions = get(state, ['user', 'permissions', 'list'], []);
    const canEdit = userPermissions.includes(permissions.notifications.update);
    const canDelete = userPermissions.includes(permissions.notifications.delete);
    return { canEdit, canDelete };
  },
  getAutoShareRule: (state, id) => {
    const rules = selectors.getAutoShareRules(state);
    return (rules || []).find((rule) => rule.id === id);
  },
  getAutoShareRules: (
    state,
    { shouldFilterByText = true, shouldFilterByDropDownFilters = true } = {}
  ) => {
    const rules = get(state, path).rules;
    if (!rules || rules.length === 0) [];

    return compose(
      shouldFilterByDropDownFilters
        ? curry(autoShareRulesFilterByDropDown)(selectors.getFiltersDropDownState(state))
        : identity,
      shouldFilterByText
        ? curry(autShareRulesFilterByText)(selectors.getFilterText(state))
        : identity
    )(get(state, path).rules);
  },
};

export default selectors;
