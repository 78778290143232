import { react2angular } from 'react2angular';
import provideStore from 'react/utils/provideStore';
import TroubleshootingButton from 'react/modules/integrations/Troubleshooting/TroubleshootingButton/TroubleshootingButton';

angular
  .module('bigpanda')
  .component(
    'reactTroubleshootingButton',
    react2angular(provideStore(TroubleshootingButton), ['store', 'integration'])
  );

angular.module('bigpanda').directive('troubleshootingButton', troubleshootingButton);

function troubleshootingButton() {
  return {
    restrict: 'E',
    scope: {
      integration: '=',
    },
    templateUrl: 'integrations/integration_info/troubleshooting/react_troubleshooting_button',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($ngRedux) {
    const vm = this;
    vm.store = $ngRedux;
  }
}
