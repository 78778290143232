import { BamBpqlInput } from '@bp/bam';
import { ISelectOption } from '@bp/kung-fu';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import { tripleEqualSignRegex } from '../../../../../../app/react/helpers/regex';
import { MaintenancePlan } from '../../../types/MaintenancePlan';
import { DispatchPayload } from '../modal/types/DispatchPayload';
import { EMaintenanceFrequency } from './types/EMaintenanceFrequency';
import { EMaintenanceRepeat } from './types/EMaintenanceRepeat';
import { MaintenancePlanForm } from './types/MaintenancePlanForm';
import { TimeframeData } from './types/TimeframeData';

export const validateName = (val = ''): string => {
  if (val.trim().length < 2) {
    return 'Enter a plan name with at least two characters';
  }
  return undefined;
};

export const validateCondition = (val = ''): string => {
  if (!val.trim()) return 'Condition cannot be empty';
  const bpqlInputValidation = BamBpqlInput.helpers.BpqlInputValidator(val);
  if (bpqlInputValidation) {
    if (tripleEqualSignRegex.test(bpqlInputValidation)) {
      return bpqlInputValidation.replace(tripleEqualSignRegex, '"');
    }
    return bpqlInputValidation;
  }
  if (tripleEqualSignRegex.test(val)) {
    return 'Expected whitespace, quotes, slash or a character but "=" found.';
  }
  return undefined;
};

export interface ITimeFrame {
  from: moment.Moment,
  to: moment.Moment,
}

type IPayloadProps = MaintenancePlanForm & { endRepeat: number; repeat: ISelectOption; };

export const getPayload = (formValues: IPayloadProps):
DispatchPayload => {
  const {
    from, to, endRepeat, repeat, ...values
  } = formValues;
  const fromUTC = from.clone().utc();

  const times = values.frequency === EMaintenanceFrequency.Once
    ? { from, to }
    : { from: fromUTC.startOf('minute'), to: moment.unix(endRepeat).endOf('day') };

  const frequencyData = values.frequency === EMaintenanceFrequency.Once
    ? {}
    : {
      frequencyData: {
        ...(values.frequency !== EMaintenanceFrequency.Once
          ? { duration: to.diff(from, 'seconds') }
          : {}
        ),
        ...(repeat.id === EMaintenanceRepeat.MONTHLY_ON_DAY
          ? { relativity: capitalize(repeat.relativity) }
          : {}
        ),
      },
    };

  return {
    plan: {
      ...values,
      times,
      ...frequencyData,
      condition: BamBpqlInput.helpers.StringToBpql(values.condition),
    } as MaintenancePlan & { times: object },
    active: true,
  };
};

export const getTimeframeData = (
  values: MaintenancePlan,
  initialStartTime: moment.Moment = getNextHalfHour(),
  isNew = false,
): TimeframeData => {
  const {
    start, end, frequency_data: frequencyData, frequency,
  } = values || {};

  if (!isNew && !frequencyData?.duration && frequency === EMaintenanceFrequency.Once) {
    const nextStart = moment.unix(start);
    const diff = moment.unix(end).diff(nextStart);
    return {
      startDate: nextStart,
      endDate: nextStart.clone().add(diff, 'milliseconds'),
    };
  }

  const startDate = (!isNew && start) ? moment.unix(frequencyData.next_run) : initialStartTime;
  const endDate = (!isNew && frequencyData?.duration) ? moment(startDate).add(frequencyData.duration, 'seconds') : moment(startDate).add(3, 'hours');

  return {
    startDate, endDate,
  };
};

export const getNextHalfHour = (): moment.Moment => {
  const remainder = 30 - (moment().minute() % 30);
  return moment().add(remainder, 'minutes').startOf('minute');
};
