angular.module('bigpanda').service('IntercomInitService', IntercomInitService);

function IntercomInitService($window, $q, $rootScope, Config) {
  const appId = Config.intercomAppId;
  const deferred = $q.defer();
  let intercomObj = null;
  let dummyIntercom;

  $window.intercomSettings = {
    app_id: appId,
  };

  this.boot = boot;
  this.track = track;
  this.shutdown = shutdown;
  this.update = update;
  this.reattachActivator = reattachActivator;
  this.hide = hide;
  this.show = show;
  this.showNewMessage = showNewMessage;
  this.onShow = onShow;
  this.onHide = onHide;
  this.onActivatorClick = onActivatorClick;

  function initIntercom() {
    const w = $window;
    const ic = w.Intercom;
    if (angular.isFunction(ic)) {
      ic('reattach_activator');
      ic('update', $window.intercomSettings);
    } else {
      const i = () => {
        i.c(arguments);
      };
      i.q = [];
      i.c = (args) => {
        i.q.push(args);
      };
      w.Intercom = i;
      dummyIntercom = i;

      loadScript();
    }
  }

  function loadScript() {
    const d = document;
    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${appId}`;
    const x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }

  function track(eventName, options) {
    invoke('trackEvent', eventName, options);
  }

  function shutdown() {
    invoke('shutdown');
  }

  function update(arg) {
    invoke('update', arg);
  }

  function reattachActivator() {
    invoke('reattach_activator');
  }

  function hide() {
    invoke('hide');
  }

  function show() {
    invoke('show');
  }
  function showNewMessage(message) {
    invoke('showNewMessage', message);
  }

  function onShow(cb) {
    invoke('onShow', cb || angular.noop);
  }

  function onHide(cb) {
    invoke('onHide', cb || angular.noop);
  }

  function onActivatorClick() {
    invoke('onActivatorClick');
  }

  function getIntercom() {
    const stopWatch = $rootScope.$watch(
      () => $window.Intercom,
      (newVal, oldVal) => {
        if (angular.isFunction(newVal) && newVal !== dummyIntercom) {
          deferred.resolve(newVal);
          stopWatch();
        }
      }
    );
    return deferred.promise;
  }

  function boot(options) {
    let _options;
    initIntercom();
    return getIntercom().then((intercom) => {
      _options = angular.extend({}, options, { app_id: appId });
      intercom('boot', _options);
      intercomObj = intercom;
    });
  }

  function invoke() {
    if (!intercomObj) {
      return;
    }
    intercomObj.apply(undefined, arguments);
  }
}
