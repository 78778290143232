angular.module('bigpanda').directive('activityFeedList', activityFeedList);

function activityFeedList() {
  return {
    restrict: 'E',
    scope: {
      events: '<',
      limitDisplayedEvents: '=',
      removeSeparator: '@',
    },
    templateUrl: 'overview/incidents/info/activity_feed/activity_feed_list/activity_feed_list',
    bindToController: false,
  };
}
