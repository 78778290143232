import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { combineEpics } from 'redux-observable';
import * as incidentLabelsEndpoint from 'common/endpoints/incident-labels';
import actionTypes from './actionTypes';
import actions from './actions';
import { AI_GENERIC_ERROR, AI_OPERATION_IN_PROGRESS, path } from './constants';

const getIncidentTags = (action$) =>
  action$.ofType(actionTypes.GET_INCIDENT_TAGS).mergeMap(async ({ payload }) => {
    const { environmentId, incidentId } = payload;
    try {
      const response = await incidentLabelsEndpoint.getLabelsForIncident(environmentId, incidentId);
      return actions.getIncidentTagsSuccess(response);
    } catch (e) {
      return actions.getIncidentTagsFailure();
    }
  });

const setTagValueForIncident = (action$, state) =>
  action$.ofType(actionTypes.SET_TAG_VALUE_FOR_INCIDENT).mergeMap(async ({ payload }) => {
    const { environmentId, incidentId, tagId, tagValue, name, email, timestamp } = payload;
    const currentState = state.getState();
    const incidentTagsData = cloneDeep(get(currentState, path).incidentTagsData);
    const index = findIndex(incidentTagsData, { tag_id: tagId });
    if (index > -1) {
      incidentTagsData[index].tag_value = tagValue;
      incidentTagsData[index].name = name;
      incidentTagsData[index].email = email;
      incidentTagsData[index].timestamp = timestamp;
    } else {
      incidentTagsData.push({
        incident_id: incidentId,
        tag_id: tagId,
        tag_value: tagValue,
        name,
        email,
        timestamp,
      });
    }
    try {
      await incidentLabelsEndpoint.setTagValueForIncident(
        environmentId,
        incidentId,
        tagId,
        tagValue
      );
      return actions.setTagValueForIncidentSuccess(incidentTagsData);
    } catch (e) {
      return actions.setTagValueForIncidentFailure();
    }
  });

const removeTagValueFromIncident = (action$, state) =>
  action$.ofType(actionTypes.REMOVE_TAG_VALUE_FROM_INCIDENT).mergeMap(async ({ payload }) => {
    const { environmentId, incidentId, tagId } = payload;
    const currentState = state.getState();
    const incidentTagsData = cloneDeep(get(currentState, path).incidentTagsData);
    const index = findIndex(incidentTagsData, { tag_id: tagId });
    if (index > -1) {
      incidentTagsData.splice(index, 1);
    }
    try {
      await incidentLabelsEndpoint.removeTagValueFromIncident(environmentId, incidentId, tagId);
      return actions.removeTagValueFromIncidentSuccess(incidentTagsData);
    } catch (e) {
      return actions.removeTagValueFromIncidentFailure();
    }
  });

const createAiAnalysis = (action$) =>
  action$.ofType(actionTypes.CREATE_AI_ANALYSIS).mergeMap(async ({ payload }) => {
    const { environmentId, incidentId } = payload;
    try {
      const response = await incidentLabelsEndpoint.createAiAnalysis(environmentId, incidentId);
      return actions.createAiAnalysisSuccess(response);
    } catch (e) {
      if (e.errors && e.errors.includes(AI_OPERATION_IN_PROGRESS)) {
        return actions.createAiAnalysisSuccess({});
      }

      const ERROR_TYPE = e.errors ? e.errors[0] : AI_GENERIC_ERROR;
      return actions.createAiAnalysisFailure(ERROR_TYPE);
    }
  });

export default combineEpics(
  getIncidentTags,
  setTagValueForIncident,
  removeTagValueFromIncident,
  createAiAnalysis
);
