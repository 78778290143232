export default {
  options: {
    lastChange: 'last_change',
    status: 'status',
    start: 'start',
    alerts: 'alerts',
    duration: 'duration',
    created: 'created',
  },
  titles: {
    last_change: 'Changed:',
    status: 'Changed:',
    start: 'Created:',
    alerts: 'Changed:',
    duration: 'Duration:',
    created: 'Created:',
  },
};
