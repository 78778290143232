const identityProviders = [
  { name: 'onelogin', display: 'OneLogin', imgSrc: '/assets/img/logos/onelogin.png' },
  { name: 'okta', display: 'Okta', imgSrc: '/assets/img/logos/okta.png' },
  { name: 'pingone', display: 'PingOne', imgSrc: '/assets/img/logos/pingone.png' },
  {
    name: 'ca',
    display: 'CA Single Sign On',
    imgSrc: 'assets/img/logos/ca.png',
    options: { featureToggle: true },
  },
];

export function getAllIdentityProviders() {
  return identityProviders;
}

export function findIdP(id) {
  return identityProviders.find((idp) => idp.name === id);
}
