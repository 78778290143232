import get from 'lodash/get';
/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BamRangeDatePicker, TimeFormats } from '@bp/bam';
import { Button, HBox } from '@bp/kung-fu';
import FiltersDropdown from 'react/common/components/FiltersDropdown/FiltersDropdown';

import { ANY_OPTION_VALUE, CUSTOM_DATES_RANGE, datesRangeOptions, LAST_7_DAYS } from '../constants';
import selectors from '../selectors';
import actions from '../actions';
import { troubleshootingDataPropType } from '../propTypes';
import styles from './TroubleshootingModalFilters.scss';

const { momentOrEpochRangeRelativeTimeFrameFormat } = TimeFormats;

class TroubleshootingModalFilters extends React.Component {
  customDateRef = React.createRef();

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideDatePicker);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideDatePicker);
  }

  onClickOutsideDatePicker = (event) => {
    const {
      troubleshootingData: { datePickerState },
      hideDatePicker,
    } = this.props;
    if (datePickerState && this.customDateRef) {
      const path = event.path || (event.composedPath && event.composedPath());
      if (path && !path.includes(this.customDateRef.current)) {
        hideDatePicker();
      }
    }
  };

  render() {
    const {
      troubleshootingData,
      isLoading,
      onFilterChange,
      showDatePicker,
      hideDatePicker,
      setCustomDateRange,
    } = this.props;
    const {
      filtersDropDownState,
      descriptions,
      datePickerState,
      datePickerFrom,
      datePickerTo,
      getRequestStatus,
    } = troubleshootingData;
    const {
      description: { value: descriptionValue, text: descriptionText },
      datesRange: { value: dateRangeValue, text: dateRangeText },
    } = filtersDropDownState;
    const customRangeText = momentOrEpochRangeRelativeTimeFrameFormat({
      from: moment(datePickerFrom).format('X'),
      to: moment(datePickerTo).format('X'),
    });
    return (
      <React.Fragment>
        <span className={styles.filter}>
          <FiltersDropdown
            data={descriptions}
            selectedValue={descriptionValue}
            selectedText={descriptionText}
            defaultText="Description"
            onChange={(e, selection) => onFilterChange({ description: selection })}
            anyOptionValue={ANY_OPTION_VALUE}
            aria-label="description filter combobox"
            disabled={isLoading}
          />
        </span>
        <span className={styles.filter}>
          <FiltersDropdown
            data={datesRangeOptions}
            selectedValue={dateRangeValue}
            selectedText={
              dateRangeValue === CUSTOM_DATES_RANGE.value
                ? customRangeText.substr(1, customRangeText.length - 2)
                : dateRangeText
            }
            defaultText={LAST_7_DAYS.text}
            onChange={(e, selection) => {
              if (selection.value === CUSTOM_DATES_RANGE.value) {
                showDatePicker();
              } else {
                onFilterChange({ datesRange: selection });
              }
            }}
            anyOptionValue={LAST_7_DAYS.value}
            disabled={isLoading || datePickerState}
            aria-label="date filter combobox"
          />
          {datePickerState && !isLoading && (
            <div ref={this.customDateRef} className={styles.customDate}>
              <div className={styles.customDate__header}>Pick a Date Range</div>
              <div className={styles.customDate__picker}>
                <BamRangeDatePicker
                  input={{
                    value: {
                      from: moment(datePickerFrom),
                      to: moment(datePickerTo),
                    },
                    onChange: ({ from, to }) =>
                      setCustomDateRange({
                        from: Number(from.format('x')),
                        to: Number(to.format('x')),
                      }),
                  }}
                  showTime
                  minDate={moment().subtract(7, 'days')}
                  maxDate={moment()}
                  overrideMinMax={false}
                  baseProps={{
                    title: { from: 'Start Date', to: 'End Date' },
                  }}
                  aria-label="custom date range picker"
                />
              </div>
              <HBox gap="5px" className={styles.customDate__footer}>
                <Button
                  width="80px"
                  variant="primary"
                  size="medium"
                  onClick={() => onFilterChange({ datesRange: CUSTOM_DATES_RANGE })}
                >
                  Apply
                </Button>

                <Button width="80px" variant="secondary" size="medium" onClick={hideDatePicker}>
                  Cancel
                </Button>
              </HBox>
            </div>
          )}
        </span>
      </React.Fragment>
    );
  }
}

TroubleshootingModalFilters.propTypes = {
  troubleshootingData: troubleshootingDataPropType.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  showDatePicker: PropTypes.func.isRequired,
  hideDatePicker: PropTypes.func.isRequired,
  setCustomDateRange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  const troubleshootingData = selectors.getTroubleshooting(state);
  const isLoading = selectors.getIsLoading(state);
  return {
    organization,
    user,
    troubleshootingData,
    isLoading,
  };
};

const mapDispatchToProps = {
  showDatePicker: actions.showDatePicker,
  hideDatePicker: actions.hideDatePicker,
  setCustomDateRange: actions.setCustomDateRange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hot(module)(TroubleshootingModalFilters));
