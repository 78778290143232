import { path as prevPath } from '../constants';
import styles from './topbar.scss';

export const name = 'topbar';
export const path = `${prevPath}.${name}`;

export const nonShowTypes = ['pov', 'live'];
export const organizationTypes = {
  live: {
    orgType: 'Live',
  },
  test: {
    orgType: 'Test',
    orgColor: 'bp_orange',
    orgClass: styles['org-status-bar_test'],
  },
  'live-sandbox': {
    orgType: 'Live-sandbox',
    orgColor: 'bp_orange',
    orgClass: styles['org-status-bar_test'],
  },
  internal: {
    orgType: 'Internal',
    orgColor: 'bp_green',
    orgClass: styles['org-status-bar_internal'],
  },
  pov: {
    orgType: 'POV',
    orgColor: 'bp_turq',
    orgClass: styles['org-status-bar_pov'],
  },
  demo: {
    orgType: 'Demo',
    orgColor: 'bp_pink',
    orgClass: styles['org-status-bar_demo'],
  },
};
