angular.module('bigpanda').service('TabsResizableStore', TabsResizableStore);

function TabsResizableStore() {
  const state = {};

  this.updateState = updateState;
  this.getState = getState;

  function updateState(key, value) {
    state[key] = value;
  }

  function getState(key) {
    return state[key];
  }
}
