import upperFirst from 'lodash/capitalize';
import get from 'lodash/get';

import utils from './utils';

export const name = 'autoShareRules';

export const path = 'modules.settings.autoShareRules';

export const urlRoute = (id) => `/#/app/settings/rules/${id}`;

const customSystemIcons = {
  sms: 'sms-1',
};
export const systemIconClass = (systemId) => `bp-icon-${customSystemIcons[systemId] || systemId}`;

export const getNameAndIcon = (rule) => {
  const ruleWithOptions = get(rule, 'autoshare_options');
  const ruleDisplayName = ruleWithOptions
    ? ruleWithOptions.display
    : utils.systemDisplayText(rule.system);

  const iconName = ruleWithOptions
    ? ruleWithOptions.type
    : get(rule, ['system', 'parent_system_id']);
  const parentSystemIdForIcon = systemIconClass(iconName);

  return { systemName: ruleDisplayName, systemIcon: parentSystemIdForIcon };
};

export const ANY_OPTION_VALUE = '-1';

export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  environment: { value: ANY_OPTION_VALUE, text: 'Any Environment' },
  channel: { value: ANY_OPTION_VALUE, text: 'Any Channel' },
  recipients: { value: ANY_OPTION_VALUE, text: 'Any Recipient' },
  status: { value: ANY_OPTION_VALUE, text: 'Any Status' },
};

export const RULES_STATUSES = {
  active: 'active',
  inactive: 'inactive',
};

export const DROPDOWN_FILTER_STATUS_ITEMS = [
  {
    key: RULES_STATUSES.active,
    text: upperFirst(RULES_STATUSES.active),
    value: RULES_STATUSES.active,
  },
  {
    key: RULES_STATUSES.inactive,
    text: upperFirst(RULES_STATUSES.inactive),
    value: RULES_STATUSES.inactive,
  },
];

export const RULE_CONTACTS_PATH = ['params', 'contacts'];

export const RULES_CONTACT_TYPES = {
  sms: 'sms',
  email: 'email',
};

// eslint-disable-next-line no-useless-escape
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
