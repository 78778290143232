import get from 'lodash/get';

angular.module('bigpanda').directive('incidentRow', incidentRow);

function incidentRow() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '?^^checkedIncidentsState',
    },
    scope: {
      incident: '<',
      selectedIncidentId: '=',
      previewMode: '=',
    },
    bindToController: true,
    templateUrl: 'overview/incidents/list/row/incident_list_row',
    controller: controller,
    controllerAs: 'vm',
  };

  function controller(
    $scope,
    pubSubService,
    sourceBreakdownService,
    stateService,
    FullscreenStore,
    Permissions,
    IncidentObjectBuilderService,
    UserFeatureTogglesService,
    LabelsStore
  ) {
    const vm = this;

    vm.bambooData = {};
    vm.checked = false;
    vm.isHover = false;

    vm.freezeRow = freezeRow;
    vm.setRowActionClass = setRowActionClass;
    vm.updateHoverState = updateHoverState;
    vm.goToIncident = goToIncident;
    vm.$onInit = onInit;

    vm.fullscreen = FullscreenStore.getFullscreen();
    vm.isDeleteIncidentTagDisabled = true;

    vm.incidentSubtitleStr = '';
    vm.incidentTitleStr = '';

    const currentEnvId = stateService.getSelectedEnvironmentId();

    vm.currentEnvActions = {
      delete: Permissions.checkGranularPermission('action@delete', 'environments', currentEnvId),
      update: Permissions.checkGranularPermission('action@update', 'environments', currentEnvId),
    };

    function updateHoverState({ isHover }) {
      vm.isHover = isHover;
    }

    function resetTileAriaTitles(incident) {
      const incidentType = get(incident, 'titleData.type');
      const incidentSubtitle = get(incident, 'titleData.subtitle');
      const incidentTitle = get(incident, 'titleData.title');
      if (incidentTitle && incidentType !== 'no-correlation') {
        const subtitleType = get(incidentSubtitle, 'itemType');
        const subtitleItems = get(incidentSubtitle, 'items', []);
        vm.incidentSubtitleStr = `${subtitleType}: ${subtitleItems.join(', ')}`;
        vm.incidentTitleStr = incidentTitle
          .map((title) => `${title.key.str}: ${title.value.str}`)
          .join(', ');
      } else if (incidentType && incidentTitle && incidentSubtitle) {
        vm.incidentSubtitleStr = `${incidentSubtitle.key}: ${incidentSubtitle.value}`;
        vm.incidentTitleStr = `${incidentTitle.key}: ${incidentTitle.value}`;
      }
    }

    let frozen = false;
    const folderId = stateService.getSelectedFolderId();

    function updateChecked() {
      vm.checked = vm.checkedIncidentsState && vm.checkedIncidentsState.isChecked(vm.incident.id);
    }

    vm.$onChanges = onChange;

    function onChange() {
      updateChecked();
    }

    function onInit() {
      // If we're not using checkbox selection, we mock 'checkedIncidentsState' so the flow of the class will work
      // as expected
      if (!vm.checkedIncidentsState) {
        vm.checkedIncidentsState = {
          subscribe: angular.noop,
          isChecked: () => false,
          check: () => angular.noop,
          uncheck: () => angular.noop,
        };
      }
      updateChecked();

      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: updateChecked,
      });

      $scope.$watch(() => vm.checked, checkClicked);
      $scope.$watch(() => vm.incident.timeStampDisplay, setIncidentTime);

      $scope.$watch(
        () => vm.incident,
        () => {
          vm.bambooData.incident = vm.incident;
          vm.bambooData.titleData = IncidentObjectBuilderService.getTitle(vm.incident);
          vm.bambooData.breakdownSources = IncidentObjectBuilderService.breakdownSources(
            vm.incident
          );
          updateIncidentTagDeleteButtonDisabled();
          resetTileAriaTitles(vm.bambooData);
        }
      );

      $scope.$watchCollection('vm.incident.entities', () => {
        vm.bambooData.numberOfIncidentActiveAlerts = IncidentObjectBuilderService.getActiveEntitiesCount(
          vm.incident
        );
        vm.bambooData.numberOfIncidentMaintenanceAlerts = IncidentObjectBuilderService.getMaintenanceEntitiesCount(
          vm.incident
        );
      });
    }

    function goToIncident($event) {
      pubSubService.broadcast('wallboard.incident.click');
      if ($event.target.id !== 'goToIncident') {
        $event.stopPropagation();
      } else {
        $event.preventDefault();
      }
    }

    function setIncidentTime() {
      IncidentObjectBuilderService.getTimeProps(vm.incident, folderId).then((timeProps) => {
        vm.incident.timeStampDisplay = angular.copy(timeProps.timeStampDisplay);
        vm.bambooData.timeProps = timeProps;
      });
    }

    function freezeRow(freeze) {
      frozen = freeze;
    }

    function setRowActionClass() {
      if (!vm.incident) return null;

      const cls = [];

      if (!vm.currentEnvActions.update && !vm.currentEnvActions.delete) {
        cls.push('read-only-incident-row');
      }

      if (vm.previewMode) {
        return 'preview-mode';
      }

      if (vm.checked) {
        cls.push('checked');
      }

      if (frozen) {
        cls.push('frozen');
      }

      if (vm.showIncidentActions && !vm.mdMedia('gt-xs')) {
        cls.push('swiped');
      }

      if (vm.incident.id === vm.selectedIncidentId) {
        cls.push('selected');
      }

      return cls.join(' ');
    }

    function checkClicked() {
      if (vm.checked) {
        vm.checkedIncidentsState.check(vm.incident.id);
      } else {
        vm.checkedIncidentsState.uncheck(vm.incident.id);
      }
    }

    function updateIncidentTagDeleteButtonDisabled() {
      LabelsStore.getIncidentTags(vm.incident.id).then((res) => {
        const foundTags = res.labels && res.labels.length;
        vm.isDeleteIncidentTagDisabled = !foundTags;
      });
    }
  }
}
