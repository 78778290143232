angular.module('bp.widgets').directive('bpFocus', ($timeout) => ({
  restrict: 'A',
  link: function (scope, element, attrs) {
    if (attrs.bpFocus != 'bp-focus' && attrs.bpFocus != 'true') {
      return;
    }
    var numOfTries = 10;
    $timeout(function tryFocus() {
      if (!element.is(':focus')) {
        element.focus();
        element.select();

        if (numOfTries-- > 0) {
          $timeout(tryFocus);
        }
      }
    });
  },
}));
