import { combineEpics } from 'redux-observable';
import DisplayCommon from '@bp/display-common';
import { getTags as getTagsRequest } from 'common/endpoints/tags';
import actionTypes from './actionTypes';
import * as actions from './actions';

const loadAlertTags = (action$) =>
  action$.ofType(actionTypes.LOAD_ALERT_TAGS).mergeMap(async () => {
    const rawAlertTags = await getTagsRequest().then((res) => res.item);
    const displayAlertTags = alertTagsForDisplay(rawAlertTags);
    return actions.loadAlertTagsSuccess({ displayAlertTags, rawAlertTags });
  });

export const alertTagsForDisplay = (alertTags, customTags = []) => {
  const sourceSystems = {};
  const totalHits = {};
  [...alertTags, ...customTags].forEach((tag) => {
    totalHits[tag.name] = (totalHits[tag.name] || 0) + (tag.totalHits || 0);

    if (!sourceSystems[tag.name]) {
      sourceSystems[tag.name] = [];
    }
    const parentDisplayName = getParentDisplayName(tag.parentSourceSystem);
    if (
      !sourceSystems[tag.name].find(
        (sourceSystem) => sourceSystem.parentSourceSystem === tag.parentSourceSystem
      )
    ) {
      sourceSystems[tag.name].push({
        parentDisplayName: parentDisplayName,
        parentSourceSystem: tag.parentSourceSystem,
      });
    }
  });
  const data = Object.keys(sourceSystems).map((name) => {
    const tagInConfig = customTags.find((customTag) => customTag.name === name) != null;
    return {
      name: name,
      sourceSystems: sourceSystems[name],
      totalHits: totalHits[name],
      tagInConfig: tagInConfig,
    };
  });

  return data.sort((a, b) => {
    if (a.tagInConfig === b.tagInConfig) {
      return b.totalHits - a.totalHits;
    }
    return a.tagInConfig ? -1 : 1;
  });
};

const getParentDisplayName = (parentSourceSystem) => {
  if (parentSourceSystem === 'api') {
    return 'Api';
  }
  const parsedDisplayName = DisplayCommon.getIntegrationMetadataBySourceSystem(parentSourceSystem);
  return parsedDisplayName && parsedDisplayName.name ? parsedDisplayName.name : parentSourceSystem;
};

export default combineEpics(loadAlertTags);
