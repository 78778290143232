import styled from 'styled-components';
import { AllSizes, BasicButton, HBox, Text } from '@bp/kung-fu';

export const ButtonWrapper = styled(HBox)`
  width: 100%;
`;

export const AiButton = styled(BasicButton)`
  text-align: start;
  height: 56px;
  width: 100%;

  && {
    &:hover {
      background-color: ${(p) => p.theme.bp_gray_01_5};
      border-radius: 3px;
    }
  }
`;

export const AiText = styled(Text).attrs({ size: AllSizes.SMALL })`
`;

export const IconWrapper = styled(HBox)`
  margin-inline-start: 10px;
`;
