import {
  Select,
  TimeFrameSelect,
} from '@bp/kung-fu';
import isEqual from 'lodash/isEqual';
import React, { memo, useState } from 'react';

import { anyChangeObject, DEFAULT_FILTERS } from '../../../../constants';
import useObjectNameFilter from '../../../../hooks/useObjectNameFilter';
import useUsersFilter from '../../../../hooks/useUsersFilter/useUsersFilter';
import { TableFiltersProps } from '../types';
import ClearButton from './components/ClearButton';
import CustomObjectNameOption from './components/CustomObjectNameOption';
import {
  ActionSelect, CategorySelect, ChangedObjectSelect, DateSelect, UserSelect,
} from './components/CustomSelect/CustomSelect';
import CustomUserOption from './components/CustomUserOption';
import {
  actionOptions, anyUser, categoryOptions, dateOptions,
} from './constants';
import { FilterContainerWrapper } from './TableFilters.style';

function TableFilters({ filters, setFilters }: TableFiltersProps): JSX.Element {
  const {
    changedObjectOptions,
    changedObjectAsyncSelectCb,
    changedObjectIsLoading,
  } = useObjectNameFilter();

  const {
    usersAsyncSelectCb,
    usersOption,
    usersIsLoading,
  } = useUsersFilter();

  const [userFilter, setUserFilter] = useState(anyUser);
  const [actionFilter, setActionFilter] = useState(actionOptions[0]);
  const [categoryFilter, setCategoryFilter] = useState(categoryOptions[0]);
  const [changeObjectFilter, setChangeObjectFilter] = useState(anyChangeObject);
  const [dateFilter, setDateFilter] = useState(dateOptions[3]);

  const handleDateFilterChange = ({ value, id }): void => {
    const { startDate, endDate } = value;
    const newFilter = dateOptions.find((date) => date.id === id);
    setDateFilter(newFilter);
    const startTime = startDate ? (startDate / 1000).toFixed() : '';
    const endTime = (endDate / 1000).toFixed();
    setFilters((prevFilter) => ({
      ...prevFilter,
      start: startTime,
      end: endTime,
    }));
  };

  const handleClearFilter = (): void => {
    setFilters(DEFAULT_FILTERS);
    setUserFilter(anyUser);
    setActionFilter(actionOptions[0]);
    setCategoryFilter(categoryOptions[0]);
    setChangeObjectFilter(anyChangeObject);
    setDateFilter(dateOptions[3]);
  };

  return (
    <FilterContainerWrapper>
      <Select
        value={userFilter}
        updateField={(name, value): void => {
          setUserFilter(value);
          setFilters((prevFilter) => ({
            ...prevFilter,
            userId: value.id,
          }));
        }}
        name="text"
        options={usersOption}
        disabled={false}
        isFilterable
        isClearable
        onAsyncValueChange={usersAsyncSelectCb}
        isLoading={usersIsLoading}
        menuWidth="330px"
        controlComponent={UserSelect}
        optionComponent={CustomUserOption}
      />
      <Select
        value={actionFilter}
        updateField={(name, value): void => {
          const newAction = actionOptions.find((option) => option.id === value.id);
          setActionFilter(newAction);
          setFilters((prevFilter) => ({
            ...prevFilter,
            actionType: value.id,
          }));
        }}
        options={actionOptions}
        name="text"
        disabled={false}
        isFilterable
        isClearable
        menuWidth="200px"
        controlComponent={ActionSelect}
      />
      <Select
        value={categoryFilter}
        updateField={(name, value): void => {
          const newCategory = categoryOptions.find(
            (option) => option.id === value.id,
          );
          setCategoryFilter(newCategory);
          setFilters((prevFilter) => ({
            ...prevFilter,
            resourceType: value.id,
          }));
        }}
        options={categoryOptions}
        name="text"
        disabled={false}
        isClearable
        menuWidth="200px"
        controlComponent={CategorySelect}
      />
      <Select
        value={changeObjectFilter}
        updateField={(name, value): void => {
          setChangeObjectFilter(value);
          setFilters((prevFilter) => ({
            ...prevFilter,
            resourceId: value.id,
          }));
        }}
        options={changedObjectOptions}
        name="text"
        disabled={false}
        isFilterable
        isClearable
        onAsyncValueChange={changedObjectAsyncSelectCb}
        isLoading={changedObjectIsLoading}
        menuWidth="310px"
        controlComponent={ChangedObjectSelect}
        optionComponent={CustomObjectNameOption}
      />
      <TimeFrameSelect
        value={dateFilter}
        options={dateOptions}
        name="name"
        width="100%"
        menuWidth="fit-content"
        controlComponent={DateSelect}
        viewMode={false}
        blockFuture
        updateField={(name, value): void => handleDateFilterChange(value)}
      />
      <ClearButton
        showButton={!isEqual(filters, DEFAULT_FILTERS)}
        handleClick={handleClearFilter}
      />
    </FilterContainerWrapper>
  );
}

export default memo(TableFilters);
