import fetchPrimarySecondaryFromEntity from '../../../../utils/fetch_primary_secondary_from_entity';

angular
  .module('bigpanda')
  .controller('EntitiesBatchResolveActivityEventCtrl', EntitiesBatchResolveActivityEventCtrl);

const NUMBER_OF_ALERTS_TO_SHOW = 10;

function EntitiesBatchResolveActivityEventCtrl($scope, $stateParams) {
  const { params } = $scope.event._source;

  $scope.event = {
    ...$scope.event,
    entities_count: params.entities_count,
    entities_left: params.entities_count - NUMBER_OF_ALERTS_TO_SHOW,
    plural: params.entities_count > 1 ? 's' : '',
    annotation: params.annotation,
    incidentId: $stateParams.incidentId,
    resolution_type: params.resolution_type,
    tooltip: fetchPrimarySecondaryFromEntity(params.entities_data),
    tooltipAdd:
      'overview/incidents/info/activity_feed/events/batch_resolve/entities_batch_resolve_activity_event_tooltip',
  };
}
