angular.module('bp.widgets').directive('bpHighlightChar', () => ({
  restrict: 'A',
  scope: {
    message: '=bpHighlightChar',
    highlightIndex: '=',
  },
  template:
    '<span ng-repeat="char in chars track by $index", ng-bind="char", ng-class="{highlighted: highlightIndex === $index}"></span>',
  link: (scope) =>
    scope.$watch('message', (message) => (scope.chars = message.split('').concat(' '))),
}));
