import { react2angular } from 'react2angular';
import TagDetailsPane from '../../../../../../workspaces/apps/alert-enrichment/src/components/TagInfo/TagDetailsPane';

const routes = {};

routes.appSettingsAlertEnrichmentInfo = {
  url: '/:tagName',
  views: {
    'master-detail-info@app.settings.alert_enrichment': {
      template:
        '<tag-details-pane class="react-settings-info" tag-name="stateParams.tagName" store="store" />',
      controller: ($scope, $ngRedux, $stateParams, UserFeatureTogglesService) => {
        'ngInject';

        if (!UserFeatureTogglesService.getToggle('frontier_alert_enrichment')) {
          window.location.href = '/#';
        }

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
      },
    },
  },
};

angular
  .module('bigpanda')
  .component('tagDetailsPane', react2angular(TagDetailsPane, ['tagName', 'store']));

export default routes;
