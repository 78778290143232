import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import partition from 'lodash/partition';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FormSpy } from 'react-final-form';
import { v4 } from 'uuid';
import moment from 'moment';
import { darkTheme } from '@bp/kung-fu';
import { BamForm, BamActionableTooltip } from '@bp/bam';
import selectors from '../selectors';
import actions from '../actions';
import { hasMapping, moveItem } from '../utils';
import styles from './AlertTagForm.scss';
import isMapping from './utils/isMapping';
import extractRule from '../../../../../../workspaces/apps/alert-enrichment/src/helpers/extractRule';
import {
  parseRule,
  parseRuleForPreview,
} from '../../../../../../workspaces/apps/alert-enrichment/src/helpers/parseRule';
import RulesList from '../../../../../../workspaces/apps/alert-enrichment/src/components/Modal/RulesList';
import { AddNewRuleButton } from '../../../../../../workspaces/apps/alert-enrichment/src/components/AddNewRuleButton';
import { tagPropType } from '../propTypes';
import { TAG_TYPE } from '../constants';
import { AlertTagFormHeader } from '../../../../../../workspaces/apps/alert-enrichment/src/components/AlertTagFormHeader';
import { calcTagType } from '../../../../../../workspaces/apps/alert-enrichment/src/helpers/calculate-tag-type';
import AlertTagPreview from '../../../../../../workspaces/apps/alert-enrichment/src/components/AlertTagPreview/AlertTagPreview';
import { RulesHeader } from '../../../../../../workspaces/apps/alert-enrichment/src/components/Modal/RulesHeader';
import { AlertTagFormFooter } from '../../../../../../workspaces/apps/alert-enrichment/src/components/Modal/AlertTagFormFooter';
import { selectors as FTSelectors } from '../../../../user/feature_toggles';
import { shouldUseTagOperations } from '../../../../../../workspaces/apps/alert-enrichment/src/helpers/should-use-tag-operations';
import texts from '../../../../../../workspaces/apps/alert-enrichment/src/dictionary';
import { getInterpolatedText } from '../../../../../../workspaces/apps/alert-enrichment/src/helpers/get-interpolated-text';
import {
  MAX_NUM_OF_TAGS,
  MAX_NUM_OF_RULES,
  MAX_RULES_FOR_TAG,
} from '../../../../../../workspaces/apps/alert-enrichment/src/constants/limits';

const newRule = (id, __isDuplicate) => ({
  id,
  type: TAG_TYPE['Multi Type'],
  __isNew: true,
  __isDuplicate,
});
const newRuleId = v4();

const rulesSubscriptionConfig = { value: false, meta: false };

const TOTAL_NUM_OF_RULES_REACHED_TOOLTIP_TEXT = getInterpolatedText(
  texts.edit_tag_modal__maximum_num_of_rules_warning_message,
  { totalNumOfRules: MAX_NUM_OF_RULES.toLocaleString() }
);

const TOTAL_NUM_OF_TAGS_REACHED_TOOLTIP_TEXT = getInterpolatedText(
  texts.create_tag_modal__maximum_num_of_tags_warning_message,
  { totalNumOfTags: MAX_NUM_OF_TAGS.toLocaleString() }
);

const TOTAL_NUM_OF_RULES_FOR_TAG_REACHED_TOOLTIP_TEXT = getInterpolatedText(
  texts.edit_tag_modal__maximum_num_of_rules_warning_message,
  { totalNumOfRules: MAX_RULES_FOR_TAG.toLocaleString() }
);

class AlertTagForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const editMode = !!props.tag && !props.duplicate;
    const enrichments = get(props, 'tag.enrichments', []);
    const name = get(props, 'tag.name', '');
    const rules =
      editMode || props.duplicate
        ? enrichments.map(({ id, type }) => ({
            id,
            type: isMapping(type) ? type : TAG_TYPE['Multi Type'],
          }))
        : [newRule(newRuleId)];

    this.state = {
      showUpdateMappingTagNameTooltip: false,
      rules,
      name,
      description: get(props, 'tag.description', ''),
      removedRules: [],
      editMode,
      forceFocus: false,
      dirtyFields: {},
      originalOrder: map(rules, 'id'),
      formValueChanged: false,
      tagType: editMode && props.tag.type,
    };
    this.forwardedRef = React.createRef();
  }

  static getDerivedStateFromProps({ draggedItems, setDraggedItems }, { rules }) {
    if (!draggedItems) return null;
    const { source, destination } = draggedItems;
    const reorderedRules = moveItem(rules, source.index, destination.index);
    setDraggedItems(undefined);
    return { rules: reorderedRules };
  }

  componentDidMount() {
    const { setCurrentTag, duplicate } = this.props;
    const { editMode, name } = this.state;
    if (editMode || duplicate) {
      setCurrentTag(name);
    }

    this.formInitialization = {};
  }

  onChange = ({ name, rules }) => {
    this.setState({
      name,
      tagType: Object.keys(rules).length > 0 && calcTagType(rules),
    });
  };

  onSubmit = ({ rules, name: destination, description, active }) => {
    const { duplicate, tag, closeModal, createTag, removeTag, useTagOps } = this.props;
    const {
      rules: stateRules,
      editMode,
      showUpdateMappingTagNameTooltip,
      removedRules,
      dirtyFields,
    } = this.state;

    if (!editMode && !stateRules.length) return;

    const tagName = destination.toLowerCase();

    if (editMode && !stateRules.length) {
      removeTag({ rules: removedRules, tagName });
    } else if (editMode && !duplicate) {
      if (
        dirtyFields.name &&
        !showUpdateMappingTagNameTooltip &&
        hasMapping(Object.values(rules))
      ) {
        return this.setState({ showUpdateMappingTagNameTooltip: true });
      }
      this.update({ rules, destination, oldTagName: tag.name, description });
    } else {
      const parsedRules = keyBy(this.parseRules({ rules, destination: tagName }), 'id');
      const orderedRules = stateRules.map(({ id }) => parsedRules[id]);

      createTag({
        rules: orderedRules,
        metadata: {
          tagName,
          description: duplicate ? description : dirtyFields.description && description,
          active: useTagOps ? active : orderedRules.some((r) => Boolean(r.active)),
        },
      });
    }

    closeModal();
  };

  onLoadSingleRulePreview = (ruleId, values) => {
    const { loadPreview, reservedTagsNames } = this.props;
    const { editMode } = this.state;
    const { rules: formRules, name: destination = '' } = values || this.formRef.getFormValues();
    const rule = formRules[ruleId];

    const rawRule = {
      id: ruleId,
      type: get(rule, 'type'),
      ...formRules[ruleId],
    };

    const parsedRule = parseRuleForPreview(
      {
        id: ruleId,
        destination,
        ...formRules[ruleId],
      },
      reservedTagsNames
    );
    const timeframe = {
      from: moment().subtract(3, 'weeks').unix() * 1000,
      to: moment().unix() * 1000,
    };
    loadPreview({
      destination,
      timeframe,
      rules: [parsedRule],
      rawRule,
      isNew: !editMode,
    });
  };

  onFormValueChanged = () => this.setState({ formValueChanged: true });

  // eslint-disable-next-line react/sort-comp
  partitionRules = (rules, dirtyFields, destination) => {
    if (isEmpty(rules)) return [];
    const touchedRules = groupBy(keys(dirtyFields), (field) => field.split('.')[1]);
    const parsedRules = this.parseRules({ rules, destination });
    const [toCreate, rest] = partition(parsedRules, ({ __isNew }) => __isNew);
    return [toCreate, rest.filter(({ id }) => touchedRules[id] || dirtyFields.name)];
  };

  update = ({ rules, destination, oldTagName, description }) => {
    const { updateTag, closeModal, updateRulesOrder } = this.props;
    const { removedRules: toDelete, rules: stateRules, dirtyFields, originalOrder } = this.state;
    const rulesWithoutMapping = pickBy(rules, (obj) => !isMapping(obj.type));
    const tagName = destination.toLowerCase();
    const [toCreate = [], toUpdate = []] = this.partitionRules(
      rulesWithoutMapping,
      dirtyFields,
      tagName
    );
    const rulesIds = map(stateRules, 'id');
    const didDescriptionChange = this.didDescriptionChange(description);
    const rulesReordered = !isEqual(rulesIds, originalOrder);
    if (isEmpty(dirtyFields) && !toCreate.length && !toDelete.length && !didDescriptionChange) {
      if (rulesReordered) {
        updateRulesOrder({ tagName, rulesIds });
        return closeModal();
      }
      return closeModal();
    }

    if (dirtyFields.name && hasMapping(Object.values(rules))) {
      const newTagRulesIds = rulesIds.filter((id) => !isMapping(get(rules[id], 'type')));
      return updateTag({
        tagName,
        oldTagName,
        rules: { toCreate, toUpdate, toDelete },
        rulesIds: newTagRulesIds,
        metadata: {
          description: didDescriptionChange && description,
        },
      });
    }

    updateTag({
      tagName,
      oldTagName,
      rules: { toCreate, toUpdate, toDelete },
      rulesIds: rulesReordered ? rulesIds : [],
      metadata: {
        description: didDescriptionChange && description,
      },
    });
  };

  saveAnyway = ({ rules, name: destination, description }) => {
    this.setState({ showUpdateMappingTagNameTooltip: false });
    this.update({ rules, destination, description });
    this.props.closeModal();
  };

  closeUpdateMappingTooltip = () => {
    this.setState({ showUpdateMappingTagNameTooltip: false });
  };

  parseRules = ({ rules, destination, defaultActive }) => {
    const { rules: stateRules } = this.state;
    const ruleTypesById = mapValues(keyBy(stateRules, 'id'), 'type');
    const ruleIsNewById = mapValues(keyBy(stateRules, 'id'), '__isNew');
    return Object.keys(rules).map((ruleId) => {
      const active =
        defaultActive !== undefined ? defaultActive : get(rules[ruleId], 'active', true);
      return parseRule({
        id: ruleId,
        type: ruleTypesById[ruleId],
        ...rules[ruleId],
        active,
        destination,
        __isNew: ruleIsNewById[ruleId],
      });
    });
  };

  addNewRule = (id, isDuplicate = false) => {
    const { rules } = this.state;
    this.setState({ rules: [...rules, newRule(id, isDuplicate)] }, () => {
      if (!isDuplicate) {
        this.setState({ forceFocus: true, forceFocusIndex: this.state.rules.length - 1 });
      }
    });
  };

  removeRule = (ruleId) => {
    const { rules, removedRules } = this.state;
    const removedRuleIndex = rules.findIndex(({ id }) => id === ruleId);
    const newRulesList = rules.filter(({ id }) => id !== ruleId);
    const { __isNew } = rules.find(({ id }) => id === ruleId);
    const newRemovedRules = __isNew ? removedRules : [...removedRules, ruleId];

    this.setState({ rules: newRulesList, removedRules: newRemovedRules }, () => {
      if (removedRuleIndex === newRulesList.length) {
        this.forwardedRef.current.focus();
      } else {
        this.setState({ forceFocus: true, forceFocusIndex: removedRuleIndex });
      }
    });
  };

  initForm() {
    const { editMode } = this.state;
    const { tag, duplicate } = this.props;

    if (!editMode && !duplicate) {
      return {
        name: '',
        rules: {
          [newRuleId]: { active: true },
        },
        description: '',
        active: true,
      };
    }

    const { name, enrichments, description, active } = tag;

    const rules = enrichments.reduce((obj, rule) => {
      const formRule = extractRule(rule);
      return { ...obj, [rule.id]: formRule };
    }, {});

    const newFormInitialization = {
      name,
      rules,
      description,
      active,
    };

    if (!isEqual(this.formInitialization, newFormInitialization)) {
      this.formInitialization = newFormInitialization;
    }

    return this.formInitialization;
  }

  freeFocus = () => {
    this.setState({ forceFocus: false });
  };

  didDescriptionChange = (description) => {
    const { tag } = this.props;

    if (typeof description !== 'string') {
      return false;
    }

    if (!tag) {
      return true;
    }

    return tag.description !== description;
  };

  registerDirtyFields = ({ dirtyFields: { rules, ...dirtyFields } }) => {
    const { dirtyFields: stateDirtyFields } = this.state;
    this.setState({ dirtyFields: { ...dirtyFields, ...stateDirtyFields } });
  };

  validateTagLimits = () => {
    const { totalNumOfTags, totalNumOfRules, useTagOps } = this.props;
    const { rules } = this.state;
    const hasPassedTagsLimit = totalNumOfTags + 1 > MAX_NUM_OF_TAGS;
    const hasPassedMaxNumOfTotalRules = totalNumOfRules + 1 > MAX_NUM_OF_RULES;
    const hasPassedMaxNumOfRulesForThisTag = rules.length + 1 > MAX_RULES_FOR_TAG;

    if (!useTagOps) {
      return '';
    }

    if (hasPassedTagsLimit) {
      return TOTAL_NUM_OF_TAGS_REACHED_TOOLTIP_TEXT;
    }

    if (hasPassedMaxNumOfTotalRules) {
      return TOTAL_NUM_OF_RULES_REACHED_TOOLTIP_TEXT;
    }

    if (hasPassedMaxNumOfRulesForThisTag) {
      return TOTAL_NUM_OF_RULES_FOR_TAG_REACHED_TOOLTIP_TEXT;
    }

    return '';
  };

  renderRulesList = () => {
    const { rules, forceFocus, forceFocusIndex, tagType } = this.state;
    const { tag } = this.props;

    return (
      <RulesList
        rules={rules}
        tag={{ ...(tag || {}), type: tagType }}
        onLoadPreview={this.onLoadSingleRulePreview}
        forceFocus={forceFocus}
        forceFocusIndex={forceFocusIndex}
        freeFocus={this.freeFocus}
        onValueChanged={this.onFormValueChanged}
      />
    );
  };

  render() {
    const {
      rules,
      name,
      showUpdateMappingTagNameTooltip,
      editMode,
      formValueChanged,
      tagType,
    } = this.state;
    const { closeModal, tag, duplicate, tags, reservedTags } = this.props;
    const updateMode = tag && !duplicate;
    const createNewTagValidationText = this.validateTagLimits();
    const hasReachedCreationLimit = Boolean(createNewTagValidationText);

    return (
      <ThemeProvider theme={darkTheme}>
        <div className={styles.form}>
          <BamForm
            id="AlertTagForm"
            ref={(r) => (this.formRef = r)}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            rightPane={
              <AlertTagPreview
                tagName={name}
                formValueChanged={formValueChanged}
                loadPreview={this.onLoadSingleRulePreview}
              />
            }
            scrollable
            initialValues={this.initForm()}
            keepDirtyOnReinitialize
            positiveButton={{
              text: `${updateMode ? 'Update Tag' : 'Create Tag'}`,
              'data-product-id': updateMode
                ? 'update_alert_enrichment_tag'
                : 'create_alert_enrichment_tag',
              disabled: !editMode && !rules.length,
            }}
            closeButton={{
              text: 'Cancel',
              onClick: closeModal,
            }}
            mutators={{
              removeRule: ([ruleId], state, { changeValue }) => {
                changeValue(state, 'rules', (oldValue) => {
                  if (oldValue) return omit(oldValue, ruleId);
                  return oldValue;
                });
                this.removeRule(ruleId);
              },
              addRule: ([ruleId], state, { changeValue }) => {
                changeValue(state, 'rules', (oldValue) => {
                  const lastRuleId = get(last(rules), 'id', '');
                  const type = get(oldValue, [lastRuleId, 'type']);
                  return {
                    ...oldValue,
                    [ruleId]: {
                      active: true,
                      ...(lastRuleId && !isMapping(type) ? { type } : {}),
                    },
                  };
                });
                this.addNewRule(ruleId);
              },
              duplicateRule: ([values], state, { changeValue }) => {
                const newRuleId = v4();
                changeValue(state, 'rules', (oldValue) => ({
                  ...oldValue,
                  [newRuleId]: { ...values },
                }));
                this.addNewRule(newRuleId, true);
              },
            }}
          >
            <AlertTagFormHeader
              tag={{ ...(tag || {}), type: tagType }}
              tags={tags}
              reservedTags={reservedTags}
              editMode={editMode}
              name={name}
            />

            {rules.length > 0 && (
              <div>
                <hr />
                <RulesHeader numOfRules={rules.length} />
                <Field
                  name="rules"
                  render={this.renderRulesList}
                  subscription={rulesSubscriptionConfig}
                />
              </div>
            )}
            <AddNewRuleButton
              forwardedRef={this.forwardedRef}
              tooltipProps={
                hasReachedCreationLimit && {
                  text: createNewTagValidationText,
                  isActive: true,
                }
              }
              disabled={hasReachedCreationLimit}
            />
            {showUpdateMappingTagNameTooltip && (
              <BamActionableTooltip
                trigger={<div className={styles['updateMappingTagName-tooltip-trigger']} />}
                header="Mapping rules will be separated"
                message="Pay attention this tag contains mapping rule/s that not matching the new name and they will be separated from this tags"
                action={() => {
                  const { rules: formRules, description } = this.formRef.getFormValues();
                  return this.saveAnyway({ rules: formRules, name, description });
                }}
                actionText="Save anyway"
                cancel={this.closeUpdateMappingTooltip}
                cancelText="Back To Editing"
                open
              />
            )}
            <FormSpy subscription={{ dirtyFields: true }} onChange={this.registerDirtyFields} />

            <AlertTagFormFooter editMode={editMode} rules={rules} />
          </BamForm>
        </div>
      </ThemeProvider>
    );
  }
}

AlertTagForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  updateTag: PropTypes.func.isRequired,
  draggedItems: PropTypes.shape({
    destination: PropTypes.shape({ index: PropTypes.number }),
    source: PropTypes.shape({ index: PropTypes.number }),
  }).isRequired,
  loadPreview: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(tagPropType),
  duplicate: PropTypes.bool,
  tag: tagPropType,
  previewRule: PropTypes.shape({}),
  removeTag: PropTypes.func.isRequired,
  setCurrentTag: PropTypes.func.isRequired,
  updateRulesOrder: PropTypes.func.isRequired,
  reservedTags: PropTypes.arrayOf(PropTypes.shape({})),
  reservedTagsNames: PropTypes.arrayOf(PropTypes.string),
  useTagOps: PropTypes.bool,
  totalNumOfTags: PropTypes.number,
  totalNumOfRules: PropTypes.number,
};

const mapStateToProps = (state) => ({
  draggedItems: selectors.getDraggedItems(state),
  tags: selectors.getTags(state),
  previewRule: selectors.getPreviewRule(state),
  reservedTags: selectors.getReservedTags(state),
  reservedTagsNames: selectors.getReservedTagsNames(state),
  useTagOps: shouldUseTagOperations(FTSelectors.getFeatureToggles(state) || {}),
  totalNumOfTags: selectors.getTotalTags(state),
  totalNumOfRules: selectors.getTotalRules(state),
});

const mapDispatchToProps = {
  setDraggedItems: actions.setDraggedItems,
  createTag: actions.createAlertTag,
  loadPreview: actions.loadPreviewAlerts,
  updateTag: actions.updateAlertTag,
  removeTag: actions.removeAlertTag,
  setCurrentTag: actions.setCurrentTag,
  updateRulesOrder: actions.updateRulesOrder,
};

AlertTagForm.defaultProps = {
  duplicate: false,
  tags: undefined,
  tag: undefined,
  reservedTags: undefined,
  reservedTagsNames: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertTagForm);
