import React from 'react';
import { Avatar, InfoThinIcon, SCHBox, Text } from '@bp/kung-fu';

const ChangeCorrelationIndicator = (props) => (
  <SCHBox gap="4px" {...props}>
    <Avatar name="Change Correlation Algorithm" size="x-small" url="/assets/img/BP_USER.png" />
    <Text size="small" weight="bold">
      Change Correlation Algorithm
    </Text>
    <Text size="small">initially marked it as a Suspect</Text>
    <InfoThinIcon size="large" color={(p) => p.theme.bp_gray_08} />
  </SCHBox>
);

export default ChangeCorrelationIndicator;
