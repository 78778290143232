import {
  AllSizes, HelpTooltip, SCHBox, Text,
} from '@bp/kung-fu';
import React from 'react';

import { Tag } from '../../../types';

interface IProps {
  tag: Tag;
  numOfTags: number;
}

export function AlertTagFormExecutionOrder({ tag, numOfTags }: IProps): JSX.Element {
  const newTagOrder = numOfTags !== undefined && numOfTags + 1;
  const order = (tag && tag.order) || newTagOrder;

  return (
    Boolean(order) && (
    <SCHBox gap="5px">
      <Text color={(p): string => p.theme.bp_gray_06} size={AllSizes.SMALL}>
        Execution Order:
      </Text>
      <Text weight="bold" color={(p): string => p.theme.bp_gray_07} size={AllSizes.SMALL}>
        #
        {order}
      </Text>
      <HelpTooltip text="The order of the tag in the execution plan" />
    </SCHBox>
    )
  );
}

export default AlertTagFormExecutionOrder;
