import bpFetch from 'common/utils/http/fetcher';

export function getContacts() {
  return bpFetch({ url: '/web/contacts' }).then((res) => res.data.item);
}

export function createContact({ name, phone_number, email }) {
  return bpFetch({
    url: '/web/contacts',
    method: 'POST',
    data: { name, phone_number, email },
  }).then(({ data: { item } }) => item);
}

export function updateContact(id, name, phone) {
  return bpFetch({
    url: `/web/contacts/${id}`,
    method: 'PATCH',
    data: {
      name,
      phone_number: phone,
    },
  }).then((res) => res.data.item);
}
