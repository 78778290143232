import { BamNotificationStatusOptions, bamUnNotify } from '@bp/bam';
import BPQL from '@bp/bpql';
import { combineEpics, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { parseQuery } from '../../../../app/common/modules/settings/QuerySourceParser';
import { showFailureMessage, showInProgressMessage, showSuccessMessage } from '../../../../app/react/layout/settings/feedback';
import actions from './actions';
import actionTypes from './actionTypes';
import api from './apiEndpoints';
import { AlertFilter } from './types/AlertFilter';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION, DropdownOption } from './types/DropdownOption';
import alertFilterUrl from './utils/alertFilterUrl';

const showSuccessMessageForFilters = (
  action: string,
  toasterId: string = null,
  status = BamNotificationStatusOptions.ACTION_SUCCESS,
): void => {
  if (toasterId) bamUnNotify(toasterId);
  setTimeout(() => showSuccessMessage('Alert Filter', action, status), 500);
};

const showFailureMessageForFilters = (action: string, toasterId = null): void => {
  if (toasterId) bamUnNotify(toasterId);
  setTimeout(() => showFailureMessage('Alert Filter', action), 500);
};

const showInProgressMessageForFilters = (
  action: string,
  status = BamNotificationStatusOptions.IN_PROGRESS,
): string => showInProgressMessage('Alert Filter', action, status);

const loadAlertFilters = (action$): Observable<void> => action$.pipe(
  ofType(actionTypes.LoadAlertFilters),
  mergeMap(async () => {
    try {
      const alertFilters = await api.getAlertFilters() as unknown as AlertFilter[];
      const enrichedAlertFilters: AlertFilter[] = alertFilters.map((alertFilter: AlertFilter) => {
        const query = parseQuery(alertFilter.bpql, [{ type: 'regex', value: '*' }], true);
        let displayQuery = '';
        if (query.pureQuery && Object.keys(query.pureQuery).length > 0) {
          displayQuery = BPQL.reverse(alertFilter.bpql);
        }
        return { ...alertFilter, condition: { query, displayQuery } };
      });
      return actions.loadAlertFiltersSuccess(enrichedAlertFilters);
    } catch (ex) {
      showFailureMessageForFilters('load');
      return actions.loadAlertFiltersFailure();
    }
  }),
);

const filtersWithDefaultOption = (key: string, filters = []): DropdownOption[] => [
  DROPDOWN_FILTERS_ALL_ITEM_OPTION[key],
  ...filters,
];

const createFiltersDropDownItems = (action$): Observable<void> => action$.pipe(
  ofType(actionTypes.LoadAlertFiltersSuccess),
  map(() => {
    const statusItemsArray: DropdownOption[] = filtersWithDefaultOption('status', [
      { key: 'active', text: 'Active', value: 'true' },
      { key: 'inactive', text: 'Inactive', value: 'false' },
    ]);

    return actions.createFiltersDropdownItemsSuccess({
      status: statusItemsArray,
    });
  }),
);

const deleteAlertFilter = (action$): Observable<void> => action$.pipe(
  ofType(actionTypes.DeleteAlertFilter),
  mergeMap(async ({ payload: filter }) => {
    const toasterId = showInProgressMessageForFilters('Deleting');
    try {
      await api.deleteAlertFilter(filter.id);
      showSuccessMessageForFilters('deleted', toasterId);
      window.location.href = alertFilterUrl();
    } catch (_) {
      showFailureMessageForFilters('delete', toasterId);
    }
    return actions.loadAlertFilters({ reload: false });
  }),
);

const updateAlertFilter = (action$): Observable<void> => action$.pipe(
  ofType(actionTypes.UpdateAlertFilter),
  mergeMap(async ({ payload: filter }) => {
    const toasterId = showInProgressMessageForFilters('Updating');
    try {
      const bpql = typeof filter.condition === 'string'
        ? { bpql: BPQL.buildParserFromGrammer('correlation')(filter.condition) }
        : {};
      await api.updateAlertFilter({ ...filter, ...bpql });
      showSuccessMessageForFilters('updated', toasterId);
    } catch (ex) {
      showFailureMessageForFilters('update', toasterId);
    }
    return actions.loadAlertFilters({ reload: false });
  }),
);

const createAlertFilter = (action$): Observable<void> => action$.pipe(
  ofType(actionTypes.CreateAlertFilter),
  mergeMap(async ({ payload: filter }) => {
    const toasterId = showInProgressMessageForFilters('Creating');
    try {
      const bpql = BPQL.buildParserFromGrammer('correlation')(filter.condition);
      const newFilter: AlertFilter = await api.createAlertFilter({ ...filter, bpql }) as unknown as AlertFilter;
      showSuccessMessageForFilters('created', toasterId);
      window.location.href = alertFilterUrl(newFilter.id);
    } catch (_) {
      showFailureMessageForFilters('create', toasterId);
    }
    return actions.loadAlertFilters({ reload: false });
  }),
);

export default combineEpics(
  loadAlertFilters,
  createFiltersDropDownItems,
  deleteAlertFilter,
  updateAlertFilter,
  createAlertFilter,
);
