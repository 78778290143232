import PropTypes from 'prop-types';

const contactPropType = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  bigpanda_user_id: PropTypes.string,
});

const recipientsTooltipPropType = PropTypes.shape({
  contacts: PropTypes.arrayOf(contactPropType),
  type: PropTypes.string,
});

export default recipientsTooltipPropType;
