import actionTypes from './actionTypes';

const initialState = {
  isSaving: false,
  isLoading: false,
  configurationSent: false,
  userEmail: null,
  userName: null,
  userOrganizationName: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_USER_NAME_PASSWORD:
      return {
        ...state,
        isSaving: true,
      };
    case actionTypes.UPDATE_USER_NAME_PASSWORD_SUCCESS:
      return {
        ...state,
        isSaving: false,
        configurationSent: true,
      };
    case actionTypes.UPDATE_USER_NAME_PASSWORD_FAILURE:
      return {
        ...state,
        isSaving: false,
      };
    case actionTypes.LOAD_WELCOME_USER_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOAD_WELCOME_USER_DATA_SUCCESS:
      return {
        ...state,
        userEmail: action.payload.userEmail,
        userName: action.payload.userName,
        userOrganizationName: action.payload.userOrganizationName,
        isLoading: false,
      };
    case actionTypes.LOAD_WELCOME_USER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
