import { Text, VBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const RowDetailsContainer = styled(VBox)`
  @-webkit-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    1% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    1% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    1% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    1% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fadeInFromNone 0.5qs ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
  display: block;
  width: 100%;
`;

export const RowDetailsWrapper = styled.div`
  width: 100%;
  margin: 25px 50px;
  user-select: text;
`;

export const RowText = styled(Text)`
  color: ${(p): string => p.theme.bp_gray_06}
`;
