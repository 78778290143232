import { IRowRenderProps } from '@bp/kung-fu';
import React from 'react';

import { bigPandaAdmin, DataTestsId, systemGeneratedUser } from '../../../../constants';
import useAuditData from '../../../../hooks/useAuditData';
import { ActionTypeFormatted, AuditRow } from '../../../../hooks/useAuditTable/types';
import InitStateRow from './components/InitStateRow';
import RowDetailsBody from './components/RowDetailsBody';
import RowDetailsChanges from './components/RowDetailsChanges';
import RowDetailsContext from './components/RowDetailsContext';
import RowDetailsHeader from './components/RowDetailsHeader';
import { RowDetailsContainer, RowDetailsWrapper } from './RowDetails.style';

function RowDetails({ rowIndex, rowData }: IRowRenderProps): JSX.Element {
  const currentId = rowData.id.split('-details')[0];
  const expandedRowData = useAuditData(currentId) as AuditRow;

  return (
    <RowDetailsContainer>
      {expandedRowData.action === ActionTypeFormatted.init_state
              && <InitStateRow />}
      <RowDetailsWrapper data-testid={`${DataTestsId.expandTableRow}-${rowIndex}`}>

        <RowDetailsHeader rowData={expandedRowData} />
        <RowDetailsBody rowData={expandedRowData} />
        {(expandedRowData.action !== ActionTypeFormatted.init_state
            && expandedRowData.user.name !== systemGeneratedUser.name
            && expandedRowData.user.name !== bigPandaAdmin
            && !expandedRowData.user.name.includes('root'))
            && <RowDetailsContext rowData={expandedRowData} />}
        {expandedRowData.object.changes && <RowDetailsChanges rowData={expandedRowData} />}
      </RowDetailsWrapper>
    </RowDetailsContainer>
  );
}

export default RowDetails;
