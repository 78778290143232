const { bpToggleDropdown } = require('angularjs/widgets/bp_toggle_dropdown/bp.toggle.dropdown.js');

angular.module('bigpanda').directive('forwardButton', forwardButton);

function forwardButton() {
  return {
    restrict: 'E',
    templateUrl: 'overview/incidents/list/widgets/forward_button/forward_button',
    require: {
      checkedIncidentsState: '?^^checkedIncidentsState',
    },
    scope: {
      incidentId: '<',
      environmentId: '<',
      incident: '<',
    },
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(
    $scope,
    $location,
    $element,
    $timeout,
    $window,
    $dropdown,
    $state,
    IncidentsSearchFolderService
  ) {
    const vm = this;

    vm.$onDestroy = onDestroy;
    vm.$onInit = onInit;

    function onInit() {
      $element.click(onClick);
    }

    function onClick() {
      let stateParams = {
        incidentId: vm.incidentId,
        environment: vm.environmentId,
        returnTo: 'app.overview.incidents.incident-info',
        folder: 'active',
      };

      IncidentsSearchFolderService.searchFolderWExpired(
        '',
        stateParams.environment,
        stateParams.incidentId
      ).then((res) => {
        stateParams.folder = res.folder;
        const myUrl = `/#/app/overview/${stateParams.environment}/${stateParams.folder}/incidents/${stateParams.incidentId}`;
        $window.open(myUrl, '_blank');
      });
    }

    function onDestroy() {}
  }
}
