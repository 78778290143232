import { AllSizes } from '@bp/kung-fu';
import React from 'react';

import { LI, Span } from './style';

export interface ListItem {
  value: JSX.Element;
  id: string;
}

interface IProps {
  bulletSize?: AllSizes;
  items: ListItem[];
}

export function UList({ bulletSize = AllSizes.MEDIUM, items }: IProps): JSX.Element {
  return (
    <ul>
      {items.map((item: ListItem) => (
        <LI key={item.id} bulletSize={bulletSize}>
          <Span>{item.value}</Span>
        </LI>
      ))}
    </ul>
  );
}

export default UList;
