import BPQL from '@bp/bpql';
import {
  AllSizes, Badge, StatusEnum, Text,
} from '@bp/kung-fu';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';
import selectors from '../../../../../../app/react/modules/settings/maintenance_plans/maintenance_plan_info/selectors';
import { selectors as featureTogglesSelectors } from '../../../../../../app/react/user/feature_toggles';
import { FeatureToggles } from '../../../types/FeatureToggles';
import { MaintenancePlan } from '../../../types/MaintenancePlan';
import { semanticPlanStatus } from '../../utils/semanticPlanStatus';
import { dateFormat } from '../form/constants';
import { EMaintenanceFrequency } from '../form/types/EMaintenanceFrequency';
import styles from '../maintenance_plan_info.scss';
import TimeWindow from '../TimeWindow';

const ValueText = styled(Text).attrs({ size: AllSizes.LARGE })`
  line-height: unset;
`;

interface IProps {
  plan: MaintenancePlan;
  momentStart: moment.Moment;
  momentEnd: moment.Moment;
}

export function MaintenancePlanInfoContent({ plan, momentStart, momentEnd }: IProps): JSX.Element {
  const featureToggles: FeatureToggles = useSelector(featureTogglesSelectors.getFeatureToggles);
  const recurringMaintenance = featureToggles?.frontier_recurring_maintenance_plans || false;

  const {
    status,
    custom_tags: customTags = [],
    condition,
    description,
    maintenance_key: maintenanceKey,
    maintenance_key_link: maintenanceKeyLink,
  } = plan;

  const customTagsList = customTags.map(({ name, value }) => (
    <SettingsInfoScreen.SectionRow title={`${name}:`} key={name}>
      {value}
    </SettingsInfoScreen.SectionRow>
  ));

  const maintenanceKeyComp = maintenanceKeyLink ? (
    <a href={maintenanceKeyLink}>{maintenanceKey}</a>
  ) : (
    <span>{maintenanceKey}</span>
  );

  const planStartStr = (): string => {
    if (plan.status === StatusEnum.DONE) return 'Started on:';
    return 'Next run on:';
  };

  return (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.SectionRow title="Status:">
        <Badge text={semanticPlanStatus(status)} status={status} isCapitalized size="large" />
      </SettingsInfoScreen.SectionRow>
      <SettingsInfoScreen.SectionRow title="Maintenance Key:">
        <ValueText>
          {maintenanceKeyComp}
        </ValueText>
      </SettingsInfoScreen.SectionRow>
      <SettingsInfoScreen.SectionRow title="Condition:">
        <ValueText>
          {isEmpty(condition) ? '' : BPQL.reverse(condition)}
        </ValueText>
      </SettingsInfoScreen.SectionRow>
      <SettingsInfoScreen.SectionRow title="Time Window:">
        {recurringMaintenance
          ? <TimeWindow plan={plan} />
          : (
            <>
              <span>{selectors.windowDateFormat(momentStart, momentEnd)}</span>
              <span className={styles['num-hours']}>
                (
                {selectors.calcDiff(momentStart, momentEnd)}
                )
              </span>
            </>
          )}
      </SettingsInfoScreen.SectionRow>
      {recurringMaintenance
      && plan.frequency !== EMaintenanceFrequency.Once
      && plan.status !== StatusEnum.INPROGRESS
      && moment.unix(plan.frequency_data.next_run).isBefore(moment.unix(plan.end).endOf('day')) && (
        <SettingsInfoScreen.SectionRow title={planStartStr()}>
          <ValueText>
            {moment.unix(plan.frequency_data.next_run).format(dateFormat)}
            {' '}
            in
            {' '}
            {moment.unix(plan.frequency_data.next_run).from(moment(), true)}
          </ValueText>
        </SettingsInfoScreen.SectionRow>
      )}
      {recurringMaintenance
      && plan.frequency === EMaintenanceFrequency.Once
      && moment.unix(plan.end).isBefore(moment()) && (
        <SettingsInfoScreen.SectionRow title="Next run on:">
          <ValueText>-</ValueText>
        </SettingsInfoScreen.SectionRow>
      )}
      {(recurringMaintenance && plan.frequency !== EMaintenanceFrequency.Once)
      && (
      <SettingsInfoScreen.SectionRow title="End Repeat On:">
        <ValueText>
          {moment.unix(plan.end).format('MMM Do, YYYY')}
        </ValueText>
      </SettingsInfoScreen.SectionRow>
      )}
      {customTagsList}
      {description && (
      <SettingsInfoScreen.SectionRow title="Description:">
        {description}
      </SettingsInfoScreen.SectionRow>
      )}
    </SettingsInfoScreen.Section>
  );
}
