module.exports = angular
  .module('bigpanda.IntegrationsFaqConfigProvider', [])
  .constant('IntegrationsFaqConfig', {
    integrationsFaq: [
      {
        source_system: 'nagios',
        faq_type: 'agent',
        faqs: [
          {
            question: 'How does Nagios connect with the agent?',
            answer:
              `Nagios sends notifications to a BigPanda Contact.
          Alerts are updated whenever Nagios sends another notification.
          The agent also parses the status.dat and objects.cache files for enriched functionality.
          For more information or to integrate based on the Nagios log file, see ` +
              '<a href="https://www.bigpanda.io/docs/display/BD/Nagios" target="_blank">our documentation</a>.',
          },
          {
            question: 'Can I change how Nagios connects with BigPanda?',
            answer:
              'Yes, to migrate between the log-based and notification-based integration methods, ' +
              "see <a href='https://bigpanda.io/docs/display/BD/Migrating+Between+Nagios+Integrations' target='_blank'>our documentation</a>.",
          },
          {
            question: 'Where can I find the status.dat file?',
            answer:
              'The status.dat file is usually located at ' +
              '<i><b>/usr/local/nagios/var/status.dat</b></i> or <i><b>/var/cache/nagios3/status.dat</b></i>',
          },
          {
            question: 'Where can I find the objects.cache file?',
            answer:
              'The cache file is usually located at ' +
              '<i><b>/usr/local/nagios/var/objects.cache</b></i> or <i><b>/var/cache/nagios3/objects.cache</b></i>',
          },
          {
            question: 'Using Nagios XI?',
            answer:
              'Follow Steps 1-4 here, and then jump over to the ' +
              '<a href="https://docs.bigpanda.io/docs/nagios-xi" target="_blank">Nagios XI documentation</a> to finish configuration',
          },
        ],
      },
      {
        source_system: 'zabbix',
        faq_type: 'agent',
        faqs: [
          {
            question: 'What does that Zabbix configuration script do?',
            answer:
              'The configuration script creates a dedicated media type, user group, user and action in Zabbix. ' +
              'This isolates and customizes the way Zabbix exposes alerts to the agent.',
          },
          {
            question: 'How does the agent connect with Zabbix?',
            answer:
              'A dedicated media type, user group, user and action are configured in Zabbix. This gives the ' +
              'agent access to your alerts. The agent periodically queries Zabbix for alerts using the REST API.',
          },
        ],
      },
      {
        source_system: 'zabbixapi',
        faq_type: 'agent',
        faqs: [
          {
            question: 'What does that Zabbix configuration script do?',
            answer:
              'The configuration script creates a dedicated user group and user in Zabbix for BigPanda. ' +
              'These are used to login to Zabbix and retrieve alert information.',
          },
          {
            question: 'How does the agent connect with Zabbix?',
            answer:
              'The BigPanda agent uses the dedicated usergroup and user to query Zabbix periodically for alerts via the REST API.',
          },
        ],
      },
      {
        source_system: 'opsview',
        faq_type: 'agent',
        faqs: [
          {
            question: 'How does the agent connect with OpsView?',
            answer:
              'The agent parses the OpsView log, status.dat and objects.cache files. Parsing the log file enables ' +
              'realtime synchronization of OpsView alerts to BigPanda. Parsing status.dat allows BigPanda to sync alerts ' +
              'retroactively and enrich each alert with state that normally is not available in the log file. Parsing the ' +
              'objects.cache file enriches alerts with host-groups and service-groups information.',
          },
        ],
      },
      {
        source_system: 'icinga',
        faq_type: 'agent',
        faqs: [
          {
            question: 'How does the agent connect with Icinga?',
            answer:
              'The agent parses the Icinga log, status.dat and objects.cache files. Parsing the log file enables realtime ' +
              'synchronization of Icinga alerts to BigPanda. Parsing status.dat allows BigPanda to sync alerts retroactively and enrich ' +
              'each alert with state that normally is not available in the log file. Parsing the objects.cache file enriches alerts with ' +
              'host-groups and service-groups information.',
          },
          {
            question: 'Where can I find the Icinga log file?',
            answer:
              'The log file is usually located at <i><b>/usr/local/icinga/var/icinga.log</b></i> or ' +
              '<b><i>/var/log/icinga/icinga.log</i></b>',
          },
          {
            question: 'Where can I find the status.dat file?',
            answer:
              'The status.dat file is usually located at <i><b>/usr/local/icinga/var/status.dat</b></i> or ' +
              '<b><i>/var/cache/icinga/status.dat</i></b>',
          },
          {
            question: 'Where can I find the objects.cache file?',
            answer:
              'The cache file is usually located at <i><b>/usr/local/icinga/var/objects.cache</b></i> or ' +
              '<b><i>/var/cache/icinga/objects.cache</i></b>',
          },
        ],
      },
      {
        source_system: 'icinga2',
        faq_type: 'agent',
        faqs: [
          {
            question: 'How does the agent connect with Icinga?',
            answer:
              'The agent parses the Icinga2 compatibility log file, status.dat and objects.cache files. Parsing the log ' +
              'file enables realtime synchronization of Icinga2 alerts to BigPanda. Parsing status.dat allows BigPanda to sync alerts ' +
              'retroactively and enrich each alert with state that normally is not available in the log file. Parsing the objects.cache file ' +
              'enriches alerts with host-groups and service-groups information.',
          },
          {
            question: 'Where can I find the Icinga2 compatibility log file?',
            answer:
              'The log file is usually located at <i><b>/var/log/icinga2/compat/icinga.log</b></i>',
          },
          {
            question: 'Where can I find the status.dat file?',
            answer: 'The status.dat file is usually located at <i><b>/var/cache/status.dat</b></i>',
          },
          {
            question: 'Where can I find the objects.cache file?',
            answer: 'The cache file is usually located at <i><b>/var/caache/objects.cache</b></i>',
          },
        ],
      },
      {
        source_system: 'zenoss',
        faq_type: 'agent',
        faqs: [
          {
            question: 'How does the agent connect with Zenoss?',
            answer:
              'The agent periodically queries Zenoss for alerts using the REST API. The querying is done ' +
              'in small batches, to avoid overloading Zenoss.',
          },
        ],
      },
      {
        source_system: 'zenossapi',
        faq_type: 'agent',
        faqs: [
          {
            question: 'How does the agent connect with Zenoss?',
            answer:
              'The agent periodically queries Zenoss for alerts using the REST API. The querying is done in small batches, ' +
              'to avoid overloading Zenoss.',
          },
        ],
      },
      {
        source_system: 'sensu',
        faqs: [
          {
            question: 'What does "unknown handler" in sensu-server.log mean?',
            answer:
              'Sometimes you might get this error for "handler_name: bigpanda", ' +
              "this probably means that your Sensu user doesn't have " +
              'read permissions for handler_bigpanda.json. Run: sudo chmod 644 /etc/sensu/conf.d/handler_bigpanda.json',
          },
        ],
      },
      {
        source_system: 'cloudwatch',
        faqs: [
          {
            question: 'Is it really necessary to give full access permissions on CloudWatch?',
            answer:
              'Full access permissions is not required, but will spare you the drudgery of' +
              'manually selecting the required permissions. If granting full access is not an option, ' +
              'please refer to our <a href="https://docs.bigpanda.io/docs/cloudwatch#section-configuring-cloudwatch-permissions" ' +
              'target="_blank">documentation</a> for the exact policies.',
          },
          {
            question: 'Where can I manage existing CloudWatch accounts?',
            answer:
              'The already integrated systems are shown under the <b>My Integrations</b> list on the right pane.',
          },
        ],
      },
      {
        source_system: 'prtg',
        faqs: [
          {
            question: 'Where can I manage existing PRTG accounts?',
            answer:
              'The already integrated systems are shown under the <b>My Integrations</b> list on the right pane.',
          },
          {
            question: 'Does BigPanda supports PRTG in different languages?',
            answer:
              'BigPanda relies on parsing the content of notifications sent by PRTG to extract the alert data, ' +
              'and therefore only supports PRTG in English.',
          },
        ],
      },
      {
        source_system: 'newrelic',
        faqs: [
          {
            question: 'Where can I manage existing New Relic accounts?',
            answer:
              'The already integrated systems are shown under the <b>My Integrations</b> list on the right pane.',
          },
          {
            question: 'Can I integrate alerts from both Legacy Alerting and Alerts Beta?',
            answer:
              'Yes, BigPanda supports integration with both Legacy Alerting and Alerts Beta. A separate integration is needed for each.',
          },
        ],
      },
      {
        source_system: 'api',
        faqs: [
          {
            question: 'Where can I manage existing REST API application?',
            answer:
              'The already integrated systems are shown under the <b>My Integrations</b> list on the right pane.',
          },
        ],
      },
      {
        source_system: 'changes',
        faqs: [
          {
            question: 'Where can I manage existing REST API application?',
            answer:
              'The already integrated systems are shown under the <b>My Integrations</b> list on the right pane.',
          },
        ],
      },
      {
        source_system: 'scout',
        faqs: [
          {
            question: 'What are the supported plugins?',
            answer:
              'Currently we are supporting plugins with a start event and an end event. \n' +
              'If you want to know if a specific plugin is supported, please contact us via the feedback in the user menu.',
          },
          {
            question: 'Where can I manage existing Scout accounts?',
            answer:
              'The already integrated systems are shown under the <b>My Integrations</b> list on the right pane.',
          },
        ],
      },
      {
        source_system: 'sentry',
        faqs: [
          {
            question: 'What Rules do I need to configure?',
            answer:
              'In the Settings page, go to the Rules section: <br/>1. For the <b>Send a notification for new events</b> rule, ' +
              'make sure that the <b>Send a notification (for all enabled services)</b> action is defined.<br/>2. For the <b>Send a ' +
              'notification for regressions</b> rule, make sure that the <b>' +
              'Send a notification (for all enabled services)</b> action is defined.',
          },
          {
            question: 'Where can I manage existing Sentry accounts?',
            answer:
              'The already integrated systems are shown under the <b>My Integrations</b> list on the right pane.',
          },
        ],
      },
      {
        source_system: 'splunk',
        faqs: [],
      },
      {
        source_system: 'servicenow',
        faqs: [
          {
            question:
              'I\'m getting "Failed ACL Validation" error when trying to share an incident.',
            answer: 'Make sure that the ServiceNow user has the <b>x_bip_panda_user</b> role.',
          },
        ],
      },
      {
        source_system: 'pagerduty',
        faqs: [
          {
            question: 'Why does BigPanda need a REST API access?',
            answer: "BigPanda uses the PagerDuty REST API to retrieve incidents' realtime status.",
          },
        ],
      },
      {
        source_system: 'jira',
        faqs: [
          {
            question: 'To which group the JIRA user should belong to in order to open tickets?',
            answer:
              'Any group that has permissions to create new issues, which means at least the <i>jira-users</i> group.',
          },
          {
            question:
              'My JIRA instance is deployed on premise. Can I still integrate it with BigPanda?',
            answer: 'Yes, as long as JIRA is open to the internet.',
          },
          {
            question:
              'My JIRA instance is not open to the internet. Can I still integrate it with BigPanda?',
            answer:
              'Yes. Please contact your account manager or our support team for more details.',
          },
          {
            question:
              'I want to be able to open issues in different JIRA project. Is that possible?',
            answer:
              'Yes. You can configure as many JIRA integrations as you want, each integration configured to use a different project. ' +
              'Then when sharing an incident, simply select the relevant JIRA integration from the list.',
          },
        ],
      },
      {
        source_system: 'appdynamicsapi',
        faqs: [
          {
            question:
              'How can I upgrade from the action script to the webhook version of the AppDynamics integration?',
            answer:
              'Follow <a href="https://www.bigpanda.io/docs/x/cQAqAQ" target="_blank">' +
              'these instructions</a> to uninstall the action script version. Then, install the webhook version',
          },
        ],
      },
      {
        source_system: 'solarwinds',
        faqs: [
          {
            question: 'How can I remove the subscription and stop sending events to BigPanda?',
            answer:
              'Use the BigPanda SolarWinds Integration utility to obtain the Subscription ID' +
              'and remove the integration. For more information, see ' +
              '<a href="https://www.bigpanda.io/docs/display/BD/Uninstalling+SolarWinds" target="_blank">our documentation</a>.',
          },
        ],
      },
      {
        source_system: 'logicmonitor',
        faqs: [
          {
            question: 'How can I stop sending LogicMonitor events to BigPanda?',
            answer:
              "You can remove the integration in your LogicMonitor's settings. For certain error levels of events you can update " +
              'your escalation chain and decide which type of error levels will be sent to BigPanda.',
          },
        ],
      },
    ],
  });
