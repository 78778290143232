import get from 'lodash/get';
import find from 'lodash/find';
import pluralize from 'common/utils/utils';
import {
  INTEGRATION_NAME_MAX_LENGTH,
  AUTO_RESOLVE_TIME_OPTIONS,
  AUTO_RESOLVE_TOOLTIP,
  DONT_RESOLVE_OPTION,
  DEFAULT_VAL,
} from '../../integrations.constants';

angular.module('bigpanda').controller('IntegrationStatusController', IntegrationStatusController);

function IntegrationStatusController(
  $scope,
  $http,
  $state,
  stateService,
  UserIntegrationsStore,
  notificationService,
  pubSubService,
  PERMISSIONS_NAMES,
  Permissions,
  UserFeatureTogglesService,
  UserIntegrationsBackendService,
  AvailableIntegrationsStore
) {
  const vm = this;
  const ESCAPE = 27;

  vm.deleteIntegration = deleteIntegration;
  vm.buttons = [
    {
      icon: 'bp-icon-trash',
      name: 'Delete',
      action: deleteIntegration,
      permission: PERMISSIONS_NAMES.integrations.delete,
    },
  ];
  vm.onKeyDown = onKeyDown;
  vm.editNameClick = editNameClick;
  vm.cancelEdit = cancelEdit;
  vm.saveName = saveName;
  vm.setInputFocused = setInputFocused;
  vm.pluralize = pluralize;
  vm.inputFocused = true;
  vm.integrationsPermissions = PERMISSIONS_NAMES.integrations;
  vm.isTroubleshootingFeatureEnabled = Permissions.hasFullAccess('integrations');
  vm.errorsTooltip = 'integrations/integration_info/status/troubleshooting_tooltip';
  vm.troubleshootingState = false;
  vm.saveAutoResolveConfig = saveAutoResolveConfig;
  vm.integrationLoading = true;

  init();

  function init() {
    getIntegration();
    updateTroubleshootingState($state.current);
    UserIntegrationsStore.subscribe($scope, { storeUpdated: getIntegration });
    pubSubService.on('$stateChangeSuccess', (event, toState) =>
      updateTroubleshootingState(toState)
    );
  }


  function deleteIntegration(integration) {
    pubSubService.broadcast('Intercom.showNewMessage', {
      message: `[${integration.system_id}] Please delete the following integration: ${integration.name} (ID: ${integration.stream_id})`,
    });
  }

  function onKeyDown($event) {
    if ($event.keyCode === ESCAPE) {
      vm.cancelEdit();
    }
  }

  function editNameClick() {
    vm.editName = true;
  }

  function setInputFocused(focused) {
    vm.inputFocused = focused;
  }

  function cancelEdit() {
    vm.newName = vm.integration.name;
    vm.error = '';
    vm.editName = false;
  }

  function saveName(newName) {
    vm.error = '';
    if (validateName()) {
      let payload;
      if (newName) {
        vm.newName = newName;
      }
      if (vm.integration.type !== 'target') {
        payload = {
          name: vm.newName,
          parent_source_system: vm.integration.parent_source_system,
        };
        $http
          .put(`/web/organization/integrations/${vm.integration.source_system}/name`, payload)
          .then(handleSuccess, handleError);
      } else {
        payload = {
          name: vm.newName,
          parent_system_id: vm.integration.parent_system_id,
          target_system_id: vm.integration.target_system_id,
        };
        $http
          .put(
            `/web/organization/integrations/outbound/${vm.integration.target_system_id}/name`,
            payload
          )
          .then(handleSuccess, handleError);
      }
    }
  }

  function getIntegration() {
    vm.enableAutoResolveDefault = UserFeatureTogglesService.getToggle(
      'frontier_enable_auto_resolve_default'
    );
    vm.enableDontResolve = UserFeatureTogglesService.getToggle(
        'frontier_enable_auto_resolve_dont_resolve'
    );

    UserIntegrationsStore.getIntegration(stateService.getSelectedIntegrationId()).then(
      async (vmIntegration) => {
        if (!vmIntegration) {
          $state.go('^');
          return null;
        }
        const integrationTypeConfig = find(AvailableIntegrationsStore.getAvailableIntegrations(), {
          source_system: vmIntegration.parent_source_system,
        });
        vm.integration = vmIntegration;
        vm.imgUrl = `/assets/img/logos/${vm.integration.parent_source_system.replace(' ', '')}.png`;
        vm.newName = vm.integration.name;
        vm.isAlert = vm.integration.type === 'alert';
        vm.idTooltip = getIntegrationIdTooltip(vm.integration);
        vm.displayErrors =
          vm.isTroubleshootingFeatureEnabled &&
          get(integrationTypeConfig, 'category') === 'monitoring';
        if (vm.displayErrors && vm.integration.last_week_error_count === undefined) {
          vm.integration.last_week_error_count = await UserIntegrationsBackendService.getLastWeekErrors(
            vm.integration.system_id
          );
          UserIntegrationsStore.addErrorsToIntegrationCache(
            vm.integration.source_system,
            vm.integration.last_week_error_count
          );
        }
        vm.autoResolveTooltip = AUTO_RESOLVE_TOOLTIP;

        vm.autoResolveValue = getAutoResolveValue();

        vm.integrationLoading = false;
      }
    );
  }

  function validateName() {
    if (vm.newName.length > INTEGRATION_NAME_MAX_LENGTH) {
      vm.error = `Use up to ${INTEGRATION_NAME_MAX_LENGTH} characters`;
      return false;
    }
    return true;
  }

  function handleError(err) {
    if (err.status === 412) {
      vm.error = 'Name already exist.';
    } else {
      vm.error = 'Server error. please try again.';
    }
  }

  function handleSuccess() {
    vm.integration.name = vm.newName;
    notificationService.info('Integration name was updated successfully');
    vm.editName = false;
  }

  function updateTroubleshootingState(state) {
    vm.troubleshootingState = state.name.endsWith('troubleshooting');
  }

  const getIntegrationIdTooltip = ({ system_id, type }) =>
    type === 'target'
      ? 'The integration identifier can be used in API calls'
      : `The integration identifier can be used in BPQL, search and API calls (e.g: source_system = '${system_id}')`;

  function getAutoResolveValue() {
    const resolveValues = [...AUTO_RESOLVE_TIME_OPTIONS, ...(vm.enableDontResolve ? [DONT_RESOLVE_OPTION] : [])]
    let autoResolveValue = resolveValues.find((item) =>
      item.label.includes(DEFAULT_VAL)
    ).label;
    const resolveAfterInactivityMS = get(
      vm,
      'integration.config.auto_resolve.resolve_after_inactivity_ms'
    );
    if (resolveAfterInactivityMS) {
      const foundOption = resolveValues.find(
        (o) => Number(o.value) == vm.integration.config.auto_resolve.resolve_after_inactivity_ms
      );
      if (foundOption) {
        autoResolveValue = foundOption.label;
      }
    }

    return autoResolveValue;
  }

  function saveAutoResolveConfig(autoResolveTime) {
    if (!autoResolveTime) return;

    const config = {
      auto_resolve: { resolve_after_inactivity_ms: Number(autoResolveTime.id) },
    };

    $http
      .put(`/web/integrations/${vm.integration.source_system}`, { config })
      .then(() => {
        vm.integration.config = {
          auto_resolve: {
            resolve_after_inactivity_ms: autoResolveTime.id,
          },
        };
        notificationService.info('Integration auto-resolve was updated successfully');
        vm.autoResolveValue = getAutoResolveValue();
      })
      .catch(() => {
        vm.error = `Server error. please try again.`;
        notificationService.error(vm.error);
      });
  }
}
