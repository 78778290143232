angular.module('bigpanda').directive('dashboardEnvironmentWidget', dashboardEnvironmentWidget);

function dashboardEnvironmentWidget() {
  return {
    scope: {
      widget: '<',
      dashboard: '<',
    },
    templateUrl: 'dashboard/dashboard_environment_widget',
    restrict: 'E',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(EnvironmentsService) {
    const vm = this;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;

    function onInit() {
      EnvironmentsService.getEnvById(vm.widget.envId).then((env) => {
        vm.name = env.name;
      });

      onChanges();
    }

    function onChanges() {
      if (vm.dashboard) {
        vm.dashboardType = vm.dashboard.dashboard_type;
      }
    }
  }
}
