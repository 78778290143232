import partial from 'lodash/partial';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import debounce from 'lodash/debounce';
import chunkArray from 'lodash/chunk';

import pLimit from 'p-limit';
angular.module('bigpanda.utils').service('PromiseBatchService', PromiseBatchService);

const DEFAULT_CHUNK_SIZE = 100;
const DEFAULT_PARALLELISM = 2;

/**
 * A service for batching promises, i.e. for batching mutliple backend requests into one request.
 * This allows us to call the wrapped function as often as we want without worrying about performance.
 * The calls are debounced so that the batch is only executed after some (configurable) time has passed since the last call.
 *
 * @returns {Function} Returns an instance of the batching function.
 */
function PromiseBatchService($q, $log) {
  this.batch = batch;

  /**
   * @param {Function} func The function to batch, receives one parameter which is an array of string ids.
   * @param {Function} mapper The function mapping between the each id sent to the batched function and its matching result.
   * @param {number} [wait=0] The number of milliseconds to send for the debounce.
   * @param {Function} singleFunc Optional function that receives a single id, can be used to more efficiently handle single id requests
   * when batching isn't necessary. When supplied the batch function is also run on the leading edge the timeout, so that on cases when
   * there is only one request there's no unnecessary slowdown while waiting for more requests.
   * @returns {Promise} Returns a promise resolving to an array of batched results.
   */
  function batch(func, mapper, wait = 0, singleFunc) {
    let limit = pLimit(DEFAULT_PARALLELISM);
    let batchToExecute = new Map();
    const debouncedExecute = debounce(executeBatch, wait, { leading: singleFunc !== undefined });

    return execute;

    function execute(arg) {
      if (!isString(arg) && !isNumber(arg)) {
        const err = new Error(`Illegal arg ${arg} passed to batcher, must be a string or a number`);
        $log.error(err.message);
        return $q.reject(err);
      }

      const deferred = getOrSetDeferred(arg);
      debouncedExecute();

      return deferred.promise;
    }

    function handlePromise({ promise, chunk }) {
      return promise
        .then((results) => {
          for (const [arg, deferred] of chunk) {
            const result = find(results, partial(mapper, arg));

            if (result) {
              deferred.resolve(result);
            } else {
              deferred.reject(
                new Error(`Failed to execute mapper for arg ${arg}, mapper ${mapper}`)
              );
            }
          }
        })
        .catch((err) => {
          $log.error(`Failed to executeBatch: ${err}`);
          $log.debug(err);

          for (const [arg, deferred] of chunk) {
            deferred.reject(`Failed to execute batch: ${err}, arg: ${arg} `);
          }
        });
    }

    function executeBatch() {
      const batchCopy = new Map(batchToExecute);
      batchToExecute = new Map();
      const idsArr = Array.from(batchCopy.entries());

      const chunks = chunkArray(idsArr, DEFAULT_CHUNK_SIZE);

      const promises = chunks.map((chunk) => {
        let promise;
        if (singleFunc && chunk.length === 1) {
          promise = handlePromise({ promise: singleFunc(chunk[0][0]).then((res) => [res]), chunk });
        } else {
          promise = handlePromise({
            promise: limit(() => func(chunk.map((arg) => arg[0]))),
            chunk,
          });
        }
        return promise;
      });

      return Promise.allSettled(promises);
    }

    function getOrSetDeferred(arg) {
      let deferred = batchToExecute.get(arg);

      if (!deferred) {
        deferred = $q.defer();
        batchToExecute.set(arg, deferred);
      }

      return deferred;
    }
  }
}
