import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './navigation_item.scss';

const NavigationItem = ({ title, icon, selectedItem }) => {
  const isItemSelected = title === selectedItem;
  const iconClass = classnames([styles['item-icon'], icon]);
  const navigationItemClass = classnames([
    styles['navigation-item'],
    { [styles.selected]: isItemSelected },
  ]);

  return (
    <span className={navigationItemClass}>
      <i className={iconClass} aria-hidden />
      <span className={styles['item-text']}>{title}</span>
    </span>
  );
};

NavigationItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selectedItem: PropTypes.string,
};

export default NavigationItem;
