import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Title, Groups } from './SettingsPageMenu.css';
import MenuGroup from '../components/menu-group/MenuGroup';

const SettingsPageMenu = ({ headerText, itemsGroup, ...rest }) => {
  return (
    <Wrapper>
      <Title>{headerText}</Title>
      <Groups>
        {itemsGroup.map(
          (group) =>
            !!group.list.length && (
              <MenuGroup
                key={`menuGroup${group.title}`}
                title={group.title}
                list={group.list}
                {...rest}
              />
            )
        )}
      </Groups>
    </Wrapper>
  );
};

SettingsPageMenu.propTypes = {
  headerText: PropTypes.string,
  itemsGroup: PropTypes.arrayOf(PropTypes.object) /* eslint-disable-line */,
};

SettingsPageMenu.defaultProps = {
  headerText: undefined,
  itemsGroup: [],
};

export default SettingsPageMenu;
