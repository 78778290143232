angular.module('bigpanda').controller('SessionWarningCtrl', SessionWarningCtrl);

function SessionWarningCtrl(
  $scope,
  $interval,
  AuthService,
  SessionTimeoutWarningService,
  cookieStore,
  Config
) {
  const vm = this;
  let countdownCounter;

  vm.countdown = countdown;
  vm.renewToken = renewToken;
  vm.logOff = AuthService.resetUser;

  countdown();

  function getDisplayCounter(duration) {
    return duration.minutes() > 0
      ? `${duration.minutes()} min ${duration.seconds()} sec`
      : `${duration.seconds()} sec`;
  }

  function getCookie(cookieName) {
    return parseInt(cookieStore.getCookie(cookieName));
  }

  function closeAndRenewToken() {
    $scope.close();
    renewToken();
  }

  function getCookieSuffix() {
    return Config.cookiesSuffix ? `_${Config.cookiesSuffix}` : '';
  }

  function countdown() {
    const sessionTimeoutUnix = parseInt(getCookie(`st${getCookieSuffix()}`));
    const sessionDuration = moment.duration(sessionTimeoutUnix - moment().unix(), 's');

    vm.timeCounter = getDisplayCounter(sessionDuration);
    countdownCounter = $interval(() => {
      const updatedSessionTimeoutUnix = parseInt(getCookie(`st${getCookieSuffix()}`));
      if (updatedSessionTimeoutUnix > sessionTimeoutUnix) {
        return closeAndRenewToken();
      } else if (!updatedSessionTimeoutUnix) {
        logOutUser();
      }

      sessionDuration.subtract(1, 'seconds');
      if (sessionDuration._milliseconds <= 0) {
        logOutUser();
      }
      vm.timeCounter = getDisplayCounter(sessionDuration);
    }, 1000);
  }

  function logOutUser() {
    $interval.cancel(countdownCounter);
    $scope.close();
    vm.logOff({ expired: true });
  }

  function renewToken() {
    SessionTimeoutWarningService.resetWarningDisplay();
    $interval.cancel(countdownCounter);
    SessionTimeoutWarningService.renewToken();
  }
}
