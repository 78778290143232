import map from 'lodash/map';
angular.module('bigpanda').service('CorrelationRuleActions', CorrelationRuleActions);

function CorrelationRuleActions(
  $state,
  ModalService,
  CorrelationActions,
  CorrelationRulesService,
  Permissions,
  PERMISSIONS_NAMES
) {
  this.getSourceSystems = getSourceSystems;
  this.toggleCorrelationRule = toggleCorrelationRule;
  this.buttons = [];

  if (Permissions.isPermitted(PERMISSIONS_NAMES.correlations.update)) {
    this.buttons.push({
      name: 'Edit',
      action: openEditRoute,
      icon: 'bp-icon-edit',
    });
  }

  if (Permissions.isPermitted(PERMISSIONS_NAMES.correlations.create)) {
    this.buttons.push({
      name: 'Duplicate',
      action: openDuplicateRoute,
      icon: 'bp-icon-duplicate',
    });
  }

  if (Permissions.isPermitted(PERMISSIONS_NAMES.correlations.delete)) {
    this.buttons.push({
      name: 'Delete',
      action: deleteCorrelationRule,
      icon: 'bp-icon-trash',
    });
  }

  const toggleText = {
    title: 'Deactivate Pattern?',
    content: `
      <b>Are you sure you want to deactivate this pattern?</b><br>
      <br>
      New incidents will not be correlated by it and<br>
      undoing this action may take a few minutes.
    `,
  };

  const deleteText = {
    title: 'Delete Pattern?',
    content: `
      <b>Are you sure you want to delete this pattern?</b><br>
      <br>
      New incidents will not be correlated by it<br>
      and you will have to create it again to restore it.
    `,
  };

  function toggleCorrelationRule(rule) {
    if (!Permissions.isPermitted(PERMISSIONS_NAMES.correlations.update)) return;

    if (rule.active) {
      const toggleCallback = () => toggleAction(rule);
      CorrelationActions.confirmToggleItem(rule, toggleText, toggleCallback);
    } else {
      toggleAction(rule);
    }
  }

  function deleteCorrelationRule(rule) {
    const deleteCallback = () => deleteAction(rule);
    CorrelationActions.confirmDeleteItem(rule, deleteText, deleteCallback);
  }

  function toggleAction(correlation) {
    CorrelationRulesService.toggleCorrelationRuleState(correlation);
  }

  function deleteAction(rule) {
    ModalService.hide();
    $state.go('app.settings.correlations');
    CorrelationRulesService.removeCorrelationRule(rule.id);
  }

  function openEditRoute(rule) {
    if (!angular.isObject(rule)) {
      return;
    }
    $state.go('app.settings.correlations.info.edit', {
      correlationId: rule.id,
    });
  }

  function openDuplicateRoute(rule) {
    $state.go('app.settings.correlations.info.duplicate', {
      correlationId: rule.id,
    });
  }

  function getSourceSystems(correlationRule) {
    const systems = map(correlationRule.sourceSystems, (system) => system.display);
    return systems.join(', ');
  }
}
