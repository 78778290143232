import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import partition from 'lodash/partition';
import get from 'lodash/get';
angular.module('bigpanda').service('DashboardsSortingUtils', DashboardsSortingUtils);

function DashboardsSortingUtils() {
  this.sort = sort;

  function sort(dashboards, dashboardCustomizations) {
    const favoriteDashboardsIds = get(dashboardCustomizations, 'navigation_list.favorites', []);
    const [favoriteDashboards, nonFavoriteDashboards] = partition(dashboards, (dashboard) =>
      includes(favoriteDashboardsIds, dashboard._id)
    );

    return {
      favoriteDashboards: sortBy(favoriteDashboards, 'name'),
      nonFavoriteDashboards: sortBy(nonFavoriteDashboards, 'name'),
    };
  }
}
