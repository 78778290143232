angular.module('bp.widgets').directive('bpGenericListButton', () => {
  return {
    restrict: 'E',
    scope: {
      href: '@',
    },
    transclude: true,
    templateUrl: 'widgets/bp_generic_list_button/bp_generic_list_button',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller() {
    const vm = this;
    vm.onClick = onClick;

    function onClick() {
      if (vm.href) {
        window.location.replace(vm.href);
      }
    }
  }
});
