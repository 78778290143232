import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
export function getValueRecursive(item, property) {
  let val = null;

  Object.keys(item).forEach((key) => {
    if (typeof item[key] === 'object' && !isArray(item[key])) {
      val = getValueRecursive(item[key], property);
      if (val) {
        return val;
      }
    }
    if (key === property) {
      return item[key];
    }
    return null;
  });

  return val;
}

/**
 * Find the item's value for the given property.
 *
 * This will do a DFS search for the value by going to deeper level object.
 */
export function getItemPropertyValue(item, property) {
  const prop = isArray(property) ? property : [property];
  return reduce(
    prop,
    (value, propName) => {
      const currentValue = getValueRecursive(item, propName);
      return value || currentValue;
    },
    undefined
  );
}
