import { IColumn } from '@bp/kung-fu';
import get from 'lodash/get';

import { text } from '../../../../../../../../constants';
import { ActionColumn } from '../../../../../Columns';
import { EncrichmentAudit } from './types';

const columnDefaultSize = 125;

export const columnData: IColumn[] = [
  {
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Exec. Order',
    width: columnDefaultSize,
    key: 'order',
  },
  {
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Function Type',
    width: columnDefaultSize,
    key: 'functionType',
  },
  {
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Value',
    width: columnDefaultSize,
    key: 'value',
  },
  {
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Source',
    width: columnDefaultSize,
    key: 'source',
  },
  {
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Condition',
    width: columnDefaultSize,
    key: 'condition',
  },
  {
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Note',
    width: columnDefaultSize,
    key: 'note',
  },
  {
    cellRenderer: ActionColumn,
    isBasicFont: true,
    isResizable: true,
    isSortable: true,
    name: 'Status',
    width: columnDefaultSize,
    key: 'status',
  },
] as IColumn[];

export const alertEnrichmentMapper = (enrichments: Array<Record<string, string>>)
: EncrichmentAudit[] => enrichments
  .map((enrichment, index) => ({
    id: enrichment.id,
    order: index + 1,
    functionType: enrichment.type,
    value: get(enrichment, 'config.value', '-'),
    source: get(enrichment, 'config.source', '-'),
    condition: get(enrichment, 'config.regex', '-'),
    note: enrichment.note ? enrichment.note : '-',
    status: get(enrichment, 'active', true) === true
      ? text.expandRowAlertEnrichment.status.active : text.expandRowAlertEnrichment.status.inactive,
  }));
