import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { BamCollapsibleIncidentTile } from '@bp/bam';
import styles from './correlation_pattern_preview_items.scss';
import CorrelationPatternPreviewEntitiesTable from './CorrelationPatternPreviewEntitiesTable';

const INITIAL_ROW_COUNT = 5;

class CorrelationPatternPreviewItems extends React.PureComponent {
  closedIncidents = {};
  expandedTables = {};

  handleIncidentToggled = (incidentId, open) => {
    this.closedIncidents[incidentId] = !open;
    if (!open) {
      this.expandedTables[incidentId] = null;
    }
  };

  renderItem = ({ index, style }) => {
    const { incidentsData } = this.props;
    const incidentData = incidentsData[index];
    const isOpen = !this.closedIncidents[incidentData.id];
    return (
      <div className={styles['incident-container']} style={style} key={index}>
        <BamCollapsibleIncidentTile
          incident={incidentData}
          open={isOpen}
          onClick={this.handleIncidentToggled}
          hideTimeWindow
        >
          <CorrelationPatternPreviewEntitiesTable
            incidentData={incidentData}
            initialRowCount={INITIAL_ROW_COUNT}
            expanded={!!this.expandedTables[incidentData.id]}
          />
        </BamCollapsibleIncidentTile>
      </div>
    );
  };

  render() {
    const { incidentsData } = this.props;
    return <div>{incidentsData.map((a, i) => this.renderItem({ index: i }))}</div>;
  }
}

CorrelationPatternPreviewItems.propTypes = {
  incidentsData: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
};

export default hot(module)(CorrelationPatternPreviewItems);
