import { HBox, SCHBox, SCVBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const RowDetailsBodyWrapper = styled('div')`
  margin-bottom: 15px;
  text-transform: capitalize;
`;

export const DetailsRow = styled(SCHBox)`
  min-height: 40px;
  align-items: flex-start;
  height: 100%;
  width: 850px;
`;

export const DetailsColumn = styled(SCHBox)`
  min-width: 210px;
`;

export const DetailsValueColumn = styled(SCVBox)`
`;

export const ShowMoreWrapper = styled(HBox)`
  width: 100%;
  justify-content: flex-start;
  margin-top: 6px;
`;
