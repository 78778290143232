import { react2angular } from 'react2angular';
import IncidentLabelsInfo from './components/IncidentLabelsInfo';
const routes = {};

routes.appSettingsIncidentEnrichmentInfoReact = {
  url: '/:incidentLabelId',
  views: {
    'master-detail-info@app.settings.incidentenrichment': {
      template:
        '<incident-labels-info class="react-settings-info" incident-label-id="stateParams.incidentLabelId" store="store" />',
      controller: ($scope, $ngRedux, $stateParams) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
      },
    },
  },
};

angular
  .module('bigpanda')
  .component('incidentLabelsInfo', react2angular(IncidentLabelsInfo, ['incidentLabelId', 'store']));

export default routes;
