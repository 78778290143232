/* eslint-disable max-len */
export default {
  edit_integration_label: 'Edit integration',
  edit_integration_modal_cancel_button: 'Cancel',
  edit_integration_modal_update_button: 'Update',
  edit_integration_modal_note: 'Note: Resolving existing active alerts may not be immediate.',
  edit_integration_modal_name_field_label: 'Integration Name',
  edit_integration_modal_name_field_max_length_error: 'Use up to {{max_val}} characters',
  edit_integration_modal_auto_resolve_field_label: 'Auto-resolve',
  edit_integration_modal_auto_resolve_field_tooltip: 'The amount of time elapsed before alerts from this integration are resolved automatically',
  edit_integration_modal_auto_resolve_field_static_notice: 'The change will affect both existing and future alerts',
};
/* eslint-enable max-len */
