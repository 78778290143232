import moment from 'moment';

import { IRelativity } from '../../../types/MaintenancePlan';

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);
export const formatOrdinal = (n: number): string => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export const maintenancePlansOperatorsText = '= | != | IN | NOT IN | OR | AND';

export const dateFormat = 'MMM Do, YYYY';

export const getDaysOfMonth = (date: moment.Moment): Record<string, number[]> => {
  const daysInMonth = date.daysInMonth();
  const daysObj = days.reduce((acc, day) => ({ ...acc, [day]: [] }), {});
  for (let i = 1; i <= daysInMonth; i += 1) {
    const current = date.clone().date(i);
    daysObj[current.format('dddd')].push(i);
  }
  return daysObj;
};

export const getRelativeDayString = (n: number): IRelativity => {
  if (n === 0) return 'First';
  if (n === 1) return 'Second';
  if (n === 2) return 'Third';
  if (n === 3) return 'Fourth';
  return 'Last';
};

export const getRelativeDayNumber = (r: IRelativity): number => {
  if (r === 'First') return 0;
  if (r === 'Second') return 1;
  if (r === 'Third') return 2;
  if (r === 'Fourth') return 3;
  return 4;
};
