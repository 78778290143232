import { CodeSizes } from '@bp/kung-fu';
import React from 'react';

import highlightedExtractionTagParts from '../../../../../../app/common/utils/highlightedExtractionTagParts';
import { HighlightedNode } from './HighlightTag.css';

export interface ExtractionTagProps {
  value: string;
  regex: string;
}

export function ExtractionTag({ value, regex }: ExtractionTagProps): JSX.Element {
  const parts = highlightedExtractionTagParts(value, regex);

  return parts.map((part, idx) => (
    <HighlightedNode key={`part_${part}`} isHighlighted={idx === 1} size={CodeSizes.SMALL_CODE}>
      {part}
    </HighlightedNode>
  ));
}

export default ExtractionTag;
