const INTEGRATION_NAME_MAX_LENGTH = 35;
const DEFAULT_VAL = 'Default';
const AUTO_RESOLVE_TIME_OPTIONS = [
  { label: '1 Hour', value: '3600000', rawLabel: '1h' },
  { label: '2 Hours', value: '7200000', rawLabel: '2h' },
  { label: '4 Hours', value: '14400000', rawLabel: '4h' },
  { label: '8 Hours', value: '28800000', rawLabel: '8h' },
  { label: '12 Hours', value: '43200000', rawLabel: '12h' },
  { label: '1 Day', value: '86400000', rawLabel: '1d' },
  { label: '2 Days', value: '172800000', rawLabel: '2d' },
  { label: '3 Days', value: '259200000', rawLabel: '3d' },
  { label: '7 Days', value: '604800000', rawLabel: '7d' },
  { label: '14 Days', value: '1209600000', rawLabel: '14d' },
  { label: `30 Days (${DEFAULT_VAL})`, value: '2592000000', rawLabel: '30d' },
  { label: '60 Days', value: '5184000000', rawLabel: '60d' },
  { label: '90 Days', value: '7776000000', rawLabel: '90d' },
];

const DONT_RESOLVE_OPTION =   { label: 'Do not auto resolve', value: '1702944000000', rawLabel: 'Never' };
const SOURCE_INFO_LOADING_TEXT = 'Loading...';
const SOURCE_INFO_FAILED_TEXT = 'Failed to fetch source information.';
const AUTO_RESOLVE_TOOLTIP =
  'Alerts that are not updated within this timeframe will be auto resolved.';

module.exports = {
  INTEGRATION_NAME_MAX_LENGTH,
  AUTO_RESOLVE_TIME_OPTIONS,
  DONT_RESOLVE_OPTION,
  SOURCE_INFO_LOADING_TEXT,
  SOURCE_INFO_FAILED_TEXT,
  AUTO_RESOLVE_TOOLTIP,
  DEFAULT_VAL,
};
