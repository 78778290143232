angular.module('bigpanda').directive('editorRules', () => ({
  restrict: 'E',
  replace: true,
  scope: {
    sources: '=',
    rules: '=',
    beforeSubmit: '=',
    showModal: '=',
    setChecks: '=',
    isNotPreviewRefresh: '=',
    incidentTagIsSelectedByRule: '=',
    tags: '=',
    priorities: '=',
    operators: '=',
    userList: '=',
  },
  bindToController: true,
  templateUrl: 'overview/environments/editor/rules/editor_rules',
  controller: 'EditorRulesCtrl',
  controllerAs: 'editorRulesVm',
}));
