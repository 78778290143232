import { path as prevPath } from '../constants';

export const name = 'sso';
export const path = `${prevPath}.${name}`;

export const SSO_STATES = {
  TESTING: 'testing',
  TESTED: 'tested',
  OK: 'ok',
};
