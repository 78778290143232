import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';
import get from 'lodash/get';
import { Wrapper, Title, ErrorMessage } from './Select.css';
import { defaultItemToString, filterItems, getFlatItems } from './utils';
import { minItemsForSearch, variants } from './constants';
import { itemPropTypes } from './propTypes';
import { SelectMenu, SelectToggle } from './components';

const Select = ({
  value,
  items,
  getItemDisplayValue,
  multiChoice,
  placeholder,
  title,
  variant,
  fullWidth,
  metadata,
  error,
  disabled,
  onChange,
  inline,
  selectToggle,
  errorMessage,
  openToLeft,
  ariaLabel,
  openToTop,
  onToggle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(items);
  const [query, setQuery] = useState('');
  const selectedItem = allItems.find((item) => get(item, 'value') === value) || null;
  const selectedValue = get(selectedItem, 'value');
  const selectedGroup = get(selectedItem, 'group');
  const selectedGroupValue = selectedGroup ? getItemDisplayValue(selectedGroup) : undefined;
  const shouldShowSearch = allItems.length > minItemsForSearch;

  const onIsOpenChange = ({ isOpen }) => {
    setIsOpen(isOpen);
    onToggle(isOpen);
    if (isOpen) {
      onQueryChange('');
    }
  };

  const onQueryChange = (query) => {
    setQuery(query);
    setFilteredItems(filterItems(items, getItemDisplayValue, query));
  };

  const { getToggleButtonProps, getMenuProps, getLabelProps, getItemProps } = useSelect({
    items: getFlatItems(filteredItems),
    selectedItem,
    disabled,
    getItemDisplayValue,
    onIsOpenChange,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      onChange && onChange(get(newSelectedItem, 'value'), metadata, newSelectedItem);
    },
  });

  useEffect(() => {
    setAllItems(getFlatItems(items));
  }, [items]);

  const defaultSelectToggle = (
    <SelectToggle
      name={ariaLabel}
      ariaLabel={ariaLabel}
      role="listbox"
      value={value}
      isOpen={isOpen}
      getItemDisplayValue={getItemDisplayValue}
      selectedItem={selectedItem}
      selectedGroupValue={selectedGroupValue}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      variant={variant}
      fullWidth={fullWidth}
      multiChoice={multiChoice}
      getToggleButtonProps={getToggleButtonProps}
      getLabelProps={getLabelProps}
    />
  );

  return (
    <Wrapper disabled={disabled} fullWidth={fullWidth} aria-label={ariaLabel}>
      {title && <Title>{title}</Title>}
      {selectToggle ? selectToggle({ getToggleButtonProps }) : defaultSelectToggle}
      <SelectMenu
        isOpen={isOpen}
        filteredItems={filteredItems}
        getItemDisplayValue={getItemDisplayValue}
        multiChoice={multiChoice}
        query={query}
        openToTop={openToTop}
        openToLeft={openToLeft}
        selectedValue={selectedValue}
        shouldShowSearch={shouldShowSearch}
        getItemProps={getItemProps}
        getMenuProps={getMenuProps}
        onQueryChange={onQueryChange}
        variant={variant}
        inline={inline}
      />
      {!isOpen && error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...itemPropTypes,
      items: PropTypes.arrayOf(PropTypes.shape(itemPropTypes)),
    })
  ).isRequired,
  getItemDisplayValue: PropTypes.func,
  multiChoice: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(variants)),
  fullWidth: PropTypes.bool,
  metadata: PropTypes.shape({}),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  selectToggle: PropTypes.func,
  errorMessage: PropTypes.string,
  openToLeft: PropTypes.bool,
  openToTop: PropTypes.bool,
  ariaLabel: PropTypes.string,
  onToggle: PropTypes.func,
};

Select.defaultProps = {
  getItemDisplayValue: defaultItemToString,
  variant: variants.fill,
  fullWidth: false,
  errorMessage: null,
  error: false,
  openToTop: false,
  openToLeft: false,
  ariaLabel: null,
  onToggle: () => {},
};

export default Select;
