import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import SettingsListItemActions from 'react/layout/settings/components/SettingsListItemActions';
import actions from '../actions';
import selectors from '../selectors';
import CorrelationPatternModal from '../modals/CorrelationPatternModal';

const CorrelationPatternsActions = ({
  correlationPattern,
  deleteCorrelationPattern,
  actionPermissions,
}) => {
  const removeCorrelationPattern = () => deleteCorrelationPattern(correlationPattern.id);

  const { tagNames, id } = correlationPattern;
  const tagNamesAsString = tagNames.join(', ');

  const messages = {
    edit: `Cannot Edit correlation pattern '${tagNamesAsString}'`,
    delete: `Cannot Delete correlation pattern '${tagNamesAsString}'`,
  };

  return (
    <SettingsListItemActions
      item={correlationPattern}
      modelName="Pattern"
      messages={messages}
      editProps={{
        disabled: !actionPermissions.hasEditPermission,
        modalProps: { currentCorrelationPatternId: id, title: 'Edit Correlation Pattern' },
        modal: CorrelationPatternModal,
      }}
      duplicateProps={{
        disabled: !actionPermissions.hasDuplicatePermission,
        modalProps: { currentCorrelationPatternId: id },
        modal: CorrelationPatternModal,
      }}
      deleteProps={{
        disabled: !actionPermissions.hasDeletePermission,
        action: removeCorrelationPattern,
        modalProps: {
          contentTitleType: 'string',
          contentTitle: tagNamesAsString,
          content: 'Are you sure you want to delete this pattern?',
          note:
            'Note: New incidents will not be correlated by it and you will have to create it again to restore it.',
        },
      }}
    />
  );
};

CorrelationPatternsActions.propTypes = {
  correlationPattern: PropTypes.shape({
    id: PropTypes.string,
    tagNames: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  deleteCorrelationPattern: PropTypes.func.isRequired,
  actionPermissions: PropTypes.shape({
    hasEditPermission: PropTypes.bool,
    hasDuplicatePermission: PropTypes.bool,
    hasDeletePermission: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    actionPermissions: selectors.getActionsPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCorrelationPattern: (correlationPatternId) =>
      dispatch(actions.deleteCorrelationPattern(correlationPatternId)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hot(module)(CorrelationPatternsActions));
