import { path as prevPath } from '../constants';

export const name = 'rolesList';
export const path = `${prevPath}.${name}`;

export const CREATED_SORT_MODE = { text: 'Creation time', field: 'created_at', ascending: false };
export const UPDATED_SORT_MODE = {
  text: 'Updated',
  field: 'updated_at',
  ascending: false,
  isDefault: true,
};
export const NAME_SORT_MODE = { text: 'Role name', field: 'name', ascending: true };
export const SORT_MODES = [UPDATED_SORT_MODE, NAME_SORT_MODE, CREATED_SORT_MODE];

export const ENVIRONMENT_PERMISSIONS = [
  'Environments_Read',
  'Environments_Incident_Actions',
  'Environments_Full_Access',
];

export const DEFAULT_SORT_MODE = UPDATED_SORT_MODE;

export const ANY_OPTION_VALUE = '-1';

export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  permission: { value: ANY_OPTION_VALUE, key: ANY_OPTION_VALUE, text: 'Any Permission' },
  user: { value: ANY_OPTION_VALUE, key: ANY_OPTION_VALUE, text: 'Any User' },
};
