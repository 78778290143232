import React from 'react';
import { MultiStarsIcon, SCHBox } from '@bp/kung-fu';
import PropTypes from 'prop-types';

import dic from '../../dictionary';

import { AiButton, AiText, ButtonWrapper, GenerateText, IconWrapper } from './style.css';

const GenerateAi = ({ onAiGenerate }) => {
  return (
    <AiButton aria-label={dic.generate_now_title} onClick={onAiGenerate} width="100%">
      <SCHBox gap="5px" width="100%" height="100%">
        <IconWrapper>
          <MultiStarsIcon fontSize="26px" color={(p) => p.theme.bp_purple} />
        </IconWrapper>
        <AiText data-testid="ai-placeholder">{dic.ai_placeholder_title}</AiText>
      </SCHBox>
    </AiButton>
  );
};

GenerateAi.propTypes = {
  onAiGenerate: PropTypes.func,
};

export default GenerateAi;
