import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import provideStore from 'react/utils/provideStore';
import { BamEmptyMessage } from '@bp/bam';
import AutoShareRuleInfo from './AutoShareRuleInfo';
import selectors from '../../selectors';
import { rulePropType } from '../../propTypes';

class AutoShareRuleDetailsPane extends React.PureComponent {
  render() {
    const { rule } = this.props;

    if (!rule) {
      return (
        <BamEmptyMessage header="NO AUTOSHARE SELECTED">
          Select an AutoShare to see details
        </BamEmptyMessage>
      );
    }

    return <AutoShareRuleInfo rule={rule} />;
  }
}

AutoShareRuleDetailsPane.propTypes = {
  ruleId: PropTypes.string /* eslint-disable-line */,
  rule: rulePropType,
};

AutoShareRuleDetailsPane.defaultProps = {
  ruleId: '',
  rule: undefined,
};

function mapStateToProps(state, { ruleId }) {
  const rule = selectors.getAutoShareRule(state, ruleId);
  return { rule };
}

export default provideStore(connect(mapStateToProps)(AutoShareRuleDetailsPane));
