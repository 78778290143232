angular.module('bigpanda').service('MaintenancePlansService', MaintenancePlansService);

function MaintenancePlansService(
  $q,
  $filter,
  StreamConnector,
  pubSubService,
  MaintenancePlansBackendService,
  notificationService
) {
  this.getMaintenancePlansByIds = getMaintenancePlansByIds;
  return this;

  function getMaintenancePlansByIds(planIds) {
    return MaintenancePlansBackendService.getPlansByIds(planIds)
      .then((res) =>
        res.map((rawMaintenancePlan) => $filter('maintenancePlanNormalizer')(rawMaintenancePlan))
      )
      .catch((error) => {
        if (error.status === 504) {
          notificationService.error('Unexpected error, we will try to reconnect soon...', {
            disableScreen: true,
            until: ['Envy.refresh'],
          });
        }

        return $q.reject(error);
      });
  }
}
