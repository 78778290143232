import get from 'lodash/get';

import { generateBaseSelectors } from '../../../../app/react/modules/settings/redux.utils';
import { DEFAULT_SORT_MODE, path } from './constants';

const selectors = generateBaseSelectors(path);

selectors.getPlans = (state) => get(state, path).plans;

selectors.getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;
selectors.getError = (state) => get(state, path).error;
selectors.getLink = (state) => get(state, path).link;
selectors.isPaginating = (state) => get(state, path).isPaginating;
selectors.showDonePlans = (state) => get(state, path).showDonePlans;

export default selectors;
