angular.module('bigpanda').directive('overviewTitle', overviewTitle);

function overviewTitle() {
  return {
    restrict: 'E',
    scope: {
      boldText: '@',
      normalText: '@',
      selectTab: '=',
      tabName: '@',
      linkText: '@',
      showBubble: '=',
      showAllEntities: '=',
      alertsCount: '<',
    },
    templateUrl: 'overview/incidents/overview_title/overview_title',
    bindToController: true,
    controller: controller,
    controllerAs: 'overviewTitleVm',
  };

  function controller() {
    const vm = this;
    vm.tooltipTemplate = 'overview/incidents/overview_title/overview_title_tooltip';
  }
}
