import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { BamFormField } from '@bp/bam';
import CreatableMultiselect from '../../CreatableMultiselect/CreatableMultiselect';
import { CREATE_OPTION_POSITIONS } from '../../CreatableMultiselect/constants';
import styles from './configuration_labels_field.scss';
import { convertLabelsToDropdownOptions, validateNewLabel, parseLabel } from './utils';
import { ADD_NEW_VALUE_DISABLED_EXAMPLE, INPUT_PLACEHOLDER, NO_OPTION_MESSAGE } from './constants';
import { LabelPropType, LABEL_SEPARATOR, TITLE } from '../constants';

const MIN_VALID_INPUT = /^[\w-]+:./;

const LabelsComponentDefaultProps = {
  fieldTitle: TITLE,
  labelDefinitions: [],
  onOpen: () => undefined,
};

const LabelOption = ({ rawLabel: { group, value } }) => {
  return (
    <span className={styles['label-option']}>
      {group}
      {LABEL_SEPARATOR.DISPLAYED}
      <b>{value}</b>
    </span>
  );
};

LabelOption.propTypes = {
  rawLabel: LabelPropType.isRequired,
};

const AddNewValueDisabledHint = () => {
  return (
    <span>
      e.g. <b>{ADD_NEW_VALUE_DISABLED_EXAMPLE}</b>
    </span>
  );
};

const LabelsComponentProps = {
  fieldTitle: PropTypes.string,
  input: PropTypes.shape({
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(LabelPropType),
  }).isRequired,
  labelDefinitions: PropTypes.arrayOf(LabelPropType),
  onOpen: PropTypes.func,
};

class ConfigurationLabels extends React.Component {
  constructor(props) {
    super(props);
    const isSelectorOpen = !isEmpty(this.props.input.value);
    this.state = { isOpen: isSelectorOpen, inputValue: '' };
    if (isSelectorOpen) {
      this.props.onOpen();
    }
  }

  onAddNewLabel = ({ value: inputValue }) => ({ rawLabel: parseLabel(inputValue) });

  onSelectedLabelsChange = (selectedOptions) => {
    this.setState({ inputValue: '' });
    const selectedLabels = selectedOptions.map(({ rawLabel }) => rawLabel);
    this.props.input.onChange(selectedLabels);
  };

  setInputValue = (newValue) => {
    this.setState({ inputValue: newValue });
  };

  open = () => {
    this.setState({ isOpen: true }, this.props.onOpen);
  };

  filterOptionByInputValue = ({ data: { value = '', rawLabel: { isDisabled } = {} } }, input) => {
    if (!input || isDisabled) {
      return true;
    }
    const effectiveValue = input.replace(LABEL_SEPARATOR.DISPLAYED, LABEL_SEPARATOR.INTERNAL);
    return value.toLowerCase().includes(effectiveValue.toLowerCase());
  };

  isOptionDisabled = ({ value }) => !MIN_VALID_INPUT.test(value);

  render() {
    const { fieldTitle, input, labelDefinitions } = this.props;
    const { isOpen } = this.state;

    const labelOptions = convertLabelsToDropdownOptions(labelDefinitions);
    const selectedOptions = convertLabelsToDropdownOptions(input.value);

    return (
      <div className={styles['configuration-labels']} data-api-id="configuration-labels-field">
        {isOpen ? (
          <div>
            <CreatableMultiselect
              AddNewValueDisabledHint={AddNewValueDisabledHint}
              autoFocus={false}
              closeOnBlur
              createOptionPosition={CREATE_OPTION_POSITIONS.LAST}
              filterOption={this.filterOptionByInputValue}
              hideFirstOption={false}
              isOptionDisabled={this.isOptionDisabled}
              inputId="configuration-labels"
              inputValue={this.state.inputValue}
              noOptionsMessage={NO_OPTION_MESSAGE}
              onInputChange={this.setInputValue}
              options={labelOptions}
              onChange={this.onSelectedLabelsChange}
              onAddNewValue={this.onAddNewLabel}
              openMenuOnFocus
              optionClassName={cn([{ [styles['label-option-overline']]: !!this.state.inputValue }])}
              placeholder={INPUT_PLACEHOLDER}
              validateAllValues={() => ''}
              validateNewValue={(input) => validateNewLabel(input, labelDefinitions)}
              validateOnBlur
              values={selectedOptions}
              OptionLabel={LabelOption}
              ValueLabel={LabelOption}
            />
          </div>
        ) : (
          <div className={styles['title-container']}>
            <span
              className={cn(styles['labels-click'], { isOpen })}
              onClick={this.open}
              onKeyPress={this.open}
              role="button"
              tabIndex={0}
            >
              <i className={styles['icon-plus-icon']} />
              <span className={styles.title}>{fieldTitle}</span>
            </span>
          </div>
        )}
      </div>
    );
  }
}

ConfigurationLabels.propTypes = LabelsComponentProps;
ConfigurationLabels.defaultProps = LabelsComponentDefaultProps;

const ConfigurationLabelsField = (props) => (
  <BamFormField
    {...props}
    baseComponent={ConfigurationLabels}
    className="configuration-labels-field"
    optional
  />
);

ConfigurationLabelsField.propTypes = {
  ...LabelsComponentProps,
};

ConfigurationLabelsField.defaultProps = {
  ...LabelsComponentDefaultProps,
};

export { ConfigurationLabelsField, LabelPropType };
