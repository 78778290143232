import isString from 'lodash/isString';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
angular.module('bigpanda').service('EnvironmentsUtils', EnvironmentsUtils);

function EnvironmentsUtils(CustomizationsStore) {
  this.getDefaultEnv = getDefaultEnv;
  this.getAllEnv = getAllEnv;
  this.getEnvironmentCounters = getEnvironmentCounters;
  this.getFolderCounters = getFolderCounters;

  function getAllEnv(envs) {
    return find(envs, (env) => env.name.toLowerCase() === 'all');
  }

  function getDefaultEnv(envs) {
    const allEnv = getAllEnv(envs);

    return CustomizationsStore.getCustomizations()
      .then((customizations) => {
        const favoriteIds = customizations.environments.navigation_list.favorites;

        const sortedEnvironments = sortBy(envs, [sortByAllEnv, sortByName]);
        const favoriteEnvironments = sortedEnvironments.filter((env) =>
          favoriteIds.includes(env._id)
        );

        return head(favoriteEnvironments) || allEnv || head(sortedEnvironments) || null;
      })
      .catch(() => allEnv);

    function sortByAllEnv(env) {
      if (!allEnv || env._id !== allEnv._id) {
        return 0;
      }

      return -1;
    }

    function sortByName(env) {
      return env.name.toLowerCase();
    }
  }

  function getEnvironmentCounters(allEnvironmentsCounters, environment) {
    // It's a bit annoying that environment is sometimes an ID (and therefore a string) and sometimes a full environment object.
    // But since in some contexts we have the full object and in some only the ID, I didn't want to split it to two different functions.
    // Also, I didn't want to always get ID, because remembering to call environment._id (instead of environment.id or something else) is buggy,
    // and is better enclosed within this function, and not outside of it.
    const environmentId = isString(environment) ? environment : environment._id;
    return find(allEnvironmentsCounters, { id: environmentId });
  }

  function getFolderCounters(allEnvironmentsCounters, environment, folderId) {
    const envCounters = getEnvironmentCounters(allEnvironmentsCounters, environment);

    if (!envCounters) {
      return 0;
    }

    const folderCounter = find(envCounters.folders, { id: folderId });
    return folderCounter ? folderCounter.counter : 0;
  }
}
