import includes from 'lodash/includes';
import filter from 'lodash/filter';
import find from 'lodash/find';
angular.module('bigpanda').service('UsersStore', UsersStore);

function UsersStore(UsersBackendService, PersonalSettingsStore, SsoService, ReduxBridgeService) {
  let userPromise;
  let users = [];
  let rolesPromise;

  ReduxBridgeService.subscribe('users', (payload) => {
    users = payload;
    userPromise = null;
  });

  this.getUsers = getUsers;
  this.getUserById = getUserById;
  this.getUsersByIds = getUsersByIds;
  this.inviteUser = inviteUser;
  this.getRolesList = getRolesList;
  this.getUserByUsername = getUserByUsername;

  function convertDateToSeconds(isoDate) {
    return new Date(isoDate).getTime() / 1000;
  }

  function getStateCls(state) {
    switch (state) {
      case 'valid':
      case 'welcome':
        return 'active';
      case 'initial':
        return 'pending';
      default:
        return 'off';
    }
  }

  function normalizeUser(user) {
    return angular.extend({}, user, {
      id: user._id,
      cls: getStateCls(user.state),
      active: !!user.state,
      createdTimestamp: convertDateToSeconds(user.createdAt),
      firstLoginAtTimeStamp: convertDateToSeconds(user.firstLoginAt),
      lastLoginAtTimeStamp: convertDateToSeconds(user.lastLoginAt),
    });
  }

  function getUsers(forceReload) {
    if (!forceReload && userPromise) {
      // Since users list gets updated on invite user, we need a fresh copy.
      return userPromise.then(() => angular.copy(users));
    }

    userPromise = UsersBackendService.get().then((usersList) => {
      users = usersList.map(normalizeUser);
      return angular.copy(users);
    });

    return userPromise;
  }

  function getUserById(userId) {
    return getUsers().then((usersList) => find(usersList, { id: userId }));
  }

  function getUserByUsername(username) {
    return getUsers().then((usersList) => find(usersList, { username: username }));
  }

  function getUsersByIds(userIds) {
    return getUsers().then((usersList) => filter(usersList, (user) => includes(userIds, user.id)));
  }

  function inviteUser(username, name, phoneNumber, roles) {
    return inviteContext({ origin: 'invite' })
      .then((context) => UsersBackendService.invite(username, name, phoneNumber, roles, context))
      .then((newUser) => {
        const normalizedUser = normalizeUser(newUser);

        users.push(normalizedUser);

        return normalizedUser;
      });
  }

  function inviteContext(context = {}) {
    return PersonalSettingsStore.getUser().then((user) => {
      if (
        user.organization.config &&
        user.organization.config.sso &&
        user.organization.config.sso.status === 'ok'
      ) {
        const idpConf = SsoService.findIdP(user.organization.config.sso.idP);
        if (idpConf) {
          context.ssoIdP = idpConf.display;
          context.sso = true;
        }
      }

      return context;
    });
  }

  function getRolesList(forceReload) {
    if (!forceReload && rolesPromise) {
      return rolesPromise;
    }

    rolesPromise = UsersBackendService.getRoles().then((rolesList) => angular.copy(rolesList));

    return rolesPromise;
  }

  return this;
}
