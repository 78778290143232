import { hot } from 'react-hot-loader';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import BpTimeSince from 'common/utils/BpTimeSince';
import { AllSizes, Badge } from '@bp/kung-fu';
import { BamTile } from '@bp/bam';
import { SortShape } from 'react/layout/settings/components/SettingsList';
import moment from 'moment';
import { CREATED_SORT_MODE } from '../../constants';
import {
  ACTIVE_INTEGRATION_STATE,
  TARGET_INTEGRATION_TYPE,
} from '../../../../../../common/integrations/constants';

const labelTypes = {
  daily: (dataset_options) => {
    return {
      label: 'Daily Quota:',
      value: `${get(dataset_options, 'quota.used')}/${get(dataset_options, 'quota.available')}`,
    };
  },
  noData: { label: 'No incoming data' },
  noDataYet: { label: 'No incoming data yet' },
  active: (last_event) => {
    return {
      label: 'Last Event:',
      value: moment(last_event).format('MMM D, Y HH:mm'),
    };
  },
};

const ListItem = ({ integration, sortMode, ...props }) => {
  const generateFirstRow = () => ({
    customParts: [
      <Badge
        text={get(integration, 'status', '')}
        status={integration.status}
        isCapitalized
        size={AllSizes.SMALL}
      />,
    ],
    title: integration.name,
    time: {
      title: sortMode === CREATED_SORT_MODE ? 'Created' : 'Changed',
      timestamp: BpTimeSince(
        sortMode === CREATED_SORT_MODE
          ? integration.created_at
          : integration.updated_at || integration.created_at
      ),
    },
  });

  const getLabeledValue = () => {
    const { last_event, type, dataset_options, state } = integration;

    switch (type) {
      case TARGET_INTEGRATION_TYPE: {
        if (state === ACTIVE_INTEGRATION_STATE) {
          return labelTypes.daily(dataset_options);
        }
        return labelTypes.noData;
      }
      case ACTIVE_INTEGRATION_STATE: {
        return labelTypes.active(last_event);
      }
      default: {
        return labelTypes.noDataYet;
      }
    }
  };

  const generateSecondRow = () => {
    return {
      textParts: [getLabeledValue()],
    };
  };

  const handleClick = () => {
    window.location.href = `/#/app/settings/integrations/${integration.system_id}`;
  };

  return (
    <BamTile
      bamStatus={get(integration, 'status', '').toLowerCase()}
      firstRow={generateFirstRow()}
      secondRow={generateSecondRow()}
      onClick={handleClick}
      {...props}
    />
  );
};

ListItem.propTypes = {
  integration: PropTypes.object.isRequired /* eslint-disable-line */,
  sortMode: SortShape.isRequired,
};

export default hot(module)(ListItem);
