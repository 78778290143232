import React from 'react';
import PropTypes from 'prop-types';
import { BamFiltersPlaceHolder } from '@bp/bam';
import FiltersDropdown, {
  DropdownOptionType,
} from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION, ANY_OPTION_VALUE } from '../../constants';

const FiltersComponent = ({ filtersDropDownState, onChange, data }) => {
  const {
    name: { value: nameValue, text: nameText },
    status: { value: statusValue, text: statusText },
  } = filtersDropDownState;

  const dropdownElements = [
    <FiltersDropdown
      data={data.name}
      selectedValue={nameValue}
      selectedText={nameText}
      defaultText="Associated User"
      onChange={(e, selection) => onChange({ name: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.status}
      selectedValue={statusValue}
      selectedText={statusText}
      defaultText="Status"
      onChange={(e, selection) => onChange({ status: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={[nameValue, statusValue].some((v) => v !== ANY_OPTION_VALUE)}
      onClearButtonClick={() => {
        onChange({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
      }}
    />
  );
};
FiltersComponent.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    name: DropdownOptionType,
    status: DropdownOptionType,
  }).isRequired,
};
export default FiltersComponent;
