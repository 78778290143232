import actionTypes from './actionTypes';
import { generateBaseSettingsActions } from '../../redux.utils';

const actions = generateBaseSettingsActions(actionTypes);

actions.loadIntegrations = (payload) => ({ type: actionTypes.LOAD_INTEGRATIONS, payload: payload });
actions.loadIntegrationsSuccess = (payload) => ({
  type: actionTypes.LOAD_INTEGRATIONS_SUCCESS,
  payload: payload || [],
});

export default actions;
