import actionTypes from '../actionTypes';

const initialState = {
  featureToggles: undefined,
};

const mergeFeatureToggles = (mePayload) => ({
  ...((mePayload.organization || {}).feature_toggles || {}),
  ...(mePayload.feature_toggles || {}),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        featureToggles: mergeFeatureToggles(action.payload),
      };

    default:
      return state;
  }
}
