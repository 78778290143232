import React from 'react';
import { hot } from 'react-hot-loader';
import provideStore from 'react/utils/provideStore';
import AutoShareRules from '../../../../../workspaces/apps/auto-share/src/components/AutoShareRules';

const AutoShareRulesWrapper = function AutoShareRulesWrapper(props) {
  return <AutoShareRules {...props} />;
};

export default provideStore(hot(module)(AutoShareRulesWrapper));
