import assign from 'lodash/assign';
import extend from 'lodash/extend';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
angular.module('bigpanda').controller('FoldersCtrl', FoldersCtrl);

function FoldersCtrl($scope, $state, $stateParams, $mdSidenav, stateService, pubSubService) {
  const vm = this;

  pubSubService.on('folder.changed', changedFolder, $scope);

  vm.$onInit = init;

  $scope.$watch(
    'foldersVm.environmentCounters',
    (newFolders, oldFolders) => {
      if (newFolders === oldFolders) return;
      mergeFoldersWithCounters();
    },
    true
  );

  function changedFolder(e, folderId) {
    vm.selectedFolderId = folderId;
  }

  function mergeFoldersWithCounters() {
    vm.folders.forEach((folder) => {
      const folderCounter = vm.environmentCounters
        ? find(vm.environmentCounters.folders, { id: folder.id })
        : null;

      // It's important not to set the counter to 0 if there already is a counter set to this folder (hence the isNil check).
      if (!folderCounter && isNil(folder.counter)) {
        folder.counter = 0;
      } else if (folderCounter) {
        folder.counter = folderCounter.counter;
      }
    });
  }

  function init() {
    vm.folders = vm.environment.folders;
    vm.folderClick = folderClick;

    mergeFoldersWithCounters();

    const selectedId = stateService.getSelectedFolderId();
    const folder = find(vm.folders, { id: selectedId });
    vm.selectedFolderId = selectedId;

    if (selectedId && folder) {
      selectFolder(folder);
    } else {
      goToDefaultFolder();
    }
  }

  function folderClick(folder) {
    if (!folder.isActive) {
      pubSubService.broadcast('Folders.folderSelected', {
        folder: folder.name,
        environment: $stateParams.environment,
      });
    } else {
      closeSidenav();
      return;
    }
    const params = extend({}, $stateParams, { folder: folder.id });
    $state.go('app.overview.incidents.list', params);
    selectFolder(folder);
    closeSidenav();
  }

  function getFolderById(id) {
    return find(vm.folders, (folder) => folder.id === id);
  }

  function goToDefaultFolder() {
    const selectedEnvId = stateService.getSelectedEnvironmentId();
    const params = assign({}, $stateParams, { environment: selectedEnvId, folder: 'active' });
    $state.go('app.overview.incidents.list', params);
    selectFolder(getFolderById('active'));
  }

  function clearIsActive() {
    vm.folders.forEach((folder) => {
      folder.isActive = false;
    });
  }

  function selectFolder(folder) {
    clearIsActive();
    folder.isActive = true;
    vm.selectedFolderId = folder.id;
    pubSubService.broadcast('title.currentFolderChanged', folder.id);
  }

  function closeSidenav() {
    return $mdSidenav('left').close();
  }
}
