import {
  AllSizes, ITooltip, SCHBox, Text,
} from '@bp/kung-fu';
import React, { memo } from 'react';
import styled from 'styled-components';

import DisabledTooltip from './DisabledTooltip';

interface IFormField {
  label: string;
  isTooltipActive?: boolean;
  children: ITooltip['children'];
}

const Wrapper = styled(SCHBox)`
  margin-bottom: 16px;
`;

const Label = styled(Text).attrs({
  size: AllSizes.SMALL,
  weight: 'bold',
  color: (p): string => p.theme.bp_gray_07,
})`
  width: 80px;
`;

export default memo(({
  label, isTooltipActive = false, children,
}: IFormField) => (
  <Wrapper>
    <Label>{label}</Label>
    <DisabledTooltip isActive={isTooltipActive}>
      {children}
    </DisabledTooltip>
  </Wrapper>
));
