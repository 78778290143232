import { react2angular } from 'react2angular';
import AuditLogsView from '../../../../../../workspaces/apps/audit-logs/src/components/AuditLogsView';

const template = `<section class="bp-master-detail-view">
    <audit-logs-view class="left-panel" store="store" query-client="queryClient"></audit-logs-view>
  </section>`;

const routes = {
  appSettingsAuditLogs: {
    url: '/audit-logs',
    views: {
      'settings-page-view': {
        template,
        controller: ($scope, $ngRedux, UserFeatureTogglesService, QueryClientService) => {
          'ngInject';

          $scope.store = $ngRedux;
          $scope.queryClient = QueryClientService.getQueryClient();
        },
      },
    },
    onEnter: ($transitions, pubSubService) => {
      'ngInject';

      $transitions.onSuccess({ to: 'app.settings.audit_logs' }, () => {
        pubSubService.broadcast('settingsPageChanged', 'auditLogsView');
      });
    },
  },
};

angular
  .module('bigpanda')
  .component('auditLogsView', react2angular(AuditLogsView, ['store', 'queryClient']));

export default routes;
