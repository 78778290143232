import { darkTheme, Form, Select } from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import EnvRulePrioritySelectItem from '../EnvRulePrioritySelectItem';
import SelectWrapper from './index.style';
import { IEnvRulePrioritySelect } from './types';

function envRulePrioritySelect(props: IEnvRulePrioritySelect): JSX.Element {
  const {
    selected,
    items,
    metadata,
    onChange,
  } = props;

  return (
    <ThemeProvider theme={darkTheme}>
      <SelectWrapper>
        <Form>
          <Select
            name={`ruleIndex_${metadata.ruleIndex}_andIndex_${metadata.andIndex}_priority`}
            value={selected}
            options={items}
            updateField={(name, value): void => onChange(value, metadata)}
            placeholder="Select priority..."
            filterPlaceholder="Type to filter.."
            labelKey="name"
            isMulti
            isFilterable
            optionComponent={EnvRulePrioritySelectItem}
          />
        </Form>
      </SelectWrapper>
    </ThemeProvider>
  );
}

export default envRulePrioritySelect;
