import * as actionTypes from './actionTypes';

export const changePassword = (payload, onSuccess) => ({
  type: actionTypes.CHANGE_PASSWORD,
  payload,
  meta: { onSuccess },
});
export const changePasswordSuccess = () => ({ type: actionTypes.CHANGE_PASSWORD_SUCCESS });
export const changePasswordFailure = (error) => ({
  type: actionTypes.CHANGE_PASSWORD_FAILURE,
  error: true,
  payload: error,
});
