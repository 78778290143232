import { getTitle as getIncidentTitle } from '../../../../../workspaces/apps/incident-feed/src/utils/title-builder';

angular.module('bigpanda').service('IncidentTitleService', IncidentTitleService);

function IncidentTitleService(TextWidthService, stateService, UserIntegrationsStore) {
  this.getTitle = getTitle;

  function getTitle(incident, options) {
    const folder = stateService.getSelectedFolderId();
    const integrations = UserIntegrationsStore.getUnsafeMutableIntegrations();
    return getIncidentTitle(integrations, { getWidth }, incident, options, folder);
  }

  function getWidth(font, fullString) {
    return TextWidthService.calcTextWidth(font, fullString);
  }
}
