import { AllSizes, Text, VBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const Container = styled(VBox)`
    align-items: center;
    width: 436px;
    padding: 25px 0 35px 0;
    background-color: ${({ theme }) => theme.bp_gray_01_5};
`;

export const InputContainer = styled(VBox)`
    margin-bottom: 20px;
`;

export const DropdownText = styled(Text).attrs({
  size: AllSizes.XSMALL,
})`
    color: ${({ theme }) => theme.bp_gray_05};
    margin-top: 5px;
`;
