import styled from 'styled-components';
import { bp_gray_08 } from '@bp/pastel/colors';

export const Container = styled.div`
display: flex;
align-items: center;
gap: 10px;
`;

export const PendingMsg = styled.span`
font-size: 14px;
font-weight: bold;
color: ${bp_gray_08};
`;
