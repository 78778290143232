import { SCVBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const PageContainer = styled(SCVBox)`
  width: 100%;
  height: 100%;
  padding: 0px 36px 36px 36px;
  overflow: auto;
  margin-bottom: 25px;
`;
