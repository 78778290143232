import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { Div, Button, AllSizes } from '@bp/kung-fu';
import { BamForm } from '@bp/bam';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import styles from './welcome_form.scss';
import WelcomeFormInput from './WelcomeFormInput';
import { PasswordField } from '../../../../../common/components';
import validate from '../../validations';
import selectors from '../../selectors';

class WelcomeForm extends React.Component {
  handleSubmit = (values) => {
    const validationMessages = validate(values);
    if (validationMessages !== null) {
      return validationMessages;
    }

    this.props.onSubmit(values);
  };
  render() {
    const { userName, isSaving } = this.props;
    const initNameField = { name: userName };
    return (
      <div className={styles.form}>
        <BamForm
          scrollable={false}
          onSubmit={this.handleSubmit}
          id="welcome-form-modal"
          initialValues={initNameField}
        >
          <Field
            name="name"
            title="Full Name"
            subscription={{
              value: true,
              submitError: true,
            }}
            component={WelcomeFormInput}
          />
          <div className={styles.passwordField}>
            <Field
              name="newPassword"
              title="Create your password"
              subscription={{
                value: true,
                submitError: true,
              }}
              component={PasswordField}
              aria-label="new password"
              placeholder="Choose a strong password"
            />
          </div>

          <Div className={styles.formButton}>
            <Button
              type="submit"
              variant="primary"
              expand
              disabled={isSaving}
              size="large"
              fontSize={AllSizes.LARGE}
            >
              Get Started
            </Button>
          </Div>
        </BamForm>
      </div>
    );
  }
}

WelcomeForm.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};
WelcomeForm.defaultProps = {
  userName: undefined,
};

const mapStateToProps = (state) => ({
  isSaving: selectors.isSaving(state),
  userName: selectors.userName(state),
});

export default connect(mapStateToProps)(hot(module)(WelcomeForm));
