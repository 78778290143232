angular.module('bigpanda.backend.services').factory('version', version);

function version(
  $http,
  $log,
  $interval,
  $window,
  $timeout,
  Config,
  htmlVersion,
  cacheBuster,
  VersionStore
) {
  const service = {
    version: null,
  };

  const url = `${Config.baseUrl}/version.json`;
  let errorCount = 0;

  function fetchVersion() {
    try {
      const cacheBustUrl = `${url}?cachebuster=${cacheBuster.generate()}`;
      $http({ method: 'GET', url: cacheBustUrl })
        .then((res) => {
          errorCount = 0;
          const newVersion = res.data.version;
          const forceReload = res.data.force_reload;

          if (!service.version) {
            service.version = newVersion;
          } else if (service.version !== newVersion) {
            $log.debug(
              `local version does not equal to server version. local version: ${service.version}; server version: ${newVersion}`
            );
            reloadVersion(forceReload);
          }
        })
        .catch((data, status) => {
          $log.error(`Cannot get version. HTTP status: ${status}`);
          // Ignoring, probably network issue.
        });
    } catch (e) {
      $log.error(`Cannot get version. Error: ${e}`);
    }
  }

  function reloadVersion(forceReload) {
    if (forceReload) {
      const randomUpToThirtySecs = Math.floor(Math.random() * 30 * 1000);
      $timeout(() => {
        $window.location.reload(true);
      }, randomUpToThirtySecs);
    } else {
      VersionStore.setNeedsUpdate();
    }
  }

  $interval(fetchVersion, 1000 * 60);
  fetchVersion();

  return service;
}
