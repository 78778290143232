import find from 'lodash/find';
angular
  .module('bigpanda.reports')
  .controller(
    'ReportPreviewController',
    function (
      $q,
      $scope,
      $http,
      stateService,
      ReportsConfig,
      $window,
      $stateParams,
      $state,
      $timeout,
      pubSubService
    ) {
      var vm = this;

      vm.reportSnapshot = null;
      vm.reportTimeframe;
      vm.reportEnvironment;

      vm.print = print;
      vm.getReportData = getReportData;

      init();

      if (!$scope.authorisationOptions) {
        onError({ status: 401 });
      }

      function init() {
        if (!$scope.bootstrapPromise) {
          onError({ status: 401 });
          return;
        }

        return $scope.bootstrapPromise.then(retrieveReportAndWidgets, onError);
      }

      function getReportData(widget) {
        return $q.when(find(vm.reportSnapshot.data, { widget_id: widget._id }).data);
      }

      function retrieveReportAndWidgets() {
        var reportSnapshotId = stateService.getSelectedReportSnapshotId();

        pubSubService.broadcast('ReportSnapshotPreview.visit', {
          reportSnapshotId: reportSnapshotId,
        });

        return $http
          .get(`/web/report-snapshots/${reportSnapshotId}`, $scope.authorisationOptions)
          .then(
            (res) => {
              vm.reportSnapshot = res.data.item.snapshot;
              if (
                vm.reportSnapshot.report.widgets &&
                vm.reportSnapshot.report.widgets.length !== 0
              ) {
                vm.reportTimeframe = calculateTimeframe(
                  vm.reportSnapshot,
                  vm.reportSnapshot.report.widgets[0].timeframe
                );
                // replace it with the real environment name
                vm.reportEnvironment = vm.reportSnapshot.environment;
              }

              openPrintWindowIfNeeded();
            },
            (err) => {
              onError(err);
            }
          );
      }

      function onError(err) {
        vm.errorState = true;
        if (err && err.status && err.status == 401) {
          vm.errorMsg = 'The report page is out of date';
          vm.errorSubmsg = 'and does no longer exists';
        } else {
          vm.errorMsg = 'Server Error';
          vm.errorSubmsg = 'Please refresh to try again';
        }
      }

      function openPrintWindowIfNeeded() {
        if ($scope.printMode) {
          vm.forceWidgetLayout = 'desktop';

          $timeout(() => {
            $window.print();
          }, 2000);
        }
      }

      function calculateTimeframe(snapshot, timeframeObject) {
        if (timeframeObject.type === 'relative') {
          var timeframe = find(ReportsConfig.timeframePresets, { timeframe: timeframeObject });
          return {
            period: timeframe.title,
            timeframeAsString: getTimestampAsString(
              timeframe.getStartMoment(snapshot.created_at * 1000),
              moment(snapshot.created_at * 1000)
            ),
          };
        }
        return {
          timeframeAsString: getTimestampAsString(
            moment(timeframeObject.start * 1000),
            moment(timeframeObject.end * 1000)
          ),
        };
      }

      function getTimestampAsString(startMoment, endMoment) {
        var timeframeAsString;
        var end = endMoment.format('MMM DD, YYYY');
        var start = startMoment.format('MMM DD, YYYY');
        if (start !== end) {
          timeframeAsString = `${start} - ${end}`;
        } else {
          timeframeAsString = start;
        }

        return timeframeAsString;
      }

      function print() {
        pubSubService.broadcast('ReportSnapshotPreview.print', {
          reportSnapshotId: stateService.getSelectedReportSnapshotId(),
        });
        var url = $state.href($state.$current.toString(), {
          reportSnapshotId: $stateParams.reportSnapshotId,
          print: true,
          t: $stateParams.t,
        });
        $window.open(url, '_blank');
      }
    }
  );
