// eslint-disable-next-line import/prefer-default-export
export function extractTemplateToTokens(template) {
  return extractTokenArrayFromTemplate(template)
    .filter((token) => token !== undefined)
    .map((token) => {
      if (isTagToken(token)) {
        return token.match(/\$\{exact\([^)]+\)\}/)
          ? {
              type: 'tag',
              prefix: '${exact(',
              postfix: ')}',
              value: token.replace('${exact(', '').replace(')}', ''),
            }
          : {
              type: 'tag',
              prefix: '${',
              postfix: '}',
              value: token.replace('${', '').replace('}', ''),
            };
      }
      return { type: 'text', value: token };
    });
}

function extractTokenArrayFromTemplate(template = '') {
  return template.split(/(\$\{.*?\})/g);
}

function isTagToken(token = '') {
  return token.indexOf('${') === 0;
}
