import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/combineLatest';
import { getUsers } from 'common/endpoints/users';
import actionTypes from './actionTypes';
import * as actions from './actions';

function convertDateToSeconds(isoDate) {
  return new Date(isoDate).getTime() / 1000;
}

const getStateCls = (state) => {
  switch (state) {
    case 'valid':
    case 'welcome':
      return 'active';
    case 'initial':
      return 'pending';
    default:
      return 'off';
  }
};

const getStateDisplayText = (state) => {
  switch (state) {
    case 'valid':
    case 'welcome':
      return 'active';
    case 'initial':
      return 'pending';
    default:
      return 'inactive';
  }
};

export function normalizeUser(user) {
  return {
    ...user,
    id: user._id,
    cls: getStateCls(user.state),
    stateText: getStateDisplayText(user.state),
    active: !!user.state,
    createdTimestamp: convertDateToSeconds(user.createdAt),
    firstLoginAtTimeStamp: convertDateToSeconds(user.firstLoginAt),
    lastLoginAtTimeStamp: convertDateToSeconds(user.lastLoginAt),
  };
}

const loadUsers = (action$) =>
  action$.ofType(actionTypes.LOAD_USERS).mergeMap(async () => {
    const users = await getUsers();
    const normalizedUsers = users.map(normalizeUser);
    return actions.loadUsersSuccess(normalizedUsers);
  });

export default combineEpics(loadUsers);
