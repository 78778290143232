import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';

import SettingsListItemActions from '../../../../../../app/react/layout/settings/components/SettingsListItemActions';
import { getInterpolatedText } from '../../../../alert-enrichment/src/helpers/get-interpolated-text';
import { QueryKeys } from '../../api/constants';
import { deleteAlertFilter } from '../../api/endpoints';
import dictionary from '../../dictionary';
import { AlertFilterModal } from '../../modal/AlertFilterModal';
import selectors from '../../selectors';
import { AlertFilter } from '../../types/AlertFilter';
import { Permissions } from '../../types/Permissions';
import alertFilterUrl from '../../utils/alertFilterUrl';
import { deleteAtIndex } from '../../utils/deleteAtIndex';
import { showFailureMessageForFilters, showInProgressMessageForFilters, showSuccessMessageForFilters } from '../../utils/toasters';

interface IProps {
  filter: AlertFilter;
}

export function AlertFilteringActionButtons({ filter }: IProps): JSX.Element {
  const actionPermissions: Permissions = useSelector(selectors.getActionsPermissions);
  const alertFilterMutation = useMutation(deleteAlertFilter);
  const queryClient = useQueryClient();

  return (
    <SettingsListItemActions
      item={filter}
      modelName={dictionary.alert_filter}
      editProps={{
        disabled: !actionPermissions.hasEditPermission,
        modalProps: {
          filter,
          title: dictionary.edit_alert_filter,
          isNew: false,
        },
        modal: AlertFilterModal,
        ariaLabel: `edit ${filter.name} plan`,
      }}
      duplicateProps={{
        disabled: !actionPermissions.hasDuplicatePermission,
        modalProps: {
          filter: { ...filter, name: getInterpolatedText(dictionary.copy_of_filter, { filterName: filter.name }) },
          title: dictionary.duplicate_alert_filter,
          isDuplicate: true,
        },
        modal: AlertFilterModal,
        ariaLabel: `duplicate ${filter.name} plan`,
      }}
      deleteProps={{
        disabled: !actionPermissions.hasDeletePermission,
        action: (): void => {
          const toasterId = showInProgressMessageForFilters('Deleting');
          alertFilterMutation.mutate(filter.id, {
            onSuccess: (): void => {
              queryClient.setQueryData(
                [QueryKeys.AlertFilter],
                (previous: AlertFilter[]): AlertFilter[] => {
                  const itemIdx = previous.findIndex(({ id }) => id === filter.id);
                  if (itemIdx === -1) return previous;
                  return deleteAtIndex(previous, itemIdx);
                },
              );
              window.location.href = alertFilterUrl();
              showSuccessMessageForFilters('deleted', toasterId);
            },
            onError: (): void => {
              showFailureMessageForFilters('delete', toasterId);
            },
          });
        },
        modalProps: {
          contentTitleType: 'string',
          contentTitle: filter.name,
          content: dictionary.delete_filter_confirmation,
        },
        ariaLabel: `delete ${filter.name} plan`,
      }}
    />
  );
}
