import keys from 'lodash/keys';
import sampleSize from 'lodash/sampleSize';
import filter from 'lodash/filter';
import map from 'lodash/map';

angular.module('bigpanda').controller('CustomTagPopupCtrl', CustomTagPopupCtrl);

function CustomTagPopupCtrl(
  $scope,
  $state,
  $timeout,
  TagsService,
  CustomTagsService,
  resolvedTag,
  resolvedIntegrations,
  resolvedSourceTags,
  resolvedConfigTags,
  CustomTagPopup,
  CustomTagUtils,
  CorrelationActions,
  CorrelationCompletionLogic,
  BpqlUtils,
  CorrelationConfigService
) {
  if (!resolvedIntegrations) {
    return CorrelationActions.escapeRoute('Could not find Custom Tag info', 'app.settings.tags');
  }

  const vm = this;
  const showBPQLError = CorrelationActions.setErrorDebounce(setDebouncedBPQLError);

  let configTags = resolvedConfigTags;

  vm.stateData = CorrelationActions.getStateData('tag', $state.params);
  vm.editMode = !!($state.params.edit || $state.params.duplicate);
  vm.integrations = resolvedIntegrations;
  vm.tabs = [
    {
      type: 'extraction',
      displayValue: 'Extraction tag',
    },
    {
      type: 'composition',
      displayValue: 'Composition Tag',
    },
  ];
  vm.previewResults = [];
  vm.tagsList = [];
  vm.duplicateError = false;
  vm.previewInitialized = false;
  vm.previewDefinitionsChanged = false;
  vm.loadingPreview = false;
  vm.inactive = false;

  vm.dismissGlobalError = dismissGlobalError;
  vm.closePopup = closePopup;
  vm.setTagType = setTagType;
  vm.refreshPreview = refreshPreview;
  vm.submit = submit;
  vm.onQueryChange = onQueryChange;
  vm.onFormChanged = onFormChanged;
  vm.checkPreviewRefreshValidity = checkPreviewRefreshValidity;
  vm.autocompleteLogic = CorrelationCompletionLogic.getLogic(onQueryChange);
  vm.refresh = refresh;

  init();

  function init() {
    resolvedIntegrations.unshift({ display: 'All Systems', value: '*' });
    vm.customTagVm = CustomTagPopup.convertToCustomTagVm(resolvedTag);
    vm.inactive = !vm.customTagVm.active;

    $scope.$watch(() => vm.customTagVm.integration, onIntegrationChange);
    $scope.$watch(
      () => vm.inactive,
      () => {
        vm.customTagVm.active = !vm.inactive;
      }
    );

    $timeout(refreshPreview, 0);
  }

  function updateTags() {
    return CorrelationConfigService.getConfig().then((config) => {
      configTags = config.transformations;
    });
  }

  function setDebouncedBPQLError(error) {
    if (vm.BPQLInvalid) {
      vm.BPQLError = error;
      $scope.$digest();
    }
  }

  function onIntegrationChange(newVal, oldVal) {
    let tags = [];
    if (newVal === '*') {
      tags = resolvedSourceTags;
    } else if (newVal) {
      tags = TagsService.getTagsOfSourceSystem(resolvedSourceTags, newVal);
    }
    vm.sourceTags = map(tags, (tag) => ({ name: tag.name }));
    if (newVal !== oldVal) {
      vm.customTagVm.source = null;
    }
  }

  function onQueryChange() {
    $timeout(() => {
      const query = BpqlUtils.getBpqlQuery(vm.customTagVm.query, true);
      if (query.invalid && query.error) {
        vm.BPQLInvalid = true;
        showBPQLError(query.error.message);
      } else {
        vm.BPQLInvalid = false;
        vm.BPQLError = null;
        onFormChanged(true);
      }
    }, 0);
  }

  function onFormChanged(isPreviewRelevant) {
    if (isPreviewRelevant) {
      vm.previewDefinitionsChanged = true;
    }
    if (!vm.previewInitialized) {
      $timeout(refreshPreview, 0);
    }
  }

  function setTagType(tab) {
    vm.customTagVm.type = tab.type;
    vm.previewResults = [];
    $timeout(refreshPreview, 0);
  }

  function refreshPreview() {
    if (!checkPreviewRefreshValidity(vm.form.$error)) {
      return;
    }

    vm.previewInitialized = true;
    vm.previewDefinitionsChanged = false;
    vm.loadingPreview = true;
    vm.previewResults = [];

    const bpqlQuery = buildNewCustomTag({
      forceActive: true,
      isPreview: true,
    }).query;
    const tags = getTagsBeingUsed();
    TagsService.getUniqueTagsSamples({
      tags,
      bpql: {
        parsed_object: bpqlQuery,
      },
      source_system: vm.customTagVm.integration,
      resultsAmount: 100,
    }).then((res) => {
      // In composition mode you dont have to request tags from the backend, Which means res.tags will always be an empty array.
      // When res.tags is empty we only want to show a result in case our filter matches at least one result,
      // for that we check res.total.
      vm.previewResults = !tags.length && res.total > 0 ? [{}] : sampleSize(res.tags, 50);
      vm.loadingPreview = false;
    });
  }

  function checkPreviewRefreshValidity(formErrors) {
    const errors = keys(formErrors);
    const onlyRegexError = errors.length === 1 && errors[0] === 'regexValidator';
    return errors.length === 0 || onlyRegexError;
  }

  function getTagsBeingUsed() {
    if (vm.customTagVm.type === 'extraction') {
      return [vm.customTagVm.source.toLowerCase()];
    }
    let getTagsBeingUsed = CustomTagUtils.extractTemplateToTokens(vm.customTagVm.template);
    getTagsBeingUsed = filter(getTagsBeingUsed, { type: 'tag' });
    getTagsBeingUsed = map(getTagsBeingUsed, 'value');
    getTagsBeingUsed = map(getTagsBeingUsed, (tag) => tag.toLowerCase());
    return getTagsBeingUsed;
  }

  function submit() {
    return CorrelationConfigService.checkForConfigChanges().then((isChanged) => {
      if (isChanged || vm.showRefresh) {
        vm.showRefresh = true;
        vm.globalError = true;
        vm.globalErrorText = 'Some items were modified by another user. Refresh to updates.';
      } else if (vm.form.$valid && !vm.BPQLInvalid) {
        const customTag = buildNewCustomTag({
          edit: $state.params.edit,
          customTags: configTags,
        });

        if (customTag) {
          // BUG-691: When creating a new tag and requesting it immediately from correlation
          // we sometimes have a race condition where correlation hasn't updated its cache
          // yet. We add this 1 second delay to overcome it. HACK HACK.
          const delayedCreateAction = (newTag) =>
            CustomTagsService.addNewTag(newTag).then((editedTagId) =>
              $timeout(angular.noop, 1000).then(() => editedTagId)
            );
          const action = $state.params.edit ? CustomTagsService.editTag : delayedCreateAction;
          action(customTag).then((editedTagId) => {
            closePopup(editedTagId);
          });
        } else {
          vm.showRefresh = false;
          vm.globalError = true;
          vm.globalErrorText =
            'This tag is identical to an existing one. Change your definitions and try again.';
        }
      }
    });
  }

  function closePopup(id) {
    CorrelationActions.closePopup('app.settings.tags', { tagId: id });
  }

  function buildNewCustomTag(options) {
    return CustomTagPopup.convertFromCustomTagVm(vm.customTagVm, options);
  }

  function dismissGlobalError() {
    vm.globalError = false;
    vm.globalErrorText = '';
  }

  function refresh() {
    if (!resolvedTag) {
      return updateTags().then(() => {
        vm.showRefresh = false;
        dismissGlobalError();
      });
    }

    CustomTagsService.getTagById(resolvedTag.id).then((tag) => {
      if (tag) {
        vm.customTagVm = CustomTagPopup.convertToCustomTagVm(tag);
        vm.inactive = !vm.customTagVm.active;
        vm.showRefresh = false;
        $timeout(refreshPreview, 0);
        dismissGlobalError();
      } else {
        CorrelationActions.itemDeletedNotification(
          'The custom tag has been deleted since you started editing.',
          'tags'
        );
      }
    });
  }
}
