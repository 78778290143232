import { HBox, IOption, Text } from '@bp/kung-fu';
import React from 'react';

function CustomObjectNameOption({ id, text }: IOption): JSX.Element {
  return (
    <HBox gap="5px">
      <Text>{text}</Text>
      <Text color={(p): string => p.theme.bp_gray_06}>{id.length > 0 && `(${id})`}</Text>
    </HBox>
  );
}

export default CustomObjectNameOption;
