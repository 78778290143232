import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import { path as prevPath } from '../constants';

export const name = 'apiKeysManagement';
export const path = `${prevPath}.${name}`;

export const ANY_OPTION_VALUE = '-1';

export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  name: { value: ANY_OPTION_VALUE, text: 'Any Associated User' },
  status: { value: ANY_OPTION_VALUE, text: 'Any Status' },
};

export const API_KEYS_STATUSES = {
  active: 'active',
  inactive: 'inactive',
  error: 'error',
};

export const CREATED_SORT_MODE = {
  text: 'Created',
  field: 'created_at',
  ascending: false,
};
export const LAST_USED_SORT_MODE = {
  text: 'Last Used',
  field: 'last_used',
  ascending: false,
};
export const UPDATED_SORT_MODE = {
  text: 'Updated',
  field: 'updated_at',
  isDefault: true,
  ascending: false,
};
export const SORT_MODES = [UPDATED_SORT_MODE, CREATED_SORT_MODE, LAST_USED_SORT_MODE];

export const DEFAULT_SORT_MODE = UPDATED_SORT_MODE;

export const SortShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool,
});

export const DROPDOWN_FILTER_STATUS_ITEMS = [
  {
    key: API_KEYS_STATUSES.active,
    text: upperFirst(API_KEYS_STATUSES.active),
    value: API_KEYS_STATUSES.active,
  },
  {
    key: API_KEYS_STATUSES.inactive,
    text: upperFirst(API_KEYS_STATUSES.inactive),
    value: API_KEYS_STATUSES.inactive,
  },
  {
    key: API_KEYS_STATUSES.error,
    text: upperFirst(API_KEYS_STATUSES.error),
    value: API_KEYS_STATUSES.error,
  },
];
