import {
  AllSizes,
  Button,
  ButtonVariants,
  darkTheme,
  EditIcon,
  IUseForm,
  Modal,
  ModalModes,
  useForm,
  WarningIcon,
} from '@bp/kung-fu';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import FTSelectors from '../../../../../../../app/react/user/feature_toggles/selectors';
import { AUTO_RESOLVE_TIME_OPTIONS, AutoResolveConfig, DONT_RESOLVE_OPTION } from '../constants';
import dictionary from '../dictionary';
import { EditIntegrationModal } from '../edit-integration-modal';

interface IProps {
  integration: { name: string, type: string };
  autoResolveConfig: {
    resolve_after_inactivity_ms: string,
  };
  saveIntegrationName: (name: string) => Promise<void>;
  saveAutoResolveConfig: (autoResolveTime: AutoResolveConfig['autoResolveTime']) => Promise<void>;
}

export const EditIntegrationButton = ({
  integration,
  autoResolveConfig,
  saveIntegrationName,
  saveAutoResolveConfig,
}: IProps) => {
  const featureToggles = useSelector(FTSelectors.getFeatureToggles)?.featureToggles;
  const enableDontResolve: boolean = featureToggles?.frontier_enable_auto_resolve_dont_resolve || false;
  const integrationConfigName = integration.name;
  const autoResolveTime = useMemo(
    // eslint-disable-next-line max-len
    () => autoResolveConfig && [...AUTO_RESOLVE_TIME_OPTIONS, ...(enableDontResolve ? DONT_RESOLVE_OPTION : [])].find((o) => o.id === String(autoResolveConfig.resolve_after_inactivity_ms)),
    [autoResolveConfig],
  );
  const {
    register,
    validateForm,
    state,
  }: IUseForm<AutoResolveConfig> = useForm({
    integrationConfigName,
    autoResolveTime,
  });

  const onSubmit = async (closeModal: () => void): Promise<void> => {
    await Promise.all([
      saveIntegrationName(state.integrationConfigName),
      saveAutoResolveConfig(state.autoResolveTime),
    ])
      .catch(() => {})
      .finally(closeModal);
  };

  const isAutoResolveTimeNoticeDisplayed = Number(state.autoResolveTime?.id) < Number(autoResolveTime?.id);

  return (
    <ThemeProvider theme={darkTheme}>
      <Modal
        content={(
          <EditIntegrationModal register={register} integrationConfig={integration} />
        )}
        ariaLabelledby={dictionary.edit_integration_label}
        ariaDescribedby={dictionary.edit_integration_label}
        headerProps={{
          title: dictionary.edit_integration_label,
          icon: <EditIcon />,
        }}
        footerProps={{
          actionButtonText: dictionary.edit_integration_modal_update_button,
          cancelButtonText: dictionary.edit_integration_modal_cancel_button,
          isAsync: true,
          onActionClick: (closeModal): boolean => validateForm(() => onSubmit(closeModal)),
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customFooterProps={isAutoResolveTimeNoticeDisplayed && {
          bgColor: (p) => p.theme.bp_notification_yellow,
          text: dictionary.edit_integration_modal_note,
          Icon: WarningIcon,
          iconColor: (p) => p.theme.bp_blue,
        }}
        mode={ModalModes.LIGHT}
      >
        <Button
          variant={ButtonVariants.ACTION_COLOR}
          size={AllSizes.LARGE}
          icon={<EditIcon />}
          fontSize={AllSizes.MEDIUM}
        >
          {dictionary.edit_integration_label}
        </Button>
      </Modal>
    </ThemeProvider>
  );
};
