import { react2angular } from 'react2angular';
import RolesManagement from './components/RolesManagement';
import actions from './actions';

const routes = {};
const template = `<section class="bp-master-detail-view">
    <roles-management class="left-panel" store="store" ></roles-management>
    <div class="spacer"></div>
    <div ui-view="master-detail-info" class="master-detail-info" ></div>
  </section>`;

routes.appSettingsRoles = {
  url: '/roles',
  views: {
    'settings-page-view': {
      template: template,
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
    'master-detail-info@app.settings.roles': {
      template: '<role-info class="react-settings-info" store="store" />',
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
  },
  onEnter: ($transitions, pubSubService) => {
    'ngInject';

    $transitions.onSuccess({ to: 'app.settings.roles' }, () => {
      pubSubService.broadcast('settingsPageChanged', 'roles');
    });
  },
  onExit: ($ngRedux) => {
    'ngInject';

    $ngRedux.dispatch(actions.routeExit());
  },
};

angular.module('bigpanda').component('rolesManagement', react2angular(RolesManagement));

export default routes;
