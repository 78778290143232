import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import { BamModal } from '@bp/bam';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import get from 'lodash/get';
import { v4 } from 'uuid';
import actions from '../actions';
import selectors from '../selectors';
import IncidentLabelForm from './IncidentLabelForm';
import {
  defaultEnrichmentConfig,
  defaultIncidentLabel,
  defaultMultiValueEnrichmentConfig,
  incidentTagModalActionTypes,
  incidentTagTypes,
  reqStatusType,
} from '../constants';

class IncidentLabelModal extends React.Component {
  componentDidUpdate() {
    const { updateRequestStatus } = this.props;
    switch (updateRequestStatus) {
      case reqStatusType.SUCCESS:
      case reqStatusType.CREATE_SUCCESS:
        this.handleClose();
        break;
    }
  }

  getIncident = (incidentLabelId) => {
    const { incidentLabels } = this.props;
    const foundIncidentLabel =
      incidentLabels && incidentLabels.find((item) => item.id === incidentLabelId);
    const editedIncidentLabelData = cloneDeep(foundIncidentLabel) || defaultIncidentLabel();
    if (editedIncidentLabelData.enrichment_config) {
      editedIncidentLabelData.enrichment_config.items.forEach((item) => (item.id = v4()));
    } else {
      const isMultiValue = editedIncidentLabelData.type === incidentTagTypes.MULTI_VALUE;
      if (isMultiValue) {
        editedIncidentLabelData.enrichment_config = defaultMultiValueEnrichmentConfig();
      } else {
        editedIncidentLabelData.enrichment_config = defaultEnrichmentConfig();
      }
    }
    return editedIncidentLabelData;
  };

  getIncidentLabelForForm = (mode, incidentLabelId) => {
    const incidentToProcess = this.getIncident(incidentLabelId);
    if (mode === 'duplicate') {
      const duplicatedTag = omit(incidentToProcess, 'id');
      const duplicatedName = `Copy_of_${incidentToProcess.name}`;
      return { ...duplicatedTag, name: duplicatedName, description: '' };
    }
    return incidentToProcess;
  };

  handleClose = () => {
    this.ref.close();
  };

  render() {
    const {
      titleIcon,
      title,
      triggerProps,
      currentIncidentLabelId,
      mode,
      featureToggles,
    } = this.props;
    const isPreviewFormulaFTOpen = get(featureToggles, 'preview_formula', false);
    const getPositiveButtonText = () => {
      switch (mode) {
        case 'edit':
          return 'Update Tag';
        case 'duplicate':
          return 'Duplicate Tag';
        default:
          return 'Create Tag';
      }
    };
    const getPositiveButtonProductId = () => {
      switch (mode) {
        case 'edit':
          return 'update_incident_enrichment_tag';
        case 'create':
          return 'create_incident_enrichment_tag';
        default:
          return undefined;
      }
    };
    const incidentForForm = this.getIncidentLabelForForm(mode, currentIncidentLabelId);
    const disableQueryHelper = get(featureToggles, 'disable_query_helper', false);

    return (
      <BamModal
        ref={(r) => {
          this.ref = r;
        }}
        trigger={<ModalTriggerButton {...triggerProps} onClick={this.onOpenModal} />}
        titleIcon={titleIcon}
        title={title}
        formMode
        fullscreen={isPreviewFormulaFTOpen}
        style={{ width: 785, overflow: !disableQueryHelper ? 'visible' : 'auto' }}
        docsLink="https://docs.bigpanda.io/docs/incident-tags-beta"
      >
        <IncidentLabelForm
          {...this.props}
          incidentLabel={incidentForForm}
          positiveButtonText={getPositiveButtonText()}
          positiveButtonProductId={getPositiveButtonProductId()}
          close={this.handleClose}
        />
      </BamModal>
    );
  }
}

IncidentLabelModal.propTypes = {
  incidentLabels: PropTypes.arrayOf(PropTypes.object),
  titleIcon: PropTypes.string.isRequired,
  updateIncidentLabel: PropTypes.func.isRequired,
  createIncidentLabel: PropTypes.func.isRequired,
  currentIncidentLabelId: PropTypes.string,
  title: PropTypes.string,
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  mode: PropTypes.oneOf(incidentTagModalActionTypes),
  updateRequestStatus: PropTypes.oneOf(reqStatusType),
  featureToggles: PropTypes.shape({
    disable_query_helper: PropTypes.bool,
  }).isRequired,
};

IncidentLabelModal.defaultProps = {
  currentIncidentLabelId: undefined,
  title: '',
  mode: undefined,
  updateRequestStatus: undefined,
};

function mapStateToProps(state) {
  return {
    incidentLabels: selectors.getIncidentLabelsData(state),
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
    updateRequestStatus: selectors.getUpdateRequestState(state),
  };
}

const mapDispatchToProps = {
  updateIncidentLabel: actions.updateIncidentLabel,
  createIncidentLabel: actions.createIncidentLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(IncidentLabelModal));
