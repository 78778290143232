import styled from 'styled-components';
import {
  bp_blue,
  bp_gray_02,
  bp_gray_04,
  bp_gray_05,
  bp_gray_07,
  bp_gray_09,
} from '@bp/pastel/colors';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;

export const ItemContainer = styled.div`
 padding-left: ${({ isDivider }) => (isDivider ? '14px' : '16px')};
`;

export const IconWrapper = styled.div`
font-size: 16px;
display: flex;
fill: ${({ isSelected }) => (isSelected ? bp_blue : bp_gray_07)};

&:hover{
${({ isSelected }) => !isSelected && `fill: ${bp_blue}`};
}
`;

export const Title = styled.span`
color: ${bp_gray_05};
font-size: 12px;
font-weight: bold;
margin-bottom: 12px;
padding-left:16px;
`;

export const MenuItem = styled.a.attrs((p) => ({ className: p.isSelected && 'isSelected' }))`
display: flex;
padding: 7px;
font-size: 12px;
color: ${bp_gray_07};
border-radius: 6px;
cursor: pointer;
gap: 8px;
font-weight:400;
&:hover:not(.isSelected){
    text-decoration: none;
    background-color: ${bp_gray_02};
    opacity: 60%;
    color: ${bp_gray_09}; 
    i{
        color: ${bp_blue};
    }
    transition-duration: 300ms;
}
&:active{
    background-color: ${bp_gray_04};
}
& i{
    font-size: 16px;
    display: flex;
}
&.isSelected{
    font-weight:600;
    text-decoration: none;
    background-color: ${bp_gray_02};
    color: ${bp_gray_09}; 
    i{
        color: ${bp_blue};
    }
    transition-duration: 300ms;
}
`;
