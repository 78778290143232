angular.module('bigpanda').controller('incidentsSortingTooltipCtrl', incidentsSortingTooltipCtrl);

function incidentsSortingTooltipCtrl(pubSubService, LabelsStore, UserFeatureTogglesService) {
  const vm = this;

  vm.$onInit = onInit;

  function onInit() {
    updatePriorityLabel();
    pubSubService.on('incidentTagsDefinitions.updated', updatePriorityLabel);
  }

  function updatePriorityLabel() {
    const priorityTag = LabelsStore.getPriorityDefinition();
    if (priorityTag.active) {
      vm.priorityTagName = priorityTag.name || 'Priority';
      vm.priorityTagNameLowerCase = vm.priorityTagName.toLowerCase();
      vm.priorityTagSubtitle = `First level of ${vm.priorityTagNameLowerCase} on top.`;
    }
  }
}
