import React from 'react';
import { Field } from 'react-final-form';

import Combobox from '../../../../../../../app/react/common/components/Combobox/Combobox';
import ruleFieldName from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/ruleFieldName';
import { sourceSystemsOptions } from '../../../../../../../app/react/modules/settings/alert_enrichment/utils';
import {
  validateSource,
  validateSourceSystems,
} from '../../../../../../../app/react/modules/settings/alert_enrichment/validation.utils';
import { IntegrationOption, LoadPreview } from '../types';
import { SourceSystemv2, SourceTagv2 } from './Extraction.style';

interface IProps {
  ruleId: string;
  integrationOptions: IntegrationOption[];
  sourceSystemOptions: IntegrationOption[];
  loadPreview: LoadPreview;
  integration: string;
}

export function Filtersv2Fields({
  ruleId,
  integrationOptions,
  sourceSystemOptions,
  loadPreview,
  integration,
}: IProps): JSX.Element {
  return (
    <>
      <SourceSystemv2>
        <Field
          validateOnBlur
          validate={validateSourceSystems}
          name={ruleFieldName(ruleId, 'integration')}
        >
          {({ input: { onChange: fieldOnChange, value }, meta }): JSX.Element => (
            <Combobox
              items={sourceSystemsOptions(integrationOptions)}
              value={value}
              onChange={fieldOnChange}
              error={(meta.touched && !!meta.error) || (meta.submitFailed && !meta.valid)}
              errorMessage={(meta.touched || (meta.submitFailed && !meta.valid)) && meta.error}
              title="Source System"
              placeholder="Select Source..."
              ariaLabel="source system"
              fullWidth
            />
          )}
        </Field>
      </SourceSystemv2>
      <SourceTagv2>
        <Field validateOnBlur validate={validateSource} name={ruleFieldName(ruleId, 'source')}>
          {({ input: { onChange: fieldOnChange, value }, meta }): JSX.Element => (
            <Combobox
              items={sourceSystemOptions}
              value={value}
              onChange={(e): void => {
                fieldOnChange(e);
                loadPreview(e);
              }}
              error={(meta.touched && !!meta.error) || (meta.submitFailed && !meta.valid)}
              errorMessage={
                (meta.touched || (meta.submitFailed && !meta.valid)) && integration && meta.error
              }
              title="Source Tag"
              placeholder="Select Tag..."
              ariaLabel="source tag"
              fullWidth
              disabled={!integration}
            />
          )}
        </Field>
      </SourceTagv2>
    </>
  );
}

export default Filtersv2Fields;
