import { react2angular } from 'react2angular';
import CustomTagInfo from './components/CustomTagInfo';

const routes = {};

routes.appSettingsCustomTagsInfoReact = {
  url: '/:customTagId',
  views: {
    'master-detail-info@app.settings.customtags': {
      template:
        '<custom-tag-info class="react-settings-info" custom-tag-id="stateParams.customTagId" store="store" />',
      controller: ($scope, $ngRedux, $stateParams) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
      },
    },
  },
};

angular
  .module('bigpanda')
  .component('customTagInfo', react2angular(CustomTagInfo, ['customTagId', 'store']));

export default routes;
