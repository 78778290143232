import {
  AllSizes, CodeSizes, HBox, Text,
} from '@bp/kung-fu';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import pluralize from 'pluralize';
import React from 'react';

import { TextSeparator, TextWithTooltip } from '../../../../../../app/react/common/components';
import getRuleIntegrationDisplay from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/getRuleIntegrationDisplay';
import { RuleType } from '../../../types';
import { Alert, PreviewRule, TagValue } from '../PreviewTags/types';
import {
  CodeText,
  EllipsisText, EllipsisTextWrapper, PreviewSubheader,
} from './AlertTagPreview.style';

interface IProps {
  previewLoading: boolean;
  previewAlerts: Alert[];
  tagsView: boolean;
  uniquePreviewTags: TagValue[];
  previewRule: PreviewRule;
  integrations: object[];
}

export function AlertTagPreviewSubheader({
  previewLoading,
  previewAlerts,
  tagsView,
  uniquePreviewTags,
  previewRule,
  integrations,
}: IProps): JSX.Element {
  if (previewLoading || !previewAlerts || (tagsView && !uniquePreviewTags)) return null;

  const integration = getRuleIntegrationDisplay(get(previewRule, 'integration'), integrations);

  const amount = tagsView ? uniquePreviewTags.length : previewAlerts.length;
  const sampleOrFunction = tagsView ? capitalize(previewRule.type) : 'Sample';
  const alertOrValue = tagsView ? 'value' : 'Alert';
  const sampleAlertsString = `${amount} ${sampleOrFunction} ${pluralize(alertOrValue, amount)}`;

  return (
    <PreviewSubheader>
      <Text weight="bold">{sampleAlertsString}</Text>
      {get(integration, 'display') && (
        <>
          <TextSeparator />
          <Text size={AllSizes.MEDIUM}>{get(integration, 'display')}</Text>
          {previewRule.source && (
            <Text size={AllSizes.MEDIUM} weight="bold">
              /
              {previewRule.source}
            </Text>
          )}
        </>
      )}
      {get(previewRule, 'type') === RuleType.Extraction && (
        <HBox>
          <TextSeparator />
          <Text
            size={CodeSizes.MEDIUM_CODE}
            color={(p): string => (previewRule.regex ? p.theme.bp_gray_07 : p.theme.bp_gray_05)}
          >
            <TextWithTooltip
              text={previewRule.regex || 'No Regex'}
              triggerText={previewRule.regex || 'No Regex'}
              length={(previewRule.regex || 'No Regex').length}
            />
          </Text>
        </HBox>
      )}
      {get(previewRule, 'condition') && (
        <EllipsisTextWrapper>
          <TextSeparator />
          <EllipsisText size={CodeSizes.SMALL_CODE} ellipsis>
            <TextWithTooltip
              text={previewRule.condition}
              triggerText={previewRule.condition}
              length={previewRule.condition.length}
              TextWrapper={CodeText}
            />
          </EllipsisText>
        </EllipsisTextWrapper>
      )}
    </PreviewSubheader>
  );
}

export default AlertTagPreviewSubheader;
