import {
  darkTheme, EditableField, EditIcon, Modal, WarningIcon,
} from '@bp/kung-fu';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import toString from 'lodash/toString';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, withTheme } from 'styled-components';

import { bpUserImg } from '../../../../../app/common/constants';
import { incidentTagClosedListTypes, incidentTagTypes } from '../../../../../app/react/modules/settings/incident_labels/constants';
import actions from '../../../../../app/react/modules/tags/actions';
import { selectors as getUserSelectors } from '../../../../../app/react/user/details';
import { selectors as featureTogglesSelectors } from '../../../../../app/react/user/feature_toggles';
import { checkGranularPermission, selectors as permissionsSelectors } from '../../../../../app/react/user/permissions';
import { CellContentWrapper, CellWrapper } from './IncidentTagsGridCellV2.style';
import IncidentTagsGridCellV2ModalContent from './IncidentTagsGridCellV2ModalContent';

const customFooterProps = {
  text: 'Changing tag data will stop automated enrichment for this tag',
  helpTooltipProps: {
    text: 'By default, incident tags are automatically updated as new and updated alerts enter BigPanda. Manually changing tag data stops these automatic updates.',
  },
  Icon: WarningIcon,
  bgColor: (p) => p.theme.bp_gray_01,
  iconColor: (p) => p.theme.bp_gray_05,
};

const hrefRegex = /<a href="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?)"[a-zA-Z0-9\s"=_]*?>([-a-zA-Z0-9@:%_+.~#?&//= ]*)<\/a>/g;

function replacer(match, link, p2, linkLabel) {
  return `[${linkLabel}|${link}]`;
}

export function refactorLinks(tags) {
  return tags.map((tag) => toString(tag).replace(hrefRegex, replacer));
}

function getTagValue(tag, cellType) {
  switch (cellType) {
    case incidentTagTypes.TEXT:
      return toString(tag).replace(hrefRegex, replacer);
    case incidentTagTypes.MULTI_VALUE:
    case incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE:
    case incidentTagClosedListTypes.CLOSED_LIST_VALUE_MULTI:
      return refactorLinks(tag);
    default:
      return '';
  }
}

function IncidentTagsGridCallV2(props) {
  const {
    name,
    tagData,
    disabled,
    cellType,
    environmentId,
    incidentId,
    tagDefinitionId,
    isVisible,
    borderTagClass,
    tagWidth,
    isEllipsis,
    handleIsValueEllipsis,
    closedListValues,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTagValue, setCurrentTagValue] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const userPermissions = useSelector(permissionsSelectors.getPermissionsList);
  const granularPermissions = useSelector(permissionsSelectors.getGranularPermissions);
  const featureToggles = useSelector(featureTogglesSelectors.getFeatureToggles);
  const currentUser = useSelector(getUserSelectors.getUser);
  const hasEditPermission = checkGranularPermission(
    userPermissions,
    featureToggles,
    granularPermissions,
    'action@update',
    'environments',
    environmentId,
  );

  function setTagValueForIncident(envId, _incidentId, tagId, tagValue) {
    dispatch(actions.setTagValueForIncident(envId, _incidentId, tagId, tagValue));
  }
  function removeTagValueFromIncident(envId, _incidentId, tagId) {
    dispatch(actions.removeTagValueFromIncident(envId, _incidentId, tagId));
  }

  const headerProps = {
    title: 'Edit Incident Tag',
    icon: <EditIcon />,
  };

  const updateFromChild = (newValue, errorFromChild) => {
    setCurrentTagValue(newValue);
    setError(errorFromChild);
  };

  const handleModalClick = () => {
    if (!error && hasEditPermission) {
      if (!isEqual(currentTagValue, get(tagData, 'tag_value'))) {
        if (currentTagValue && currentTagValue.length) {
          setTagValueForIncident({
            environmentId,
            incidentId,
            tagId: tagDefinitionId,
            tagValue: currentTagValue,
            name: currentUser.name,
            email: currentUser.email,
            timestamp: Date.now(),
          });
        } else {
          removeTagValueFromIncident({
            environmentId,
            incidentId,
            tagId: tagDefinitionId,
            name: currentUser.name,
            email: currentUser.email,
            timestamp: Date.now(),
          });
        }
      }
      setIsModalOpen(false);
    }
  };

  const footerProps = {
    actionButtonText: 'Update',
    cancelButtonText: 'Cancel',
    buttonWidth: '75px',
    onActionClick: handleModalClick,
  };

  const fieldData = useMemo(
    () => (tagData
      ? {
        fieldName: name,
        name,
        user: {
          date: new Date(tagData.timestamp || Date.now()).toISOString(),
          email: tagData.email,
          isManual: tagData.name,
          name: tagData.name || 'BigPanda',
          url: tagData.email ? null : bpUserImg,
        },
        disabled,
        onClick: () => setIsModalOpen(true),
        value: getTagValue(tagData.tag_value, cellType),
      }
      : {
        fieldName: name,
        name,
        disabled,
        onClick: () => setIsModalOpen(true),
        value: '',
      }),
    [tagData, name, disabled],
  );

  const handleEllipsisChange = (fieldName, isEllipsisState) => {
    handleIsValueEllipsis({ [fieldName]: isEllipsisState });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      {isVisible && (
        <CellWrapper className={borderTagClass} tagWidth={tagWidth} aria-label="Grid cell main">
          <EditableField
            {...fieldData}
            isOpen={!isEllipsis}
            handleEllipsisChange={isEllipsis && handleEllipsisChange}
          />
          <Modal
            controlledState={isModalOpen}
            setControlledState={setIsModalOpen}
            content={(
              <CellContentWrapper>
                <IncidentTagsGridCellV2ModalContent
                  cellType={cellType}
                  tagData={tagData}
                  name={name}
                  updateFromChild={updateFromChild}
                  closedListValues={closedListValues}
                />
              </CellContentWrapper>
                          )}
            footerProps={footerProps}
            headerProps={headerProps}
            customFooterProps={customFooterProps}
          />
        </CellWrapper>
      )}
    </ThemeProvider>
  );
}

IncidentTagsGridCallV2.propTypes = {
  environmentId: PropTypes.string.isRequired,
  incidentId: PropTypes.string.isRequired,
  tagDefinitionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cellType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  tagData: PropTypes.shape({
    tag_value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])),
    ]),
    name: PropTypes.string,
    email: PropTypes.string,
    timestamp: PropTypes.number,
    tag_id: PropTypes.string.isRequired,
  }),
  isVisible: PropTypes.bool.isRequired,
  borderTagClass: PropTypes.string.isRequired,
  tagWidth: PropTypes.string.isRequired,
  isEllipsis: PropTypes.bool.isRequired,
  handleIsValueEllipsis: PropTypes.func.isRequired,
  closedListValues: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
};

IncidentTagsGridCallV2.defaultProps = {
  tagData: null,
};

export default hot(module)(withTheme(IncidentTagsGridCallV2));
