import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { BamTile } from '@bp/bam';
import {
  CREATED_SORT_MODE,
  SortShape,
} from 'react/modules/settings/custom_tags/custom_tags_list/constants';
import BpTimeSince from 'common/utils/BpTimeSince';
import CustomTagsActions from 'react/modules/settings/custom_tags/actions/CustomTagsActions';

const generateFirstRow = (customTag, sortMode) => {
  const data = {
    title: customTag.destination,
    textParts: customTag.sourceSystem ? [{ value: customTag.sourceSystem.display }] : null,
    time:
      sortMode === CREATED_SORT_MODE
        ? { title: 'Created', timestamp: BpTimeSince(customTag.metadata.created_time) }
        : { title: 'Updated', timestamp: BpTimeSince(customTag.metadata.updated_time) },
  };

  if (customTag.type === 'extraction' && customTag.source) {
    if (data.textParts) {
      data.textParts.push({ label: 'Tag', value: customTag.source });
    }
  }

  return data;
};

const generateSecondRow = (customTag) => {
  const textParts = [];
  if (customTag.type === 'extraction') {
    if (customTag.pattern) {
      textParts.push({ label: 'Extraction', value: customTag.pattern, fixedFont: true });
    }
  } else if (customTag.type === 'composition') {
    textParts.push({ label: 'Composition', value: customTag.template });
  }

  if (customTag.displayQuery) {
    textParts.push({ label: 'Query', value: customTag.displayQuery, fixedFont: true });
  }

  return { textParts: textParts, actionsBundle: <CustomTagsActions customTag={customTag} /> };
};

const ListItem = ({ customTag, sortMode, ...props }) => {
  const handleClick = () => {
    window.location.href = `/#/app/settings/custom-tags/${customTag.id}`;
  };

  return (
    <BamTile
      className="custom-tag-tile"
      bamStatus={customTag.active ? 'ok' : 'off'}
      firstRow={generateFirstRow(customTag, sortMode)}
      secondRow={generateSecondRow(customTag)}
      onClick={handleClick}
      inactive={!customTag.active}
      {...props}
    />
  );
};

ListItem.propTypes = {
  customTag: PropTypes.shape({ id: PropTypes.string, active: PropTypes.bool }).isRequired,
  sortMode: SortShape.isRequired,
};

export default hot(module)(ListItem);
