import { react2angular } from 'react2angular';
import RoleInfo from './components/RoleInfo';

const routes = {};

routes.appSettingsRolesInfo = {
  url: '/:roleId',
  views: {
    'master-detail-info@app.settings.roles': {
      template:
        '<role-info class="react-settings-info" role-id="stateParams.roleId" store="store" />',
      controller: ($scope, $ngRedux, $stateParams) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
      },
    },
  },
};

angular.module('bigpanda').component('roleInfo', react2angular(RoleInfo, ['roleId', 'store']));

export default routes;
