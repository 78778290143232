angular.module('bigpanda.utils').service('KeyboardService', KeyboardService);

function KeyboardService($document) {
  const SHIFT_KEY = 16;

  let shiftDown = false;

  $document.bind('keydown', (e) => {
    if (e.keyCode === SHIFT_KEY) {
      shiftDown = true;
    }
  });

  $document.bind('keyup', (e) => {
    if (e.keyCode === SHIFT_KEY) {
      shiftDown = false;
    }
  });

  this.isShiftDown = () => shiftDown;
}
