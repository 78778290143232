import orderBy from 'lodash/orderBy';

import boolToLodashOrder from '../../../../../../app/common/utils/boolToLodashOrder';
import { AlertFilter } from '../../types/AlertFilter';
import { SortMode } from '../../types/SortMode';
import { DEFAULT_SORT_MODE } from '../../types/SortModes';

export const sortFilters = ({ field, ascending }: SortMode) => (alertFilters: AlertFilter[]): AlertFilter[] => {
  if (!alertFilters) return undefined;
  const { field: defaultField, ascending: defaultOrder } = DEFAULT_SORT_MODE;
  return orderBy(
    alertFilters,
    [field, defaultField],
    [ascending, defaultOrder].map(boolToLodashOrder),
  ) as AlertFilter[];
};
