import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import { bp_gray_02, bp_gray_07 } from '@bp/pastel/colors';
import StyledIconButton from './shared_components/StyledIconButton';

const OuterHeader = styled.div`
  width: 100%;
  height: 56px;
`;

const OuterHeaderHeadline = styled.div`
  width: 96%;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 15px 0 0 10px;
  margin-left: 11px;
  border-bottom: solid 1px ${bp_gray_02};
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: ${bp_gray_07};
`;

const PreviewHeadline = styled.span`
  margin-right:16px;
`;

const MainScreen = styled.div`
  width: 96%;
  margin-left: 11px;
`;

const MainScreenHeadline = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 10px;
`;

const MainScreenHeadlineResults = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 10px;
`;

const MainScreenHeadlineConditions = styled.div``;

function IncidentLabelPreview(props) {
  const incidents = [];
  return (
    <React.Fragment>
      <OuterHeader>
        <OuterHeaderHeadline>
          <PreviewHeadline>Preview</PreviewHeadline>
          <StyledIconButton icon="bp-icon-eye-1" isActive>
            Preview All
          </StyledIconButton>
        </OuterHeaderHeadline>
      </OuterHeader>
      <MainScreen>
        <MainScreenHeadline>
          <MainScreenHeadlineResults>{incidents.length} Incidents</MainScreenHeadlineResults>
          <span />
          <MainScreenHeadlineConditions />
        </MainScreenHeadline>
      </MainScreen>
    </React.Fragment>
  );
}

export default hot(module)(IncidentLabelPreview);
