import PropTypes from 'prop-types';

const enrichmentTypePropType = PropTypes.oneOf(['mapping', 'composition', 'extraction']);

const enrichmentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  config: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  type: enrichmentTypePropType.isRequired,
});

const tagPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  created_by: PropTypes.string.isRequired,
  created_at: PropTypes.number.isRequired,
  updated_by: PropTypes.string,
  updated_at: PropTypes.number,
  order: PropTypes.number,
  type: PropTypes.string.isRequired,
  enrichments: PropTypes.arrayOf(enrichmentPropType),
});

export { tagPropType, enrichmentPropType };
