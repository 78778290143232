import React from 'react';
import PropTypes from 'prop-types';
import { webApiBaseUrl } from 'common/config';
import LogoutButton from './LogoutButton';
import { getRandomId } from './helpers';
import {
  HorizontalLayout,
  Content,
  Title,
  Description,
  Steps,
  Step,
  Note,
  CodeSpan,
  Badge,
  SSOForm,
} from '../../components';

const formId = getRandomId();

const PingOneInstructions = ({
  isConfigurationSent,
  configureProvider,
  organization,
  updateAndLogout,
}) => (
  <div>
    <HorizontalLayout>
      <Badge>2</Badge>
      <Content>
        <Title>Add the BigPanda App to PingOne</Title>
        <Steps>
          <Step>Log in to your PingOne Admin account.</Step>
          <Step>
            In PingOne, go to <strong>Applications &gt; My Applications</strong>.
          </Step>
          <Step>
            Click <strong>Add Applications</strong>, and select{' '}
            <strong>New SAML Application</strong> from the drop-down list.
          </Step>
          <Step>
            Enter the application details and continue to the next Step.{' '}
            <strong>Application Name, Application Description</strong>, and Category are required
            fields. For logos and icons, PNG is the only supported graphics format.
          </Step>
          <Step>
            Ensure that the <strong>I have the SAML configuration</strong> selection is enabled and
            click <strong>Download</strong> to preview the SAML Metadata file, which will be used in
            Step 3 below.
          </Step>
          <Step>
            <div>Enter the SAML configuration details for BigPanda.</div>
            <Steps>
              <Step>
                <strong>Protocol Version:</strong> select <strong>SAML v2.0</strong>
              </Step>
              <Step>
                <strong>Assertion Consumer Service (ACS):</strong> enter{' '}
                <CodeSpan canBeCopied>
                  {`${webApiBaseUrl}/login/${organization}/pingone/callback`}
                </CodeSpan>
              </Step>
              <Step>
                <strong>Entity ID:</strong> enter{' '}
                <CodeSpan canBeCopied>https://bigpanda.io/SAML2</CodeSpan>
              </Step>
              <Step>
                <strong>Signing Algorithm:</strong> select <strong>RSA_SHA256</strong>
              </Step>
            </Steps>
          </Step>
          <Step>Click Continue to Next Step to review the setup.</Step>
          <Step>
            Click <strong>Finish</strong> to complete the integration. You can click the application
            name in the <strong>My Applications</strong> tab to view the configuration.
          </Step>
        </Steps>
      </Content>
    </HorizontalLayout>
    <HorizontalLayout>
      <Badge>3</Badge>
      <Content>
        <Title>Configure PingOne Information in BigPanda</Title>
        <Steps>
          <Step>
            Enter <CodeSpan canBeCopied>https://bigpanda.io/SAML2</CodeSpan> into the{' '}
            <strong>Issuer ID</strong> field.
          </Step>
          <Step>
            <div>From the SAML Metadata file in Step 2:</div>
            <Steps>
              <Step>
                Copy the value of the <CodeSpan>SingleSignOnService Location</CodeSpan> attribute
                and paste it into <strong>SAML 2.0 Endpoint (HTTP).</strong>
              </Step>
              <Step>
                Copy the value of the <CodeSpan>X509Certificate</CodeSpan> attribute into your
                preferred editor. Remove whitespaces, and paste it into{' '}
                <strong>X.509 Certificate</strong>.
              </Step>
            </Steps>
          </Step>
          <Step>
            Click <strong>Configure PingOne</strong>.
          </Step>
        </Steps>
        <SSOForm
          providerName="PingOne"
          onSubmit={configureProvider}
          id={formId}
          disabled={!isConfigurationSent}
        />
      </Content>
    </HorizontalLayout>
    <HorizontalLayout>
      <Badge>4</Badge>
      <Content>
        <Title>Validate and Enable the Integration</Title>
        <Description>
          Before enabling the PingOne integration for your entire organization, test it with your
          own account.
        </Description>
        <Steps>
          <Step>
            Click <strong>Logout and Validate.</strong>
          </Step>
          <Step>
            Log in to BigPanda with PingOne SSO. Be sure to leave the BigPanda password field blank.
          </Step>
          <Step>
            If your login was successful, return to this page and click{' '}
            <strong>Activate SSO</strong> to enable SSO for everybody in your organization.
          </Step>
        </Steps>
        <Note>
          Note: Until you enable the integration for the entire organization, users (including you)
          can log in to BigPanda with their original BigPanda passwords. Once enabled, they will be
          required to use PingOne&apos;s authentication.
        </Note>

        <LogoutButton updateAndLogout={updateAndLogout} disabled={false} />
      </Content>
    </HorizontalLayout>
  </div>
);

PingOneInstructions.propTypes = {
  updateAndLogout: PropTypes.func.isRequired,
  configureProvider: PropTypes.func.isRequired,
  isConfigurationSent: PropTypes.bool.isRequired,
  organization: PropTypes.string.isRequired,
};

export default PingOneInstructions;
