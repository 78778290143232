export default (strategyCreator, validators) => (values, context) => {
  const strategy = strategyCreator({ validators, values });
  for (const validator of validators) {
    const errorMessage = validator.validate(values, context);

    if (errorMessage) {
      strategy.addError({ name: validator.name, message: errorMessage });
      if (strategy.first) {
        return strategy.getErrors();
      }
    }
  }

  return strategy.first ? null : strategy.getErrors();
};
