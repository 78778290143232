import React from 'react';

import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';
import selectors from '../../../../../../app/react/modules/settings/maintenance_plans/maintenance_plan_info/selectors';
import { MaintenancePlan } from '../../../types/MaintenancePlan';
import { User } from '../../../types/User';

interface IProps {
  user?: User;
  updatedBy?: User;
  plan: MaintenancePlan
}

export function Activity({ plan, user, updatedBy }: IProps): JSX.Element {
  return (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.ActivityRow
        title="Created by:"
        name={user ? user.name || user.username : 'N/A'}
        value={selectors.formatDate(plan.created_at)}
      />
      {plan.updated_at && (
      <SettingsInfoScreen.ActivityRow
        title="Last changed by:"
        name={updatedBy ? updatedBy.name || updatedBy.username : 'N/A'}
        value={selectors.formatDate(plan.updated_at)}
      />
      )}
    </SettingsInfoScreen.Section>
  );
}
