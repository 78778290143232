import isUndefined from 'lodash/isUndefined';
angular.module('bigpanda').directive('incidentTitle', incidentTitle);

function incidentTitle(IncidentTitleService, $timeout, pubSubService) {
  return {
    restrict: 'E',
    scope: {
      incident: '<',
      sameLine: '@?',
    },
    templateUrl: 'overview/incidents/title/incident_title',
    bindToController: true,
    controllerAs: 'titleCtrl',
    controller: controller,
  };

  function controller($scope, $element) {
    const vm = this;

    let fontData = '';
    let maxWidth = 0;

    init();

    function init() {
      $scope.$watch(getTitle, setScopeTitleData, true);

      pubSubService.on(
        'tabs.resizing',
        () => {
          getTitleDataFromDOM();
          setScopeTitleData();
        },
        $scope
      );

      $timeout(getTitleDataFromDOM, 0, true);
    }

    function getTitleDataFromDOM() {
      fontData = $element.find('.title').css('font');
      maxWidth = $element.parent().width();

      // When we're using title and subtitle in the same line, we just say that each one can take max width of half
      // the size of the parent
      if (!isUndefined(vm.sameLine)) {
        maxWidth /= 2;
      }
    }

    function getTitle() {
      return IncidentTitleService.getTitle(vm.incident, {
        fontData: fontData,
        widthLimit: {
          max: maxWidth,
          dynamicMin: 50,
        },
      });
    }

    function setScopeTitleData() {
      const data = getTitle();

      vm.data = data;
      vm.over = ' over ';

      vm.underMaintenance = vm.incident.entities.filter(
        (entity) => entity.maintenance_plan_ids && entity.maintenance_plan_ids.length
      ).length;

      if (vm.underMaintenance) {
        vm.underMaintenanceToolTip = `${vm.underMaintenance} Alert${
          vm.underMaintenance > 1 ? 's' : ''
        } in maintenance`;
      }

      const multipleSubtitleItems = vm.data.subtitle.items;
      const singleSubtitleItem = vm.data.subtitle.key;

      if (multipleSubtitleItems) {
        vm.subtitleItemsToShow = multipleSubtitleItems.join(', ');
      } else {
        vm.subtitleItem = {
          key: singleSubtitleItem ? `${singleSubtitleItem}: ` : '',
          value: vm.data.subtitle.value,
        };
      }

      if (vm.data.type === 'correlation') {
        setCorrelationTooltips(vm.data);
      }

      vm.subtitleTooltip = 'overview/incidents/title/incident_subtitle_tooltip';
      vm.titleTooltip = 'overview/incidents/title/incident_title_tooltip';
    }

    function setCorrelationTooltips(data) {
      vm.subtitleItemsLength = data.subtitle.count;
      vm.subtitleItems = data.subtitle.items;

      vm.titleLineSize = data.title.length + 3;
      vm.subtitleLineSize = vm.subtitleItems.length + 3;

      if (data.subtitle.count > data.subtitle.items.length) {
        vm.leftover = data.subtitle.count - data.subtitle.items.length;
        vm.subtitleLineSize += 1;
      }
    }
  }
}
