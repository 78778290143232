import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BamConfirmModal, BamEmptyMessage, BamTile, BamToggle, TimeFormats } from '@bp/bam';
import { Badge, darkTheme, EditIcon } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import provideStore from 'react/utils/provideStore';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import capitalize from 'lodash/capitalize';
import './custom_tag_info.scss';
import CustomTagsActions from '../../actions/CustomTagsActions';
import selectors from '../../selectors';
import CustomTagModal from '../../modals/CustomTagModal';
import actions from '../../actions';
import { TemplateToTokens } from '../../../../../common/components';

const { momentOrEpochRelativeTimeFormat } = TimeFormats;

class CustomTagInfo extends React.PureComponent {
  toggleTagClicked = () => {
    if (!this.props.customTag.active) {
      this.toggleCustomTag();
    }
  };

  toggleCustomTag = () => {
    const { customTag, updateCustomTag } = this.props;
    updateCustomTag({ id: customTag.id, active: !customTag.active });
  };

  render() {
    const { customTag, actionPermissions } = this.props;

    if (!customTag) {
      return (
        <BamEmptyMessage header="NO CUSTOM TAG SELECTED">
          Select a custom tag to see details
        </BamEmptyMessage>
      );
    }

    const content = (
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.SectionRow title="Status:">
          <Badge text={customTag.statusText} status={customTag.status} isCapitalized size="large" />
        </SettingsInfoScreen.SectionRow>
        {customTag.type && (
          <SettingsInfoScreen.SectionRow title="Type:">
            {capitalize(customTag.type)}
          </SettingsInfoScreen.SectionRow>
        )}
        {customTag.sourceSystems.length > 0 && (
          <SettingsInfoScreen.SectionRow title="Source:">
            {customTag.sourceSystems[0].display}
          </SettingsInfoScreen.SectionRow>
        )}
        {customTag.source && (
          <SettingsInfoScreen.SectionRow title="Tag:">
            {customTag.source}
          </SettingsInfoScreen.SectionRow>
        )}
        {customTag.pattern && (
          <SettingsInfoScreen.SectionRow fixed title="Extraction:">
            {customTag.pattern}
          </SettingsInfoScreen.SectionRow>
        )}
        {customTag.type === 'composition' && (
          <SettingsInfoScreen.SectionRow title="Composition:">
            <div>
              <TemplateToTokens template={customTag.template} />
            </div>
          </SettingsInfoScreen.SectionRow>
        )}
        {customTag.displayQuery && (
          <SettingsInfoScreen.SectionRow fixed title="Query:">
            {customTag.displayQuery}
          </SettingsInfoScreen.SectionRow>
        )}
        {customTag.metadata.description && (
          <SettingsInfoScreen.SectionRow title="Note:">
            <pre>{customTag.metadata.description}</pre>
          </SettingsInfoScreen.SectionRow>
        )}
      </SettingsInfoScreen.Section>
    );

    const settingsActions = (
      <SettingsInfoScreen.Section>
        {actionPermissions.hasEditPermission && (
          <CustomTagModal
            triggerProps={{
              variant: 'action-color',
              size: 'large',
              text: 'Edit Custom Tag',
              icon: <EditIcon />,
            }}
            title="Edit Custom Tag"
            titleIcon="bp-icon-edit"
            currentCustomTagId={customTag.id}
          />
        )}
      </SettingsInfoScreen.Section>
    );

    const activity = (
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.ActivityRow
          title="Created By:"
          name={customTag.createdByUserName}
          value={momentOrEpochRelativeTimeFormat(customTag.metadata.created_time)}
        />
        <SettingsInfoScreen.ActivityRow
          title="Updated By:"
          name={customTag.updatedByUserName}
          value={momentOrEpochRelativeTimeFormat(customTag.metadata.updated_time)}
        />
      </SettingsInfoScreen.Section>
    );

    const toggle = (
      <BamToggle
        label="Active:"
        labelPosition="left"
        onClick={this.toggleTagClicked}
        checked={customTag.active}
        color="green"
        big
      />
    );

    const deactivateModal = (
      <BamConfirmModal
        icon="bp-icon-power-off"
        headerTitle="Deactivate Custom Tag"
        content="Do you want to deactivate this Custom Tag?"
        note="Note: New alerts will not contain the tag, and undoing this action may take a few minutes"
        contentTitle={customTag ? customTag.destination : ''}
        contentTitleType="string"
        action={this.toggleCustomTag}
        actionText="Deactivate"
        trigger={toggle}
        destructive={false}
        openCondition={() => customTag.active}
      />
    );
    const wrappedToggle = actionPermissions.hasEditPermission ? deactivateModal : null;

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsInfoScreen
          topActions={<CustomTagsActions customTag={customTag} />}
          header={
            <BamTile
              bamStatus={customTag.active ? 'ok' : 'off'}
              headerRow={{ title: customTag.destination, toggle: wrappedToggle }}
            />
          }
          content={content}
          actions={settingsActions}
          activity={activity}
        />
      </ThemeProvider>
    );
  }
}

CustomTagInfo.propTypes = {
  customTag: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    id: PropTypes.string,
    status: PropTypes.string,
    statusText: PropTypes.string,
    type: PropTypes.string,
    sourceSystems: PropTypes.arrayOf(PropTypes.shape({ display: PropTypes.string })),
    source: PropTypes.string,
    pattern: PropTypes.string,
    template: PropTypes.string,
    displayQuery: PropTypes.string,
    metadata: PropTypes.shape({
      description: PropTypes.string,
      created_time: PropTypes.number,
      updated_time: PropTypes.number,
    }),
    createdByUserName: PropTypes.string,
    updatedByUserName: PropTypes.string,
    destination: PropTypes.string,
  }),
  actionPermissions: PropTypes.shape({ hasEditPermission: PropTypes.bool }).isRequired,
  updateCustomTag: PropTypes.func.isRequired,
};

CustomTagInfo.defaultProps = {
  customTag: null,
};

function mapStateToProps(state, ownProps) {
  const customTag = selectors.getCustomTag(state, ownProps.customTagId);
  const actionPermissions = selectors.getActionsPermissions(state);
  return { customTag, actionPermissions };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCustomTag: (customTag) => dispatch(actions.updateCustomTag(customTag)),
  };
}

export default provideStore(connect(mapStateToProps, mapDispatchToProps)(CustomTagInfo));
