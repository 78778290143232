import React from 'react';
import { Button, CommentIcon } from '@bp/kung-fu';
import { BamProgressBar } from '@bp/bam';
import cn from 'classnames';
import PropTypes from 'prop-types';
import integrationPropType from '../../props/sharing_quotas_prop_types';
import styles from './sharing_quotas_table_row.scss';

const SharingQuotasTableRow = ({ integration, requestQuotaChange }) => {
  const usedQuota = integration.datasetOptions.quota.used;
  const availableQuota = integration.datasetOptions.quota.available;
  const usedQuotaDisplayed = usedQuota > availableQuota ? availableQuota : usedQuota;

  return (
    <tr className={styles.sharingQuotasRow}>
      <td className={styles.nameCell}>
        <div className={styles.nameBlock}>
          <i className={cn(integration.iconClass, styles.nameIcon)} />
          <span className={styles.nameText}>{integration.name}</span>
        </div>
      </td>
      <td className={styles.quotaCell}>
        <div className={styles.quotaProgress}>
          <BamProgressBar
            min={0}
            max={availableQuota}
            now={usedQuota}
            barClassName={styles.progressBar}
            emptyProgressClassName={styles.emptyProgressBar}
          />
          <span className={styles.integrationQuotaText}>
            {usedQuotaDisplayed} / {availableQuota} per day
          </span>
        </div>
      </td>
      <td className={styles.actionCell}>
        <Button
          variant="action-container"
          size="medium"
          icon={<CommentIcon />}
          className={styles.actionButton}
          onClick={() => requestQuotaChange(integration)}
        >
          Request Change
        </Button>
      </td>
    </tr>
  );
};

SharingQuotasTableRow.propTypes = {
  integration: integrationPropType.isRequired,
  requestQuotaChange: PropTypes.func.isRequired,
};

export default SharingQuotasTableRow;
