import * as personalSettings from './personal_settings';
import * as rolesManagement from './roles_management';
import * as maintenancePlans from './maintenance_plans';
import * as userManagement from './user_management';
import * as apiKeysManagement from './api_keys_management';
import * as customTags from './custom_tags';
import * as incidentLabels from './incident_labels';
import * as correlationPatterns from './correlation_patterns';
import * as alertEnrichment from './alert_enrichment';
import * as integrationsManagament from './integrations_management';
import * as analytics from './analytics';
import * as auditLogs from './audit_logs';
import * as alertFiltering from './alert_filtering';

export default {
  ...personalSettings.routes,
  ...rolesManagement.routes,
  ...maintenancePlans.routes,
  ...userManagement.routes,
  ...apiKeysManagement.routes,
  ...customTags.routes,
  ...incidentLabels.routes,
  ...correlationPatterns.routes,
  ...alertEnrichment.routes,
  ...integrationsManagament.routes,
  ...analytics.routes,
  ...auditLogs.routes,
  ...alertFiltering.routes,
};
