angular
  .module('bigpanda')
  .directive('dashboardEnvironmentActivities', dashboardEnvironmentActivities);

function dashboardEnvironmentActivities() {
  return {
    scope: {
      widget: '<',
    },
    templateUrl: 'dashboard/dashboard_environment_activities',
    restrict: 'E',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller() {}
}
