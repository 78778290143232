import get from 'lodash/get';
import find from 'lodash/find';
import { compose, identity, curry } from 'lodash/fp';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import permissions from 'common/routing/permissions';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { path } from './constants';
import { generateBaseSelectors } from '../redux.utils';
import { DEFAULT_SORT_MODE } from './incident_labels_list/constants';
import { filteredIncidentLabelsItems, incidentLabelsFilterByDropDown, sortedItems } from './utils';

const selectors = generateBaseSelectors(path);

selectors.getActionsPermissions = (state) => {
  const userPermissions = permissionsSelectors.getPermissionsList(state);
  const hasEditPermission = userPermissions.includes(permissions.tags_definition.update);
  const hasDeletePermission = userPermissions.includes(permissions.tags_definition.delete);
  return { hasEditPermission, hasDeletePermission };
};

selectors.getIncidentLabels = (state) => {
  const filterText = selectors.getFilterText(state);
  const isLoading = get(state, path).isLoadingForSelector;
  const incidentLabelsItems = get(state, path).incidentLabelsData;
  if (!incidentLabelsItems) return;

  const settingsSortingFT = get(
    featureTogglesSelectors.getFeatureToggles(state),
    'frontier_settings_sorting_and_filters',
    false
  );

  return isLoading
    ? incidentLabelsItems
    : compose(
        settingsSortingFT
          ? curry(incidentLabelsFilterByDropDown)(selectors.getFiltersDropDownState(state))
          : identity,
        curry(sortedItems)(selectors.getSortMode(state)),
        curry(filteredIncidentLabelsItems)(filterText)
      )(incidentLabelsItems);
};

selectors.getIncidentLabelsData = (state) => get(state, path).incidentLabelsData;
selectors.getLoadRequestState = (state) => get(state, path).loadRequestStatus;
selectors.getUpdateRequestState = (state) => get(state, path).updateRequestStatus;
selectors.getFormErrors = (state) => get(state, path).formErrors;
selectors.getIncidentLabel = (state, id) => {
  const incidentLabels = selectors.getIncidentLabels(state);
  if (!incidentLabels || incidentLabels.length === 0) {
    return null;
  }
  return find(incidentLabels, (incidentLabel) => incidentLabel.id === id);
};
selectors.getIncidentLabelsData = (state) => get(state, path).incidentLabelsData;
selectors.getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;
selectors.getIsLoading = (state) => get(state, path).isLoadingForSelector;

export default selectors;
