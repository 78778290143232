import {
  AllSizes, ArrowdownIcon, AssignIcon,
  Button, ButtonVariants,
} from '@bp/kung-fu';
import styled from 'styled-components';

export { AssignIcon };

export const ArrowDown = styled(ArrowdownIcon)`

`;

export const TriggerButton = styled(Button).attrs({ size: AllSizes.LARGE, variant: ButtonVariants.ACTION_CONTAINER })`

&& ${ArrowDown} {
  font-size: 14px;
}

&& ${AssignIcon}{
  font-size: 18px;
}
`;
