import {
  addDaysToDate,
  buildDateString,
  DateFormat,
  getCurrentDate,
  ISelectOption,
  StatusEnum,
} from '@bp/kung-fu';

import { MapCategoryRowName } from '../../../../hooks/useAuditTable/constants';

export const anyUser: ISelectOption = { id: '', name: 'Any User' };

const now = getCurrentDate();

export const actionOptions: ISelectOption[] = [
  {
    id: '',
    text: 'Any Action',
    status: StatusEnum.DISABLED,
  },
  {
    id: 'create',
    text: 'Created',
    status: StatusEnum.ACTIVE,
    dataTest: 'id-created',
  },
  {
    id: 'delete',
    text: 'Deleted',
    status: StatusEnum.CRITICAL,
  },
  {
    id: 'update',
    text: 'Updated',
    status: StatusEnum.NEW,
  },
  {
    id: 'init_state',
    text: 'Initial State',
    status: StatusEnum.NONE,
  },
];

export const categoryOptions: ISelectOption[] = [
  {
    id: '',
    text: 'Any Category',
  },
  {
    id: 'correlation_pattern',
    text: MapCategoryRowName.correlation_pattern,
  },
  {
    id: 'auto_share_rules',
    text: MapCategoryRowName.auto_share_rules,
  },
  {
    id: 'environments',
    text: MapCategoryRowName.environments,
  },
  {
    id: 'users',
    text: MapCategoryRowName.users,
  },
  {
    id: 'roles',
    text: MapCategoryRowName.roles,
  },
  {
    id: 'jit_domains',
    text: MapCategoryRowName.jit_domains,
  },
  {
    id: 'jit_roles',
    text: MapCategoryRowName.jit_roles,
  },
  {
    id: 'sso_test',
    text: MapCategoryRowName.sso_test,
  },
  {
    id: 'sso_config',
    text: MapCategoryRowName.sso_config,
  },
  {
    id: 'api_keys',
    text: MapCategoryRowName.api_keys,
  },
  {
    id: 'tag',
    text: MapCategoryRowName.tag,
  },
  {
    id: 'tags_order',
    text: MapCategoryRowName.tags_order,
  },
  {
    id: 'tag_enrichments',
    text: MapCategoryRowName.tag_enrichments,
  },
  {
    id: 'tag_enrichments_order',
    text: MapCategoryRowName.tag_enrichments_order,
  },
  {
    id: 'mapping_enrichment',
    text: MapCategoryRowName.mapping_enrichment,
  },
  {
    id: 'mapping_table_upload',
    text: MapCategoryRowName.mapping_table_upload,
  },
  {
    id: 'integrations',
    text: MapCategoryRowName.integrations,
  },
];

export const dateOptions: ISelectOption[] = [
  {
    id: 'ANY_TIME',
    text: 'Any Time',
    value: {
      startDate: undefined,
      endDate: now,
    },
  },
  {
    id: 'LAST_24_HOURS',
    text: 'Last 24 Hours',
    value: {
      startDate: addDaysToDate(now, -1),
      endDate: now,
    },
    description: `(${buildDateString(addDaysToDate(now, -1), DateFormat.FullDate)})`,
  },
  {
    id: 'LAST_48_HOURS',
    text: 'Last 48 Hours',
    value: {
      startDate: addDaysToDate(now, -2),
      endDate: now,
    },
    description: `(${buildDateString(addDaysToDate(now, -2), DateFormat.FullDate)})`,

  },
  {
    id: 'LAST_7_DAYS',
    text: 'Last 7 Days',
    value: {
      startDate: addDaysToDate(now, -7),
      endDate: now,
    },
    description: `(${buildDateString(addDaysToDate(now, -7), DateFormat.FullDate)})`,

  },
  {
    id: 'LAST_30_DAYS',
    text: 'Last 30 Days',
    value: {
      startDate: addDaysToDate(now, -30),
      endDate: now,
    },
    description: `(${buildDateString(addDaysToDate(now, -30), DateFormat.FullDate)})`,

  },
];
