import actionTypes from './actionTypes';

const initialState = {
  ssoState: null,
  configurationSent: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SSO_SUCCESS:
      return {
        ...state,
        ssoState: action.payload,
        configurationSent: '',
      };
    case actionTypes.LOAD_SSO_STATE_SUCCESS:
      return {
        ...state,
        ssoState: action.payload,
      };
    case actionTypes.CONFIGURE_SSO_PROVIDER_SUCCESS:
      return {
        ...state,
        configurationSent: action.payload.provider,
      };
    case actionTypes.DISABLE_SSO_SUCCESS:
      return {
        ...state,
        ssoState: action.payload,
      };

    default:
      return state;
  }
}
