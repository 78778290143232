import find from 'lodash/find';
angular.module('bigpanda').controller('IntegrationInfoController', IntegrationInfoController);

function IntegrationInfoController(
  $scope,
  $state,
  stateService,
  AvailableIntegrationsStore,
  IntegrationsFaqConfig,
  TypesFaqConfig,
  pubSubService,
  UserFeatureTogglesService
) {
  const vm = this;
  vm.currentIntegration = AvailableIntegrationsStore.getAvailableIntegration(
    stateService.getSelectedIntegrationId()
  );
  const integrationFaq = find(IntegrationsFaqConfig.integrationsFaq, {
    source_system: vm.currentIntegration.source_system,
  });
  vm.goToSelection = goToSelection;
  vm.goToIntegrations = goToIntegrations;
  vm.openIntercom = openIntercom;

  $scope.guideNumberStart = 0;
  if (vm.currentIntegration.options && vm.currentIntegration.options.abstract) {
    $scope.guideNumberStart++;
  }
  if (vm.currentIntegration.guides && vm.currentIntegration.guides.length > 1) {
    $scope.guideNumberStart++;
  }

  if (integrationFaq) {
    const faqsByType = find(TypesFaqConfig.typesFaq, { faq_type: integrationFaq.faq_type });

    if (faqsByType) {
      vm.faqs = integrationFaq.faqs.concat(
        find(TypesFaqConfig.typesFaq, { faq_type: integrationFaq.faq_type }).faqs
      );
    } else {
      vm.faqs = integrationFaq.faqs;
    }
  }

  vm.imgUrl = `/assets/img/logos/${vm.currentIntegration.source_system.replace(' ', '')}.png`;

  function goToSelection() {
    $state.go('app.integrations.integration-info', {
      integration: stateService.getSelectedIntegrationId(),
    });
  }

  function goToIntegrations() {
    $state.go('app.integrations');
  }

  function openIntercom() {
    pubSubService.broadcast('Intercom.show');
  }
}
