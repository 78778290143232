import bpFetch from 'common/utils/http/fetcher';

import { cacheTillFinished } from 'common/utils/http/cache';

export const getEnvironments = cacheTillFinished(() => {
  return bpFetch({ url: '/web/environments' })
    .then((res) => res.data)
    .catch(() => []);
});

export function createMerge(payload, environmentId, incidentId) {
  /* eslint-disable-line */
  return bpFetch({
    url: `/web/environments/${environmentId}/incidents/${incidentId}/merge`,
    method: 'POST',
    data: payload,
  }).then((res) => res.data.mergeResponse);
}

export function createSplit(payload, environmentId, incidentId) {
  /* eslint-disable-line */
  return bpFetch({
    url: `/web/environments/${environmentId}/incidents/${incidentId}/split`,
    method: 'POST',
    data: payload,
  }).then((res) => res.data.splitResponse);
}

export function createEntitiesBatchResolve(payload, environmentId) {
  /* eslint-disable-line */
  return bpFetch({
    url: `/web/batch-resolve/environments/${environmentId}/entities`,
    method: 'POST',
    data: payload,
  });
}
