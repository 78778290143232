import {
  Date, DateFormat,
} from '@bp/kung-fu';
import React from 'react';

import { CustomColumnProps } from './types';

function DateColumn({ cellData }: CustomColumnProps): JSX.Element {
  return <Date date={cellData} format={DateFormat.FullDate} withTooltip={false} />;
}

export default DateColumn;
