import extend from 'lodash/extend';
// eslint-disable-next-line import/extensions
import featureToggles from 'feature-toggles';
angular.module('bigpanda').service('PersonalSettingsStore', PersonalSettingsStore);
function PersonalSettingsStore(
  $q,
  PersonalSettingsBackendService,
  SubscribersService,
  deepCloneObject
) {
  let user;
  let contact;
  this.subscribe = subscribe;
  this.getUser = getUser;
  this.getContact = getContact;
  this.updateContact = updateContact;
  this.updateUser = updateUser;

  const subscribers = new SubscribersService();

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }
  function getUser(forceLoad) {
    if (user && !forceLoad) {
      return $q.when(deepCloneObject.cloneDeep(user));
    }
    return PersonalSettingsBackendService.getUser().then((rawUser) => {
      user = normalizeUser(rawUser);
      return deepCloneObject.cloneDeep(user);
    });
  }
  function getContact(forceLoad) {
    if (contact && !forceLoad) {
      return $q.when(deepCloneObject.cloneDeep(contact));
    }
    return PersonalSettingsBackendService.getContact().then((rawContact) => {
      contact = rawContact;
      return deepCloneObject.cloneDeep(contact);
    });
  }

  function updateUser(payload) {
    return PersonalSettingsBackendService.updateUser(payload).then((rawUser) => {
      user = normalizeUser(rawUser);
      subscribers.fireStoreUpdated();
      return deepCloneObject.cloneDeep(user);
    });
  }

  function updateContact(payload) {
    return PersonalSettingsBackendService.updateContact(payload).then((rawContact) => {
      contact = rawContact;
      subscribers.fireStoreUpdated();
      return deepCloneObject.cloneDeep(contact);
    });
  }

  function normalizeUser(rawUser) {
    const normalizedUser = deepCloneObject.cloneDeep(rawUser);
    normalizedUser.featureToggles = extend(
      {},
      rawUser.organization.feature_toggles,
      rawUser.feature_toggles,
      featureToggles
    );
    normalizedUser.organizationName = rawUser.organization.name;
    normalizedUser.organizationDisplayName = rawUser.organization.display_name;
    return normalizedUser;
  }
}
