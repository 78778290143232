import isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import React from 'react';
import PropTypes from 'prop-types';
import { showFailureMessage } from 'react/layout/settings/feedback';
import { reqStatusType } from '../constants';
import selectors from '../selectors';

const showNotifcationMessage = (loadRequestStatus) => {
  if (loadRequestStatus === reqStatusType.FAILURE) {
    showFailureMessage('Incident Tag', 'load');
  }
};
class IncidentLabelsLoadNotification extends React.Component {
  componentDidMount() {
    if (!isNil(this.props.loadRequestStatus)) {
      showNotifcationMessage(this.props.loadRequestStatus);
    }
  }
  componentDidUpdate() {
    if (!isNil(this.props.loadRequestStatus)) {
      showNotifcationMessage(this.props.loadRequestStatus);
    }
  }
  render() {
    return null;
  }
}
IncidentLabelsLoadNotification.propTypes = {
  loadRequestStatus: PropTypes.oneOf([...Object.values(reqStatusType)]).isRequired,
};
function mapStateToProps(state) {
  return {
    loadRequestStatus: selectors.getLoadRequestState(state),
  };
}

export default connect(mapStateToProps, null)(hot(module)(IncidentLabelsLoadNotification));
