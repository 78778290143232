import {
  Button, ButtonVariants, RefreshIcon, Text,
} from '@bp/kung-fu';
import React from 'react';

import { ContentPreview } from '../../../../alert-enrichment/src/components/Modal/ContentPreview';
import { getInterpolatedText } from '../../../../alert-enrichment/src/helpers/get-interpolated-text';
import dictionary from '../../dictionary';
import { PreviewState } from '../../types/PreviewState';
import { Subheader } from './FilterPreview.styles';

interface IProps {
  canPreview: boolean;
  isLoading: boolean;
  isConditionChanged: boolean;
  previewState: PreviewState;
  getPreviewFilters: () => void;
}

export function FilterPreviewKF({
  canPreview, isLoading, isConditionChanged, previewState, getPreviewFilters,
}: IProps): JSX.Element {
  return (
    <ContentPreview
      header={(
        <Button
          icon={<RefreshIcon />}
          onClick={getPreviewFilters}
          variant={ButtonVariants.ACTION_COLOR}
          disabled={!canPreview || isLoading}
        >
          Refresh
        </Button>
      )}
      subheader={previewState?.events && (
        <Subheader>
          <Text weight="bold">
            {getInterpolatedText(dictionary.filter_preview_subheader, { limit: `${previewState.events.length}` })}
            {isConditionChanged ? 'condition changed' : ''}
          </Text>
        </Subheader>

      )}
      loading={isLoading}
      emptyPreviewHeader={previewState?.events ? 'No matches' : 'No preview yet'}
      emptyPreviewMessage={
        previewState?.events
          ? 'Adjust form definitions to preview values from matching alerts'
          : 'Fill out the form to see values from matching alerts'
    }
      unpadded
    >
      {previewState?.events?.length > 0 ? (
        <div>Preview alerts</div>
      ) : []}
    </ContentPreview>
  );
}
