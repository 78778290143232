import { DiceIcon } from '@bp/kung-fu';
import React from 'react';

export const DROPDOWMN_SHADOW = '-6px 2px 7px 0px rgba(0, 0, 0, 0.10)';

export const INCIDENT_TAGS_METADATA = [
  {
    title: 'assignee',
    id: 'assignee',
    description: 'The assigned user for the incident',
    example: ['assignee = derrick*', 'assignee = derrick@bigpanda.io'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'Comment',
    id: 'Comment',
    description: 'The assigned user for the incident',
    example: ['Comment = {{value}}'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'IsActive',
    id: 'IsActive',
    example: ['IsActive = {{value}}'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'severity',
    id: 'severity',
    description: 'The current severity of the incident ("Critical", "Warning", "Unknown", "Ok")',
    example: ['severity = {{value}}'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'status',
    id: 'status',
    description: 'The current status of the incident ("Critical", "Warning", "Unknown", "Ok")',
    example: ['status = critical'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'source_system',
    id: 'source_system',
    description: 'The integration that sent an alert to BigPanda',
    example: ['source_system = nagios*', 'source_system IN [nagios*,new_relic*]'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'ZeroImpact',
    id: 'ZeroImpact',
    example: ['ZeroImpact = critical'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
];

export const OLD_INCIDENT_TAGS_METADATA = [
  {
    title: 'assignee',
    description: 'The assigned user for the incident',
    example: ['assignee = derrick*', 'assignee = derrick@bigpanda.io'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'status',
    description: 'The current status of the incident ("Critical", "Warning", "Unknown", "Ok")',
    example: ['status = critical'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: 'source_system',
    description: 'The integration that sent an alert to BigPanda',
    example: ['source_system = nagios*', 'source_system IN [nagios*,new_relic*]'],
    isMetadata: true,
    icon: <DiceIcon />,
  },
];
