angular.module('bigpanda.backend.services').service('RolesBackendService', RolesBackendService);

function RolesBackendService($http) {
  this.getEnvironmentRoles = getEnvironmentRoles;
  this.createFullResource = createFullResource;
  this.editFullResource = editFullResource;

  function getEnvironmentRoles(id) {
    return $http.get(`/resources/v2.0/roles/environments/${id}`).then((res) => res.data.roles);
  }

  function createFullResource(payload) {
    return $http.post('/resources/v2.0/roles/environments', payload).then((res) => res.data);
  }

  function editFullResource(payload) {
    return $http
      .patch(`/resources/v2.0/roles/environments/${payload.resource.id}`, payload)
      .then((res) => res.data);
  }

  return this;
}
