import { path as prevPath } from '../constants';

export const name = 'integrations';
export const path = `${prevPath}.${name}`;

export const CREATED_SORT_MODE = { text: 'Creation time', field: 'created_at', ascending: false };
export const CHANGED_SORT_MODE = {
  text: 'Change date',
  field: 'updated_at',
  ascending: false,
  isDefault: true,
};
export const SORT_MODES = [CHANGED_SORT_MODE, CREATED_SORT_MODE];

export const DEFAULT_SORT_MODE = CREATED_SORT_MODE;
