import React from 'react';
import PropTypes from 'prop-types';
import { Text, SCHBox, HelpTooltip, Select, ActionableBadge } from '@bp/kung-fu';
import { hot } from 'react-hot-loader';
import { Field, FormSpy } from 'react-final-form';
import { Wrapper } from './FormulaExpressionListHeader.css';
import { buildHelpTooltipText } from './FormulaExpressionListHeader.logic';

const appendOptions = [
  {
    text: 'Any',
    value: true,
    color: 'gray',
    font: 'dark_gray',
    bold: false,
  },
  {
    text: 'First',
    value: false,
    color: 'gray',
    font: 'dark_gray',
    bold: false,
  },
];

const name = 'enrichment_config.append_matching_items';

const FormulaExpressionListHeader = ({ counter, allowAppend, appendValue }) => {
  return (
    <Wrapper>
      <SCHBox gap="4px">
        <Text weight="bold">Automatic Enrichment</Text>
        {counter > 0 && <Text>({counter})</Text>}
      </SCHBox>

      <SCHBox gap="4px">
        {allowAppend ? (
          <React.Fragment>
            <FormSpy>
              {({ mutators }) => (
                <Field
                  name={name}
                  render={({ input }) => {
                    const selectedValue = input.value ? appendOptions[0] : appendOptions[1];
                    return (
                      <Select
                        name={input.name}
                        updateField={(name, { value }) => mutators.update(name, value)}
                        value={selectedValue}
                        controlComponent={(p) => <ActionableBadge {...p} color="bp_gray_02" />}
                        menuWidth="80px"
                        valueKey="value"
                        options={appendOptions}
                      />
                    );
                  }}
                  subscription={{ value: true }}
                />
              )}
            </FormSpy>

            <Text>matching item will be applied</Text>
          </React.Fragment>
        ) : (
          <Text>First matching item will be applied</Text>
        )}

        <HelpTooltip text={buildHelpTooltipText(appendValue)} />
      </SCHBox>
    </Wrapper>
  );
};

FormulaExpressionListHeader.propTypes = {
  counter: PropTypes.number.isRequired,
  allowAppend: PropTypes.bool,
  appendValue: PropTypes.bool,
};

FormulaExpressionListHeader.defaultProps = {
  allowAppend: false,
  appendValue: undefined,
};

export default hot(module)(FormulaExpressionListHeader);
