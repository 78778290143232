angular.module('bigpanda').controller('SidebarUserMenuController', SidebarUserMenuController);

function SidebarUserMenuController(UserMenuService, $timeout) {
  const vm = this;

  vm.triggerAction = (action) => {
    if (action) {
      $timeout(action);
    }
  };

  vm.actions = UserMenuService.actions(true);
}
