import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import { firstBy } from 'thenby';
import { MsteamsIcon } from '@bp/kung-fu';

import { targetSystemConfig } from './targetSystems';
import utils from '../utils';
import { systemIconClass } from '../constants';

const retriveShareOptions = (integrations) => {
  const normalizedOptions = [];
  const integrationsWithOptions = integrations
    .filter((integration) => integration.share_options)
    .filter((integration) =>
      find(targetSystemConfig, {
        id: integration.parent_system_id,
      })
    );

  integrationsWithOptions.forEach((integrationWithOptions) =>
    integrationWithOptions.share_options.forEach((option) =>
      normalizedOptions.push(configIntegrationWOptionsObject(integrationWithOptions, option))
    )
  );
  return normalizedOptions;
};

const getIntegrationIcon = (systemId) => {
  if (systemId === 'teams') {
    return <MsteamsIcon />;
  }
  return <i className={`${systemIconClass(systemId)} icon-type`} />;
};

const configIntegrationObject = (integration) => ({
  app_key: integration.stream_id,
  key: integration.system_id,
  text: utils.systemDisplayText(integration),
  value: integration.system_id,
  icon: {
    component: getIntegrationIcon(integration.parent_system_id),
  },
});

const configIntegrationWOptionsObject = (integration, option) => {
  return {
    key: option.id,
    text: option.display,
    value: {
      option: option,
      targetSystemId: integration.target_system_id,
    },
    icon: {
      // eslint-disable-next-line react/jsx-filename-extension
      component: <i className={`${systemIconClass(option.type)} icon-type`} />,
    },
  };
};

const isSimpleIntegration = (integration) =>
  get(integration, 'type') === 'target' && !get(integration, 'share_options');

const getSimpleIntegrations = (integrations) =>
  integrations
    .filter(isSimpleIntegration)
    .sort(firstBy('order').thenBy('name'))
    .map(configIntegrationObject);

export { retriveShareOptions, getSimpleIntegrations, getIntegrationIcon };
