import get from 'lodash/get';

import { path, reqStatusType } from './constants';

const selectors = {
  getTroubleshooting: (state) => get(state, path),
  getIsLoading: (state) => {
    const troubleshootingData = get(state, path);
    const { getRequestStatus, metadataRequestStatus } = troubleshootingData;
    return (
      getRequestStatus === reqStatusType.LOADING || metadataRequestStatus === reqStatusType.LOADING
    );
  },
};

export default selectors;
