import {
  darkTheme, Form, Select,
} from '@bp/kung-fu';
import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import SelectWrapper from './index.style';
import { IEnvRuleClosedListSelect } from './types';
import { checkValuesNotExist } from './utils';

function envRuleClosedListSelect(props: IEnvRuleClosedListSelect): JSX.Element {
  const {
    selected,
    items,
    metadata,
    onChange,
  } = props;

  const closedListOptions = useMemo(() => items
    .map(({ value }) => ({ id: value, text: value })), [items]);

  const error = useMemo(() => (selected?.length > 0)
      && checkValuesNotExist(selected, closedListOptions), [selected, closedListOptions]);

  return (
    <ThemeProvider theme={darkTheme}>
      <SelectWrapper>
        <Form>
          <Select
            name={`ruleIndex_${metadata.ruleIndex}_andIndex_${metadata.andIndex}_priority`}
            value={selected}
            options={closedListOptions}
            updateField={(name, value): void => onChange(value, metadata)}
            placeholder="Select Values..."
            filterPlaceholder="Type to filter..."
            isMulti
            isFilterable
            error={error}
          />
        </Form>
      </SelectWrapper>
    </ThemeProvider>
  );
}

export default envRuleClosedListSelect;
