import actionTypes from './actionTypes';

const initialState = {
  labelDefinitions: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_CONFIGURATION_LABEL_DEFINITIONS_SUCCESS:
      return {
        ...state,
        labelDefinitions: action.payload,
      };

    default:
      return state;
  }
}
