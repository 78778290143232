import React, { useState } from 'react';

import {
  CollapseIcon, Container, Draggable, DragIcon,
} from './ContextButtons.style';

interface IProps {
  defaultArrow: React.ReactNode;
  hover: boolean;
  focus: boolean;
  isDragging: boolean;
}

export function ContextButtons({
  defaultArrow, hover, isDragging, ...dragAndDropProps
}: IProps): JSX.Element {
  const [focus, setFocus] = useState(false);

  return (
    <Container>
      <Draggable
        onFocus={(): void => setFocus(true)}
        onBlur={(): void => setFocus(false)}
        isDragging={isDragging}
        {...dragAndDropProps}
      >
        <DragIcon isActive={isDragging || focus || hover} />
      </Draggable>
      <CollapseIcon>{defaultArrow}</CollapseIcon>
    </Container>
  );
}

export default ContextButtons;
