import { HBox, VBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const Container = styled(VBox)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

export const FooterLine = styled(HBox)`
    height: 42.5px;
    padding: 15px 24px 13.5px;
    border-top: solid 1px ${(p) => p.theme.bp_gray_03};
    background-color: ${(p) => p.backgroundColor};
`;
