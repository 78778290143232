import { path } from './constants';

const actionTypes = {};

actionTypes.LOAD_CORRELATION_CONFIG = `${path}.LOAD_CORRELATION_CONFIG`;
actionTypes.LOAD_CORRELATION_CONFIG_SUCCESS = `${path}.LOAD_CORRELATION_CONFIG_SUCCESS`;
actionTypes.ENRICH_CORRELATION_CONFIG_ITEMS_SUCCESS = `${path}.ENRICH_CORRELATION_CONFIG_ITEMS_SUCCESS`;
actionTypes.LOAD_UNIFIED_TAGS_SUCCESS = `${path}.LOAD_UNIFIED_TAGS_SUCCESS`;

export default actionTypes;
