import { LABEL_SEPARATOR } from './constants';

const generateHelpText = (resource) =>
  `Labels allow you to classify and filter ${resource} over time`;

const getLabelDisplayValue = ({ group, value }) => `${group}${LABEL_SEPARATOR.DISPLAYED}${value}`;
const getLabelInternalValue = ({ group, value }) =>
  `${group}${LABEL_SEPARATOR.INTERNAL}${value}`.toLowerCase();

export { generateHelpText, getLabelDisplayValue, getLabelInternalValue };
