import find from 'lodash/find';
angular.module('bigpanda').service('AvailableIntegrationsStore', AvailableIntegrationsStore);

function AvailableIntegrationsStore(DisplayCommonService) {
  const availableIntegrations = DisplayCommonService.getAvailableIntegrations();

  this.getAvailableIntegrations = getAvailableIntegrations;
  this.getAvailableIntegration = getAvailableIntegration;

  function getAvailableIntegrations() {
    return angular.copy(availableIntegrations);
  }

  function getAvailableIntegration(sourceSystem) {
    return angular.copy(
      find(availableIntegrations, { source_system: getParentSourceSystem(sourceSystem) })
    );
  }

  function getParentSourceSystem(sourceSystem) {
    return sourceSystem.split('.')[0];
  }
}
