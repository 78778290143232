import React, { useCallback, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { MultiStarsIcon, SCHBox, useBoolean, darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';

import { AIWrapper, AISection } from './style.css';
import AiGenerate from './components/generate-ai/generate-ai';
import AiAnalysis from './components/ai-analysis/ai-analysis';
import FeedbackModal from './components/feedback-modal/feedback-modal';
import DotsLoader from './components/dots-loader/dots-loader';

const IncidentAiWrapper = ({ aiTags, onGenerate, isLoading, isGenerationEnabled, isReadOnly }) => {
  const [isOpenModal, { setTrue: openModal, setFalse: setModalOpenFalse }] = useBoolean(false);
  const [isThumbsDownPressed, setIsThumbsDownPressed] = useState(false);
  const [isThumbsUpPressed, setIsThumbsUpPressed] = useState(false);
  const [reason, setReason] = useState('');

  const onThumbsDown = useCallback(() => {
    if (!isThumbsDownPressed) {
      openModal();
    }

    setIsThumbsDownPressed(!isThumbsDownPressed);
    setIsThumbsUpPressed(false);
  }, [openModal, isThumbsDownPressed]);

  const onThumbsUp = useCallback(() => {
    setIsThumbsUpPressed(!isThumbsUpPressed);
    setIsThumbsDownPressed(false);
  }, [isThumbsUpPressed]);

  const closeModal = useCallback(() => {
    setReason('');
    setModalOpenFalse();
  }, []);

  const onRegenerate = useCallback(() => {
    onGenerate();
    setIsThumbsUpPressed(false);
    closeModal();
  }, [closeModal, onGenerate]);

  const setReasonChange = (e) => setReason(e.target.value);
  return (
    <ThemeProvider theme={darkTheme}>
      <AISection>
        <AIWrapper width="100%" height="100%" minHeight="56px">
          {isLoading && (
            <SCHBox gap="5px" margin="14px">
              <MultiStarsIcon fontSize="26px" color={(p) => p.theme.bp_purple} />
              <DotsLoader />
            </SCHBox>
          )}
          {!isLoading && aiTags && !!aiTags.length && (
            <AiAnalysis
              isReadOnly={isReadOnly}
              onThumbsUp={onThumbsUp}
              isThumbsDownPressed={isThumbsDownPressed}
              isThumbsUpPressed={isThumbsUpPressed}
              isGenerationEnabled={isGenerationEnabled}
              aiTags={aiTags}
              onThumbsDown={onThumbsDown}
              onRegenerate={onRegenerate}
            />
          )}
          {!isReadOnly && !isLoading && (!aiTags || !aiTags.length) && (
            <AiGenerate onAiGenerate={() => onGenerate()} />
          )}
        </AIWrapper>
        {isOpenModal && (
          <FeedbackModal
            onActionClick={closeModal}
            reason={reason}
            setReason={setReasonChange}
            onCloseModal={closeModal}
          />
        )}
      </AISection>
    </ThemeProvider>
  );
};

IncidentAiWrapper.propTypes = {
  aiTags: PropTypes.arrayOf({
    tag_id: PropTypes.string,
    tag_value: PropTypes.string,
  }),
  onGenerate: PropTypes.func,
  isLoading: PropTypes.bool,
  isGenerationEnabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default IncidentAiWrapper;
