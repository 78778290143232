import get from 'lodash/get';
import { pipe, identity } from 'lodash/fp';
import { firstBy } from 'thenby';
import boolToLodashOrder from 'common/utils/boolToLodashOrder';
import { filterItems, filterItemsByDropDownFilters } from '../redux.utils';
import { path, ANY_OPTION_VALUE } from './constants';
import { DEFAULT_SORT_MODE } from './alert_enrichment_list/constants';
import { selectors as permissionsSelectors } from '../../../user/permissions';
import permissions from '../../../../common/routing/permissions';

const getActionsPermissions = (state) => {
  const userPermissions = permissionsSelectors.getPermissionsList(state);
  const hasEditPermission = userPermissions.includes(permissions.enrichments.update);
  const hasDeletePermission = userPermissions.includes(permissions.enrichments.delete);
  return {
    hasEditPermission,
    hasDeletePermission,
  };
};

const mappingFilterFields = ({ config: { fields, name } }) => [
  name,
  ...fields.map(({ tag_name }) => tag_name),
];

const enrichmentFilterFields = (enrichment) => {
  const configFields =
    enrichment.type === 'mapping'
      ? mappingFilterFields(enrichment)
      : [enrichment.config.destination, enrichment.config.value, enrichment.note];

  return [enrichment.displayQuery, ...configFields];
};

const tagsFilterByText = (text) => (tags) =>
  filterItems(tags, text, ({ name, enrichments }) => [
    name,
    ...enrichments.flatMap(enrichmentFilterFields),
  ]);

const tagsFilterByDropDown = (filters) => (tags) =>
  filterItemsByDropDownFilters(
    filters,
    {
      tagName: (tag) => [tag.name],
      status: (tag) => [`${tag.active}`],
      type: (tag) => [tag.type],
      source: (tag) => tag.sourceSystems.map(({ value }) => value),
    },
    tags,
    ANY_OPTION_VALUE
  );

const tagsSort = ({ field, ascending }) => (tags) => {
  if (!tags) return undefined;
  const { field: defaultField, ascending: defaultOrder } = DEFAULT_SORT_MODE;
  return tags.sort(
    firstBy(field, boolToLodashOrder(ascending)).thenBy(
      defaultField,
      boolToLodashOrder(defaultOrder)
    )
  );
};

const getFilterText = (state) => get(state, path).filterText;

const getTag = (state, tagName) => {
  const filterOpts = {
    shouldFilterByText: false,
    shouldFilterByDropDownFilters: false,
    shouldSort: false,
  };
  const tags = getTags(state, filterOpts) || [];
  return tags.find((tag) => tag.name === tagName);
};

const getTags = (
  state,
  {
    shouldFilterByText = true,
    shouldFilterByDropDownFilters = true,
    shouldSort = true,
    sortBy,
  } = {}
) => {
  const { tags } = get(state, path);
  const filterAndSort = pipe(
    shouldFilterByText ? tagsFilterByText(getFilterText(state)) : identity,
    shouldFilterByDropDownFilters ? tagsFilterByDropDown(getFilters(state)) : identity,
    shouldSort ? tagsSort(sortBy || getSortMode(state)) : identity
  );
  return filterAndSort(tags);
};

const getFiltersDropDownItems = (state) => get(state, path).filtersDropDownItems;

const getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;

const getFilters = (state) => get(state, path).filters;

const isLoading = (state) => get(state, path).isLoading;

const getDraggedItems = (state) => get(state, path).draggedItems;

const getPreviewAlerts = (state) => get(state, path).previewAlerts;

const getPreviewLoading = (state) => get(state, path).previewLoading;

const getPreviewRule = (state) => get(state, path).previewRule;

const getUniquePreviewTags = (state) => get(state, path).uniquePreviewTags;

const getReservedTags = (state) => get(state, path).reservedTags || [];

const getReservedTagsNames = (state) => get(state, path).reservedTagsNames || [];

const getTotalTags = (state) => get(state, `${path}.tags`, []).length;

const getTotalRules = (state) => get(state, path).totalNumOfRules || 0;

export default {
  getTotalTags,
  getTotalRules,
  getFiltersDropDownItems,
  getFilters,
  getFilterText,
  getSortMode,
  getTag,
  getTags,
  isLoading,
  getActionsPermissions,
  getDraggedItems,
  getPreviewAlerts,
  getPreviewLoading,
  getPreviewRule,
  getUniquePreviewTags,
  getReservedTags,
  getReservedTagsNames,
};
