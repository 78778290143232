import OneLoginInstructions from './OneLoginInstructions';
import OktaInstructions from './OktaInstructions';
import AzureInstructions from './AzureInstructions';
import PingOneInstructions from './PingOneInstructions';
import caInstructions from './caInstructions';

const tutorials = {
  onelogin: OneLoginInstructions,
  okta: OktaInstructions,
  pingone: PingOneInstructions,
  ca: caInstructions,
  azure: AzureInstructions,
};

export default tutorials;
