import React from 'react';
import PropTypes from 'prop-types';
import IncidentTagsGridMultiValueEditor from './IncidentTagsGridMultiValueEditor';

const IncidentTagsUpdateMultiValue = (props) => {
  const { onValueChange, cellValue, error, onValidationChange } = props;

  const validateTextValue = (value) => (value && value.length > 256 ? 'Max 256 chars' : null);

  const getLabels = (creatableSelectValues) => creatableSelectValues.map((value) => value.label);

  const normalizeToCreatableSelectValues = (values) =>
    values.map((value) => ({
      value: value,
      label: value.toString(),
      __isNew__: true,
    }));

  const currSelectValues =
    cellValue && cellValue.length ? normalizeToCreatableSelectValues(cellValue) : [];

  const onSelectValueChange = (values) => {
    onValueChange(getLabels(values));
  };

  return (
    <IncidentTagsGridMultiValueEditor
      onBlur={() => true}
      error={error}
      values={currSelectValues}
      onValidationChange={onValidationChange}
      validate={validateTextValue}
      onChange={onSelectValueChange}
    />
  );
};

IncidentTagsUpdateMultiValue.propTypes = {
  onValueChange: PropTypes.func,
  onValidationChange: PropTypes.func,
  cellValue: PropTypes.string,
  error: PropTypes.string,
};

export default IncidentTagsUpdateMultiValue;
