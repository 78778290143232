const HIGHLIGHT_DIVIDER = 'highlight_divider';

const highlightedExtractionTagParts = (text, pattern) => {
  text = typeof text === 'string' ? text : Object.values(text)[0];

  if (!pattern) {
    return [text];
  }
  // Modify phrase regex so that it will capture before and after the original capture group
  const newRegex = pattern.replace(/^(.*)(\([^?][^)]+\))(.*)$/, '($1)$2($3)');
  if (newRegex === pattern) {
    return [text];
  }
  try {
    const regex = new RegExp(`${newRegex}`, 'i');
    const highlightedText = text.replace(regex, `$1${HIGHLIGHT_DIVIDER}$2${HIGHLIGHT_DIVIDER}$3`);
    return highlightedText.split(HIGHLIGHT_DIVIDER);
  } catch (e) {
    return [text];
  }
};

export default highlightedExtractionTagParts;
