import has from 'lodash/has';
angular
  .module('bigpanda.utils')
  .service('integrationGuideService', function ($log, $http, $q, Config, version) {
    var cachedGuides = {};
    var localDocsUrl = '/resources/tutorials/';

    this.getTutorial = function (source_name) {
      if (has(cachedGuides, source_name)) {
        return $q.when(cachedGuides[source_name]);
      }

      var guideUrl = `${localDocsUrl + source_name}.md?v=${version.version}&d=${Config.deployTime}`;

      return $http.get(guideUrl).then(
        (data) => processGuide(source_name, data),
        (err) => {
          $log.info("Couldn't find doc, falling back to generic");
          guideUrl = `${localDocsUrl}generic.md?v=${version.version}&d=${Config.deployTime}`;
          return $http.get(guideUrl).then(
            (data) => processGuide(source_name, data),
            (err) => {
              $log.error(`Error getting doc: '${guideUrl}'`, err.toString());
              return $q.reject(err);
            }
          );
        }
      );

      function processGuide(source_name, data) {
        var guideContent = data.data;
        guideContent = guideContent.replace(/\/media\//g, localDocsUrl);
        cachedGuides[source_name] = guideContent;
        return guideContent;
      }
    };
  });
