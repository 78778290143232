import React from 'react';

const bpqlTooltopContent = (
  <div className="input-bpql-tooltip">
    <ul className="content-wrapper">
      <li className="content-item">
        <div className="title">Free text:</div>
        <div className="value">phx* | &#34;disk failure&#34;</div>
      </li>
      <li className="content-item">
        <div className="title">Tag value:</div>
        <div className="value">host=phx* | host IN [phx*,ny*,syd*]</div>
      </li>
      <li className="content-item">
        <div className="title">Boolean:</div>
        <div className="value">host=phx* AND check=&quot;high cpu&quot;</div>
      </li>
      <li className="content-item">
        <div className="title">Regex:</div>
        <div className="value">host=/…phx[0-9]{3}/</div>
      </li>
      <li className="content-item">
        <div className="title">Operators</div>
        <div className="value">= | != | IN | NOT IN | OR | AND</div>
      </li>
    </ul>
  </div>
);

export default {
  header: 'Quick Help',
  content: bpqlTooltopContent,
  link: {
    title: 'See Docs',
    url: 'https://docs.bigpanda.io/docs/bigpanda-query-language',
    external: true,
  },
  style: {
    height: '210px',
    width: '450px',
  },
};
