angular.module('bigpanda').directive('faq', faq);

function faq() {
  return {
    restrict: 'A',
    scope: {
      faq: '=',
      integration: '=',
    },
    templateUrl: 'integrations/integration_info/faq/faq',
    link: link,
  };

  function link(scope, element) {
    scope.answerHeight = 0;
    scope.element = element;

    scope.show = () => {
      scope.isShown = !scope.isShown;
      scope.answerHeight = scope.isShown
        ? scope.element.find('.faq-section .answer .answer-content').height() + 30
        : 0;
    };
  }
}
