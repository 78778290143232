import { AUTO_RESOLVE_TIME_OPTIONS } from '../../../../../../../app/angularjs/integrations/integrations.constants';
import bpFetch from '../../../../../../../app/common/utils/http/fetcher';
import { IBreakdownSources, ISourceInfo } from '../types';

export const isCorrelation = (type: string): boolean => type === 'correlation';

let integrations = null;

export const getToolTipText = async (breakdownSources:IBreakdownSources): Promise<ISourceInfo[]> => {
  const relevantSources = await getRelevantSources(breakdownSources.otherSystems, breakdownSources.lastSystem);
  return Array.isArray(relevantSources)
    ? relevantSources.map((source) => getInfoForSource(source))
    : [getInfoForSource(relevantSources)];
};

function getInfoForSource(source) : ISourceInfo {
    type ObjectKey = keyof typeof source;
    const name = `${source['name' as ObjectKey]}\n`;
    const sourceSystem = source['source_system' as ObjectKey];
    let autoResolveTime = AUTO_RESOLVE_TIME_OPTIONS[AUTO_RESOLVE_TIME_OPTIONS.length - 1];
    if (source['config' as ObjectKey] != null) {
      const sourceConfig = source['config' as ObjectKey];
      const autoResolveConfig = sourceConfig['auto_resolve' as ObjectKey];
      autoResolveTime = autoResolveConfig['resolve_after_inactivity_ms' as ObjectKey];
    }
    const autoResolveLabel = convertAutoResolveTimeToText(autoResolveTime)['label' as ObjectKey];
    const description = `Integration ID: ${sourceSystem}\nAuto-resolve: ${autoResolveLabel}`;
    return {
      title: name,
      description,
    } as ISourceInfo;
}

async function getRelevantSources(othersSystemsNames: string, lastSystemName: string){
  if (integrations === null) {
    integrations = await getIntegrations();
  }
  const lastSystem = integrations.find(({ name }) => name === lastSystemName);
  if (othersSystemsNames !== null){
    const othersSystemsNamesList = othersSystemsNames.split('.');
        type ObjectKey = keyof typeof othersSystemsNamesList;
        const otherSystems = integrations.filter((int) => othersSystemsNamesList.includes(int['name' as ObjectKey]));
        otherSystems.push(lastSystem);
        return otherSystems;
  }
  return lastSystem;
}

async function getIntegrations(){
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return bpFetch({ url: '/web/integrations' }).then((res) => res.data.item);
}

function convertAutoResolveTimeToText(time){
  return AUTO_RESOLVE_TIME_OPTIONS.find(({ value: timeValue }) => timeValue === time.toString());
}
