import bpFetch from 'common/utils/http/fetcher';

const rulesPath = '/web/organization/my/rules';

const getMany = () => bpFetch({ url: rulesPath }).then((res) => res.data.item);

const get = (id) => bpFetch({ url: `${rulesPath}/${id}` }).then((res) => res.data.item);

const update = ({ id, ...data }) =>
  bpFetch({
    url: `${rulesPath}/${id}`,
    method: 'PATCH',
    data,
  }).then((res) => res.data);

const create = (data) =>
  bpFetch({
    url: rulesPath,
    method: 'POST',
    data,
  }).then((res) => res.data);

const remove = (id) =>
  bpFetch({
    url: `${rulesPath}/${id}`,
    method: 'DELETE',
  }).then((res) => res.data);

export { getMany, get, update, create, remove };
