import map from 'lodash/map';
angular.module('bigpanda').directive('mergeButton', mergeButton);

function mergeButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '^^checkedIncidentsState',
    },
    scope: {
      showSmallButton: '<',
      checkCount: '<',
      search: '&',
      searchValue: '=',
    },
    templateUrl: 'overview/incidents/list/widgets/merge_button/merge_button',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(
    $scope,
    $q,
    $ngRedux,
    IncidentsService,
    IncidentObjectBuilderService,
    stateService,
    CorrelationConfigBackendService,
    EnvironmentsService
  ) {
    const vm = this;

    vm.$onInit = init;
    vm.store = $ngRedux;
    vm.envId = stateService.getSelectedEnvironmentId();
    vm.getIncidents = updateMergedProps;
    vm.getCorrelationConfig = CorrelationConfigBackendService.getCorrelationsConfig;
    vm.getEnvById = EnvironmentsService.getEnvById;

    function init() {
      getButtonType();
      $scope.$watch('vm.checkCount', getButtonType);
      $scope.$watch('vm.showSmallButton', getButtonType);

      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: () => {
          vm.incidentsIds = vm.checkedIncidentsState.getCheckedIncidentIds();
        },
      });
    }

    function updateMergedProps() {
      return getIncidents().then((incidents) => {
        const promises = incidents.map(async (incident) => {
          const incidentObj = {};
          incidentObj.breakdownSources = IncidentObjectBuilderService.breakdownSources(incident);
          incidentObj.incident = incident;
          incidentObj.titleData = IncidentObjectBuilderService.getTitle(incident);
          incidentObj.numberOfIncidentActiveAlerts = IncidentObjectBuilderService.getActiveEntitiesCount(
            incident
          );

          [
            incidentObj.timeProps,
            incidentObj.comments,
            incidentObj.shares,
            incidentObj.snoozed,
          ] = await Promise.all([
            IncidentObjectBuilderService.getTimeProps(incident, stateService.getSelectedFolderId()),
            IncidentObjectBuilderService.getComments(incident._id),
            IncidentObjectBuilderService.getShares(incident._id),
            IncidentObjectBuilderService.getSnoozeData(incident._id),
          ]);

          return incidentObj;
        });

        return Promise.all(promises).then(async (incidents) => {
          const incidentIds = vm.checkedIncidentsState.getCheckedIncidentIds();
          let assignees = await IncidentObjectBuilderService.getAssignments(incidentIds);
          const userIds = assignees.map(({ assignee }) => assignee);
          let users = await IncidentObjectBuilderService.getUsersByIds(userIds);
          users = users.reduce((prevAcc, user) => {
            const newAcc = prevAcc;
            newAcc[user._id] = user;

            return newAcc;
          }, {});

          assignees = assignees.reduce((prevAcc, assignee) => {
            const newAcc = prevAcc;

            newAcc[assignee.incident_id] = {
              ...assignee,
              email: users[assignee.assignee].username,
              name: users[assignee.assignee].name,
            };

            return newAcc;
          }, {});

          return incidents.map((incident) => ({
            ...incident,
            incident: {
              ...incident.incident,
              assignee: assignees[incident.incident._id],
            },
          }));
        });
      });
    }

    function getIncidents() {
      return $q.all(
        map(vm.checkedIncidentsState.getCheckedIncidentIds(), (incidentId) =>
          IncidentsService.getIncidentById(incidentId)
        )
      );
    }

    function getButtonType() {
      vm.buttonType = `${vm.showSmallButton ? 'no-text' : ''} ${
        vm.checkCount <= 1 ? 'disabled' : ''
      }`;
    }
  }
}
