angular.module('bigpanda').filter('entityDuration', entityDuration);

function entityDuration() {
  /**
   * Returns the entity's duration in seconds
   */
  return (entity) => {
    if (!entity) {
      return 0;
    }

    if (entity.is_active) {
      return moment().unix() - entity.start;
    }

    return (entity.end ? entity.end : entity.lastChangedTimestamp) - entity.start;
  };
}
