import { TimeFormats } from '@bp/bam';
import { EditIcon } from '@bp/kung-fu';
import React from 'react';
import { useSelector } from 'react-redux';

import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';
import UserActivity from '../../../../alert-enrichment/src/components/TagInfo/UserActivity';
import dictionary from '../../dictionary';
import { AlertFilteringActionButtons } from '../../filter-list/components/AlertFilteringActionButtons';
import { AlertFilterModal } from '../../modal/AlertFilterModal';
import selectors from '../../selectors';
import { AlertFilter } from '../../types/AlertFilter';
import { Permissions } from '../../types/Permissions';
import { FilterInfoContent } from './FilterInfoContent';
import FilterInfoHeader from './FilterInfoHeader';

const { momentOrEpochRelativeTimeFormat } = TimeFormats;

interface IProps {
  filter: AlertFilter;
}

export function FilterInfo({ filter }: IProps): JSX.Element {
  const actionPermissions: Permissions = useSelector(selectors.getActionsPermissions);

  return (
    <SettingsInfoScreen
      topActions={<AlertFilteringActionButtons filter={filter} />}
      header={<FilterInfoHeader filter={filter} />}
      content={<FilterInfoContent filter={filter} />}
      actions={
        actionPermissions.hasEditPermission && (
          <SettingsInfoScreen.Section>
            <AlertFilterModal
              triggerProps={{
                variant: 'action-color',
                size: 'large',
                text: dictionary.edit_alert_filter,
                icon: <EditIcon />,
              }}
              title={dictionary.edit_alert_filter}
              titleIcon="bp-icon-edit"
              filter={filter}
            />
          </SettingsInfoScreen.Section>
        )
      }
      activity={(
        <SettingsInfoScreen.Section>
          <UserActivity
            title={`${dictionary.created_at}:`}
            date={momentOrEpochRelativeTimeFormat(filter.created_on)}
          />
          {filter.updated_on && (
            <UserActivity
              title={`${dictionary.updated_at}:`}
              date={momentOrEpochRelativeTimeFormat(filter.updated_on)}
            />
          )}
        </SettingsInfoScreen.Section>
      )}
    />
  );
}
