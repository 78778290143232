import actionTypes from './actionTypes';

const initialState = {
  lastReceivedIncident: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ENVIRONMENT_WEBSOCKETS_MESSAGE:
      return {
        ...state,
        lastReceivedIncident: JSON.parse(action.payload.message),
      };

    default:
      return state;
  }
}
