import { path } from './constants';

const actionTypes = {};

actionTypes.CREATE_MERGE = `${path}.CREATE_MERGE`;
actionTypes.CREATE_SPLIT = `${path}.CREATE_SPLIT`;
actionTypes.CREATE_MERGE_SUCCESS = `${path}.CREATE_MERGE_SUCCESS`;
actionTypes.CREATE_SPLIT_SUCCESS = `${path}.CREATE_SPLIT_SUCCESS`;
actionTypes.OPEN_MODAL = `${path}.OPEN_MODAL`;
actionTypes.CLOSE_MODAL = `${path}.CLOSE_MODAL`;
actionTypes.SWITCH_DEST_INCIDENT = `${path}.SWITCH_DEST_INCIDENT`;
actionTypes.CREATE_ENTITIES_BATCH_RESOLVE = `${path}.CREATE_ENTITIES_BATCH_RESOLVE`;
actionTypes.CREATE_ENTITIES_BATCH_RESOLVE_SUCCESS = `${path}.CREATE_ENTITIES_BATCH_RESOLVE_SUCCESS`;

export default actionTypes;
