import debounce from 'lodash/debounce';
import find from 'lodash/find';
import countBy from 'lodash/countBy';
import first from 'lodash/first';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

angular.module('bigpanda').directive('timelinesContainer', timelineContainer);

function timelineContainer(STATUS_COLORS) {
  return {
    restrict: 'E',
    scope: {
      entities: '<',
      highlightRange: '<',
      status: '@',
      showSource: '=',
    },
    bindToController: true,
    templateUrl: 'timeline/container/timelines_container',
    controllerAs: 'timelinesContainerVm',
    controller: controller,
  };

  function controller(
    $scope,
    $filter,
    $element,
    $window,
    EntitiesListColumnsPicker,
    PersonalSettingsStore,
    MaintenancePlansService
  ) {
    const vm = this;
    vm.loading = true;
    vm.$postLink = postLink;
    vm.plans = [];
    vm.entitiesUnderMaintenance = [];

    function getMaintenancePlans(entityId) {
      const relevantEntity = vm.entitiesUnderMaintenance.find((eum) => eum._id === entityId);
      return relevantEntity
        ? vm.plans.filter((plan) => (relevantEntity.maintenance_plan_ids || []).includes(plan.id))
        : [];
    }

    $scope.$watch('timelinesContainerVm.entities', (entities) => {
      if (entities && entities.length) {
        PersonalSettingsStore.getUser().then((user) => {
          const entitiesPlanIds = Array.from(
            new Set([].concat(...vm.entities.map((e) => e.maintenance_plan_ids).filter((e) => !!e)))
          );
          if (entitiesPlanIds && entitiesPlanIds.length) {
            MaintenancePlansService.getMaintenancePlansByIds(entitiesPlanIds).then(
              (maintenancePlans) => {
                vm.plans = maintenancePlans;

                const organizationEntitySorter = user.organization.config.entity_tags_sorter;
                const entitiesListColumnsPicker = new EntitiesListColumnsPicker(
                  entities,
                  organizationEntitySorter
                );
                vm.orderedTagList = entitiesListColumnsPicker.getOrderedTagList();

                vm.entities.sort((a, b) => a.start - b.start);
                vm.startDate = new Date(first(vm.entities).start * 1000);

                if (vm.status === 'ok') {
                  let endDate = 0;

                  vm.entities.forEach((entity) => {
                    if (entity.last_status_change_at > endDate) {
                      endDate = entity.last_status_change_at;
                    }
                  });

                  vm.endDate = new Date(endDate * 1000);
                }

                vm.displayedEntities = vm.entities.map((entity) =>
                  $filter('entityNormalizer')(entity, vm.incident)
                );
                vm.entitiesUnderMaintenance = vm.displayedEntities.filter(
                  (entity) => entity.underMaintenance
                );

                vm.displayedEntities = vm.displayedEntities.map((entity) => {
                  const dupe = { ...entity };
                  dupe.links = null;
                  dupe.underMaintenance =
                    entity.maintenance_plan_ids && entity.maintenance_plan_ids.length;
                  if (dupe.underMaintenance) {
                    dupe.maintenanceTooltip = {
                      template: 'investigator/result/maintenance_tooltip/maintenance_tooltip',
                      getMaintenancePlans: getMaintenancePlans,
                      plans: getMaintenancePlans(dupe._id),
                    };
                    dupe.plans = getMaintenancePlans(dupe._id);
                  }

                  return dupe;
                });

                vm.lifecyclePoints = $filter('timelinePoints')(
                  vm.displayedEntities,
                  vm.orderedTagList
                );

                vm.range = getRange();
                vm.entitiesBreakdown = calcBreakdown(vm.displayedEntities);
                vm.loading = false;
              }
            );
          } else {
            const organizationEntitySorter = user.organization.config.entity_tags_sorter;
            const entitiesListColumnsPicker = new EntitiesListColumnsPicker(
              entities,
              organizationEntitySorter
            );
            vm.orderedTagList = entitiesListColumnsPicker.getOrderedTagList();

            vm.entities.sort((a, b) => a.start - b.start);
            vm.startDate = new Date(first(vm.entities).start * 1000);

            if (vm.status === 'ok') {
              let endDate = 0;

              vm.entities.forEach((entity) => {
                if (entity.last_status_change_at > endDate) {
                  endDate = entity.last_status_change_at;
                }
              });

              vm.endDate = new Date(endDate * 1000);
            }

            vm.displayedEntities = vm.entities.map((entity) =>
              $filter('entityNormalizer')(entity, vm.incident)
            );
            vm.entitiesUnderMaintenance = vm.displayedEntities.filter(
              (entity) => entity.underMaintenance
            );

            vm.lifecyclePoints = $filter('timelinePoints')(vm.displayedEntities, vm.orderedTagList);

            vm.range = getRange();
            vm.entitiesBreakdown = calcBreakdown(vm.displayedEntities);
            vm.loading = false;
          }
        });
      }
    });

    function getRange() {
      const first = moment.unix(vm.entities[0].start);
      const last = vm.endDate ? moment(vm.endDate) : moment();
      return [first.toDate(), last.toDate()];
    }

    function calcBreakdown(entities) {
      const breakdown = countBy(entities, (entity) => {
        const status = entity.status && entity.status.toLowerCase();
        const colorObject = find(STATUS_COLORS, (color) => color.values.indexOf(status) >= 0);
        return colorObject && colorObject.name;
      });

      const breakdownWithColors = [];
      let breakdownColors = filter(STATUS_COLORS, (c) => c.breakdown);
      breakdownColors = sortBy(breakdownColors, (c) => c.order);
      breakdownColors.forEach((color) => {
        breakdownWithColors.push({
          color: color.color,
          name: color.name,
          count: breakdown[color.name] || 0,
        });
      });

      return breakdownWithColors;
    }

    function postLink() {
      const debouncedResize = debounce(resize, 200);
      angular.element($window).bind('resize', debouncedResize);
      $scope.$on('$destroy', () => angular.element($window).unbind('resize', debouncedResize));

      resize();
    }

    function resize() {
      vm.width = $element.find('timeline-axis')[0].offsetWidth - 1;
    }
  }
}
