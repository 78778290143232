import { fetcher } from '@bp/data-access';

import { UserApiResponse } from '../components/types';
import { DEFAULT_FILTERS } from '../constants';
import { COUNT_PER_PAGE } from '../hooks/useAuditTable/constants';
import { AuditLog, AuditLogResponse } from '../hooks/useAuditTable/types';
import {
  AuditLogSearchURL, AuditLogURL, ResponseConfig, UsersURL,
} from './constants';

export { getAuditLogs, getUsers, searchObjectName };

async function getAuditLogs(pageNumber = 1, perPage = COUNT_PER_PAGE, filters = DEFAULT_FILTERS)
  : Promise<AuditLogResponse> {
  return fetcher({
    requestConfig: {
      url: `${AuditLogURL}?page=${pageNumber}`
          + `&user_id=${filters?.userId}`
          + `&per_page=${perPage}`
          + `&action_type=${filters?.actionType}`
          + `&resource_type=${filters?.resourceType}`
          + `&resource_id=${filters?.resourceId}`
          + `&start=${filters?.start}`
          + `&end=${filters?.end}`,
    },
    responseConfig: ResponseConfig,
  });
}

async function searchObjectName(changedObject = ''): Promise<AuditLog[]> {
  return fetcher({
    requestConfig: {
      url: `${AuditLogSearchURL}?changedObject=${changedObject}`,
    },
    responseConfig: ResponseConfig,
  });
}

async function getUsers(debounceSearch = ''): Promise<UserApiResponse> {
  return fetcher({
    requestConfig: {
      url: `${UsersURL}?${debounceSearch === '' ? '' : `&query=${debounceSearch}`}`,
    },
    responseConfig: ResponseConfig,
  });
}
