import { combineEpics } from 'redux-observable';
import * as configurationLabelDefinitions from './configuration_label_definitions';
import * as contacts from './contacts';
import * as enrichedUsers from './enriched_users';
import * as users from './users';
import * as correlationConfig from './correlation_config';
import * as integrations from './integrations';
import * as alertTags from './alert_tags';

export default combineEpics(
  configurationLabelDefinitions.epic,
  contacts.epic,
  enrichedUsers.epic,
  users.epic,
  correlationConfig.epic,
  integrations.epic,
  alertTags.epic
);
