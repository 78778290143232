import has from 'lodash/has';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import keys from 'lodash/keys';
import reverse from 'lodash/reverse';
angular
  .module('bigpanda')
  .controller('DashboardEditorPopupController', DashboardEditorPopupController);

function DashboardEditorPopupController(
  $timeout,
  $state,
  $q,
  DashboardsSettingsConfig,
  DashboardsStore,
  EnvironmentsService,
  CustomizationsStore,
  deepCloneObject
) {
  const vm = this;

  vm.closePopup = closePopup;
  vm.envToggle = envToggle;
  vm.submit = submit;
  vm.selectAllEnvs = selectAllEnvs;
  vm.deselectAllEnvs = deselectAllEnvs;
  vm.multiSelectToggle = multiSelectToggle;
  vm.selectFavoriteEnvs = selectFavoriteEnvs;
  vm.dismissGlobalError = dismissGlobalError;

  const DUPLICATE = 'duplicate';
  const NEW = 'new';
  const EDIT = 'edit';

  const statuses = {
    [DUPLICATE]: {
      stateData: {
        iconClass: 'bp-icon-duplicate',
        title: 'Duplicate Dashboard',
        actionText: 'Create Dashboard',
      },
    },
    [NEW]: {
      stateData: {
        iconClass: 'bp-icon-plus-icon',
        title: 'Create a New Dashboard',
        actionText: 'Create Dashboard',
      },
    },
    [EDIT]: {
      stateData: {
        iconClass: 'bp-icon-edit',
        title: 'Edit Dashboard',
        actionText: 'Update Dashboard',
      },
    },
  };

  init();

  function init() {
    if (!vm.status) {
      vm.status = EDIT;
    }

    initStateData();

    vm.sortOptions = angular.copy(DashboardsSettingsConfig.sortOptions);
    vm.timeframeOptions = reverse(angular.copy(DashboardsSettingsConfig.timeframeOptions));
    vm.widgetTypes = DashboardsSettingsConfig.widgetTypes;

    getInitialDashboardState().then((dashboard) => {
      if (vm.status === DUPLICATE) {
        vm.dashboard = deepCloneObject.cloneDeep(dashboard);
        delete vm.dashboard._id;
      } else {
        vm.dashboard = dashboard;
      }

      vm.selectedEnvs = idsListToObject(dashboard.selected_environments);

      updateEnvironmentSelection();
    });
    EnvironmentsService.get().then((environments) => {
      vm.environments = environments;
    });

    fetchFavoriteEnvs();
  }

  function getInitialDashboardState() {
    if (vm.status !== NEW) {
      return DashboardsStore.getDashboardById(vm.dashboardId);
    }

    return $q.when({
      sort_by: vm.sortOptions[0].value,
      time_frame: vm.timeframeOptions[0].value,
      dashboard_type: vm.widgetTypes.severity,
    });
  }

  function closePopup(dashboardId) {
    if (dashboardId) {
      $state.go('app.dashboards.display', { dashboardId: dashboardId });
    } else {
      $state.go('^');
    }
  }

  function envToggle() {
    $timeout(() => {
      updateEnvironmentSelection();
    });
  }

  function updateEnvironmentSelection() {
    vm.dashboard.selected_environments = keys(pickBy(vm.selectedEnvs));

    vm.hasEnvSelection = !isEmpty(vm.dashboard.selected_environments);
  }

  function submit() {
    if (vm.form.$invalid) {
      if (vm.form.hasEnvSelection.$invalid) {
        vm.noEnvsError = true;
      }
      return;
    }

    const savePromise =
      vm.status === EDIT
        ? DashboardsStore.updateDashboard(vm.dashboard._id, vm.dashboard)
        : DashboardsStore.createDashboard(vm.dashboard);

    savePromise.then((dashboard) => vm.closePopup(dashboard._id));
    // TODO handle errors
  }

  function selectAllEnvs() {
    vm.selectedEnvs = fromPairs(map(vm.environments, (env) => [env._id, true]));
    updateEnvironmentSelection();
  }

  function deselectAllEnvs() {
    vm.selectedEnvs = {};
    updateEnvironmentSelection();
  }

  function multiSelectToggle(event) {
    if (vm.dashboard.selected_environments.length === 0) {
      selectAllEnvs();
    } else {
      deselectAllEnvs();
    }
    event.stopPropagation();
    event.preventDefault();
  }

  function fetchFavoriteEnvs() {
    CustomizationsStore.getCustomizations().then((customizations) => {
      if (has(customizations, 'environments.navigation_list.favorites')) {
        const favs = customizations.environments.navigation_list.favorites;
        vm.favoriteEnvs = idsListToObject(favs);
      }
    });
  }

  function selectFavoriteEnvs() {
    vm.selectedEnvs = angular.copy(vm.favoriteEnvs);
    updateEnvironmentSelection();
  }

  function idsListToObject(ids) {
    return fromPairs(map(ids, (id) => [id, true]));
  }

  function initStateData() {
    vm.stateData = statuses[vm.status].stateData;
  }

  function dismissGlobalError() {
    vm.noEnvsError = false;
  }
}
