import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Button, darkTheme } from '@bp/kung-fu';
import { BamForm } from '@bp/bam';
import { Field } from 'react-final-form';
import SSOFormInput from './SSOFormInput';
import validate from '../../../validations';
import styles from './sso_form.scss';

const defaultFieldsTitles = {
  issuerID: 'Issuer ID',
  SAML2Endpoint: 'SAML 2.0 Endpoint (HTTP)',
  certificate: 'X.509 Certificate',
};

const SSOForm = ({
  onSubmit,
  providerName,
  id,
  renderAdditionalFields,
  customFieldsTitles = defaultFieldsTitles,
}) => {
  const handleSubmit = (values) => {
    const validationMessages = validate(values);
    if (validationMessages) {
      return validationMessages;
    }

    onSubmit(values);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={styles.ssoForm}>
        <BamForm scrollable={false} onSubmit={handleSubmit} id={id}>
          <Field
            name="IssuerIDInput"
            title={customFieldsTitles.issuerID}
            subscription={{ value: true, submitError: true }}
            component={SSOFormInput}
          />
          <Field
            name="EndPointInput"
            title={customFieldsTitles.SAML2Endpoint}
            subscription={{ value: true, submitError: true }}
            component={SSOFormInput}
          />
          <Field
            name="CertificateInput"
            title={customFieldsTitles.certificate}
            subscription={{ value: true, submitError: true }}
            component={SSOFormInput}
          />
          {!!renderAdditionalFields && renderAdditionalFields()}
          <Button
            type="submit"
            variant="primary"
            size="large"
          >{`Configure ${providerName}`}</Button>
        </BamForm>
      </div>
    </ThemeProvider>
  );
};

SSOForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  providerName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  renderAdditionalFields: PropTypes.func,
  customFieldsTitles: PropTypes.shape({
    issuerID: PropTypes.string,
    SAML2Endpoint: PropTypes.string,
    certificate: PropTypes.string,
  }).isRequired,
};

SSOForm.defaultProps = {
  renderAdditionalFields: null,
};

export default SSOForm;
