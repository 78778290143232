import { SCHBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const FilterContainerWrapper = styled(SCHBox)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 18px;
  gap: 8px;
  line-height: 1px;
  height: 25px;

  @media only screen and (min-width: 1980px) {
    width: 90%;
  }
`;
