import {
  AllSizes, CodeSizes, InfoIcon, SCHBox, Text,
} from '@bp/kung-fu';
import styled from 'styled-components';

export const StyledHBox = styled(SCHBox)`
    margin-inline-start: 10px;
`;

export const LineSeparator = styled.div`
    border-top: 1px solid ${(p) => p.theme.bp_gray_03};
    margin: 0 -11px 0px;
`;

export const BasicTitle = styled(Text).attrs({ size: AllSizes.SMALL })`
    color: ${(p) => p.theme.bp_gray_06};
`;

export const Title = styled(BasicTitle)`
    width: 100px;
    line-height: 1.8;
`;

export const Value = styled(Text).attrs({ size: AllSizes.SMALL })`
    color: ${(p) => p.theme.bp_gray_07};
    max-width: 505px;
    line-height: 1.8;
`;

export const MiddleValue = styled(Value)`
    margin: 0 4px;
`;

export const Icon = styled(InfoIcon).attrs({
  size: AllSizes.LARGE,
  color: (p) => p.theme.bp_gray_06,
})`
    margin-inline-end: 6px;
`;

export const ConditionText = styled(Value).attrs({ size: CodeSizes.SMALL_CODE })`
    color: ${(p) => (p.hasCondition ? p.theme.bp_gray_07 : p.theme.bp_gray_05)};
`;
