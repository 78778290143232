import { BamEmptyMessage } from '@bp/bam';
import React from 'react';
import { useSelector } from 'react-redux';

import selectors from '../../../../../../app/react/modules/settings/alert_enrichment/selectors';
import provideStore from '../../../../../../app/react/utils/provideStore';
import TagInfo from './TagInfo';

interface IProps {
  tagName: string;
}

export function TagDetailsPane({ tagName }: IProps): JSX.Element {
  const tag = useSelector((state) => selectors.getTag(state, tagName));

  return tag ? (
    <TagInfo tag={tag} />
  ) : (
    <BamEmptyMessage header="NO TAG SELECTED">Select a tag to see details</BamEmptyMessage>
  );
}

export default provideStore(TagDetailsPane);
