angular.module('bigpanda').controller('TopologyController', TopologyController);

function TopologyController($scope, $ngRedux, $stateParams, IncidentsService, $location) {
  const vm = this;
  const intercomWidget = document.querySelector('.intercom-lightweight-app');
  vm.store = $ngRedux;
  vm.incident = null;
  vm.isError = false;
  vm.iframeMode = $location.$$path === '/app/topology';

  loadIncident();
  $scope.updatePage('topology');

  if (intercomWidget) {
    intercomWidget.style.display = vm.iframeMode ? 'none' : 'initial';
  }

  async function loadIncident() {
    const { incidentId } = $stateParams;
    if (incidentId) {
      try {
        const incidentData = await IncidentsService.getIncidentById(incidentId);
        const normalizedIncident = {
          ...incidentData,
          entities: incidentData.entities.map((entity) => ({
            ...entity,
            status: entity.status.toLowerCase(),
          })),
        };
        vm.incident = normalizedIncident;
      } catch (err) {
        vm.isError = true;
      }
    } else {
      vm.isError = true;
    }
  }
}
