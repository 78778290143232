angular.module('bigpanda').directive('staticWelcome', staticWelcome);

function staticWelcome() {
  return {
    restrict: 'E',
    replace: true,
    controllerAs: 'staticWelcomeCtrl',
    bindToController: true,
    scope: {
      header: '@',
      text: '@',
      imgSrc: '@',
    },
    templateUrl: 'integrations/welcome_sequence/static_welcome_sequence',
    controller: () => {},
  };
}
