import React from 'react';
import PropTypes from 'prop-types';
import { webApiBaseUrl } from 'common/config';
import LogoutButton from './LogoutButton';
import {
  HorizontalLayout,
  Content,
  Note,
  Title,
  Description,
  Steps,
  Step,
  Badge,
  CodeSpan,
  SSOForm,
} from '../../components';
import { getRandomId } from './helpers';

const formId = getRandomId();
const customFieldsTitles = {
  issuerID: 'Identifier (Entity ID)',
  SAML2Endpoint: 'Login URL',
  certificate: 'SAML Certificate',
};
const AzureInstructions = ({
  configureProvider,
  isConfigurationSent,
  organization,
  updateAndLogout,
}) => (
  <div>
    <HorizontalLayout>
      <Badge>2</Badge>
      <Content>
        <Title>Add the BigPanda App to Microsoft Entra ID (Azure AD)</Title>
        <Steps>
          <Step>
            Log in to your Azure portal and navigate to the <strong>Microsoft Entra ID</strong>{' '}
            service.
          </Step>
          <Step>
            Go to the <strong>EnterPrise Applications</strong> item in the menu and click{' '}
            <strong>New Application</strong>.
          </Step>
          <Step>
            Search for the <strong>BigPanda</strong> app, after selecting it click the{' '}
            <strong>Create</strong> button.
          </Step>
          <Step>
            Click <strong>Set up single sign on</strong> and then select <strong>SAML.</strong>
          </Step>
          <Step>
            Go to the <strong>Basic SAML Configuration</strong> section and select{' '}
            <strong>Edit</strong>.
          </Step>
          <Step>
            In the <strong>Reply URL</strong> bar, paste
            <CodeSpan
              canBeCopied
            >{`${webApiBaseUrl}/login/${organization}/azure/callback`}</CodeSpan>
          </Step>
          <Step>
            Click <strong>Save</strong> and exit to the main SAML page.
          </Step>
          <Step>
            Go to <strong>SAML certificates</strong> and download the{' '}
            <strong>Federation Metadata XML</strong> file.
          </Step>
        </Steps>
      </Content>
    </HorizontalLayout>
    <HorizontalLayout>
      <Badge>3</Badge>
      <Content>
        <Title>Configure Microsoft Entra ID Information in BigPanda</Title>
        <Steps>
          <Step>
            Paste <CodeSpan canBeCopied>https://bigpanda.io/SAML2</CodeSpan> into the{' '}
            <strong>Identifier (Entity ID)</strong> field.
          </Step>
          <Step>
            Copy/paste the <strong>Login URL</strong> from Azure AD (in the Set up Big Panda
            section) into the <strong>Login URL</strong> field.
          </Step>
          <Step>
            {`From the downloaded metadata file, copy the string between the <X509certificate>`}{' '}
            markers and paste into the <strong>SAML Certificate</strong> field without the BEGIN
            CERTIFICATE and END CERTIFICATE lines, and without whitespaces.
          </Step>
        </Steps>
        <SSOForm
          providerName="Azure"
          onSubmit={configureProvider}
          id={formId}
          customFieldsTitles={customFieldsTitles}
        />
      </Content>
    </HorizontalLayout>
    <HorizontalLayout>
      <Badge>4</Badge>
      <Content>
        <Title>Validate and Enable the Integration</Title>
        <Description>
          Before enabling the Azure AD integration for your entire organization, test it with your
          own account.
        </Description>
        <Steps>
          <Step>
            Click <strong>Logout and Validate.</strong>
          </Step>
          <Step>
            In Azure AD, ensure the test user has been assigned to BigPanda in the{' '}
            <strong>User and Groups</strong> section.
          </Step>
          <Step>
            Log in to BigPanda with Azure AD SSO. Be sure to leave the BigPanda password field
            blank.git{' '}
          </Step>
          <Step>
            If your login was successful, return to this page and click{' '}
            <strong>Activate SSO</strong> to enable SSO for everybody in your organization.
          </Step>
        </Steps>
        <Note>
          Note: Until you enable the integration for the entire organization, users (including you)
          can log in to BigPanda with their original BigPanda passwords. Once enabled, they will be
          required to use Azure AD&apos;s authentication.
        </Note>
      </Content>
    </HorizontalLayout>

    <LogoutButton updateAndLogout={updateAndLogout} disabled={!isConfigurationSent} />
  </div>
);

AzureInstructions.propTypes = {
  configureProvider: PropTypes.func.isRequired,
  updateAndLogout: PropTypes.func.isRequired,
  isConfigurationSent: PropTypes.bool.isRequired,
  organization: PropTypes.string.isRequired,
};

export default AzureInstructions;
