import { combineEpics } from 'redux-observable';
import fetchCorrelationLabelDefinitions from 'common/endpoints/configuration-label-definitions';
import actionTypes from './actionTypes';
import * as actions from './actions';

const loadConfigurationLabels = ($action) =>
  $action.ofType(actionTypes.LOAD_CONFIGURATION_LABEL_DEFINITIONS).mergeMap(async () => {
    const labelDefinitions = await fetchCorrelationLabelDefinitions();
    return actions.loadLabelDefinitionsSuccess(labelDefinitions);
  });

export default combineEpics(loadConfigurationLabels);
