import { BamTile } from '@bp/bam';
import React from 'react';

import BpTimeSince from '../../../../../../app/common/utils/BpTimeSince';
import AlertEnrichmentActionButtons from '../../../../../../app/react/modules/settings/alert_enrichment/action_buttons/AlertEnrichmentActionButtons';
import { CREATED_SORT_MODE } from '../../../../../../app/react/modules/settings/alert_enrichment/alert_enrichment_list/constants';
import { alertTagUrl } from '../../../../../../app/react/modules/settings/alert_enrichment/constants';
import { multiTypeEnrichmentTypes } from '../../../../../../app/react/modules/settings/alert_enrichment/utils';
import { SortMode } from '../../../../alert-filtering/src/types/SortMode';
import { EnrichmentRule, Tag } from '../../../types';

type TextPart = {
  label?: string;
  value: string | number;
};

type TileRow = {
  title?: string;
  textParts: TextPart[];
  actionsBundle?: JSX.Element;
  time?: {
    title: string;
    timestamp: string;
  }
};

const generateFirstRow = (tag: Tag, sortMode: SortMode = CREATED_SORT_MODE): TileRow => ({
  title: tag.name,
  textParts: [{ value: tag.type }],
  time:
    sortMode === CREATED_SORT_MODE
      ? { title: 'Created', timestamp: BpTimeSince(tag.created_at) }
      : { title: 'Updated', timestamp: BpTimeSince(tag.updated_at) },
});

const itemsLabelByType = (type: string, enrichments: EnrichmentRule[]): string | number => (type === 'Multi Type'
  ? `${enrichments.length} (${multiTypeEnrichmentTypes(enrichments)})`
  : enrichments.length);

const generateSecondRow = (tag: Tag): TileRow => {
  const { enrichments = [], order, type } = tag;
  const textParts = [
    { label: 'Exec. Order', value: order },
    { label: 'Items', value: itemsLabelByType(type, enrichments) },
  ];

  return {
    textParts,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    actionsBundle: <AlertEnrichmentActionButtons tag={tag} />,
  };
};

interface IProps {
  tag: Tag;
  sortMode: SortMode;
}

function ListItem({ tag, sortMode, ...props }: IProps): JSX.Element {
  const handleClick = (): void => {
    window.location.href = alertTagUrl(tag.name);
  };

  return (
    <div role="listitem" aria-label={`${tag.name} tag`}>
      <BamTile
        bamStatus={tag.active ? 'ok' : 'off'}
        firstRow={generateFirstRow(tag, sortMode)}
        secondRow={generateSecondRow(tag)}
        onClick={handleClick}
        inactive={!tag.active}
        {...props}
      />
    </div>
  );
}

export default ListItem;
