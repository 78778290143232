import get from 'lodash/get';
import React from 'react';
import { Tooltip } from '@bp/kung-fu';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { selectors as permissionsSelectors, checkGranularPermission } from 'react/user/permissions';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import actions from '../actions';
import { path } from '../constants';
import { incidentTagTypes } from '../../settings/incident_labels/constants';
import { IncidentTagsGridTextCell } from './components';
import styles from './IncidentTagsGridCell.scss';
import IncidentTagsGridMultiValueCell from './components/IncidentTagsGridMultiValueCell';
import { isInitialValueDifferent } from '../utils';
import IncidentTagsUpdateModal from './components/IncidentTagsUpdateModal';

export const constOffset = 5;

class IncidentTagsGridCell extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { initialValue: nextValue, tagDefinition } = nextProps;
    const { currValue, initialValueState } = prevState;
    if (
      currValue === null ||
      isInitialValueDifferent(tagDefinition, nextValue.tag_value, initialValueState)
    ) {
      return {
        currValue: nextValue.tag_value,
        initialValueState: nextValue.tag_value,
        error: null,
      };
    }
    return null;
  }

  state = {
    currValue: null,
    error: null,
    isTooltipEnabled: false,
    // eslint-disable-next-line react/no-unused-state
    initialValueState: null,
    allowTagsManualInput:
      this.props.featureToggles && this.props.featureToggles.allow_tags_manual_input,
  };

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isTooltipEnabled: this.isTagNameEllipsisActive(this.cellRef) });
  }

  onValueChange = (currValue) => {
    this.setState({ currValue });
  };

  onValidationChange = (error) => {
    this.setState({ error });
  };

  cellRef = React.createRef();

  openEditor = () => {
    const { tagDefinition, toggleEditor, tagsData, hasEditPermission } = this.props;
    if (hasEditPermission && !tagsData.isLoading) {
      toggleEditor(tagDefinition.id);
    }
  };

  closeEditor = () => {
    const { toggleEditor } = this.props;
    toggleEditor(null);
    this.setState({ currValue: null });
  };

  submit = (submittedValue = null) => {
    const {
      environmentId,
      incident,
      tagDefinition,
      hasEditPermission,
      setTagValueForIncident,
      removeTagValueFromIncident,
    } = this.props;
    const { currValue, error } = this.state;
    if (hasEditPermission && !error) {
      const newTagValue = submittedValue || currValue;
      if (newTagValue && newTagValue.length) {
        setTagValueForIncident({
          environmentId,
          incidentId: incident.id,
          tagId: tagDefinition.id,
          tagValue: newTagValue,
        });
      } else {
        removeTagValueFromIncident({
          environmentId,
          incidentId: incident.id,
          tagId: tagDefinition.id,
        });
      }
    }
  };

  isEllipsisValueNeeded = (scroll, offset) => {
    const { handleIsValueEllipsis, id, editMode, truncate } = this.props;
    if (!truncate) return;
    if (!editMode) {
      handleIsValueEllipsis({ [id]: scroll > offset + constOffset });
    } else {
      handleIsValueEllipsis({ [id]: false });
    }
  };

  isTagNameEllipsisActive = (e) => e.current.clientWidth < e.current.scrollWidth;

  renderCell = () => {
    const {
      store,
      tagDefinition,
      initialValue,
      editMode,
      truncate,
      hasEditPermission,
      id,
      gridWidth,
    } = this.props;
    const { currValue, error, allowTagsManualInput } = this.state;
    const useOldInput = !allowTagsManualInput;
    switch (tagDefinition.type) {
      case incidentTagTypes.TEXT:
        return (
          <IncidentTagsGridTextCell
            store={store}
            currValue={currValue}
            error={error}
            initialValue={initialValue}
            editMode={editMode}
            truncate={truncate}
            hasEditPermission={hasEditPermission}
            onValueChange={this.onValueChange}
            onValidationChange={this.onValidationChange}
            onSubmit={this.submit}
            openEditor={useOldInput && this.openEditor}
            closeEditor={this.closeEditor}
            handleIsValueEllipsis={this.isEllipsisValueNeeded}
            gridWidth={gridWidth}
          />
        );
      case incidentTagTypes.MULTI_VALUE:
        return (
          <IncidentTagsGridMultiValueCell
            store={store}
            currValue={currValue}
            error={error}
            initialValue={initialValue}
            editMode={editMode}
            truncate={truncate}
            hasEditPermission={hasEditPermission}
            onValueChange={this.onValueChange}
            onValidationChange={this.onValidationChange}
            onSubmit={this.submit}
            openEditor={useOldInput && this.openEditor}
            closeEditor={this.closeEditor}
            handleIsValueEllipsis={this.isEllipsisValueNeeded}
            gridWidth={gridWidth}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      tagDefinition,
      editMode,
      hasEditPermission,
      tagWidth,
      borderTagClass,
      isVisible,
      truncate,
    } = this.props;
    const { error, isTooltipEnabled, allowTagsManualInput, currValue } = this.state;
    const { name } = tagDefinition;
    const isMultiValue = tagDefinition.type === incidentTagTypes.MULTI_VALUE;
    const valueClasses = cn({
      [styles.tag__value]: true,
      [styles.tag__value_multivalue]: isMultiValue,
      [styles.tag__value_multivalue_expand]: isMultiValue && !truncate,
      [styles['tag__value-readOnly']]: !hasEditPermission,
      [styles['tag__value-editMode']]: editMode,
      [styles['tag__value-noBorder']]: allowTagsManualInput,
    });
    const tagClasses = cn(styles.tag, borderTagClass, {
      [styles['tag--isHidden']]: !isVisible,
    });
    const tagNameClasses = cn(styles.tag__name, {
      [styles.tag__name_ellipsis]: truncate,
    });
    return (
      <div className={tagClasses} style={{ width: tagWidth }}>
        <Tooltip maxWidth="300px" placement="bottom-start" text={name} isActive={isTooltipEnabled}>
          <div className={tagNameClasses} ref={this.cellRef}>
            {name}:
          </div>
        </Tooltip>

        <div className={valueClasses}>
          {this.renderCell()}
          {editMode && error && <div className={styles.error}>{error}</div>}
        </div>
        <div
          className={cn({
            [styles.canEdit]: tagDefinition.canManualInput,
            [styles.canNotEdit]: !tagDefinition.canManualInput,
            bam: true,
          })}
        >
          {allowTagsManualInput && (
            <IncidentTagsUpdateModal
              tagDefinition={tagDefinition}
              onValueChange={this.onValueChange}
              onSubmit={this.submit}
              cellValue={currValue}
              error={error}
              onValidationChange={this.onValidationChange}
            />
          )}
        </div>
      </div>
    );
  }
}

IncidentTagsGridCell.propTypes = {
  store: PropTypes.object.isRequired, //eslint-disable-line
  environmentId: PropTypes.string.isRequired,
  incident: PropTypes.shape({ id: PropTypes.string }).isRequired,
  tagDefinition: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    canManualInput: PropTypes.bool,
  }).isRequired,
  initialValue: PropTypes.shape({ tag_value: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
  editMode: PropTypes.bool.isRequired,
  truncate: PropTypes.bool.isRequired,
  toggleEditor: PropTypes.func.isRequired,
  setTagValueForIncident: PropTypes.func.isRequired,
  removeTagValueFromIncident: PropTypes.func.isRequired,
  tagsData: PropTypes.object.isRequired, //eslint-disable-line
  hasEditPermission: PropTypes.bool.isRequired,
  tagWidth: PropTypes.string.isRequired,
  gridWidth: PropTypes.string.isRequired,
  borderTagClass: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  handleIsValueEllipsis: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  featureToggles: PropTypes.shape({ allow_tags_manual_input: PropTypes.bool }).isRequired,
};

IncidentTagsGridCell.defaultProps = {
  initialValue: { tag_value: undefined },
};

const mapStateToProps = (state, ownProps) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  const tagsData = get(state, path);
  const userPermissions = permissionsSelectors.getPermissionsList(state);
  const granularPermissions = permissionsSelectors.getGranularPermissions(state);
  const featureToggles = featureTogglesSelectors.getFeatureToggles(state);
  const hasEditPermission = checkGranularPermission(
    userPermissions,
    featureToggles,
    granularPermissions,
    'action@update',
    'environments',
    ownProps.environmentId
  );
  return {
    organization,
    user,
    tagsData,
    hasEditPermission,
    featureToggles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTagValueForIncident: (environmentId, incidentId, tagId, tagValue) =>
    dispatch(actions.setTagValueForIncident(environmentId, incidentId, tagId, tagValue)),
  removeTagValueFromIncident: (environmentId, incidentId, tagId) =>
    dispatch(actions.removeTagValueFromIncident(environmentId, incidentId, tagId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(IncidentTagsGridCell));
