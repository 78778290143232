import { CodeSizes, HBox, VBox } from '@bp/kung-fu';
import React from 'react';

import { InputWithHelpTooltip, InputWithHelpTooltipProps } from '../InputWithHelpTooltip';
import { StyledText } from '../InputWithHelpTooltip/style';

export const HELP_TEXT = 'Combining together any number of existing tag values and/or'
    + 'additional information. Make sure the referenced tag execution order is before this tag.';

export function CompositionTemplateInput(props: InputWithHelpTooltipProps): JSX.Element {
  return (
    <InputWithHelpTooltip
      {...props}
      helpTitle="Composition Template"
      helpText={HELP_TEXT}
      docsLink="https://docs.bigpanda.io/docs/alert-enrichment#composition-tag"
      helpExample={(
        <VBox>
          <HBox marginBottom="8px">
            <StyledText lineHeight="1.5" size={CodeSizes.SMALL_CODE}>
              {/* eslint-disable-next-line no-template-curly-in-string */}
              {'Using tag value as a variable: ${<tag_name>}'}
            </StyledText>
          </HBox>
          <HBox marginBottom="8px">
            <StyledText lineHeight="1.5" size={CodeSizes.SMALL_CODE}>
              {/* eslint-disable-next-line no-template-curly-in-string */}
              {'e.g. https://mywiki.com/${host}/${check}'}
            </StyledText>
          </HBox>
        </VBox>
              )}
    />
  );
}

export default CompositionTemplateInput;
