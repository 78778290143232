import bpFetch from 'common/utils/http/fetcher';

const enrichmentsConfig = '/resources/v2.0/enrichments-config';
const tagsPath = `${enrichmentsConfig}/tags`;
const tagsPostPath = '/resources/v2.0/alert-enrichments';

const webApiTagsPath = '/web/alert-enrichment/tags';
const webApiRulesPath = '/web/alert-enrichment/rules';

const getTags = () => bpFetch({ url: webApiTagsPath }).then(({ data: { item } }) => item);

const updateRulesOrder = ({ tagName, useTagOps, ...data }) => {
  let url = `${tagsPath}/${tagName}/order`;

  if (useTagOps) {
    url = url.replace('v2.0', 'v2.1');
  }

  return bpFetch({ method: 'PUT', url, data }).then(({ data }) => data.data);
};

const updateTagsOrder = ({ useTagOps, ...data }) =>
  bpFetch({
    method: 'PUT',
    url: useTagOps ? enrichmentsConfig.replace('v2.0', 'v2.1') : enrichmentsConfig,
    data,
  }).then(({ data }) => data.data);

const getPreview = ({ data, params = {} }) =>
  bpFetch({
    method: 'POST',
    url: `${tagsPostPath}/preview`,
    data,
    params,
  }).then(({ data }) => data.data);

const postRules = (data) =>
  bpFetch({ method: 'POST', url: webApiRulesPath, data }).then(({ data: { item } }) => item);
const updateRules = (data) =>
  bpFetch({ method: 'PATCH', url: webApiRulesPath, data }).then(({ data }) => data);

const deleteRules = (data) =>
  bpFetch({ method: 'DELETE', url: webApiRulesPath, data }).then(({ data }) => data);

/* Tag Operations */
const postTag = ({ metadata: { tagName, ...metadata }, ...data }) =>
  bpFetch({
    method: 'POST',
    url: webApiTagsPath,
    data: { ...data, ...metadata, name: tagName },
  }).then(({ data }) => data);

const deleteTag = ({ tagName }) =>
  bpFetch({ method: 'DELETE', url: `${webApiTagsPath}/${tagName}` });

const patchTagMetadata = ({ metadata: { tagName, ...metadata } }) =>
  bpFetch({ method: 'PATCH', url: `${webApiTagsPath}/${tagName}`, data: metadata }).then(
    ({ data }) => data
  );

const postTagRules = ({ metadata: { tagName }, ...data }) =>
  bpFetch({ method: 'POST', url: `${webApiTagsPath}/${tagName}/enrichments`, data }).then(
    ({ data: { item } }) => item
  );

const patchTagRules = ({ tagName, ...data }) =>
  bpFetch({
    method: 'PATCH',
    url: `${webApiTagsPath}/${tagName}${
      data.enrichments && data.enrichments.length ? '/enrichments' : ''
    }`,
    data,
  }).then(({ data }) => data);

const deleteTagRules = ({ metadata: { tagName }, ...data }) =>
  bpFetch({ method: 'DELETE', url: `${webApiTagsPath}/${tagName}/enrichments`, data }).then(
    ({ data }) => data
  );

const getReservedTags = () =>
  bpFetch({ method: 'GET', url: `${webApiTagsPath}/reserved-tags` }).then(
    ({ data: { item } }) => item
  );

export {
  getTags,
  updateRulesOrder,
  updateTagsOrder,
  getPreview,
  postRules,
  deleteRules,
  updateRules,
  postTag,
  deleteTag,
  patchTagMetadata,
  postTagRules,
  patchTagRules,
  deleteTagRules,
  getReservedTags,
};
