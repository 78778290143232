import { BulletTooltip, Text } from '@bp/kung-fu';
import React from 'react';

import { ITitleData } from '../../types';
import { TooltipKeyText } from './HeaderTooltip.style';

const Line = ({ name, value }: { name: string; value: string }): JSX.Element => (
  <Text>
    <TooltipKeyText>{`${name.trim()}:`}</TooltipKeyText>
    <Text weight="bold">{value}</Text>
  </Text>
);

const TitleTooltip = ({ title }: ITitleData): JSX.Element => (Array.isArray(title) ? (
  <BulletTooltip
    list={title.map((item) => (
      <Line key={item.value.str} name={item.key.str} value={item.value.str} />
    ))}
    title={title.length > 1 ? 'correlations' : 'correlation'}
  />
) : (
  <div>
    {title.value.length === 1 ? (
      <Line name={title.key} value={title.value[0]} />
    ) : (
      <BulletTooltip
        list={title.value.map((item) => (
          <Text key={item}>{item}</Text>
        ))}
        title={title.key}
      />
    )}
  </div>
));

export default TitleTooltip;
