import {
  ISelectOption, useBoolean, useDebounce,
} from '@bp/kung-fu';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { searchObjectName } from '../../api/endpoints';
import { QueryKeys } from '../../constants';
import selectors from '../../store/selectors';
import { Environment } from '../useAuditTable/types';
import { auditResponseMapper } from '../useAuditTable/utils';
import { UseObjectNameFilter } from './types';
import { mapChangeObjectDefaults } from './utils';

const useObjectNameFilter = (): UseObjectNameFilter => {
  const envs: Environment[] = useSelector(selectors.environments);

  const [isLoading, setIsLoading] = useBoolean(true);
  const [options, setOptions] = useState<ISelectOption[]>();
  const [search, setSearch] = useState<string>('');

  const debounceSearch = useDebounce(search, 500);

  useQuery([QueryKeys.AuditChangedObjects,
    { debounceSearch }], () => searchObjectName(debounceSearch), {
    onSuccess: (result) => {
      setOptions(mapChangeObjectDefaults(auditResponseMapper(result, envs)));
      setIsLoading.setFalse();
    },
  });

  const changedObjectAsyncSelectCb = (inputValue: string): void => {
    setIsLoading.setTrue();
    setSearch(inputValue);
  };

  return {
    changedObjectAsyncSelectCb,
    changedObjectOptions: options,
    changedObjectIsLoading: isLoading,
  };
};

export default useObjectNameFilter;
