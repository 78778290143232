import React from 'react';
import PropTypes from 'prop-types';
import styles from './description.scss';

const Description = ({ children }) => <div className={styles.description}>{children}</div>;

Description.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Description;
