import bpFetch from 'common/utils/http/fetcher';

const organizationSSOURL = '/web/organization/my/sso';

export function loadSSOState() {
  return bpFetch({ url: organizationSSOURL }).then((res) => {
    if (res.data && res.data.data) {
      return res.data.data;
    }
    return res.data;
  });
}

export function configureSSO(provider, issuer, url, certificate, disableRequestedAuthnContext) {
  return bpFetch({
    url: `${organizationSSOURL}/${provider}/configure`,
    method: 'POST',
    data: {
      idPissuer: issuer,
      idPssoUrl: url,
      idPcert: certificate,
      disableRequestedAuthnContext: !!disableRequestedAuthnContext,
    },
  }).then((res) => res.data);
}

export function updateSSO(idP, enabled, status) {
  return bpFetch({
    url: organizationSSOURL,
    method: 'PUT',
    data: { idP, enabled, status },
  }).then((res) => res.data);
}

export function disableSSO() {
  return bpFetch({
    url: organizationSSOURL,
    method: 'PUT',
    data: { enabled: false },
  }).then((res) => res.data);
}
