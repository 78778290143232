import _filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
angular.module('bigpanda').controller('AdvancedEditorRulesCtrl', AdvancedEditorRulesCtrl);

function AdvancedEditorRulesCtrl(
  $ngRedux,
  $state,
  EnvironmentsService,
  SiftService,
  $scope,
  UserFeatureTogglesService,
  LabelsStore
) {
  const vm = this;
  vm.$onInit = init;
  vm.onBpqlChange = onBpqlChange;
  vm.hasChagned = false;
  vm.originalValue = null;
  vm.enableQueryHelper = UserFeatureTogglesService.getToggle('enable_create_an_environment_helper');

  vm.store = $ngRedux;
  vm.currentSiftValue = null;

  $scope.$watch('vm.advancedEnvFilter', function (val) {
    vm.value = val;
  });

  function envToSift() {
    if (vm.envName.toLowerCase() !== 'all') {
      const validatedValue = testAndValidity(vm.siftValue);
      return SiftService.rulesToFilter(validatedValue, true, null);
    }
    return undefined;
  }

  function shouldConvertToBpql(filterArray) {
    if (isEmpty(filterArray)) return false;

    if (filterArray.length > 1) return true;

    if (filterArray.length === 1) {
      const filter = filterArray[0];

      return !isEmpty(filter.checks) || !isEmpty(filter.ands) || filter.source;
    }
  }

  function testAndValidity(filters) {
    return filters.reduce((filtered, filter) => {
      if (filter.source) {
        const ands = _filter(filter.ands, (and) => and.check);
        filtered.push({ ...filter, ands });
      }
      return filtered;
    }, []);
  }

  function init() {
    vm.loading = true;
    vm.tagsDefinitions = LabelsStore.getDefinitions();

    const shouldConvert = vm.siftValue && shouldConvertToBpql(vm.siftValue);
    if (shouldConvert) {
      const siftExpression = envToSift(vm.siftValue);
      EnvironmentsService.convertSiftToBpql({ siftExpression }).then((res) => {
        vm.value = res.bpqlExpression;
        vm.setOriginalValue(vm.value);
        vm.loading = false;
      });
    } else {
      if (vm.advancedEnvFilter) vm.value = vm.advancedEnvFilter;
      vm.loading = false;
    }
  }

  function onBpqlChange(val = '', validationErr) {
    vm.onBpqlInputChange(val, validationErr);
  }

  init();
}
