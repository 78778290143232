import find from 'lodash/find';
import values from 'lodash/values';
import map from 'lodash/map';
import extend from 'lodash/extend';
const { getErrorMessageTransform } = require('angularjs/utils/notification/message.transforms.js');

angular.module('bigpanda').controller('SharePopupController', SharePopupController);

function SharePopupController(
  $scope,
  $q,
  $timeout,
  $log,
  IncidentsService,
  SharesStore,
  TargetSystemsStore,
  CustomizationsStore,
  notificationService,
  pubSubService,
  resolvedIncidentIds,
  stateService
) {
  const vm = this;

  vm.incidentsIds = resolvedIncidentIds;
  vm.shareTestTextId = 'Share_modal_text';

  const multiple = vm.incidentsIds.length > 1;

  $scope.parentVm = vm;

  // Dialog settings
  $scope.dialogTitle = `Share Incident${multiple ? 's' : ''}`;
  $scope.titleIconClass = 'bp-icon-share';
  $scope.submitButtonCaption = 'Share';
  $scope.cancelButtonCaption = 'Cancel';
  $scope.saveButtonProductId = 'share_incident';

  $scope.preSave = preSave;

  // preSaveHooks lets custom target system params add a functionality that will run before save
  // we use it for saving new contacts for example
  let preSaveHooks = [];
  let title = `${vm.incidentsIds.length} incidents`;

  vm.system = {};

  vm.registerPreSaveHook = registerPreSaveHook;

  init();

  function init() {
    if (vm.incidentsIds.length === 1) {
      IncidentsService.getIncidentById(vm.incidentsIds[0]).then((incident) => {
        title = incident.title;
      });
    }

    const systemsPromise = TargetSystemsStore.getAll().then((systems) => {
      vm.systems = systems;
      vm.systems.forEach((system) => {
        system.paramsTemplate = TargetSystemsStore.getSystemParams(system);
      });
    });

    $q.all({
      systems: systemsPromise,
      customizations: CustomizationsStore.getCustomizations(),
    }).then((result) => {
      if (!vm.system.selected && result.customizations) {
        vm.system.selected = getDefaultShareSystem(result.customizations);
      }
    });

    $scope.$watch(
      () => vm.system.selected,
      (current) => {
        unselect();
        if (current) {
          current.selected = true;
        }
        vm.fields = vm.fields || {};
        vm.fields.recipient = '';
        preSaveHooks = [];
      }
    );

    $timeout(() => {
      // To allow child controllers access the modal dialog form. We do it in timeout because the dialog doesn't
      // initialize it when out controller run
      vm.modalDialogForm = $scope.modalDialogForm;
    });
  }

  function unselect() {
    vm.systems.forEach((system) => {
      system.selected = false;
    });
  }

  function preSave() {
    extend(vm.fields, { manualShare: { envId: stateService.getSelectedEnvironmentId() } });
    vm.fields.targetSystemId = vm.system.selected.id;
    $q.all(map(preSaveHooks, (hook) => hook())).then(() => {
      preSaveHooks = [];
      share();
    });
  }

  function share() {
    TargetSystemsStore.getById(vm.system.selected.id).then(
      (targetSystem) => {
        const params = { ...vm.fields };
        if (vm.system.selected.shareOptions) {
          const shareOptions = { ...vm.system.selected.shareOptions, message: params.message };
          params.share_options = shareOptions;
        }
        const createSharesRequest = SharesStore.createShares(
          vm.incidentsIds,
          title,
          vm.system.selected,
          params,
          vm.context
        );

        createSharesRequest.then(
          (incidentsShares) => {
            const shares = values(incidentsShares);
            if (shares.length === 1) {
              if (targetSystem && targetSystem.getNotificationMessage) {
                notificationService.info(targetSystem.getNotificationMessage(shares));
              }
            }
          },
          (err) => {
            let serverError =
              err.data &&
              err.data.response &&
              err.data.response.errors &&
              err.data.response.errors.join(', ');
            let errMsg = 'Something went wrong. Please try again';
            $log.error(`${errMsg}. serverError: ${serverError}`);
            if (err.status === 424) {
              if (targetSystem) {
                errMsg = `${targetSystem.name} returned an error`;
              }
            } else if (err.status === 412) {
              // External error occured
              errMsg = 'BigPanda server returned an error';
            }

            serverError = serverError ? `: ${serverError}` : '';

            notificationService.error(
              `${getErrorMessageTransform(title, 'share')()} - ${errMsg}${serverError}`
            );
          }
        );
      },
      () => {
        notificationService.error(
          `${getErrorMessageTransform(title, 'share')()} - Something went wrong. Please try again`
        );
      }
    );
  }

  function registerPreSaveHook(hook) {
    preSaveHooks.push(hook);
  }

  function getDefaultShareSystem(customizations) {
    if (!customizations.shares) {
      return undefined;
    }
    let defaultSystem;
    if (stateService.getSelectedEnvironmentId()) {
      const defaultConfig = find(customizations.shares.environments_defaults, {
        environment: stateService.getSelectedEnvironmentId(),
      });
      if (defaultConfig) {
        defaultSystem = find(vm.systems, { id: defaultConfig.target_system_id });
      }
    }
    if (!defaultSystem && customizations.shares.default_target_system_id) {
      defaultSystem = find(vm.systems, { id: customizations.shares.default_target_system_id });
    }

    return defaultSystem;
  }
}
