module.exports = angular
  .module('bigpanda.runtime', [])
  .factory('execution', ['$timeout', '$q', 'notificationService', execution]);

function execution($timeout, $q, notificationService) {
  const timeoutPromise = {};
  /**
   * This function will run a function and will try it until it succeed
   * @param run the function that runRetry will execute
   * @param rollback the function that executed automatically after failure
   * @param options an object with the following properties:
   *  retry: 3 // How many time to try
   *  timeout: 250 // Time to wait between fails
   *  factor: 2 // Multiplier of the timeout after each fail
   *  messageTransform: undefined // the function to reform the error messages
   *
   * @returns {Promise.promise}
   */
  function runRetry(toRun, rollback, options = {}) {
    const factor = options.factor || 2;
    const messageTransform = options.messageTransform;
    let retries = options.retry || 3;
    let timeout = options.timeout || 250;

    function _succeed(returned) {
      return $q.when(returned);
    }

    function _failed(error) {
      if (--retries <= 0) {
        const message = messageTransform && messageTransform(error);
        if (options.noVisual) {
          if (rollback) rollback();
          return $q.reject(error);
        }

        return notificationService
          .error(message || (error && error.message), { originalError: error })
          .then(() => {
            if (rollback) rollback();
            return $q.reject(error);
          });
      }

      const promise = $timeout(() => {
        try {
          return toRun();
        } catch (e) {
          return $q.reject(e);
        }
      }, timeout).then(_succeed, _failed);
      timeout *= factor;
      return promise;
    }

    function runImmediate() {
      // The reason for adding the try / catch is to make sure that incase the run function throws exception
      // we still return a valid promise
      try {
        return $q.when(toRun()).then(_succeed, _failed);
      } catch (e) {
        // The success handler won't be called because the promise is already rejected.
        return $q.reject(e).then(null, _failed);
      }
    }

    return runImmediate();
  }

  function doBeforeWait(context, fn, wait) {
    let args;
    let self;

    function later() {
      fn.apply(self, args);
      self = args = timeoutPromise[context] = null;
    }
    function laterFirst() {
      timeoutPromise[context] = null;
    }

    return function () {
      self = this;
      args = arguments;
      if (timeoutPromise[context]) {
        $timeout.cancel(timeoutPromise[context]);
        timeoutPromise[context] = $timeout(later, wait);
      } else {
        // First time
        later();
        timeoutPromise[context] = $timeout(laterFirst, wait);
      }
    };
  }

  function deferRun(fn, time) {
    if (typeof time === 'undefined' || time === null) {
      time = 0;
    }
    return $timeout(fn, time);
  }

  return {
    runRetry: runRetry,
    deferRun: deferRun,
    doBeforeWait: doBeforeWait,
  };
}
