angular.module('bigpanda').directive('activityFeed', activityFeed);

function activityFeed() {
  return {
    restrict: 'E',
    scope: {
      showStatusChangesActivity: '=',
      onFilterStatusChanges: '=',
      statusChangesCount: '=',
      editingComment: '=',
      currentEnvActions: '=',
      incident: '<',
      events: '<',
    },
    templateUrl: 'overview/incidents/info/activity_feed/incident_info_activity_feed',
    bindToController: false,
  };
}
