import { hot } from 'react-hot-loader';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsListScreen from 'react/layout/settings/SettingsListScreen';
import { SortShape } from 'react/layout/settings/components/SettingsList';
import provideStore from 'react/utils/provideStore';
import SettingsListEmptyState from 'react/layout/settings/components/SettingsListEmptyState';
import { DropdownOptionType } from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import get from 'lodash/get';

import RoleModal from './modal/RoleModal';
import actions from '../actions';
import selectors from '../selectors';
import { SORT_MODES, ANY_OPTION_VALUE } from '../constants';
import './roles_management.scss';
import ListItem from './list/ListItem';
import FiltersComponent from './filters/FiltersComponent';

class RolesManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.roles) {
      this.props.loadRoles();
    }
  }

  render() {
    const {
      roles,
      filterText,
      sortMode,
      updateFilterText,
      updateSort,
      isLoading,
      filtersDropDownState,
      filtersDropDownItems,
      updateFiltersDropDowns,
      featureToggles,
    } = this.props;

    const settingsSortingFT = get(featureToggles, 'frontier_settings_sorting_and_filters', false);
    const filterDDItems = settingsSortingFT ? filtersDropDownItems : undefined;

    const sortTooltip = (
      <div>
        <b>Updated</b>
        <br />
        Most recently updated on top.
        <br />
        <br />
        <b>Role name</b>
        <br />
        Alphabetically ordered.
        <br />
        <br />
        <b>Creation time</b>
        <br />
        Newest role on top.
      </div>
    );

    return (
      <SettingsListScreen
        loading={isLoading}
        items={roles}
        noItemsTitle="No Roles Added Yet"
        createItemProps={{
          modal: {
            title: 'Create a New Role',
            titleIcon: 'bp-icon-plus-icon',
            component: RoleModal,
          },
          itemName: 'role',
          buttonText: 'New Role',
          tileText: 'Add a new role',
        }}
        listItem={ListItem}
        listItemProp="role"
        permissionName="roles"
        headerName="Roles Management"
        filterPlaceholderText="Find Roles..."
        sortHeaderLink="https://docs.bigpanda.io/docs/rbac-role-based-access-control"
        sortTooltip={sortTooltip}
        updateFilterText={updateFilterText}
        updateSort={updateSort}
        filterText={filterText}
        sortModes={SORT_MODES}
        sortMode={sortMode}
        renderFilterRowElement={() =>
          filterDDItems && filtersDropDownState ? (
            <FiltersComponent
              filtersDropDownState={filtersDropDownState}
              onChange={updateFiltersDropDowns}
              data={filterDDItems}
            />
          ) : null
        }
        renderNoResultsByFilters={() =>
          filterText ||
          Object.values(filtersDropDownState).some(
            (dropDownState) => dropDownState.value !== ANY_OPTION_VALUE
          ) ? (
            <SettingsListEmptyState
              title="No Matches Found"
              subtitle="Try refining your query and search filters"
            />
          ) : null
        }
      />
    );
  }
}

RolesManagement.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  loadRoles: PropTypes.func.isRequired,
  updateFilterText: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  sortMode: SortShape.isRequired,
  updateFiltersDropDowns: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    tagType: DropdownOptionType,
    status: DropdownOptionType,
  }),
  filtersDropDownItems: PropTypes.shape({
    tagType: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }),
  featureToggles: PropTypes.shape({}).isRequired,
};

RolesManagement.defaultProps = {
  roles: undefined,
  filterText: '',
  filtersDropDownState: undefined,
  filtersDropDownItems: undefined,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    roles: selectors.getRoles(state),
    filterText: selectors.getFilterText(state),
    sortMode: selectors.getSortMode(state),
    filtersDropDownState: selectors.getFiltersDropDownState(state),
    filtersDropDownItems: selectors.getFiltersDropDownItems(state),
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadRoles: () => dispatch(actions.loadRoles()),
    updateFilterText: (filterText) => dispatch(actions.updateFilterText(filterText)),
    updateSort: (sortMode) => dispatch(actions.updateSort(sortMode)),
    updateFiltersDropDowns: (filterState) => dispatch(actions.updateFiltersDropDowns(filterState)),
  };
}

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(RolesManagement))
);
