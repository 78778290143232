import React from 'react';
import PropTypes from 'prop-types';
import styles from './steps.scss';

const Steps = ({ children }) => <ol className={styles.steps}>{children}</ol>;

Steps.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Steps;
