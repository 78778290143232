angular.module('bigpanda').controller('PingoneController', ($scope, $http, $log, pubSubService) => {
  $scope.credentials = {};
  $scope.pingoneError = '';

  var formStatuses = {
    empty: { empty: true },
    failed: { message: 'Not enough permissions', failed: true },
    failed_local: { message: 'All fields are mandatory', failed: true },
    success: { message: 'Done', success: true },
    inProgress: { message: 'Configuration In Progress', inProgress: true },
  };

  function validateBasic() {
    if (
      !$scope.credentials.idPssoUrl ||
      !$scope.credentials.idPissuer ||
      !$scope.credentials.idPcert
    ) {
      $scope.status = formStatuses.failed_local;
      return true;
    }
  }

  $scope.configure = function () {
    $scope.status = formStatuses.empty;
    $scope.pingoneError = '';

    if (validateBasic()) {
      return;
    }

    $scope.status = formStatuses.empty;
    $scope.configureDisabled = true;

    $scope.status = formStatuses.inProgress;

    $http.post('/web/organization/my/sso/pingone/configure', $scope.credentials).then(
      () => {
        $scope.status = formStatuses.success;
        pubSubService.broadcast('SsoIntegrationCompleted', 'pingone');
        $scope.configureDisabled = false;
      },
      (err) => {
        $scope.configureDisabled = false;
        $scope.status = formStatuses.failed;
        $scope.pingoneError =
          err.data && err.data.response && err.data.response.errors && err.data.response.errors[0];
      }
    );
  };
});
