angular.module('bigpanda.utils').factory('stateService', stateService);

function stateService($stateParams) {
  function getSelectedApiKeyId() {
    return $stateParams.apiKeyId;
  }

  function getSelectedIncidentId() {
    return $stateParams.incidentId;
  }

  function getSelectedCorrelationId() {
    return $stateParams.correlationId;
  }

  function getSelectedTagId() {
    return $stateParams.tagId;
  }

  function getSelectedEnvironmentId() {
    return $stateParams.environment;
  }

  function getSelectedFolderId() {
    return $stateParams.folder;
  }

  function getSelectedChangeId() {
    return $stateParams.changeId;
  }

  function getSelectedIntegrationId() {
    return $stateParams.integration;
  }

  function getSelectedGuideId() {
    return $stateParams.guide;
  }

  function getSso() {
    return $stateParams.sso;
  }

  function getTarget() {
    return $stateParams.target;
  }

  function getCreated() {
    return $stateParams.created;
  }

  function getSelectedReportId() {
    return $stateParams.reportId;
  }

  function getSelectedReportSnapshotId() {
    return $stateParams.reportSnapshotId;
  }

  function getSelectedIncidentsIds() {
    return $stateParams.incidentsIds;
  }

  function getSignedRequest() {
    return $stateParams.signed_request;
  }

  function getUserId() {
    return $stateParams.userId;
  }

  return {
    getSelectedApiKeyId,
    getSelectedIncidentId,
    getSelectedCorrelationId,
    getSelectedTagId,
    getSelectedEnvironmentId,
    getSelectedFolderId,
    getSelectedChangeId,
    getSelectedIntegrationId,
    getSelectedGuideId,
    getSelectedReportId,
    getSelectedReportSnapshotId,
    getSelectedIncidentsIds,
    getSso,
    getTarget,
    getCreated,
    getSignedRequest,
    getUserId,
  };
}
