import {
  AllSizes, darkTheme,
  Dot, Text,
} from '@bp/kung-fu';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { Anchorize, TemplateToTokens } from '../../../../../app/react/common/components';
import { Tag, TagType } from '../../types';
import { ExtractionRuleTooltipContent } from '../components/ExtractionRuleTooltipContent';
import { MappingValueTableRow } from '../components/MappingValueTableRow';
import {
  statusToColor, statusToString, valueByType, valueFormatByType,
} from './utils';

function EmptyCell(): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <Text size={AllSizes.SMALL} color={(p): string => p.theme.bp_gray_05}>
        -
      </Text>
    </ThemeProvider>
  );
}

interface Column {
  id: string;
}

export const getEnrichmentColumns = (tagType: Tag['type']): Column[] => [
  {
    id: 'order',
    accessor: 'index',
    headerContent: 'Order',
    cellContent: ({ value }) => value,
    sortable: false,
    resizable: false,
    width: 60,
    frozen: 'left',
  },
  tagType === 'Multi Type' && {
    id: 'function',
    accessor: 'type',
    headerContent: 'Function Type',
    cellContent: ({ value }) => capitalize(value),
    tooltipContent: ({ value }) => value,
    resizable: false,
    sortable: false,
  },
  {
    id: 'value',
    accessor: valueByType,
    headerContent: 'Value',
    cellContent: ({ original, value }: { original: Tag, value: string }): React.ReactNode => {
      const mapName = get(original, 'config.name');

      if (!value && !mapName) {
        return <EmptyCell />;
      }

      switch (original.type.toLowerCase()) {
        case TagType.Composition:
          return <TemplateToTokens template={value} />;

        case TagType.Mapping:
          return <MappingValueTableRow mapName={mapName} />;

        default:
          return value;
      }
    },
    tooltipContent: valueFormatByType,
    sortable: false,
  },
  {
    id: 'source',
    accessor: ({ sourceSystems }) => sourceSystems.map(({ display }) => display).join(', '),
    headerContent: 'Source',
    cellContent: ({ value, original }): React.ReactNode => {
      if (!value) {
        return <EmptyCell />;
      }

      const sourceSystem = get(original, 'config.source');

      return (
        <>
          <Text size={AllSizes.SMALL}>{value}</Text>
          {sourceSystem && (
          <Text size={AllSizes.SMALL} weight="bold">
            /
            {sourceSystem}
          </Text>
          )}
        </>
      );
    },
    tooltipContent: ({ value, original }): React.ReactNode => {
      const sourceTag = get(original, 'config.source');

      switch (original.type.toLowerCase()) {
        case TagType.Extraction:
          return <ExtractionRuleTooltipContent sourceSystem={value} sourceTag={sourceTag} />;

        default:
          return sourceTag ? `${value}/${sourceTag}` : value;
      }
    },
    sortable: false,
  },
  {
    id: 'condition',
    accessor: 'displayQuery',
    headerContent: 'Condition',
    cellContent: ({ value }) => value || <EmptyCell />,
    tooltipContent: ({ value }) => value,
    sortable: false,
  },
  {
    id: 'note',
    accessor: 'note',
    headerContent: 'Note',
    cellContent: ({ value }) => (value ? <Anchorize input={value} /> : <EmptyCell />),
    tooltipContent: ({ value }) => value,
    sortable: false,
  },
  {
    id: 'status',
    accessor: 'active',
    headerContent: 'Status',
    cellContent: (row) => (row.value === undefined ? (
      <EmptyCell />
    ) : (
      <Dot size="medium" color={statusToColor(row.value)} text={statusToString(row.value)} />
    )),
    tooltipContent: ({ value }) => statusToString(value),
    width: 100,
    sortable: false,
    frozen: 'right',
  },
].filter(Boolean);

export default getEnrichmentColumns;
