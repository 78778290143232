/* eslint-disable object-shorthand */
import React from 'react';
import { TimeFormats } from '@bp/bam';
import { Dot } from '@bp/kung-fu';
import BPQL from '@bp/bpql';
import get from 'lodash/get';
import moment from 'moment';
import RelatedMetadata from './RelatedMetadata/RelatedMetadata';
import styles from './changes.scss';

const { momentOrEpochRelativeTimeFormat, standardFullDateTimeFormatter } = TimeFormats;
export const INITIAL_TOGGLE_VALUE = false;
export const FILTER_RCC_ONLY = false;
export const SORT_MATCHES = 'match';
export const COL_WIDTH = 150;
export const DEFAULT_MAX_DYNAMIC_TAG = 30;
export const TAG_SPACE_REPLACE_PATTERN = '___';
export const TAG_PREFIX = '_';

const defaultTooltipContent = ({ value = '' }) => value;
const diffTooltipContent = ({ value = '' }) => value;

export const copyToClipboard = true;
export const RELATED_METADATA_COL_ID = 'relatedMetadata';
const changeStatusToColor = {
  Planned: styles.bp_orange,
  'In Progress': styles.bp_green,
  Canceled: styles.bp_gray_04,
  Done: styles.bp_gray_06,
};

const diffHeaderTooltip =
  'The time difference between the change start time and the incident start time';

export const staticColumns = ({
  user,
  users,
  incidentId,
  disabled,
  setPopupProps,
  incidentStartTime,
}) => [
  {
    id: 'status',
    headerContent: 'Status',
    accessor: 'status',
    frozen: 'left',
    width: 100,
    autofitCalculated: true,
    sortable: false,
    sortMethod: (a, b) => {
      const statusWeight = {
        Done: 4,
        'In Progress': 3,
        Planned: 2,
        Canceled: 1,
      };
      if (statusWeight[a] > statusWeight[b]) {
        return 1;
      }
      if (statusWeight[a] < statusWeight[b]) {
        return -1;
      }
      return 0;
    },
    // eslint-disable-next-line react/prop-types
    cellContent: ({ value }) => (
      <Dot size="medium" color={changeStatusToColor[value]} text={value} />
    ),
  },
  {
    id: 'key',
    accessor: 'identifier',
    headerContent: 'Key',
    doNotAddStaticWidth: true,
    sortable: false,
    // eslint-disable-next-line react/prop-types
    cellContent: ({ value, row }) => (
      // eslint-disable-next-line react/jsx-no-target-blank,react/prop-types
      <a className={styles.bamLink} target="_blank" href={row._original.ticket_url}>
        {value}
      </a>
    ),
    tooltipContent: ({ row }) => row._original.ticket_url, // eslint-disable-line react/prop-types
    copyToClipboard,
    copyContent: ({ value }) => value,
  },
  {
    id: 'summary',
    headerContent: 'Summary',
    accessor: 'summary',
    width: COL_WIDTH,
    autofitCalculated: true,
    sortable: false,
    cellContent: ({ value }) => value || '', // eslint-disable-line react/prop-types
    tooltipContent: defaultTooltipContent,
    copyToClipboard,
  },
  {
    id: 'start',
    headerContent: 'Start Time',
    accessor: 'start',
    width: 130,
    autofitCalculated: true,
    sortable: false,
    cellContent: ({ value }) => (value ? momentOrEpochRelativeTimeFormat(value) : ''),
    tooltipContent: ({ value }) => (value ? standardFullDateTimeFormatter(value) : ''),
    copyToClipboard,
  },
  {
    id: 'end',
    headerContent: 'End Time',
    accessor: 'end',
    width: 130,
    autofitCalculated: true,
    sortable: false,
    cellContent: ({ value }) => (value ? momentOrEpochRelativeTimeFormat(value) : ''),
    tooltipContent: ({ value }) => (value ? standardFullDateTimeFormatter(value) : ''),
    copyToClipboard,
  },
  {
    id: 'diff',
    frozen: 'right',
    width: 45,
    autofitCalculated: true,
    sortable: false,
    headerContent: 'Diff',
    detailsTag: 'Time Difference (diff)',
    headerTooltipContent: diffHeaderTooltip,
    cellContent: (value) => calcDiff(value.row.start, incidentStartTime, true),
    tooltipContent: (value) => calcDiff(value.row.start, incidentStartTime, false),
    tagContent: (value) => calcDiff(value.row.start, incidentStartTime, false),
  },
  {
    id: RELATED_METADATA_COL_ID,
    accessor: 'relatedMetadata',
    width: 122,
    autofitCalculated: true,
    headerContent: () => (
      <React.Fragment>
        <i className="bp-icon-user" /> Root Cause
      </React.Fragment>
    ),
    overflowElement: true,
    cellContent: (
      // eslint-disable-next-line react/prop-types
      { value, original: change }
    ) => (
      <RelatedMetadata
        defaultSelected={get(value, 'match_certainty')}
        relatedMetadata={value}
        user={users && users[get(value, 'user_id')]}
        currentUser={user}
        incidentId={incidentId}
        change={change}
        disabled={disabled}
        setPopupProps={setPopupProps}
      />
    ),
    hasCacheBustingSetPopupProps: setPopupProps !== undefined,
    frozen: 'right',
    sortable: false,
  },
];
const diffcellTooltipContent = (data) => (
  <span>
    Started<span style={{ fontWeight: 'bold' }}>{data}</span> before the Incident
  </span>
);
const diffcellAfter = (started) => (
  <span>
    Start{started ? 's' : 'ed'}&nbsp;<span style={{ fontWeight: 'bold' }}>after</span> the Incident
  </span>
);

export const calcDiff = (changeStartTime, incidentStartTime, shortText) => {
  const diff = incidentStartTime - changeStartTime;
  if (diff > 0) {
    const diffMoment = moment.duration(diff, 's');
    const days = Math.floor(diffMoment.asDays());
    if (days) {
      return shortText ? `${days}d` : diffcellTooltipContent(` ${days} day${days > 1 ? 's' : ''}`);
    }
    const hours = Math.floor(diffMoment.asHours());
    if (hours) {
      return shortText
        ? `${hours}h`
        : diffcellTooltipContent(` ${hours} hour${hours > 1 ? 's' : ''}`);
    }
    const mins = Math.floor(diffMoment.asMinutes());
    return shortText
      ? `${mins}m`
      : diffcellTooltipContent(` ${mins} minute${mins == 1 ? '' : 's'}`);
  }
  return shortText ? '-' : diffcellAfter(changeStartTime > moment().format('X'));
};

export const extractTagsFromChangesArrayAsSet = (changes = [], excludedTags = new Set()) =>
  changes.reduce((acc, change) => {
    if (!change || !change.tags) {
      return acc;
    }
    Object.keys(change.tags).forEach((tag) => {
      if (!excludedTags.has(tag)) {
        acc.add(tag);
      }
    });
    return acc;
  }, new Set());

export const getTagsColumnsFromChangesArray = (changes = [], excludedTags = new Set()) =>
  [...extractTagsFromChangesArrayAsSet(changes, excludedTags)].map((tagName) => ({
    id: `${TAG_PREFIX}${tagName}`.replace(/[\s]/g, TAG_SPACE_REPLACE_PATTERN),
    headerContent: tagName,
    accessor: `tags.${tagName}`,
    cellContent: ({ value }) => {
      if (Array.isArray(value)) return value.join(' | ');
      return value || '';
    },
    tooltipContent: defaultTooltipContent,
    copyToClipboard,
    isTag: true,
    sortable: false,
  }));

export const normalizeArrayToObject = (array, key) =>
  array.reduce((acc, nextItem) => {
    acc[nextItem[key]] = nextItem;
    return acc;
  }, {});

export const removeUnusedPrevTagsColumns = (prevColumns, nextColumns) => {
  const nextColumnsObject = normalizeArrayToObject(nextColumns, 'id');
  const columnsIntersection = prevColumns.reduce((acc, col) => {
    const colFromNext = nextColumnsObject[col.id];
    if (colFromNext) {
      delete nextColumnsObject[col.id];
      return [...acc, colFromNext];
    }
    return acc;
  }, []);
  const nextCols = [
    ...columnsIntersection,
    ...Object.keys(nextColumnsObject).map((key) => nextColumnsObject[key]),
  ];
  return nextCols;
};
export const MAX_POPUP_HEIGHT = 1650;
export const MAX_POPUP_WIDTH = 650;
export const POPUP_TIMEOUT = 8000;
export const POPUP_MARGIN = {
  MARGIN_TOP: 46,
  MARGIN_BOTTOM: 15,
  MARGIN_LEFT: 40,
  MARGIN_RIGHT: 15,
};
export const RCC_TOOLTIP_CONTENT = <span>Showing only RCC (Root Cause Changes)</span>;

export const calcDetailsPopupStyle = (clientHeight, tableLeft) => {
  const maxHeight = Math.min(
    clientHeight - POPUP_MARGIN.MARGIN_BOTTOM - POPUP_MARGIN.MARGIN_TOP,
    MAX_POPUP_HEIGHT
  );
  const width = Math.min(
    tableLeft - POPUP_MARGIN.MARGIN_LEFT - POPUP_MARGIN.MARGIN_RIGHT,
    MAX_POPUP_WIDTH
  );
  return {
    maxHeight,
    width,
  };
};
export const STATIC_SEARCH_TOOLTIP_DATA = {
  header: 'Search Logic',
  content: (
    <span>
      The search is on <strong>Status, Key and Summary</strong> fields. The results include only
      matches to an exact phrase consisting of one or more words.
    </span>
  ),
};
export const normalizeColumns = (nextChanges, prevColumns) => {
  const nextTagColumns = getTagsColumnsFromChangesArray(nextChanges);
  const nextColumnsDirty = [...prevColumns, ...nextTagColumns];
  const nextColumns = removeUnusedPrevTagsColumns(prevColumns, nextColumnsDirty);
  // add static col width to new cols.
  nextColumns.forEach((col) => {
    if (!col.width && !col.doNotAddStaticWidth) {
      col.width = COL_WIDTH;
    }
  });
  return nextColumns;
};

export const tagKey = (id) =>
  `_original.tags.${id
    .slice(TAG_PREFIX.length, id.length)
    .replace(new RegExp(TAG_SPACE_REPLACE_PATTERN, 'g'), ' ')}`;

export const parser = BPQL.buildParserFromGrammer('bigpanda');

export class BpqlParseError {
  constructor(message) {
    this.message = message;
  }
}

export class BpqlValue {
  constructor(value) {
    this.value = value;
  }
}
