import includes from 'lodash/includes';
angular.module('bigpanda').service('SortingStore', SortingStore);

function SortingStore($q, $log, SubscribersService, SORTING, PersonalSettingsStore) {
  const subscribers = new SubscribersService();
  let sortBy;

  this.options = SORTING.options;
  this.titles = SORTING.titles;
  this.getSortBy = getSortBy;
  this.updateSortBy = updateSortBy;
  this.resetSortBy = resetSortBy;
  this.subscribe = subscribe;

  function subscribe(subscriber, scope) {
    subscribers.subscribe(scope, subscriber);
  }

  function getSortBy() {
    if (sortBy) {
      return $q.when(sortBy);
    }

    return PersonalSettingsStore.getUser().then((user) => {
      if (user.organization.config && user.organization.config.sort_by) {
        sortBy = user.organization.config.sort_by;
        return validateSortBy();
      }

      return SORTING.options.lastChange;
    });
  }

  function validateSortBy() {
    if (!includes(SORTING.options, sortBy)) {
      $log.error(`Wrong type of sortBy: ${sortBy}`);
      return SORTING.options.lastChange;
    }
    return sortBy;
  }

  function updateSortBy(sortByValue) {
    sortBy = sortByValue;
    subscribers.fireStoreUpdated();
  }

  function resetSortBy() {
    sortBy = SORTING.lastChange;
  }

  return this;
}
