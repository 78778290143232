const PERSONAL_SETTINGS_PATH = 'modules.settings.personalSettings';
const LOAD_USER = `${PERSONAL_SETTINGS_PATH}.LOAD_USER`;
const UPDATE_USER = `${PERSONAL_SETTINGS_PATH}.UPDATE_USER`;

export const actions = {
  loadUser: () => {
    return { type: LOAD_USER };
  },
  updateUser: (payload) => {
    return { type: UPDATE_USER, payload: payload };
  },
};

export const selectors = {
  personalSettings: PERSONAL_SETTINGS_PATH,
};
