import isEmpty from 'lodash/isEmpty';
import { LABEL_SEPARATOR } from '../constants';
import { getLabelInternalValue, getLabelDisplayValue } from '../utils';

const LABEL_VALIDATION = {
  LABEL_PATTERN: /^[\w-]+$/,
  MAX_GROUP_LENGTH: 128,
  MAX_VALUE_LENGTH: 400,
};

const convertLabelsToDropdownOptions = (labels) =>
  (labels || []).map((label) => ({
    value: getLabelInternalValue(label),
    label: getLabelDisplayValue(label),
    rawLabel: label,
  }));

function parseLabel(inputValue) {
  const [group = '', rawValue = '', ...rest] = inputValue.split(LABEL_SEPARATOR.INTERNAL);
  const value = rawValue.startsWith(' ') ? rawValue.slice(1) : rawValue;
  return { group, value, ...(isEmpty(rest) ? {} : { rest }) };
}

function validateNewLabel(inputValue = '', labelDefinitions = []) {
  const { group, value, rest } = parseLabel(inputValue);
  if (!isEmpty(rest)) {
    return 'Invalid label: ":" can be used only to associate the label with a group';
  }
  if (!group || !value) {
    return 'Invalid label: please, provide group and value';
  }
  if (group.length > LABEL_VALIDATION.MAX_GROUP_LENGTH) {
    return `Group name is too long: ${group.length}. Maximum length allowed is ${LABEL_VALIDATION.MAX_GROUP_LENGTH}`;
  }
  if (value.length > LABEL_VALIDATION.MAX_VALUE_LENGTH) {
    return `Label is too long: ${value.length}. Maximum length allowed is ${LABEL_VALIDATION.MAX_VALUE_LENGTH}`;
  }
  if (!LABEL_VALIDATION.LABEL_PATTERN.test(group)) {
    return 'Group name contains not-allowed characters. Only alphanumeric character and underscore can be used';
  }
  if (!LABEL_VALIDATION.LABEL_PATTERN.test(value)) {
    return 'Label contains not-allowed characters. Only alphanumeric character and underscore can be used';
  }
  const currentValues = labelDefinitions
    .map(getLabelInternalValue)
    .map((value) => value.toLowerCase());
  if (currentValues.includes(getLabelInternalValue({ group, value }).toLowerCase())) {
    return 'Label already exists';
  }
  return '';
}

export { LABEL_VALIDATION, convertLabelsToDropdownOptions, validateNewLabel, parseLabel };
