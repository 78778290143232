import { withReactQueryClient } from '@bp/data-access';
import { darkTheme } from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import provideStore from '../../../../../../app/react/utils/provideStore';
import AuditLogTable from '../AuditLogTable/AuditLogTable';
import {
  PageContainer,
} from './AuditLogsView.style';

function AuditLogsViewComponent(): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <PageContainer>
        <AuditLogTable />
      </PageContainer>
    </ThemeProvider>
  );
}

const AuditLogsView = provideStore(AuditLogsViewComponent);
export default withReactQueryClient(AuditLogsView);
