import forEach from 'lodash/forEach';
import spread from 'lodash/spread';
angular.module('bigpanda').service('AssignmentsStore', AssignmentsStore);

function AssignmentsStore(
  $q,
  AssignmentsBackendService,
  StreamConnector,
  SubscribersService,
  PromiseBatchService,
  PersonalSettingsStore,
  pubSubService,
  EnvironmentsService,
  EnvironmentsUtils,
  notificationService,
  Config,
  stateService,
  deepCloneObject
) {
  this.subscribe = subscribe;
  this.assign = assign;
  this.multiAssign = multiAssign;
  this.unassign = unassign;
  this.multiUnassign = multiUnassign;
  this.getAssignment = getAssignment;

  const assignmentsCache = {};
  const currentUserIdPromise = PersonalSettingsStore.getUser().then((user) => user._id);
  const subscribers = new SubscribersService();
  const batchGetAssignment = PromiseBatchService.batch(
    AssignmentsBackendService.getAssignments,
    (incidentId, assignResult) => incidentId === assignResult.incident_id,
    200
  );

  StreamConnector.on('assign', (incident) =>
    getAssignment(incident.id, true).then((assignment) => {
      subscribers.fireStoreUpdated();
      notifyUser(assignment);
    })
  );

  StreamConnector.on('unassign', (incident) => {
    updateCache(incident.id, {});
    subscribers.fireStoreUpdated();
  });

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function notifyUser(assignment) {
    currentUserIdPromise.then((currentUserId) => {
      if (currentUserId === assignment.assignee && currentUserId !== assignment.assigner) {
        EnvironmentsService.get().then((envs) => {
          const allEnvId = EnvironmentsUtils.getAllEnv(envs)._id;
          const frontEndUrl =
            `${Config.baseUrl}/#/app/overview/${allEnvId}` +
            `/search/incidents/${assignment.incident_id}`;

          notificationService.notify(
            `1 incident Assigned to you <a href=${frontEndUrl}>(view)</a>`
          );
        });
      }
    });
  }

  function assign(incidentId, assigneeId) {
    let assignerId;
    let formerAssignment;

    return currentUserIdPromise
      .then((currentUserId) => {
        assignerId = currentUserId;
        formerAssignment = assignmentsCache[incidentId];
        updateCache(incidentId, { assignee: assigneeId, assigner: assignerId });
        subscribers.fireStoreUpdated();

        return EnvironmentsService.getEnvById(stateService.getSelectedEnvironmentId()).then(
          (environment) =>
            AssignmentsBackendService.multiAssign(environment._id, {
              ids: [incidentId],
              assignee_id: assigneeId,
              environment_name: environment.name,
            })
        );
      })
      .then(() => {
        pubSubService.broadcast('incident.assigned', {
          id: incidentId,
          '@assignee': assigneeId,
          '@assigner': assignerId,
        });
      })
      .catch(() => {
        updateCache(incidentId, formerAssignment);
      });
  }

  function unassign(incidentId) {
    const formerAssignment = assignmentsCache[incidentId];

    updateCache(incidentId, {});
    subscribers.fireStoreUpdated();

    return $q
      .all([
        currentUserIdPromise,
        AssignmentsBackendService.multiUnassign(stateService.getSelectedEnvironmentId(), {
          ids: [incidentId],
        }),
      ])
      .then(
        spread((unassigner) =>
          pubSubService.broadcast('incident.unassigned', { '@unassigner': unassigner })
        )
      )
      .catch(() => {
        updateCache(incidentId, formerAssignment);
      });
  }

  function multiAssign(incidentIds, assigneeId) {
    let assignerId;
    const formerAssignments = {};

    return currentUserIdPromise
      .then((currentUserId) => {
        assignerId = currentUserId;

        forEach(incidentIds, (id) => {
          formerAssignments[id] = assignmentsCache[id];
          updateCache(id, { assignee: assigneeId, assigner: assignerId });
          subscribers.fireStoreUpdated();
        });

        return EnvironmentsService.getEnvById(stateService.getSelectedEnvironmentId()).then(
          (environment) =>
            AssignmentsBackendService.multiAssign(environment._id, {
              ids: incidentIds,
              assignee_id: assigneeId,
              environment_name: environment.name,
            })
        );
      })
      .then(() =>
        forEach(incidentIds, (id) => {
          pubSubService.broadcast('incident.assigned', {
            id: id,
            '@assignee': assigneeId,
            '@assigner': assignerId,
          });
        })
      )
      .catch(() => forEach(incidentIds, (id) => updateCache(id, formerAssignments[id])));
  }

  function multiUnassign(incidentIds) {
    const formerAssignments = {};

    forEach(incidentIds, (id) => {
      formerAssignments[id] = assignmentsCache[id];
      updateCache(id, {});
      subscribers.fireStoreUpdated();
    });

    return $q
      .all([
        currentUserIdPromise,
        AssignmentsBackendService.multiUnassign(stateService.getSelectedEnvironmentId(), {
          ids: incidentIds,
        }),
      ])
      .then(
        spread((unassigner) =>
          forEach(incidentIds, (id) =>
            pubSubService.broadcast('incident.unassigned', { id: id, '@unassigner': unassigner })
          )
        )
      )
      .catch(() => forEach(incidentIds, (id) => updateCache(id, formerAssignments[id])));
  }

  function getAssignment(incidentId, forceReload) {
    if (!forceReload && assignmentsCache[incidentId]) {
      return $q.when(deepCloneObject.cloneDeep(assignmentsCache[incidentId]));
    }

    return batchGetAssignment(incidentId)
      .then((assignment) => {
        updateCache(incidentId, assignment);
        return deepCloneObject.cloneDeep(assignment);
      })
      .catch(() => {
        updateCache(incidentId, {});
        return assignmentsCache[incidentId];
      });
  }

  function updateCache(incidentId, assignment) {
    assignmentsCache[incidentId] = assignment;
  }
}
