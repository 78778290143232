import actionTypes from '../actionTypes';

const initialState = {
  id: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        id: action.payload._id,
      };

    default:
      return state;
  }
}
