import { path } from './constants';

const actionTypes = {};

actionTypes.LOAD_CHANGES = `${path}.LOAD_CHANGES`;
actionTypes.LOAD_RELATED_CHANGES = `${path}.LOAD_RELATED_CHANGES`;
actionTypes.LOADING_CHANGES = `${path}.LOADING_CHANGES`;
actionTypes.LOAD_CHANGES_FAILED = `${path}.LOAD_CHANGES_FAILED`;
actionTypes.LOAD_CHANGES_SUCCESSFULLY = `${path}.LOAD_CHANGES_SUCCESSFULLY`;
actionTypes.LOAD_RELATED_CHANGES_SUCCESSFULLY = `${path}.LOAD_RELATED_CHANGES_SUCCESSFULLY`;
actionTypes.UPSERT_CHANGES_METADATA = `${path}.UPSERT_CHANGES_METADATA`;
actionTypes.UPSERT_CHANGES_METADATA_SUCCESSFULLY = `${path}.UPSERT_CHANGES_METADATA_SUCCESSFULLY`;
actionTypes.UPSERT_CHANGES_METADATA_FAILED = `${path}.UPSERT_CHANGES_METADATA_FAILED`;
actionTypes.CHANGE_DATE_RANGE = `${path}.CHANGE_DATE_RANGE`;
actionTypes.CHANGE_SEARCH = `${path}.CHANGE_SEARCH`;
actionTypes.INIT_CHANGES = `${path}.INIT_CHANGES`;
actionTypes.LOAD_SEARCH_CHANGES = `${path}.LOAD_SEARCH_CHANGES`;
actionTypes.LOAD_SEARCH_CHANGES_SUCCESSFULLY = `${path}.LOAD_SEARCH_CHANGES_SUCCESSFULLY`;
actionTypes.CHANGE_TOGGLE_VALUE = `${path}.CHANGE_TOGGLE_VALUE`;

export default actionTypes;
