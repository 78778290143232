import {
  AllSizes, Avatar, IOption, SCHBox,
  Text,
} from '@bp/kung-fu';
import React from 'react';

import { anyUser } from '../../constants';
import { AnyUserIcon, UserNameText } from './CustomUserOption.style';

function CustomUserOption({ name, username }: IOption): JSX.Element {
  const isAnyUser = name === anyUser.name;

  return (
    <SCHBox gap="5px">
      {isAnyUser
        ? (
          <AnyUserIcon
            color={(p): string => p.theme.bp_gray_06}
            size={AllSizes.XLARGE}
          />
        )
        : (
          <Avatar
            name={name}
            size={AllSizes.XSMALL}
          />
        )}

      <Text>{name}</Text>
      { username && (
        <UserNameText>
          {username}
        </UserNameText>
      )}
    </SCHBox>
  );
}

export default CustomUserOption;
