import { path as prevPath } from '../constants';

export const name = 'incidentTags';
export const path = `${prevPath}.${name}`;

export const AI_OPERATION_IN_PROGRESS = 'OPERATION_IN_PROGRESS';
export const AI_CAPACITY_REACHED = 'CAPACITY_REACHED';
export const AI_GENERIC_ERROR = 'GENERIC_ERROR';

export const reqStatusType = {
  NOT_INITIALIZED: -1,
  LOADING: 2,
  FAILURE: 0,
  SUCCESS: 1,
};
