import styled from 'styled-components';


const isIframeDarkMode = ({ iframeMode, theme }) => iframeMode && theme.theme_name === 'darkTheme';

export const Wrapper = styled.div`
height: ${({ iframeMode }) => iframeMode ? '100%' : '96%'};
border: ${({ iframeMode }) => iframeMode && '1px solid transparent'};
background-color: ${(p) => p.theme.v2_bg_color1};

& tspan{
  fill: ${(p) => isIframeDarkMode(p) && `${p.theme.v2_text_1}`};
}

& .bam-checkbox label, .counter{
  color: ${(p) => isIframeDarkMode(p) && p.theme.v2_text_3} !important;
}

& .toggle-label {
  color: ${(p) => isIframeDarkMode(p) && p.theme.v2_text_3} !important;
}
`

export const TopologyContainer = styled.div`
background-color: ${(p) => p.theme.v2_bg_color1};
margin-top: 23px;
height: 100%;
`

export const FiltersWrapper = styled.div`
display: flex;
justify-content: space-between;
margin: 20px 20px 1px 20px;
padding: 13px 0 4px 0;
border-bottom: solid 1px ${(p) => p.theme.v2_outline_2};
`

export const FullScreenDiv = styled.div`
opacity: ${(p) => p.iframeMode && '0'};
pointer-events: ${(p) => p.iframeMode && 'none'};
`