import extend from 'lodash/extend';
d3.tip = require('d3-tip');

angular
  .module('bigpanda.reports')
  .factory('D3HorizontalBarchartFactory', ['D3Utils', D3HorizontalBarchartFactory]);

function D3HorizontalBarchartFactory(D3Utils) {
  return function () {
    return new D3HorizontalBarchart(D3Utils);
  };
}

function D3HorizontalBarchart(D3Utils) {
  var self = this;

  self.animationEnabled = true;
  var animationDelay = 250,
    animationDuration = 750,
    barAnimationDelay = animationDelay,
    barAnimationDuration = animationDuration,
    valueLabelAnimationDelay = animationDelay,
    valueLabelAnimationDuration = animationDuration;

  self.margins = {
    desktop: {
      top: 36,
      bottom: 32,
      left: 26,
      right: 51,
    },
    mobile: {
      top: 36,
      bottom: 32,
      left: 26,
      right: 30,
    },
  };

  extend(self, {
    headersHeight: 20,
    tagsRelativeSize: 0.5,
    tagsMaxWidth: 390, // The size is calculated as min(tagsMaxWidth, tagsRelativeSize). The bars take up the rest of the width.
    tagsContainerMargins: { top: 5, bottom: 5, right: 20, left: 5 },
    barsContainerMargins: { top: 5, bottom: 5, right: 5, left: 5 },
    headersContainerMargins: { top: 4, bottom: 2, right: 5, left: 5 },
    barHeight: 22,
    barMargins: 6,
    valueLabelOffset: 5,
  });

  self.countScale = d3.scale.linear();

  self.tagTooltip = d3
    .tip()
    .attr('class', 'd3-tip')
    .html((d) => d);

  function textWrapper(width) {
    return function (d) {
      var textNode = d3.select(this),
        textLength = textNode.node().getComputedTextLength(),
        text = textNode.text(),
        truncated = false;

      // Glitches happen and we sometimes get called with zero/negative widths.
      // This causes an endless loop, so don't even try to truncate if that happens.
      if (textLength > width && width > 0) {
        truncated = true;

        // Initial split
        var middle = Math.round(text.length / 2);
        var leftPart = text.slice(0, middle),
          rightPart = text.slice(middle),
          slicingAlternator = false;

        while (textLength > width && text.length > 0) {
          if (slicingAlternator) {
            leftPart = leftPart.slice(0, -1);
          } else {
            rightPart = rightPart.slice(1);
          }

          textNode.text(`${leftPart}...${rightPart}`);
          textLength = textNode.node().getComputedTextLength();
          slicingAlternator = !slicingAlternator;
        }
      }

      textNode.attr('truncated', truncated);
    };
  }

  self.render = function (selection, layoutName) {
    selection.each(function (data) {
      var svg = d3.select(this).select('svg').selectAll('g.chart-container').data([data]);

      var gEnter = svg.enter().append('g').attr('class', 'chart-container');
      gEnter
        .append('g')
        .attr('class', 'headers-container')
        .append('text')
        .attr('class', 'tags-header');
      gEnter
        .select('.headers-container')
        .append('text')
        .text(self.valueHeader)
        .attr('class', 'bars-header');
      gEnter.append('g').attr('class', 'tags-container');
      gEnter.append('g').attr('class', 'bars-container');

      var headersContainer = svg
        .select('.headers-container')
        .select('.tags-header')
        .text(self.tagHeader);
      var tagsContainer = svg.select('.tags-container');
      var barsContainer = svg.select('.bars-container');

      // Update scale
      self.countScale.domain([0, d3.max(data, self.Y) * 1.1]);

      // Setup tooltip for truncated labels
      svg.call(self.tagTooltip);

      var tagLabels = tagsContainer.selectAll('g').data(data.map(self.X));

      tagLabels.enter().append('g').append('text').attr('class', 'tag-value');

      tagLabels.select('text').text((d) => d);

      tagLabels.exit().remove();

      var bars = barsContainer.selectAll('g').data(data.map(self.Y));

      var barEnter = bars.enter().append('g');
      barEnter.append('rect').attr('class', 'backdrop-bar');
      barEnter.append('rect').attr('class', 'value-bar');
      barEnter.append('text').attr('class', 'value-bar-label');

      bars.select('text').text(d3.format(','));

      bars.exit().remove();

      d3.select(this).call(self.resize, layoutName);

      if (self.animationEnabled) {
        bars
          .select('.value-bar')
          .attr('width', 0) // Initial value to transition from
          .transition()
          .delay(barAnimationDelay)
          .duration(barAnimationDuration)
          .attr('width', self.countScale);

        bars
          .select('text')
          .style('opacity', 0)
          .transition()
          .delay(valueLabelAnimationDelay)
          .duration(valueLabelAnimationDuration)
          .style('opacity', 1);
      }
    });
  };

  self.resize = function (selection, layoutName) {
    selection.each(function (data) {
      var dimensions = d3.select(this).node().getBoundingClientRect();
      var svgWidthWithMargins = dimensions.width,
        svgHeightWithMargins = dimensions.height;

      if (svgWidthWithMargins <= 0 || svgHeightWithMargins <= 0) {
        return;
      }

      return d3
        .select(this)
        .call(self[`${layoutName}Layout`], svgWidthWithMargins, svgHeightWithMargins);
    });
  };

  self.mobileLayout = function mobileLayout(selection, widthWithMargins, heightWithMargins) {
    selection.each(function (data) {
      var width = widthWithMargins - (self.margins.mobile.left + self.margins.mobile.right),
        height = heightWithMargins - (self.margins.mobile.top + self.margins.mobile.bottom);

      var dataDependentHeight =
        data.length * (self.barHeight + self.barMargins) * 2 +
        (self.margins.mobile.top + self.margins.mobile.bottom);
      var tagHeight = 7;

      self.countScale.range([0, width]);

      var svg = d3
        .select(this)
        .select('svg')
        .attr('width', width + (self.margins.mobile.left + self.margins.mobile.right))
        .attr('height', dataDependentHeight)
        .select('g.chart-container')
        .attr('transform', `translate(${self.margins.mobile.left},${self.margins.mobile.top})`);

      svg.select('.headers-container').attr('display', 'none');

      svg
        .select('.tags-container')
        .attr('transform', 'translate(0,0)')
        .selectAll('g')
        .attr('transform', (d, i) => `translate(0, ${i * (self.barHeight + self.barMargins) * 2})`)
        .select('text')
        .text((d) => d)
        .attr('y', null)
        .attr('dy', null)
        .each(textWrapper(width))
        .on('mouseover', null)
        .on('mouseout', null);

      svg
        .select('.bars-container')
        .attr('transform', 'translate(0,0)')
        .selectAll('g')
        .attr(
          'transform',
          (d, i) => `translate(0,${i * (self.barHeight + self.barMargins) * 2 + tagHeight})`
        )
        .selectAll('rect')
        .attr('height', self.barHeight);

      svg.select('.bars-container').selectAll('g').select('.backdrop-bar').attr('width', width);

      svg
        .select('.bars-container')
        .selectAll('g')
        .select('.value-bar')
        .attr('width', self.countScale);

      svg
        .select('.bars-container')
        .selectAll('g')
        .select('text')
        .attr('x', self.valueLabelOffset)
        .attr('y', (self.barHeight + self.barMargins) / 2)
        .attr('dy', '.19em')
        .attr('class', 'value-bar-label')
        .each(function (d) {
          // Offsets label to the right of the value bar if there's not enough room for it
          var textNode = d3.select(this);

          if (
            textNode.node().getComputedTextLength() + self.valueLabelOffset * 2 >
            self.countScale(d)
          ) {
            textNode.attr('x', self.countScale(d) + self.valueLabelOffset);
            textNode.attr('class', 'value-bar-label-reversed');
          }
        });
    });
  };

  self.desktopLayout = function desktopLayout(selection, widthWithMargins, heightWithMargins) {
    selection.each(function (data) {
      var width = widthWithMargins - (self.margins.desktop.left + self.margins.desktop.right),
        height = heightWithMargins - (self.margins.desktop.top + self.margins.desktop.bottom);

      var headersWidth =
          width - (self.headersContainerMargins.left + self.headersContainerMargins.right),
        tagsWidth =
          d3.min([width * self.tagsRelativeSize, self.tagsMaxWidth]) -
          (self.tagsContainerMargins.left + self.tagsContainerMargins.right),
        barsWidth =
          width - tagsWidth - (self.barsContainerMargins.left + self.barsContainerMargins.right);

      self.countScale.range([0, barsWidth]);

      var svg = d3
        .select(this)
        .select('svg')
        .attr('width', width + (self.margins.desktop.left + self.margins.desktop.right))
        .attr('height', height + (self.margins.desktop.top + self.margins.desktop.bottom))
        .select('g.chart-container')
        .attr('transform', `translate(${self.margins.desktop.left},${self.margins.desktop.top})`);

      svg
        .select('.headers-container')
        .attr('display', null)
        .attr(
          'transform',
          `translate(${self.headersContainerMargins.left},${self.headersContainerMargins.top})`
        )
        .select('.bars-header')
        .attr('x', self.tagsContainerMargins.left + tagsWidth + self.tagsContainerMargins.right);

      svg
        .select('.tags-container')
        .attr(
          'transform',
          `translate(${self.tagsContainerMargins.left},${
            self.headersHeight + self.tagsContainerMargins.top
          })`
        )
        .selectAll('g')
        .attr('transform', (d, i) => `translate(0, ${i * (self.barHeight + self.barMargins)})`)
        .select('text')
        .attr('y', (self.barHeight + self.barMargins) / 2)
        .attr('dy', '.19em')
        .text((d) => d)
        .each(textWrapper(tagsWidth))
        .on('mouseover', null)
        .on('mouseout', null);

      svg
        .select('.tags-container')
        .selectAll('g')
        .select('text[truncated=true]')
        .on('mouseover', (d) => {
          self.tagTooltip.attr('class', 'd3-tip animate').show(d);
        })
        .on('mouseout', (d) => {
          self.tagTooltip.attr('class', 'd3-tip').hide();
        });

      svg
        .select('.bars-container')
        .attr(
          'transform',
          `translate(${
            self.tagsContainerMargins.left +
            tagsWidth +
            self.tagsContainerMargins.right +
            self.barsContainerMargins.left
          },${self.headersHeight + self.tagsContainerMargins.top})`
        )
        .selectAll('g')
        .attr('transform', (d, i) => `translate(0,${i * (self.barHeight + self.barMargins)})`)
        .selectAll('rect')
        .attr('height', self.barHeight);

      svg.select('.bars-container').selectAll('g').select('.backdrop-bar').attr('width', barsWidth);

      svg
        .select('.bars-container')
        .selectAll('g')
        .select('.value-bar')
        .attr('width', self.countScale);

      svg
        .select('.bars-container')
        .selectAll('g')
        .select('text')
        .attr('x', self.valueLabelOffset)
        .attr('y', (self.barHeight + self.barMargins) / 2)
        .attr('dy', '.19em')
        .attr('class', 'value-bar-label')
        .each(function (d) {
          // Offsets label to the right of the value bar if there's not enough room for it
          var textNode = d3.select(this);

          if (
            textNode.node().getComputedTextLength() + self.valueLabelOffset * 2 >
            self.countScale(d)
          ) {
            textNode.attr('x', self.countScale(d) + self.valueLabelOffset);
            textNode.attr('class', 'value-bar-label-reversed');
          }
        });
    });
  };

  self.animate = function (_) {
    if (!arguments.length) {
      return self.animationEnabled;
    }

    self.animationEnabled = _;
    return self;
  };

  self.x = function (_) {
    if (!arguments.length) {
      return self.X;
    }

    self.X = _;
    return self;
  };

  self.y = function (_) {
    if (!arguments.length) {
      return self.Y;
    }

    self.Y = _;
    return self;
  };

  self.barsWidth = function (_) {
    if (!arguments.length) {
      return self.barsRelativeSize;
    }

    self.barsRelativeSize = _;
    return self;
  };

  self.tagsWidth = function (_) {
    if (!arguments.length) {
      return self.tagsRelativeSize;
    }

    self.tagsRelativeSize = _;
    return self;
  };

  self.tagColumnName = function (_) {
    if (!arguments.length) {
      return self.tagHeader;
    }

    self.tagHeader = _;
    return self;
  };

  self.valueColumnName = function (_) {
    if (!arguments.length) {
      return self.valueHeader;
    }

    self.valueHeader = _;
    return self;
  };
}
