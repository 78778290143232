import { DraguniqueIcon, HBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const Container = styled(HBox)`
    display: flex;
    line-height: 0;
    min-width: 29px;
`;

export const Draggable = styled(HBox)`
    width: 12px;
    margin-top: 2px;
    color: ${({ theme, isDragging }) => (isDragging ? theme.bp_gray_08 : theme.bp_gray_05)};

    :hover {
        color: ${({ theme }) => theme.bp_gray_08};
    }
`;

export const CollapseIcon = HBox;

export const DragIcon = styled(DraguniqueIcon)`
    display: ${({ isActive }) => (isActive ? 'inline-block' : 'none')};
    fill: ${({ theme }) => theme.bp_gray_05};
`;
