import { BamBpqlInput } from '@bp/bam';
import BPQL from '@bp/bpql';
import {
  AddIcon,
  Checkbox,
  EditIcon,
  Modal,
  TwoColumnSideFooterLayout,
  useForm,
} from '@bp/kung-fu';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadAlertTags } from '../../../../../app/react/common/alert_tags/actions';
import { selectors as FTSelectors } from '../../../../../app/react/user/feature_toggles';
import onboardingDic from '../../../onboarding/src/dictionary';
import { completeStep } from '../../../onboarding/src/endpoints/onboarding';
import { shouldAllowOnboarding } from '../../../onboarding/src/helpers/shouldAllowOnboarding';
import { QueryKeys } from '../api/constants';
import { createAlertFilter, getPreviewFilter, updateAlertFilter } from '../api/endpoints';
import dictionary from '../dictionary';
import { AlertFilter } from '../types/AlertFilter';
import { FilterForm } from '../types/FilterForm';
import { PreviewResponse } from '../types/PreviewReponse';
import { PreviewState } from '../types/PreviewState';
import alertFilterUrl from '../utils/alertFilterUrl';
import { showFailureMessageForFilters, showInProgressMessageForFilters, showSuccessMessageForFilters } from '../utils/toasters';
import { AlertFilterForm } from './AlertFilterForm';
import { FilterPreviewKF } from './FilterPreview/FilterPreviewKF';
import { Footer } from './style';

const validateDisplayQuery = (val: string): string => (val ? BamBpqlInput.helpers.BpqlInputValidator(val) : dictionary.condition_is_required);

const isValidCondition = (condition = ''): boolean => !validateDisplayQuery(condition);

interface IProps {
  title: string;
  isNew?: boolean;
  isDuplicate?: boolean;
  filter?: AlertFilter;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export function AlertFilterFormWrapper({
  filter = {} as AlertFilter,
  setModalOpen,
  title,
  isNew,
  isDuplicate,
}: IProps): JSX.Element {
  const [canPreview, setCanPreview] = useState(isValidCondition(filter.condition?.displayQuery));
  const [isLoading, setIsLoading] = useState(false);
  const [isConditionChanged, setIsConditionChanged] = useState(false);
  const [previewState, setPreviewState] = useState<PreviewState>();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const updateFilterMutation = useMutation(updateAlertFilter);
  const createFilterMutation = useMutation(createAlertFilter);
  const isOnboarding = shouldAllowOnboarding(useSelector(FTSelectors.getFeatureToggles));

  const isEdit = !(isNew || isDuplicate);

  useEffect(() => {
    dispatch(loadAlertTags());
    getPreviewFilters();
  }, []);

  const {
    updateField,
    state,
    register,
    validateForm,
  } = useForm<FilterForm>({
    id: filter.id,
    name: filter.name,
    condition: filter.condition?.displayQuery,
    active: Boolean(isNew || filter.active),
    description: filter.description,
  });

  const onUpdateField = (fieldName: string, value: string): void => {
    if (fieldName === 'condition') {
      setCanPreview(isValidCondition(value));
    }
    updateField(fieldName, value);
  };

  const onSubmit = (): void => {
    const alertFilterValues = {
      ...state,
      bpql: BPQL.buildParserFromGrammer('correlation')(state.condition),
    };

    if (!isEdit) {
      const toasterId = showInProgressMessageForFilters('Creating');
      createFilterMutation.mutate(alertFilterValues, {
        onSuccess: (newFilter: AlertFilter): void => {
          queryClient.invalidateQueries([QueryKeys.AlertFilter]);
          window.location.href = alertFilterUrl(newFilter.id);
          showSuccessMessageForFilters('created', toasterId);
          setModalOpen(false);
          if (isOnboarding) {
            completeStep(onboardingDic.onboardingSteps.alert_filtering);
          }
        },
        onError: (): void => {
          showFailureMessageForFilters('create', toasterId);
        },
      });
      return;
    }

    const toasterId = showInProgressMessageForFilters('Updating');
    updateFilterMutation.mutate(alertFilterValues, {
      onSuccess: (): void => {
        queryClient.invalidateQueries([QueryKeys.AlertFilter]);
        showSuccessMessageForFilters('updated', toasterId);
        setModalOpen(false);
      },
      onError: (): void => {
        showFailureMessageForFilters('update', toasterId);
      },
    });
  };

  const getPreviewFilters = async (): Promise<void> => {
    if (!canPreview) return;
    const { condition } = state;
    const conditionObj = BPQL.buildParserFromGrammer('correlation')(condition);
    setIsLoading(true);
    const data = await queryClient.fetchQuery<PreviewResponse>(
      [QueryKeys.FilterPreview],
      () => getPreviewFilter(conditionObj),
    );
    setIsConditionChanged(false);
    setIsLoading(false);
    setPreviewState({ ...data, hasPreviewed: true });
  };

  return (
    <Modal
      ariaDescribedby={dictionary.alert_filter}
      ariaLabelledby={dictionary.alert_filter}
      controlledState
      setControlledState={setModalOpen}
      content={(
        <TwoColumnSideFooterLayout
          leftPanel={<AlertFilterForm register={register} updateField={onUpdateField} condition={filter?.condition?.displayQuery} />}
          leftColumnProps={{
            width: '550px',
          }}
          rightPanel={(
            <FilterPreviewKF
              canPreview={canPreview}
              getPreviewFilters={getPreviewFilters}
              isLoading={isLoading}
              previewState={previewState}
              isConditionChanged={isConditionChanged}
            />
          )}
          footer={(
            <Footer>
              <Checkbox
                text={dictionary.create_as_inactive}
                checked
                name="active"
                ariaLabel="create filter as inactive"
                onChange={(): void => {
                // input.onChange(!e.currentTarget.checked);
                }}
              />
            </Footer>
          )}
        />
      )}
      footerProps={{
        actionButtonText: isEdit ? dictionary.update_filter : dictionary.create_filter,
        buttonWidth: '107px',
        cancelButtonText: dictionary.cancel,
        isAsync: true,
        onActionClick: (): boolean => validateForm(onSubmit),
      }}
      headerProps={{
        title,
        icon: isEdit ? <EditIcon /> : <AddIcon />,
      }}
      expand
    />
  );
}
