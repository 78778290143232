import { TagType } from '../../types';

interface FormRule {
  type: TagType;
}

const DEFAULT_TYPE = TagType.MultiType;

export const calcTagType = (rules: Record<string, FormRule> = {}): TagType => {
  const rulesArray = Object.values(rules);

  if (!rulesArray.length) {
    return DEFAULT_TYPE;
  }

  const firstRuleType = rulesArray[0].type;
  const doAllRulesHaveTheSameType = rulesArray.filter(Boolean).every((rule) => firstRuleType === rule.type);

  return doAllRulesHaveTheSameType ? firstRuleType : DEFAULT_TYPE;
};

export default calcTagType;
