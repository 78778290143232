import find from 'lodash/find';
import extend from 'lodash/extend';
import map from 'lodash/map';
import remove from 'lodash/remove';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import omit from 'lodash/omit';
import { incidentTagClosedListTypes } from 'react/modules/settings/incident_labels';
import {
  getIncidentLabel,
  getMultipleIncidentTagDefinitions,
} from '../../../../common/endpoints/incident-labels';
import { isTagTypeIsClosedListMap } from '../../../../../workspaces/apps/incident-tags/src/IncidentTagsGridCellV2/constants';

angular.module('bigpanda').controller('EnvironmentEditorCtrl', EnvironmentEditorCtrl);

function EnvironmentEditorCtrl(
  $scope,
  $animate,
  $log,
  $q,
  $timeout,
  $filter,
  $state,
  ModalService,
  EnvironmentsService,
  CompatibilityService,
  notificationService,
  SiftService,
  UserIntegrationsStore,
  TopbarService,
  previousStateService,
  TagsService,
  UserIntegrationsUtils,
  EnvironmentsUtils,
  UsersStore,
  UserFeatureTogglesService,
  EnvironmentGroupsStore,
  EnvironmentGroupsService,
  EnvironmentGroupConstants,
  pubSubService,
  deepCloneObject,
  AssignPanelUtils,
  LabelsStore,
  Permissions,
  PERMISSIONS_NAMES
) {
  const vm = this;
  const MAX_ENV_NAME_CHAR = 200;

  const defaultNewRule = {
    warnings: true,
    acknowledged: false,
    source: null,
    ands: [],
    checks: [],
  };

  const allSystemsSource = {
    display: 'All Systems',
    value: '*.*',
  };

  let environmentId = $state.params.environmentId;
  const BASIC_ENV = 'basic';
  const ADVANCED_ENV = 'advanced';
  vm.tooltip = 'overview/environments/editor/tooltip';

  $scope.updatePage('environments');

  vm.action = environmentId ? 'Edit' : 'Create';
  vm.saveButtonProductId = environmentId ? 'update_environment' : 'create_new_environment';
  vm.name = '';
  vm.environmentGroups = {
    allGroups: [],
    options: [],
    selectedGroup: null,
    tempSelectedGroup: null,
    selectionError: null,
  };
  vm.beforeSubmit = true;
  vm.filterLoadingForAllEnv = false;
  vm.filterLoading = true;
  vm.saving = false;
  vm.currentEnv = null;
  vm.isBpqlValueValid = true;
  vm.currentAdvancedEnv = {};
  vm.permissionError = null;
  vm.nameError = null;
  vm.validateName = validateName;
  vm.selectGroup = selectGroup;
  vm.discardChanges = discardChanges;
  vm.sources = [];
  vm.rules = [];
  vm.dupEnvironmentId = $state.params.dupEnvironmentId;
  vm.selectedRoles = [];
  vm.selectedReadOnlyRoles = [];
  vm.disableBasicEditor = false;
  vm.originalEnvFromServer = null;
  vm.roles = [];
  vm.tags = [];
  vm.alertTags = [];
  vm.incidentTagIsSelectedByRule = [];
  vm.isIncidentTagIsSelected = false;
  vm.isIncidentTagAdvanceModeQuery = false;
  const INCIDENT_TAG_NAMESPACE = 'incident.';
  const envWithIncidentTagsFT = UserFeatureTogglesService.getToggle('env_with_incident_tags');
  const showPreview = UserFeatureTogglesService.getToggle('enable_namespace_for_search');
  vm.envWithIncidentTagsFT = envWithIncidentTagsFT === null ? true : envWithIncidentTagsFT;
  const assigneeIncidentTagsFT = UserFeatureTogglesService.getToggle('assignee_env_filter_enable');
  vm.assigneeIncidentTagsFT = assigneeIncidentTagsFT === null ? true : assigneeIncidentTagsFT;
  vm.operators = [
    { display: 'Equals To', id: '$eq', type: 'string' },
    { display: 'Not Equals To', id: '$ne', type: 'string' },
    { display: 'In List', id: '$in', type: 'list' },
    { display: 'Not In List', id: '$nin', type: 'list' },
  ];
  const priorityTagId = 'itd_priority_1';
  const assigneeId = 'assignee';
  const tagTypes = { incidentTags: 'INCIDENT_TAGS', alertTags: 'ALERT_TAGS' };
  vm.incidentMetadata = vm.assigneeIncidentTagsFT
    ? [
        { id: 'id', text: 'Incident ID', type: 'INCIDENT_METADATA' },
        { id: assigneeId, text: 'Assignee', type: 'INCIDENT_METADATA' },
      ]
    : [{ id: 'id', text: 'Incident ID', type: 'INCIDENT_METADATA' }];

  if (vm.dupEnvironmentId) {
    vm.action = 'Duplicate';
    environmentId = vm.dupEnvironmentId;
  }

  vm.save = save;
  vm.cancel = cancel;
  vm.setChecks = setChecks;
  vm.showModal = showModal;
  vm.showInvalidPreview = showInvalidPreview;
  vm.isNotPreviewRefresh = isNotPreviewRefresh;
  vm.featureToggle = UserFeatureTogglesService.getToggle('enable_global_auth');
  vm.isEqual = isEqual;
  vm.isDirty = isDirty;
  vm.rulesChanged = false;
  vm.rulesLoaded = false;
  vm.originalBpqlValue = null;
  vm.onBpqlChange = onBpqlChange;
  vm.tooltipMessage = 'The query was saved in advanced mode and can’t be translated to basic';
  vm.tooltipMessageInvalid = showPreview
    ? 'Fill all the required fields in order to generate the advanced query'
    : '';
  vm.loading = false;
  vm.refresh = debounce(refreshFcn, 300);
  vm.setOriginalBpqlValue = setOriginalBpqlValue;
  vm.isValid = isValid;
  vm.priorities = [];

  init().catch((reason) => {
    goToPreviousState(reason);
  });

  $scope.$on('$destroy', () => {
    TopbarService.setShowTopbar(true);
    rulesWatcher();
  });

  const rulesWatcher = $scope.$watch(
    () => vm.rules,
    (newRules, oldRules) => {
      if (showPreview) {
        vm.disableAdvnacedEditor = get(newRules, '[0].source') ? !vm.isValid(true) : false;
      } else if (vm.incidentTagIsSelectedByRule.some((value) => value === true)) {
        vm.disableAdvnacedEditor = true;
        vm.tooltipMessageInvalid =
          'Advanced mode is not supported while using Incident Tag as a Source Tag';
        vm.isIncidentTagIsSelected = true;
      } else if (get(newRules, '[0].source') && !vm.isValid(true)) {
        vm.disableAdvnacedEditor = true;
        vm.tooltipMessageInvalid =
          'Fill all the required fields in order to generate the advanced query';
      } else {
        vm.disableAdvnacedEditor = false;
        vm.isIncidentTagIsSelected = false;
      }
      if (newRules === oldRules) {
        return;
      }

      vm.environmentEditorForm.$dirty = true;
      // Disable initial load
      if (vm.rulesLoaded && oldRules.length) {
        vm.rulesChanged = true;
      }
    },
    true
  );

  function init() {
    TopbarService.setShowTopbar(false);

    return loadTags().then(() => {
      UsersStore.getRolesList(true).then((rolesList) => {
        vm.roles = rolesList;
      });

      refreshEnvironmentGroups();
      EnvironmentGroupsStore.subscribe($scope, { storeUpdated: () => refreshEnvironmentGroups() });

      return UserIntegrationsStore.getIntegrations().then((integrations) => {
        vm.sources = UserIntegrationsUtils.getIntegrationsForDisplay(
          filter(integrations, { type: 'alert' })
        );
        vm.sources.unshift(allSystemsSource);

        if (environmentId) {
          vm.loading = true;
          return EnvironmentsService.getEnvWithRoles(environmentId).then(
            (env) => {
              if (!env) {
                vm.loading = false;
                return $q.reject(`Cannot find environment '${environmentId}'...`);
              }

              if (env.bpql_filter) {
                vm.isBpqlValueValid = true;
                vm.currentEditorMode = ADVANCED_ENV;
                vm.originalEnvFromServer = ADVANCED_ENV;
                vm.disableBasicEditor = true;
                vm.originalBpqlValue = env.bpql_filter;

                return EnvironmentsService.getBpqlEnv(environmentId).then((advancedEnv) => {
                  vm.currentAdvancedEnv = { ...env, ...advancedEnv };
                  populateEditorFromAdvancedEnv();
                  triggerPreview(env.bpql_filter);
                  vm.loading = false;
                  return $q.when();
                });
              }

              vm.originalEnvFromServer = BASIC_ENV;
              vm.currentEditorMode = BASIC_ENV;
              vm.currentEnv = { ...env };
              vm.loading = false;

              if (env.name.toLowerCase() === 'all') {
                vm.filterLoadingForAllEnv = true;
                populateEditor();
                return $q.when();
              }

              if (vm.dupEnvironmentId) {
                delete vm.currentEnv._id;
              }

              populateEditor();

              $timeout(() => {
                if (!vm.environmentEditorForm) {
                  return;
                }
                vm.currentEditorMode = BASIC_ENV;

                ['name', 'roles', 'readOnlyRoles'].forEach((formField) => {
                  if (vm.environmentEditorForm[formField]) {
                    vm.environmentEditorForm[formField].$pristine = false;
                  }
                });
              }, 50);
              return $q.when();
            },
            () => goToPreviousState(`Can't find environment '${environmentId}'...`)
          );
        }

        vm.currentEditorMode = BASIC_ENV;
        populateEditor();
        return $q.when();
      });
    });
  }

  function getListOperators() {
    return filter(vm.operators, (operator) => operator.type === 'list');
  }

  function populateEditorFromAdvancedEnv() {
    vm.name = vm.currentAdvancedEnv.name;
    if (vm.dupEnvironmentId) {
      vm.name = `Duplicate of ${vm.name}`;
      environmentId = null;
      delete vm.currentAdvancedEnv.id;
    }

    if (vm.currentAdvancedEnv && !isEmpty(vm.currentAdvancedEnv.roles)) {
      vm.selectedRoles = [...vm.currentAdvancedEnv.roles];
    }

    if (vm.currentAdvancedEnv && !isEmpty(vm.currentAdvancedEnv.readOnlyRoles)) {
      vm.selectedReadOnlyRoles = [...vm.currentAdvancedEnv.readOnlyRoles];
    }

    EnvironmentsService.get().then((environments) => {
      vm.environments = environments;
    });
  }

  function refreshEnvironmentGroups(forceReload) {
    EnvironmentGroupsStore.getEnvironmentGroups(forceReload).then((environmentGroups) => {
      vm.environmentGroups.allGroups = environmentGroups;
      buildEnvironmentGroupsOptions();

      if (!vm.environmentGroups.selectedGroup) {
        if (environmentId) {
          vm.environmentGroups.selectedGroup =
            environmentGroups.find((group) => group.environmentIds.includes(environmentId)) ||
            getDefaultGroup();
        } else {
          vm.environmentGroups.selectedGroup = getDefaultGroup();
        }
      } else {
        // Even though there already is a selected group in this state, after a refresh the copy held in this controller might be stale.
        // So we want to take the refreshed copy that has just arrived from the store, identified by the selected group we already hold.
        vm.environmentGroups.selectedGroup =
          environmentGroups.find((group) => group.id === vm.environmentGroups.selectedGroup.id) ||
          getDefaultGroup();
      }

      vm.environmentGroups.tempSelectedGroup = vm.environmentGroups.selectedGroup;
    });
  }

  function buildEnvironmentGroupsOptions() {
    vm.environmentGroups.options = clone(vm.environmentGroups.allGroups);

    vm.environmentGroups.options.unshift(getDefaultGroup());

    vm.environmentGroups.options.push({
      id: EnvironmentGroupConstants.ADD_NEW_GROUP_OPTION_ID,
      name: EnvironmentGroupConstants.ADD_NEW_GROUP_OPTION_NAME,
    });
  }

  function getDefaultGroup() {
    return {
      id: EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_ID,
      name: `${EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_NAME} ${EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_SUBTITLE}`,
    };
  }

  function environmentGroupCreated(environmentGroup) {
    if (environmentGroup) {
      vm.environmentGroups.selectedGroup = environmentGroup;
      vm.environmentGroups.tempSelectedGroup = environmentGroup;

      pubSubService.broadcast('environment.group.created', environmentGroup, 'Env Builder');
    }

    return refreshEnvironmentGroups(true);
  }

  function environmentGroupCreationCancelled() {
    if (
      vm.environmentGroups.tempSelectedGroup &&
      vm.environmentGroups.tempSelectedGroup.id ===
        EnvironmentGroupConstants.ADD_NEW_GROUP_OPTION_ID
    ) {
      // If no group was created, make sure the selected option is not the one of the pre-defined Add New Group one.
      vm.environmentGroups.tempSelectedGroup = vm.environmentGroups.selectedGroup;
    }

    return refreshEnvironmentGroups(true);
  }

  function handleEnvError(errorTxt) {
    let errorMsg = 'Error saving environment...';

    if (errorTxt) {
      errorMsg += `<br/>${errorTxt}`;
    }

    notificationService.error(errorMsg);
    return EnvironmentsService.get()
      .then(EnvironmentsUtils.getDefaultEnv)
      .then((env) => {
        $state.go('app.overview.incidents.list', { environment: env._id, folder: 'active' });
      });
  }

  function saveAdvancedEnv() {
    let advancedComplete = false;
    const modalScope = {
      advancedComplete: () => advancedComplete,
    };

    showModal(
      null,
      null,
      'overview/environments/actions/advanced_environment_saving',
      modalScope,
      true
    );

    const env = {
      ...vm.currentAdvancedEnv,
      name: vm.name,
      roles: vm.selectedRoles,
      read_only_roles: vm.selectedReadOnlyRoles,
    };

    const changedFromSiftToBpql =
      vm.originalEnvFromServer === BASIC_ENV && vm.currentEditorMode === ADVANCED_ENV;

    if (changedFromSiftToBpql) {
      env.id = vm.currentEnv._id;
    }

    if (vm.currentAdvancedEnv.id) {
      env.updateEnvRolesOnly = onlyEnvRolesChanged();
    }

    if (!vm.currentAdvancedEnv.filter) {
      env.filter = vm.originalBpqlValue;
    }
    EnvironmentsService.saveBpqlEnv(
      env,
      vm.selectedRoles,
      vm.selectedReadOnlyRoles,
      onlyEnvRolesChanged()
    ).then((envResult) => {
      persistEnvironmentGroup(envResult).then(() => {
        $timeout(() => {
          advancedComplete = true;
          return $timeout(() => {
            ModalService.hide();
            const params = { environment: envResult.id || envResult._id, folder: 'active' };

            previousStateService.resetPreviousState();
            return $state.go('app.overview.incidents.list', params);
          }, 1000);
        }, 1000);
      });
    });
  }

  function save() {
    vm.beforeSubmit = false;
    vm.saving = true;
    const scope = {
      dialogTitle: 'Saving in advanced mode',
      titleIconClass: 'bp-icon-information',
      cancelButtonCaption: 'Cancel',
      submitButtonCaption: 'Save anyway',
      bodyTemplateClass: 'advanced-env-modal-content',
      preSave: () => {
        saveAdvancedEnv();
        ModalService.hide();
        return false;
      },
    };

    if (vm.currentEditorMode === ADVANCED_ENV) {
      if (!vm.isBpqlValueValid || !isValid()) return;

      if (!vm.disableBasicEditor) {
        showModal(
          null,
          null,
          'overview/environments/editor/advanced_rules_editor/advanced_env_popup',
          scope,
          true
        );
      } else {
        saveAdvancedEnv();
      }
    } else if (isValid()) {
      if (!isDirty()) {
        // Go inside in case -> no change in the env form
        EnvironmentsService.getEnvById(vm.currentEnv._id).then((isEnvExist) => {
          if (isEnvExist) {
            goToPreviousState('Nothing to save, No changes were made.', true);
          } else {
            handleEnvError('environment no longer exists');
          }
        });
      } else if (nameWillBeDuplicate()) {
        // Go inside in case -> user duplicate env
        showModal(
          'Duplicate name',
          `Please change '${vm.name}' as it is a duplicate of an existing environment...`
        );
      } else {
        // Go inside in case -> user edit or create env
        const env = envToSift(true);
        let complete = false;
        const modalScope = {
          complete: () => complete,
        };

        showModal(null, null, 'overview/environments/actions/environment_saving', modalScope, true);
        EnvironmentsService.save(
          env,
          vm.selectedRoles,
          vm.selectedReadOnlyRoles,
          onlyEnvRolesChanged()
        ).then(
          (envResult) => {
            persistEnvironmentGroup(envResult).then(() => {
              $timeout(() => {
                complete = true;
                return $timeout(() => {
                  ModalService.hide();
                  const params = { environment: environmentId || envResult._id, folder: 'active' };
                  const previousState = previousStateService.getPreviousState();

                  if (previousState && previousState.name.indexOf('app.rules') > -1) {
                    return goToPreviousState();
                  }

                  previousStateService.resetPreviousState();
                  return $state.go('app.overview.incidents.list', params);
                }, 1000);
              }, 1000);
            });
          },
          (err) =>
            $timeout(() => {
              let errorMsg = 'Please validate your rules and try again.';
              if (err && err.data.response.errors) {
                errorMsg = err.data.response.errors[0];
              }
              ModalService.hide();
              $log.error(`error saving envionemnts ${errorMsg}`);
              handleEnvError(errorMsg);
            }, 1000)
        );
      }
    }
  }

  function persistEnvironmentGroup(persistedEnvironment) {
    const environmentPreviousGroup = deepCloneObject.cloneDeep(
      vm.environmentGroups.allGroups.find((group) =>
        group.environmentIds.includes(persistedEnvironment._id)
      ),
      { lodash: true }
    );
    const environmentNewGroup =
      vm.environmentGroups.selectedGroup &&
      vm.environmentGroups.selectedGroup.id !== EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_ID
        ? deepCloneObject.cloneDeep(vm.environmentGroups.selectedGroup, { lodash: true })
        : null;

    removeEnvironmentFromGroup(
      environmentPreviousGroup,
      persistedEnvironment._id || persistedEnvironment.id
    );
    addEnvironmentToGroup(environmentNewGroup, persistedEnvironment._id || persistedEnvironment.id);

    let promise;

    if (
      environmentPreviousGroup &&
      environmentNewGroup &&
      environmentPreviousGroup.id !== environmentNewGroup.id
    ) {
      promise = EnvironmentGroupsStore.updateEnvironment(
        persistedEnvironment._id,
        environmentPreviousGroup.id,
        environmentNewGroup.id
      );
    } else if (environmentPreviousGroup && !environmentNewGroup) {
      promise = EnvironmentGroupsStore.update(environmentPreviousGroup);
    } else if (environmentNewGroup && !environmentPreviousGroup) {
      promise = EnvironmentGroupsStore.update(environmentNewGroup);
    } else {
      promise = $q.resolve({});
    }

    return promise;
  }

  function addEnvironmentToGroup(group, environmentId) {
    if (!group) {
      return;
    }

    if (!group.environmentIds) {
      group.environmentIds = [environmentId];
    } else if (!group.environmentIds.includes(environmentId)) {
      group.environmentIds.push(environmentId);
    }
  }

  function removeEnvironmentFromGroup(group, environmentId) {
    if (group && group.environmentIds) {
      remove(group.environmentIds, (id) => id === environmentId);
    }
  }

  function cancel() {
    if (isDirty()) {
      showModal(null, null, 'overview/environments/editor/environment_editor_exit', {
        exit: () => {
          ModalService.hide();
          goToPreviousState();
          return false;
        },
        cancel: () => {
          ModalService.hide();
          return false;
        },
      });
    } else {
      goToPreviousState();
    }
  }

  function validateName() {
    let error = null;
    if (!vm.name || !vm.name.length || !vm.name.match(/([^\s])/gi)) {
      error = 'Enter at least one Alphanumeric character';
    } else if (vm.name.match(/([^A-Z0-9-\.\s])/gi)) {
      error = 'Name can include Alphanumeric character, hyphen, space and dot only';
    } else if (vm.name.length > MAX_ENV_NAME_CHAR) {
      error = `Name can't have more than ${MAX_ENV_NAME_CHAR} character`;
    }
    vm.nameError = error;
    if (error) {
      return false;
    }
    return true;
  }

  function selectGroup(value) {
    if (!value) {
      return;
    }

    if (value.id === EnvironmentGroupConstants.ADD_NEW_GROUP_OPTION_ID) {
      EnvironmentGroupsService.showEditModal(vm.environmentGroups.allGroups)
        .then(environmentGroupCreated)
        .catch(environmentGroupCreationCancelled);
    } else {
      vm.environmentGroups.selectedGroup = vm.environmentGroups.tempSelectedGroup;
    }
  }

  function goToPreviousState(message, info) {
    $animate.enabled(false);

    if (!message) {
      return previousStateService.goToPreviousState().then(() => $animate.enabled(true));
    }

    notificationService[info ? 'info' : 'error'](message);
    return previousStateService.goToPreviousState().then(() => $animate.enabled(true));
  }

  function isEqual(arrayA, arrayB) {
    const a = new Set(arrayA);
    const b = new Set(arrayB);
    const intersection = function (a, b) {
      const commonElements = new Set();
      for (let elem of b) {
        if (a.has(elem)) {
          commonElements.add(elem);
        }
      }
      return commonElements;
    };
    return a.size === b.size && a.size === intersection(a, b).size;
  }

  function hasRolesChanged(selectedRoles, currentEnv, rolesListToCheck) {
    const selectedRolesNames = selectedRoles.map((role) => role.name.toLowerCase());
    if (currentEnv && currentEnv[rolesListToCheck]) {
      const currentEnvRolesNames = currentEnv[rolesListToCheck].map((role) =>
        role.name.toLowerCase()
      );
      return !isEqual(selectedRolesNames, currentEnvRolesNames);
    }
    return false;
  }

  function hasNameChanged() {
    const env = vm.currentEditorMode === ADVANCED_ENV ? 'currentAdvancedEnv' : 'currentEnv';
    if (vm[env] && vm[env].name) return vm.name !== vm[env].name;
    return vm.name !== '';
  }

  function isDirty() {
    if (vm.currentEditorMode === BASIC_ENV) {
      if (
        !!vm.currentEnv &&
        (hasRolesChanged(vm.selectedRoles, vm.currentEnv, 'roles') ||
          hasRolesChanged(vm.selectedReadOnlyRoles, vm.currentEnv, 'readOnlyRoles') ||
          hasNameChanged())
      ) {
        return true;
      }

      // This FUGLY hack is due to the fact that the form is set to dirty if the roles/readOnlyRole are empty.
      // because... angularjs. /ragequit
      return (
        vm.rulesChanged ||
        (vm.environmentEditorForm.$dirty === true &&
          vm.environmentEditorForm.$$controls.filter((x) => x.$dirty).length > 0)
      );
    }
    return true;
  }

  function onlyEnvRolesChanged() {
    const isSelectedRolesChanged = hasRolesChanged(
      vm.selectedRoles,
      vm.currentEditorMode === ADVANCED_ENV ? vm.currentAdvancedEnv : vm.currentEnv,
      'roles'
    );
    const isRolesChanged = hasRolesChanged(
      vm.selectedReadOnlyRoles,
      vm.currentEditorMode === ADVANCED_ENV ? vm.currentAdvancedEnv : vm.currentEnv,
      'readOnlyRoles'
    );

    if (
      hasNameChanged() ||
      vm.rulesChanged ||
      (vm.environmentEditorForm.$dirty === true &&
        vm.environmentEditorForm.$$controls.filter((x) => x.$dirty).length > 0)
    ) {
      return false;
    }
    if (isSelectedRolesChanged || isRolesChanged) {
      return true;
    }
  }

  function isValid(disregardName) {
    if (vm.environmentEditorForm) {
      // This lovely piece of nastyness is due to the fact that bamboo sets the required validity check automagically.
      // making it imposible (IMPOSSIBRU) to validate the form without requiring input to our roles select widgets.
      const formValid =
        vm.environmentEditorForm.$valid ||
        (!vm.environmentEditorForm.$valid && !vm.environmentEditorForm.$error) ||
        (vm.environmentEditorForm.$error &&
          vm.environmentEditorForm.$error.required &&
          vm.environmentEditorForm.$error.required.filter(
            (f) => !['roles', 'readOnlyRoles'].includes(f.$$attr.name)
          ).length === 0);

      return disregardName ? formValid : formValid && validateName();
    }

    return false;
  }

  function populateEditor() {
    if (!vm.currentEnv) {
      vm.rules = [clone(defaultNewRule)];
      vm.rulesLoaded = true;
    } else {
      vm.name = vm.currentEnv.name;
      if (vm.dupEnvironmentId) {
        vm.name = `Duplicate of ${vm.name}`;
        environmentId = null;
        delete vm.currentEnv._id;
      }
      if (vm.currentEnv && vm.currentEnv.roles && vm.currentEnv.roles.length > 0) {
        vm.selectedRoles = [...vm.currentEnv.roles];
      }
      if (vm.currentEnv && vm.currentEnv.readOnlyRoles && vm.currentEnv.readOnlyRoles.length > 0) {
        vm.selectedReadOnlyRoles = [...vm.currentEnv.readOnlyRoles];
      }
      if (vm.currentEnv.name.toLowerCase() !== 'all') {
        $q.all(map(SiftService.convertFilter(vm.currentEnv.filter), vm.setChecks)).then((rules) => {
          rules.forEach((rule, index) => {
            rule.ands.forEach((and) => {
              const incidentTags = Object.values(vm.tags).find(
                (tag) => tag.value === tagTypes.incidentTags
              );
              const currentIncidentTag = incidentTags.options.find((tag) => tag.id === and.check);
              and.operators = vm.operators;
              and.isPrioritySelected = and.check === priorityTagId;
              and.isAssigneeSelected = and.check === assigneeId;
              and.isClosedListSelected =
                currentIncidentTag && isTagTypeIsClosedListMap[currentIncidentTag.incidentTagType];
              and.operatorObject = and.operators.find((operator) => operator.id === and.operator);

              if (and.isClosedListSelected) {
                and.incidentTagType = currentIncidentTag.incidentTagType;
                and.closedListValues = currentIncidentTag.closedListValues;
                if (and.value)
                  and.value = and.value.split(',').map((value) => ({ id: value, text: value }));
                and.operators = getListOperators();
              }
              if (and.isPrioritySelected) {
                const selectedPriorities = and.value.split(',');
                and.value = [];
                selectedPriorities.forEach((selectedPriority) => {
                  and.value.push(
                    vm.priorities.find((priority) => priority.id.toString() === selectedPriority)
                  );
                });
                and.operators = getListOperators();
              }
              if (and.isAssigneeSelected) {
                and.ruleTypeList = and.operatorObject.type === 'list';
                if (and.ruleTypeList) {
                  const selectedUsers = Array.isArray(and.value) ? and.value : and.value.split(',');
                  and.value = [];
                  selectedUsers.forEach((selectedUser) => {
                    and.value.push(vm.userList.find((user) => user.email === selectedUser));
                  });
                  and.objValue = and.value;
                  and.strValue = and.value.map((val) => val.id).join();
                } else {
                  const selectedUsers = Array.isArray(and.value) ? and.value.join() : and.value;
                  and.value = [];
                  and.value.push(selectedUsers);
                  and.strValue = selectedUsers;
                }
              }
              and.tag = { id: and.check, type: and.type };
              checkIfIncidentTagWasSelected(and.type, index);
            });
          });
          vm.rules = rules;
          vm.rulesLoaded = true;
        });
      } else {
        vm.rules = {};
        vm.rulesLoaded = true;
      }
    }
    EnvironmentsService.get().then((environments) => {
      vm.environments = environments;
    });
  }

  function envToSift(escapeRegexes, frontend) {
    const env = {
      name: vm.name,
    };

    if (environmentId) {
      env._id = environmentId;
    }
    if (vm.name.toLowerCase() !== 'all') {
      env.filter = SiftService.rulesToFilter(vm.rules, escapeRegexes, frontend);
    } else {
      env.filter = {};
    }
    return env;
  }

  function showModal(title, content, templateUrl, templateScope, noEscape) {
    const modalParams = {
      escapeToClose: !noEscape && CompatibilityService.canClosePopupsWithEscape(),
    };

    if (templateUrl) {
      modalParams.templateUrl = templateUrl;

      if (templateScope) {
        modalParams.scope = extend($scope.$new(), templateScope);
      }
    } else {
      modalParams.templateUrl = 'overview/environments/actions/environment_generic_prompt';

      modalParams.scope = extend($scope.$new(), {
        title: title,
        content: content,
        hide: ModalService.hide,
      });
    }

    return ModalService.showModal(modalParams);
  }

  function nameWillBeDuplicate() {
    return find(
      vm.environments,
      (env) =>
        env.name.toLowerCase() === vm.name.toLowerCase() &&
        (vm.action !== 'Edit' || env._id !== vm.currentEnv._id)
    );
  }

  function setChecks(rule) {
    if (rule.source) {
      return TagsService.getTags().then((tags) => {
        const sourceTags = TagsService.getTagsOfSourceSystem(tags, rule.source);
        rule.checks = map(sourceTags, (sourceTag) => ({
          display: $filter('normalizeTagType')(sourceTag.name),
          value: sourceTag.name,
        }));

        return rule;
      });
    }

    return null;
  }

  function showInvalidPreview() {
    return !vm.beforeSubmit && !isValid(true);
  }

  function isNotPreviewRefresh() {
    if (
      vm.envWithIncidentTagsFT &&
      vm.incidentTagIsSelectedByRule.some((value) => value === true)
    ) {
      if (!showPreview) {
        vm.isIncidentTagIsSelected = true;
      }
      return false;
    }
    if (!showPreview) {
      vm.isIncidentTagIsSelected = false;
    }
    if (vm.currentEditorMode === ADVANCED_ENV) {
      return !vm.currentAdvancedEnv || !vm.isBpqlValueValid;
    }
    return (!vm.currentEnv && !isDirty()) || !isValid(true);
  }

  function refreshFcn(rules) {
    const shouldNotRefresh = vm.isNotPreviewRefresh();
    if (shouldNotRefresh) return;
    pubSubService.broadcast('editorPreview.refresh', JSON.stringify(rules));
    refreshScrolling();
  }

  function refreshScrolling() {
    pubSubService.broadcast('BPScrollbar.ContentChanged');
  }

  function triggerPreview(bpqlExpression) {
    if (bpqlExpression) {
      EnvironmentsService.convertBpqlToSift({ bpqlExpression }).then(({ siftExpression }) => {
        vm.refresh(siftExpression);
      });
    } else {
      vm.refresh();
    }
  }

  function setOriginalBpqlValue(val) {
    vm.originalBpqlValue = val;
  }

  function onBpqlChange(val, validationErr) {
    vm.isIncidentTagAdvanceModeQuery = showPreview
      ? false
      : val.indexOf(INCIDENT_TAG_NAMESPACE) >= 0;
    vm.isBpqlValueValid = vm.isIncidentTagAdvanceModeQuery || !validationErr;
    vm.currentAdvancedEnv.filter = val || '';

    if (val && vm.originalBpqlValue && !vm.currentAdvancedEnv.id) {
      vm.showDiscard = vm.originalBpqlValue.replace(/'/g, '"') !== val.trim().replace(/'/g, '"');
      $scope.$apply();
    }

    if (vm.isBpqlValueValid) {
      triggerPreview(val);
    }
  }

  function discardChanges() {
    vm.currentAdvancedEnv.filter = vm.originalBpqlValue;
    triggerPreview(vm.currentAdvancedEnv.filter, true);
    vm.showDiscard = false;
    vm.isBpqlValueValid = true;

    setTimeout(() => {
      document.querySelector('.bpql-input-container .bam-base-input').value = vm.originalBpqlValue;
    }, 1000);
  }

  async function loadTags() {
    // This is needed because new tags might have been created, and the application might not know about them unless it forces the store to refresh.
    if (!vm.envWithIncidentTagsFT) {
      const [alertTags] = await Promise.all([TagsService.getTags(true)]);
      vm.tags = vm.alertTags = alertTags.map((item) => ({
        ...item,
        sourceSystems: uniqBy(item.sourceSystems, 'parentDisplayName'),
        type: tagTypes.alertTags,
      }));
    } else {
      const [alertTags, incidentTags] = await Promise.all([
        TagsService.getTags(true),
        LabelsStore.getMultipleIncidentTagDefinitions('all'),
      ]);
      vm.tags = [
        {
          value: tagTypes.alertTags,
          label: 'Alert Tags',
          options: (alertTags || []).map(({ name }) => ({
            id: name,
            text: name,
            type: tagTypes.alertTags,
          })),
        },
      ]
        .filter((group) => !group.hidden)
        .map((group) => omit(group, 'hidden'));

      vm.tags.unshift({
        value: tagTypes.incidentTags,
        label: 'Incident Tags',
        options: [
          ...vm.incidentMetadata,
          ...(incidentTags || []).map(({ id, name, type: incidentTagType, config }) => ({
            id: id,
            text: name,
            type: tagTypes.incidentTags,
            incidentTagType: incidentTagType,
            closedListValues: get(config, 'incident_tag_values', []),
          })),
        ],
      });

      const priorityTag = (incidentTags || []).find((tag) => tag.id === priorityTagId);
      vm.priorities = get(priorityTag, 'config.ordered_list', []).map(
        ({ order_id, display_name }) => ({
          id: order_id,
          name: display_name,
        })
      );
      vm.alertTags = alertTags.map((item) => ({
        ...item,
        sourceSystems: uniqBy(item.sourceSystems, 'parentDisplayName'),
        type: tagTypes.alertTags,
      }));
      AssignPanelUtils.getUserOptions().then((data) => {
        vm.userList = data.map(({ displayName, email }) => ({
          id: email,
          name: displayName,
          email,
        }));
      });
    }

    vm.filterLoading = false;
  }

  function checkIfIncidentTagWasSelected(andType, ruleIndex) {
    if (andType !== tagTypes.alertTags) {
      set(vm.incidentTagIsSelectedByRule, [ruleIndex], true);
    } else {
      const prevIncidentTagIsSelectedByRule = get(vm.incidentTagIsSelectedByRule, [ruleIndex]);
      if (!prevIncidentTagIsSelectedByRule) {
        set(vm.incidentTagIsSelectedByRule, [ruleIndex], false);
      }
    }
  }
}
