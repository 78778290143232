import React from 'react';
import { hot } from 'react-hot-loader';

import provideStore from '../../../../../app/react/utils/provideStore';
import PriorityDropdown from './PriorityDropdown';

const PriorityDropdownWrapper = function PriorityDropdownWrapper(props) {
  return <PriorityDropdown {...props} />;
};

export default provideStore(hot(module)(PriorityDropdownWrapper));
