import React from 'react';
import PropTypes from 'prop-types';
import styles from './step.scss';

const Step = ({ children }) => <li className={styles.step}>{children}</li>;

Step.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Step;
