angular
  .module('bigpanda.backend.services')
  .service('EnvironmentsBackendService', EnvironmentsBackendService);

function EnvironmentsBackendService($http, UserFeatureTogglesService) {
  this.getEnvironments = getEnvironments;
  this.getCounters = getCounters;
  this.getEnvironment = getEnvironment;
  this.getAdvancedEnv = getAdvancedEnv;
  this.createEnvironment = createEnvironment;
  this.updateEnvironment = updateEnvironment;
  this.updateAdvancedEnv = updateAdvancedEnv;
  this.saveNewAdvancedEnv = saveNewAdvancedEnv;
  this.deleteEnvironment = deleteEnvironment;
  this.convertSiftToBpql = convertSiftToBpql;
  this.convertBpqlToSift = convertBpqlToSift;

  function getEnvironments() {
    return $http
      .get('/web/environments')
      .then((res) => res.data.item)
      .catch(() => []);
  }

  function getAdvancedEnv(id) {
    return $http.get(`/resources/v2.0/environments/${id}`).then((res) => res.data);
  }

  function convertSiftToBpql(filter) {
    return $http.post(`/resources/v2.0/translate/sift-to-bpql`, filter).then((res) => res.data);
  }

  function convertBpqlToSift(filter) {
    return $http.post(`/resources/v2.0/translate/bpql-to-sift`, filter).then((res) => res.data);
  }

  function saveNewAdvancedEnv(env) {
    return $http.post(`/resources/v2.0/environments?skipRoles=true`, env).then((res) => res.data);
  }

  function updateAdvancedEnv(env) {
    return $http
      .patch(`/resources/v2.0/environments/${env.id}?skipRoles=true`, env)
      .then((res) => res.data);
  }

  function getCounters() {
    return $http.get('/resources/v2.0/environments/counters').then((res) => res.data.item);
  }

  function getEnvironment(id, userHasReadPermission) {
    return $http
      .get(`/web/environments/${id}?${userHasReadPermission ? '_roles=skip' : ''}`)
      .then((res) => res.data.item);
  }

  function createEnvironment(payload, userHasCreatePermission) {
    return $http
      .post(`/web/environments?${userHasCreatePermission ? '_roles=skip' : ''}`, payload)
      .then((res) => res.data);
  }

  function updateEnvironment(payload, userHasUpdatePermission) {
    return $http
      .put(
        `/web/environments/${payload._id}?${userHasUpdatePermission ? '_roles=skip' : ''}`,
        payload
      )
      .then((res) => res.data);
  }

  function deleteEnvironment(id) {
    return $http.delete(`/web/environments/${id}`).then((res) => res.data);
  }

  return this;
}
