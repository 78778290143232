import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Header, Container, MainText, SubLine, SubText, Logo, Link } from './ErrorMsg.css';

const SUPPORT_URL = 'support@bigpanda.io';
const DEFAULT_ERROR_MSG = 'Something went wrong';
const SUNTEXT_PREFIX = 'Need Help?';

const ErrorMsg = ({ errorMsg }) => {
  return (
    <Wrapper>
      <Header>
        <Logo src="assets/img/bp_logo_text_dark.svg" />
      </Header>
      <Container>
        <MainText>{errorMsg}</MainText>
        <SubLine>
          <SubText>{SUNTEXT_PREFIX}</SubText>
          <Link href={`mailto:${SUPPORT_URL}`}>{SUPPORT_URL}</Link>
        </SubLine>
      </Container>
    </Wrapper>
  );
};

ErrorMsg.propTypes = {
  errorMsg: PropTypes.string,
};

ErrorMsg.defaultProps = {
  errorMsg: DEFAULT_ERROR_MSG,
};

export default ErrorMsg;
