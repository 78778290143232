import get from 'lodash/get';
import { path } from './constants';

const selectors = {};

selectors.getEnrichedUsers = (state) => get(state, path).enrichedUsersList;

selectors.getEnrichedUserById = (state, userId) => {
  const users = selectors.getEnrichedUsers(state);
  return (users || []).find((user) => user.id === userId);
};

export default selectors;
