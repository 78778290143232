import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import { RuleType } from '../../../../types';
import {
  Alert, PreviewRule, Tag, TagValue,
} from '../types';

export default function getUniqueTags(
  previewAlerts: Alert[],
  { type: ruleType, source }: PreviewRule,
  tagName: string,
): TagValue[] {
  const values: TagValue[] = previewAlerts
    .map(({ tags = [], ...original }) => {
      const nameToMatch = ruleType === RuleType.Extraction ? source : tagName;
      const tag: Tag | undefined = tags.find(({ type }) => type === nameToMatch);
      return {
        original: original as Alert,
        value: get(tag, 'value', ''),
      };
    })
    .filter(({ value }) => value);
  return uniqBy(values, 'value');
}
