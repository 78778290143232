import React from 'react';
import { bpqlSupportedNameRegex } from 'react/helpers';
import { incidentTagsNameByType, tagDefinitionIconByType, incidentTagTypes } from '../constants';

export const validateDescriptionField = (value) => {
  if (value && value.length > 200) {
    return 'Up to 200 chars';
  }
  return undefined;
};

export const validateNameField = (value, names) => {
  const list = names.map((name) => name.toLowerCase());
  if (!value) {
    return 'Name is required';
  }
  if (list.includes(value.toLowerCase())) {
    return 'Name already exists';
  }
  if (value.length > 20) {
    return 'Up to 20 chars';
  }
  if (!bpqlSupportedNameRegex.test(value)) {
    return 'Name is not valid';
  }
  return undefined;
};

export const validateFormulaValueField = (value) => {
  if (!value) {
    return 'Value is required';
  }
  return undefined;
};

export const generatedTypesOptions = Object.values(incidentTagTypes)
  .filter((type) => type !== incidentTagTypes.PRIORITY)
  .map((option) => {
    const icon = `${tagDefinitionIconByType(option)} icon-type`;
    return {
      text: incidentTagsNameByType(option),
      value: option,
      icon: {
        component: <i className={icon} />,
      },
    };
  });

export const getEnrichmentConfigForUpdate = (enrichmentConfig) =>
  enrichmentConfig && enrichmentConfig.items.length ? enrichmentConfig : undefined;
