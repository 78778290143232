angular.module('bigpanda').directive('tabsResizableBar', tabsResizableBar);

function tabsResizableBar() {
  return {
    require: { tabsResizable: '^^tabsResizable' },
    controller: controller,
    bindToController: true,
  };

  function controller($element) {
    const vm = this;
    this.$onInit = () => {
      vm.tabsResizable.registerBar($element);
    };
  }
}
