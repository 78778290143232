import extend from 'lodash/extend';
import find from 'lodash/find';
angular
  .module('bigpanda.reports')
  .controller('ReportSnapshotsDialogController', ReportSnapshotsDialogController);

function ReportSnapshotsDialogController(
  $filter,
  EnvironmentsService,
  ReportsConfig,
  ModalService
) {
  const vm = this;

  vm.environmentNames = {};
  vm.activeSnapshots = [];
  vm.inactiveSnapshots = [];
  vm.hide = ModalService.hide;

  init();

  function init() {
    vm.snapshots.forEach((snapshot) => {
      EnvironmentsService.getEnvById(snapshot.report.widgets[0].query.environment_id).then(
        (env) => {
          if (env.id === 'all') {
            snapshot.environment_name = 'All Environments';
          } else {
            snapshot.environment_name = env.name;
          }
        }
      );

      // Timeframe
      const timeframe = snapshot.report.widgets[0].timeframe;
      const timeframePreset = find(
        ReportsConfig.timeframePresets,
        (preset) => preset.timeframe.interval === timeframe.interval
      );

      extend(timeframePreset.timeframe, timeframe);

      let formattedTimeframe = $filter('timeframePresetRange')(
        timeframePreset,
        moment(snapshot.created_at * 1000)
      );

      if (timeframe.type === 'relative') {
        formattedTimeframe = `${timeframePreset.title} (${formattedTimeframe})`;
      }

      snapshot.formattedTimeframe = formattedTimeframe;

      // Active/Inactive
      if (Math.abs(moment().unix() - snapshot.created_at) < 60 * 60 * 24 * 30) {
        vm.activeSnapshots.push(snapshot);
      } else {
        vm.inactiveSnapshots.push(snapshot);
      }
    });
  }
}
