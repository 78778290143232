import isEqual from 'lodash/isEqual';
/* eslint-disable import/prefer-default-export */
export const cacheTillFinished = (fn) => {
  let lastArgs = null;
  let lastPromise = null;
  const onFinish = (result) => {
    lastArgs = null;
    lastPromise = null;
    return result;
  };
  return (...args) => {
    if (isEqual(lastArgs, args) && lastPromise !== null) {
      return lastPromise;
    }
    lastArgs = args;
    lastPromise = fn(...args);
    return lastPromise.then(onFinish).catch((error) => {
      onFinish();
      throw error;
    });
  };
};
