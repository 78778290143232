angular
  .module('bigpanda.backend.services')
  .service('EnvironmentGroupsBackendService', EnvironmentGroupsBackendService);

function EnvironmentGroupsBackendService($q, $http) {
  this.getEnvironmentGroups = getEnvironmentGroups;
  this.create = create;
  this.update = update;
  this.remove = remove;
  this.updateEnvironment = updateEnvironment;

  function getEnvironmentGroups() {
    return $http
      .get('/web/environment-groups')
      .then((res) => res.data.item)
      .catch(() => []);
  }

  function create(environmentGroup) {
    return $http.post('/web/environment-groups', environmentGroup).then((res) => res.data.item);
  }

  function update(environmentGroup) {
    return $http
      .put(`/web/environment-groups/${environmentGroup.id}`, environmentGroup)
      .then((res) => res.data.item);
  }

  function remove(environmentGroupId) {
    return $http
      .delete(`/web/environment-groups/${environmentGroupId}`)
      .then((res) => res.data.item);
  }

  function updateEnvironment(environmentId, fromEnvironmentGroupId, toEnvironmentGroupId) {
    return $http
      .put(`/web/environment-groups/environment/${environmentId}`, {
        fromEnvironmentGroupId,
        toEnvironmentGroupId,
      })
      .then((res) => res.data.item);
  }

  return this;
}
