import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { bp_blue } from '@bp/pastel/colors';
import { Spinner, darkTheme } from '@bp/kung-fu';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import provideStore from 'react/utils/provideStore';
import SettingsFullScreen from '../../../layout/settings/SettingsFullScreen';
import {
  SSOIntegrationInstructions,
  SSOPreValidation,
  SSOPostValidation,
  SSOPostActivation,
} from './sso_stages';
import * as actions from './actions';
import selectors from './selectors';
import SSOProviderTabs from './SSOProviderTabs';
import { SSO_STATES } from './constants';
import './sso_styles.scss';

class SSO extends React.Component {
  componentDidMount() {
    if (this.props.ssoState === null) {
      this.props.loadSSOState();
    }
  }

  getCurrentProviderNameFromUrl = () => {
    const { selectedProvider: URLSelectedProvider } = this.props;
    return !isEmpty(URLSelectedProvider) ? URLSelectedProvider.name : '';
  };

  getCurrentStatus = () => {
    const { ssoState } = this.props;
    return get(ssoState, 'status');
  };

  getDisplayedComponent = () => {
    const { disableSSO, openTutorial, ssoState } = this.props;

    const status = this.getCurrentStatus();
    const providerId = get(ssoState, 'idP');
    const provider = SSOProviderTabs.find((providerTab) => providerTab.name === providerId);
    const providerName = isEmpty(provider) ? '' : provider.display;

    const providerNameByURL = this.getCurrentProviderNameFromUrl();

    switch (status) {
      case SSO_STATES.TESTING:
        return <SSOPreValidation providerName={providerName} disableSSO={disableSSO} />;
      case SSO_STATES.TESTED:
        return (
          <SSOPostValidation
            providerName={providerName}
            activateSSO={this.activateSSO}
            disableSSO={disableSSO}
          />
        );
      case SSO_STATES.OK:
        return <SSOPostActivation providerName={providerName} disableSSO={disableSSO} />;
      case undefined:
        return (
          <SSOIntegrationInstructions
            selectProvider={openTutorial}
            selectedProvider={providerNameByURL}
            updateAndLogout={this.updateAndLogout}
          />
        );
      default:
        return <Spinner color={bp_blue} />;
    }
  };

  updateAndLogout = () => {
    this.props.updateSSO({
      provider: this.getCurrentProviderNameFromUrl(),
      enabled: true,
      status: SSO_STATES.TESTING,
    });
    this.props.logout();
  };

  activateSSO = () => {
    this.props.updateSSO({
      provider: this.getCurrentProviderNameFromUrl(),
      enabled: true,
      status: SSO_STATES.OK,
    });
  };

  render() {
    const displayedComponent = this.getDisplayedComponent();

    const currentSSOStatus = this.getCurrentStatus();

    const pageTitle = currentSSOStatus
      ? 'Single Sign-on (SSO) - Integration Instructions'
      : 'Single Sign-on (SSO)';

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsFullScreen title={pageTitle}>{displayedComponent}</SettingsFullScreen>;
      </ThemeProvider>
    );
  }
}

SSO.propTypes = {
  disableSSO: PropTypes.func.isRequired,
  loadSSOState: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  openTutorial: PropTypes.func.isRequired,
  selectedProvider: PropTypes.shape({
    name: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    options: PropTypes.shape({
      featureToggle: PropTypes.bool,
    }),
  }),
  ssoState: PropTypes.shape({}),
  updateSSO: PropTypes.func.isRequired,
};

SSO.defaultProps = {
  selectedProvider: null,
  ssoState: null,
};

const mapStateToProps = (state) => ({
  ssoState: selectors.getSSOCurrentState(state),
});

const mapDispatchToProps = {
  loadSSOState: actions.loadSSOState,
  updateSSO: actions.updateSSO,
  disableSSO: actions.disableSSO,
};

export default provideStore(connect(mapStateToProps, mapDispatchToProps)(hot(module)(SSO)));
