import get from 'lodash/get';
import identity from 'lodash/identity';
import extend from 'lodash/extend';
import find from 'lodash/find';
import last from 'lodash/last';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';
angular.module('bigpanda').controller('DashboardDisplayController', DashboardDisplayController);

function DashboardDisplayController(
  $state,
  $stateParams,
  $timeout,
  $scope,
  DashboardsStore,
  DashboardsSettingsConfig,
  EnvironmentsService,
  notificationService,
  SessionTimeoutWarningService,
  CustomizationsStore,
  PERMISSIONS_NAMES
) {
  const vm = this;
  const pollInterval = 60 * 1000;
  let fetchDataPromise;
  let envsById = {};
  let favoriteDashboards = new Set();

  vm.sortKeyGetter = sortKeyGetter;
  vm.toggleFullscreen = toggleFullscreen;
  vm.toggleFavoriteStatus = toggleFavoriteStatus;
  vm.toggleDarkMode = toggleDarkMode;
  vm.isFullscreen = isFullscreen;
  vm.$onInit = init;

  vm.editPermission = [PERMISSIONS_NAMES.dashboards.update];

  function init() {
    const dashboardPromise = DashboardsStore.getDashboardById($stateParams.dashboardId);
    updateDashboard(dashboardPromise);

    $scope.$on('$destroy', () => {
      $timeout.cancel(fetchDataPromise);
    });

    EnvironmentsService.get().then((envs) => {
      envsById = fromPairs(map(envs, (e) => [e._id, e]));
    });

    CustomizationsStore.subscribe(
      {
        storeUpdated: fetchCustomizations,
      },
      $scope
    );

    DashboardsStore.subscribe($scope, {
      storeUpdated: dashboardsChanged,
    });

    fetchCustomizations();
  }

  function poll() {
    fetchDataPromise = $timeout(() => fetchData(), pollInterval);
    fetchDataPromise.then(poll);
  }

  function updateLastGraphPoints(widgetsData) {
    const dropFirst =
      last(vm.widgetsData[0].graph).range.start !== last(widgetsData[0].graph).range.start;

    widgetsData.forEach((widget) => {
      const existingWidget = find(vm.widgetsData, { envId: widget.envId });
      if (!existingWidget) {
        return;
      }

      const oldGraph = existingWidget.graph;
      if (dropFirst) {
        oldGraph.shift();
      } else {
        oldGraph.pop();
      }
      oldGraph.push(widget.graph[0]);
      extend(existingWidget, widget, { graph: oldGraph });
    });
  }

  function fetchData(forceFullReload = false) {
    const onlyLast = !forceFullReload;

    return DashboardsStore.getWidgetsData($stateParams.dashboardId, onlyLast).then(
      (widgetsData) => {
        if (!onlyLast) {
          vm.widgetsData = widgetsData;
        } else {
          updateLastGraphPoints(widgetsData);
        }
      }
    );
  }

  function updateDashboard(dashboardPromise) {
    vm.loading = true;

    dashboardPromise.then((dashboard) => {
      updateDisplayValues(dashboard);
      vm.dashboard = dashboard;

      if (!dashboard.selected_environments.length) {
        notificationService.error('Please select at least one environment for this dashboard');
        vm.loading = false;
        return;
      }

      fetchData(true).then(() => {
        vm.loading = false;
        if (!fetchDataPromise) {
          poll();
        }
        SessionTimeoutWarningService.startReoccurringTokenRenewal($scope);
      });
    });

    updateFavoriteStatus();
  }

  function updateDisplayValues(dashboard) {
    const sortOption = find(DashboardsSettingsConfig.sortOptions, { value: dashboard.sort_by });
    vm.sortBy = sortOption.display;
    vm.timeframe = find(DashboardsSettingsConfig.timeframeOptions, {
      value: dashboard.time_frame,
    }).display;

    vm.sortKey = sortOption.key;
  }

  function sortKeyGetter(widget) {
    const key = vm.sortKey || identity;
    return key(envsById[widget.envId] || {}, widget);
  }

  function toggleFullscreen() {
    $state.go(
      '.',
      extend({}, $stateParams, { fullscreen: $stateParams.fullscreen ? null : 'true' })
    );
  }

  function fetchCustomizations() {
    CustomizationsStore.getCustomizations().then((customizations) => {
      favoriteDashboards = new Set(get(customizations, 'dashboards.navigation_list.favorites', []));
      updateFavoriteStatus();
    });
  }

  function updateFavoriteStatus() {
    vm.isFavorite = favoriteDashboards.has($stateParams.dashboardId);
  }

  function toggleFavoriteStatus() {
    vm.isFavorite = !vm.isFavorite;
    if (vm.isFavorite) {
      favoriteDashboards.add($stateParams.dashboardId);
    } else {
      favoriteDashboards.delete($stateParams.dashboardId);
    }

    CustomizationsStore.updateCustomizations({
      dashboards: { navigation_list: { favorites: Array.from(favoriteDashboards) } },
    });
  }

  function toggleDarkMode() {
    $state.go('.', extend({}, $stateParams, { dark: $stateParams.dark ? null : 'true' }));
  }

  function isFullscreen() {
    return $stateParams.fullscreen === 'true';
  }

  function dashboardsChanged() {
    const dashboardPromise = DashboardsStore.getDashboardById($stateParams.dashboardId);
    dashboardPromise.then((dashboard) => {
      if (!angular.equals(dashboard, vm.dashboard)) {
        updateDashboard(dashboardPromise);
      }
    });
  }
}
