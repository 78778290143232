import get from 'lodash/get';
export const changeSelectedTag = (vm, labelsStore, tagValue, name = null, email = null) => {
  let payload;
  if (vm.isMultiple) {
    payload = {
      tags: [{ tag_id: vm.priorityProps.tagId, tag_value: tagValue }],
      incident_ids: vm.checkedIncidentsState.getCheckedIncidentIds(),
    };
    labelsStore.setBatchIncidentTags(vm.priorityProps.incidentId, payload);
  } else if (vm.priorityProps.label.value !== tagValue) {
    payload = {
      tag_id: vm.priorityProps.tagId,
      tag_value: tagValue,
      name,
      email,
    };
    labelsStore.setIncidentTag(vm.priorityProps.incidentId, payload);
  }
};

export const deleteSelectedTag = (vm, labelStore) => {
  if (vm.isMultiple) {
    const payload = { incident_ids: vm.checkedIncidentsState.getCheckedIncidentIds() };

    labelStore.deleteMultipleIncidentsTag(vm.priorityProps.tagId, payload);
  } else {
    labelStore.deleteIncidentTag(vm.priorityProps.incidentId, vm.priorityProps.tagId);
  }
};

export const onIncidentTagsUpdated = (vm, labelStore) => {
  const priorityTag = labelStore.getPriorityDefinition();
  vm.priorityProps.priorityTag = priorityTag;
  if (!priorityTag.id) return;

  vm.priorityProps.tagId = priorityTag.id;
  vm.priorityProps.isTagEnabled = !!(priorityTag.active && vm.currentEnvActions.update);
  vm.priorityProps.list = get(priorityTag, 'config.ordered_list', []).map(
    ({ display_name: displayName, color, order_id: orderId, active }) => ({
      color: color,
      orderId: orderId,
      active: active,
    })
  );
  vm.priorityProps.listObj = vm.priorityProps.list.reduce((acc, item) => {
    acc[item.orderId] = item;
    return acc;
  }, {});
  vm.priorityProps.list = vm.priorityProps.list.filter((item) => item.active);
  if (!vm.deleteDisabled) {
    vm.priorityProps.list.push({ divider: true });
    vm.priorityProps.list.push({
      action: 'delete',
    });
  }
  updateContext(vm, labelStore);
};

export const updateContext = (vm, labelStore) => {
  vm.priorityProps.label = {};
  return updateContextWithPriorityTag(vm, vm.priorityProps, labelStore);
};

export const updateContextWithPriorityTag = (vm, context, labelStore) => {
  if (!context.incidentId) {
    return Promise.resolve(true);
  }
  labelStore.setCurrentIncident(context.incidentId);

  // Mutation of the sent context is required to make sure we edit the original context in case the vm one is replaced on scroll.
  return labelStore
    .getIncidentTags(context.incidentId)
    .then((res) => {
      const { labels } = res;
      const label = (labels || []).find(({ id }) => id === vm.priorityProps.tagId);
      if (!label) {
        context.label = {};
        return;
      }
      const tagValue = label.value;

      context.label = {
        value: tagValue,
      };
    })
    .catch(() => {
      context.label = {};
    });
};

export const updateIncidentTagDeleteButtonDisabled = (vm, labelStore) => {
  labelStore.getIncidentTags(vm.priorityProps.id).then((res) => {
    const foundTags = res.labels && res.labels.length;
    vm.isDeleteIncidentTagDisabled = !foundTags;
  });
};
