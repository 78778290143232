import isNumber from 'lodash/isNumber';
angular.module('bigpanda.reports').filter('timeframePresetRange', timeframePresetRange);

function timeframePresetRange($log) {
  return filter;

  function filter(preset, end, isLong) {
    let startMoment;
    let endMoment;
    let today;
    let range = null;

    if (preset.timeframe.type === 'relative') {
      startMoment = preset.getStartMoment(end);
      today = moment().startOf('day');

      if (!isLong) {
        range = `${startMoment.format('MMM D')} - ${moment(preset.timeframe.end).format('MMM D')}`;
      } else if (startMoment.isSame(today, 'day')) {
        range = `${startMoment.format('h:mma')} - ${moment(preset.timeframe.end).format('h:mma')}`;
      } else {
        range = `${startMoment.format('MMM D')} - ${moment(preset.timeframe.end).format('MMM D')}`;
      }
    } else if (preset.timeframe.type === 'absolute') {
      if (
        angular.isUndefined(preset.timeframe.start) &&
        angular.isUndefined(preset.timeframe.end)
      ) {
        return null;
      }

      const timeframeEnd = preset.timeframe.end;
      const timeframeStart = preset.timeframe.start;

      endMoment = moment(isNumber(timeframeEnd) ? timeframeEnd * 1000 : timeframeEnd);
      startMoment = moment(isNumber(timeframeStart) ? timeframeStart * 1000 : timeframeStart);

      if (!isLong) {
        if (startMoment.isSame(endMoment, 'day')) {
          range = startMoment.format('MMM D, YYYY');
        } else {
          range = `${startMoment.format('MMM D')} - ${endMoment.format('MMM D')}`;
        }
      } else {
        range = `${startMoment.format('MMM D, h:mma')} - ${endMoment.format('MMM D, h:mma')}`;
      }
    } else {
      $log.error(`Got unknown timeframe type : ${preset.timeframe.type}`);
    }

    if (!range) {
      $log.warn(`Got unknown timeframe type: ${preset.timeframe.type}`);
    }

    return range;
  }
}
