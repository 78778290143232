import {
  AllSizes, ButtonVariants, PopOutIcon, SCHBox, Text, Theme,
} from '@bp/kung-fu';
import React from 'react';

import { text } from '../../../../../../constants';
import ActionColumn from '../../../Columns/ActionColumn';
import DateColumn from '../../../Columns/DateColumn';
import { RowDetailsSectionProps } from '../../types';
import {
  AdditionalDataSeparator,
  LineSeparator,
  RowDetailsHeaderWrapper,
  RowDetailsTitle,
  RowDetailsTitleWrapper, RowLinkButton,
  RowLinkWrapper,
} from './RowDetailsHeader.style';

function RowDetailsHeader({ rowData }: RowDetailsSectionProps): JSX.Element {
  const { resourceId, category, object } = rowData;
  const categoryToSlugMap = {
    auto_share_rules: `/#/app/settings/rules/${resourceId}`,
    correlation_pattern: `/v2/settings/alert-correlation/${resourceId}`,
    environments: `/#/app/environments?environmentId=${resourceId}`,
    tag: `/#/app/settings/alert-enrichment/${resourceId}`,
    tag_enrichments_order: `/#/app/settings/alert-enrichment/${resourceId}`,
    tag_enrichments: `/#/app/settings/alert-enrichment/${resourceId}`,
    users: `/#/app/settings/user_management/${resourceId}`,
    roles: `/#/app/settings/roles/${resourceId}`,
    jit_domains: '/v2/settings/jit-sso',
    jit_roles: '/v2/settings/jit-sso',
    api_keys: '/v2/settings/api_keys',
    sso_test: '/#/app/settings/sso',
    sso_config: '/#/app/settings/sso',
    integrations: `/#/app/integrations/${object.target_system_id ?? ''}/instructions/${(object.target_system_id as string ?? '').split('.')?.[0]}`,
    alert_filtering: `/v2/settings/alert-filtering/${object.plan_id}`,
    maintenance_plan: `/v2/settings/planned-maintenance/${object.plan_id}`,
  };

  const disableCategories = ['mapping_enrichment', 'mapping_table_upload'];

  const isAlertEnrichmentMapping = disableCategories.includes(category.name);

  const viewObjectTooltip = isAlertEnrichmentMapping ? text.alertMappingTooltip : text.viewObject;
  const handleViewObjectOnClick = (): WindowProxy => window.open(`${window.location.origin}${categoryToSlugMap[rowData.category.name]}`);

  return (
    <RowDetailsHeaderWrapper>
      <RowDetailsTitleWrapper>
        <Text>{rowData.category.expandRowValue}</Text>
        <Text>/</Text>
        <RowDetailsTitle>{rowData.changedObject}</RowDetailsTitle>
        <RowLinkWrapper title={viewObjectTooltip}>
          <RowLinkButton
            disabled={isAlertEnrichmentMapping}
            variant={ButtonVariants.LINK}
            onClick={handleViewObjectOnClick}
          >
            <SCHBox gap="4px">
              <Text>{text.viewObject}</Text>
              <PopOutIcon
                size={AllSizes.SMALL}
                color={({ theme }: { theme: Theme }): string => theme.bp_blue}
              />
            </SCHBox>
          </RowLinkButton>
        </RowLinkWrapper>
      </RowDetailsTitleWrapper>

      <RowDetailsTitleWrapper>
        <ActionColumn cellData={rowData.action} />
        <AdditionalDataSeparator>·</AdditionalDataSeparator>
        <DateColumn cellData={rowData.date} />
      </RowDetailsTitleWrapper>

      <LineSeparator />
    </RowDetailsHeaderWrapper>
  );
}

export default RowDetailsHeader;
