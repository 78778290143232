angular.module('bigpanda').directive('activityFeedComment', activityFeedComment);

function activityFeedComment() {
  return {
    restrict: 'E',
    scope: {
      editingComment: '=',
      incident: '<',
      currentEnvActions: '=',
    },
    templateUrl:
      'overview/incidents/info/activity_feed/activity_feed_comment/activity_feed_comment',
  };
}
