import last from 'lodash/last';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';

const uniqList = (list) =>
  [...new Set(list.map((item) => JSON.stringify(item)))].map((json) => JSON.parse(json));

export const buildMatchers = ({ matchers_log }) =>
  [
    ...new Set(
      flatten(last(matchers_log).matchers.map(({ tags }) => tags)).map((tag) => JSON.stringify(tag))
    ),
  ].map((tag) => {
    const { type, value } = JSON.parse(tag);
    const nodeType = 'matcher';
    return { type: normalizeType(type), value, nodeType };
  });

export const getTypes = (list) => list.map(({ type }) => type);

const idString = (type, value) => `${type}_${value}`.replace(/\s/g, '_');

export const buildNodes = (list, histogram = {}) =>
  list.reduce(
    (
      acc,
      { type, value, nodeType = '', activeStates } // nodeType used for debugging the default logic
    ) => [
      ...acc,
      {
        group: type,
        label: value,
        id: `${idString(type, value)}`,
        badgeNumber: histogram[idString(type, value)],
        tooltipContent: { type, value, nodeType },
        activeStates,
      },
    ],
    []
  );

export const buildLinksMap = (incident) => {
  const matchesTypes = [...new Set(getTypes(buildMatchers(incident)))];
  const lastMatcher = last(matchesTypes);
  const matchersLinksMap = matchesTypes.map((type, index) => {
    if (index === 0 && matchesTypes.length > 1) {
      return { source: lastMatcher, target: type };
    }
    return { source: matchesTypes[index - 1], target: type };
  });

  const entitiesLinksMap = incident.entities.flatMap(({ primary_property, secondary_property }) => {
    const normalizedPrimary = normalizeType(primary_property);
    const primaryMatcher = matchesTypes.includes(normalizedPrimary);

    const normalizedSecondary = secondary_property ? normalizeType(secondary_property) : undefined;
    const secondaryMatcher = normalizedSecondary
      ? matchesTypes.includes(normalizedSecondary)
      : undefined;
    const links =
      normalizedSecondary && !secondaryMatcher
        ? [{ source: normalizedPrimary, target: normalizedSecondary }]
        : [];

    if (!primaryMatcher) {
      links.push({ source: lastMatcher, target: normalizedPrimary });
    }

    return links;
  });

  return [...matchersLinksMap, ...uniqList(entitiesLinksMap)];
};

export const incidentToNodesAndLinks = (incident, hideInactive) => {
  const defaultLinksMap = buildLinksMap(incident);
  return buildTopologyData(defaultLinksMap, incident.entities, hideInactive);
};

export const getKeyFromTypes = (typesMap, type) =>
  Object.keys(typesMap).find((key) => typesMap[key].includes(type));

export function loopIteration(list) {
  function* gen() {
    let i = 0;
    while (true) {
      const reset = yield list[i];
      i = i === list.length - 1 || reset ? 0 : i + 1;
    }
  }

  return gen();
}

const uniqEnrichedNodes = (procceedNodes, nodes) => {
  const accumulator = procceedNodes.reduce((acc, node) => {
    const tempNode = cloneDeep(node);
    delete node.activeStates;
    const str = JSON.stringify(node);
    acc[str] = tempNode;
    return acc;
  }, {});
  const obj = nodes.reduce((acc, node) => {
    const active = node.active;
    delete node.active;
    const str = JSON.stringify(node);
    if (acc[str]) {
      node.activeStates = [...acc[str].activeStates, active];
    } else {
      node.activeStates = [active];
    }
    acc[str] = node;
    return acc;
  }, accumulator);
  return Object.values(obj);
};

export const buildTopologyData = (linksMap, entities, hideInactive) => {
  const histogram = entities.reduce((acc, { tags, status, maintenance_plan_ids }) => {
    const active = !(
      status === 'acknowledged' ||
      status === 'ok' ||
      (maintenance_plan_ids != undefined && maintenance_plan_ids.length > 0)
    );
    const shouldHide = hideInactive && !active;
    tags.forEach((tag) => {
      const typeValuesArr = handleTagWithArrayValue(tag);
      typeValuesArr.forEach(({ type, value }) => {
        const id = idString(normalizeType(type), value);
        if (!acc[id]) acc[id] = 0;
        if (!shouldHide) acc[id] += 1;
      });
    });
    return acc;
  }, {});
  const result = linksMap.reduce(
    (results, link) => {
      const topologyData = entities.reduce(
        ({ links, nodes }, { tags, status, maintenance_plan_ids }) => {
          const source = tags.find(({ type }) => normalizeType(type) === link.source);
          const target = tags.find(({ type }) => normalizeType(type) === link.target);

          if (source && target) {
            const linksAndNodes = customLinksAndNodes(
              source,
              target,
              status,
              maintenance_plan_ids,
              nodes,
              links,
              hideInactive
            );
            const flattLinksAndNodes = linksAndNodes.reduce(
              (acc, current) => ({
                links: uniqList([...acc.links, ...current.links]),
                nodes: uniqList([...acc.nodes, ...current.nodes]),
              }),
              { links: [], nodes: [] }
            );
            return flattLinksAndNodes;
          }
          return { links, nodes };
        },
        { links: [], nodes: [] }
      );

      const obj = {
        links: uniqList([...results.links, ...topologyData.links]),
        nodes: uniqEnrichedNodes([...results.nodes], [...topologyData.nodes]),
      };
      return obj;
    },
    { links: [], nodes: [] }
  );
  return { links: result.links, nodes: buildNodes(result.nodes, histogram) };
};

export const customLinksAndNodes = (
  source,
  target,
  status,
  maintenance_plan_ids,
  nodes,
  links,
  hideInactive
) =>
  handleTagWithArrayValue(source).flatMap((s) =>
    handleTagWithArrayValue(target).map((t) => {
      const active = !(
        status === 'acknowledged' ||
        status === 'ok' ||
        (maintenance_plan_ids != undefined && maintenance_plan_ids.length > 0)
      );
      const shouldHide = hideInactive && !active;
      const normalizedSource = { ...s, type: normalizeType(s.type), shouldHide, active };
      const normalizedTarget = { ...t, type: normalizeType(t.type), shouldHide, active };
      const newLink = {
        source: idString(normalizeType(s.type), s.value),
        target: idString(normalizeType(t.type), t.value),
      };
      if (shouldHide) return { links: [...links], nodes: [...nodes] };
      return { links: [...links, newLink], nodes: [...nodes, normalizedSource, normalizedTarget] };
    })
  );

export const handleTagWithArrayValue = ({ type, value: suspectedValue }) => {
  const valuesArray = Array.isArray(suspectedValue) ? suspectedValue : [suspectedValue];
  return valuesArray.map((value) => ({ type, value }));
};

export const normalizeType = (type) => type.replace(/^_/, '');
