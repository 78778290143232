const { react2angular } = require('react2angular');
const IncidentAi = require('react/modules/overview/incident_ai/incident-ai').default;

angular
  .module('bigpanda')
  .component(
    'reactIncidentAi',
    react2angular(IncidentAi, [
      'store',
      'environmentId',
      'incident',
      'tagDefinitions',
      'aiTags',
      'isReadOnly',
    ])
  );

angular.module('bigpanda').directive('incidentAi', incidentAi);

function incidentAi() {
  return {
    restrict: 'E',
    scope: {
      environmentId: '=',
      incident: '=',
      tagDefinitions: '=',
      aiTags: '=',
      isReadOnly: '=',
    },
    templateUrl: 'overview/incidents/incident_ai/react_incident_ai',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($ngRedux) {
    const vm = this;
    vm.store = $ngRedux;
  }
}
