import actionTypes from './actionTypes';

export function loadUser() {
  return { type: actionTypes.LOAD_USER };
}
export function loadUserSuccess(payload) {
  return { type: actionTypes.LOAD_USER_SUCCESS, payload };
}

export function loadCustomizations() {
  return { type: actionTypes.LOAD_CUSTOMIZATIONS };
}

export function loadCustomizationsSuccess(payload) {
  return { type: actionTypes.LOAD_CUSTOMIZATIONS_SUCCESS, payload };
}

export function loadCustomizationsFailure() {
  return { type: actionTypes.LOAD_CUSTOMIZATIONS_FAILURE };
}
