import {
  HBox, Link,
  TextField, Tooltip, useDropDown,
} from '@bp/kung-fu';
import React, { useEffect } from 'react';

import preventEnterKey from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/preventEnterKey';
import { Tag } from '../../../types';
import texts from '../../dictionary';
import { validateTagName } from '../../helpers/validate-tag-name';
import { UnifiedTag } from '../RuleTypeForms/types';
import {
  BulletContainer,
  NewTagNameInfoContainer,
  NewTagNameInfoText,
  TagNameField,
} from './AlertTagFormHeader.style';

export interface IProps {
  tags: Tag[];
  tag: Tag;
  reservedTags: UnifiedTag[];
  editMode: boolean;
  useTagOperations: boolean;
  name?: string;
}

export function TagNameFieldComp({
  tags,
  reservedTags,
  tag,
  editMode,
  name,
  useTagOperations,
}: IProps): JSX.Element {
  const {
    register, Container, isOpen, setOpen,
  } = useDropDown();

  useEffect(() => {
    if (!name && !editMode) {
      setOpen(true);
    }
  }, [name, editMode]);

  const validateName = (value = ''): string => {
    if (editMode && tag.name.toLowerCase() === value.toLowerCase()) return '';

    return validateTagName({ tags, reservedTags, value });
  };

  return (
    <>
      <TagNameField
        autoFocus
        name="name"
        validate={validateName}
        onKeyPress={preventEnterKey}
        render={({ input, meta }): JSX.Element => (
          <Tooltip
            placement="bottom-start"
            text={texts.tag_name_disabled_tooltip_text}
            isActive={useTagOperations && editMode}
          >
            <div {...register()}>
              <TextField
                {...input}
                onChange={(e): void => {
                  if (e.currentTarget.value) {
                    setOpen(false);
                  }

                  input.onChange(e);
                }}
                onFocus={(e): void => {
                  if (e.currentTarget.value) {
                    setOpen(false);
                  }
                }}
                label={texts.tag_name_input_field__label}
                ariaLabel={texts.tag_name_input_field__aria_label}
                error={(meta.dirty || meta.submitFailed || input.value) && meta.error}
                placeholder={texts.tag_name_input_field__placeholder}
                isErrorAbsolute={false}
                disabled={useTagOperations && editMode}
              />
            </div>
          </Tooltip>
        )}
      />

      {isOpen && useTagOperations && !editMode && (
        <Container>
          <NewTagNameInfoContainer>
            <HBox>
              <BulletContainer><NewTagNameInfoText>•</NewTagNameInfoText></BulletContainer>
              <NewTagNameInfoText>{texts.tag_name_info_tooltip_line1}</NewTagNameInfoText>
            </HBox>

            <HBox>
              <BulletContainer><NewTagNameInfoText>•</NewTagNameInfoText></BulletContainer>
              <NewTagNameInfoText>
                {texts.tag_name_info_tooltip_line2}
                {'  '}
                <Link href={texts.tag_name_learn_more_link}>
                  {texts.tag_name_info_tooltip_line2_learn_more}
                </Link>
              </NewTagNameInfoText>
            </HBox>

          </NewTagNameInfoContainer>
        </Container>
      )}
    </>
  );
}
