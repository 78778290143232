import React from 'react';
import PropTypes from 'prop-types';
import {
  UsersIcon,
  PermissionsIcon,
  ShareIcon,
  TagIcon,
  CorrelationIcon,
  LabelIcon,
  MaintenanceIcon,
  QuotaIcon,
  KeyIcon,
  SsoIcon,
  IntegrationIcon,
  TextIcon,
  darkTheme,
  QueryIcon,
  MultivalueIcon,
  TicketIcon,
  MapsIcon,
  FullPageIcon,
} from '@bp/kung-fu';
import { ThemeProvider } from 'styled-components';

import { Wrapper, Title, MenuItem, ItemContainer, IconWrapper } from './MenuGroup.css';

const ICON_SIZE = 'xx-large';

const MenuGroup = ({ title, list, selectedItemId, onItemClick }) => {
  const getIcon = (id) => {
    switch (id) {
      case 'users':
        return <UsersIcon size={ICON_SIZE} />;
      case 'roles':
        return <PermissionsIcon size={ICON_SIZE} />;
      case 'rules':
        return <ShareIcon size={ICON_SIZE} />;
      case 'customTags':
      case 'alertEnrichment':
        return <TagIcon size={ICON_SIZE} />;
      case 'alertFiltering':
        return <QueryIcon size={ICON_SIZE} />;
      case 'correlationRules':
        return <CorrelationIcon size={ICON_SIZE} />;
      case 'incidentLabels':
        return <LabelIcon size={ICON_SIZE} />;
      case 'maintenancePlans':
        return <MaintenanceIcon size={ICON_SIZE} />;
      case 'quota':
        return <QuotaIcon size={ICON_SIZE} />;
      case 'apiKeys':
        return <KeyIcon size={ICON_SIZE} />;
      case 'sso':
        return <SsoIcon size={ICON_SIZE} />;
      case 'integrationsManagement':
        return <IntegrationIcon size={ICON_SIZE} />;
      case 'alertView':
        return <TextIcon size={ICON_SIZE} />;
      case 'incidentView':
        return <FullPageIcon size={ICON_SIZE} />;
      case 'auditLogsView':
        return <MultivalueIcon size={ICON_SIZE} />;
      case 'mappingEnrichment':
        return <TicketIcon size={ICON_SIZE} />;
      case 'ssoConfiguration':
        return <MapsIcon size={ICON_SIZE} />;
      default:
        return '';
    }
  };

  const isMaintenanceIncluded = () => list.some((obj) => obj.label === 'Planned Maintenance');

  const getDivider = (iconSource) => {
    return list.length > 2 && isMaintenanceIncluded() ? (
      <img src={iconSource} style={{ display: 'flex', padding: '2px 0' }} alt="divider" />
    ) : null;
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Wrapper>
        <Title>{title}</Title>
        {list.map((item) => (
          <ItemContainer key={item.id} isDivider={item.isDivider}>
            {!item.isDivider ? (
              <MenuItem
                data-intercomid={item.id}
                isSelected={selectedItemId === item.id}
                onClick={() => onItemClick(item)}
              >
                <IconWrapper isSelected={selectedItemId === item.id}>
                  {getIcon(item.id)}
                </IconWrapper>
                {item.label}
              </MenuItem>
            ) : (
              getDivider(item.iconSource)
            )}
          </ItemContainer>
        ))}
      </Wrapper>
    </ThemeProvider>
  );
};

MenuGroup.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  onItemClick: PropTypes.func,
  selectedItemId: PropTypes.string,
};

export default MenuGroup;
