import actionTypes from './actionTypes';
import { generateBaseSettingsActions } from '../redux.utils';

const actions = generateBaseSettingsActions(actionTypes);

actions.updateUser = (payload) => ({ type: actionTypes.UPDATE_USER, payload: payload });
actions.deleteUser = (payload) => ({ type: actionTypes.DELETE_USER, payload: payload });
actions.reactivateUser = (payload) => ({ type: actionTypes.REACTIVATE_USER, payload: payload });
actions.resendUserInvitation = (payload) => ({
  type: actionTypes.RESEND_USER_INVITATION,
  payload: payload,
});
actions.resendUserInvitationCompleted = (payload) => ({
  type: actionTypes.RESEND_USER_INVITATION_COMPLETED,
  payload: payload,
});
actions.updateOrInviteUserSuccess = () => ({ type: actionTypes.UPDATE_OR_INVITE_USER_SUCCESS });
actions.updateOrInviteUserFailure = () => ({ type: actionTypes.UPDATE_OR_INVITE_USER_FAILURE });
actions.getRoles = () => ({ type: actionTypes.GET_ROLES });
actions.getRolesSuccess = (payload) => ({ type: actionTypes.GET_ROLES_SUCCESS, payload: payload });
actions.createFiltersDropDownItemsSuccess = (payload) => ({
  type: actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS,
  payload: payload,
});
actions.updateFiltersDropDowns = (payload) => ({
  type: actionTypes.UPDATE_FILTERS_DROPDOWNS,
  payload: payload,
});

export default actions;
