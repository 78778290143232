import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { HBox, SCHBox, darkTheme, Tooltip, HelpTooltip } from '@bp/kung-fu';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Field, FormSpy } from 'react-final-form';
import { BamInput, BamDropdownField, BamToggle, BamAddNoteField, BamCheckboxField } from '@bp/bam';
import FormulaExpressionList from '../../components/FormulaExpressionList';
import FormulaValue from './components/formula/FormulaValue';
import FormulaCondition from './components/formula/FormulaCondition';
import priorityTooltip from './components/formula/PriorityTooltip';
import { validateNameField, validateDescriptionField } from '../IncidentLabelForm.utils';
import {
  incidentTagTypes,
  colorsNameHexObj,
  defaultFormulaExpression,
  tooltipWidthByType,
} from '../../constants';
import styles from './PriorityForm.scss';
import FormulaExpressionListHeader from './components/FormulaExpressionListHeader';
import {
  validateDisplayNameField,
  nameFieldTooltipLogic,
  generatedColorsOptions,
  activeToggleTooltipLogic,
  getActiveLevels,
} from './PriorityForm.utils';

class PriorityForm extends React.Component {
  state = {
    activeLevels: {},
    allowDeactivation: false,
  };
  componentDidMount() {
    this.updateActiveLevels(this.props.incidentLabel);
  }

  updateActiveLevels = (incidentLabel) => {
    const { activeLevels } = this.state;
    const newActiveLevels = getActiveLevels(incidentLabel);
    if (!isEqual(activeLevels, newActiveLevels)) {
      this.setState({
        activeLevels: newActiveLevels,
        allowDeactivation: Object.keys(newActiveLevels).length > 1,
      });
    }
  };

  render() {
    const {
      incidentLabel,
      incidentLabels,
      featureToggles,
      formErrors,
      unifiedTags,
      displayErrorContent,
      closeErrorMessage,
    } = this.props;
    const existedNames = incidentLabels
      .map((label) => label.name)
      .filter((name) => name !== incidentLabel.name);
    const {
      type,
      config: { ordered_list: orderedItemsList },
      enrichment_config: enrichmentConfig,
    } = incidentLabel;
    const { activeLevels, allowDeactivation } = this.state;

    const canManualInput = get(featureToggles, 'allow_tags_manual_input', true);

    return (
      <ThemeProvider theme={darkTheme}>
        <div id="priority-form" className={styles['priority-form']}>
          <div className={styles.details}>
            <div className={styles['details-name']}>
              <Field
                name="name"
                validate={(value) => validateNameField(value, existedNames)}
                render={({ input, meta }) => (
                  <div className={meta.error ? styles.fieldError : ''}>
                    <BamInput
                      input={{ ...input }}
                      title="Tag Name"
                      aria-label="Tag Name"
                      placeholder="Enter a name"
                      autoFocus
                    />
                    {meta.error && <span className={styles['error-text']}>{meta.error}</span>}
                  </div>
                )}
                subscription={{ value: true, error: true }}
              />
            </div>
            <div className={styles['details-type']}>
              <span>{type}</span>
              <HelpTooltip text="Allows marking and sorting incidents using customizable priority levels" />
            </div>
            {canManualInput && (
              <div className={styles.manualInput}>
                <div className={styles.horizontalSeparator} />
                <div className={styles.checkboxLayout}>
                  <Field
                    name="canManualInput"
                    component={BamCheckboxField}
                    label="Allow manual input"
                    baseProps={{
                      hint:
                        'Enables users to manually change the values. Manually changing tag data will stop BigPanda’s automated incident enrichment for that tag.',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.notes}>
            <Field
              name="description"
              title="Add a Note"
              optional
              validate={validateDescriptionField}
              render={({ input, meta }) => (
                <div className={meta.error ? styles.fieldError : ''}>
                  <BamAddNoteField input={{ ...input }} description="(optional)" maxRows={1} />
                  {meta.error && <span>{meta.error}</span>}
                </div>
              )}
              subscription={{ value: true, error: true }}
            />
          </div>
          <div className={styles.list}>
            <div className={styles['list-header']}>
              <div className={styles['list-header-id']}>Level</div>
              <div className={styles['list-header-orderId']}>
                <SCHBox gap="5px">
                  <span>Order ID</span>
                  <HBox>
                    <HelpTooltip text="Order ID of a level" />
                  </HBox>
                </SCHBox>
              </div>
              <div className={styles['list-header-name']}>Name</div>
              <div className={styles['list-header-color']}>Color</div>
              <div className={styles['list-header-description']}>
                Description <span>(optional)</span>
              </div>
              <div className={styles['list-header-activeToggle']}>Active</div>
            </div>
            {orderedItemsList.map((item, index) => {
              const fieldNamePrefix = `config.ordered_list[${index}]`;
              const isInactive = activeLevels[item.order_id] === undefined;
              const itemClasses = {
                [styles['list-item']]: true,
                [styles['list-item--inactive']]: isInactive,
              };
              return (
                <div key={fieldNamePrefix} className={cn(itemClasses)}>
                  <div className={styles['list-item-id']}>{index + 1}</div>
                  <div className={styles['list-item-orderId']}>{item.order_id}</div>
                  <div className={styles['list-item-name']}>
                    <Field
                      name={`${fieldNamePrefix}.display_name`}
                      validate={validateDisplayNameField}
                      validateOnBlur
                      render={({ input, meta }) => {
                        const {
                          shouldShow: shouldShowTooltip,
                          message: tooltipMessage,
                        } = nameFieldTooltipLogic(input.value);
                        return (
                          <div className={meta.error ? styles.fieldError : ''}>
                            <Tooltip
                              placement="right"
                              text={tooltipMessage}
                              variant="notice"
                              isAlwaysVisible={shouldShowTooltip && meta.active}
                              isActive={shouldShowTooltip && meta.active}
                            >
                              <BamInput input={{ ...input }} disabled={isInactive} />
                            </Tooltip>
                            {meta.error && <span>{meta.error}</span>}
                          </div>
                        );
                      }}
                      subscription={{ value: true, error: true, active: true }}
                    />
                  </div>
                  <div className={styles['list-item-color']}>
                    <Field
                      name={`${fieldNamePrefix}.color`}
                      render={({ input }) => {
                        const icon = (
                          <div
                            className={cn(styles.colorDot, styles['colorDot--active'])}
                            style={{ background: colorsNameHexObj[input.value].value }}
                          />
                        );
                        return (
                          <BamDropdownField
                            icon={icon}
                            input={{ value: input.value, onChange: input.onChange }}
                            baseProps={{ maxHeight: 170 }}
                            options={generatedColorsOptions}
                            minimalOptionsLengthForSearch={generatedColorsOptions.length + 1}
                            disabled={isInactive}
                          />
                        );
                      }}
                      subscription={{ value: true }}
                    />
                  </div>
                  <div className={styles['list-item-description']}>
                    <Field
                      validate={validateDescriptionField}
                      name={`${fieldNamePrefix}.description`}
                      render={({ input, meta }) => (
                        <div className={meta.error ? styles.fieldError : ''}>
                          <BamInput input={{ ...input }} disabled={isInactive} />
                          {meta.error && <span>{meta.error}</span>}
                        </div>
                      )}
                      subscription={{ value: true, error: true }}
                    />
                  </div>
                  <div className={styles['list-item-activeToggle']}>
                    <Field
                      name={`${fieldNamePrefix}.active`}
                      render={({ input, meta }) => {
                        const tooltip = activeToggleTooltipLogic(
                          allowDeactivation,
                          input.value,
                          'Priority'
                        );
                        return (
                          <Tooltip
                            text={tooltip.content}
                            isAlwaysVisible={meta.active}
                            placement={tooltip.position}
                          >
                            <div>
                              <BamToggle
                                color="green"
                                checked={input.value}
                                disabled={input.value && !allowDeactivation}
                                onClick={input.onChange}
                              />
                            </div>
                          </Tooltip>
                        );
                      }}
                      subscription={{ value: true, meta: true }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.separator} />
          <div className={styles.patterns}>
            <FormulaExpressionList
              arrayName="enrichment_config.items"
              items={enrichmentConfig ? enrichmentConfig.items : []}
              listHeader={
                <FormulaExpressionListHeader
                  counter={enrichmentConfig ? enrichmentConfig.items.length : 0}
                />
              }
              title="Item"
              collapseClassName={styles.expression}
              renderItem={(keyName, formula, error) => (
                <React.Fragment>
                  <FormulaCondition
                    name={`${keyName}.condition`}
                    defaultValue={formula.condition}
                    title={
                      <span>
                        Condition{' '}
                        <span className={styles['formula-expression-subtitle']}>(Optional)</span>
                      </span>
                    }
                    placeholder="Query filter (e.g. application = billing*)"
                    hint="Use a condition to define when to apply the value"
                    unifiedTags={unifiedTags}
                  />
                  <FormulaValue
                    name={`${keyName}.value`}
                    defaultValue={formula.value}
                    title={<span>Value </span>}
                    placeholder="Fixed value or Formula"
                    hint="Use formula to define the tag value"
                    tooltip={priorityTooltip}
                    tooltipWidth={tooltipWidthByType(incidentLabel.type)}
                    error={error}
                  />
                </React.Fragment>
              )}
              defaultItem={defaultFormulaExpression()}
              formErrors={formErrors}
              featureToggles={featureToggles}
              displayErrorContent={displayErrorContent}
              closeErrorMessage={closeErrorMessage}
            />
          </div>
          <FormSpy
            subscription={{ values: true }}
            onChange={(form) => this.updateActiveLevels(form.values)}
          />
        </div>
      </ThemeProvider>
    );
  }
}

PriorityForm.propTypes = {
  incidentLabel: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
    type: PropTypes.oneOfType(incidentTagTypes),
    config: PropTypes.shape({
      ordered_list: PropTypes.arrayOf(
        PropTypes.shape({ order_id: PropTypes.number, active: PropTypes.bool })
      ),
    }),
    enrichment_config: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          condition: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }),
  }),
  incidentLabels: PropTypes.arrayOf(PropTypes.object),
  featureToggles: PropTypes.shape({ allow_tags_manual_input: PropTypes.bool }).isRequired,
  formErrors: PropTypes.shape({}),
  unifiedTags: PropTypes.arrayOf(PropTypes.shape({})),
  displayErrorContent: PropTypes.func.isRequired,
  closeErrorMessage: PropTypes.func.isRequired,
};

PriorityForm.defaultProps = {
  incidentLabel: {},
  incidentLabels: [],
  formErrors: {},
  unifiedTags: undefined,
};

export default hot(module)(PriorityForm);
