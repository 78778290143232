import {
  AllSizes, HBox, Text, VBox,
} from '@bp/kung-fu';
import React from 'react';

interface IProps {
  sourceSystem: string | undefined;
  sourceTag: string;
}

export function ExtractionRuleTooltipContent({ sourceSystem, sourceTag }: IProps): JSX.Element {
  return (
    <VBox>
      {sourceSystem && (
        <HBox>
          <VBox marginEnd="5px">
            <Text size={AllSizes.SMALL} weight="bold">
              Source System:
            </Text>
          </VBox>
          <VBox>
            <Text size={AllSizes.SMALL}>{sourceSystem}</Text>
          </VBox>
        </HBox>
      )}
      {sourceTag && (
        <HBox>
          <VBox marginEnd="5px">
            <Text size={AllSizes.SMALL} weight="bold">
              Source Tag:
            </Text>
          </VBox>
          <VBox>
            <Text size={AllSizes.SMALL}>{sourceTag}</Text>
          </VBox>
        </HBox>
      )}
    </VBox>
  );
}

export default ExtractionRuleTooltipContent;
