import styled from 'styled-components';
import { HBox } from '@bp/kung-fu';

export const List = styled.ul`
display: flex;
flex-direction: column;
gap: 4px;
`;

export const ListItem = styled.li`
display: flex;
align-items: center;
gap: 8px;
`;

export const Bullet = styled(HBox)`
width: 5px;
height: 5px;
border-radius: 50%;
background-color: ${(p) => p.theme.bp_white};
`;
