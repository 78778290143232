const { io } = require('socket.io-client');

angular.module('bigpanda').factory('socketIo', socketIo);

const WEBSOCKETS_PATH = '/web/sockets/v2';

function socketIo($log, Config, AuthService) {
  function connect() {
    if (!AuthService.isAuth()) {
      throw new Error('Not authenticated. Web socket will not open');
    }

    return io(Config.webSocketBaseUrl, {
      autoConnect: true,
      reconnection: true,
      reconnectionDelayMax: Config.webSocketsReconnectTimeout,
      reconnectionDelay: 500,
      reconnectionAttempts: 100,
      timeout: 20000,
      path: WEBSOCKETS_PATH,
      withCredentials: true,
      transports: ['websocket', 'polling'],
    });
  }

  return {
    connect: connect,
  };
}
