import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './horizontal_rule.scss';

const HorizontalRule = ({ className }) => <hr className={cn(styles.horizontalRule, className)} />;

HorizontalRule.propTypes = {
  className: PropTypes.string,
};

HorizontalRule.defaultProps = {
  className: undefined,
};

export default HorizontalRule;
