import { validateMandatory, validatePassword } from 'react/common/validations/common';

const SERVER_ERRORS = {
  400: 'Server validation error, please contact support',
  403: 'This is not your current password',
  default: 'Temporary issue, please try again',
};

const getServerErrorMessage = (status) => SERVER_ERRORS[status] || SERVER_ERRORS.default;

export const isCurrentPasswordError = (status) => status === 403 && getServerErrorMessage(status);
export const isServerError = (status) => status !== 403 && getServerErrorMessage(status);

export const isCurrentPasswordValid = (value) => {
  return validateMandatory(value, 'Current password');
};

export const isConfirmPasswordValid = (confirmPassword, { newPassword, currentPassword }) => {
  if (!confirmPassword) {
    return validateMandatory(confirmPassword, 'Confirm password');
  } else if (newPassword !== confirmPassword) {
    return 'Passwords do not match';
  } else if (currentPassword === confirmPassword) {
    return 'New password must be different than the old one';
  }

  return null;
};

export const isNewPasswordValid = (value) => {
  return validatePassword(value);
};
