import { path as prevPath } from '../constants';

export const name = 'integrations';
export const path = `${prevPath}.${name}`;

export const PENDING_INTEGRATION_STATE = 'pending';
export const INTEGRATION_ALERT_TYPE = 'alert';

export const TARGET_INTEGRATION_TYPE = 'target';
export const ACTIVE_INTEGRATION_STATE = 'active';
