import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Field } from 'react-final-form';
import { BamBpqlInput } from '@bp/bam';
import { AllSizes } from '@bp/kung-fu';
import QueryHelper from '../../../../../../../../../workspaces/apps/incident-enrichment/src/components/QueryHelper';

class FormulaCondition extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      triggerComponentProps: {
        title: 'Condition',
        description: '(optional)',
        size: AllSizes.MEDIUM,
        value: '',
      },
    };
  }

  componentDidMount() {
    this.setTriggerComponentInitialValue();
  }

  setTriggerComponentInitialValue = () =>
    this.setState({
      triggerComponentProps: {
        ...this.state.triggerComponentProps,
        value: this.props.defaultValue,
      },
    });

  validateDisplayQuery = (val) => (val ? BamBpqlInput.helpers.BpqlInputValidator(val) : undefined);

  render() {
    const {
      defaultValue,
      placeholder,
      hint,
      name,
      title,
      unifiedTags,
      disableQueryHelper,
    } = this.props;

    return !disableQueryHelper ? (
      <Field name={name}>
        {({ input: { onChange } }) => (
          <QueryHelper
            placeholder={placeholder}
            alertTags={unifiedTags}
            onChange={onChange}
            triggerComponentProps={this.state.triggerComponentProps}
          />
        )}
      </Field>
    ) : (
      <Field
        name={name}
        defaultValue={defaultValue}
        aria-label={placeholder}
        title={title}
        hint={hint}
        component={BamBpqlInput}
        placeholder={placeholder}
        interactiveTooltip
        fixedFont
        validate={this.validateDisplayQuery}
        validateOnBlur
        tags={unifiedTags}
      />
    );
  }
}

FormulaCondition.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  title: PropTypes.PropTypes.node.isRequired,
  placeholder: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  unifiedTags: PropTypes.arrayOf(PropTypes.shape({})),
  disableQueryHelper: PropTypes.bool,
};

export default hot(module)(FormulaCondition);
