import moment from 'moment';
import { createCustomDateOption, TimeFormats } from '@bp/bam';
const { momentOrEpochRangeRelativeTimeFrameFormat } = TimeFormats;

const now = () => moment();
const twoDaysAgo = () => now().clone().subtract(2, 'days');
const defaultDateTimeOption = 'last-2-days';
const defaultDateTimeOptionIndex = 4;

const getCorrelationTimeFrameOptions = () => {
  const defaultOption = {
    text: 'Last 48 Hours',
    value: defaultDateTimeOption,
    from: now().clone().subtract(2, 'days'),
    to: now(),
  };

  const options = [
    {
      text: 'Last Hour',
      value: 'last-hour',
      from: now().clone().subtract(1, 'hours'),
      to: now(),
    },
    {
      text: 'Last 2 Hours',
      value: 'last-2-hours',
      from: now().clone().subtract(2, 'hours'),
      to: now(),
    },
    {
      text: 'Last 6 Hours',
      value: 'last-6-hours',
      from: now().clone().subtract(6, 'hours'),
      to: now(),
    },
    {
      text: 'Last 24 Hours',
      value: 'last-24-hours',
      from: now().clone().subtract(1, 'days'),
      to: now(),
    },
    defaultOption,
    createCustomDateOption({ from: twoDaysAgo(), to: now() }),
  ].map((o) => ({ ...o, description: momentOrEpochRangeRelativeTimeFrameFormat(o) }));

  return { options, defaultOption };
};

module.exports = {
  getCorrelationTimeFrameOptions,
  defaultDateTimeOptionIndex,
};
