import {
  AllSizes,
  darkTheme, ShareIcon, SplitButton,
} from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

interface IShareButton {
  disabled?: boolean,
  tooltipText?: string,
  countTooltipText?: string
  onClick?: () => void,
  countOnClick: () => void,
  shareCount: number
}

const ShareButton = ({
  disabled, tooltipText, onClick, shareCount, countOnClick, countTooltipText,
}: IShareButton) => (
  <ThemeProvider theme={darkTheme}>
    <SplitButton
      leftButtonProps={{
        icon: <ShareIcon color={(p) => (shareCount ? p.theme.bp_blue : undefined)} />,
        ariaLabel: 'Share incident',
        size: AllSizes.LARGE,
        onClick,
        disabled,
        tooltipProps: {
          isActive: true,
          text: tooltipText,
          placement: 'bottom',
        },
      }}
      rightButtonProps={
        shareCount
          ? {
            size: AllSizes.LARGE,
            text: shareCount,
            ariaLabel: `Number of shares - ${shareCount}`,
            onClick: countOnClick,
            disabled,
            tooltipProps: {
              isActive: true,
              text: countTooltipText,
              placement: 'bottom',
            },
          }
          : undefined
      }
    />
  </ThemeProvider>
);

export default ShareButton;
