module.exports = angular.module('bigpanda.reports', [
  // eslint-disable-line angular/module-setter
  'bigpanda.backend.services',
  'bigpanda.utils',
  'bp.widgets',
  'DisplayCommon',
  'ngAnimate',
  'mgcrea.ngStrap',
  'ui.router',
]);
