angular.module('bigpanda').controller('ChangeUserPasswordCtrl', ChangeUserPasswordCtrl);

function ChangeUserPasswordCtrl($scope, $state, Config, pubSubService, $ngRedux) {
  const vm = this;
  $scope.updatePage('changepassword');
  pubSubService.broadcast('currentTitleChanged', '');

  vm.origin = $state.current.data.getOrigin();
  vm.handleSuccess = handleSuccess;
  vm.store = $ngRedux;

  function handleSuccess() {
    pubSubService.broadcast('User.PasswordChanged', { origin: vm.origin });
    $state.go(Config.defaultState, null, { location: false, reload: true });
  }
}
