import get from 'lodash/get';
import moment from 'moment';
import { DATE_FORMAT_THIS_YEAR, DATE_TIME_FORMAT, TIME_FORMAT } from 'common/constants';
import { path as rolesListPath } from '../roles_list/constants';
import { formatDisplayedPermissions, formatDisplayedEnvironments } from '../utils/formatCollection';

const selectors = {};

const formatTimestamp = (timestamp) =>
  timestamp && moment.unix(timestamp).format('MMM D, YYYY HH:MMa');

export const normalizeRole = (selectedRole) => ({
  ...selectedRole,
  displayedCreationTimestamp: formatTimestamp(selectedRole.created_at),
  displayedLastChanged: formatTimestamp(selectedRole.updated_at),
  displayedPermissions: formatDisplayedPermissions(selectedRole.permissionsWithoutEnvironments),
  displayedEnvironments: formatDisplayedEnvironments(selectedRole.environments),
});

selectors.getRoleById = (state, roleId) => {
  const { roles } = get(state, rolesListPath);
  const selectedRole = roles && roles.find((role) => role._id === roleId);
  return selectedRole ? normalizeRole(selectedRole) : undefined;
};

selectors.formatDate = (timestamp) =>
  timestamp && moment.unix(timestamp).format('MMM D, YYYY HH:MMa');

export default selectors;
