import { withReactQueryClient } from '@bp/data-access';
import {
  AllSizes, ButtonVariants, CorrelationIcon, darkTheme,
  Modal,
} from '@bp/kung-fu';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { IIncident } from '../../types';
import dictionary from './dictionary';
import { getIncident } from './incident';
import { MatchedPatternsTrigger } from './matched-patterns.style';
import MatchedPatternsContent from './matched-patterns-content';
import { ICorrelationPattern } from './types';

const MatchedPatternsModal = ({ correlationPatterns = [], manualCorrelation, incidentId }:
{ correlationPatterns?: ICorrelationPattern[], manualCorrelation?: string, incidentId: string }) => {

  let incidentCorrelationPatterns = [];

  if (!correlationPatterns.length) {
    const { data: incident }: UseQueryResult<IIncident> = useQuery(
      [incidentId],
      () => getIncident(incidentId),
    );
    incidentCorrelationPatterns = incident?.correlation_patterns || [];
  }

  const correlations = (correlationPatterns.length ? correlationPatterns : incidentCorrelationPatterns);

  const isActive = !!correlations.length && !manualCorrelation;

  const activeTooltipText = isActive && dictionary.active_matched_patterns_tooltip;
  const inactiveTooltipText = manualCorrelation ? dictionary.manual_matched_patterns_tooltip
    : dictionary.no_matched_patterns_tooltip;

  return (
    <ThemeProvider theme={darkTheme}>
      <Modal
        headerProps={{
          icon: <CorrelationIcon />,
          title: dictionary.matched_patterns_title,
          docsUrl,
        }}
        ariaDescribedby={dictionary.matched_patterns_title}
        ariaLabelledby={dictionary.matched_patterns_title}
        content={
          <MatchedPatternsContent correlationPatterns={correlations} />
      }
      >
        <MatchedPatternsTrigger
          variant={ButtonVariants.ACTION_CONTAINER}
          size={AllSizes.LARGE}
          icon={<CorrelationIcon color={(p) => p.theme.bp_blue_light} />}
          ariaLabel={dictionary.matched_patterns_title}
          disabled={!isActive}
          tooltipProps={{
            text: activeTooltipText || inactiveTooltipText,
            placement: 'bottom',
            isActive: true,
          }}
        />
      </Modal>
    </ThemeProvider>
  );
};

export default withReactQueryClient(MatchedPatternsModal);

const docsUrl = {
  label: dictionary.learn_more_title,
  url: dictionary.matched_patterns_docs_url,
};
