angular.module('bp.widgets').directive('bpEmptyMsg', () => {
  return {
    restrict: 'E',
    templateUrl: 'widgets/bp_empty_msg/bp_empty_msg',
    scope: {
      header: '@',
      msg: '@',
      center: '@',
      callToAction: '@',
      link: '@',
      action: '&',
      href: '@',
    },
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };
  function controller() {
    const vm = this;
    vm.onClick = onClick;

    function onClick() {
      if (vm.href) {
        window.location.replace(vm.href);
      }
    }
  }
});
