angular.module('bigpanda').directive('noDashboardsAvailable', noDashboardsAvailable);

function noDashboardsAvailable() {
  return {
    templateUrl: 'dashboard/no_dashboards_available/no_dashboards_available',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller() {}
}
