import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
angular.module('bigpanda').controller('CorrelationRulePopupCtrl', CorrelationRulePopupCtrl);

function CorrelationRulePopupCtrl(
  $scope,
  $state,
  $q,
  $timeout,
  CorrelationRulesService,
  CorrelationPreview,
  CorrelationRulePopup,
  resolvedIntegrations,
  CorrelationConfigService,
  resolvedCorrelation,
  resolvedConfigRules,
  CorrelationTimeframePresets,
  CorrelationCompletionLogic,
  CorrelationActions,
  BpqlUtils
) {
  if (!resolvedIntegrations) {
    return CorrelationActions.escapeRoute(
      'Could not find Correlations Rule info',
      'app.settings.correlations'
    );
  }

  let wrapUpTimer = null;
  let configRules = resolvedConfigRules;

  const vm = this;
  const PREVIEW_DEFAULT = {
    totalEntities: 0,
    compressionRate: 0,
    progress: { done: 0, goal: 0 },
  };
  const bpqlErrorDebounce = CorrelationActions.setErrorDebounce(BPQLError);

  vm.ENTITIES_PREVIEW_AMOUNT = 5;
  vm.MAX_TAGS = 5;
  vm.WINDOW_LIMIT = 4320;

  vm.stateData = CorrelationActions.getStateData('correlation', $state.params);
  vm.editMode = !!($state.params.edit || $state.params.duplicate);
  vm.windowTooltip = 'settings/correlation/correlation_rules/popup/correlation_rule_window_tooltip';

  vm.preview = null;
  vm.previewData = PREVIEW_DEFAULT;
  vm.loadingPreview = false;
  vm.formChanged = false;
  vm.previewInitialized = false;
  vm.inactive = false;
  vm.globalError = false;
  vm.globalErrorText = '';
  vm.actionInProgress = false;

  vm.dismissGlobalError = dismissGlobalError;
  vm.refresh = refresh;
  vm.loadTagsFromAutocompleteQuery = loadTagsFromAutocompleteQuery;
  vm.loadSourceFromAutocompleteQuery = loadSourceFromAutocompleteQuery;
  vm.getTagsErrMsg = getTagsErrMsg;
  vm.getScannedTextValue = getScannedTextValue;
  vm.integrations = resolvedIntegrations;
  vm.onFormChanged = onFormChanged;
  vm.calcProgress = calcProgress;
  vm.regeneratePreview = regeneratePreview;
  vm.closePopup = closePopup;
  vm.submit = submit;

  vm.timeframePresets = CorrelationTimeframePresets.getPresets();
  vm.selectedTimeframePreset = vm.timeframePresets[4];
  vm.autocompleteLogic = CorrelationCompletionLogic.getLogic(onQueryChange);

  init();

  function init() {
    resolvedIntegrations.unshift({ display: 'All Systems', value: '*' });

    vm.correlationRuleVm = CorrelationRulePopup.convertToCorrelationRuleVm(resolvedCorrelation);
    vm.inactive = !vm.correlationRuleVm.active;

    $scope.$watch(() => vm.selectedTimeframePreset.timeframe, onTimePresetChanged, true);
    $scope.$watch(
      () => vm.inactive,
      () => {
        vm.correlationRuleVm.active = !vm.inactive;
      }
    );

    $timeout(refreshPreview, 0);
  }

  function updateRules() {
    return CorrelationConfigService.getConfig().then((config) => {
      configRules = config.matching_rules;
    });
  }

  function loadTagsFromAutocompleteQuery($query) {
    return CorrelationCompletionLogic.autocompleateTagsFieldChange($query);
  }

  function loadSourceFromAutocompleteQuery($query) {
    return CorrelationCompletionLogic.autocompleateSourceFieldChange($query, resolvedIntegrations);
  }

  function onTimePresetChanged(newVal, oldVal) {
    if (newVal !== oldVal) {
      onFormChanged();
    }
  }

  function getScannedTextValue() {
    const progress = vm.previewData.progress;
    const scale = progress.scale || '';
    const progressGoalDisplay = Number.isInteger(progress.goal)
      ? progress.goal
      : progress.goal.toFixed(2);
    return `${Math.round(progress.done)}/${progressGoalDisplay} ${scale}`;
  }

  function BPQLError(error) {
    if (vm.BPQLInvalid) {
      vm.BPQLError = error;
      $scope.$digest();
    }
  }

  function getTagsErrMsg(name, errorsObj = {}) {
    let msg = '';
    if (errorsObj.leftoverText) {
      msg = `Please choose a ${name} or remove leftover text`;
    } else if (errorsObj.minTags) {
      msg = `Please use at least one ${name}`;
    } else if (errorsObj.maxTags) {
      msg = `Maximum number of ${name}s allowed is ${vm.MAX_TAGS}`;
    }
    return msg;
  }

  function onQueryChange() {
    $timeout(() => {
      const query = BpqlUtils.getBpqlQuery(vm.correlationRuleVm.query, true);
      if (query.invalid && query.error) {
        vm.BPQLInvalid = true;
        bpqlErrorDebounce(query.error.message);
      } else {
        vm.BPQLInvalid = false;
        vm.BPQLError = null;
        onFormChanged();
      }
    }, 0);
  }

  function onFormChanged() {
    vm.formChanged = true;
    if (!vm.previewInitialized) {
      $timeout(() => {
        refreshPreview();
      }, 0);
    }
  }

  function submit() {
    vm.actionInProgress = true;
    return CorrelationConfigService.checkForConfigChanges().then((isChanged) => {
      if (isChanged || vm.showRefresh) {
        vm.actionInProgress = false;
        vm.showRefresh = true;
        vm.globalError = true;
        vm.globalErrorText = 'Some items were modified by another user. Refresh to updates.';
      } else if (vm.form.$valid && !vm.BPQLInvalid) {
        const correlationRule = buildNewRule({
          edit: $state.params.edit,
          correlationRules: configRules,
        });

        if (correlationRule) {
          const action = $state.params.edit
            ? CorrelationRulesService.editCorrelationRule
            : CorrelationRulesService.addNewCorrelationRule;
          return action(correlationRule).then((editedRuleId) => {
            closePopup(editedRuleId);
          });
        }
        vm.actionInProgress = false;
        vm.showRefresh = false;
        vm.globalError = true;
        vm.globalErrorText =
          'This rule is identical to an existing one. Change your definitions and try again.';
      }
    });
  }

  function calcProgress() {
    if (vm.previewData && vm.previewData.progress) {
      const done = vm.previewData.progress.doneInSeconds / vm.previewData.progress.goalInSeconds;
      return Math.round(done * 100);
    }
    return 0;
  }

  function regeneratePreview() {
    debounce(refreshPreview, 300)();
  }

  function refreshPreview() {
    if (!vm.form.$valid) {
      return;
    }

    // Since a refresh call creates a timeout we need to make sure
    // there are no timeouts pending from previuos refresh calls
    if (wrapUpTimer) {
      $timeout.cancel(wrapUpTimer);
      wrapUpTimer = null;
    }

    vm.formChanged = false;
    vm.loadingPreview = true;
    vm.previewInitialized = true;

    const start = moment(vm.selectedTimeframePreset.timeframe.start).unix();
    const end = moment(vm.selectedTimeframePreset.timeframe.end).unix();

    removeCurrentPreview()
      .then(() => {
        // After removal is done, the earlier promise is rejected, so we set the loading again
        vm.loadingPreview = true;
        return CorrelationRulePopup.getConfigWithNoRules();
      })
      .then((config) => {
        const configCopy = angular.copy(config);
        const previewRule = convertToPreviewRule(
          buildNewRule({
            forceActive: true,
            isPreview: true,
          })
        );
        configCopy.matching_rules.push(previewRule);
        vm.preview = new CorrelationPreview(configCopy, { start, end });
        return vm.preview.getPreview().then(
          (data) => {
            vm.loadingPreview = false;
          },
          null,
          (previewData) =>
            CorrelationRulePopup.makePreviewViewModel(previewData).then((patternPreview) => {
              vm.previewData = patternPreview;
              vm.loadingPreview = previewData.status !== 'done';
            })
        );
      })
      .then(() => {
        // After the preview loading is done the we take extra time to coplete the
        // animation of the loading strip
        wrapUpTimer = $timeout(() => {}, 1200);
        return wrapUpTimer;
      });
  }

  function removeCurrentPreview() {
    if (vm.preview) {
      const promise = vm.preview.removePreview();
      vm.preview = null;
      vm.previewData = PREVIEW_DEFAULT;
      return promise;
    }
    return $q.when({});
  }

  function closePopup(id) {
    CorrelationActions.closePopup(
      'app.settings.correlations',
      { correlationId: id },
      removeCurrentPreview
    );
  }

  function buildNewRule(options) {
    return CorrelationRulePopup.convertFromCorrelationRuleVm(vm.correlationRuleVm, options);
  }

  function dismissGlobalError() {
    vm.globalError = false;
    vm.globalErrorText = '';
  }

  function refresh() {
    if (!resolvedCorrelation) {
      return updateRules().then(() => {
        vm.showRefresh = false;
        dismissGlobalError();
      });
    }

    CorrelationRulesService.getCorrelationRuleById(resolvedCorrelation.id).then(
      (correlationRule) => {
        if (correlationRule) {
          vm.correlationRuleVm = CorrelationRulePopup.convertToCorrelationRuleVm(correlationRule);
          vm.inactive = !vm.correlationRuleVm.active;
          vm.showRefresh = false;
          dismissGlobalError();
        } else {
          CorrelationActions.itemDeletedNotification(
            'The correlation pattern has been deleted since you started editing.',
            'correlations'
          );
        }
      }
    );
  }

  function convertToPreviewRule(correlationRule) {
    return omit(correlationRule, 'metadata');
  }
}
