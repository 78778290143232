const { react2angular } = require('react2angular');
const IncidentTagsGrid = require('react/modules/tags/IncidentTagsGrid/IncidentTagsGrid').default;

angular
  .module('bigpanda')
  .component(
    'reactIncidentTagsGrid',
    react2angular(IncidentTagsGrid, ['store', 'environmentId', 'incident', 'tagDefinitions'])
  );

angular.module('bigpanda').directive('incidentTagsGrid', incidentTagsGrid);

function incidentTagsGrid() {
  return {
    restrict: 'E',
    scope: {
      environmentId: '=',
      incident: '=',
      tagDefinitions: '=',
    },
    templateUrl: 'overview/incidents/incident_tags/react_incident_tags_grid',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($ngRedux) {
    const vm = this;
    vm.store = $ngRedux;
  }
}
