import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { BamTile } from '@bp/bam';
import { AllSizes, Badge } from '@bp/kung-fu';
import BpTimeSince from 'common/utils/BpTimeSince';
import './list_item.scss';
import ApiKeysActions from '../../../actions/ApiKeysActions';
import {
  CREATED_SORT_MODE,
  UPDATED_SORT_MODE,
  SortShape,
  LAST_USED_SORT_MODE,
} from '../../../constants';

const generateFirstRow = (apiKey, sortMode) => {
  const { updated_at, created_at, last_used } = apiKey;

  const timeObject = {
    title: '',
    timestamp: '',
  };

  switch (sortMode) {
    case UPDATED_SORT_MODE:
      timeObject.title = UPDATED_SORT_MODE.text;
      timeObject.timestamp = BpTimeSince(updated_at);
      timeObject.timestampUnix = updated_at;
      break;
    case CREATED_SORT_MODE:
      timeObject.title = CREATED_SORT_MODE.text;
      timeObject.timestamp = BpTimeSince(created_at);
      timeObject.timestampUnix = created_at;
      break;
    case LAST_USED_SORT_MODE:
      timeObject.title = LAST_USED_SORT_MODE.text;
      timeObject.timestamp = BpTimeSince(last_used);
      timeObject.timestampUnix = last_used;
      break;
  }

  return {
    customParts: !apiKey.alive
      ? [<Badge text="Error" status={apiKey.status} size={AllSizes.SMALL} />]
      : null,
    title: apiKey.name,
    textParts: [{ value: apiKey.key_suffix }],
    time: timeObject,
  };
};

const generateSecondRow = (apiKey) => ({
  textParts: [
    {
      label: 'Associated User',
      value: apiKey.alive ? apiKey.user.name : `N/A (${apiKey.user.name})`,
    },
  ],
  actionsBundle: <ApiKeysActions apiKey={apiKey} />,
});

const ListItem = ({ apiKey, sortMode, ...props }) => {
  const handleClick = () => {
    window.location.href = `/#/app/settings/api_keys/${apiKey.id}`;
  };

  return (
    <BamTile
      className="api-key-tile fs-exclude"
      bamStatus={apiKey.status}
      firstRow={generateFirstRow(apiKey, sortMode)}
      secondRow={generateSecondRow(apiKey)}
      onClick={handleClick}
      inactive={!apiKey.active}
      {...props}
    />
  );
};

ListItem.propTypes = {
  apiKey: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  sortMode: SortShape.isRequired,
};

export default hot(module)(ListItem);
