import map from 'lodash/map';
import isString from 'lodash/isString';
import filter from 'lodash/filter';
import * as EntityTitleGenerator from 'common/modules/entities/EntityTitleGenerator';
import * as TimestampGenerator from 'common/modules/incidents/TimestampGenerator';
import { getTitle } from 'common/modules/incidents/TitleBuilder';
import {
  getSourceBreakdownData,
  findSystemName,
} from 'common/modules/incidents/SourceBreakdownGenerator';
import { getTagValue } from 'common/modules/tags/TagUtils';
import BpTimeSince from 'common/utils/BpTimeSince';
import { TimeFormats } from '@bp/bam';
const { momentOrEpochRelativeTimeFormat } = TimeFormats;
// eslint-disable-next-line import/prefer-default-export
export function enrichIncident(rawIncident, integrations) {
  const incident = { ...rawIncident };
  incident.lastChanged = rawIncident.last_change
    ? moment.unix(rawIncident.last_change).calendar()
    : '';
  incident._v = rawIncident.__v; // Use the moongose version
  incident.consolidated =
    rawIncident.entities && rawIncident.entities.filter((e) => e.is_active).length > 1;
  incident.lastChangeSince = BpTimeSince(rawIncident.last_change);
  incident.acknowledged =
    (rawIncident.zeroImpact && !rawIncident.is_active) || rawIncident.status === 'acknowledged';
  incident.maintenance =
    (rawIncident.zeroImpact && !rawIncident.is_active) || rawIncident.status === 'maintenance';
  incident.status = rawIncident.status.toLowerCase();
  incident.entities = incident.entities.map((e) => enrichEntity(e, integrations));

  const { _id: id } = incident;

  const timeProps = TimestampGenerator.getIncidentTimestamp(incident);
  const titleData = getTitle(integrations, { getWidth }, incident, {
    fontData: '',
    widthLimit: { max: 500, dynamicMin: 50 },
  });
  const breakdownSources = generateBreakdownSources(incident, integrations);
  const numberOfIncidentActiveAlerts = getActiveEntitiesCount(incident);
  const numberOfIncidentMaintenanceAlerts = getMaintenanceEntitiesCount(incident);

  return {
    id,
    incident,
    timeProps,
    titleData,
    breakdownSources,
    numberOfIncidentActiveAlerts,
    numberOfIncidentMaintenanceAlerts,
  };
}

function generateBreakdownSources(incident, integrations) {
  const breakdown = getSourceBreakdownData(integrations, incident.entities, incident.activeOnly);
  const { systems } = breakdown;

  if (systems.length > 1) {
    const parentSystems = [...breakdown.parentSystems];
    return {
      lastSystem: parentSystems.pop(),
      otherSystems: parentSystems.join(', '),
    };
  }

  return {
    lastSystem: systems[0],
    otherSystems: null,
  };
}

function getActiveEntitiesCount(incident) {
  return incident.status === 'ok'
    ? incident.entities.length
    : incident.entities.filter(
        (e) => e.is_active && (!e.maintenance_plan_ids || e.maintenance_plan_ids.length === 0)
      ).length;
}

function getMaintenanceEntitiesCount(incident) {
  return incident.entities.filter(
    (e) => e.maintenance_plan_ids && e.maintenance_plan_ids.length > 0
  ).length;
}

function enrichEntity(entity, integrations) {
  const enrichedEntity = { ...entity, ...dateParams(entity), ...titleParams(entity) };
  enrichedEntity.duration = getDuration(entity);
  enrichedEntity.status = getEntityStatus(entity);
  enrichedEntity.id = entity.id;
  enrichedEntity.initialStatus = (entity.initial_status || 'critical').toLowerCase();
  enrichedEntity.sourceCap = findSystemName(integrations, entity.source_system);
  enrichedEntity.hasStatusChange =
    entity.start !== entity.last_status_change_at || entity.status !== entity.initialStatus;
  enrichedEntity.links = normalizeLinks(entity);
  enrichedEntity.underMaintenance =
    entity.maintenance_plan_ids != null && entity.maintenance_plan_ids.length > 0;
  return enrichedEntity;
}

function isAcknowledged(rawEntity) {
  return !!getTagValue(rawEntity, { type: 'acknowledged' }) || rawEntity.acknowledged;
}

function isMaintenance(rawEntity) {
  return !!getTagValue(rawEntity, { type: '_maintenance' }) || rawEntity.maintenance;
}

function getEntityStatus(entity) {
  if (isMaintenance(entity)) {
    return 'maintenance';
  } else if (isAcknowledged(entity)) {
    return 'acknowledged';
  }
  return entity.status ? entity.status.toLowerCase() : 'critical';
}

function dateParams(entity) {
  const createdTime = entity.created;
  const createMoment = moment.unix(createdTime);
  const startedTime = entity.start;
  const startMoment = moment.unix(startedTime);

  const changeTime = entity.last_status_change_at || entity.changed;
  const changeMoment = moment.unix(changeTime);
  return {
    formattedCreatedTime: formattedTime(createdTime),
    createdFullDate: createMoment.calendar(),
    startedFullDate: momentOrEpochRelativeTimeFormat(startMoment),
    lastChanged: changeMoment.calendar(),
    lastChangedTimestamp: changeTime,
    lastChangedDate: changeMoment.format('MMM D'),
    lastChangedTime: changeMoment.format('h:mm a'),
    lastUpdated: formattedTime(changeTime),
  };
}

function titleParams(entity) {
  const entityMainProperties = EntityTitleGenerator.getMainProperties([entity]);
  return {
    title: getValue(entity, entityMainProperties.primary),
    subtitle: getValue(entity, entityMainProperties.secondary),
  };
}

function getValue(rawEntity, fields) {
  return fields.reduce((result, currentField) => {
    if (!result) {
      const tag = rawEntity.tags.find((t) => t.type === currentField.name);
      return tag ? { type: currentField.name, value: tag.value } : null;
    }
    return null;
  }, null);
}

function normalizeLinks(rawEntity) {
  const simpleUrlRegex = /^(http|https):\/\//i;
  const linkTags = filter(
    rawEntity.tags,
    (tag) => tag && isString(tag.value) && tag.value.match(simpleUrlRegex)
  );
  return map(linkTags, (tag) => ({
    name: tag.type,
    value: tag.value,
  }));
}

function formattedTime(time) {
  if (time) {
    const momentTime = moment(time * 1000);
    if (isTimestampBeforeAYearAgo(momentTime)) {
      return momentTime.year();
    } else if (isTimestampBeforeYesterday(momentTime)) {
      return momentTime.format('MMM D');
    }
    return momentTime.format('h:mm a');
  }
  return null;
}

function isTimestampBeforeYesterday(momentTime) {
  return momentTime.isBefore(moment().subtract(1, 'days'));
}

function isTimestampBeforeAYearAgo(momentTime) {
  return momentTime.isBefore(moment().subtract(1, 'year'));
}

function getDuration(entity) {
  if (entity.is_active) {
    return moment().unix() - entity.start;
  }
  return (entity.end ? entity.end : entity.lastChangedTimestamp) - entity.start;
}

function getWidth(font, fullString) {
  // if given, use cached canvas for better performance. Otherwise, create new canvas
  const canvas = getWidth.canvas || (getWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(fullString);
  return metrics.width;
}
