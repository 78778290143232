import { LAST_UI_STATE } from '../../common/constants';

angular.module('bigpanda').controller('BootstrapController', BootstrapController);

function BootstrapController(
  $state,
  bootstrap,
  pubSubService,
  BootstrapUtilsService,
  AuthService,
  $location
) {
  const vm = this;

  pubSubService.on('bootstrap.shouldAuthenticate', () => {
    AuthService.goToLogin();
  });

  vm.incidentFullScreen = $location.search().incidentfullscreen;
  vm.isIncidentsState = BootstrapUtilsService.isIncidentsState();
  vm.isTopologyFullScreen = $location.$$path === '/app/topology';

  pubSubService.on('bootstrap.finished', () => {
    let state = sessionStorage.getItem(LAST_UI_STATE);
    $state.go('app.default', null, { location: false });
    if (state) {
      try {
        state = JSON.parse(state);
        if (state.name) {
          $state.go(state.name, state.params || {});
        }
      } catch (err) {
        // JSON parse error
      }
      sessionStorage.removeItem(LAST_UI_STATE);
    }
  });

  bootstrap.boot(); // Start the bootstraping process.
}
