import sortBy from 'lodash/sortBy';
angular.module('bigpanda.reports').directive('bpCorrelationTable', ($filter, pubSubService) => {
  var commasFormatter = d3.format(',');

  function renderVisualization(data, $scope, displayConfig) {
    $scope.reportTable = formatReportTable(data, displayConfig);
  }

  function formatReportTable(data, displayConfig) {
    if (data.length === 0) {
      return data;
    }

    data = sortBy(data, 'key');
    var newTable = [[displayConfig.dateHeader, 'Alerts', 'Incidents', 'Compression']];
    var totalEvents = 0;
    var totalIncidents = 0;
    data.forEach((row) => {
      totalEvents += row.events_count;
      totalIncidents += row.incidents_count;
      var compression = $filter('eventCompression')(row.events_count, row.incidents_count);
      newTable.push([
        $filter('granularityBasedFormat')(displayConfig.timeGranularity, row.key),
        commasFormatter(row.events_count),
        commasFormatter(row.incidents_count),
        `${compression}%`,
      ]);
    });

    var compression = $filter('eventCompression')(totalEvents, totalIncidents);
    newTable.push([
      'Total',
      commasFormatter(totalEvents),
      commasFormatter(totalIncidents),
      `${compression}%`,
    ]);
    return newTable;
  }

  function onRefreshReportData($scope, containerController) {
    containerController.getDisplayConfiguration().then((displayConfig) =>
      containerController.getReportData().then((data) => {
        renderVisualization(data, $scope, displayConfig);
      })
    );
  }

  function link($scope, $element, $attrs, containerController) {
    onRefreshReportData($scope, containerController);

    var unsubscribe = pubSubService.on('reportWidgetsController.refreshReportData', (event) => {
      onRefreshReportData($scope, containerController);
    });

    $scope.$on('$destroy', () => {
      unsubscribe();
    });
  }

  return {
    restrict: 'E',
    templateUrl: 'reports/visualization_widget/visualizations/correlation_table/correlation_table',
    require: '^bpVisualizationWidgetContainer',
    scope: {},
    link: link,
  };
});
