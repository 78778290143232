import React, { useMemo } from 'react';
import {
  MultiStarsIcon,
  HBox,
  Text,
  copyToClipboard,
  VBox,
  ButtonVariants,
  AllSizes,
  RefreshIcon,
  Badge,
  StatusEnum,
  Button,
  useBoolean,
  ArrowdownIcon,
  ArrowupIcon,
  Collapsable,
} from '@bp/kung-fu';
import PropTypes from 'prop-types';
import {
  AI_INCIDENT_RCA,
  AI_INCIDENT_REASONING,
  AI_INCIDENT_SUMMARY,
  AI_INCIDENT_TITLE,
} from '../../../../../../common/constants';

import dic from '../../dictionary';

import AiActions from '../ai_actions/ai-actions';
import AIAnalysisKey from './ai-analysis-key';
import {
  RegenerateButtonWrapper,
  TitleWrapper,
  ExpandButton,
  AIActionsWrapper,
  AIBadgeWrapper,
} from '../../style.css';

const AiAnalysis = ({
  onThumbsDown,
  aiTags,
  onThumbsUp,
  isThumbsDownPressed = false,
  isThumbsUpPressed = false,
  onRegenerate,
  isGenerationEnabled,
  isReadOnly,
}) => {
  const title = useMemo(() => aiTags && aiTags.find((tag) => tag.tag_id === AI_INCIDENT_TITLE), [
    aiTags,
  ]);

  const filteredTags = useMemo(
    () => aiTags && aiTags.filter((tag) => tag.tag_id !== AI_INCIDENT_TITLE),
    [aiTags]
  );

  const getTagNameAndOrder = (tagId) => {
    switch (tagId) {
      case AI_INCIDENT_SUMMARY:
        return { order: 1, name: dic.ai_summary_title };
      case AI_INCIDENT_RCA:
        return { order: 2, name: dic.ai_root_cause_title };
      case AI_INCIDENT_REASONING:
        return { order: 3, name: dic.ai_reasoning_title };
      default:
        return '';
    }
  };

  const normalizedTags = useMemo(
    () =>
      filteredTags &&
      filteredTags
        .map((tag) => ({ ...getTagNameAndOrder(tag.tag_id), value: tag.tag_value }))
        .sort((a, b) => a.order - b.order),
    [filteredTags]
  );
  const collapsedItems = useMemo(
    () => normalizedTags && normalizedTags.length > 2 && normalizedTags.slice(1),
    [normalizedTags]
  );

  const [isCollapsed, { toggle: toggleCollapsed }] = useBoolean(false);

  const onCopy = () => {
    let str = `${title.tag_value}\n\n`;
    normalizedTags.forEach((tag) => (str += `${tag.name}: ${tag.value}\n\n`));
    return copyToClipboard(str);
  };

  const expandText = isCollapsed ? dic.collapse_title : dic.expand_title;

  return (
    <HBox gap="5px" flexGrow={1} margin="16px" marginTop="20px">
      <MultiStarsIcon fontSize="26px" color={(p) => p.theme.bp_purple} />
      <VBox gap="16px" width="100%">
        <TitleWrapper>
          <AIBadgeWrapper>
            <Badge status={StatusEnum.AI} text={dic.ai_analysis_title} size={AllSizes.MEDIUM} />
          </AIBadgeWrapper>
          {title && (
            <Text weight="bold" lineHeight="180%">
              {title.tag_value}
            </Text>
          )}
        </TitleWrapper>
        <VBox>
          {normalizedTags && normalizedTags[0] && (
            <VBox width="100%" flexGrow={1} paddingEnd="20px">
              <AIAnalysisKey name={normalizedTags[0].name} value={normalizedTags[0].value} />
            </VBox>
          )}
          <ExpandButton
            variant={ButtonVariants.ACTION_DEFAULT_V2}
            onClick={toggleCollapsed}
            text="Expand"
          >
            {expandText}
          </ExpandButton>
        </VBox>
        <Collapsable isOpen={isCollapsed}>
          <VBox gap="16px">
            {collapsedItems &&
              collapsedItems.map((tag) => <AIAnalysisKey name={tag.name} value={tag.value} />)}
            <AIActionsWrapper>
              <HBox height="100%">
                <AiActions
                  onThumbsDown={onThumbsDown}
                  onThumbsUp={onThumbsUp}
                  onCopy={onCopy}
                  isThumbsUpPressed={isThumbsUpPressed}
                  isThumbsDownPressed={isThumbsDownPressed}
                />
              </HBox>
              <RegenerateButtonWrapper
                variant={ButtonVariants.ACTION_DEFAULT}
                size={AllSizes.LARGE}
                ariaLabel={dic.ai_regenerate_label}
                onClick={onRegenerate}
                tooltipProps={{
                  isActive: !isGenerationEnabled && !isReadOnly,
                  text: dic.ai_capacity_reached_title,
                  placement: 'left',
                }}
                disabled={!isGenerationEnabled || isReadOnly}
                icon={<RefreshIcon />}
              >
                {dic.ai_regenerate_title}
              </RegenerateButtonWrapper>
            </AIActionsWrapper>
          </VBox>
        </Collapsable>
      </VBox>
      {collapsedItems && (
        <Button
          icon={isCollapsed ? <ArrowupIcon /> : <ArrowdownIcon />}
          variant={ButtonVariants.ACTION_DEFAULT}
          onClick={toggleCollapsed}
        />
      )}
    </HBox>
  );
};

AiAnalysis.propTypes = {
  onThumbsDown: PropTypes.func,
  onRegenerate: PropTypes.func,
  aiTags: PropTypes.arrayOf({
    tag_id: PropTypes.string,
    tag_value: PropTypes.string,
  }),
  onThumbsUp: PropTypes.func,
  isThumbsDownPressed: PropTypes.bool,
  isThumbsUpPressed: PropTypes.bool,
  isGenerationEnabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default AiAnalysis;
