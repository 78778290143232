import { AllSizes, Toggle, Tooltip } from '@bp/kung-fu';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../../../../../../app/react/modules/settings/alert_enrichment/actions';
import { selectors as FTSelectors } from '../../../../../../../app/react/user/feature_toggles';
import { Tag } from '../../../../types';
import texts from '../../../dictionary';
import { shouldUseTagOperations } from '../../../helpers/should-use-tag-operations';

const getActivationToggleTooltipText = (isOn: boolean): string => (isOn
  ? texts.active_tag_activation_toggle_tooltip_text
  : texts.inactive_tag_activation_toggle_tooltip_text
);

interface IProps {
  active: Tag['active'];
  tagName: Tag['name']
}

export function TagActivationToggle({ active, tagName }: IProps): JSX.Element {
  const [isToggleDisabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const useTagOperations = shouldUseTagOperations(
    useSelector(FTSelectors.getFeatureToggles),
  );

  useEffect(() => {
    setDisabled(false);
  }, [active]);

  if (!useTagOperations) {
    return null;
  }

  return (
    <Tooltip
      placement="bottom"
      text={getActivationToggleTooltipText(active)}
    >
      <Toggle
        name="tag.active"
        ariaLabel={texts.tag_activation_toggle_aria_label}
        role="checkbox"
        text="Active:"
        checked={active}
        disabled={isToggleDisabled}
        size={AllSizes.MEDIUM}
        onChange={(e): void => {
          setDisabled(true);
          dispatch(actions.updateAlertTag({
            tagName,
            metadata: { active: e.currentTarget.checked },
          }));
        }}
      />
    </Tooltip>
  );
}

export default TagActivationToggle;
