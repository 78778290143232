import React from 'react';
import PropTypes from 'prop-types';
import { BamFiltersPlaceHolder } from '@bp/bam';
import FiltersDropdown, {
  DropdownOptionType,
} from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION, ANY_OPTION_VALUE } from '../../constants';

const FiltersComponent = ({ filtersDropDownState, onChange, data }) => {
  const {
    permission: { value: permValue, text: permText },
    user: { value: userValue, text: userText },
  } = filtersDropDownState;

  const dropdownElements = [
    <FiltersDropdown
      data={data.permission}
      selectedValue={permValue}
      selectedText={permText}
      defaultText="Permission"
      onChange={(e, selection) => onChange({ permission: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.user}
      selectedValue={userValue}
      selectedText={userText}
      defaultText="User"
      onChange={(e, selection) => onChange({ user: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={[permValue, userValue].some((v) => v !== ANY_OPTION_VALUE)}
      onClearButtonClick={() => {
        onChange({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
      }}
    />
  );
};
FiltersComponent.propTypes = {
  data: PropTypes.shape({
    permission: PropTypes.arrayOf(DropdownOptionType),
    user: PropTypes.arrayOf(DropdownOptionType),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    permission: DropdownOptionType,
    user: DropdownOptionType,
  }).isRequired,
};
export default FiltersComponent;
