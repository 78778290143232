import React from 'react';
import PropTypes from 'prop-types';
import styles from './horizontal_layout.scss';

const HorizontalLayout = ({ children }) => (
  <div className={styles['horizontal-layout']}>{children}</div>
);

HorizontalLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HorizontalLayout;
