import merge from 'lodash/merge';
import findIndex from 'lodash/findIndex';
import extend from 'lodash/extend';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import delay from 'lodash/delay';
import forEach from 'lodash/forEach';
import clone from 'lodash/clone';
import map from 'lodash/map';
import remove from 'lodash/remove';
import debounce from 'lodash/debounce';
angular.module('bigpanda').directive('incidentsList', incidentsList);
angular.module('bigpanda').controller('incidentsListController', controller);

function incidentsList() {
  return {
    restrict: 'E',
    replace: true,
    require: {
      checkedIncidentsState: '^^checkedIncidentsState',
    },
    scope: {
      emptySearchList: '=',
      emptyList: '=',
      incidentsSearchNotification: '=',
      userId: '=',
      hasIntegrations: '=',
      folder: '=',
    },
    templateUrl: 'overview/incidents/list/incidents_list',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
  };
}

function controller(
  $scope,
  $element,
  $state,
  $stateParams,
  $filter,
  $log,
  $timeout,
  $window,
  stateService,
  pubSubService,
  execution,
  notificationService,
  IncidentsService,
  KeyboardService,
  EnvironmentsService,
  EnvironmentsUtils,
  BpqlUtils,
  SortingStore,
  FeedModeStore,
  FullscreenStore,
  LabelsStore,
  AssigneeFilterStore,
  deepCloneObject,
  UserFeatureTogglesService
) {
  const vm = this;
  const folderId = stateService.getSelectedFolderId();
  const envId = stateService.getSelectedEnvironmentId();
  const feedModeFeatureToggle = FeedModeStore.getToggle();
  // search-live-mode feature toggle is a dependency of live-mode feature toggle
  const liveUpdatesOnSearchFeatureToggle = feedModeFeatureToggle;
  const containerScrollElem = $element.find('.md-virtual-repeat-scroller');
  const searchPagingParams = {
    page: 0,
    pages: 0,
    bpqlQuery: {},
    perPage: 40,
  };
  let bufferChangeTimeout = null;
  let inSearchMode = false;
  let lastIncidentFromServerChange;
  let incidentsToRemove = [];
  let incidentsToMerge = [];
  let incidentBoxHeight;
  let liveUpdatesTimeout;
  vm.topIndex = 0;
  vm.selectedIncidentId = stateService.getSelectedIncidentId();
  vm.streamIncidents = [];
  vm.incidentsBlackList = [];
  vm.historyActionItem = { data: {} };
  vm.sortingChanged = false;
  vm.scrolling = false;
  vm.fullscreen = FullscreenStore.getFullscreen();
  vm.infiniteItems = {
    items: [],
    numLoaded: 0,
    busy: false,
    loadMore: false,
    isScrollbarDrag: false,
    loadMoreItems: () => {},
    getItemAtIndex: () => {},
    getLength: () => {},
  };

  vm.feedModeFeatureToggle = feedModeFeatureToggle;
  vm.updateIncidentsList = feedModeFeatureToggle ? updateIncidentsList : updateIncidentsListOld;
  vm.selectIncident = selectIncident;
  vm.showIndicator = showIndicator;
  vm.manualModeTrigger = manualModeTrigger;
  vm.$onInit = onInit;
  vm.$onDestroy = onDestroy;

  async function onInit() {
    initView();

    if (!vm.fullscreen) {
      incidentBoxHeight = 63;
    } else {
      incidentBoxHeight = 37;
    }
    pubSubService.on('incident.newData', resetLiveUpdatesPolling, $scope);
    pubSubService.on(
      'incident.newData',
      feedModeFeatureToggle ? handleIncomingData : handleIncomingDataOld,
      $scope
    );
    pubSubService.on('incident.newData', uncheckIncident, $scope);
    pubSubService.on('incident.search', search, $scope);
    pubSubService.on('Environment.Updated', onEnvUpdate, $scope);
    addPubsubListeners(['incident.clicked'], updateSelectedIncident);
    addPubsubListeners(['incident.snoozed', 'incident.resolved'], handleItemActionHistory);
    addPubsubListeners(['streamConnector.reconnected', 'Envy.refresh'], getIncidents);
    $scope.$on('$stateChangeSuccess', stateChanged);
    resetLiveUpdatesPolling();

    // Determine whether we're in empty list mode based on the list of incidents.
    // This seems to be the easiest and safest way to couple the two.
    $scope.$watchCollection('vm.infiniteItems.items', updateEmptyList);
    $scope.$watch('vm.dataLoaded', updateEmptyList);

    vm.checkedIncidentsState.subscribe($scope, {
      storeUpdated: incidentChecked,
    });

    if (feedModeFeatureToggle) {
      FeedModeStore.subscribe($scope, {
        storeUpdated: toggleFeedMode,
      });

      FeedModeStore.getFeedMode().then((feedMode) => {
        vm.liveMode = feedMode;
      });
    }

    getIncidentTagDefinitions();

    SortingStore.subscribe({ storeUpdated: changeSort }, $scope);
    return SortingStore.getSortBy().then((sortBy) => {
      vm.sortBy = sortBy;
      getIncidents(true);
    });
  }

  function onDestroy() {
    clearTimeout(liveUpdatesTimeout);
  }

  function initView() {
    const KeyCodes = {
      ESCAPE: 27,
      SPACEBAR: 32,
      UPARROW: 38,
      DOWNARROW: 40,
      ENTER: 13,
      k: 75,
      j: 74,
    };

    const scrollDebounce = debounce(
      () => {
        vm.scrolling = false;
      },
      400,
      { trailing: true, leading: false }
    );

    containerScrollElem.bind('wheel', scrollContainer);
    containerScrollElem.bind('scroll', scrollContainer);
    containerScrollElem.bind('keydown', keyDown);

    function scrollContainer(e) {
      vm.scrolling = true;
      if (e.type === 'scroll') {
        vm.infiniteItems.isScrollbarDrag = true;
      } else if (e.type === 'wheel') {
        vm.infiniteItems.isScrollbarDrag = false;
      }
      scrollDebounce();
    }

    function scrollToItem(pos, nextIncidentId) {
      vm.topIndex = pos;
      $element.find(`#incident-${nextIncidentId}`).focus();
    }

    function isElemInScrolledView(pos) {
      const containerScrollTop = 0;
      const containerBottom = containerScrollTop + containerScrollElem[0].offsetHeight;

      const elemTop = pos * incidentBoxHeight - containerScrollElem[0].scrollTop;
      const elemBottom = elemTop + incidentBoxHeight;
      return elemBottom < containerBottom && elemTop > containerScrollTop;
    }

    function isInRange(offset) {
      const incidentsListItemIndex = vm.itemIndex;
      const incidentListItemsLength = vm.infiniteItems.items.length;

      return (
        incidentsListItemIndex + offset < incidentListItemsLength &&
        incidentsListItemIndex + offset >= 0
      );
    }

    function keyDown(event) {
      if (!angular.isNumber(vm.itemIndex)) return;

      switch (event.keyCode) {
        case KeyCodes.ESCAPE:
          $state.go('app.overview.incidents.list', {
            environment: $stateParams.environment,
            folder: $stateParams.folder,
          });
          break;
        case KeyCodes.k:
        case KeyCodes.UPARROW:
          event.preventDefault();
          if (isInRange(-1)) {
            const prevIncidentIndex = vm.itemIndex - 1;
            const prevIncident = vm.infiniteItems.items[prevIncidentIndex];

            selectIncident(prevIncident.id, event, prevIncidentIndex, true);
            if (!isElemInScrolledView(prevIncidentIndex)) {
              scrollToItem(vm.itemIndex, prevIncident.id);
            }
            $scope.$digest();
          }
          break;
        case KeyCodes.SPACEBAR:
        case KeyCodes.ENTER:
        case KeyCodes.j:
        case KeyCodes.DOWNARROW:
          event.preventDefault();
          if (isInRange(1)) {
            const nextIncidentIndex = vm.itemIndex + 1;
            const nextIncident = vm.infiniteItems.items[nextIncidentIndex];

            selectIncident(nextIncident.id, event, nextIncidentIndex, true);
            if (!isElemInScrolledView(nextIncidentIndex)) {
              scrollToItem(vm.itemIndex, nextIncident.id);
            }
            $scope.$digest();
          }
          break;
      }
    }
  }

  function toggleFeedMode() {
    vm.liveMode = !vm.liveMode;
    updateIncidentsList();
  }

  function showIndicator() {
    return (
      vm.streamIncidents.length > 0 &&
      (!vm.feedModeFeatureToggle || (vm.feedModeFeatureToggle && !vm.liveMode))
    );
  }

  async function changeSort() {
    vm.sortBy = await SortingStore.getSortBy();

    if (vm.sortBy === SortingStore.options.label_priority) {
      inSearchMode = false;
      vm.emptySearchList = false;
      AssigneeFilterStore.resetAssignee();
    } else if (searchPagingParams.bpqlQuery && !!Object.keys(searchPagingParams.bpqlQuery).length) {
      inSearchMode = true;
    }

    vm.dataLoaded = false;
    vm.sortingChanged = true;
    vm.topIndex = 0;
    vm.infiniteItems.items = [];
    lastIncidentFromServerChange = null;

    if (inSearchMode) {
      search(
        null,
        searchPagingParams.bpqlQuery,
        searchPagingParams.searchVal,
        searchPagingParams.assignee
      );
    } else {
      getIncidents();
    }

    return EnvironmentsService.getEnvById(envId).then((environment) => {
      pubSubService.broadcast('incidents.sorting', {
        feature: 'Feed Settings',
        incidents_sort_type: vm.sortBy,
        environment: environment.name,
      });
    });
  }

  function addPubsubListeners(list, callback) {
    list.forEach((e) => pubSubService.on(e, callback, $scope));
  }

  function updateSelectedIncident(e, incidentId) {
    vm.selectedIncidentId = incidentId;
  }

  function onEnvUpdate(event, env, userId) {
    if (!inSearchMode && vm.userId === userId && env._id === envId) {
      vm.dataLoaded = false;
      getIncidents();
    }
  }

  function manualModeTrigger() {
    vm.topIndex = 0;
    if (feedModeFeatureToggle) {
      updateIncidentsList();
    } else {
      updateIncidentsListOld();
    }
  }

  const incomingDataBuffer = debounce(
    () => {
      if (vm.liveMode) {
        if (vm.scrolling) {
          incomingDataBuffer();
        } else {
          updateIncidentsList();
          pubSubService.broadcast('IncidentsController.bufferChange', vm.streamIncidents);
        }
      } else {
        if (incidentsToMerge.length > 0) {
          vm.infiniteItems = mergeToInfiniteItems(incidentsToMerge, vm.infiniteItems);
          vm.streamIncidents = removeFromIncidentsList(incidentsToMerge, vm.streamIncidents);
          incidentsToMerge = [];
        }
        if (incidentsToRemove.length > 0) {
          vm.infiniteItems = removeIncidentsFromInfiniteItems(incidentsToRemove, vm.infiniteItems);
          vm.streamIncidents = removeFromIncidentsList(incidentsToRemove, vm.streamIncidents);
          incidentsToRemove = [];
        }
        pubSubService.broadcast('IncidentsController.bufferChange', vm.streamIncidents);
      }
    },
    400,
    { maxWait: 1000, trailing: true, leading: false }
  );

  function resetLiveUpdatesPolling() {
    clearTimeout(liveUpdatesTimeout);
    liveUpdatesTimeout = setTimeout(() => {
      getIncidents();
      resetLiveUpdatesPolling();
    }, 15 * 1000);
  }

  function handleIncomingData(event, incident) {
    if (inSearchMode && !liveUpdatesOnSearchFeatureToggle) return;

    const incidentListIndex = getIncidentPosIndex(incident.id, vm.infiniteItems.items);
    const incidentPosInStream = getIncidentPosIndex(incident.id, vm.streamIncidents);
    if (inCurrentEnv(incident) && inCurrentFolder(incident) && isNotAbandoned(incident)) {
      if (incidentListIndex > -1) {
        incidentsToMerge.push(incident);
      } else if (incidentPosInStream > -1) {
        mergeIncident(vm.streamIncidents[incidentPosInStream], incident);
      } else if (!inSearchMode) {
        vm.streamIncidents.push(incident);
      }
    } else if (isToRemove(incident)) {
      if (incidentPosInStream > -1) {
        vm.streamIncidents.splice(incidentPosInStream, 1);
      }
      if (incidentListIndex > -1) {
        incidentsToRemove.push(incident);
      }
    }
    incomingDataBuffer();
  }

  function resetIncidentsBuffers() {
    incidentsToRemove = [];
    incidentsToMerge = [];
  }

  function handleIncomingDataOld(event, incident) {
    if (inSearchMode && !liveUpdatesOnSearchFeatureToggle) return;

    const incidentListIndex = getIncidentPosIndex(incident.id, vm.infiniteItems.items);
    const incidentPosInStream = getIncidentPosIndex(incident.id, vm.streamIncidents);

    if (inCurrentEnv(incident) && inCurrentFolder(incident) && isNotAbandoned(incident)) {
      if (incidentListIndex > -1) {
        mergeIncident(vm.infiniteItems.items[incidentListIndex], incident);
        vm.infiniteItems.items = IncidentsService.sort(vm.infiniteItems.items, vm.sortBy, folderId);
      } else if (incidentPosInStream > -1) {
        mergeIncident(vm.streamIncidents[incidentPosInStream], incident);
      } else {
        vm.streamIncidents.push(incident);
      }
    } else if (isToRemove(incident)) {
      if (incidentPosInStream > -1) {
        vm.streamIncidents.splice(incidentPosInStream, 1);
      }
      if (incidentListIndex > -1) {
        remove(vm.infiniteItems.items, (item) => item.id === incident.id);
        vm.infiniteItems.numLoaded--;
      }
    }

    $timeout.cancel(bufferChangeTimeout);
    bufferChangeTimeout = $timeout(() => {
      pubSubService.broadcast('IncidentsController.bufferChange', vm.streamIncidents);
    }, 400);
  }

  function resetStreamIncidents() {
    resetIncidentsBuffers();
    vm.streamIncidents = [];
    pubSubService.broadcast('IncidentsController.bufferChange', []);
  }

  function mergeToInfiniteItems(incidentsToMerge, infiniteItems) {
    const incidentsToMergeKeyVal = map(incidentsToMerge, (incident) => {
      const index = getIncidentPosIndex(incident.id, infiniteItems.items);
      return { index: index, incident: incident };
    });

    infiniteItems.items = clone(infiniteItems.items);
    forEach(incidentsToMergeKeyVal, (incidentToMerge) => {
      if (incidentToMerge.index !== -1) {
        infiniteItems.items[incidentToMerge.index] = mergeIncident(
          deepCloneObject.cloneDeep(infiniteItems.items[incidentToMerge.index]),
          incidentToMerge.incident
        );
      } else {
        infiniteItems.items.push(incidentToMerge.incident);
      }
    });
    infiniteItems.items = IncidentsService.sort(infiniteItems.items, vm.sortBy, folderId);
    infiniteItems.numLoaded = infiniteItems.items.length;
    return infiniteItems;
  }

  function removeFromIncidentsList(incidentsToRemove, incidentsList) {
    const idsToRemove = map(incidentsToRemove, (incident) => incident.id);
    return incidentsList.filter((item) => !idsToRemove.includes(item.id));
  }

  function removeIncidentsFromInfiniteItems(incidentsToRemove, infiniteItems) {
    infiniteItems.items = removeFromIncidentsList(incidentsToRemove, clone(infiniteItems.items));
    infiniteItems.numLoaded = infiniteItems.items.length;
    return infiniteItems;
  }

  function getNewAnchorIndex(anchorIncident) {
    return getIncidentPosIndex(anchorIncident.id, vm.infiniteItems.items);
  }

  function getIncidentOffset() {
    const elemTop = parseInt(vm.topIndex) * incidentBoxHeight - containerScrollElem[0].scrollTop;
    const elemBottom = elemTop + incidentBoxHeight;

    return (parseFloat(incidentBoxHeight) - elemBottom) / parseFloat(incidentBoxHeight);
  }

  function updateIncidentsList() {
    const streamIncidents = clone(vm.streamIncidents);

    const infiniteItemsNotEmpty = vm.infiniteItems.items.length > 0;
    let incidentIndex = vm.topIndex > 0 ? Math.floor(vm.topIndex) : 0;
    const wasOnTop = incidentIndex === 0;
    const relativeOffset = getIncidentOffset();
    const anchorIncident = getItemAtIndex(incidentIndex);

    if (incidentsToMerge.length > 0 || streamIncidents.length > 0) {
      const streamAndMergeItems = [...streamIncidents, ...incidentsToMerge];
      vm.infiniteItems = mergeToInfiniteItems(streamAndMergeItems, vm.infiniteItems);
    }

    if (incidentsToRemove.length > 0) {
      vm.infiniteItems = removeIncidentsFromInfiniteItems(incidentsToRemove, vm.infiniteItems);
    }

    if (infiniteItemsNotEmpty) {
      incidentIndex = getNewAnchorIndex(anchorIncident);
      if (!wasOnTop) {
        if (endOfList(streamIncidents) && streamIncidents.length > 0) {
          delay(() => (vm.topIndex = parseFloat(incidentIndex) + relativeOffset));
        } else {
          vm.topIndex = parseFloat(incidentIndex) + relativeOffset;
        }
      } else {
        vm.topIndex -= relativeOffset;
      }
    }

    resetStreamIncidents();
  }

  function endOfList(streamIncidents) {
    const containerScrollTop = 0;
    const containerBottom = containerScrollTop + containerScrollElem[0].offsetHeight;
    const virtualSize = vm.infiniteItems.items.length * incidentBoxHeight;
    const viewableIncidents = Math.ceil(containerBottom / incidentBoxHeight);
    const streamLength = streamIncidents.length;
    const currentScrollTop = containerScrollElem[0].scrollTop;
    const offset = currentScrollTop + incidentBoxHeight * (viewableIncidents + streamLength);

    return offset > virtualSize;
  }

  function updateIncidentsListOld() {
    const streamIncidents = clone(vm.streamIncidents);

    vm.infiniteItems.items = IncidentsService.sort(
      uniqBy(vm.infiniteItems.items.concat(streamIncidents), '_id'),
      vm.sortBy,
      folderId
    );
    vm.infiniteItems.numLoaded += vm.streamIncidents.length;
    resetStreamIncidents();
  }

  function getIncidents(notifyError) {
    if (inSearchMode || folderId === 'search') return;

    IncidentsService.getIncidents(envId, folderId, null, vm.sortBy)
      .then(
        (res) => {
          const incidents = normalizeIncidents(res.incidents);

          if (incidents.length > 0) {
            markAsCheckedIncidents(incidents);
            lastIncidentFromServerChange = incidents[incidents.length - 1].last_change;
          }

          vm.dataLoaded = true;
          vm.sortingChanged = false;

          initIncidentsListPaging({
            incidents: incidents,
            last_page: res.last_page,
            fetchMoreItemsCb: incidentsFetchMoreItems,
          });
        },
        (error) => {
          vm.dataLoaded = true;
          vm.emptyList = false;

          if (notifyError && error.status !== 401) {
            notificationService.error(
              `There was an error while loading environment with id ${envId} and folder with id ${folderId}`,
              null
            );
            return;
          }

          // 401 check is a temporary solution for bug [BUG-252]
          if (error.status !== 504 && error.status !== 401 && error.status !== -1) {
            notificationService.error(
              `Couldn't find environment with id ${envId} and folder with id ${folderId}`,
              null
            );

            return EnvironmentsService.get()
              .then(EnvironmentsUtils.getDefaultEnv)
              .then((env) =>
                $state.go('app.overview.incidents.list', { environment: env._id, folder: 'active' })
              );
          }
        }
      )
      .finally(() => {
        resetStreamIncidents();
      });
  }

  function uncheckIncident(event, { id }) {
    vm.checkedIncidentsState.uncheck(id);
  }

  function incidentChecked() {
    const checkedIncidentId = vm.checkedIncidentsState.getLastCheckedId();
    const lastIncidentCheckedId = vm.checkedIncidentsState.getSecondLastCheckedId();
    const checked = vm.checkedIncidentsState.isChecked(checkedIncidentId);

    if (
      lastIncidentCheckedId &&
      vm.checkedIncidentsState.isAnythingChecked() &&
      KeyboardService.isShiftDown()
    ) {
      const indexOfLast = getIncidentPosIndex(lastIncidentCheckedId, vm.infiniteItems.items);
      const indexOfCurr = getIncidentPosIndex(checkedIncidentId, vm.infiniteItems.items);
      if (indexOfLast >= 0 && indexOfCurr >= 0) {
        const newIncidentsToCheck = vm.infiniteItems.items.slice(
          Math.min(indexOfLast, indexOfCurr),
          Math.max(indexOfLast, indexOfCurr)
        );

        const incidentsToCheck = map(newIncidentsToCheck, 'id');
        incidentsToCheck.push(vm.infiniteItems.items[indexOfLast].id);

        if (checked) {
          vm.checkedIncidentsState.checkMulti(incidentsToCheck);
        } else {
          vm.checkedIncidentsState.uncheckMulti(incidentsToCheck);
        }
      }
    }
  }

  function hasClass(element, clazz) {
    if (element.classList.contains(clazz)) {
      return true;
    } else if (element.parentElement) {
      return hasClass(element.parentElement, clazz);
    }
    return false;
  }

  function shouldSkipSelect(incidentId, element) {
    if (incidentId && vm.selectedIncidentId === incidentId) return true;
    if (element) return hasClass(element, 'no-selection');

    return false;
  }

  function stateChanged() {
    selectIncident($stateParams.incidentId);
    if (envId !== stateService.getSelectedEnvironmentId()) {
      pubSubService.broadcast('Environment.Selected');
    }
  }

  function selectIncident(incidentId, event, itemIndex, isKeyboard) {
    if (shouldSkipSelect(incidentId, event && event.target)) return;
    vm.selectedIncidentId = incidentId;
    vm.itemIndex = itemIndex;
    if (!incidentId) {
      return;
    }

    if (isKeyboard) {
      beforeSelectionChanged(incidentId);
    } else {
      selectionChanged(incidentId);
    }
  }

  function addIncidentHistoryBackToList(incidentId) {
    const incidentHistory = vm.historyActionItem.data[incidentId];
    vm.infiniteItems.items = IncidentsService.sort(
      vm.infiniteItems.items.concat(incidentHistory),
      vm.sortBy,
      folderId
    );
    delete vm.historyActionItem.data[incidentId];
  }

  function handleItemActionHistory(event, incidentOptions) {
    if (!incidentOptions) return;

    if (!incidentOptions.undo) {
      vm.historyActionItem.data[incidentOptions.id] = find(vm.infiniteItems.items, {
        id: incidentOptions.id,
      });

      if (event.name === 'incident.snoozed') {
        if (
          (folderId === 'snoozed' && !incidentOptions.snoozed) ||
          ['snoozed', 'resolved'].indexOf(folderId) === -1
        ) {
          remove(vm.infiniteItems.items, (item) => item.id === incidentOptions.id);
        }
      } else {
        remove(vm.infiniteItems.items, (item) => item.id === incidentOptions.id);
      }
    } else if (event.name === 'incident.snoozed') {
      if (
        (folderId === 'snoozed' && incidentOptions.snoozed) ||
        ['snoozed', 'resolved'].indexOf(folderId) === -1
      ) {
        addIncidentHistoryBackToList(incidentOptions.id);
      }
    } else {
      addIncidentHistoryBackToList(incidentOptions.id);
    }
  }

  function markAsCheckedIncidents(incidents) {
    const incidentsMap = keyBy(incidents, 'id');

    vm.checkedIncidentsState.getCheckedIncidentIds().forEach((id) => {
      if (incidentsMap[id]) {
        incidentsMap[id].checked = true;
      } else {
        uncheckIncident(null, { id: id });
      }
    });
  }

  function normalizeIncidents(rawIncidents) {
    const tmpIncidents = [];
    forEach(rawIncidents, (incident) => {
      const rawIncident = extend({}, incident, { id: incident._id });
      if (isNotAbandoned(rawIncident)) {
        tmpIncidents.push($filter('incidentNormalizer')(rawIncident));
      }
    });

    return tmpIncidents;
  }

  function inCurrentEnv(rawIncident) {
    return (
      rawIncident.env_changes.in.indexOf(envId) > -1 ||
      rawIncident.env_changes.added.indexOf(envId) > -1
    );
  }

  function inCurrentFolder(rawIncident) {
    return (
      rawIncident.folder_changes.in.indexOf(folderId) > -1 ||
      rawIncident.folder_changes.added.indexOf(folderId) > -1
    );
  }

  function isNotAbandoned(rawIncident) {
    return !(rawIncident.status.toLowerCase() === 'ok' && rawIncident.entities.length == 0);
  }

  function isToRemove(rawIncident) {
    return (
      rawIncident.env_changes.removed.indexOf(envId) > -1 ||
      rawIncident.folder_changes.removed.indexOf(folderId) > -1 ||
      rawIncident.folder_changes.in.indexOf(folderId) === -1
    );
  }

  function getIncidentPosIndex(incidentId, list) {
    if (!angular.isDefined(list)) {
      list = vm.infiniteItems.items;
    }

    return findIndex(list, (incident) => incident.id === incidentId);
  }

  function selectionChanged(incidentId) {
    let stateParams = {};
    let stateName = 'app.overview.incidents';

    if (incidentId) {
      stateName = 'app.overview.incidents.incident-info';
      stateParams = { incidentId: incidentId, notify: false };
      broadcastAnalytics('incidentsList.incidentSelected', { incidentId: incidentId });
    }

    if (FullscreenStore.getFullscreen()) {
      $window.open($state.href(stateName, stateParams), '_blank');
    } else {
      $state.go(stateName, stateParams);
    }
  }

  // This function is just used for its name - to mark that this event should only be used for analytics and not by
  // any other controller/service
  function broadcastAnalytics(eventName, data) {
    pubSubService.broadcast(eventName, data);
  }

  function beforeSelectionChanged(incidentId) {
    if (incidentId) {
      execution.doBeforeWait(
        'incidents.list',
        () => {
          selectionChanged(incidentId);
        },
        400
      )();
    }
  }

  function incidentsFetchMoreItems() {
    if (vm.infiniteItems.busy) return;

    vm.infiniteItems.busy = true;
    vm.infiniteItems.loadMore = false;

    IncidentsService.getIncidents(envId, folderId, lastIncidentFromServerChange, vm.sortBy).then(
      (res) => {
        const incidents = normalizeIncidents(res.incidents);
        if (incidents.length > 0) {
          lastIncidentFromServerChange = incidents[incidents.length - 1].last_change;
        }

        vm.infiniteItems.items = IncidentsService.sort(
          uniqBy(vm.infiniteItems.items.concat(incidents), '_id'),
          vm.sortBy,
          folderId
        );
        vm.infiniteItems.last_page = res.last_page;
        vm.infiniteItems.numLoaded = vm.infiniteItems.items.length;
        vm.infiniteItems.busy = false;
        vm.infiniteItems.loadMore = false;
        vm.dataLoaded = true;
      }
    );
  }

  function incidentsSearchFetchMoreItems() {
    if (vm.infiniteItems.busy) return;

    vm.infiniteItems.busy = true;
    vm.infiniteItems.loadMore = false;
    searchPagingParams.page++;

    const paging = {
      from: searchPagingParams.page * searchPagingParams.perPage,
      size: searchPagingParams.perPage,
    };

    IncidentsService.searchIncidents(
      envId,
      folderId,
      searchPagingParams.bpqlQuery,
      paging,
      vm.sortBy,
      searchPagingParams.searchVal
    ).then((res) => {
      const incidents = normalizeIncidents(res.item.incidents);

      vm.infiniteItems.items = IncidentsService.sort(
        uniqBy(vm.infiniteItems.items.concat(incidents), '_id'),
        vm.sortBy,
        folderId
      );
      vm.infiniteItems.numLoaded = vm.infiniteItems.items.length;
      vm.infiniteItems.busy = false;
      vm.infiniteItems.loadMore = false;
      vm.infiniteItems.last_page = searchPagingParams.pages <= searchPagingParams.page;
      vm.dataLoaded = true;
    });
  }

  function getItemAtIndex(index) {
    if (!vm.infiniteItems.last_page && index + 25 >= vm.infiniteItems.getLength()) {
      if (vm.infiniteItems.isScrollbarDrag) {
        vm.infiniteItems.loadMore = true;
      } else {
        vm.infiniteItems.fetchMoreItems();
      }
    }
    return vm.infiniteItems.items[index];
  }

  function initIncidentsListPaging(options) {
    if (options.type === 'search') {
      vm.infiniteItems.items = IncidentsService.sort(options.incidents, vm.sortBy, folderId);
    } else {
      vm.infiniteItems.items = options.incidents;
    }

    merge(vm.infiniteItems, {
      busy: false,
      loadMore: false,
      isScrollbarDrag: false,
      numLoaded: options.incidents.length,
      last_page: options.last_page,
      getItemAtIndex: getItemAtIndex,
      fetchMoreItems: options.fetchMoreItemsCb,
      getLength: () => vm.infiniteItems.items.length,
    });
  }

  function search(event, bpqlQuery, searchVal, assignee) {
    if (bpqlQuery !== '') {
      const perPage = 100;
      const paging = { from: 0, size: perPage };
      const query = BpqlUtils.formatBpqlQuery(assignee, searchVal);
      inSearchMode = true;
      vm.dataLoaded = false;
      vm.emptyList = false;
      vm.emptySearchList = false;
      vm.bpqlQuery = query;
      extend(searchPagingParams, {
        bpqlQuery: bpqlQuery,
        searchVal: searchVal,
        assignee: assignee,
      });
      resetStreamIncidents();

      IncidentsService.searchIncidents(envId, folderId, bpqlQuery, paging, vm.sortBy, searchVal)
        .then(
          (results) => {
            const incidents = normalizeIncidents(results.item.incidents);
            const numberOfPages = Math.round(results.item.total / perPage);
            vm.incidentsSearchNotification = {
              error: false,
              msg: `<b>${results.item.total} incidents matched:</b> ${query}`,
              cls: 'info',
            };

            vm.emptySearchList = results.item.total === 0;
            vm.emptyList = angular.copy(vm.emptySearchList);

            extend(searchPagingParams, {
              page: 0,
              pages: numberOfPages,
              perPage: perPage,
            });

            initIncidentsListPaging({
              type: 'search',
              incidents: incidents,
              last_page: searchPagingParams.pages <= searchPagingParams.page,
              fetchMoreItemsCb: incidentsSearchFetchMoreItems,
            });
          },
          (error) => {
            if (!inSearchMode) return;

            vm.emptySearchList = true;
            vm.emptyList = true;
            vm.incidentsSearchNotification = {
              error: true,
              msg: error.statusText,
              cls: 'error',
            };

            $log.error(`incidents.feed: ${error.statusText}`);
          }
        )
        .finally(() => {
          vm.dataLoaded = true;
          pubSubService.broadcast('incidentsList.isEmptySearch', vm.emptySearchList);
        });
    } else if (inSearchMode) {
      inSearchMode = false;
      vm.dataLoaded = false;
      vm.emptySearchList = false;
      vm.emptyList = false;
      vm.incidentsSearchNotification = null;
      vm.bpqlQuery = undefined;
      searchPagingParams.bpqlQuery = '';
      getIncidents();
      pubSubService.broadcast('incidentsList.isEmptySearch', vm.emptySearchList);
    }
  }

  function updateEmptyList() {
    vm.emptyList =
      (!vm.infiniteItems || !vm.infiniteItems.items || !vm.infiniteItems.items.length) &&
      vm.dataLoaded;
  }

  function mergeIncident(existingIncident, incidentFromUpdate) {
    const updatedIncident = merge(existingIncident, incidentFromUpdate);
    if (incidentFromUpdate.entities && incidentFromUpdate.entities.length) {
      updatedIncident.entities = incidentFromUpdate.entities;
    }
    return updatedIncident;
  }

  function getIncidentTagDefinitions() {
    return LabelsStore.getMultipleIncidentTagDefinitions(envId);
  }
}
