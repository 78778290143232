import styled from 'styled-components';
import { Div } from '@bp/kung-fu';

export const Wrapper = styled(Div)`
max-width: 100%;
display: inline-block;
`;

export const Overlay = styled.div`
white-space: normal;
`;
export const Trigger = styled.div`
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;
