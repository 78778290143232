import styled from 'styled-components';
import * as colors from '@bp/pastel/colors';

export const Wrapper = styled.div`
  width: inherit;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  ${({ disabled }) => disabled && `opacity: 0.5`};
`;

export const Title = styled.div`
  line-height: 1;
  margin-bottom: 7px;
  margin-left: 1px;
  font-size: 12px;
  font-weight: bold;
  color: ${colors.bp_gray_06};
`;

export const ErrorMessage = styled.div`
  padding-top: 6px;
  line-height: 1;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.bp_red};
`;
