import bpFetch from 'common/utils/http/fetcher';

export function getTags() {
  return bpFetch({ url: '/web/tags' }).then((res) => res.data);
}

export function validateTagRegex(regex) {
  return bpFetch({ url: '/web/correlation/validate/regex', method: 'PUT', data: { regex } }).then(
    (res) => res.data
  );
}

export function getUniqueTagSamples(query) {
  return bpFetch({ url: '/web/search/tags', method: 'POST', data: query }).then(
    (res) => res.data.item
  );
}
