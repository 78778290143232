import toLower from 'lodash/toLower';
import sortBy from 'lodash/sortBy';
import extend from 'lodash/extend';
import { datadogRum } from '@datadog/browser-rum';
const helperRecentSearchesMaxResults = 8;

angular
  .module('bigpanda.backend.services')
  .factory('IncidentsBackendService', IncidentsBackendService);

function IncidentsBackendService($http, SortingStore, EnvironmentsService) {
  return {
    getPreviewIncidents: getPreviewIncidents,
    getIncidents: getIncidents,
    getIncidentById: getIncidentById,
    getIncidentsByIds: getIncidentsByIds,
    entities: entities,
    searchIncidents: searchIncidents,
    getRecentSearches: getRecentSearches,
    getAlertViewRules: getAlertViewRules,
  };

  function getRecentSearches() {
    return $http
      .get(`/resources/v2.1/search-queries?per_page=${helperRecentSearchesMaxResults}`)
      .then((res) => res.data);
  }

  function getPreviewIncidents(payload) {
    return $http.post('/web/incidents/preview', payload).then((res) => res.data);
  }

  async function getIncidents(envId, folderId, lastChange, sort) {
    const environmentId =
      toLower(envId) === 'all' ? await EnvironmentsService.getAllEnvId() : envId;

    // Sometimes envId is an old_id instead of the id, we replace it with the id
    const envIdForRequest = await EnvironmentsService.getEnvId(environmentId);

    return $http
      .get(`/resources/v2.0/environments/${envIdForRequest}/incidents/folders/${folderId}`, {
        params: {
          envId: envIdForRequest,
          folderId: folderId,
          lastChange: lastChange,
          sortBy: sort || true, // (true) supports backward compatibility
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        datadogRum.addError(error);
        return Promise.reject(error);
      });
  }

  function getIncidentById(incidentId) {
    return $http.get(`/web/incidents/${incidentId}`).then((res) => res.data);
  }

  function getIncidentsByIds(incidentIds) {
    return $http
      .post('/web/incidents', { ids: incidentIds })
      .then((res) => res.data)
      .catch((error) => {
        datadogRum.addError(error);
        return Promise.reject(error);
      });
  }

  function entities(incidentId, includeEvents) {
    return $http
      .get(`/web/incidents/${incidentId}/entities`, {
        params: { include_events: includeEvents },
      })
      .then((res) => res.data);
  }

  function searchIncidents(envId, folderId, bpqlQuery, paging, sortBy, searchVal) {
    const searchParams = {
      environment_id: envId,
      folder_id: folderId,
      bpql: bpqlQuery,
      search_query_str: searchVal,
      sort: sortBy,
    };

    if (paging) {
      extend(searchParams, { paging: paging });
    }

    if (sortBy === SortingStore.options.label_priority) {
      return Promise.resolve([]);
    }

    return $http.post('/web/search/incidents', searchParams).then((res) => res.data);
  }

  function getAlertViewRules() {
    return $http
      .get('/resources/v2.0/alert-views')
      .then((res) => sortBy(res.data, 'ordering'))
      .catch((error) => {
        datadogRum.addError(error);
        return Promise.resolve([]);
      });
  }
}
