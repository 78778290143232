import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import { resolutionTypes } from './constants';

angular.module('bigpanda').directive('entitiesList', entitiesList);

const MIN_COLUMN_WIDTH = 50;
const MAX_COLUMN_WIDTH = 250;
const LETTER_SIZE_IN_PX = 9;

function entitiesList() {
  return {
    restrict: 'E',
    scope: {
      entities: '<',
      incident: '<',
      gridHeight: '@',
      limitedView: '=', // on a maintenance/resolved folder show only maintenance/resolved entities in accordance
      updateSelectEntitiesCount: '=',
      hideSelectedMsg: '=',
      showAllEntities: '=',
      showIsolated: '=',
      search: '&',
      searchValue: '=',
    },
    bindToController: true,
    templateUrl: 'overview/entities/list/entities_list',
    controller: controller,
    controllerAs: 'vm',
  };

  function controller(
    $scope,
    $element,
    $filter,
    $timeout,
    $window,
    $mdPanel,
    $mdMedia,
    $animate,
    $q,
    $stateParams,
    EntitiesListColumnsPicker,
    PersonalSettingsStore,
    pubSubService,
    MaintenancePlansService,
    UserFeatureTogglesService,
    EntitiesService,
    EntityPersistencyStore,
    stateService,
    Permissions,
    IncidentsService
  ) {
    const vm = this;

    $animate.enabled($element, false);
    const appWindow = angular.element($window);
    const statusPriority = {
      critical: 5,
      warning: 4,
      unknown: 3,
      acknowledged: 2,
      ok: 1,
    };

    vm.newAlertsAutoFit = UserFeatureTogglesService.getToggle('new_alerts_autofit');

    const currentEnvId = stateService.getSelectedEnvironmentId();
    let widthColumnsPersistencyOn;
    let orderColumnsPersistencyOn;

    if (!vm.newAlertsAutoFit) {
      widthColumnsPersistencyOn = EntityPersistencyStore.persistencyOn('bp_columns_width');
      orderColumnsPersistencyOn = EntityPersistencyStore.persistencyOn('bp_columns_ordered');
    }

    let organizationEntitySorter = null;
    let entitiesListColumnsPicker = null;

    let columnDefs;
    let rowData = [];
    let gridApi;
    let gridColumnApi;
    let entity;
    let panel;
    let rowElement = null;
    let prevSortedColId = 'lastUpdate';
    let closerTimeout = null;
    let pickedColumnsState;
    let numberOfInitialColumns = 0;
    let initialSort = true;
    let sourceSystem;
    let alertViewRules;

    vm.openPanel = selectionChanged;
    vm.selectionChanged = selectionChanged;
    vm.updateEntitiesToShow = updateEntitiesToShow;

    vm.orderedTagList = [];
    vm.pickedColumns = [];
    vm.entitiesToShow = [];
    vm.autoSizeColumn = false;
    vm.activeEntities = [];
    vm.entitiesUnderMaintenance = [];
    vm.resolvedEntities = [];
    vm.plans = [];
    vm.selectedEntities = [];
    vm.narrowTableTopBarArea = false;
    vm.showSplitAndResolveButtons = Permissions.checkGranularPermission(
      'action@update',
      'environments',
      currentEnvId
    );
    vm.gridOptions = initializeGridOptions();
    vm.setCheckboxLogic = setCheckboxLogic;
    vm.isMobile = $mdMedia('max-width: 600px');

    vm.maintenanceTooltip = {
      template: 'overview/entities/maintenance_tooltip/maintenance_tooltip',
      getMaintenancePlans: getMaintenancePlans,
    };

    vm.copyClick = copyClick;
    vm.calcSelectedIds = calcSelectedIds;
    vm.selectAllRows = selectAllRows;
    vm.disableEntitiesBatchResolve = UserFeatureTogglesService.getToggle(
      'frontier_disable_entities_batch_resolve'
    );

    this.$onInit = init;

    async function init() {
      alertViewRules = await IncidentsService.getAlertViewRules();

      appWindow.on('resize', closePanel);
      $scope.$on('$destroy', () => appWindow.off('resize', closePanel));
      PersonalSettingsStore.getUser().then((user) => {
        organizationEntitySorter = user.organization.config.entity_tags_sorter;
        const initWithEntitiesChanged = (entities) => onEntitiesChange(entities, vm.incident);
        $scope.$watch(() => vm.entities, initWithEntitiesChanged);
        pubSubService.on('tabs.resizing', updateColumnsByWidth, $scope);

        updateColumnsByWidth();

        pubSubService.on(
          'tabs.resizing',
          (event, data) => shouldShowNarrowTableTopBarArea(data),
          $scope
        );
      });

      const { currentFolder, incidentType } = EntitiesService.calcIncidentFolderAndType(
        vm.incident,
        $stateParams.folder
      );
      vm.currentFolder = currentFolder;
    }

    $scope.$watch('vm.selectedEntities', (newValue, oldValue) => {
      if (vm.updateSelectEntitiesCount) {
        vm.updateSelectEntitiesCount(newValue.length);
      }
    });

    $scope.$watch('vm.showAllEntities', (newValue, oldValue) => {
      if (newValue) {
        onEntitiesChange(vm.entities, vm.incident);
      }
    });

    function shouldShowNarrowTableTopBarArea(data) {
      vm.narrowTableTopBarArea = data && data.right < 440;
    }

    // Finds the longest string value of property of Entity
    function getEntityLongestValueByField(entities, field) {
      const maxLength = field.length;

      return entities.reduce((maxLengthSoFar, entity) => {
        let currentEntityFieldValue;

        if (entity && entity._tags) {
          currentEntityFieldValue = entity._tags.find(
            (o) => o.type === field || o.type === `_${field}`
          );
        } else {
          currentEntityFieldValue = entity[field];
        }
        return currentEntityFieldValue && currentEntityFieldValue.value
          ? Math.max(currentEntityFieldValue.value.length, maxLengthSoFar)
          : maxLengthSoFar;
      }, maxLength);
    }

    function normalizeEntityColumnWidth(entities, column, field, minColumnWidth, maxColumnWidth) {
      let nonTextColumnWidth;

      let fieldWidth = field.length;
      if (column && column.type === 'text') {
        fieldWidth = getEntityLongestValueByField(entities, field);
      } else if (column && column.minWidth) {
        nonTextColumnWidth = column.minWidth;
      }

      // Normalize too short and too long columns
      if (fieldWidth > 10) {
        fieldWidth *= 0.9;
      } else {
        fieldWidth *= 1.2;
      }

      // Calculate column width in pixels
      // In case the font-size in entitites table is changed, we should change this calculation, in scecific the LETTER_SIZE_IN_PX.
      let resFieldWidth = fieldWidth * LETTER_SIZE_IN_PX;

      // Reduce or expand size to min/max length
      resFieldWidth = Math.min(Math.max(resFieldWidth, minColumnWidth), maxColumnWidth);

      return nonTextColumnWidth || resFieldWidth;
    }

    function setCheckboxLogic(currentFolder) {
      vm.resolvedEntities = EntitiesService.filterResolvedEntities(vm.entities);
      vm.entitiesUnderMaintenance = EntitiesService.filterMaintenanceEntities(vm.entities);
      vm.activeEntities = EntitiesService.filterActiveEntities(vm.entities);

      if (vm.showAllEntities) {
        vm.showActive = !!vm.activeEntities.length;
        vm.showUnderMaintenance = !!vm.entitiesUnderMaintenance.length;
        vm.showResolved = !!vm.resolvedEntities.length;
        return;
      }

      switch (currentFolder) {
        case 'maintenance':
          vm.showActive = false;
          vm.showUnderMaintenance = true;
          vm.showResolved = true;
          vm.activeCheckDisable = true;
          vm.maintenanceCheckDisable = false;
          break;
        case 'resolved':
          vm.showActive = false;
          vm.showUnderMaintenance = false;
          vm.showResolved = true;
          vm.activeCheckDisable = true;
          vm.maintenanceCheckDisable = true;
          break;
        default:
          vm.showActive = true;
          vm.showUnderMaintenance = vm.entitiesUnderMaintenance.length > 0 && !vm.limitedView;
          vm.showResolved = !vm.showIsolated;
          vm.activeCheckDisable = false;
          vm.maintenanceCheckDisable = !vm.showUnderMaintenance;
          break;
      }
    }

    function onEntitiesChange(entities, incident) {
      if (entities.length) {
        const { currentFolder, incidentType } = EntitiesService.calcIncidentFolderAndType(
          incident,
          $stateParams.folder
        );
        vm.currentFolder = currentFolder;
        setCheckboxLogic(currentFolder);
        const entitiesPlanIds = Array.from(
          new Set([].concat(...entities.map((e) => e.maintenance_plan_ids).filter((e) => !!e)))
        );
        if (entitiesPlanIds && entitiesPlanIds.length) {
          MaintenancePlansService.getMaintenancePlansByIds(entitiesPlanIds).then(
            (maintenancePlans) => {
              vm.plans = maintenancePlans;
            }
          );
        }
        updateEntitiesToShow();
      }
    }

    function doesRowMatchFolder(row, folder) {
      const isMaintenanceIncident = row.data.underMaintenance;
      if (folder === 'maintenance') {
        return isMaintenanceIncident;
      }
      const isResolvedIncident = row.data.status === 'ok';
      if (folder === 'resolved') {
        return isResolvedIncident;
      }
      return !isMaintenanceIncident && !isResolvedIncident;
    }

    function initializeGridOptions() {
      const rowClassRules = vm.showAllEntities || {
        'secondary-row': (row) => !doesRowMatchFolder(row, $stateParams.folder),
      };
      return {
        deltaRowDataMode: true,
        columnDefs: columnDefs,
        rowData: rowData,
        getRowNodeId: (data) => data.symbol,
        headerHeight: 36,
        rowHeight: 36,
        rowClassRules: rowClassRules,
        angularCompileRows: true,
        suppressCellSelection: true,
        enableColResize: true,
        enableSorting: true,
        suppressDragLeaveHidesColumns: true,
        rowSelection: 'single',
        onColumnResized: onColumnResized,
        onGridReady: onGridReady,
        onRowClicked: onRowClicked,
        onSortChanged: onSortChanged,
        onDragStopped: onDragStopped,
        onSelectionChanged: angular.noop, // rowSelection wont work without onSelectionChanged callback
      };
    }

    function onDragStopped(event) {
      setLockedColumnsPosition();

      const orderedColumns = vm.gridOptions.columnApi
        .getAllGridColumns()
        .map((col) => ({ field: col.colId, pinned: col.pinned }));

      if (!vm.newAlertsAutoFit) {
        EntityPersistencyStore.setPersistency(sourceSystem, 'bp_columns_ordered', orderedColumns);
      }
    }

    function setLockedColumnsPosition() {
      const lastPosition = columnDefs.length - 1;
      vm.gridOptions.columnApi.moveColumns(['selected', 'status', 'underMaintenance'], 0);
      vm.gridOptions.columnApi.moveColumn('lastUpdate', lastPosition);
    }

    function onColumnResized(event) {
      const fitNotSuppressed = columnDefs.filter((colDef) => !colDef.suppressSizeToFit);
      if (numberOfInitialColumns <= fitNotSuppressed.length) {
        numberOfInitialColumns += 1;
        return;
      }

      if (event.finished && event.column) {
        const { colDef } = event.column;
        const field = colDef.field.toLowerCase();

        if (!colDef.suppressResize && Number.isInteger(event.column.actualWidth)) {
          pubSubService.broadcast('column.resized', {
            field: field,
            width: event.column.actualWidth,
          });
          if (!vm.newAlertsAutoFit && vm.gridOptions.columnApi) {
            const allColumnsWidth = vm.gridOptions.columnApi
              .getAllGridColumns()
              .map((col) => ({ field: col.colId, width: col.actualWidth }));

            EntityPersistencyStore.setPersistency('', 'bp_columns_width', allColumnsWidth);
          }
        }
      }
    }

    function onGridReady(params) {
      gridApi = params.api;
      gridColumnApi = params.columnApi;
      setDefaultSorting(gridApi, 'desc');

      EntityPersistencyStore.subscribe($scope, {
        storeUpdated: angular.noop,
      });

      if (widthColumnsPersistencyOn && !vm.newAlertsAutoFit) {
        waitForColumns().then((columns) => {
          columns.forEach((column) => {
            if (!column.colDef.suppressResize) {
              const field = column.colId.toLowerCase();
              const persistencyColumns = EntityPersistencyStore.getPersistency(
                '',
                'bp_columns_width'
              );
              const matchedColumn = persistencyColumns.find(
                (persistColumn) => persistColumn.field === field
              );

              gridColumnApi.setColumnWidth(column, matchedColumn ? matchedColumn.width : 100);
            }
          });
        });
      }
    }

    function waitForColumns() {
      let countRounds = 0;
      const deferred = $q.defer();
      const waitOneTick = () => $timeout(angular.noop, 100);
      const columnsGetter = () => {
        countRounds += 1;
        const columns = vm.gridOptions.columnApi.getAllColumns();
        if (columns) {
          deferred.resolve(columns);
          return $q.resolve();
        }
        if (countRounds > 50) {
          deferred.reject('no columns');
          return $q.resolve();
        }
        return waitOneTick().then(columnsGetter);
      };

      waitOneTick().then(columnsGetter);
      return deferred.promise;
    }

    function onRowClicked(event) {
      rowElement = event.event.target;
      if (!entity) {
        entity = getEntity(event.data.entityId, vm.entitiesToShow);
        vm.openPanel();
        pubSubService.broadcast('open.panel');
      } else if (entity && entity.id !== event.data.entityId) {
        $timeout.cancel(closerTimeout);
        entity = getEntity(event.data.entityId, vm.entitiesToShow);
        vm.openPanel();
        pubSubService.broadcast('open.panel');
      } else {
        clearSelection();
      }
    }

    function onSortChanged() {
      const sortModel = gridApi.getSortModel();

      if (sortModel.length > 0 && !initialSort) {
        const field = sortModel[0].colId;
        const { sort } = sortModel[0];

        pubSubService.broadcast('column.sort', { field, sort });
      }

      if (sortModel.length === 0 && prevSortedColId !== 'lastUpdate') {
        setDefaultSorting(gridApi, 'desc');
      } else if (sortModel.length === 0 && prevSortedColId === 'lastUpdate') {
        setDefaultSorting(gridApi, 'asc');
      }

      if (sortModel.length > 0) {
        prevSortedColId = sortModel[0].colId;
      }

      if (initialSort && sortModel.length > 0) {
        initialSort = false;
      }
    }

    function generateBaseColumn(column) {
      const field = getField(column);
      let fieldWidth = {
        minWidth: 100,
      };

      if (vm.newAlertsAutoFit) {
        fieldWidth = {
          width: normalizeEntityColumnWidth(
            vm.entitiesToShow,
            column,
            field,
            MIN_COLUMN_WIDTH,
            MAX_COLUMN_WIDTH
          ),
        };
      }

      return {
        suppressSizeToFit: true,
        lockVisible: true,
        headerName: escapeHtml(column.headerDisplayName),
        sortingOrder: ['desc', 'asc'],
        field: field,
        entityColField: column.field,
        headerTooltip: escapeHtml(column.headerDisplayName),
        template: buildTemplate(column),
        ...fieldWidth,
      };

      function escapeHtml(unsafe) {
        if (typeof unsafe === 'string' || unsafe instanceof String) {
          return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
        }
        return unsafe;
      }
    }

    function buildTemplate(column) {
      const field = getField(column);
      let tooltipText = `{{ rowNode.data['${field}'] | normalizeTagArray }}`;
      let copyButton = '';

      if (column.type) {
        if (column.type === 'duration') {
          tooltipText =
            "Duration: {{ rowNode.data['duration'] | normalizeTagArray }} (Created: {{ rowNode.data['created'] | normalizeTagArray }})";
        } else if (column.type === 'lastUpdate') {
          tooltipText =
            "Status Change: {{ rowNode.data['lastUpdateCalendar'] | normalizeTagArray }}";
        } else {
          // add copy button for other types
          copyButton = `<bp-copy ng-click="vm.copyClick($event, '${field}')" copy-model="rowNode.data['${field}']"></bp-copy>`;
        }
      }

      return `<span class="d-flex copy-wrapper">
          <span class="text-truncate" ng-bind="rowNode.data['${field}'] | normalizeTagArray" bp-tooltip bp-tooltip-content="${tooltipText}"></span>
          ${copyButton}
        </span>`;
    }

    function leftPinnedColumnsEnrich(column) {
      const enrichedColumn = {};
      if (
        column.field === 'status' ||
        column.field === 'underMaintenance' ||
        column.field === 'selected'
      ) {
        if (column.field === 'status') {
          enrichedColumn.minWidth = 34;
          enrichedColumn.headerName = '<i class="entity-value status off background"></i>';
          enrichedColumn.template =
            '<i ng-class="rowNode.data.status" class="entity-value status background"></i>';
          enrichedColumn.comparator = (valueA, valueB, nodeA, nodeB, isInverted) =>
            statusPriority[valueA] - statusPriority[valueB];
        } else if (column.field === 'underMaintenance') {
          enrichedColumn.minWidth = 38;
          enrichedColumn.headerName = '<i class="icon bp-icon-maintenance under-maintenance"></i>';
          enrichedColumn.template =
            '<i ng-if="rowNode.data.underMaintenance === true" class="icon bp-icon-maintenance under-maintenance" bp-tooltip bp-tooltip-template-content="{{ vm.maintenanceTooltip.template }}"></i>';
        } else {
          enrichedColumn.minWidth = 34;
          enrichedColumn.template =
            '<input type="checkbox" ng-model="rowNode.data.selected" ng-click="$event.stopPropagation()" ng-change="vm.calcSelectedIds()" />';
          enrichedColumn.headerComponent = SelectionHeaderRenderer;
        }

        enrichedColumn.pinned = 'left';
        enrichedColumn.maxWidth = enrichedColumn.minWidth;
        enrichedColumn.suppressMovable = true;
        enrichedColumn.suppressResize = true;
        enrichedColumn.suppressSizeToFit = false;
        enrichedColumn.lockPosition = true;
      }

      return { ...column, ...enrichedColumn };
    }

    function rightPinnedColumnsEnrich(column) {
      const enrichedColumn = {};

      if (column.field === 'lastUpdate' || column.field === 'duration') {
        enrichedColumn.pinned = 'right';
        enrichedColumn.suppressResize = true;
        enrichedColumn.suppressSizeToFit = false;

        if (column.field === 'duration') {
          enrichedColumn.minWidth = 50;
          enrichedColumn.maxWidth = 50;
          enrichedColumn.comparator = (valueA, valueB, nodeA, nodeB) =>
            nodeA.data.rawDuration - nodeB.data.rawDuration;
        }

        if (column.field === 'lastUpdate') {
          enrichedColumn.maxWidth = 75;
          enrichedColumn.minWidth = 75;
          enrichedColumn.suppressMovable = true;
          enrichedColumn.comparator = (valueA, valueB, nodeA, nodeB) =>
            nodeA.data.rawLastUpdate - nodeB.data.rawLastUpdate;
        }
      }

      return { ...column, ...enrichedColumn };
    }

    function linksEnrichColumn(column) {
      const enrichedColumn = {};

      if (column.field === 'links') {
        enrichedColumn.headerName = '<i class="icon bp-icon-link"></i>';
        enrichedColumn.minWidth = 48;
        enrichedColumn.maxWidth = 48;
        enrichedColumn.suppressResize = true;
        enrichedColumn.suppressSizeToFit = false;
        enrichedColumn.template =
          '<entity-links entity="rowNode.data.agEntityData" class="entity-col"></entity-links>';

        enrichedColumn.comparator = (valueA, valueB, nodeA, nodeB) => {
          if (nodeA.data.entity.links.length === 0) {
            return -1;
          }
          if (nodeB.data.entity.links.length === 0) {
            return 1;
          }
          return null;
        };
      }

      return { ...column, ...enrichedColumn };
    }

    function initGrid() {
      if (vm.pickedColumns.length === 0) {
        return;
      }

      sourceSystem = vm.entities[0].source_system;
      const filteredColumns = filterByAlertViewRule(vm.pickedColumns);
      columnDefs = filteredColumns.map((column) =>
        linksEnrichColumn(
          rightPinnedColumnsEnrich(leftPinnedColumnsEnrich(generateBaseColumn(column)))
        )
      );

      rowData = [];
      vm.entitiesToShow.forEach((entity) => {
        rowData.push(generateRowData(entity, columnDefs));
      });

      if (!isEqual(vm.pickedColumns, pickedColumnsState)) {
        pickedColumnsState = vm.pickedColumns;

        if (!vm.newAlertsAutoFit) {
          const orderedColumns = setColumnsPersistency(columnDefs);
          vm.gridOptions.api.setColumnDefs(orderedColumns);
          vm.gridOptions.api.sizeColumnsToFit();
        } else {
          vm.gridOptions.api.setColumnDefs(columnDefs);
        }
      }
      vm.gridOptions.api.setRowData(rowData);

      if (!vm.gridHeight) {
        vm.gridHeightStyle = '90%';
      } else {
        vm.gridHeightStyle = `${vm.gridHeight}px`;
      }
    }

    function arrayToDict(arr) {
      return arr.reduce(
        (obj, current) => ({
          ...obj,
          ...{
            [current.field]: current,
          },
        }),
        {}
      );
    }

    function setColumnsPersistency(columnDefs) {
      const orderedColumns = EntityPersistencyStore.getPersistency(
        sourceSystem,
        'bp_columns_ordered'
      );
      if (!orderColumnsPersistencyOn || (orderColumnsPersistencyOn && !orderedColumns)) {
        return columnDefs;
      }

      const parsedPersistenceColumns = orderedColumns;
      if (!parsedPersistenceColumns.length) {
        return columnDefs;
      }

      const originalOrderedColumnKeys = arrayToDict(columnDefs);
      const newColumnDefs = parsedPersistenceColumns
        .filter((savedCol) => originalOrderedColumnKeys[savedCol.field])
        .map((persistenceColumn) => ({
          ...originalOrderedColumnKeys[persistenceColumn.field],
          ...{
            pinned: persistenceColumn.pinned,
          },
        }));

      const orderedColumnKeys = arrayToDict(newColumnDefs);
      return [...newColumnDefs, ...columnDefs.filter((column) => !orderedColumnKeys[column.field])];
    }

    function getField(column) {
      if (column.type === 'text') {
        return column.field;
      }
      return column.type;
    }

    function generateRowData(entity, columnDefs) {
      return columnDefs.reduce((accObject, column) => createRowData(accObject, column, entity), {});
    }

    function createRowData(accObject, column, entity) {
      if (column.field === 'lastUpdate') {
        // use date if last updated today, otherwise use time
        const lastUpdate = $filter('lastUpdated')(entity.lastChangedTimestamp);

        const currentRowData = {
          symbol: entity._id,
          entityId: entity._id,
          [column.field]: lastUpdate,
          lastUpdateCalendar: moment.unix(entity.lastChangedTimestamp).calendar(),
          rawLastUpdate: entity.lastChangedTimestamp,
          is_active: entity.is_active,
        };
        return Object.assign({}, currentRowData, accObject);
      }

      if (column.field === 'selected') {
        return Object.assign(
          {},
          { [column.field]: !!vm.selectedEntities.find((e) => e._id === entity._id) },
          accObject
        );
      }

      if (column.field === 'status') {
        return Object.assign({}, { [column.field]: entity.status }, accObject);
      }

      if (column.field === 'underMaintenance') {
        return Object.assign({}, { [column.field]: entity.underMaintenance }, accObject);
      }

      if (column.field === 'description') {
        const rowData = {
          [column.field]: entity.description,
        };
        return Object.assign({}, rowData, accObject);
      }

      if (column.field === 'duration') {
        const rawDuration = getDurationDiff(entity.start, entity.end);
        const duration = $filter('duration')(entity.start, entity.end);
        const rowData = {
          [column.field]: duration,
          rawDuration: rawDuration,
          created: moment.unix(entity.start).calendar(),
        };
        return Object.assign({}, rowData, accObject);
      }

      if (column.field === 'links') {
        return Object.assign({}, { agEntityData: entity }, accObject);
      }

      if (column.field === 'source') {
        return Object.assign({}, { [column.field]: entity.sourceCap }, accObject);
      }

      let tagText = '';
      try {
        tagText = decodeURI(getTextTagValue(entity, column.entityColField) || '');
      } catch (_) {
        tagText = getTextTagValue(entity, column.entityColField);
      }
      return Object.assign({}, { [column.field]: tagText }, accObject);
    }

    function setDefaultSorting(gridApi, direction) {
      gridApi.setSortModel([{ colId: 'lastUpdate', sort: direction }]);
    }

    function updateEntitiesToShow() {
      const entities = [];
      if (vm.showActive) {
        entities.push(...vm.activeEntities);
      }
      if (vm.showUnderMaintenance) {
        entities.push(...vm.entitiesUnderMaintenance);
      }
      if (vm.showResolved) {
        entities.push(...vm.resolvedEntities);
      }

      let entitiesToShow = sortBy(entities, ['lastChangedTimestamp']);
      entitiesToShow = reverse(entitiesToShow);
      vm.entitiesToShow = entitiesToShow;

      updateColumnsByEntities();
    }

    function updateColumnsByEntities() {
      entitiesListColumnsPicker = new EntitiesListColumnsPicker(
        vm.entities,
        organizationEntitySorter
      );
      vm.orderedTagList = entitiesListColumnsPicker.getOrderedTagList().map((tag) => escape(tag));
      updateColumnsByWidth();
      initGrid();
      calcSelectedIds();
      setLockedColumnsPosition();
    }

    function selectAllRows(event) {
      const shouldSelect = vm.selectedEntities.length !== vm.entitiesToShow.length;
      vm.entitiesToShow.forEach((e) => {
        rowData.find((r) => r.entityId === e._id).selected = shouldSelect;
        vm.gridOptions.api.refreshCells({
          rowNodes: [vm.gridOptions.api.getRowNode(e._id)],
          force: true,
          columns: ['selected'],
        });
      });
      vm.selectedEntities = shouldSelect ? vm.entitiesToShow : [];
      event.stopPropagation();
    }

    function calcSelectedIds() {
      const newSelectedEntities = rowData
        .filter((r) => r.selected)
        .map((r) => vm.entitiesToShow.find((e) => e._id === r.entityId));
      const wereAllSelected = vm.selectedEntities.length === vm.entitiesToShow.length;
      const shouldAllBeSelected = newSelectedEntities.length === vm.entitiesToShow.length;

      vm.selectedEntities = newSelectedEntities;
      if (wereAllSelected !== shouldAllBeSelected || vm.showAllEntities) {
        vm.gridOptions.api.refreshHeader();
      }
    }

    function updateColumnsByWidth() {
      if (entitiesListColumnsPicker) {
        vm.pickedColumns = entitiesListColumnsPicker.pickColumns().reduce((acc, col) => {
          if (!(col.type !== 'underMaintenance' || vm.showUnderMaintenance)) {
            return acc;
          }
          if (col.field) {
            col.field = escape(col.field);
          }

          acc.push(col);

          return acc;
        }, []);

        if (gridApi && !widthColumnsPersistencyOn && !vm.newAlertsAutoFit) {
          $timeout(() => gridApi.sizeColumnsToFit(), 300);
        }
      }
    }

    function getMaintenancePlans(entityId) {
      const relevantEntity = vm.entitiesUnderMaintenance.find((eum) => eum._id === entityId);
      return relevantEntity
        ? vm.plans.filter((plan) => (relevantEntity.maintenance_plan_ids || []).includes(plan.id))
        : [];
    }

    function getEntity(entityId, entities) {
      return entities.find((entity) => entity._id === entityId);
    }

    function getDurationDiff(timeStampUnix, tillTimeUnix) {
      let since;

      if (
        tillTimeUnix &&
        angular.isNumber(timeStampUnix) &&
        !Number.isNaN(timeStampUnix) &&
        timeStampUnix > 0
      ) {
        since = moment.unix(tillTimeUnix).diff(moment.unix(timeStampUnix)) / 1000;
      } else {
        since = moment().diff(moment.unix(timeStampUnix)) / 1000;
      }

      return since;
    }

    function getTextTagValue(entity, columnField) {
      const tag =
        find(entity.tags, (t) => t.type === columnField || t.type === `_${columnField}`) || {};
      return angular.isArray(tag) ? tag.value.join('  |  ') : tag.value;
    }

    function copyClick($event, field) {
      pubSubService.broadcast('copy.to.clipboard', { field });
      $event.stopPropagation();
    }

    // mdPanel Logic
    function selectionChanged() {
      if (panel) {
        closePanel();
      }

      const panelLocals = {
        entity: angular.copy(entity),
      };

      const panelClass = 'entity-details-panel';
      $mdPanel
        .open({
          templateUrl: 'shared_ui/bp_event_details/bp_event_details',
          zIndex: 150,
          escapeToClose: true,
          propagateContainerEvents: true,
          position: $mdPanel.newPanelPosition().absolute(),
          panelClass: panelClass,
          locals: panelLocals,
          controllerAs: 'vm',
          bindToController: true,
          clickOutsideToClose: true,
          controller: panelController,
          onDomRemoved: () => {
            closerTimeout = $timeout(() => {
              clearSelection();
            });
          },
        })
        .then((PanelReference) => {
          panel = PanelReference;
          panel.updatePosition(adjustPosition(rowElement));
        });
    }

    function panelController() {
      const panelVm = this;
      panelVm.disableButtons = true;
      panelVm.event = getEventDetailsFromEntity(entity);
      if (entity.underMaintenance) {
        panelVm.maintenanceTooltip = {
          template: 'overview/entities/maintenance_tooltip/maintenance_tooltip',
          plans: getMaintenancePlans(entity._id),
        };
      }

      panelVm.close = () => {
        closePanel();
      };
    }

    function closePanel() {
      if (panel) {
        panel.close();
      }
    }

    function clearSelection() {
      panel = null;
      rowElement = null;
      entity = null;
      gridApi.deselectAll();
    }

    function adjustPosition(element) {
      const elementOffset = angular.element(element).offset();
      const tableOffset = $element.find('.entities-grid').offset();
      const position = $mdPanel.newPanelPosition();
      const windowElement = angular.element(window);
      const panelHeight = angular.element('.entity-details-panel').height();

      if (!$mdMedia('gt-sm')) {
        position.center();
      } else {
        position.left(`${tableOffset.left - 560}px`);
        if (elementOffset.top + panelHeight > windowElement.height()) {
          position.bottom('10px');
        } else {
          position.top(`${elementOffset.top - 60}px`);
        }
      }
      return position;
    }

    function getEventDetailsFromEntity(passedEntity) {
      let tags = passedEntity.tags.filter(
        (tag) =>
          !isNil(tag.value) && (!isEmpty(tag.value) || isBoolean(tag.value) || isNumber(tag.value))
      );

      if (passedEntity.description) {
        !find(tags, { type: 'description' }) &&
          tags.push({ type: 'description', value: passedEntity.description });
        !find(passedEntity.tags, { type: 'description' }) &&
          passedEntity.tags.push({ type: 'description', value: passedEntity.description });
      }
      tags = sortBy(tags, (tag) =>
        findIndex(vm.orderedTagList, (type) => type === tag.type || `_${type}` === tag.type)
      );

      let collapsedTags = [];
      const alertTags = IncidentsService.getAlertTags(passedEntity, alertViewRules);
      if (alertTags) {
        tags = alertTags[0];
        collapsedTags = alertTags[1];
      }

      return {
        id: passedEntity._id,
        source: passedEntity.sourceCap,
        status: passedEntity.status.toLowerCase(),
        underMaintenance: passedEntity.underMaintenance,
        created: moment.unix(passedEntity.start).calendar(),
        lastEvent: moment.unix(passedEntity.last_event).calendar(),
        tags: tags,
        collapsedTags: collapsedTags,
        resolutionType: getResolutionTypeUserFormat(passedEntity.resolution_type),
      };

      function getResolutionTypeUserFormat(resolution_type) {
        switch (resolution_type) {
          case resolutionTypes.MANUAL_UI:
          case resolutionTypes.MANUAL_API:
            return 'Manually resolved';
          case resolutionTypes.AUTO_RESOLVE:
            return 'Auto-resolved';
        }
      }
    }

    function filterByAlertViewRule(columns) {
      const firstRule = find(alertViewRules || [], (rule) => {
        return (
          rule.enable &&
          (rule.source_system.includes(sourceSystem) ||
            rule.source_system.includes('*') ||
            rule.source_system.includes(`${sourceSystem}.*`))
        );
      });
      if (firstRule) {
        !firstRule.tags.includes('description') && remove(columns, { type: 'description' });
        return columns.filter((col) => !col.field || firstRule.tags.includes(col.field));
      }

      return columns;
    }

    // ag-grid does not support firing angular event from headers. Since we need one for the selection header, we use plain javascript.
    // A header component must contain the `init` and `getGui` functions.
    class SelectionHeaderRenderer {
      init() {
        this.eGui = document.createElement('input');
        this.eGui.setAttribute('type', 'checkbox');
        this.eGui.addEventListener('click', vm.selectAllRows);
      }
      getGui() {
        this.eGui.checked =
          vm.selectedEntities.length === vm.entitiesToShow.length ? true : undefined;
        return this.eGui;
      }
      destroy() {
        this.eGui.removeEventListener('click', vm.selectAllRows);
      }
    }
  }
}
