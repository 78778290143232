const helperRecentSearchesMaxResults = 8;

angular
  .module('bigpanda.backend.services')
  .service('InvestigatorBackendService', InvestigatorBackendService);

function InvestigatorBackendService($http) {
  return {
    searchIncidents: searchIncidents,
    getRecentSearches: getRecentSearches,
  };

  function getRecentSearches() {
    return $http
      .get(`/resources/v2.1/search-queries?per_page=${helperRecentSearchesMaxResults}`)
      .then((res) => res.data);
  }

  function searchIncidents(query) {
    return $http.post('/web/search/incidents', query).then((res) => res.data.item);
  }
}
