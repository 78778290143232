import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './navigation_image.scss';

const NavigationImage = ({ image, title, className }) => {
  const imageClass = classnames([styles['navigation-image'], className]);

  return <img src={image} className={imageClass} alt={title} />;
};

NavigationImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NavigationImage;
