import pluralize from 'common/utils/utils';
const { BamNotificationStatusOptions } = require('@bp/bam');
const {
  showFailureMessage,
  showSuccessMessage,
  showInProgressMessageUndo,
} = require('react/layout/settings/feedback');
angular.module('bigpanda').factory('ResolveService', ResolveService);

const showSuccessMessageForBatchResolve = (title, action) => {
  setTimeout(
    () =>
      showSuccessMessage(
        title,
        action,
        BamNotificationStatusOptions.ACTION_SUCCESS,
        '',
        `and operation will be completed soon`,
        true
      ),
    5000
  );
};
const showInProgressMessageUndoForBatchResolve = (title, content, action, payload) => {
  showInProgressMessageUndo(
    title,
    content,
    BamNotificationStatusOptions.UNDO,
    {
      text: 'Undo',
    },
    action,
    payload
  );
};

const showFailureMessageForBatchResolve = (action, numOfAlerts) => {
  setTimeout(() => showFailureMessage(getAlertsString(numOfAlerts), action), 500);
};
function getAlertsString(numOfAlerts) {
  return numOfAlerts + pluralize(numOfAlerts, ` Alert`);
}

const SCHEDULING_TEXT = 'Scheduling incident to resolve';
const SCHEDULING_TEXT_COMPELTE = 'Incident';
const RESOLVED_ACTION = 'Resolved';

function ResolveService(
  $q,
  IncidentsService,
  pubSubService,
  BeforeUnloadService,
  ResolveBackendService,
  stateService,
  EnvironmentsService
) {
  return { resolve };
  async function sendResolveBatchEntitiesRequest(payload) {
    try {
      await ResolveBackendService.resolve(payload.ids, payload.annotation, payload.environment);
    } catch (e) {
      showFailureMessageForBatchResolve(RESOLVED_ACTION, payload.ids.length);
    }
  }

  function resolve(ids, annotation) {
    BeforeUnloadService.start();
    const multiple = ids.length > 1;
    let incidentQuery = $q.when();
    if (!multiple) {
      incidentQuery = IncidentsService.getIncidentById(ids[0]);
    }

    return incidentQuery.then((incident) => {
      const title = multiple ? `${ids.length} incidents` : incident.title;

      EnvironmentsService.getEnvById(stateService.getSelectedEnvironmentId()).then(
        async (environment) => {
          try {
            let payload = { ids: ids, annotation: annotation, environment: environment };
            showInProgressMessageUndoForBatchResolve(
              title,
              SCHEDULING_TEXT,
              sendResolveBatchEntitiesRequest,
              payload
            );
            showSuccessMessageForBatchResolve(SCHEDULING_TEXT_COMPELTE, RESOLVED_ACTION);
          } catch (e) {
            showFailureMessageForBatchResolve(RESOLVED_ACTION, ids.length);
          }
        }
      );
      ids.forEach((id) => {
        pubSubService.broadcast('incident.resolved', { id: id, undo: false });
      });
    });
  }
}
