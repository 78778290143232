import 'rxjs/add/observable/combineLatest';

import { BamNotificationStatusOptions, bamUnNotify } from '@bp/bam';
import { combineEpics } from 'redux-observable';

import {
  showFailureMessage,
  showInProgressMessage,
  showSuccessMessage,
} from '../../../../app/react/layout/settings/feedback';
import {
  createMaintenancePlan,
  deleteMaintenancePlan,
  getMaintenancePlans,
  stopMaintenancePlan,
  updateMaintenancePlan,
} from '../api/endpoints';
import actions from './actions';
import actionTypes from './actionTypes';

const loadPlans = (action$) => action$
  .ofType(actionTypes.LOAD_PLANS, actionTypes.LOAD_INITIAL_PLANS)
  .mergeMap(async ({
    payload: {
      link, active, search, refresh,
    } = {},
  }) => {
    try {
      const {
        data: plans,
        link: nextLink,
        showDonePlans,
      } = await getMaintenancePlans({ link, active, search });
      return actions.loadPlansSuccess({
        plans,
        link: nextLink,
        showDonePlans,
        refresh,
      });
    } catch (ex) {
      return showFailureMessage('load');
    }
  });

const createOrUpdatePlan = (action$) => action$.ofType(
  actionTypes.CREATE_OR_UPDATE_PLAN,
).mergeMap(async ({ payload }) => {
  const {
    plan: {
      id, name, times: { from, to }, condition, description = '', frequency, frequencyData,
    },
    hasPlanStarted,
  } = payload;
  let toasterId;

  const planToCreateOrUpdate = hasPlanStarted
    ? { id, end: parseInt(to.format('X'), 10) }
    : {
      id,
      name,
      start: parseInt(from.format('X'), 10),
      end: parseInt(to.format('X'), 10),
      condition,
      description,
      frequency,
      frequency_data: frequencyData,
    };

  if (planToCreateOrUpdate.id) {
    try {
      await updateMaintenancePlan(planToCreateOrUpdate);
      showSuccessMessageForPlan('updated');
    } catch (e) {
      showFailureMessageForPlan('update');
    }
  } else {
    try {
      toasterId = showInProgressMessageForPlan('Plan', 'Creating');
      const newPlan = await createMaintenancePlan(planToCreateOrUpdate);
      if (window.location.href.includes('planned-maintenance')) {
        window.location.href = `/#/app/settings/planned-maintenance/${newPlan.id}`;
      }
      showSuccessMessageForPlan('created', toasterId);
    } catch (e) {
      showFailureMessageForPlan('create', toasterId);
    }
  }
  return actions.loadInitialPlans({ active: payload.active });
});

const updatePlanEnd = (action$) => action$.ofType(
  actionTypes.UPDATE_PLAN_END,
).mergeMap(async ({ payload }) => {
  const { plan } = payload;
  const planUpdate = {
    id: plan.id,
    end: parseInt(plan.times.to.format('X'), 10),
  };
  try {
    await updateMaintenancePlan(planUpdate);
    showSuccessMessageForPlan('update end');
  } catch (e) {
    showFailureMessageForPlan('update end');
  }
  return actions.loadInitialPlans({ active: payload.active });
});

const deletePlan = (action$) => action$.ofType(
  actionTypes.DELETE_PLAN,
).mergeMap(async ({ payload }) => {
  let toasterId;
  try {
    toasterId = showInProgressMessageForPlan('Plan', 'Deleting');
    await deleteMaintenancePlan(payload);
    showSuccessMessageForPlan('deleted', toasterId);
  } catch (e) {
    showFailureMessageForPlan('delete', toasterId);
  }
  return actions.loadInitialPlans({ active: payload.active });
});

const stopPlan = (actions$) => actions$.ofType(
  actionTypes.STOP_PLAN,
).mergeMap(async ({ payload }) => {
  const { plan } = payload;
  let toasterId;
  try {
    toasterId = showInProgressMessageForPlan('Plan', 'Stop');
    await stopMaintenancePlan(plan);
    showSuccessMessageForPlan('stopped', toasterId);
  } catch (e) {
    showFailureMessageForPlan('stop', toasterId);
  }
  return actions.loadInitialPlans({ active: payload.active });
});

const showInProgressMessageForPlan = (
  title,
  action,
  status = BamNotificationStatusOptions.IN_PROGRESS,
) => showInProgressMessage(title, action, status);

const showSuccessMessageForPlan = (
  action,
  toasterId,
  status = BamNotificationStatusOptions.ACTION_SUCCESS,
) => {
  if (toasterId) {
    bamUnNotify(toasterId);
  }
  setTimeout(() => showSuccessMessage('Plan', action, status), 1000);
};

const showFailureMessageForPlan = (action, toasterId) => {
  if (toasterId) {
    bamUnNotify(toasterId);
  }
  setTimeout(() => showFailureMessage('Plan', action), 1000);
};

export default combineEpics(
  loadPlans,
  createOrUpdatePlan,
  deletePlan,
  stopPlan,
  updatePlanEnd,
);
