import get from 'lodash/get';
import { path, DEFAULT_SORT_MODE } from './constants';
import { generateBaseSelectors } from '../../redux.utils';

const selectors = generateBaseSelectors(path);

const getFilteredIntegrations = (integrations, filterText) => {
  const lowerCaseTextFilter = filterText.toLowerCase();
  return integrations.filter((integration) => integration.name.includes(lowerCaseTextFilter));
};

selectors.getIntegrations = (state) => {
  const { integrations, filterText } = get(state, path);
  if (!integrations) {
    return undefined;
  }

  const filteredIntegrations = filterText
    ? getFilteredIntegrations(integrations, filterText)
    : integrations;

  return filteredIntegrations;
};
selectors.getSortMode = (state) => get(state, [path, 'sortMode'], DEFAULT_SORT_MODE);

export default selectors;
