import { path } from './constants';

const actionTypes = {
  GET_LOGS: `${path}.GET_LOGS`,
  GET_LOGS_SUCCESS: `${path}.GET_LOGS_SUCCESS`,
  GET_LOGS_FAILURE: `${path}.GET_LOGS_FAILURE`,
  GET_LOGS_METADATA: `${path}.GET_LOGS_METADATA`,
  GET_LOGS_METADATA_SUCCESS: `${path}.GET_LOGS_METADATA_SUCCESS`,
  GET_LOGS_METADATA_FAILURE: `${path}.GET_LOGS_METADATA_FAILURE`,
  SHOW_DATE_PICKER: `${path}.SHOW_DATE_PICKER`,
  HIDE_DATE_PICKER: `${path}.HIDE_DATE_PICKER`,
  SET_CUSTOM_DATE_RANGE: `${path}.SET_CUSTOM_DATE_RANGE`,
};

export default actionTypes;
