import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@bp/kung-fu';
import RelatedChangeActivityRecord from '../RelatedChangeActivityRecord/RelatedChangeActivityRecord';

const RelatedChangeActivityRecordTooltip = ({ relatedChange, content, trigger, position }) => {
  return (
    <Tooltip
      wrapperDisplay="flex"
      placement={position}
      maxWidth="400px"
      text={
        relatedChange ? (
          <RelatedChangeActivityRecord
            user={relatedChange.user}
            updatedAt={relatedChange.updatedAt}
            matchCertainty={relatedChange.matchCertainty}
            comment={relatedChange.comment}
            richComment={relatedChange.richComment}
            suggestedByBP={relatedChange.suggestedByBP}
          />
        ) : (
          content
        )
      }
    >
      {trigger}
    </Tooltip>
  );
};

RelatedChangeActivityRecordTooltip.propTypes = {
  position: PropTypes.string.isRequired,
  trigger: PropTypes.node,
  // eslint-disable-next-line react/no-typos
  relatedChange: PropTypes.shape(RelatedChangeActivityRecord.propTypes),
  content: (props) =>
    !props.relatedChange
      ? PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
      : null,
};

RelatedChangeActivityRecordTooltip.defaultProps = {
  trigger: undefined,
  relatedChange: undefined,
  content: undefined,
};

export default RelatedChangeActivityRecordTooltip;
