import map from 'lodash/map';
import reduce from 'lodash/reduce';
const { bpToggleDropdown } = require('angularjs/widgets/bp_toggle_dropdown/bp.toggle.dropdown.js');

angular.module('bigpanda').directive('multipleSnoozeButton', multipleSnoozeButton);

function multipleSnoozeButton() {
  return {
    restrict: 'E',
    templateUrl: 'overview/incidents/list/widgets/snooze_button/multiple_snooze_button',
    require: {
      checkedIncidentsState: '^^checkedIncidentsState',
    },
    scope: {},
    replace: true,
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(
    $scope,
    $element,
    $q,
    $state,
    $stateParams,
    $window,
    $dropdown,
    ModalService,
    SnoozeStore,
    stateService
  ) {
    const vm = this;

    let dropdown = null;

    vm.snoozeTooltip = 'Snooze';
    vm.snoozeProps = {
      snoozed: false,
      'bp-disabled': false,
    };

    vm.$onInit = onInit;

    function onInit() {
      $element.click(onClick);

      updateSnoozeProps();
      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: updateSnoozeProps,
      });
    }

    function onClick(e) {
      if (!vm.snoozeProps.snoozed) {
        $scope.$apply(schedule);
      } else {
        e.stopPropagation();
        const dropdownScope = $scope.$new();

        dropdownScope.snoozeSelectorVm = {
          schedule: schedule,
          cancelSnooze: cancelSnooze,
        };

        dropdown = bpToggleDropdown(
          dropdown,
          $element,
          dropdownScope,
          'overview/incidents/actions/snooze_selector',
          $window,
          $dropdown
        );
      }
    }

    function schedule() {
      ModalService.showModal({
        templateUrl: 'overview/incidents/actions/snooze/snooze_popup',
        controller: 'SnoozePopupController',
        controllerAs: 'vm',
        bindToController: true,
        resolve: {
          resolvedIncidentIds: () => vm.checkedIncidentsState.getCheckedIncidentIds(),
        },
      }).then(() => {
        vm.checkedIncidentsState.clear();
      });
    }

    function cancelSnooze() {
      SnoozeStore.unsnooze(
        stateService.getSelectedEnvironmentId(),
        vm.checkedIncidentsState.getCheckedIncidentIds()
      );
      vm.checkedIncidentsState.clear();
    }

    function updateSnoozeProps() {
      isAtLeastOneSnoozed().then((atLeastOneIncidentSnoozed) => {
        vm.snoozeProps = {
          snoozed: atLeastOneIncidentSnoozed,
          'bp-disabled': false,
        };
      });
    }

    function isAtLeastOneSnoozed() {
      return getCheckedIncidentsSnoozeData().then((snoozeData) =>
        reduce(snoozeData, (snoozed, data) => !!snoozed || !!data.timestamp, false)
      );
    }

    function getCheckedIncidentsSnoozeData() {
      return $q.all(
        map(vm.checkedIncidentsState.getCheckedIncidentIds(), (incidentId) =>
          SnoozeStore.getSnoozeData(incidentId)
        )
      );
    }
  }
}
