angular.module('bigpanda').controller('CustomTagInfoCtrl', CustomTagInfoCtrl);

function CustomTagInfoCtrl(
  $scope,
  resolvedTag,
  CorrelationActions,
  CustomTagActions,
  PERMISSIONS_NAMES,
  CorrelationConfigService,
  CustomTagsService
) {
  const vm = this;

  if (!resolvedTag) {
    return CorrelationActions.escapeRoute('Could not find Custom Tag info', 'app.settings.tags');
  }

  vm.updatePermissions = [PERMISSIONS_NAMES.correlations.update];
  vm.toggleCustomTag = CustomTagActions.toggleCustomTag;
  vm.buttons = CustomTagActions.buttons;
  vm.tag = resolvedTag;

  init();

  function init() {
    CorrelationConfigService.subscribe($scope, {
      storeUpdated: () =>
        CustomTagsService.getTagById(resolvedTag.id).then((tag) => {
          if (tag) {
            vm.tag = tag;
          }
        }),
    });

    vm.query = getQueryValue(vm.tag.pureQuery);
    $scope.$watch(
      () => vm.tag.pureQuery,
      (pureQuery) => {
        vm.query = getQueryValue(pureQuery);
      }
    );

    if (vm.tag.metadata) {
      setMetadata(vm.tag.metadata);
      $scope.$watch(
        () => vm.tag.metadata.updated_time,
        () => {
          setMetadata(vm.tag.metadata);
        }
      );
    }
  }

  function setMetadata(metadata) {
    const createdUserId = metadata.created_user;
    const updatedUserId = metadata.updated_user;
    CorrelationActions.getInvolvedUserNames(createdUserId).then((name) => {
      vm.createdUser = name;
    });
    CorrelationActions.getInvolvedUserNames(updatedUserId).then((name) => {
      vm.updatedUser = name;
    });
  }

  function getQueryValue(pureQuery) {
    return CorrelationActions.getQueryDisplayVal(pureQuery);
  }
}
