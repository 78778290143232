export default {
  incidents: 'Incidents',
  alert_tags: 'Alert Tags',
  recent_searches: 'Recent Searches',
  dropdown_option1_title: 'assignee',
  dropdown_option2_title: 'status',
  dropdown_option3_title: 'source_system',
  dropdown_option1_description: 'The assigned user for the incident',
  dropdown_option2_description: 'The current status of the incident (“Critical”, “Warning“, ”Unknown“, ”Ok“)',
  dropdown_option3_description: 'The integration that sent an alert to BigPanda',
  dropdown_option1_example1: 'assignee = derrick*',
  dropdown_option1_example2: 'assignee = derrick@bigpanda.io',
  dropdown_option2_example1: 'status = critical',
  dropdown_option3_example1: 'source_system = nagios*',
  dropdown_option3_example2: 'source_system IN [nagios*,new_relic*]',
};
