import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Field } from 'react-final-form';
import { firstBy } from 'thenby';
import memoize from 'memoize-one';
import { BamForm, BamInput, BamDropdownField } from '@bp/bam';
import sortBy from 'lodash/sortBy';

import CreatableMultiselect from 'react/common/components/CreatableMultiselect/CreatableMultiselect';
import { rulePropType, environmentPropType, userPropType, systemPropType } from '../propTypes';
import utils from '../utils';
import styles from './AutoShareRuleForm.scss';
import { systems, targetSystemSchema } from './targetSystems';
import {
  getSimpleIntegrations,
  retriveShareOptions,
  getIntegrationIcon,
} from './integrationsHelper';

class AutoShareRuleForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const { rule } = this.props;
    const contacts = get(rule, ['params', 'contacts'], []);

    const selectedContactsBySystem = Object.values(systems).reduce(
      (acc, system) => ({
        ...acc,
        [system]: this.usersOptions(contacts, rule.targetSystemId),
      }),
      {}
    );

    this.state = {
      targetSystemId: rule.targetSystemId,
      recipientsInput: '',
      ...selectedContactsBySystem,
    };
  }

  onSelectedContactsChanged = (onChange) => (selectedContacts) => {
    this.setState({ recipientsInput: '', [this.state.targetSystemId]: selectedContacts });
    onChange(selectedContacts);
  };

  onValidateRecipients = (values) =>
    !values || !values.length ? 'Recipients must be selected' : '';

  onValidateNewRecipient = (value) => {
    const config = targetSystemSchema[this.state.targetSystemId];
    return !config.validate(value) ? `The ${config.propertyName} format is invalid` : '';
  };

  onAddNewContact = ({ value: name, label }) => {
    const { targetSystemId } = this.state;
    const { propertyName } = targetSystemSchema[targetSystemId];
    return {
      user: {
        name,
        [propertyName]: label,
      },
    };
  };

  onChangeTargetSystem = (targetSystemId) => {
    if (this.state.targetSystemId === targetSystemId) {
      return targetSystemId ? undefined : 'Channel is required';
    }
    this.setState({ recipientsInput: '', targetSystemId });
  };

  onRecipientsInputChange = (recipientsInput) => this.setState({ recipientsInput });

  handleSubmit = (values, form) => {
    const isUpdate = this.isUpdate();
    const { close } = this.props;

    if (form.getState().dirty) {
      const { updateRule, createRule } = this.props;

      const { targetSystemId: systemId, [systemId]: selectedContacts } = this.state;
      const {
        id,
        params: paramValues,
        environment: { old_id: envId },
        targetSystemId,
      } = values;

      if (this.hasRecipients() && !selectedContacts.length) return;
      const contacts = this.hasRecipients()
        ? { contacts: selectedContacts.map(({ user }) => ({ ...user, _id: user.contact_id })) }
        : {};

      const targetWOption = get(targetSystemId, 'option');

      const params = {
        ...paramValues,
        ...contacts,
        autoshare_options: targetWOption ? targetSystemId.option : null,
      };

      const targetSystemName = targetWOption ? targetSystemId.targetSystemId : targetSystemId;

      if (isUpdate) {
        updateRule({ id, params });
      } else {
        createRule({ params, envId, targetSystemId: targetSystemName });
      }
    }
    close();
  };

  environmentsOptions = memoize((environments) =>
    environments.sort(firstBy('name')).map((env) => ({
      key: env.old_id,
      text: capitalize(utils.envName(env.name)),
      value: env.old_id,
    }))
  );

  targetSystemsOptions = (integrations) => {
    const shareOptionsIntegrations = retriveShareOptions(integrations);
    const simpleIntegrations = getSimpleIntegrations(integrations);

    const sortedTargetIntegrations = sortBy(
      [...simpleIntegrations, ...shareOptionsIntegrations],
      'order',
      'text'
    );

    return sortedTargetIntegrations;
  };

  userPropertyForTargetSystem = (user, targetSystemId) =>
    user[targetSystemSchema[targetSystemId].propertyName];
  usersOptions = memoize((users, targetSystemId) =>
    targetSystemSchema[targetSystemId]
      ? users
          .filter((user) => !!this.userPropertyForTargetSystem(user, targetSystemId))
          .map((user) => ({
            value: user.contact_id,
            label: this.userPropertyForTargetSystem(user, targetSystemId),
            user,
          }))
      : []
  );

  isUpdate = () => get(this.props.rule, 'id');

  hasRecipients = () => !!targetSystemSchema[this.state.targetSystemId];

  ruleName = ({ environment, system }) => (
    <div className={styles.info}>
      <span className={styles.info_environment_name}>{utils.envName(environment.name)}</span>
      <span className={styles.info_bullet}>•</span>
      <span className={styles.info_icon}>{getIntegrationIcon(system.parent_system_id)}</span>
      <span className={styles.info_system_name}>{system.name}</span>
    </div>
  );

  ValueLabel = ({ user: { name } }) => <span>{name}</span>;
  OptionLabel = ({ user }) => {
    const { targetSystemId } = this.state;
    const { propertyName } = targetSystemSchema[targetSystemId];
    return (
      <span>
        <strong>{user.name}</strong> ({user[propertyName]})
      </span>
    );
  };

  validateField = (field) => (val) => (!val || !val.length ? `${field} is required` : undefined);

  filterByName = ({ data: { user } }, input) =>
    !input || (!!user && user.name.toLowerCase().includes(input.toLowerCase()));

  render() {
    const { rule, close, integrations, users, environments } = this.props;
    const { targetSystemId, recipientsInput, [targetSystemId]: selectedContacts } = this.state;

    const isUpdate = this.isUpdate();
    const approveButtonText = isUpdate ? 'Update AutoShare' : 'Create AutoShare';
    const approveButtonProductId = isUpdate
      ? 'update_incident_autoshare'
      : 'create_incident_autoshare';

    const parentSystemId =
      get(targetSystemId, ['option', 'type']) ||
      get(
        integrations.find(({ system_id }) => system_id === targetSystemId),
        'parent_system_id'
      );

    const selectedIcon = (
      <div className={cn(styles['list-selected-icon'])}>{getIntegrationIcon(parentSystemId)}</div>
    );

    return (
      <BamForm
        id="AutoShareRuleForm"
        onSubmit={this.handleSubmit}
        initialValues={rule}
        positiveButton={{ text: approveButtonText, 'data-product-id': approveButtonProductId }}
        closeButton={{
          text: 'Cancel',
          onClick: close,
        }}
      >
        {isUpdate && this.ruleName(rule)}
        {!isUpdate && (
          <Field
            name="environment.old_id"
            title="Environment"
            component={BamDropdownField}
            className={styles.list}
            options={this.environmentsOptions(environments)}
            selected={rule.envId}
            placeholder="Select an environment..."
            validate={this.validateField('Environment')}
          />
        )}
        {!isUpdate && (
          <Field
            name="targetSystemId"
            title="Share Via"
            component={BamDropdownField}
            className={cn(styles.list, { [styles['list-selected']]: targetSystemId })}
            icon={targetSystemId && selectedIcon}
            options={this.targetSystemsOptions(integrations)}
            selected={rule.targetSystemId}
            validate={this.onChangeTargetSystem}
            placeholder="Select a channel..."
          />
        )}
        {this.hasRecipients() && (
          <div className={styles['recipients-field']}>
            <label htmlFor="integration-recipients" className={styles.fieldLabel}>
              Recipients
            </label>
            <Field name="recipients">
              {({ input: { onChange }, meta }) => (
                <CreatableMultiselect
                  closeOnBlur
                  filterOption={this.filterByName}
                  hideFirstOption
                  isMenuOpen={false}
                  values={selectedContacts}
                  inputValue={recipientsInput}
                  onInputChange={this.onRecipientsInputChange}
                  options={this.usersOptions(users, targetSystemId)}
                  OptionLabel={this.OptionLabel}
                  ValueLabel={this.ValueLabel}
                  validateOnBlur
                  validateNewValue={this.onValidateNewRecipient}
                  validateAllValues={this.onValidateRecipients}
                  onChange={this.onSelectedContactsChanged(onChange)}
                  onAddNewValue={this.onAddNewContact}
                  className={styles['as-react-select']}
                  placeholder="Select recipients..."
                  inputId="integration-recipients"
                  noOptionsMessage="No options"
                  meta={meta}
                />
              )}
            </Field>
          </div>
        )}
        <Field
          name="params.message"
          title="Personal Message"
          component={BamInput}
          placeholder="Enter your message"
          inputtype="textarea"
          rows="3"
          optional
        />
      </BamForm>
    );
  }
}

AutoShareRuleForm.propTypes = {
  updateRule: PropTypes.func.isRequired,
  createRule: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  environments: PropTypes.arrayOf(environmentPropType),
  users: PropTypes.arrayOf(userPropType),
  integrations: PropTypes.arrayOf(systemPropType),
  rule: rulePropType,
};

AutoShareRuleForm.defaultProps = {
  users: [],
  environments: undefined,
  integrations: [],
  rule: {
    contacts: [],
  },
};

export default hot(module)(AutoShareRuleForm);
