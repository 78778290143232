import {
  AllSizes, Button, ButtonVariants, HBox, SBHBox, SCHBox, VBox,
} from '@bp/kung-fu';
import styled from 'styled-components';

export const Wrapper = styled(VBox)`
    width: 550px;
    max-height: 60vh;
    overflow: auto;
`;

export const ElementWrapper = styled(SCHBox)`
    border-bottom: 1px solid ${(p) => p.theme.bp_gray_02};
    padding: 16px 20px;
`;

export const TileWrapper = styled(SBHBox)`
    border-bottom: 1px solid ${(p) => p.theme.bp_gray_02};
    padding: 26px 20px;
    gap: 1rem;
`;

export const ButtonWrapper = styled(HBox)`
flex-shrink: 0;
`;

export const MatchedPatternsTrigger = styled(Button).attrs({
  size: AllSizes.LARGE, variant: ButtonVariants.ACTION_DEFAULT,
})`
  margin-inline-end: 5px;
   && {
     border: 1px solid ${(p) => p.theme.bp_gray_03};
    }
`;
