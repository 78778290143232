import { darkTheme, Select } from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import EnvRuleAssigneeSelectItem from '../EnvRuleAssigneeSelectItem';
import SelectWrapper from './index.style';
import { IEnvRuleAssigneeSelect } from './types';

function envRuleAssigneeSelect(props: IEnvRuleAssigneeSelect): JSX.Element {
  const {
    selected,
    items,
    metadata,
    onChange,
  } = props;

  return (
    <ThemeProvider theme={darkTheme}>
      <SelectWrapper>
        <Select
          name={`ruleIndex_${metadata.ruleIndex}_andIndex_${metadata.andIndex}_assignee`}
          value={selected}
          options={items}
          updateField={(name, assignees): void => onChange(assignees, metadata, true)}
          placeholder="Select assignee..."
          labelKey="name"
          isMulti
          isFilterable
          optionComponent={EnvRuleAssigneeSelectItem}
        />
      </SelectWrapper>
    </ThemeProvider>
  );
}

export default envRuleAssigneeSelect;
