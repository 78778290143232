import map from 'lodash/map';
import values from 'lodash/values';
angular.module('bigpanda').directive('multipleResolveButton', multipleResolveButton);

function multipleResolveButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '^^checkedIncidentsState',
    },
    scope: {},
    replace: true,
    templateUrl: 'overview/incidents/list/widgets/resolve_button/resolve_button',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($scope, $q, $state, $stateParams, ModalService, IncidentsService) {
    const vm = this;

    vm.resolveTooltip = 'Resolve';
    vm.resolveProps = {
      resolved: false,
      'bp-disabled': true,
    };

    vm.resolve = resolve;

    vm.$onInit = onInit;

    function onInit() {
      updateResolvedProps();
      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: updateResolvedProps,
      });
    }

    function updateResolvedProps() {
      isAtLeastOneResolved().then((atLeastOneIncidentResolved) => {
        vm.resolveProps = {
          resolved: false,
          'bp-disabled': atLeastOneIncidentResolved,
        };
      });
    }

    function isAtLeastOneResolved() {
      return getCheckedIncidents().then((incidents) =>
        values(incidents).reduce(
          (resolved, incident) => !!resolved || (incident && incident.status === 'ok'),
          false
        )
      );
    }

    function getCheckedIncidents() {
      return $q.all(
        map(vm.checkedIncidentsState.getCheckedIncidentIds(), (incidentId) =>
          IncidentsService.getIncidentById(incidentId)
        )
      );
    }

    function resolve() {
      ModalService.showModal({
        templateUrl: 'overview/incidents/actions/resolve/resolve_popup',
        controller: 'IncidentResolveCtrl',
        controllerAs: 'incidentResolveVm',
        bindToController: true,
        resolve: {
          resolvedIncidentIds: () => vm.checkedIncidentsState.getCheckedIncidentIds(),
        },
      }).then(() => {
        vm.checkedIncidentsState.clear();
      });
    }
  }
}
