import {
  AllSizes,
  darkTheme,
  HelperType,
  IDataListObject,
  LabelIcon,
  QueryHelper,
  SearchField,
  SearchfilterIcon,
  TagIcon,
} from '@bp/kung-fu';
import get from 'lodash/get';
import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { loadAlertTags } from '../../../../../../app/react/common/alert_tags/actions';
import selectors from '../../../../../../app/react/common/alert_tags/selectors';
import provideStore from '../../../../../../app/react/utils/provideStore';
import {
  ALERT_TAGS,
  DROPDOWMN_SHADOW,
  INCIDENT_TAGS,
  INCIDENT_TAGS_METADATA, OLD_INCIDENT_TAGS_METADATA,
  RECENT_SEARCHES,
  SEARCH_INPUT_PLACEHOLDER,
} from './constants';
import { QueryHelperWrapper } from './QueryHelper.style';
import { IQueryHelper } from './types/IQueryHelper';

function IncidentsQueryHelper({
  handleSearch,
  incidentTags,
  error,
  getRecentSearches,
  clearButtonClicked,
  disabled,
  onChange,
  useIncidentTag,
}: IQueryHelper): JSX.Element {
  const [recentSearches, setRecentSearches] = useState([]);
  const dispatch = useDispatch();
  const alertTags = useSelector((state) => selectors.getDisplayAlertTags(state)) || [];

  const handleGetRecentSearches = useCallback(async () => {
    const { items } = await getRecentSearches();
    setRecentSearches(items);
  }, []);

  const buildIncidentTagsQueryHelperData = (): IDataListObject => {
    const incidentTagsValues = [
      ...(useIncidentTag ? INCIDENT_TAGS_METADATA : OLD_INCIDENT_TAGS_METADATA),
      ...(incidentTags && useIncidentTag
        ? incidentTags.map(({ name, description, id }) => ({ title: name, description, id })) : []),
    ];
    return {
      title: INCIDENT_TAGS,
      type: HelperType.INCIDENT_TAGS,
      disabled: false,
      icon: <LabelIcon />,
      values: incidentTagsValues,
    };
  };

  const buildAlertTagsQueryHelperData = (): IDataListObject => {
    const alertTagsValues = alertTags.map(({ name, sourceSystems }) => ({
      title: name,
      description: get(sourceSystems, '0.parentDisplayName'),
    }));
    return (
      alertTags.length && {
        title: ALERT_TAGS,
        type: HelperType.ALERT_TAGS,
        disabled: false,
        icon: <TagIcon />,
        values: alertTagsValues,
      }
    );
  };

  const buildRecentSearchesQueryHelperData = (): IDataListObject => {
    const recentSearchesValues = recentSearches.map((title: string) => ({
      title,
    }));
    return (
      recentSearches.length && {
        title: RECENT_SEARCHES,
        type: HelperType.RECENT_SEARCHES,
        disabled: false,
        icon: <SearchfilterIcon />,
        values: recentSearchesValues,
      }
    );
  };

  const handleSearchQuery = useCallback((searchQuery: string) => {
    setRecentSearches([searchQuery, ...recentSearches]);
    handleSearch(searchQuery);
  }, [recentSearches]);

  useEffect(() => {
    dispatch(loadAlertTags());
  }, []);

  useEffect(() => {
    handleGetRecentSearches();
  }, []);

  const incidentTagsData = useMemo(buildIncidentTagsQueryHelperData, [incidentTags]);

  const alertTagsData = useMemo(buildAlertTagsQueryHelperData, [alertTags]);

  const recentSearchesData = useMemo(buildRecentSearchesQueryHelperData, [recentSearches]);

  const queryHelperData = [
    ...(recentSearchesData ? [recentSearchesData] : []),
    ...(incidentTagsData ? [incidentTagsData] : []),
    ...(alertTagsData ? [alertTagsData] : []),
  ];

  const handleOnChange = (value: string) => {
    onChange(value);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <QueryHelperWrapper>
        <QueryHelper
          onInputClearButtonClick={clearButtonClicked}
          placeholder={SEARCH_INPUT_PLACEHOLDER}
          data={queryHelperData}
          TriggerComponent={SearchField}
          onClick={handleSearchQuery}
          onChange={handleOnChange}
          error={error?.msg}
          dropDownShadow={DROPDOWMN_SHADOW}
          triggerComponentProps={{ size: AllSizes.MEDIUM, disabled }}
          shouldUseNamespace={useIncidentTag}
        />
      </QueryHelperWrapper>
    </ThemeProvider>
  );
}

export default provideStore(memo(IncidentsQueryHelper));
