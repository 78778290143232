import { DefaultRootState } from 'react-redux';

import selectors from '../../../../../../../app/react/user/organization/selectors';

type Organization = {
  _id: string,
};

type Signature = (state: DefaultRootState) => Organization;

const organizationSelector = selectors.getOrganization;

export default organizationSelector as unknown as Signature;
