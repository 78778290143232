import extend from 'lodash/extend';
angular.module('bigpanda').service('CommentsStore', CommentsStore);

function CommentsStore(
  $q,
  CommentsBackendService,
  StreamConnector,
  SubscribersService,
  PromiseBatchService,
  stateService,
  EnvironmentsService
) {
  this.subscribe = subscribe;
  this.getCommentsCount = getCommentsCount;
  this.getComments = getComments;
  this.createComment = createComment;
  this.createMultiComment = createMultiComment;

  const commentCount = {};
  const subscribers = new SubscribersService();
  const batchCommentsCount = PromiseBatchService.batch(
    CommentsBackendService.getCommentsCounts,
    (commentId, commentResult) => commentId === commentResult.key,
    200
  );

  StreamConnector.on('comment', (comment) => {
    refreshComments(comment.id);
  });

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function getCommentsCount(id, forceReload) {
    if (!forceReload && Number.isInteger(commentCount[id])) {
      return $q.when(commentCount[id]);
    }

    return batchCommentsCount(id).then((count) => {
      commentCount[id] = count.doc_count;
      return count.doc_count;
    });
  }

  function getComments(id) {
    return CommentsBackendService.getComments(id);
  }

  function createComment(id, params) {
    return createMultiComment([id], params);
  }

  function createMultiComment(ids, params) {
    const envId = stateService.getSelectedEnvironmentId();
    const commentPayload = extend(params, { ids });

    return EnvironmentsService.getEnvById(envId)
      .then((environment) =>
        CommentsBackendService.createMultiComment(
          environment._id,
          extend(commentPayload, {
            environment: environment.name,
          })
        )
      )
      .then(() => $q.all(ids.map((incidentId) => refreshComments(incidentId))));
  }

  function refreshComments(incidentId) {
    getCommentsCount(incidentId, true).then(subscribers.fireStoreUpdated);
  }
}
