import get from 'lodash/get';
import partition from 'lodash/partition';
import { firstBy } from 'thenby';
import { organizationTypes, path, nonShowTypes } from './constants';
import { path as organizationPath } from '../../user/constants';
import { path as environmentsPath } from '../../modules/settings/roles_management/roles_list/constants';

const selectors = {
  getUser: (state) => get(state, path).user,
  getOrganization: (state) => get(state, organizationPath).organization,
  getOrganizationType: (state) => {
    const orgType = get(state, [organizationPath, 'organization', 'config', 'type']);

    if (orgType && !nonShowTypes.includes(orgType)) {
      const validateOrgType = Object.keys(organizationTypes).find(
        (arrOrgType) => arrOrgType === orgType
      );

      if (validateOrgType) {
        return organizationTypes[validateOrgType];
      }
    }

    return {};
  },
  getCustomizations: (state) => get(state, path).customizations,
  getPermissions: (state) => get(state, environmentsPath).permissions,
  getEnvironments: (state) => get(state, environmentsPath).environments,
  getDefaultEnvironment: (state) => {
    const { environments } = get(state, environmentsPath);

    const customEnvironmentsPath = [path, 'customizations', 'environments'].join('.');

    const customEnvironments = get(state, customEnvironmentsPath);

    const [[allEnv], nonAllEnvironments] = partition(
      environments,
      (env) => env.name.toLowerCase() === 'all'
    );

    if (!customEnvironments) {
      return allEnv;
    }

    const favoriteIds = get(customEnvironments, ['navigation_list', 'favorites']);

    const environmentsByName = nonAllEnvironments
      .sort(firstBy('name', { ignoreCase: true }))
      .concat(allEnv ? [allEnv] : []);

    const favoriteEnvironments = environmentsByName.filter((env) => favoriteIds.includes(env._id));

    return favoriteEnvironments[0] || allEnv || environmentsByName[0];
  },
};

export default selectors;
