import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
angular.module('bigpanda').controller('ShareActivityEventController', ShareActivityEventController);

function ShareActivityEventController($scope, TargetSystemsStore, SharesStore) {
  SharesStore.getShares($scope.event._source.incident_id).then((shares) => {
    const share = find(shares, { _id: $scope.event._source.params.escalationId });

    const shareOptId = get(share, 'escalation_options.share_options.id');
    const shareOptDisplayName = get(share, 'escalation_options.share_options.display');
    const shareOptMessage = get(share, 'escalation_options.message');
    const { target_system_id: shareTargetId } = share;

    TargetSystemsStore.getById(
      shareTargetId,
      shareOptId,
      shareOptDisplayName,
      shareOptMessage
    ).then((targetSystem) => {
      $scope.event.label = targetSystem.label;
      formatEscalatees(share);
      formatLink(targetSystem, share);
    });
  });

  function formatEscalatees(share) {
    if (share.escalatees) {
      const escalateesNames = map(share.escalatees, 'name');
      $scope.event.contacts = escalateesNames.join(', ');
    }
  }

  function formatLink(targetSystem, share) {
    if (targetSystem.getLink) {
      $scope.event.link = targetSystem.getLink(share);
    }
  }
}
