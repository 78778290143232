angular.module('bigpanda').filter('timeframePickerObjectFilter', timeframePickerObjectFilter);

function timeframePickerObjectFilter() {
  return filter;

  function filter(dateInfo, from, to) {
    let timeFrameObject = {
      timeframe: { type: 'relative', interval: '-7d' },
      title: 'Last 7 Days',
      getStartMoment: (end) => moment(end || undefined).subtract(7, 'days'),
    };

    switch (dateInfo) {
      case '-1h':
        timeFrameObject.timeframe.type = 'relative';
        timeFrameObject.timeframe.interval = '-1h';
        timeFrameObject.title = 'Last Hour';
        timeFrameObject.getStartMoment = (end) => moment(end || undefined).subtract(1, 'hours');
        break;
      case '-2h':
        timeFrameObject.timeframe.type = 'relative';
        timeFrameObject.timeframe.interval = '-2h';
        timeFrameObject.title = 'Last 2 Hours';
        timeFrameObject.getStartMoment = (end) => moment(end || undefined).subtract(2, 'hours');
        break;
      case '-6h':
        timeFrameObject.timeframe.type = 'relative';
        timeFrameObject.timeframe.interval = '-6h';
        timeFrameObject.title = 'Last 6 Hours';
        timeFrameObject.getStartMoment = (end) => moment(end || undefined).subtract(6, 'hours');
        break;
      case '-24h':
        timeFrameObject.timeframe.type = 'relative';
        timeFrameObject.timeframe.interval = '-24h';
        timeFrameObject.title = 'Last 24 Hours';
        timeFrameObject.getStartMoment = (end) => moment(end || undefined).subtract(24, 'hours');
        break;
      case '-7d':
        timeFrameObject.timeframe.type = 'relative';
        timeFrameObject.timeframe.interval = '-7d';
        timeFrameObject.title = 'Last 7 Days';
        timeFrameObject.getStartMoment = (end) => moment(end || undefined).subtract(7, 'days');
        break;
      case 'custom':
        timeFrameObject.timeframe.type = 'absolute';
        timeFrameObject.timeframe.interval = 'custom';
        timeFrameObject.title = 'Pick Date Range...';
        timeFrameObject.iconClass = 'bp-icon-calendar';
        timeFrameObject.timeframe.start = new Date(parseInt(from));

        if (to) {
          timeFrameObject.timeframe.end = new Date(parseInt(to));
        }
        break;
      default:
        return timeFrameObject;
    }
    return timeFrameObject;
  }
}
