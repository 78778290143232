import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import cn from 'classnames';
import { Field } from 'react-final-form';
import { BamBpflInput } from '@bp/bam';
import styles from './FormulaValue.scss';
import { validateFormulaValueField } from '../../../IncidentLabelForm.utils';

const url = 'https://docs.bigpanda.io/docs/bigpanda-formula-language';

class FormulaValue extends React.PureComponent {
  render() {
    const {
      defaultValue,
      placeholder,
      hint,
      name,
      title,
      tooltip,
      error,
      tooltipWidth,
    } = this.props;
    const errorClass = cn({
      [styles['field-error']]: error,
    });
    return (
      <React.Fragment>
        <Field
          name={name}
          defaultValue={defaultValue}
          title={title}
          hint={hint}
          component={BamBpflInput}
          aria-label={placeholder}
          placeholder={placeholder}
          interactiveTooltip
          customRightTitle={
            <div>
              <span className={styles['see-docs-text']}>Need Formula help? </span>
              <a className={styles['see-docs-link']} href={url} rel="noreferrer" target="_blank">
                See docs
              </a>
            </div>
          }
          tooltip={tooltip}
          fixedFont
          validateOnBlur
          className={errorClass}
          tooltipStyle={{
            height: '360px',
            maxWidth: tooltipWidth,
            width: tooltipWidth,
          }}
          subscription={{ value: true, error: true, touched: true, active: true }}
        />
        {error && <div className={styles['field-error-message']}>{error}</div>}
      </React.Fragment>
    );
  }
}

FormulaValue.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  title: PropTypes.PropTypes.node.isRequired,
  placeholder: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  tooltip: PropTypes.func.isRequired,
  tooltipWidth: PropTypes.string.isRequired,
  error: PropTypes.string,
};

FormulaValue.defaultProps = {
  error: undefined,
};

export default hot(module)(FormulaValue);
