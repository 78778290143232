import { BamForm } from '@bp/bam';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import { tagPropType } from '../../propTypes';
import selectors from '../../selectors';
import { moveItem } from '../../utils';
import TagsList from './TagsList';
import styles from './AlertTagOrdering.scss';
import { EXEC_ORDER_ASC_SORT_MODE } from '../../alert_enrichment_list/constants';

class AlertTagOrderingForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags,
    };
  }

  static getDerivedStateFromProps({ draggedItems, setDraggedItems }, { tags }) {
    if (!draggedItems) return null;
    const { source, destination } = draggedItems;
    const reorderedTags = moveItem(tags, source.index, destination.index);
    setDraggedItems(undefined);
    return { tags: reorderedTags };
  }

  onSubmit = () => {
    const { updateTagOrdering, closeModal } = this.props;
    const { tags } = this.state;
    updateTagOrdering(tags);
    closeModal();
  };

  handleClose = () => {
    const { closeModal } = this.props;
    this.state.tags = this.props.tags;
    closeModal();
  };

  moveTag = (tagIndex, top) => {
    const { tags } = this.state;
    const to = top ? 0 : tags.length;
    this.setState({ tags: moveItem(tags, tagIndex, to) });
  };

  render() {
    const { tags } = this.state;
    return (
      <div className={styles['alert-tag-ordering']}>
        <BamForm
          id="AlertTagOrderingForm"
          onSubmit={this.onSubmit}
          scrollable
          positiveButton={{
            text: 'Save',
          }}
          closeButton={{
            text: 'Cancel',
            onClick: this.handleClose,
          }}
          mutators={{
            moveTag: ([tagIndex, top]) => this.moveTag(tagIndex, top),
          }}
        >
          <div className={styles['alert-tag-ordering-explanation']}>
            Define the execution order by moving the tags to the desired location.
          </div>
          <hr className={styles['alert-tag-ordering-divider']} />
          <TagsList tags={tags} />
        </BamForm>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  draggedItems: selectors.getDraggedItems(state),
  tags: selectors.getTags(state, {
    shouldFilterByText: false,
    shouldFilterByDropDownFilters: false,
    shouldSort: true,
    sortBy: EXEC_ORDER_ASC_SORT_MODE,
  }),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDraggedItems: (payload) => dispatch(actions.setDraggedItems(payload)),
    updateTagOrdering: (tags) => dispatch(actions.updateTagsOrder(tags)),
  };
};

AlertTagOrderingForm.defaultProps = {
  tags: undefined,
};

AlertTagOrderingForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(tagPropType),
  updateTagOrdering: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertTagOrderingForm);
