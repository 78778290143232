import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import highlightedExtractionTagParts from 'common/utils/highlightedExtractionTagParts';
import styles from './custom_tag_preview.scss';

const isUrl = (template) => {
  const urlRegex = /^((https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6}))|^(https?:\/\/)/;
  return urlRegex.test(template);
};

const HIGHLIGHT_DIVIDER = 'highlight_divider';

class CustomTagPreviewItems extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { validForPreview, pattern, patternValid, items } = nextProps;
    return (
      validForPreview &&
      (!pattern || patternValid) &&
      (items !== this.props.items || pattern !== this.props.pattern)
    );
  }

  highlightedCompositionTagParts(text) {
    const { template } = this.props;
    const allTagsRegex = /(\$\{.*?\})/g; // regex that matches multiple template groups.
    const splitTag = template.split(allTagsRegex);

    // filter out non existing tag parts
    // replace tags with matching object value or the original template
    return splitTag
      .filter((tagPart) => !!tagPart)
      .map((tagPart) =>
        tagPart.replace(/\$\{((?!exact)[^}]+)\}/, (index, key) => text[key] || tagPart)
      )
      .map((tagPart) =>
        tagPart.replace(/\$\{exact\(([^)]+)\)\}/, (index, key) => text[key] || tagPart)
      );
  }

  renderExtractionItems() {
    const { items, pattern } = this.props;
    return items.map((item) => {
      const parts = highlightedExtractionTagParts(Array.isArray(item) ? item[0] : item, pattern);
      return (
        <li key={`item_${item}`} className={styles.item}>
          <pre>
            {parts.map((part, idx) => (
              <span
                key={`part_${part}`}
                data-api-id={
                  idx === 1
                    ? `CustomTagPreviewItems__extraction-highlighted-part___${part}`
                    : undefined
                }
                className={classnames({ [styles.highlighted]: idx === 1 })}
              >
                {part}
              </span>
            ))}
          </pre>
        </li>
      );
    });
  }

  renderCompositionItems() {
    /* eslint-disable react/jsx-no-target-blank */
    const { items, template } = this.props;
    if (!items || !items.length) {
      if (isUrl(template)) {
        return (
          <li className={styles.item}>
            <a target="_blank" href={template} key={`link_${template}`}>
              <pre>{template}</pre>
            </a>
          </li>
        );
      }
      return (
        <li className={styles.item} key={`li_${template}`}>
          <pre>{template}</pre>
        </li>
      );
    }
    return items.map((item) => {
      const text = Array.isArray(item) ? item[0] : item;
      const parts = this.highlightedCompositionTagParts(Array.isArray(item) ? item[0] : item);
      const value = parts.join('');
      if (isUrl(template)) {
        return (
          <li className={styles.item} key={`li_${value}`}>
            <a target="_blank" href={value} key={`link_${value}`}>
              {getHighlightedListItemText(parts)}
            </a>
          </li>
        );
      }
      return (
        <li key={`li_${value}`} className={styles.item}>
          {getHighlightedListItemText(parts)}
        </li>
      );

      function getHighlightedListItemText(textParts) {
        return (
          <pre>
            {textParts.map((textPart) => {
              const shouldHighlight = Object.values(text).indexOf(textPart) > -1;
              return (
                <span
                  key={`part_${textPart}`}
                  data-api-id={
                    shouldHighlight
                      ? `CustomTagPreviewItems__composition-highlighted-part___${textPart}`
                      : undefined
                  }
                  className={classnames({ [styles.highlighted]: shouldHighlight })}
                >
                  {textPart}
                </span>
              );
            })}
          </pre>
        );
      }
    });
    /* eslint-enable react/jsx-no-target-blank */
  }

  render() {
    const { type } = this.props;
    return (
      <ul className={styles['custom-tag-preview']}>
        {type === 'extraction' && this.renderExtractionItems()}
        {type === 'composition' && this.renderCompositionItems()}
      </ul>
    );
  }
}

CustomTagPreviewItems.propTypes = {
  type: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])).isRequired,
  validForPreview: PropTypes.bool.isRequired,
  patternValid: PropTypes.bool.isRequired,
  template: PropTypes.string,
  pattern: PropTypes.string,
};

CustomTagPreviewItems.defaultProps = {
  template: null,
  pattern: null,
};

export default hot(module)(CustomTagPreviewItems);
