import {
  Button, ButtonVariants, PreviewIcon, Tooltip, TooltipVariants,
} from '@bp/kung-fu';
import React from 'react';

interface IProps {
  canPreview: boolean;
  isLoading: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>, callback?: () => void) => void;
  showRefreshNotice: boolean;
  isPreviewed: boolean;
}

export function PreviewButton({
  canPreview, isLoading, showRefreshNotice, onClick, isPreviewed,
}: IProps): JSX.Element {
  return (
    <Tooltip
      isAlwaysVisible
      isActive={!!showRefreshNotice}
      text="Definition changed"
      variant={TooltipVariants.NOTICE}
      placement="top-end"
    >
      <Button
        icon={<PreviewIcon color={(p): string => isPreviewed && p.theme.bp_blue} />}
        disabled={!canPreview || isLoading}
        variant={ButtonVariants.ACTION_CONTAINER}
        size="medium"
        onClick={onClick}
        tooltipProps={{
          isActive: true,
          placement: 'right',
          text: !canPreview
            ? 'Fill item details in order to generate preview'
            : 'Preview tag values without running the execution plan',
        }}
      >
        Preview
      </Button>
    </Tooltip>
  );
}

export default PreviewButton;
