import './maintenance_plans.scss';

import { withReactQueryClient } from '@bp/data-access';
import { darkTheme } from '@bp/kung-fu';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { selectors as alertTagsSelectors } from '../../../../../app/react/common/alert_tags';
import { loadAlertTags as loadAlertTagsAction } from '../../../../../app/react/common/alert_tags/actions';
import { loadEnrichedUsers } from '../../../../../app/react/common/enriched_users/actions';
import usersSelectors from '../../../../../app/react/common/users/selectors';
import SettingsListScreen from '../../../../../app/react/layout/settings/SettingsListScreen';
import provideStore from '../../../../../app/react/utils/provideStore';
import MaintenanceModal from '../../maintenance_plan_info/components/modal/MaintenanceModal';
import { AlertTag } from '../../types/AlertTag';
import { MaintenancePlan } from '../../types/MaintenancePlan';
import { User } from '../../types/User';
import actions from '../actions';
import selectors from '../selectors';
import ListItem from './list/ListItem';

export function MaintenancePlans(): JSX.Element {
  const debounceSearch = useCallback(debounce(
    ({ active, search }) => dispatch(actions.loadPlans({
      active, search, refresh: true,
    })),
    400,
  ), []);

  const [filterText, setFilterText] = useState('');

  const plans: MaintenancePlan[] = useSelector((state) => selectors.getPlans(state, filterText));
  const tags: AlertTag[] = useSelector(alertTagsSelectors.getDisplayAlertTags);
  const users: User = useSelector(usersSelectors.getUsers);

  useEffect(() => {
    if (!plans) {
      dispatch(actions.loadInitialPlans({ active: showDonePlans }));
    }
    if (!users) {
      dispatch(loadEnrichedUsers());
    }
    if (!tags) {
      dispatch(loadAlertTagsAction());
    }
  }, []);

  const dispatch = useDispatch();

  const isLoading: boolean = useSelector(selectors.isLoading);
  const link: string = useSelector(selectors.getLink);
  const isPaginating: boolean = useSelector(selectors.isPaginating);
  const showDonePlans: boolean = useSelector(selectors.showDonePlans);

  const onLoadNext = (e: { stopIndex: number }): void => {
    const loadTreshhold = 20;
    const plansLength = plans.length;
    const shouldLoadMore = !isPaginating;
    if (e.stopIndex + loadTreshhold > plansLength && shouldLoadMore && link) {
      setTimeout(
        () => dispatch(actions.loadPlans({ link, active: showDonePlans, search: filterText })),
        0,
      );
    }
  };

  const checkedDonePlans = (checked: boolean): void => {
    dispatch(actions.loadInitialPlans({ active: !checked, search: filterText }));
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <SettingsListScreen
        loading={isLoading || !users || !tags}
        items={plans}
        noItemsTitle="No Plans Added Yet"
        createItemProps={{
          modal: {
            title: 'Create a New Plan',
            titleIcon: 'bp-icon-plus-icon',
            component: MaintenanceModal,
            showDonePlans,
          },
          itemName: 'plan',
          buttonText: 'New Plan',
        }}
        paginationLoader={isPaginating}
        listItem={ListItem}
        listItemProp="plan"
        permissionName="plans"
        headerName="Planned Maintenance"
        filterPlaceholderText="Find Plans..."
        sortHeaderLink="https://docs.bigpanda.io/docs/rbac-role-based-access-control" // TODO
        updateFilterText={(search: string): void => {
          setFilterText(search);
          debounceSearch({ active: showDonePlans, search });
        }}
        filterText={filterText}
        hideSorting
        checkBox={{
          filter: checkedDonePlans,
          label: 'Show "Done" Plans',
        }}
        loadNext={onLoadNext}
      />
    </ThemeProvider>
  );
}

export default provideStore(withReactQueryClient(MaintenancePlans));
