import reduxWebsocket from '@giantmachines/redux-websocket';
import config from 'common/config';
import * as auth from 'common/auth';

require('rxjs');
const { createEpicMiddleware } = require('redux-observable');
const { batchDispatchMiddleware } = require('redux-batched-actions');
const initSubscriber = require('redux-subscriber').default;
const initInterceptors = require('common/utils/http/interceptors').default;
const routes = require('../../common/routing/routes').default;
const { rootReducer, rootEpic } = require('../../react');
const { loadUserData } = require('../../react/user/actions');

const reduxWebsocketMiddlewareForEnvironments = reduxWebsocket({
  prefix: config.webSocketsPrefix,
  reconnectOnClose: true,
});

angular.module('bigpanda').run(($ngRedux, $transitions, ModalService) => {
  // Redux actions to be performed on init are dispatched here
  const headers = {
    Authorization: `Bearer ${auth.getAuthTokenFromQuery() || auth.getAuthTokenFromCookie()}`,
  };
  $ngRedux.dispatch(loadUserData(headers));
  initSubscriber($ngRedux);

  $transitions.onExit({}, () => {
    ModalService.hide();
  });
});

angular
  .module('bigpanda')
  .config(($urlRouterProvider, $stateProvider, $ngReduxProvider, $compileProvider) => {
    initInterceptors();
    const epicMiddleware = createEpicMiddleware(rootEpic);
    $ngReduxProvider.createStoreWith(
      rootReducer,
      [epicMiddleware, batchDispatchMiddleware, reduxWebsocketMiddlewareForEnvironments],
      window.__REDUX_DEVTOOLS_EXTENSION__ && [window.__REDUX_DEVTOOLS_EXTENSION__()]
    );
    $urlRouterProvider.otherwise('/app/default');

    $compileProvider.commentDirectivesEnabled(false);

    /**
     * #############################
     * # APPLICATION BOOTSTRAPPING #
     * #############################
     */
    $stateProvider.state('bootstrap', routes.bootstrap);
    $stateProvider.state('app', routes.app);
    $stateProvider.state('app.default', routes.appDefault);

    /**
     * ###################################
     * # SPECIFIC PAGES AND INNER STATES #
     * ###################################
     */
    $stateProvider.state('app.welcome', routes.appWelcome);
    $stateProvider.state('app.welcome.v2', routes.appWelcomeV2);
    $stateProvider.state('app.change_password', routes.appChangePassword);
    $stateProvider.state('app.change_password_app', routes.appChangePasswordApp);
    $stateProvider.state('app.fullpage', routes.appFullpage);
    $stateProvider.state('app.fullpage.installIntegration', routes.appFullpageInstallIntegration);
    $stateProvider.state('app.integrations', routes.appIntegrations);
    $stateProvider.state(
      'app.integrations.integration-info',
      routes.appIntegrationsIntegrationInfo
    );
    $stateProvider.state(
      'app.integrations.integration-info-troubleshooting',
      routes.appIntegrationsIntegrationInfoTroubleshooting
    );
    $stateProvider.state('app.integrations.selection', routes.appIntegrationsSelection);
    $stateProvider.state('app.integrations.welcome', routes.appIntegrationsWelcome);
    $stateProvider.state('app.integrationInfo', routes.appIntegrationInfo);
    $stateProvider.state('app.integrationInfo.instructions', routes.appIntegrationInfoInstructions);
    $stateProvider.state(
      'app.integrationInfo.instructions.guide',
      routes.appIntegrationInfoInstructionsGuide
    );
    $stateProvider.state('app.mobile', routes.appMobile);
    $stateProvider.state('preview', routes.preview);
    $stateProvider.state('preview.incident', routes.previewIncident);
    $stateProvider.state('app.settings', routes.appSettings);
    $stateProvider.state('app.settings.personal', routes.appSettingsPersonal);
    $stateProvider.state('app.settings.roles', routes.appSettingsRoles);
    $stateProvider.state('app.settings.roles.info', routes.appSettingsRolesInfo);
    $stateProvider.state('app.settings.user_management', routes.appSettingsUsers);
    $stateProvider.state('app.settings.user_management.info', routes.appSettingsUsersInfo);
    $stateProvider.state('app.settings.api_keys', routes.appSettingsApiKeys);
    $stateProvider.state('app.settings.api_keys.info', routes.appSettingsApiKeysInfo);

    $stateProvider.state('app.settings.planned_maintenance', routes.appSettingsPlannedMaintenance);
    $stateProvider.state(
      'app.settings.planned_maintenance.info',
      routes.appSettingsPlannedMaintenanceInfo
    );

    $stateProvider.state('app.settings.integrations', routes.appSettingsIntegrationsList);
    $stateProvider.state('app.settings.integrations.info', routes.appSettingsIntegrationsInfo);
    $stateProvider.state(
      'app.settings.integrations.selection',
      routes.appSettingsIntegrationsNewIntegration
    );

    $stateProvider.state('app.settings.quota', routes.appSettingsQuota);
    $stateProvider.state('app.settings.tags', routes.appSettingsTags);
    $stateProvider.state('app.settings.tags.new', routes.appSettingsTagsNew);
    $stateProvider.state('app.settings.tags.info', routes.appSettingsTagsInfo);
    $stateProvider.state('app.settings.tags.info.edit', routes.appSettingsTagsInfoEdit);
    $stateProvider.state('app.settings.tags.info.duplicate', routes.appSettingsTagsInfoDuplicate);
    $stateProvider.state('app.settings.audit_logs', routes.appSettingsAuditLogs);

    $stateProvider.state('app.settings.alert_enrichment', routes.appSettingsAlertEnrichment);
    $stateProvider.state(
      'app.settings.alert_enrichment.info',
      routes.appSettingsAlertEnrichmentInfo
    );

    $stateProvider.state('app.settings.alert_filtering', routes.appSettingsAlertFiltering);
    $stateProvider.state('app.settings.alert_filtering.info', routes.appSettingsAlertFilteringInfo);

    $stateProvider.state('app.settings.customtags', routes.appSettingsCustomTagsReact);
    $stateProvider.state('app.settings.customtags.info', routes.appSettingsCustomTagsInfoReact);

    $stateProvider.state(
      'app.settings.incidentenrichment',
      routes.appSettingsIncidentEnrichmentReact
    );
    $stateProvider.state(
      'app.settings.incidentenrichment.info',
      routes.appSettingsIncidentEnrichmentInfoReact
    );

    $stateProvider.state('app.settings.alertcorrelation', routes.appSettingsAlertCorrelationReact);
    $stateProvider.state(
      'app.settings.alertcorrelation.info',
      routes.appSettingsAlertCorrelationInfoReact
    );

    $stateProvider.state('app.settings.correlations', routes.appSettingsCorrelations);
    $stateProvider.state('app.settings.correlations.new', routes.appSettingsCorrelationsNew);
    $stateProvider.state('app.settings.correlations.info', routes.appSettingsCorrelationsInfo);
    $stateProvider.state(
      'app.settings.correlations.info.edit',
      routes.appSettingsCorrelationsInfoEdit
    );
    $stateProvider.state(
      'app.settings.correlations.info.duplicate',
      routes.appSettingsCorrelationsInfoDuplicate
    );
    $stateProvider.state('app.settings.sso', routes.appSettingsSso);
    $stateProvider.state('app.settings.rules', routes.appSettingsRules);
    $stateProvider.state('app.settings.rules.new', routes.appSettingsRulesNew);
    $stateProvider.state('app.settings.rules.view', routes.appSettingsRulesView);
    $stateProvider.state('app.environments', routes.appEnvironments);
    $stateProvider.state('app.overview', routes.appOverview);
    $stateProvider.state('app.overview.incidents', routes.appOverviewIncidents);
    $stateProvider.state('app.overview.incidents.list', routes.appOverviewIncidentsList);
    $stateProvider.state('app.overview.incidents.validate', routes.appOverviewIncidentsValidate);
    $stateProvider.state(
      'app.overview.incidents.incident-info',
      routes.appOverviewIncidentsIncidentInfo
    );
    $stateProvider.state(
      'app.overview.incidents.incident-info.lifecycle',
      routes.appOverviewIncidentsIncidentInfoLifecycle
    );
    $stateProvider.state(
      'app.overview.incidents.incident-info.share',
      routes.appOverviewIncidentsIncidentInfoShare
    );
    $stateProvider.state(
      'app.overview.incidents.incident-info.shares-list',
      routes.appOverviewIncidentsIncidentInfoSharesList
    );
    $stateProvider.state(
      'app.overview.incidents.incident-info.comment',
      routes.appOverviewIncidentsIncidentInfoComment
    );
    $stateProvider.state(
      'app.overview.incidents.incident-info.snooze',
      routes.appOverviewIncidentsIncidentInfoSnooze
    );
    $stateProvider.state(
      'app.overview.incidents.incident-info.resolve',
      routes.appOverviewIncidentsIncidentInfoResolve
    );
    $stateProvider.state('app.investigator', routes.appInvestigator);
    $stateProvider.state('app.investigator.shares-list', routes.appInvestigatorSharesList);
    $stateProvider.state('app.investigator.comment', routes.appInvestigatorComment);
    $stateProvider.state('app.investigator.snooze', routes.appInvestigatorSnooze);
    $stateProvider.state('app.reports', routes.appReports);
    $stateProvider.state('app.reports.detail', routes.appReportsDetail);
    $stateProvider.state('app.reports.detail.show-report', routes.appReportsDetailShowReport);
    $stateProvider.state('preview.report', routes.previewReport);
    $stateProvider.state('app.dashboards', routes.appDashboards);
    $stateProvider.state('app.dashboards.new', routes.appDashboardsNew);
    $stateProvider.state('app.dashboards.display', routes.appDashboardsDisplay);
    $stateProvider.state('app.dashboards.display.edit', routes.appDashboardsDisplayEdit);
    $stateProvider.state('app.dashboards.display.duplicate', routes.appDashboardsDisplayDuplicate);
    $stateProvider.state('app.dashboards.display.delete', routes.appDashboardsDisplayDelete);
    $stateProvider.state('app.unified-analytics', routes.appAnalyticsView);
    $stateProvider.state('app.topology', routes.appTopology);
  });
