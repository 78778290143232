require('./config/activity.events.config.module');
require('./config/integrations.faq.config.module');
require('./config/types.faq.config.module');
require('./config/config.provider.module');

module.exports = angular.module(
  'bigpanda.Config', // eslint-disable-line angular/module-setter
  [
    'bigpanda.TypesFaqConfigProvider',
    'bigpanda.IntegrationsFaqConfigProvider',
    'bigpanda.ConfigProvider',
    'bigpanda.ActivityEventsConfigProvider',
  ]
);
