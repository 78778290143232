angular.module('bigpanda').directive('noPermissions', noPermissions);

function noPermissions() {
  return {
    templateUrl: 'no_permissions/no_permissions',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller() {}
}
