angular.module('bigpanda').constant('SORTING', {
  options: {
    lastChange: 'last_change',
    status: 'status',
    start: 'start',
    alerts: 'alerts',
    duration: 'duration',
    label_priority: 'label_priority',
  },
  titles: {
    last_change: 'Changed:',
    status: 'Changed:',
    start: 'Created:',
    alerts: 'Changed:',
    duration: 'Duration:',
    label_priority: 'Priority:',
  },
});
