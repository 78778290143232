import React from 'react';

import { RowText } from '../../RowDetails.style';
import { RowDetailsSectionProps } from '../../types';
import {
  Pre,
  Table,
  Td,
  Th,
} from './RowDetailsChanges.style';

interface Change {
  key: string;
  new_value: unknown;
  old_value: unknown;
}

function RowDetailsChanges({ rowData }: RowDetailsSectionProps): JSX.Element {
  const changes = rowData.object.changes as Change[];

  if (!changes || !changes.length) return null;

  return (
    <Table>
      <thead>
        <Th>Key</Th>
        <Th>Old value</Th>
        <Th>New value</Th>
      </thead>
      <tbody>
        {
          changes.map(({ key, old_value, new_value }) => (
            <tr>
              <Td><RowText>{key}</RowText></Td>
              <Td><Pre>{JSON.stringify(old_value, null, 2)}</Pre></Td>
              <Td><Pre>{JSON.stringify(new_value, null, 2)}</Pre></Td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}

export default RowDetailsChanges;
