import actionTypes from './actionTypes';

export function loadWelcomeUserData() {
  return { type: actionTypes.LOAD_WELCOME_USER_DATA };
}

export function loadWelcomeUserDataSuccess(payload) {
  return { type: actionTypes.LOAD_WELCOME_USER_DATA_SUCCESS, payload: payload };
}

export function loadWelcomeUserDataFailure() {
  return { type: actionTypes.LOAD_WELCOME_USER_DATA_FAILURE };
}

export function updateUserNamePassword(payload) {
  return { type: actionTypes.UPDATE_USER_NAME_PASSWORD, payload: payload };
}

export function updateUserNamePasswordSuccess() {
  return { type: actionTypes.UPDATE_USER_NAME_PASSWORD_SUCCESS };
}

export function updateUserNamePasswordFailure() {
  return { type: actionTypes.UPDATE_USER_NAME_PASSWORD_FAILURE };
}
