import { react2angular } from 'react2angular';
import CorrelationPatternsManagement from './components/CorrelationPatternsManagement';
import actions from '../actions';

const routes = {};
const template = `<section class="bp-master-detail-view">
    <correlation-patterns-management class="left-panel" store="store"></correlation-patterns-management>
    <div class="spacer"></div>
    <div ui-view="master-detail-info" class="master-detail-info"></div>
  </section>`;

routes.appSettingsAlertCorrelationReact = {
  url: '/alert-correlation?tour',
  views: {
    'settings-page-view': {
      template: template,
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
    'master-detail-info@app.settings.alertcorrelation': {
      template: '<correlation-pattern-info class="react-settings-info" store="store" />',
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
  },
  params: {
    tour: null,
  },
  onEnter: ($transitions, pubSubService) => {
    'ngInject';

    $transitions.onSuccess({ to: 'app.settings.alertcorrelation' }, () => {
      // TODO: change this to correlation rules once we move to react implementation only
      pubSubService.broadcast('settingsPageChanged', 'correlationRules');
    });
  },
  onExit: ($ngRedux) => {
    'ngInject';

    $ngRedux.dispatch(actions.routeExit());
  },
};

angular
  .module('bigpanda')
  .component('correlationPatternsManagement', react2angular(CorrelationPatternsManagement));

export default routes;
