import filter from 'lodash/filter';
import extend from 'lodash/extend';
const tagEditDefaults = {
  templateUrl: 'settings/correlation/custom_tags/popup/custom_tag_popup',
  controller: 'CustomTagPopupCtrl',
  controllerAs: 'popupVm',
  fullscreen: true,
  clickOutsideToClose: false,
  escapeToClose: false,
  onShowing(scope, element) {
    element.addClass('custom-tag-popup');
  },
};

function onEnterNew(
  ModalService,
  UserIntegrationsStore,
  UserIntegrationsUtils,
  TagsService,
  CorrelationConfigService
) {
  const options = extend({}, tagEditDefaults, {
    resolve: {
      resolvedTag() {
        return null;
      },
      resolvedConfigTags() {
        return CorrelationConfigService.getConfig().then((config) => config.transformations);
      },
      resolvedIntegrations() {
        return UserIntegrationsStore.getIntegrations().then((integrations) =>
          UserIntegrationsUtils.getIntegrationsForDisplay(filter(integrations, { type: 'alert' }))
        );
      },
      resolvedSourceTags() {
        return TagsService.getTags();
      },
    },
  });

  return CorrelationConfigService.checkForConfigChanges().then(() =>
    ModalService.showModal(options)
  );
}

function onEnterEdit(
  $stateParams,
  ModalService,
  UserIntegrationsStore,
  UserIntegrationsUtils,
  CustomTagsService,
  TagsService,
  CorrelationConfigService,
  CorrelationActions
) {
  const options = extend({}, tagEditDefaults, {
    resolve: {
      resolvedTag() {
        return CustomTagsService.getTagById($stateParams.tagId);
      },
      resolvedConfigTags() {
        return CorrelationConfigService.getConfig().then((config) => config.transformations);
      },
      resolvedIntegrations() {
        return UserIntegrationsStore.getIntegrations().then((integrations) =>
          UserIntegrationsUtils.getIntegrationsForDisplay(filter(integrations, { type: 'alert' }))
        );
      },
      resolvedSourceTags() {
        return TagsService.getTags();
      },
    },
  });

  return CorrelationConfigService.checkForConfigChanges()
    .then(() => CustomTagsService.getTagById($stateParams.tagId))
    .then((tag) => {
      if (tag) {
        ModalService.showModal(options);
      } else {
        CorrelationActions.itemDeletedNotification(
          'The custom tag has been deleted by another user in your organisation',
          'tags'
        );
      }
    });
}

module.exports = { onEnterNew, onEnterEdit };
