angular.module('bigpanda').directive('environmentMenu', environmentMenu);

function environmentMenu() {
  return {
    scope: {
      environment: '<',
      favoriteEnvironments: '<',
    },
    restrict: 'E',
    templateUrl: 'overview/environments/menu/environment_menu',
    controller: 'EnvironmentMenuCtrl',
    controllerAs: 'vm',
  };
}
