/* eslint react/prop-types: 0 */
import { withReactQueryClient } from '@bp/data-access';
import React from 'react';
import OrganizationDropdown from './OrganizationDropdown';
import OrgStatusBadge from './OrgStatusBadge';
import { useGetUserOrganizations } from './useGetUserOrganizations';

const OrganizationMenu = ({ orgName, orgColor, orgType, displayName }) => {
  const { organizations, isLoading, isFetching, isMultiOrg } = useGetUserOrganizations();
  if (isLoading || isFetching) {
    return null;
  }

  if (isMultiOrg) {
    return (
      <OrganizationDropdown
        organizations={organizations}
        orgName={orgName}
        orgColor={orgColor}
        displayName={displayName}
      />
    );
  }
  if (orgType) {
    return <OrgStatusBadge orgType={orgType} orgName={orgName} orgColor={orgColor} />;
  }
  return null;
};

export default withReactQueryClient(OrganizationMenu);
