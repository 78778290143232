angular.module('bp.widgets').directive('bpGenericListItem', bpGenericListItem);

function bpGenericListItem() {
  return {
    restrict: 'E',
    scope: {
      status: '<',
      buttons: '<',
      timestamp: '<',
      timestampTitle: '<',
      item: '<',
      getButtonsList: '=',
    },
    transclude: true,
    templateUrl: 'widgets/bp_generic_list_item/bp_generic_list_item',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller() {
    const vm = this;

    vm.$onInit = updateButtonList;
    vm.$onChanges = updateButtonList;

    function updateButtonList() {
      vm.buttonsList = angular.isFunction(vm.getButtonsList)
        ? vm.getButtonsList(vm.item)
        : vm.buttons;
    }
  }
}
