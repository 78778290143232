import {
  AllSizes, CHBox, darkTheme, Spinner,
} from '@bp/kung-fu';
import React, { useLayoutEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';

const Wrapper = styled(CHBox)`
width: 100%;
height: 100%;
position: relative;
`;

function AnalyticsView(): JSX.Element {
  useLayoutEffect(() => {
    window.location.replace('/v2/unified-analytics');
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <Wrapper>
        <Spinner size={AllSizes.LARGE} />
      </Wrapper>
    </ThemeProvider>
  );
}

export default AnalyticsView;
