import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import autobind from 'class-autobind';
import { BamModal } from '@bp/bam';
import { loadUsers } from 'react/common/users/actions';
import { selectors as usersSelectors } from 'react/common/users';
import actions from '../../actions';
import selectors from '../../selectors';
import RoleForm from '../form/RoleForm';
import '../roles_management.scss';
import { ENVIRONMENT_PERMISSIONS } from '../../constants';

class RoleModal extends React.Component {
  constructor(props) {
    super(props);

    autobind(this, RoleModal.prototype);
  }

  getEnvironmentPermissions() {
    const globalEnvironmentPermissions = ENVIRONMENT_PERMISSIONS.map((item) => ({
      key: item,
      text: item,
      value: item,
    }));
    const existingEnvironmentPermissions = this.props.permissionsAndEnvironments.environments.reduce(
      (acc, env) => [
        ...acc,
        {
          key: `${capitalize(env.name)}_Incident_Actions`,
          text: `${capitalize(env.name)}_Incident_Actions`,
          value: `${env._id}_Incident_Actions`,
        },
        {
          key: `${capitalize(env.name)}_Read`,
          text: `${capitalize(env.name)}_Read`,
          value: `${env._id}_Read`,
        },
      ],
      []
    );

    const concattedEnvironmentOptions = [
      ...globalEnvironmentPermissions,
      { isDivider: true },
      ...existingEnvironmentPermissions,
    ];
    return concattedEnvironmentOptions;
  }

  handleClose = () => {
    this.ref.close();
  };

  render() {
    const {
      roles,
      permissionsAndEnvironments,
      users,
      currentRoleId,
      triggerProps,
      titleIcon,
      title,
    } = this.props;

    const onMountModal = () => {
      if (!roles) {
        this.props.loadRoles();
      }

      if (!permissionsAndEnvironments) {
        this.props.loadPermissionsAndEnvironments();
      }

      if (!users) {
        this.props.loadUsers();
      }
    };

    let positiveButtonAction;
    if (this.props.duplicate) {
      positiveButtonAction = 'Duplicate';
    } else if (currentRoleId) {
      positiveButtonAction = 'Update';
    } else {
      positiveButtonAction = 'Create';
    }

    return (
      <BamModal
        ref={(r) => {
          this.ref = r;
        }}
        trigger={<ModalTriggerButton {...triggerProps} />}
        titleIcon={titleIcon}
        title={title}
        onMount={onMountModal}
        formMode
        autoFocus
      >
        <RoleForm
          {...this.props}
          positiveButtonText={`${positiveButtonAction} Role`}
          close={this.handleClose}
        />
      </BamModal>
    );
  }
}

RoleModal.propTypes = {
  createOrUpdateRole: PropTypes.func.isRequired,
  currentRoleId: PropTypes.string,
  duplicate: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  permissionsAndEnvironments: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  loadRoles: PropTypes.func.isRequired,
  loadPermissionsAndEnvironments: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string,
  titleIcon: PropTypes.string.isRequired,
};

RoleModal.defaultProps = {
  currentRoleId: undefined,
  duplicate: false,
  users: undefined,
  roles: undefined,
  initialValues: undefined,
  permissionsAndEnvironments: undefined,
  title: '',
};

function mapStateToProps(state, ownProps) {
  return {
    roles: selectors.getRoles(state),
    initialValues: selectors.getInitialRoleValues(
      state,
      ownProps.currentRoleId,
      ownProps.duplicate
    ),
    permissionsAndEnvironments: selectors.getPermissionsAndEnvironments(state),
    users: usersSelectors.getUsers(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadRoles: () => dispatch(actions.loadRoles()),
    loadPermissionsAndEnvironments: () => {
      dispatch(actions.loadPermissions());
      dispatch(actions.loadEnvironments());
      dispatch(actions.loadPermissionsAndEnvironments());
    },
    loadUsers: () => dispatch(loadUsers()),
    createOrUpdateRole: (role) => dispatch(actions.createOrUpdateRole(role)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(RoleModal));
