import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import get from 'lodash/get';
import { HBox, Spinner } from '@bp/kung-fu';
import { BamNotificationStatusOptions, bamNotify } from '@bp/bam';
import { bp_blue } from '@bp/pastel/colors';
import IncidentAiWrapper from './incident-ai-wrapper';
import { AI_CAPACITY_REACHED, path } from '../../tags/constants';
import actions from '../../tags/actions';
import dic from './dictionary';

const DelayedWrapper = (props) => {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  const { aiTags, isReadOnly } = props;
  const hasAITags = !!aiTags && !!aiTags.length;

  if (!hasAITags && isReadOnly) return null;

  return (
    <HBox>
      {showComponent ? <IncidentAi {...props} /> : <Spinner color={bp_blue} size="small" />}
    </HBox>
  );
};

const IncidentAi = ({
  environmentId,
  incident: { id: incidentId },
  aiTags,
  createAiAnalysis,
  aiAnalysisError,
  isReadOnly,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerationEnabled, setIsGenerationEnabled] = useState(true);
  const toastId = useRef(null);

  const showFailureMessage = () => {
    bamNotify({
      message: [{ message: dic.ai_failed_to_generate_title }],
      status: BamNotificationStatusOptions.FAILURE,
    });
  };

  useEffect(() => {
    setIsLoading(false);
    clearTimeout(toastId.current);
  }, [aiTags]);

  useEffect(() => {
    if (aiAnalysisError) {
      if (aiAnalysisError !== AI_CAPACITY_REACHED) {
        showFailureMessage();
      }
      clearTimeout(toastId.current);
      setIsLoading(false);

      if (aiAnalysisError === AI_CAPACITY_REACHED) {
        setIsGenerationEnabled(false);
      }
    }
  }, [aiAnalysisError]);

  const onGenerate = async () => {
    setIsLoading(true);
    toastId.current = setTimeout(() => {
      setIsLoading(false);
      showFailureMessage();
    }, 40000);
    createAiAnalysis(environmentId, incidentId);
  };

  return (
    <IncidentAiWrapper
      aiTags={aiTags}
      isGenerationEnabled={isGenerationEnabled}
      isLoading={isLoading}
      onGenerate={onGenerate}
      isReadOnly={isReadOnly}
    />
  );
};

DelayedWrapper.propTypes = {
  aiTags: PropTypes.arrayOf(
    PropTypes.shape({
      tag_id: PropTypes.string,
      tag_value: PropTypes.string,
    })
  ),
  isReadOnly: PropTypes.bool,
};

IncidentAi.propTypes = {
  environmentId: PropTypes.string.isRequired,
  incident: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  isReadOnly: PropTypes.bool,
  aiAnalysisError: PropTypes.string,
  aiTags: PropTypes.arrayOf({
    tag_id: PropTypes.string,
    tag_value: PropTypes.string,
  }),
  createAiAnalysis: PropTypes.func.isRequired,
};

const selectors = {
  getIncidentTags: (state) => {
    return get(state, path).incidentTagsData || [];
  },
  getAiGenerationStatus: (state) => {
    return get(state, path).aiAnalysisError;
  },
};

function mapDispatchToProps(dispatch) {
  return {
    createAiAnalysis: (environmentId, incidentId) => {
      return dispatch(actions.createAiAnalysis({ environmentId, incidentId }));
    },
  };
}

function mapStateToProps(state) {
  return {
    incidentTags: selectors.getIncidentTags(state) || {},
    aiAnalysisError: selectors.getAiGenerationStatus(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(DelayedWrapper));
