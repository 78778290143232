import {
  AllSizes,
  Dot, HBox,
  Text,
} from '@bp/kung-fu';
import React from 'react';

import { AuditActionStatus } from '../../../../constants';
import { CustomColumnProps } from './types';

function ActionColumn({ cellData }: CustomColumnProps): JSX.Element {
  return (
    <HBox gap="5px">
      <Dot size={AllSizes.SMALL} status={AuditActionStatus[cellData]} />
      <Text title={cellData}>{cellData}</Text>
    </HBox>
  );
}

export default ActionColumn;
