export const baseUrl = '/resources/v2.0/plans';
export const getBaseUrl = '/resources/v2.0/plans/filters-with-schedules';

export const responseConfig = {
  path: 'data',
};

export enum QueryKeys {
  AlertFilter = 'alert_filter',
  FilterPreview = 'filter_preview',
}
