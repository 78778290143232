import forEach from 'lodash/forEach';
angular.module('bigpanda').directive('incidentsTimestampInterval', incidentsTimestampInterval);

function incidentsTimestampInterval($interval, stateService, IncidentTimestampService) {
  return {
    restrict: 'A',
    scope: {
      incidentsTimestampInterval: '=',
    },
    link: link,
  };

  function link(scope) {
    let interval = null;
    scope.$on('$destroy', function () {
      $interval.cancel(interval);
    });

    const folderId = stateService.getSelectedFolderId();

    function updateIncidents() {
      forEach(scope.incidentsTimestampInterval, (incident) => {
        IncidentTimestampService.getIncidentTimestamp(incident, folderId).then((timeProps) => {
          incident.timeStampDisplay = timeProps.timeStampDisplay;
        });
      });
    }

    interval = $interval(updateIncidents, 60 * 1000);
  }
}
