angular
  .module('bigpanda.backend.services')
  .service('CustomizationsBackendService', CustomizationsBackendService);

function CustomizationsBackendService($http) {
  this.getCustomizations = getCustomizations;
  this.updateCustomizations = updateCustomizations;

  function getCustomizations() {
    return $http.get('/web/customizations').then((res) => res.data.item);
  }

  function updateCustomizations(newCustomizations) {
    return $http.patch('/web/customizations', newCustomizations).then((res) => res.data.item);
  }
}
