import get from 'lodash/get';
/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import DOMPurify from 'dompurify';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Dot } from '@bp/kung-fu';
import { TimeFormats, BamCopyToClipboard } from '@bp/bam';
import { getStatusColor } from '../utils';
import { troubleshootingLogPropType } from '../propTypes';
import styles from './TroubleshootingPayload.scss';

const { momentOrEpochRelativeTimeFormat } = TimeFormats;
const COPY_ANIMATION_TIMEOUT = 1000;

class TroubleshootingPayload extends React.Component {
  state = {
    timeoutId: undefined,
  };

  copyPayloadHandler = () => {
    clearTimeout(this.state.timeoutId);
    this.setState({
      timeoutId: setTimeout(() => this.setState({ timeoutId: undefined }), COPY_ANIMATION_TIMEOUT),
    });
  };

  highlightSearch = (text, search, ariaLabel) => {
    const highlighted = `<span role="mark" aria-label=${ariaLabel} class=${styles.mark}>${search}</span>`;
    return search
      ? text
          .split(search)
          .map((text) => `<span>${text}</span>`)
          .join(highlighted)
      : text;
  };

  render() {
    const {
      search,
      log: { level, log_type, message, timestamp, payload },
    } = this.props;
    return (
      <div className={styles.root}>
        <div className={styles.title}>
          <Dot size="medium" color={getStatusColor(level, styles)} />
          <div className={styles.type}>{log_type}</div>
          <div className={styles.timestamp}>
            {momentOrEpochRelativeTimeFormat(timestamp, { seconds: true })}
          </div>
          <BamCopyToClipboard
            triggerType="button"
            content={payload}
            buttonPropsStyle={{
              type: 'cta-secondary',
              iconClass: 'bp-icon-copy-to-clipboard',
              className: 'copy-button-to-clipboard',
            }}
            copyHandler={this.copyPayloadHandler}
          />
        </div>
        <div className={styles.content}>
          <div
            className={styles.message}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(this.highlightSearch(message, search, 'message')),
            }}
          />
          <div
            className={classnames(styles.payload, { [styles.highlighted]: !!this.state.timeoutId })}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(this.highlightSearch(payload, search, 'payload')),
            }}
          />
        </div>
      </div>
    );
  }
}

TroubleshootingPayload.propTypes = {
  log: troubleshootingLogPropType.isRequired,
  search: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  return {
    organization,
    user,
  };
};

export default connect(mapStateToProps, null)(hot(module)(TroubleshootingPayload));
