import get from 'lodash/get';
import has from 'lodash/has';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

import { bigPandaAdmin, systemGeneratedUser } from '../../constants';
import { EnvAllName, MapCategoryExpandRowName, MapCategoryRowName } from './constants';
import {
  Actor, ActorType, AuditCategory, AuditLog, AuditRow, CategoryType, Environment, User,
} from './types';

export {
  auditResponseMapper, capitalizeFirstLetter, formatText, getChangedObjectName, getUser,
};

const capitalizeFirstLetter = (string: string): string => string[0].toUpperCase() + string.slice(1);

const formatText = (data: string): string => data.split(/[-,._\s]/).map(capitalizeFirstLetter).join(' ');

const normalizeCategoryName = (data: AuditLog): AuditCategory => {
  const category = CategoryType[data.resource_type];
  let name = data.resource_type;
  if (category === CategoryType.alert_filtering) {
    name = data.object.plan_type === 'maintenance_plan' ? 'maintenance_plan' : 'alert_filtering';
  }

  const rowValue = MapCategoryRowName[name] || formatText(name);
  const expandRowValue = MapCategoryExpandRowName[name] || formatText(name);
  return {
    name,
    rowValue,
    expandRowValue,
  };
};

const getEnv = (envs: Environment[], envId: string): Environment => envs.find(
  // eslint-disable-next-line no-underscore-dangle
  (env) => envId === env.old_id,
);

const getChangedObjectName = (auditLog: AuditLog, envs: Environment[]): string => {
  const category = CategoryType[auditLog.resource_type];
  if ([CategoryType.environments, CategoryType.users,
    CategoryType.roles, CategoryType.api_keys].includes(category)) {
    return auditLog.object.name ? auditLog.object.name as string : auditLog.resource_id;
  }
  if (category === CategoryType.correlation_pattern && has(auditLog, 'object.tags')) {
    if (auditLog.object.tags && Array.isArray(auditLog.object.tags)) {
      const tags = auditLog.object.tags.map((tag) => {
        if (isObject(tag)) {
          return Object.values(tag);
        }
        return tag;
      }).join(' · ');
      return tags;
    }
  }
  if (category === CategoryType.jit_domains) {
    return auditLog.object.domain ? auditLog.object.domain as string : auditLog.resource_id;
  }
  if (category === CategoryType.auto_share_rules) {
    const env = getEnv(envs, auditLog.object.env_id as string);
    const envName = get(env, 'name', EnvAllName);
    const targetSystemId = get(auditLog, 'object.target_system_id', undefined);
    if (targetSystemId) {
      const targetSystem = targetSystemId.split('.')[0];
      return `${envName} (${targetSystem})`;
    }
    return `${envName}`;
  }

  if (category === CategoryType.integrations) {
    return get(auditLog, 'object.target_system_id', '');
  }

  if ([
    CategoryType.tag,
    CategoryType.sso_config,
    CategoryType.sso_test,
    CategoryType.tag_enrichments_order,
    CategoryType.tag_enrichments,
    CategoryType.mapping_enrichment,
    CategoryType.mapping_table_upload,
  ].includes(category)) {
    return auditLog.resource_id;
  }

  if (category === CategoryType.alert_filtering) {
    return auditLog.object.plan_name as string;
  }

  const fallback = auditLog.resource_id ? auditLog.resource_id : 'Missing Data';

  return fallback;
};

const getUser = (actor: Actor): User => {
  const { user, type } = actor;

  if (type === ActorType.system) {
    return systemGeneratedUser;
  }

  const { name, id, email } = user;

  if (name === bigPandaAdmin) {
    return {
      id,
      name,
    };
  }
  if (isNil(name)) {
    return {
      name: email,
      id,
      email,
    };
  }
  return user;
};

const auditResponseMapper = (logs: AuditLog[], envs: Environment[]): AuditRow[] => logs
  .map(
    (data, index) => ({
      id: data.resource_id + index,
      resourceId: data.resource_id,
      user: getUser(data.actor),
      context: data.context,
      action: formatText(data.action_type),
      category: normalizeCategoryName(data),
      changedObject: getChangedObjectName(data, envs),
      date: data.timestamp * 1000,
      object: data.object,
      children: [{
        id: `${data.resource_id + index}-details`,
      }],
    } as AuditRow),
  );
