import includes from 'lodash/includes';
angular.module('bp.widgets').service('bpPanelSelectService', bpPanelSelectService);

function bpPanelSelectService($mdPanel) {
  this.openSelector = openSelector;

  /**
   *
   * @param {element} element - The element that the panel will open from
   * @param {array} options - The options that the panel will display. Each option must have the key 'id',
   *                                 which will be passed on when an option is selected and will be used
   *                                 to determine which option is currently selected. Each option must have the
   *                                 key 'display' - the URL path to the template of the option
   * @param {String} selectedId - The id of the option that is currently selected
   * @param {String} filterKey - The key of the option that will be used to filter the options
   * @param {String} resetActionName - The name of the reset action that will be displayed in the panel
   * @param {String} panelSize - The size of the panel, 'small' or 'large'
   * @param {function} onSelect - The callback that will be called when an option is clicked
   *                              The callback receives the option's id as a parameter
   *                              Clicking on the 'reset action' will not pass the id as the parameter to the callback function
   * @param {Boolean} multiSelectMode
   * @param {string} incidentId
   */

  function openSelector({
    element,
    options,
    selectedId,
    filterKey,
    resetActionName,
    panelSize,
    onSelect,
    multiSelectMode,
    incidentId,
    title,
    onOpenComplete,
    onDomRemoved,
  }) {
    let panel;
    const panelPosition = $mdPanel
      .newPanelPosition()
      .relativeTo(element)
      .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW)
      .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ABOVE);

    $mdPanel
      .open({
        templateUrl: 'widgets/bp_panel_select/bp_panel_select',
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          options: options,
          selectedId: selectedId,
          filterKey: filterKey,
          resetActionName: resetActionName,
          onSelect: onSelect,
          multiSelectMode: multiSelectMode,
          title: title,
        },
        panelClass: `bp-panel-select-${panelSize}`,
        controllerAs: 'vm',
        position: panelPosition,
        controller: ['$element', controller],
        attachTo: angular.element(document.body),
        onOpenComplete: onOpenComplete && onOpenComplete(),
        onDomRemoved: () => {
          onDomRemoved && onDomRemoved();
          panel.destroy();
        },
      })
      .then((panelRef) => {
        panel = panelRef;
      });

    function controller() {
      const vm = this;

      vm.optionClick = optionClick;
      vm.filter = filter;

      function filter(option) {
        if (vm.query) {
          return includes(option[filterKey].toLowerCase(), vm.query.toLowerCase());
        }
        return true;
      }

      function optionClick(selectedOptionId) {
        panel.close();
        onSelect(selectedOptionId, incidentId);
      }
    }
  }
}
