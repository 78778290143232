import sortBy from 'lodash/sortBy';
angular.module('bigpanda.reports').directive('bpMttrTable', ($filter, pubSubService) => {
  return {
    restrict: 'E',
    templateUrl: 'reports/visualization_widget/visualizations/mttr_table/mttr_table',
    require: '^bpVisualizationWidgetContainer',
    scope: {},
    link: link,
  };

  function link($scope, $element, $attrs, containerController) {
    onRefreshReportData($scope, containerController);

    var unsubscribe = pubSubService.on('reportWidgetsController.refreshReportData', (event) => {
      onRefreshReportData($scope, containerController);
    });

    $scope.$on('$destroy', () => {
      unsubscribe();
    });
  }

  function onRefreshReportData($scope, containerController) {
    containerController.getDisplayConfiguration().then((displayConfig) =>
      containerController.getReportData().then((data) => {
        renderVisualization(data, $scope, displayConfig);
      })
    );
  }

  function renderVisualization(data, $scope, displayConfig) {
    $scope.reportTable = formatReportTable(data, displayConfig);
  }

  function formatReportTable(data, displayConfig) {
    if (data.length === 0) {
      return data;
    }

    var commasFormatter = d3.format(',');
    var totalIncidents = data.reduce((prev, cur) => prev + cur.distinct_count.value, 0);
    var totalDurations = data.reduce((prev, cur) => prev + cur.sum_duration.value || 0, 0);

    data = sortBy(data, 'key');

    var table = [[displayConfig.dateHeader, 'Incidents', 'MTTR']].concat(
      data.map((row) => [
        $filter('granularityBasedFormat')(displayConfig.timeGranularity, row.key),
        commasFormatter(row.distinct_count.value),
        $filter('reportsDuration')(row.sum_duration.value / row.distinct_count.value || 0),
      ])
    );

    table.push([
      'Total',
      commasFormatter(totalIncidents),
      $filter('reportsDuration')(totalDurations / totalIncidents),
    ]);

    return table;
  }
});
