import { react2angular } from 'react2angular';
import PriorityDropdown from '../../../../workspaces/apps/incident-tags/src/PriorityDropdown/PriorityDropdownWrapper';

angular
  .module('bigpanda')
  .component(
    'priorityDropdownKf',
    react2angular(PriorityDropdown, [
      'store',
      'priorityTags',
      'updatePriorityTag',
      'removePriorityTag',
      'priorityValue',
      'fromList',
    ])
  );

export { name } from './constants';
export { default as reducer } from './reducer';
export { default as epic } from './epic';
export { default as selectors } from './selectors';
