import { CHBox, HBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const ModalFormWrapper = styled(CHBox)`
    height: 100%;
    width: 100%;
    padding: 20px;
`;

export const CellWrapper = styled(HBox)`
    flex-grow: 1;
    width: ${(p) => p.tagWidth};
    max-width: 500px;
`;

export const CellContentWrapper = styled(CHBox)`
    background-color: ${(p) => p.theme.bp_gray_01};
    height: 100%;
    width: 100%;
`;
