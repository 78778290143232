import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import autobind from 'class-autobind';
import { Button } from '@bp/kung-fu';

class IntegrationsModal extends React.Component {
  constructor(props) {
    super(props);
    autobind(this, IntegrationsModal.prototype);
  }

  render() {
    const { triggerProps, openModal } = this.props;
    return (
      <Button onClick={openModal} {...triggerProps}>
        {triggerProps.text}
      </Button>
    );
  }
}

IntegrationsModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
};

export default hot(module)(IntegrationsModal);
