import { react2angular } from 'react2angular';
import AlertFilteringList from '../../../../../../workspaces/apps/alert-filtering/src/filter-list/components/AlertFilteringList';

const template = `<section class="bp-master-detail-view">
    <alert-filtering-list class="left-panel" store="store" query-client="queryClient"></alert-filtering-list>
    <div class="spacer"></div>
    <div ui-view="master-detail-info" class="master-detail-info" ></div>
  </section>`;

const routes = {
  appSettingsAlertFiltering: {
    url: '/alert-filtering',
    views: {
      'settings-page-view': {
        template,
        controller: ($scope, $ngRedux, UserFeatureTogglesService, QueryClientService) => {
          'ngInject';

          if (UserFeatureTogglesService.getToggle('frontier_disable_alert_filtering')) {
            window.location.href = '/#';
          }

          $scope.store = $ngRedux;
          $scope.queryClient = QueryClientService.getQueryClient();
        },
      },
      'master-detail-info@app.settings.alert_filtering': {
        template:
          '<filter-details-pane class="react-settings-info" store="store" query-client="queryClient" />',
        controller: ($scope, $ngRedux, UserFeatureTogglesService, QueryClientService) => {
          'ngInject';

          if (UserFeatureTogglesService.getToggle('frontier_disable_alert_filtering')) {
            window.location.href = '/#';
          }

          $scope.store = $ngRedux;
          $scope.queryClient = QueryClientService.getQueryClient();
        },
      },
    },
    onEnter: ($transitions, pubSubService) => {
      'ngInject';

      $transitions.onSuccess({ to: 'app.settings.alert_filtering' }, () => {
        pubSubService.broadcast('settingsPageChanged', 'alertFiltering');
      });
    },
  },
};

angular
  .module('bigpanda')
  .component('alertFilteringList', react2angular(AlertFilteringList, ['store', 'queryClient']));
export default routes;
