import { withReactQueryClient } from '@bp/data-access';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as integrationsSelectors } from '../../../../../../app/react/common/integrations';
import { loadIntegrations } from '../../../../../../app/react/common/integrations/actions';
import SettingsListScreen from '../../../../../../app/react/layout/settings/SettingsListScreen';
import provideStore from '../../../../../../app/react/utils/provideStore';
import dictionary from '../../dictionary';
import { useAlertFilters } from '../../hooks/useAlertFilters';
import { AlertFilterModal } from '../../modal/AlertFilterModal';
import { allFilterOptions, DROPDOWN_FILTERS_ALL_ITEM_OPTION, DropdownRecord } from '../../types/DropdownOption';
import { SortMode } from '../../types/SortMode';
import { DEFAULT_SORT_MODE, SortModes } from '../../types/SortModes';
import { showFailureMessageForFilters } from '../../utils/toasters';
import { ListFilters } from './ListFilters';
import { ListItem } from './ListItem';

export function AlertFilteringList(): JSX.Element {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadIntegrations());
  }, []);

  const [filterText, setFilterText] = useState<string>('');
  const [sortMode, setSortMode] = useState<SortMode>(DEFAULT_SORT_MODE);
  const [filters, setFilters] = useState<DropdownRecord>(DROPDOWN_FILTERS_ALL_ITEM_OPTION);
  const [showError, setShowError] = useState(false);
  const [errorShown, setErrorShown] = useState(false);
  const [getDonePlans, setGetDonePlans] = useState(false);
  const integrations = useSelector(integrationsSelectors.getIntegrationOptions);

  const {
    alertFilters, isLoading, isFetching, isError,
  } = useAlertFilters({
    filterText, sortMode, filters, integrations,
  }, { getDonePlans: true }); // todo: use state variable instead

  if (isError && !errorShown) { setShowError(true); }
  if (showError) {
    showFailureMessageForFilters('load');
    setShowError(false);
    setErrorShown(true);
  }

  const filtersOptions = useMemo(() => allFilterOptions(integrations), [integrations]);

  const checkedDonePlans = (checked: boolean): void => {
    setGetDonePlans(checked);
  };

  return (
    <SettingsListScreen
      createItemProps={{
        modal: {
          title: dictionary.new_alert_filter,
          titleIcon: 'bp-icon-plus-icon',
          component: AlertFilterModal,
          isNew: true,
        },
        itemName: dictionary.alert_filter,
        buttonText: dictionary.new_filter,
      }}
      loading={isLoading || isFetching}
      items={alertFilters.filter(({ done }) => {
        if (!getDonePlans) return !done;
        return true;
      })}
      listItem={ListItem}
      listItemProp="filter"
      noItemsTitle={dictionary.no_alert_filter_added_yet}
      headerName={dictionary.alert_filtering}
      permissionName="plans"
      filterPlaceholderText={dictionary.find_filter_rule_placeholder}
      sortModes={SortModes}
      sortMode={sortMode}
      updateSort={setSortMode}
      filterText={filterText}
      updateFilterText={setFilterText}
      checkBox={{
        filter: checkedDonePlans,
        label: 'Show "Done" Rules',
      }}
      renderFilterRowElement={(): JSX.Element => (
        <ListFilters
          onChange={setFilters}
          data={filtersOptions}
          filters={filters}
        />
      )}
    />
  );
}

export default provideStore(withReactQueryClient(AlertFilteringList));
