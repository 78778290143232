import find from 'lodash/find';
angular.module('bigpanda').controller('EnvironmentMenuCtrl', EnvironmentMenuCtrl);

function EnvironmentMenuCtrl(
  $rootScope,
  $scope,
  $state,
  $log,
  notificationService,
  ModalService,
  CompatibilityService,
  EnvironmentsService,
  previousStateService,
  RulesService,
  Permissions,
  CustomizationsUtils,
  CustomizationsStore,
  PERMISSIONS_NAMES,
  UserFeatureTogglesService
) {
  const vm = this;
  const enableGlobalAuthFeatureToggle = UserFeatureTogglesService.getToggle('enable_global_auth');

  vm.$onInit = function () {
    vm.isFavorite = find($scope.favoriteEnvironments, $scope.environment);

    vm.content = [];

    if ($scope.environment.name === 'All' && !!enableGlobalAuthFeatureToggle) {
      vm.content.push({
        id: 'edit',
        label: 'Edit',
        exe: editEnvironment,
        icon: 'bp-icon-edit',
        permission: PERMISSIONS_NAMES.environments.update,
      });
    }

    // add edit and duplicate options unless it's 'All Incidents'
    if ($scope.environment.name !== 'All') {
      vm.content.push(
        {
          id: 'edit',
          label: 'Edit',
          exe: editEnvironment,
          icon: 'bp-icon-edit',
          permission: PERMISSIONS_NAMES.environments.update,
        },
        {
          id: 'duplicate',
          label: 'Duplicate',
          exe: duplicateEnvironment,
          icon: 'bp-icon-duplicate',
          permission: PERMISSIONS_NAMES.environments.create,
        },
        {
          id: 'delete',
          label: 'Delete',
          exe: deleteEnvironment,
          icon: 'bp-icon-trash',
          permission: PERMISSIONS_NAMES.environments.delete,
        }
      );
    }

    // Toggle Star/Unstar buttons
    if (vm.isFavorite) {
      vm.content.push({
        id: 'favorite',
        label: 'Unstar',
        exe: unfavoriteEnvironment,
        icon: 'bp-icon-star-hollow',
        appliesForAll: true,
      });
    } else {
      vm.content.push({
        label: 'Star',
        exe: favoriteEnvironment,
        icon: 'bp-icon-star-full',
        appliesForAll: true,
      });
    }
  };

  function editEnvironment(env) {
    previousStateService.resetPreviousState();
    previousStateService.saveAndGoto($state.current, $state.params, 'app.environments', {
      environmentId: env._id,
    });
  }

  function duplicateEnvironment(env) {
    previousStateService.saveAndGoto($state.current, $state.params, 'app.environments', {
      dupEnvironmentId: env._id,
    });
  }

  function deleteEnvironment(env) {
    if (!Permissions.isPermitted(PERMISSIONS_NAMES.environments.delete)) return;

    RulesService.getRulesByEnvId(env.old_id).then((rules) => {
      const scope = $rootScope.$new();
      scope.env = env;
      scope.rulesCount = rules.length;

      scope.delete = () => {
        EnvironmentsService.delete(env._id);
        ModalService.hide();
      };

      scope.hide = ModalService.hide;

      const modalParams = {
        show: true,
        keyboard: CompatibilityService.canClosePopupsWithEscape(),
        templateUrl: 'overview/environments/actions/environment_delete',
        scope: scope,
      };

      if (!CompatibilityService.canClosePopupsWithBackdropClick()) {
        modalParams.backdrop = 'static';
      }
      return ModalService.showModal(modalParams);
    });
  }

  function favoriteEnvironment(env) {
    updateFavorites((favorites) => CustomizationsUtils.addFavorite(favorites, env, 'EnvMenu'));
  }

  function unfavoriteEnvironment(env) {
    updateFavorites((favorites) => CustomizationsUtils.deleteFavorite(favorites, env, 'EnvMenu'));
  }

  function updateFavorites(updaterFunc) {
    CustomizationsStore.getCustomizations()
      .then((customizations) => {
        const favorites = updaterFunc(customizations.environments.navigation_list.favorites);
        return CustomizationsStore.updateCustomizations({
          environments: { navigation_list: { favorites: favorites } },
        });
      })
      .catch((err) => {
        $log.error(`Failed updating user customizations: ${err}`);
        notificationService.error('Error updating stars', { error: err });
      });
  }

  vm.actionClicked = function (action, env) {
    action.exe(env);
  };
}
