import get from 'lodash/get';
import { path as parentPath } from '../constants';
import { path } from './constants';

const selectors = {};

selectors.getUserId = (state) => get(state, `${parentPath}.${path}`).id;
selectors.getUser = (state) => get(state, `layout.topbar.user`);

export default selectors;
