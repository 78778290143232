import spread from 'lodash/spread';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';

angular.module('bigpanda').service('AssignPanelUtils', AssignPanelUtils);

function AssignPanelUtils(ContactsStore, PersonalSettingsStore, UsersStore, $q) {
  this.getUserOptions = getUserOptions;

  function getUserOptions() {
    const userContactsPromise = ContactsStore.getContacts().then((contacts) =>
      filter(contacts, (contact) => contact.bigpanda_user_id)
    );
    const usersPromise = UsersStore.getUsers();
    const currentContactPromise = PersonalSettingsStore.getContact();

    return $q
      .all([userContactsPromise, usersPromise, currentContactPromise])
      .then(spread(configureOptions));
  }

  function configureOptions(userContacts, users, currentContact) {
    let options = filter(userContacts, (contact) => find(users, { _id: contact.bigpanda_user_id }));
    options = map(options, (o) => normalizeOption(o));
    options = sortBy(options, [(o) => sortMeFirst(o, currentContact), 'displayName']);
    return options;
  }

  function normalizeOption(option) {
    return {
      id: option.bigpanda_user_id,
      email: option.email,
      displayName: configureDisplayName(option),
      template: 'overview/incidents/actions/assign/panel_select/assign_panel_select_option',
    };
  }

  function sortMeFirst(option, currentContact) {
    if (option.id === currentContact.bigpanda_user_id) {
      return -1;
    }

    return 1;
  }

  function configureDisplayName(option) {
    return option.name || option.email;
  }
}
