import { HBox } from '@bp/kung-fu';
import { Field } from 'react-final-form';
import styled from 'styled-components';

import { ExtractionRegexInput } from '../../ExtractionRegexInput';

export const ExtractionForm = styled(HBox)`
    justify-content: flex-start;
`;

export const SourceSystemField = styled(Field)`
    margin-right: 10px;
    min-width: 130px;
    width: 130px;
`;

export const SourceSystemv2 = styled.div`
    margin-right: 10px;
    width: 140px;
    min-width: 140px;
    margin-bottom: 20px;
`;

export const SourceTagField = styled(Field)`
    margin-right: 10px;
    width: 130px;
    min-width: 130px;
`;

export const SourceTagv2 = styled.div`
    margin-right: 10px;
    width: 140px;
    min-width: 140px;
    margin-bottom: 20px;
`;

export const RegexField = styled(ExtractionRegexInput)`
    & :global(label.bam-field-title) {
        display: block !important;
    }

    & :global(label.error-validation) {
        margin-bottom: 0 !important;
    }
`;
