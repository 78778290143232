angular.module('bigpanda.reports').directive('bpTableWidget', [TableWidget]);

function TableWidget() {
  return {
    restrict: 'E',
    templateUrl: 'reports/visualization_widget/generic_visualizations/table_widget/table_widget',
    scope: {
      data: '=data',
    },
  };
}
