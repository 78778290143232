import { StatusEnum } from '@bp/kung-fu';

import { MaintenancePlan } from '../../types/MaintenancePlan';

export const semanticPlanStatus = (status: MaintenancePlan['status'], isExternalPlan?: boolean): string => {
  if (isExternalPlan) {
    return 'External';
  }

  switch (status) {
    case StatusEnum.PLANNED:
      return 'Planned';
    case StatusEnum.INPROGRESS:
      return 'Running';
    case StatusEnum.DONE:
    default:
      return 'Done';
  }
};
