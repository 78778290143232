import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DraggableTag from './DraggableTag';
import { enrichmentPropType } from '../../propTypes';

const TagsList = ({ tags }) => (
  <Droppable droppableId="alert tags">
    {(provided) => (
      <div {...provided.droppableProps} ref={provided.innerRef}>
        {tags.map((tag, index) => (
          <DraggableTag key={tag.name} tag={tag} index={index} />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

TagsList.propTypes = {
  tags: PropTypes.arrayOf(enrichmentPropType).isRequired,
};

export default TagsList;
