import React from 'react';
import PropTypes from 'prop-types';
import { BamFiltersPlaceHolder } from '@bp/bam';
import FiltersDropdown, {
  DropdownOptionType,
} from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { ANY_OPTION_VALUE } from '../../constants';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION } from '../../../constants';

const FiltersComponent = ({ filtersDropDownState, onChange, data }) => {
  const {
    roles: { value: rolesValue, text: rolesText },
    phone: { value: phoneValue, text: phoneText },
    status: { value: statusValue, text: statusText },
  } = filtersDropDownState;

  const dropdownElements = [
    <FiltersDropdown
      data={data.roles}
      selectedValue={rolesValue}
      selectedText={rolesText}
      defaultText="Roles"
      onChange={(e, selection) => onChange({ roles: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.phone}
      selectedValue={phoneValue}
      selectedText={phoneText}
      defaultText="Phone Number"
      onChange={(e, selection) => onChange({ phone: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.status}
      selectedValue={statusValue}
      selectedText={statusText}
      defaultText="Status"
      onChange={(e, selection) => onChange({ status: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={[rolesValue, phoneValue, statusValue].some(
        (v) => v !== ANY_OPTION_VALUE
      )}
      onClearButtonClick={() => {
        onChange({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
      }}
    />
  );
};
FiltersComponent.propTypes = {
  data: PropTypes.shape({
    roles: PropTypes.arrayOf(DropdownOptionType),
    phone: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    roles: DropdownOptionType,
    phone: DropdownOptionType,
    status: DropdownOptionType,
  }).isRequired,
};
export default FiltersComponent;
