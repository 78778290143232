import React from 'react';

import PreviewTags from '../PreviewTags';
import { Alert, PreviewRule, TagValue } from '../PreviewTags/types';
import { PreviewTable, TableContainer } from './AlertTagPreview.style';

interface IProps {
  previewRule: PreviewRule;
  uniquePreviewTags: TagValue[];
  tagsView: boolean;
  columns: object[];
  previewAlerts: Alert[];
  onColumnsChanged: (curColumns: object[]) => void;
  getColumnStyle: (id: string) => object;
  getColumnHeaderStyle: (id: string) => object;
}

export function AlertTagPreviewContent({
  previewRule,
  tagsView,
  uniquePreviewTags,
  columns,
  previewAlerts,
  onColumnsChanged,
  getColumnStyle,
  getColumnHeaderStyle,
}: IProps): JSX.Element {
  if (tagsView) {
    return <PreviewTags previewRule={previewRule} tags={uniquePreviewTags} />;
  }
  return (
    <TableContainer role="table" aria-label="alert tag preview">
      <PreviewTable
        id="alert-tag-preview"
        columns={columns}
        data={previewAlerts}
        onColumnsChanged={onColumnsChanged}
        getTdProps={(_, __, { id }): object => getColumnStyle(id)}
        getTheadThProps={(_, __, { id }): object => getColumnHeaderStyle(id)}
        aria-label="alert tag preview"
      />
    </TableContainer>
  );
}

export default AlertTagPreviewContent;
