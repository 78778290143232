import React from 'react';
import PropTypes from 'prop-types';
import { BamActivityRecord } from '@bp/bam';
import capitalize from 'lodash/capitalize';
import { bigPandaUserName, bpUserImg } from 'common/constants';
import Comment from '../Comment';

const RelatedChangeActivityRecord = ({
  user = {},
  updatedAt,
  matchCertainty,
  comment,
  richComment,
  suggestedByBP,
}) => (
  <BamActivityRecord
    quote
    inverted
    name={(suggestedByBP && bigPandaUserName) || user.name}
    date={updatedAt}
    title={`Marked as a ${capitalize(matchCertainty)}`}
    text={<Comment comment={comment} richComment={richComment} suggestedByBp={suggestedByBP} />}
    email={user.email}
    match={matchCertainty.toLowerCase()}
    avatarUrl={suggestedByBP ? bpUserImg : ''}
  />
);

RelatedChangeActivityRecord.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  // eslint-disable-next-line react/no-typos
  updatedAt: BamActivityRecord.propTypes.date.isRequired,
  matchCertainty: PropTypes.string.isRequired,
  comment: PropTypes.string,
  richComment: PropTypes.string,
  suggestedByBP: PropTypes.bool,
};

RelatedChangeActivityRecord.defaultProps = {
  user: undefined,
  comment: '',
  richComment: '',
  suggestedByBP: false,
};

export default RelatedChangeActivityRecord;
