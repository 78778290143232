import get from 'lodash/get';

export const baseSettingInitialState = {
  isLoading: false,
  filterText: '',
};

export function generateBaseSettingsReducer(actionTypes, initialState) {
  return function reducer(state = initialState, action = null) {
    switch (action.type) {
      case actionTypes.ROUTE_EXIT:
        return {
          ...initialState,
          isLoading: false,
        };

      case actionTypes.UPDATE_FILTER_TEXT:
        return {
          ...state,
          filterText: action.payload,
        };

      case actionTypes.UPDATE_SORT:
        return {
          ...state,
          sortMode: action.payload,
        };

      case actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS:
        return {
          ...state,
          filtersDropDownItems: {
            ...action.payload,
          },
        };
      case actionTypes.UPDATE_FILTERS_DROPDOWNS:
        return {
          ...state,
          filtersDropDownState: {
            ...state.filtersDropDownState,
            ...action.payload,
          },
        };
      case actionTypes.UPDATE_LABELS_FILTER_SELECTED_ITEM:
        return {
          ...state,
          labelsFilterSelectedItem: action.payload,
        };
      default:
        return state;
    }
  };
}

export function generateBaseSettingsActionPaths(path) {
  return {
    ROUTE_EXIT: `${path}.ROUTE_EXIT`,
    UPDATE_FILTER_TEXT: `${path}.UPDATE_FILTER_TEXT`,
    UPDATE_SORT: `${path}.UPDATE_SORT`,
    UPDATE_FILTERS_DROPDOWNS: `${path}.UPDATE_FILTERS_DROPDOWNS`,
    CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS: `${path}.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS`,
    UPDATE_LABELS_FILTER_SELECTED_ITEM: `${path}.UPDATE_LABELS_FILTER_SELECTED_ITEM`,
    REMOVE_ALERT_TAG: `${path}.REMOVE_ALERT_TAG`,
  };
}

export function generateBaseSettingsActions(actionTypes) {
  return {
    routeExit: () => ({ type: actionTypes.ROUTE_EXIT }),
    updateFilterText: (payload) => ({ type: actionTypes.UPDATE_FILTER_TEXT, payload }),
    updateSort: (payload) => ({ type: actionTypes.UPDATE_SORT, payload }),
    updateFiltersDropDowns: (payload) => ({
      type: actionTypes.UPDATE_FILTERS_DROPDOWNS,
      payload,
    }),
    createFiltersDropDownItemsSuccess: (payload) => ({
      type: actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS,
      payload,
    }),
    updateLabelsFilterSelectedItem: (payload) => ({
      type: actionTypes.UPDATE_LABELS_FILTER_SELECTED_ITEM,
      payload,
    }),
  };
}

export function generateBaseSelectors(path) {
  return {
    getFilterText: (state) => get(state, path).filterText,
    getFiltersDropDownState: (state) => get(state, path).filtersDropDownState,
    getFiltersDropDownItems: (state) => get(state, path).filtersDropDownItems,
    getLabelsFilterSelectedItem: (state) => get(state, path).labelsFilterSelectedItem,
    isLoading: (state) => get(state, path).isLoading,
  };
}

export function filterItemsByDropDownFilters(
  dropDownState,
  dropDownItemDataMapping,
  items,
  noFilterActionValue = '-1',
) {
  if (!items) return undefined;
  if (!dropDownState || !dropDownItemDataMapping) return items;
  const actionableDropDownStateKeys = Object.keys(dropDownState).filter(
    (key) => dropDownState[key].value !== noFilterActionValue,
  );

  return items.filter((item) => actionableDropDownStateKeys.every(
    (key) => dropDownItemDataMapping[key]
                && dropDownItemDataMapping[key](item).some(
                  (d) => d && d.toLowerCase() === dropDownState[key].value.toLowerCase(),
                ),
  ));
}
export function filterItems(items, filterText, itemData) {
  if (!items) return undefined;
  if (!filterText) return items;
  return items.filter((item) => {
    const searchTerm = filterText.toLowerCase();
    return itemData(item).some((d) => d && d.toLowerCase().includes(searchTerm));
  });
}
