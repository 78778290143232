import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/combineLatest';
import { bamNotify, BamNotificationStatusOptions } from '@bp/bam';
import { selectors as organizationSelectors } from 'react/user/organization';
import {
  deleteUser as deleteUserRequest,
  reactivateUser as reactivateUserRequest,
  resendUserInvitation as resendUserInvitationRequest,
  setUserRoles as setUserRolesRequest,
} from 'common/endpoints/users';
import { getRoles as getRolesRequest } from 'common/endpoints/roles';
import { updateContact as updateContactRequest } from 'common/endpoints/contacts';
import { loadEnrichedUsers } from 'react/common/enriched_users/actions';
import { showSuccessMessage, showFailureMessage } from 'react/layout/settings/feedback';
import { findIdP } from 'common/modules/settings/SsoService';
import contactSelectors from 'react/common/contacts/selectors';
import actionTypes from './actionTypes';
import actions from './actions';
import selectors from './selectors';
import {
  DROPDOWN_FILTERS_ALL_ITEM_OPTION,
  DROPDOWN_FILTER_STATUS_ITEMS,
  DROPDOWN_FILTER_PHONE_ITEMS,
} from './constants';

export const inviteContext = (state) => {
  const context = { origin: 'invite' };
  const organization = organizationSelectors.getOrganization(state);
  if (
    organization &&
    organization.config &&
    organization.config.sso &&
    organization.config.sso.status === 'ok'
  ) {
    const idpConf = findIdP(organization.config.sso.idP);
    if (idpConf) {
      context.ssoIdP = idpConf.display;
      context.sso = true;
    }
  }
  return context;
};

const showSuccessMessageForUser = (action, status = BamNotificationStatusOptions.ACTION_SUCCESS) =>
  showSuccessMessage('User', action, status);

const showFailureMessageForUser = (action) => showFailureMessage('user', action);

const updateUser = (action$, state$) =>
  action$.ofType(actionTypes.UPDATE_USER).mergeMap(async ({ payload }) => {
    const { id, name, roles, isPhoneNumberInputDirty } = payload;
    let { phone } = payload;
    const state = state$.getState();

    if (isPhoneNumberInputDirty && !phone) {
      phone = '';
    }
    try {
      const requests = [
        ...(name || phone || isPhoneNumberInputDirty
          ? [updateContactRequest(contactSelectors.getContactIdByUserId(state, id), name, phone)]
          : []),
        ...(roles ? [setUserRolesRequest(id, selectors.getRolesByIds(state, roles))] : []),
      ];
      await Promise.all(requests);
      return actions.updateOrInviteUserSuccess();
    } catch (e) {
      return actions.updateOrInviteUserFailure();
    }
  });

const deleteUser = (action$) =>
  action$.ofType(actionTypes.DELETE_USER).mergeMap(async ({ payload: userId }) => {
    try {
      await deleteUserRequest(userId);
      showSuccessMessageForUser('deleted', BamNotificationStatusOptions.DELETE_SUCCESS);
    } catch (e) {
      showFailureMessageForUser('delete');
    }
    return loadEnrichedUsers();
  });

const reactivateUser = (action$) =>
  action$.ofType(actionTypes.REACTIVATE_USER).mergeMap(async ({ payload }) => {
    try {
      const { email, name, formatedRoles, phone } = payload;
      await reactivateUserRequest(email, name, formatedRoles, phone);
      showSuccessMessageForUser('reactivated');
    } catch (e) {
      showFailureMessageForUser('reactivate');
    }
    return loadEnrichedUsers();
  });

const resendUserInvitation = (action$, state$) =>
  action$.ofType(actionTypes.RESEND_USER_INVITATION).mergeMap(async ({ payload: userId }) => {
    const context = inviteContext(state$.getState());
    try {
      await resendUserInvitationRequest(userId, context);
      bamNotify({
        message: [{ message: 'Resent', highlight: true }, { message: 'an invitation to the user' }],
        status: BamNotificationStatusOptions.ACTION_SUCCESS,
      });
      return actions.resendUserInvitationCompleted({ success: true });
    } catch (e) {
      bamNotify({
        message: [
          { message: 'Failed to' },
          { message: 'resend an invitation', highlight: true },
          { message: 'to the user' },
        ],
        status: BamNotificationStatusOptions.FAILURE,
      });
      return actions.resendUserInvitationCompleted({ success: false });
    }
  });

const handleUpdateOrInviteUserSuccess = (action$) =>
  action$.ofType(actionTypes.UPDATE_OR_INVITE_USER_SUCCESS).mergeMap(async () => {
    showSuccessMessageForUser('saved');
    return loadEnrichedUsers();
  });

const handleUpdateOrInviteUserFailure = (action$) =>
  action$.ofType(actionTypes.UPDATE_OR_INVITE_USER_FAILURE).mergeMap(async () => {
    showFailureMessageForUser('save');
    return loadEnrichedUsers();
  });

const getRoles = (action$) =>
  action$.ofType(actionTypes.GET_ROLES).mergeMap(async () => {
    const roles = await getRolesRequest();
    const normalizedRoles = roles
      .filter((role) => !role.hidden)
      .map(({ _id: key, name, users }) => ({ key, name, users }));
    return actions.getRolesSuccess(normalizedRoles);
  });

const createFiltersDropDownItems = (action$) =>
  action$.ofType(actionTypes.GET_ROLES_SUCCESS).map(({ payload }) => {
    const rolesArray = payload.map((role) => ({ text: role.name, value: role.key, key: role.key }));

    return actions.createFiltersDropDownItemsSuccess({
      roles: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.roles, ...rolesArray],
      phone: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.phone, ...DROPDOWN_FILTER_PHONE_ITEMS],
      status: [DROPDOWN_FILTERS_ALL_ITEM_OPTION.status, ...DROPDOWN_FILTER_STATUS_ITEMS],
    });
  });

export default combineEpics(
  updateUser,
  deleteUser,
  reactivateUser,
  resendUserInvitation,
  handleUpdateOrInviteUserSuccess,
  handleUpdateOrInviteUserFailure,
  getRoles,
  createFiltersDropDownItems
);
