angular.module('bigpanda.backend.services').service('TagsBackendService', TagsBackend);

function TagsBackend($log, $http) {
  this.getRawTags = getRawTags;
  this.getUniqueTagsSamples = getUniqueTagsSamples;

  function getRawTags() {
    return $http.get('/web/tags').then((res) => res.data.item);
  }

  function getUniqueTagsSamples(query) {
    return $http
      .post('/web/search/tags', query)
      .then((res) => res.data.item)
      .catch((err) => {
        $log.error(`getUniqueTagsSamples Fail: ${err.statusText}`);
        return [];
      });
  }
}
