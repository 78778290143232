import last from 'lodash/last';
angular.module('bigpanda').constant('DashboardsSettingsConfig', {
  sortOptions: [
    { display: 'Name', value: 'name', key: (env, widgetData) => env.name },
    {
      display: 'Active Incidents',
      value: 'active',
      key: (env, widgetData) => -(last(widgetData.graph) || {}).count,
    },
  ],
  timeframeOptions: [
    { display: 'Last Hour', value: 1 },
    { display: 'Last 2 Hours', value: 2 },
    { display: 'Last 4 Hours', value: 4 },
    { display: 'Last 6 Hours', value: 6 },
    { display: 'Last 12 Hours', value: 12 },
    { display: 'Last 24 Hours', value: 24 },
  ],
  widgetTypes: {
    workflow: 'activities',
    severity: 'severity',
  },
});
