import { fetcher } from '@bp/data-access';

import { IIncident } from '../../types';

export async function getIncident(incidentId: string): Promise<IIncident> {
  return fetcher({
    requestConfig: {
      url: `web/incidents/${incidentId}`,
    },
    responseConfig: { path: 'data.item' },
  });
}
