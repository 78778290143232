import { Button, ButtonVariants, HBox, lightTheme } from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

export default () => {
  const handleChange = () => {
    localStorage.setItem('isIncidentConsoleV2', 'yes');
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <HBox padding="8px 16px">
        <Button variant={ButtonVariants.LINK} onClick={handleChange}>
          New feed experience
        </Button>
      </HBox>
    </ThemeProvider>
  );
};
