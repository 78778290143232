import dictionary from '../dictionary';

export type DropdownOption = {
  value: string;
  text: string;
  key?: string;
  isAnyOption?: boolean;
  tooltip?: string;
};

export type DropdownRecord = Record<string, DropdownOption>;

export type DropdownRecords = Record<string, DropdownOption[]>;

export const ANY_OPTION_VALUE = '-1';

export const DROPDOWN_FILTERS_ALL_ITEM_OPTION: DropdownRecord = {
  status: {
    value: ANY_OPTION_VALUE,
    text: dictionary.any_status,
    key: dictionary.any_status,
    isAnyOption: true,
  },
  source: {
    value: ANY_OPTION_VALUE,
    text: dictionary.any_source,
    key: ANY_OPTION_VALUE,
    isAnyOption: true,
  },
};

export const sourceOptions = (integrations = []): DropdownOption[] => [
  {
    value: ANY_OPTION_VALUE, text: 'Any Source', key: 'Any Source', isAnyOption: true,
  },
  { text: dictionary.all_systems, value: '*', key: '*' },
  ...integrations.map(({ value, display }) => ({ key: value, text: display, value })),
];

export const allFilterOptions = (integrations = []): Record<string, DropdownOption[]> => ({
  status: [
    {
      value: ANY_OPTION_VALUE,
      text: dictionary.any_status,
      key: dictionary.any_status,
      isAnyOption: true,
    },
    { key: 'active', text: dictionary.active, value: 'true' },
    { key: 'inactive', text: dictionary.inactive, value: 'false' },
  ],
  source: sourceOptions(integrations),
});
