import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bp/kung-fu';
import { BamTileButton } from '@bp/bam';

const ModalTriggerButton = (props) => {
  const { type, displayButtonText, text, ariaLabel } = props;

  return type !== 'tile' ? (
    <Button {...props} ariaLabel={ariaLabel || text} text={displayButtonText ? text : undefined} />
  ) : (
    <BamTileButton {...props} />
  );
};

export default ModalTriggerButton;

ModalTriggerButton.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  text: PropTypes.string,
  iconClass: PropTypes.string,
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool,
  displayButtonText: PropTypes.bool,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
};

ModalTriggerButton.defaultProps = {
  disabled: false,
  tooltipText: undefined,
  iconClass: undefined,
  text: undefined,
  variant: undefined,
  type: undefined,
  displayButtonText: true,
  ariaLabel: undefined,
  onClick: undefined,
};
