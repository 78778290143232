import { Div } from '@bp/kung-fu';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { variants } from '../../../../../../app/react/common/components/Select/constants';
import Select from '../../../../../../app/react/common/components/Select/Select';
import { DropdownOption } from '../../../../alert-filtering/src/types/DropdownOption';

interface IProps {
  options: DropdownOption[];
  onSelectedChanged?: (a: unknown, b: string, c: unknown) => void;
  name?: string;
  value?: string;
  ariaLabel?: string;
}

export function AlertEnrichmentFilter({
  options = [], onSelectedChanged, name, value, ariaLabel,
}: IProps): JSX.Element {
  return (
    <Div data-api-id="configuration-labels-filter">
      <Select
        getItemDisplayValue={(item): void => get(item, 'text')}
        disabled={isEmpty(options)}
        error={false}
        items={options}
        onChange={onSelectedChanged}
        placeholder={name}
        value={value}
        variant={variants.filter}
        ariaLabel={ariaLabel}
      />
    </Div>
  );
}

export default AlertEnrichmentFilter;
