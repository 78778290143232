import isEmpty from 'lodash/isEmpty';
angular.module('bigpanda').controller('WelcomeController', WelcomeController);

function WelcomeController(
  $scope,
  $http,
  $state,
  PersonalSettingsStore,
  UserUtils,
  pubSubService,
  UserFeatureTogglesService,
  $ngRedux
) {
  const vm = this;

  vm.password = '';
  vm.errors = {};
  vm.user = null;
  vm.contact = null;

  vm.save = save;
  vm.success = success;
  vm.err = err;
  vm.hasValidationErrors = hasValidationErrors;
  vm.pubSubBroadcastUserNameChanged = pubSubBroadcastUserNameChanged;
  vm.store = $ngRedux;

  init();

  function init() {
    $scope.updatePage('welcome');
    pubSubService.broadcast('welcomePage.moment', { event: 'Welcome page visit' });

    PersonalSettingsStore.getUser().then((user) => {
      vm.user = user;
    });
    PersonalSettingsStore.getContact().then((contact) => {
      vm.contact = contact;
    });
  }

  function validate() {
    let wasError = false;

    const nameFormObj = vm.welcomeForm.name;
    if (nameFormObj.$error && nameFormObj.$error.required) {
      vm.errors.name = 'Name is mandatory';
      wasError = true;
    } else if (
      !wasError &&
      nameFormObj.$error &&
      (nameFormObj.$error.minlength || nameFormObj.$error.maxlength)
    ) {
      vm.errors.name = 'Full name must be between 2 and 100 characters';
      wasError = true;
    } else {
      delete vm.errors.name;
    }

    if (!wasError && vm.welcomeForm.password.$error && vm.welcomeForm.password.$error.required) {
      vm.errors.password = ['Password is mandatory'];
      wasError = true;
    } else {
      delete vm.errors.password;
    }

    if (!wasError) {
      const pwdStatus = UserUtils.isPasswordStrengthValid(vm.password);
      if (!pwdStatus.isValid) {
        wasError = true;
        vm.errors.password = pwdStatus.error;
      }
    }
    return !wasError;
  }

  function pubSubBroadcastUserNameChanged(newName) {
    pubSubService.broadcast('UserService.nameChanged', { name: newName });
  }

  function save() {
    vm.saving = true;
    if (!validate()) {
      vm.saving = false;
      return;
    }

    PersonalSettingsStore.updateContact({ name: vm.contact.name }).then(() => {
      pubSubBroadcastUserNameChanged(vm.contact.name);
      const data = { newPassword: vm.password };
      $http
        .post('/web/me/changePassword', data)
        .then((res) => res.data)
        .then(vm.success, vm.err);
    }, vm.err);
  }

  function success() {
    pubSubService.broadcast('welcomePage.moment', { event: 'Welcome page completion' });

    vm.saving = false;

    // In order to get the new status of the user (after changePassword, we get the user again)
    PersonalSettingsStore.getUser(true).then(() => {
      $scope.updatePage('welcome');
      $state.go('app.default', null, { location: false, reload: true });
    });
  }

  function err(error) {
    vm.error =
      error.status === 400
        ? 'Server validation error, please contact support.'
        : 'Temporary issue, please try again.';
    vm.saving = false;
  }

  function hasValidationErrors() {
    return !isEmpty(vm.errors);
  }
}
