export default {
  timeline_title: 'Timeline',
  resize_button_arialabel: 'Incident view resize',
  resize_button_v2_label: 'Incident view v2',
  manual_title: 'Manual',
  manual_badge_tooltip: 'Correlation disabled for manual incidents',
  min_title: 'min',
  spaced_and_title: ' and ',
  time_window_title: 'Time Window:',
  created_title: 'Created:',
  source_title: 'Source: ',
  assign_remove_title: 'Unassign',
};
