import React from 'react';
import { ThemeProvider } from 'styled-components';
import autobind from 'class-autobind';
import pickBy from 'lodash/pickBy';
import {
  BamBaseModal,
  BamModalContent,
  BamModalHeader,
  BamIncidentsBox,
  BamAlertTile,
  BamTooltipAlert,
  BamModalActions,
  BamAddNote,
} from '@bp/bam';
import { Spinner, SplitIcon, darkTheme, Button, Tooltip } from '@bp/kung-fu';
import { bp_blue } from '@bp/pastel/colors';
import { docsUrl as docsBaseUrl } from 'common/config';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../actions';
import styles from './add_note.scss';

const updateSearchMode = (searchValue, search) => {
  if (searchValue) {
    search();
  }
};

const SpinnerComp = () => (
  <BamModalContent>
    <div className="is-merging" style={{ height: '362px' }}>
      <Spinner
        color={bp_blue}
        size="large"
        text="Please wait a few seconds while we split your incident..."
      />
    </div>
  </BamModalContent>
);

const getTooltipText = (hasAlerts, allAlertsSelected) => {
  if (allAlertsSelected) {
    return 'Cannot split all the Alerts';
  }
  if (hasAlerts) {
    return 'Split selected Alerts';
  }
  return 'Select Alerts to Split';
};
class SplitModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      annotation: '',
      error: false,
      alerts: [],
    };

    autobind(this, SplitModal.prototype);
  }

  openModal() {
    const { alerts, incident } = this.props;
    const hasError = alerts.length === incident.entities.length;

    this.setState(() => ({
      isModalOpen: !hasError,
      error: hasError,
      alerts: alerts,
    }));

    if (hasError) {
      setTimeout(this.reset, 7000);
    }
  }

  reset() {
    this.setState(() => ({
      isModalOpen: false,
      isSpliting: false,
      annotation: '',
      error: false,
    }));
  }

  close() {
    if (this.state.isSpliting) return;
    this.reset();
  }

  async handleClick() {
    const { alerts } = this.state;
    const {
      organization,
      incident,
      createSplit,
      envId,
      getEnvById,
      searchValue,
      search,
    } = this.props;
    const { name: organizationName } = organization;
    const { name: envName } = await getEnvById(envId);

    this.setState({ isSpliting: true });

    const payload = pickBy({
      entityIds: alerts.map(({ id }) => id),
      incidentId: incident.id,
      annotation: this.state.annotation,
      envId: envId,
      envName: envName,
      organization: organizationName,
    });

    createSplit(payload);

    setTimeout(() => {
      this.reset();
      updateSearchMode(searchValue, search);
    }, 3000);
  }

  addNoteOnChangeHandler(event) {
    this.setState({
      annotation: event.target.value,
    });
  }

  renderModalContent() {
    const { alerts } = this.state;
    const showSource =
      alerts.reduce((acc, { sourceCap }) => acc.add(sourceCap), new Set()).size > 1;
    const docsLink = `${docsBaseUrl}/v1.0/docs/split-incidents`;
    const helpTooltip = (
      <div>
        Create a brand new incident based on the selected alerts.
        <br />
        Note that actions (comments, assignee, snooze, etc.) from the source incident won&apos;t be
        moved to the newly created incident.
        <br />
        But the alerts history is preserved.
        <br />
        Also, both the source incident and the newly created incident will be labeled
        &quot;manual&quot; and will not receive any new alerts. <br />
        This operation is irreversible.
      </div>
    );

    return (
      <React.Fragment>
        <BamModalHeader
          helpTooltip={helpTooltip}
          docsLink={docsLink}
          close={this.close}
          title="Split to a new incident"
          icon="bp-icon-split"
          size="huge"
        />
        <BamModalContent>
          <BamIncidentsBox title={`Split ${alerts.length} alert${alerts.length > 1 ? 's' : ''}`}>
            {alerts.map((alert) => {
              const source = showSource && alert.sourceCap;
              return <BamAlertTile key={alert.id} alert={{ ...alert, sourceCap: source }} />;
            })}
          </BamIncidentsBox>
          <div className={styles['add-note-container']}>
            <BamAddNote
              onChange={this.addNoteOnChangeHandler}
              value={this.state.annotation}
              description="(Explain why you split this incident)"
            />
          </div>
        </BamModalContent>
        <BamModalActions
          submitButton={{ text: 'Split Incident', onClick: this.handleClick }}
          closeButton={{ text: 'Cancel', onClick: this.close }}
        />
      </React.Fragment>
    );
  }

  render() {
    const { alerts, incident } = this.props;
    const { error } = this.state;
    const allAlertsSelected = alerts.length === incident.entities.length;

    const SplitButton = () =>
      error ? (
        <BamTooltipAlert
          trigger={
            <Button
              variant="action-color"
              size="large"
              onClick={this.openModal}
              icon={<SplitIcon />}
            >
              Split
            </Button>
          }
          open={error}
          onUnmount={() => {
            this.setState({
              error: false,
            });
          }}
          content="Cannot split all incident's alerts"
          position="bottom center"
        />
      ) : (
        <Button
          variant="action-color"
          size="large"
          onClick={this.openModal}
          disabled={!alerts.length || allAlertsSelected}
          icon={<SplitIcon />}
          tooltipProps={{
            isActive: true,
            placement: 'bottom',
            text: getTooltipText(alerts.length > 0, allAlertsSelected),
          }}
        >
          Split
        </Button>
      );

    return (
      <ThemeProvider theme={darkTheme}>
        <div>
          <SplitButton />
          <BamBaseModal
            open={this.state.isModalOpen}
            onClose={this.close}
            onMount={this.onOpen}
            className="bam-split-modal"
          >
            {!this.state.isSpliting ? this.renderModalContent() : <SpinnerComp />}
          </BamBaseModal>
        </div>
      </ThemeProvider>
    );
  }
}

SplitModal.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  incident: PropTypes.object.isRequired, // eslint-disable-line
  envId: PropTypes.string.isRequired,
  getEnvById: PropTypes.func.isRequired,
  createSplit: PropTypes.func.isRequired,
  organization: PropTypes.shape({ name: PropTypes.string }),
  searchValue: PropTypes.string,
  search: PropTypes.func,
};

SplitModal.defaultProps = {
  alerts: [],
  organization: {},
};

function mapStateToProps({ user: { organization } = {} }) {
  return {
    organization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSplit: (payload) => dispatch(actions.createSplit(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SplitModal);
