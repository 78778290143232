import { AllSizes, EmptyMessage, VBox } from '@bp/kung-fu';
import React from 'react';

import { TAG_TYPE } from '../../../../../../app/react/modules/settings/alert_enrichment/constants';
import { normalizeType } from '../../../../../../app/react/modules/topology/logic/logic';
import { CompositionTag, ExtractionTag } from '../HighlightTag';
import { FullCVBox } from './style';
import { PreviewRule, ReferenceTag, TagValue } from './types';
import { ListItem, UList } from './UList';

const normalizeName = (type = ''): string => normalizeType(type || '-').toLowerCase();
const isTagType: (tagType: string) => (type: string) => boolean = (tagType = '') => (type = '') => normalizeName(type) === normalizeName(tagType);
const isExtraction: (type: string) => boolean = isTagType(TAG_TYPE.Extraction);
const isComposition: (type: string) => boolean = isTagType(TAG_TYPE.Composition);

const renderValue = (previewRule: PreviewRule, value: string, referenceTags: ReferenceTag): JSX.Element => {
  const { regex, template, type } = previewRule;

  if (isExtraction(type) && regex) {
    return <ExtractionTag value={value} regex={regex} />;
  }

  if (isComposition(type) && template) {
    return <CompositionTag template={template} referenceTags={referenceTags} />;
  }

  return <span>{value}</span>;
};

interface IProps {
  previewRule: PreviewRule;
  tags: TagValue[];
}

export function PreviewTags({ previewRule, tags }: IProps): JSX.Element {
  const tagItems: ListItem[] = tags.map(({ value, original }: TagValue) => ({
    id: value,
    value: renderValue(previewRule, value, original.referenceTags),
  }));

  if (!tagItems.length) {
    return (
      <FullCVBox>
        <EmptyMessage>No results found</EmptyMessage>
      </FullCVBox>
    );
  }

  return (
    <VBox padding="30px">
      <UList items={tagItems} bulletSize={AllSizes.LARGE} />
    </VBox>
  );
}

export default PreviewTags;
