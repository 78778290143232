export const validateMandatory = (value, name) => {
  if (!value) {
    return `${name} is mandatory`;
  }

  return null;
};

export const validatePassword = (password = '') => {
  if (!password.trim().length) {
    return 'Password is mandatory';
  }

  if (!/(?=.*[a-z])(?=.*[!@#$&*0-9])(?=.*[A-Z]).{8,}/g.test(password)) {
    return 'Password does not meet requirements';
  }

  return null;
};

export const validatePasswordWithText = (password = '') => {
  if (!password.trim().length) {
    return 'Password is mandatory';
  }

  if (!/(?=.*[a-z])(?=.*[!@#$&*0-9])(?=.*[A-Z]).{8,}/g.test(password)) {
    return [
      'Make sure your password contains:',
      'At least 8 characters',
      'At least 1 lowercase & 1 uppercase letter',
      'At least 1 numeric or special character',
    ];
  }

  return null;
};
