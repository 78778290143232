import find from 'lodash/find';
import keys from 'lodash/keys';
import extend from 'lodash/extend';
angular.module('bigpanda.reports').controller('ReportWidgetsController', ReportWidgetsController);

function ReportWidgetsController(
  $scope,
  $q,
  $state,
  stateService,
  DefaultReportConfig,
  ReportsConfig,
  ReportsService,
  WidgetsService,
  ReportDataBackendService,
  EnvironmentsService,
  ModalService,
  pubSubService,
  PERMISSIONS_NAMES,
  Permissions
) {
  const vm = this;
  const isPermitted = Permissions.isPermitted;

  vm.getReportData = getReportData;
  vm.refreshReportData = refreshReportData;
  vm.saveReport = saveReport;

  vm.availableEnvironments = [];
  vm.error = false;
  vm.errorMessage = '';
  vm.reportDefinition = null;
  vm.reportSnapshotsCount = 0;
  vm.selectedEnvironment = null;
  vm.timeframePresets = [];
  vm.selectedTimeframePreset = {};
  vm.widgets = {};
  vm.reportsPermissions = PERMISSIONS_NAMES.analytics;
  vm.updatePermitted = isPermitted(vm.reportsPermissions.update);
  vm.createPermitted = isPermitted(vm.reportsPermissions.create);
  vm.isReportConfigDisabled = isReportConfigDisabled;

  vm.$onInit = onInit;

  function isReportConfigDisabled() {
    const isActionsAuthorized =
      isPermitted(PERMISSIONS_NAMES.analytics.update) &&
      isPermitted(PERMISSIONS_NAMES.analytics.create);
    if (isActionsAuthorized) {
      return false;
    } else if (vm.reportDefinition && !vm.reportDefinition.system && !isActionsAuthorized) {
      return true;
    }

    return false;
  }

  function getReportData(widget) {
    return ReportDataBackendService.getReportData(
      widget.type,
      extend({}, widget, {
        group_by: widget.group_by && ReportsConfig.tagPrefix + widget.group_by,
      })
    );
  }

  function refreshReportData() {
    pubSubService.broadcast('reportWidgetsController.refreshReportData');
  }

  function saveReport() {
    // Save all widgets
    const promises = keys(vm.widgets).map((widgetId) => WidgetsService.updateWidget(widgetId));

    // Save the report
    promises.push(ReportsService.updateReport(vm.reportDefinition._id));

    return $q.all(promises).then((results) => {
      // TODO: Display some feedback
    });
  }

  pubSubService.on(
    'ReportSettingsNew.dataLoaded',
    (event, data) => {
      const reportOverrides = {
        title: data.title,
        description: data.description,
      };

      const widgetOverrides = {
        query: { environment_id: vm.selectedEnvironment.id },
        timeframe: convertToUnix(vm.selectedTimeframePreset.timeframe),
      };

      return ReportsService.cloneReport(data._id, reportOverrides, widgetOverrides).then(
        (report) => {
          $state.go('.', { reportId: report._id });
        }
      );
    },
    $scope
  );

  function convertToUnix(timeframe) {
    let newTimeframe = angular.copy(timeframe);
    if (newTimeframe.type === 'absolute') {
      newTimeframe.start = moment(newTimeframe.start).unix();
      newTimeframe.end = moment(newTimeframe.end).unix();
    }
    return newTimeframe;
  }

  function convertToDate(timeframe) {
    let newTimeframe = angular.copy(timeframe);
    if (newTimeframe.type === 'absolute') {
      newTimeframe.start = moment(newTimeframe.start * 1000).toDate();
      newTimeframe.end = moment(newTimeframe.end * 1000).toDate();
    }
    return newTimeframe;
  }

  let envsPromise = null;
  function retrieveEnvironments() {
    if (envsPromise) {
      return envsPromise;
    }

    envsPromise = EnvironmentsService.get().then((envs) => {
      vm.availableEnvironments = envs.map((env) => {
        var newEnv = {
          id: env.old_id,
          name: env.name,
        };
        return newEnv;
      });

      //  TODO - here for test and debug - delete before
      // let index = vm.availableEnvironments.findIndex((env) => env.id === 'all');
      // vm.availableEnvironments.splice(index, 1);

      return vm.availableEnvironments;
    });

    return envsPromise;
  }

  function loadSnapshotMetadata(currentReportId) {
    return ReportsService.getSnapshotMetadata(currentReportId).then((snapshots) => {
      vm.reportSnapshotsCount = snapshots.length;
      vm.reportSnapshots = snapshots;
    });
  }

  function retrieveReportAndParameters(currentReportId) {
    return ReportsService.getReport(currentReportId)
      .then((report) =>
        WidgetsService.getMultipleWidgets(report.widgets).then((widgetMap) => {
          vm.reportDefinition = report;
          vm.widgets = widgetMap;

          vm.error = false;

          // For now, we assume that all widgets in a report have the same environment and timeframe.
          const repWidget = vm.widgets[vm.reportDefinition.widgets[0]];
          const widgetTimeframe = repWidget.timeframe || DefaultReportConfig.defaultTimeframe;

          if (widgetTimeframe.type === 'relative') {
            vm.selectedTimeframePreset = angular.copy(
              find(vm.timeframePresets, { timeframe: { interval: widgetTimeframe.interval } }) ||
                find(vm.timeframePresets, {
                  timeframe: { interval: DefaultReportConfig.defaultTimeframe.interval },
                }) // Make sure
              // we don't spew errors when the interval in the database is one we do not have a preset for
            );
          } else if (widgetTimeframe.type === 'absolute') {
            // Copy the custom timeframe preset
            let customTimeframePreset = angular.copy(
              find(vm.timeframePresets, { timeframe: { type: 'absolute' } })
            );
            // Override the timeframe with the widget settings
            extend(customTimeframePreset.timeframe, convertToDate(widgetTimeframe));
            vm.selectedTimeframePreset = customTimeframePreset;
          }

          // Update window title
          pubSubService.broadcast(
            'currentTitleChanged',
            `Analytics - ${vm.reportDefinition.title}`
          );

          return retrieveEnvironments()
            .then((environments) => {
              // TODO - my workspace for RBACK
              vm.selectedEnvironment =
                find(environments, {
                  id: (repWidget.query && repWidget.query.environment_id) || 'all',
                }) || environments[0];
            })
            .then(registerHandlers);
        })
      )
      .catch(() => {
        vm.error = true;
        vm.errorMessage = 'Error retrieving report.';
      });
  }

  function calculateGroupByTime(widget) {
    if (!widget || !widget.group_by_time) {
      return;
    }

    let intervalInDays = 0;
    if (widget.timeframe.type === 'relative' && widget.timeframe.interval) {
      const relativeSetting = find(
        ReportsConfig.timeframePresets,
        (item) => item.timeframe.interval === widget.timeframe.interval
      );

      intervalInDays = moment().diff(relativeSetting.getStartMoment(), 'days');
    } else {
      let end = widget.timeframe.end;
      if (!end) {
        end = moment().endOf('day').unix();
      }

      intervalInDays = moment(end * 1000).diff(moment(widget.timeframe.start * 1000), 'days') + 1;
    }

    if (intervalInDays < 8) {
      widget.group_by_time = 'day';
    } else if (intervalInDays < 50) {
      widget.group_by_time = 'week';
    } else {
      widget.group_by_time = 'month';
    }
  }

  function calculateTimeZone(widget) {
    if (!widget || !widget.timeframe) {
      widget.time_zone = 0;
      return;
    }

    widget.time_zone = -new Date().getTimezoneOffset() / 60;
  }

  function registerHandlers() {
    $scope.$watchCollection(
      () => vm.selectedTimeframePreset.timeframe,
      (newTimeframe, oldTimeframe) => {
        if (newTimeframe === oldTimeframe) {
          return;
        }

        keys(vm.widgets).forEach((widgetId) => {
          vm.widgets[widgetId].timeframe = convertToUnix(newTimeframe);
          calculateGroupByTime(vm.widgets[widgetId]);
          calculateTimeZone(vm.widgets[widgetId]);
        });

        return saveReport().then(refreshReportData);
      }
    );

    $scope.$watch(
      () => vm.selectedEnvironment,
      (newEnvironment, oldEnvironment) => {
        if (newEnvironment === oldEnvironment) {
          return;
        }

        keys(vm.widgets).forEach((widgetId) => {
          vm.widgets[widgetId].query = { environment_id: newEnvironment.id };
        });

        return saveReport().then(refreshReportData);
      }
    );

    const unsubscribes = [
      $scope.$on('reportWidgetsController.widgetEdited', (event, widgetId) => {
        event.stopPropagation();
        return WidgetsService.updateWidget(widgetId);
      }),
    ];

    $scope.$on('$destroy', () => {
      unsubscribes.map((unsubscribe) => {
        unsubscribe();
      });
    });

    pubSubService.on('reportWidgetsController.refreshReportSnapshots', (event, reportId) => {
      if (reportId === vm.reportDefinition._id) {
        loadSnapshotMetadata(reportId);
      }
    });
  }

  vm.showNewReportDialog = () => {
    ModalService.showModal({
      templateUrl: 'reports/detail/report_settings_dialog/report_settings_dialog',
      controller: 'ReportSettingsDialogController',
      locals: {
        data: Object.assign({}, vm.reportDefinition),
        dialogTitle: 'Save as New Report',
        submitButtonCaption: 'Save Report',
        cancelButtonCaption: 'Cancel',
        titleIconClass: 'bp-icon-file-document-text',
        eventPrefix: 'ReportSettingsNew',
      },
      bindToController: true,
    });
  };

  vm.showExportDialog = () => {
    if (!vm.updatePermitted) return;

    const timeframePreset = angular.copy(vm.selectedTimeframePreset);
    timeframePreset.timeframe = convertToUnix(timeframePreset.timeframe);

    ModalService.showModal({
      templateUrl: 'reports/detail/report_export_dialog/report_export_dialog',
      controller: 'ReportExportDialogController',
      controllerAs: 'reportExportDialogController',
      locals: {
        report: Object.assign({}, vm.reportDefinition),
        widgets: vm.widgets,
        timeframePreset: timeframePreset,
        environment: vm.selectedEnvironment,
      },
      bindToController: true,
    });
  };

  vm.showSnapshotsDialog = () => {
    ModalService.showModal({
      templateUrl: 'reports/detail/report_snapshots_dialog/report_snapshots_dialog',
      controller: 'ReportSnapshotsDialogController',
      controllerAs: 'reportSnapshotsDialogController',
      locals: {
        snapshots: vm.reportSnapshots,
      },
      bindToController: true,
    });
  };

  function onInit() {
    if (!Permissions.checkBasicPermissions()) {
      return;
    }

    vm.timeframePresets = angular.copy(ReportsConfig.timeframePresets);
    vm.selectedTimeframePreset = angular.copy(
      find(vm.timeframePresets, {
        timeframe: { interval: DefaultReportConfig.defaultTimeframe.interval },
      })
    );

    return ReportsService.getReports().then((reports) => {
      let defaultReportId = find(reports, { system: true });
      if (defaultReportId) {
        defaultReportId = defaultReportId._id;
      }

      const currentReportId = stateService.getSelectedReportId() || defaultReportId;

      return retrieveEnvironments()
        .then(retrieveReportAndParameters(currentReportId))
        .then(loadSnapshotMetadata(currentReportId));
    });
  }
}
