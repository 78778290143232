import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/combineLatest';
import {
  getMaintenancePlans,
  createMaintenancePlan,
  updateMaintenancePlan,
  deleteMaintenancePlan,
  stopMaintenancePlan,
} from 'common/endpoints/maintenance-plans';
import { BamNotificationStatusOptions, bamUnNotify } from '@bp/bam';
import {
  showFailureMessage,
  showSuccessMessage,
  showInProgressMessage,
} from 'react/layout/settings/feedback';
import actionTypes from './actionTypes';
import actions from './actions';

const loadPlans = (action$) =>
  action$
    .ofType(actionTypes.LOAD_PLANS, actionTypes.LOAD_INITIAL_PLANS)
    .mergeMap(async ({ payload = {} }) =>
      actions.loadPlansSuccess(
        await getMaintenancePlans(payload.link, payload.active).catch(() =>
          showFailureMessage('load')
        )
      )
    );

const createOrUpdatePlan = (action$) =>
  action$.ofType(actionTypes.CREATE_OR_UPDATE_PLAN).mergeMap(async ({ payload }) => {
    const { plan } = payload;
    let toasterId;
    const planToCreateOrUpdate = {
      name: plan.name,
      id: plan.id,
      start: parseInt(plan.times.from.format('X')),
      end: parseInt(plan.times.to.format('X')),
      condition: plan.condition,
      description: plan.description || '',
    };
    if (planToCreateOrUpdate.id) {
      try {
        await updateMaintenancePlan(planToCreateOrUpdate);
        showSuccessMessageForPlan('updated');
      } catch (e) {
        showFailureMessageForPlan('update');
      }
    } else {
      try {
        toasterId = showInProgressMessageForPlan('Plan', 'Creating');
        const newPlan = await createMaintenancePlan(planToCreateOrUpdate);
        if (window.location.href.includes('planned-maintenance')) {
          window.location.href = `/#/app/settings/planned-maintenance/${newPlan.id}`;
        }
        showSuccessMessageForPlan('created', toasterId);
      } catch (e) {
        showFailureMessageForPlan('create', toasterId);
      }
    }
    return actions.loadInitialPlans({ active: payload.active });
  });

const updatePlanEnd = (action$) =>
  action$.ofType(actionTypes.UPDATE_PLAN_END).mergeMap(async ({ payload }) => {
    const { plan } = payload;
    const planUpdate = {
      id: plan.id,
      end: parseInt(plan.times.to.format('X')),
    };
    try {
      await updateMaintenancePlan(planUpdate);
      showSuccessMessageForPlan('update end');
    } catch (e) {
      showFailureMessageForPlan('update end');
    }
    return actions.loadInitialPlans({ active: payload.active });
  });

const deletePlan = (action$) =>
  action$.ofType(actionTypes.DELETE_PLAN).mergeMap(async ({ payload }) => {
    let toasterId;
    try {
      toasterId = showInProgressMessageForPlan('Plan', 'Deleting');
      await deleteMaintenancePlan(payload);
      showSuccessMessageForPlan('deleted', toasterId);
    } catch (e) {
      showFailureMessageForPlan('delete', toasterId);
    }
    return actions.loadInitialPlans({ active: payload.active });
  });

const stopPlan = (actions$) =>
  actions$.ofType(actionTypes.STOP_PLAN).mergeMap(async ({ payload }) => {
    const { plan } = payload;
    let toasterId;
    try {
      toasterId = showInProgressMessageForPlan('Plan', 'Stop');
      await stopMaintenancePlan(plan);
      showSuccessMessageForPlan('stoped', toasterId);
    } catch (e) {
      showFailureMessageForPlan('stop', toasterId);
    }
    return actions.loadInitialPlans({ active: payload.active });
  });

const showInProgressMessageForPlan = (
  title,
  action,
  status = BamNotificationStatusOptions.IN_PROGRESS
) => showInProgressMessage(title, action, status);

const showSuccessMessageForPlan = (
  action,
  toasterId,
  status = BamNotificationStatusOptions.ACTION_SUCCESS
) => {
  toasterId && bamUnNotify(toasterId);
  setTimeout(() => showSuccessMessage('Plan', action, status), 1000);
};

const showFailureMessageForPlan = (action, toasterId) => {
  toasterId && bamUnNotify(toasterId);
  setTimeout(() => showFailureMessage('Plan', action), 1000);
};

export default combineEpics(loadPlans, createOrUpdatePlan, deletePlan, stopPlan, updatePlanEnd);
