import actionTypes from './actionTypes';

export function loadUserData(headers) {
  return { type: actionTypes.FETCH_USER, payload: headers };
}

export function loadUserDataSuccess(payload) {
  return { type: actionTypes.FETCH_USER_SUCCESS, payload: payload };
}

export function loadUserDataFailure(error) {
  return { type: actionTypes.FETCH_USER_FAILURE, error: error };
}
