angular
  .module('bigpanda')
  .controller(
    'PreviewController',
    ($scope, $http, $state, pubSubService, AuthService, $stateParams, CompatibilityService) => {
      const token = $stateParams.t || AuthService.getCookieToken();
      $scope.printMode = $stateParams.print;
      $scope.accessType = $stateParams.t ? 'anonymous' : 'token';
      $scope.url = { url: window.location.origin, title: 'Login to BigPanda' };

      pubSubService.on('PreviewController.updateUrl', (e, data) => {
        $scope.url = data;
      });

      if (token) {
        $scope.authorisationOptions = Object.freeze({
          headers: { Authorization: `Bearer ${token}` },
        });
      }

      if ($scope.printMode) {
        if (CompatibilityService.shouldAddCssOnBodyForPrint()) {
          // print hack for firefox
          angular.element('body').css('overflow', 'visible');

          // flex on the entire page not render correctly in firefox print
          angular.element('body').css('display', 'block');
          angular.element('#main-view').css('display', 'block');
        }
      }

      $scope.bootstrapPromise = $http
        .get('/web/me/organization', $scope.authorisationOptions)
        .then((res) => {
          return res.data.organization;
        });
    }
  );
