import { getCurrentDate, isDateBefore, StatusEnum } from '@bp/kung-fu';

import dictionary from '../dictionary';
import { AlertFilter } from '../types/AlertFilter';

type FilterStatus = {
  color: StatusEnum;
  string: string;
};

export const filterStatus = (filter: AlertFilter): FilterStatus => {
  let color = filter?.active ? StatusEnum.ACTIVE : StatusEnum.DISABLED;
  let string = filter?.active ? dictionary.active : dictionary.inactive;
  if (filter?.start_time && filter?.end_time && filter?.active) {
    const currentDate = getCurrentDate();
    color = StatusEnum.INPROGRESS;
    if (isDateBefore(currentDate, filter.start_time)) {
      color = StatusEnum.PLANNED;
      string = dictionary.scheduled;
    } else if (isDateBefore(filter.end_time, currentDate)) {
      color = StatusEnum.DONE;
      string = dictionary.done;
    }
  }

  return {
    color,
    string,
  };
};
