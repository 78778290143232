import { BamFiltersPlaceHolder } from '@bp/bam';
import React from 'react';

import FiltersDropdown from '../../../../../../app/react/common/components/FiltersDropdown/FiltersDropdown';
import dictionary from '../../dictionary';
import {
  ANY_OPTION_VALUE,
  DROPDOWN_FILTERS_ALL_ITEM_OPTION,
  DropdownRecord,
  DropdownRecords,
} from '../../types/DropdownOption';

interface IProps {
  onChange: (selection: DropdownRecord) => void;
  data: DropdownRecords;
  filters: DropdownRecord;
}

export function ListFilters({ filters, onChange, data }: IProps): JSX.Element {
  const {
    status: { value: statusValue, text: statusText },
    source: { value: sourceValue, text: sourceText },
  } = filters;

  const dropdownElements = [
    <FiltersDropdown
      data={data.status}
      selectedValue={statusValue}
      selectedText={statusText}
      defaultText={dictionary.status}
      onChange={(e, selection): void => onChange({ ...filters, status: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
      aria-label="status filter"
    />,
    <FiltersDropdown
      data={data.source}
      selectedValue={sourceValue}
      selectedText={sourceText}
      defaultText={dictionary.source}
      onChange={(e, selection): void => onChange({ ...filters, source: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
      aria-label="source filter"
    />,
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={[statusValue, sourceValue].some(
        (v) => v !== ANY_OPTION_VALUE,
      )}
      onClearButtonClick={(): void => {
        onChange({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
      }}
    />
  );
}
