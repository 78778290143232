angular.module('bigpanda').directive('activityStatusFilter', activityStatusFilter);

function activityStatusFilter() {
  return {
    restrict: 'E',
    scope: {
      showStatusChangesActivity: '=',
      onFilterStatusChanges: '=',
      statusChangesCount: '=',
    },
    templateUrl:
      'overview/incidents/info/activity_feed/activity_feed_status_filter/activity_feed_status_filter',
  };
}
