import styled from 'styled-components';
import { bp_gray_01, bp_gray_02, bp_gray_03 } from '@bp/pastel/colors';

export const Header = styled.div`
padding: 12px 14px 7px;
color: ${bp_gray_03};
user-select: text;
display: flex;
`;
export const VBox = styled.div`
display: flex;
flex-direction: column;
margin-inline-start: 11px;
gap: 4px;
`;

export const UserEmail = styled.span`
font-size: 11px;
width: 100%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

export const UserName = styled(UserEmail)`
font-size: 12px;
color: ${bp_gray_01};
font-weight: bold;
`;

export const SettingsHeader = styled.div`
font-size: 13px;
color: ${bp_gray_02};
font-weight: bold;
padding: 10px 14px 12px 22px;
`;
