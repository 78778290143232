import { selectors as userSelectors } from 'react/user/details';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import permissions from 'common/routing/permissions';
import enrichedUsersSelectors from 'react/common/enriched_users/selectors';
import get from 'lodash/get';
import { compose, curry, identity } from 'lodash/fp';
import orderBy from 'lodash/orderBy';
import boolToLodashOrder from 'common/utils/boolToLodashOrder';
import { path } from './constants';
import { generateBaseSelectors } from '../redux.utils';
import {
  DEFAULT_SORT_MODE,
  LAST_SEEN_SORT_MODE,
  USER_NAME_SORT_MODE,
} from './users_list/constants';
import { filterUsersByDropDown, filterUsersByText } from './utils';

const selectors = generateBaseSelectors(path);

selectors.getActionsPermissions = (state, user) => {
  if (!user) {
    return {};
  }

  const userPermissions = permissionsSelectors.getPermissionsList(state);
  const isSelf = user.id === userSelectors.getUserId(state);

  const hasEditPermission = userPermissions.includes(permissions.users.update);
  const hasDeletePermission = userPermissions.includes(permissions.users.delete);
  const canDelete = hasDeletePermission && !isSelf;
  const canInvite =
    userPermissions.includes(permissions.users.create) && ['pending', 'off'].includes(user.cls);

  return { hasEditPermission, hasDeletePermission, canDelete, canInvite };
};

selectors.getRoles = (state) => get(state, path).roles;
selectors.getRolesByIds = (state, ids) =>
  selectors.getRoles(state).filter(({ key }) => ids.includes(key));

selectors.getUsers = (state) => {
  let users = enrichedUsersSelectors.getEnrichedUsers(state);
  if (users) {
    const filterText = selectors.getFilterText(state);
    const dropDownFiltersState = selectors.getFiltersDropDownState(state);

    const sortItems = (users) => {
      const { field, ascending } = selectors.getSortMode(state);

      let sorter = field;
      if (field === USER_NAME_SORT_MODE.field) {
        sorter = (user) => user[field].toLowerCase();
      }

      const usersUnknownLastSeen = orderBy(
        users.filter((user) => !user.lastLoginAt),
        [sorter],
        [ascending].map(boolToLodashOrder)
      );
      const usersWLastSeen = orderBy(
        users.filter((user) => user.lastLoginAt),
        [sorter],
        [ascending].map(boolToLodashOrder)
      );

      if (field === LAST_SEEN_SORT_MODE.field) {
        return [...usersWLastSeen, ...usersUnknownLastSeen];
      }
      return orderBy(users, [sorter], [ascending].map(boolToLodashOrder));
    };

    const settingsSortingFT = get(
      state,
      ['user', 'organization', 'feature_toggles', 'frontier_settings_sorting_and_filters'],
      false
    );

    return compose(
      curry(sortItems),
      settingsSortingFT ? curry(filterUsersByDropDown)(dropDownFiltersState) : identity,
      curry(filterUsersByText)(filterText)
    )(users);
  }
  return undefined;
};
selectors.getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;
selectors.filtersDropDownState = (state) => get(state, path).filtersDropDownState;
selectors.getFiltersDropDownItems = (state) => get(state, path).filtersDropDownItems;

export default selectors;
