import {
  darkTheme, HBox, Select,
} from '@bp/kung-fu';
import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import { IAssignOption, IAssignValue } from '../../types';
import AssignTrigger from './AssignTrigger';
import { reconstructSelectedUser, reconstructUsers } from './logic';

interface IAssignSelect {
  onSelect: (id?: string) => void,
  value: IAssignValue,
  list: IAssignOption[]
}

const AssignSelect = ({ value, list, onSelect }: IAssignSelect) => {

  const selectedUser = reconstructSelectedUser(value);
  const isUserSelected = !!selectedUser;
  const finalList = useMemo(() => reconstructUsers(list, isUserSelected), [list, isUserSelected]);

  return (
    <ThemeProvider theme={darkTheme}>
      <HBox marginEnd="5px">
        <Select
          isClearable
          value={selectedUser}
          name="assignSelect"
          options={finalList}
          updateField={(name, item) => onSelect(item?.id || null)}
          menuWidth="fit-content"
          controlComponent={AssignTrigger}
          isFilterable
        />
      </HBox>

    </ThemeProvider>
  );
};

export default AssignSelect;
