/* eslint react/prop-types: 0 */
import styled from 'styled-components';
import { withReactQueryClient } from '@bp/data-access';
import React, { useState, useMemo } from 'react';
import { ActionableBadge, Div, Select } from '@bp/kung-fu';
import styles from '../../topbar.scss';
import createMidTextEllipsis from './createMidTextEllipsis';
import { useSwitchOrganizationLogin } from './useSwitchOrganizationLogin';

const MAX_BADGE_CHARS = 20;
const DEFAULT_BADGE_COLOR = 'bp_gray_08';

const mapOrgsToSelectOptions = (orgs) => {
  return orgs.map((org) => {
    return {
      text: org.displayName,
      value: org.organizationName,
    };
  });
};

const OrganizationDropdown = ({ orgName, orgColor, organizations, displayName }) => {
  const color = orgColor || DEFAULT_BADGE_COLOR;
  const { switchOrganizationLogin } = useSwitchOrganizationLogin();

  const [selectedOrg, setSelectedOrg] = useState({ text: orgName, value: orgName });

  const handleSwitchOrg = async (selectedOrg) => {
    setSelectedOrg(selectedOrg);
    await switchOrganizationLogin(selectedOrg.value);
  };

  const options = useMemo(() => mapOrgsToSelectOptions(organizations), [organizations]);

  return (
    <div className={styles['multi-org-menu']}>
      <Select
        key={selectedOrg.value}
        name="multi-org-select"
        value={selectedOrg}
        controlComponent={OrganizationBadge}
        width="100%"
        menuWidth="200px"
        menuMinWidth="92px"
        valueKey="text"
        options={options}
        updateField={(name, value) => handleSwitchOrg(value)}
        customProps={{ orgColor: color, displayName: displayName }}
      />
    </div>
  );
};

export default withReactQueryClient(OrganizationDropdown);

const OrganizationBadge = ({ requiredProps, customProps }) => {
  const ellipsesText = createMidTextEllipsis(customProps.displayName, MAX_BADGE_CHARS);
  return (
    <BadgeWrapper {...requiredProps}>
      <ActionableBadge
        key={customProps.displayName}
        textColor="bp_white"
        text={ellipsesText}
        color={customProps.orgColor}
        width="100%"
        weight="bold"
      />
    </BadgeWrapper>
  );
};

const BadgeWrapper = styled(Div)`
  max-width: 152px;
`;
