angular.module('bp.widgets').directive('bpContactDetails', ($popover) => ({
  restrict: 'A',
  scope: {
    escalatee: '=',
  },
  link: function (scope, element, attrs) {
    var newScope = scope.$new(true);
    newScope.item = scope.escalatee;
    $popover(element, {
      container: attrs.sticktoelement || false,
      trigger: 'hover',
      placement: 'top',
      autoClose: true,
      scope: newScope,
      template: 'widgets/bp_contact_details/bp_contact_details',
      delay: { show: 250, hide: 100 }, // Add 200ms delay, since in case of 0 delay we encountered bug. Rapid repetitive motion with the cursor on the trigger element, triggers the hover event multiple times which cause to bug with the show and hide element animation.
    });
  },
}));
