import { organizationTypes } from './organization_data/organization.type.constant';

angular.module('bigpanda').controller('TopbarCtrl', topbarCtrl);

function topbarCtrl(
  $rootScope,
  pubSubService,
  TopbarService,
  $mdSidenav,
  $mdMedia,
  EnvironmentsService,
  EnvironmentsUtils,
  $state,
  Permissions,
  RoutesConstants,
  $ngRedux,
  UserFeatureTogglesService
) {
  const vm = this;

  vm.mdMedia = $mdMedia;
  vm.hideSearchButton = false;
  vm.toggleSidenav = (sidenavName) => $mdSidenav(sidenavName).toggle();
  vm.toggleSearchBar = () => pubSubService.broadcast('Topbar.toggleSearchBar');
  vm.showTopbar = TopbarService.shouldShowTopbar();
  vm.hideSearchButton = TopbarService.shouldHideSearchButton();
  vm.homeButton = homeButton;
  vm.homeButtonURL = '/';
  vm.getOrgData = getOrgData;
  vm.navigateToFeed = navigateToFeed;
  vm.$onInit = onInit;
  vm.store = $ngRedux;
  vm.currentRouteName = '';
  vm.selectedSettingsPageId = null;

  function onInit() {
    pubSubService.on('topbar.toggle', (event, data) => {
      vm.showTopbar = data;
    });

    pubSubService.on('settingsPageChanged', (c, id) => {
      vm.selectedSettingsPageId = id;
    });

    vm.hasPermissions = Permissions.checkBasicPermissions();

    EnvironmentsService.get()
      .then(EnvironmentsUtils.getDefaultEnv)
      .then((env) => {
        vm.defaultEnvId = env ? env._id : RoutesConstants.NO_PERMISSIONS_ENV;
        vm.homeButtonURL = `/#/app/overview/${vm.defaultEnvId}/active/incidents`;
      });

    vm.org = vm.getOrgData();
  }

  function homeButton($event) {
    $event.preventDefault();

    $state.go(
      'app.overview.incidents.list',
      {
        environment: vm.defaultEnvId,
        folder: vm.hasPermissions ? 'active' : RoutesConstants.NO_PERMISSIONS_FOLDER,
      },
      { reload: true }
    );
  }

  function getOrgData() {
    const org = $ngRedux.getState().user.organization;
    const orgType = org && org.config && org.config.type ? org.config.type : organizationTypes[0];
    const orgName = org.name;
    return { orgType, orgName };
    // add notificationService.error('Can not get Account Type') when org type will be common;
  }

  function navigateToFeed() {
    $state.go('app.overview.incidents.list', {}, { reload: true });
  }

  $rootScope.$on('$stateChangeSuccess', function (event, next) {
    if (next.url !== vm.currentRouteName) {
      vm.currentRouteName = next.name;
    }
  });
}
