import {
  AllSizes, Badge, HBox, StatusEnum, Tooltip,
} from '@bp/kung-fu';
import React, { useEffect, useRef, useState } from 'react';

import provideStore from '../../../../../../../app/react/utils/provideStore';
import ThemeWrapper from '../../../../../../../app/react/utils/WithTheme';
import PriorityDropdown from '../../../../../incident-tags/src/PriorityDropdown/PriorityDropdown';
import dic from '../IncidentView.dic';
import { IIncidentViewHeader } from '../types';
import {
  BottomRow, ButtonsContainers, Content, ResizeButton, ResizeButtonWrapper,
  TimelineButton, UpperRow, UpperRowWrapper,
} from './Header.style';
import HeaderSubTitle from './header-subtitle/HeaderSubtitle';
import HeaderTitle from './header-title/HeaderTitle';

const IncidentViewHeader = ({
  openTimeline,
  priorityProps,
  titleData, incident,
  breakdownSources,
  showSmallButton,
  selectedPriorityId,
  showPriorityDd,
  toggleFullScreen,

}: IIncidentViewHeader): JSX.Element => {

  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef();

  useEffect(() => {
    const cachedRef = headerRef.current;
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      { threshold: [1] },
    );

    observer.observe(cachedRef);

    return () => {
      observer.unobserve(cachedRef);
    };
  });

  const {
    status, manual_correlation: manualCorrelation, maintenanceTitle, numMaintenanceAlerts,
  } = incident;

  return (
    <ThemeWrapper>
      <UpperRowWrapper ref={headerRef} isSticky={isSticky}>
        <UpperRow>
          <Content>
            <HBox gap="4px">
              <Badge status={status as StatusEnum} text={status} isCapitalized size={AllSizes.LARGE} />
              {manualCorrelation
                && (
                  <Tooltip text={dic.manual_badge_tooltip} placement="bottom">
                    <Badge status={StatusEnum.MANUAL} text={dic.manual_title} isCapitalized size={AllSizes.LARGE} />
                  </Tooltip>
                )}
            </HBox>
            {showPriorityDd
              && (
                <PriorityDropdown
                  fromList={false}
                  priorityTags={priorityProps.priorityTag}
                  incidentId={priorityProps.incidentId}
                  environmentId={priorityProps.envId}
                  updatePriorityTag={priorityProps.changeSelectedTag}
                  removePriorityTag={priorityProps.deleteSelectedTag}
                  priorityValue={selectedPriorityId}
                />
              )}

            <HeaderTitle titleData={titleData} />

          </Content>

          <ButtonsContainers>
            {!isSticky && (
              <TimelineButton onClick={openTimeline} data-product-id="view_incident_timeline">
                {!showSmallButton ? dic.timeline_title : ''}
              </TimelineButton>
            )}
            <ResizeButtonWrapper>
              <ResizeButton onClick={toggleFullScreen} />
            </ResizeButtonWrapper>
          </ButtonsContainers>

        </UpperRow>

      </UpperRowWrapper>

      <HBox padding="0 40px" marginTop="2px">
        <BottomRow>
          <HeaderSubTitle
            {...titleData}
            start={incident.start}
            breakdownSources={breakdownSources}
            maintenanceTitle={maintenanceTitle}
            numMaintenanceAlerts={numMaintenanceAlerts}
          />
        </BottomRow>
      </HBox>
    </ThemeWrapper>
  );
};

export default provideStore(IncidentViewHeader);
