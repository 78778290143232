/* eslint-disable max-len */
export default {
  modal_title: 'Alert View',
  add_item_button_text: 'New tag',
  add_item_button_aria_label: 'add new tag',
  validate_rule_name_error: 'Name is required',
  validate_source_system_error: 'Please select at least one source system',
  validate_tags_error: 'Please select at least one tag',
  new_positive_button_text: 'Create View',
  existing_positive_button_text: 'Update View',
  close_button_text: 'Cancel',
  source_system_filed_title: 'Source Systems',
  source_system_filed_placeholder: 'Select sources...',
  tag_select_placeholder: 'Choose alert tag',
  form_content_tooltip:
        'Manage your alert view by adding tags and defining order. Only the added tags will be shown by default in your alert views (Incident feed, Incident timeline and Unified Search). The other will be presented under "show more". There are 3 added common tags that you\'re able to remove if needed.',
  create_modal_title: 'Create a new Alert View',
  create_modal_button_text: 'New View',
  setting_screen_header_name: 'Alert View',
  filter_placeholder_text: 'Find Alert Views...',
  setting_screen_no_items_title: 'No alert view added yet',
  list_item_second_row_label: 'Source System',
  edit_button_title: 'Edit Alert View',
  delete_button_content: 'Are you sure you want to delete this Alert view?',
  table_header: 'Alert View’s Tags:',
  table_sub_header: 'This view will contain the following tags',
  item_title: 'item',
  items_title: 'items',
  order_title: 'Order',
  tag_name_title: 'Tag Name',
  active_title: 'active',
  inactive_title: 'inactive',
  status_title: 'Status:',
  source_system_title: 'Source System:',
  toggle_aria_label: 'Toggle tag status',
  toggle_prefix_title: 'Active:',
  no_alert_view_selected_title: 'NO ALERT VIEW SELECTED',
  no_alert_view_selected_subtitle: 'Select an alert view to see details.',
  help_tooltip_title: 'The order of the view in the execution plan',
  rule_name_label: 'Alert View Name',
  rule_name_placeholder: 'e.g. nagios',
  execution_order: 'Execution Order:',
  edit_title: 'Edit',
  remove_title: 'Remove',
  send_to_top_title: 'Send to top',
  send_to_bottom_title: 'Send to bottom',
  order_modal_title: 'Alert views execution order',
  order_modal_text: 'Define the execution order by moving the alert views to the desired location.',
  save_title: 'Save',
  cancel_title: 'Cancel',
  preview_title: 'Preview',
  refresh_title: 'Refresh',
  last_week_title: 'Last 7 days',
  order_modal_button_label: 'Edit alert view execution order',
  no_preview_message: 'Fill out the form to see the alerts view',
  no_results: 'No results found',
  additional_tags_disclaimer: 'Additional tags will be displayed under show more',
  alerts_table_no_results_title: 'Alerts Table Preview',
  alert_table_progress_bar_title: 'Preview progress bar',
  form_name_exists_error: 'Name already exists',
  collapse_button_open_text: 'Show less',
  collapse_button_close_text: 'Show more',
};
/* eslint-enable max-len */
