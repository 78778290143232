angular.module('bp.widgets').directive('reserved', ReservedValidator);

// The validator accepts a string as an attribute, which is the not-allowed reserved value.
function ReservedValidator() {
  return {
    require: 'ngModel',
    link: (scope, element, attr, ctrl) => {
      ctrl.$validators.reserved = (modelValue) => {
        if (!modelValue) {
          return true;
        }

        const reservedValue = attr.reserved;
        return modelValue.toLowerCase() !== reservedValue.toLowerCase();
      };
    },
  };
}
