angular.module('bigpanda').service('TopbarService', function (pubSubService, $location) {
  this.showTopbar = $location.$$path !== '/app/topology';
  this.hideSearchButton = false;

  this.shouldShowTopbar = () => this.showTopbar;
  this.shouldHideSearchButton = () => this.hideSearchButton;
  this.setHideSearchButton = (hide) => {
    this.hideSearchButton = hide;
  };

  this.setShowTopbar = (showTopbar) => {
    this.showTopbar = showTopbar;
    pubSubService.broadcast('topbar.toggle', showTopbar);
  };
});
