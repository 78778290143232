import {
  AllSizes, darkTheme, HBox, Link, SBHBox,
} from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { StyledText, Title, TooltipContainer } from './style';

export interface HelpTooltipProps {
  helpTitle: string;
  helpText: string;
  helpExample: string | React.ReactNode;
  docsLink: string | undefined;
}

export function HelpTooltip({
  helpTitle, helpText, helpExample, docsLink,
}: HelpTooltipProps): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <TooltipContainer>
        <SBHBox marginBottom="14px">
          <Title size={AllSizes.LARGE}>{helpTitle}</Title>
          <Link
            href={docsLink || 'https://docs.bigpanda.io/docs/alert-enrichment'}
            external
            size={AllSizes.SMALL}
          >
            See docs
          </Link>
        </SBHBox>

        <HBox marginBottom="12px">
          <StyledText lineHeight="1.5" size={AllSizes.SMALL}>
            {helpText}
          </StyledText>
        </HBox>

        <HBox>{helpExample}</HBox>
      </TooltipContainer>
    </ThemeProvider>
  );
}

export default HelpTooltip;
