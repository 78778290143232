angular.module('bp.widgets').directive('regexValidator', () => ({
  require: 'ngModel',
  link: (scope, elm, attrs, ctrl) => {
    ctrl.$validators.regexValidator = (modelValue) => {
      ctrl.regexError = undefined;
      if (ctrl.$isEmpty(modelValue)) {
        ctrl.regexError = "regex can't be empty";
        return false;
      }

      try {
        const regex = RegExp(modelValue);
        const groupCount = new RegExp(`${regex.toString()}|`).exec('').length - 1;
        if (groupCount < 1) {
          ctrl.regexError = 'Use exactly one Regex group';
          return false;
        }
      } catch (e) {
        ctrl.regexError = e.message;
        return false;
      }
      return true;
    };
  },
}));
