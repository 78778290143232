import get from 'lodash/get';
import React from 'react';

import { incidentTagClosedListTypes, incidentTagTypes } from '../../../../../app/react/modules/settings/incident_labels/constants';
import IncidentTagsGridCellV2MultiValue from './IncidentTagsGridCellV2MultiValue';
import IncidentTagsGridCellV2Text from './IncidentTagsGridCellV2Text';

function IncidentTagsGridCellV2ModalContent(props) {
  const {
    cellType, tagData, name, updateFromChild, closedListValues,
  } = props;

  function renderCell() {
    switch (cellType) {
      case incidentTagTypes.TEXT:
        return (
          <IncidentTagsGridCellV2Text
            tagValue={get(tagData, 'tag_value', '')}
            tagLabel={name}
            updateParent={updateFromChild}
          />
        );
      case incidentTagTypes.MULTI_VALUE:
      case incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE:
      case incidentTagClosedListTypes.CLOSED_LIST_VALUE_MULTI:
        return (
          <IncidentTagsGridCellV2MultiValue
            tagValue={get(tagData, 'tag_value', [])}
            tagLabel={name}
            updateParent={updateFromChild}
            type={cellType}
            closedListValues={closedListValues}
          />
        );
      default:
        return <div />;
    }
  }

  return renderCell();
}

export default IncidentTagsGridCellV2ModalContent;
