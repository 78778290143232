import React from 'react';
import { hot } from 'react-hot-loader';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { BamDropdownField, BamForm, BamInput } from '@bp/bam';
import './api_key_form.scss';

class ApiKeyForm extends React.PureComponent {
  handleSubmit = (values, form) => {
    const { currentApiKeyId: apiKeyId, apiKey, updateApiKey, onCreate, close } = this.props;

    if (apiKeyId && !form.getState().dirty) {
      close();
      return;
    }

    const { name, associatedUserId, description } = values;
    const active = apiKey && apiKey.active;
    if (apiKeyId) {
      updateApiKey({ name, associatedUserId, description, active, apiKeyId });
    } else {
      onCreate({ name, associatedUserId, description });
    }
    close();
  };

  validateName = (val) =>
    !val || val.length < 2 || val.length > 100
      ? 'Key name must be between 2 and 100 characters'
      : undefined;

  render() {
    const { currentApiKeyId, apiKey, users, currentUserId, close } = this.props;
    const currentUser = users.find((user) => user._id === currentUserId);
    const associatedUserId = apiKey ? apiKey.user._id : (currentUser || {})._id;

    const initialValues = apiKey ? { ...apiKey, associatedUserId } : { associatedUserId };

    return (
      <BamForm
        id="ApiKeyForm"
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        positiveButton={{
          text: `${currentApiKeyId ? 'Update Key' : 'Create Key'}`,
        }}
        closeButton={{
          text: 'Cancel',
          onClick: close,
        }}
      >
        <Field
          name="name"
          title="Key Name"
          component={BamInput}
          autoFocus
          aria-label="API key name"
          placeholder="Name of your API key"
          maxLength={100}
          validate={this.validateName}
        />
        <Field
          name="associatedUserId"
          title="Associated User"
          hint="The user which its identity and roles are used for API requests authorization and authentication"
          component={BamDropdownField}
          options={
            users ? users.map((user) => ({ key: user._id, text: user.name, value: user._id })) : []
          }
          closeOnBlur
        />
        <Field
          name="description"
          title="Description"
          component={BamInput}
          optional
          inputtype="textarea"
          placeholder="Type your description..."
          maxLength={250}
          maxRows={5}
        />
      </BamForm>
    );
  }
}

ApiKeyForm.propTypes = {
  updateApiKey: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  currentApiKeyId: PropTypes.string,
  apiKey: PropTypes.shape({
    user: PropTypes.shape({ _id: PropTypes.string }),
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
  }),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  currentUserId: PropTypes.string,
  close: PropTypes.func.isRequired,
};

ApiKeyForm.defaultProps = {
  currentApiKeyId: undefined,
  apiKey: undefined,
  users: undefined,
  currentUserId: undefined,
  onCreate: undefined,
};

export default hot(module)(ApiKeyForm);
