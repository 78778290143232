angular.module('bigpanda').directive('entityTitle', entityTitle);

function entityTitle() {
  return {
    restrict: 'E',
    scope: {
      entity: '<',
      showSource: '<',
    },
    templateUrl: 'overview/entities/title/entity_title',
    bindToController: true,
    controllerAs: 'titleVm',
    controller: controller,
  };

  function controller() {}
}
