export default {
  ai_placeholder_title: 'Click to perform AI Incident Analysis',
  ai_thumbs_up_title: 'Thumbs up',
  ai_thumbs_down_title: 'Thumbs down',
  ai_feedback_summary_title: 'Provide additional feedback',
  ai_submit_title: 'Submit',
  ai_regenerate_title: 'Regenerate',
  ai_regenerate_label: 'Regenerate AI analysis',
  ai_analysis_title: 'AI Analysis',
  ai_recreate_let_us_know_title: 'Let us know why',
  ai_copy_button_label: 'Copy AI analysis to clipboard',
  ai_optional: '(Optional)',
  ai_this_isnt_helpful_title: 'The analysis is unclear',
  ai_this_isnt_true_title: "The analysis isn't right",
  ai_this_is_right_but_not_helpful_title: 'The analysis is right, but unhelpful',
  ai_failed_to_generate_title: 'Failed to generate Automated Incident Analysis',
  ai_capacity_reached_title: 'Incidents cannot be analyzed more than 10 times',
  ai_summary_title: 'Summary',
  ai_root_cause_title: 'Root Cause Analysis',
  ai_reasoning_title: 'Reasoning',
  expand_title: 'Expand',
  collapse_title: 'Close',
};
