const { react2angular } = require('react2angular');
const RelatedChanges = require('react/modules/changes/RelatedChanges').default;

angular
  .module('bigpanda')
  .component(
    'reactRelatedChanges',
    react2angular(RelatedChanges, [
      'incident',
      'store',
      'statePath',
      'tableOnlyMode',
      'embed',
      'rccToggleDefault',
      'getData',
      'searchBpql',
      'summaryTextContent',
    ])
  );

angular.module('bigpanda').directive('relatedChanges', relatedChanges);

function relatedChanges() {
  return {
    restrict: 'E',
    scope: {
      getData: '=?',
      incident: '=',
      statePath: '=',
      tableOnlyMode: '=',
      embed: '=',
      showOnlyRccToggle: '=',
      rccToggleDefault: '=',
      rccOnly: '=',
      searchBpql: '=',
      summaryTextContent: '=',
    },
    templateUrl: 'overview/incidents/info/changes/react_related_changes',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($scope, $state, IncidentsService, stateService, $ngRedux) {
    const vm = this;
    vm.store = $ngRedux;
    vm.getData = null;
    function getData(reqData) {
      IncidentsService.getIncidentChangesData({
        ...reqData,
        showOnlyRccToggle: vm.showOnlyRccToggle,
      });
    }

    this.$onInit = init;
    function init() {
      vm.getData = vm.getData || getData;
    }
  }
}
