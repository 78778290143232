import trim from 'lodash/trim';
import spread from 'lodash/spread';
import find from 'lodash/find';
import get from 'lodash/get';
import extend from 'lodash/extend';
import isEnvironmentFilteredByIncidentTags from '../../../workspaces/apps/environment/src/helpers/isEnvironmentFilteredByIncidentTags';
import { completeStep } from '../../../workspaces/apps/onboarding/src/endpoints/onboarding';
import dic from '../../../workspaces/apps/onboarding/src/dictionary';
import UserPreferencesToggle from './userPreferencesToggle';
angular.module('bigpanda').directive('incidents', incidents);
// We're exporting the controller in order to use it in the tests without having to load the whole directive and
// everything below it
angular.module('bigpanda').controller('incidentsController', controller);

const { react2angular } = require('react2angular');

angular
  .module('bigpanda')
  .component('userPreferencesToggle', react2angular(UserPreferencesToggle, []));

function incidents() {
  return {
    require: {
      checkedIncidentsState: 'checkedIncidentsState',
    },
    scope: {},
    templateUrl: 'overview/incidents/incidents',
    controllerAs: 'vm',
    bindToController: true,
    controller: controller,
  };
}

function controller(
  $ngRedux,
  $scope,
  $q,
  $mdSidenav,
  $mdMedia,
  $log,
  $stateParams,
  $state,
  $timeout,
  pubSubService,
  notificationService,
  PersonalSettingsStore,
  EnvironmentsService,
  stateService,
  UserIntegrationsStore,
  UserIntegrationsUtils,
  CustomizationsStore,
  BPQLService,
  CustomizationsUtils,
  UserFeatureTogglesService,
  SortingStore,
  FeedModeStore,
  TopbarService,
  FullscreenStore,
  SessionTimeoutWarningService,
  EnvironmentsCountersStore,
  BpqlUtils,
  EntityPersistencyStore,
  Permissions,
  LabelsStore,
  AssigneeFilterStore
) {
  const vm = this;
  const envId = stateService.getSelectedEnvironmentId();
  const folderId = stateService.getSelectedFolderId();
  let filterToggle = false;

  LabelsStore.getMultipleIncidentTagDefinitions(envId);

  vm.showSmallButton = false;
  vm.mdMedia = $mdMedia;
  vm.detachedFilter = '';
  vm.emptyList = false;
  vm.manualRefresh = false;
  vm.selectedIncidentId = stateService.getSelectedIncidentId();
  vm.checkCount = 0;
  vm.feedModeFeatureToggle = FeedModeStore.getToggle();
  vm.sortOptions = [];
  vm.disableQueryHelper = UserFeatureTogglesService.getToggle('disable_query_helper');
  vm.feedModeOptions = [
    { display: 'Live Updates', value: true },
    { display: 'Manual Updates', value: false },
  ];
  vm.fullscreen = false;

  vm.search = search;
  vm.toggleFavoriteStatus = toggleFavoriteStatus;
  vm.changeIncidentsFeedSorting = changeIncidentsFeedSorting;
  vm.toggleFullscreen = toggleFullscreen;
  vm.isFeedV2 =
    UserFeatureTogglesService.getToggle('incident_console_v2') ||
    (UserFeatureTogglesService.getToggle('console_v2_super_admin') &&
      get($ngRedux.getState(), 'user.permissions.roles', []).some((r) => r.key === 'superadmin'));
  vm.searchWithIncidentTagsFt = UserFeatureTogglesService.getToggle('enable_namespace_for_search');
  vm.feedModeCallback = (selection) => {
    FeedModeStore.setFeedMode(selection.value);
  };

  vm.showFilter = () =>
    $mdMedia('gt-xs') ? !vm.checkedIncidentsState.isAnythingChecked() : filterToggle;
  vm.unselectAll = unselectAll;

  $scope.$on('$destroy', () => {
    TopbarService.setShowTopbar(true);
  });

  vm.$onInit = onInit;

  vm.currentEnvActions = {
    delete: Permissions.checkGranularPermission('action@delete', 'environments', envId),
    update: Permissions.checkGranularPermission('action@update', 'environments', envId),
  };

  function stateChanged(event, data) {
    vm.incidentFullScreen = data.incidentFullScreen;
  }

  function onInit() {
    shouldDisableSearch($stateParams.environment);
    pubSubService.on('Environment.Updated', onEnvUpdate, $scope);
    pubSubService.on('Environment.Deleted', onEnvDelete, $scope);
    pubSubService.on('Topbar.toggleSearchBar', onTopbarSearchToggle, $scope);
    ['streamConnector.reconnected', 'Envy.refresh'].forEach((e) =>
      pubSubService.on(e, () => initEnvironmentData(e, true), $scope)
    );
    pubSubService.on('incidentTagsDefinitions.updated', updateSortOptions, $scope);
    pubSubService.on('incidentfullscreen', stateChanged, $scope);

    vm.incidentFullScreen = $stateParams.incidentfullscreen;

    CustomizationsStore.subscribe({ storeUpdated: isEnvFavorite }, $scope);
    EnvironmentsCountersStore.subscribe($scope, { storeUpdated: refreshCounter });
    AssigneeFilterStore.subscribe(
      {
        storeUpdated: () => {
          updateAssignee();
          search();
        },
      },
      $scope
    );

    if (
      UserFeatureTogglesService.getToggle('is_onboarding_enable') &&
      $stateParams.tour &&
      $stateParams.tour === dic.maturity_levels.starter
    ) {
      completeStep(dic.onboardingSteps.environment_basic);
    }
    updateSortOptions();
    setupFullscreen();
    AssigneeFilterStore.resetAssignee();

    FeedModeStore.getFeedMode().then((feedMode) => {
      vm.selectedFeedMode = feedMode;
    });

    FeedModeStore.subscribe($scope, {
      storeUpdated: () => {
        FeedModeStore.getFeedMode().then((feedMode) => {
          vm.selectedFeedMode = feedMode;
          const data = {
            environment: vm.env.name,
            incidents_feed_mode: vm.selectedFeedMode ? 'live' : 'manual',
          };
          pubSubService.broadcast('feed.mode', data);
        });
      },
    });

    updateCheckCount();
    vm.checkedIncidentsState.subscribe($scope, {
      storeUpdated: updateCheckCount,
    });

    initEnvironmentData(null, true);

    UserIntegrationsStore.getIntegrations().then((integrations) => {
      vm.hasIntegrations = UserIntegrationsUtils.hasActiveIntegrations(integrations);
    });

    PersonalSettingsStore.getUser().then((user) => {
      vm.localUserId = user._id;
      userSessionHandler(user.username);
    });

    SortingStore.getSortBy().then((sortBy) => {
      vm.sortBy = sortBy;
    });

    FullscreenStore.subscribe($scope, {
      storeUpdated: () => {
        $state.go(
          'app.overview.incidents.list',
          extend({}, $stateParams, { fullscreen: vm.fullscreen ? null : 'true' })
        );
      },
    });

    pubSubService.on('tabs.resizing', (event, data) => shouldShowSmallButton(data), $scope);

    // We have to use a timeout here, because we want to ensure the element is rendered and has dimensions.
    $timeout(() => {
      shouldShowSmallButton();
    });
  }

  function shouldDisableSearch(environmentId) {
    EnvironmentsService.getEnvById(environmentId).then((env) => {
      vm.disabled = isEnvironmentFilteredByIncidentTags(env, vm.searchWithIncidentTagsFt);
    });
  }

  function updateSortOptions() {
    const priorityTag = LabelsStore.getPriorityDefinition();
    vm.sortOptions = [
      {
        display: 'Last Changed',
        value: SortingStore.options.lastChange,
        btnText: !$stateParams.fullscreen ? '' : 'Last Change',
      },
      { display: 'Status', value: SortingStore.options.status, btnText: 'Status' },
      { display: 'Created', value: SortingStore.options.start, btnText: 'Created' },
      { display: 'No. of Alerts', value: SortingStore.options.alerts, btnText: 'No. of Alerts' },
      { display: 'Duration', value: SortingStore.options.duration, btnText: 'Duration' },
    ];
    if (priorityTag.active) {
      const priorityTagName = priorityTag.name || 'Priority';
      vm.sortOptions.splice(1, 0, {
        display: priorityTagName,
        value: SortingStore.options.label_priority,
        btnText: priorityTagName,
        disabled: hasSearchOrFilters(),
        disabledTooltip: `Sorting by ${priorityTagName.toLowerCase()} is unavailable if filters are applied`,
      });
    }
  }
  function shouldShowSmallButton(data) {
    if (data) {
      vm.showSmallButton = data.left < 350;
    }
  }

  function userSessionHandler(username) {
    const sessionUser = EntityPersistencyStore.getPersistency('', 'username');
    if (sessionUser && sessionUser !== username) {
      EntityPersistencyStore.setPersistency('', 'username', username);
      EntityPersistencyStore.clearPersistency();
    }

    if (!sessionUser) {
      EntityPersistencyStore.setPersistency('', 'username', username);
    }
  }

  function setupFullscreen() {
    FullscreenStore.setFullscreenState($stateParams.fullscreen);
    vm.fullscreen = FullscreenStore.getFullscreen();
    TopbarService.setShowTopbar(!vm.fullscreen);
    if (vm.fullscreen) {
      SessionTimeoutWarningService.startReoccurringTokenRenewal($scope);
    } else {
      SessionTimeoutWarningService.stopReoccurringTokenRenewal();
    }
  }

  function toggleFullscreen() {
    FullscreenStore.setFullscreenState(!vm.fullscreen);
    if (!vm.fullscreen) {
      pubSubService.broadcast(
        'wallboard.feed.opened',
        extend(
          { state: vm.fullscreen },
          {
            sortMode: vm.sortBy,
            feedMode: vm.selectedFeedMode,
          }
        )
      );
    } else {
      pubSubService.broadcast('wallboard.feed.close');
    }
  }

  function updateCheckCount() {
    vm.checkCount = vm.checkedIncidentsState.getCheckedIncidentIds().length;
  }

  function unselectAll() {
    vm.checkedIncidentsState.clear();
  }

  function onIncidentNewData() {
    EnvironmentsService.getEnvById(envId).then((environment) => {
      vm.env = environment;
      vm.folder = find(vm.env.folders, { id: folderId });

      refreshCounter();
    });
  }

  function onEnvUpdate(event, env, userId, username) {
    if (envId === env._id || envId === env.id) {
      vm.env = env;
      vm.folder = find(vm.env.folders, { id: folderId });

      refreshCounter();

      handleEnvAction('updated', env._id, userId, username);
    }
  }

  function onEnvDelete(event, environmentId, userId, username) {
    handleEnvAction('removed', environmentId, userId, username);
  }

  function onTopbarSearchToggle() {
    filterToggle = !filterToggle;
    $scope.$digest();
  }

  function initEnvironmentData(event, forceReload) {
    if (folderId === 'search') return;

    const environmentsPromise = EnvironmentsService.getEnvById(envId, forceReload);
    const customizationsPromise = CustomizationsStore.getCustomizations().catch((err) => {
      $log.error(`Failed getting user customizations: ${err}`);
      notificationService.error('Error getting user customizations', { error: err });
    });

    $q.all([environmentsPromise, customizationsPromise]).then(
      spread(
        (environment, customizations) => {
          vm.env = environment;
          vm.folder = find(vm.env.folders, { id: folderId });

          refreshCounter();

          vm.isFavorite = find(
            customizations ? customizations.environments.navigation_list.favorites : [],
            (favorite) => favorite === vm.env._id
          );
        },
        () => {
          vm.emptyList = true;
        }
      )
    );
  }

  function handleEnvAction(action, environmentId, userId, username) {
    if (vm.env._id === environmentId || vm.env.id === environmentId) {
      if (vm.localUserId !== userId) {
        $mdSidenav('incident-info').close();
        vm.manualRefresh = true;
        vm.action = action;
        vm.username = username;
      }
    }
  }

  function toggleFavoriteStatus() {
    CustomizationsStore.getCustomizations()
      .then((customizations) => {
        const favorites = CustomizationsUtils.updateFavorites(
          customizations.environments.navigation_list.favorites,
          vm.env,
          !vm.isFavorite,
          'Headline'
        );
        return CustomizationsStore.updateCustomizations({
          environments: { navigation_list: { favorites: favorites } },
        });
      })
      .catch((err) => {
        $log.error(`Failed updating user customizations: ${err}`);
        notificationService.error('Error updating stars', { error: err });
      });
  }

  function isEnvFavorite() {
    CustomizationsStore.getCustomizations()
      .then((customizations) => {
        vm.isFavorite = find(
          customizations.environments.navigation_list.favorites,
          (favorite) => favorite === vm.env._id
        );
      })
      .catch((err) => {
        $log.error(`Failed getting user customizations: ${err}`);
        notificationService.error('Error getting user customizations', { error: err });
      });
  }

  function search() {
    let bpqlQuery = '';
    let bpqlQueryString = '';

    if (hasSearchOrFilters()) {
      const parser = BPQLService.buildParserFromGrammer();
      try {
        bpqlQueryString = BpqlUtils.formatBpqlQuery(vm.assignee, vm.searchValue);
        bpqlQuery = parser(bpqlQueryString);
        vm.displayedSearchTerm = angular.copy(vm.searchValue);
        pubSubService.broadcast('Incidents.search', { searchValue: bpqlQueryString });
      } catch (e) {
        e.queryText = vm.searchValue;
        vm.incidentsSearchNotification = {
          type: 'error',
          msg: e.message,
          cls: 'error',
        };
        throw e;
      }
    }

    updateSortOptions();

    pubSubService.broadcast('incident.search', bpqlQuery, vm.searchValue, vm.assignee);
  }

  function hasSearchOrFilters() {
    return trim(vm.searchValue) || vm.assignee;
  }

  function updateAssignee() {
    const { unassignedId, getAssignee } = AssigneeFilterStore;
    const assignee = getAssignee() || {};
    vm.assignee =
      !assignee.bigpanda_user_id || assignee.bigpanda_user_id === unassignedId
        ? assignee.bigpanda_user_id
        : assignee.email;
  }

  function changeIncidentsFeedSorting(selection) {
    vm.sortBy = selection.value;
    SortingStore.updateSortBy(selection.value);
  }

  function refreshCounter() {
    if (!vm.folder) {
      return;
    }
    return EnvironmentsCountersStore.getEnvFolderCounter(vm.env, vm.folder.id).then(
      (folderCounter) => {
        vm.folderCounter = folderCounter;
      }
    );
  }
}
