import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import provideStore from 'react/utils/provideStore';
import styles from './sso_integration_instructions.scss';
import Tabs from '../../SSOProviderTabs';
import tutorials from './tutorials';
import selectors from '../../selectors';
import { configureSSOProvider } from '../../actions';
import {
  Tutorial,
  Content,
  HorizontalLayout,
  Title,
  Description,
  Badge,
  TabsBar,
} from '../components';

class SSOIntegrationInstructions extends React.Component {
  state = {
    currentProvider: this.props.selectedProvider,
  };

  changeProvider = (selectedProvider) => {
    this.setState({ currentProvider: selectedProvider.name });
    this.props.selectProvider(selectedProvider.name);
  };

  configureSSOProvider = (values) => {
    values.provider = this.state.currentProvider;
    this.props.configureSSOProvider(values);
  };

  render() {
    const { currentProvider } = this.state;
    const { configurationSent, featureToggles, organization, updateAndLogout } = this.props;
    const SelectedProviderTutorial = currentProvider ? tutorials[currentProvider] : () => null;
    const isProviderConfigSent = currentProvider === configurationSent;
    const displayTabs = Tabs.filter(
      (tab) => !tab.featureToggle || featureToggles[`sso/${tab.name}`]
    );
    return (
      <Tutorial>
        <div className={styles.spacer} />
        <HorizontalLayout>
          <Badge>1</Badge>
          <Content>
            <Title>Select One of the Following</Title>
            <Description>
              A Single Sign-on provider gives you the ability to connect with and utilize existing
              user profiles from your Directory Service, such as LDAP or Active Directory.
            </Description>
            <TabsBar
              tabs={displayTabs}
              onTabClick={this.changeProvider}
              isFixed
              fixedHeight={66}
              fixedWidth={132}
              selectedTab={currentProvider}
            />
          </Content>
        </HorizontalLayout>
        <SelectedProviderTutorial
          configureProvider={this.configureSSOProvider}
          isConfigurationSent={isProviderConfigSent}
          organization={organization}
          updateAndLogout={updateAndLogout}
        />
      </Tutorial>
    );
  }
}

SSOIntegrationInstructions.propTypes = {
  configureSSOProvider: PropTypes.func.isRequired,
  configurationSent: PropTypes.string.isRequired,
  featureToggles: PropTypes.shape({ [PropTypes.string]: PropTypes.bool }).isRequired,
  selectedProvider: PropTypes.string.isRequired,
  selectProvider: PropTypes.func.isRequired,
  updateAndLogout: PropTypes.func.isRequired,
  organization: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  configurationSent: selectors.isConfigurationSent(state),
  featureToggles: featureTogglesSelectors.getFeatureToggles(state),
  organization: selectors.getOrganizationName(state),
});

const mapDispatchToProps = {
  configureSSOProvider,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hot(module)(SSOIntegrationInstructions));
