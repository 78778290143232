import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import find from 'lodash/find';
angular.module('bigpanda').service('CustomizationsStore', CustomizationsStore);

function CustomizationsStore(
  $q,
  CustomizationsBackendService,
  SubscribersService,
  deepCloneObject
) {
  this.getCustomizations = getCustomizations;
  this.updateCustomizations = updateCustomizations;
  this.subscribe = subscribe;

  let customizationsCache;
  const subscribers = new SubscribersService();

  function getCustomizations() {
    const customizationsPromise = customizationsCache
      ? $q.when(customizationsCache)
      : CustomizationsBackendService.getCustomizations().then((rawCustomizations) => {
          const mergedCustomizations = mergeOrgAndUserCustomizations(rawCustomizations);
          customizationsCache = mergedCustomizations.customizations;
          return deepCloneObject.cloneDeep(customizationsCache);
        });

    return customizationsPromise;
  }

  function subscribe(subscriber, scope) {
    subscribers.subscribe(scope, subscriber);
  }

  function updateCustomizations(patchCustomizations) {
    return CustomizationsBackendService.updateCustomizations(patchCustomizations).then(
      (rawCustomization) => {
        customizationsCache = rawCustomization;
        subscribers.fireStoreUpdated();
      }
    );
  }

  function mergeOrgAndUserCustomizations(customizationsList) {
    const orgCustomizations = find(customizationsList, { parent_type: 'organization' }) || {};
    const userCustomizations = find(customizationsList, { parent_type: 'user' }) || {};
    return mergeWith(
      {},
      orgCustomizations,
      userCustomizations,
      mergeWithoutOverridingArraysWithEmptyArrays
    );
  }

  function mergeWithoutOverridingArraysWithEmptyArrays(destinationValue, sourceValue) {
    if (isArray(sourceValue)) {
      if (sourceValue.length === 0) {
        return destinationValue || [];
      }
      return sourceValue;
    }

    return undefined;
  }
}
