import reject from 'lodash/reject';
import map from 'lodash/map';
import includes from 'lodash/includes';
angular.module('bigpanda').directive('uniqueDashboardNameValidator', uniqueDashboardNameValidator);

function uniqueDashboardNameValidator($q, DashboardsStore) {
  return {
    restrict: 'A',
    bindToController: true,
    controller: controller,
    require: {
      ngModelCtrl: 'ngModel',
    },
  };

  function controller($scope, $attrs) {
    const vm = this;
    let dashboardId;

    vm.$onInit = onInit;

    function onInit() {
      let dashboardNamesPromise = getDashbordNames(dashboardId);
      $attrs.$observe('dashboardId', () => {
        dashboardId = $attrs.dashboardId;

        dashboardNamesPromise = getDashbordNames(dashboardId);
        dashboardNamesPromise.then(() => {
          vm.ngModelCtrl.$validate();
        });
      });

      vm.ngModelCtrl.$asyncValidators.uniqueName = (modelValue, viewValue) => {
        const dashboardName = modelValue || viewValue;

        if (vm.ngModelCtrl.$isEmpty(dashboardName)) {
          return $q.when(true);
        }

        return dashboardNamesPromise.then((names) => {
          if (includes(names, dashboardName)) {
            return $q.reject();
          }
          return true;
        });
      };
    }

    function getDashbordNames(ignoredDashboardId) {
      return DashboardsStore.getDashboards().then((dashboards) => {
        if (ignoredDashboardId) {
          return map(reject(dashboards, { _id: ignoredDashboardId }), 'name');
        }

        return map(dashboards, 'name');
      });
    }
  }
}
