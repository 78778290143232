import { bpColors } from '../../../settings/incident_labels/constants';

const creatableSelectCustomStyles = (error) => ({
  container: (base, state) => ({
    ...base,
    margin: '-5px -5px 10px -5px',
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: '2px 2px',
  }),
  control: (base, state) => ({
    ...base,
    minHeight: '32px',
    maxHeight: '67px',
    maxWidth: '100%',
    overflowY: 'auto',
    borderColor: error ? bpColors.bp_red : bpColors.bp_blue,
    boxShadow: error ? bpColors.bp_red : bpColors.bp_blue,
    ':hover': {
      borderColor: error ? bpColors.bp_red : bpColors.bp_blue,
      boxShadow: error ? bpColors.bp_red : bpColors.bp_blue,
    },
    borderRadius: 3,
  }),
  multiValue: (base, state) => ({
    ...base,
    border: `1px solid ${bpColors.bp_gray_03}`,
    borerRadius: '3px',
    background: bpColors.bp_gray_01,
    height: '22px',
    overflow: 'unset',
    ':hover': {
      background: bpColors.bp_white,
      color: bpColors.bp_gray_07,
      cursor: 'pointer',
    },
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    fontSize: '12px',
    display: 'inline-block',
    width: '100%',
    verticalAlign: 'middle',
    lineHeight: 'normal',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    padding: '1px 3px 0 0',
    ':hover': {
      cursor: 'pointer',
      background: bpColors.bp_gray_01,
      '>svg': {
        fill: bpColors.bp_gray_07,
      },
    },
    '>svg': {
      fill: bpColors.bp_gray_05,
      padding: '1px',
    },
  }),
  option: (base, state) => ({
    ...base,
    padding: '0',
  }),
  menuPortal: (base, state) => ({
    ...base,
    zIndex: 99999,
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: '2px',
    left: '1px',
    right: '1px',
    width: 'unset',
    borderRadius: 3,
    border: `1px solid ${bpColors.bp_gray_04}`,
    boxShadow: '0 0 0 0 rgba(0,0,0,0.1), 0 4px 11px rgba(0,0,0,0.1)',
  }),
});

export default creatableSelectCustomStyles;
