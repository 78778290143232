import { BamDetails } from '@bp/bam';
import {
  AllSizes,
  Badge,
  CodeSizes,
  StatusEnum,
  Text,
} from '@bp/kung-fu';
import capitalize from 'lodash/capitalize';
import React from 'react';
import styled from 'styled-components';

import { Anchorize } from '../../../../../../app/react/common/components';
import { EnrichmentRule } from '../../../types';
import dictionary from '../../dictionary';
import {
  ruleValueFormatByType,
  valueByType,
} from '../../helpers/utils';

const CodeText = styled(Text).attrs({ size: CodeSizes.SMALL_CODE })`
  color: (p): string => p.theme.bp_gray_08
`;

interface IProps {
  item: EnrichmentRule;
  onClose: () => void;
}

export function EnrichmentRuleDetails({ onClose, item }: IProps): JSX.Element {
  const {
    active,
    type,
    sourceSystems,
    displayQuery,
    note,
    config: { name },
  } = item;

  const statusText = active ? 'active' : 'inactive';

  const sourceSystemsNames = sourceSystems.map(({ display }) => display).join(', ');

  const tags = [
    {
      tag: dictionary.rule_details_status,
      value: (<Badge text={statusText} status={active ? StatusEnum.OK : StatusEnum.OFF} isCapitalized size={AllSizes.SMALL} />),
    },
    { tag: dictionary.rule_details_function, value: <CodeText>{capitalize(type)}</CodeText> },
    {
      tag: dictionary.rule_details_value,
      value: (
        <CodeText>
          {ruleValueFormatByType({ type, name, value: valueByType(item) })}
        </CodeText>
      ),
    },
    {
      tag: dictionary.rule_details_source,
      value: <CodeText>{sourceSystemsNames}</CodeText>,
    },
    { tag: dictionary.rule_details_condition, value: <CodeText>{displayQuery}</CodeText> },
    {
      tag: dictionary.rule_details_note,
      value: <Anchorize Wrapper={CodeText} input={note} breakLines />,
    },
  ];

  return (
    <BamDetails>
      <BamDetails.BamDetailsHeader onClose={onClose} title={dictionary.rule_details_item_title} />
      <BamDetails.BamDetailsBody>
        <BamDetails.BamDetailsTags tags={tags} />
      </BamDetails.BamDetailsBody>
    </BamDetails>
  );
}

export default EnrichmentRuleDetails;
