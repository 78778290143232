import isEmpty from 'lodash/isEmpty';

const IncidentsTitle = 'Incidents';
export const singleIncidentRoute = 'app.overview.incidents.incident-info';

const routesToTitles = {
  [singleIncidentRoute]: 'Incident Overview',
  'app.overview': IncidentsTitle,
  'app.investigator': 'Unified Search',
  'app.dashboards': 'Dashboards',
  'app.reports': 'Analytics',
  'app.unified-analytics': 'Analytics',
  'app.integrations': 'Integrations',
  'app.default': '',
};

export const getPageTitleByRoute = (routeName) => {
  if (isEmpty(routeName)) {
    return IncidentsTitle;
  }

  const requiredRoute = Object.keys(routesToTitles).find((route) => routeName.indexOf(route) === 0);

  return routesToTitles[requiredRoute];
};

export const routesConstants = {
  NO_PERMISSIONS_ENV: 'no-permissions',
  NO_PERMISSIONS_FOLDER: 'no-folder',
  ACTIVE: 'active',
};
