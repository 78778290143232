import filter from 'lodash/filter';
import find from 'lodash/find';
import keys from 'lodash/keys';

// eslint-disable-next-line import/prefer-default-export
export function parseQuery(
  query,
  defaultSourceSystem = null,
  allowSourceAsQuery = false,
  parseSourceSystem = true
) {
  const queryKeys = keys(query);
  if (queryKeys.length === 1 && queryKeys[0] === 'AND') {
    return handleMultipleQueries(query, defaultSourceSystem, parseSourceSystem);
  }
  return handleSingleQuery(query, defaultSourceSystem, allowSourceAsQuery);
}

function handleMultipleQueries(query, defaultSourceSystem, parseSourceSystem = true) {
  const sourceQuery =
    parseSourceSystem && find(query.AND, (queryItem) => !!getSourceSystems(queryItem));

  let sourceSystems;
  let pureQuery;
  if (!sourceQuery) {
    sourceSystems = defaultSourceSystem;
    pureQuery = query;
  } else {
    sourceSystems = getSourceSystems(sourceQuery);
    if (!sourceSystems) {
      console.error('Could not get "source_system" from Correlation Query');
      return null;
    }
    const pureItems = filter(query.AND, (queryItem) => !getSourceSystems(queryItem));

    if (pureItems.length < 1) {
      pureQuery = {};
    } else if (pureItems.length === 1) {
      pureQuery = pureItems[0];
    } else if (pureItems.length > 1) {
      pureQuery = { AND: pureItems };
    }
  }

  return { pureQuery, sourceSystems };
}

function handleSingleQuery(query, defaultSourceSystem, allowSourceAsQuery) {
  const sourceSystems = getSourceSystems(query) || defaultSourceSystem;
  if (!sourceSystems) {
    console.error('Could not get "source_system" from Correlation Query');
    return null;
  }

  if (query && queryIsSourceSystem(query) && allowSourceAsQuery) {
    return { pureQuery: query, sourceSystems };
  }

  if (!query || !defaultSourceSystem || queryIsSourceSystem(query)) {
    return { pureQuery: {}, sourceSystems };
  }

  return { pureQuery: query, sourceSystems };
}

function queryIsSourceSystem(query) {
  if (query.IN) {
    return query.IN.length && query.IN[0] === 'source_system';
  } else if (query['=']) {
    return query['='].length && query['='][0] === 'source_system';
  }
  return false;
}

function getSourceSystems(query) {
  const queryKeys = keys(query);
  if (queryKeys.length !== 1) {
    return null;
  }

  let property;
  if (queryKeys[0] === '=' && query['='][0] === 'source_system') {
    property = '=';
  } else if (queryKeys[0] === 'IN') {
    property = 'IN';
  }

  if (property) {
    const queryArr = query[property];
    if (queryArr.length !== 2 || queryArr[0] !== 'source_system') {
      return false;
    }
    const value = queryArr[1];
    return Array.isArray(value) ? value : [value];
  }

  return false;
}
