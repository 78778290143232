/* eslint-disable import/prefer-default-export */
export const itemsMaxHeight = 340;
export const toggleHeight = 34;
export const searchBoxHeight = 40;
export const marginFromWindow = 10;
export const minItemsForSearch = 8;

export const variants = {
  fill: 'fill',
  filter: 'filter',
};
