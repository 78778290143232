import React from 'react';

import { colors as colorsArray } from '../../constants';
import styles from './PriorityForm.scss';

export const generatedColorsOptions = colorsArray.map((option) => ({
  text: option.nameToPresent,
  value: option.name,
  icon: {
    component: <div className={styles.colorDot} style={{ background: option.value }} />,
  },
}));

export const getActiveLevels = (incidentLabel) =>
  incidentLabel.config.ordered_list.reduce((activeLevels, item) => {
    if (item.active) {
      activeLevels[item.order_id] = item.order_id;
    }
    return activeLevels;
  }, {});

export const nameFieldTooltipLogic = (input) => {
  if (input.length > 5 && input.length <= 7) {
    return { shouldShow: true, message: 'We recommend using up to 5 characters' };
  }
  return {
    shouldShow: false,
    message: '',
  };
};

export const validateDisplayNameField = (value) => {
  if (!value) {
    return 'Min 1 chars';
  }
  if (value.length > 10) {
    return 'Max 10 chars';
  }
  return undefined;
};

export const activeToggleTooltipLogic = (allowDeactivation, levelIsActive, tagName) => {
  let content = '';
  let position;
  if (levelIsActive && !allowDeactivation) {
    content = 'At least one active level is needed';
    position = 'right';
  } else {
    content = (
      <div className={styles.deactivateTooltip}>
        <div className={styles.deactivateTooltip__title}>Deactivating level</div>
        <div>
          Turning this toggle off will deactivate this level and would hide it from the incident{' '}
          {tagName.toLowerCase()} menu
        </div>
      </div>
    );
    position = 'bottom-start';
  }
  return {
    content,
    position,
  };
};
