angular.module('bigpanda.reports').directive('bpReportEditPopover', bpReportEditPopover);

function bpReportEditPopover($window, $animate, $popover) {
  return {
    restrict: 'EA',
    scope: {
      template: '@',
      placement: '@',
      onShow: '&',
      onHide: '&',
      reportId: '@',
    },

    link: (scope, element) => {
      const eventDeregistrationFns = [];

      scope.popover = $popover(element, {
        scope: scope,
        animation: 'none',
        template: scope.template,
        placement: scope.placement,
        trigger: 'manual',
        prefixEvent: 'bpPopover',
        container: 'body',
        viewport: { selector: 'body' },
      });

      // Ah, it's not a challenge without monkey-patching. Turns out that
      // the lovely $tooltip destroys its scope when hiding, which means
      // that our child scope is cut off from the $digest cycle. Which basically
      // means no databinding for any view.
      //
      // Solution - manually hide the fucker and manually destroy it,
      // when we goddamn feel like it.
      function hidePopover() {
        scope.popover.$scope.$$postDigest(() => {
          // eslint-disable-line angular/no-private-call
          $animate.leave(scope.popover.$element).then(() => {
            scope.popover.$scope.$emit('bpPopover.hide', scope.popover.$tooltip);
            scope.popover.$isShown = false;
          });
        });
      }
      scope.popover.$scope.hidePopover = hidePopover;

      element.on('click', () => {
        if (scope.popover.$isShown) {
          hidePopover();
          return;
        }

        scope.popover.$scope.$apply(scope.popover.show);

        // Replicate $tooltip's autoClose: true behavior. Note that the
        // window event handler is a one-shot handler so it won't interfere
        // with the activation of the popover and that it is added post-digest
        // so it won't be activated during the bubbling of this event.
        //
        // The reason stopPropagation isn't used is that it interferes with
        // closing the popover when other popovers are activated.
        scope.popover.$scope.$$postDigest(() => {
          // eslint-disable-line angular/no-private-call
          angular.element($window.document).one('click', () => {
            scope.popover.$scope.$apply(scope.popover.$scope.hidePopover);
          });
        });
      });

      // Clean-up when we're going away
      scope.$on('$destroy', () => {
        scope.popover.destroy();
        eventDeregistrationFns.forEach((dereg) => dereg());
      });

      if (angular.isDefined(scope.onShow)) {
        eventDeregistrationFns.push(
          scope.$on('bpPopover.show', () => {
            scope.$apply(scope.onShow);
          })
        );
      }

      if (angular.isDefined(scope.onHide)) {
        eventDeregistrationFns.push(
          scope.$on('bpPopover.hide', () => {
            scope.$apply(scope.onHide);
          })
        );
      }
    },
  };
}
