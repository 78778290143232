import React from 'react';
import PropTypes from 'prop-types';
import LogoutButton from './LogoutButton';
import {
  HorizontalLayout,
  Content,
  Note,
  Title,
  Description,
  Steps,
  Step,
  Badge,
  CodeSpan,
  SSOForm,
} from '../../components';
import { getRandomId } from './helpers';

const formId = getRandomId();

const OktaInstructions = ({
  configureProvider,
  isConfigurationSent,
  organization,
  updateAndLogout,
}) => (
  <div>
    <HorizontalLayout>
      <Badge>2</Badge>
      <Content>
        <Title>Add the BigPanda App to Okta</Title>
        <Steps>
          <Step>Log in to your Okta account.</Step>
          <Step>
            Navigate to the <strong>Admin</strong> section.
          </Step>
          <Step>
            Go to the <strong>Applications</strong> tab.
          </Step>
          <Step>
            Click on <strong>Add Application</strong>.
          </Step>
          <Step>
            Search for and find the <strong>BigPanda</strong> app, and click <strong>Add</strong>.
          </Step>
          <Step>
            In the <strong>General Settings</strong> page, enter{' '}
            <CodeSpan canBeCopied>{organization}</CodeSpan> into the <strong>Company Name</strong>{' '}
            field and click <strong>Next</strong>.
          </Step>
          <Step>
            Assign BigPanda to the relevant users. Ensure that their username matches their username
            in BigPanda.
          </Step>
          <Step>
            Finish the wizard by clicking <strong>Next</strong> and <strong>Done</strong>.
          </Step>
        </Steps>
      </Content>
    </HorizontalLayout>
    <HorizontalLayout>
      <Badge>3</Badge>
      <Content>
        <Title>Configure Okta Information in BigPanda</Title>
        <Steps>
          <Step>
            In Okta, navigate to{' '}
            <strong>Applications &gt; BigPanda &gt; Sign On &gt; View Setup Instructions</strong>.
          </Step>
          <Step>
            Copy the value of <strong>IDP Issuer/Entity ID</strong> into the{' '}
            <strong>Issuer ID</strong> field.
          </Step>
          <Step>
            Copy the value of <strong>Login URL/SignOn URL</strong> into the{' '}
            <strong>SAML 2.0 Endpoint (HTTP)</strong> field.
          </Step>
          <Step>
            Download the <strong>x.509 Certificate</strong> and copy the certificate into the{' '}
            <strong>x.509 Certificate</strong> field.
          </Step>
        </Steps>
        <SSOForm providerName="Okta" onSubmit={configureProvider} id={formId} />
      </Content>
    </HorizontalLayout>
    <HorizontalLayout>
      <Badge>4</Badge>
      <Content>
        <Title>Validate and Enable the Integration</Title>
        <Description>
          Before enabling the Okta integration for your entire organization, test it with your own
          account.
        </Description>
        <Steps>
          <Step>
            Click <strong>Logout and Validate.</strong>
          </Step>
          <Step>
            Log in to BigPanda with Okta SSO. Be sure to leave the BigPanda password field blank.
          </Step>
          <Step>
            If your login was successful, return to this page and click{' '}
            <strong>Activate SSO</strong> to enable SSO for everybody in your organization.
          </Step>
        </Steps>
        <Note>
          Note: Until you enable the integration for the entire organization, users (including you)
          can log in to BigPanda with their original BigPanda passwords. Once enabled, they will be
          required to use Okta&apos;s authentication.
        </Note>
      </Content>
    </HorizontalLayout>

    <LogoutButton updateAndLogout={updateAndLogout} disabled={!isConfigurationSent} />
  </div>
);

OktaInstructions.propTypes = {
  configureProvider: PropTypes.func.isRequired,
  updateAndLogout: PropTypes.func.isRequired,
  isConfigurationSent: PropTypes.bool.isRequired,
  organization: PropTypes.string.isRequired,
};

export default OktaInstructions;
