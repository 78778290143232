import {
  AllSizes,
  ArrowdownIcon, CalendarIcon, ICustomSelect, Tooltip,
} from '@bp/kung-fu';
import React from 'react';

import { anyChangeObject, DataTestsId, text as pageText } from '../../../../../../constants';
import { actionOptions, categoryOptions, dateOptions } from '../../constants';
import { CustomSelectButton, CustomSelectWrapper, SelectText } from './CustomSelect.style';

function CustomSelect({
  requiredProps, text, fontWeight, icon,
}: ICustomSelect): JSX.Element {
  return (
    <CustomSelectButton {...requiredProps} data-testid={DataTestsId.filterSelect(text)}>
      <CustomSelectWrapper>
        {icon}
        <Tooltip placement="bottom-start" text={text}>
          <SelectText
            weight={fontWeight}
            color={(p): string => (fontWeight ? p.theme.bp_gray_08 : p.theme.bp_gray_06)}
          >
            {text}
          </SelectText>
        </Tooltip>
        <ArrowdownIcon color={(p): string => p.theme.bp_gray_06} size={AllSizes.LARGE} />
      </CustomSelectWrapper>
    </CustomSelectButton>

  );
}

export function UserSelect(props: ICustomSelect): JSX.Element {
  const { text } = props;
  const userName = text && text.split(' ')[0];
  return (
    <CustomSelect
      {...props}
      text={text ? userName : pageText.filters.userSelect}
      fontWeight={text && 700}
      textWidth={36}
    />
  );
}

export function ActionSelect(props: ICustomSelect): JSX.Element {
  const { text } = props;
  return (
    <CustomSelect
      {...props}
      text={text === '' || text === actionOptions[0].text ? pageText.filters.actionSelect : text}
      fontWeight={!(text === '' || text === actionOptions[0].text) && 700}
    />
  );
}

export function CategorySelect(props: ICustomSelect): JSX.Element {
  const { text } = props;
  return (
    <CustomSelect
      {...props}
      text={text === '' || text === categoryOptions[0].text ? pageText.filters.categorySelect : text}
      fontWeight={!(text === '' || text === categoryOptions[0].text) && 700}
    />
  );
}

export function ChangedObjectSelect(props: ICustomSelect): JSX.Element {
  const { text } = props;
  return (
    <CustomSelect
      {...props}
      text={text === '' || text === pageText.filters.objectName || text === anyChangeObject.text ? pageText.filters.objectName : text}
      fontWeight={!(text === '' || text === pageText.filters.objectName || text === anyChangeObject.text) && 700}
    />
  );
}

export function DateSelect(props: ICustomSelect): JSX.Element {
  const { text } = props;
  const icon = <CalendarIcon size={AllSizes.XLARGE} color={(p): string => p.theme.bp_gray_06} />;
  return (
    <CustomSelect {...props} text={text || dateOptions[0].text} fontWeight={700} icon={icon} />
  );
}
