angular.module('bigpanda').directive('incidentsRefreshNotification', incidentsRefreshNotification);

function incidentsRefreshNotification() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      envId: '=',
      folderId: '=',
      action: '=',
      username: '=',
    },
    templateUrl: 'overview/incidents/refresh_notification/incidents_refresh_notification',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
  };
}

function controller($q, $state, EnvironmentsService, EnvironmentsUtils) {
  const vm = this;
  vm.refreshState = refreshState;

  function refreshState() {
    let envIdPromise;

    if (vm.action === 'removed') {
      envIdPromise = EnvironmentsService.get().then(EnvironmentsUtils.getDefaultEnv);
    } else {
      envIdPromise = $q.resolve({ _id: vm.envId });
    }

    envIdPromise.then((env) => {
      $state.go('app.overview.incidents.list', { environment: env._id }, { reload: true });
    });
  }
}
