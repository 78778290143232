import {
  AllSizes,
  HBox, Text, Tooltip,
} from '@bp/kung-fu';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { TextWithTooltip } from '../../../../../../../app/react/common/components';
import { TAG_TYPE } from '../../../../../../../app/react/modules/settings/alert_enrichment/constants';
import getRuleIntegrationDisplay from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/getRuleIntegrationDisplay';
import isMapping from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/isMapping';
import isMultiType from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/isMultiType';
import { RuleType } from '../../../../types';
import { ExtractionRuleTooltipContent } from '../../ExtractionRuleTooltipContent';
import { SummaryData } from '../DraggableRule/SummaryData';
import styles from './RuleSummary.scss';
import { ExternalValues, RuleSummaryDataProps } from './types';

export function RuleSummaryData({
  enrichmentRule,
  rule,
  index,
  open,
  values = {} as ExternalValues,
  hover,
  integrations,
  tagType,
  isPreviewed,
}: RuleSummaryDataProps): JSX.Element {
  const {
    condition, integration, source, regex, template, tagValue, type,
  } = values;
  const integrationName = get(getRuleIntegrationDisplay(integration, integrations), 'display', '');
  let first;
  let isFirstWithoutValue = false;
  let second = integrationName;
  let secondNoValueMsg = 'No source system';
  let thirdNoValueMsg = 'No condition';
  const isMultiWithoutType = isMultiType(rule.type) && !type;
  const funcType = isMultiWithoutType ? '' : startCase(toLower(type));
  const showFuncType = isMultiType(tagType);

  if (isMultiWithoutType) {
    first = undefined;
    second = undefined;
    secondNoValueMsg = undefined;
    thirdNoValueMsg = undefined;
  }

  if (open) {
    return (
      <HBox>
        <span className={styles['summary-index']}>
          #
          {index + 1}
        </span>
        <span className={styles['summary-type']}>
          <Tooltip
            maxWidth="240px"
            placement="bottom"
            text="Function type cannot be edited. You can duplicate the tag and delete this one."
          >
            <span>{funcType}</span>
          </Tooltip>
        </span>
      </HBox>
    );
  }

  if (rule.type === RuleType.Composition || type === RuleType.Composition) {
    first = (
      <TextWithTooltip
        text={template || 'No composition template'}
        triggerText={undefined}
        length={undefined}
      />
    );
    isFirstWithoutValue = !template;
  } else if (rule.type === RuleType.Extraction || type === RuleType.Extraction) {
    const integrationNameText = integrationName ? `${integrationName}/` : '';

    isFirstWithoutValue = !regex;
    first = (
      <TextWithTooltip text={regex || 'No regex'} triggerText={undefined} length={undefined} />
    );
    second = integration && source && (
      <TextWithTooltip
        triggerText={(
          <Text size={AllSizes.SMALL}>
            {integrationNameText}
            <strong>{source}</strong>
          </Text>
        )}
        text={<ExtractionRuleTooltipContent sourceSystem={integrationName} sourceTag={source} />}
        length={undefined}
      />
    );
  } else if (rule.type === TAG_TYPE['Fixed Value'] || type === TAG_TYPE['Fixed Value']) {
    first = (
      <TextWithTooltip
        text={tagValue || 'No tag value'}
        triggerText={undefined}
        length={undefined}
      />
    );
    isFirstWithoutValue = !tagValue;
  } else if (isMapping(rule.type) && enrichmentRule) {
    first = (
      <TextWithTooltip
        text={enrichmentRule.config.name || 'No values'}
        triggerText={undefined}
        length={undefined}
      />
    );
    isFirstWithoutValue = !enrichmentRule.config.name;
  }

  return (
    <SummaryData
      index={index}
      funcType={showFuncType && funcType}
      first={first}
      isFirstWithoutValue={isFirstWithoutValue}
      second={second}
      secondNoValueMsg={secondNoValueMsg}
      third={condition}
      thirdNoValueMsg={thirdNoValueMsg}
      hover={hover}
      open={open}
      isPreviewed={isPreviewed}
    />
  );
}

export default RuleSummaryData;
