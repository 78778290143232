import extend from 'lodash/extend';
angular.module('bigpanda').controller('SnoozePopupController', SnoozePopupController);

function SnoozePopupController(
  $scope,
  $q,
  SnoozeStore,
  PersonalSettingsStore,
  resolvedIncidentIds,
  stateService,
  EnvironmentsService,
  CustomizationsStore,
  $log,
  notificationService
) {
  const vm = this;

  vm.incidentsIds = resolvedIncidentIds;
  vm.autoUnsnooze = { chk: false };
  vm.maxDateToSnooze = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);

  fetchAutoUnsnoozeCustomization();

  const multiple = vm.incidentsIds.length > 1;

  vm.snoozes = [
    {
      name: '30 Minutes',
      time: moment().add(30, 'minutes').utc().unix(),
      label: `<div class='time-box'>
              <span class='time-title'> 30 Minutes </span>
              <span class='time-text'>(${moment().add(30, 'minutes').calendar()})</span>
            </div>`,
    },
    {
      name: '1 hour',
      time: moment().add(1, 'hour').utc().unix(),
      label: `<div class='time-box'>
              <span class='time-title'> 1 Hour </span>
              <span class='time-text'>(${moment().add(1, 'hours').calendar()})</span>
            </div>`,
    },
    {
      name: '3 hour',
      time: moment().add(3, 'hour').utc().unix(),
      label: `<div class='time-box'>
              <span class='time-title'> 3 Hours </span>
              <span class='time-text'>(${moment().add(3, 'hours').calendar()})</span>
            </div>`,
    },
    {
      name: '6 hour',
      time: moment().add(6, 'hour').utc().unix(),
      label: `<div class='time-box'>
              <span class='time-title'> 6 Hours </span>
              <span class='time-text'>(${moment().add(6, 'hours').calendar()})</span>
            </div>`,
    },
    {
      name: '12 hour',
      time: moment().add(12, 'hour').utc().unix(),
      label: `<div class='time-box'>
              <span class='time-title'> 12 Hours </span>
              <span class='time-text'>(${moment().add(12, 'hours').calendar()})</span>
            </div>`,
    },
    {
      name: '1 day',
      time: moment().add(1, 'day').utc().unix(),
      label: `<div class='time-box'>
              <span class='time-title'> 1 Day </span>
              <span class='time-text'>(${moment().add(1, 'day').calendar()})</span>
            </div>`,
    },
    {
      name: '1 week',
      time: moment().add(1, 'week').utc().unix(),
      label: `<div class='time-box'>
              <span class='time-title'> 1 Week </span>
              <span class='time-text'>(${moment().add(1, 'week').calendar()})</span>
            </div>`,
    },
    {
      name: 'custom',
      label: "<i class='bp-icon-calendar'></i><span>Pick a Date</span>",
    },
  ];

  extend($scope, {
    dialogTitle: `Snooze Incident${multiple ? 's' : ''}`,
    titleIconClass: 'bp-icon-snooze-full',
    submitButtonCaption: 'Snooze',
    cancelButtonCaption: 'Cancel',
    saveButtonProductId: 'snooze_incident',
    preSave: preSave,
  });

  vm.snooze = { selected: vm.snoozes[0], date: new Date() };
  vm.snooze.selected.selected = true;
  vm.annotationObject = {};
  vm.snoozeTestTextId = 'Snooze_modal_text';

  vm.openPicker = openPicker;

  function openPicker(pickerElement) {
    angular.element(`.${pickerElement}`).focus();
  }

  function preSave() {
    const snooze = getSnoozeObject();

    // snooze time should be in the future (30 second from now)
    if (snooze.time - moment().utc().unix() < 30) {
      extend($scope, {
        submitError: true,
        errorMessage: 'Snooze time should be in the future.',
      });
      return;
    }

    let getEnvPromise = $q.when();
    if (stateService.getSelectedEnvironmentId()) {
      getEnvPromise = EnvironmentsService.getEnvById(stateService.getSelectedEnvironmentId());
    }

    // not using creating and done stated as snooze is synced and
    // handling the async part by itself
    return getEnvPromise.then((environment) =>
      SnoozeStore.snooze(environment._id, vm.incidentsIds, {
        snoozedUntil: snooze.time,
        isUnsnooze: false,
        annotation: vm.annotationObject.annotation,
        autoUnsnooze: vm.autoUnsnooze.chk,
        timeframe: snooze.name,
        environment: environment && environment.name,
      })
    );
  }

  $scope.$watch(
    () => vm.snooze.selected,
    (current, old) => {
      if (old) old.selected = false;
      if (current) current.selected = true;
    }
  );

  function getSnoozeObject() {
    if (vm.snooze.selected.name === 'custom') {
      return {
        time: moment(vm.snooze.date).utc().unix(),
        name: 'custom',
      };
    }

    return vm.snooze.selected;
  }

  function fetchAutoUnsnoozeCustomization() {
    return CustomizationsStore.getCustomizations()
      .then((customizations) => {
        vm.autoUnsnooze = {
          chk: customizations.snooze ? customizations.snooze.auto_unsnooze : false,
        };
      })
      .catch((err) => {
        $log.error(`Failed getting user customizations: ${err}`);
        notificationService.error('Error getting user customizations', { error: err });
      });
  }
}
