import React from 'react';
import PropTypes from 'prop-types';
import { BamFiltersPlaceHolder } from '@bp/bam';
import FiltersDropdown, {
  DropdownOptionType,
} from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION, ANY_OPTION_VALUE } from '../../../constants';

const FiltersComponent = ({ filtersDropDownState, onChange, data }) => {
  const {
    tagName: { value: tagNameValue, text: tagNameText },
    type: { value: typeValue, text: typeText },
    source: { value: sourceValue, text: sourceText },
    status: { value: statusValue, text: statusText },
  } = filtersDropDownState;

  const dropdownElements = [
    <FiltersDropdown
      data={data.tagName}
      selectedValue={tagNameValue}
      selectedText={tagNameText}
      defaultText="Tag Name"
      onChange={(e, selection) => onChange({ tagName: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.source}
      selectedValue={sourceValue}
      selectedText={sourceText}
      defaultText="Source"
      onChange={(e, selection) => onChange({ source: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.type}
      selectedValue={typeValue}
      selectedText={typeText}
      defaultText="Type"
      onChange={(e, selection) => onChange({ type: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.status}
      selectedValue={statusValue}
      selectedText={statusText}
      defaultText="Status"
      onChange={(e, selection) => onChange({ status: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={[tagNameValue, typeValue, sourceValue, statusValue].some(
        (v) => v !== ANY_OPTION_VALUE
      )}
      onClearButtonClick={() => {
        onChange({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
      }}
    />
  );
};
FiltersComponent.propTypes = {
  data: PropTypes.shape({
    source: PropTypes.arrayOf(DropdownOptionType),
    tagName: PropTypes.arrayOf(DropdownOptionType),
    type: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    source: DropdownOptionType,
    tagName: DropdownOptionType,
    type: DropdownOptionType,
    status: DropdownOptionType,
  }).isRequired,
};
export default FiltersComponent;
