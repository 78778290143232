angular.module('bigpanda.backend.services').factory('StreamConnector', StreamConnector);

function StreamConnector(
  $q,
  $log,
  $rootScope,
  $timeout,
  socketIo,
  Config,
  pubSubService,
  notificationService,
  PersonalSettingsStore
) {
  let socket = null;
  let notificationScope = null;
  let reconnectingTimeout = null;
  let disconnectTimeout = null;
  let reconnectingIntervalMs = Config.webSocketsReconnectTimeout;
  let disconnectTimeoutMs = Config.disconnectedNotificationTimeout;

  const deferred = $q.defer();
  const RECONNECT_ATTEMPTS_BEFORE_NOTIFICATION = 5;

  function onData(data) {
    pubSubService.broadcast(`streamConnector.newData_${data.type}`, data.data);
    if (data.type === 'error') {
      $log.error('Unexpected error in stream', data.data);
      pubSubService.broadcast('streamConnector.error', data.data);
    }
  }

  function onConnect() {
    try {
      pubSubService.broadcast('streamConnector.connected');
      deferred.resolve();
    } catch (e) {
      deferred.reject(e);
    }
  }

  function onConnectionError(err) {
    if (err.message === 'Unauthorized') {
      pubSubService.broadcast('Authentication.failed');
    }
    socket.io.opts.transports = ['polling', 'websocket'];
  }

  function onReconnect(attempt) {
    $timeout.cancel(disconnectTimeout);
    $timeout.cancel(reconnectingTimeout);
    pubSubService.broadcast('streamConnector.reconnected');
    if (notificationScope) {
      notificationService.info('...welcome back!');
    }
    disconnectTimeout = null;
    notificationScope = null;
    reconnectingTimeout = null;
  }

  function onReconnectAttempt(attempt) {
    if (
      attempt <= RECONNECT_ATTEMPTS_BEFORE_NOTIFICATION ||
      disconnectTimeout ||
      reconnectingTimeout
    ) {
      return;
    }

    const getMessage = () =>
      `Reconnecting in ${Math.floor(reconnectingIntervalMs / 1000)} seconds...`;
    pubSubService.broadcast('streamConnector.disconnected');
    notificationScope = $rootScope.$new();
    notificationScope.content = 'Connection closed, we will try to reconnect soon...';
    notificationService.error(null, {
      scope: notificationScope,
      showStopper: true,
      until: ['streamConnector.connected', 'streamConnector.reconnected'],
    });
    reconnectingTimeout = $timeout(function _innerTimeout() {
      reconnectingIntervalMs -= 1000;
      reconnectingIntervalMs =
        reconnectingIntervalMs < 0 ? Config.webSocketsReconnectTimeout : reconnectingIntervalMs;
      if (notificationScope) {
        notificationScope.content = getMessage();
      } else {
        notificationScope = $rootScope.$new();
        notificationScope.content = getMessage();
        notificationService.error(null, {
          scope: notificationScope,
          showStopper: true,
          until: ['streamConnector.connected', 'streamConnector.reconnected'],
        });
      }
      reconnectingTimeout = $timeout(_innerTimeout, 1000);
    }, Config.disconnectedNotificationTimeout);
  }

  return {
    connect: function () {
      if (socket) {
        deferred.resolve();
        return deferred.promise;
      }
      try {
        socket = socketIo.connect();
        if (!socket) {
          deferred.reject(new Error('Failed to connect web socket'));
          return;
        }
        socket.on('connect', onConnect);
        socket.on('connect_error', onConnectionError);
        socket.on('message', onData);
        socket.io.on('reconnect', onReconnect);
        socket.io.on('reconnect_attempt', onReconnectAttempt);
      } catch (e) {
        deferred.reject(e);
      }
      return deferred.promise;
    },
    on: (eventType, callback) => {
      pubSubService.on(`streamConnector.newData_${eventType}`, (event, data) => {
        callback(data);
      });
    },
  };
}
