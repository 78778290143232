angular.module('bigpanda').directive('incidentTimestamp', incidentTimestamp);

function incidentTimestamp() {
  return {
    restrict: 'E',
    scope: {
      incident: '<',
    },
    templateUrl: 'overview/incidents/incident_timestamp/incident_timestamp',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(stateService, IncidentTimestampService) {
    const vm = this;
    const folderId = stateService.getSelectedFolderId();
    this.$onInit = setIncidentTime;
    this.$onChanges = setIncidentTime;

    function setIncidentTime() {
      IncidentTimestampService.getIncidentTimestamp(vm.incident, folderId).then((timeProps) => {
        vm.timeProps = timeProps;
        vm.incident.timeStampDisplay = angular.copy(timeProps.timeStampDisplay);
      });
    }
  }
}
