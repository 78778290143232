import React from 'react';
import cn from 'classnames';
import styles from './Tooltip.scss';

const multiValueTooltip = (ref) => (
  <div className={styles['bpfl-tooltip']} ref={ref}>
    <ul className={styles['content-wrapper']}>
      <li className={styles['content-item']}>
        <div className={styles.title}>Logical Expression:</div>
        <div className={styles.value}>
          IF(logical_expression, [&quot;value1&quot;], [&quot;value1&quot;, &quot;value2&quot;])
          <div className={styles['secondary-title']}>
            Returns [&quot;value1&quot;] if true, otherwise returns [&quot;value1&quot;,
            &quot;value2&quot;]
          </div>
        </div>
      </li>
      <li className={styles['content-item']}>
        <div className={styles.title}>Unique:</div>
        <div className={styles.value}>
          UNIQUE(array)
          <div className={styles['secondary-title']}>Returns unique values in array</div>
        </div>
      </li>
      <li className={styles['content-item']}>
        <div className={styles.title}>Count:</div>
        <div className={styles.value}>
          COUNT(array)
          <div className={styles['secondary-title']}>Returns the number of items in an array</div>
        </div>
      </li>
      <li className={styles['content-item']}>
        <div className={styles.title}>Filter:</div>
        <div className={styles.value}>
          FILTER(extracted_field, condition_field, operator, condition_value)
          <div className={styles['secondary-title']}>
            Returns the extracted field’s values in an array based on the condition
          </div>
        </div>
      </li>
      <li className={cn(styles['content-item'], styles['content-item-last'])}>
        <div className={styles.title}>Operators:</div>
        <div className={styles.value}>= | != | &gt; | &lt; | &gt;= | &lt;=</div>
      </li>
      <div className={styles['row-delimiter']} />
      <li className={styles['content-item']}>
        <div className={styles.title}>Example:</div>
        <div className={styles.value}>
          IF(COUNT(UNIQUE(&#123;alert_tags.service&#125;)) &gt; 2, [&quot;dev&quot;],
          [&quot;dev&quot;, &quot;noc&quot;])
        </div>
      </li>
    </ul>
  </div>
);

export default multiValueTooltip;
