import {
  AllSizes,
  Button, ButtonVariants, HBox, PopOutIcon, SBHBox, SCHBox, TimelineIcon, VBox,
} from '@bp/kung-fu';
import styled from 'styled-components';

import dic from '../IncidentView.dic';

export const UpperRowWrapper = styled(VBox) <{ isSticky: boolean }>`
padding: 0 40px;
height: 50px;
position: sticky;
top: -1px;
z-index: 1;
background-color: ${(p) => p.theme.bp_white};
border-bottom: ${({ isSticky, theme }) => isSticky && `1px solid ${theme.bp_gray_02}`};
transition-duration: 300ms;
justify-content: flex-end;
padding-bottom: 4px;
`;

export const UpperRow = styled(SBHBox)`
height: 34px;
`;

export const Content = styled(SCHBox)`
`;

export const ButtonsContainers = styled(SCHBox)`
gap: 12px;
`;

export const TimelineButton = styled(Button).attrs({
  variant: ButtonVariants.PRIMARY,
  size: AllSizes.MEDIUM,
  icon: TimelineIcon,
})`
`;

export const BottomRow = styled(HBox)`
`;

export const ResizeButtonWrapper = styled.div`
  @media (max-width: 959px) {
    display: none;
  }
`;

export const ResizeButton = styled(Button).attrs(() => ({
  variant: ButtonVariants.ACTION_DEFAULT,
  ariaLabel: dic.resize_button_v2_label,
  icon: PopOutIcon,
  size: AllSizes.LARGE,
}))`
`;
