angular.module('bigpanda').directive('commentButton', commentButton);

function commentButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '?^^checkedIncidentsState',
    },
    scope: {
      incidentId: '<',
      commentState: '@',
      commentDisabled: '=?',
      isActionsV2: '<',
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: controller,
    templateUrl: 'overview/incidents/list/widgets/comment_button/comment_button',
  };

  function controller($scope, $state, CommentsStore, $timeout) {
    const vm = this;

    vm.context = null;
    vm.commentsTooltip = 'Comments';
    vm.commentTestId = `Comment_list_button`;
    vm.buttonClass = {};

    vm.showComments = showComments;

    vm.$onInit = onInit;
    vm.$onChanges = updateContext;

    function onInit() {
      // If we're not in the screen of the incidents list, we mock the controller so the flow of the class will work
      // as expected
      if (!vm.checkedIncidentsState) {
        vm.checkedIncidentsState = {
          subscribe: angular.noop,
          isAnythingChecked: () => false,
        };
      }

      updateContext().then(() => {
        CommentsStore.subscribe($scope, {
          storeUpdated: () => fetchCommentCount(vm.context),
        });
      });

      updateButtonClass();
      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: updateButtonClass,
      });
    }

    function updateContext() {
      // We need change context on the fly because of the virtual repeat, the same directive is reused with new data.
      vm.context = {
        incidentId: vm.incidentId,
        count: 0,
      };
      vm.isCommentRelevant = !vm.commentDisabled || vm.context.count > 0;
      return fetchCommentCount(vm.context);
    }

    function showComments() {
      $timeout(() => {
        $state.go(
          vm.commentState,
          {
            incidentId: vm.context.incidentId,
          },
          { notify: false }
        );
      }, 10);
    }

    function fetchCommentCount(context) {
      // Mutation of the sent context is required to make sure we edit the original context in case the vm one is replaced on scroll.
      return CommentsStore.getCommentsCount(context.incidentId)
        .then((count) => {
          context.count = count;
        })
        .catch(() => {
          context.count = 0;
        })
        .finally(updateButtonClass);
    }

    function updateButtonClass() {
      const isDisabled = vm.checkedIncidentsState.isAnythingChecked();
      vm.buttonClass = {
        commented: vm.context.count,
        'bp-disabled': vm.checkedIncidentsState.isAnythingChecked(),
        'quick-action-button-with-border': vm.context.count || !vm.commentDisabled,
      };

      vm.isCommentRelevant = !vm.commentDisabled || vm.context.count > 0;
      vm.commentsTooltip = isDisabled ? null : 'Comments';
    }
  }
}
