/* eslint-disable no-template-curly-in-string */
import { BamDropdownField } from '@bp/bam';
import get from 'lodash/get';
import React, { useState } from 'react';
import { FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';

import Combobox from '../../../../../../app/react/common/components/Combobox/Combobox';
import { selectors as integrationsSelectors } from '../../../../../../app/react/common/integrations';
import preventEnterKey from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/preventEnterKey';
import ruleFieldName from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/ruleFieldName';
import { sourceSystemsOptions } from '../../../../../../app/react/modules/settings/alert_enrichment/utils';
import {
  mandatoryField,
  validateSourceSystems,
} from '../../../../../../app/react/modules/settings/alert_enrichment/validation.utils';
import { selectors as featureTogglesSelectors } from '../../../../../../app/react/user/feature_toggles';
import { EnrichmentRule } from '../../../types';
import { CompositionTemplateInput } from '../CompositionTemplateInput';
import {
  CompositionForm,
  IntegrationField,
  IntegrationFieldv2,
  SourceSystem,
} from './Composition.style';
import { IntegrationOption, LoadPreview } from './types';

interface IProps {
  rule: EnrichmentRule;
  onLoadPreview: LoadPreview;
}

export function Composition({ rule, onLoadPreview }: IProps): JSX.Element {
  const [values, setValues] = useState({});
  const onBlur = (): void => {
    if (!get(values, ruleFieldName(rule.id, 'template'))) return;
    onLoadPreview(rule.id);
  };

  const integrationOptions: IntegrationOption[] = useSelector(
    integrationsSelectors.getAllIntegrationOptions,
  ) || [];
  const filtersv2FT: boolean = get(
    useSelector(featureTogglesSelectors.getFeatureToggles),
    'filters_v2',
  );

  return (
    <>
      <CompositionForm>
        {filtersv2FT ? (
          <SourceSystem>
            <IntegrationFieldv2
              name={ruleFieldName(rule.id, 'integration')}
              validate={validateSourceSystems}
              validateOnBlur
            >
              {({ input: { onChange, value }, meta }): JSX.Element => (
                <Combobox
                  title="Source System"
                  placeholder="Select Source..."
                  ariaLabel="source system"
                  items={sourceSystemsOptions(integrationOptions)}
                  value={value}
                  onChange={onChange}
                  error={(meta.touched && !!meta.error) || (meta.submitFailed && !meta.valid)}
                  errorMessage={(meta.touched || (meta.submitFailed && !meta.valid)) && meta.error}
                  fullWidth
                />
              )}
            </IntegrationFieldv2>
          </SourceSystem>
        ) : (
          <IntegrationField
            name={ruleFieldName(rule.id, 'integration')}
            validate={validateSourceSystems}
            validateOnBlur
            component={BamDropdownField}
            title="Source System"
            options={sourceSystemsOptions(integrationOptions)}
            placeholder="Select source..."
          />
        )}
        <CompositionTemplateInput
          name={ruleFieldName(rule.id, 'template')}
          title="Composition Template"
          hint="Template for building a tag’s value by combining values of existing tags and additional information"
          placeholder="e.g. http://wiki.acme.com/${host}/${check}"
          validate={mandatoryField('Template')}
          validateOnBlur
          fixedFont
          ariaLabel="composition template"
          onKeyPress={preventEnterKey}
          onBlur={onBlur}
          value={get(values, ruleFieldName(rule.id, 'template'))}
        />
      </CompositionForm>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values: formValues }): void => setValues(formValues)}
      />
    </>
  );
}

export default Composition;
/* eslint-enable no-template-curly-in-string */
