import isEmpty from 'lodash/isEmpty';
angular.module('bigpanda').factory('IncidentsSearchFolderService', IncidentsSearchFolderService);

const expiredMessage =
  'The incident you looked for was resolved more than 24 hours ago and cannot be selected';

function IncidentsSearchFolderService(
  IncidentsBackendService,
  SnoozeStore,
  notificationService,
  $filter,
  $q
) {
  function searchFolder(returnTo, envId, incidentId) {
    return SnoozeStore.getSnoozeData(incidentId)
      .then((snoozeData) =>
        IncidentsBackendService.getIncidentById(incidentId).then((res) => {
          const incident = res.item;
          const normalized = $filter('incidentNormalizer')(incident);
          let folder = 'active';

          if (normalized.is_active) {
            if (!isEmpty(snoozeData)) {
              folder = 'snoozed';
            }
          } else if (normalized.last_change >= moment().add(-1, 'day').unix()) {
            folder = 'resolved';
          } else {
            notificationService.notify(expiredMessage, { duration: 10 });
            return $q.reject(new Error(expiredMessage));
          }

          // Check if all entities of the incident have maintenance plans property
          const hasMaintenancePlans = (entity) =>
            entity.maintenance_plan_ids && entity.maintenance_plan_ids.length;
          const isUnderFullMaintenance =
            normalized.entities &&
            normalized.entities.length &&
            normalized.entities.every(hasMaintenancePlans);

          if (isUnderFullMaintenance) {
            folder = 'maintenance';
          }

          return {
            environment: envId,
            returnTo: returnTo,
            folder: folder,
            incidentId: incidentId,
          };
        })
      )
      .catch((err) => {
        // Fix for searchFolderWExpired func - if the problem is the 'solved expired' - we should be able to track it
        if (err && err.toString() === new Error(expiredMessage).toString()) {
          return $q.reject(new Error(expiredMessage));
        }

        // Else in case the incident is not 'solved expired', throw exception
        const message = 'The incident you looked for was not found';
        notificationService.error(message, { duration: 10 });

        return $q.reject(new Error(message));
      });
  }

  function searchFolderWExpired(returnTo, envId, incidentId) {
    return searchFolder(returnTo, envId, incidentId).catch((err) => {
      // If the expection caused by an expired resolved incident - ignore the exception and return folder 'resolved'
      if (err && err.toString() === new Error(expiredMessage).toString()) {
        return {
          environment: envId,
          returnTo: returnTo,
          folder: 'resolved',
          incidentId: incidentId,
        };
      }
      return $q.reject(new Error(err));
    });
  }

  return {
    searchFolder: searchFolder,
    searchFolderWExpired: searchFolderWExpired,
  };
}
