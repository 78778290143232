angular.module('bigpanda').factory('AppValidatorService', (bootstrap, previousStateService) => ({
  validateApp: function (toState, toParams) {
    if (!bootstrap.isReady()) {
      var source = toParams.bp_source;

      previousStateService.savePreviousState(toState, toParams);
      return { to: 'bootstrap', params: { bp_source: source }, options: { location: false } };
    }
    return null;
  },
}));
