import every from 'lodash/every';
angular.module('bp.widgets').directive('duplicate', DuplicateValidator);

// The validator accepts an array as attribute, which is the list of existing values, against which the new value will be tested.
// Since the list is passed as an attribute, it won't really be an array.
// It will be an array.toString(), which is a string in the format [value1, value2, value3, ...]
// This string can be parsed into an actual array using JSON.
function DuplicateValidator() {
  return {
    require: 'ngModel',
    link: (scope, element, attr, ctrl) => {
      ctrl.$validators.duplicate = (modelValue) => {
        if (!modelValue) {
          return true;
        }

        const valuesList = JSON.parse(attr.duplicate);
        return every(
          valuesList,
          (existingValue) => existingValue.toLowerCase() !== modelValue.toLowerCase()
        );
      };
    },
  };
}
