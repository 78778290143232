import bpFetch from 'common/utils/http/fetcher';

export function getUsers() {
  /* eslint-disable-line */
  return bpFetch({ url: '/web/users/list' }).then((res) => res.data.item);
}

export function inviteUser(email, name, phone, roles, context) {
  return bpFetch({
    url: '/web/users/invite',
    method: 'POST',
    data: {
      username: email.toLowerCase(),
      name: name,
      phone_number: phone,
      context: context,
      roles: roles,
    },
  }).then((res) => res.data.item);
}

export function deleteUser(userId) {
  return bpFetch({ url: `/web/users/${userId}`, method: 'DELETE' }).then((res) => res.data);
}

export function reactivateUser(email, name, formatedRoles, phone) {
  return bpFetch({
    url: '/web/users/activate',
    method: 'POST',
    data: {
      username: email.toLowerCase(),
      name: name,
      roles: formatedRoles,
      phone_number: phone,
    },
  }).then((res) => res.data.item);
}

export function resendUserInvitation(userId, context) {
  return bpFetch({
    url: '/web/users/invite/resend',
    method: 'POST',
    data: { userId, context },
  }).then((res) => res.data);
}

export function setUserRoles(userId, roles) {
  return bpFetch({ url: `/web/users/${userId}/roles`, method: 'PUT', data: { roles } }).then(
    (res) => res.data
  );
}

export function getUserLoginOrganizations() {
  return bpFetch({ url: '/resources/v2.1/user/organizations?type=login' })
    .then((res) => res.data)
    .catch(() => {
      return [];
    });
}

export function switchLoginOrganization(organizationName) {
  return bpFetch({
    url: '/login/switch',
    method: 'POST',
    data: {
      organization_name: organizationName,
    },
  }).then((res) => res.data);
}
