import memoize from 'lodash/memoize';
angular
  .module('bigpanda')
  .controller(
    'QuotaSettingsController',
    ($scope, TargetSystemsStore, pubSubService, UserFeatureTogglesService, $ngRedux) => {
      pubSubService.broadcast('currentTitleChanged', 'Quotas Settings');
      pubSubService.broadcast('settingsPageChanged', 'quota');

      $scope.store = $ngRedux;
      $scope.normalizeIntegration = memoize(TargetSystemsStore.normalizeIntegration);

      TargetSystemsStore.getAll().then((targets) => {
        $scope.targets = targets;
      });
      $scope.requestMore = function (target) {
        pubSubService.broadcast('Intercom.showNewMessage', {
          message: `Hi BigPanda,\nPlease change our sharing quota for ${target.name}.\n`,
        });
      };
    }
  );
