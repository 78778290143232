import lowerCase from 'lodash/lowerCase';
import get from 'lodash/get';
const imagesPath = '/assets/img/activity-logos';
const templatesPath = 'overview/incidents/info/activity_feed/events';

module.exports = angular
  .module('bigpanda.ActivityEventsConfigProvider', [])
  .constant('ActivityEventsConfig', {
    aiIncident: { icon: `${imagesPath}/ai.png` },
    activityEvents: [
      {
        event_type: 'incident#new',
        config: {
          icon: `${imagesPath}/incident.png`,
          text: 'Created',
        },
      },
      {
        event_type: 'incident#closed',
        config: {
          icon: `${imagesPath}/incident_resolved.png`,
          text: 'Resolved',
        },
      },
      {
        event_type: 'incident#reopen',
        config: {
          icon: `${imagesPath}/incident.png`,
          text: 'Reopened',
        },
      },
      {
        event_type: 'merge#destination',
        config: {
          icon: `${imagesPath}/merge.png`,
          textTemplate: `${templatesPath}/split_merge/merge_destination_activity_event`,
        },
      },
      {
        event_type: 'merge#source',
        config: {
          icon: `${imagesPath}/merge.png`,
          textTemplate: `${templatesPath}/split_merge/merge_source_activity_event`,
        },
      },
      {
        event_type: 'split#source',
        config: {
          icon: `${imagesPath}/split.png`,
          textTemplate: `${templatesPath}/split_merge/split_source_activity_event`,
        },
      },
      {
        event_type: 'split#destination',
        config: {
          icon: `${imagesPath}/split.png`,
          textTemplate: `${templatesPath}/split_merge/split_destination_activity_event`,
        },
      },
      {
        event_type: 'incident#manual-resolve',
        config: {
          icon: `${imagesPath}/resolved.png`,
          text: 'Manually Resolved',
        },
      },
      {
        event_type: 'incident#external-resolve',
        config: {
          icon: `${imagesPath}/resolved.png`,
          text: 'Resolved by External System',
        },
      },

      {
        event_type: 'incident#become-flapping',
        config: {
          icon: `${imagesPath}/flapping.png`,
          text: 'Flapping',
        },
      },
      {
        event_type: 'incident#stopped-flapping',
        config: {
          icon: `${imagesPath}/unflapping.png`,
          text: 'Flapping Stopped',
        },
      },
      {
        event_type: 'incident#snoozed',
        config: {
          icon: `${imagesPath}/snooze.png`,
          textTemplate: `${templatesPath}/snooze/snooze_activity_event`,
        },
      },
      {
        event_type: 'incident#unsnoozed',
        config: {
          icon: `${imagesPath}/unsnoozed.png`,
          textTemplate: `${templatesPath}/snooze/unsnooze_activity_event`,
        },
      },
      {
        event_type: 'incident#shared',
        config: {
          icon: `${imagesPath}/share.png`,
          textTemplate: `${templatesPath}/share/share_activity_event`,
        },
      },
      {
        event_type: 'incident#auto-shared',
        config: {
          icon: `${imagesPath}/autoshare.png`,
          textTemplate: `${templatesPath}/share/share_activity_event`,
        },
      },
      {
        event_type: 'incident#commented',
        config: {
          icon: `${imagesPath}/commented.png`,
          textTemplate: `${templatesPath}/comment/comment_activity_event`,
        },
      },
      {
        event_type: 'incident#assigned',
        config: {
          icon: `${imagesPath}/assigned.png`,
          textTemplate: `${templatesPath}/assign/assign_activity_event`,
        },
      },
      {
        event_type: 'incident-tag#removed',
        config: {
          iconTemplate: `${templatesPath}/incident_tags/incident_tag_activity_event_icon`,
          textTemplate: `${templatesPath}/incident_tags/incident_tag_removed_activity_event`,
        },
      },
      {
        event_type: 'incident-tag#upserted',
        config: {
          iconTemplate: `${templatesPath}/incident_tags/incident_tag_activity_event_icon`,
          textTemplate: `${templatesPath}/incident_tags/incident_tag_upserted_activity_event`,
        },
      },
      {
        event_type: 'incident#unassigned',
        config: {
          icon: `${imagesPath}/unassigned.png`,
          textTemplate: `${templatesPath}/assign/unassign_activity_event`,
        },
      },
      {
        event_type: 'related-metadata#update',
        config: {
          icon: (event) => {
            try {
              const matchCertainty = get(
                event,
                '_source.params.change.relatedMetadata.matchCertainty'
              );
              return `${imagesPath}/${lowerCase(matchCertainty)}.png`;
            } catch (e) {
              console.error("Can't determine icon path", e);
            }
          },
          textTemplate: `${templatesPath}/manual_correlation/manual_correlation_activity_event`,
        },
      },
      {
        event_type: 'incident#entities-resolve',
        config: {
          icon: `${imagesPath}/resolved.png`,
          textTemplate: `${templatesPath}/batch_resolve/entities_batch_resolve_activity_event`,
        },
      },
      {
        event_type: 'incident#entities-auto-resolve',
        config: {
          icon: `${imagesPath}/auto_resolved.png`,
          textTemplate: `${templatesPath}/auto_resolve/entities_auto_resolve_activity_event`,
        },
      },
    ],
  });
