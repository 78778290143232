angular.module('bp.widgets').directive('bpFilterInput', bpFilterInput);

function bpFilterInput() {
  return {
    restrict: 'E',
    templateUrl: 'widgets/bp_filter_input/bp_filter_input',
    controllerAs: 'inputFilterVm',
    bindToController: true,
    replace: true,
    scope: {
      searchValue: '=ngModel',
      placeholder: '@',
      ariaLabel: '@',
      enableEmpty: '@',
      hideSearchArea: '=',
    },
    controller: controller,
  };

  function controller() {
    const vm = this;

    vm.disableInput = () => !vm.searchValue && (!vm.enableEmpty || !vm.prevValue);
    vm.focus = () => {
      vm.inputFocused = true;
    };
    vm.unfocus = () => {
      vm.inputFocused = false;
    };

    vm.searchEmpty = () => {
      vm.searchValue = '';
      vm.unfocus();
    };
  }
}
