angular
  .module('bigpanda.reports')
  .controller('VisualizationWidgetContainerController', VisualizationWidgetContainerController);

function VisualizationWidgetContainerController(
  $scope,
  $timeout,
  $q,
  $filter,
  $mdMedia,
  pubSubService,
  stateService,
  DisplayCommonService,
  ReportsConfig,
  ModalService
) {
  const vm = this;

  vm.dataLoading = false;
  vm.error = false;
  vm.errorMessage = null;
  vm.infoTextPerVisualizationType = ReportsConfig.infoTextPerVisualizationType;

  vm.getReportData = getReportData;
  vm.getDisplayConfiguration = getDisplayConfiguration;
  vm.getLayoutName = getLayoutName;
  vm.showWidgetEditDialog = showWidgetEditDialog;

  function getLayoutName() {
    return $mdMedia('gt-sm') ? 'desktop' : 'mobile';
  }

  function getReportData() {
    $scope.$broadcast('visualizationWidgetContainerController.dataLoading');
    vm.dataLoading = true;
    vm.error = false;
    vm.errorMessage = '';

    return vm.getData({ widget: vm.widgetDefinition }).then(
      (reportData) => {
        $scope.$broadcast('visualizationWidgetContainerController.dataLoaded');

        vm.dataLoading = false;

        if (reportData.length === 0) {
          vm.error = true;

          vm.errorMessage =
            'No data matches the selected criteria. Try adjusting the settings or see Analytics Troubleshooting.';
          vm.firstLineError = 'No data matches the selected criteria';
          vm.secondLineError = 'Try adjusting the settings or see ';
          vm.linkTextError = 'Analytics Troubleshooting';
          return $q.reject(vm.errorMessage);
        }

        // The empty $timeout makes sure that the promise will be returned to the caller
        // after Angular finishes hiding/showing elements according to
        // vm.dataLoading.
        return $timeout(angular.noop, 200).then(() => reportData);
      },
      (error) => {
        $scope.$broadcast('visualizationWidgetContainerController.dataLoaded');

        vm.dataLoading = false;
        vm.error = true;
        vm.firstLineError = 'Oops! An error occurred';
        vm.secondLineError = 'We are looking into it. Try refreshing the page or see the ';
        vm.linkTextError = 'KB article';
        return $q.reject(error);
      }
    );
  }

  function getDisplayConfiguration() {
    // Ugly static configuration for now
    return $q.when(vm.widgetDefinition).then((widget) => {
      const displayConfig = {};

      if (widget.visualization_type === 'bp-events-histogram-bars') {
        displayConfig.tagColumnHeader = $filter('titleCase')(
          $filter('normalizeTagType')(widget.group_by)
        );
      }

      if (
        widget.visualization_type === 'bp-correlation-table' ||
        widget.visualization_type === 'bp-mttr-table'
      ) {
        displayConfig.dateHeader =
          widget.group_by_time.charAt(0).toUpperCase() + widget.group_by_time.slice(1);
        displayConfig.timeGranularity = widget.group_by_time;
      }

      if (
        widget.visualization_type === 'bp-correlation-linechart' ||
        widget.visualization_type === 'bp-mttr-barchart'
      ) {
        displayConfig.timeGranularity = widget.group_by_time;
      }

      return displayConfig;
    });
  }

  function showWidgetEditDialog() {
    ModalService.showModal({
      templateUrl: 'reports/visualization_widget/widget_edit_dialog/widget_edit_dialog',
      controller: 'WidgetEditDialogController',
      locals: {
        data: Object.assign({}, vm.widgetDefinition),
      },
      bindToController: true,
    });
  }

  pubSubService.on(
    'WidgetEdit.dataLoaded',
    (event, data) => {
      if (data._id === vm.widgetDefinition._id) {
        vm.widgetDefinition.group_by = data.group_by;
        vm.widgetDefinition.states = data.states;
        buildTitle();

        $scope.$emit('reportWidgetsController.widgetEdited', vm.widgetDefinition._id);
        pubSubService.broadcast('reportWidgetsController.refreshReportData');
      }
    },
    $scope
  );

  function buildTitle() {
    if (!vm.widgetDefinition.group_by) {
      return;
    }
    let groupConfig = DisplayCommonService.getFieldNamesConfig()[vm.widgetDefinition.group_by];

    if (!groupConfig) {
      groupConfig = {
        singular: vm.widgetDefinition.group_by,
        plural: `${vm.widgetDefinition.group_by}s`,
      };
    }

    const name = toTitleCase(groupConfig.plural);
    if (ReportsConfig.widgetsTypesToTitlePrefix[vm.widgetDefinition.type]) {
      vm.widgetDefinition.title =
        ReportsConfig.widgetsTypesToTitlePrefix[vm.widgetDefinition.type] + name;
    }
  }

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }
}
