import pick from 'lodash/pick';
import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Badge, ProgressBar } from '@bp/kung-fu';
import { BamPreview, BamTimeFrameDropdown, TimeFormats } from '@bp/bam';
import isEqual from 'lodash/isEqual';
import {
  getCorrelationConfig,
  createPreview,
  getPreview,
  deletePreview,
} from 'common/endpoints/correlation-config';
import sorting from 'common/modules/incidents/sorting';
import { enrichIncident } from 'react/common/incidents/enricher';
import styles from './correlation_pattern_preview.scss';
import CorrelationPatternPreviewItems from './CorrelationPatternPreviewItems';
import * as TimestampGenerator from '../../../../../common/modules/incidents/TimestampGenerator';
import { getCorrelationTimeFrameOptions, defaultDateTimeOptionIndex } from './consts';

const { momentOrEpochRangeRelativeTimeFrameFormat } = TimeFormats;

const IN_PROGRESS_POLLING_INTERVAL = 2500;
const NOT_READY_POLLING_INTERVAL = 100;
const MAX_CORRELATIONS = 20;
const MAX_EVENTS = 1000000;
const PROGRESS_BAR_DONE_DELAY = 1000;

const itemsForPreview = (pattern) => ({
  ...pick(pattern, ['tags', 'window', 'query', 'cross_source']),
});

const now = () => moment();
const twoDaysAgo = () => now().clone().subtract(2, 'days');

class CorrelationPatternPreview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      definitionsChanged: false,
      loading: false,
      previewResult: null,
      hasLoadedPreview: false,
      incidentsData: [],
      start: twoDaysAgo().unix(),
      end: now().unix(),
    };
    this.previewId = null;
  }

  componentDidMount() {
    this.handleChange();
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleChange(prevProps);
  }

  componentWillUnmount() {
    if (this.previewId) {
      deletePreview(this.previewId);
      this.previewId = null;
    }
  }

  onTimeFrameChanged = (selectedOption) => {
    const { from, to } = selectedOption;
    const unixStartTime = from && from.unix();
    const unixEndTime = to && to.unix();

    const correlationTimeFrameOptions = getCorrelationTimeFrameOptions().options;

    if (selectedOption.id === correlationTimeFrameOptions[defaultDateTimeOptionIndex].id) {
      // eslint-disable-next-line no-param-reassign
      selectedOption.description = momentOrEpochRangeRelativeTimeFrameFormat(selectedOption);
    }

    const { start, end } = this.state;
    if (unixStartTime !== start || unixEndTime !== end) {
      this.setState({ definitionsChanged: true, start: unixStartTime, end: unixEndTime });
    }
  };

  getRefreshButtonState = () => {
    const { refreshButtonStates } = BamPreview.Header;
    const { validForPreview } = this.props;
    const { hasLoadedPreview } = this.state;

    if (!hasLoadedPreview) {
      return refreshButtonStates.HIDDEN;
    } else if (!validForPreview) {
      return refreshButtonStates.ERROR;
    }

    return refreshButtonStates.VISIBLE;
  };

  setPreviewIncidents(previewResult) {
    const { integrations } = this.props;
    if (previewResult) {
      if (previewResult.status === 'done' && previewResult.incidents.length === 0) {
        this.setState({ loading: false });
      } else {
        const isDone = previewResult.status === 'done';
        const delayProgressBar = isDone ? PROGRESS_BAR_DONE_DELAY : 0;

        const newIncidents = previewResult.incidents;
        if (newIncidents !== null && newIncidents.length) {
          const newIncidentsEnriched = newIncidents.map((incident) =>
            this.fixIncidentForDisplay(incident, integrations)
          );
          setTimeout(
            () => this.setState({ loading: !isDone, incidentsData: newIncidentsEnriched }),
            delayProgressBar
          );
        } else {
          setTimeout(() => this.setState({ loading: !isDone }), delayProgressBar);
        }
      }
    }
  }

  fixIncidentForDisplay = (incident, integrations) => {
    const incidentData = enrichIncident(incident, integrations);
    incidentData.titleData.subtitle.window = incidentData.titleData.subtitle.createdWindow;
    incidentData.numberOfIncidentActiveAlerts = incident.entities.length;
    incidentData.incident.status = 'manual';
    incidentData.timeProps = TimestampGenerator.getIncidentTimestamp(
      incident,
      null,
      sorting.options.start
    );
    return incidentData;
  };

  handleChange(prevProps) {
    const { correlationPattern: prevPattern } = prevProps || {};
    const { correlationPattern, validForPreview } = this.props;
    const { previewResult } = this.state;
    const canRenderPreviewBasedOnPreviousValues =
      !prevPattern ||
      !isEqual(itemsForPreview(correlationPattern), itemsForPreview(prevPattern)) ||
      !prevProps.validForPreview;

    if (validForPreview && correlationPattern && canRenderPreviewBasedOnPreviousValues) {
      if (previewResult) {
        this.setState({ definitionsChanged: true });
      } else if (!this.state.loading) {
        this.loadPreview();
      }
    }
  }

  loadPreview = async () => {
    const { correlationPattern } = this.props;
    const { start, end } = this.state;

    this.previewProgress = 0;
    this.setState({
      definitionsChanged: false,
      loading: true,
      previewResult: null,
      incidentsData: [],
    });

    try {
      if (this.previewId) {
        const previewIdToDelete = this.previewId;
        this.previewId = null;
        await deletePreview(previewIdToDelete);
      }

      const currentCorrelationConfig = await getCorrelationConfig();
      currentCorrelationConfig.matching_rules = [
        { ...itemsForPreview(correlationPattern), active: true },
      ];
      const { id } = await createPreview(
        currentCorrelationConfig,
        start,
        end,
        MAX_EVENTS,
        MAX_CORRELATIONS
      );
      this.previewId = id;
      this.pollPreviewResult(0);
    } catch (e) {
      this.setState({ loading: false, previewResult: null });
    }
  };

  pollPreviewResult(timeout) {
    setTimeout(async () => {
      if (!this.previewId) {
        return;
      }
      const currPreviewId = this.previewId;
      const previewResult = await getPreview(this.previewId);
      if (currPreviewId !== this.previewId) {
        return;
      }
      this.setState({ previewResult, hasLoadedPreview: true });
      if (previewResult.status !== 'done') {
        if (previewResult.status === 'in_progress' || previewResult.status === 'not_ready') {
          this.setPreviewIncidents(previewResult);

          this.pollPreviewResult(
            previewResult.status === 'in_progress'
              ? IN_PROGRESS_POLLING_INTERVAL
              : NOT_READY_POLLING_INTERVAL
          );
        } else {
          throw new Error('Unknown polling state');
        }
      } else {
        this.setPreviewIncidents(previewResult);
      }
    }, timeout);
  }

  calculateProgress = (numEventsScanned, numScannedHours, numTotalHours) => {
    const avgNumEventsPerHour = numScannedHours ? numEventsScanned / numScannedHours : 0;
    const expectedNumTotalEvents = avgNumEventsPerHour * numTotalHours;
    this.previewProgress = Math.max(
      this.previewProgress || 0,
      expectedNumTotalEvents ? numEventsScanned / expectedNumTotalEvents : 0
    );
    return this.previewProgress;
  };

  render() {
    const { definitionsChanged, loading, previewResult = {}, incidentsData = [] } = this.state;
    const {
      status,
      events = 0,
      end_time: endTime = 0,
      start_time: startTime = 0,
      cur_time: currTime = 0,
      incidents: incidentsForMetadata = [],
    } = previewResult || {};

    const numIncidents = incidentsForMetadata.length;
    const numEventsScanned = events;
    const numTotalHours = Math.round((endTime - startTime) / 60 / 60);
    const numScannedHours = status === 'done' ? numTotalHours : (currTime - startTime) / 60 / 60;
    const numEntities = numIncidents
      ? incidentsForMetadata.map((i) => i.entities.length).reduce((a, b) => a + b)
      : 0;
    const compressionRate =
      numEntities > 0 ? Math.round((1 - incidentsData.length / numEntities) * 100) : 0;

    const showLoadingOnEntirePreview = loading && numIncidents === 0;

    const progress = this.calculateProgress(numEventsScanned, numScannedHours, numTotalHours);

    const { options, defaultOption } = getCorrelationTimeFrameOptions();

    const subHeader = (
      <React.Fragment>
        <div className={styles['sub-header']}>
          <div className={styles['preview-totals']}>
            <span className={styles['preview-totals-label']}>Matched:</span>
            <span className={styles['preview-totals-value']}>
              <strong>
                {numIncidents} {numIncidents === 1 ? 'Incident' : 'Incidents'}
              </strong>
            </span>
            <span className={classnames(styles['preview-totals-label'], styles['with-dot'])}>
              Scanned:
            </span>
            <span className={styles['preview-totals-value']}>
              <strong>{numEventsScanned} events</strong> ({Math.round(numScannedHours)}/
              {numTotalHours} hours)
            </span>
            {numIncidents > 0 && status === 'done' && (
              <React.Fragment>
                <span className={classnames(styles['preview-totals-label'], styles['with-dot'])}>
                  Compression:
                </span>
                <Badge text={`${compressionRate}%`} color={(p) => p.theme.bp_blue} size="small" />
              </React.Fragment>
            )}
          </div>
        </div>

        {loading && (
          <ProgressBar
            currentValue={(progress || 0) * 100}
            completedColor={(p) => p.theme.bp_blue}
            bgColor={(p) => p.theme.bp_white}
            ariaLabel="Scanning events"
          />
        )}
      </React.Fragment>
    );

    return (
      <div className={styles['correlation-pattern-preview-container']}>
        <BamPreview
          header={
            <BamPreview.Header
              onRefresh={this.loadPreview}
              refreshButtonState={this.getRefreshButtonState()}
              refreshNotice={definitionsChanged ? 'Definitions Changed' : null}
              refreshError="Validate the form to refresh the preview"
            >
              <BamTimeFrameDropdown
                disabled={this.state.loading}
                options={options}
                defaultSelected={defaultOption}
                onChange={this.onTimeFrameChanged}
                direction="left"
                fullCustomDateDisplay
                title="Time Frame Menu"
              />
            </BamPreview.Header>
          }
          subheader={previewResult ? subHeader : null}
          loading={showLoadingOnEntirePreview}
          unpadded
          emptyPreviewHeader={previewResult ? 'No Matches' : 'Incidents Preview'}
          emptyPreviewMessage={
            previewResult
              ? 'Adjust form definitions to preview matching alerts'
              : 'Fill out the form to see matching alerts'
          }
        >
          {numIncidents && <CorrelationPatternPreviewItems incidentsData={incidentsData} />}
        </BamPreview>
      </div>
    );
  }
}

CorrelationPatternPreview.propTypes = {
  correlationPattern: PropTypes.shape({}),
  integrations: PropTypes.arrayOf(PropTypes.shape({})),
  validForPreview: PropTypes.bool.isRequired,
};

CorrelationPatternPreview.defaultProps = {
  correlationPattern: null,
  integrations: [],
};

export default hot(module)(CorrelationPatternPreview);
