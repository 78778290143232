import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@bp/kung-fu';
import isMapping from '../../modals/utils/isMapping';
import { enrichmentPropType } from '../../propTypes';

const RulesTooltip = ({ enrichments, text }) => {
  const mapRules = enrichments.filter(({ type }) => isMapping(type));
  if (!mapRules.length) return text;

  const mappingsList = (
    <ul>
      {mapRules.map(({ config: { name } }) => (
        <li key={name}>{name}</li>
      ))}
    </ul>
  );

  return (
    <Tooltip title={`${mapRules.length} Mappings`} text={mappingsList} placement="top">
      <span>{text}</span>
    </Tooltip>
  );
};

RulesTooltip.propTypes = {
  enrichments: enrichmentPropType,
  text: PropTypes.string.isRequired,
};

export default RulesTooltip;
