import { darkTheme } from '@bp/kung-fu';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import React, { useEffect, useState } from 'react';

import removeLeadSnake from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/removeLeadSnake';
import { Alert } from '../../types/Alert';
import { Tag } from '../../types/Tag';
import { previewEventFields } from './constants';
import { PreviewTable, TableContainer } from './FilterPreview.styles';

interface IProps {
  alerts: Alert[];
  conditionFields: string[];
}

type Column = {
  id: string;
  headerContent: string;
  accessor: (event: Alert) => string;
  tooltipContent: (tag: Tag) => string;
  cellContent: (tag: Tag) => string;
  sortable: boolean;
  width: number;
};

const column = ({ field, width = 120 }): Column => ({
  id: field,
  headerContent: removeLeadSnake(field),
  accessor: (event: Alert): string => {
    const value = previewEventFields.includes(field) ? event[field] : get(event.tags.find(
      ({ type: alertTagType }) => removeLeadSnake(alertTagType) === field,
    ), 'value', '-').toString();
    return removeLeadSnake(value);
  },
  tooltipContent: ({ value }: Tag): string => value,
  cellContent: ({ value }: Tag): string => value,
  sortable: false,
  width,
});

export function FilterPreviewTable({ alerts, conditionFields }: IProps): JSX.Element {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const tags = unionBy(flatten(map(alerts, 'tags')), ({ type }) => removeLeadSnake(type));
    const conditionColumns = conditionFields.map((field) => column({ field, width: 200 }));
    const allTags = tags.filter(({ type }) => !conditionFields.includes(type)).map(({ type }) => column({ field: type }));

    setColumns([...conditionColumns, ...allTags]);
  }, [alerts]);

  const getTagStyle = ({
    isReference,
    backgroundColor,
    additionalStyles = {},
  }): object => (isReference
    ? {
      style: {
        backgroundColor,
        ...additionalStyles,
      },
    }
    : {});

  const getColumnHeaderStyle = (columnId: string): object => getTagStyle({
    isReference: conditionFields.includes(columnId),
    backgroundColor: darkTheme.bp_light_purple,
    additionalStyles: { color: darkTheme.bp_gray_07 },
  });

  const getColumnStyle = (columnId: string): object => getTagStyle({
    isReference: conditionFields.includes(columnId),
    backgroundColor: darkTheme.bp_soft_purple,
  });

  return (
    <TableContainer aria-label="alert filter preview" role="table">
      <PreviewTable
        id="filter-preview-table"
        columns={columns}
        data={alerts}
        onColumnsChanged={setColumns}
        getTdProps={(_, __, { id }): object => getColumnStyle(id)}
        getTheadThProps={(_, __, { id }): object => getColumnHeaderStyle(id)}
      />
    </TableContainer>
  );
}
