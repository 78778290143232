import React from 'react';

const SortTooltip = () => (
  <div>
    <div>
      <b>Full name</b>
      <p>Ascending alphabetical order.</p>
    </div>
    <div>
      <b>Created</b>
      <p>Most recently created on top.</p>
    </div>
    <div>
      <b>Last seen</b>
      <p>Most recently seen user on top.</p>
    </div>
  </div>
);

export default SortTooltip;
