import { selectors as permissionsSelectors } from 'react/user/permissions';
import permissions from 'common/routing/permissions';
import { compose, identity, curry } from 'lodash/fp';
import get from 'lodash/get';
import boolToLodashOrder from 'common/utils/boolToLodashOrder';
import orderBy from 'lodash/orderBy';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { generateBaseSelectors } from '../redux.utils';
import { filterApiKeysByDropDown, filterApiKeysByText } from './utils';
import { path, DEFAULT_SORT_MODE } from './constants';

const selectors = generateBaseSelectors(path);

selectors.getActionsPermissions = (state) => {
  const userPermissions = permissionsSelectors.getPermissionsList(state);
  const hasEditPermission = userPermissions.includes(permissions.apiKeys.update);
  const hasDeletePermission = userPermissions.includes(permissions.apiKeys.delete);
  return { hasEditPermission, hasDeletePermission };
};

selectors.getApiKeys = (state) => {
  const apiKeysList = get(state, `${path}.apiKeys`, []);
  const { field, ascending } = selectors.getSortMode(state);

  const settingsSortingFT = get(
    featureTogglesSelectors.getFeatureToggles(state),
    'frontier_settings_sorting_and_filters',
    false
  );

  const sortItems = (apiKeys) => {
    const itemsSortFieldUndefined = apiKeys.filter((item) => !item[field]);
    const itemsSortFieldFiltered = apiKeys.filter((item) => item[field]);
    const itemsSortField = orderBy(
      itemsSortFieldFiltered,
      [field],
      [ascending].map(boolToLodashOrder)
    );

    return [...itemsSortField, ...itemsSortFieldUndefined];
  };

  const filterText = selectors.getFilterText(state);

  return compose(
    settingsSortingFT
      ? curry(filterApiKeysByDropDown)(selectors.getFiltersDropDownState(state))
      : identity,
    curry(sortItems),
    curry(filterApiKeysByText)(filterText)
  )(apiKeysList);
};

selectors.getApiKey = (state, id) => {
  const apiKeys = selectors.getApiKeys(state);
  if (!apiKeys || apiKeys.length === 0) {
    return null;
  }
  return apiKeys.filter((apiKey) => apiKey.id === id)[0];
};

selectors.getSortMode = (state) => get(state, path).sortMode || DEFAULT_SORT_MODE;

export default selectors;
