import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React from 'react';
import { Tooltip, HBox, Button } from '@bp/kung-fu';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { BamBaseInput } from '@bp/bam';
import { Anchorize } from 'react/common/components';
import { onKeyboardInteractionEvent } from 'react/helpers/on-keyboard-interaction-event';
import { path } from '../../constants';
import { validateTextValue } from '../../utils';
import styles from './IncidentTagsGridTextCell.scss';

class IncidentTagsGridTextCell extends React.Component {
  componentDidMount() {
    this.isEllipsisNeeded();
  }

  componentDidUpdate(prevProps, prevState) {
    const { gridWidth, truncate, initialValue } = this.props;
    if (!truncate) return;
    if (
      gridWidth !== prevProps.gridWidth ||
      initialValue.tag_value !== prevProps.initialValue.tag_value
    ) {
      this.isEllipsisNeeded();
    }
  }

  onValueClick = (e) => {
    if (!get(e, 'target.href')) {
      this.props.openEditor();
    }
  };

  onValueKeyboardInteraction = onKeyboardInteractionEvent(this.onValueClick);

  isEllipsisNeeded() {
    const { handleIsValueEllipsis } = this.props;
    if (!isNil(this.watchModeRef.current)) {
      handleIsValueEllipsis(
        this.watchModeRef.current.scrollWidth,
        this.watchModeRef.current.offsetWidth
      );
    }
  }

  watchModeRef = React.createRef();

  handleSubmit = () => {
    this.props.onSubmit();
  };

  render() {
    const {
      initialValue,
      editMode,
      truncate,
      tagsData,
      currValue,
      error,
      hasEditPermission,
      onValueChange,
      onSubmit,
      closeEditor,
      onValidationChange,
    } = this.props;

    const tagValue = get(initialValue, 'tag_value');
    const hasValue = tagValue !== undefined && tagValue !== null;
    const contentClasses = cn({
      [styles.value]: true,
      [styles['value-ellipsis']]: truncate,
      [styles['value-empty']]: !hasValue,
      [styles['value-readOnly']]: !hasEditPermission,
    });
    const editorClasses = cn({
      [styles.editor]: true,
      [styles['editor-error']]: !!error,
    });
    return editMode ? (
      <div className={editorClasses}>
        <div className={styles.editor__input}>
          <BamBaseInput
            autoFocus
            placeholder="Enter value..."
            inputtype="textarea"
            aria-label="Enter tag value"
            role="textbox"
            maxRows={3}
            value={currValue}
            onChange={(e) => {
              const currValue = get(e.target, 'value', '');
              const error = validateTextValue(currValue);
              onValidationChange(error);
              onValueChange(currValue);
            }}
          />
        </div>
        <HBox gap="5px" className={styles.editor__footer}>
          <Button
            isLoading={editMode && tagsData.isUpdating}
            variant="primary"
            size="small"
            width="54px"
            disabled={!!error}
            onClick={currValue !== tagValue ? this.handleSubmit : closeEditor}
          >
            Save
          </Button>

          <Button size="small" variant="secondary" width="54px" onClick={closeEditor}>
            Cancel
          </Button>
        </HBox>
      </div>
    ) : (
      <Tooltip
        isActive={hasValue}
        text={hasValue ? <HBox className={styles.tooltip_content}>{tagValue.toString()}</HBox> : ''}
        maxWidth="300px"
        placement="bottom-start"
      >
        <div
          ref={this.watchModeRef}
          className={contentClasses}
          onClick={this.onValueClick}
          onKeyPress={this.onValueKeyboardInteraction}
          role="button"
          aria-label={hasValue ? tagValue.toString() : 'Enter tag value'}
          tabIndex={0}
        >
          {hasValue ? (
            <Anchorize
              className={styles['tag-value']}
              input={tagValue.toString().replace(/<(?!a)(?!\/a)/g, '&lt;')}
              anchorClassName={styles.link}
            />
          ) : (
            <React.Fragment>None</React.Fragment>
          )}
        </div>
      </Tooltip>
    );
  }
}

IncidentTagsGridTextCell.propTypes = {
  initialValue: PropTypes.shape({ tag_value: PropTypes.string }),
  currValue: PropTypes.string,
  error: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
  truncate: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  tagsData: PropTypes.object.isRequired, //eslint-disable-line
  onValueChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  openEditor: PropTypes.func.isRequired,
  closeEditor: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  handleIsValueEllipsis: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  gridWidth: PropTypes.string.isRequired,
};

IncidentTagsGridTextCell.defaultProps = {
  initialValue: { tag_value: undefined },
  currValue: '',
  error: null,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  const tagsData = get(state, path);
  return {
    organization,
    user,
    tagsData,
  };
};

export default connect(mapStateToProps, null)(hot(module)(IncidentTagsGridTextCell));
