import React from 'react';
import PropTypes from 'prop-types';
import { Popper } from 'react-popper';
import { PortalWithState } from 'react-portal';
import './popup.scss';

export default function PopupHoc(Component) {
  return class PopupWrapper extends React.PureComponent {
    static propTypes = {
      onClose: PropTypes.func,
    };

    static defaultProps = {
      onClose: undefined,
    };

    state = {
      ref: null, // eslint-disable-line react/no-unused-state
    };

    body = document.querySelector('body'); // eslint-disable-line

    setPopupProps = (popupProps = {}) => {
      popupProps.open ? this.open() : this.close();
      this.setState({ popupProps });
    };

    componentDidMount() {
      window.addEventListener('resize', this.closePopup);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.closePopup);
    }

    closePopup = () => {
      const { onClose } = this.props;
      onClose && onClose();
      this.setPopupProps({ open: false });
    };

    render() {
      const { ...props } = this.props;
      const { popupProps = {} } = this.state;
      const { open, children, style: popupStyle, ...restPopupProps } = popupProps;
      return (
        <PortalWithState closeOnOutsideClick onClose={popupProps.onClose}>
          {({ openPortal, closePortal, isOpen, portal }) => {
            if (!this.open || !this.close) {
              this.open = openPortal;
              this.close = closePortal;
            }
            return (
              <React.Fragment>
                <Component {...props} setPopupProps={this.setPopupProps} openPortal={openPortal} />
                {
                  portal(
                    <Popper {...restPopupProps}>
                      {({ placement, ref, style }) => (
                        <div
                          className="bam bam-popup"
                          ref={ref}
                          style={{ ...style, ...popupStyle }}
                          data-placement={placement}
                        >
                          {children}
                        </div>
                      )}
                    </Popper>
                  ) //eslint-disable-line
                }
              </React.Fragment>
            );
          }}
        </PortalWithState>
      );
    }
  };
}
