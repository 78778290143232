import get from 'lodash/get';
angular.module('bigpanda').directive('dashboardSidebar', dashboardSidebar);

function dashboardSidebar(DashboardsSortingUtils, PERMISSIONS_NAMES, Permissions) {
  return {
    scope: {
      dashboards: '<',
      dashboardCustomizations: '<',
      toggleNonFavs: '&',
    },
    templateUrl: 'dashboard/dashboard_sidebar',
    restrict: 'E',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($state) {
    const vm = this;
    vm.hasDashboardActionPermissions =
      Permissions.isPermitted(PERMISSIONS_NAMES.dashboards.update) ||
      Permissions.isPermitted(PERMISSIONS_NAMES.dashboards.create) ||
      Permissions.isPermitted(PERMISSIONS_NAMES.dashboards.delete);
    vm.dashboardPermissions = PERMISSIONS_NAMES.dashboards;
    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.execAction = execAction;

    function onInit() {
      refreshViewModel();
    }

    function onChanges() {
      refreshViewModel();
    }

    function sortDashboards() {
      const sortedDashboards = DashboardsSortingUtils.sort(
        vm.dashboards,
        vm.dashboardCustomizations
      );
      vm.favoriteDashboards = sortedDashboards.favoriteDashboards;
      vm.nonFavoriteDashboards = sortedDashboards.nonFavoriteDashboards;
    }

    function refreshViewModel() {
      sortDashboards();
      updateNonFavsToggle();
    }

    function updateNonFavsToggle() {
      vm.nonFavsHidden = get(
        vm.dashboardCustomizations,
        'navigation_list.hide_non_favorites',
        false
      );
    }

    function editAction(dashboard) {
      $state.go('.edit', { dashboardId: dashboard._id });
    }

    function deleteAction(dashboard) {
      $state.go('.delete', { dashboardId: dashboard._id });
    }

    function duplicateAction(dashboard) {
      $state.go('.duplicate', { dashboardId: dashboard._id });
    }

    function execAction(action, dashboard) {
      action.exe(dashboard);
    }

    vm.actions = [
      {
        icon: 'bp-icon-edit',
        label: 'Edit',
        exe: editAction,
        permission: PERMISSIONS_NAMES.dashboards.update,
      },
      {
        icon: 'bp-icon-duplicate',
        label: 'Duplicate',
        exe: duplicateAction,
        permission: PERMISSIONS_NAMES.dashboards.create,
      },
      {
        icon: 'bp-icon-trash',
        label: 'Delete',
        exe: deleteAction,
        permission: PERMISSIONS_NAMES.dashboards.delete,
      },
      // TODO: {id: 4, name: '<i class="bp-icon-star-hollow"></i>Unstar this'}];
    ];
  }
}
