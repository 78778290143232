import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import * as api from 'common/endpoints/changes';
import actionTypes from './actionTypes';
import actions from './actions';
import { SORT_MATCHES } from './Consts';
import { MATCH_CERTAINTY_TYPES as MCT } from './RelatedMetadata/RelatedMetadata';

const loadChanges = (action$, store) =>
  action$.ofType(actionTypes.LOAD_CHANGES).mergeMap(async ({ payload = {}, summaryOnly }) => {
    try {
      const changesStore = get(store.getState(), 'modules.changes');
      const { incidentId, nextLink } = payload;
      const { results, ...body } = await api.getChanges({
        ...payload,
        sort: changesStore.checked ? SORT_MATCHES : '',
      });
      const mergeResults = nextLink;
      const nextChanges = summaryOnly
        ? []
        : [...(mergeResults ? changesStore.changes : []), ...results];
      const nextPayload = {
        ...body,
        changes: nextChanges,
        incidentId,
      };
      return actions.loadChangesSuccessfully(nextPayload);
    } catch (error) {
      console.error(error);
      return actions.loadChangesFailed(error);
    }
  });

const loadRelatedChanges = (action$, store) =>
  action$.ofType(actionTypes.LOAD_RELATED_CHANGES).mergeMap(async ({ payload = {} }) => {
    try {
      const changesStore = get(store.getState(), 'modules.changes');
      const { incidentId, nextLink } = payload;
      const { nextLinkHeader, relatedChanges, totalRelatedCount } = await api.getRelatedChanges({
        ...payload,
      });
      const relatedChangesParsed = buildRelatedChangesArr(relatedChanges);
      const mergeResults = nextLink;
      const nextChanges = [...(mergeResults ? changesStore.changes : []), ...relatedChangesParsed];
      const nextPayload = {
        nextLink: nextLinkHeader,
        changes: nextChanges,
        incidentId,
        totalRelatedCount: totalRelatedCount,
      };
      return actions.loadRelatedChangesSuccessfully(nextPayload);
    } catch (error) {
      console.error(error);
      return actions.loadChangesFailed(error);
    }
  });

const loadSearchChanges = (action$, store) =>
  action$ // changes V2
    .ofType(actionTypes.LOAD_SEARCH_CHANGES)
    .mergeMap(async ({ payload = {}, summaryOnly }) => {
      try {
        const changesStore = get(store.getState(), 'modules.changes');
        const { incidentId, nextLink, startTimeFrame: from, endTimeFrame: to } = payload;
        const { results, ...body } = await api.getSearchRelatedChanges({ ...payload, to, from });
        const mergeResults = nextLink;
        const nextChanges = summaryOnly
          ? []
          : [...(mergeResults ? changesStore.changes : []), ...results];
        const nextPayload = {
          ...body,
          changes: nextChanges,
          incidentId,
        };
        return actions.loadSearchChangesSuccessfully(nextPayload);
      } catch (error) {
        console.error(error);
        return actions.loadChangesFailed(error);
      }
    });

export const buildRelatedChangesArr = (relatedChanges) =>
  relatedChanges.map((relatedChange) => {
    const { change, ...relatedMetadata } = relatedChange;
    return { ...change, relatedMetadata };
  });

const upsertChangesMetadata = (action$, store) =>
  action$.ofType(actionTypes.UPSERT_CHANGES_METADATA).mergeMap(async ({ payload = {} }) => {
    try {
      const { relatedMetadata = {}, change = {} } = payload;
      const { modules: { changes } = {} } = store.getState();
      const { data } = await api.upsertChangeMetadata(change._id, { relatedMetadata });
      const [nextChanges, nextTotalRelatedCount] = getNextChangesAndTotalRelated({
        prevChanges: changes.changes,
        prevTotalRelatedCount: changes.totalRelatedCount,
        relatedMetadata: data,
        checked: changes.checked,
        rccOnly: changes.rccOnly,
      });
      const updatedChange = nextChanges.find((c) => c._id === change._id);
      return actions.upsertChangesMetadataSuccessfully({
        relatedMetadata: data,
        change: updatedChange,
        changes: nextChanges,
        totalRelatedCount: nextTotalRelatedCount,
      });
    } catch (error) {
      console.error(error);
      return actions.upsertChangesMetadataFailed(error);
    }
  });

export const sortChanges = (changeA, changeB) => {
  const relatedMetadataA = get(changeA, 'relatedMetadata', {});
  const relatedMetadataB = get(changeB, 'relatedMetadata', {});
  if (relatedMetadataA.match_certainty && relatedMetadataB.match_certainty) {
    if (relatedMetadataA.match_certainty === relatedMetadataB.match_certainty) {
      return sortChangesByStartTime(changeA, changeB);
    }
    if (relatedMetadataA.match_certainty === MCT.MATCH) {
      return -1;
    }
    if (relatedMetadataB.match_certainty === MCT.MATCH) {
      return 1;
    }
    if (relatedMetadataA.match_certainty === MCT.SUSPECT) {
      return -1;
    }
    return 1;
  }

  if (!relatedMetadataA.match_certainty && !relatedMetadataB.match_certainty) {
    return sortChangesByStartTime(changeA, changeB);
  }
  if (!relatedMetadataA.match_certainty) {
    return 1;
  }
  return -1;
};

export const sortChangesByStartTime = (changeA, changeB) => changeB.start - changeA.start;

export const getNextTotalRelatedCountIncrement = (prevMCT, currentMCT) => {
  if (prevMCT === MCT.NONE && currentMCT !== MCT.NONE) {
    return 1;
  }
  if (prevMCT !== MCT.NONE && currentMCT === MCT.NONE) {
    return -1;
  }
  return 0;
};

export const getNextChangesAndTotalRelated = ({
  prevChanges,
  prevTotalRelatedCount,
  relatedMetadata,
  checked,
  rccOnly,
}) => {
  const prevChange = prevChanges.find((change) => change._id === relatedMetadata.change_id);
  const prevMatchCertainty = get(prevChange, 'relatedMetadata.match_certainty', MCT.NONE);
  if (prevChange) {
    prevChange.relatedMetadata = relatedMetadata;
  }
  const nextTotalRelatedCount =
    prevTotalRelatedCount +
    getNextTotalRelatedCountIncrement(prevMatchCertainty, relatedMetadata.match_certainty);
  checked && prevChanges.sort(sortChanges);
  if (rccOnly) {
    prevChanges = prevChanges.filter(
      (change) => change.relatedMetadata && change.relatedMetadata.match_certainty !== 'NONE'
    );
  }
  return [prevChanges, nextTotalRelatedCount];
};

export default combineEpics(
  loadChanges,
  loadRelatedChanges,
  loadSearchChanges,
  upsertChangesMetadata
);
