import { AllSizes, Divider, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const KeyText = styled(Text).attrs({ size: AllSizes.XXLARGE })`
    color: ${(p) => p.theme.bp_gray_06};
    font-weight: bold;
    margin-inline-end: 4px;
`;

export const ValueText = styled(Text).attrs({ size: AllSizes.XXLARGE })`
    color: ${(p) => p.theme.bp_gray_08};
    font-weight: bold;
`;

export const ValueTextWithDivider = styled(ValueText)``;

export const CustomDivider = styled(Divider)`
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-inline-end: 8px;
    & div {
        display: inline-block;
        margin-bottom: 3px;
    }
`;

export const Container = styled(Text)`
    & ${ValueTextWithDivider}:not(:last-of-type)::after {
        content: ',';
        margin-inline-end: 4px;
    }
`;
