import get from 'lodash/get';
import countBy from 'lodash/countBy';
import capitalize from 'lodash/capitalize';
import memoize from 'memoize-one';
import pluralize from 'common/utils/utils';
import isMapping from './modals/utils/isMapping';
import { ANY_OPTION_VALUE } from './constants';

const isTagActive = (tag, useTagOps) => {
  if (useTagOps) {
    return tag.active;
  }

  return get(tag, 'enrichments', []).some(({ active }) => active);
};

const tagTypeString = ({ enrichments = [] }) => {
  const types = countBy(enrichments, 'type');
  const keys = Object.keys(types);
  if (keys.length > 1) {
    return 'Multi Type';
  }
  return capitalize(keys[0]);
};

const moveItem = (list, sourceIndex, destinationIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

const sourceSystemsOptions = memoize((integrationOptions) => [
  { key: '*', text: 'All Systems', value: '*', tooltip: 'All Systems', display: 'All Systems' },
  ...(integrationOptions || []).map((i) => ({
    key: i.value,
    text: i.display,
    value: i.value,
    tooltip: i.display,
    display: i.display,
  })),
]);

const multiTypeEnrichmentTypes = (enrichments) => {
  const enrichmentTypesCount = countBy(enrichments, 'type');
  return Object.keys(enrichmentTypesCount)
    .map(
      (type) =>
        `${enrichmentTypesCount[type]} ${capitalize(pluralize(enrichmentTypesCount[type], type))}`
    )
    .join(', ');
};

const hasMapping = (enrichments = []) => enrichments.some(({ type }) => isMapping(type));

const isAnyFilterApplied = memoize((filters = {}) => {
  if (!filters) {
    return false;
  }

  return Object.values(filters).some((e) => e.value !== ANY_OPTION_VALUE);
});

export {
  isTagActive,
  tagTypeString,
  moveItem,
  sourceSystemsOptions,
  multiTypeEnrichmentTypes,
  hasMapping,
  isAnyFilterApplied,
};
