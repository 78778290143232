import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { hot } from 'react-hot-loader';
import { Field } from 'react-final-form';
import { BamInput, BamDropdownField, BamAddNoteField, BamCheckboxField } from '@bp/bam';
import { Tooltip } from '@bp/kung-fu';
import FormulaExpressionList from '../../components/FormulaExpressionList';
import FormulaCondition from './components/formula/FormulaCondition';
import FormulaValue from './components/formula/FormulaValue';
import textTooltip from './components/formula/TextTooltip';
import multiValueTooltip from './components/formula/MultiValueTooltipTooltip';
import {
  tagDefinitionIconByType,
  incidentTagModalActionTypes,
  incidentTagTypes,
  defaultFormulaExpression,
  tooltipWidthByType,
} from '../../constants';
import {
  validateNameField,
  generatedTypesOptions,
  validateDescriptionField,
} from '../IncidentLabelForm.utils';
import styles from './TextForm.scss';
import FormulaExpressionListHeader from './components/FormulaExpressionListHeader';
class TextForm extends React.Component {
  componentDidMount() {
    const { incidentLabels, loadMultipleIncidentLabels } = this.props;
    if (!incidentLabels) {
      loadMultipleIncidentLabels();
    }
  }

  tooltipByType = (type) => {
    switch (type) {
      case incidentTagTypes.TEXT:
        return textTooltip;
      case incidentTagTypes.MULTI_VALUE:
        return multiValueTooltip;
    }
  };

  render() {
    const {
      incidentLabel,
      incidentLabels,
      currentIncidentLabelId,
      featureToggles,
      mode,
      formErrors,
      unifiedTags,
      displayErrorContent,
      closeErrorMessage,
    } = this.props;

    const disableQueryHelper = get(featureToggles, 'disable_query_helper', false);

    const existedNames = incidentLabels
      .map((label) => label.name)
      .filter((name) => name !== incidentLabel.name);
    const isCreateMode = mode === incidentTagModalActionTypes.CREATE;
    const options = generatedTypesOptions;
    const { type, enrichment_config: enrichmentConfig } = incidentLabel;
    const allowAppend = type === incidentTagTypes.MULTI_VALUE;

    const getToolTipContent = () => {
      if (currentIncidentLabelId && !isCreateMode) {
        return 'Changing type of an existing tag is not possible';
      }
    };

    const canManualInput = get(featureToggles, 'allow_tags_manual_input', true);

    return (
      <div className={styles['text-form']}>
        <div className={styles.header}>
          <div className={styles['header-name']}>
            <Field
              name="name"
              validate={(value) => validateNameField(value, existedNames)}
              render={({ input, meta }) => (
                <div className={meta.error && meta.submitFailed ? styles.fieldError : ''}>
                  <BamInput
                    input={{ ...input }}
                    aria-label="Tag Name"
                    title="Tag Name"
                    placeholder="Enter a name"
                    autoFocus
                  />
                  {meta.error && meta.submitFailed && (
                    <span className={styles['error-text']}>{meta.error}</span>
                  )}
                </div>
              )}
              subscription={{ value: true, error: true, submitFailed: true }}
            />
          </div>
          <div className={styles['header-type']}>
            <Field
              name="type"
              render={({ input, meta }) => (
                <div className={styles['header-type-option']}>
                  <Tooltip
                    isActive={!isCreateMode}
                    placement="bottom-start"
                    text={getToolTipContent()}
                    isAlwaysVisible={meta.active}
                  >
                    <div>
                      <BamDropdownField
                        limitdisplayeditems={options.length + 1}
                        icon={
                          <i
                            className={cn(
                              styles['header-type-option-selected'],
                              tagDefinitionIconByType(input.value)
                            )}
                          />
                        }
                        title="Type"
                        disabled={!isCreateMode}
                        input={{ value: input.value, onChange: input.onChange }}
                        baseProps={{ maxHeight: 170 }}
                        options={options}
                        minimalOptionsLengthForSearch={generatedTypesOptions.length + 1}
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
              subscription={{ value: true }}
            />
          </div>
          {canManualInput && (
            <div className={styles.manualInput}>
              <div className={styles.horizontalSeparator} />
              <div className={styles.checkboxLayout}>
                <Field
                  name="canManualInput"
                  component={BamCheckboxField}
                  label="Allow manual input"
                  baseProps={{
                    hint:
                      'Enables users to manually change the values. Manually changing tag data will stop BigPanda’s automated incident enrichment for that tag.',
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.description}>
          <Field
            validate={(value) => validateDescriptionField(value)}
            name="description"
            render={({ input, meta }) => (
              <div className={meta.error ? styles.fieldError : ''}>
                <BamAddNoteField
                  input={{ ...input }}
                  aria-label="Add Description"
                  title="Add Description"
                  openStateDescription="(optional)"
                  openStateTitle="Description"
                  maxRows={2}
                />
                {meta.error && <span className={styles['error-text']}>{meta.error}</span>}
              </div>
            )}
            subscription={{ value: true, error: true }}
          />
        </div>
        <div className={styles.separator} />
        <div className={styles.patterns}>
          <FormulaExpressionList
            arrayName="enrichment_config.items"
            items={enrichmentConfig ? enrichmentConfig.items : []}
            listHeader={
              <FormulaExpressionListHeader
                counter={enrichmentConfig ? enrichmentConfig.items.length : 0}
                allowAppend={allowAppend}
                appendValue={enrichmentConfig ? enrichmentConfig.append_matching_items : false}
              />
            }
            title="Item"
            collapseClassName={styles.expression}
            renderItem={(keyName, formula, error) => (
              <React.Fragment>
                <FormulaCondition
                  name={`${keyName}.condition`}
                  defaultValue={formula.condition}
                  title={
                    <span>
                      Condition{' '}
                      <span className={styles['formula-expression-subtitle']}>(Optional)</span>
                    </span>
                  }
                  placeholder="Query filter (e.g. application = billing*)"
                  hint="Use a condition to define when to apply the value"
                  unifiedTags={unifiedTags}
                  disableQueryHelper={disableQueryHelper}
                />
                <FormulaValue
                  name={`${keyName}.value`}
                  defaultValue={formula.value}
                  title={<span>Value </span>}
                  placeholder="Fixed value or Formula"
                  hint="Use formula to define the tag value"
                  tooltip={this.tooltipByType(incidentLabel.type)}
                  tooltipWidth={tooltipWidthByType(incidentLabel.type)}
                  error={error}
                />
              </React.Fragment>
            )}
            defaultItem={defaultFormulaExpression()}
            formErrors={formErrors}
            featureToggles={featureToggles}
            displayErrorContent={displayErrorContent}
            closeErrorMessage={closeErrorMessage}
          />
        </div>
      </div>
    );
  }
}

TextForm.propTypes = {
  currentIncidentLabelId: PropTypes.string,
  incidentLabel: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
    type: PropTypes.oneOfType(incidentTagTypes),
    enrichment_config: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          condition: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }),
  }),
  incidentLabels: PropTypes.arrayOf(PropTypes.object),
  loadMultipleIncidentLabels: PropTypes.func.isRequired,
  featureToggles: PropTypes.shape({
    allow_tags_manual_input: PropTypes.bool,
    disable_query_helper: PropTypes.bool,
  }).isRequired,
  mode: PropTypes.string.isRequired,
  formErrors: PropTypes.shape({}),
  unifiedTags: PropTypes.arrayOf(PropTypes.shape({})),
  displayErrorContent: PropTypes.func.isRequired,
  closeErrorMessage: PropTypes.func.isRequired,
};

TextForm.defaultProps = {
  currentIncidentLabelId: undefined,
  incidentLabel: {
    active: true,
    type: incidentTagTypes.TEXT,
  },
  incidentLabels: [],
  formErrors: {},
  unifiedTags: undefined,
};

export default hot(module)(TextForm);
