import React from 'react';
import PropTypes from 'prop-types';
import styles from './sharing_quotas_table.scss';
import SharingQuotasTableRow from './table-row/SharingQuotasTableRow';
import integrationPropType from '../props/sharing_quotas_prop_types';

const SharingQuotasTable = ({ integrationsData, requestQuotaChange }) => (
  <table className={styles.table}>
    <thead>
      <tr>
        <th className={styles.nameColumn}>System</th>
        <th className={styles.quotaColumn}>Quota</th>
        <th className={styles.actionColumn}>Action</th>
      </tr>
    </thead>
    <tbody>
      {integrationsData.map((integration) => (
        <SharingQuotasTableRow
          key={integration.id}
          requestQuotaChange={requestQuotaChange}
          integration={integration}
        />
      ))}
    </tbody>
  </table>
);

SharingQuotasTable.propTypes = {
  integrationsData: PropTypes.arrayOf(integrationPropType).isRequired,
  requestQuotaChange: PropTypes.func.isRequired,
};

export default SharingQuotasTable;
