import { hot } from 'react-hot-loader';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DocumentTitle from 'react-document-title';
import styles from './settings_full_screen.scss';

class SettingsFullScreen extends React.PureComponent {
  render() {
    const { title, description, children, className } = this.props;
    const screenClass = cn(styles.settingsFullContainer, className);
    return (
      <DocumentTitle title={title}>
        <div className={screenClass}>
          <div className={cn(styles.content, 'bam')}>
            <h4 className={styles.pageTitle}>{title}</h4>
            <hr />
            {!!description && <p className={styles.pageDescription}>{description}</p>}
            {children}
          </div>
          <div className={styles.sideHighlight} />
        </div>
      </DocumentTitle>
    );
  }
}

export default hot(module)(SettingsFullScreen);

SettingsFullScreen.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  description: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
