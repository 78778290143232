import actionTypes from './actionTypes';

const initialState = {
  enrichedUsersList: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ENRICHED_USERS_SUCCESS:
      return {
        ...state,
        enrichedUsersList: action.payload,
      };

    default:
      return state;
  }
}
