import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { switchLoginOrganization } from 'common/endpoints/users';
import { BamNotificationStatusOptions, bamNotify } from '@bp/bam';

const SSO_COOKIE_NAME = 'bigpandaSsoLogin';

const setCookie = () => {
  document.cookie = `${SSO_COOKIE_NAME}=true`;
};

const QUERY_KEYS = {
  USER_SWITCH_ORG: 'userSwitchOrg',
};

export function useSwitchOrganizationLogin() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const switchLoginMutation = useMutation(
    (organizationName) => switchLoginOrganization(organizationName),
    {
      onSuccess: (data) => {
        const { shouldRedirect, redirectUrl } = data;
        if (redirectUrl && shouldRedirect) {
          setCookie(SSO_COOKIE_NAME);
          window.location.href = redirectUrl;
        } else {
          queryClient.invalidateQueries(QUERY_KEYS.USER_SWITCH_ORG);
          window.location.reload();
        }
        return data;
      },
      onError: (error) => {
        console.log(error);
        showFailureMessage('organization', 'switch login');
        throw error;
      },
    }
  );

  async function switchOrganizationLogin(organizationName) {
    setIsLoading(true);
    try {
      await switchLoginMutation.mutateAsync(organizationName);
    } finally {
      setIsLoading(false);
    }
  }

  return { switchOrganizationLogin, isLoading };
}

export function showFailureMessage(objectName, action) {
  bamNotify({
    message: [
      { message: 'Failed to' },
      { message: action, highlight: true },
      { message: objectName },
    ],
    status: BamNotificationStatusOptions.FAILURE,
  });
}
