angular.module('bigpanda').constant('FOLDERS', [
  {
    id: 'active',
    name: 'Active',
    color: 'red',
    icon: 'bp-icon-flash',
  },
  {
    id: 'unhandled',
    name: 'Unhandled',
    color: 'red',
    icon: 'bp-icon-important',
  },
  {
    id: 'shared',
    name: 'Shared',
    color: 'blue',
    icon: 'bp-icon-share',
  },
  {
    id: 'snoozed',
    name: 'Snoozed',
    color: 'yellow',
    icon: 'bp-icon-snooze-full',
  },
  {
    id: 'maintenance',
    name: 'Maintenance',
    color: 'green',
    icon: 'bp-icon-maintenance-full',
  },
  {
    id: 'resolved',
    name: 'Resolved',
    subtitle: '(24h)',
    color: 'green',
    icon: 'bp-icon-resolve-full',
  },
]);
