import get from 'lodash/get';
import { path } from './constants';

const selectors = {};

selectors.getContacts = (state) => get(state, path).contactsList;
selectors.getContactByUserId = (state, userId) =>
  selectors.getContacts(state).find(({ bigpanda_user_id: id }) => id === userId);
selectors.getContactIdByUserId = (state, userId) =>
  (selectors.getContactByUserId(state, userId) || {})._id;

export default selectors;
