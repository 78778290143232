angular.module('bigpanda.utils').service('ModalService', ModalService);

function ModalService($mdDialog, $state, CompatibilityService) {
  const defaults = {
    escapeToClose: CompatibilityService.canClosePopupsWithEscape(),
    clickOutsideToClose: CompatibilityService.canClosePopupsWithBackdropClick(),
  };

  this.showModal = (options) => $mdDialog.show(Object.assign({}, defaults, options));
  this.hide = $mdDialog.hide;
}
