import { buildParserFromGrammer } from '@bp/bpql';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isObject from 'lodash/isObject';

import { parseQuery } from '../../../../../../../../../../app/common/modules/settings/QuerySourceParser';
import { MapCategoryRowName } from '../../../../../../hooks/useAuditTable/constants';
import { ActionType, AuditRow } from '../../../../../../hooks/useAuditTable/types';
import { AuditExpandDetails } from '../../types';
import { AuditStatus, FunctionType, NO_DATA } from './constants';

export function getStatus(rowData: AuditRow): AuditStatus {
  const { category, action, object } = rowData;

  if (action.toLowerCase() === ActionType.delete) {
    return AuditStatus.DELETED;
  }

  if (category.rowValue === MapCategoryRowName.auto_share_rules) {
    if (object.inactive) {
      return object.inactive ? AuditStatus.INACTIVE : AuditStatus.ACTIVE;
    }
    return AuditStatus.ACTIVE;
  }

  if (category.rowValue === MapCategoryRowName.users) {
    if (object.state !== 'valid') {
      return AuditStatus.PENDING;
    }
  }

  return AuditStatus.ACTIVE;
}

export function handleSourceSystem(rowData: AuditRow): string {
  const bpqlParser = buildParserFromGrammer('correlation');
  const filter = get(rowData, 'object.filter', NO_DATA);
  if (filter === NO_DATA) {
    return NO_DATA;
  }
  const bpql = bpqlParser(filter);
  const { sourceSystems } = parseQuery(bpql);
  if (sourceSystems) {
    return sourceSystems.map((sourceSystem) => sourceSystem.value || sourceSystem).join(', ');
  }
  return NO_DATA;
}

export function handleCorrelationTags(rowData: AuditRow): string[] | string {
  const tags = get(rowData, 'object.tags', NO_DATA);
  if (tags === NO_DATA || !Array.isArray(tags)) return NO_DATA;
  return flatten(tags.map((tag) => {
    if (isObject(tag)) {
      return Object.values(tag);
    }
    return tag;
  }));
}

export function handleShareVia(rowData: AuditRow): string {
  const shareVia = get(rowData, 'object.target_system_id', NO_DATA);
  if (shareVia !== NO_DATA) {
    return shareVia.split('.')[0];
  }
  return shareVia;
}

export function handleCorrelationExecOrder(rowData: AuditRow):
{ executionOrder: string, wasChanged: boolean } | string {
  const execOrder = get(rowData, 'object.execution_order', undefined);
  if (!execOrder) return NO_DATA;
  const wasChanged = get(rowData, 'object.execution_order_changed', false);
  return { executionOrder: execOrder, wasChanged };
}

export function handleFunctionType(rowData: AuditRow): string {
  const { enrichments } = rowData.object;
  if (!enrichments) return NO_DATA;

  const functionTypesSet = new Set((enrichments as Array<Record<string, FunctionType>>)
    .map((enrichment) => enrichment.type));

  if (functionTypesSet.size >= 2) return FunctionType.MultiType;

  return functionTypesSet.values().next().value;
}

export function handleExecutionOrder(rowData: AuditRow): string {
  const { enrichments } = rowData.object;
  if (!enrichments && !Array.isArray(enrichments)) return NO_DATA;

  return `#${(enrichments as Array<Record<string, unknown>>).length.toString()}`;
}

export function handleAutoShareEnvName(rowData: AuditRow): string {
  return get(rowData, 'changedObject', NO_DATA).split(' ')[0];
}

export function handleEnvironmentRole(rowData: AuditRow, isReadOnly: boolean): string {
  const path = isReadOnly ? 'object.read_only_roles' : 'object.roles';
  return get(rowData, path, NO_DATA).map((role) => role.name).join(', ');
}

const alertEnrichmentMapping = {
  'Alert Enrichment Tag Name': 'object.resource_id',
  'Function Type': {
    customTransform: (rowData): string => handleFunctionType(rowData),
  },
  'Execution Order': {
    customTransform: (rowData): string => handleExecutionOrder(rowData),
  },
  'Automatic Enrichment': 'object.enrichments',
};

export const CategoryDetails = {
  'Correlation Pattern': {
    'Source System': {
      customTransform: (rowData): string => handleSourceSystem(rowData),
    },
    'Cross System': 'object.cross_source',
    'Correlation Tags': {
      customTransform: (rowData): string[] | string => handleCorrelationTags(rowData),
    },
    'Time Window': 'object.time_window',
    'Query Filter': 'object.filter',
    'Execution Order': {
      customTransform:
      // eslint-disable-next-line max-len
      (rowData): { executionOrder: string, wasChanged: boolean } | string => handleCorrelationExecOrder(rowData),
    },
  },
  AutoShare: {
    Environment: {
      customTransform: (rowData): string => handleAutoShareEnvName(rowData),
    },
    'Share via': {
      customTransform: (rowData): string => handleShareVia(rowData),
    },
    Message: 'object.params.message',
  },
  User: {
    Name: 'object.name',
    Email: 'object.username',
  },
  Role: {
    Name: 'object.name',
  },
  JitDomain: {
    Name: 'object.domain',
  },
  ApiKey: {
    Name: 'object.name',
  },
  Environment: {
    'Environment name': 'object.name',
    Condition: 'object.filter',
    Description: 'object.description',
    'Environment Group Id': 'object.environment_group_id',
    Roles: {
      customTransform: (rowData): string => handleEnvironmentRole(rowData, false),
    },
    'Read Only Roles': {
      customTransform: (rowData): string => handleEnvironmentRole(rowData, true),
    },
  },
  Tag: alertEnrichmentMapping,
  'Tag Enrichments': alertEnrichmentMapping,
  'Tag Enrichments Order': alertEnrichmentMapping,
  'Maintenance Plan': {
    'Plan Name': 'object.plan_name',
    'Time Window': 'object.time_window',
  },
  'Alert Filtering': {
    'Filter Name': 'object.plan_name',
  },
};

export function mapRowDetails(auditData: AuditRow): AuditExpandDetails {
  const rowDetailsObject: AuditExpandDetails = {};

  const { category, object, resourceId } = auditData;
  rowDetailsObject[`${category.expandRowValue} ID`] = resourceId;
  rowDetailsObject.status = getStatus(auditData);
  if (object) {
    const mapper = CategoryDetails[category.expandRowValue];
    if (mapper) {
      Object.keys(mapper).forEach((mapperKey) => {
        if (mapper[mapperKey].customTransform) {
          try {
            rowDetailsObject[mapperKey] = mapper[mapperKey].customTransform(auditData);
          } catch (e) {
            rowDetailsObject[mapperKey] = NO_DATA;
          }
        } else {
          rowDetailsObject[mapperKey] = get(auditData, mapper[mapperKey], NO_DATA);
        }
      });
    }
  }
  return rowDetailsObject;
}
