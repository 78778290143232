import PropTypes from 'prop-types';

const customizationsPropType = PropTypes.shape({
  dashboards: PropTypes.shape({}),
  environments: PropTypes.shape({}),
  incidents: PropTypes.shape({}),
  shares: PropTypes.shape({}),
});

export default customizationsPropType;
