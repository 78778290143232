import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Label, Icon, CheckIcon, AddIcon, Divider } from './SelectItem.css';
import { variants } from '../constants';

const SelectItem = ({
  display,
  icon,
  borderBottom,
  multiChoice,
  isSelected,
  isHighlighted,
  getItemProps,
  variant,
  inline,
  index,
  item,
  selectItem,
  onSelect,
}) => {
  return (
    <React.Fragment>
      <Wrapper
        className="select-item-wrapper"
        aria-label={display}
        role="option"
        inline={inline}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        onMouseUp={(e) => {
          selectItem(item);
          onSelect(item);
        }}
        {...getItemProps({ item, index })}
      >
        {icon && <Icon className={icon} />}
        <Label isSelected={isSelected}>{display}</Label>
        {isSelected && <CheckIcon className="bp-icon bp-icon-ok" aria-hidden />}
        {multiChoice && <AddIcon className="bp-icon  bp-icon-plus-icon" aria-hidden />}
      </Wrapper>
      {borderBottom && <Divider />}
    </React.Fragment>
  );
};

SelectItem.propTypes = {
  display: PropTypes.string,
  icon: PropTypes.string,
  borderBottom: PropTypes.bool,
  multiChoice: PropTypes.bool,
  isSelected: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  getItemProps: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(variants)),
  inline: PropTypes.bool,
  index: PropTypes.number,
  item: PropTypes.shape({}),
  selectItem: PropTypes.func,
  onSelect: PropTypes.func,
};

SelectItem.defaultProps = {
  selectItem: () => {},
  onSelect: () => {},
};

export default SelectItem;
