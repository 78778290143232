import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './settings_list_empty_state.scss';

const SettingsListEmptyState = ({ title, subtitle, children }) => (
  <div className={styles['settings-list-empty-state-container']}>
    <div className={classnames(styles.message, styles.title)}>{title}</div>
    <div className={classnames(styles.message, styles.subtitle)}>{subtitle}</div>
    {children}
  </div>
);

SettingsListEmptyState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

SettingsListEmptyState.defaultProps = {
  title: 'No Matches Found',
  subtitle: 'Try refining your search query',
  children: null,
};

export default SettingsListEmptyState;
