import {
  AllSizes, Button,
  HBox, SCHBox, Text,
} from '@bp/kung-fu';
import styled from 'styled-components';

export const RowLinkWrapper = styled(HBox)`
  margin-left: auto;
  margin-right: 15%;

`;

export const RowLinkButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RowDetailsHeaderWrapper = styled.div`
  margin-bottom: 15px;
`;

export const RowDetailsTitleWrapper = styled(SCHBox)`
  gap: 5px;
  margin-bottom: 12px;
  font-size: 14px;
`;

export const RowDetailsTitle = styled(Text)`
  font-weight: bold;
  text-transform: capitalize;
`;

export const AdditionalDataSeparator = styled(Text)`
  font-size: ${AllSizes.LARGE};
  color: ${(p) => p.theme.bp_gray_05}
`;

export const LineSeparator = styled.div`
  height: 1px;
  background-color: ${(p) => p.theme.bp_gray_02};
`;
