// eslint-disable-next-line import/no-extraneous-dependencies
import { FormState } from 'final-form';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import { canPreviewWithTheseErrors } from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/canPreviewByType';
import ruleFieldName from '../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/ruleFieldName';
import { ExtendedRule, Tag } from '../../../types';
import DraggableRule from '../Modal/DraggableRule/DraggableRule';
import { LoadPreview, UnifiedTag } from '../RuleTypeForms/types';

// eslint-disable-next-line max-len
const isNewOrDuplicate = ({ __isNew, __isDuplicate }: ExtendedRule): boolean => __isNew || __isDuplicate;

interface IProps {
  onLoadPreview: LoadPreview;
  rulesValues: object;
  rule: ExtendedRule;
  index: number;
  dirtyFields: object;
  touched: FormState['touched'];
  errorRules: object;
  tag: Tag;
  onlyRule: boolean;
  isLastItem: boolean;
  forceFocus: boolean;
  forceFocusIndex: number;
  freeFocus: () => void | undefined;
  onValueChanged: (id: string) => void;
  unifiedTags: UnifiedTag[];
}

export function DraggableRuleWrapper({
  onLoadPreview,
  rulesValues,
  rule,
  index,
  dirtyFields,
  touched,
  errorRules,
  tag,
  onlyRule,
  isLastItem,
  forceFocus,
  forceFocusIndex,
  freeFocus,
  onValueChanged,
  unifiedTags,
}: IProps): JSX.Element {
  const defaultOpen = onlyRule || (isNewOrDuplicate(rule) && isLastItem);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [hasError, setHasError] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    // there wouldn't be new errors, or existing ones wouldn't disappear
    // if the collapsible is closed.
    if (!isOpen && !errorRules) {
      return;
    }

    setHasError(
      Object.keys(get(errorRules, rule.id, {})).some((field) => {
        const fieldName = ruleFieldName(rule.id, field);
        return (touched[fieldName] || dirtyFields[fieldName]) && get(errorRules, [rule.id, field]);
      }),
    );
  }, [errorRules, rule, touched, dirtyFields]);

  const canPreview = Object.keys(get(errorRules, rule.id, {})).every((field) => {
    const fieldName = ruleFieldName(rule.id, field);
    if (!get(errorRules, [rule.id, field])) return true;
    return canPreviewWithTheseErrors(get(errorRules, [rule.id, field]), fieldName);
  });

  return (
    <DraggableRule
      key={rule.id}
      rule={rule}
      index={index}
      unifiedTags={unifiedTags}
      tag={tag}
      canPreview={canPreview}
      values={get(rulesValues, rule.id)}
      onLoadPreview={onLoadPreview}
      hasError={hasError}
      defaultOpen={defaultOpen}
      forceFocus={index === forceFocusIndex && forceFocus}
      freeFocus={freeFocus}
      isNewOrDuplicate={isNewOrDuplicate(rule)}
      onValueChanged={onValueChanged}
      onCollapseChanged={(isOpenVal: boolean): void => setIsOpen(isOpenVal)}
      isLastRule={onlyRule}
      changed={hasChanged}
      setHasChanged={setHasChanged}
    />
  );
}

export default DraggableRuleWrapper;
