import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Collapsible, AddItemButton } from 'react/common/components';
import styles from './FormulaExprssionList.scss';
import FormulaExpressionSummary from './FormulaExpressionSummary';
import { maxNumberOfFormulaItems } from './../constants';
class FormulaExpressionList extends React.Component {
  addNewItem(e, push) {
    e.preventDefault();
    const { arrayName, defaultItem, items, displayErrorContent } = this.props;
    if (items.length === maxNumberOfFormulaItems) {
      displayErrorContent(
        `Cannot add a new item - maximum number of items is ${maxNumberOfFormulaItems}`
      );
    } else {
      push(arrayName, { ...defaultItem, id: Math.floor(Math.random() * 1000000) });
    }
  }

  render() {
    const {
      arrayName,
      title,
      collapseClassName,
      listHeader,
      renderItem,
      items,
      formErrors,
      featureToggles,
      closeErrorMessage,
    } = this.props;
    const buttonText = `New ${title}`;

    return (
      // The way the Form checks for changes (dirty state) is to compare all the key-names currently rendered within it.
      // So we added an empty <Field name={arrayName} render={() => null} /> , to keep the array as an actual final-form field and be able to identify
      // whenever the whole array of formulas is being deleted.
      <div>
        <Field name={arrayName} render={() => null} />
        {listHeader && <div className={styles['container-headline']}>{listHeader}</div>}
        {items.map((item, index) => {
          const fieldNamePrefix = `${arrayName}[${index}]`;
          const isPreviewActive = false;
          const collapseTitle = `${title} #${index + 1}`;
          const error =
            !isEmpty(formErrors) &&
            (get(formErrors, 'errors', []).find((serverError) => serverError.index === index) || {})
              .message;

          return (
            <Collapsible
              key={item.id}
              summaryRenderer={(props) => (
                <FormulaExpressionSummary
                  {...props}
                  arrayName={arrayName}
                  index={index}
                  title={collapseTitle}
                  isPreviewActive={isPreviewActive}
                  featureToggles={featureToggles}
                  closeErrorMessage={closeErrorMessage}
                />
              )}
              className={cn([styles.collapsible, collapseClassName])}
              defaultOpen
              aria-label={`Formula ${collapseTitle}`}
            >
              {renderItem(fieldNamePrefix, item, error)}
            </Collapsible>
          );
        })}
        <FormSpy>
          {({ mutators }) => (
            <AddItemButton onClick={(e) => this.addNewItem(e, mutators.push)} text={buttonText} />
          )}
        </FormSpy>
      </div>
    );
  }
}

FormulaExpressionList.propTypes = {
  title: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  listHeader: PropTypes.node,
  buttons: PropTypes.node,
  collapseClassName: PropTypes.string,
  arrayName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultItem: PropTypes.any.isRequired,
  formErrors: PropTypes.shape({}),
  featureToggles: PropTypes.shape({}).isRequired,
  displayErrorContent: PropTypes.func.isRequired,
  closeErrorMessage: PropTypes.func.isRequired,
};

FormulaExpressionList.defaultProps = {
  buttons: null,
  collapseClassName: '',
  listHeader: null,
  formErrors: {},
};

export default FormulaExpressionList;
