import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { Button } from '@bp/kung-fu';
import { BamModal } from '@bp/bam';
import styles from './sso_modal.scss';

class SSOActivateModal extends React.Component {
  onAction = () => {
    this.ref.close();
    this.props.onAction();
  };

  render() {
    const { triggerProps } = this.props;
    return (
      <BamModal
        ref={(r) => {
          this.ref = r;
        }}
        trigger={
          <div className={styles.button_container}>
            <Button {...triggerProps}>{triggerProps.text}</Button>
          </div>
        }
        className={styles['sso-modal']}
        titleIcon="bp-icon-power-off"
        title="Activate SSO"
        positiveButtonText="Activate"
        action={this.onAction}
        style={{ width: 360 }}
      >
        <div className={styles['modal-title']}>Are you sure you want to enable SSO?</div>
        <div className={styles['modal-question']}>
          You’ll need to re-enter your provider details in order to enable SSO for your
          organization.
        </div>
      </BamModal>
    );
  }
}

SSOActivateModal.propTypes = {
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
  }).isRequired,
  onAction: PropTypes.func.isRequired,
};

export default hot(module)(SSOActivateModal);
