angular.module('bigpanda').directive('environmentGroups', environmentGroups);

function environmentGroups() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'overview/environments/groups/list/environment_groups',
    controller: 'EnvironmentGroupsCtrl',
    controllerAs: 'vm',
  };
}
