import { path } from './constants';

const actionTypes = {
  LOAD_USER: `${path}.LOAD_USER`,
  LOAD_USER_SUCCESS: `${path}.LOAD_USER_SUCCESS`,
  LOAD_CUSTOMIZATIONS: `${path}.LOAD_CUSTOMIZATIONS`,
  LOAD_CUSTOMIZATIONS_SUCCESS: `${path}.LOAD_CUSTOMIZATIONS_SUCCESS`,
  LOAD_CUSTOMIZATIONS_FAILURE: `${path}.LOAD_CUSTOMIZATIONS_FAILURE`,
};

export default actionTypes;
