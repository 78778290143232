import forEach from 'lodash/forEach';
const querystring = require('querystring');

angular.module('bigpanda').directive('pagesNavigation', pagesNavigation);

function pagesNavigation() {
  return {
    restrict: 'E',
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'topbar/navigation/pages_navigation',
  };
}

function controller(
  $scope,
  $state,
  $location,
  $mdMedia,
  UserFeatureTogglesService,
  EnvironmentsService,
  EnvironmentsUtils,
  pubSubService,
  bootstrap,
  RoutesConstants,
  Permissions
) {
  const vm = this;

  vm.mdMedia = $mdMedia;
  vm.smallTitle = null;
  vm.pages = createBootstrapFakePages();

  vm.switchTo = switchTo;

  init();

  function init() {
    if (bootstrap.isReady()) {
      setAndReload();
    } else {
      pubSubService.on(
        'bootstrap.finished',
        () => {
          setAndReload();
        },
        $scope
      );
    }
  }

  function setAndReload() {
    setPages($state.current.name);
    $scope.$on('$stateChangeStart', reloadSettings);
  }

  function switchTo($event, page, force) {
    $event.preventDefault();

    if (!page.selected || force) {
      $state.go(page.sref, page.params);
    }

    if (page.selected && page.enableInnerSelection) {
      $state.go(page.sref);
    }
  }

  function setPages(page) {
    // Show tabs everywhere except in the welcome page
    if (page === 'app.welcome') {
      vm.pages = [];
    } else if (page !== 'app.default') {
      setPagesProperties().then((pages) => {
        vm.pages = pages;
        updateSelectedPage(page);
      });
    }

    if (page === 'firsttime.mobile') {
      vm.smallTitle = 'Welcome to BigPanda';
    }
  }

  function createBootstrapFakePages() {
    const pagesLength = 4;
    const pages = new Array(pagesLength);
    for (let i = 0; i < pagesLength; i++) {
      pages[i] = {
        id: i,
        fake: true,
      };
    }

    return pages;
  }

  function updateSelectedPage(page) {
    forEach(vm.pages, (currentPage) => {
      currentPage.selected = new RegExp(currentPage.pageName).test(page.toLowerCase());
      if (currentPage.selected) {
        vm.currentPage = currentPage;
        pubSubService.broadcast('Intercom.update', { page: currentPage.pageName });
      }
    });
  }

  function reloadSettings(event, toState) {
    toState && setPages(toState.name);
  }

  function setPagesProperties() {
    const pagesWhenIntegrated = [];
    let defaultEnvId = null;

    const defultEnvPromise = EnvironmentsService.get()
      .then(EnvironmentsUtils.getDefaultEnv)
      .then((defaultEnv) => {
        defaultEnvId = defaultEnv ? defaultEnv._id : RoutesConstants.NO_PERMISSIONS_ENV;
      });

    const defaultEnvFolder =
      defaultEnvId === RoutesConstants.NO_PERMISSIONS_ENV
        ? RoutesConstants.NO_PERMISSIONS_FOLDER
        : RoutesConstants.ACTIVE;

    return defultEnvPromise.then(() => {
      pagesWhenIntegrated.push({
        name: 'Incidents',
        icon: 'bp-icon-important',
        selected: false,
        sref: 'app.overview.incidents.list',
        params: {
          folder: defaultEnvFolder,
          environment: defaultEnvId,
        },
        pageName: 'overview',
        enableInnerSelection: false,
        sidenav: 'environments',
        url: `/#/app/overview/${defaultEnvId}/${defaultEnvFolder}/incidents`,
      });

      if (Permissions.isPermitted('search@read')) {
        const investigatorParams = {
          query: '',
          timeframe: '-7d',
          sort: 'last_change',
        };
        pagesWhenIntegrated.push({
          name: 'Search',
          icon: 'bp-icon-search-thick',
          selected: false,
          sref: 'app.investigator',
          params: investigatorParams,
          pageName: 'investigator',
          enableInnerSelection: false,
          sidenav: 'investigator',
          url: `/#/app/investigator?${querystring.stringify(investigatorParams)}`,
        });
      }

      if (Permissions.isPermitted('dashboards@read')) {
        pagesWhenIntegrated.push({
          name: 'Dashboards',
          icon: 'bp-icon-dashboard-icon',
          selected: false,
          sref: 'app.dashboards',
          pageName: 'dashboard',
          enableInnerSelection: false,
          url: '/#/app/dashboards',
        });
      }

      if (
        !UserFeatureTogglesService.getToggle('ignore_analytics') &&
        Permissions.isPermitted('analytics@read')
      ) {
        pagesWhenIntegrated.push({
          name: 'Analytics',
          icon: 'bp-icon-reports-icon',
          selected: false,
          sref: 'app.reports.detail.show-report',
          params: {
            reportId: '',
          },
          pageName: 'reports',
          enableInnerSelection: false,
          url: '/#/app/reports/detail/',
        });
      }

      if (
        UserFeatureTogglesService.getToggle('smart_analytics_enabled') &&
        Permissions.isPermitted('analytics@read')
      ) {
        pagesWhenIntegrated.push({
          name: 'Analytics',
          icon: 'bp-icon-reports-icon',
          selected: false,
          sref: 'app.unified-analytics',
          pageName: 'unified-analytics',
          enableInnerSelection: true,
          url: '/#/app/unified-analytics',
        });
      }

      if (
        !UserFeatureTogglesService.getToggle('frontier_integrations_lobby_react') &&
        Permissions.isPermitted('integrations@read')
      ) {
        pagesWhenIntegrated.push({
          name: 'Integrations',
          icon: 'bp-icon-integrations',
          selected: false,
          sref: 'app.integrations',
          pageName: 'integrations',
          enableInnerSelection: true,
          url: '/v2/integrations',
        });
      }

      return pagesWhenIntegrated;
    });
  }
}
