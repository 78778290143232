angular.module('bigpanda').service('DashboardsStore', DashboardsStore);

function DashboardsStore(DashboardsBackendService, SubscribersService, StreamConnector) {
  const subscribers = new SubscribersService();

  this.subscribe = subscribe;
  this.getDashboards = getDashboards;
  this.getDashboardById = getDashboardById;
  this.createDashboard = createDashboard;
  this.updateDashboard = updateDashboard;
  this.getWidgetsData = getWidgetsData;
  this.deleteDashboard = deleteDashboard;

  StreamConnector.on('dashboardChanged', dashboardChanged);

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function getDashboards() {
    return DashboardsBackendService.getAllDashboards();
  }

  function getDashboardById(dashboardId) {
    return DashboardsBackendService.getDashboardById(dashboardId);
  }

  function createDashboard(dashboardConfig) {
    return DashboardsBackendService.createDashboard(dashboardConfig);
  }

  function updateDashboard(dashboardId, dashboardConfig) {
    return DashboardsBackendService.updateDashboard(dashboardId, dashboardConfig);
  }

  function deleteDashboard(dashboardId) {
    return DashboardsBackendService.deleteDashboard(dashboardId);
  }

  function getWidgetsData(dashboardId, onlyLast) {
    return DashboardsBackendService.getWidgetsData(dashboardId, onlyLast);
  }

  function dashboardChanged(event) {
    subscribers.fireStoreUpdated();
  }

  return this;
}
