import { BamTile } from '@bp/bam';
import React from 'react';

import { AlertFilter } from '../../types/AlertFilter';
import { filterStatus } from '../../utils/filterStatus';
import { FilterActivationToggle } from './FilterActivationToggle';

interface IProps {
  filter: AlertFilter;
}

export function FilterInfoHeader({ filter }: IProps): JSX.Element {
  return (
    <BamTile
      bamStatus={filterStatus(filter).color}
      headerRow={{
        title: filter.name,
        toggle: <FilterActivationToggle filter={filter} />,
      }}
    />
  );
}

export default FilterInfoHeader;
