import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { BamBpqlInput } from '@bp/bam';
import { AllSizes } from '@bp/kung-fu';
import './bpqlWrapper.scss';
import QueryHelper from '../../../../../workspaces/apps/environment/src/components/QueryHelper';

const validateBPQL = (val) => {
  const unsupportedTags = ['description'];

  const isContainedUnsupportedTag = unsupportedTags.find((tag) => {
    if (val.includes(tag)) {
      return tag;
    }
    return false;
  });
  if (isContainedUnsupportedTag) return `Tag "${isContainedUnsupportedTag}" is not supported`;

  return BamBpqlInput.helpers.BpqlInputValidator(val);
};

const validateDisplayQuery = (val) => (val ? validateBPQL(val) : undefined);

class BpqlInputWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      value: props.value,
      validationErr: null,
      triggerComponentProps: {
        title: 'Condition',
        size: AllSizes.MEDIUM,
        value: '',
      },
    };
  }

  componentDidMount() {
    this.setAdvancedConditionValue();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setNewValue();
    }
  }

  setAdvancedConditionValue = () => {
    this.setState({
      triggerComponentProps: {
        ...this.state.triggerComponentProps,
        value: this.props.value,
      },
    });
  };

  setNewValue = () => {
    const { value } = this.props;
    const validationErr = validateDisplayQuery(value);

    this.setState({
      value,
      validationErr,
    });
  };

  setActive = (active) => {
    this.setState({
      active,
    });
  };

  handleChange = (value) => {
    let validationErr = null;
    if (value) validationErr = validateDisplayQuery(value);

    this.setState({ validationErr, value });
    this.props.onBpqlChange(value, validationErr);
  };

  render() {
    const { tags, maxRows, tagsDefinitions } = this.props;
    const { active, value, validationErr } = this.state;

    return this.props.enableQueryHelper ? (
      <QueryHelper
        triggerComponentProps={this.state.triggerComponentProps}
        alertTags={tags}
        tagsDefinitions={tagsDefinitions}
        placeholder="e.g. host=*.com"
        onChange={this.handleChange}
        showIncidents
        shouldUseNamespace
        {...this.props}
      />
    ) : (
      <div className={validationErr ? 'bpql-input-container border-red' : 'bpql-input-container'}>
        <BamBpqlInput
          value={value}
          tags={tags}
          title="Condition"
          aria-label="Please enter the condition e.g. host=*.com"
          placeholder="e.g. host=*.com"
          meta={{ active }}
          validate={validateDisplayQuery}
          validateOnBlur
          fixedFont
          interactiveTooltip
          onChange={this.handleChange}
          onFocus={() => this.setActive(true)}
          onBlur={() => this.setActive(false)}
          maxRows={maxRows}
        />
        {validationErr && <div className="error-validation">{validationErr}</div>}
      </div>
    );
  }
}

BpqlInputWrapper.propTypes = {
  onBpqlChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.node),
  maxRows: PropTypes.number,
  enableQueryHelper: PropTypes.bool,
  tagsDefinitions: PropTypes.arrayOf(PropTypes.object),
};

BpqlInputWrapper.defaultProps = {
  value: undefined,
  tags: [],
  maxRows: 5,
  tagsDefinitions: [],
};

export default hot(module)(BpqlInputWrapper);
