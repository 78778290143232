import actionTypes from './actionTypes';

export const initialState = {
  loading: 0,
  changes: [],
  totalRelatedCount: 0,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.LOAD_RELATED_CHANGES: {
      const nextState = state.incidentId === payload.incidentId ? state : initialState;
      return {
        ...nextState,
        ...payload,
        loading: nextState.loading + 1,
      };
    }
    case actionTypes.LOAD_RELATED_CHANGES_SUCCESSFULLY: {
      if (state.incidentId !== payload.incidentId) {
        return state;
      }
      return {
        ...state,
        ...payload,
        changes: payload.changes || [],
        loading: state.loading - 1,
      };
    }
    default:
      return state;
  }
}
