import DisplayCommon from '@bp/display-common';
import {
  ACTIVE_INTEGRATION_STATE,
  INTEGRATION_ALERT_TYPE,
  PENDING_INTEGRATION_STATE,
  TARGET_INTEGRATION_TYPE,
} from './constants';
const { getIntegrationsForDisplayFactory } = require('common/utils/integrations');

const getIntegrationsForDisplay = getIntegrationsForDisplayFactory(
  DisplayCommon.getIntegrationMetadataBySourceSystem,
  DisplayCommon.findSystemName
);

export const extendDisplayedIntegrations = (integrations) =>
  integrations
    .filter((integration) => !integration.ignore)
    .map((integration) => ({
      ...DisplayCommon.getTargetSystemTemplateById(integration.parent_system_id),
      ...integration,
    }))
    .map((displayedIntegration) => {
      const {
        target_system_id,
        parent_system_id,
        source_system,
        type,
        last_event,
      } = displayedIntegration;
      const overrideValues = {};
      if (target_system_id) {
        overrideValues.type = TARGET_INTEGRATION_TYPE;
        overrideValues.system_id = target_system_id;
        overrideValues.parent_source_system = parent_system_id;
      } else {
        overrideValues.system_id = source_system;
      }
      overrideValues.state =
        type === TARGET_INTEGRATION_TYPE || last_event
          ? ACTIVE_INTEGRATION_STATE
          : PENDING_INTEGRATION_STATE;
      return {
        ...displayedIntegration,
        ...overrideValues,
      };
    });

export const getIntegrationOptions = (integrations) => {
  const alertIntegrations = integrations.filter(
    (integration) => integration.type === INTEGRATION_ALERT_TYPE
  );
  return getIntegrationsForDisplay(alertIntegrations);
};

export const getAllIntegrationOptions = (integrations) => {
  const alertIntegrations = integrations.filter(
    (integration) => integration.type === INTEGRATION_ALERT_TYPE
  );
  return getIntegrationsForDisplayFactory(
    DisplayCommon.getIntegrationMetadataBySourceSystem,
    DisplayCommon.findSystemName,
    false
  )(alertIntegrations);
};
