import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Badge, AllSizes } from '@bp/kung-fu';
import { BamTile } from '@bp/bam';
import { connect } from 'react-redux';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import BpTimeSince from 'common/utils/BpTimeSince';
import CorrelationPatternsActions from 'react/modules/settings/correlation_patterns/actions/CorrelationPatternsActions';
import {
  CREATED_SORT_MODE,
  SortShape,
} from 'react/modules/settings/correlation_patterns/correlation_patterns_list/constants';
import ConfigurationLabelsRow from 'react/common/components/ConfigurationLabels/ConfigurationLabelsRow/ConfigurationLabelsRow';
import variants from 'react/common/components/ConfigurationLabels/ConfigurationLabelsRow/constants';

const generateFirstRow = (correlationPattern, sortMode) => ({
  customParts: correlationPattern.metadata.suggested
    ? [<Badge text="Suggested" color={(p) => p.theme.bp_purple} size={AllSizes.SMALL} />]
    : null,
  textParts: correlationPattern.tagNames.map((name) => ({ value: name, big: true })),
  time: {
    title: sortMode === CREATED_SORT_MODE ? 'Created' : 'Updated',
    timestamp: BpTimeSince(
      sortMode === CREATED_SORT_MODE
        ? correlationPattern.metadata.created_time
        : correlationPattern.metadata.updated_time || correlationPattern.metadata.created_time
    ),
  },
});

const generateSecondRow = (correlationPattern, enableConfigurationLabels) => {
  const textParts = [
    { value: `${correlationPattern.window / 60} min` },
    {
      value: correlationPattern.sourceSystems
        .map((sourceSystem) => sourceSystem.display)
        .join(', '),
    },
  ];
  if (correlationPattern.displayQuery) {
    textParts.push({ value: correlationPattern.displayQuery, fixedFont: true });
  }

  if (enableConfigurationLabels && !isEmpty(correlationPattern.labels)) {
    const labelsRow = (
      <ConfigurationLabelsRow labels={correlationPattern.labels} variant={variants.light} />
    );
    textParts.push({ value: labelsRow });
  }

  return {
    textParts: textParts,
    actionsBundle: <CorrelationPatternsActions correlationPattern={correlationPattern} />,
  };
};

const ListItem = ({ correlationPattern, sortMode, enableConfigurationLabels, ...props }) => {
  const handleClick = () => {
    window.location.href = `/#/app/settings/alert-correlation/${correlationPattern.id}`;
  };

  return (
    <BamTile
      bamStatus={correlationPattern.active ? 'ok' : 'off'}
      firstRow={generateFirstRow(correlationPattern, sortMode)}
      secondRow={generateSecondRow(correlationPattern, enableConfigurationLabels)}
      onClick={handleClick}
      inactive={!correlationPattern.active}
      {...props}
    />
  );
};

ListItem.propTypes = {
  correlationPattern: PropTypes.shape({ id: PropTypes.string, active: PropTypes.bool }).isRequired,
  enableConfigurationLabels: PropTypes.bool.isRequired,
  sortMode: SortShape.isRequired,
  featureToggles: PropTypes.shape({
    react_grouped_settings_menu: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  featureToggles: featureTogglesSelectors.getFeatureToggles(state),
});

export default connect(mapStateToProps, null)(hot(module)(ListItem));
