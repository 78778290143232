import PropTypes from 'prop-types';

const environmentPropType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string,
  old_id: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export default environmentPropType;
