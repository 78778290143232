import get from 'lodash/fp/get';
import { path } from './constants';

const selectors = {
  isSaving: get(`${path}.isSaving`),
  configurationSent: get(`${path}.configurationSent`),
  userOrganizationName: get(`${path}.userOrganizationName`),
  userEmail: get(`${path}.userEmail`),
  userName: get(`${path}.userName`),
};
export default selectors;
