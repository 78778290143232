import {
  ArrowforwardIcon, ArrowupIcon, Button, ButtonVariants, CodeSizes,
  Collapsable, darkTheme, FullUrlHighlight, VBox,
} from '@bp/kung-fu';
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';

import text from '../../dictionary';
import {
  ButtonWrapper, StyledHBox,
  TagType, TagValue,
} from './CollapsedTags.style';
import { Tag } from './types';

export interface ICollapsedTags {
  tags: Tag[],
}

function CollapsedTags(props: ICollapsedTags) {
  const { tags } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ThemeProvider theme={darkTheme}>
      <VBox>
        <Collapsable isOpen={isOpen}>
          {tags.map((alert, index) => (
            <StyledHBox index={index} key={alert.type}>
              <TagType
                index={index}
                color={(p) => p.theme.bp_gray_07}
                size={CodeSizes.MEDIUM_CODE}
                ellipsis
              >
                {alert.type}
                :
              </TagType>
              <TagValue>
                <FullUrlHighlight value={alert.value} color={(p) => p.theme.bp_gray_07} size={CodeSizes.MEDIUM_CODE} />
              </TagValue>
            </StyledHBox>

          ))}
        </Collapsable>
        <ButtonWrapper>
          <Button
            aria-expanded={isOpen}
            icon={isOpen ? ArrowupIcon : ArrowforwardIcon}
            variant={ButtonVariants.COLLAPSE}
            onClick={() => setIsOpen(!isOpen)}
            expand
          >
            {isOpen ? text.collapse_button_open_text : text.collapse_button_close_text}
          </Button>
        </ButtonWrapper>
      </VBox>
    </ThemeProvider>
  );
}

export default CollapsedTags;
