import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BamInput } from '@bp/bam';
import ValidationError from '../ValidationError';
import './password_field.scss';

const PasswordField = ({ input, meta, placeholder, className, title, 'aria-label': ariaLabel }) => {
  const inputClassName = classnames(className, {
    'PasswordField__input--error': !!meta.submitError,
  });
  const errorMessageLines = [].concat(meta.submitError);

  return (
    <div className="PasswordField bam">
      <BamInput
        baseProps={{ type: 'password', placeholder, className: inputClassName }}
        input={input}
        title={title}
        aria-label={ariaLabel}
      />
      {!!meta.submitError && (
        <ValidationError active={!!meta.submitError} aria-label={errorMessageLines.join('\n')}>
          {errorMessageLines.map((line) => (
            <div key={line}>{line}</div>
          ))}
        </ValidationError>
      )}
    </div>
  );
};

PasswordField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  'aria-label': PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
};

PasswordField.defaultProps = {
  'aria-label': undefined,
  className: undefined,
  title: undefined,
};

export default PasswordField;
