import { SortMode } from './SortMode';

export const UPDATED_SORT_MODE: SortMode = {
  text: 'Updated',
  field: 'updated_on',
  ascending: false,
  isDefault: true,
};

export const CREATED_SORT_MODE: SortMode = {
  text: 'Created',
  field: 'created_on',
  ascending: false,
};

export const SortModes: SortMode[] = [
  UPDATED_SORT_MODE,
  CREATED_SORT_MODE,
];

export const DEFAULT_SORT_MODE: SortMode = [
  UPDATED_SORT_MODE,
  CREATED_SORT_MODE,
].find((sortMode: SortMode) => sortMode.isDefault);
