import { path } from './constants';

const actionTypes = {};

actionTypes.LOAD_USER = `${path}.LOAD_USER`;
actionTypes.LOAD_USER_SUCCESS = `${path}.LOAD_USER_SUCCESS`;
actionTypes.LOAD_USER_FAILURE = `${path}.LOAD_USER_FAILURE`;
actionTypes.UPDATE_USER = `${path}.UPDATE_USER`;
actionTypes.UPDATE_USER_SUCCESS = `${path}.UPDATE_USER_SUCCESS`;
actionTypes.UPDATE_USER_FAILURE = `${path}.UPDATE_USER_FAILURE`;

export default actionTypes;
