import actionTypes from './actionTypes';
import { INITIAL_TOGGLE_VALUE } from './Consts';

export const initialState = {
  loading: 0,
  changes: [],
  dateRange: undefined,
  search: '',
  incidentId: undefined,
  checked: INITIAL_TOGGLE_VALUE,
  rccOnly: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.INIT_CHANGES:
      return {
        ...state,
        changes: [],
        totalRelatedCount: 0,
      };
    case actionTypes.LOAD_CHANGES:
    case actionTypes.LOAD_SEARCH_CHANGES:
      return {
        ...state,
        rccOnly: false,
        loading: state.loading + 1,
      };
    case actionTypes.LOAD_CHANGES_SUCCESSFULLY:
    case actionTypes.LOAD_SEARCH_CHANGES_SUCCESSFULLY:
      return {
        ...state,
        ...payload,
        ...(state.incidentId === payload.incidentId ? {} : { search: '', dateRange: undefined }),
        loading: state.loading - 1,
      };
    case actionTypes.LOAD_RELATED_CHANGES:
      return {
        ...state,
        checked: true,
        rccOnly: true,
        loading: state.loading + 1,
      };
    case actionTypes.LOAD_RELATED_CHANGES_SUCCESSFULLY:
      return {
        ...state,
        ...payload,
        changes: payload.changes || [],
        ...(state.incidentId === payload.incidentId ? {} : { search: '', dateRange: undefined }),
        checked: true,
        loading: state.loading - 1,
      };
    case actionTypes.LOAD_CHANGES_FAILED:
      return {
        ...state,
        ...payload,
        loading: state.loading - 1,
        totalRelatedCount: 0,
      };
    case actionTypes.UPSERT_CHANGES_METADATA_SUCCESSFULLY:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.UPSERT_CHANGES_METADATA_FAILED:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.CHANGE_DATE_RANGE:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.CHANGE_SEARCH:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.CHANGE_TOGGLE_VALUE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
