angular.module('bigpanda').directive('multipleShareButton', multipleShareButton);

function multipleShareButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '^^checkedIncidentsState',
    },
    scope: {},
    controllerAs: 'vm',
    bindToController: true,
    replace: true,
    templateUrl: 'overview/incidents/list/widgets/share_button/multiple_share_button',
    controller: controller,
  };

  function controller($state, $stateParams, $q, ModalService) {
    const vm = this;

    vm.shareProps = { shared: false, 'bp-disabled': false };
    vm.shareTooltip = 'Share';

    vm.share = share;

    // Multiple share won't be shown if user can't do a share
    vm.isShareRelevant = true;

    function share() {
      ModalService.showModal({
        templateUrl: 'overview/incidents/actions/share/share_popup',
        controller: 'SharePopupController',
        controllerAs: 'vm',
        bindToController: true,
        locals: { context: 'multiple-share-button' },
        resolve: {
          resolvedIncidentIds: () => vm.checkedIncidentsState.getCheckedIncidentIds(),
        },
      }).then(() => {
        vm.checkedIncidentsState.clear();
      });
    }
  }
}
