angular.module('bigpanda').controller('AppContainerController', AppContainerController);

function AppContainerController($scope, pubSubService, VersionStore) {
  pubSubService.broadcast('AppContainerController.loaded');

  // TODO: move this logic to a service, should not be in global scope
  $scope.updatePage = function (page) {
    let firstTime = false;
    if (!$scope.page) {
      firstTime = true;
    }

    $scope.page = page;
    $scope.pageClass = {};
    $scope.pageClass[`${$scope.page}-page`] = true;

    if (firstTime) {
      $scope.pageClass['first-time'] = true;
    }
    VersionStore.updateVersion();

    if (['welcome', 'topology'].includes(page)) {
      $scope.topbarVm.showTopbar = false;
    }
  };
}
