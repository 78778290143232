import get from 'lodash/get';
import moment from 'moment';

angular
  .module('bigpanda.backend.services')
  .service('UserIntegrationsBackendService', UserIntegrationsBackendService);

function UserIntegrationsBackendService($http) {
  this.getIntegrations = getIntegrations;
  this.getIntegration = getIntegration;
  this.suggestNewIntegration = suggestNewIntegration;
  this.getLastWeekErrors = getLastWeekErrors;

  function getIntegrations() {
    return $http.get('/web/integrations').then((res) => res.data.item);
  }

  function getIntegration(systemId) {
    return $http.get(`/web/integrations/${systemId}`).then((res) => res.data.item);
  }

  function getLastWeekErrors(systemId) {
    return getIntegration(systemId).then((res) => get(res, 'last_week_error_count', 0));
  }

  function suggestNewIntegration(integrationName) {
    return $http
      .post('/web/backend/notify-missing-integration', { integration: integrationName })
      .then((res) => res.data);
  }
}
