import { path as prevPath } from '../constants';

export const name = 'alertEnrichmentList';
export const path = `${prevPath}.${name}`;

export const CREATED_SORT_MODE = {
  text: 'Created',
  field: 'metadata.created_time',
  ascending: false,
};
export const EXEC_ORDER_ASC_SORT_MODE = {
  text: 'Exec. Order',
  field: 'order',
  ascending: true,
  isDefault: true,
};
export const EXEC_ORDER_DESC_SORT_MODE = {
  text: 'Exec. Order desc.',
  field: 'order',
  ascending: false,
};
export const UPDATED_SORT_MODE = {
  text: 'Updated',
  field: 'updated_at',
  ascending: false,
  isDefault: false,
};
export const ITEMS_COUNT_SORT_MODE = {
  text: 'Number of Items',
  field: ({ enrichments }) => enrichments.length,
  ascending: false,
};
export const TAG_NAME_SORT_MODE = { text: 'Tag Name', field: 'name', ascending: true };
export const SORT_MODES = [
  EXEC_ORDER_ASC_SORT_MODE,
  EXEC_ORDER_DESC_SORT_MODE,
  UPDATED_SORT_MODE,
  CREATED_SORT_MODE,
  ITEMS_COUNT_SORT_MODE,
  TAG_NAME_SORT_MODE,
];

export const DEFAULT_SORT_MODE = SORT_MODES.find(({ isDefault }) => isDefault);
