const isEnterEvent = (event) =>
  event.code.toLowerCase() === 'enter' || event.code.toLowerCase() === 'numpadenter';
const isSpaceEvent = (event) => event.code.toLowerCase() === 'space';

const handleEvent = (handler, predicate) => (event, ...args) => {
  if (predicate(event)) {
    return handler(event, ...args);
  }
};

export const onKeyboardSpaceEvent = (handler) =>
  handleEvent(handler, (event) => isSpaceEvent(event));

export const onKeyboardEnterEvent = (handler) =>
  handleEvent(handler, (event) => isEnterEvent(event));

export const onKeyboardInteractionEvent = (handler) =>
  handleEvent(handler, (event) => isEnterEvent(event) || isSpaceEvent(event));
