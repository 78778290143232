import toLower from 'lodash/toLower';

angular.module('bigpanda.backend.services').service('LabelsBackendService', LabelsBackendService);

const baseURL = '/resources/v2.0';
const environments = 'environments';
const incidentTags = 'incidents/tags';

function LabelsBackendService($http, stateService, EnvironmentsService) {
  this.getBatchIncidentTags = getBatchIncidentTags;
  this.setBatchIncidentTags = setBatchIncidentTags;
  this.setIncidentTag = setIncidentTag;
  this.getMultipleIncidentTagDefinitions = getMultipleIncidentTagDefinitions;
  this.deleteIncidentTag = deleteIncidentTag;
  this.deleteMultipleIncidentsTag = deleteMultipleIncidentsTag;

  function getBatchIncidentTags(incidentIds = []) {
    const envId = stateService.getSelectedEnvironmentId();
    const payload = { incident_ids: incidentIds };
    return $http
      .post(`${baseURL}/${environments}/${envId}/${incidentTags}/search`, payload)
      .then((res) =>
        Object.keys(res.data).map((key) => ({
          labels: res.data[key].map((tag) => ({ id: tag.tag_id, value: tag.tag_value })),
          incident_id: key,
        }))
      );
  }

  function setBatchIncidentTags(params) {
    const envId = stateService.getSelectedEnvironmentId();
    return $http.post(`${baseURL}/${environments}/${envId}/${incidentTags}`, params);
  }

  function setIncidentTag(incidentId, payload) {
    const { tag_id } = payload;
    const envId = stateService.getSelectedEnvironmentId();

    return $http.post(
      `${baseURL}/${environments}/${envId}/incidents/${incidentId}/tags/${tag_id}`,
      payload
    );
  }

  async function getMultipleIncidentTagDefinitions(envId) {
    const environmentId =
      toLower(envId) === 'all' ? await EnvironmentsService.getAllEnvId() : envId;

    // Sometimes envId is an old_id instead of the id, we replace it with the id
    const envIdForRequest = await EnvironmentsService.getEnvId(environmentId);

    return $http
      .get(`${baseURL}/${environments}/${envIdForRequest}/incidents/tags/feed/definitions`)
      .then((res) => res.data);
  }

  function deleteIncidentTag(incidentId, tagId) {
    const envId = stateService.getSelectedEnvironmentId();
    return $http.delete(`${baseURL}/environments/${envId}/incidents/${incidentId}/tags/${tagId}`);
  }

  function deleteMultipleIncidentsTag(tagId, payload) {
    const envId = stateService.getSelectedEnvironmentId();
    const headers = { 'Content-Type': 'application/json' };
    return $http.delete(`${baseURL}/environments/${envId}/incidents/tags/${tagId}`, {
      data: payload,
      headers,
    });
  }
}
