import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import flatten from 'lodash/flatten';
import { useSelector } from 'react-redux';

import { QueryKeys } from '../../constants';
import selectors from '../../store/selectors';
import { AuditLogResponse, AuditRow, Environment } from '../useAuditTable/types';
import { auditResponseMapper } from '../useAuditTable/utils';

const useAuditData = (auditId?: string): AuditRow[] | AuditRow => {
  const queryClient = useQueryClient();
  const envs: Environment[] = useSelector(selectors.environments);
  const { pages }: InfiniteData<AuditLogResponse> = queryClient.getQueryData([QueryKeys.AuditLogs]);
  if (Array.isArray(pages)) {
    const auditLogs = flatten(pages.map((page) => auditResponseMapper(page.items, envs)));
    if (auditId) {
      return auditLogs.find((log) => log.id === auditId);
    }
    return auditLogs;
  }
  return pages;
};

export default useAuditData;
