import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonVariants,
  HBox,
  Button,
  CopyIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  AllSizes,
} from '@bp/kung-fu';
import dictionary from '../../dictionary';

const AiActions = ({
  onCopy,
  onThumbsDown,
  onThumbsUp,
  isThumbsUpPressed = false,
  isThumbsDownPressed = false,
}) => {
  const [isTooltipActive, setTooltipActive] = useState(false);

  return (
    <HBox gap="2px">
      <Button
        variant={ButtonVariants.ACTION_DEFAULT_V2}
        icon={
          <HBox paddingTop="0.25rem" paddingEnd="0.2rem">
            <CopyIcon color={(p) => p.theme.bp_gray_04} fontSize="18px" />
          </HBox>
        }
        tooltipProps={{
          isActive: isTooltipActive,
          placement: 'bottom',
          text: 'Copied to clipboard',
        }}
        onClick={() => {
          onCopy();
          setTooltipActive(true);
          setTimeout(() => {
            setTooltipActive(false);
          }, 1500);
        }}
        size={AllSizes.XXSMALL}
        ariaLabel={dictionary.ai_copy_button_label}
      />
      <Button
        variant={ButtonVariants.ACTION_DEFAULT_V2}
        icon={
          <ThumbsUpIcon
            fontSize="20px"
            color={(p) => (isThumbsUpPressed ? p.theme.bp_green : p.theme.bp_gray_04)}
          />
        }
        onClick={onThumbsUp}
        size={AllSizes.XXSMALL}
        ariaLabel={dictionary.ai_thumbs_up_title}
      />
      <Button
        variant={ButtonVariants.ACTION_DEFAULT_V2}
        icon={
          <ThumbsDownIcon
            fontSize="20px"
            color={(p) => (isThumbsDownPressed ? p.theme.bp_red : p.theme.bp_gray_04)}
          />
        }
        onClick={onThumbsDown}
        size={AllSizes.XXSMALL}
        ariaLabel={dictionary.ai_thumbs_down_title}
      />
    </HBox>
  );
};

AiActions.propTypes = {
  onCopy: PropTypes.func,
  onThumbsDown: PropTypes.func,
  onThumbsUp: PropTypes.func,
  isThumbsUpPressed: PropTypes.bool,
  isThumbsDownPressed: PropTypes.bool,
};

export default AiActions;
