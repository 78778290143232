angular
  .module('bigpanda')
  .controller('SettingsCtrl', function ($scope, pubSubService, $state, SettingsService) {
    const vm = this;

    pubSubService.broadcast('currentTitleChanged', 'Settings');
    pubSubService.broadcast('Settings.VisitPage');
    $scope.updatePage('settings');
    vm.selectedSettingsPage = null;
    vm.selectedSettingsPageId = null;

    pubSubService.on('settingsPageChanged', (c, id) => {
      vm.selectedSettingsPageId = id;
      vm.isPersonalSettings = id === 'personal';
    });

    vm.settingsPages = SettingsService.settingsPages(vm.isPersonalSettings);

    vm.settingsPageClick = (settingsPage) => {
      if (settingsPage.isFrontierV2) {
        window.location.href = settingsPage.navigateTo;
      }
      $state.go(settingsPage.navigateTo, settingsPage.params);
    };
  });
