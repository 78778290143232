import { darkTheme } from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { StyledButton } from './ResolveButton.style';

interface IResolveButton {
  disabled?: boolean,
  tooltipText?: string,
  onClick: () => void
}

const ResolveButton = ({ disabled, tooltipText, onClick }: IResolveButton) => (
  <ThemeProvider theme={darkTheme}>
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      ariaLabel="Resolve incident"
      tooltipProps={{
        isActive: true,
        text: tooltipText,
        placement: 'bottom',
      }}
    />
  </ThemeProvider>
);

export default ResolveButton;
