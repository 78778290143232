angular
  .module('bigpanda.backend.services')
  .factory('ReportDataBackendService', ($q, $filter, $http) => {
    return {
      getReportData: getReportData,
    };

    function getReportData(name, options) {
      return $http.post(`/web/reports-data/${name}`, options).then((res) => {
        let reportData = res.data.item;
        $filter('ReportDataNormalizer')(reportData);
        return $q.when(reportData);
      });
    }
  });
