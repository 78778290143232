import moment from 'moment';

import { LAST_24_HOURS, LAST_7_DAYS } from './constants';

export const getStatusColor = (level, styles) => {
  switch (level) {
    case 'error':
      return styles.bp_red;
    default:
      return styles.bp_gray_05;
  }
};

export const normalizeLog = (log) => {
  let payload;
  try {
    payload = JSON.stringify(log.payload, undefined, 2);
  } catch (error) {
    payload = log.payload;
  }
  return {
    ...log,
    timestamp: log.timestamp / 1000,
    payload: payload,
  };
};

export const getDateRange = (value) => {
  const now = Number(moment().format('x'));
  return {
    from: Number(
      moment()
        .subtract(
          value === LAST_7_DAYS.value ? 7 : value === LAST_24_HOURS.value ? 1 : 0, // eslint-disable-line no-nested-ternary
          'days'
        )
        .format('x')
    ),
    to: now,
  };
};
