import actionTypes from './actionTypes';

export const initialState = {
  user: undefined,
  isLoading: false,
  error: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_USER:
      return {
        ...initialState,
        isLoading: true,
      };

    case actionTypes.LOAD_USER_SUCCESS:
      return {
        user: action.payload,
        isLoading: false,
      };

    case actionTypes.UPDATE_USER:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        user: action.payload,
        isLoading: false,
      };

    case actionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
