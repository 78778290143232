import React from 'react';
import { BamEmptyMessage, BamTile } from '@bp/bam';
import { ThemeProvider } from 'styled-components';
import { Avatar, darkTheme, Badge, EditIcon } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors as commonSelectors } from 'react/common';
import provideStore from 'react/utils/provideStore';
import BpTimeSince from 'common/utils/BpTimeSince';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import UserManagementActions from '../../actions/UserManagementActions';
import selectors from '../../selectors';
import UserModal from '../../modals/UserModal';
import { Container, PendingMsg } from './UserInfo.css';
import { isUserPending } from '../../constants';
import UserManagmentResendInvitation from '../../actions/UserManagmentResendInvitation';

const buttonType = 'cta-secondary';

const UserInfo = ({ user, actionPermissions }) => {
  if (!user) {
    return (
      <BamEmptyMessage header="NO USER SELECTED">Select a user to see details.</BamEmptyMessage>
    );
  }

  const isPending = isUserPending(user);

  const content = (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.SectionRow title="Status:">
        <Container>
          <Badge text={user.stateText} status={user.cls} isCapitalized size="large" />
          {isPending && <PendingMsg>Account activation email sent</PendingMsg>}
        </Container>
      </SettingsInfoScreen.SectionRow>

      <SettingsInfoScreen.SectionRow title="Email:">{user.email}</SettingsInfoScreen.SectionRow>

      {user.phone_number && (
        <SettingsInfoScreen.SectionRow title="Phone:">
          {user.phone_number}
        </SettingsInfoScreen.SectionRow>
      )}

      <SettingsInfoScreen.SectionRow title="Roles:">
        {user.roles.map(({ name }) => name).join(', ')}
      </SettingsInfoScreen.SectionRow>
    </SettingsInfoScreen.Section>
  );

  const actions = (
    <SettingsInfoScreen.Section>
      {actionPermissions.hasEditPermission && (
        <UserModal
          currentRoleId={user.id}
          triggerProps={{
            variant: 'action-color',
            size: 'large',
            text: 'Edit user details',
            icon: <EditIcon />,
          }}
          title="Edit User Details"
          titleIcon="bp-icon-edit"
          currentUserId={user.id}
        />
      )}

      {actionPermissions.canInvite && (
        <UserManagmentResendInvitation
          user={user}
          text="Resend invitation"
          buttonType={buttonType}
        />
      )}
    </SettingsInfoScreen.Section>
  );

  const activity = (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.ActivityRow title="Added:" value={BpTimeSince(user.createdTimestamp)} />

      {!isPending && (
        <SettingsInfoScreen.ActivityRow
          title="Last seen:"
          value={BpTimeSince(user.lastLoginAtTimeStamp)}
        />
      )}
    </SettingsInfoScreen.Section>
  );

  const userIcon = <Avatar size="x-large" name={user.name} email={user.email} />;

  return (
    <ThemeProvider theme={darkTheme}>
      <SettingsInfoScreen
        topActions={<UserManagementActions user={user} />}
        header={
          <BamTile bamStatus={user.cls} headerRow={{ title: user.name }} coverImage={userIcon} />
        }
        content={content}
        actions={actions}
        activity={activity}
      />
    </ThemeProvider>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object /* eslint-disable-line */,
  actionPermissions: PropTypes.shape({
    hasEditPermission: PropTypes.bool,
    canInvite: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  const user = commonSelectors.getEnrichedUserById(state, ownProps.userId);
  const actionPermissions = selectors.getActionsPermissions(state, user);
  return { user, actionPermissions };
}

export default provideStore(connect(mapStateToProps, null)(UserInfo));
