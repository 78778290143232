import bpFetch from 'common/utils/http/fetcher';

angular
  .module('bigpanda.backend.services')
  .factory('MaintenancePlansBackendService', MaintenancePlansBackendService);

function MaintenancePlansBackendService() {
  return {
    getPlansByIds,
  };

  function getPlansByIds(planIds) {
    const promises = planIds.reduce((result, current) => {
      result.push(
        bpFetch({ url: `/resources/v2.0/maintenance-plans/${current}` }).then((res) => res.data)
      );
      return result;
    }, []);
    return Promise.all(promises);
  }
}
