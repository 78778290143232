import _cloneDeep from 'lodash/cloneDeep';

angular.module('bigpanda.utils').service('deepCloneObject', deepCloneObject);

function deepCloneObject(UserFeatureTogglesService) {
  this.cloneDeep = cloneDeep;

  function cloneDeep(object, options = {}) {
    if (UserFeatureTogglesService.getToggle('clone_with_json') && !options.lodash) {
      return object && JSON.parse(JSON.stringify(object));
    }
    return _cloneDeep(object);
  }
}
