import axios from 'axios';
import { webApiBaseUrl } from 'common/config';
import { datadogRum } from '@datadog/browser-rum';

export default function initInterceptors() {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      return {
        ...config,
        withCredentials: true,
        baseURL: webApiBaseUrl,
      };
    },
    function (error) {
      datadogRum.addError(error, {
        error,
        config: error.config,
      });
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      datadogRum.addError(error, {
        error,
        config: error.config,
      });
      return Promise.reject(error);
    }
  );
}
