import { react2angular } from 'react2angular';
import ShareButton from '../../../../../../../workspaces/apps/incident-feed/src/components/incident-view/actions/shareButton';

angular
  .module('bigpanda')
  .component(
    'reactShareButton',
    react2angular(ShareButton, [
      'onClick',
      'countOnClick',
      'disabled',
      'tooltipText',
      'countTooltipText',
      'shareCount',
    ])
  );
