angular.module('bigpanda.reports').directive('bpVisualizationWidgetContainer', ($compile, $q) => {
  function link($scope, element, attrs, containerController) {
    $q.when($scope.containerController.widgetDefinition).then((widget) => {
      // Retrieve the directive type
      var directiveType = widget.visualization_type;

      // Compile it
      var directive = `<${directiveType}>`;
      var compiledDirective = $compile(directive)($scope);

      // Add to .visualization-widget-placeholder
      angular
        .element(element[0].getElementsByClassName('visualization-widget-placeholder'))
        .append(compiledDirective);
    });
  }

  return {
    restrict: 'E',
    templateUrl: 'reports/visualization_widget/visualization_widget_container',
    scope: {
      editable: '=',
      forceLayout: '=',
      getData: '&widgetDataSource',
      widgetDefinition: '=widget',
    },
    controller: 'VisualizationWidgetContainerController',
    controllerAs: 'containerController',
    bindToController: true,
    link: link,
  };
});
