angular.module('bp.widgets').directive('bpCommentBox', bpCommentBox);

function bpCommentBox() {
  return {
    restrict: 'E',
    scope: {
      incidentIds: '<',
      isEditing: '=?',
      commentsSaver: '<?',
      alwaysEditing: '<?',
      autoFocus: '<?',
    },
    controller: controller,
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared_ui/bp_comment_box/bp_comment_box',
  };
  function controller($q, $element, $scope, CommentsStore) {
    const vm = this;

    vm.editComment = editComment;
    vm.exitComment = exitComment;
    vm.onKeyDown = onKeyDown;
    vm.submitComment = submitComment;
    vm.initComment = initComment;
    vm.canSubmitComment = canSubmitComment;
    vm.commentTestTextId = 'Comment_modal_text';
    vm.commentTestButtonId = 'Comment_modal_button';

    vm.$postLink = postLink;

    if (!vm.commentsSaver) {
      vm.commentsSaver = (saveCreator) => saveCreator();
    }

    function editComment() {
      vm.isEditing = true;
    }

    function exitComment() {
      if ((!vm.commentObject || !vm.commentObject.comment) && !vm.alwaysEditing) {
        vm.isEditing = false;
      }
    }

    function onKeyDown(e) {
      if (e.keyCode === 13 && e.metaKey) {
        vm.submitComment();
        e.currentTarget.blur();
      }
    }

    function submitComment() {
      vm.error = null;
      vm.commentInProgress = true;

      vm.commentsSaver(() => {
        let commentPromise;
        if (vm.incidentIds.length === 1) {
          commentPromise = CommentsStore.createComment(vm.incidentIds[0], vm.commentObject);
        } else {
          commentPromise = CommentsStore.createMultiComment(vm.incidentIds, vm.commentObject);
        }

        return commentPromise.then(initComment, (error) => {
          vm.error = 'Connection timed out, please try again in a few seconds';
          vm.commentInProgress = false;

          return $q.reject(error);
        });
      });
    }

    function canSubmitComment() {
      return (
        vm.commentObject &&
        vm.commentObject.comment &&
        vm.commentObject.comment.length &&
        !vm.commentInProgress
      );
    }

    function initComment() {
      vm.commentObject = {};
      vm.commentInProgress = false;

      if (!vm.alwaysEditing) {
        vm.isEditing = false;
      }
    }

    function postLink() {
      if (vm.autoFocus) {
        const $textarea = $element.find('textarea');
        waitUntilDoneAnimating($textarea, () => $textarea.focus());
      }
    }

    function waitUntilDoneAnimating(animatingElement, callback) {
      let currentRect;
      window.requestAnimationFrame(check);

      function check() {
        const newRect = animatingElement[0].getBoundingClientRect();
        if (angular.equals(newRect, currentRect)) {
          $scope.$apply(callback);
        } else {
          currentRect = newRect;
          window.requestAnimationFrame(check);
        }
      }
    }
  }
}
