import { hot } from 'react-hot-loader';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { selectors as configurationLabelsSelectors } from 'react/common/configuration_label_definitions';
import SettingsListScreen from 'react/layout/settings/SettingsListScreen';
import SettingsListEmptyState from 'react/layout/settings/components/SettingsListEmptyState';
import { SortShape } from 'react/layout/settings/components/SettingsList';
import { LabelPropType } from 'react/common/components/ConfigurationLabels/constants';
import { DropdownOptionType } from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { connect } from 'react-redux';
import provideStore from 'react/utils/provideStore';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import actions from '../../actions';
import selectors from '../../selectors';
import CorrelationPatternModal from '../../modals/CorrelationPatternModal';
import ListItem from './list/ListItem';
import { SORT_MODES } from '../constants';
import SortTooltip from './SortTooltip';
import FiltersComponent from './filters/FiltersComponents';
import { ANY_OPTION_VALUE } from '../../constants';
import completeOnboardingStep from '../../../../../../../app/common/endpoints/onboarding';
import dic from '../../../../../../../workspaces/apps/onboarding/src/dictionary';
import CorrelationPatternSuggestions from '../../../../../../../workspaces/apps/alert-correlation/src/components/CorrelationPatternSuggestions';

class CorrelationPatternsManagement extends React.Component {
  componentDidMount() {
    if (!this.props.correlationPatterns) {
      this.props.loadCorrelationPatterns();
    }
  }

  render() {
    const {
      correlationPatterns,
      enableConfigurationLabels,
      updateFiltersDropDowns,
      filtersDropDownState,
      filtersDropDownItems,
      filterText,
      labelsFilterItems,
      labelsFilterSelectedItem,
      updateFilterText,
      updateLabelsFilterSelectedItem,
      isLoading,
      updateSort,
      sortMode,
      featureToggles,
      addCorrelationPattern,
    } = this.props;

    const shouldRenderFiltersRow =
      filtersDropDownItems &&
      filtersDropDownState &&
      (!enableConfigurationLabels || labelsFilterItems);

    if (featureToggles && featureToggles.is_onboarding_enable) {
      const url = new URLSearchParams(window.location.hash.split('?')[1]);
      if (url.get('tour') === dic.maturity_levels.starter) {
        completeOnboardingStep(dic.onboardingSteps.correlation_patterns_basic);
      }
    }

    return (
      <ThemeProvider theme={darkTheme}>
        <React.Fragment>
          <SettingsListScreen
            enableConfigurationLabels={enableConfigurationLabels}
            loading={isLoading}
            items={correlationPatterns}
            noItemsTitle="No Correlation Pattern Added Yet"
            createItemProps={{
              modal: {
                title: 'Create a New Correlation Pattern',
                titleIcon: 'bp-icon-plus-icon',
                component: CorrelationPatternModal,
              },
              itemName: 'Correlation pattern',
              buttonText: 'New Pattern',
              tileText: 'Create a New Correlation pattern',
            }}
            listItem={ListItem}
            listItemProp="correlationPattern"
            permissionName="correlations"
            headerName="Alert Correlation"
            filterPlaceholderText="Find Correlation Patterns..."
            updateFilterText={updateFilterText}
            filterText={filterText}
            sortModes={SORT_MODES}
            sortMode={sortMode}
            sortTooltip={SortTooltip()}
            updateSort={updateSort}
            renderFilterRowElement={() =>
              shouldRenderFiltersRow ? (
                <FiltersComponent
                  enableConfigurationLabels={enableConfigurationLabels}
                  filtersDropDownState={filtersDropDownState}
                  onChange={updateFiltersDropDowns}
                  data={filtersDropDownItems}
                  labelsData={{
                    labelsFilterItems,
                    labelsFilterSelectedItem,
                    updateLabelsFilterSelectedItem,
                  }}
                />
              ) : null
            }
            renderSuggestionsRowElement={() =>
              featureToggles &&
              featureToggles.enable_correlation_pattern_suggestions &&
              correlationPatterns ? (
                <CorrelationPatternSuggestions
                  correlationPatterns={correlationPatterns}
                  addCorrelationPattern={addCorrelationPattern}
                />
              ) : null
            }
            renderNoResultsByFilters={() =>
              filterText ||
              Object.values(filtersDropDownState).some(
                (dropDownState) => dropDownState.value !== ANY_OPTION_VALUE
              ) ? (
                <SettingsListEmptyState
                  title="No Matches Found"
                  subtitle="Try refining your query and search fitlers"
                />
              ) : null
            }
          />
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

CorrelationPatternsManagement.propTypes = {
  correlationPatterns: PropTypes.arrayOf(PropTypes.object),
  enableConfigurationLabels: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadCorrelationPatterns: PropTypes.func.isRequired,
  updateFilterText: PropTypes.func.isRequired,
  updateFiltersDropDowns: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  updateSort: PropTypes.func.isRequired,
  sortMode: SortShape.isRequired,
  filtersDropDownItems: PropTypes.shape({
    source: PropTypes.arrayOf(DropdownOptionType),
    tag: PropTypes.arrayOf(DropdownOptionType),
    attribute: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }),
  filtersDropDownState: PropTypes.shape({
    source: DropdownOptionType,
    tag: DropdownOptionType,
    attribute: DropdownOptionType,
    status: DropdownOptionType,
  }),
  labelsFilterItems: PropTypes.arrayOf(LabelPropType),
  labelsFilterSelectedItem: PropTypes.string,
  updateLabelsFilterSelectedItem: PropTypes.func.isRequired,
  featureToggles: PropTypes.shape({
    react_grouped_settings_menu: PropTypes.bool,
    enable_correlation_pattern_suggestions: PropTypes.bool,
    is_onboarding_enable: PropTypes.bool,
  }).isRequired,
  addCorrelationPattern: PropTypes.func.isRequired,
};

CorrelationPatternsManagement.defaultProps = {
  filterText: '',
  filtersDropDownState: undefined,
  filtersDropDownItems: undefined,
  labelsFilterItems: undefined,
  labelsFilterSelectedItem: undefined,
};

function mapStateToProps(state) {
  const enableConfigurationLabels = selectors.areConfigurationLabelsEnabled(state);
  const labelsFilterProps = enableConfigurationLabels
    ? {
        labelsFilterItems: configurationLabelsSelectors.getLabelDefinitions(state),
        labelsFilterSelectedItem: selectors.getLabelsFilterSelectedItem(state),
      }
    : {};
  return {
    enableConfigurationLabels,
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
    isLoading: selectors.isLoading(state),
    correlationPatterns: selectors.getCorrelationPatterns(state, {
      shouldFilterByLabels: enableConfigurationLabels,
    }),
    filterText: selectors.getFilterText(state),
    sortMode: selectors.getSortMode(state),
    filtersDropDownState: selectors.getFiltersDropDownState(state),
    filtersDropDownItems: selectors.getFiltersDropDownItems(state),
    ...labelsFilterProps,
  };
}

const mapDispatchToProps = {
  loadCorrelationPatterns: actions.loadCorrelationPatterns,
  updateFilterText: actions.updateFilterText,
  updateSort: actions.updateSort,
  updateFiltersDropDowns: actions.updateFiltersDropDowns,
  updateLabelsFilterSelectedItem: actions.updateLabelsFilterSelectedItem,
  addCorrelationPattern: actions.addCorrelationPattern,
};

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(CorrelationPatternsManagement))
);
