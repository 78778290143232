import PropTypes from 'prop-types';

const contactPropType = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  bigpanda_user_id: PropTypes.string,
});

const systemPropType = PropTypes.shape({
  name: PropTypes.string,
  parent_system_id: PropTypes.string,
  system_id: PropTypes.string,
});

const environmentPropType = PropTypes.shape({
  name: PropTypes.string,
  old_id: PropTypes.string,
});

const paramsPropType = PropTypes.shape({
  contacts: PropTypes.arrayOf(contactPropType),
});

const dropdownOptionType = PropTypes.shape({
  value: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.shape({
    component: PropTypes.node.isRequired,
    position: PropTypes.string,
  }),
});

const rulePropType = PropTypes.shape({
  inactive: PropTypes.bool,
  deleted: PropTypes.bool,
  params: paramsPropType,
  id: PropTypes.string,
  envId: PropTypes.string,
  targetSystemId: PropTypes.string,
  system: systemPropType,
  environment: environmentPropType,
});

const autoShareRulesPropType = PropTypes.shape({
  updateFilterText: PropTypes.func.isRequired,
  loadAutoShareRules: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  rules: PropTypes.arrayOf(rulePropType),
  filterText: PropTypes.string,
  updateFiltersDropDowns: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    channel: dropdownOptionType,
    environment: dropdownOptionType,
    recipients: dropdownOptionType,
    status: dropdownOptionType,
  }),
  filtersDropDownItems: PropTypes.shape({
    channel: PropTypes.arrayOf(dropdownOptionType),
    environment: PropTypes.arrayOf(dropdownOptionType),
    recipients: PropTypes.arrayOf(dropdownOptionType),
    status: PropTypes.arrayOf(dropdownOptionType),
  }),
});

export default autoShareRulesPropType;
