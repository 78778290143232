import React from 'react';
import { hot } from 'react-hot-loader';
import { Field } from 'react-final-form';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { BamActionableTooltip, BamForm, BamInput, BamMultiselect } from '@bp/bam';
import { inviteUser as inviteUserRequest } from 'common/endpoints/users';
import styles from './user_form.scss';
import { ValidationError } from '../../../../common/components';

// eslint-disable-next-line max-len
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showReactivationTooltip: false,
      isInviting: false,
      isUserExist: false,
    };
  }

  handleSubmit = (values, form) => {
    const createOrUpdatePayload = this.buildCreateOrUpdateUserPayload(values, form);
    const { close } = this.props;
    const { isUserExist } = this.state;
    if (createOrUpdatePayload && !isUserExist) {
      const { currentUserId: id, updateUser } = this.props;
      if (id) {
        updateUser({ ...createOrUpdatePayload, id });
        close();
      } else {
        this.inviteUser(createOrUpdatePayload);
      }
    }
  };

  closeReactivationTooltip = () => {
    this.setState({ showReactivationTooltip: false });
  };

  reactivateUser = () => {
    const { reactivateUser, close, roles: roleOptions } = this.props;
    const { roles, name, email, phone_number } = this.formRef.getFormValues();
    const formatedRoles = roleOptions.filter((role) => roles.includes(role.key));
    reactivateUser({ email, name, formatedRoles, phone_number });
    close();
  };

  inviteUser = async (payload) => {
    const {
      updateOrInviteUserSuccess,
      updateOrInviteUserFailure,
      roles: roleOptions,
      inviteContext,
    } = this.props;
    const { email, name, phone_number, roles } = payload;
    const { close, users } = this.props;
    const foundMyUser = users.find((user) => user.email == email);
    try {
      this.setState({ isInviting: true });
      await inviteUserRequest(
        email,
        name,
        phone_number,
        roleOptions.filter(({ key }) => roles.includes(key)),
        inviteContext
      );
      updateOrInviteUserSuccess();
      close();
    } catch (e) {
      if (e.response && e.response.status === 409 && foundMyUser == undefined) {
        this.setState({ isInviting: false, showReactivationTooltip: true });
      }
      if (
        !this.state.isUserExist &&
        e.response.data.response.status == 'failed' &&
        e.response.data.response.errors.length == 1 &&
        e.response.data.response.errors[0].includes('already exists') &&
        foundMyUser.state != undefined
      ) {
        this.setState({ isUserExist: true, isInviting: false, showReactivationTooltip: false });
      } else {
        updateOrInviteUserFailure();
      }
    }
  };

  buildCreateOrUpdateUserPayload = (values, form) => {
    const state = form.getState();
    if (this.props.currentUserId && !state.dirty) {
      return null;
    }

    const createOrUpdatePayload = this.buildActualChangesPayload(
      values,
      Object.keys(state.dirtyFields)
    );
    if (isEmpty(createOrUpdatePayload)) {
      return null;
    }

    return createOrUpdatePayload.phone_number
      ? { ...createOrUpdatePayload, phone: createOrUpdatePayload.phone_number }
      : createOrUpdatePayload;
  };

  buildActualChangesPayload = (values, touchedFields) => {
    const { userDetails = {} } = this.props;
    const changesPayload = pickBy(
      values,
      (value, key) => touchedFields.includes(key) && value !== userDetails[key]
    );
    if (isEqual(new Set(values.roles), new Set((userDetails.roles || []).map(({ key }) => key)))) {
      delete changesPayload.roles;
    }
    if (touchedFields.includes('phone_number')) {
      changesPayload.isPhoneNumberInputDirty = true;
    }
    return changesPayload;
  };

  validateName = (val) =>
    !val || val.length < 2 || val.length > 100
      ? 'Full name must be between 2 and 100 characters'
      : undefined;

  validateEmail = (val) => {
    if (!val || !val.length) {
      return 'Email is required';
    }

    if (!EMAIL_PATTERN.test(val)) {
      return 'Please type a valid e-mail address';
    }

    return undefined;
  };

  validatePhone = (val) =>
    val && !window.intlTelInputUtils.isValidNumber(val) ? 'Phone number is invalid' : undefined;

  validateRoles = (val) => (!val || !val.length ? 'A role is required' : undefined);

  handleIfUserAlreadyExistError = () => {
    const { isUserExist } = this.state;

    if (isUserExist) {
      this.setState({ isUserExist: false });
    }
  };

  render() {
    const { userDetails, roles, currentUserId, canViewRoles, close } = this.props;
    const { showReactivationTooltip, isInviting, isUserExist } = this.state;
    const initialValues = userDetails
      ? { ...userDetails, roles: userDetails.roles.map(({ key }) => key) }
      : {};

    // This preserves the current AngularJS logic and should be replaced when a decision is made.
    const canEditRoles = !currentUserId || canViewRoles;
    return (
      <BamForm
        onChange={this.handleIfUserAlreadyExistError}
        id="UserInputForm"
        ref={(r) => {
          this.formRef = r;
        }}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        positiveButton={{
          loading: isInviting,
          text: `${currentUserId ? 'Update' : 'Invite'} User`,
          disabled: showReactivationTooltip,
        }}
        closeButton={{
          text: 'Cancel',
          onClick: close,
          disabled: showReactivationTooltip,
        }}
      >
        <Field
          name="name"
          title="Full Name"
          aria-label="Full Name"
          component={BamInput}
          autoFocus
          maxLength={100}
          placeholder="e.g. John Doe"
          validate={this.validateName}
        />
        <Field
          name="email"
          component={BamInput}
          aria-label="Email"
          title="Email"
          placeholder="e.g. john@company.com"
          validate={this.validateEmail}
          disabled={userDetails && !!userDetails.id}
        />
        {this.state.isUserExist && (
          <ValidationError active="true">Email already belongs to an existing user</ValidationError>
        )}
        {canEditRoles && (
          <Field
            name="roles"
            component={BamMultiselect}
            options={roles && canViewRoles ? mapResourcesToSelectView(roles) : []}
            title="Roles"
            placeholder="Select roles..."
            validate={this.validateRoles}
          />
        )}
        <Field
          name="phone_number"
          component={BamInput}
          aria-label="Phone Number"
          title="Phone Number"
          placeholder="e.g. +1 310 5056789"
          validate={this.validatePhone}
          optional
        />
        {showReactivationTooltip && (
          <BamActionableTooltip
            trigger={<div className={styles['reactivate-tooltip-trigger']} />}
            header="This email address belongs to an inactive user"
            message="Reactivating this user will give the user access to BigPanda again and will update all past activities."
            action={this.reactivateUser}
            actionText="Reactivate User"
            cancel={this.closeReactivationTooltip}
            cancelText="Back To Editing"
            open
          />
        )}
      </BamForm>
    );
  }
}

const mapResourcesToSelectView = (list = [], textProperty = 'name', valueProperty = 'key') =>
  list.map((listItem) => ({
    key: listItem[valueProperty],
    text: listItem[textProperty],
    value: listItem[valueProperty],
  }));

UserForm.propTypes = {
  reactivateUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateOrInviteUserSuccess: PropTypes.func.isRequired,
  updateOrInviteUserFailure: PropTypes.func.isRequired,
  canViewRoles: PropTypes.bool.isRequired,
  inviteContext: PropTypes.shape({}).isRequired,
  currentUserId: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  userDetails: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    phone_number: PropTypes.string,
  }),
  close: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
  currentUserId: undefined,
  roles: undefined,
  userDetails: undefined,
  users: undefined,
};

export default hot(module)(UserForm);
