import { combineEpics } from 'redux-observable';
import * as meService from 'common/endpoints/me';

import actionTypes from './actionTypes';
import welcomeTypes from '../modules/settings/welcome/actionTypes';
import * as actions from './actions';

const loadUserData = ($action) =>
  $action
    .ofType(actionTypes.FETCH_USER, welcomeTypes.UPDATE_USER_NAME_PASSWORD_SUCCESS)
    .mergeMap(({ payload } = {}) =>
      meService
        .getUser(payload)
        .then((payload) => actions.loadUserDataSuccess(payload))
        .catch((error) => actions.loadUserDataFailure(error.message))
    );

export default combineEpics(loadUserData);
