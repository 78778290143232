import { AllSizes, Toggle } from '@bp/kung-fu';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';

import { showFailureMessage, showSuccessMessage } from '../../../../../../app/react/layout/settings/feedback';
import { QueryKeys } from '../../api/constants';
import { updateAlertFilter } from '../../api/endpoints';
import dictionary from '../../dictionary';
import { AlertFilter } from '../../types/AlertFilter';
import { setAtIndex } from '../../utils/setAtIndex';

interface IProps {
  filter: AlertFilter;
}

export function FilterActivationToggle({ filter }: IProps): JSX.Element {
  const [isToggleDisabled, setToggleDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(filter.active);
  const alertFilterMutation = useMutation(updateAlertFilter);
  const queryClient = useQueryClient();

  const onChange = useCallback(({ currentTarget: { checked: active } }): void => {
    const updatedFilter = { ...filter, active };
    setToggleDisabled(true);
    alertFilterMutation.mutate(
      updatedFilter,
      {
        onSuccess: () => {
          setIsChecked(active);
          queryClient.setQueryData(
            [QueryKeys.AlertFilter],
            (previous: AlertFilter[]): AlertFilter[] => {
              const itemIdx = previous.findIndex(({ id }) => id === filter.id);
              if (itemIdx === -1) return previous;
              return setAtIndex<AlertFilter>(previous, itemIdx, updatedFilter);
            },
          );
          queryClient.invalidateQueries([QueryKeys.AlertFilter, filter.id]);
          showSuccessMessage('Alert Filter', 'updated');
        },
        onError: () => {
          showFailureMessage('Alert Filter', 'update');
        },
        onSettled: (): void => {
          setToggleDisabled(false);
        },
      },
    );
  }, [filter]);

  return (
    <Toggle
      name="active"
      text={`${dictionary.active}:`}
      checked={isChecked}
      disabled={isToggleDisabled}
      size={AllSizes.MEDIUM}
      onChange={onChange}
    />
  );
}
