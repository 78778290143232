import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
angular.module('bigpanda.backend.services').factory('notificationService', notificationService);

function notificationService($rootScope, $log, pubSubService, $alert, $q, $timeout) {
  let lastAlert = null;

  function notify(message, options) {
    options = options || {};
    options.type = 'notify';
    return general(message, options);
  }

  function error(message, options) {
    options = options || {};
    options.type = 'error';
    return general(message, options);
  }

  function info(message, options) {
    options = options || {};
    options.type = 'info';
    return general(message, options);
  }

  function general(message, options) {
    const deferred = $q.defer();
    const scope = options.scope || $rootScope.$new();
    let closeTimeout = null;
    let deferredResult = {};

    normalizeMessage();

    scope.type = options.type;
    supportContent();
    scope.disableScreen = options.disableScreen || options.showStopper;
    scope.uiParameters = options.uiParameters;

    const alertOptions = {
      scope,
      title: options.title || '',
      placement: 'bottom-left',
      container: 'body',
      keyboard: false,
      show: true,
      template: 'utils/notification/alert',
    };

    const currentAlert = $alert(alertOptions);
    // If more changes will come to the hide function, we want to wrap it
    const closeFunction = () => {
      if (closeTimeout) $timeout.cancel(closeTimeout);
      if (currentAlert.$scope.$isShown) {
        currentAlert.hide();
      }
    };

    replaceHide();
    supportNoCloseAndSetTimeout();
    supportUndo();
    supportUntil();

    if (lastAlert && lastAlert.$scope.$isShown) {
      lastAlert.hide();
    }

    lastAlert = currentAlert;

    return deferred.promise;

    function normalizeMessage() {
      if (!message && !scope.content) {
        if (options.type === 'error') {
          message = 'General error!';
        } else {
          message = '';
        }
      } else if (!isString(message)) {
        message = `${message}`;
      }
    }

    function supportContent() {
      if (!scope.content) {
        // to let external caller change the content
        scope.content = message;
      }
    }

    function replaceHide() {
      const alertHide = currentAlert.hide;
      currentAlert.hide = () => {
        try {
          alertHide();
        } catch (e) {
          $log.error(`Error when hiding notification ${e}`);
        }
        deferred.resolve(deferredResult);
        currentAlert.destroy(); // Garbage collect
      };
    }

    function supportNoCloseAndSetTimeout() {
      // If waiting for an alert, doesn't allow to user to close the notification
      scope.noClose = options.until || options.noClose || false;
      scope.dismiss = false;
      if (!scope.noClose) {
        if (options.type !== 'error') {
          closeTimeout = $timeout(closeFunction, (options.duration || 5) * 1000);
        } else {
          scope.dismiss = true;
        }
      }

      scope.close = closeFunction;
    }

    function supportUndo() {
      if (options.undo) {
        scope.undo = () => {
          scope.undoing = true;
          deferredResult = { code: 'Undo' };
          if (closeTimeout) $timeout.cancel(closeTimeout);

          return $q.when(options.undo()).then(closeFunction, () => {
            // Notification service responsible for not letting anyone do something after undo
            scope.type = 'error';
            scope.content = 'Undoing failed...';
            scope.undo = null;
            deferredResult = { code: 'UndoFailed' };
            closeTimeout = $timeout(closeFunction, 5000);
            scope.undoing = false;
          });
        };
      }
    }

    function supportUntil() {
      if (options.until) {
        options.until = isArray(options.until) ? options.until : [options.until];
        const allDeregisters = [];
        options.until.forEach((currentOn) => {
          const untilDeregister = pubSubService.on(currentOn, () => {
            currentAlert.hide();
            allDeregisters.forEach((deregister) => {
              deregister();
            });
          });
          allDeregisters.push(untilDeregister);
        });
      }
    }
  }

  return { error, info, notify };
}
