angular.module('bp.widgets').directive('bpCarousel', () => ({
  restrict: 'E',
  templateUrl: 'widgets/bp_carousel/bp_carousel',
  controllerAs: 'tabsCtrl',
  bindToController: true,
  scope: {
    tabs: '=',
    close: '&',
    selectedIndex: '=?',
  },
  controller: function () {
    this.selectedIndex = 0;

    this.previous = () => {
      this.selectedIndex--;
    };

    this.next = () => {
      this.selectedIndex++;
    };

    this.closeFunction = () => {
      this.close()();
    };
  },
}));
