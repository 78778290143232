import { react2angular } from 'react2angular';
import TopBar from './TopBar';

angular
  .module('bigpanda')
  .component(
    'topbar',
    react2angular(TopBar, [
      'store',
      'currentRouteName',
      'toggleSearch',
      'toggleSideNav',
      'navigateToFeed',
      'selectedSettingsPageId',
    ])
  );
