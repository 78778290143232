import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme, EditIcon } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import selectors from '../../selectors';
import actions from '../../actions';
import { rulePropType } from '../../propTypes';
import AutoShareRulesActions from '../../actions/AutoShareRuleActions';
import AutoShareRuleModal from '../../modals/AutoShareRuleModal';
import AutoShareRuleInfoContent from './AutoShareRuleInfoContent';
import AutoShareTile from './AutoShareTile';
import utils from '../../utils';

class AutoShareRuleInfo extends React.PureComponent {
  toggleRule = () => {
    const { rule, updateAutoShareRule } = this.props;
    updateAutoShareRule({ inactive: !rule.inactive, id: rule.id });
  };

  render() {
    const {
      rule,
      permissions: { canEdit },
    } = this.props;

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsInfoScreen
          topActions={<AutoShareRulesActions rule={rule} />}
          header={
            <AutoShareTile
              inactive={rule.inactive}
              canEdit={canEdit}
              environmentName={utils.envName(rule.environment.name)}
              toggleRule={this.toggleRule}
            />
          }
          content={<AutoShareRuleInfoContent rule={rule} />}
          actions={
            <SettingsInfoScreen.Section>
              {canEdit && (
                <AutoShareRuleModal
                  triggerProps={{
                    variant: 'action-color',
                    text: 'Edit AutoShare Rule',
                    icon: <EditIcon />,
                    size: 'large',
                  }}
                  title="Edit AutoShare Rule"
                  titleIcon="bp-icon-edit"
                  rule={rule}
                />
              )}
            </SettingsInfoScreen.Section>
          }
        />
      </ThemeProvider>
    );
  }
}

AutoShareRuleInfo.propTypes = {
  updateAutoShareRule: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canEdit: PropTypes.bool,
  }).isRequired,
  rule: rulePropType,
};

AutoShareRuleInfo.defaultProps = {
  rule: undefined,
};

function mapStateToProps(state, ownProps) {
  const permissions = selectors.getActionsPermissions(state);
  return { permissions };
}

const mapDispatchToProps = {
  updateAutoShareRule: actions.updateAutoShareRule,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoShareRuleInfo);
