import actionTypes from './actionTypes';

export function loadSSOState() {
  return { type: actionTypes.LOAD_SSO_STATE };
}

export function loadSSOStateSuccess(payload) {
  return { type: actionTypes.LOAD_SSO_STATE_SUCCESS, payload: payload };
}

export function loadSSOStateFailure(payload) {
  return { type: actionTypes.LOAD_SSO_STATE_FAILURE, payload: payload };
}

export function configureSSOProvider(payload) {
  return { type: actionTypes.CONFIGURE_SSO_PROVIDER, payload: payload };
}

export function configureSSOProviderSuccess(payload) {
  return { type: actionTypes.CONFIGURE_SSO_PROVIDER_SUCCESS, payload: payload };
}

export function configureSSOProviderFailure(payload) {
  return { type: actionTypes.CONFIGURE_SSO_PROVIDER_FAILURE, payload: payload };
}

export function updateSSO(payload) {
  return { type: actionTypes.UPDATE_SSO, payload: payload };
}

export function updateSSOSuccess(payload) {
  return { type: actionTypes.UPDATE_SSO_SUCCESS, payload: payload };
}

export function updateSSOFailure(payload) {
  return { type: actionTypes.UPDATE_SSO_FAILURE, payload: payload };
}

export function disableSSO() {
  return { type: actionTypes.DISABLE_SSO };
}

export function disableSSOSuccess(payload) {
  return { type: actionTypes.DISABLE_SSO_SUCCESS, payload: payload };
}

export function disableSSOFailure(payload) {
  return { type: actionTypes.DISABLE_SSO_FAILURE, payload: payload };
}
