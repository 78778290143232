import React from 'react';
import { BamDetails, TimeFormats } from '@bp/bam';
import PropTypes from 'prop-types';
import styles from './PatternDetails.scss';

const { momentOrEpochRelativeTimeFormat } = TimeFormats;

const PatternDetails = (props) => {
  const { updateTime, onClose, selectedPattern } = props;
  const selectedPatternObject = selectedPattern._original;
  const tags = [
    {
      tag: 'Value',
      value: <span className={styles['tag-value']}>{selectedPatternObject.value}</span>,
    },
    {
      tag: 'Condition',
      value: <span className={styles['tag-value']}>{selectedPatternObject.condition}</span>,
    },
  ];
  return (
    <BamDetails>
      <BamDetails.BamDetailsHeader onClose={onClose} title="Item Details" />
      <BamDetails.BamDetailsBody>
        <BamDetails.BamDetailsTags tags={tags} />
      </BamDetails.BamDetailsBody>
    </BamDetails>
  );
};

PatternDetails.propTypes = {
  selectedPattern: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  updateTime: PropTypes.number.isRequired,
};

export default PatternDetails;
