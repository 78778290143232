import find from 'lodash/find';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import { getItemPropertyValue } from '../../utils/ObjectUtils';

export function getTagValue(object, criteria) {
  const values = getTagValues(object, criteria);
  return values.length === 0 ? null : values[0];
}

export function getTagValueFromEntities(entities, criteria) {
  const tags = map(entities, 'tags');
  const values = getTagValues(
    {
      tags: flatten(tags),
    },
    {
      type: criteria,
    }
  );

  return uniq(values);
}

export function getTagValues(object, criteria) {
  const tags = findTags(object, criteria);
  let values = map(tags, 'value');
  if (values.length === 1 && isArray(values[0])) {
    values = values[0];
  }
  return values;
}

export function getAnnotationValue(object, type) {
  const tag = findAnnotation(object, type);
  return tag ? tag.value : null;
}

export function findAnnotation(object, type) {
  const tags = findTags(object, { annotation: true, type: type });
  if (!isEmpty(tags)) {
    return tags[0];
  }
  return null;
}

export function findTags(object, criteria) {
  return filter(object.tags, criteria);
}
export function getAnnotations(object) {
  return filter(object.tags, (tag) => tag.annotation);
}

export function getFieldValue(object, field) {
  let value = getItemPropertyValue(object, field);
  if (!value) {
    if (isArray(field)) {
      value = reduce(field, (value, f) => value || getTagValues(object, { type: f }), null);
    } else {
      value = getTagValues(object, { type: field });
    }
  }

  if (value && !isArray(value)) {
    return [value];
  }

  return value;
}

export function findTagByType(tags, type) {
  return find(tags, (tag) => tag.type === type) || {};
}
