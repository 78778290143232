import filter from 'lodash/filter';
const { getIntegrationsForDisplayFactory } = require('common/utils/integrations');
angular.module('bigpanda').service('UserIntegrationsUtils', UserIntegrationsUtils);

function UserIntegrationsUtils(AvailableIntegrationsStore, DisplayCommonService) {
  this.isVisible = isVisible;
  this.hasActiveIntegrations = hasActiveIntegrations;
  this.getIntegrationsForDisplay = getIntegrationsForDisplayFactory(
    AvailableIntegrationsStore.getAvailableIntegration,
    DisplayCommonService.findSystemName
  );

  function isVisible(integration) {
    const integrationConfig = AvailableIntegrationsStore.getAvailableIntegration(
      integration.system_id
    );
    if (integrationConfig && integrationConfig.options && integrationConfig.options.hiddenList) {
      return false;
    }
    return true;
  }
  function hasActiveIntegrations(integrations) {
    const visibleIntegrations = filter(integrations, isVisible);
    return filter(visibleIntegrations, { state: 'active' }).length > 0;
  }
}
