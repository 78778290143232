import get from 'lodash/get';
import { path } from './constants';

const selectors = {};

selectors.getCorrelationConfig = (state) => get(state, path).correlationConfig;

selectors.getUnifiedTags = (state) => get(state, path).unifiedTags;

export default selectors;
