import * as auth from '../../../../../app/common/auth';
import bpFetch from '../../../../../app/common/utils/http/fetcher';

const integrationPath = '/resources/v2.1/integrations';
const autoSharePath = '/resources/v2.1/autoshare-rules';
const supportedTargetSystem = ['teams'];

const headers = {
  Authorization: `Bearer ${auth.getAuthTokenFromQuery() || auth.getAuthTokenFromCookie()}`,
};

async function getIntegrations(appKey = '') {
  return bpFetch({
    url: integrationPath,
    params: {
      app_key: appKey,
    },
    headers,
  }).then((response) => response.data.data.items);
}
const createAutoShareRule = async (payload) => bpFetch({
  url: autoSharePath,
  method: 'POST',
  data: payload,
  headers,
});

const toggleAutoShareRule = async (ruleId, active) => bpFetch({
  url: `${autoSharePath}/${ruleId}`,
  method: 'PATCH',
  data: {
    active,
  },
  headers,
});

const deleteAutoShareRule = async (ruleId) => bpFetch({
  url: `${autoSharePath}/${ruleId}`,
  method: 'DELETE',
  headers,
});

export {
  createAutoShareRule,
  deleteAutoShareRule,
  getIntegrations,
  supportedTargetSystem,
  toggleAutoShareRule,
};
