import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Tooltip, darkTheme } from '@bp/kung-fu';
import truncate from 'lodash/truncate';
import { Wrapper, Overlay, Trigger } from './TextWithTooltip.css';

const TextWithTooltip = ({ triggerText, text, length, TextWrapper = Overlay, ...tooltipProps }) => {
  const truncatedText = length ? truncate(text, { length }) : text;
  return (
    <ThemeProvider theme={darkTheme}>
      <Wrapper>
        <Tooltip
          text={<TextWrapper>{text}</TextWrapper>}
          placement="top"
          maxWidth="300px"
          {...tooltipProps}
        >
          <Trigger>{triggerText || truncatedText}</Trigger>
        </Tooltip>
      </Wrapper>
    </ThemeProvider>
  );
};

TextWithTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number,
  triggerText: PropTypes.string,
  TextWrapper: PropTypes.func,
};

export default TextWithTooltip;
