import actionTypes from './actionTypes';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';
import { baseSettingInitialState, generateBaseSettingsReducer } from '../redux.utils';

export const initialState = {
  ...baseSettingInitialState,
  correlationPatterns: undefined,
  filtersDropDownState: { ...DROPDOWN_FILTERS_ALL_ITEM_OPTION },
};

const baseReducer = generateBaseSettingsReducer(actionTypes, initialState);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_CORRELATION_PATTERNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        correlationPatterns: action.payload,
      };
    case actionTypes.LOAD_CORRELATION_PATTERNS:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return baseReducer(state, action);
  }
}
