import debounce from 'lodash/debounce';
angular.module('bigpanda').directive('tabsResizable', tabsResizable);

function tabsResizable() {
  return {
    controller: controller,
  };

  function controller($scope, $document, $window, $attrs, TabsResizableStore, pubSubService) {
    const vm = this;

    const TIME_TO_DIGEST = 30;

    let bar = null;
    let leftPane = null;
    let rightPane = null;
    const applyDebounce = debounce(() => $scope.$apply(), TIME_TO_DIGEST);

    vm.registerBar = registerBar;
    vm.registerLeftPane = registerLeftPane;
    vm.registerRightPane = registerRightPane;

    vm.$onInit = init;

    function init() {
      $scope.$on('$destroy', onDestroy);
      applyOnWindowResize();
      updateLeftPaneWidth(TabsResizableStore.getState($attrs.tabsResizableName));
    }

    function applyOnWindowResize() {
      const windowElement = angular.element($window);
      windowElement.on('resize', windowResize);
      $scope.$on('$destroy', () => {
        windowElement.off('resize', windowResize);
      });
    }

    function windowResize() {
      applyDebounce();
      sendResizingEvent();
    }

    function registerBar(barElement) {
      if (bar) {
        bar.off('mousedown', mousedown);
      }

      bar = barElement;
      bar.on('mousedown', mousedown);
    }

    function registerLeftPane(leftPaneElement) {
      leftPane = leftPaneElement;
      updateLeftPaneWidth(TabsResizableStore.getState($attrs.tabsResizableName));
    }

    function registerRightPane(rightPaneElement) {
      rightPane = rightPaneElement;
    }

    function mousedown(event) {
      // event.button = 0 means that it's the left mouse click and nothing else
      if (event.button === 0) {
        event.preventDefault();
        $document.on('mousemove', mousemove);
        $document.on('mouseup', mouseup);
      }
    }

    function mousemove(event) {
      // In the edge case where the leftPane hasn't registered yet, we just do nothing when the bar is dragged
      if (leftPane) {
        updateLeftPaneWidth(event.pageX - leftPane.position().left);
        applyDebounce();
      }

      sendResizingEvent();
    }

    function updateLeftPaneWidth(width) {
      if (leftPane && width) {
        leftPane.css({
          width: `${width}px`,
        });
        leftPane.addClass('resizing');
        TabsResizableStore.updateState($attrs.tabsResizableName, width);
      }
    }

    function sendResizedEvent() {
      sendBaseResizeEvent('tabs.resize');
    }

    function sendResizingEvent() {
      sendBaseResizeEvent('tabs.resizing');
    }

    function sendBaseResizeEvent(eventName) {
      const leftWidth = leftPane ? leftPane.width() : 0;
      const rightWidth = rightPane ? rightPane.width() : 0;
      const windowWidth = angular.element($window).width();
      pubSubService.broadcast(eventName, {
        name: $attrs.tabsResizableName,
        left: leftWidth,
        right: rightWidth,
        window: windowWidth,
      });
    }

    function mouseup() {
      sendResizedEvent();
      $document.off('mousemove', mousemove);
      $document.off('mouseup', mouseup);
    }

    function onDestroy() {
      if (bar) {
        bar.off('mousedown', mousedown);
      }

      $document.off('mousemove', mousemove);
      $document.off('mouseup', mouseup);
    }
  }
}
