import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import take from 'lodash/take';
import { BamTile } from '@bp/bam';
import BpTimeSince from 'common/utils/BpTimeSince';
import { SortShape } from 'react/layout/settings/components/SettingsList';
import { CREATED_SORT_MODE } from '../../../roles_list/constants';
import RolesManagementActions from '../../../actions/RolesManagementActions';
import { normalizeRole } from '../../../role_info/selectors';

const countEntitiesText = (entitesList) =>
  entitesList && entitesList.length ? entitesList.length : 'No';

const getTooltipText = (entitiesList) => (
  <div>
    {(entitiesList.length > 9 ? take(entitiesList, 10).concat(['...']) : entitiesList).map(
      (item) => (
        <div key={item}>{item}</div>
      )
    )}
  </div>
);

const getEntitiesContent = (entities, displayName) => {
  const entitiesCount = countEntitiesText(entities);
  const entitiesTooltipText = getTooltipText(entities);
  const entitiesCountContent = `${entitiesCount} ${displayName}`;

  if (entitiesCount === 'No') {
    return { value: entitiesCountContent };
  }
  return { value: entitiesCountContent, tooltip: entitiesTooltipText };
};

const generateFirstRow = (role, sortMode) => {
  const { displayedEnvironments, displayedPermissions, allEnvironmentsAccess } = role;

  const permissions = getEntitiesContent(displayedPermissions, 'Permissions');
  const environments = allEnvironmentsAccess
    ? { value: 'All Environments' }
    : getEntitiesContent(displayedEnvironments, 'Environments');
  return {
    title: role.name || '',
    textParts: [permissions, environments],
    time: {
      title: sortMode === CREATED_SORT_MODE ? 'Created' : 'Updated',
      timestamp: BpTimeSince(
        sortMode === CREATED_SORT_MODE ? role.created_at : role.updated_at || role.created_at
      ),
    },
  };
};

const generateSecondRow = (role) => ({
  textParts: [
    {
      label: 'Users',
      value: role.usernames.length ? role.usernames.map(({ username }) => username) : 'None',
    },
  ],
  actionsBundle: <RolesManagementActions role={role} />,
});

const ListItem = ({ role: rawRole, sortMode, ...props }) => {
  const role = normalizeRole(rawRole);

  const handleClick = () => {
    window.location.href = `/#/app/settings/roles/${role._id}`;
  };

  return (
    <BamTile
      firstRow={generateFirstRow(role, sortMode)}
      secondRow={generateSecondRow(role)}
      onClick={handleClick}
      {...props}
    />
  );
};

ListItem.propTypes = {
  role: PropTypes.object.isRequired /* eslint-disable-line */,
  sortMode: SortShape.isRequired,
};

export default hot(module)(ListItem);
