import get from 'lodash/get';
import find from 'lodash/find';
import assign from 'lodash/assign';
angular.module('bigpanda').controller('SharesListController', SharesListController);

function SharesListController(
  $scope,
  $http,
  $log,
  ModalService,
  TargetSystemsStore,
  SharesUtils,
  SharesStore,
  Permissions,
  stateService
) {
  const vm = this;

  const IMG_URL_PREFIX = '/assets/img/shares-logos/';
  const manualVmShares = [];
  const autoVmShares = [];

  vm.shareTypes = [
    { type: 'manual', title: 'Manual', vmShares: manualVmShares },
    { type: 'auto', title: 'Auto', vmShares: autoVmShares },
  ];

  vm.cancel = cancel;
  vm.unsubscribeToggle = unsubscribeToggle;

  vm.currentEnvActions = {
    update: false,
  };

  init();

  function init() {
    vm.currentEnvActions.update = Permissions.checkGranularPermission(
      'action@update',
      'environments',
      stateService.getSelectedEnvironmentId()
    );
    updateShares().finally(() => {
      SharesStore.subscribe($scope, {
        storeUpdated: updateShares,
      });
    });
  }

  function cancel() {
    ModalService.hide();
  }

  function unsubscribeToggle(vmShare) {
    unsubscribe(vmShare);
  }

  function unsubscribe(vmShare) {
    SharesUtils.unsubscribe(
      vm.incidentId,
      vmShare.formattedShare._id,
      vmShare.escalatee && vmShare.escalatee._id,
      !vmShare.unsubscribed
    ).catch((err) => {
      $log.error(
        `Error while trying to update unsubscribe to ${vmShare.unsubscribed} error: ${err.message}`
      );
    });
  }

  function updateShares() {
    return SharesStore.getShares(vm.incidentId).then((shares) => {
      shares.forEach((share) => {
        formatShare(share).then(() => {
          if (share.escalatees && share.escalatees.length > 0) {
            share.escalatees.forEach((escalatee) => {
              classifiedShare({
                formattedShare: share,
                escalatee: escalatee,
                unsubscribed: isUnsubscribed(share, escalatee._id) || false,
              });
            });
          } else {
            classifiedShare({
              formattedShare: share,
              unsubscribed: isUnsubscribed(share) || false,
            });
          }
        });
      });
    });
  }

  function isUnsubscribed(share, contactId) {
    return share.unsubscribes && (share.unsubscribes.all || share.unsubscribes[contactId]);
  }

  function classifiedShare(vmShare) {
    if (
      vmShare.formattedShare.escalation_options &&
      vmShare.formattedShare.escalation_options.auto
    ) {
      addShare(autoVmShares, vmShare);
    } else {
      addShare(manualVmShares, vmShare);
    }
  }

  function addShare(vmSharesCollection, vmShareToCheck) {
    const existsEscalatees = getEscalateeToAssign(vmSharesCollection, vmShareToCheck);

    const existsEscalation = getEscalationToAssign(vmSharesCollection, vmShareToCheck);

    if (existsEscalatees || existsEscalation) {
      assign(existsEscalatees || existsEscalation, vmShareToCheck);
    } else {
      vmSharesCollection.push(vmShareToCheck);
    }
  }

  function getEscalateeToAssign(vmSharesCollection, vmShareToCheck) {
    if (!vmShareToCheck.escalatee) {
      return false;
    }

    const existsEscalatee = find(
      vmSharesCollection,
      (vmShare) =>
        vmShare.escalatee &&
        vmShare.escalatee._id === vmShareToCheck.escalatee._id &&
        vmShare.formattedShare._id === vmShareToCheck.formattedShare._id
    );

    return existsEscalatee;
  }

  function getEscalationToAssign(vmSharesCollection, vmShareToCheck) {
    const existsShare = find(
      vmSharesCollection,
      (vmShare) => vmShare.formattedShare._id === vmShareToCheck.formattedShare._id
    );

    return existsShare && !vmShareToCheck.escalatee ? existsShare : false;
  }

  function formatShare(share) {
    const shareOptId = get(share, 'escalation_options.share_options.id');
    const shareOptMessage = get(share, 'escalation_options.message');
    const { target_system_id: shareTargetId } = share;

    if (share.target_system_id === 'ai-analysis.automated_incident_analysis') {
      share.imgUrl = `${IMG_URL_PREFIX}automated_incident_analysis.jpg`;
      share.targetSystem = {
        name: 'AI Analysis',
      };
    }

    return TargetSystemsStore.getById(shareTargetId, shareOptId, shareOptMessage).then(
      (targetSystem) => {
        if (targetSystem) {
          const imgUrlType =
            targetSystem.shareOptions && targetSystem.shareOptions.type
              ? targetSystem.shareOptions.type
              : targetSystem.parentId;
          share.info = targetSystem.getInfo && targetSystem.getInfo(share);
          share.targetSystem = targetSystem;
          share.statusFetched = false;
          share.showStatus = false;
          share.imgUrl = `${IMG_URL_PREFIX}${imgUrlType}.jpg`;
          if (targetSystem.options && targetSystem.options.withStatus && share.status === 'sent') {
            share.showStatus = true;
            $http
              .get(
                `/web/incidents/${vm.incidentId}/escalations/${targetSystem.parentId}/${share._id}/status`
              )
              .then(
                (res) => {
                  if (res.data && res.data.item && res.data.item.ticketStatus) {
                    share.ticketStatus = res.data.item.ticketStatus;
                  } else {
                    share.ticketStatus = 'Unknown';
                  }

                  share.statusFetched = true;
                },
                (err) => {
                  const error =
                    err.data &&
                    err.data.response &&
                    err.data.response.errors &&
                    err.data.response.errors.join(', ');

                  $log.error(
                    `User could not get status for escalation. status code: ${err.status} error: ${error}`
                  );
                  share.status = error || 'Failed to retrieve status';
                  share.statusFetched = true;
                }
              );
          }
        }
      }
    );
  }
}
