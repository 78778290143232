import { combineReducers } from 'redux';
import * as personalSettings from './personal_settings';
import * as rolesManagement from './roles_management';
import * as maintenancePlans from './maintenance_plans';
import * as userManagement from './user_management';
import * as apiKeysManagement from './api_keys_management';
import * as customTags from './custom_tags';
import * as correlationPatterns from './correlation_patterns';
import * as incidentLabels from './incident_labels';
import * as changePassword from './change_password';
import * as autoShareRules from './auto_share_rules';
import * as sso from './sso';
import * as alertEnrichment from './alert_enrichment';
import * as welcome from './welcome';
import * as integrationsManagament from './integrations_management';
import * as alertFiltering from '../../../../workspaces/apps/alert-filtering/src';

export default combineReducers({
  [personalSettings.name]: personalSettings.reducer,
  [rolesManagement.name]: rolesManagement.reducer,
  [maintenancePlans.name]: maintenancePlans.reducer,
  [userManagement.name]: userManagement.reducer,
  [apiKeysManagement.name]: apiKeysManagement.reducer,
  [customTags.name]: customTags.reducer,
  [correlationPatterns.name]: correlationPatterns.reducer,
  [incidentLabels.name]: incidentLabels.reducer,
  [changePassword.name]: changePassword.reducer,
  [autoShareRules.name]: autoShareRules.reducer,
  [sso.name]: sso.reducer,
  [alertEnrichment.name]: alertEnrichment.reducer,
  [welcome.name]: welcome.reducer,
  [integrationsManagament.name]: integrationsManagament.reducer,
  [alertFiltering.name]: alertFiltering.reducer,
});
