import { find, compose, map, cloneDeep, update, concat } from 'lodash/fp';
import {
  ANY_OPTION_VALUE,
  colorsNameHexObj,
  defaultColor,
  INCIDENT_LABELS_STATUSES,
  incidentTagClosedListTypes,
} from './constants';
import { filterItems, filterItemsByDropDownFilters } from '../redux.utils';
import { TAG_NAME_SORT_MODE } from './incident_labels_list/constants';

export const replaceObjectInListById = (objectToReplace) =>
  map((item) => (item.id === objectToReplace.id ? { ...objectToReplace } : { ...item }));

export const addObjectToList = (objectToAdd) => concat(objectToAdd);

export const getCorrectColor = (colorName, defaultTo = defaultColor) =>
  colorsNameHexObj[colorName] ? colorName : defaultTo;

export const mapIncidentLabelConfigOrderedList = (mappingfunc) =>
  compose(update('config.ordered_list', map(mappingfunc)), cloneDeep);
export const configOrderedListItemLogic = (item) => ({
  ...item,
  ...{
    color: getCorrectColor(item.color),
    description: item.description ? item.description.trim() : '',
  },
});
export const addUsernameToIncidentLabel = (users) => (item) => ({
  ...item,
  createdByUser: find({ bigpanda_user_id: item.created_by }, users),
  updatedByUser: find({ bigpanda_user_id: item.updated_by }, users),
});

export const incidentLabelsFilterByDropDown = (filtersDropDownState, items) => {
  return filterItemsByDropDownFilters(
    filtersDropDownState,
    {
      tagType: (item) => [item.type],
      status: (item) => [
        item.active ? INCIDENT_LABELS_STATUSES.active : INCIDENT_LABELS_STATUSES.inactive,
      ],
    },
    items,
    ANY_OPTION_VALUE
  );
};

export const filteredIncidentLabelsItems = (filterText, items) =>
  filterItems(items, filterText, (incidentLabel) => [
    incidentLabel.name,
    incidentLabel.type,
    incidentLabel.description,
    incidentLabel.config && incidentLabel.config.ordered_list
      ? incidentLabel.config.ordered_list
          .map((level) => `${level.display_name}${level.description}`)
          .join(',')
      : null,
  ]);

export const sortBy = (key, order) => {
  // eslint-disable-next-line no-nested-ternary
  return (b, a) => {
    let aValue = a[key];
    let bValue = b[key];
    if (key === TAG_NAME_SORT_MODE.field) {
      aValue = aValue.toLowerCase();
      bValue = bValue.toLowerCase();
    }
    if (order) {
      let temp = aValue;
      aValue = bValue;
      bValue = temp;
    }
    const sortCondition = (bValue, aValue) => (bValue > aValue ? -1 : 0);
    const mainSortCondition = (bValue, aValue) =>
      aValue > bValue ? 1 : sortCondition(bValue, aValue);
    return mainSortCondition(bValue, aValue);
  };
};

export const sortedItems = (sortMode, items) => {
  const { field, ascending } = sortMode;
  return items.sort(sortBy(field, ascending));
};

export const formatTimestamp = (timestamp) =>
  timestamp && moment.unix((timestamp /= 1000)).format('MMM D, YYYY HH:MMa');

export const isTagTypeClosedList = (tagType) =>
  [
    incidentTagClosedListTypes.CLOSED_LIST_VALUE_MULTI,
    incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE,
  ].includes(tagType);
