import React, { memo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import isMapping from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/isMapping';
import { EnrichmentRule, Tag } from '../../../../types';
import RuleForm from '../../RuleForm/RuleForm';
import { LoadPreview, UnifiedTag } from '../../RuleTypeForms/types';
import { RuleSummary } from '../RuleSummary';
import { ContextButtons } from './ContextButtons';
import { Container, StyledCollapsible } from './DraggableRule.style';

const getStyle = (dndStyle, { isDropAnimating }): object => ({
  ...dndStyle,
  left: 'inherit',
  ...(isDropAnimating ? { transitionDuration: '0.01s' } : {}),
});

interface IProps {
  rule: EnrichmentRule;
  values: EnrichmentRule;
  index: number;
  onLoadPreview: LoadPreview;
  defaultOpen: boolean;
  tag: Tag;
  hasError: boolean;
  canPreview: boolean;
  isNewOrDuplicate: boolean;
  forceFocus: boolean | undefined;
  freeFocus: () => void | undefined;
  onValueChanged: (id: string) => void;
  onCollapseChanged: (val: boolean) => void | undefined;
  unifiedTags: UnifiedTag[];
  isLastRule: boolean;
  changed: boolean;
  setHasChanged: (val: boolean) => void;
}

const doNothing = (): void => {};

function DraggableRule({
  tag,
  rule,
  values,
  index,
  hasError,
  canPreview,
  forceFocus,
  freeFocus = doNothing,
  isNewOrDuplicate,
  onValueChanged,
  unifiedTags,
  isLastRule,
  changed,
  setHasChanged,
  defaultOpen,
  onCollapseChanged,
  onLoadPreview,
}: IProps): JSX.Element {
  const [open, setIsOpen] = useState(defaultOpen && !isMapping(rule.type));

  const onPreviewClick = (): void => {
    if (hasError) return;

    onLoadPreview(rule.id);
    setHasChanged(false);
  };

  const onToggle = (isOpen: boolean): void => {
    setIsOpen(isOpen);

    if (onCollapseChanged) {
      onCollapseChanged(isOpen);
    }
  };

  return (
    <Draggable draggableId={rule.id} index={index}>
      {(provided, snapshot): JSX.Element => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getStyle(provided.draggableProps.style, snapshot)}
          isOpen={open}
        >
          <StyledCollapsible
            hasError={hasError}
            arrowRenderer={(props): JSX.Element => (
              <ContextButtons
                {...props}
                isDragging={snapshot.isDragging}
                {...provided.dragHandleProps}
              />
            )}
            summaryRenderer={(props): JSX.Element => (
              <RuleSummary
                {...props}
                rule={rule}
                index={index}
                isLastRule={isLastRule}
                enrichmentRule={
                  tag && tag.enrichments
                    ? tag.enrichments.filter((r) => r.id === rule.id)[0]
                    : undefined
                }
                values={values}
                onLoadPreview={onPreviewClick}
                canPreview={canPreview}
                onValueChanged={onValueChanged}
                tagType={tag && tag.type}
                changed={changed}
                setHasChanged={setHasChanged}
              />
            )}
            defaultOpen={open}
            aria-label={`${rule.type} rule #${index + 1}`}
            onToggle={onToggle}
            forceFocus={forceFocus}
            freeFocus={freeFocus}
          >
            <RuleForm
              unifiedTags={unifiedTags}
              rule={rule}
              index={index}
              isNewOrDuplicate={isNewOrDuplicate}
              onLoadPreview={onPreviewClick}
              values={values}
              tag={tag}
              funcType={undefined}
            />
          </StyledCollapsible>
        </Container>
      )}
    </Draggable>
  );
}

export default memo(DraggableRule);
