/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import * as colors from '@bp/pastel/colors';
import variants from './constants';

const colorsMap = {
  group: {
    [variants.light]: colors.bp_gray_05,
    [variants.normal]: colors.bp_gray_06,
  },
  value: {
    [variants.light]: colors.bp_gray_07,
    [variants.normal]: colors.bp_gray_08,
  },
};

export const LabelGroup = styled.span`
  color: ${({ variant }) => colorsMap.group[variant]};
`;

export const LabelValues = styled.span`
  color: ${({ variant }) => colorsMap.value[variant]};
`;
