import { IColumn, IRow } from '@bp/kung-fu';
import {
  useInfiniteQuery, UseInfiniteQueryResult, useQueryClient,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAuditLogs } from '../../api/endpoints';
import { AuditLogFilter } from '../../components/types';
import { DEFAULT_FILTERS, QueryKeys } from '../../constants';
import selectors from '../../store/selectors';
import { columnData, COUNT_PER_PAGE } from './constants';
import {
  AuditLogResponse, Environment, UseAuditTableResult,
} from './types';
import { auditResponseMapper } from './utils';

const useAuditTable = (): UseAuditTableResult => {
  const queryClient = useQueryClient();

  const [column, setColumn] = useState<IColumn[]>(columnData);
  const [rows, setRows] = useState<IRow[]>([]);
  const [filters, setFilters] = useState<AuditLogFilter>(DEFAULT_FILTERS);

  const envs: Environment[] = useSelector(selectors.environments);

  const {
    isLoading, isFetching, fetchNextPage, hasNextPage, refetch, isFetchingNextPage,
  }: UseInfiniteQueryResult<AuditLogResponse> = useInfiniteQuery(
    [QueryKeys.AuditLogs],
    ({ pageParam }) => getAuditLogs(pageParam, COUNT_PER_PAGE, filters),
    {
      staleTime: Infinity,
      getNextPageParam: (lastPage, allPages) => {
        if (allPages.length * COUNT_PER_PAGE < lastPage.total) {
          return allPages.length + 1;
        }
        return undefined;
      },
      onSuccess: (data) => {
        if (!isFetching && !isLoading && !hasNextPage) return;

        const { pages } = data;
        const lastPageIndex = (pages.length - 1) || 0;
        if (pages && pages[lastPageIndex]) {
          const auditLogData = auditResponseMapper(pages[lastPageIndex].items, envs);
          setRows((prevState) => [...(prevState || []), ...auditLogData]);
        }
      },
    },
  );

  const isFiltering = isFetching && !isFetchingNextPage;

  const handleRefetch = (): void => {
    setRows([]);
    queryClient.setQueryData([QueryKeys.AuditLogs], () => ({
      pages: 1,
    }));
    refetch();
  };

  useEffect(() => {
    if (!isFetching && !isLoading) handleRefetch();
  }, [filters]);

  return {
    column,
    setColumn,
    rows,
    setRows,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    setFilters,
    filters,
    isFiltering,
  };
};

export default useAuditTable;
