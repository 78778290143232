import { path as prevPath } from '../constants';

export const name = 'correlationPatterns';
export const path = `${prevPath}.${name}`;

export const ANY_OPTION_VALUE = '-1';
export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  tag: { value: ANY_OPTION_VALUE, text: 'Any Correlation Tag' },
  status: { value: ANY_OPTION_VALUE, text: 'Any Status' },
  source: { value: ANY_OPTION_VALUE, text: 'Any Source' },
  attribute: { value: ANY_OPTION_VALUE, text: 'Any Attribute' },
};
export const ATTRIBUTE_FILTERS_OPTIONS = [
  { text: 'Cross-source', value: 'cross-source', key: 'cross-source' },
  { text: 'Suggested', value: 'suggested', key: 'suggested' },
];
