import { AllSizes, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const LI = styled.li`
    margin-bottom: 14px;
    padding-left: 1em;
    text-indent: -1em;

    &:before {
        content: '●';
        font-size: ${({ bulletSize }): string => (bulletSize === AllSizes.LARGE ? '16px' : '8px')};
        color: ${(p): string => p.theme.bp_gray_06};
        position: relative;
        top: 2px;
    }
`;

export const Span = styled(Text)`
    position: relative;
    left: 14px;
`;
