import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import autobind from 'class-autobind';
import { ThemeProvider } from 'styled-components';
import { DateRangePicker, darkTheme } from '@bp/kung-fu';
import { BamInput, BamForm, BamBpqlInput, BamAddNoteField, BamRangeDatePicker } from '@bp/bam';
import { selectors as alertTagsSelectors } from 'react/common/alert_tags';
import moment from 'moment';
import get from 'lodash/get';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { tripleEqualSignRegex } from 'react/helpers/regex';
import selectors from '../../selectors';
import styles from './maintenance_form.scss';
import QueryHelper from '../../../../../../../../workspaces/apps/planned-maintenance/src/components/QueryHelper';

class MaintenanceForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this, MaintenanceForm.prototype);

    if (props.duplicate) {
      props.initialValues.start = null; // eslint-disable-line
      props.initialValues.end = null; // eslint-disable-line
    }
  }
  handleSubmit(values, form) {
    const state = form.getState();
    const {
      showDonePlans,
      editTimeWindow,
      stopPlan,
      updatePlanEnd,
      stopPlanNow,
      duplicate,
      createOrUpdatePlan,
      close,
    } = this.props;
    if (state.dirty || stopPlan || duplicate) {
      const payload = {
        plan: {
          ...values,
          condition: BamBpqlInput.helpers.StringToBpql(values.condition),
        },
        active: showDonePlans,
      };
      if (editTimeWindow) {
        updatePlanEnd(payload);
      } else if (stopPlan) {
        stopPlanNow(payload);
      } else {
        if (duplicate) {
          payload.plan.id = undefined;
        }
        createOrUpdatePlan(payload);
      }
      close();
    }
    close();
  }

  render() {
    const {
      initialValues,
      editTimeWindow,
      stopPlan,
      duplicate,
      positiveButtonText,
      close,
      featureToggles,
    } = this.props;
    const defaultDiffInSeconds = 1800;
    const startIn = 300;
    const startDate = moment.unix(initialValues.start || Math.floor(+Date.now() / 1000 + startIn));
    const endDate = moment.unix(
      initialValues.end || Math.floor(+Date.now() / 1000) + startIn + defaultDiffInSeconds
    );

    const disableQueryHelper = get(featureToggles, 'disable_query_helper', false);

    const isOldSuggestionsCalculation =
      typeof featureToggles.old_suggestions_calc === 'undefined'
        ? false
        : featureToggles.old_suggestions_calc;
    /* Metrics Functions END */

    const validateName = (val) => {
      if (!val || val.length < 2) {
        return 'Enter a plan name with at least two characters';
      }
      return undefined;
    };

    const validateCondition = (val) => {
      const bpqlInputValidation = BamBpqlInput.helpers.BpqlInputValidator(val);
      if (bpqlInputValidation) {
        if (tripleEqualSignRegex.test(bpqlInputValidation)) {
          return bpqlInputValidation.replace(tripleEqualSignRegex, '"');
        }
        return bpqlInputValidation;
      }
      if (tripleEqualSignRegex.test(val)) {
        return 'Expected whitespace, quotes, slash or a character but "=" found.';
      }
      return undefined;
    };

    const timeWindowValidation = (val) => {
      if (val.to.isBefore(moment())) {
        return 'Date must be in the future';
      } else if (val.to.isBefore(val.from)) {
        return 'Start date must be before end date';
      }
      return undefined;
    };
    const maintenancePlansOperatorsText = '= | != | IN | NOT IN | OR | AND';
    const firstSection = () => {
      if (!stopPlan && !editTimeWindow) {
        return (
          <div>
            <Field
              name="name"
              component={BamInput}
              title="Summary"
              placeholder=""
              autoFocus
              validate={validateName}
              aria-label="Summary"
            />
            {!disableQueryHelper ? (
              <Field name="condition">
                {({ input: { onChange } }) => (
                  <QueryHelper
                    triggerComponentProps={{
                      title: 'Condition',
                      value: BamBpqlInput.helpers.BpqlToString(initialValues.condition),
                    }}
                    alertTags={this.props.tags}
                    onChange={onChange}
                  />
                )}
              </Field>
            ) : (
              <Field
                name="condition"
                component={BamBpqlInput}
                title="Condition"
                aria-label="Condition"
                interactiveTooltip
                tags={this.props.tags}
                validate={validateCondition}
                operatorsText={maintenancePlansOperatorsText}
                useOldCalculation={isOldSuggestionsCalculation}
              />
            )}
          </div>
        );
      }
      return <h1 className={styles['plan-name']}>{initialValues.name}</h1>;
    };

    const showDuration = ({ input }) => input.value.from && input.value.to;
    const durationString = ({ input }) => selectors.calcDiff(input.value.from, input.value.to);
    const renderDuration = (props, editTime) =>
      showDuration(props) && !editTime ? (
        <div className={styles.duration}>
          Duration: <label className={styles.time}>{durationString(props)}</label>
        </div>
      ) : (
        ''
      );

    const timesSection = (editTime) => {
      if (stopPlan) {
        return (
          <div className={styles['stop-plan']}>
            <p>
              Clicking the stop button will immediately stop the plan and transition it to
              &apos;Done&apos;
            </p>
          </div>
        );
      }
      return (
        <div className={styles.times}>
          <h2>Time window</h2>
          <Field name="times" validate={(val) => timeWindowValidation(val)} validateOnBlur>
            {(props) => (
              <div className={styles.content}>
                <DateRangePicker
                  startDate={{
                    value: get(props, 'input.value.from'),
                    blockPast: true,
                    disabled: !!editTimeWindow,
                    width: '110px',
                    label: editTimeWindow ? 'Started' : 'Start Date',
                    timePickerProps: {
                      label: 'Time',
                      width: '115px',
                    },
                  }}
                  endDate={{
                    value: get(props, 'input.value.to'),
                    blockPast: true,
                    width: '110px',
                    label: 'End Date',
                    timePickerProps: {
                      label: 'Time',
                      width: '115px',
                    },
                  }}
                  onChange={({ startDate, endDate }) => {
                    props.input.onChange({
                      from: moment(startDate),
                      to: moment(endDate),
                    });
                  }}
                  ariaLabel="New plan time window"
                />
                {renderDuration(props, editTime)}
              </div>
            )}
          </Field>
        </div>
      );
    };

    const descriptionSection = (open) => {
      if (!editTimeWindow && !stopPlan) {
        return (
          <div className={styles.description}>
            <Field
              name="description"
              open={open}
              title="Add Description"
              openStateTitle="Description"
              openStateDescription="(optional)"
              aria-label="Description"
              component={BamAddNoteField}
            />
          </div>
        );
      }
      return undefined;
    };

    return (
      <ThemeProvider theme={darkTheme}>
        <BamForm
          id="maintenance-form"
          className={styles['maintenance-input-form']}
          onSubmit={this.handleSubmit}
          positiveButton={{ text: positiveButtonText }}
          closeButton={{ text: 'Cancel', onClick: close }}
          initialValues={{
            times: {
              from: startDate,
              to: endDate,
            },
            name: duplicate ? `Copy of ${initialValues.name}` : initialValues.name,
            id: initialValues.id,
            description: initialValues.description,
            condition: BamBpqlInput.helpers.BpqlToString(initialValues.condition),
          }}
        >
          {firstSection()}
          {timesSection(editTimeWindow)}
          {descriptionSection(!!initialValues.description)}
        </BamForm>
      </ThemeProvider>
    );
  }
}

MaintenanceForm.propTypes = {
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
  createOrUpdatePlan: PropTypes.func.isRequired,
  editTimeWindow: PropTypes.bool,
  stopPlan: PropTypes.bool,
  stopPlanNow: PropTypes.func.isRequired,
  updatePlanEnd: PropTypes.func.isRequired,
  duplicate: PropTypes.bool,
  tags: PropTypes.arrayOf(Object),
  positiveButtonText: PropTypes.string,
  close: PropTypes.func.isRequired,
  showDonePlans: PropTypes.bool.isRequired,
  featureToggles: PropTypes.object.isRequired /* eslint-disable-line */,
};

MaintenanceForm.defaultProps = {
  initialValues: {
    id: null,
    name: null,
    start: null,
    end: null,
    description: null,
    condition: null,
    status: null,
  },
  editTimeWindow: undefined,
  stopPlan: undefined,
  duplicate: undefined,
  tags: undefined,
  positiveButtonText: null,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  return {
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
    organization,
    user,
    tags: alertTagsSelectors.getDisplayAlertTags(state),
  };
};

export default connect(mapStateToProps)(MaintenanceForm);
