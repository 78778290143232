import { fetcher } from '@bp/data-access';

import CorrelationPatternSuggestion from '../model/CorrelationPatternSuggestion';

const correlationPatternSuggestionsUrl = '/data/correlation-pattern-suggestions';

async function getCorrelationPatternSuggestions(
  organizationId: string,
): Promise<CorrelationPatternSuggestion[]> {
  return fetcher({
    requestConfig: {
      url: `${correlationPatternSuggestionsUrl}/${organizationId}`,
    },
    responseConfig: { path: 'data.serving' },
  });
}

export default getCorrelationPatternSuggestions;
