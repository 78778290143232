import bpFetch from '../../../../app/common/utils/http/fetcher';

export function getMaintenancePlans({ link, active = true, search = '' }) {
  const nextLink = link ? `&next=${link}` : '';
  const activeLink = active ? '&active=true' : '';
  const searchText = search ? `&search_text=${search}` : '';
  return bpFetch({
    url: `/resources/v2.0/maintenance-plans?pagination=true${nextLink}${activeLink}${searchText}`,
  }).then((res) => ({ data: res.data, link: res.headers.link, showDonePlans: active }));
}

export function createMaintenancePlan(plan) {
  return bpFetch({ url: '/resources/v2.0/maintenance-plans', method: 'POST', data: plan }).then(
    (res) => res.data,
  );
}

export function updateMaintenancePlan(plan) {
  return bpFetch({
    url: `/resources/v2.0/maintenance-plans/${plan.id}`,
    method: 'PATCH',
    data: plan,
  }).then((res) => res.data);
}

export function deleteMaintenancePlan(planId) {
  return bpFetch({ url: `/resources/v2.0/maintenance-plans/${planId}`, method: 'DELETE' }).then(
    (res) => res.data,
  );
}

export function stopMaintenancePlan(plan) {
  return bpFetch({
    url: `/resources/v2.0/maintenance-plans/${plan.id}/stop`,
    method: 'POST',
    data: plan,
  }).then((res) => res.data);
}
