import cn from 'classnames';
import React from 'react';

import styles from './SummaryData.scss';

interface IProps {
  index: number;
  funcType: string | undefined;
  first: string | undefined;
  isFirstWithoutValue: boolean | undefined;
  second: string | undefined;
  secondNoValueMsg: string;
  third: string | undefined;
  thirdNoValueMsg: string;
  hover: boolean;
  open: boolean;
  isPreviewed: boolean;
}

export function SummaryData({
  funcType,
  first,
  isFirstWithoutValue,
  second,
  secondNoValueMsg,
  third,
  thirdNoValueMsg,
  index,
  hover,
  open,
  isPreviewed,
}: IProps): JSX.Element {
  return (
    <div className={styles['summary-data']}>
      <span className={styles['summary-data-index']}>
        #
        {index + 1}
      </span>
      {funcType && (
        <span
          className={cn([styles['summary-data-info'], styles['summary-data-info-func']])}
          aria-label="summary data function type"
        >
          {funcType}
        </span>
      )}
      <span
        className={cn([styles['summary-data-info'], styles['summary-data-info-first']], {
          [styles['summary-data-info-no-value']]: isFirstWithoutValue,
          [styles['summary-data-info-first-short']]: funcType,
          [styles['summary-data-info-first-no-func-type']]: !funcType,
        })}
      >
        {first}
      </span>
      <span
        className={cn([styles['summary-data-info'], styles['summary-data-info-second']], {
          [styles['summary-data-info-no-value']]: !second,
          [styles['summary-data-info-second-short']]: funcType,
          [styles['summary-data-info-second-no-func-type']]: !funcType,
        })}
      >
        {second || secondNoValueMsg}
      </span>
      {!(hover || open) && (
        <span
          className={cn([styles['summary-data-info'], styles['summary-data-info-third']], {
            [styles['summary-data-info-third-short']]: isPreviewed || funcType,
            [styles['summary-data-info-no-value']]: !third,
          })}
        >
          {third || thirdNoValueMsg}
        </span>
      )}
    </div>
  );
}

export default SummaryData;
