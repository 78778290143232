angular
  .module('bigpanda')
  .factory('IntegrationsConfigurationsService', IntegrationsConfigurationsService);

function IntegrationsConfigurationsService($q, $http) {
  function getCurrentConfig(parentSystemId, systemId) {
    if (!systemId || systemId === parentSystemId) {
      return $q.when();
    }
    return $http
      .get(`/web/organizations/integrations/${parentSystemId}`, {
        params: { targetSystemId: systemId },
      })
      .then((res) => res.data.item);
  }

  function getWebHookConfig(parentSystemId, systemId) {
    if (!systemId || systemId === parentSystemId) {
      return $q.when({});
    }
    return $http
      .get('/web/organizations/integrations/webhook', {
        params: { targetSystemId: systemId },
      })
      .then((res) => res.data.item);
  }

  return {
    getCurrentConfig: getCurrentConfig,
    getWebHookConfig: getWebHookConfig,
  };
}
