angular.module('bigpanda').controller('ExpiredController', ($scope, pubSubService, $window) => {
  pubSubService.broadcast('Expired.ViewBlocker');

  $scope.seePricing = function () {
    $window.open('http://bigpanda.io/pricing');
  };

  $scope.extendTrial = function () {
    $window.open(
      'mailto:trial-extension@bigpanda.io?Subject=I%20would%20like%20to%20extend%20my%20trial'
    );
  };

  $scope.sendMail = function () {
    $window.open('mailto:info@bigpanda.io');
  };
});
