angular.module('bp.widgets').directive('bpCheckbox', bpCheckbox);

function bpCheckbox() {
  return {
    restrict: 'E',
    templateUrl: 'widgets/bp_checkbox/bp_checkbox',
    scope: {
      labelText: '@',
      tooltip: '@',
      model: '=',
      preChange: '&',
    },
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
    transclude: {
      content: '?content',
    },
  };

  function controller() {
    const vm = this;

    vm.handleClick = (e) => {
      vm.model = !vm.model;
      vm.preChange({ scope: vm });
      e.preventDefault();
    };
  }
}
