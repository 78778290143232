import { HBox, Text, VBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const TooltipContainer = styled(VBox)`
    width: 323px;
    margin-top: 26px;
    margin-bottom: 18px;
    margin-inline-start: 20px;
    margin-inline-end: 20px;
`;

export const Title = styled(Text)`
    font-weight: bold;
    color: ${(p) => p.theme.bp_gray_08};
`;

export const StyledText = styled(Text)`
    color: ${(p) => p.theme.bp_gray_07};
`;

export const InputContainer = styled.div`
    width: 100%;
    height: fit-content;

    & label.bam-field-title {
        display: block;
    }

    & label.error-validation {
        margin-bottom: 2px;
    }

    & .bam-form-field {
        margin-bottom: 0;
    }
`;

export const Subtitle = styled(HBox)`
    margin-top: 5px;
    margin-bottom: 5px;
`;
