import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import startsWith from 'lodash/startsWith';
import last from 'lodash/last';
import get from 'lodash/get';
import extend from 'lodash/extend';
import filter from 'lodash/filter';
import isEnvironmentFilteredByIncidentTags from '../../../workspaces/apps/environment/src/helpers/isEnvironmentFilteredByIncidentTags';

angular.module('bigpanda').controller('InvestigatorCtrl', InvestigatorCtrl);

const defaultQuery = '*';
const getEffectiveQuery = (query) => query || defaultQuery;
const defaultEnvironmentToBeDisplayed = (env) => ({ ...env, name: 'All Environments' });
const isDefaultEnvironment = (env) => env.name === 'All';

function InvestigatorCtrl(
  $scope,
  $log,
  InvestigatorService,
  EnvironmentsService,
  UserIntegrationsStore,
  UserIntegrationsUtils,
  BPQLService,
  arrayUtils,
  pubSubService,
  BPQLCompletionLogicService,
  TagsService,
  StateParametersService,
  LabelsStore,
  SortingStore,
  UserFeatureTogglesService,
  Permissions,
  PERMISSIONS_NAMES
) {
  $scope.updatePage('investigator');
  pubSubService.broadcast('currentTitleChanged', 'Unified Search');
  pubSubService.broadcast('investigator.view');
  const vm = this;
  const stateParametersServiceInstance = new StateParametersService();
  const parser = BPQLService.buildParserFromGrammer();
  let currentPage = 0;
  TagsService.getTags(true);

  const timeframePresets = [
    {
      timeframe: { type: 'relative', interval: '-1h' },
      title: 'Last Hour',
      getStartMoment: (end) => moment(end || undefined).subtract(1, 'hours'),
    },
    {
      timeframe: { type: 'relative', interval: '-2h' },
      title: 'Last 2 Hours',
      getStartMoment: (end) => moment(end || undefined).subtract(2, 'hours'),
    },
    {
      timeframe: { type: 'relative', interval: '-6h' },
      title: 'Last 6 Hours',
      getStartMoment: (end) => moment(end || undefined).subtract(6, 'hours'),
    },
    {
      timeframe: { type: 'relative', interval: '-24h' },
      title: 'Last 24 Hours',
      getStartMoment: (end) => moment(end || undefined).subtract(24, 'hours'),
    },
    {
      timeframe: { type: 'relative', interval: '-7d' },
      title: 'Last 7 Days',
      getStartMoment: (end) => moment(end || undefined).subtract(7, 'days'),
    },
    {
      timeframe: { type: 'absolute', interval: 'custom' },
      title: 'Pick Date Range...',
      iconClass: 'bp-icon-calendar',
    },
  ];

  const stateParams = {
    environment: null,
    query: null,
    source: null,
    timeframe: timeframePresets[4],
  };

  const autocompleteLogic = {
    submit: search,
    refresh: autocompleteRefresh,
    complete: autocomplete,
    tooltipTemplate: 'investigator/investigator_tooltip',
  };
  pubSubService.on('investigator.queryHelperSearch', queryHelperSearch, $scope);

  vm.searchWithIncidentTagsFt = UserFeatureTogglesService.getToggle('enable_namespace_for_search');
  vm.disableQueryHelper = UserFeatureTogglesService.getToggle('disable_query_helper');
  vm.results = [];
  vm.pageSize = 10;
  vm.timeframePresets = timeframePresets;
  vm.stateParams = stateParams;
  vm.search = search;
  vm.nextPage = nextPage;
  vm.resetFilters = resetFilters;
  vm.sortStatusFirst = sortStatusFirst;
  vm.initEnvironmentState = initEnvironmentState;
  vm.sendFeedback = () =>
    pubSubService.broadcast('Intercom.showNewMessage', { message: 'Investigator search feedback' });
  vm.noBorder = (index, isLast) => (index > 0 && (index + 1) % vm.pageSize === 0) || isLast;
  vm.autocompleteLogic = autocompleteLogic;
  vm.changeSort = changeSort;
  vm.sortOptions = [
    { display: 'Last Changed', value: SortingStore.options.lastChange, btnText: 'Last Changed' },
    { display: 'Status', value: SortingStore.options.status, btnText: 'Status' },
    { display: 'Created', value: SortingStore.options.start, btnText: 'Created' },
    { display: 'No. of Alerts', value: SortingStore.options.alerts, btnText: 'No. of Alerts' },
  ];

  init();

  function init() {
    vm.hasPermissions = Permissions.isPermitted(PERMISSIONS_NAMES.search.read);
    if (!vm.hasPermissions) {
      return;
    }
    $scope.$watch(() => vm.stateParams, updateStateParams, true);
    EnvironmentsService.get().then(vm.initEnvironmentState);

    UserIntegrationsStore.getIntegrations().then((integrations) => {
      vm.integrations = UserIntegrationsUtils.getIntegrationsForDisplay(
        filter(integrations, { type: 'alert' })
      );
      vm.integrations.unshift({ display: 'All Sources' });
    });

    SortingStore.getSortBy().then((sortBy) => {
      extend(vm.stateParams, { sort: sortBy });
    });
  }

  function initEnvironmentState(environments) {
    const defaultEnvironment = environments.find(isDefaultEnvironment);
    const userEnvironments = environments.filter((env) => !isDefaultEnvironment(env));
    const allEnvironments = defaultEnvironment
      ? [defaultEnvironmentToBeDisplayed(defaultEnvironment), ...userEnvironments]
      : userEnvironments;
    const environmentsIds = allEnvironments.map(({ _id }) => _id);
    const currEnvQueryParam = vm.stateParams.environment;
    vm.environments = allEnvironments;
    if (!(currEnvQueryParam && environmentsIds.includes(currEnvQueryParam))) {
      vm.stateParams.environment = get(vm.environments, '[0]._id');
    }
    LabelsStore.getMultipleIncidentTagDefinitions(vm.stateParams.environment);

    $scope.$watch(() => vm.stateParams.environment, shouldDisableSearch, true);
  }

  function queryHelperSearch(event, query) {
    vm.stateParams.query = query;
    search();
  }

  function search() {
    const query = getEffectiveQuery(vm.stateParams.query).trim();
    stateParametersServiceInstance.updateUrlParams(vm.stateParams);

    vm.error = null;

    try {
      vm.bpqlQuery = parser(query);
      vm.queryText = query;
    } catch (e) {
      e.queryText = query;
      vm.error = e;
      throw e;
    }

    vm.results = [];
    vm.noResults = false;
    currentPage = 0;

    if (vm.stateParams.timeframe) {
      if (vm.stateParams.timeframe.timeframe.interval !== 'custom') {
        vm.highlightRange = [vm.stateParams.timeframe.getStartMoment().toDate(), new Date()];
      } else {
        vm.highlightRange = [];

        if (vm.stateParams.timeframe.timeframe.start) {
          vm.highlightRange[0] = vm.stateParams.timeframe.timeframe.start;
        }

        if (vm.stateParams.timeframe.timeframe.end) {
          vm.highlightRange[1] = vm.stateParams.timeframe.timeframe.end;
        } else {
          vm.highlightRange[1] = new Date();
        }
      }
    }
    vm.search_query_str = query;

    InvestigatorService.initQuery(
      vm.bpqlQuery,
      vm.stateParams.environment,
      vm.stateParams.source,
      vm.stateParams.timeframe.timeframe,
      vm.stateParams.sort,
      vm.search_query_str
    );
    vm.nextPage();
  }

  function nextPage() {
    if (!vm.noResults && !vm.loading) {
      vm.loading = true;

      InvestigatorService.getSearchResults(currentPage * vm.pageSize)
        .then(
          (results) => {
            vm.totalResults = results.total;

            if (results.incidents && results.incidents.length) {
              arrayUtils.pushAll(vm.results, results.incidents);
              currentPage += 1;
              last(vm.results).pageSeparator = currentPage + 1;

              if (vm.results.length === vm.totalResults) {
                vm.noResults = true;
              }
            } else {
              vm.noResults = true;
            }
          },
          (error) => {
            $log.error('Error loading historical search results', error);
            vm.noResults = true;
          }
        )
        .finally(() => {
          vm.loading = false;
        });
    }
  }

  function resetFilters() {
    vm.stateParams.environment = get(vm, 'environments.0._id');
    vm.stateParams.source = get(vm, 'integrations.0.value');
    vm.stateParams.timeframe = vm.timeframePresets[4];
    pubSubService.broadcast('investigator.resetTimeFrame');
    InvestigatorService.initQuery(vm.bqplFilter);
    search();
  }

  function sortStatusFirst(tag) {
    if (tag.key === 'status') return -1;
    return 1;
  }

  function normalizeCompletionTag(tagPrefix) {
    const lowercasePrefix = tagPrefix.toLowerCase();
    if (startsWith(lowercasePrefix, '_')) {
      return lowercasePrefix.substring(1);
    }
    return lowercasePrefix;
  }

  const shouldDisableSearch = (environmentId) => {
    const currentEnv = vm.environments.find(({ _id }) => _id === environmentId);
    vm.disabled = isEnvironmentFilteredByIncidentTags(currentEnv, vm.searchWithIncidentTagsFt);
  };

  function updateStateParams(newStateParams, oldStateParams) {
    const oldEffectiveStateParams = {
      ...oldStateParams,
      query: getEffectiveQuery(oldStateParams.query),
    };
    const newEffectiveStateParams = {
      ...newStateParams,
      query: getEffectiveQuery(newStateParams.query),
    };
    if (
      !isEqual(oldEffectiveStateParams, newEffectiveStateParams) &&
      oldEffectiveStateParams.query == newEffectiveStateParams.query
    ) {
      search();
      vm.isDirty =
        vm.stateParams.environment ||
        vm.stateParams.source ||
        vm.stateParams.timeframe.timeframe.interval !== '-7d';
    }
  }

  function autocompleteRefresh(text) {
    return TagsService.getTags().then((tags) => {
      const completions = BPQLCompletionLogicService.validCompletions(text);

      if (!completions.tag) {
        return [];
      }

      const completionPrefix = normalizeCompletionTag(completions.tag);
      if (!completionPrefix) {
        return [];
      }

      let matchingTags = tags.filter((t) => startsWith(t.name, completionPrefix));
      if (vm.stateParams.source) {
        matchingTags = TagsService.getTagsOfSourceSystem(matchingTags, vm.stateParams.source);
      }

      return matchingTags.map((t) => ({
        label: t.name,
        description: uniq(t.sourceSystems.map((s) => s.parentDisplayName)).join(', '),
        prefix: completionPrefix,
      }));
    });
  }

  function autocomplete(string, completion) {
    const completions = BPQLCompletionLogicService.validCompletions(string);
    return string.substring(0, string.length - (completions.tag || '').length) + completion;
  }

  function changeSort(selection) {
    pubSubService.broadcast('incidents.sorting', {
      feature: 'Investigator - sort',
      incidents_sort_type: selection.value,
    });

    vm.stateParams.sort = selection.value;
    SortingStore.updateSortBy(selection.value);
  }

  stateParametersServiceInstance.watchUrlParams(
    {
      environment: stateParametersServiceInstance.urlParamTypes.text,
      source: stateParametersServiceInstance.urlParamTypes.text,
      from: stateParametersServiceInstance.urlParamTypes.date,
      to: stateParametersServiceInstance.urlParamTypes.date,
      query: stateParametersServiceInstance.urlParamTypes.text,
      timeframe: stateParametersServiceInstance.urlParamTypes.timeframe,
      sort: stateParametersServiceInstance.urlParamTypes.sort,
    },
    (scopeParams) => {
      vm.stateParams = scopeParams;
      vm.stateParams.timeframe = vm.stateParams.timeframe || vm.timeframePresets[4];
      vm.stateParams.sort = scopeParams.sort || SortingStore.options.lastChange;
    }
  );

  $scope.$on('$destroy', () => {
    stateParametersServiceInstance.destroy();
    SortingStore.resetSortBy();
  });
}
