import get from 'lodash/get';

angular
  .module('bigpanda.backend.services')
  .service('rateLimitingService', (pubSubService, notificationService, Config) => {
    var currentState;

    pubSubService.on('$stateChangeSuccess', (event, toState) => {
      currentState = toState.name;
    });

    pubSubService.on('Rate.limited', (event, reject) => {
      const millisToTryAgain = get(reject, 'data');
      const secsToTryAgain = millisToTryAgain ? Math.floor(millisToTryAgain / 1000) : 'a few';
      const url = get(reject, 'config.url', '');
      // The login stage do their handling of the errors, also validate that it's bigpanda
      if (
        currentState !== 'change' &&
        url.indexOf(Config.domain) >= 0 &&
        notCountersRateLimit(url)
      ) {
        notificationService.error(`Wow you're going fast! Please wait ${secsToTryAgain} seconds.`);
      }
    });

    function notCountersRateLimit(url) {
      return !url.includes('/web/environments/counters');
    }
  });
