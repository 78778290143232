import actionTypes from './actionTypes';
import { generateBaseSettingsActions } from '../redux.utils';

const actions = generateBaseSettingsActions(actionTypes);

actions.loadApiKeys = () => ({ type: actionTypes.LOAD_API_KEYS });
actions.loadApiKeysSuccess = (payload) => ({
  type: actionTypes.LOAD_API_KEYS_SUCCESS,
  payload: payload,
});
actions.enrichApiKeysSuccess = (payload) => ({
  type: actionTypes.ENRICH_API_KEYS_SUCCESS,
  payload: payload,
});
actions.updateApiKey = (payload) => ({ type: actionTypes.UPDATE_API_KEY, payload: payload });
actions.deleteApiKey = (payload) => ({ type: actionTypes.DELETE_API_KEY, payload: payload });
actions.restoreApiKey = (payload) => ({ type: actionTypes.RESTORE_API_KEY, payload: payload });
actions.createFiltersDropDownItemsSuccess = (payload) => ({
  type: actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS,
  payload: payload,
});
actions.updateFiltersDropDowns = (payload) => ({
  type: actionTypes.UPDATE_FILTERS_DROPDOWNS,
  payload: payload,
});

export default actions;
