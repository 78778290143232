import assign from 'lodash/assign';
angular.module('bigpanda.utils').service('previousStateService', previousStateService);

function previousStateService(
  $state,
  $timeout,
  Config,
  EnvironmentsService,
  EnvironmentsUtils,
  RoutesConstants
) {
  this.saveAndGoto = saveAndGoto;
  this.savePreviousState = savePreviousState;
  this.resetPreviousState = resetPreviousState;
  this.goToPreviousState = goToPreviousState;
  this.getPreviousState = getPreviousState;

  let previousState = null;

  function saveAndGoto(toState, toParams, nextState, nextParams) {
    setPreviousState(toState.name, toParams);
    $state.go(nextState, nextParams);
  }

  function savePreviousState(toState, toParams) {
    setPreviousState(toState.name, toParams);
  }

  function goToPreviousState(delay) {
    return $timeout(() => {
      const prev = previousState;
      resetPreviousState();

      // If the previous state redirects us to the 'no-permissions' environment, we don't want to automaitcally
      // go there, because this time the user might have permissions. We'd rather continue with the regular flow,
      // and if indeed the user still has no permissions, that flow will know to take the user to the correct state
      // anyway.
      if (prev && prev.name && prev.params.environment !== RoutesConstants.NO_PERMISSIONS_ENV) {
        return $state.go(prev.name, prev.params);
      }

      //  Todo - not sure about this - in any case there is no environments from the backend - we will treat it the same
      return EnvironmentsService.get()
        .then(EnvironmentsUtils.getDefaultEnv)
        .then((env) =>
          $state.go('app.overview.incidents.list', {
            environment: env ? env._id : RoutesConstants.NO_PERMISSIONS_ENV,
            folder: env ? 'active' : RoutesConstants.NO_PERMISSIONS_FOLDER,
          })
        );
    }, delay || 0);
  }

  function getPreviousState() {
    return previousState;
  }

  function setPreviousState(name, params) {
    if (name === Config.defaultState) {
      return;
    }

    if (!previousState) {
      previousState = {
        name: name,
        params: assign({}, params),
      };
    }
  }

  function resetPreviousState() {
    previousState = null;
  }
}
