import pick from 'lodash/pick';
import findIndex from 'lodash/findIndex';
import { selectors as userSelectors } from 'react/user/details';
import isEqual from 'lodash/isEqual';

// eslint-disable-next-line import/prefer-default-export
export function isPatternUnique(item, items, keyParams) {
  const pureItem = pick(item, keyParams);
  const pureItems = items.filter(({ id }) => id !== item.id).map((it) => pick(it, keyParams));
  const ideticalItems = pureItems.filter((item) => {
    return (
      item.cross_source === pureItem.cross_source &&
      item.window === pureItem.window &&
      isEqual(item.tags, pureItem.tags) &&
      isEqual(item.query, pureItem.query)
    );
  });
  return ideticalItems.length === 0;
}
export function isItemUnique(item, items, keyParams) {
  const pureItem = pick(item, keyParams);
  const pureItems = items.filter(({ id }) => id !== item.id).map((it) => pick(it, keyParams));
  return findIndex(pureItems, pureItem) === -1;
}

export const generateMetadata = (item, state, description, suggested) => {
  const currentUserId = userSelectors.getUserId(state);
  const currentTime = Math.round(new Date().getTime() / 1000);

  return {
    created_user: currentUserId,
    created_time: currentTime,
    ...item.metadata,
    updated_user: currentUserId,
    updated_time: currentTime,
    description,
    suggested,
  };
};
