import React from 'react';
import VirtualList from 'react-tiny-virtual-list';
import ReactResizeDetector from 'react-resize-detector';
import { sizes } from '@bp/bam';
import PropTypes from 'prop-types';

class SettingsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedId: this.getSelectedId() };
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.setSelectedId);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.setSelectedId);
  }

  setSelectedId = () => {
    this.setState({ selectedId: this.getSelectedId() });
  };

  getSelectedId = () => window.location.hash.split('/').pop();

  isSelected = (item) =>
    item && this.state.selectedId && [item.id, item._id].includes(this.state.selectedId);

  renderItem = ({ index, style }) => {
    const {
      enableConfigurationLabels,
      items,
      listItemComponent: ListItem,
      itemName,
      lastTile,
      sortMode,
    } = this.props;
    const item = items[index];
    const selected = this.isSelected(item);
    return (
      <div style={style} key={index}>
        {items[index] ? (
          <ListItem
            sortMode={sortMode}
            enableConfigurationLabels={enableConfigurationLabels}
            {...{ [itemName]: item }}
            selected={selected}
            firstTile={index === 0}
          />
        ) : (
          lastTile
        )}
      </div>
    );
  };

  render() {
    const { items, lastTile } = this.props;
    const count = lastTile ? items.length + 1 : items.length;
    return (
      <ReactResizeDetector handleWidth handleHeight>
        {({ width, height }) => (
          // We're passing props like selected and items, to make sure the item is re-rendered whenever one of them changes.
          <VirtualList
            width={width}
            height={height || 0}
            overscanCount={20}
            itemCount={count}
            itemSize={parseInt(sizes.tileHeight)}
            onItemsRendered={this.props.loadNext}
            renderItem={this.renderItem}
            selected={this.state.selectedId}
            items={this.props.items}
            role="list"
          />
        )}
      </ReactResizeDetector>
    );
  }
}

export const SortShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool,
});

SettingsList.propTypes = {
  enableConfigurationLabels: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  listItemComponent: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  lastTile: PropTypes.node,
  sortMode: SortShape,
  loadNext: PropTypes.func,
};

SettingsList.defaultProps = {
  enableConfigurationLabels: false,
  items: [],
  sortMode: null,
  lastTile: null,
  loadNext: undefined,
};

export default SettingsList;
