/* eslint-disable react/sort-comp */
/* eslint-disable no-unused-expressions */
/* eslint-disable object-shorthand */
import { hot } from 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import { BamTable, createCustomDateOption, TimeFormats } from '@bp/bam';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import autobind from 'class-autobind';
import cn from 'classnames';
import { selectors as usersSelectors } from 'react/common/enriched_users';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import { loadEnrichedUsers } from 'react/common/enriched_users/actions';
import permissions from 'common/routing/permissions';
import PopupHoc from 'react/utils/hoc/PopupHoc';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import TableSummary from './TableSummary/TableSummary';
import RelatedChangesSearch from './RelatedChangesSearch/RelatedChangesSearch';
import CommentPopup from './CommentPopup/CommentPopup';
import ChangesDetails from './ChangesDetails/ChangesDetails';
import actions from './actions';
import styles from './changes.scss';
import './changes.css';
import {
  calcDetailsPopupStyle,
  normalizeArrayToObject,
  normalizeColumns,
  staticColumns,
  RELATED_METADATA_COL_ID,
  DEFAULT_MAX_DYNAMIC_TAG,
  parser,
  BpqlParseError,
  BpqlValue,
} from './Consts';
import { createChangesDropDownOptions } from './ChangesDropDown/Consts';

const { momentOrEpochRangeRelativeTimeFrameFormat } = TimeFormats;
const FIXED_OFFSET = 16;
const getNextColumns = (columns, staticCols, maxTags = DEFAULT_MAX_DYNAMIC_TAG) => {
  const staticIdsSet = new Set(staticCols.map((col) => col.id));
  const dynamicTags = columns.filter((col) => !staticIdsSet.has(col.id));
  return [...staticCols, ...dynamicTags.slice(0, maxTags)];
};
const roleNames = (roles = []) => roles.map((role) => role.name).join(',');

export class RelatedChanges extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      featureToggles,
      incident: { start: incidentStartTime },
      dateRange: selectedDateOption,
      rccToggleDefault,
    } = this.props;
    this.hook = {};
    const changes = [];
    const fullCols = normalizeColumns(changes, this.getStaticColumns());
    const nextColumns = getNextColumns(
      fullCols,
      this.getStaticColumns(),
      get(featureToggles, 'maxDynamicTags', DEFAULT_MAX_DYNAMIC_TAG)
    );
    const timeFrameOptions = createChangesDropDownOptions(incidentStartTime);

    const timeFrameWithPersistency = selectedDateOption || timeFrameOptions[0];
    const { from: startTimeFrame, to: endTimeFrame } = timeFrameWithPersistency;
    timeFrameOptions.push(
      createCustomDateOption({
        from: timeFrameWithPersistency.from,
        to: timeFrameWithPersistency.to,
      })
    );

    this.state = {
      fullCols,
      hooks: {},
      columns: nextColumns,
      searchText: this.props.search || '',
      timeFrameOptions: timeFrameOptions,
      selectedDateOption: selectedDateOption,
      selectedDescription: momentOrEpochRangeRelativeTimeFrameFormat({
        from: startTimeFrame.unix(),
        to: endTimeFrame.unix(),
      }),
      startTimeFrame: startTimeFrame.unix(),
      endTimeFrame: endTimeFrame.unix(),
      errorMessage: '',
    };
    this.relatedChangesHeaderRef = React.createRef();
    this.selectedRowRef = React.createRef();
    autobind(this, RelatedChanges.prototype);
    this.onToggleChange(rccToggleDefault);
  }

  calcTableHeight = () => {
    const { embed } = this.props;
    if (!embed) {
      const tableRectBottom =
        this.relatedChangesHeaderRef &&
        this.relatedChangesHeaderRef.current &&
        this.relatedChangesHeaderRef.current.getBoundingClientRect().bottom
          ? this.relatedChangesHeaderRef.current.getBoundingClientRect().bottom
          : 0;

      requestAnimationFrame(() => {
        const tableHeight = this.documentElement.clientHeight - tableRectBottom - FIXED_OFFSET;

        this.setState({ tableHeight });
      });
    }
  };

  componentDidMount() {
    const { users } = this.props;
    this.documentElement = document.documentElement;
    this.calcTableHeight();
    window.addEventListener('resize', this.calcTableHeight);

    if (!users) {
      this.props.loadEnrichedUsers();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { users: prevUsers, changes: prevChanges } = prevProps;
    const { featureToggles, users, changes } = this.props;
    const { columns } = this.state;

    if (
      prevState.hooks.setPopupProps !== this.state.hooks.setPopupProps ||
      prevChanges !== changes
    ) {
      const fullCols = normalizeColumns(changes, columns);
      const nextColumns = getNextColumns(
        fullCols,
        this.getStaticColumns(),
        get(featureToggles, 'maxDynamicTags', DEFAULT_MAX_DYNAMIC_TAG)
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        columns: nextColumns,
        fullCols,
      });
    } else if (prevUsers !== users) {
      const relatedMetaCol = this.getStaticColumns().find(
        (col) => col.id === RELATED_METADATA_COL_ID
      );
      const nextCols = columns.map((col) => {
        if (col.id === relatedMetaCol.id) {
          return relatedMetaCol;
        }
        return col;
      });
      this.setState({ columns: nextCols }); // eslint-disable-line
    }

    this.tableRef = document.querySelector('#relatedChangesTable .rt-table');
  }

  componentWillUnmount() {
    clearTimeout(this.tableNotificationTimer);
    window.removeEventListener('resize', this.calcTableHeight);
  }

  onTimeFrameChanged(selectedOption) {
    const { from, to } = selectedOption;
    const { getData, nextLink, incident, checked } = this.props;
    const {
      startTimeFrame: stateStartTimeFrame,
      endTimeFrame: stateEndTimeFrame,
      searchText,
    } = this.state;
    const unixStartTime = from && from.unix();
    const unixEndTime = to && to.unix();
    const isStartTimeChanged = unixStartTime && unixStartTime !== stateStartTimeFrame;
    const isEndTimeChanges = unixEndTime && unixEndTime !== stateEndTimeFrame;

    if (isStartTimeChanged || isEndTimeChanges) {
      const selectedDescription = momentOrEpochRangeRelativeTimeFrameFormat({
        from: unixStartTime,
        to: unixEndTime,
      });

      this.setState(
        {
          startTimeFrame: unixStartTime,
          endTimeFrame: unixEndTime,
          selectedDescription: selectedDescription,
        },
        () => {
          getData({
            checked,
            bpqlQuery: this.bpqlParser(searchText).value,
            nextLink,
            incident,
            searchText,
            endTimeFrame: unixEndTime,
            startTimeFrame: unixStartTime,
          });
        }
      );

      this.props.changeDateRange(selectedOption);
    }
  }

  onColumnChanged(columns) {
    this.setState({ columns });
  }

  onToggleChange = (checked) => {
    const { getData, nextLink, incident, changeToggleValue } = this.props;
    const { searchText, endTimeFrame, startTimeFrame } = this.state;
    changeToggleValue(checked);
    getData({
      toggleChecked: checked,
      bpqlQuery: this.bpqlParser(searchText).value,
      nextLink,
      incident,
      searchText,
      endTimeFrame,
      startTimeFrame,
    });
  };

  onPopupClose = () => this.setState({ lastChangeIdSelected: undefined });

  getStaticColumns() {
    const {
      user,
      users,
      userChangesPermissions = [],
      incident: { id, start },
    } = this.props;
    return staticColumns({
      users,
      incidentId: id,
      user,
      userChangesPermissions,
      disabled: !userChangesPermissions.includes(permissions.changes.update),
      setPopupProps: this.hook.setCommentPopupProps,
      incidentStartTime: start,
    });
  }

  getTableProps = () => ({ onScroll: this.scrolledToBottom });

  getTrProps = (state, rowInfo = {}) => {
    const { lastChangeIdSelected } = this.state;
    const { row } = rowInfo;
    if (row && row.key === lastChangeIdSelected) {
      return {
        className: styles.selectedRow,
        ref: this.selectedRowRef,
      };
    }
    return {};
  };

  closeNotification = () => {
    this.setState({ notificationOpen: false, notificationContent: undefined });
  };

  handleSearchOnClear() {
    this.setState({ errorMessage: '' }, () => this.handleSearch(''));
  }

  handleNotificationOnClose = () => {
    clearTimeout(this.tableNotificationTimer);
    this.closeNotification();
  };

  bpqlParser = (input) => {
    const fixedInput = input.replace(/\bkey=/gi, 'identifier='); // this is a fix for the rcc-search service to fix the tag
    try {
      return input === '' ? new BpqlValue(fixedInput) : new BpqlValue(parser(fixedInput));
    } catch (e) {
      return new BpqlParseError(e.message);
    }
  };

  handleSearch(inputText) {
    const { changeSearch, getData, nextLink, incident, checked, searchBpql } = this.props;
    const { searchText, endTimeFrame, startTimeFrame } = this.state;
    const newSearch = searchText !== inputText;

    if (!newSearch) {
      return;
    }
    const bpqlQuery = searchBpql ? this.bpqlParser(inputText) : '';
    if (bpqlQuery instanceof BpqlParseError) {
      this.setState({ errorMessage: bpqlQuery.message });
      return;
    }
    this.setState({ searchText: inputText, errorMessage: '' }, () => {
      getData({
        checked,
        bpqlQuery: bpqlQuery.value,
        nextLink,
        incident,
        searchText: inputText,
        endTimeFrame,
        startTimeFrame,
      });
    });

    changeSearch(inputText);
  }

  hookToApi = (hooks) => {
    this.setState({ hooks });
    this.hook = { ...this.hook, ...hooks };
  };

  openChangeDetailsPopup = ({ row } = {}, e) => {
    const { users, setPopupProps: setDetailsPopupProps } = this.props;
    const matchingUser = users[get(row, 'relatedMetadata.user_id')];
    const { lastChangeIdSelected, fullCols: columns } = this.state;
    // in case the same row clicked close the popup.
    if (lastChangeIdSelected === row.key) {
      return this.onPopupClose();
    }
    // the popup is mounted using a portal which is listening for clicks outside so this will prevent the popup to open then close immediately.
    e.nativeEvent.stopImmediatePropagation();
    requestAnimationFrame(() => {
      const { clientHeight } = this.documentElement;
      const { left: tableLeft } = this.tableRef.getBoundingClientRect();
      const popupProps = {
        onClose: this.onPopupClose,
        style: calcDetailsPopupStyle(clientHeight, tableLeft),
        eventsEnabled: false,
        open: true,
        children: (
          <ChangesDetails
            onClose={() => setDetailsPopupProps({ open: false })}
            columns={columns}
            row={row}
            user={matchingUser}
          />
        ),
        placement: 'left-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport',
            padding: 15,
          },
          offset: {
            enabled: true,
            offset: '-20px',
          },
          flip: {
            enabled: false,
          },
        },
      };
      this.setState({ lastChangeIdSelected: row.key }, () => {
        const referenceElement = ReactDOM.findDOMNode(this.selectedRowRef.current).firstChild; // eslint-disable-line
        setDetailsPopupProps({ ...popupProps, referenceElement });
      });
    });
  };

  requestMoreChanges() {
    const { nextLink, loading, getData, incident } = this.props;
    if (nextLink && !loading) {
      getData({ nextLink, useNextLink: true, incident });
    }
  }

  scrolledToBottom(event) {
    const scrollAheadDistance = 200;

    if (
      event.target.scrollHeight - event.target.clientHeight <=
      event.target.scrollTop + scrollAheadDistance
    ) {
      this.requestMoreChanges();
    }
  }

  scrollToTableTop = () => this.tableRef.scrollTo(0, 0);

  triggerNotificationReposition = () => {
    const repositionHook = get(this.hook, 'repositionTableNotification');
    repositionHook && repositionHook();
  };

  notificationOnClick = () => {
    this.scrollToTableTop();
    this.closeNotification();
  };

  upsertRelatedMetadata = (data) => {
    if (!data) {
      return;
    }
    const { upsertChangesMetadata } = this.props;
    upsertChangesMetadata(data);
  };

  render() {
    const {
      popupProps,
      columns,
      timeFrameOptions,
      selectedDescription,
      selectedDateOption,
      notificationOpen,
      notificationContent,
      lastChangeIdSelected,
      searchText,
      tableHeight,
      errorMessage,
    } = this.state;

    const {
      changes,
      loading,
      total,
      rccOnly,
      totalRelatedCount,
      tableOnlyMode,
      checked,
      searchBpql,
      summaryTextContent,
      featureToggles,
    } = this.props;

    const disableQueryHelper = get(featureToggles, 'disable_query_helper', false);

    const disableSearch = checked && searchBpql;

    const MIN_FILTER_LENGTH_SEARCH = 10;
    return (
      <ThemeProvider theme={darkTheme}>
        <div id="related-changes-react" className={cn(styles.relatedChangesWrapper, 'bam')}>
          {!tableOnlyMode && (
            <div ref={this.relatedChangesHeaderRef} className={styles.relatedChangesHeader}>
              <RelatedChangesSearch
                onClearSearchText={this.handleSearchOnClear}
                onSearch={this.handleSearch}
                onTimeFrameChanged={this.onTimeFrameChanged}
                options={timeFrameOptions}
                defaultOption={selectedDateOption}
                defaultSearchInputText={searchText}
                disable={disableSearch}
                errorMessage={errorMessage}
                bpqlSearch={searchBpql}
                disableQueryHelper={disableQueryHelper}
                {...this.props}
              />
              <TableSummary
                numOfResult={rccOnly ? totalRelatedCount : total || 0}
                description={selectedDescription}
                totalRelatedCount={totalRelatedCount}
                onToggleChange={this.onToggleChange}
                toggleValue={checked}
                loading={loading}
                minimalOptionsLengthForSearch={MIN_FILTER_LENGTH_SEARCH}
                textContent={summaryTextContent}
                rccMode={rccOnly}
              />
            </div>
          )}
          <div
            className={`${styles.relatedChangesTableWrapper} ${
              !this.state.nextLink ? styles.fullyLoaded : ''
            }`}
            style={{ height: `${tableHeight}px` }}
          >
            {tableOnlyMode && changes.length === 0 ? (
              <div className={styles.empty}>
                <span>No related changes</span>
              </div>
            ) : (
              <BamTable
                autofit
                lastChangeIdSelected={lastChangeIdSelected}
                id="relatedChangesTable"
                loading={loading > 0}
                data={changes}
                columns={columns}
                onColumnsChanged={this.onColumnChanged}
                getTableProps={this.getTableProps}
                getTrProps={this.getTrProps}
                notificationOpen={notificationOpen}
                notificationContent={notificationContent}
                hook={this.hookToApi}
                onRowClicked={this.openChangeDetailsPopup}
              />
            )}
          </div>
          <CommentPopup
            {...popupProps}
            upsertRelatedMetadata={this.upsertRelatedMetadata}
            hook={this.hookToApi}
          />
        </div>
      </ThemeProvider>
    );
  }
}

RelatedChanges.propTypes = {
  incident: PropTypes.shape({ start: PropTypes.number, id: PropTypes.string }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  enrichedUser: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  }),
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
  }).isRequired,
  loadEnrichedUsers: PropTypes.func.isRequired,
  upsertChangesMetadata: PropTypes.func.isRequired,
  changeDateRange: PropTypes.func.isRequired,
  changeSearch: PropTypes.func.isRequired,
  totalRelatedCount: PropTypes.number,
  userChangesPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.shape({ selectedDescription: PropTypes.string }),
  search: PropTypes.string,
  users: PropTypes.shape({}),
  loading: PropTypes.number,
  changes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number,
  nextLink: PropTypes.string,
  setPopupProps: PropTypes.func.isRequired,
  featureToggles: PropTypes.shape({}).isRequired,
  tableOnlyMode: PropTypes.bool,
  embed: PropTypes.bool,
  rccToggleDefault: PropTypes.bool,
  getData: PropTypes.func.isRequired,
  searchBpql: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  changeToggleValue: PropTypes.func.isRequired,
  summaryTextContent: PropTypes.shape({}),
  rccOnly: PropTypes.bool,
  statePath: PropTypes.string,
};

RelatedChanges.defaultProps = {
  users: undefined,
  enrichedUser: { roles: [] },
  total: 0,
  nextLink: undefined,
  loading: false,
  search: undefined,
  dateRange: undefined,
  tableOnlyMode: false,
  embed: false,
  totalRelatedCount: 0,
  rccToggleDefault: false,
  searchBpql: false,
  rccOnly: false,
  summaryTextContent: null,
  statePath: null,
};

const mapStateToProps = (state, props) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user', {});
  const changesStore = get(state, props.statePath || 'modules.changes');
  const usersArr = usersSelectors.getEnrichedUsers(state) || undefined;
  const enrichedUser = usersSelectors.getEnrichedUserById(state, user.bigpanda_user_id);
  const userChangesPermissions = (
    permissionsSelectors.getPermissionsList(state) || []
  ).filter((permission) => permission.includes('changes'));

  return {
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
    users: usersArr && normalizeArrayToObject(usersArr, '_id'),
    organization,
    user,
    enrichedUser,
    userChangesPermissions,
    ...changesStore,
  };
};

export default connect(mapStateToProps, {
  loadEnrichedUsers: loadEnrichedUsers,
  upsertChangesMetadata: actions.upsertChangesMetadata,
  changeSearch: actions.changeSearch,
  changeDateRange: actions.changeDateRange,
  changeToggleValue: actions.changeToggleValue,
})(hot(module)(PopupHoc(RelatedChanges)));
