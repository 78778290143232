angular.module('bigpanda.backend.services').factory('ReportSnapshotsBackendService', ($http) => {
  function createSnapshot(reportId, overrides) {
    return $http
      .post('/web/report-snapshots', { reportId: reportId, overrides: overrides || {} })
      .then((res) => res.data.item.url);
  }

  return {
    createSnapshot: createSnapshot,
  };
});
