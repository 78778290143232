const { bpToggleDropdown } = require('angularjs/widgets/bp_toggle_dropdown/bp.toggle.dropdown.js');

angular.module('bigpanda').directive('snoozeButton', snoozeButton);

function snoozeButton() {
  return {
    restrict: 'E',
    templateUrl: 'overview/incidents/list/widgets/snooze_button/snooze_button',
    require: {
      checkedIncidentsState: '?^^checkedIncidentsState',
    },
    scope: {
      freezeRow: '&',
      incidentId: '<',
      snoozeState: '@',
      disableClick: '=?',
      isActionsV2: '<',
    },
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(
    $scope,
    $element,
    $timeout,
    $window,
    $dropdown,
    $state,
    $interval,
    SnoozeStore,
    stateService
  ) {
    const vm = this;

    let dropdown;
    let snoozeInterval;
    let snoozeTimestamp;
    let isCancelActive;

    vm.snoozeProps = {};
    vm.snoozeTestId = `Snooze_list_button`;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;
    vm.$onDestroy = onDestroy;

    function onInit() {
      if (!vm.checkedIncidentsState) {
        vm.checkedIncidentsState = {
          subscribe: angular.noop,
          isAnythingChecked: () => false,
        };
      }

      onChanges();
      SnoozeStore.subscribe($scope, {
        storeUpdated: updateSnoozedView,
      });

      vm.checkedIncidentsState.subscribe($scope, {
        storeUpdated: updateDisabled,
      });

      $element.click(onClick);
    }

    function onChanges() {
      vm.snoozeProps['bp-disabled'] = true;

      if (vm.incidentId) {
        updateSnoozedView().then(updateDisabled);
      }
    }

    function updateSnoozedView() {
      return SnoozeStore.getSnoozeData(vm.incidentId).then((fetchedSnoozeData) => {
        snoozeTimestamp = fetchedSnoozeData ? fetchedSnoozeData.timestamp : null;
        isCancelActive = fetchedSnoozeData ? fetchedSnoozeData.incident_updated : null;
        vm.snoozeProps.snoozed = !!snoozeTimestamp;

        updateTooltip();
      });
    }

    function onClick(e) {
      if (vm.snoozeProps['bp-disabled']) {
        return;
      }
      if (!snoozeTimestamp) {
        rescheduleAfterIncidentSelected();
      } else {
        e.stopPropagation();
        const dropdownScope = $scope.$new();

        dropdownScope.snoozeSelectorVm = {
          schedule: schedule,
          cancelSnooze: cancelSnooze,
        };

        dropdown = bpToggleDropdown(
          dropdown,
          $element,
          dropdownScope,
          'overview/incidents/actions/snooze_selector',
          $window,
          $dropdown,
          null,
          dropdownOpened
        );
      }
    }

    function dropdownOpened(open) {
      vm.freezeRow({ freeze: open });
    }

    // If the user has selected an incident and is now clicking on the snooze button of a different incident,
    // the schedule function must occur only after the incident has already been selected and this is why we use
    // $timeout here
    function rescheduleAfterIncidentSelected() {
      $timeout(() => schedule({ notify: false }), 0);
    }

    function schedule(options) {
      $state.go(
        vm.snoozeState || 'app.overview.incidents.incident-info.snooze',
        {
          incidentId: vm.incidentId,
        },
        options
      );
    }

    function cancelSnooze() {
      SnoozeStore.unsnooze(stateService.getSelectedEnvironmentId(), [vm.incidentId]);
    }

    function onDestroy() {
      $interval.cancel(snoozeInterval);
    }

    function updateTooltip() {
      $interval.cancel(snoozeInterval);
      if (snoozeTimestamp) {
        snoozeInterval = $interval(updateActiveTooltip, 60000);
        updateActiveTooltip();
      } else {
        vm.snoozeTooltip = vm.disableClick ? null : 'Snooze incident';
      }
    }

    function updateActiveTooltip() {
      if (isCancelActive) {
        vm.snoozeTooltip = `Activated ${moment
          .unix(snoozeTimestamp)
          .fromNow()} or on critical updates`;
      } else {
        vm.snoozeTooltip = `Activated ${moment.unix(snoozeTimestamp).fromNow()}`;
      }
    }

    function updateDisabled() {
      const isDisabled = vm.checkedIncidentsState.isAnythingChecked();
      vm.snoozeProps['bp-disabled'] = isDisabled || vm.disableClick;
      vm.snoozeProps['quick-action-button-with-border'] =
        vm.snoozeProps.snoozed || !vm.disableClick;
    }
  }
}
