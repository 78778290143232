import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import extend from 'lodash/extend';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

angular.module('bigpanda').service('CorrelationActions', CorrelationActions);

function CorrelationActions(
  $q,
  $log,
  $rootScope,
  $state,
  ContactsStore,
  ModalService,
  PersonalSettingsStore,
  BPQLService,
  notificationService
) {
  this.itemDeletedNotification = itemDeletedNotification;
  this.confirmDeleteItem = confirmDeleteItem;
  this.confirmToggleItem = confirmToggleItem;
  this.setMetadata = setMetadata;
  this.getQueryDisplayVal = getQueryDisplayVal;
  this.getInvolvedUserNames = getInvolvedUserNames;
  this.setErrorDebounce = setErrorDebounce;
  this.getStateData = getStateData;
  this.escapeRoute = escapeRoute;
  this.closePopup = closePopup;
  this.onlyIfUnique = onlyIfUnique;

  function getInvolvedUserNames(_id) {
    if (_id === 'system-generated') {
      return $q.when('System');
    }
    return ContactsStore.getContactByUserId(_id).then((contact) => {
      if (!contact) {
        return 'n/a';
      }
      return contact.name || contact.email;
    });
  }

  function getQueryDisplayVal(pureQuery) {
    const hasQuery = pureQuery && !isEmpty(pureQuery);
    if (hasQuery) {
      return BPQLService.reverse(pureQuery);
    }
    return null;
  }

  function itemDeletedNotification(text, stateName) {
    const scope = $rootScope.$new();
    const modalScope = extend(scope, {
      dialogType: 'warning-dialog',
      dialogTitle: 'Item Was Deleted',
      titleIconClass: 'bp-icon-warning',
      text: text,
      cancelButtonCaption: 'Close',
      submitButtonCaption: null,
    });

    ModalService.showModal({
      scope: modalScope,
      templateUrl: 'settings/correlation/utils/prompt/configuration_changes_prompt',
      controller: [
        '$scope',
        ($scope) => {
          $scope.configChangeText = text;
        },
      ],
      controllerAs: 'vm',
      bindToController: true,
    }).finally(() => $state.go(`app.settings.${stateName}`));
  }

  function confirmDeleteItem(item, text, callback) {
    confirmAction(item, {
      type: 'deletion-dialog',
      icon: 'bp-icon-trash',
      contentType: 'delete',
      cancelText: 'Cancel',
      submitText: 'Delete',
      title: text.title,
      text: text.content,
      callback,
    });
  }

  function confirmToggleItem(item, text, callback) {
    if (item.active) {
      confirmAction(item, {
        type: 'data-dialog',
        icon: 'bp-icon-power-off',
        contentType: 'toggle',
        cancelText: 'Cancel',
        submitText: 'Deactivate',
        title: text.title,
        text: text.content,
        callback,
      });
    } else {
      callback(item);
    }
  }

  function confirmAction(item, opts) {
    const scope = $rootScope.$new();
    scope.dialogType = opts.type;
    scope.dialogTitle = opts.title;
    scope.titleIconClass = opts.icon;
    scope.text = opts.text;
    scope.cancelButtonCaption = opts.cancelText;
    scope.submitButtonCaption = opts.submitText;
    scope.preSave = () => opts.callback(item);
    const modalParams = {
      scope,
      templateUrl: 'settings/correlation/utils/prompt/correlation_prompt',
      controller: [
        '$scope',
        ($scope) => {
          $scope.item = item;
          $scope.contentType = opts.contentType;
        },
      ],
    };
    return ModalService.showModal(modalParams);
  }

  function setMetadata(item) {
    return PersonalSettingsStore.getUser().then((user) => {
      const currentTime = Math.round(new Date().getTime() / 1000);

      item.metadata = item.metadata || {};
      const metadata = {
        created_user: item.metadata.created_user || user._id,
        created_time: item.metadata.created_time || currentTime,
        updated_user: user._id,
        updated_time: currentTime,
      };

      merge(item.metadata, metadata);

      return item;
    });
  }

  function setErrorDebounce(callback) {
    return debounce(callback, 2500, {
      leading: false,
      trailing: true,
    });
  }

  function getStateData(type, modes) {
    const name = type === 'tag' ? 'Custom Tag' : 'Correlation Pattern';
    const shortName = type === 'tag' ? 'Tag' : 'Pattern';
    if (modes.edit) {
      return {
        title: `Edit ${name}`,
        actionText: `Update ${shortName}`,
        iconClass: 'bp-icon-edit',
      };
    } else if (modes.duplicate) {
      return {
        title: `Duplicate ${name}`,
        actionText: `Duplicate ${shortName}`,
        iconClass: 'bp-icon-duplicate',
      };
    }
    return {
      title: `Create a New ${name}`,
      actionText: `Create ${shortName}`,
      iconClass: 'bp-icon-plus-icon',
    };
  }

  function escapeRoute(errMsg, toRoute) {
    const err = errMsg;
    $state.go(toRoute);
    notificationService.error(err, null);
    $log.error(err);
  }

  function closePopup(route, params, preHook) {
    if (preHook) {
      preHook();
    }
    if (params.tagId || params.correlationId) {
      $state.go(`${route}.info`, params);
    } else {
      $state.go(route);
    }
  }

  function onlyIfUnique(item, items, keyParams) {
    const pureItem = pick(item, keyParams);
    let pureItems = filter(items, ({ id }) => id !== item.id);
    pureItems = map(pureItems, (listItem) => pick(listItem, keyParams));

    const isUnique = !pureItems.some((listItem) => isEqual(listItem, pureItem));
    return isUnique && item;
  }
}
