angular.module('bigpanda.utils').factory('BeforeUnloadService', ($window) => {
  var counter = 0;

  return {
    start: function () {
      if (counter++ === 0) {
        $window.onbeforeunload = function () {
          return 'Your changes have not been saved. If you exit now, some changes may be lost';
        };
      }
    },
    end: function () {
      if (--counter === 0) {
        $window.onbeforeunload = null;
      }
    },
  };
});
