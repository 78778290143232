import find from 'lodash/find';
angular.module('bp.widgets').directive('bpSelectionMenu', bpSelectionMenu);

/*
   bp-selection-menu(
    options="[{
      display: 'String'
      value: 'someValue'
      btnText: 'String' // optional
    }]"
    icon-name="iconCssName"
    options-title="String"
    options-title-tooltip="path/to/your/tooltip_html_template" // optional
    selected-value="selectedValue",
    left-offset="number" // uses to horizontal align to md-content-menu
    callback="callbackFnc({ selection: option.value })"
   )
*/

function bpSelectionMenu() {
  return {
    restrict: 'E',
    scope: {
      options: '<',
      selectedValue: '<',
      iconName: '@',
      arrowIcon: '@',
      optionsTitle: '@',
      optionsTitleTooltip: '@',
      leftOffset: '@',
      callback: '&',
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'widgets/bp_selection_menu/bp_selection_menu',
    controller: controller,
  };

  function controller() {
    const vm = this;
    this.$onInit = initOption;
    this.$onChanges = initOption;

    function initOption() {
      vm.option = find(vm.options, { value: vm.selectedValue });
    }
  }
}
