/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import * as colors from '@bp/pastel/colors';

export const Wrapper = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  padding: ${({ inline }) => (inline ? '7px 7px 7px 10px' : '7px 0 7px 10px')};
  color: ${colors.bp_gray_08};
  cursor: pointer;
  &:hover {
    background-color: ${colors.bp_gray_13};
  }
  &.select-item-wrapper[aria-selected="true"] {
    background-color: ${colors.bp_gray_13};
  }
`;

export const Icon = styled.i`
  width: 16px;
  height: 16px;
  font-size: 16px;
  margin-right: 2px;
  line-height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  color: ${colors.bp_gray_05};
`;

export const Label = styled.div`
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const CheckIcon = styled.i`
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 16px;
  color: ${colors.bp_gray_08};
  font-size: 10px;
  display: flex;
`;

export const AddIcon = styled.i`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  color: ${colors.bp_blue};
  margin-right: 8px;
  margin-left: 16px;
  font-size: 16px;
  display: flex;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 2px 0;
  border: 1px solid ${colors.bp_gray_03};
`;
