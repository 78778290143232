angular.module('bigpanda.utils').service('CompatibilityService', CompatibilityService);

function CompatibilityService() {
  this.canClosePopupsWithEscape = canClosePopupsWithEscape;
  this.canClosePopupsWithBackdropClick = canClosePopupsWithBackdropClick;
  this.shouldAddCssOnBodyForPrint = shouldAddCssOnBodyForPrint;

  function canClosePopupsWithEscape() {
    return !isExplorer();
  }

  function canClosePopupsWithBackdropClick() {
    return !isExplorer();
  }

  function shouldAddCssOnBodyForPrint() {
    return isFirefox();
  }

  function isExplorer() {
    return navigator.userAgent && navigator.userAgent.indexOf('Trident') >= 0;
  }

  function isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }
}
