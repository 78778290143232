import {
  AllSizes,
  MlSuggestedIcon,
  SCHBox,
  Text,
} from '@bp/kung-fu';
import React from 'react';
import styled from 'styled-components';

import CorrelationPatternSuggestionsStripProps from './CorrelationPatternSuggestionsStripProps';

function getStripText(count: number): string {
  return `${count} Suggested patterns from BigPanda AI`;
}

const Container = styled(SCHBox)`
  height: 36px;
  background-color: #F4EDFB;
  justify-content: space-between;
  padding-inline-end: 9px;
  padding-inline-start: 13.5px;
  cursor: pointer;
`;

const IconContainer = styled(SCHBox)`
  margin-inline-end: 9px;  
`;

function CorrelationPatternSuggestionsStrip({
  count,
  onClick,
}: CorrelationPatternSuggestionsStripProps): JSX.Element {
  return (
    <Container
      onClick={onClick}
      aria-label="Open pattern suggestions modal"
    >
      <SCHBox>
        <IconContainer>
          <MlSuggestedIcon size={AllSizes.XLARGE} color={(p): string => p.theme.bp_purple} />
        </IconContainer>
        <Text size={AllSizes.SMALL} color={(p): string => p.theme.bp_purple} weight="bold">
          {getStripText(count)}
        </Text>
      </SCHBox>
      <Text size={AllSizes.SMALL} color={(p): string => p.theme.bp_gray_07} weight="bold">
        View Suggestions
      </Text>
    </Container>
  );
}

export default CorrelationPatternSuggestionsStrip;
