angular.module('bigpanda').constant('STATUS_COLORS', [
  { name: 'Critical', color: '#ed002c', breakdown: true, order: 0, values: ['critical'] },
  { name: 'Warnings', color: '#ff7d00', breakdown: true, order: 1, values: ['warning'] },
  { name: 'Resolved', color: '#83c31d', breakdown: true, order: 2, values: ['ok'] },
  {
    name: 'Ack',
    color: '#cacdbf',
    breakdown: true,
    order: 3,
    values: ['acknowledged', 'maintenance', 'nostatus'],
  },
  { name: 'Oksuspect', color: '#acc537', values: ['oksuspect'] },
  { name: 'Warningsuspect', color: '#f3a000', values: ['warningsuspect'] },
  { name: 'Criticalsuspect', color: '#f0453b', values: ['criticalsuspect'] },
  { name: 'Unknown', color: '#f7cb00', values: ['unknown'] },
]);

angular.module('bigpanda').constant('STATUS_RANKS', {
  UNDEFINED: 0,
  critical: 1,
  warning: 2,
  unknown: 3,
  ok: 4,
  criticalsuspect: 5,
  warningsuspect: 6,
  unknownsuspect: 7,
  oksuspect: 8,
  nostatus: 9,
  acknowledged: 9,
  maintenance: 9,
});
