/* eslint-disable object-shorthand */
/* eslint-disable react/sort-comp */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import lowerCase from 'lodash/lowerCase';
import getTopologiesCustomLinks from 'common/endpoints/topology';
import { bp_blue } from '@bp/pastel/colors';
import {
  Spinner,
  lightTheme,
  darkThemeV2,
  Button,
  FullscreenIcon,
  RefreshIcon,
  HelpTooltip,
} from '@bp/kung-fu';
import { BamTopology, BamModal, BamToggle } from '@bp/bam';
import {
  Wrapper,
  TopologyContainer,
  FiltersWrapper,
  FullScreenDiv,
} from './IncidentTopology.style';
import styles from './topology.scss';
import {
  incidentToNodesAndLinks,
  loopIteration,
  getKeyFromTypes,
  buildTopologyData,
} from './logic/logic';
import {
  defaultColors,
  dynamicColors,
  iconsMap,
  fallbackIcons,
  typesMap,
  graphConfig,
  toggleHelper,
} from './consts';
// should move all temp mapping inside the component state, so I could write propper tests to it.
import FilterCheckboxes from './FilterCheckboxes/FilterCheckboxes';

const tempColorsMap = {};
const tempIconsMap = {};

const getTooltipContent = (type, value) => `${lowerCase(type)}: ${value}`;
const colorsIterator = loopIteration(dynamicColors);
const fallbackIconIterator = loopIteration(fallbackIcons);

const getIcon = (type, iconsM, typesM) => {
  const iconKey = getKeyFromTypes(typesM, type);
  let icon;
  if (iconsM[iconKey]) {
    icon = iconsM[iconKey];
  } else if (tempIconsMap[iconKey || type]) {
    icon = tempIconsMap[iconKey || type];
  } else {
    icon = fallbackIconIterator.next().value;
    tempIconsMap[iconKey || type] = icon;
  }
  return icon;
};

const generateNodeColorFromType = (type, typesM, defColors) => {
  const key = getKeyFromTypes(typesM, type);
  let color;
  if (defColors[key]) {
    color = defColors[key];
  } else if (tempColorsMap[key || type]) {
    color = tempColorsMap[key || type];
  } else {
    color = colorsIterator.next().value;
    tempColorsMap[key || type] = color;
  }
  return color;
};

const convertNodesAndLinksToData = ({ nodes, links }) => {
  const inactiveColor = styles.bp_gray_05;
  const activeColor = styles.bp_red;
  return {
    nodes: nodes.map(
      ({
        id,
        label,
        group,
        badgeNumber,
        tooltipContent: { nodeType, type, value },
        activeStates,
      }) => ({
        id: id,
        group: group,
        customLabel: label.toString(),
        ...(badgeNumber ? { badgeNumber } : {}),
        tooltipContent: getTooltipContent(type, value, nodeType),
        icon: getIcon(type, iconsMap, typesMap),
        nodeClass: generateNodeColorFromType(type, typesMap, defaultColors),
        badgeColor: activeStates.every((elm) => !elm) ? inactiveColor : activeColor,
      })
    ),
    links: links,
  };
};

class IncidentTopology extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentIncident: null,
      hiddenNodeGroups: {},
      hideInactive: true,
      postMsgSent: false,
    };

    this.colorsIterator = colorsIterator;
    this.fallbackIconIterator = fallbackIconIterator;
  }

  setCurrentIncidet = (incident) => this.setState({ currentIncident: incident, loading: false });

  shouldComponentUpdate(nextProps, nextState) {
    const { hiddenNodeGroups, currentIncident, hideInactive } = this.state;
    const { incident } = nextProps;
    if (!currentIncident && incident) {
      this.setCurrentIncidet(incident);
      return true;
    }
    if (!isEqual(hiddenNodeGroups, nextState.hiddenNodeGroups)) {
      return true;
    }

    if (nextState.hideInactive !== hideInactive) return true;

    return false;
  }

  async componentDidMount() {
    if (this.props.iframeMode) {
      const widget = document.querySelector('.intercom-lightweight-app');
      if (widget) {
        widget.style.display = 'none';
      }
    }
    const { links } = await getTopologiesCustomLinks();
    this.setLinksToState(links);
    this.showFullInactiveIncident(this.props.incident);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { incident } = props;
    this.setCurrentIncidet(incident);
    this.showFullInactiveIncident(incident);
  }

  setLinksToState = (links) => this.setState({ links, loading: false });

  componentWillUnmount() {
    this.colorsIterator.next(true);
    this.fallbackIconIterator.next(true);
  }

  removeHiddenNodes = (nodesAndLinks) => {
    const { hiddenNodeGroups } = this.state;
    const { nodes, links } = nodesAndLinks;
    const filteredNodes = nodes.filter((node) => !hiddenNodeGroups[node.group]);
    const filteredLinks = links.filter(({ source, target }) => {
      const filterSource = filteredNodes.find(({ id }) => source.includes(id));
      const filterTarget = filteredNodes.find(({ id }) => target.includes(id));
      return filterSource && filterTarget;
    });
    return { links: filteredLinks, nodes: filteredNodes };
  };

  updateTopology = () => {
    const { incident } = this.props;
    this.setCurrentIncidet(incident);
    this.forceUpdate();
  };

  onToggleInactive = (e) => {
    this.setState({
      hideInactive: !e,
    });
  };

  showFullInactiveIncident = (incident) => {
    if (
      incident.status === 'ok' ||
      incident.status === 'acknowledged' ||
      incident.numMaintenanceAlerts === incident.entities.length
    ) {
      this.setState({ hideInactive: false });
    }
  };

  componentDidUpdate() {
    const { loading, postMsgSent } = this.state;
    const { iframeMode } = this.props;
    if (iframeMode && !loading && !postMsgSent) {
      this.sendPostMsg();
    }
  }

  sendPostMsg = () => {
    window.parent.postMessage({ type: 'topology_loaded' }, '*');
    this.setState({ postMsgSent: true });
  };

  render() {
    const { links, loading, currentIncident, hiddenNodeGroups, hideInactive } = this.state;
    const { incident, hideLoadingText, iframeMode } = this.props;
    const incidentToUse = currentIncident || incident;
    const customNodesAndLinks =
      links && links.length && buildTopologyData(links, incidentToUse.entities, hideInactive);
    const defaultNodesAndLinks = incidentToNodesAndLinks(incidentToUse, hideInactive);
    const nodesAndLinks =
      customNodesAndLinks && customNodesAndLinks.links && customNodesAndLinks.links.length
        ? customNodesAndLinks
        : defaultNodesAndLinks;
    const data = loading ? {} : convertNodesAndLinksToData(this.removeHiddenNodes(nodesAndLinks));
    const showTopology = data.nodes && data.nodes.length > 0;
    const updateTopologyButton = (
      <Button
        icon={<RefreshIcon />}
        variant="action-color"
        onClick={this.updateTopology}
        tooltipProps={{
          isActive: true,
          placement: 'bottom-end',
          text: 'Regenerate the graph with updated data',
        }}
      >
        Update
      </Button>
    );
    const toggleBtn = (
      <BamToggle
        onClick={this.onToggleInactive}
        label="Include inactive alerts"
        labelPosition="left"
        checked={!hideInactive}
        small
        color="blue"
      />
    );
    const noData = (
      <div className={styles.logoContainer}>
        <img className={styles.logo} src="assets/img/panda_logo.png" alt="" />
        <div className={styles.noData}>No data to show</div>
      </div>
    );
    const modalContent = (
      <div className={styles.topologyModalContentWrapper}>
        <FiltersWrapper>
          <div className={styles.topologyFilter}>
            <FilterCheckboxes
              nodesAndLinks={nodesAndLinks}
              defaultColors={defaultColors}
              tempColorsMap={tempColorsMap}
              typesMap={typesMap}
              hiddenNodeGroups={hiddenNodeGroups}
              hideInactive={hideInactive}
              onClick={({ filtered, node }) => {
                this.setState({
                  hiddenNodeGroups: { ...hiddenNodeGroups, [node.group]: !filtered },
                });
              }}
            />
          </div>
          <div className={`bam ${styles.topologyUpdate}`}>
            <div className={styles.tooltipWrapper}>{updateTopologyButton}</div>
          </div>
        </FiltersWrapper>
        <div className={styles.topologyInactive}>
          {toggleBtn}
          <HelpTooltip text={toggleHelper} placement="bottom" maxWidth="250px" />
        </div>
        <div className={styles.bamTopology}>
          {(() => {
            if (loading) {
              return <Spinner color={bp_blue} text={hideLoadingText ? undefined : 'Loading...'} />;
            }

            if (showTopology) {
              return <BamTopology data={data} config={graphConfig} />;
            }

            return noData;
          })()}
        </div>
      </div>
    );
    const modalTrigger = (
      <FullScreenDiv className={`bam ${styles.topologyUpdate}`} iframeMode={iframeMode}>
        <Button
          variant="action-default"
          icon={<FullscreenIcon />}
          tooltipProps={{
            isActive: true,
            text: 'Full Screen',
            placement: 'bottom-end',
          }}
        />
      </FullScreenDiv>
    );

    const theme =
      (iframeMode && localStorage.getItem('bp_theme')) === '"darkTheme"' ? darkThemeV2 : lightTheme;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper iframeMode={iframeMode}>
          <TopologyContainer>
            <FiltersWrapper>
              <div className={styles.topologyFilter}>
                <FilterCheckboxes
                  nodesAndLinks={nodesAndLinks}
                  defaultColors={defaultColors}
                  tempColorsMap={tempColorsMap}
                  typesMap={typesMap}
                  hiddenNodeGroups={hiddenNodeGroups}
                  hideInactive={hideInactive}
                  onClick={({ filtered, node }) => {
                    this.setState({
                      hiddenNodeGroups: { ...hiddenNodeGroups, [node.group]: !filtered },
                    });
                  }}
                />
              </div>
              <div className={`bam ${styles.topologyUpdate} bam ${styles.topologyUpdateFrame}`}>
                <div className={styles.tooltipWrapper}>{updateTopologyButton}</div>
                <BamModal
                  trigger={modalTrigger}
                  titleIcon="bp-icon-topology"
                  title="Service Topology"
                  scrollable={false}
                  className="fullscreen-topology-modal"
                  style={{ height: 'calc(100% - 16px)', width: 'calc(100% - 16px)' }}
                >
                  <div className={styles.topologyModalContentWrapper}>{modalContent}</div>
                </BamModal>
              </div>
            </FiltersWrapper>
            <div className={styles.topologyInactive}>
              {toggleBtn}
              <HelpTooltip text={toggleHelper} placement="bottom" maxWidth="250px" />
            </div>
            <div className={styles.bamTopology}>
              {(() => {
                if (loading) {
                  return (
                    <Spinner color={bp_blue} text={hideLoadingText ? undefined : 'Loading...'} />
                  );
                }

                if (showTopology) {
                  return <BamTopology data={data} config={graphConfig} />;
                }

                return noData;
              })()}
            </div>
          </TopologyContainer>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

IncidentTopology.propTypes = {
  incident: PropTypes.shape({ start: PropTypes.number, id: PropTypes.string }).isRequired,
  hideLoadingText: PropTypes.bool,
  iframeMode: PropTypes.bool,
};

IncidentTopology.defaultProps = {
  hideLoadingText: false,
  iframeMode: false,
};

export default IncidentTopology;
