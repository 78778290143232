import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { changePassword, updateContact, getUser } from '../../../../common/endpoints/me';
import { showFailureMessage } from '../../../layout/settings/feedback';
import { path } from './constants';
import * as auth from '../../../../common/auth';

const loadWelcomeUserData = (action$, state$) =>
  action$.ofType(actionTypes.LOAD_WELCOME_USER_DATA).mergeMap(async () => {
    const userName = get(state$.getState(), `${path}.userName`);
    if (!userName) {
      const headers = {
        Authorization: `Bearer ${auth.getAuthTokenFromQuery() || auth.getAuthTokenFromCookie()}`,
      };
      return getUser(headers)
        .then((payload) => {
          const userName = get(payload, 'name');
          const userEmail = get(payload, 'username');
          const userOrganizationName =
            get(payload, 'organization.name') || get(payload, 'organization.display_name');
          return actions.loadWelcomeUserDataSuccess({ userName, userEmail, userOrganizationName });
        })
        .catch(() => {
          showFailureMessage('user', 'loading');
          return actions.loadWelcomeUserDataFailure();
        });
    }
  });

const updateUserNamePassword = (action$) =>
  action$.ofType(actionTypes.UPDATE_USER_NAME_PASSWORD).mergeMap(async ({ payload }) => {
    try {
      await updateContact({ name: payload.name });
    } catch (e) {
      showFailureMessage('name', 'update');
      return actions.updateUserNamePasswordFailure();
    }
    try {
      await changePassword({ newPassword: payload.newPassword });
    } catch (e) {
      showFailureMessage('password', 'update');
      return actions.updateUserNamePasswordFailure();
    }
    return actions.updateUserNamePasswordSuccess();
  });

export default combineEpics(loadWelcomeUserData, updateUserNamePassword);
