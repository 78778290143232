import actionTypes from './actionTypes';
import { generateBaseSettingsActions } from '../../redux.utils';

const actions = generateBaseSettingsActions(actionTypes);

actions.loadRoles = () => ({ type: actionTypes.LOAD_ROLES });
actions.loadRolesSuccess = (payload) => ({
  type: actionTypes.LOAD_ROLES_SUCCESS,
  payload: payload,
});
actions.loadEnvironments = () => ({ type: actionTypes.LOAD_ENVIRONMENTS });
actions.loadEnvironmentsSuccess = (payload) => ({
  type: actionTypes.LOAD_ENVIRONMENTS_SUCCESS,
  payload: payload,
});
actions.loadPermissions = () => ({ type: actionTypes.LOAD_PERMISSIONS });
actions.loadPermissionsSuccess = (payload) => ({
  type: actionTypes.LOAD_PERMISSIONS_SUCCESS,
  payload: payload,
});
actions.createOrUpdateRole = (payload) => ({
  type: actionTypes.CREATE_OR_UPDATE_ROLE,
  payload: payload,
});
actions.deleteRole = (payload) => ({ type: actionTypes.DELETE_ROLE, payload: payload });
actions.loadPermissionsAndEnvironments = () => ({
  type: actionTypes.LOAD_PERMISSIONS_AND_ENVIRONMENTS,
});
actions.loadPermissionsAndEnvironmentsSuccess = (payload) => ({
  type: actionTypes.LOAD_PERMISSIONS_AND_ENVIRONMENTS_SUCCESS,
  payload: payload,
});
actions.createFiltersDropDownItemsSuccess = (payload) => ({
  type: actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS,
  payload: payload,
});
actions.updateFiltersDropDowns = (payload) => ({
  type: actionTypes.UPDATE_FILTERS_DROPDOWNS,
  payload: payload,
});

export default actions;
