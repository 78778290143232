import find from 'lodash/find';
import isArray from 'lodash/isArray';
angular.module('bigpanda').controller('NewRuleController', NewRuleController);

function NewRuleController(
  $scope,
  RulesService,
  notificationService,
  $controller,
  EnvironmentsService,
  TargetSystemsStore
) {
  const vm = this;
  $scope.parentVm = vm;
  angular.extend(this, $controller('RuleController', { vm: vm }));

  const states = {
    choose_env: {
      saveRule: toSaveRuleState,
      template: 'settings/rules/info/choose_channel_env',
      title: 'New AutoShare',
      saveButtonText: 'NEXT',
    },
    save_rule: {
      saveRule: saveRule,
      template: 'settings/rules/info/rule',
      title: 'Configure AutoShare',
      saveButtonText: 'CREATE',
    },
  };

  vm.enableButtons = true;
  vm.selectedParams = {};

  init();

  function init() {
    initWatchers();
    initFields();
    initEnvironments();
    initTargetSystems();
    setState(states.choose_env);
  }

  function setState(state) {
    vm.saveRule = state.saveRule;
    vm.template = state.template;
    vm.title = state.title;
    vm.saveButtonText = state.saveButtonText;
  }

  function validate() {
    var error = false;
    if (!vm.selectedParams.system) {
      vm.errors.system = 'Channel is required';
      error = true;
    }

    if (!vm.selectedParams.environment) {
      vm.errors.environment = 'Environment is required';
      error = true;
    }

    return !error;
  }

  function toSaveRuleState() {
    if (validate()) {
      setState(states.save_rule);
    }
  }

  function saveRule() {
    vm.preSave().then(() => {
      vm.enableButtons = false;
      const payload = {
        envId: vm.selectedParams.environment,
        targetSystemId: vm.selectedParams.system.id,
        params: vm.fields,
      };

      if (vm.selectedParams.system.shareOptions) {
        payload.autoshare_options = {
          ...vm.selectedParams.system.shareOptions,
          ...{ message: vm.fields.message },
        };
      }

      return RulesService.createRule(payload).then(
        () => {
          vm.postSave();
        },
        (err) => {
          var error =
            err &&
            err.data &&
            err.data.response &&
            isArray(err.data.response.errors) &&
            err.data.response.errors.join(',');
          notificationService.error(`Failed to create rule${error ? `: ${error}` : ''}`);
          vm.postSave(err);
        }
      );
    });
  }

  function initEnvironments() {
    EnvironmentsService.get().then((environments) => {
      vm.environments = environments;
    });
  }

  function initTargetSystems() {
    TargetSystemsStore.getAll().then((systems) => {
      vm.systems = systems;
    });
  }

  function initFields() {
    $scope.$watch(
      () => vm.selectedParams.environment,
      () => {
        vm.errors.environment = null;
      }
    );

    $scope.$watch(
      () => vm.selectedParams.system,
      () => {
        vm.errors.system = null;
      }
    );
  }

  function initWatchers() {
    $scope.$watch(
      () => vm.selectedParams.system,
      (newVal) => {
        if (newVal) {
          vm.system = find(vm.systems, { id: newVal.id });
          if (vm.system) {
            vm.systemTemplate = TargetSystemsStore.getSystemParams(vm.system);
          }
        }
      }
    );

    $scope.$watch(
      () => vm.selectedParams.environment,
      (newVal) => {
        if (newVal) {
          vm.environment = find(vm.environments, { old_id: newVal });
        }
      }
    );
  }
}
