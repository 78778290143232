import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BamConfirmModal, BamEmptyMessage, BamTag, BamTile, BamToggle } from '@bp/bam';
import { Badge, Tooltip, darkTheme, EditIcon } from '@bp/kung-fu';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import provideStore from 'react/utils/provideStore';
import BpTimeSince from 'common/utils/BpTimeSince';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import styles from './api_key_info.scss';
import ApiKeysActions from '../../actions/ApiKeysActions';
import selectors from '../../selectors';
import ApiKeyModal from '../../modals/ApiKeyModal';
import actions from '../../actions';
import { formatTimestamp } from '../../utils';

class ApiKeyInfo extends React.PureComponent {
  toggleApiKeyClicked = () => {
    if (!this.props.apiKey.active) {
      this.toggleApiKey();
    }
  };

  toggleApiKey = () => {
    const { apiKey, updateApiKey } = this.props;
    updateApiKey({
      ...apiKey,
      active: !apiKey.active,
      apiKeyId: apiKey.id,
      associatedUserId: apiKey.user_id,
    });
  };

  render() {
    const { apiKey, actionPermissions } = this.props;
    const user = get(apiKey, 'user');

    if (!apiKey) {
      return (
        <BamEmptyMessage header="No API KEY SELECTED">
          Select an API key to see details
        </BamEmptyMessage>
      );
    }

    const associatedUser = (
      <div>
        <Tooltip text={user.username} isActive={!!user.username}>
          <BamTag text={user.name} userEmail={user.email} disabled={!apiKey.alive} />
        </Tooltip>
        {!apiKey.alive && <span className={styles['not-active']}>User does not exist</span>}
      </div>
    );

    const content = (
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.SectionRow title="Status:">
          <Badge text={apiKey.statusText} status={apiKey.status} isCapitalized size="large" />
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Key Suffix:">
          {apiKey.key_suffix}
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Associated User:">
          {associatedUser}
        </SettingsInfoScreen.SectionRow>
        {user.roles && (
          <SettingsInfoScreen.SectionRow title="User's Roles:">
            {user.roles.map(({ name }) => name).join(', ')}
          </SettingsInfoScreen.SectionRow>
        )}
        {apiKey.description && (
          <SettingsInfoScreen.SectionRow title="Description:">
            {apiKey.description}
          </SettingsInfoScreen.SectionRow>
        )}
        <SettingsInfoScreen.SectionRow title="Last Used:">
          {BpTimeSince(apiKey.last_used)}
        </SettingsInfoScreen.SectionRow>
      </SettingsInfoScreen.Section>
    );

    const settingsActions = (
      <SettingsInfoScreen.Section>
        {actionPermissions.hasEditPermission && (
          <ApiKeyModal
            triggerProps={{
              variant: 'action-color',
              size: 'large',
              text: 'Edit Key',
              icon: <EditIcon />,
            }}
            title="Edit API Key"
            titleIcon="bp-icon-edit"
            currentApiKeyId={apiKey.id}
          />
        )}
      </SettingsInfoScreen.Section>
    );

    const createdByUserName = get(apiKey, ['createdByUser', 'name'], 'N/A');
    const updatedByUserName = get(apiKey, ['updatedByUser', 'name'], 'N/A');

    const activity = (
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.ActivityRow
          title="Created by:"
          name={createdByUserName}
          value={formatTimestamp(apiKey.created_at)}
        />
        <SettingsInfoScreen.ActivityRow
          title="Updated:"
          name={updatedByUserName}
          value={formatTimestamp(apiKey.updated_at)}
        />
      </SettingsInfoScreen.Section>
    );

    const toggle = (
      <BamToggle
        label="Active:"
        labelPosition="left"
        onClick={this.toggleApiKeyClicked}
        checked={apiKey.active}
        color="green"
        big
      />
    );

    const deactivateModal = (
      <BamConfirmModal
        icon="bp-icon-power-off"
        headerTitle="Deactivate API Key"
        content="Do you want to deactivate this API key?"
        note="Note: The action will disable any access until you manually reactivate the API key"
        contentTitle={apiKey ? apiKey.name : ''}
        contentTitleType="string"
        action={this.toggleApiKey}
        actionText="Deactivate"
        trigger={toggle}
        destructive={false}
        openCondition={() => apiKey.active}
      />
    );
    const wrappedToggle = actionPermissions.hasEditPermission ? deactivateModal : null;

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsInfoScreen
          topActions={<ApiKeysActions apiKey={apiKey} />}
          header={
            <BamTile
              bamStatus={apiKey.status}
              headerRow={{ title: apiKey.name, toggle: wrappedToggle }}
            />
          }
          content={content}
          actions={settingsActions}
          activity={activity}
        />
      </ThemeProvider>
    );
  }
}

ApiKeyInfo.propTypes = {
  apiKey: PropTypes.shape({
    active: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    user_id: PropTypes.string,
    statusText: PropTypes.string,
    status: PropTypes.string,
    key_suffix: PropTypes.string,
    description: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string,
      username: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    }).isRequired,
    alive: PropTypes.bool,
    last_used: PropTypes.number,
    createdByUser: PropTypes.shape({ name: PropTypes.string }),
    updatedByUser: PropTypes.shape({ name: PropTypes.string }),
    created_at: PropTypes.number,
    updated_at: PropTypes.number,
  }),
  actionPermissions: PropTypes.shape({ hasEditPermission: PropTypes.bool }).isRequired,
  updateApiKey: PropTypes.func.isRequired,
};

ApiKeyInfo.defaultProps = {
  apiKey: null,
};

function mapStateToProps(state, ownProps) {
  const apiKey = selectors.getApiKey(state, ownProps.apiKeyId);
  const actionPermissions = selectors.getActionsPermissions(state);
  return { apiKey, actionPermissions };
}

function mapDispatchToProps(dispatch) {
  return {
    updateApiKey: (apiKey) => dispatch(actions.updateApiKey(apiKey)),
  };
}

export default provideStore(connect(mapStateToProps, mapDispatchToProps)(ApiKeyInfo));
