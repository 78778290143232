import { TagType } from '../../types';

const configByType = ({
  type, destination, source, template, regex = '(.*)', integration, ...config
}) => {
  if (type === TagType.Composition) {
    return {
      destination,
      value: template,
      selected_source_system: integration,
    };
  }
  if (type === TagType.Extraction) {
    return {
      destination,
      source,
      regex,
      selected_source_system: integration,
    };
  }

  return config;
};

export default configByType;
