import { react2angular } from 'react2angular';
import IntegrationsInfo from './components/IntegrationsInfo';

const routes = {};

routes.appSettingsIntegrationsInfo = {
  url: '/:planId',
  views: {
    'master-detail-info@app.settings.integrations': {
      template:
        '<integrations-info class="react-settings-info" plan-id="stateParams.planId" store="store" />',
      controller: ($scope, $ngRedux, $stateParams) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
      },
    },
  },
};

angular
  .module('bigpanda')
  .component('integrationsInfo', react2angular(IntegrationsInfo, ['planId', 'store']));

export default routes;
