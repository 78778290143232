import capitalize from 'lodash/capitalize';
import React from 'react';
import { Dot } from '@bp/kung-fu';
import { TimeFormats } from '@bp/bam';

import styles from './TroubleshootingModal.scss';
import { getStatusColor } from '../utils';

const { momentOrEpochRelativeTimeFormat, standardFullDateTimeFormatter } = TimeFormats;

const columnsSettings = [
  {
    id: 'level',
    accessor: 'level',
    headerContent: (
      <Dot size="medium" className={styles.levelCell} color={getStatusColor(null, styles)} />
    ),
    cellContent: (cell) => (
      <Dot size="medium" className={styles.levelCell} color={getStatusColor(cell.value, styles)} />
    ),
    headerTooltipContent: 'Level',
    tooltipContent: ({ value }) => capitalize(value),
    width: 40,
    fixed: 'left',
    resizable: false,
    sortable: false,
  },
  {
    id: 'log_type',
    accessor: 'log_type',
    headerContent: 'Event Type',
    cellContent: ({ value }) => value,
    tooltipContent: ({ value }) => value,
    width: 120,
    sortable: false,
  },
  {
    id: 'message',
    accessor: 'message',
    headerContent: 'Description',
    cellContent: ({ value }) => value,
    tooltipContent: ({ value }) => value,
    sortable: false,
  },
  {
    id: 'timestamp',
    accessor: 'timestamp',
    headerContent: 'Time',
    cellContent: ({ value }) => momentOrEpochRelativeTimeFormat(value, { seconds: true }),
    tooltipContent: ({ value }) => standardFullDateTimeFormatter(value, { seconds: true }),
    width: 170,
    fixed: 'right',
    resizable: false,
    sortable: false,
  },
];

export default columnsSettings;
