import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import { BamModal } from '@bp/bam';
import { selectors as usersSelectors } from 'react/common/enriched_users';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import permissions from 'common/routing/permissions';
import { inviteContext } from '../epic';
import actions from '../actions';
import selectors from '../selectors';
import UserForm from './UserForm';

class UserModal extends React.Component {
  onMountModal = () => {
    const { roles, loadRoles } = this.props;
    if (!roles) {
      loadRoles();
    }
  };

  handleClose = () => {
    this.ref.close();
  };

  render() {
    const { titleIcon, title, triggerProps, users } = this.props;
    return (
      <BamModal
        ref={(r) => {
          this.ref = r;
        }}
        trigger={<ModalTriggerButton {...triggerProps} />}
        titleIcon={titleIcon}
        title={title}
        onMount={this.onMountModal}
        formMode
        autoFocus
        style={{ width: 400 }}
      >
        <UserForm {...this.props} close={this.handleClose} />
      </BamModal>
    );
  }
}

UserModal.propTypes = {
  titleIcon: PropTypes.string.isRequired,
  loadRoles: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateOrInviteUserSuccess: PropTypes.func.isRequired,
  updateOrInviteUserFailure: PropTypes.func.isRequired,
  reactivateUser: PropTypes.func.isRequired,
  canViewRoles: PropTypes.bool.isRequired,
  inviteContext: PropTypes.shape({}).isRequired,
  inviteUser: PropTypes.func,
  currentUserId: PropTypes.string,
  duplicate: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.object),
  permissionsAndEnvironments: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  userDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
};

UserModal.defaultProps = {
  currentUserId: undefined,
  inviteUser: undefined,
  duplicate: false,
  roles: undefined,
  userDetails: undefined,
  permissionsAndEnvironments: undefined,
  title: '',
  users: undefined,
};

function mapStateToProps(state, ownProps) {
  return {
    roles: selectors.getRoles(state),
    userDetails: usersSelectors.getEnrichedUserById(state, ownProps.currentUserId),
    // This preserves the current AngularJS logic and should be replaced when a decision is made.
    canViewRoles: permissionsSelectors.getPermissionsList(state).includes(permissions.users.update),
    inviteContext: inviteContext(state),
    users: selectors.getUsers(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadRoles: () => dispatch(actions.getRoles()),
    updateUser: (user) => dispatch(actions.updateUser(user)),
    updateOrInviteUserSuccess: () => dispatch(actions.updateOrInviteUserSuccess()),
    updateOrInviteUserFailure: () => dispatch(actions.updateOrInviteUserFailure()),
    reactivateUser: (user) => dispatch(actions.reactivateUser(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(UserModal));
