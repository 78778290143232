function bpToggleDropdown(
  dropdown,
  element,
  scope,
  template,
  $window,
  $dropdown,
  placement,
  after
) {
  if (dropdown && dropdown.$isShown) {
    return dropdown;
  }

  if (!placement) {
    placement = element.offset().top < $window.innerHeight - 100 ? 'bottom-left' : 'top-left';
  }

  const options = {
    scope: scope,
    container: 'body',
    animation: 'none',
    template: template,
    placement: placement,
    trigger: 'manual',
  };

  const newDropdown = $dropdown(element, options);
  if (after) after(true);
  const justHide = newDropdown.hide;

  newDropdown.hide = () => {
    if (newDropdown.$isShown) {
      justHide.apply(newDropdown);
      newDropdown.destroy();
    }
    if (after) after(false);
    scope.$broadcast('bpDropdown.enableTooltips');
  };

  newDropdown.$promise.then(() => {
    scope.$$postDigest(newDropdown.show); // eslint-disable-line angular/no-private-call
  });

  scope.$broadcast('bpDropdown.disableTooltips');
  return newDropdown;
}

module.exports = { bpToggleDropdown: bpToggleDropdown };
