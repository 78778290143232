import { BamDropdownField } from '@bp/bam';
import React from 'react';
import { Field } from 'react-final-form';

import Select from '../../../../../../../app/react/common/components/Select/Select';
import ruleFieldName from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/ruleFieldName';
import { mandatoryField } from '../../../../../../../app/react/modules/settings/alert_enrichment/validation.utils';
import { EnrichmentRule } from '../../../../types';
import functionTypeOptions from '../utils/functionTypeOptions';
import { FunctionContent, FunctionField } from './MultiType.style';

interface IProps {
  filtersv2FT: boolean;
  rule: EnrichmentRule;
}
export function FunctionType({ filtersv2FT, rule }: IProps): JSX.Element {
  return (
    <FunctionContent>
      {filtersv2FT ? (
        <Field
          name={ruleFieldName(rule.id, 'type')}
          validate={mandatoryField('Function type')}
          validateOnBlur
        >
          {({ input, meta }): JSX.Element => (
            <Select
              title="Function Type"
              placeholder="Select Type..."
              items={functionTypeOptions}
              value={input.value}
              onChange={input.onChange}
              fullWidth
              disabled={!rule.__isNew}
              ariaLabel="function type"
              error={(meta.touched && !!meta.error) || (meta.submitFailed && !meta.valid)}
              errorMessage={(meta.touched || (meta.submitFailed && !meta.valid)) && meta.error}
            />
          )}
        </Field>
      ) : (
        <FunctionField
          name={ruleFieldName(rule.id, 'type')}
          component={BamDropdownField}
          title="Function type"
          options={functionTypeOptions}
          placeholder="Select Type..."
          validate={mandatoryField('Function type')}
          validateOnBlur
          disabled={!rule.__isNew}
          aria-label="rule type"
        />
      )}
    </FunctionContent>
  );
}

export default FunctionType;
