import React from 'react';
import pluralize from 'common/utils/utils';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { Div, HelpTooltip } from '@bp/kung-fu';
import { BamToggle } from '@bp/bam';
import styles from '../changes.scss';

const TableSummary = ({
  numOfResult,
  description,
  actions,
  totalRelatedCount,
  onToggleChange,
  rccMode,
  toggleValue,
  loading,
  textContent,
}) => {
  const ToggleLabel = textContent.label;
  const TooltipContent = textContent.tooltip;
  return (
    <ReactResizeDetector handleWidth>
      {({ width }) => {
        const minimalMode = width <= 550;
        const text = rccMode
          ? pluralize(numOfResult, 'Potential Root Cause Change')
          : `${pluralize(numOfResult, 'Change')} Found`;
        const title = minimalMode ? 'Found' : text;
        const fullTitle = `${numOfResult} ${title}`;
        const loadingTitle = 'Finding Changes...';
        const displayTitle = loading ? loadingTitle : fullTitle;
        return (
          <div className={styles.bamRelatedChangesSummary}>
            <div>
              <b>{displayTitle}</b> {minimalMode || rccMode || description}
            </div>
            <div className={styles.actionsContainer}>
              {actions}
              <BamToggle
                labelPosition="left"
                label={ToggleLabel}
                counter={totalRelatedCount}
                onClick={onToggleChange}
                checked={toggleValue}
              />
              <Div marginStart="5px">
                <HelpTooltip text={TooltipContent} />
              </Div>
            </div>
          </div>
        );
      }}
    </ReactResizeDetector>
  );
};

TableSummary.propTypes = {
  numOfResult: PropTypes.number.isRequired,
  description: PropTypes.string,
  actions: PropTypes.oneOfType([PropTypes.node]),
  totalRelatedCount: PropTypes.number,
  onToggleChange: PropTypes.func.isRequired,
  toggleValue: PropTypes.bool.isRequired,
  loading: PropTypes.number.isRequired,
  textContent: PropTypes.shape({ label: PropTypes.node, tooltip: PropTypes.node }).isRequired,
  rccMode: PropTypes.bool,
};

TableSummary.defaultProps = {
  description: '',
  actions: null,
  totalRelatedCount: 0,
  rccMode: false,
};

export default TableSummary;
