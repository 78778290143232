import { BamTile } from '@bp/bam';
import BPQL from '@bp/bpql';
import {
  AllSizes,
  Badge,
  HBox,
  StatusEnum,
} from '@bp/kung-fu';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import BpTimeSince from '../../../../../../app/common/utils/BpTimeSince';
import selectors from '../../../../../../app/react/modules/settings/maintenance_plans/maintenance_plan_info/selectors';
import { selectors as featureTogglesSelectors } from '../../../../../../app/react/user/feature_toggles';
import MaintenancePlansActions from '../../../actions/MaintenancePlansActions';
import TimeWindow from '../../../maintenance_plan_info/components/TimeWindow';
import { semanticPlanStatus } from '../../../maintenance_plan_info/utils/semanticPlanStatus';
import { FeatureToggles } from '../../../types/FeatureToggles';
import { MaintenancePlan } from '../../../types/MaintenancePlan';

const Tile = styled(BamTile)`
  width: 100%;
`;

type FirstRow = {
  customParts: JSX.Element[];
  title: string;
  time: {
    title: string;
    timestamp: string;
  }
};

const generateFirstRow = (plan: MaintenancePlan): FirstRow => ({
  customParts: [
    <Badge
      text={semanticPlanStatus(plan.status)}
      status={plan.status}
      isCapitalized
      size={AllSizes.SMALL}
    />,
  ],
  title: plan.name,
  time: {
    title: 'Created',
    timestamp: BpTimeSince(plan.created_at),
  },
});

const timeTitle = (status: StatusEnum): string => {
  switch (status) {
    case StatusEnum.INPROGRESS:
      return 'Ends';
    case StatusEnum.PLANNED:
      return 'Starts';
    default:
      return 'Ended';
  }
};

type SecondRow = {
  textParts?: Record<string, string>[];
  customParts?: JSX.Element[];
  actionsBundle: JSX.Element;
};

const generateSecondRow = (plan: MaintenancePlan, hasRecurring: boolean): SecondRow => {
  if (hasRecurring) {
    return {
      customParts: [
        <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <TimeWindow plan={plan} isPlain />
        </div>,
      ],
      actionsBundle: <MaintenancePlansActions plan={plan} />,
    };
  }

  const value = selectors.formatDate(plan.status !== StatusEnum.PLANNED ? plan.end : plan.start);
  const label = timeTitle(plan.status);
  const conditionLabel = isEmpty(plan.condition) ? '' : BPQL.reverse(plan.condition);
  return {
    textParts: [
      { value: plan.maintenance_key },
      { label, value },
      { label: 'Conditions', value: conditionLabel },
    ],
    actionsBundle: <MaintenancePlansActions plan={plan} />,
  };
};

interface IProps {
  plan: MaintenancePlan;
}

function ListItem({ plan, ...props }: IProps): JSX.Element {
  const handleClick = (): void => {
    window.location.href = `/#/app/settings/planned-maintenance/${plan.id}`;
  };

  const featureToggles: FeatureToggles = useSelector(featureTogglesSelectors.getFeatureToggles);
  const hasRecurring = featureToggles?.frontier_recurring_maintenance_plans || false;

  return (
    <HBox role="listitem" aria-label={`${plan.name} plan`}>
      <Tile
        bamStatus={plan.status}
        firstRow={generateFirstRow(plan)}
        secondRow={generateSecondRow(plan, hasRecurring)}
        onClick={handleClick}
        featureToggles={featureToggles}
        {...props}
      />
    </HBox>
  );
}

export default ListItem;
