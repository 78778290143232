import { DiceIcon } from '@bp/kung-fu';
import React from 'react';

import text from './dictionary';

export const DROPDOWMN_SHADOW = '-6px 2px 7px 0px rgba(0, 0, 0, 0.10)';

export const INCIDENT_TAGS_METADATA = (): object[] => [
  {
    title: text.dropdown_option1_title,
    description: text.dropdown_option1_description,
    example: [text.dropdown_option1_example1, text.dropdown_option1_example2],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: text.dropdown_option2_title,
    description: text.dropdown_option2_description,
    example: [text.dropdown_option2_example1],
    isMetadata: true,
    icon: <DiceIcon />,
  },
  {
    title: text.dropdown_option3_title,
    description: text.dropdown_option3_description,
    example: [text.dropdown_option3_example1, text.dropdown_option3_example2],
    isMetadata: true,
    icon: <DiceIcon />,
  },
];
