import { ClockIcon, StopIcon } from '@bp/kung-fu';
import React from 'react';

import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';
import { MaintenancePlan } from '../../../types/MaintenancePlan';
import styles from '../maintenance_plan_info.scss';
import MaintenanceModal from '../modal/MaintenanceModal';

interface IProps {
  plan: MaintenancePlan;
  endsIn: string;
  canStop: boolean;
}

export function InProgressActionButtons({ plan, endsIn, canStop }: IProps): JSX.Element {
  return (
    <SettingsInfoScreen.Section>
      <MaintenanceModal
        triggerProps={{
          variant: 'action-color',
          size: 'medium',
          icon: <StopIcon />,
          text: 'Stop',
          disabled: !canStop,
        }}
        title="Stop Running Plan"
        titleIcon="bp-icon-stop"
        stopPlan
        initialValues={plan}
      />
      <MaintenanceModal
        triggerProps={{
          variant: 'action-color',
          size: 'medium',
          icon: <ClockIcon />,
          text: 'Edit Time Window',
          disabled: !canStop,
        }}
        title="Edit Time Window"
        titleIcon="bp-icon-clock"
        editTimeWindow
        initialValues={plan}
      />
      <span className={styles.ends}>
        <span className={styles['ends-in']}>Ends in:</span>
        <span className={styles.time}>{endsIn}</span>
      </span>
    </SettingsInfoScreen.Section>
  );
}
