import PropTypes from 'prop-types';

const itemPropTypes = {
  value: PropTypes.string,
  display: PropTypes.string,
  tooltip: PropTypes.string,
  icon: PropTypes.string,
  isAnyOption: PropTypes.bool,
  borderBottom: PropTypes.bool,
};

module.exports = {
  itemPropTypes,
};
