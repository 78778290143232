angular.module('bp.widgets').directive('bpSearchNotification', bpSearchNotification);

function bpSearchNotification(SortingStore) {
  return {
    restrict: 'E',
    templateUrl: 'widgets/bp_search_notification/bp_search_notification',
    controllerAs: 'notificationVm',
    bindToController: true,
    replace: true,
    scope: {
      notification: '=',
    },
    controller,
  };

  function controller($scope) {
    const vm = this;
    vm.updateSortBy = () => {
      SortingStore.getSortBy().then((sortBy) => (vm.sortBy = sortBy));
    };
    vm.isSortedByPriority = () => vm.sortBy === SortingStore.options.label_priority;
    vm.updateSortBy();
    SortingStore.subscribe({ storeUpdated: vm.updateSortBy }, $scope);
  }
}
