import { ITooltipProps } from '@bp/kung-fu';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { v4 } from 'uuid';

import { AddItemButton } from '../../../../../../app/react/common/components';

interface IProps {
  onClick: () => void | undefined;
  forwardedRef: React.ForwardedRef<HTMLButtonElement>;
  disabled: boolean;
  tooltipProps: ITooltipProps;
}

export function AddNewRuleButton({
  onClick, forwardedRef, disabled, tooltipProps,
}: IProps): JSX.Element {
  return (
    <FormSpy>
      {({ form: { mutators } }): JSX.Element => (
        <AddItemButton
          ariaLabel="add enrichment rule"
          forwardedRef={forwardedRef}
          disabled={disabled}
          tooltipProps={tooltipProps}
          onClick={(e): void => {
            e.preventDefault();
            mutators.addRule(v4());
            if (onClick) onClick();
          }}
        />
      )}
    </FormSpy>
  );
}

export default AddNewRuleButton;
