import { path } from './constants';

const actionTypes = {
  GET_INCIDENT_TAGS: `${path}.GET_INCIDENT_TAGS`,
  GET_INCIDENT_TAGS_SUCCESS: `${path}.GET_INCIDENT_TAGS_SUCCESS`,
  GET_INCIDENT_TAGS_FAILURE: `${path}.GET_INCIDENT_TAGS_FAILURE`,
  SET_TAG_VALUE_FOR_INCIDENT: `${path}.SET_TAG_VALUE_FOR_INCIDENT`,
  SET_TAG_VALUE_FOR_INCIDENT_SUCCESS: `${path}.SET_TAG_VALUE_FOR_INCIDENT_SUCCESS`,
  SET_TAG_VALUE_FOR_INCIDENT_FAILURE: `${path}.SET_TAG_VALUE_FOR_INCIDENT_FAILURE`,
  RESET_LOADING_FOR_INCIDENT: `${path}.RESET_LOADING_FOR_INCIDENT`,
  REMOVE_TAG_VALUE_FROM_INCIDENT: `${path}.REMOVE_TAG_VALUE_FROM_INCIDENT`,
  REMOVE_TAG_VALUE_FROM_INCIDENT_SUCCESS: `${path}.REMOVE_TAG_VALUE_FROM_INCIDENT_SUCCESS`,
  REMOVE_TAG_VALUE_FROM_INCIDENT_FAILURE: `${path}.REMOVE_TAG_VALUE_FROM_INCIDENT_FAILURE`,
  SET_EDITED_TAG_ID: `${path}.SET_EDITED_TAG_ID`,
  CREATE_AI_ANALYSIS: `${path}.CREATE_AI_ANALYSIS`,
  CREATE_AI_ANALYSIS_SUCCESS: `${path}.CREATE_AI_ANALYSIS_SUCCESS`,
  CREATE_AI_ANALYSIS_FAILURE: `${path}.CREATE_AI_ANALYSIS_FAILURE`,
};

export default actionTypes;
