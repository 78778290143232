import { HBox, SCHBox, VBox } from '@bp/kung-fu';
import styled from 'styled-components';

export const Container = styled(VBox)`
    height: 100%;
    width: 100%;
`;

export const HeaderContainer = styled(SCHBox)`
    flex-shrink: 0;
    justify-content: space-between;
    height: 60px;
    padding-left: 15px;
    margin: 0 15px;
    border-bottom: ${(p) => `1px solid ${p.theme.bp_gray_02}`};
`;

export const SubheaderContainer = styled(HBox)`
    padding: 22px 30px 0;
`;

export const ContentWrapper = styled(HBox)`
    padding: ${({ unpadded }) => (unpadded ? 0 : '30px 15px')};
    width: 100%;
    overflow-y: auto;
    min-height: 150px;
`;

export const Content = styled(HBox)`
    width: 100%;
`;
