import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import SettingsListItemActions from 'react/layout/settings/components/SettingsListItemActions';
import permissions from 'common/routing/permissions';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import get from 'lodash/get';
import RoleModal from '../roles_list/components/modal/RoleModal';
import actions from '../roles_list/actions';

const calcIsUserSuperAdmin = (state) => {
  const currentUser = get(state, 'layout.topbar.user');
  const roles = get(state, 'modules.settings.rolesManagement.rolesList.roles');

  if (currentUser && roles) {
    return !!roles
      .find((role) => role.name === 'superadmin')
      .users.find((userId) => currentUser.bigpanda_user_id === userId);
  }

  return false;
};

const calcIsDefaultRole = (roleName = '') => ['user', 'admin'].indexOf(roleName) !== -1;

const RolesManagementActions = ({ role, isUserSuperAdmin, userPermissions, deleteRole }) => {
  const isDefaultRole = calcIsDefaultRole(role.name);
  const editDisabled =
    !isUserSuperAdmin && (isDefaultRole || !userPermissions.includes(permissions.roles.update));

  const removeRole = () => deleteRole(role._id);

  const messages = {
    edit: `Cannot Edit '${role.name}' Role`,
    duplicate: `Cannot Duplicate '${role.name}' Role`,
    delete: `Cannot Delete '${role.name}' Role`,
  };

  return (
    <SettingsListItemActions
      item={role}
      modelName="role"
      messages={messages}
      editProps={{
        disabled: editDisabled,
        showIfDisabled: userPermissions.includes(permissions.roles.update),
        modalProps: { currentRoleId: role._id },
        modal: RoleModal,
      }}
      duplicateProps={{
        disabled: !userPermissions.includes(permissions.roles.create),
        modalProps: { currentRoleId: role._id },
        modal: RoleModal,
      }}
      deleteProps={{
        disabled: isDefaultRole || !userPermissions.includes(permissions.roles.delete),
        showIfDisabled: userPermissions.includes(permissions.roles.delete),
        action: removeRole,
      }}
    />
  );
};

RolesManagementActions.propTypes = {
  role: PropTypes.object.isRequired /* eslint-disable-line */,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  deleteRole: PropTypes.func.isRequired,
  isUserSuperAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    userPermissions: permissionsSelectors.getPermissionsList(state) || [],
    isUserSuperAdmin: calcIsUserSuperAdmin(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteRole: (roleId) => dispatch(actions.deleteRole(roleId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(RolesManagementActions));
