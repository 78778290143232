angular.module('bp.widgets').directive('bpMasterDetail', () => {
  return {
    restrict: 'E',
    scope: {
      mainTitle: '@',
      buttonText: '@',
      newRoute: '@',
      newRoutePermission: '=',
      newRouteHref: '@',
      searchValue: '=',
      loadingRoute: '=',
      filter: '=',
      placeholder: '@',
      ariaLabel: '@',
    },
    transclude: true,
    templateUrl: 'widgets/bp_master_detail/bp_master_detail',
    controllerAs: 'masterDetailVm',
    bindToController: true,
    controller: controller,
  };

  function controller() {
    const vm = this;
    vm.currentSearch = '';
    vm.updateSearch = updateSearch;
    vm.isFilter = isFilter;
    vm.onClick = onClick;

    function isFilter() {
      return angular.isDefined(vm.filter);
    }

    function updateSearch() {
      vm.searchValue = vm.currentSearch;
    }

    function onClick() {
      if (vm.newRouteHref) {
        window.location.replace(vm.newRouteHref);
      }
    }
  }
});
