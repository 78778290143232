import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import last from 'lodash/last';

angular.module('bigpanda').service('TagsService', TagsService);

function TagsService(
  $q,
  TagsBackendService,
  sourceBreakdownService,
  CorrelationConfigBackendService,
  querySourceParser
) {
  let tags;
  let getTagsPromise;

  this.getTagsOfSourceSystem = getTagsOfSourceSystem;
  this.getTags = getTags;
  this.getUniqueTagsSamples = getUniqueTagsSamples;

  function createTagsFromRawTags(rawTags) {
    let tags = groupBy(rawTags, 'name');
    tags = map(tags, normalizeTagGroup);
    tags = sortBy(tags, ['tagInConfig', 'totalHits']);
    tags = reverse(tags);
    return tags;
  }

  function normalizeTagGroup(tagsGroup, name) {
    let sourceSystems = sortBy(tagsGroup, (group) => group.sourceSystem);
    sourceSystems = map(sourceSystems, getTagGroupSourceSystemData);

    return {
      name: name,
      sourceSystems: sourceSystems,
      totalHits: sumBy(tagsGroup, 'totalHits'),
      tagInConfig: !!find(tagsGroup, { tagInConfig: true }),
    };
  }

  function getTagGroupSourceSystemData(group) {
    return {
      system: group.sourceSystem,
      parentSourceSystem: group.parentSourceSystem,
      parentDisplayName: sourceBreakdownService.findSystemName(group.parentSourceSystem),
    };
  }

  function getTags(force = false) {
    const promises = [];

    if (tags && !force) {
      return $q.when(tags);
    }
    if (getTagsPromise) {
      return getTagsPromise;
    }

    promises.push(TagsBackendService.getRawTags());
    promises.push(CorrelationConfigBackendService.getCorrelationsConfig());

    getTagsPromise = $q
      .all(promises)
      .then((data) => {
        const [rawTags, rawConfig] = data;
        const configTags = getConfigTags(rawConfig);

        tags = createTagsFromRawTags(rawTags.concat(configTags));
        return tags;
      })
      .finally(() => {
        getTagsPromise = undefined;
      });
    return getTagsPromise;
  }

  function getConfigTags(rawConfig) {
    const activeTags = filter(rawConfig.transformations, { active: true });
    return formatConfigTags(activeTags);
  }

  function formatConfigTags(activeTags) {
    const configTags = [];
    each(activeTags, (tag) => {
      const query = querySourceParser.parseQuery(tag.query);
      const sourceSystems = getSourceSystems(query.sourceSystems);

      each(sourceSystems, (sourceSystem) => {
        const parentSourceSystem = sourceSystem.value
          ? sourceSystem.value.split('.')[0]
          : sourceSystem.split('.')[0];
        configTags.push({
          name: tag.destination,
          tagInConfig: true,
          sourceSystem: sourceSystem,
          parentSourceSystem: parentSourceSystem,
        });
      });
    });
    return configTags;
  }

  function getSourceSystems(sourceSystems) {
    return map(sourceSystems, (sourceSystem) => {
      if (sourceSystem && sourceSystem.type === 'regex') {
        return sourceSystem.value === '*' ? 'All Systems' : sourceSystem.value;
      }
      return sourceSystem;
    });
  }

  function getTagsOfSourceSystem(tagsArray, integration) {
    return tagsArray.filter((t) => {
      const matchingSystems = getMatchingSourceSystemsFromIntegrationForDisplay(
        integration,
        t.sourceSystems
      );
      return !isEmpty(matchingSystems);
    });
  }

  function getMatchingSourceSystemsFromIntegrationForDisplay(displayIntegration, sourceSystems) {
    let parent;
    if (displayIntegration.indexOf('.') > -1) {
      // v2 ALL SYSTEMS
      parent = displayIntegration.split('.')[0];
    } else if (last(displayIntegration) === '*') {
      // ALL SYSTEMS
      parent = displayIntegration.slice(0, -1);
    }
    return sourceSystems.filter(
      (s) => s.parentSourceSystem.indexOf(parent) === 0 || parent === '*'
    );
  }

  function getUniqueTagsSamples(query) {
    return TagsBackendService.getUniqueTagsSamples(query);
  }
}
