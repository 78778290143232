angular
  .module('bigpanda.reports')
  .controller('GroupByController', function GroupByController($scope, DisplayCommonService) {
    var vm = this;
    vm.fieldNames = rebuidFiledNames(DisplayCommonService.getFieldNamesConfig());
  });

function rebuidFiledNames(fieldNamesConfig) {
  var names = [];
  Object.keys(fieldNamesConfig).forEach((fieldName) => {
    names.push({
      key: fieldName,
      singular: fieldNamesConfig[fieldName].singular,
      plural: fieldNamesConfig[fieldName].plural,
    });
  });
  return names;
}
