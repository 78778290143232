import get from 'lodash/get';
import find from 'lodash/find';

import actionTypes from './actionTypes';
import { baseSettingInitialState, generateBaseSettingsReducer } from '../../redux.utils';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';

export const initialState = {
  ...baseSettingInitialState,
  roles: undefined,
  environments: undefined,
  permissions: undefined,
  permissionsAndEnvironments: undefined,
  error: undefined,
  filtersDropDownState: { ...DROPDOWN_FILTERS_ALL_ITEM_OPTION },
  allEnvironmentId: undefined,
};

const baseReducer = generateBaseSettingsReducer(actionTypes, initialState);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_ROLES:
      return {
        ...state,
        isLoading: !state.roles,
      };

    case actionTypes.LOAD_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        isLoading: false,
      };

    case actionTypes.LOAD_ENVIRONMENTS_SUCCESS:
      return {
        ...state,
        environments: action.payload,
        allEnvironmentId: get(find(action.payload, { name: 'All' }), '_id'),
      };

    case actionTypes.LOAD_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };

    case actionTypes.LOAD_PERMISSIONS_AND_ENVIRONMENTS_SUCCESS:
      return {
        ...state,
        permissionsAndEnvironments: action.payload,
      };

    case actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS:
      return {
        ...state,
        filtersDropDownItems: {
          ...action.payload,
        },
      };

    case actionTypes.UPDATE_FILTERS_DROPDOWNS:
      return {
        ...state,
        filtersDropDownState: {
          ...state.filtersDropDownState,
          ...action.payload,
        },
      };

    default:
      return baseReducer(state, action);
  }
}
