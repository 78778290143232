import {
  AllSizes,
  Button,
  ButtonVariants,
  Table,
  Text,
  UniversityIcon,
} from '@bp/kung-fu';
import React from 'react';

import { AuditLogDocURL, text } from '../../constants';
import useAuditTable from '../../hooks/useAuditTable';
import { SubTitleWrapper, TableContainerWrapper, TitleWrapper } from './AuditLogTable.style';
import RowDetails from './components/RowDetails/RowDetails';
import TableFilters from './components/TableFilters';

function AuditLogTable(): JSX.Element {
  const {
    column, setColumn, rows, setRows, isLoading, isFetching, isFiltering, fetchNextPage,
    filters, setFilters,
  } = useAuditTable();

  const openInNewTab = (url): void => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleEndReached = (distanceFromEnd): void => {
    if (distanceFromEnd > 0) fetchNextPage();
  };

  return (
    <TableContainerWrapper>
      <TitleWrapper>
        <Text size={AllSizes.XXLARGE} weight="700">{text.pageTitle}</Text>
      </TitleWrapper>
      <SubTitleWrapper>
        <Text size={AllSizes.LARGE} weight="400">{text.description}</Text>
        <Button
          variant={ButtonVariants.LINK}
          icon={UniversityIcon}
          onClick={(): void => openInNewTab(AuditLogDocURL)}
        >
          <Text size={AllSizes.LARGE} weight="700">{text.learnMore}</Text>
        </Button>
      </SubTitleWrapper>
      {!isLoading && <TableFilters setFilters={setFilters} filters={filters} />}
      <Table
        data-testid="audit-log-table"
        columns={column}
        setColumns={setColumn}
        rows={rows}
        setRows={setRows}
        width="90%"
        height="100%"
        rowHeight={40}
        isLoading={isLoading || isFiltering}
        isFetching={isFetching}
        isDraggable={false}
        onEndReached={handleEndReached}
        CustomExpandComponent={RowDetails}
        isExpandable
        noResultsText={text.emptyState}
      />
    </TableContainerWrapper>
  );
}

export default AuditLogTable;
