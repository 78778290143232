import { Select, useForm } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { incidentTagClosedListTypes, incidentTagTypes } from '../../../../../app/react/modules/settings/incident_labels/constants';
import { allowMultiTypeMap, removeOption } from './constants';
import { ModalFormWrapper } from './IncidentTagsGridCellV2.style';
import {
  createClosedListOptions, getSingleValue, isClosedListValuesIsValid, multiValueFieldValidation,
} from './logic';

const stringToSelectValue = (string) => ({
  text: string,
  id: string,
});

function IncidentTagsGridCellV2MultiValue(props) {
  const {
    tagValue, updateParent, tagLabel, type, closedListValues,
  } = props;
  const [currentTagValue, setCurrentTagValue] = useState(tagValue);

  const {
    state, register, errors, updateField,
  } = useForm({
    multiValueSelect: tagValue.map(stringToSelectValue),
  });

  useEffect(() => {
    updateParent(currentTagValue, errors.multiValueSelect);
  }, [currentTagValue, errors]);

  const updateLocalState = (newValue) => {
    const newTagValues = Array.isArray(newValue)
      ? newValue.map((value) => value.id) : [newValue.id];
    setCurrentTagValue(newTagValues);
    updateParent(newTagValues);
  };

  const updateNewValue = (str) => {
    const newValues = [...state.multiValueSelect, stringToSelectValue(str)];
    updateField('multiValueSelect', newValues);
    updateLocalState(newValues);
  };

  const updateNewValues = (name, value) => {
    const newValue = value?.text === removeOption.text ? [] : value;
    updateField(name, newValue);
    updateLocalState(newValue);
  };

  const isMultiValue = type === incidentTagTypes.MULTI_VALUE;

  const allowMultiSelect = allowMultiTypeMap[type];

  const selectValue = type === incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE
    ? getSingleValue(state.multiValueSelect) : state.multiValueSelect;

  const closedListOptions = createClosedListOptions(selectValue, type, closedListValues);

  const multiValueSelectRegister = { ...register('multiValueSelect', { validation: (value) => multiValueFieldValidation(value, type, closedListValues), type: 'change' }) };

  const { error } = multiValueSelectRegister;

  const errorMessage = useMemo(() => (
    (!isMultiValue
      && isClosedListValuesIsValid(selectValue, closedListValues)
    ) || error), [isMultiValue, selectValue, closedListValues, error]);

  return (
    <ModalFormWrapper>
      <Select
        {...multiValueSelectRegister}
        updateField={updateNewValues}
        label={`${tagLabel}:`}
        value={selectValue}
        options={closedListOptions || []}
        width="420px"
        isSearchable
        isMulti={allowMultiSelect}
        isCreatable={isMultiValue}
        handleCreate={updateNewValue}
        autoFocus
        noResultsText={isMultiValue ? 'Please type a value' : "Closed List value doesn't exist"}
        error={errorMessage}
      />
    </ModalFormWrapper>
  );
}

IncidentTagsGridCellV2MultiValue.propTypes = {
  tagValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateParent: PropTypes.func.isRequired,
  tagLabel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closedListValues: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
};

export default IncidentTagsGridCellV2MultiValue;
