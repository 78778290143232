import { BamTile } from '@bp/bam';
import { AllSizes, Div, Text } from '@bp/kung-fu';
import get from 'lodash/get';
import React from 'react';
import { hot } from 'react-hot-loader';

import {
  getIntegrationIcon,
} from '../../../../../../../app/react/modules/settings/auto_share_rules/modals/integrationsHelper';
import { supportedTargetSystem } from '../../../api/integrations-api';
import { getNameAndIcon, urlRoute } from '../../../core/constants';
import utils from '../../../core/utils';
import AutoShareRuleActions from '../../AutoShareRuleActions/AutoShareRuleActions';
import listItemPropType from './propTypes';
import RecipientsTooltip from './RecipientsTooltip/RecipientsTooltip';

const generateFirstRow = ({ environment }) => ({
  title: utils.envName(environment.name),
});

const generateSecondRow = (rule) => {
  const { systemName, systemIcon } = getNameAndIcon(rule);
  const parentSystemId = get(rule, ['system', 'parent_system_id'], {});

  const firstTextPart = supportedTargetSystem.includes(parentSystemId)
    ? {
      value: (
        <Div>
          {getIntegrationIcon(parentSystemId)}
          <Text color={(p) => p.theme.bp_gray_07} size={AllSizes.SMALL}>{systemName}</Text>
        </Div>
      ),
    }
    : {
      icon: systemIcon,
      value: systemName,
    };

  const textParts = [
    firstTextPart,
  ];

  if (get(rule.params, ['contacts', 'length'])) {
    textParts.push({
      label: 'Recipients',
      value: (
        <Div display="inline-block">
          <RecipientsTooltip
            contacts={rule.params.contacts}
            type={get(rule, ['system', 'parent_system_id'])}
          />
        </Div>
      ),
    });
  }

  return {
    textParts,
    actionsBundle: <AutoShareRuleActions rule={rule} />,
  };
};

function ListItem({ rule, ...props }) {
  const handleClick = () => {
    window.location.href = urlRoute(rule.id);
  };

  return (
    <BamTile
      bamStatus={rule.inactive ? 'off' : 'ok'}
      firstRow={generateFirstRow(rule)}
      secondRow={generateSecondRow(rule)}
      onClick={handleClick}
      inactive={rule.inactive}
      {...props}
    />
  );
}

ListItem.propTypes = listItemPropType;

export default hot(module)(ListItem);
