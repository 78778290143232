angular.module('bigpanda').service('CustomizationsUtils', CustomizationsUtils);

function CustomizationsUtils(pubSubService) {
  this.addFavorite = (favorites, env, analyticsName) =>
    updateFavorites(favorites, env, true, analyticsName);
  this.deleteFavorite = (favorites, env, analyticsName) =>
    updateFavorites(favorites, env, false, analyticsName);
  this.updateFavorites = updateFavorites;

  function updateFavorites(favorites, env, isAdding, analyticsName) {
    const updatedFavorites = new Set(favorites);

    if (isAdding) {
      pubSubService.broadcast('environment.starred', env.name, analyticsName);
      updatedFavorites.add(env._id);
    } else {
      pubSubService.broadcast('environment.unstarred', env.name, analyticsName);
      updatedFavorites.delete(env._id);
    }

    return Array.from(updatedFavorites);
  }
}
