import keyBy from 'lodash/keyBy';

import actionTypes from './actionTypes';
import { reqStatusType } from './constants';

export const initialState = {
  isLoading: false,
  isUpdating: false,
  incidentTagsData: [],
  editedTagId: null,
  updateRequestStatus: reqStatusType.NOT_INITIALIZED,
};

function margeData(oldTags, newData) {
  const oldTagsObj = keyBy(oldTags, 'tag_id');
  const newDataObj = keyBy(newData, 'tag_id');

  return Object.keys(newDataObj).map((key) => {
    return key in oldTagsObj
      ? {
          tag_id: oldTagsObj[key].tag_id,
          tag_value: newDataObj[key].tag_value || oldTagsObj[key].tag_value,
          name: newDataObj[key].name || oldTagsObj[key].name,
          email: newDataObj[key].email || oldTagsObj[key].email,
          timestamp: newDataObj[key].timestamp || oldTagsObj[key].timestamp || Date.now(),
        }
      : newDataObj[key];
  });
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INCIDENT_TAGS:
      return {
        ...state,
        isLoading: true,
        isUpdating: false,
        aiAnalysisError: null,
        editedTagId: null,
        updateRequestStatus: reqStatusType.NOT_INITIALIZED,
      };
    case actionTypes.GET_INCIDENT_TAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        incidentTagsData: action.payload,
        editedTagId: null,
        updateRequestStatus: reqStatusType.NOT_INITIALIZED,
      };
    case actionTypes.GET_INCIDENT_TAGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        editedTagId: null,
        updateRequestStatus: reqStatusType.NOT_INITIALIZED,
      };
    case actionTypes.SET_TAG_VALUE_FOR_INCIDENT:
      return {
        ...state,
        isLoading: false,
        isUpdating: true,
        isValueUpdatedByUser: true,
        updateRequestStatus: reqStatusType.LOADING,
      };
    case actionTypes.RESET_LOADING_FOR_INCIDENT:
      return {
        ...state,
        isValueUpdatedByUser: true,
        updateRequestStatus: reqStatusType.LOADING,
      };
    case actionTypes.SET_TAG_VALUE_FOR_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        incidentTagsData: margeData(state.incidentTagsData, action.payload),
        isValueUpdatedByUser: false,
        updateRequestStatus: state.isValueUpdatedByUser
          ? reqStatusType.SUCCESS
          : reqStatusType.NOT_INITIALIZED,
        editedTagId: null,
      };
    case actionTypes.SET_TAG_VALUE_FOR_INCIDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        isValueUpdatedByUser: false,
        updateRequestStatus: state.isValueUpdatedByUser
          ? reqStatusType.FAILURE
          : reqStatusType.NOT_INITIALIZED,
        editedTagId: null,
      };
    case actionTypes.REMOVE_TAG_VALUE_FROM_INCIDENT:
      return {
        ...state,
        isLoading: false,
        isUpdating: true,
        isValueUpdatedByUser: true,
        updateRequestStatus: reqStatusType.LOADING,
      };
    case actionTypes.REMOVE_TAG_VALUE_FROM_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        incidentTagsData: action.payload,
        isValueUpdatedByUser: false,
        updateRequestStatus: state.isValueUpdatedByUser
          ? reqStatusType.SUCCESS
          : reqStatusType.NOT_INITIALIZED,
        editedTagId: null,
      };
    case actionTypes.REMOVE_TAG_VALUE_FROM_INCIDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        isValueUpdatedByUser: false,
        updateRequestStatus: state.isValueUpdatedByUser
          ? reqStatusType.FAILURE
          : reqStatusType.NOT_INITIALIZED,
        editedTagId: null,
      };
    case actionTypes.CREATE_AI_ANALYSIS_FAILURE:
      return {
        ...state,
        aiAnalysisError: action.payload,
      };
    case actionTypes.CREATE_AI_ANALYSIS:
      return {
        ...state,
        aiAnalysisError: null,
      };
    case actionTypes.SET_EDITED_TAG_ID:
      return {
        ...state,
        editedTagId: action.payload,
      };
    default:
      return state;
  }
}
