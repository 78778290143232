import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';
angular
  .module('bigpanda')
  .controller(
    'RulesController',
    (
      $rootScope,
      $scope,
      ModalService,
      RulesService,
      pubSubService,
      previousStateService,
      $state,
      stateService,
      Permissions,
      PERMISSIONS_NAMES,
      UserFeatureTogglesService,
      $ngRedux,
      $stateParams
    ) => {
      $scope.createPermissions = [PERMISSIONS_NAMES.notifications.create];
      $scope.updatePermissions = [PERMISSIONS_NAMES.notifications.update];
      $scope.deletePermissions = [PERMISSIONS_NAMES.notifications.delete];
      $scope.hasNotificationsCreatePermission = Permissions.isPermitted('notifications@create');

      $scope.store = $ngRedux;
      $scope.ruleId = $stateParams.rule;

      $scope.activeDropdown = [
        {
          text: 'Active',
          click: 'changeActive(rule, false)',
        },
        {
          text: 'Inactive',
          click: 'changeActive(rule, true)',
        },
      ];

      pubSubService.broadcast('currentTitleChanged', 'AutoShare');
      pubSubService.broadcast('settingsPageChanged', 'rules');

      RulesService.getFormattedRules(true).then((rules) => {
        $scope.rules = rules;
      });

      $scope.changeActive = function (rule, inactive) {
        if (!Permissions.isPermitted(PERMISSIONS_NAMES.notifications.delete)) return;
        rule.inactive = inactive;
        $scope.updateRule(rule.id, { inactive: inactive });
      };

      $scope.createNewRule = function () {
        $state.go('.new');
      };

      $scope.$watchCollection('rules', () => {
        if (!$scope.rules) {
          return;
        }
        $scope.sortedRules = sortBy($scope.rules, 'envId');
        $scope.sortedRules = reject(
          $scope.sortedRules,
          (rule) => rule.deleted || !rule.environment
        );

        var envCount = -1;
        var prevEnv = null;

        $scope.sortedRules.forEach((rule) => {
          if (rule.envId != prevEnv) {
            prevEnv = rule.envId;
            envCount++;
          }
          rule.envCounter = envCount;
        });
      });

      $scope.viewRule = function (rule) {
        $state.go('.view', { rule: rule.id });
      };

      $scope.deleteRule = function (rule) {
        if (!Permissions.isPermitted(PERMISSIONS_NAMES.notifications.delete)) return;
        var newScope = $scope.$new();
        newScope.header = 'Delete AutoShare';
        newScope.title = 'Are you sure you want to delete this AutoShare?';
        newScope.callback = function () {
          return RulesService.deleteRule(rule.id);
        };
        return ModalService.showModal({
          templateUrl: 'widgets/bp_prompt/bp_prompt',
          controller: 'bpPromptController',
          scope: newScope,
        });
      };

      $scope.updateRule = function (id, payload) {
        return RulesService.updateRule(id, payload);
      };

      $scope.newEnvironment = function () {
        previousStateService.saveAndGoto($state.current, $state.params, 'app.environments', {});
      };
    }
  );
