import trim from 'lodash/trim';
import extend from 'lodash/extend';
const {
  getProcessingMessage,
  getErrorMessageTransform,
} = require('angularjs/utils/notification/message.transforms.js');

angular.module('bigpanda').service('SnoozeStore', SnoozeStore);

function SnoozeStore(
  $log,
  $q,
  IncidentsService,
  SnoozeBackendService,
  execution,
  notificationService,
  BeforeUnloadService,
  pubSubService,
  SubscribersService
) {
  const subscribers = new SubscribersService();
  const cache = {};

  this.snooze = snooze;
  this.unsnooze = unsnooze;
  this.getSnoozeData = getSnoozeData;
  this.subscribe = subscribe;

  pubSubService.on('incident.newData', incidentChanged);

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function incidentChanged(event, incident) {
    const id = incident.id;
    delete cache[id];
    getSnoozeData(id).then(subscribers.fireStoreUpdated);
  }

  function getUsageExtendedParams(snoozeConfig) {
    const usageTrackingParams = {};
    if (snoozeConfig.timeframe) {
      extend(usageTrackingParams, { timeframe: snoozeConfig.timeframe });
    }

    extend(usageTrackingParams, {
      annotation: !!(snoozeConfig.annotation && trim(snoozeConfig.annotation) !== ''),
    });

    return usageTrackingParams;
  }

  function getAnalyticsProps(snoozeConfig, incidentSnoozeProps) {
    const usageTrackingParams = getUsageExtendedParams(snoozeConfig);
    return extend(
      {
        id: incidentSnoozeProps.id,
        snoozed: incidentSnoozeProps.snoozed,
        undo: incidentSnoozeProps.undo,
      },
      usageTrackingParams
    );
  }

  function getSnoozeData(incidentId) {
    if (cache[incidentId]) {
      return $q.when(cache[incidentId]);
    }
    // This should not access the IncidentsService but it should access the SnoozeBackendService in order to get the
    // snooze data
    return IncidentsService.getIncidentById(incidentId).then((incident) => {
      const conditions = incident.snooze_config && incident.snooze_config.conditions;
      const snoozeObj = (conditions || []).reduce((acc, curr) => ({ ...acc, ...curr }), {});
      cache[incidentId] = snoozeObj;
      return cache[incidentId];
    });
  }

  function snooze(envId, ids, snoozeConfig) {
    BeforeUnloadService.start();

    $q.all(ids.map((id) => IncidentsService.getIncidentById(id))).then((incidents) => {
      if (ids.length !== incidents.length) {
        $log.error(`SnoozeStore - missing incidents when querying: ${ids}`);
        return;
      }

      const before = incidents.reduce((all, incident) => {
        all[incident.id] = incident.snoozed;
        return all;
      }, {});

      const title = incidents.length > 1 ? `${incidents.length} incidents` : incidents[0].title;

      incidents.forEach((incident) => {
        incident.snoozed = snoozeConfig.snoozedUntil;
        pubSubService.broadcast(
          'incident.snoozed',
          getAnalyticsProps(snoozeConfig, {
            id: incident.id,
            snoozed: !!snoozeConfig.snoozedUntil,
            undo: false,
          })
        );
      });

      notificationService
        .notify(getProcessingMessage(title, snoozeConfig.isUnsnooze ? 'Activating' : 'Snoozing'), {
          undo: undo,
        })
        .then((res) => {
          if (res.code === 'Undo') {
            return;
          }
          execution.runRetry(() => run().then(subscribers.fireStoreUpdated), undo, {
            messageTransform: getErrorMessageTransform(
              title,
              snoozeConfig.isUnsnooze ? 'activate' : 'snooze'
            ),
          });
        })
        .finally(() => {
          BeforeUnloadService.end();
        });

      function run() {
        if (!snoozeConfig.isUnsnooze) {
          const snoozeData = {
            conditions: [{ timestamp: snoozeConfig.snoozedUntil }],
            environment: snoozeConfig.environment,
          };
          if (snoozeConfig.annotation) {
            snoozeData.annotation = snoozeConfig.annotation;
          }
          if (angular.isDefined(snoozeConfig.autoUnsnooze) && snoozeConfig.autoUnsnooze) {
            snoozeData.conditions.push({ incident_updated: snoozeConfig.autoUnsnooze });
          }
          return SnoozeBackendService.snooze(envId, ids, snoozeData);
        }

        return SnoozeBackendService.unsnooze(envId, ids);
      }

      function undo() {
        incidents.forEach((incident) => {
          incident.snoozed = before[incident.id];
          pubSubService.broadcast(
            'incident.snoozed',
            getAnalyticsProps(snoozeConfig, {
              id: incident.id,
              snoozed: !!before[incident.id],
              undo: true,
            })
          );
        });
      }
    });
  }

  function unsnooze(envId, ids) {
    snooze(envId, ids, {
      snoozedUntil: null,
      isUnsnooze: true,
      annotation: 'unmute',
    });
  }
}
