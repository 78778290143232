angular.module('bigpanda.backend.services').service('SharesBackendService', SharesBackendService);

function SharesBackendService($http) {
  function share(ids, targetSystem, params) {
    return $http
      .post(`/web/incidents/escalations/${targetSystem}`, { ids, params })
      .then((res) => res.data.item);
  }

  this.share = share;
}
