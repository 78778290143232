import React from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import { DeleteButtonWithTooltip } from 'react/common/components';
import get from 'lodash/get';
import styled from 'styled-components';
import { bp_gray_03 } from '@bp/pastel/colors';
import StyledIconButton from '../modals/shared_components/StyledIconButton';

const SummaryDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $bp_gray_08;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
`;

const Delimiter = styled.div`
  border: solid 1px ${bp_gray_03};
  height: 17px;
  margin-right: 7px;
`;

const SummaryButtons = styled.div`
  display: flex;
  align-items: center;
`;

const FormulaExpressionSummary = ({
  title,
  hover,
  hoverOut,
  open,
  isPreviewActive,
  arrayName,
  index,
  featureToggles,
  closeErrorMessage,
}) => {
  const isPreviewFormulaFTOpen = get(featureToggles, 'preview_formula', false);
  const isButtonsDisplayed = open || hover;
  const isPreviewDisplayed = isPreviewFormulaFTOpen && (isButtonsDisplayed || isPreviewActive);
  const deleteButtonAction = (mutators) => {
    mutators.splice(arrayName, index);
    closeErrorMessage();
  };
  const actionButtons = (
    <React.Fragment>
      {isButtonsDisplayed && (
        <FormSpy>
          {({ mutators }) => (
            <DeleteButtonWithTooltip
              hoverOut={hoverOut}
              onDelete={() => deleteButtonAction(mutators)}
            />
          )}
        </FormSpy>
      )}
      {isPreviewDisplayed && (
        <React.Fragment>
          <Delimiter />
          <StyledIconButton icon="bp-icon-eye-1" isActive={isPreviewActive} width="75">
            Preview
          </StyledIconButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
  return (
    <SummaryDiv>
      <div>{title}</div>
      <SummaryButtons>{actionButtons}</SummaryButtons>
    </SummaryDiv>
  );
};

FormulaExpressionSummary.propTypes = {
  title: PropTypes.string.isRequired,
  arrayName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  hover: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  isPreviewActive: PropTypes.bool.isRequired,
  hoverOut: PropTypes.func.isRequired,
  featureToggles: PropTypes.shape({}).isRequired,
  closeErrorMessage: PropTypes.func.isRequired,
};

export default FormulaExpressionSummary;
