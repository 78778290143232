import debounce from 'lodash/debounce';
angular.module('bigpanda').directive('userInteractionSessionRenew', userInteraction);

function userInteraction($rootScope, SessionTimeoutWarningService, AuthService) {
  return {
    restrict: 'A',
    link: userInteractionLink,
  };

  function userInteractionLink(scope, element) {
    function sendTokenRequest() {
      if (isLoggedInAndWarningNotdisplayed()) {
        renewToken();
      }
    }

    function isLoggedInAndWarningNotdisplayed() {
      return AuthService.isAuth() && !SessionTimeoutWarningService.warningDisplayed;
    }

    // delaying renewal token call each minute until maxWait time exceeded
    const renewToken = debounce(
      () => {
        $rootScope.$apply(() => {
          // multi-tab session check
          if (AuthService.getAccessToken()) {
            SessionTimeoutWarningService.renewToken();
          } else {
            AuthService.resetUser();
          }
        });
      },
      10000,
      {
        leading: true,
        maxWait: 10000,
      }
    );

    element.on('mousemove scroll keyUp keyDown click', sendTokenRequest);
  }
}
