import bpFetch from 'common/utils/http/fetcher';

export function getRoles() {
  return bpFetch({ url: '/resources/v2.0/roles/permissions' }).then(
    (res) => res.data.orgRolesWithPermissions
  );
}

export function createRole(role) {
  return bpFetch({ url: '/resources/v2.0/roles', method: 'POST', data: { role } }).then(
    (res) => res.data.createdRole
  );
}

export function updateRole(roleId, updatedRole) {
  return bpFetch({
    url: `/resources/v2.0/roles/${roleId}`,
    method: 'PATCH',
    data: { role: updatedRole },
  }).then((res) => res.data.updatedRole);
}

export function deleteRole(roleId) {
  return bpFetch({ url: `/resources/v2.0/roles/${roleId}`, method: 'DELETE' }).then(
    (res) => res.data
  );
}
