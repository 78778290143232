const columnsSettings = () => [
  {
    id: 'value',
    accessor: 'value',
    headerContent: 'Value',
    cellContent: ({ value }) => value,
    tooltipContent: ({ value }) => value,
    minWidth: 50,
    sortable: false,
    copyToClipboard: true,
  },
  {
    id: 'condition',
    accessor: 'condition',
    headerContent: 'Condition',
    cellContent: ({ value }) => value,
    tooltipContent: ({ value }) => value,
    minWidth: 50,
    sortable: false,
    copyToClipboard: true,
  },
];

export default columnsSettings;
