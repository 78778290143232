import debounce from 'lodash/debounce';
import get from 'lodash/get';
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import { Spinner, darkTheme } from '@bp/kung-fu';
import { bp_blue } from '@bp/pastel/colors';
import provideStore from 'react/utils/provideStore';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import actions from '../actions';
import { path } from '../constants';
import IncidentTagsGridCell from './IncidentTagsGridCell';
import IncidentTagsGridNotification from './IncidentTagsGridNotification';
import styles from './IncidentTagsGrid.scss';
import IncidentTagsGridCallV2 from '../../../../../workspaces/apps/incident-tags/src/IncidentTagsGridCellV2/IncidentTagsGridCellV2';
import { isTagDefinitionClosedList } from '../utils';

const evaluateBreakpoint = (config) => (width) =>
  Object.entries(config)
    .sort((a, b) => a[0] - b[0])
    .find((breakpoint) => breakpoint[0] > width)[1]();
const GridBreakpoints = {
  SMALL: 579,
  MEDIUM: 899,
  LARGE: 1389,
  MAX: Infinity,
};
const getColumnCountByWidth = evaluateBreakpoint({
  [GridBreakpoints.SMALL]: () => 1,
  [GridBreakpoints.MEDIUM]: () => 2,
  [GridBreakpoints.LARGE]: () => 3,
  [GridBreakpoints.MAX]: () => 4,
});

class IncidentTagsGrid extends React.Component {
  gridRef = React.createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    const { tagDefinitions, tagsData, featureToggles } = nextProps;
    const renderNewGridCell = get(featureToggles, 'allow_tags_manual_input', true);
    const columnCount = renderNewGridCell
      ? Math.floor(prevState.gridWidth / 320) || 1
      : getColumnCountByWidth(prevState.gridWidth);
    const tagWidth = `${100 / columnCount}%`;
    const possibleTagsInGrid = 2 * columnCount;
    const tagDataIds = tagsData.incidentTagsData.map((tag) => tag.tag_id);
    const filteredTagDefinitions = renderNewGridCell
      ? tagDefinitions.filter((tag) => tagDataIds.includes(tag.id) || tag.canManualInput)
      : tagDefinitions;
    const noValueAndCantEditTags = tagDefinitions.length - filteredTagDefinitions.length;
    const hiddenTags = tagDefinitions.length - possibleTagsInGrid - noValueAndCantEditTags;
    const emptyTagDivCount =
      tagDefinitions.length !== 0 && columnCount !== 0 && tagDefinitions.length > columnCount
        ? IncidentTagsGrid.calculateEmptyTagDivCount(columnCount, filteredTagDefinitions)
        : 0;
    return {
      columnCount,
      tagWidth,
      hiddenTags,
      emptyTagDivCount,
      filteredTagDefinitions,
    };
  }

  state = {
    columnCount: 0,
    tagWidth: '100%',
    showAll: false,
    gridWidth: '100%',
    isEllipsis: {},
  };

  componentDidMount() {
    this.initializeIncidentTags();
    if (this.gridRef.current) {
      this.resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          entries.forEach((entry) => {
            const { gridWidth } = this.state;
            if (gridWidth !== entry.contentRect.width) {
              this.setState({
                gridWidth: entry.contentRect.width,
              });
            }
          });
        });
      });
      this.resizeObserver.observe(this.gridRef.current);
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.gridRef.current);
    }
  }

  initializeIncidentTags = () => {
    const { environmentId, incident, getIncidentTags } = this.props;
    getIncidentTags({ environmentId, incidentId: incident.id });
  };

  toggleEditor = (editedTagId) => {
    const { setEditedTagId } = this.props;
    setEditedTagId(editedTagId);
  };

  isEllipsisNeeded = (isEllipsisNeeded) => {
    this.setState((prevState, props) => ({
      isEllipsis: { ...prevState.isEllipsis, ...isEllipsisNeeded },
    }));
  };

  toggleShowAll = () => {
    const { tagsData } = this.props;
    const { showAll } = this.state;
    if (!(showAll && tagsData.editedTagId !== null)) {
      this.setState({ showAll: !showAll });
    }
  };

  static calculateEmptyTagDivCount = (columnCount, tagDefinitions) => {
    const remainder = tagDefinitions.length % columnCount;
    return remainder === 0 ? remainder : columnCount - remainder;
  };

  render() {
    const { store, environmentId, incident, tagsData, featureToggles } = this.props;
    const {
      showAll,
      tagWidth,
      columnCount,
      hiddenTags,
      emptyTagDivCount,
      isEllipsis,
      gridWidth,
      filteredTagDefinitions,
    } = this.state;
    const isEllipsisNeeded = Object.values(isEllipsis).some((value) => value === true);
    const gridClasses = cn({
      [styles.grid]: true,
      [styles['grid-collapsed']]: !showAll && tagsData.editedTagId === null,
      [styles['grid-collapsed-edited']]: !showAll && tagsData.editedTagId !== null,
    });
    const showAllClasses = cn({
      [styles.showAllButton]: true,
      [styles['showAllButton-disabled']]: showAll && tagsData.editedTagId !== null,
    });
    const borderTagClasses = cn(styles[`separationBorders__${columnCount}_columns`]);
    const bottomSpacerClasses = cn(styles.showAllTopSpacer);
    const renderNewGridCell = get(featureToggles, 'allow_tags_manual_input', true);
    return (
      <ThemeProvider theme={darkTheme}>
        <div ref={this.gridRef} className={styles.root}>
          {!tagsData.isLoading ? (
            <React.Fragment>
              <div className={gridClasses}>
                {filteredTagDefinitions.map((tag, index) => {
                  const { id } = tag;
                  const isVisible = index < filteredTagDefinitions.length - hiddenTags || showAll;
                  const value = tagsData.incidentTagsData.find((tag) => tag.tag_id === id);
                  const closedListValues = isTagDefinitionClosedList(tag)
                    ? tag.config.incident_tag_values
                    : null;
                  return renderNewGridCell ? (
                    <IncidentTagsGridCallV2
                      key={id}
                      name={tag.name}
                      cellType={tag.type}
                      tagWidth={tagWidth}
                      tagDefinitionId={tag.id}
                      environmentId={environmentId}
                      incidentId={incident.id}
                      disabled={!tag.canManualInput}
                      tagData={value}
                      isVisible={isVisible}
                      borderTagClass={borderTagClasses}
                      isEllipsis={!showAll}
                      handleIsValueEllipsis={this.isEllipsisNeeded}
                      closedListValues={closedListValues}
                    />
                  ) : (
                    <IncidentTagsGridCell
                      isVisible={isVisible}
                      key={id}
                      id={id}
                      store={store}
                      tagWidth={tagWidth}
                      gridWidth={gridWidth}
                      borderTagClass={borderTagClasses}
                      environmentId={environmentId}
                      incident={incident}
                      tagDefinition={tag}
                      initialValue={value}
                      editMode={id === tagsData.editedTagId}
                      truncate={!showAll}
                      toggleEditor={this.toggleEditor}
                      handleIsValueEllipsis={this.isEllipsisNeeded}
                    />
                  );
                })}
                {[...Array(emptyTagDivCount)].map((x, i) => {
                  const key = `id_${i}`;
                  return <div key={key} className={borderTagClasses} style={{ width: tagWidth }} />;
                })}
              </div>
              {!showAll ? <div className={bottomSpacerClasses} /> : null}
              {(isEllipsisNeeded || hiddenTags > 0) && (
                <div className={styles.showAll}>
                  <button className={showAllClasses} onClick={this.toggleShowAll}>
                    {showAll ? (
                      <React.Fragment>
                        <span className={styles.showAllButton__label}>Show less</span>
                        <i className="bp-icon bp-icon-arrow-up" />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span className={styles.showAllButton__label}>
                          <span>Show more </span>
                          {hiddenTags > 0 && (
                            <span className={styles.showAllButton__count}>({hiddenTags})</span>
                          )}
                        </span>
                        <i className="bp-icon bp-icon-arrow-down-thick" />
                      </React.Fragment>
                    )}
                  </button>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className={styles.spinner}>
              <Spinner color={bp_blue} />
            </div>
          )}
          <IncidentTagsGridNotification store={store} />
        </div>
      </ThemeProvider>
    );
  }
}

IncidentTagsGrid.propTypes = {
  store: PropTypes.object.isRequired, //eslint-disable-line
  environmentId: PropTypes.string.isRequired,
  incident: PropTypes.shape({ id: PropTypes.string }).isRequired,
  getIncidentTags: PropTypes.func.isRequired,
  setEditedTagId: PropTypes.func.isRequired,
  tagsData: PropTypes.object.isRequired, //eslint-disable-line
  featureToggles: PropTypes.shape({ allow_tags_manual_input: PropTypes.bool }).isRequired,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  const featureToggles = featureTogglesSelectors.getFeatureToggles(state);
  const tagsData = get(state, path);
  return {
    organization,
    user,
    featureToggles,
    tagsData,
  };
};

const mapDispatchToProps = {
  getIncidentTags: actions.getIncidentTags,
  setEditedTagId: actions.setEditedTagId,
};

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(IncidentTagsGrid))
);
