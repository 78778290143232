import { useEffect } from 'react';

const UseEventListener = (eventType, callback, dependencyArray) => {
  useEffect(() => {
    window.addEventListener(eventType, callback);

    return () => {
      window.removeEventListener(eventType, callback);
    };
  }, dependencyArray);
};

export default UseEventListener;
