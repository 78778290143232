import { react2angular } from 'react2angular';
import ApiKeyInfo from './components/ApiKeyInfo';

const routes = {};

routes.appSettingsApiKeysInfo = {
  url: '/:apiKeyId',
  views: {
    'master-detail-info@app.settings.api_keys': {
      template:
        '<api-key-info class="react-settings-info" api-key-id="stateParams.apiKeyId" store="store" />',
      controller: ($scope, $ngRedux, $stateParams) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
      },
    },
  },
};

angular
  .module('bigpanda')
  .component('apiKeyInfo', react2angular(ApiKeyInfo, ['apiKeyId', 'store']));

export default routes;
