import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import SettingsListItemActions from 'react/layout/settings/components/SettingsListItemActions';
import actions from '../actions';
import selectors from '../selectors';
import CustomTagModal from '../modals/CustomTagModal';

const CustomTagsActions = ({ customTag, deleteCustomTag, actionPermissions }) => {
  const removeCustomTag = () => deleteCustomTag(customTag.id);

  const messages = {
    edit: `Cannot Edit custom tag '${customTag.destination}'`,
    delete: `Cannot Delete custom tag '${customTag.destination}'`,
  };

  return (
    <SettingsListItemActions
      item={customTag}
      modelName="Tag"
      messages={messages}
      editProps={{
        disabled: !actionPermissions.hasEditPermission,
        modalProps: { currentCustomTagId: customTag.id, title: 'Edit Custom Tag' },
        modal: CustomTagModal,
      }}
      duplicateProps={{
        disabled: !actionPermissions.hasDuplicatePermission,
        modalProps: { currentCustomTagId: customTag.id },
        modal: CustomTagModal,
      }}
      deleteProps={{
        disabled: !actionPermissions.hasDeletePermission,
        action: removeCustomTag,
        modalProps: {
          contentTitleType: 'string',
          contentTitle: customTag.destination,
          content: 'Are you sure you want to delete this custom tag?',
        },
      }}
    />
  );
};

CustomTagsActions.propTypes = {
  customTag: PropTypes.shape({ id: PropTypes.string, destination: PropTypes.string }).isRequired,
  deleteCustomTag: PropTypes.func.isRequired,
  actionPermissions: PropTypes.shape({
    hasDeletePermission: PropTypes.bool,
    hasEditPermission: PropTypes.bool,
    hasDuplicatePermission: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    actionPermissions: selectors.getActionsPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCustomTag: (customTagId) => dispatch(actions.deleteCustomTag(customTagId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(CustomTagsActions));
