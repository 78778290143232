import get from 'lodash/get';
angular.module('bigpanda').service('ReduxBridgeService', ReduxBridgeService);
function ReduxBridgeService(ReduxSubscriberService) {
  this.subscribe = subscribe;

  const reduxStoreLocations = {
    users: 'common.users.usersList',
    contacts: 'common.contacts.contactsList',
    correlationConfig: 'common.correlationConfig.correlationConfig',
    changes: 'modules.changes',
  };

  /**
   * Subscribe a callback to a named location as seen in reduxStoreLocations.
   * The callback will be called every time the mapped location in the redux store is changed.
   *
   * @param namedLocation A short name given to a path in the redux store.
   * @param callback A callback to call when the store is updated. Receives the changed value (1st param) and the entire state (2nd param).
   *
   * @throws an Error if namedLocation is not mapped to a redux store path.
   * @return a function which serves as an unsubscribe hook. Calling it (with no arguments) will remove the subscription.
   */
  function subscribe(namedLocation, callback) {
    const reduxPath = reduxStoreLocations[namedLocation] || namedLocation;
    if (!reduxPath) {
      throw new Error(
        `Named location "${namedLocation}" was not mapped. Map it to a redux store location to use ReduxBridgeService.`
      );
    }
    return ReduxSubscriberService.subscribe(reduxPath, (state) =>
      callback(get(state, reduxPath), state)
    );
  }

  return this;
}
