angular.module('bigpanda').controller('EnvironmentsGroupPopupCtrl', EnvironmentsGroupPopupCtrl);

function EnvironmentsGroupPopupCtrl(
  $scope,
  EnvironmentGroupsStore,
  environmentGroup,
  allGroups,
  onSuccess,
  EnvironmentGroupConstants,
  deepCloneObject
) {
  const vm = this;

  const group = deepCloneObject.cloneDeep(environmentGroup);

  vm.groupName = group ? group.name : null;
  vm.getAllGroupNames = getAllGroupNames;

  $scope.dialogTitle = group ? 'Update Group' : 'Create a New Group';
  $scope.titleIconClass = group ? 'bp-icon-edit' : 'bp-icon-plus-icon';
  $scope.submitButtonCaption = group ? 'Update Group' : 'Create Group';
  $scope.cancelButtonCaption = 'Cancel';
  $scope.bodyTemplateClass = 'environment-group-modal-content';
  $scope.EnvironmentGroupConstants = EnvironmentGroupConstants;

  $scope.preSave = preSave;

  function preSave() {
    let promise = null;

    if (group) {
      group.name = vm.groupName;
      promise = EnvironmentGroupsStore.update(group);
    } else {
      promise = EnvironmentGroupsStore.create({ name: vm.groupName });
    }

    promise &&
      promise.then((persistedGroup) => {
        onSuccess(persistedGroup);
      });

    return promise;
  }

  function getAllGroupNames() {
    return allGroups.map((group) => group.name);
  }
}
