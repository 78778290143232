angular.module('bigpanda.utils').service('UserFeatureTogglesService', UserFeatureTogglesService);

function UserFeatureTogglesService(PropertiesLookupService) {
  let lookup = null;
  let callbacks = [];

  this.init = function init(userData) {
    lookup = new PropertiesLookupService(userData, 'featureToggles');
    callbacks = callbacks.reduce((acc, callback) => {
      if (typeof callback.func === 'function') {
        callback.func(this);
      }
      if (callback.persist) {
        acc.push(callback);
      }
      return acc;
    }, []);
  };

  this.register = function register(callback) {
    callbacks.push(callback);
  };

  this.getToggle = function getToggle(toggleName, propertyName) {
    if (!lookup) {
      return false; // test mode
    }
    const toggle = lookup.getProperty(toggleName);
    if (propertyName && Array.isArray(toggle)) {
      return toggle.indexOf(propertyName) >= 0;
    }
    return toggle;
  };
}
