import React from 'react';
import { OrderingIcon } from '@bp/kung-fu';
import { BamModal } from '@bp/bam';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import actions from '../../actions';
import { tagPropType } from '../../propTypes';
import AlertTagOrdering from './AlertTagOrdering';
import styles from './AlertTagOrdering.scss';

class AlertTagModal extends React.PureComponent {
  onDragEnd = (draggedItems) => {
    if (!draggedItems.destination) return;
    this.props.setDraggedItems(draggedItems);
  };

  handleClose = () => {
    this.ref.close();
  };

  render() {
    const { triggerProps, title, titleIcon } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <BamModal
          className={styles.modal}
          ref={(r) => {
            this.ref = r;
          }}
          trigger={
            <ModalTriggerButton
              {...triggerProps}
              ariaLabel="execution order"
              icon={<OrderingIcon />}
            />
          }
          titleIcon={titleIcon}
          title={title}
          formMode
          closeOnEscape={false}
        >
          <AlertTagOrdering {...this.props} closeModal={this.handleClose} />
        </BamModal>
      </DragDropContext>
    );
  }
}

AlertTagModal.propTypes = {
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
    displayButtonText: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string.isRequired,
  duplicate: PropTypes.bool,
  tag: tagPropType,
  setDraggedItems: PropTypes.func.isRequired,
};

AlertTagModal.defaultProps = {
  tag: undefined,
};

const mapDispatchToProps = {
  setDraggedItems: actions.setDraggedItems,
  clearPreview: actions.clearPreviewAlerts,
};

export default connect(null, mapDispatchToProps)(AlertTagModal);
