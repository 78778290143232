import DisplayCommon from '@bp/display-common';
import { phoneRegex, emailRegex } from '../../../../../react/helpers/regex';

const systems = {
  sms: 'sms.sms',
  email: 'email.email',
};

const targetSystemSchema = {
  [systems.sms]: {
    validate: (value) => phoneRegex.test(value),
    propertyName: 'phone_number',
  },
  [systems.email]: {
    validate: (value) => emailRegex.test(value),
    propertyName: 'email',
  },
};

const targetSystemConfig = DisplayCommon.getTargetSystemsTemplates();

export { systems, targetSystemSchema, targetSystemConfig };
