import { react2angular } from 'react2angular';
import MaintenancePlans from '../../../../../../workspaces/apps/maintenance-plans/maintenance_plans_list/components/MaintenancePlans';
import actions from './actions';

const routes = {};
const template = `<section class="bp-master-detail-view">
    <maintenance-plans class="left-panel" store="store" query-client="queryClient"></maintenance-plans>
    <div class="spacer"></div>
    <div ui-view="master-detail-info" class="master-detail-info" ></div>
  </section>`;

routes.appSettingsPlannedMaintenance = {
  url: '/planned-maintenance',
  views: {
    'settings-page-view': {
      template: template,
      controller: ($scope, $ngRedux, QueryClientService) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.queryClient = QueryClientService.getQueryClient();
      },
    },
    'master-detail-info@app.settings.planned_maintenance': {
      template:
        '<maintenance-plan-info class="react-settings-info" store="store" query-client="queryClient" />',
      controller: ($scope, $ngRedux, QueryClientService) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.queryClient = QueryClientService.getQueryClient();
      },
    },
  },
  onEnter: ($transitions, pubSubService) => {
    'ngInject';

    $transitions.onSuccess({ to: 'app.settings.planned_maintenance' }, () => {
      pubSubService.broadcast('settingsPageChanged', 'maintenancePlans');
    });
  },
  onExit: ($ngRedux) => {
    'ngInject';

    $ngRedux.dispatch(actions.routeExit());
  },
};

angular
  .module('bigpanda')
  .component('maintenancePlans', react2angular(MaintenancePlans, ['store', 'queryClient']));

export default routes;
