import get from 'lodash/get';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { HelpTooltip, Tooltip, darkTheme } from '@bp/kung-fu';
import { hot } from 'react-hot-loader';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isItemUnique } from 'react/common/correlation_config/utils';
import { validateTagRegex } from 'common/endpoints/tags';
import { getCorrelationConfig } from 'common/endpoints/correlation-config';
import { buildParserAndGenerateQuery } from 'common/modules/settings/QuerySourceGenerator';
import { propertyNameRegex } from 'react/helpers';
import {
  BamAddNoteField,
  BamBpqlInput,
  BamCheckbox,
  BamDropdownField,
  BamForm,
  BamInput,
  BamRadioGroupField,
} from '@bp/bam';
import styles from './custom_tag_form.scss';
import CustomTagPreview from './CustomTagPreview';
import { TAG_TYPE } from '../constants';

const getIntegration = (customTag) =>
  customTag && customTag.sourceSystem ? customTag.sourceSystem.value : null;

const UNIQUE_KEYS_FOR_COMPOSITION_TAG = ['destination', 'query', 'template', 'type'];
const DISPLAYED_TAGS = 100;
const UNIQUE_KEYS_FOR_EXTRACTION_TAG = [...UNIQUE_KEYS_FOR_COMPOSITION_TAG, 'source', 'pattern'];
const EXTRACTION_PREVIEW_FIELDS = ['integration', 'source', 'displayQuery'];
const COMPOSITION_PREVIEW_FIELDS = ['integration', 'template', 'displayQuery'];
const FIELDS_FOR_RERENDER = [
  'type',
  'integration',
  'source',
  'template',
  'displayQuery',
  'pattern',
];
const TagType = TAG_TYPE;
class CustomTagForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formValues = {};
    this.state = {
      active: get(props, 'customTag.active', true),
      displayDuplicateTagError: false,
      hasVisitedCompositionTemplate: !!props.customTag,
    };
  }

  onActiveToggle = (inactive) => {
    this.setState({ active: !inactive });
  };

  // eslint-disable-next-line class-methods-use-this
  getTagsOfSourceSystem(tagsArray, integration) {
    return (tagsArray || []).filter(
      (t) => !!t.sourceSystems.find((s) => integration.split('.')[0] === s.parentSourceSystem)
    );
  }

  setHasVisitedCompositionTemplate = () => this.setState({ hasVisitedCompositionTemplate: true });

  handleSubmit = async (values) => {
    const { customTag, duplicate, updateCustomTag, addCustomTag, close } = this.props;
    const updateMode = customTag && !duplicate;

    const payload = this.createCustomTagPayload(values, updateMode ? customTag.id : null);

    const currentCorrelationConfig = await getCorrelationConfig();
    const uniqueFields =
      payload.type === TagType.Extraction
        ? UNIQUE_KEYS_FOR_EXTRACTION_TAG
        : UNIQUE_KEYS_FOR_COMPOSITION_TAG;
    if (!isItemUnique(payload, currentCorrelationConfig.transformations, uniqueFields)) {
      this.setState({ displayDuplicateTagError: true });
      return;
    }

    payload.currentCorrelationConfig = currentCorrelationConfig;
    if (updateMode) {
      updateCustomTag(payload);
    } else {
      addCustomTag(payload);
    }

    close();
  };

  handleChange = (values) => {
    const formValues = { ...values };

    // We do not save it on the state for code readability (so that we won't have to read some fields from the formValues
    // and override some of them (in certain cases only) from the state - this is why we use forceUpdate;
    if (FIELDS_FOR_RERENDER.some((field) => formValues[field] !== this.formValues[field])) {
      if (
        Object.keys(this.formValues).length &&
        formValues.integration !== this.formValues.integration
      ) {
        formValues.source = null;
      }
      this.setState({ displayDuplicateTagError: false });
      this.forceUpdate();
    }

    this.formValues = formValues;
  };

  isValidForPreview = () => {
    if (!this.formRef) {
      return !!this.props.customTag;
    }

    if (!this.state.hasVisitedCompositionTemplate && this.formValues.type === TagType.Composition) {
      return false;
    }

    if (!this.formValues.source && this.formValues.type === TagType.Extraction) {
      return false;
    }

    const { errors } = this.formRef.formRef.form.getState();
    const fieldsToValidateForPreview =
      this.formValues.type === TagType.Extraction
        ? EXTRACTION_PREVIEW_FIELDS
        : COMPOSITION_PREVIEW_FIELDS;
    return !fieldsToValidateForPreview.some(
      (field) => Object.entries(errors).find(([key, val]) => key === field && val) != null
    );
  };

  isValidPattern = () => {
    if (!this.formRef) {
      return !!this.props.customTag;
    }

    return !this.formRef.formRef.form.getState().errors.pattern;
  };

  createCustomTagPayload = (values, customTagId) => {
    const {
      displayQuery,
      integration,
      destination,
      type,
      source,
      pattern,
      template,
      description,
    } = values;

    const isExtraction = type === TagType.Extraction;
    const generatedTemplate = isExtraction ? '$1' : template;
    const extractionSource = isExtraction ? source : null;
    const extractionPattern = isExtraction ? pattern : null;

    return {
      query: buildParserAndGenerateQuery(displayQuery, [integration]),
      active: this.state.active,
      destination: destination.toLowerCase(),
      source: extractionSource,
      type: type,
      pattern: extractionPattern,
      template: generatedTemplate,
      id: customTagId,
      description: description,
    };
  };

  validateDisplayQuery = (val) => (val ? BamBpqlInput.helpers.BpqlInputValidator(val) : undefined);

  validateName = (val) => {
    if (!val || val.length === 0) {
      return 'Tag name is required';
    }
    return propertyNameRegex.test(val) ? undefined : 'Tag name is not valid';
  };

  validateSource = (val) => (!val ? 'Please select a base tag' : undefined);

  validateIntegration = (val) => (!val ? 'Please select a system' : undefined);

  validateCompositionTemplate = (val) =>
    !val || val === '' ? 'Please enter a tag composition' : undefined;

  hasRegexValidationError = (val) => {
    if (!val) {
      return 'Regex is required';
    }
    try {
      const regex = RegExp(val);
      const groupCount = new RegExp(`${regex.toString()}|`).exec('').length - 1;
      if (groupCount < 1) {
        return 'Use exactly one Regex group';
      }
      return validateTagRegex(val)
        .then(() => undefined)
        .catch(() => 'Invalid regex, please recheck syntax');
    } catch (e) {
      return e.message;
    }
  };

  render() {
    const { customTag, duplicate, integrationOptions, close, unifiedTags } = this.props;

    const sourceSystems = [
      { key: '*', text: 'All Systems', value: '*', tooltip: 'All Systems' },
      ...(integrationOptions || []).map((i) => ({
        key: i.value,
        text: i.display,
        value: i.value,
        tooltip: i.display,
      })),
    ];

    const formValues = customTag
      ? {
          ...customTag,
          integration: getIntegration(customTag),
          description: customTag.metadata.description,
          ...this.formValues,
        }
      : { type: TagType.Extraction, ...this.formValues };

    const { type, integration, source, template, displayQuery, pattern } = formValues;

    let tagsMatchingSourceSystem = [];
    if (integration) {
      const sourceSystemAlertTags =
        integration === '*' ? unifiedTags : this.getTagsOfSourceSystem(unifiedTags, integration);
      tagsMatchingSourceSystem = (sourceSystemAlertTags || []).map(({ name }) => ({
        key: name,
        text: name,
        value: name,
      }));
    }

    const footer = (
      <div className={styles['form-footer']}>
        {this.state.displayDuplicateTagError && (
          <div className={styles['duplicate-tag-error']}>
            <i className={classnames(styles['warning-icon'], 'bp-icon-warning')} />
            <span className={styles['error-message']}>
              This tag is identical to an existing one.
            </span>
          </div>
        )}
        {(!customTag || duplicate) && (
          <div className={styles['inactive-checkbox']}>
            <BamCheckbox
              label="Create as 'Inactive'"
              onClick={this.onActiveToggle}
              defaultChecked={!this.state.active}
            />
            <HelpTooltip text="Save the tag definition without activating it" />
          </div>
        )}
      </div>
    );

    return (
      <ThemeProvider theme={darkTheme}>
        <BamForm
          id="CustomTagForm"
          ref={(r) => {
            this.formRef = r;
          }}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
          rightPane={
            <CustomTagPreview
              source={source}
              template={template}
              type={type}
              integration={integration}
              displayQuery={displayQuery}
              pattern={pattern}
              validForPreview={this.isValidForPreview()}
              patternValid={this.isValidPattern()}
            />
          }
          footer={footer}
          scrollable
          initialValues={formValues}
          positiveButton={{
            text: `${customTag && !duplicate ? 'Update Tag' : 'Create Tag'}`,
          }}
          closeButton={{
            text: 'Cancel',
            onClick: close,
          }}
        >
          <Field
            name="destination"
            title="Tag Name"
            component={BamInput}
            autoFocus
            placeholder={type === TagType.Extraction ? 'e.g. cluster' : 'e.g. wiki_url'}
            maxLength={100}
            validate={this.validateName}
          />
          <Field
            name="type"
            title="Type"
            component={BamRadioGroupField}
            options={[
              { value: TagType.Extraction, text: 'Extraction', disabled: !!customTag },
              { value: TagType.Composition, text: 'Composition', disabled: !!customTag },
            ]}
            inline
          />
          <Field
            name="integration"
            title="Source System"
            component={BamDropdownField}
            options={sourceSystems}
            placeholder="Select a Source System..."
            validate={this.validateIntegration}
            className="custom-tag-form-source-system"
            validateOnBlur
          />
          <div className={styles[type !== TagType.Extraction ? 'hidden-fields' : '']}>
            <div className={styles.sourceTagWrapper}>
              <Tooltip
                isActive={!integration}
                text="Select a source system in order to select a tag"
                placement="bottom-start"
                maxWidth="370px"
              >
                <Field
                  name="source"
                  title="Source Tag"
                  component={BamDropdownField}
                  className="source-tag-dropdown"
                  options={tagsMatchingSourceSystem}
                  limitDisplayedItems={DISPLAYED_TAGS}
                  placeholder="Select a Tag..."
                  validate={(values) =>
                    type !== TagType.Extraction ? undefined : this.validateSource(values)
                  }
                  validateOnBlur
                  disabled={!integration}
                />
              </Tooltip>
            </div>
            <Field
              name="pattern"
              title="Extraction Regex"
              hint="Regular expression that defines the pattern for extracting the custom tag value from the source tag value"
              component={BamInput}
              placeholder="e.g. ^\D{3}-(\D+)-"
              validate={(values) =>
                type !== TagType.Extraction ? undefined : this.hasRegexValidationError(values)
              }
              validateOnBlur
              fixedFont
            />
          </div>
          <div className={styles[type !== TagType.Composition ? 'hidden-fields' : '']}>
            <Field
              name="template"
              title="Composition Template"
              hint="Format for building the tag value from existing tag values and additional information"
              component={BamInput}
              placeholder="e.g. http://wiki.acme.com/${host}/${check}"
              validate={(values) =>
                type !== TagType.Composition ? undefined : this.validateCompositionTemplate(values)
              }
              validateOnBlur
              onBlur={this.setHasVisitedCompositionTemplate}
            />
          </div>
          <Field
            name="displayQuery"
            component={BamBpqlInput}
            title="Query Filter"
            optional
            tags={unifiedTags}
            placeholder="e.g. host=*.com"
            validate={this.validateDisplayQuery}
            validateOnBlur
            fixedFont
            interactiveTooltip
          />
          <Field name="description" component={BamAddNoteField} />
        </BamForm>
      </ThemeProvider>
    );
  }
}

CustomTagForm.propTypes = {
  updateCustomTag: PropTypes.func.isRequired,
  addCustomTag: PropTypes.func.isRequired,
  customTag: PropTypes.shape({
    id: PropTypes.string,
    metadata: PropTypes.shape({
      description: PropTypes.string,
    }),
    user: PropTypes.shape({}),
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
  }),
  duplicate: PropTypes.bool,
  unifiedTags: PropTypes.arrayOf(PropTypes.shape({})),
  integrationOptions: PropTypes.arrayOf(PropTypes.shape({})),
  close: PropTypes.func.isRequired,
};

CustomTagForm.defaultProps = {
  customTag: undefined,
  duplicate: false,
  unifiedTags: undefined,
  integrationOptions: [],
};

export default hot(module)(CustomTagForm);
