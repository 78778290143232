import moment from 'moment';
import BpTimeSince, { getTimeAgo } from 'common/utils/BpTimeSince';
import sorting from './sorting';

// eslint-disable-next-line import/prefer-default-export
export function getIncidentTimestamp(incident, folderId, sortBy = sorting.options.lastChange) {
  let timeStampDisplay;
  let timeTitleTooltip;

  switch (sortBy) {
    case sorting.options.start:
    case sorting.options.created: {
      timeStampDisplay = BpTimeSince(incident[sortBy]);
      timeTitleTooltip = formatTooltip(incident[sortBy]);
      break;
    }
    case sorting.options.duration: {
      let incidentDurationParams;
      let incidentTimeStamp;

      if (folderId === 'resolved') {
        incidentDurationParams = [incident.start, incident.end];
        incidentTimeStamp = incident.end - incident.start;
      } else {
        incidentDurationParams = [incident.start, moment().unix()];
        incidentTimeStamp = moment().unix() - incident.start;
      }

      timeStampDisplay = getTimeAgo(...incidentDurationParams);
      timeTitleTooltip = formatTooltip(incidentTimeStamp);
      break;
    }
    default: {
      timeStampDisplay = BpTimeSince(incident.last_change);
      timeTitleTooltip = formatTooltip(incident.last_change);
    }
  }

  const timeTitle = sorting.titles[sortBy];
  return { timeTitle, timeStampDisplay, timeTitleTooltip, sortBy };
}

function formatTooltip(timestamp) {
  return timestamp ? moment.unix(timestamp).calendar() : '';
}
