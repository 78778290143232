import createValidator from '../../../common/validations';
import allValidationStrategy from '../../../common/validations/strategies/all';

const emptyField = (value, message) => (!value || value.length === 0 ? message : null);

const validators = [
  {
    name: 'IssuerIDInput',
    validate: ({ IssuerIDInput }) => emptyField(IssuerIDInput, 'Issuer ID is mandatory'),
  },
  {
    name: 'EndPointInput',
    validate: ({ EndPointInput }) =>
      emptyField(EndPointInput, 'SAML 2.0 Endpoint (HTTP) is mandatory'),
  },
  {
    name: 'CertificateInput',
    validate: ({ CertificateInput }) =>
      emptyField(CertificateInput, 'X.509 Certificate is mandatory'),
  },
];

export default createValidator(allValidationStrategy, validators);
