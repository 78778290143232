import get from 'lodash/get';
import { hot } from 'react-hot-loader';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadEnrichedUsers } from 'react/common/enriched_users/actions';
import SettingsListScreen from 'react/layout/settings/SettingsListScreen';
import SettingsListEmptyState from 'react/layout/settings/components/SettingsListEmptyState';
import { DropdownOptionType } from 'react/common/components/FiltersDropdown/FiltersDropdown';
import provideStore from 'react/utils/provideStore';
import { BamConfirmModal, BamCopyContent } from '@bp/bam';
import { createApiKey as createApiKeyRequest } from 'common/endpoints/api-keys';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import actions from '../../actions';
import selectors from '../../selectors';
import { showFailureMessageForApiKey } from '../../epic';
import ApiKeyModal from '../../modals/ApiKeyModal';
import ListItem from './list/ListItem';
import FiltersComponent from './FiltersComponent';
import SortTooltip from './SortTooltip';
import { SORT_MODES, SortShape, ANY_OPTION_VALUE } from '../../constants';

class ApiKeysManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lastCreatedApiKey: null };
  }

  componentDidMount() {
    this.props.loadApiKeys();
    this.props.loadEnrichedUsers();
  }

  createApiKey = async (payload) => {
    try {
      const apiKey = await createApiKeyRequest(payload);
      this.setState({ lastCreatedApiKey: apiKey.key }, () => this.apiKeyCreatedModal.modal.open());
      window.location.href = `/#/app/settings/api_keys/${apiKey.id}`;
    } catch (e) {
      showFailureMessageForApiKey('create');
    }
    this.props.loadApiKeys();
  };

  render() {
    const {
      apiKeys,
      filterText,
      updateFilterText,
      isLoading,
      sortMode,
      updateSort,
      filtersDropDownState,
      filtersDropDownItems,
      featureToggles,
      updateFiltersDropDowns,
    } = this.props;
    const settingsSortingFT = get(featureToggles, 'frontier_settings_sorting_and_filters', false);
    const sortModes = settingsSortingFT ? SORT_MODES : undefined;
    const filterDDItems = settingsSortingFT ? filtersDropDownItems : undefined;

    return (
      <ThemeProvider theme={darkTheme}>
        <React.Fragment>
          <SettingsListScreen
            loading={isLoading}
            items={apiKeys}
            noItemsTitle="No API Keys Added Yet"
            createItemProps={{
              modal: {
                title: 'Create a New API Key',
                titleIcon: 'bp-icon-plus-icon',
                component: ApiKeyModal,
                onCreate: this.createApiKey,
              },
              itemName: 'API key',
              buttonText: 'New Key',
              tileText: 'Create New API key',
            }}
            listItem={ListItem}
            listItemProp="apiKey"
            permissionName="apiKeys"
            headerName="API Keys"
            filterPlaceholderText="Find API Keys..."
            sortModes={sortModes}
            sortMode={sortMode}
            sortTooltip={SortTooltip()}
            updateSort={updateSort}
            updateFilterText={updateFilterText}
            filterText={filterText}
            renderFilterRowElement={() =>
              filterDDItems &&
              filtersDropDownState && (
                <FiltersComponent
                  filtersDropDownState={filtersDropDownState}
                  onChange={updateFiltersDropDowns}
                  data={filterDDItems}
                />
              )
            }
            renderNoResultsByFilters={() =>
              filterText ||
              Object.values(filtersDropDownState).some(
                (dropDownState) => dropDownState.value !== ANY_OPTION_VALUE
              ) ? (
                <SettingsListEmptyState
                  title="No Matches Found"
                  subtitle="Try refining your query and search filters"
                />
              ) : null
            }
          />
          {this.state.lastCreatedApiKey && (
            <BamConfirmModal
              ref={(r) => {
                this.apiKeyCreatedModal = r;
              }}
              icon="bp-icon-resolve-full"
              trigger={<div />}
              content={
                <BamCopyContent
                  content={this.state.lastCreatedApiKey}
                  title="Copy and save your API key"
                />
              }
              note="Important! Copy and save your API key now as it will not be retrievable after this point"
              headerTitle="API Key created Successfully"
              warning
              destructive={false}
            />
          )}
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

ApiKeysManagement.propTypes = {
  apiKeys: PropTypes.arrayOf(PropTypes.object),
  featureToggles: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadApiKeys: PropTypes.func.isRequired,
  loadEnrichedUsers: PropTypes.func.isRequired,
  updateFilterText: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  updateSort: PropTypes.func.isRequired,
  sortMode: SortShape.isRequired,
  updateFiltersDropDowns: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    name: DropdownOptionType,
    status: DropdownOptionType,
  }),
  filtersDropDownItems: PropTypes.shape({
    name: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }),
};

ApiKeysManagement.defaultProps = {
  apiKeys: undefined,
  filterText: '',
  filtersDropDownState: undefined,
  filtersDropDownItems: undefined,
};

function mapStateToProps(state) {
  return {
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
    isLoading: selectors.isLoading(state),
    apiKeys: selectors.getApiKeys(state),
    filterText: selectors.getFilterText(state),
    filtersDropDownState: selectors.getFiltersDropDownState(state),
    filtersDropDownItems: selectors.getFiltersDropDownItems(state),
    sortMode: selectors.getSortMode(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadApiKeys: () => dispatch(actions.loadApiKeys()),
    loadEnrichedUsers: () => dispatch(loadEnrichedUsers()),
    updateFilterText: (filterText) => dispatch(actions.updateFilterText(filterText)),
    updateSort: (payload) => dispatch(actions.updateSort(payload)),
    updateFiltersDropDowns: (filterState) => dispatch(actions.updateFiltersDropDowns(filterState)),
  };
}

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(ApiKeysManagement))
);
