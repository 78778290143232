/* eslint-disable no-unused-expressions */
/* eslint-disable object-shorthand */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { HBox, Avatar, darkTheme, Select, ActionableBadge, StatusEnum, SCHBox } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { bpUserImg, bigPandaUserName } from 'common/constants';
import get from 'lodash/get';
import RelatedChangeActivityRecordTooltip from '../RelatedChangeActivityRecordTooltip/RelatedChangeActivityRecordTooltip';
import style from './related_metadata.scss';

export const MATCH_CERTAINTY_TYPES = {
  NONE: 'NONE',
  MATCH: 'MATCH',
  SUSPECT: 'SUSPECT',
};

const matchDropDownOptions = [
  {
    value: MATCH_CERTAINTY_TYPES.NONE,
    text: capitalize(MATCH_CERTAINTY_TYPES.NONE),
    color: 'bp_gray_02',
    status: StatusEnum.ACKNOWLEDGED,
  },
  {
    value: MATCH_CERTAINTY_TYPES.SUSPECT,
    text: capitalize(MATCH_CERTAINTY_TYPES.SUSPECT),
    weight: 'bold',
    textColor: 'bp_white',
    status: StatusEnum.SUSPECT,
  },
  {
    value: MATCH_CERTAINTY_TYPES.MATCH,
    text: capitalize(MATCH_CERTAINTY_TYPES.MATCH),
    weight: 'bold',
    textColor: 'bp_white',
    status: StatusEnum.MATCH,
  },
];

class RelatedMetadata extends React.PureComponent {
  contextRef = React.createRef(); // eslint-disable-line
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { user, defaultSelected, change } = this.props;
    const { change: prevChange } = prevProps;
    // in case the row changed we need to "reset" our state so match_certainty and user will be valid
    if (get(prevChange, '_id') !== get(change, '_id')) {
      this.setState({ user, selected: defaultSelected }); //eslint-disable-line
    }
  }

  onChange = (name, { value }) => {
    const { change, currentUser, incidentId, setPopupProps } = this.props;
    if (get(change, 'relatedMetadata.match_certainty') === value) {
      return;
    }
    this.setState({ selected: value, user: currentUser }, () => {
      const metadataPayload = {
        relatedMetadata: {
          matchCertainty: value,
          incidentId,
        },
        change,
      };
      const popupProps = {
        open: true,
        context: this.contextRef,
        position: 'left center',
        metadataPayload,
        dropdownClickableHandler: this.dropdownClickableHandler,
      };

      setPopupProps(popupProps);
    });
  };

  dropdownClickableHandler = (clickable) => {
    this.setState({ clickable });
  };

  getUserIcon = (relatedMetadata, user, suggestedByBP) =>
    (suggestedByBP || user) && (
      <div ref={this.contextRef}>
        <Avatar
          email={suggestedByBP ? undefined : user.email || user.username || ''}
          name={(suggestedByBP && bigPandaUserName) || user.name || ''}
          size="x-small"
          url={suggestedByBP ? bpUserImg : undefined}
        />
      </div>
    );

  render() {
    const { selected: stateSelectedMatchCertainty, user: stateUser, clickable } = this.state;
    const {
      user: propUser,
      relatedMetadata = {},
      defaultSelected: defaultSelectedMatchCertainty,
      incidentId,
      currentUser,
      setPopupProps,
      disabled,
      ...props
    } = this.props;

    const {
      updated_at: updatedAt,
      comment,
      rich_comment: richComment,
      suggested_by_bigpanda: suggestedByBP,
    } = relatedMetadata;
    const matchCertainty = stateSelectedMatchCertainty || defaultSelectedMatchCertainty;
    const user = stateUser || propUser;
    const showIcon = !!user || suggestedByBP;
    const UserIcon = showIcon && this.getUserIcon(relatedMetadata, user, suggestedByBP);
    const withRelatedChangesActivityRecordTooltip = (userIcon) =>
      userIcon ? (
        <RelatedChangeActivityRecordTooltip
          position="bottom-end"
          relatedChange={{
            user,
            updatedAt,
            matchCertainty,
            comment,
            richComment,
            suggestedByBP,
          }}
          trigger={<span className={style.iconWrapper}>{userIcon}</span>}
        />
      ) : null;

    const selectedOption = matchDropDownOptions.find((item) => item.value === matchCertainty);

    return (
      <ThemeProvider theme={darkTheme}>
        <SCHBox className={style.relatedMetadata}>
          {showIcon ? withRelatedChangesActivityRecordTooltip(UserIcon) : null}
          <HBox marginStart={!showIcon && '29px'} onClick={(e) => e.stopPropagation()}>
            <Select
              name={props.change.id}
              updateField={this.onChange}
              options={matchDropDownOptions}
              valueKey="value"
              value={selectedOption}
              controlComponent={(p) => <ActionableBadge {...p} width="72px" {...selectedOption} />}
              withPortal
              menuWidth="fit-content"
              menuMinWidth="100px"
              placement="end"
            />
          </HBox>
        </SCHBox>
      </ThemeProvider>
    );
  }
}

const MCT_PROP = PropTypes.oneOf(Object.keys(MATCH_CERTAINTY_TYPES));
const userPropType = PropTypes.shape({
  email: PropTypes.string,
  name: PropTypes.string,
});

RelatedMetadata.propTypes = {
  user: userPropType,
  currentUser: userPropType.isRequired,
  relatedMetadata: PropTypes.shape({
    match_certainty: MCT_PROP,
    updated_at: PropTypes.string,
    incident_id: PropTypes.string,
  }),
  change: PropTypes.shape({}).isRequired,
  incidentId: PropTypes.string.isRequired,
  defaultSelected: MCT_PROP,
  setPopupProps: PropTypes.func,
  disabled: PropTypes.bool,
};

RelatedMetadata.defaultProps = {
  user: undefined,
  relatedMetadata: undefined,
  defaultSelected: MATCH_CERTAINTY_TYPES.NONE,
  setPopupProps: undefined,
  disabled: true,
};
export default RelatedMetadata;
