angular.module('bigpanda').directive('entityLinks', entityLinks);

function entityLinks() {
  return {
    restrict: 'E',
    scope: {
      entity: '<',
    },
    templateUrl: 'overview/entities/list/cols/entity_links/entity_links',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($scope, $element) {
    const vm = this;
    vm.entity = [];
    vm.isPopupOpen = false;
    vm.togglePopup = togglePopup;
    vm.buttonId = `${this.entity._id}-entity-link-button`;

    vm.$onInit = () => {
      $element.on('mousedown', (e) => {
        e.stopPropagation();
        return false;
      });

      window.addEventListener('click', onWindowClick, true);
    };

    $scope.$watch('vm.entity', () => {
      vm.buttonId = `${vm.entity._id}-entity-link-button`;
    });

    function onWindowClick(event) {
      if (vm.isPopupOpen) {
        vm.togglePopup(event);
        $scope.$apply();
      }
    }

    function togglePopup($event) {
      vm.isPopupOpen = !vm.isPopupOpen;
      $event.stopPropagation();
    }

    $scope.$on('$destroy', () => {
      window.removeEventListener('click', onWindowClick, true);
    });
  }
}
