import actionTypes from '../actionTypes';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        ...action.payload.organization,
      };

    default:
      return state;
  }
}
