import React from 'react';
import { BamQuickPopup } from '@bp/bam';
import PropTypes from 'prop-types';
import { POPUP_TIMEOUT } from '../Consts';

class CommentPopup extends React.PureComponent {
  // eslint-disable-next-line react/sort-comp
  updateRccTimeoutRef = null;
  timeoutCB = null;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { hook } = this.props;
    hook({ setCommentPopupProps: this.setPopupProps });
  }

  componentWillUnmount() {
    clearTimeout(this.updateRccTimeoutRef);
    this.timeoutCB && this.timeoutCB();
  }

  setPopupProps = ({ open, dropdownClickableHandler, metadataPayload, ...rest }) => {
    this.updateRccTimeoutRef = this.createUpdateRCCTimeout(
      metadataPayload,
      dropdownClickableHandler
    );
    const popupProps = {
      open,
      onAction: this.popupHandler(
        dropdownClickableHandler,
        metadataPayload,
        this.updateRccTimeoutRef
      ),
      onFocus: this.createOnFocusCB(this.updateRccTimeoutRef),
      ...rest,
    };
    dropdownClickableHandler(false);
    this.setState({ ...popupProps });
  };

  popupHandler = (dropdownClickableHandler, metadata, timeoutRef) => (textareaValue, action) => {
    const metadataPayload = {
      ...metadata,
    };
    if (textareaValue && action === 'send') {
      metadataPayload.relatedMetadata.comment = textareaValue;
    }
    if (action === 'close') {
      delete this.timeoutCB;
      clearTimeout(timeoutRef);
    }
    this.props.upsertRelatedMetadata(metadataPayload);
    this.setState({ open: false }, () => dropdownClickableHandler(true));
  };

  createUpdateRCCTimeout = (metadataPayload, dropdownClickableHandler) => {
    this.timeoutCB = () => {
      delete this.timeoutCB;
      this.setState({ open: false });
      this.props.upsertRelatedMetadata(metadataPayload);
      dropdownClickableHandler(true);
    };
    return setTimeout(this.timeoutCB, POPUP_TIMEOUT);
  };

  createOnFocusCB = (timeoutRef) => (e) => {
    const { onFocus } = this.props;
    delete this.timeoutCB;
    clearTimeout(timeoutRef);
    onFocus && onFocus(e);
  };

  render() {
    const { hook, upsertRelatedMetadata, ...props } = this.props;

    return <BamQuickPopup {...props} {...this.state} />;
  }
}

CommentPopup.propTypes = {
  upsertRelatedMetadata: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  hook: PropTypes.func,
};

CommentPopup.defaultProps = {
  onFocus: () => {},
  hook: () => {},
};
export default CommentPopup;
