import {
  AllSizes, CodeSizes, HBox, VBox,
} from '@bp/kung-fu';
import React from 'react';

import { InputWithHelpTooltip, InputWithHelpTooltipProps } from '../InputWithHelpTooltip';
import { StyledText } from '../InputWithHelpTooltip/style';

export const HELP_TEXT = 'Define the pattern for extracting the Alert tag value from the source tag';
export const EXTRACTION_LIMIT = 300;

export function ExtractionRegexInput(props: InputWithHelpTooltipProps): JSX.Element {
  const { isReservedSource, source, ...restProps } = props;
  return (
    <InputWithHelpTooltip
      {...restProps}
      helpTitle="Extraction Regex"
      helpText={HELP_TEXT}
      docsLink="https://docs.bigpanda.io/docs/alert-enrichment#extraction-tag"
      helpExample={(
        <VBox>
          <HBox marginBottom="8px">
            <StyledText lineHeight="1.5" size={AllSizes.SMALL}>
              Examples:
            </StyledText>
          </HBox>
          <HBox marginBottom="8px">
            <StyledText lineHeight="1.5" size={CodeSizes.SMALL_CODE}>
              ^([a-zA-Z]*)-.*\..*-[0-9]*$
            </StyledText>
          </HBox>
          <HBox marginBottom="8px">
            <StyledText lineHeight="1.5" size={CodeSizes.SMALL_CODE}>
              ([^.]+).[a-z]
              {3}
              .com
            </StyledText>
          </HBox>
        </VBox>
              )}
    />
  );
}

export default ExtractionRegexInput;
