import { Field } from 'react-final-form';
import styled from 'styled-components';

export const FunctionField = styled(Field)`
    margin-right: 10px;
    width: 140px;
    min-width: 140px;
    margin-bottom: 20px;
`;

export const Notes = styled.div`
    margin-bottom: 20px;

    :global(.bam-add-note .title-container .add-note-click .title),
    :global(.bam-add-note .title-container .add-note-click .bp-icon-plus-icon) {
        color: ${(p) => p.theme.bp_gray_05};
        margin-bottom: 0px;
    }

    :global(.bam-add-note .title-container .add-note-click:hover .title),
    :global(.bam-add-note .title-container .add-note-click:hover .bp-icon-plus-icon) {
        color: darken(${(p) => p.theme.bp_gray_05}, 15%);
    }
`;

export const FunctionContent = styled.div`
    margin-right: 10px;
    width: 140px;
    min-width: 140px;
    margin-bottom: 20px;
`;

export const FieldError = styled.div`
    margin-bottom: 20px;

    :global(.bam-base-input) {
        border-color: ${(p) => p.theme.bp_red} !important;
    }

    :global(.bam-form-field) {
        margin-bottom: 0px;
    }

    span {
        color: ${(p) => p.theme.bp_red};
        font-size: 12px;
        font-weight: bold;
    }

    :global(.bam-add-note .title-container .add-note-click .title),
    :global(.bam-add-note .title-container .add-note-click .bp-icon-plus-icon) {
        color: ${(p) => p.theme.bp_gray_05};
        margin-bottom: 0px;
    }

    :global(.bam-add-note .title-container .add-note-click:hover .title),
    :global(.bam-add-note .title-container .add-note-click:hover .bp-icon-plus-icon) {
        color: darken(${(p) => p.theme.bp_gray_05}, 15%);
    }
`;

export const HR = styled.hr`
    margin: 0 0 20px 0;
`;
