import get from 'lodash/get';
import 'css.escape';
import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { BamTable } from '@bp/bam';
import { isArrayEqual } from 'react/helpers';
import { getMainProperties } from 'common/modules/entities/EntityTitleGenerator';

class CorrelationPatternPreviewEntitiesTable extends React.Component {
  static getHeight = (...args) => BamTable.getHeight(...args);

  constructor(props) {
    super(props);
    this.state = { columns: this.initColumns(props) };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const nextEntities = get(nextProps, 'incidentData.incident.entities');
    const currentEntities = get(this.props, 'incidentData.incident.entities');

    if (!isArrayEqual(currentEntities, nextEntities)) {
      this.setState({ columns: this.initColumns(nextProps) });
    }
  }

  onColumnsChanged = (columns) => {
    this.setState({ columns });
  };

  initColumns = (props) => {
    const {
      incidentData: { incident },
    } = props;
    const { entities } = incident;
    const tagValues = [];
    const sourceCaps = [];
    entities.forEach((entity) => {
      const mainProperties = getMainProperties([entity]);
      const tagsByOrder = [
        ...mainProperties.primary.map((p) => p.name),
        ...mainProperties.secondary.map((p) => p.name),
        ...entity.tags.map((t) => t.type),
      ];
      tagsByOrder.slice(0, 9).forEach((tagName) => {
        if (!tagValues.includes(tagName)) {
          tagValues.push(tagName);
        }
      });
      if (entity.sourceCap && !sourceCaps.includes(entity.sourceCap)) {
        sourceCaps.push(entity.sourceCap);
      }
    });
    const propertiesColumns = tagValues.map((property) => ({
      headerContent: property[0] === '_' ? property.slice(1) : property,
      cellContent: (props) => props.value,
      tooltipContent: (props) => props.value,
      accessor: (d) => {
        const { value } = d.tags.find((t) => t.type === property) || {};
        return Array.isArray(value) ? value.join(' | ') : (value != null ? value : '').toString();
      },
      id: `property-${CSS.escape(property)}`,
      sortable: false,
    }));

    if (sourceCaps.length > 1) {
      propertiesColumns.unshift({
        headerContent: 'Source',
        cellContent: (props) => props.value,
        tooltipContent: (props) => props.value,
        accessor: (d) => (d.sourceCap ? d.sourceCap : null),
        id: 'property-sourceCap',
        sortable: false,
      });
    }

    return [
      ...propertiesColumns,
      {
        headerContent: 'Created',
        tooltipContent: (props) => props.value,
        accessor: 'startedFullDate',
        resizable: false,
        cellContent: (props) => props.value,
        fixed: 'right',
        frozen: 'right',
        sortable: false,
        width: 160,
      },
    ];
  };

  render() {
    const { columns } = this.state;
    const { incidentData, initialRowCount, expanded } = this.props;
    const { incident, id } = incidentData;
    const { entities } = incident;

    return (
      <BamTable
        id={`incident_${id}`}
        columns={columns}
        data={entities}
        onColumnsChanged={this.onColumnsChanged}
        initialRowCount={initialRowCount}
        showAll={expanded}
        autofit
        staticTable
        simpleCell
      />
    );
  }
}

CorrelationPatternPreviewEntitiesTable.propTypes = {
  incidentData: PropTypes.shape({
    incident: PropTypes.shape({
      entities: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    id: PropTypes.string,
  }).isRequired,
  initialRowCount: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default hot(module)(CorrelationPatternPreviewEntitiesTable);
