import actionTypes from './actionTypes';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_CONTACTS_SUCCESS:
      return {
        ...state,
        contactsList: action.payload,
      };

    default:
      return state;
  }
}
