import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { Div, HelpTooltip } from '@bp/kung-fu';
import { BamTable } from '@bp/bam';
import cn from 'classnames';
import { TextSeparator } from 'react/common/components';
import styles from './FormulaExpressionView.scss';
import columnsSettings from '../incident_labels_info/columnSettings';
import PatternDetails from './PatternDetails';
import PopupHoc from '../../../../utils/hoc/PopupHoc';

class FormulaExpressionView extends React.Component {
  state = {
    columns: columnsSettings(),
    selectedPattern: null,
  };

  onColumnsChanged = (columns) => {
    this.setState({ columns });
  };

  onPopupClose = () => this.setState({ selectedPattern: null });

  getTrProps = (rowInfo = {}) => {
    const { row } = rowInfo;
    return {
      className: cn({
        [styles['row-selected']]:
          get(this.state, 'selectedPattern._index', NaN) === get(row, '_index'),
      }),
    };
  };
  tableRef = React.createRef();

  openPatternDetailsPopup = ({ row } = {}, e) => {
    const { setPopupProps, updateTime } = this.props;
    const { selectedPattern } = this.state;

    if (selectedPattern && selectedPattern._index === row._index) {
      return this.onPopupClose();
    }

    e.nativeEvent.stopImmediatePropagation();
    requestAnimationFrame(() => {
      const popupProps = {
        onClose: this.onPopupClose,
        eventsEnabled: false,
        open: true,
        referenceElement: this.tableRef.current,
        children: (
          <div className={styles['details-container']}>
            <PatternDetails
              selectedPattern={row}
              onClose={() => setPopupProps({ open: false })}
              updateTime={updateTime}
            />
          </div>
        ),
        placement: 'left-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport',
          },
          offset: {
            enabled: true,
            offset: 0,
          },
        },
      };
      this.setState({ selectedPattern: row }, () => {
        setPopupProps({ ...popupProps });
      });
    });
  };

  render() {
    const { columns } = this.state;
    const { items, secondaryTitle, helperContent } = this.props;
    return (
      <div>
        <div className={styles['headline-container']}>
          <span className={styles['headline-container-primary']}>
            Automatic Enrichment{' '}
            <span className={styles['headline-container-counter']}>
              ({items.length} item{items.length > 1 ? 's' : ''})
            </span>
          </span>
          <TextSeparator />
          {secondaryTitle && (
            <span className={styles['headline-container-secondary']}>{secondaryTitle}</span>
          )}
          <Div display="inline-block" marginStart="5px">
            <HelpTooltip text={helperContent} />
          </Div>
        </div>
        <div ref={this.tableRef}>
          <BamTable
            autofit
            id="formulaItemsTable"
            data={items}
            columns={columns}
            onColumnsChanged={this.onColumnsChanged}
            onRowClicked={this.openPatternDetailsPopup}
            getTrProps={(state, rowInfo) => this.getTrProps(rowInfo)}
          />
        </div>
      </div>
    );
  }
}
FormulaExpressionView.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      condition: PropTypes.string,
    })
  ),
  secondaryTitle: PropTypes.string,
  updateTime: PropTypes.number.isRequired,
  setPopupProps: PropTypes.func.isRequired,
  helperContent: PropTypes.string.isRequired,
};

FormulaExpressionView.defaultProps = {
  items: [],
  secondaryTitle: '',
};

export default PopupHoc(FormulaExpressionView);
