import get from 'lodash/get';
import { path as parentPath } from '../constants';
import { path } from './constants';

const selectors = {};

selectors.getPermissionsList = (state) => get(state, `${parentPath}.${path}.list`);
selectors.getGranularPermissions = (state) =>
  get(state, `${parentPath}.${path}.granularPermissions`);
selectors.getRoles = (state) => get(state, `${parentPath}.${path}.roles`);

export default selectors;
