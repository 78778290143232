import keys from 'lodash/keys';
angular
  .module('bigpanda.backend.services')
  .service('PersonalSettingsBackendService', PersonalSettingsBackendService);

function PersonalSettingsBackendService($http) {
  this.getUser = getUser;
  this.getContact = getContact;
  this.updateUser = updateUser;
  this.updateContact = updateContact;

  function getUser() {
    return $http.get('/web/me').then((res) => res.data);
  }

  function updateUser(payload) {
    const key = keys(payload)[0];
    return $http.put(`/web/me/${key}`, payload).then((res) => res.data);
  }

  function getContact() {
    return $http.get('/web/me/contact').then((res) => res.data);
  }

  function updateContact(payload) {
    return $http.patch('/web/me/contact', payload).then((res) => res.data);
  }
}
