import { Action } from 'redux';

import actionTypes from './actionTypes';
import { AlertFilter } from './types/AlertFilter';
import { DropdownRecord, DropdownRecords } from './types/DropdownOption';
import { SortMode } from './types/SortMode';

type PayloadAction = Action & { payload: unknown };

export default {
  updateFilterText: (payload: string): object => ({
    type: actionTypes.UpdateFilterText,
    payload,
  }),
  updateSortMode: (payload: SortMode): object => ({
    type: actionTypes.UpdateSortMode,
    payload,
  }),
  loadAlertFilters: (payload: { reload: boolean }): PayloadAction => ({
    type: actionTypes.LoadAlertFilters,
    payload,
  }),
  loadAlertFiltersSuccess: (payload: AlertFilter[]): PayloadAction => ({
    type: actionTypes.LoadAlertFiltersSuccess,
    payload,
  }),
  loadAlertFiltersFailure: (): Action => ({
    type: actionTypes.LoadAlertFiltersFailure,
  }),
  createFiltersDropdownItemsSuccess: (payload: DropdownRecords): PayloadAction => ({
    type: actionTypes.CreateFiltersDropdownItemsSuccess,
    payload,
  }),
  updateFilters: (payload: DropdownRecord): PayloadAction => ({
    type: actionTypes.UpdateFilters,
    payload,
  }),
  deleteAlertFilter: (payload: AlertFilter): PayloadAction => ({
    type: actionTypes.DeleteAlertFilter,
    payload,
  }),
  updateAlertFilter: (payload: AlertFilter): PayloadAction => ({
    type: actionTypes.UpdateAlertFilter,
    payload,
  }),
  createAlertFilter: (payload: AlertFilter): PayloadAction => ({
    type: actionTypes.CreateAlertFilter,
    payload,
  }),
};
