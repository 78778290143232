import isArray from 'lodash/isArray';
import each from 'lodash/each';
import keys from 'lodash/keys';
angular.module('bigpanda').directive('compositionTagPreview', compositionTagPreview);

function compositionTagPreview() {
  return {
    restrict: 'E',
    templateUrl: 'settings/correlation/custom_tags/popup/utils/composition_tag_preview',
    scope: {
      tags: '=',
      template: '@',
    },
    controllerAs: 'tagPreviewVm',
    bindToController: true,
    controller: controller,
  };
}

function controller() {
  const vm = this;
  vm.tagHref = null;
  vm.tagDisplay = '';
  vm.isUrl = false;
  vm.$onInit = init;

  function init() {
    const tagKeys = keys(vm.tags);
    getCompositionTagValue(tagKeys);
  }

  function getCompositionTagValue(tagKeys) {
    vm.tagDisplay = vm.template;
    vm.tagHref = vm.template;
    vm.isUrl = isUrl(vm.template);

    each(tagKeys, (tagKey) => {
      const tagRegex = new RegExp(`\\$\\{(${tagKey})\\}`);
      replaceTag(tagKey, tagRegex);
      if (vm.isUrl) {
        replaceLinkTag(tagKey, tagRegex);
      }
    });
  }

  function replaceTag(tagKey, tagRegex) {
    const tagValue = vm.tags[tagKey];
    const tagDisplay = isArray(tagValue) ? tagValue[0] : tagValue;
    const highlightSpan = `<span class="highlighted">${tagDisplay}</span>`;
    vm.tagDisplay = vm.tagDisplay.replace(tagRegex, highlightSpan);
  }

  function replaceLinkTag(key, tagRegex) {
    const encodedTag = encodeURI(vm.tags[key]);
    vm.tagHref = vm.tagHref.replace(tagRegex, encodedTag);
  }

  function isUrl(template) {
    const urlRegex = /^((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6}))|^(https?:\/\/)/;
    return urlRegex.test(template);
  }
}
