import { combineEpics } from 'redux-observable';
import { batchActions } from 'redux-batched-actions';
import { Observable } from 'rxjs/Observable';
import actionTypes from './actionTypes';
import * as actions from './actions';
import * as contacts from '../contacts/index';
import * as users from '../users/index';
import { loadContacts } from '../contacts/actions';
import { loadUsers } from '../users/actions';

const loadEnrichedUsers = (action$) =>
  action$
    .ofType(actionTypes.LOAD_ENRICHED_USERS)
    .map(() => batchActions([loadContacts(), loadUsers()]));

const enrichUsers = (action$) =>
  Observable.combineLatest(
    action$.ofType(contacts.actionTypes.LOAD_CONTACTS_SUCCESS),
    action$.ofType(users.actionTypes.LOAD_USERS_SUCCESS),
    ({ payload: currentContacts }, { payload: currentUsers }) => {
      if (!currentUsers || !currentContacts) {
        return actions.loadEnrichedUsersSuccess(null);
      }

      const enrichedUsers = currentUsers.map((user) => {
        const contact = (currentContacts || []).find((c) => c.bigpanda_user_id === user.id);
        const name = contact && contact.name ? contact.name : user.username;
        const { email, _id: contact_id, phone_number } = contact || {};
        const contactDetails = Object.fromEntries(
          Object.entries({
            contact_id,
            name,
            email,
            phone_number,
          }).filter(([_, value]) => value)
        );

        return { ...user, ...contactDetails };
      });

      return actions.loadEnrichedUsersSuccess(enrichedUsers);
    }
  );

export default combineEpics(loadEnrichedUsers, enrichUsers);
