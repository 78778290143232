import {
  AUTO_RESOLVE_TIME_OPTIONS as options,
  DONT_RESOLVE_OPTION as dontResolveOption,
} from '../../../../../../app/angularjs/integrations/integrations.constants';

export const AUTO_RESOLVE_TIME_OPTIONS = options.map((option) => ({
  text: option.label,
  id: option.value,
  label: option.rawLabel,
}));

export const DONT_RESOLVE_OPTION = [{
  text: dontResolveOption.label,
  id: dontResolveOption.value,
  label: dontResolveOption.rawLabel,
}];

export type AutoResolveConfig = {
  integrationConfigName: string;
  autoResolveTime: {
    id: string | number;
    name?: string;
    label: string;
    text: string;
  };
};
