import find from 'lodash/find';
angular.module('bigpanda').service('CustomTagsService', CustomTagsService);

function CustomTagsService(CorrelationConfigService) {
  this.getTags = getTags;
  this.addNewTag = addNewTag;
  this.editTag = editTag;
  this.removeTag = removeTag;
  this.toggleTagState = toggleTagState;
  this.getTagById = getTagById;
  this.validateTagRegex = validateTagRegex;

  function removeTag(id) {
    return getTags({ force: true }).then((tags) =>
      CorrelationConfigService.removeItem(
        tags,
        id,
        'correlations.customTagRemoved',
        'transformations'
      )
    );
  }

  function addNewTag(tag) {
    return getTags().then((tags) =>
      CorrelationConfigService.addNewItem(
        tags,
        tag,
        'correlations.customTagAdded',
        'transformations'
      )
    );
  }

  function editTag(tag) {
    return getTags().then((tags) =>
      CorrelationConfigService.editItem(
        tags,
        tag,
        'correlations.customTagModified',
        'transformations'
      )
    );
  }

  function toggleTagState(tag) {
    return CorrelationConfigService.checkForConfigChanges().then(() =>
      getTags().then((tags) =>
        CorrelationConfigService.toggleItemState(
          tags,
          tag,
          'correlations.customTagToggled',
          'transformations'
        )
      )
    );
  }

  function getTags(opts) {
    return CorrelationConfigService.getConfig(opts).then((conf) => {
      const rawTags = conf ? conf.transformations : [];
      return CorrelationConfigService.enrichConfig(rawTags);
    });
  }

  function getTagById(id) {
    return getTags().then((tags) => {
      if (tags) {
        return find(tags, { id });
      }
      return null;
    });
  }

  function validateTagRegex(regex) {
    return CorrelationConfigService.validateTagRegex(regex);
  }
}
