angular.module('bigpanda').controller('WelcomeSequenceController', WelcomeSequenceController);

function WelcomeSequenceController($state, ModalService) {
  const vm = this;

  vm.tabs = [
    {
      template:
        '<static-welcome header="welcome to bigpanda" text="BigPanda helps you cut through noisy IT alerts and easily see what\'s really going on." img-src="/assets/img/welcome-sequence/welcome.png"/>',
    },
    {
      template:
        '<static-welcome header="reduce noise and mttr" text="BigPanda groups your noisy alerts into consolidated incidents, which helps you detect issues faster and reduce MTTR by up to 86%." img-src="/assets/img/welcome-sequence/mttr.png"/>',
    },
    {
      template:
        '<static-welcome header="how we do it" text="BigPanda aggregates alerts from all your monitoring tools, normalizes them into a unified data model, and then intelligently correlates related issues into high-level incidents." img-src="/assets/img/welcome-sequence/aggregation.png"/>',
    },
    {
      template:
        '<static-welcome header="isolate alerts for any app, team or cloud" text="Laser focus on any app, cloud, micro-service or team, by creating custom monitoring views for any slice of your production stack." img-src="/assets/img/welcome-sequence/isolation.png"/>',
    },
    {
      template:
        '<static-welcome header="join the revolution" text="Trusted by the world\'s most-respected IT organizations. BigPanda powers cloud-scale monitoring that\'s as agile as your business." img-src="/assets/img/welcome-sequence/trusted_by.png"/>',
    },
    { templateUrl: 'integrations/welcome_sequence/integrations_welcome_sequence' },
  ];

  vm.close = () => {
    ModalService.hide();
    $state.go('^');
  };
}
