import first from 'lodash/first';
import filter from 'lodash/filter';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
angular.module('bigpanda.reports').controller('ReportsListController', ReportsListController);

function ReportsListController(
  $scope,
  $state,
  $element,
  stateService,
  ReportsService,
  pubSubService,
  PERMISSIONS_NAMES
) {
  const vm = this;

  vm.currentSelected = null;
  vm.currentConfigured = null;

  vm.switchReport = switchReport;
  vm.markSelected = markSelected;
  vm.markConfigured = markConfigured;
  vm.clearConfigured = clearConfigured;
  vm.isActionsPermitted = [
    PERMISSIONS_NAMES.analytics.create,
    PERMISSIONS_NAMES.analytics.update,
    PERMISSIONS_NAMES.analytics.delete,
  ];

  init();

  function markSelected(reportId) {
    vm.currentSelected = reportId;
  }

  function markConfigured(reportId) {
    vm.currentConfigured = reportId;
  }

  function clearConfigured(reportId) {
    // This is needed in case we're switching to configuring another report,
    // and its onShow ran before our onHide.
    if (vm.currentConfigured == reportId) {
      vm.currentConfigured = null;
    }
  }

  function switchReport(reportId) {
    if (vm.currentSelected == reportId) {
      pubSubService.broadcast('reportWidgetsController.refreshReportData');
    }
    $state.go('app.reports.detail.show-report', { reportId: reportId });
  }

  function init() {
    ReportsService.getReports().then((reports) => {
      const sortedReports = sortBy(values(reports), 'title');
      vm.systemReports = filter(sortedReports, (r) => r.system);
      vm.nonSystemReports = filter(sortedReports, (r) => !r.system);

      let defaultReportId = first(vm.systemReports);
      if (defaultReportId) {
        defaultReportId = defaultReportId._id;
      }
      const currentReportId = stateService.getSelectedReportId() || defaultReportId;

      markSelected(currentReportId);
      $state.go('app.reports.detail.show-report', { reportId: currentReportId });

      const reportsListElement = angular.element($element).find('.reports-list')[0];
      $scope.$watch(
        () => reportsListElement.getBoundingClientRect().height,
        () => $scope.$broadcast('$md-resize')
      );
    });
  }
}
