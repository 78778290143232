import 'rxjs/add/observable/combineLatest';
import { bamNotify, BamNotificationStatusOptions } from '@bp/bam';
import { combineEpics } from 'redux-observable';
import { changePassword } from 'common/endpoints/me';
import { CHANGE_PASSWORD } from './actionTypes';
import { changePasswordSuccess, changePasswordFailure } from './actions';

const changePasswordEpic = (action$) =>
  action$.ofType(CHANGE_PASSWORD).flatMap(({ payload, meta }) =>
    changePassword(payload)
      .then(() => {
        meta.onSuccess();
        bamNotify({
          message: [
            { message: 'Password' },
            { message: 'changed', highlight: true },
            { message: 'successfully' },
          ],
          status: BamNotificationStatusOptions.ACTION_SUCCESS,
        });
        return changePasswordSuccess();
      })
      .catch(changePasswordFailure)
  );

export default combineEpics(changePasswordEpic);
