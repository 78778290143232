import filter from 'lodash/filter';
angular.module('bp.widgets').directive('bpContactsSelect', bpContactsSelect);

function bpContactsSelect() {
  return {
    restrict: 'E',
    templateUrl: 'shared_ui/bp_contacts_select/bp_contacts_select',
    scope: {
      model: '=',
      type: '@',
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: controller,
  };
}

function controller(ContactsStore, $log) {
  const vm = this;

  let contacts = [];

  vm.loadAutocompleteContacts = loadAutocompleteContacts;
  vm.onTagAdding = onTagAdding;

  init();

  function init() {
    ContactsStore.getContacts().then((result) => {
      contacts = result;
    });
  }

  function onTagAdding(newContact) {
    if (!newContact._id) {
      if (!isFittingPattern(newContact.name)) {
        return false;
      }
      newContact.newItem = true;
      newContact._id = `new_${Math.random()}`;
      assignNameAccordingToType(newContact);
    }
    return true;
  }

  function assignNameAccordingToType(newContact) {
    if (vm.type === 'email') {
      newContact.email = newContact.name;
    } else if (vm.type === 'phone') {
      newContact.phone_number = newContact.name;
    } else {
      $log.error('No type supplied! This probably means a bug', vm.type);
    }
  }

  function match(contact, text) {
    return (
      containsLowerCase(contact.name, text) ||
      containsLowerCase(contact.email, text) ||
      containsLowerCase(contact.phone_number, text)
    );
  }

  function containsLowerCase(fullText, searchText) {
    fullText = fullText || '';
    return fullText.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
  }

  function isFittingPattern(query) {
    if (vm.type === 'email') {
      return isEmailPattern(query);
    } else if (vm.type === 'phone') {
      return isPhonePattern(query);
    }
    $log.error('No type supplied! This probably means a bug', vm.type);
    return false;
  }

  function isEmailPattern(query) {
    const mailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mailPattern.test(query);
  }

  function isPhonePattern(query) {
    return window.intlTelInputUtils.isValidNumber(query);
  }

  function loadAutocompleteContacts($query) {
    return filter(
      contacts,
      (contact) => getContactNeededAttribute(contact) && match(contact, $query)
    );
  }

  function getContactNeededAttribute(contact) {
    if (vm.type === 'email') {
      return contact.email;
    } else if (vm.type === 'phone') {
      return contact.phone_number;
    }
    $log.error('No type supplied! This probably means a bug', vm.type);
  }
}
