const omit = require('lodash/omit');

angular
  .module('bigpanda.backend.services')
  .service('DashboardsBackendService', DashboardsBackendService);

function DashboardsBackendService($http) {
  this.getAllDashboards = getAllDashboards;
  this.getDashboardById = getDashboardById;
  this.createDashboard = createDashboard;
  this.updateDashboard = updateDashboard;
  this.getWidgetsData = getWidgetsData;
  this.deleteDashboard = deleteDashboard;

  function getAllDashboards() {
    return $http.get('/resources/v2.0/dashboards').then((res) => res.data.item);
  }

  function getDashboardById(dashboardId) {
    return $http.get(`/resources/v2.0/dashboards/${dashboardId}`).then((res) => res.data.item);
  }

  function createDashboard(dashboardConfig) {
    return $http.post('/resources/v2.0/dashboards', dashboardConfig).then((res) => res.data.item);
  }

  function updateDashboard(dashboardId, dashboardConfig) {
    const payload = omit(dashboardConfig, 'created_at');
    return $http
      .patch(`/resources/v2.0/dashboards/${dashboardId}`, payload)
      .then((res) => res.data.item);
  }

  function deleteDashboard(dashboardId) {
    return $http.delete(`/resources/v2.0/dashboards/${dashboardId}`).then((res) => res.data);
  }

  function getWidgetsData(dashboardId, onlyLast) {
    return $http
      .get(`/resources/v2.0/dashboards/widgets/${dashboardId}${onlyLast ? '/last' : ''}`)
      .then((res) => res.data.item);
  }

  return this;
}
