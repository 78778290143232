angular.module('bigpanda').controller('SnoozeSelectorCtrl', SnoozeSelectorCtrl);

function SnoozeSelectorCtrl($scope) {
  const vm = this;
  const snoozeSelectorVm = $scope.snoozeSelectorVm;

  vm.content = [
    { text: 'Cancel Snooze', reschedule: false },
    { text: 'Reschedule', reschedule: true },
  ];

  vm.snooze = snooze;

  function snooze(item) {
    $scope.$hide();
    if (item.reschedule) {
      snoozeSelectorVm.schedule();
    } else {
      snoozeSelectorVm.cancelSnooze();
    }
  }
}
