import moment from 'moment';

const BpTimeSince = (timeUnix) => {
  let timestamp = Math.round(timeUnix);
  let convertedTime;

  if (Number.isNaN(timestamp) || timestamp <= 0) {
    return 'n/a';
  }

  if (timestamp.toString().length === 13) {
    timestamp /= 1000;
  }

  const diffInDays = moment().diff(moment.unix(timestamp), 'days');
  if (diffInDays >= 365) {
    convertedTime = moment.unix(timestamp).format('YYYY');
  } else if (diffInDays >= 7) {
    convertedTime = moment.unix(timestamp).format('MMM D');
  } else {
    convertedTime = `${getTimeAgo(timestamp, moment().unix())} ago`;
  }

  return convertedTime;
};

export function getTimeAgo(timeUnix, tillTime) {
  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_DAY = 86400;
  const TIME_DICT = {
    days: { singular: 'day', plural: 'days', short: 'd' },
    hours: { singular: 'hour', plural: 'hours', short: 'h' },
    minutes: { singular: 'minute', plural: 'minutes', short: 'm' },
  };

  const duration = getDurationDiff(timeUnix, tillTime);
  const displayTimeArr = getTimeForDisplay(createTimeObject(duration));

  if (displayTimeArr.length === 0) {
    return '< 1m';
  }
  return displayTimeArr[0].replace(' ', '');

  function getDurationDiff(timeStampUnix, tillTimeUnix) {
    let since;

    if (tillTimeUnix && !Number.isNaN(timeStampUnix) && timeStampUnix > 0) {
      since = moment.unix(tillTimeUnix).diff(moment.unix(timeStampUnix)) / 1000;
    } else {
      since = moment().diff(moment.unix(timeStampUnix)) / 1000;
    }

    return since;
  }

  function createTimeObject(seconds) {
    const calc = Math.round;
    return {
      days: calculateTime(seconds, SECONDS_IN_DAY, calc),
      hours: calculateTime(seconds, SECONDS_IN_HOUR, calc),
      minutes: calculateTime(seconds, SECONDS_IN_MINUTE, calc),
    };
  }

  function calculateTime(seconds, timeWindow, calc) {
    return seconds >= timeWindow ? calc(seconds / timeWindow) : null;
  }

  function getTimeForDisplay(timeObj) {
    const timeArr = [];
    Object.entries(timeObj).forEach(([key, value]) => {
      if (value) {
        const displayText = getDisplayValue(TIME_DICT[key]);
        timeArr.push(`${value} ${displayText}`);
      }
    });
    return timeArr;
  }

  function getDisplayValue(dict) {
    return dict.short;
  }
}

export default BpTimeSince;
