import debounce from 'lodash/debounce';
angular.module('bigpanda.backend.services').service('ExpiredService', ExpiredService);

function ExpiredService($rootScope, $transitions, ModalService, PersonalSettingsStore) {
  function showModal() {
    const modalParams = {
      scope: $rootScope.$new(),
      templateUrl: 'user/expired',
      controller: 'ExpiredController',
      clickOutsideToClose: false,
      escapeToClose: false,
    };
    ModalService.showModal(modalParams);
  }

  PersonalSettingsStore.getUser().then((user) => {
    if (user.expired) {
      $transitions.onEnter({}, debounce(showModal, 200));
    }
  });
}
