import {
  AllSizes, HBox, SCHBox, Text, VBox,
} from '@bp/kung-fu';
import { Field } from 'react-final-form';
import styled from 'styled-components';

export const TagNameField = styled(Field)`
    font-size: 12px;
`;

export const NewTagNameInfoContainer = styled(VBox)`
    width: 350px;
    padding: 15px 25px 22px 17px;

    & a {
        display: inline;
    }
`;

export const BulletContainer = styled(HBox)`
    width: 5px;
    min-width: 5px;
    height: 5px;
    margin-right: 3px;
`;

export const NewTagNameInfoText = styled(Text).attrs({
  size: AllSizes.SMALL,
  lineHeight: '22px',
})`
    color: ${(p) => p.theme.bp_gray_07};
`;

export const StaticContentContainer = styled(SCHBox)`
    margin-inline-start: 24px;
    height: 34px;
    margin-top: 20px;
`;

export const ContentSeparator = styled(Text).attrs({ size: AllSizes.SMALL })`
    margin: 0 8px;
`;

export const AddNoteContainer = styled(HBox)`
    margin-top: 20px;
`;
