import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import { DragDropContext } from 'react-beautiful-dnd';
import { BamModal } from '@bp/bam';
import AlertTagForm from './AlertTagForm';
import { tagPropType } from '../propTypes';
import { GlobalStyle } from '../../../../../../workspaces/apps/alert-enrichment/src/components/GlobalStyle';

import actions from '../actions';

class AlertTagModal extends React.PureComponent {
  onDragEnd = (draggedItems) => {
    if (!draggedItems.destination) return;
    this.props.setDraggedItems(draggedItems);
  };

  handleClose = () => {
    this.props.clearPreview();
    this.props.clearCurrentTag();
    this.ref.close();
  };

  render() {
    const { triggerProps, title, titleIcon, openCondition } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <GlobalStyle />
        <BamModal
          openCondition={openCondition}
          autoFocus
          ref={(r) => {
            this.ref = r;
          }}
          trigger={<ModalTriggerButton {...triggerProps} />}
          titleIcon={titleIcon}
          title={title}
          formMode
          fullscreen
          closeOnEscape={false}
          docsLink="https://docs.bigpanda.io/docs/alert-enrichment"
        >
          <AlertTagForm {...this.props} closeModal={this.handleClose} />
        </BamModal>
      </DragDropContext>
    );
  }
}

AlertTagModal.propTypes = {
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
    tooltipProps: PropTypes.shape({
      text: PropTypes.string,
    }),
  }).isRequired,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string.isRequired,
  duplicate: PropTypes.bool,
  tag: tagPropType,
  setDraggedItems: PropTypes.func.isRequired,
  clearPreview: PropTypes.func.isRequired,
  clearCurrentTag: PropTypes.func.isRequired,
  openCondition: PropTypes.func,
};

AlertTagModal.defaultProps = {
  duplicate: false,
  tag: undefined,
};

const mapDispatchToProps = {
  setDraggedItems: actions.setDraggedItems,
  clearPreview: actions.clearPreviewAlerts,
  clearCurrentTag: actions.clearCurrentTag,
};

export default connect(null, mapDispatchToProps)(AlertTagModal);
