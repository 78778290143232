import { ResetIcon } from '@bp/kung-fu';

import dictionary from '../../IncidentView.dic';
import { IAssignOption, IAssignValue } from '../../types';

const UNASSIGN_OPTION = {
  id: '',
  displayName: '',
  email: '',
  text: dictionary.assign_remove_title,
  user: undefined,
  Icon: ResetIcon,
};

export const reconstructUsers = (list: IAssignOption[], isAssigned: boolean) => {
  if (Array.isArray(list)) {
    const updatedList = list.map((item) => (
      { ...item, text: item.displayName, user: { name: item.displayName, email: item.email } }));

    if (isAssigned) {
      updatedList.unshift(UNASSIGN_OPTION);
    }

    return updatedList;
  }

  return [];
};

export const reconstructSelectedUser = (user?: IAssignValue) => {
  if (user?.assigneeName) {
    return { ...user, text: user.assigneeName, id: user.assignee };
  }
  return null;
};
