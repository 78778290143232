import actionTypes from './actionTypes';

const actions = {
  updateFilterText: (payload) => ({ type: actionTypes.UPDATE_FILTER_TEXT, payload }),
  loadAutoShareRules: () => ({ type: actionTypes.LOAD_AUTO_SHARE_RULES }),
  loadAutoShareRulesSuccess: (payload) => ({
    type: actionTypes.LOAD_AUTO_SHARE_RULES_SUCCESS,
    payload,
  }),
  loadAutoShareRulesFailure: () => ({ type: actionTypes.LOAD_AUTO_SHARE_RULES_FAILURE }),
  loadRules: () => ({ type: actionTypes.LOAD_RULES }),
  loadRulesSuccess: (payload) => ({ type: actionTypes.LOAD_RULES_SUCCESS, payload }),
  createAutoShareRule: (payload) => ({ type: actionTypes.CREATE_AUTO_SHARE_RULE, payload }),
  updateAutoShareRule: (payload) => ({ type: actionTypes.UPDATE_AUTO_SHARE_RULE, payload }),
  deleteAutoShareRule: (payload) => ({ type: actionTypes.DELETE_AUTO_SHARE_RULE, rule: payload }),
  createFiltersDropDownItemsSuccess: (payload) => ({
    type: actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS,
    payload: payload,
  }),
  updateFiltersDropDowns: (payload) => ({
    type: actionTypes.UPDATE_FILTERS_DROPDOWNS,
    payload: payload,
  }),
};

export default actions;
