angular.module('bigpanda.reports').constant('ReportsConfig', {
  tagPrefix: 'tags.general.',
  timeframePresets: [
    {
      timeframe: { type: 'relative', interval: '/d' },
      title: 'Today',
      getStartMoment: function (end) {
        return moment(end || undefined).startOf('day');
      },
    },
    {
      timeframe: { type: 'relative', interval: '/w' },
      title: 'Last Week',
      getStartMoment: function (end) {
        return moment(end || undefined).startOf('isoweek');
      },
    }, // isoweek starts weeks on mondays
    {
      timeframe: { type: 'relative', interval: '/M' },
      title: 'Last Month',
      getStartMoment: function (end) {
        return moment(end || undefined).startOf('month');
      },
    },
    {
      timeframe: { type: 'relative', interval: '-7d/d' },
      title: 'Last 7 Days',
      getStartMoment: function (end) {
        return moment(end || undefined)
          .subtract(7, 'days')
          .startOf('day');
      },
    },
    {
      timeframe: { type: 'relative', interval: '-30d/d' },
      title: 'Last 30 Days',
      getStartMoment: function (end) {
        return moment(end || undefined)
          .subtract(30, 'days')
          .startOf('day');
      },
    },
    { timeframe: { type: 'absolute', interval: 'custom' }, title: 'Custom Timeframe' },
  ],
  widgetConfigMapping: {
    'events-histogram': ['group_by'],
    correlation: [],
    mttr: ['incident_toggle'],
  },
  widgetsTypesToTitlePrefix: {
    'events-histogram': 'Top Alerting ',
  },
  infoTextPerVisualizationType: {
    'bp-events-histogram-bars':
      '<span style="font-weight:bold">Top Alerting Chart</span><br/>Display up to 10 of the grouping field values with the most frequent alerts for the selected Environment and date range. You can select a different grouping field.',
    'bp-correlation-linechart':
      '<span style="font-weight:bold">Alerts Correlation</span><br/>Compares the number of alerts to the number of incidents opened during the selected date range for the selected Environment. Each data point represents the time period starting on the specified date. The length of the time period is determined automatically based on the selected date range. You can point to a data point to see the exact number of alerts or incidents opened during the time period.',
    'bp-correlation-table':
      '<span style="font-weight:bold">Alerts Correlation Data</span><br/>Displays the ratio of alerts to incidents opened during the selected date range for the selected Environment. Each row in the table represents the time period starting on the specified date. The length of the time period is determined automatically based on the selected date range.',
    'bp-mttr-barchart':
      '<span style="font-weight:bold">MTTR</span><br/>Compares the mean time to resolution (MTTR) to the number of incidents opened during the selected date range for the selected Environment. MTTR calculates the average duration that an incident is open before it is resolved. For incidents that are reopened, the MTTR calculation includes only the time when the incidents are open. It also includes the time when incidents are snoozed and, by default, the time when incidents are flapping. You can choose whether to include the time when incidents are flapping.',
    'bp-mttr-table':
      '<span style="font-weight:bold">MTTR Data</span><br/>Compares the number of incidents opened to the mean time to resolution (MTTR) during the selected date range for the selected Environment.',
  },
});
