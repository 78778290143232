import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import get from 'lodash/get';
import SettingsListItemActions from 'react/layout/settings/components/SettingsListItemActions';
import actions from '../actions';
import selectors from '../selectors';
import AutoShareRuleModal from '../modals/AutoShareRuleModal';
import { rulePropType } from '../propTypes';
import { systemIconClass } from '../constants';
import styles from './AutoShareActions.scss';

const AutoShareRuleActions = ({ rule, deleteRule, permissions: { canEdit, canDelete } }) => {
  const systemId = get(rule, ['system', 'parent_system_id'], '');

  return (
    <SettingsListItemActions
      item={{
        name: (
          <div className={styles.ruleInfo}>
            <span className={styles.environmentName}>{rule.environment.name}</span>
            <span className={styles.bullet}>•</span>
            <span className={styles.icon}>
              <i className={systemIconClass(systemId)} />
            </span>
            <span>{rule.system.name}</span>
          </div>
        ),
      }}
      modelName="AutoShare"
      messages={{
        edit: 'edit',
        delete: 'delete',
      }}
      editProps={{
        disabled: !canEdit,
        modalProps: { rule, title: 'Edit AutoShare' },
        modal: AutoShareRuleModal,
      }}
      deleteProps={{
        disabled: !canDelete,
        action: () => deleteRule(rule),
        modalProps: {
          rule,
          role: 'dialog',
          title: 'Delete AutoShare',
          contentTitleType: 'string',
          content: 'Are you sure you want to delete this AutosShare?',
        },
      }}
    />
  );
};

AutoShareRuleActions.propTypes = {
  rule: rulePropType.isRequired,
  deleteRule: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canDelete: PropTypes.bool,
    canEdit: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    permissions: selectors.getActionsPermissions(state),
  };
}

const mapDispatchToProps = {
  deleteRule: actions.deleteAutoShareRule,
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(AutoShareRuleActions));
