import BPQL from '@bp/bpql';
import { parseQuery } from 'common/modules/settings/QuerySourceParser';

// eslint-disable-next-line import/prefer-default-export
export function enrichItemsWithIntegrations(items, integrations) {
  return items.map((item) => {
    const enrichedItem = { ...item };
    if (item.query) {
      const sourceObj = parseQuery(item.query);
      if (sourceObj) {
        enrichedItem.pureQuery = sourceObj.pureQuery;
        if (sourceObj.pureQuery && Object.keys(sourceObj.pureQuery).length > 0) {
          enrichedItem.displayQuery = BPQL.reverse(sourceObj.pureQuery);
        }
        enrichedItem.sourceSystems = multipleSourcesToSourceNames(sourceObj, integrations);
      }
    }
    return enrichedItem;
  });
}

export function multipleSourcesToSourceNames(data, integrations) {
  return data.sourceSystems
    .map((sourceSystem) => sourceToSourceName(sourceSystem, integrations))
    .filter((s) => !!s);
}

function sourceToSourceName(sourceSystem, integrations) {
  if (sourceSystem.type === 'regex') {
    return getSourceFromRegex(integrations, sourceSystem.value);
  } else if (typeof sourceSystem === 'string') {
    return integrations.find((integration) => integration.value === sourceSystem);
  }
  return null;
}

function getSourceFromRegex(integrations, value) {
  // Legacy: Replace any '.' or '^.' in the value start
  const fixedValue = value.replace(/^\^\.?/, '');
  if (fixedValue === '*') {
    return { display: 'All Systems', value: '*' };
  }
  return integrations.find((integration) => integration.value === fixedValue);
}
