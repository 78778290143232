import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/combineLatest';
import { batchActions } from 'redux-batched-actions';
import { concat, pipe, map, uniq } from 'lodash/fp';
import { loadIntegrations } from 'react/common/integrations/actions';
import { generateMetadata } from 'react/common/correlation_config/utils';
import {
  createCustomTag as createCustomTagRequest,
  deleteCustomTag as deleteCustomTagRequest,
  fetchCustomTags as fetchCustomTagsRequest,
  updateCustomTag as updateCustomTagRequest,
} from 'common/endpoints/correlation-config';
import { loadCorrelationConfig } from 'react/common/correlation_config/actions';
import { loadEnrichedUsers } from 'react/common/enriched_users/actions';
import { actionTypes as correlationConfigActionTypes } from 'react/common/correlation_config';
import { selectors as integrationsSelectors } from 'react/common/integrations';
import { showFailureMessage, showSuccessMessage } from 'react/layout/settings/feedback';
import { BamNotificationStatusOptions } from '@bp/bam';
import actionTypes from './actionTypes';
import actions from './actions';
import { TAG_TYPE, DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';
import { DROPDOWN_FILTER_STATUS_ITEMS } from '../constants';

const showSuccessMessageForTag = (action, status = BamNotificationStatusOptions.ACTION_SUCCESS) =>
  showSuccessMessage('Custom tag', action, status);

const showFailureMessageForTag = (action) => showFailureMessage('tag', action);

const addCustomTag = (action$, state$) => {
  const onSuccess = () => showSuccessMessageForTag('added');
  const onFailure = () => showFailureMessageForTag('add');
  return action$.ofType(actionTypes.ADD_CUSTOM_TAG).mergeMap(async ({ payload }) => {
    const { description, ...fields } = payload;
    const newCustomTag = {
      ...fields,
      metadata: generateMetadata(fields, state$.getState(), description),
    };
    return executeRequest(createCustomTagRequest(newCustomTag), onSuccess, onFailure);
  });
};

const deleteCustomTag = (action$) => {
  const onSuccess = () => showSuccessMessageForTag('deleted');
  const onFailure = () => showFailureMessageForTag('delete');
  return action$
    .ofType(actionTypes.DELETE_CUSTOM_TAG)
    .mergeMap(async ({ payload: id }) =>
      executeRequest(deleteCustomTagRequest(id), onSuccess, onFailure)
    );
};

const updateCustomTag = (action$, state$) => {
  const onSuccess = () => showSuccessMessageForTag('updated');
  const onFailure = () => showFailureMessageForTag('update');
  return action$.ofType(actionTypes.UPDATE_CUSTOM_TAG).mergeMap(async ({ payload }) => {
    const { customTags: loadedCustomTags, id, description, ...fields } = payload;
    const customTags = loadedCustomTags || (await fetchCustomTagsRequest());
    const updatedCustomTag = customTags.find((i) => i.id === id);
    if (!updatedCustomTag) {
      throw new Error(`Cannot update custom tag: id ${id} not found`);
    }
    Object.assign(updatedCustomTag, fields);
    Object.assign(updatedCustomTag, {
      metadata: generateMetadata(updatedCustomTag, state$.getState(), description),
    });
    return executeRequest(updateCustomTagRequest(updatedCustomTag), onSuccess, onFailure);
  });
};

async function executeRequest(requestPromise, onSuccess, onFailure) {
  try {
    await requestPromise;
    // As per BUG-691 - we need to delay reloading the config after changes.
    return loadCorrelationConfig({ delay: true }, onSuccess);
  } catch (e) {
    return loadCorrelationConfig({ delay: true }, onFailure);
  }
}

const loadCustomTags = (action$) =>
  action$
    .ofType(actionTypes.LOAD_CUSTOM_TAGS)
    .map(() => batchActions([loadCorrelationConfig(), loadIntegrations(), loadEnrichedUsers()]));

const loadEnrichedCustomTagsSuccess = (action$) =>
  action$
    .ofType(correlationConfigActionTypes.ENRICH_CORRELATION_CONFIG_ITEMS_SUCCESS)
    .mergeMap(async ({ payload }) => {
      const { customTags } = payload;
      const enrichedCustomTags = customTags.map((customTag) => {
        const sourceSystem =
          customTag.sourceSystems && customTag.sourceSystems.length
            ? customTag.sourceSystems[0]
            : null;
        return { ...customTag, sourceSystem: sourceSystem ? { ...sourceSystem } : null };
      });
      return actions.loadCustomTagsSuccess(enrichedCustomTags);
    });
const createFiltersDropDownItems = (action$, state$) =>
  action$.ofType(actionTypes.LOAD_CUSTOM_TAGS_SUCCESS).map(({ payload }) => {
    const tagNameItemsArray = pipe(
      map((costumTagItem) => costumTagItem.destination),
      uniq,
      map((tag) => ({ text: tag, value: tag, key: tag })),
      Object.values,
      concat([DROPDOWN_FILTERS_ALL_ITEM_OPTION.tagName])
    )(payload);
    const sourceItemsArray = [
      DROPDOWN_FILTERS_ALL_ITEM_OPTION.source,
      { text: 'All Systems', value: '*', key: '*' },
    ].concat(
      integrationsSelectors
        .getIntegrationOptions(state$.getState())
        .map((i) => ({ key: i.value, text: i.display, value: i.value }))
    );

    const typeItemsArray = [DROPDOWN_FILTERS_ALL_ITEM_OPTION.type].concat(
      Object.entries(TAG_TYPE).map(([a, b]) => ({ key: b, text: a, value: b }))
    );

    const statusItemsArray = [DROPDOWN_FILTERS_ALL_ITEM_OPTION.status].concat(
      DROPDOWN_FILTER_STATUS_ITEMS
    );

    return actions.createFiltersDropDownItemsSuccess({
      tagName: tagNameItemsArray,
      source: sourceItemsArray,
      type: typeItemsArray,
      status: statusItemsArray,
    });
  });

export default combineEpics(
  loadCustomTags,
  loadEnrichedCustomTagsSuccess,
  addCustomTag,
  updateCustomTag,
  deleteCustomTag,
  createFiltersDropDownItems
);
