export default {
  alert_filter: 'Alert Filter',
  alert_filtering: 'Alert Filtering',
  select_filter_to_see_details: 'Select a filter to see details',
  new_filter: 'New Filter',
  new_alert_filter: 'New Alert Filter',
  edit_alert_filter: 'Edit Alert Filter',
  duplicate_alert_filter: 'Duplicate Alert Filter',
  update_filter: 'Update Filter',
  create_filter: 'Create Filter',
  cancel: 'Cancel',
  created: 'Created',
  updated: 'Updated',
  created_at: 'Created at',
  updated_at: 'Updated at',
  status: 'Status',
  condition: 'Condition',
  description: 'Description',
  active: 'Active',
  inactive: 'Inactive',
  delete_filter_confirmation: 'Are you sure you want to delete this filter?',
  copy_of_filter: 'copy of {{filterName}}',
  no_alert_filter_added_yet: 'No Alert Filters Added Yet',
  find_filter_rule_placeholder: 'Find filter rule...',
  condition_is_required: 'Condition is required',
  name_is_required: 'Name is required',
  filter_name: 'Filter Name',
  enter_a_name_placeholder: 'Enter a name...',
  condition_placeholder: 'e.g. host = acme*',
  condition_tooltip: 'Alerts matching this BPQL query will be filtered out',
  add_description: 'Add Description',
  create_as_inactive: 'Create as inactive',
  create_as_inactive_tooltip: "Save the rule, but don't start filtering",
  no_filter_selected: 'No filter rule selected',
  any_status: 'Any Status',
  alert_filter_preview: 'Alert Filter Preview',
  empty_preview_message: 'Fill out the form to see matching alerts',
  filter_preview_subheader: '{{limit}} first matched filtered out alerts',
  refresh: 'Refresh',
  preview_filtered_alerts: 'Preview filtered alerts',
  definition_changed: 'Definition changed',
  source: 'Source',
  any_source: 'Any Source',
  all_systems: 'All Systems',
  systems: 'Systems',
  source_systems: 'Source Systems',
  timewindow: 'Time Window',
  in_progress: 'In Progress',
  scheduled: 'Scheduled',
  done: 'Done',
};
