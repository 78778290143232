const { react2angular } = require('react2angular');
const SplitModal = require('react/modules/feed/components/SplitModal').default;

angular
  .module('bigpanda')
  .component(
    'splitModal',
    react2angular(SplitModal, [
      'store',
      'alerts',
      'getEnvById',
      'envId',
      'incident',
      'searchValue',
      'search',
    ])
  );
