import clone from 'lodash/clone';
angular.module('bigpanda').service('IncidentObjectBuilderService', IncidentObjectBuilderService);

function IncidentObjectBuilderService(
  IncidentTitleService,
  sourceBreakdownService,
  IncidentTimestampService,
  CommentsBackendService,
  SharesStore,
  SnoozeStore,
  AssignmentsBackendService,
  UsersStore
) {
  this.getTimeProps = getTimeProps;
  this.breakdownSources = breakdownSources;
  this.getTitle = getTitle;
  this.getActiveEntitiesCount = getActiveEntitiesCount;
  this.getMaintenanceEntitiesCount = getMaintenanceEntitiesCount;
  this.getComments = CommentsBackendService.getComments;
  this.getShares = SharesStore.getShares;
  this.getSnoozeData = SnoozeStore.getSnoozeData;
  this.getAssignments = AssignmentsBackendService.getAssignments;
  this.getUsersByIds = UsersStore.getUsersByIds;

  function getActiveEntitiesCount(incident) {
    return incident.status === 'ok'
      ? incident.entities.length
      : incident.entities.filter(
          (e) => e.is_active && (!e.maintenance_plan_ids || e.maintenance_plan_ids.length === 0)
        ).length;
  }

  function getMaintenanceEntitiesCount(incident) {
    return incident.entities.filter(
      (e) => e.maintenance_plan_ids && e.maintenance_plan_ids.length > 0
    ).length;
  }

  function getTimeProps(incident, folderId) {
    return IncidentTimestampService.getIncidentTimestamp(incident, folderId);
  }

  function breakdownSources(incident) {
    const breakdown = sourceBreakdownService.getSourceBreakdownData(
      incident.entities,
      incident.activeOnly
    );
    const systems = breakdown.systems;

    if (systems.length > 1) {
      const parentSystems = clone(breakdown.parentSystems);
      return {
        lastSystem: parentSystems.pop(),
        otherSystems: parentSystems.join(', '),
      };
    }

    return {
      lastSystem: breakdown.systems[0],
      otherSystems: null,
    };
  }

  function getTitle(incident) {
    return IncidentTitleService.getTitle(incident, {
      fontData: '',
      widthLimit: {
        max: 500,
        dynamicMin: 50,
      },
    });
  }
}
