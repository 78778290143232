angular
  .module('bigpanda.reports')
  .directive('bpMttrBarchart', ($q, $window, pubSubService, D3ComboBarchartFactory) => {
    return {
      restrict: 'E',
      templateUrl: 'reports/visualization_widget/visualizations/mttr_barchart/mttr_barchart',
      require: '^bpVisualizationWidgetContainer',
      scope: {},
      link: link,
    };

    function link($scope, $element, $attrs, containerController) {
      var unsubscribe = pubSubService.on(
        'reportWidgetsController.refreshReportData',
        onRefreshReportData.bind(undefined, $scope, $element, containerController)
      );

      $scope.$on('$destroy', unsubscribe);

      $scope.chart = D3ComboBarchartFactory()
        .timestamp((d) => d.key)
        .duration((d) => (d.sum_duration.value / d.distinct_count.value) * 1000 || 0)
        .incidentCount((d) => d.distinct_count.value);

      function resize() {
        d3.select($element[0]).call($scope.chart.resize, containerController.getLayoutName());
      }

      $q.when(containerController.widgetDefinition).then((widget) => {
        angular.element($window).on(`resize.${widget._id}`, () => {
          if (!containerController.dataLoading && !containerController.error) {
            resize();
          }
        });

        if ($window.matchMedia) {
          $window.matchMedia('print').addListener((media) => {
            resize();
          });
        }
        if ($window.onbeforeprint) {
          (function (oldOnbeforeprint) {
            $window.onbeforeprint = function () {
              resize();
              oldOnbeforeprint();
            };
          })($window.onbeforeprint);
        } else {
          (function (oldPrint) {
            $window.print = function () {
              resize();
              oldPrint();
            };
          })($window.print);
        }
      });

      onRefreshReportData($scope, $element, containerController);
    }

    function onRefreshReportData($scope, $element, containerController) {
      containerController.getDisplayConfiguration().then((displayConfig) =>
        containerController.getReportData().then((data) => {
          $scope.chart.setTimeGranularity(displayConfig.timeGranularity);
          d3.select($element[0])
            .datum(data)
            .call($scope.chart.render, containerController.getLayoutName());
        })
      );
    }
  });
