angular
  .module('bigpanda')
  .service('CorrelationTimeframePresets', function CorrelationTimeframePresets() {
    const CUSTOM_PRESET = {
      timeframe: { type: 'absolute', interval: 'custom' },
      title: 'Pick Date Range...',
      iconClass: 'bp-icon-calendar',
    };

    this.getPresets = getPresets;

    function getPresets() {
      return [
        createAgoTimeframe('Last Hour', 1, 'hours'),
        createAgoTimeframe('Last 2 Hours', 2, 'hours'),
        createAgoTimeframe('Last 6 Hours', 6, 'hours'),
        createAgoTimeframe('Last 24 Hours', 24, 'hours'),
        createAgoTimeframe('Last 7 Days', 7, 'days'),
        CUSTOM_PRESET,
      ];
    }

    function createAgoTimeframe(title, count, time) {
      return {
        timeframe: {
          start: moment().subtract(count, time),
          end: moment().toDate(),

          // These fields are basically a patch so we won't have to touch the timeframe-picker
          // directive for now which rely on those fields (we don't use or need them)
          interval: title,
          type: 'relative',
        },
        getStartMoment: () => moment().subtract(count, time),
        title,
      };
    }
  });
