import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import { TAG_TYPE } from '../../constants';
import { hasRegexValidationError, validateDisplayQuery } from '../../validation.utils';

const previewFieldsByType = {
  [TAG_TYPE.Composition]: ['integration', 'template'],
  [TAG_TYPE.Extraction]: ['integration', 'source'],
  [TAG_TYPE['Multi Type']]: [],
  [TAG_TYPE.Mapping]: true,
};

const FIELD_TYPE = {
  CONDITION: 'condition',
  REGEX: 'regex',
};

const previewValidErrorsByFieldName = {
  [FIELD_TYPE.CONDITION]: validateDisplayQuery(' '),
  [FIELD_TYPE.REGEX]: hasRegexValidationError(''),
};

const getSpecificFieldByFieldName = (fieldName) => {
  if (fieldName.includes(FIELD_TYPE.CONDITION)) return FIELD_TYPE.CONDITION;
  if (fieldName.includes(FIELD_TYPE.REGEX)) return FIELD_TYPE.REGEX;
  return undefined;
};

const canPreviewWithTheseErrors = (error, fieldName) => {
  const field = getSpecificFieldByFieldName(fieldName);
  return field && previewValidErrorsByFieldName[field]
    ? previewValidErrorsByFieldName[field].includes(error)
    : false;
};

const canPreviewByType = (type, values) => {
  const fieldsByType = previewFieldsByType[type];
  if (!Array.isArray(fieldsByType)) return fieldsByType;
  return !fieldsByType.map((field) => trim(values[field])).some(isEmpty);
};

export { canPreviewByType, canPreviewWithTheseErrors };
