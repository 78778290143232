import { hot } from 'react-hot-loader';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsListScreen from 'react/layout/settings/SettingsListScreen';
import { SortShape } from 'react/layout/settings/components/SettingsList';
import provideStore from 'react/utils/provideStore';
import actions from '../actions';
import selectors from '../selectors';
import ListItem from './list/ListItem';
import { SORT_MODES } from '../constants';
import IntegrationsModal from '../../integrations_info/components/modal/IntegrationsModal';
import './integrations_list.scss';

class IntegrationsList extends React.Component {
  componentDidMount() {
    const { loadIntegrations, integrations } = this.props;

    if (!integrations) {
      loadIntegrations();
    }
  }

  render() {
    const {
      integrations = [],
      filterText,
      sortMode,
      updateFilterText,
      isLoading,
      openModal,
    } = this.props;

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsListScreen
          loading={isLoading || !integrations}
          items={integrations}
          noItemsTitle="No Integrations Added Yet"
          createItemProps={{
            modal: {
              title: 'New Integration',
              titleIcon: 'bp-icon-plus-icon',
              component: IntegrationsModal,
              openModal: openModal,
            },
            itemName: 'integration',
            buttonText: 'New Integration',
          }}
          listItem={ListItem}
          listItemProp="integration"
          permissionName="integrations"
          headerName="Integrations"
          filterPlaceholderText="Find Integrations..."
          sortHeaderLink="https://docs.bigpanda.io/"
          updateFilterText={updateFilterText}
          filterText={filterText}
          sortModes={SORT_MODES}
          sortMode={sortMode}
          hideSorting
        />
      </ThemeProvider>
    );
  }
}

IntegrationsList.propTypes = {
  integrations: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  loadIntegrations: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  updateFilterText: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  sortMode: SortShape.isRequired,
};

IntegrationsList.defaultProps = {
  filterText: '',
};

function mapStateToProps(state) {
  return {
    integrations: selectors.getIntegrations(state),
    isLoading: selectors.isLoading(state),
    filterText: selectors.getFilterText(state),
    sortMode: selectors.getSortMode(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadIntegrations: (payload) => dispatch(actions.loadIntegrations(payload)),
    updateFilterText: (filterText) => dispatch(actions.updateFilterText(filterText)),
  };
}

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(IntegrationsList))
);
