import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { BamConfirmModal } from '@bp/bam';
import { bp_blue } from '@bp/pastel/colors';
import { getUserName } from '../constants';
import actions from '../actions';

const UserManagmentResendInvitation = ({ user, text, buttonType, resendUserInvitation }) => {
  const inviteIcon = 'bp-icon-plane';
  const userName = getUserName(user);

  return (
    <BamConfirmModal
      icon={inviteIcon}
      headerTitle="Resend Invitation to..."
      content="Do you want to resend the invitation email to this user?"
      contentTitle={userName}
      contentTitleType="string"
      destructive={false}
      actionText="Send Invite"
      action={() => resendUserInvitation(user.id)}
      buttonProps={{
        type: buttonType || 'cta-action',
        iconClass: inviteIcon,
        tooltipText: 'Resend invitation',
        tooltipPosition: 'bottom right',
        iconColor: bp_blue,
        text,
      }}
    />
  );
};

UserManagmentResendInvitation.propTypes = {
  user: PropTypes.object.isRequired /* eslint-disable-line */,
  resendUserInvitation: PropTypes.func.isRequired,
  text: PropTypes.string,
  buttonType: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return { resendUserInvitation: (userId) => dispatch(actions.resendUserInvitation(userId)) };
};

export default connect(null, mapDispatchToProps)(hot(module)(UserManagmentResendInvitation));
