angular
  .module('bigpanda')
  .controller('AssignActivityEventController', AssignActivityEventController);

function AssignActivityEventController($scope, ContactsStore) {
  formatAssigneeName($scope.event);

  function formatAssigneeName(event) {
    ContactsStore.getContactByUserId(event._source.params.assignee.id).then((contact) => {
      event.assigneeName = contact.name || contact.email;
    });
  }
}
