import get from 'lodash/get';

angular.module('bigpanda').controller('overviewCtrl', overviewCtrl);

function overviewCtrl(
  $scope,
  $state,
  SortingStore,
  $ngRedux,
  FullscreenStore,
  Permissions,
  PersonalSettingsStore,
  ModalService,
  Config,
  $stateParams,
  pubSubService,
  UserFeatureTogglesService
) {
  const vm = this;
  $scope.updatePage('overview');
  $scope.empty = $state.current.name === 'app.overview.empty';
  $scope.hasPermissions = Permissions.checkBasicPermissions();
  vm.isV2Feed =
    (UserFeatureTogglesService.getToggle('incident_console_v2') ||
      (UserFeatureTogglesService.getToggle('console_v2_super_admin') &&
        get($ngRedux.getState(), 'user.permissions.roles', []).some(
          (r) => r.key === 'superadmin'
        ))) &&
    localStorage.getItem('isIncidentConsoleV2') !== 'no';

  if (vm.isV2Feed) {
    const { incidentId, environment, folder = 'active' } = $stateParams;
    const isTimeline = window.location.hash.includes('/timeline');
    const v2FeedUrl = environment
      ? `/v2/feed/${environment}${incidentId ? `/${incidentId}` : ''}?folder=${folder}${
          isTimeline ? '&timeline=true' : ''
        }`
      : '/v2/feed';
    window.location.href = v2FeedUrl;
    return null;
  }
  $scope.$on('$destroy', SortingStore.resetSortBy);

  function stateChanged(event, data) {
    vm.incidentFullScreen = data.incidentFullScreen;
  }

  pubSubService.on('incidentfullscreen', stateChanged, $scope);

  vm.incidentFullScreen = $stateParams.incidentfullscreen;

  vm.fullscreen = FullscreenStore.getFullscreen();

  FullscreenStore.subscribe($scope, {
    storeUpdated: () => {
      vm.fullscreen = FullscreenStore.getFullscreen();
    },
  });

  checkSso();

  function checkSso() {
    const ssoNotice = $.cookie('sso_notice');

    if (!ssoNotice) {
      PersonalSettingsStore.getUser().then((user) => {
        const ssoConfig =
          user.organization && user.organization.config && user.organization.config.sso;

        if (ssoConfig && ssoConfig.tester == user.username && ssoConfig.status == 'tested') {
          const newScope = $scope.$new();
          newScope.header = 'SSO Validation';
          newScope.title =
            "You're in the middle of configuring SSO for your organization. Would you like to continue?";
          newScope.callback = () => {
            $state.go('app.settings.sso', { guide: '' });
          };

          ModalService.showModal({
            templateUrl: 'widgets/bp_prompt/bp_prompt',
            controller: 'bpPromptController',
            scope: newScope,
          });
          // $modal({ template: 'widgets/popup_prompt/prompt', scope: newScope });

          let domain = Config.domain;
          if (domain === 'localhost') domain = null;

          const now = moment();
          now.add('hours', 24);
          $.cookie('sso_notice', true, {
            expires: now.toDate(),
            path: '/',
            domain: domain,
          });
        }
      });
    }
  }
}
