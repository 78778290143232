import actionTypes from './actionTypes';
import { generateBaseSettingsActions } from '../redux.utils';

const actions = generateBaseSettingsActions(actionTypes);

actions.loadCorrelationPatterns = () => ({ type: actionTypes.LOAD_CORRELATION_PATTERNS });
actions.loadCorrelationPatternsSuccess = (payload) => ({
  type: actionTypes.LOAD_CORRELATION_PATTERNS_SUCCESS,
  payload: payload,
});
actions.addCorrelationPattern = (payload) => ({
  type: actionTypes.ADD_CORRELATION_PATTERN,
  payload: payload,
});
actions.updateCorrelationPattern = (payload) => ({
  type: actionTypes.UPDATE_CORRELATION_PATTERN,
  payload: payload,
});
actions.deleteCorrelationPattern = (payload) => ({
  type: actionTypes.DELETE_CORRELATION_PATTERN,
  payload: payload,
});

export default actions;
