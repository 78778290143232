const { cacheTillFinished } = require('common/utils/http/cache');

angular
  .module('bigpanda.backend.services')
  .service('ContactsBackendService', ContactsBackendService);

function ContactsBackendService($http) {
  this.getAllContacts = cacheTillFinished(getAllContacts);
  this.createContact = createContact;
  this.updateContact = updateContact;

  function getAllContacts() {
    return $http.get('/web/contacts').then((res) => res.data.item);
  }

  function createContact(contact) {
    return $http.post('/web/contacts', contact).then((res) => res.data.item);
  }

  function updateContact(id, data) {
    return $http.patch(`/web/contacts/${id}`, data).then((res) => res.data.item);
  }
}
