import has from 'lodash/has';
import tail from 'lodash/tail';
import initial from 'lodash/initial';
import includes from 'lodash/includes';
angular.module('bigpanda.utils').factory('PropertiesLookupService', () => {
  var getSubPropertyByLookupNotation = function (obj, selector, defaultValue) {
    /* NOTE:
     * selector can be:
     * any dot notation : "aa.bb.cc" => {"aa":{"bb":{"cc":'value'}}} => returns value
     * array filters : "aa.[val]" => {"aa":['val'] => Returns true
     * object filters : "aa.[val]" => {"aa":{'val':123}} => Returns 123 -- Should not be needed, the usual dot notation should cover this
     */
    if (!obj) {
      return obj;
    }
    if (!includes(selector, '.')) {
      if (selector[0] == '[') {
        // array/obj notation
        var sel = initial(tail(selector));
        return obj[sel] || defaultValue;
      }
      return has(obj, selector) ? obj[selector] : defaultValue;
    }

    var split = selector.split('.').reverse();
    var leftKey = split.pop();
    var rest = split.reverse().join('.');

    if (!has(obj, leftKey)) {
      return defaultValue;
    }

    return getSubPropertyByLookupNotation(obj[leftKey], rest, defaultValue);
  };

  var propertiesLookupService = function (data, property) {
    this.userProperties = getSubPropertyByLookupNotation(data || {}, property, {});
    var self = this;
    this.getProperty = function (prop) {
      return getSubPropertyByLookupNotation(self.userProperties, prop, null);
    };
  };

  propertiesLookupService.getSubPropertyByLookupNotation = getSubPropertyByLookupNotation;

  return propertiesLookupService;
});
