import React from 'react';
import last from 'lodash/last';
import get from 'lodash/get';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import flatten from 'lodash/flatten';
import { TimeFormats } from '@bp/bam';
import { TAG_TYPE } from '../../constants';
import {
  ExtractionTag,
  CompositionTag,
} from '../../../../../../../workspaces/apps/alert-enrichment/src/components/HighlightTag';
import { normalizeType } from '../../../../topology/logic/logic';
import getRuleIntegrationDisplay from './getRuleIntegrationDisplay';
import removeLeadSnake from '../utils/removeLeadSnake';

const { momentOrEpochRelativeTimeFormat } = TimeFormats;

const primaryTagsByType = (type = '') => {
  const tagsByType = {
    [TAG_TYPE.Extraction]: ['source'],
  };
  return tagsByType[type.toLowerCase()] || [];
};

// delicious curry
const isTagType = (tagType = '') => ({ type = '' } = {}) =>
  normalizeName(type) === normalizeName(tagType);
const isExtraction = isTagType(TAG_TYPE.Extraction);
const isComposition = isTagType(TAG_TYPE.Composition);
export const normalizeName = (type = '') => normalizeType(type || '-').toLowerCase();
export const getReferenceTagNames = (previewAlerts) =>
  Object.keys(get(previewAlerts, ['0', 'referenceTags'], {}));

const tagColumn = ({
  type,
  isPrimary,
  previewRule = {},
  frozen,
  highlight,
  width,
  columnWidths = {},
}) => ({
  id: type || '-',
  headerContent: removeLeadSnake(type || '-'),
  accessor: (d) => {
    if (isExtraction(previewRule) && isPrimary && !previewRule.regex) {
      return '';
    }
    if (isComposition(previewRule) && d.referenceTags[type]) {
      return d.referenceTags[type];
    }
    const { value = '' } = d.tags.find((t) => removeLeadSnake(t.type) === type) || {};
    return Array.isArray(value) ? value.join(' | ') : value.toString();
  },
  tooltipContent: ({ value = '' }) => {
    try {
      return decodeURI(value);
    } catch (_) {
      return value;
    }
  },
  cellContent: ({ value, original: { referenceTags: tags } }) => {
    const { regex, template } = previewRule;
    if (isExtraction(previewRule) && highlight && regex) {
      return <ExtractionTag value={value} regex={regex} />;
    } else if (isComposition(previewRule) && highlight && template) {
      return <CompositionTag template={template} referenceTags={tags} />;
    }
    return value;
  },
  sortable: false,
  frozen,
  ...(columnWidths[type] || width ? { width: columnWidths[type] || width } : {}),
});

const getFirstColumn = (type, previewRule = {}, columnWidths) =>
  tagColumn({
    type,
    isPrimary: true,
    previewRule,
    frozen: 'left',
    highlight: isComposition(previewRule),
    width: isComposition(previewRule) ? 200 : 150,
    columnWidths,
  });

const getReferenceColumns = ({
  previewRule,
  tags,
  referenceTagNames,
  columnWidths,
  primaryTagType,
}) => {
  if (isComposition(previewRule)) {
    return referenceTagNames.map((type) =>
      tagColumn({ type, previewRule, width: 150, columnWidths })
    );
  }

  const primaryTagsValues = primaryTagsByType(previewRule.type).map((tagName) =>
    normalizeName(previewRule[tagName])
  );

  if (!primaryTagsValues.length) return [];

  return tags
    .filter(
      ({ type }) =>
        normalizeName(type) !== normalizeName(primaryTagType) &&
        primaryTagsValues.includes(normalizeName(type))
    )
    .map(({ type }) => tagColumn({ type, previewRule, highlight: true, width: 220, columnWidths }));
};

const previewColumns = ({
  previewAlerts = [],
  showSource = false,
  tagName,
  previewRule = {},
  integrations,
  columnWidths = {},
}) => {
  if (!previewAlerts.length) return [];

  const tags = unionBy(flatten(map(previewAlerts, 'tags')), ({ type }) => removeLeadSnake(type));
  const referenceTagNames = getReferenceTagNames(previewAlerts);

  const sourceColumn = showSource
    ? [
        {
          id: 'source_system',
          headerContent: 'source',
          accessor: 'source_system',
          tooltipContent: ({ value }) =>
            get(getRuleIntegrationDisplay(value, integrations), 'display'),
          cellContent: ({ value }) =>
            get(getRuleIntegrationDisplay(value, integrations), 'display'),
          sortable: false,
          ...(columnWidths.source_system ? { width: columnWidths.source_system } : {}),
        },
      ]
    : [];

  const changedColumn = {
    id: 'changed',
    headerContent: 'Changed',
    accessor: 'timestamp',
    cellContent: ({ value }) => momentOrEpochRelativeTimeFormat(value),
    tooltipContent: ({ value }) => momentOrEpochRelativeTimeFormat(value),
    width: columnWidths.changed || 120,
    sortable: false,
    frozen: 'right',
  };

  const primaryTag = tags.find(({ type }) => normalizeName(type) === normalizeName(tagName));
  const referenceColumns = getReferenceColumns({
    previewRule,
    tags,
    columnWidths,
    primaryTagType: get(primaryTag, 'type'),
    referenceTagNames,
  });

  const tagValuesToOmit = [
    ...(primaryTag ? [normalizeName(primaryTag.type)] : []),
    ...primaryTagsByType(previewRule.type).map((tagName) => normalizeName(previewRule[tagName])),
    ...referenceTagNames,
  ];

  const tagsColumns = tags
    .filter(({ type }) => !tagValuesToOmit.includes(normalizeName(type)))
    .map(({ type }) => tagColumn({ type, previewRule, columnWidths }));

  return [
    getFirstColumn(get(primaryTag, 'type', tagName), previewRule, columnWidths),
    ...referenceColumns,
    ...sourceColumn,
    ...tagsColumns,
    changedColumn,
  ];
};

const initPreviewColumns = ({
  previewAlerts,
  previewRule = {},
  tagName,
  integrations,
  columnWidths,
}) =>
  previewColumns({
    previewAlerts,
    showSource: last(previewRule.integration) === '*',
    tagName,
    previewRule: previewRule || {},
    integrations,
    columnWidths,
  });

export default initPreviewColumns;
