import reject from 'lodash/reject';
angular
  .module('bigpanda')
  .service('UserMenuService', function ($state, Config, pubSubService, AuthService, stateService) {
    const docsBaseUrl = Config.docsUrl;

    function logout() {
      AuthService.resetUser({ sso: stateService.getSso() });
    }

    function gotoSettings() {
      $state.go('app.settings.personal');
    }

    function feedback() {
      pubSubService.broadcast('Intercom.show');
    }

    let actionsList = [
      { id: 'docs', icon: 'bp-icon-file-document-text', label: 'Docs', href: docsBaseUrl },
      { id: 'settings', icon: 'bp-icon-settings', label: 'Settings', action: gotoSettings },
      {
        id: 'support',
        icon: 'bp-icon-feedback',
        label: 'Support',
        action: feedback,
        compactMode: true,
      },
      { id: 'divider', separator: true },
      {
        id: 'logout',
        icon: 'bp-icon-power-off',
        label: 'Logout',
        action: logout,
        compactMode: true,
      },
    ];

    if (!Config.intercomAppId) {
      actionsList = reject(actionsList, (item) => item.id === 'support');
    }

    this.actions = (compactMode) =>
      compactMode ? actionsList.filter((v) => v.compactMode === true) : actionsList;
  });
