import React from 'react';
import { SCHBox, HelpTooltip } from '@bp/kung-fu';
import PropTypes from 'prop-types';

const SectionRowTitle = ({ title, tooltip }) => {
  return (
    <SCHBox gap="5px">
      {title}
      <HelpTooltip text={tooltip} />
    </SCHBox>
  );
};
SectionRowTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default SectionRowTitle;
