import { path } from './constants';
import { generateBaseSettingsActionPaths } from '../redux.utils';

const actionTypes = generateBaseSettingsActionPaths(path);

actionTypes.LOAD_API_KEYS = `${path}.LOAD_API_KEYS`;
actionTypes.LOAD_API_KEYS_SUCCESS = `${path}.LOAD_API_KEYS_SUCCESS`;
actionTypes.ENRICH_API_KEYS_SUCCESS = `${path}.ENRICH_API_KEYS_SUCCESS`;
actionTypes.CREATE_API_KEY = `${path}.CREATE_API_KEY`;
actionTypes.UPDATE_API_KEY = `${path}.UPDATE_API_KEY`;
actionTypes.DELETE_API_KEY = `${path}.DELETE_API_KEY`;
actionTypes.RESTORE_API_KEY = `${path}.RESTORE_API_KEY`;
actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS = `${path}.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS`;
actionTypes.UPDATE_FILTERS_DROPDOWNS = `${path}.UPDATE_FILTERS_DROPDOWNS`;
export default actionTypes;
