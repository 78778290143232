import {
  AllSizes, Avatar,
} from '@bp/kung-fu';
import React from 'react';

import { systemGeneratedUser } from '../../../../constants';

function UserAvatar({ userName }: { userName: string }): JSX.Element {
  const avatarUrl = userName === systemGeneratedUser.name ? '/assets/img/BP_USER.png' : '';
  const isRootUser = userName === 'N/A';
  const userNameValue = isRootUser ? '?' : userName;

  return (
    <Avatar
      name={userNameValue}
      size={AllSizes.SMALL}
      url={avatarUrl}
      bgColor={isRootUser && ((p): string => p.theme.bp_gray_03)}
    />
  );
}

export default UserAvatar;
