import actionTypes from './actionTypes';

const initialState = {
  usersList: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
      };

    default:
      return state;
  }
}
