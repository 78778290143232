import { combineEpics } from 'redux-observable';
import { getContacts } from 'common/endpoints/contacts';
import actionTypes from './actionTypes';
import * as actions from './actions';

const loadContacts = ($action) =>
  $action
    .ofType(actionTypes.LOAD_CONTACTS)
    .mergeMap(() => getContacts().then((payload) => actions.loadContactsSuccess(payload)));

export default combineEpics(loadContacts);
