import {
  darkTheme,
  HelperType,
  IDataListObject,
  LabelIcon,
  QueryHelper,
  SearchField,
  TagIcon,
} from '@bp/kung-fu';
import get from 'lodash/get';
import React, {
  useCallback, useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { loadAlertTags } from '../../../../../../app/react/common/alert_tags/actions';
import selectors from '../../../../../../app/react/common/alert_tags/selectors';
import provideStore from '../../../../../../app/react/utils/provideStore';
import { DROPDOWMN_SHADOW, INCIDENT_TAGS_METADATA } from './constants';
import text from './dictionary';
import { QueryHelperWrapper } from './QueryHelper.style';
import { IQueryHelper } from './types/IQueryHelper';

function ChangesQueryHelper({
  handleSearch,
  incidentTags,
  error,
  displayCustomTags,
  triggerComponentProps,
}: IQueryHelper): JSX.Element {
  const dispatch = useDispatch();
  const alertTags = useSelector((state) => selectors.getDisplayAlertTags(state)) || [];

  const buildIncidentTagsData = (): IDataListObject => {
    const incidentTagsValues = [...INCIDENT_TAGS_METADATA,
      ...(incidentTags && displayCustomTags
        ? incidentTags.map(({ name }) => ({ title: name })) : []),
    ];
    return ({
      title: text.incidents,
      type: HelperType.INCIDENT_TAGS,
      disabled: false,
      icon: <LabelIcon />,
      values: incidentTagsValues,
    });
  };

  const buildAlertTagsData = (): IDataListObject => {
    const alertTagsValues = alertTags.map(({ name, sourceSystems }) => ({
      title: name,
      description: get(sourceSystems, '0.parentDisplayName'),
    }));
    return alertTags.length && ({
      title: text.alert_tags,
      type: HelperType.ALERT_TAGS,
      disabled: false,
      icon: <TagIcon />,
      values: alertTagsValues,
    });
  };

  const handleSearchQuery = useCallback((searchQuery: string) => {
    handleSearch(searchQuery);
  }, []);

  useEffect(() => {
    dispatch(loadAlertTags());
  }, []);

  const incidentTagsQueryHelperData = useMemo(buildIncidentTagsData, [incidentTags]);

  const alertTagsQueryHelperData = useMemo(buildAlertTagsData, [alertTags]);

  const queryHelperData = [
    ...(incidentTagsQueryHelperData ? [incidentTagsQueryHelperData] : []),
    ...(alertTagsQueryHelperData ? [alertTagsQueryHelperData] : []),
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <QueryHelperWrapper>
        <QueryHelper
          placeholder={text.input_placeholder}
          data={queryHelperData}
          TriggerComponent={SearchField}
          onClick={handleSearchQuery}
          error={error}
          dropDownShadow={DROPDOWMN_SHADOW}
          triggerComponentProps={triggerComponentProps}
        />
      </QueryHelperWrapper>
    </ThemeProvider>
  );
}

export default provideStore(ChangesQueryHelper);
