import actionTypes from './actionTypes';

const actions = {};

actions.loadRelatedChanges = (payload) => ({ type: actionTypes.LOAD_RELATED_CHANGES, payload });
actions.loadRelatedChangesSuccessfully = (payload = {}) => ({
  type: actionTypes.LOAD_RELATED_CHANGES_SUCCESSFULLY,
  payload,
});
actions.loadRelatedChangesFailed = (payload = {}) => ({
  type: actionTypes.LOAD_RELATED_CHANGES_FAILED,
  payload,
});

export default actions;
