import React from 'react';
import { Text } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { AITagPrefix } from '../../style.css';

const AIAnalysisKey = ({ name, value }) => {
  return (
    <Text lineHeight="150%" color={(p) => p.theme.v2_text_5} key={name}>
      <AITagPrefix color={(p) => p.theme.v2_text_1}>{`${name}:`}</AITagPrefix>
      {value}
    </Text>
  );
};

AIAnalysisKey.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

export default AIAnalysisKey;
