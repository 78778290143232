import get from 'lodash/get';
import map from 'lodash/map';

import { extractTemplateToTokens } from '../../../../../app/common/formulas';
import { buildParserAndGenerateQuery } from '../../../../../app/common/modules/settings/QuerySourceGenerator';
import { TagType } from '../../types';
import configByType from './configByType';

const parseRule = ({
  id,
  destination,
  type,
  condition,
  source,
  regex,
  integration,
  template = null,
  description: note = null,
  active,
  __isNew,
  config,
}) => ({
  id,
  type,
  active,
  when: buildParserAndGenerateQuery(condition, [integration]),
  config: configByType({
    ...config, type, destination, template, source, regex, integration,
  }),
  note,
  __isNew,
});

const parseRuleForPreview = (
  {
    id,
    destination,
    type,
    condition: ruleCondition,
    source,
    regex,
    integration,
    template,
    description: note = null,
    __isNew,
    config,
  },
  reservedTagsNames = [],
) => {
  const baseRule = {
    id,
    type,
    active: true,
    config: configByType({
      ...config, type, destination, template, source, regex,
    }),
    note,
    __isNew,
  };

  if (type === TagType.Composition) {
    const tagsCondition = map(
      extractTemplateToTokens(template).filter(({ type: tagType }) => tagType === 'tag'),
      'value',
    )
      .map((value) => `${value} = *`)
      .join(' AND ');
    const condition = ruleCondition && tagsCondition
      ? [ruleCondition, tagsCondition].join(' AND ')
      : tagsCondition || ruleCondition;
    return {
      ...baseRule,
      when: buildParserAndGenerateQuery(condition, [integration]),
    };
  }

  let sourceTags = [];
  if (type === TagType.Mapping) {
    sourceTags = map(
      get(config, 'fields', []).filter(({ type: tagType }) => tagType === 'query_tag'),
      'tag_name',
    );
  } else {
    sourceTags = reservedTagsNames.includes(source) ? [] : [source];
  }

  return {
    ...baseRule,
    when: buildParserAndGenerateQuery(ruleCondition, [integration], sourceTags),
  };
};

export { parseRule, parseRuleForPreview };
