import { path } from './constants';

const actionTypes = {
  UPDATE_FILTER_TEXT: `${path}.UPDATE_FILTER_TEXT`,
  LOAD_AUTO_SHARE_RULES: `${path}.LOAD_AUTO_SHARE_RULES`,
  LOAD_AUTO_SHARE_RULES_SUCCESS: `${path}.LOAD_AUTO_SHARE_RULES_SUCCESS`,
  LOAD_AUTO_SHARE_RULES_FAILURE: `${path}.LOAD_AUTO_SHARE_RULES_FAILURE`,
  LOAD_RULES: `${path}.LOAD_RULES`,
  LOAD_RULES_SUCCESS: `${path}.LOAD_RULES_SUCCESS`,
  CREATE_AUTO_SHARE_RULE: `${path}.CREATE_AUTO_SHARE_RULE`,
  UPDATE_AUTO_SHARE_RULE: `${path}.UPDATE_AUTO_SHARE_RULE`,
  DELETE_AUTO_SHARE_RULE: `${path}.DELETE_AUTO_SHARE_RULE`,
  CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS: `${path}.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS`,
  UPDATE_FILTERS_DROPDOWNS: `${path}.UPDATE_FILTERS_DROPDOWNS`,
};

export default actionTypes;
