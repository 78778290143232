import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import cn from 'classnames';
import { Dot, darkTheme } from '@bp/kung-fu';
import Select from '../../../../../common/components/Select/Select';
import { statusToColor } from '../../../../../../../workspaces/apps/alert-enrichment/src/helpers/utils';
import { tagTypeString, isTagActive, multiTypeEnrichmentTypes } from '../../utils';
import isMapping from '../../modals/utils/isMapping';
import { tagPropType } from '../../propTypes';
import sendToOptions from './sendToOptions';
import RulesTooltip from './RulesTooltip';
import ToggleButton from './ToggleButton';
import styles from './DraggableTag.scss';
import { shouldUseTagOperations } from '../../../../../../../workspaces/apps/alert-enrichment/src/helpers/should-use-tag-operations';
import { selectors as FTSelectors } from '../../../../../user/feature_toggles';

const mappingBreakdown = (tagType, enrichments) => {
  if (!isMapping(tagType)) {
    return <RulesTooltip enrichments={enrichments} text={multiTypeEnrichmentTypes(enrichments)} />;
  }

  if (enrichments.length == 1) {
    return `Mapping (${enrichments[0].config.name})`;
  }

  return <RulesTooltip enrichments={enrichments} text={`${enrichments.length} Mappings`} />;
};

const TagSummary = ({ tag, index, hover, hoverOut }) => {
  const useTagOperations = shouldUseTagOperations(useSelector(FTSelectors.getFeatureToggles));
  const tagType = tagTypeString(tag);
  const { enrichments, name } = tag;
  const rowNum = index + 1;
  const length = rowNum.toString().length;
  const style = `summary-index-${length}`;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ThemeProvider theme={darkTheme}>
      <span className={styles.summary}>
        <span className={styles[style]}>#{index + 1}</span>
        <span className={styles['summary-name']}>{name}</span>
        <span className={styles['summary-actions']}>
          <span className={cn([styles['summary-functions']])}>
            {mappingBreakdown(tagType, enrichments)}
          </span>

          <Dot
            size="medium"
            color={statusToColor(isTagActive(tag, useTagOperations))}
            className={styles['summary-actions-status']}
          />

          <FormSpy>
            {({ form: { mutators } }) => (
              <Select
                items={sendToOptions}
                onChange={(val) => {
                  hoverOut();
                  mutators.moveTag(index, val === 0);
                }}
                selectToggle={(props) => <ToggleButton {...props} hover={hover || isOpen} />}
                inline
                ariaLabel="move tag"
                onToggle={setIsOpen}
              />
            )}
          </FormSpy>
        </span>
      </span>
    </ThemeProvider>
  );
};

TagSummary.propTypes = {
  tag: tagPropType.isRequired,
  index: PropTypes.number.isRequired,
  hover: PropTypes.bool.isRequired,
  hoverOut: PropTypes.func.isRequired,
};

export default TagSummary;
