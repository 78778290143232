import { path } from './constants';
import { generateBaseSettingsActionPaths } from '../redux.utils';

const actionTypes = {
  ...generateBaseSettingsActionPaths(path),
  LOAD_MULTIPLE_INCIDENT_LABELS: `${path}.LOAD_MULTIPLE_INCIDENT_LABELS`,
  LOAD_MULTIPLE_INCIDENT_LABELS_SUCCESS: `${path}.LOAD_MULTIPLE_INCIDENT_LABELS_SUCCESS`,
  LOAD_MULTIPLE_INCIDENT_LABELS_FAILURE: `${path}.LOAD_MULTIPLE_INCIDENT_LABELS_FAILURE`,
  UPDATE_INCIDENT_LABEL: `${path}.UPDATE_INCIDENT_LABEL`,
  UPDATE_INCIDENT_LABEL_SUCCESS: `${path}.UPDATE_INCIDENT_LABEL_SUCCESS`,
  UPDATE_INCIDENT_LABEL_FAILURE: `${path}.UPDATE_INCIDENT_LABEL_FAILURE`,
  CREATE_INCIDENT_LABEL: `${path}.CREATE_INCIDENT_LABEL`,
  CREATE_INCIDENT_LABEL_SUCCESS: `${path}.CREATE_INCIDENT_LABEL_SUCCESS`,
  CREATE_INCIDENT_LABEL_FAILURE: `${path}.CREATE_INCIDENT_LABEL_FAILURE`,
  CANCEL_LOCAL_INCIDENT_LABEL_CHANGES: `${path}.CANCEL_LOCAL_INCIDENT_LABEL_CHANGES`,
  LOAD_UNIFIED_TAGS_FOR_INCIDENT_LABEL: `${path}.LOAD_UNIFIED_TAGS_FOR_INCIDENT_LABEL`,
  DELETE_TAG_DEFINITION: `${path}.DELETE_TAG_DEFINITION`,
  DELETE_TAG_DEFINITION_SUCCESS: `${path}.DELETE_TAG_DEFINITION_SUCCESS`,
  DELETE_TAG_DEFINITION_FAILURE: `${path}.DELETE_TAG_DEFINITION_FAILURE`,
  CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS: `${path}.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS`,
  UPDATE_FILTERS_DROPDOWNS: `${path}.UPDATE_FILTERS_DROPDOWNS`,
};

export default actionTypes;
