import actionTypes from './actionTypes';
import { AlertFilter } from './types/AlertFilter';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION, DropdownRecord, DropdownRecords } from './types/DropdownOption';
import { SortMode } from './types/SortMode';
import { DEFAULT_SORT_MODE } from './types/SortModes';

export interface State {
  alertFilters: AlertFilter[];
  filterText: string;
  sortMode: SortMode;
  filterItems: DropdownRecords;
  filters: DropdownRecord;
  isLoading: boolean;
}

export const initialState: State = {
  alertFilters: [],
  filterText: '',
  sortMode: DEFAULT_SORT_MODE,
  filterItems: {
    status: [],
    source: [],
  },
  filters: DROPDOWN_FILTERS_ALL_ITEM_OPTION,
  isLoading: false,
};

const defaultAction = {
  type: '',
  payload: undefined,
};

export default function reducer(state: State = initialState, action = defaultAction): State {
  switch (action.type) {
    case actionTypes.LoadAlertFilters:
      return {
        ...state,
        isLoading: action.payload.reload,
      };
    case actionTypes.LoadAlertFiltersSuccess:
      return {
        ...state,
        alertFilters: action.payload as AlertFilter[],
        isLoading: false,
      };
    case actionTypes.LoadAlertFiltersFailure:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UpdateSortMode:
      return {
        ...state,
        sortMode: action.payload as SortMode,
      };
    case actionTypes.UpdateFilterText:
      return {
        ...state,
        filterText: action.payload as string,
      };
    case actionTypes.CreateFiltersDropdownItemsSuccess:
      return {
        ...state,
        filterItems: action.payload as DropdownRecords,
      };
    case actionTypes.UpdateFilters:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload as DropdownRecord,
        },
      };
    default:
      return state;
  }
}
