import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import { AI_TAGS } from '../../../common/constants';

const { loadRelatedChanges } = require('react/modules/overview/actions').default;

angular.module('bigpanda').directive('overviewTab', overviewTab);

const MAX_DISPLAYED_ACTIVITIES = 5;

const FILTERED_TAG_IDS_FROM_GRID = AI_TAGS;

function overviewTab() {
  return {
    restrict: 'E',
    scope: {
      entities: '<',
      incident: '<',
      statePath: '<',
      events: '<',
      selectTab: '=',
      relatedChangesRead: '=',
      search: '&',
      searchValue: '=',
    },
    bindToController: true,
    templateUrl: 'overview/overview_tab/overview_tab',
    controller: controller,
    controllerAs: 'vm',
  };

  function controller(
    $scope,
    $document,
    $element,
    $filter,
    $timeout,
    $window,
    $mdPanel,
    $mdMedia,
    $animate,
    $ngRedux,
    EntitiesService,
    $stateParams,
    ReduxBridgeService,
    ActivitiesService,
    LabelsStore,
    UserFeatureTogglesService,
    pubSubService,
    stateService,
    Permissions
  ) {
    const vm = this;
    vm.noop = () => {};
    vm.showAllBubble = false;
    let bubbleTimeout;
    $scope.$watch('vm.entities', (newEntities) => {
      updateTableHeight(newEntities);
      vm.entitiesDetailedCout = alertsCount(newEntities);
    });

    vm.environmentId = stateService.getSelectedEnvironmentId();
    vm.isFullPermission = Permissions.checkGranularPermission(
      'action@update',
      'environments',
      vm.environmentId
    );
    vm.shouldDisplayAiAnalysis = UserFeatureTogglesService.getToggle('ai_incident_summary');

    function updateTableHeight(newEntities, showMaxEntites) {
      const { currentFolder, incidentType } = EntitiesService.calcIncidentFolderAndType(
        vm.incident,
        $stateParams.folder
      );
      vm.currentFolderDisplay = incidentType;
      vm.currentEntitites = showMaxEntites
        ? newEntities.length
        : EntitiesService.filterEntitiesByFolder(newEntities, currentFolder);
      vm.totalEntities = newEntities.length;
      vm.calcHeightEntities = vm.currentEntitites;

      if (vm.calcHeightEntities < 2) {
        vm.entityRowHeight = 27;
      } else if (vm.calcHeightEntities == 2) {
        vm.entityRowHeight = 31;
      } else if (vm.calcHeightEntities == 3) {
        vm.entityRowHeight = 33;
      } else if (vm.calcHeightEntities == 4) {
        vm.entityRowHeight = 34;
      } else if (vm.calcHeightEntities > 4) {
        vm.entityRowHeight = 34;
        vm.calcHeightEntities = 4.5;
      }

      updateAlertsTitle();
    }

    $scope.$watch('vm.events', (newEntities, oldValue) => {
      updateTagDefinitions();

      vm.displayedEvents = newEntities.filter(ActivitiesService.filterStatusChanges);
    });

    $animate.enabled($element, false);

    const unsubscribe = ReduxBridgeService.subscribe('modules.overview', (overview) => {
      vm.relatedChangesCount = get(overview, 'changes.length', 0);
      vm.changesLoading = get(overview, 'loading', false);
    });

    $scope.$on('$destroy', function () {
      unsubscribe();
    });

    this.$onInit = init;

    vm.pluralize = pluralize;
    function init() {
      vm.relatedChangesRead &&
        $ngRedux.dispatch(loadRelatedChanges({ incidentId: vm.incident._id }));
      vm.statePath = 'modules.overview';
      vm.selectedEntities = 0;
      vm.calcHeightEntities = 0;
      vm.entityRowHeight = 29;
      vm.alertsTitle = `${vm.currentEntitites} ${vm.currentFolderDisplay} ${pluralize(
        vm.currentEntitites,
        'Alert'
      )}`;
      vm.displayedEvents = vm.events.filter(ActivitiesService.filterStatusChanges);
      vm.entitiesDetailedCout = alertsCount(vm.entities);
      vm.limitEvents = MAX_DISPLAYED_ACTIVITIES;

      updateTagDefinitions();
      updateAiTags();
      pubSubService.on('incidentTagsDefinitions.updated', updateTagDefinitions, $scope);
      pubSubService.on('incident.newData', updateAiTags, $scope);
    }

    function updateAiTags() {
      LabelsStore.getIncidentTags(vm.incident._id).then((res) => {
        vm.aiTags = getAITags(res.labels);
      });
    }

    function updateTagDefinitions() {
      const tagDefinitions = (LabelsStore.getDefinitions() || []).filter(
        (tag) =>
          tag.active && tag.type !== 'Priority' && !FILTERED_TAG_IDS_FROM_GRID.includes(tag.id)
      );
      vm.tagDefinitions = sortBy(tagDefinitions, (tag) => tag.name.toLowerCase(), 'desc');
      vm.showIncidentTagsGrid = tagDefinitions.length > 0;
    }

    function getAITags(tags) {
      const aiTags = tags.filter((tag) => AI_TAGS.includes(tag.id));
      return aiTags.map((tag) => ({ tag_id: tag.id, tag_value: tag.value }));
    }

    function alertsCount(entities) {
      return {
        active: EntitiesService.filterActiveEntities(entities).length,
        resolved: EntitiesService.filterResolvedEntities(entities).length,
        maintenance: EntitiesService.filterMaintenanceEntities(entities).length,
      };
    }

    function updateAlertsTitle() {
      let alertsTitle;
      if (!vm.selectedEntities) {
        alertsTitle = `${vm.currentEntitites} ${vm.currentFolderDisplay} ${pluralize(
          vm.currentEntitites,
          'Alert'
        )}`;
      } else {
        alertsTitle = `${vm.selectedEntities} ${pluralize(vm.selectedEntities, 'Alert')} selected`;
      }
      vm.alertsTitle = alertsTitle;
    }

    function pluralize(count, noun) {
      const suffix = count !== 1 ? 's' : '';
      const resString = noun + suffix;
      return resString;
    }

    vm.updateSelectEntitiesCount = function (selectedEntitiesCount) {
      vm.selectedEntities = selectedEntitiesCount;
      updateAlertsTitle();
      const showBubble = vm.selectedEntities > 0 && vm.currentEntitites < vm.totalEntities;
      if (showBubble) {
        $timeout(() => {
          vm.showAllBubble = showBubble;
          $document[0].body.addEventListener('click', () => {
            vm.showAllBubble = false;
            $timeout.cancel(bubbleTimeout);
            $scope.$apply();
          });
          bubbleTimeout = $timeout(() => {
            vm.showAllBubble = false;
          }, 15000);
        }, 1000);
      } else {
        vm.showAllBubble = false;
        $timeout.cancel(bubbleTimeout);
      }
    };

    vm.showAllEntities = function () {
      vm.showAllBubble = false;
      $timeout.cancel(bubbleTimeout);
      vm.showAllEntitiesInTable = true;
      updateTableHeight(vm.entities, true);
    };
  }
}
