import find from 'lodash/find';
import map from 'lodash/map';
angular.module('bigpanda').controller('CommentPopupController', CommentPopupController);

function CommentPopupController(
  $scope,
  $q,
  CommentsStore,
  ContactsStore,
  stateService,
  Permissions,
  deepCloneObject
) {
  const vm = this;
  vm.loading = true;
  vm.allowAddingComments = true;

  angular.extend($scope, {
    dialogTitle: 'Comments',
    titleIconClass: 'bp-icon-comment',
    noButtons: true,
    allowAddingComments: vm.allowAddingComments,
  });

  init();

  function init() {
    vm.allowAddingComments = Permissions.checkGranularPermission(
      'action@update',
      'environments',
      stateService.getSelectedEnvironmentId()
    );
    loadComments().then(() => {
      CommentsStore.subscribe($scope, {
        storeUpdated: loadComments,
      });
    });
  }

  function loadComments() {
    return $q
      .all({
        comments: CommentsStore.getComments(vm.incidentId),
        contacts: ContactsStore.getContacts(),
      })
      .then((result) => {
        vm.vmComments = map(result.comments, (comment) =>
          normalizeComment(comment, result.contacts)
        );
        vm.loading = false;
        angular.extend($scope, { dialogTitleCount: vm.vmComments.length });
      });
  }

  function normalizeComment(comment, contacts) {
    return {
      date: moment(comment._source.date).format('MMM D'),
      time: moment(comment._source.date).format('h:mm a'),
      comment: deepCloneObject.cloneDeep(comment._source.params.comment),
      contact: find(contacts, { bigpanda_user_id: comment._source.params.performer }),
    };
  }
}
