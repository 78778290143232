angular.module('bigpanda').directive('multipleAssignButton', multipleAssignButton);

function multipleAssignButton() {
  return {
    restrict: 'E',
    require: {
      checkedIncidentsState: '^^checkedIncidentsState',
    },
    scope: {},
    templateUrl: 'overview/incidents/list/widgets/assign_button/multiple_assign_button',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($element, AssignPanelUtils, bpPanelSelectService, AssignmentsStore) {
    const vm = this;
    vm.openSelector = openSelector;

    function openSelector() {
      AssignPanelUtils.getUserOptions().then((userOptions) =>
        bpPanelSelectService.openSelector({
          element: $element,
          options: userOptions,
          filterKey: 'displayName',
          panelSize: 'large',
          resetActionName: 'Unassign',
          onSelect: onSelect,
          multiSelectMode: true,
        })
      );
    }

    function onSelect(selectedUserId) {
      if (selectedUserId) {
        AssignmentsStore.multiAssign(
          vm.checkedIncidentsState.getCheckedIncidentIds(),
          selectedUserId
        );
      } else {
        AssignmentsStore.multiUnassign(vm.checkedIncidentsState.getCheckedIncidentIds());
      }
      vm.checkedIncidentsState.clear();
    }
  }
}
