angular.module('bp.widgets').directive(
  'bpEnterPress',
  () =>
    function (scope, element, attrs) {
      function keypressed(event) {
        if (event.which === 13) {
          scope.$apply(() => {
            scope.$eval(attrs.bpEnterPress);
          });

          event.preventDefault();
        }
      }

      element.bind('keydown keypress', keypressed);

      scope.$on('$destroy', () => {
        element.unbind('keydown keypress', keypressed);
      });
    }
);
