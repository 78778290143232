import capitalize from 'lodash/capitalize';

const messageMap = {
  sms: 'Text Message (SMS)',
  email: 'Email',
};

const systemDisplayText = (integration) => {
  if (!integration) return '';

  return (
    messageMap[integration.parent_system_id] ||
    `${capitalize(integration.parent_system_id)} - ${integration.name}`
  );
};

const envName = (name = '') => (name.toLowerCase() === 'all' ? 'All Environments' : name);

export default { systemDisplayText, envName };
