import styled from 'styled-components';
import * as colors from '@bp/pastel/colors';

import { searchBoxHeight } from '../constants';

export const SearchWrapper = styled.div`
  height: ${searchBoxHeight}px;
  padding: 5px;
  border-bottom: 1px solid ${colors.bp_gray_03};
`;

export const SearchBox = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid ${colors.bp_gray_03};
  border-radius: 3px;
  color: ${colors.bp_black};
`;

export const SearchBoxIcon = styled.i`
  height: 16px;
  font-size: 16px;
  margin-right: 6px;
  line-height: 16px;
  color: ${colors.bp_gray_05};
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::placeholder {
    color: ${colors.bp_gray_04};
  }
`;

export const SearchClearIcon = styled.i`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 14px;
  margin-left: 5px;
  line-height: 14px;
  color: ${colors.bp_gray_04};
  cursor: pointer;
  &:hover {
    color: ${colors.bp_gray_06};
  }
`;
