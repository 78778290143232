angular.module('bp.widgets').directive('phoneNumberValidator', phoneNumberValidator);

function phoneNumberValidator() {
  return {
    require: { ngModel: 'ngModel' },
    controller: controller,
    bindToController: true,
  };

  function controller() {
    const vm = this;

    this.$onInit = init;

    function init() {
      vm.ngModel.$validators.phoneNumberValidator = phoneNumberValidator;
    }

    function phoneNumberValidator(modelValue) {
      if (vm.ngModel.$isEmpty(modelValue)) {
        return true;
      }

      const valid = window.intlTelInputUtils.isValidNumber(modelValue);
      if (!valid) {
        vm.ngModel.phoneNumberError = 'phone number is invalid';
      }
      return valid;
    }
  }
}
