import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import provideStore from 'react/utils/provideStore';
import SettingsListScreen from 'react/layout/settings/SettingsListScreen';
import { DropdownOptionType } from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { SortShape } from 'react/layout/settings/components/SettingsList';
import { SORT_MODES } from '../constants';
import selectors from '../../selectors';
import actions from '../../actions';
import { tagPropType } from '../../propTypes';
import AlertTagModal from '../../modals/AlertTagModal';
import { selectors as featureTogglesSelectors } from '../../../../../user/feature_toggles';
import AlertTagOrderingModal from '../../modals/alert_tag_ordering/AlertTagOrderingModal';
import { isAnyFilterApplied } from '../../utils';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION } from '../../constants';
import FiltersV2 from '../../../../../../../workspaces/apps/alert-enrichment/src/components/Lobby/FiltersV2';
import Filters from '../../../../../../../workspaces/apps/alert-enrichment/src/components/Lobby/Filters';
import ListItem from '../../../../../../../workspaces/apps/alert-enrichment/src/components/Lobby/ListItem';
import SortTooltip from '../../../../../../../workspaces/apps/alert-enrichment/src/components/Lobby/SortTooltip';
import {
  MAX_NUM_OF_TAGS,
  MAX_NUM_OF_RULES,
} from '../../../../../../../workspaces/apps/alert-enrichment/src/constants/limits';
import { shouldUseTagOperations } from '../../../../../../../workspaces/apps/alert-enrichment/src/helpers/should-use-tag-operations';
import texts from '../../../../../../../workspaces/apps/alert-enrichment/src/dictionary';
import { getInterpolatedText } from '../../../../../../../workspaces/apps/alert-enrichment/src/helpers/get-interpolated-text';

const TOTAL_NUM_OF_RULES_REACHED_TOOLTIP_TEXT = getInterpolatedText(
  texts.edit_tag_modal__maximum_num_of_rules_warning_message,
  { totalNumOfRules: MAX_NUM_OF_RULES.toLocaleString() }
);

const TOTAL_NUM_OF_TAGS_REACHED_TOOLTIP_TEXT = getInterpolatedText(
  texts.create_tag_modal__maximum_num_of_tags_warning_message,
  { totalNumOfTags: MAX_NUM_OF_TAGS.toLocaleString() }
);

class AlertEnrichmentList extends React.PureComponent {
  componentDidMount() {
    if (!this.props.tags) {
      this.props.loadEnrichmentTags();
    }
  }

  componentWillUnmount() {
    this.resetAllFilters();
  }

  resetAllFilters = () => {
    this.props.updateFiltersDropDowns({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
    this.props.updateFilterText('');
  };

  updateFilter = (value, type, item) => {
    this.props.updateFiltersDropDowns({ [type]: item });
  };

  orderTagsButton = () => {
    const {
      actionsPermissions: { hasEditPermission },
      isLoading,
      tags = [],
    } = this.props;
    if (!hasEditPermission) return;
    const triggerProps = {
      variant: 'action-container',
      size: 'medium',
      disabled: isLoading || !tags.length,
      tooltipProps: {
        isActive: true,
        text: 'Edit Alert tags execution order',
        placement: 'bottom',
      },
      displayButtonText: false,
    };
    return (
      <AlertTagOrderingModal
        triggerProps={triggerProps}
        title="Alert tags execution order"
        titleIcon="bp-icon-edit"
      />
    );
  };

  validateTagLimits = () => {
    const { totalNumOfTags, totalNumOfRules, featureToggles } = this.props;
    const hasPassedTagsLimit = totalNumOfTags + 1 > MAX_NUM_OF_TAGS;
    const hasPassedMaxNumOfTotalRules = totalNumOfRules > MAX_NUM_OF_RULES;

    if (!shouldUseTagOperations(featureToggles)) {
      return '';
    }

    if (hasPassedTagsLimit) {
      return TOTAL_NUM_OF_TAGS_REACHED_TOOLTIP_TEXT;
    }

    if (hasPassedMaxNumOfTotalRules) {
      return TOTAL_NUM_OF_RULES_REACHED_TOOLTIP_TEXT;
    }

    return '';
  };

  render() {
    const {
      isLoading,
      filterText,
      updateFilterText,
      filtersDropDownItems,
      filters,
      updateFiltersDropDowns,
      sortMode,
      tags,
      updateSort,
      featureToggles,
    } = this.props;

    const createNewTagValidationText = this.validateTagLimits();
    const isReachedToCreationLimit = Boolean(createNewTagValidationText);

    return (
      <SettingsListScreen
        listCustomActions={this.orderTagsButton()}
        createItemProps={{
          modal: {
            title: 'New Alert Tag',
            titleIcon: 'bp-icon-plus-icon',
            component: AlertTagModal,
          },
          itemName: 'Alert tag',
          buttonText: 'New Tag',
          tileText: 'Create a new Alert Tag',
          disabled: isReachedToCreationLimit,
          tooltipProps: isReachedToCreationLimit && {
            text: createNewTagValidationText,
            isActive: true,
          },
        }}
        filterPlaceholderText="Find Tags..."
        filterText={filterText}
        headerName="Alert Enrichment"
        items={tags}
        listItem={ListItem}
        listItemProp="tag"
        loading={isLoading}
        noItemsTitle="No tags added yet"
        permissionName="enrichments"
        sortModes={SORT_MODES}
        sortMode={sortMode}
        sortTooltip={<SortTooltip />}
        updateSort={updateSort}
        updateFilterText={updateFilterText}
        isAnyFilterApplied={isAnyFilterApplied(filters)}
        renderFilterRowElement={() =>
          filtersDropDownItems &&
          filters &&
          (featureToggles.filters_v2 ? (
            <FiltersV2
              reset={updateFiltersDropDowns}
              filters={filters}
              onChange={this.updateFilter}
              data={filtersDropDownItems}
            />
          ) : (
            <Filters
              filters={filters}
              onChange={updateFiltersDropDowns}
              data={filtersDropDownItems}
            />
          ))
        }
      />
    );
  }
}

AlertEnrichmentList.propTypes = {
  filtersDropDownItems: PropTypes.shape({
    tagName: PropTypes.arrayOf(DropdownOptionType),
    type: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }),
  filters: PropTypes.shape({
    tagName: DropdownOptionType,
    type: DropdownOptionType,
    status: DropdownOptionType,
  }),
  featureToggles: PropTypes.shape({
    filters_v2: PropTypes.bool,
  }).isRequired,
  filterText: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  loadEnrichmentTags: PropTypes.func.isRequired,
  sortMode: SortShape.isRequired,
  tags: PropTypes.arrayOf(tagPropType),
  updateFilterText: PropTypes.func.isRequired,
  updateFiltersDropDowns: PropTypes.func.isRequired,
  updateSort: PropTypes.func.isRequired,
  actionsPermissions: PropTypes.shape({
    hasEditPermission: PropTypes.bool,
  }).isRequired,
  totalNumOfTags: PropTypes.number,
  totalNumOfRules: PropTypes.number,
};

AlertEnrichmentList.defaultProps = {
  filtersDropDownItems: undefined,
  filters: undefined,
  filterText: '',
  tags: undefined,
};

function mapStateToProps(state) {
  return {
    filtersDropDownItems: selectors.getFiltersDropDownItems(state),
    filters: selectors.getFilters(state),
    filterText: selectors.getFilterText(state),
    isLoading: selectors.isLoading(state),
    sortMode: selectors.getSortMode(state),
    tags: selectors.getTags(state),
    actionsPermissions: selectors.getActionsPermissions(state),
    featureToggles: featureTogglesSelectors.getFeatureToggles(state) || {},
    totalNumOfTags: selectors.getTotalTags(state),
    totalNumOfRules: selectors.getTotalRules(state),
  };
}

const mapDispatchToProps = {
  updateFilterText: actions.updateFilterText,
  updateFiltersDropDowns: actions.updateFiltersDropDowns,
  updateSort: actions.updateSort,
  loadEnrichmentTags: actions.loadEnrichmentTags,
};

export default provideStore(connect(mapStateToProps, mapDispatchToProps)(AlertEnrichmentList));
