import {
  AllSizes, IVoidFn, ModalLayout, Text,
} from '@bp/kung-fu';
import React, { memo } from 'react';

import dictionary from './dictionary';
import MatchedPatternTile from './matched-pattern-tile';
import { ElementWrapper, Wrapper } from './matched-patterns.style';
import { ICorrelationPattern } from './types';

export default memo(({ correlationPatterns, closeModal }:
{ correlationPatterns: ICorrelationPattern[], closeModal?: IVoidFn }) => (

  <ModalLayout closeModal={closeModal}>
    <Wrapper>
      <ElementWrapper>
        <Text size={AllSizes.MEDIUM} color={(p) => p.theme.bp_gray_08}>
          {dictionary.matched_patterns_modal_title}
        </Text>
      </ElementWrapper>
      {correlationPatterns.map((pattern, index) => (
        <MatchedPatternTile
          key={pattern.id}
          correlationPattern={pattern}
          index={index}
        />
      ))}
    </Wrapper>
  </ModalLayout>
));
