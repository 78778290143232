import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import shortid from 'shortid';
import { BamTag, BamTile, BamEmptyMessage } from '@bp/bam';
import { Tooltip, darkTheme, EditIcon } from '@bp/kung-fu';
import permissions from 'common/routing/permissions';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import provideStore from 'react/utils/provideStore';
import selectors from '../selectors';
import RoleModal from '../../roles_list/components/modal/RoleModal';
import styles from './role_info.scss';
import RolesManagementActions from '../../actions/RolesManagementActions';
import { BP_SYSTEM_USERNAME } from '../../../../../../common/constants';

const RoleInfo = ({ role, userPermissions }) => {
  if (!role) {
    return (
      <BamEmptyMessage header="No role selected">Select a role to see details.</BamEmptyMessage>
    );
  }

  const usersTagsList = (users) => {
    if (users && users.length) {
      return users.map(({ username, email }) => {
        const emailTag = email || <i>Unavailable</i>;
        return (
          <div className={styles['bam-tag-wrapper']}>
            <Tooltip text={emailTag} key={shortid.generate()} placement="bottom">
              <BamTag text={username} />
            </Tooltip>
          </div>
        );
      });
    }
    return <div className={styles['bam-none-tag']}> None </div>;
  };

  const collectionTagsList = (collection) => {
    if (collection && collection.length) {
      return collection.map((resource) => (
        <div className={styles['bam-tag-wrapper']} key={shortid.generate()}>
          <BamTag text={resource} />
        </div>
      ));
    }
    return <div className={styles['bam-none-tag']}> None </div>;
  };

  const canEdit =
    ['user', 'admin'].indexOf(role ? role.name : '') === -1 &&
    userPermissions.indexOf(permissions.roles.update) !== -1;

  const content = (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.SectionRow title="Permissions:">
        {collectionTagsList(role.displayedPermissions)}
      </SettingsInfoScreen.SectionRow>
      <SettingsInfoScreen.SectionRow title="Environment Access:">
        {collectionTagsList(role.displayedEnvironments)}
      </SettingsInfoScreen.SectionRow>
      <SettingsInfoScreen.SectionRow title="Users:">
        {usersTagsList(role.usernames)}
      </SettingsInfoScreen.SectionRow>
    </SettingsInfoScreen.Section>
  );

  const actions = (
    <SettingsInfoScreen.Section>
      {canEdit && (
        <RoleModal
          currentRoleId={role._id}
          triggerProps={{
            variant: 'action-color',
            size: 'large',
            text: 'Edit Role',
            icon: <EditIcon />,
          }}
          title="Edit Role"
          titleIcon="bp-icon-edit"
        />
      )}
    </SettingsInfoScreen.Section>
  );

  const createdByActivityValue = role.createdByUsername
    ? role.displayedCreationTimestamp
    : BP_SYSTEM_USERNAME;
  const activity = (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.ActivityRow
        title="Created By:"
        name={role.createdByUsername}
        value={createdByActivityValue}
      />
      <SettingsInfoScreen.ActivityRow title="Updated:" value={role.displayedLastChanged} />
    </SettingsInfoScreen.Section>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <SettingsInfoScreen
        topActions={<RolesManagementActions role={role} />}
        header={<BamTile headerRow={{ title: role.name }} />}
        content={content}
        actions={actions}
        activity={activity}
      />
    </ThemeProvider>
  );
};

RoleInfo.propTypes = {
  role: PropTypes.object /* eslint-disable-line */,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    roleId: ownProps.roleId,
    role: selectors.getRoleById(state, ownProps.roleId),
    userPermissions: permissionsSelectors.getPermissionsList(state),
  };
}

export default provideStore(connect(mapStateToProps, null)(RoleInfo));
