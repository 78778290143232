import {
  Button, ButtonVariants,
  darkTheme, Dot, DuplicateIcon, HBox, Tooltip,
} from '@bp/kung-fu';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormState } from 'final-form';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import onlyDefined from '../../../../../../../app/common/utils/onlyDefined';
import { DeleteButtonWithTooltip } from '../../../../../../../app/react/common/components';
import integrationsSelectors from '../../../../../../../app/react/common/integrations/selectors';
import { canPreviewByType } from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/canPreviewByType';
import isMapping from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/isMapping';
import selectors from '../../../../../../../app/react/modules/settings/alert_enrichment/selectors';
import { selectors as featureTogglesSelectors } from '../../../../../../../app/react/user/feature_toggles';
import texts from '../../../dictionary';
import { statusToColor } from '../../../helpers/utils';
import { PreviewButton } from '../../PreviewButton';
import { RuleActivationToggle } from '../../RuleActivationToggle';
import styles from './RuleSummary.scss';
import { RuleSummaryData } from './RuleSummaryData';
import { RuleSummaryProps, SelectorsResult } from './types';

const DONT_TRIGGER_TOOLTIP = ['active'];

const getDeleteRuleTooltipText = ({ isLastRule, isRuleTypeMapping }): string => {
  if (isRuleTypeMapping) {
    return '';
  }

  if (isLastRule) {
    return 'The last item cannot be deleted';
  }

  return 'Delete Item';
};

export function RuleSummary({
  onLoadPreview,
  values: propValues,
  onValueChanged,
  setHasChanged,
  hover,
  open,
  rule,
  canPreview,
  isLastRule,
  changed,
  ...rest
}: RuleSummaryProps): JSX.Element {
  const integrations: SelectorsResult['integrations'] = useSelector(
    integrationsSelectors.getAllIntegrationOptions,
  );
  const previewRule: SelectorsResult['previewRule'] = useSelector(selectors.getPreviewRule);
  const previewLoading: SelectorsResult['previewLoading'] = useSelector(
    selectors.getPreviewLoading,
  );
  const featureToggles: SelectorsResult['featureToggles'] = useSelector(
    featureTogglesSelectors.getFeatureToggles,
  );

  const onPreviewClick = (): void => {
    onLoadPreview(rule.id);
  };

  const isPreviewed = get(previewRule, 'id') === rule.id;

  const onChange = ({ values: { rules } }: FormState): void => {
    if (isEqual(onlyDefined(propValues), onlyDefined(rules[rule.id]))) {
      return;
    }

    onValueChanged(rule.id);

    if (
      isEqual(
        onlyDefined(propValues, DONT_TRIGGER_TOOLTIP),
        onlyDefined(rules[rule.id], DONT_TRIGGER_TOOLTIP),
      )
    ) {
      return;
    }

    if (!isPreviewed) {
      return;
    }

    setHasChanged(true);
  };

  const actionsClass = cn([styles['summary-actions']], {
    [styles['summary-actions-hidden']]: !(hover || open),
  });
  const isTagTypeDetermined = !!propValues.type;
  const previewButtonEnabled = isTagTypeDetermined && canPreview && canPreviewByType(propValues.type || rule.type, propValues);
  const previewButton = (
    <>
      <span
        className={cn(styles['summary-actions-divider'], {
          [styles['summary-actions-active-preview-divider']]: isPreviewed,
        })}
      />
      <PreviewButton
        canPreview={previewButtonEnabled}
        isPreviewed={isPreviewed}
        isLoading={previewLoading}
        onClick={onPreviewClick}
        showRefreshNotice={isPreviewed && changed}
      />
    </>
  );

  const isRuleTypeMapping = isMapping(rule.type);

  return (
    <ThemeProvider theme={darkTheme}>
      <span className={styles.summary}>
        <RuleSummaryData
          rule={rule}
          open={open}
          hover={hover}
          values={propValues}
          featureToggles={featureToggles}
          isPreviewed={isPreviewed}
          integrations={integrations}
          {...rest}
        />
        <span className={actionsClass}>
          <span className={styles['summary-actions-toggle']}>
            <RuleActivationToggle rule={rule} isTagTypeDetermined={isTagTypeDetermined} />
          </span>
          <FormSpy>
            {({ form: { mutators } }): JSX.Element => (
              <Tooltip
                placement="bottom"
                isActive={isTagTypeDetermined && !isLastRule && isRuleTypeMapping}
                text={texts.rule_summary__disabled_mapping_rule_tooltip_text}
              >
                <HBox>
                  <Button
                    ariaLabel="duplicate"
                    variant={ButtonVariants.ACTION_CONTAINER}
                    size="medium"
                    icon={<DuplicateIcon />}
                    onClick={(): void => mutators.duplicateRule(propValues)}
                    disabled={!isTagTypeDetermined || isRuleTypeMapping}
                    tooltipProps={
                      !isRuleTypeMapping && {
                        text: 'Duplicate Item',
                        isActive: true,
                      }
                    }
                  />
                  <DeleteButtonWithTooltip
                    onDelete={(): void => mutators.removeRule(rule.id)}
                    disabled={!isTagTypeDetermined || isLastRule || isRuleTypeMapping}
                    className={styles['summary-actions-delete-button']}
                    customTooltipText={getDeleteRuleTooltipText({
                      isLastRule,
                      isRuleTypeMapping,
                    })}
                  />
                </HBox>
              </Tooltip>
            )}
          </FormSpy>
          {previewButton}
        </span>
        {!(hover || open) && (
          <span className={styles['summary-actions']}>
            <HBox className={styles['summary-actions-status']}>
              <Dot size="medium" color={statusToColor(propValues.active)} />
            </HBox>
            {isPreviewed && previewButton}
          </span>
        )}
        <FormSpy onChange={onChange} />
      </span>
    </ThemeProvider>
  );
}

export default RuleSummary;
