angular.module('bigpanda').controller('CorrelationRuleInfoCtrl', CorrelationRuleInfoCtrl);

function CorrelationRuleInfoCtrl(
  $scope,
  resolvedCorrelation,
  CorrelationRuleActions,
  CorrelationActions,
  PERMISSIONS_NAMES,
  CorrelationConfigService,
  CorrelationRulesService
) {
  const vm = this;

  if (!resolvedCorrelation) {
    return CorrelationActions.escapeRoute(
      'Could not find Correlation Pattern info',
      'app.settings.correlations'
    );
  }

  vm.updatePermissions = [PERMISSIONS_NAMES.correlations.update];
  vm.toggleCorrelationRule = CorrelationRuleActions.toggleCorrelationRule;
  vm.buttons = CorrelationRuleActions.buttons;
  vm.getSourceSystems = CorrelationRuleActions.getSourceSystems;
  vm.correlation = resolvedCorrelation;

  init();

  function init() {
    CorrelationConfigService.subscribe($scope, {
      storeUpdated: () => {
        CorrelationRulesService.getCorrelationRuleById(resolvedCorrelation.id).then(
          (correlation) => {
            if (correlation) {
              vm.correlation = correlation;
            }
          }
        );
      },
    });

    vm.query = getQueryValue(vm.correlation.pureQuery);
    $scope.$watch(
      () => vm.correlation,
      (correlation) => {
        if (correlation && correlation.pureQuery) {
          vm.query = getQueryValue(correlation.pureQuery);
        }
      }
    );

    if (vm.correlation.metadata) {
      setMetadata(vm.correlation.metadata);
      $scope.$watch(
        () => vm.correlation.metadata.updated_time,
        () => {
          setMetadata(vm.correlation.metadata);
        }
      );
    }
  }

  function setMetadata(metadata) {
    const createdUserId = metadata.created_user;
    const updatedUserId = metadata.updated_user;
    CorrelationActions.getInvolvedUserNames(createdUserId).then((name) => {
      vm.createdUser = name;
    });
    CorrelationActions.getInvolvedUserNames(updatedUserId).then((name) => {
      vm.updatedUser = name;
    });
  }

  function getQueryValue(pureQuery) {
    return CorrelationActions.getQueryDisplayVal(pureQuery);
  }
}
