angular
  .module('bigpanda.reports')
  .directive('bpEventsHistogramBars', ($window, $q, pubSubService, D3HorizontalBarchartFactory) => {
    return {
      restrict: 'E',
      templateUrl:
        'reports/visualization_widget/visualizations/events_histogram_bars/events_histogram_bars',
      require: '^bpVisualizationWidgetContainer',
      scope: {},
      link: link,
    };

    function link($scope, $element, $attrs, containerController) {
      var unsubscribe = pubSubService.on(
        'reportWidgetsController.refreshReportData',
        onRefreshReportData.bind(undefined, $scope, $element, containerController)
      );

      $scope.$on('$destroy', unsubscribe);

      $scope.chart = D3HorizontalBarchartFactory()
        .x((d) => d.key)
        .y((d) => d.doc_count)
        .valueColumnName('Alerts (#)');

      function resize() {
        d3.select($element[0]).call($scope.chart.resize, containerController.getLayoutName());
      }

      $q.when(containerController.widgetDefinition).then((widget) => {
        angular.element($window).on(`resize.${widget._id}`, () => {
          if (!containerController.dataLoading && !containerController.error) {
            resize();
          }
        });

        if ($window.matchMedia) {
          $window.matchMedia('print').addListener((media) => {
            resize();
          });
        }
        if ($window.onbeforeprint) {
          (function (oldOnbeforeprint) {
            $window.onbeforeprint = function () {
              resize();
              oldOnbeforeprint();
            };
          })($window.onbeforeprint);
        } else {
          (function (oldPrint) {
            $window.print = function () {
              resize();
              oldPrint();
            };
          })($window.print);
        }
      });

      // Initial render
      onRefreshReportData($scope, $element, containerController);
    }

    function onRefreshReportData($scope, $element, containerController) {
      containerController.getDisplayConfiguration().then((displayConfig) =>
        containerController.getReportData().then((data) => {
          $scope.chart.tagColumnName(displayConfig.tagColumnHeader);

          d3.select($element[0])
            .datum(data)
            .call($scope.chart.render, containerController.getLayoutName());
        })
      );
    }
  });
