import { filterItemsByDropDownFilters } from '../../../../../../app/react/modules/settings/redux.utils';
import { AlertFilter } from '../../types/AlertFilter';
import { ANY_OPTION_VALUE, DropdownRecord } from '../../types/DropdownOption';

export const filterByDropdowns = (
  filters: DropdownRecord,
) => (alertFilters: AlertFilter[]): AlertFilter[] => filterItemsByDropDownFilters(
  filters,
  {
    // status: (alertFilter: AlertFilter): string[] => [`${!alertFilter.done && alertFilter.active}`],
    status: (alertFilter: AlertFilter): string[] => [`${alertFilter.active}`],
    source: (alertFilter: AlertFilter): string[] => alertFilter.sourceSystems.map(({ value }) => value),
  },
  alertFilters,
  ANY_OPTION_VALUE,
);
