import get from 'lodash/get';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bp_blue } from '@bp/pastel/colors';
import { Spinner } from '@bp/kung-fu';
import { useEventListener } from 'react/utils/custom_hooks';
import ErrorMsg from 'react/common/components/ErrorMsg/ErrorMsg';
import {
  INTEGRATION_CREATED_MESSAGE,
  INTEGRATION_SELECT_MESSAGE,
  INTEGRATION_SUCCESSFULLY_LOADED_MESSAGE,
} from './helpers';
import { Wrapper, Iframe, SpinnerContainer } from './IntegrationsIframe.css';

const IntegrationsIframe = ({ handleValidMsg, integrationUrl, iframeOriginUrl, isModalMode }) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [successfullyLoaded, setSuccessfullyLoaded] = useState(false);

  const handleMsg = (e) => {
    if (e.origin === iframeOriginUrl) {
      const action = get(e, 'data.action', null);

      switch (action) {
        case INTEGRATION_SUCCESSFULLY_LOADED_MESSAGE: {
          setSuccessfullyLoaded(true);
          break;
        }
        case INTEGRATION_CREATED_MESSAGE: {
          const integrationName = e.data.source_system;
          handleValidMsg(integrationName);
          break;
        }
        case INTEGRATION_SELECT_MESSAGE: {
          const selectedIntegration = e.data.selectedIntegration;
          handleValidMsg({ source_system: selectedIntegration });
          break;
        }
        default:
          break;
      }
    }
  };

  const onIframeLoaded = () => {
    setIframeLoaded(true);
  };

  useEventListener('message', handleMsg);

  const isContentAvailable = iframeLoaded && successfullyLoaded;

  return (
    <Wrapper isModalMode={isModalMode}>
      {!isContentAvailable && (
        <SpinnerContainer>
          {!iframeLoaded ? <Spinner size="large" color={bp_blue} expand={false} /> : <ErrorMsg />}
        </SpinnerContainer>
      )}
      {integrationUrl && (
        <Iframe
          allow="clipboard-read; clipboard-write"
          src={integrationUrl}
          onLoad={onIframeLoaded}
          isVisible={isContentAvailable}
        />
      )}
    </Wrapper>
  );
};

IntegrationsIframe.propTypes = {
  handleValidMsg: PropTypes.func,
  integrationUrl: PropTypes.string,
  iframeOriginUrl: PropTypes.string,
  isModalMode: PropTypes.bool,
};

IntegrationsIframe.defaultProps = {
  handleValidMsg: undefined,
  integrationUrl: undefined,
  iframeOriginUrl: undefined,
  isModalMode: false,
};

export default IntegrationsIframe;
