export enum TagType {
  Extraction = 'extraction',
  Composition = 'composition',
  Mapping = 'mapping',
  MultiType = 'multi type',
}

export enum RuleType {
  Extraction = 'extraction',
  Composition = 'composition',
  Mapping = 'mapping',
}

export interface EnrichmentRule {
  id: string;
  active: boolean;
  version?: number;
  created_by?: string;
  updated_by?: string;
  note?: string;
  displayQuery?: string;
  sourceObj: object;
  pureQuery: object;
  config?: {
    name?: string;
    value?: string;
    source?: string;
    destination?: string;
    regex?: string;
    template?: string;
    fields?: {
      title: string;
      type: string;
      tag_name: string;
      override_existing: boolean;
      optional?: boolean;
    }[];
    id_generation_function?: string;
    case_insensitive?: boolean;
  };
  type: RuleType;
  when: object;
  sourceSystems: {
    display: string;
    value: string;
  }[];
  condition?: string;
  __isNew?: boolean;
}

export type ExtendedRule = EnrichmentRule & { __isNew: boolean; __isDuplicate: boolean };

export interface Tag {
  name: string;
  description?: string;
  created_by: string;
  created_at: number;
  updated_by?: string;
  updated_at?: number;
  order?: number;
  type: 'Mapping' | 'Extraction' | 'Multi Type' | 'Composition';
  enrichments: EnrichmentRule[];
  active?: boolean;
}
