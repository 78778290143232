import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import { BamModal } from '@bp/bam';
import { selectors as integrationsSelectors } from 'react/common/integrations';
import { selectors as correlationConfigSelectors } from 'react/common/correlation_config';
import { loadAlertTags as loadAlertTagsAction } from 'react/common/alert_tags/actions';
import actions from '../actions';
import selectors from '../selectors';
import CustomTagForm from './CustomTagForm';

class CustomTagModal extends React.Component {
  onMountModal = () => {
    const { unifiedTags, loadAlertTags } = this.props;
    if (!unifiedTags) {
      loadAlertTags();
    }
  };

  handleClose = () => {
    this.ref.close();
  };

  render() {
    const { titleIcon, title, triggerProps } = this.props;
    return (
      <BamModal
        ref={(r) => {
          this.ref = r;
        }}
        trigger={<ModalTriggerButton {...triggerProps} />}
        titleIcon={titleIcon}
        title={title}
        docsLink="https://docs.bigpanda.io/docs/enrichment#section-defining-custom-tags"
        onMount={this.onMountModal}
        formMode
        fullscreen
        closeOnEscape={false}
      >
        <CustomTagForm {...this.props} close={this.handleClose} />
      </BamModal>
    );
  }
}

CustomTagModal.propTypes = {
  titleIcon: PropTypes.string.isRequired,
  updateCustomTag: PropTypes.func.isRequired,
  addCustomTag: PropTypes.func.isRequired,
  currentCustomTagId: PropTypes.string,
  customTag: PropTypes.shape({}),
  integrationOptions: PropTypes.arrayOf(PropTypes.shape({})),
  loadAlertTags: PropTypes.func.isRequired,
  unifiedTags: PropTypes.arrayOf(PropTypes.shape({})),
  duplicate: PropTypes.bool,
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string,
};

CustomTagModal.defaultProps = {
  currentCustomTagId: undefined,
  customTag: undefined,
  integrationOptions: [],
  duplicate: false,
  title: '',
  unifiedTags: undefined,
};

function mapStateToProps(state, ownProps) {
  return {
    customTag: selectors.getCustomTag(state, ownProps.currentCustomTagId),
    integrationOptions: integrationsSelectors.getIntegrationOptions(state),
    unifiedTags: correlationConfigSelectors.getUnifiedTags(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCustomTag: (customTag) => dispatch(actions.updateCustomTag(customTag)),
    addCustomTag: (customTag) => dispatch(actions.addCustomTag(customTag)),
    loadAlertTags: () => dispatch(loadAlertTagsAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(CustomTagModal));
