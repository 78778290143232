import { hot } from 'react-hot-loader';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import provideStore from 'react/utils/provideStore';
import { loadIntegrations } from 'react/common/integrations/actions';
import selectors from 'react/common/integrations/selectors';
import SharingQuotasTable from './table/SharingQuotasTable';
import SettingsFullScreen from '../../../../layout/settings/SettingsFullScreen';
import integrationPropType from './props/sharing_quotas_prop_types';

const pageTitle = 'Sharing Quotas';
const pageDescription = `BigPanda employs rate limiting to protect 3rd party vendors from overload.
    Below you can see your daily quota for each Sharing endpoint. The usage counters reset every 24 hours.
    Note: once you reach 90% of your quota, your account admins will be notified via email.`;

class SharingQuotas extends React.Component {
  componentDidMount() {
    window.location.href = '/v2/settings/quota';
    this.props.initializeSharingQuotasPage();
  }

  requestQuotaChange = (integrationData) => {
    this.props.requestQuotaChange(integrationData);
  };

  render() {
    const { integrationsData = [] } = this.props;
    const integrationsWithQuota = integrationsData;
    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsFullScreen title={pageTitle} description={pageDescription}>
          {!!integrationsWithQuota.length && (
            <SharingQuotasTable
              integrationsData={integrationsWithQuota}
              requestQuotaChange={this.requestQuotaChange}
            />
          )}
        </SettingsFullScreen>
      </ThemeProvider>
    );
  }
}

SharingQuotas.propTypes = {
  integrationsData: PropTypes.arrayOf(integrationPropType).isRequired,
  initializeSharingQuotasPage: PropTypes.func.isRequired,
  requestQuotaChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  normalizeIntegration: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    integrationsData: selectors
      .getIntegrations(state)
      .map(ownProps.normalizeIntegration)
      .filter((integration) => integration !== undefined),
  };
}

const mapDispatchToProps = {
  initializeSharingQuotasPage: loadIntegrations,
};

export default provideStore(
  connect(mapStateToProps, mapDispatchToProps)(hot(module)(SharingQuotas))
);
