import { datadogRum } from '@datadog/browser-rum';

angular.module('bigpanda').config(bpConfig);

function bpConfig($httpProvider, $qProvider) {
  $qProvider.errorOnUnhandledRejections(false);
  $httpProvider.interceptors.push(['$q', 'pubSubService', '$injector', 'Config', httpInterceptors]);

  function httpInterceptors($q, pubSubService, $injector, Config) {
    function hasAccessToken(config, credentials) {
      return !!(
        (config.params && config.params.access_token) ||
        (config.headers && config.headers.Authorization) ||
        (credentials && credentials.token)
      );
    }

    return {
      request: (config) => {
        const AuthService = $injector.get('AuthService');

        if (config.url.indexOf('/web/') !== 0 && config.url.indexOf('/resources/v') !== 0) {
          return config;
        }

        config.url = `${Config.webApiBaseUrl}${config.url}`;
        config.withCredentials = true;
        return hasAccessToken(config, AuthService.credentials)
          ? config
          : $q.reject('No AuthToken, please relogin');
      },
      response: (response) => {
        const headers = response.headers;

        if (headers('X-Session-Token-Renewed')) {
          const AuthService = $injector.get('AuthService');
          const SessionTimeoutWarningService = $injector.get('SessionTimeoutWarningService');

          AuthService.loadCredentials();
          SessionTimeoutWarningService.start();
        }

        return response;
      },
      responseError: (rejection) => {
        if (rejection.status === 401) {
          // Add custom conditions
          pubSubService.broadcast('Authentication.failed');
        } else if (rejection.status === 429) {
          pubSubService.broadcast('Rate.limited', rejection);
        }

        try {
          datadogRum.addError(rejection, { rejection });
        } catch (e) {
          console.error('Error occurred, while recording another error', { e });
        }

        return $q.reject(rejection);
      },

      requestError: (rejection) => {
        try {
          datadogRum.addError(rejection, { rejection });
        } catch (e) {
          console.error('Error occurred, while recording another error', { e });
        }
        return $q.reject(rejection);
      },
    };
  }
}
