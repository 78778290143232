import {
  AddNote,
  HBox, VBox,
} from '@bp/kung-fu';
import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectors as FTSelectors } from '../../../../../../app/react/user/feature_toggles';
import { Tag } from '../../../types';
import texts from '../../dictionary';
import { getInterpolatedText } from '../../helpers/get-interpolated-text';
import { shouldUseTagOperations } from '../../helpers/should-use-tag-operations';
import { AlertTagFormExecutionOrder } from '../AlertTagFormExecutionOrder';
import { AlertTagFormFunctionType } from '../AlertTagFormFunctionType';
import { UnifiedTag } from '../RuleTypeForms/types';
import {
  AddNoteContainer,
  ContentSeparator,
  StaticContentContainer,
} from './AlertTagFormHeader.style';
import { TagNameFieldComp } from './TagNameField';

export const MAX_DESCRIPTION_LENGTH = 2500;

const DESCRIPTION_ERROR_MESSAGE = getInterpolatedText(texts.add_description__error_message, {
  maxLength: MAX_DESCRIPTION_LENGTH.toLocaleString(),
});

export interface IProps {
  tags: Tag[];
  tag: Tag;
  reservedTags: UnifiedTag[];
  editMode: boolean;
  name?: string;
}

export function AlertTagFormHeader(props: IProps): JSX.Element {
  const { tag, tags } = props;
  const useTagOperations = shouldUseTagOperations(
    useSelector(FTSelectors.getFeatureToggles),
  );

  const validateDescriptionInput = (value): string | undefined => (value || '').length > MAX_DESCRIPTION_LENGTH
    && DESCRIPTION_ERROR_MESSAGE;

  return (
    <VBox role="heading">
      <HBox>
        <TagNameFieldComp {...props} useTagOperations={useTagOperations} />

        <StaticContentContainer>
          <AlertTagFormFunctionType tag={tag} />
          <ContentSeparator color={(p): string => p.theme.bp_gray_04}>•</ContentSeparator>
          <AlertTagFormExecutionOrder tag={tag} numOfTags={tags.length} />
        </StaticContentContainer>
      </HBox>

      {useTagOperations && (
        <AddNoteContainer>
          <Field
            name="description"
            validate={validateDescriptionInput}
            render={({ input, meta }): JSX.Element => (
              <AddNote
                error={meta.error}
                onChange={input.onChange}
                name="description"
                value={input.value}
                label={texts.add_description__label}
                description={texts.add_description__description_text}
                showDescriptionWhenClosed={false}
                ariaLabel={texts.add_description__aria_label}
                isErrorAbsolute={false}
              />
            )}
          />

        </AddNoteContainer>
      )}
    </VBox>
  );
}

export default AlertTagFormHeader;
