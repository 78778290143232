import PropTypes from 'prop-types';

import { DropdownOptionType } from 'react/common/components/FiltersDropdown/FiltersDropdown';

const integrationPropType = PropTypes.shape({
  activated_at: PropTypes.string,
  agent_ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  created_at: PropTypes.string.isRequired,
  dataset_options: PropTypes.shape({}).isRequired,
  last_event: PropTypes.string,
  name: PropTypes.string.isRequired,
  parent_source_system: PropTypes.string.isRequired,
  source_system: PropTypes.string.isRequired,
  stream_id: PropTypes.string.isRequired,
  transport: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updated_at: PropTypes.number.isRequired,
});

const troubleshootingLogPropType = PropTypes.shape({
  log_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  level: PropTypes.string.isRequired,
  log_type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  payload: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
});

const troubleshootingDataPropType = PropTypes.shape({
  data: PropTypes.arrayOf(troubleshootingLogPropType.isRequired),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  descriptions: PropTypes.arrayOf(DropdownOptionType),
  page: PropTypes.number,
  filtersDropDownState: PropTypes.shape({
    description: DropdownOptionType,
    datesRange: DropdownOptionType,
  }),
  datePickerState: PropTypes.bool,
  datePickerFrom: PropTypes.number,
  datePickerTo: PropTypes.number,
  search: PropTypes.string,
  getRequestStatus: PropTypes.number,
  metadataRequestStatus: PropTypes.number,
});

export { integrationPropType, troubleshootingLogPropType, troubleshootingDataPropType };
