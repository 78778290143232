import keys from 'lodash/keys';
const { getProcessingMessage } = require('angularjs/utils/notification/message.transforms.js');

angular.module('bigpanda').service('SharesStore', SharesStore);

function SharesStore(
  $q,
  pubSubService,
  SharesBackendService,
  notificationService,
  BeforeUnloadService,
  IncidentsService,
  SubscribersService,
  deepCloneObject
) {
  const subscribers = new SubscribersService();
  const sharesData = {};

  this.createShares = createShares;
  this.getShares = getShares;
  this.subscribe = subscribe;

  const incidentChanged = (incidentId, options) => {
    delete sharesData[incidentId];
    this.getShares(incidentId, options).then(subscribers.fireStoreUpdated);
  };

  pubSubService.on('incident.newData', (event, incident) => incidentChanged(incident.id));
  pubSubService.on('incidentShare.newData', (event, incidentId) =>
    incidentChanged(incidentId, { disableCache: true })
  );

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function getShares(incidentId, { disableCache = false } = {}) {
    if (sharesData[incidentId]) {
      return $q.when(sharesData[incidentId]);
    }
    return (disableCache
      ? IncidentsService.refetchIncidentById(incidentId)
      : IncidentsService.getIncidentById(incidentId)
    ).then((incident) => {
      sharesData[incidentId] = deepCloneObject.cloneDeep(incident.escalations);
      return sharesData[incidentId];
    });
  }

  function createShares(incidentsIds, title, system, params, context) {
    BeforeUnloadService.start();
    return notificationService
      .notify(getProcessingMessage(title, 'Sharing'), { undo: () => {} })
      .then((res) => {
        if (res.code === 'Undo') {
          return null;
        }
        return SharesBackendService.share(incidentsIds, system.sendVia || system.parentId, params);
      })
      .then((shares) => {
        keys(shares).forEach((incidentId) => {
          pubSubService.broadcast('incident.shared', { id: incidentId });
        });
        return shares;
      })
      .finally(() => {
        BeforeUnloadService.end();
      });
  }
}
