import pick from 'lodash/pick';
import { buildParserFromGrammer, reverse as reversedBpqlParser } from '@bp/bpql';

const bpqlParser = buildParserFromGrammer('correlation');

const COMMON_FIELDS = ['id', 'active', 'tags', 'cross_source'];
const TIME_WINDOW_IN_SECONDS = 60;

const convertOldCorrelationPatternToNew = (oldCorrelationPattern) => {
  const defaultTimeInSeconds = Math.round(Date.now() / 1000);
  const {
    window,
    query = {},
    metadata: {
      created_time: created_at = defaultTimeInSeconds,
      created_user: created_by = '',
      updated_time: updated_at = defaultTimeInSeconds,
      updated_user: updated_by = '',
      description,
      suggested,
    } = {},
    ...rest
  } = oldCorrelationPattern;
  const commonFields = pick(oldCorrelationPattern, COMMON_FIELDS);
  return {
    ...rest,
    ...commonFields,
    time_window: window / TIME_WINDOW_IN_SECONDS,
    filter: reversedBpqlParser(query),
    created_by,
    created_at,
    updated_by,
    updated_at,
    ...(description && { note: description }),
    ...(suggested && { suggested_at: suggested }),
  };
};

const convertNewCorrelationPatternToOld = (newCorrelationPattern) => {
  const {
    time_window,
    filter = '',
    created_by: created_user,
    created_at: created_time,
    updated_by: updated_user,
    updated_at: updated_time,
    note,
    suggested_at,
    ...rest
  } = newCorrelationPattern;
  const commonFields = pick(newCorrelationPattern, COMMON_FIELDS);
  return {
    ...rest,
    ...commonFields,
    window: time_window * TIME_WINDOW_IN_SECONDS,
    query: bpqlParser(filter),
    metadata: {
      created_time,
      created_user,
      updated_time,
      updated_user,
      ...(note && { description: note }),
      ...(suggested_at && { suggested: suggested_at }),
    },
  };
};

export { convertNewCorrelationPatternToOld, convertOldCorrelationPatternToNew };
