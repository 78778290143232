import omit from 'lodash/omit';
import isUndefined from 'lodash/isUndefined';
import trim from 'lodash/trim';
import lowerCase from 'lodash/lowerCase';
import get from 'lodash/get';

export const defaultItemToString = (item) => get(item, 'display', '');

export const filterItems = (items, getItemDisplayValue, query) => {
  const filteredItems = items.reduce((all, item) => {
    if (item.items) {
      const filteredSubItems = item.items.reduce(
        (subItems, subItem) =>
          isMatchingQuery(getItemDisplayValue(subItem), query) ? [...subItems, subItem] : subItems,
        []
      );
      return [...all, { ...item, items: filteredSubItems }];
    }
    return isMatchingQuery(getItemDisplayValue(item), query) ? [...all, item] : all;
  }, []);
  return filteredItems;
};

export const isMatchingQuery = (display = '', query = '') =>
  lowerCase(display).indexOf(lowerCase(trim(query))) > -1;

export const isSelected = (itemValue, selectedValue) =>
  !isUndefined(selectedValue) && itemValue === selectedValue;

export const getFlatItems = (items) =>
  items.reduce(
    (all, item) =>
      item.items
        ? [...all, ...item.items.map((subItem) => ({ ...subItem, group: omit(item, 'items') }))]
        : [...all, item],
    []
  );
