import styled from 'styled-components';
import * as colors from '@bp/pastel/colors';

import { itemsMaxHeight, searchBoxHeight, variants } from '../constants';

export const MenuWrapper = styled.div``;

export const Menu = styled.div`
  min-width: ${({ variant, inline }) => {
    if (inline) return 'max-content';
    return variant === variants.filter ? '150px' : '100%';
  }};
  position: absolute;
  top: ${({ openToTop }) => (openToTop ? 'unset' : '100%')};
  right: ${({ openToLeft, inline }) => (inline || openToLeft ? '0' : 'unset')};
  bottom: ${({ openToTop }) => (openToTop ? '100%' : 'unset')};
  left: ${({ openToLeft, inline }) => {
    if (inline) return 'auto';
    return openToLeft ? 'unset' : '0';
  }};
  overflow-x: hidden;
  margin: 3px 0;
  border-radius: 3px;
  background: ${colors.bp_white};
  border: 1px solid ${colors.bp_gray_04};
  z-index: 3;
  max-width: 300px;
  max-height:300px;
`;

export const Items = styled.div`
  min-height: 40px;
  max-height: ${itemsMaxHeight - searchBoxHeight}px;
  overflow: auto;
  padding: 4px 0;
`;

export const Group = styled.div``;

export const GroupTitle = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 0 5px 10px;
  font-size: 11px;
  font-weight: bold;
  color: ${colors.bp_gray_05};
`;

export const NoResultsLabel = styled.div`
  padding: 8px 12px;
  font-size: 12px;
  color: ${colors.bp_gray_05};
`;
