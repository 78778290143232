import remove from 'lodash/remove';
import assign from 'lodash/assign';
angular.module('bigpanda').service('EnvironmentGroupsStore', EnvironmentGroupsStore);

function EnvironmentGroupsStore(
  EnvironmentGroupsBackendService,
  SubscribersService,
  UserFeatureTogglesService,
  StreamConnector,
  deepCloneObject
) {
  let groupsCache = null;
  let streamInit = false;

  this.getEnvironmentGroups = getEnvironmentGroups;
  this.create = create;
  this.update = update;
  this.remove = remove;
  this.updateEnvironment = updateEnvironment;
  this.subscribe = subscribe;

  const subscribers = new SubscribersService();

  initStream();

  return this;

  function getEnvironmentGroups(forceReload) {
    if (!forceReload && groupsCache !== null) {
      return Promise.resolve(deepCloneObject.cloneDeep(groupsCache));
    }

    return EnvironmentGroupsBackendService.getEnvironmentGroups().then((groups) => {
      groupsCache = groups;

      subscribers.fireStoreUpdated();

      return deepCloneObject.cloneDeep(groupsCache);
    });
  }

  function create(environmentGroup) {
    return EnvironmentGroupsBackendService.create(environmentGroup).then(
      (persistedEnvironmentGroup) => {
        updateCache(persistedEnvironmentGroup);
        subscribers.fireStoreUpdated();
        return deepCloneObject.cloneDeep(persistedEnvironmentGroup);
      }
    );
  }

  function update(environmentGroup) {
    return EnvironmentGroupsBackendService.update(environmentGroup).then(
      (persistedEnvironmentGroup) => {
        updateCache(persistedEnvironmentGroup);
        subscribers.fireStoreUpdated();
        return deepCloneObject.cloneDeep(persistedEnvironmentGroup);
      }
    );
  }

  function remove(environmentGroupId) {
    return EnvironmentGroupsBackendService.remove(environmentGroupId).then(() => {
      removeFromCache(environmentGroupId);
      subscribers.fireStoreUpdated();
    });
  }

  function updateEnvironment(environmentId, fromEnvironmentGroupId, toEnvironmentGroupId) {
    return EnvironmentGroupsBackendService.updateEnvironment(
      environmentId,
      fromEnvironmentGroupId,
      toEnvironmentGroupId
    ).then((result) => {
      const { fromEnvironmentGroup, toEnvironmentGroup } = result;
      updateCache(fromEnvironmentGroup);
      updateCache(toEnvironmentGroup);
      subscribers.fireStoreUpdated();
    });
  }

  function updateCache(environmentGroup) {
    const existingGroup = getGroupFromCache(environmentGroup.id);

    if (!existingGroup) {
      if (!groupsCache) {
        groupsCache = [];
      }
      groupsCache.push(environmentGroup);
    } else {
      assign(existingGroup, environmentGroup);
    }
  }

  function removeFromCache(environmentGroupId) {
    remove(groupsCache, (group) => group.id === environmentGroupId);
  }

  function getGroupFromCache(environmentGroupId) {
    return groupsCache ? groupsCache.find((group) => group.id === environmentGroupId) : null;
  }

  function initStream() {
    if (!streamInit) {
      streamInit = true;
      StreamConnector.on('EnvironmentGroup', (stream) => {
        switch (stream.action) {
          case 'created':
          case 'updated':
            updateCache(stream.environmentGroup);
            break;
          case 'removed':
            removeFromCache(stream.environmentGroup);
            break;
          default:
            break;
        }

        subscribers.fireStoreUpdated();
      });
    }
  }

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }
}
