import {
  Badge, Button, ButtonVariants,
  Chip, HBox, Modal, Text,
} from '@bp/kung-fu';
import React, { useState } from 'react';

import timeWindowString from '../../../../../../../../maintenance-plans/maintenance_plan_info/utils/timeWindowString';
import { text } from '../../../../../../constants';
import { RowText } from '../../RowDetails.style';
import { AuditExpandDetails, RowDetailsSectionProps } from '../../types';
import AlertEnrichmentsTable from './components/AlertEnrichmentsTable/AlertEnrichmentsTable';
import { NO_DATA, StatusToBadge } from './constants';
import {
  DetailsColumn, DetailsRow, DetailsValueColumn, RowDetailsBodyWrapper, ShowMoreWrapper,
} from './RowDetailsBody.style';
import { mapRowDetails } from './utils';

function RowDetailsBody({ rowData }: RowDetailsSectionProps): JSX.Element {
  const [rowDetails] = useState<AuditExpandDetails>(mapRowDetails(rowData));

  const renderStatus = (status): JSX.Element => (
    <Badge text={status} status={StatusToBadge[status]} />
  );

  const renderCorrelationTags = (tags): JSX.Element => (
    <HBox gap="8px">
      {tags
        .map((tag) => (
          <Chip text={tag} />
        ))}
    </HBox>
  );

  const renderExecutionOrder = (orderRow): JSX.Element => (
    <Text weight={orderRow.wasChanged ? 'bold' : 'normal'}>
      {`${orderRow.executionOrder} ${orderRow.wasChanged ? text.changed : ''}`}
    </Text>
  );

  const renderCondition = (rowValue): JSX.Element => {
    const [showMore, setShowMore] = useState(false);
    const shouldTruncate = rowValue.length > 250;
    return !shouldTruncate ? <Text>{rowValue}</Text>
      : (
        <>
          <Text>{showMore ? rowValue : `${rowValue.substring(0, 250)}...`}</Text>
          <ShowMoreWrapper>
            <Button
              variant={ButtonVariants.LINK}
              onClick={(): void => setShowMore(!showMore)}
            >
              {showMore
                ? text.expandRowEnv.condition.showLess
                : text.expandRowEnv.condition.showMore}
            </Button>
          </ShowMoreWrapper>
        </>
      );
  };

  const renderDetailsColumn = (): JSX.Element[] => Object.keys(rowDetails)
    .filter((rowKey) => rowDetails[rowKey] !== NO_DATA && rowDetails[rowKey])
    .map((rowKey) => <RowText key={rowKey}>{`${rowKey}:`}</RowText>);

  const renderDetailsValueColumn = (): JSX.Element[] => Object.keys(rowDetails)
    .filter((rowKey) => rowDetails[rowKey] !== NO_DATA && rowDetails[rowKey])
    .map((rowKey) => {
      if (renderCustomValue[rowKey]) {
        return renderCustomValue[rowKey](rowDetails[rowKey]);
      }
      return <Text key={rowKey}>{rowDetails[rowKey].toString()}</Text>;
    });

  const renderAutomaticEnrichment = (enrichments: Array<Record<string, string>>): JSX.Element => (
    <Modal
      content={<AlertEnrichmentsTable enrichments={enrichments} />}
      headerProps={{
        title: text.expandRowAlertEnrichment.title(enrichments.length),
      }}
      ariaDescribedby={text.expandRowAlertEnrichment.automaticEnrichmentModal}
      ariaLabelledby={text.expandRowAlertEnrichment.automaticEnrichmentModal}
    >
      <Button variant={ButtonVariants.LINK}>
        {text.expandRowAlertEnrichment.viewDetailsButton}
      </Button>
    </Modal>
  );

  const renderCustomValue = {
    status: (rowValue) => renderStatus(rowValue),
    'Correlation Tags': (rowValue) => renderCorrelationTags(rowValue),
    'Execution Order': (rowValue) => renderExecutionOrder(rowValue),
    'Automatic Enrichment': (rowValue) => renderAutomaticEnrichment(rowValue),
    Condition: (rowValue) => renderCondition(rowValue),
    'Time Window': (rowValue): string => timeWindowString(rowValue),
  };

  const renderDetails = (): JSX.Element[] => {
    const detailsColumn = renderDetailsColumn();
    const detailsValueColumn = renderDetailsValueColumn();
    return detailsColumn.map((detail, index) => (
      <DetailsRow key={`${detail.key}-${detailsValueColumn[index].key}`}>
        <DetailsColumn>
          {detail}
        </DetailsColumn>
        <DetailsValueColumn>
          {detailsValueColumn[index]}
        </DetailsValueColumn>
      </DetailsRow>
    ));
  };

  return (
    <RowDetailsBodyWrapper>
      {renderDetails()}
    </RowDetailsBodyWrapper>
  );
}

export default RowDetailsBody;
