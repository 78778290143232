import BPQL from '@bp/bpql';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const extractRule = ({
  active, config, note: description, type, when, sourceObj,
}) => {
  const {
    destination, source, regex, value: template,
  } = config;

  let condition = '';
  let integration;
  if (!sourceObj) {
    const hasQuery = !isEmpty(when);
    condition = hasQuery ? BPQL.reverse(when) : '';
  } else {
    const { pureQuery, sourceSystems } = sourceObj;
    integration = get(sourceSystems, '[0].value', sourceSystems[0]);
    condition = pureQuery && !isEmpty(pureQuery) ? BPQL.reverse(pureQuery) : '';
  }

  return {
    destination,
    type,
    condition,
    source,
    regex,
    integration,
    template,
    description,
    active,
    config,
  };
};

export default extractRule;
