import get from 'lodash/get';
import { path as parentPath } from '../constants';
import { path } from './constants';

const selectors = {};

selectors.getFeatureToggles = (state) =>
  get(state, `${parentPath}.${path}`, { featureToggles: undefined }).featureToggles;

export default selectors;
