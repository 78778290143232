import React from 'react';
import PropTypes from 'prop-types';
import styles from './title.scss';

const Title = ({ children, level = 1 }) => (
  <span className={styles.title} role="heading" aria-level={level}>
    {children}
  </span>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number,
};

export default Title;
