import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { BamFiltersPlaceHolder } from '@bp/bam';
import FiltersDropdown, {
  DropdownOptionType,
} from 'react/common/components/FiltersDropdown/FiltersDropdown';
import { LabelPropType } from 'react/common/components/ConfigurationLabels/constants';
import ConfigurationLabelsFilter from 'react/common/components/ConfigurationLabels/ConfigurationLabelsFilter/ConfigurationLabelsFilter';
import { ANY_LABEL_FILTER_ITEM } from 'react/common/components/ConfigurationLabels/ConfigurationLabelsFilter/constants';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION, ANY_OPTION_VALUE } from '../../../constants';

function FiltersComponent({
  enableConfigurationLabels,
  filtersDropDownState,
  onChange,
  data,
  labelsData,
}) {
  const clearFiltersSelection = () => {
    const { updateLabelsFilterSelectedItem } = labelsData;
    onChange({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
    if (enableConfigurationLabels) {
      updateLabelsFilterSelectedItem(undefined);
    }
  };

  const {
    tag: { value: tagValue, text: tagText },
    source: { value: sourceValue, text: sourceText },
    status: { value: statusValue, text: statusText },
  } = filtersDropDownState;

  const shouldShowClearFiltersButton =
    [tagValue, sourceValue, statusValue].some((v) => v !== ANY_OPTION_VALUE) ||
    (enableConfigurationLabels &&
      labelsData.labelsFilterSelectedItem !== ANY_LABEL_FILTER_ITEM.value);

  const dropdownElements = [
    <FiltersDropdown
      data={data.tag}
      selectedValue={tagValue}
      selectedText={tagText}
      defaultText="Correlation Tag"
      onChange={(e, selection) => onChange({ tag: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.source}
      selectedValue={sourceValue}
      selectedText={sourceText}
      defaultText="Source"
      onChange={(e, selection) => onChange({ source: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.status}
      selectedValue={statusValue}
      selectedText={statusText}
      defaultText="Status"
      onChange={(e, selection) => onChange({ status: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    enableConfigurationLabels && (
      <ConfigurationLabelsFilter
        allLabels={labelsData.labelsFilterItems}
        selectedLabel={labelsData.labelsFilterSelectedItem}
        onSelectedLabelChanged={labelsData.updateLabelsFilterSelectedItem}
      />
    ),
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={shouldShowClearFiltersButton}
      onClearButtonClick={clearFiltersSelection}
    />
  );
}

FiltersComponent.propTypes = {
  data: PropTypes.shape({
    source: PropTypes.arrayOf(DropdownOptionType),
    tag: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }).isRequired,
  enableConfigurationLabels: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    source: DropdownOptionType,
    tag: DropdownOptionType,
    attribute: DropdownOptionType,
    status: DropdownOptionType,
  }).isRequired,
  labelsData: PropTypes.shape({
    labelsFilterItems: PropTypes.arrayOf(LabelPropType),
    labelsFilterSelectedItem: PropTypes.string,
    updateLabelsFilterSelectedItem: PropTypes.func,
  }),
};
FiltersComponent.defaultProps = {
  labelsData: {
    labelsFilterItems: undefined,
    labelsFilterSelectedItem: undefined,
    updateLabelsFilterSelectedItem: () => undefined,
  },
};
export default FiltersComponent;
