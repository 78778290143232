import { nodeColors } from './topology.scss';

export const defaultColors = {
  check: 'bp-orange',
  server: 'bp-blue-v2',
  location: 'bp-turq',
  environmnet: 'bp-pink',
  application: 'bp-purple',
  ip: 'bp-blue-light-v2',
  router: 'bp-green-dark',
  cluster: 'bp-purple-dark',
  team: 'bp-blue-dark-v2',
};
const allColors = [
  'bp-orange',
  'bp-blue',
  'bp-turq',
  'bp-pink',
  'bp-purple',
  'bp-green-dark',
  'bp-purple-dark',
  'bp-blue-v2',
  'bp-blue-dark-v2',
  'bp-blue-light-v2',
  'bp-bordeaux',
  'bp-blue-x-light-v2',
  'bp-pink-light',
  'bp-olive',
  'bp-green',
  'bp-brown',
  'bp-gray-05',
  'bp-gray-07',
  'bp-yellow',
];

export const dynamicColors = allColors.filter(
  (color) => !Object.values(defaultColors).includes(color)
);

// should move to some kind of storage
export const iconsMap = {
  server: 'server',
  location: 'location',
  check: 'check',
  environmnet: 'environmnet',
  application: 'application',
  ip: 'ip',
  router: 'router',
  cluster: 'cluster',
  team: 'team',
};

export const typesMap = {
  server: [
    'server',
    'servers',
    'host',
    'hosts',
    'hostname',
    'hostnames',
    'host_name',
    'host_names',
    'device',
    'devices',
    'instance',
    'instances',
    'object',
    'objects',
    'node',
    'nodes',
  ],
  location: [
    'location',
    'locations',
    'datacenter',
    'datacenters',
    'dc',
    'store',
    'stores',
    'site',
    'site_code',
    'store_id',
    'region',
    'regions',
    'city',
    'country',
    'geo',
  ],
  check: [
    'check',
    'checks',
    'trigger',
    'triggers',
    'alert_type',
    'alert_name',
    'health_rule',
    'summary',
    'monitor',
    'health_rules',
    'title',
    'titles',
  ],
  environmnet: ['environment', 'environments', 'env', 'envs', 'tier', 'tiers', 'stage'],
  application: [
    'application',
    'applications',
    'app',
    'apps',
    'service',
    'services',
    'business_service',
  ],
  ip: ['ip_address', 'ipaddress', 'ipaddresses', 'ip_class_b', 'ip_class_c', 'ip'],
  router: ['router', 'routers', 'switch', 'switches', 'hub', 'repeater', 'bridge', 'gateway'],
  cluster: ['rack', 'cluster', 'clusters', 'tower', 'towers'],
  team: [
    'owner',
    'owners',
    'team',
    'teams',
    'group',
    'groups',
    'assignment_group',
    'responsible_group',
  ],
};

// should move to some kind of storage
export const fallbackIcons = [
  'flash',
  'star-full',
  'fallback-c',
  'fallback-d',
  'fallback-a',
  'fallback-g',
  'fallback-f',
  'fallback-h',
  'fallback-b',
  'integrations',
  'plane',
  'fallback-e',
  'timeline',
  'fallback-i',
  'fallback-j',
];

export const graphConfig = {
  node: {
    opacity: 1,
    size: 550,
  },
  link: {
    strokeWidth: 3,
    highlightColor: '#D7BAF3',
    color: '#dddddd',
  },
};

export const toggleHelper = `Default view includes data from active alerts only.
To include inactive alerts (ack, resolved and maintenance) as well, switch this toggle on`;
