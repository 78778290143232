import { incidentTagClosedListTypes } from '../../../../../app/react/modules/settings/incident_labels/constants';
import { isTagTypeIsClosedListMap, removeOption } from './constants';

export const getSingleValue = (value) => (Array.isArray(value) ? value[0] : value);

export const createClosedListOptions = (selectedValue, type, closedListValues) => {
  const closedListOptions = closedListValues?.map(({ value }) => ({ id: value, text: value }));
  const isClosedListSingle = type === incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE;
  return isClosedListSingle && selectedValue?.text
    ? [removeOption, ...closedListOptions] : closedListOptions;
};

export const isClosedListValuesIsValid = (values, closedListValues) => {
  const closedListValuesMap = closedListValues.reduce((acc, item) => {
    acc[item.value] = true;
    return acc;
  }, {});

  const errorMessage = (value) => `${value} is no longer part of the closed list.`;

  if (Array.isArray(values)) {
    const notExistingValues = values.filter(
      (selectValue) => selectValue?.text && !closedListValuesMap[selectValue.text],
    );

    if (notExistingValues.length > 0) {
      return errorMessage(notExistingValues[0].text);
    }
  }

  if (values?.text && !closedListValuesMap[values.text]) {
    return errorMessage(values.text);
  }

  return undefined;
};

export const multiValueFieldValidation = (value, type, closedListValues) => {
  if (isTagTypeIsClosedListMap[type] && value) {
    return isClosedListValuesIsValid(value, closedListValues);
  }
  return Array.isArray(value)
        && value.length > 0
        && value[value.length - 1].text.length > 400 ? 'Max 400 chars' : null;
};
