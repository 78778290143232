import {
  AllSizes, Avatar, IOption, SCHBox, Text, Tooltip,
} from '@bp/kung-fu';
import React from 'react';

import ItemWrapper from './index.style';

function EnvRuleAssigneeSelectItem(props: IOption): JSX.Element {
  const {
    name, email,
  } = props;
  return (
    <ItemWrapper>
      <Tooltip text={email} placement="right">
        <SCHBox gap="4px">
          <Avatar name={name} email={email} size={AllSizes.XXSMALL} />
          <Text>{name}</Text>
        </SCHBox>
      </Tooltip>
    </ItemWrapper>
  );
}

export default EnvRuleAssigneeSelectItem;
