import extend from 'lodash/extend';
angular
  .module('bigpanda')
  .service('WidgetsService', function WidgetsService($q, WidgetsBackendService) {
    var self = this;

    self.widgets = {};

    self.cloneWidget = cloneWidget;
    self.createWidget = createWidget;
    self.deleteWidget = deleteWidget;
    self.getMultipleWidgets = getMultipleWidgets;
    self.getWidget = getWidget;
    self.updateWidget = updateWidget;

    /*
     * Returns a map of widgets: { id: widget }
     */
    function getMultipleWidgets(ids) {
      var uncachedWidgetIds = ids.filter((id) => self.widgets[id] === undefined),
        cachedWidgetIds = ids.filter((id) => self.widgets[id] !== undefined);

      var widgets = cachedWidgetIds.reduce((widgetMap, currentWidgetId) => {
        widgetMap[currentWidgetId] = self.widgets[currentWidgetId];
        return widgetMap;
      }, {});

      if (uncachedWidgetIds.length > 0) {
        // Return an object containing all requested widgets indexed by ID
        return WidgetsBackendService.getMultipleWidgets(uncachedWidgetIds).then(
          (returnedWidgets) => {
            returnedWidgets.forEach((widget) => {
              // Cache the widget in the service cache
              self.widgets[widget._id] = widget;
              // Add it to the result set
              widgets[widget._id] = widget;
            });

            return widgets;
          }
        );
      }
      return $q.when(widgets);
    }

    function getWidget(id) {
      if (self.widgets[id] !== undefined) {
        return $q.when(self.widgets[id]);
      }
      return WidgetsBackendService.getWidget(id).then((returnedWidget) => {
        // Cache the widget in the service cache
        self.widgets[returnedWidget._id] = returnedWidget;
        return returnedWidget;
      });
    }

    function updateWidget(id) {
      if (self.widgets[id] !== undefined) {
        var widget = extend({}, self.widgets[id]);
        // aviod update for system widgets
        if (widget.system) {
          return $q.when(widget);
        }

        // Don't attempt to modify _id - Mongo doesn't like that
        delete widget._id;

        return WidgetsBackendService.updateWidget(id, widget);
      }
      return $q.reject();
    }

    function cloneWidget(id, overrides) {
      return getWidget(id)
        .then((widget) => {
          var newWidget = extend({}, widget, overrides);

          // Don't copy over the _id to avoid duplicate key exceptions
          delete newWidget._id;
          newWidget.system && delete newWidget.system;
          newWidget.system_id && delete newWidget.system_id;

          return WidgetsBackendService.createWidget(newWidget);
        })
        .then((newWidgetId) => getWidget(newWidgetId));
    }

    function createWidget(widget) {
      return WidgetsBackendService.createWidget(widget).then((newWidgetId) =>
        getWidget(newWidgetId)
      );
    }

    function deleteWidget(id) {
      return WidgetsBackendService.deleteWidget(id).then(() => {
        delete self.widgets[id];
      });
    }
  });
