import isEmpty from 'lodash/isEmpty';
angular.module('bigpanda').controller('CustomTagsSettingsCtrl', CustomTagsSettingsCtrl);

function CustomTagsSettingsCtrl(
  $scope,
  stateService,
  pubSubService,
  CorrelationActions,
  CustomTagsService,
  CustomTagActions,
  PERMISSIONS_NAMES,
  CorrelationConfigService
) {
  const vm = this;

  pubSubService.broadcast('currentTitleChanged', 'Custom Tags');
  pubSubService.broadcast('settingsPageChanged', 'customTags');

  vm.search = '';
  vm.timeStampTitle = 'Updated:';
  vm.loadingRoute = true;
  vm.tags = [];
  vm.selectedId = stateService.getSelectedTagId;
  vm.buttons = CustomTagActions.buttons;
  vm.hasQuery = hasQuery;
  vm.getQuery = getQuery;
  vm.createPermissions = [PERMISSIONS_NAMES.tags.create];
  init();

  function init() {
    CorrelationConfigService.subscribe($scope, { storeUpdated: () => getTags() });
    getTags({ force: true });
  }

  function getTags(opt) {
    CustomTagsService.getTags(opt).then(onRulesRecived, escapeRoute);
  }

  function getQuery(correlationRule) {
    return CorrelationActions.getQueryDisplayVal(correlationRule.pureQuery);
  }

  function hasQuery(correlationRule) {
    return correlationRule.pureQuery && !isEmpty(correlationRule.pureQuery);
  }

  function onRulesRecived(customTags) {
    if (!customTags) {
      escapeRoute();
    }
    vm.tags = customTags;
    vm.loadingRoute = false;
  }

  function escapeRoute() {
    CorrelationActions.escapeRoute('Could not find Custom Tags', 'app.settings.personal');
  }
}
