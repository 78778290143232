/* istanbul ignore file */
import React from 'react';
import { DotFlashing, DotsWrapper } from './style.css';

const DotsLoaderWrapper = () => {
  return (
    <DotsWrapper>
      <DotFlashing />
    </DotsWrapper>
  );
};

export default DotsLoaderWrapper;
