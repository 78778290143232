import concat from 'lodash/concat';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import size from 'lodash/size';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

angular.module('bigpanda').service('EntitiesListColumnsPicker', EntitiesListColumnsPicker);

function EntitiesListColumnsPicker($filter, EntityTitleService, sourceBreakdownService) {
  const NORMAL_FIELD_MIN_WIDTH = 140;
  const staticColumns = [
    {
      type: 'selected',
      headerDisplayName: 'Selected',
      colClass: 'selected',
      order: 1,
      minWidth: 34,
      maxWidth: 34,
      serverOrderId: 'static#selected',
    },
    {
      type: 'lastUpdate',
      headerDisplayName: 'Change',
      colClass: 'last-update',
      order: 2003,
      minWidth: 53,
      maxWidth: 53,
      serverOrderId: 'static#lastUpdate',
    },
    {
      type: 'status',
      headerDisplayName: 'Status',
      colClass: 'status',
      order: 2,
      minWidth: 34,
      maxWidth: 34,
      serverOrderId: 'static#status',
    },
    {
      type: 'duration',
      headerDisplayName: 'Dur.',
      colClass: 'duration',
      order: 2002,
      minWidth: 36,
      maxWidth: 36,
      serverOrderId: 'static#duration',
    },
    {
      type: 'links',
      headerDisplayName: 'Links',
      colClass: 'links',
      order: 4,
      minWidth: 35,
      maxWidth: 35,
      serverOrderId: 'static#links',
    },
    {
      type: 'underMaintenance',
      headerDisplayName: 'Under maintenance',
      colClass: 'underMaintenance',
      order: 3,
      minWidth: 38,
      maxWidth: 38,
      serverOrderId: 'static#underMaintenance',
    },
  ];

  return EntitiesListColumnsPickerClass;

  function EntitiesListColumnsPickerClass(entities, organizationEntitySorter) {
    let primaryFields = [];
    let secondaryFields = [];
    let columnsMetadata = [];

    this.pickColumns = pickColumns;
    this.getOrderedTagList = getOrderedTagList;

    refreshStaticColumns();
    refreshColumnsMetadata();

    function refreshColumnsMetadata() {
      columnsMetadata = [];
      addSourceSystem();
      refreshPrimaryList();
      refreshSecondaryList();
      addDescription();
      fillAllOtherTags();
      overrideOrderAndDisplayName(columnsMetadata);
      columnsMetadata = sortBy(columnsMetadata, 'order');
    }

    function refreshStaticColumns() {
      overrideOrderAndDisplayName(staticColumns);
    }

    function addSourceSystem() {
      if (sourceBreakdownService.isCrossSource(entities)) {
        columnsMetadata.push({
          type: 'source',
          headerDisplayName: 'source',
          order: 500,
          minWidth: NORMAL_FIELD_MIN_WIDTH,
          serverOrderId: 'source',
        });
      }
    }

    function refreshPrimaryList() {
      primaryFields = map(
        map(EntityTitleService.getMainProperties(entities).primary, 'name'),
        $filter('normalizeTagType')
      );

      columnsMetadata = concat(
        columnsMetadata,
        map(primaryFields, (field) => createTagCol(field, 600, 'primary'))
      );
    }

    function refreshSecondaryList() {
      secondaryFields = map(
        map(EntityTitleService.getMainProperties(entities).secondary, 'name'),
        $filter('normalizeTagType')
      );

      columnsMetadata = concat(
        columnsMetadata,
        map(secondaryFields, (field) => createTagCol(field, 700, 'secondary'))
      );
      columnsMetadata = uniqBy(columnsMetadata, 'headerDisplayName');
    }

    function createTagCol(field, order, colClass) {
      return {
        type: 'text',
        colClass: colClass,
        headerDisplayName: field,
        field: field,
        order: order,
        minWidth: NORMAL_FIELD_MIN_WIDTH,
        serverOrderId: `tag#${field}`,
      };
    }

    function addDescription() {
      let nonEmptyDescriptions = map(entities, 'description');
      nonEmptyDescriptions = nonEmptyDescriptions.sort();
      nonEmptyDescriptions = uniq(nonEmptyDescriptions);
      nonEmptyDescriptions = reject(nonEmptyDescriptions, (description) => isEmpty(description));
      nonEmptyDescriptions = size(nonEmptyDescriptions);

      if (nonEmptyDescriptions) {
        columnsMetadata.push({
          type: 'description',
          headerDisplayName: 'description',
          order: 800,
          minWidth: NORMAL_FIELD_MIN_WIDTH,
          serverOrderId: 'description',
        });
      }
    }

    function fillAllOtherTags() {
      // TODO The list of tags to show should be taken from some kind of configuration
      // TODO for now, we're using all available tags from the entities
      const isEmptyValue = ({ value }) => !!value;
      const toEntityWithoutEmptyTags = (entity) => {
        const { tags: oldTags, ...entityNoTags } = entity;
        const tags = oldTags.reduce((acc, tag) => {
          if (isEmptyValue(tag)) {
            acc.push(tag);
          }
          return acc;
        }, []);
        return { tags, ...entityNoTags };
      };
      const entitiesNoEmptyTags = entities.map((entity) => toEntityWithoutEmptyTags(entity));
      let listOfTagsToShow = map(entitiesNoEmptyTags, 'tags');
      listOfTagsToShow = map(listOfTagsToShow, (tags) =>
        map(tags, (tag) => $filter('normalizeTagType')(tag.type))
      );
      listOfTagsToShow = flatten(listOfTagsToShow);
      listOfTagsToShow = listOfTagsToShow.sort();
      listOfTagsToShow = uniq(listOfTagsToShow);
      listOfTagsToShow = reject(listOfTagsToShow, (tag) => includes(primaryFields, tag));
      listOfTagsToShow = reject(listOfTagsToShow, (tag) => includes(secondaryFields, tag));
      forEach(listOfTagsToShow, (tag) => {
        columnsMetadata.push(createTagCol(tag, 900));
      });
    }

    function overrideOrderAndDisplayName(columns) {
      if (!organizationEntitySorter) {
        return;
      }

      forEach(columns, (column) => {
        if (organizationEntitySorter[column.serverOrderId]) {
          column.order = organizationEntitySorter[column.serverOrderId].order || column.order;
          column.headerDisplayName =
            organizationEntitySorter[column.serverOrderId].display_name || column.headerDisplayName;
        }
      });
    }

    function pickColumns() {
      const pickedColumns = [...staticColumns, ...columnsMetadata];

      return sortBy(pickedColumns, 'order');
    }

    function getOrderedTagList() {
      let orderedTagList = sortBy(columnsMetadata, 'order');
      orderedTagList = filter(
        orderedTagList,
        (col) => col.type === 'text' || col.type === 'description'
      );
      orderedTagList = map(orderedTagList, (tag) => {
        if (tag.type === 'description') {
          return 'description';
        }
        return tag.field;
      });
      return orderedTagList;
    }
  }
}
