angular.module('bigpanda').service('momentService', () => {
  moment.locale('en', {
    calendar: {
      lastDay: 'MMM D, h:mma',
      sameDay: '[Today], h:mma',
      nextDay: 'MMM D, h:mma',
      lastWeek: 'MMM D, h:mma',
      nextWeek: 'MMM D, h:mma',
      sameElse: 'MMM D, h:mma',
    },
  });
});
