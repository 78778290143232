angular
  .module('bigpanda.backend.services')
  .service('CommentsBackendService', CommentsBackendService);

function CommentsBackendService($http) {
  this.createMultiComment = createMultiComment;
  this.getComments = getComments;
  this.getCommentsCounts = getCommentsCounts;

  function createMultiComment(envId, params) {
    return $http
      .post(`/web/environments/${envId}/incidents/comment`, params)
      .then((res) => res.data);
  }

  function getComments(incidentId) {
    return $http
      .get(`/web/incidents/${incidentId}/comments`, { timeout: 5000 })
      .then((res) => res.data.item);
  }

  function getCommentsCounts(ids) {
    return $http
      .post('/web/incidents/comments_count', { ids }, { timeout: 5000 })
      .then((res) => res.data.item.item);
  }
}
