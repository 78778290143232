import { combineEpics } from 'redux-observable';
import { getCorrelationConfig as getCorrelationConfigRequest } from 'common/endpoints/correlation-config';
import delay from 'common/utils/delay';
import { Observable } from 'rxjs';
import { actionTypes as integrationsActionTypes } from 'react/common/integrations';
import { actionTypes as enrichedUsersActionTypes } from 'react/common/enriched_users';
import { actionTypes as contactsActionTypes } from 'react/common/contacts';
import { actionTypes as alertTagsActionTypes } from 'react/common/alert_tags';
import { enrichItemsWithIntegrations } from 'react/common/integrations/enricher';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { alertTagsForDisplay } from '../alert_tags/epic';
import { getIntegrationOptions } from '../integrations/utils';
import { SYSTEM_GENERATED_USER_ID, BP_SYSTEM_USERNAME } from '../../../common/constants';

const loadCorrelationConfig = ($action) =>
  $action.ofType(actionTypes.LOAD_CORRELATION_CONFIG).mergeMap(({ payload = {}, callback }) =>
    delay(payload.delay ? 1000 : 0)
      .then(() => getCorrelationConfigRequest())
      .then((data) => {
        if (callback) {
          callback();
        }
        return actions.loadCorrelationConfigSuccess(data);
      })
  );

const enrichCorrelationConfigItems = (action$) =>
  Observable.combineLatest(
    action$.ofType(actionTypes.LOAD_CORRELATION_CONFIG_SUCCESS),
    action$.ofType(integrationsActionTypes.LOAD_INTEGRATIONS_SUCCESS),
    action$.ofType(enrichedUsersActionTypes.LOAD_ENRICHED_USERS_SUCCESS),
    action$.ofType(contactsActionTypes.LOAD_CONTACTS_SUCCESS),
    (
      { payload: correlationConfig },
      { payload: integrations },
      { payload: enrichedUsers },
      { payload: contacts }
    ) => {
      if (!correlationConfig || !integrations || !enrichedUsers) {
        return actions.enrichCorrelationConfigItemsSuccess(null);
      }

      const integrationOptions = getIntegrationOptions(integrations);
      const customTags = enrichItems(
        correlationConfig.transformations,
        integrationOptions,
        enrichedUsers,
        contacts
      );
      const correlationPatterns = enrichItems(
        correlationConfig.matching_rules,
        integrationOptions,
        enrichedUsers,
        contacts
      );
      return actions.enrichCorrelationConfigItemsSuccess({ customTags, correlationPatterns });
    }
  );

const enrichItems = (items, integrationOptions, enrichedUsers, contacts) => {
  const enrichedWithIntegrations = enrichItemsWithIntegrations(items || [], integrationOptions);
  return enrichedWithIntegrations.map((item) => {
    const itemStatus = getItemStatus(item);
    const metadata = item.metadata || {};
    const { created_user, updated_user } = metadata;
    const createdByUserName = getUserName(created_user, enrichedUsers, contacts);
    const updatedByUserName = getUserName(updated_user, enrichedUsers, contacts);

    return { ...item, createdByUserName, updatedByUserName, ...itemStatus, metadata };
  });
};

const getUserName = (userId, enrichedUsers, contacts) => {
  if (userId === SYSTEM_GENERATED_USER_ID) {
    return BP_SYSTEM_USERNAME;
  }
  let matchingUser = enrichedUsers.find((user) => user.id === userId);
  if (!matchingUser) {
    matchingUser = contacts.find((contact) => contact.bigpanda_user_id === userId);
  }
  return matchingUser ? matchingUser.name : 'N/A';
};

const getItemStatus = (item) => {
  if (item.active) {
    return { status: 'ok', statusText: 'Active' };
  }
  return { status: 'off', statusText: 'Inactive' };
};

const loadUnifiedTags = (action$) =>
  Observable.combineLatest(
    action$.ofType(alertTagsActionTypes.LOAD_ALERT_TAGS_SUCCESS),
    action$.ofType(actionTypes.ENRICH_CORRELATION_CONFIG_ITEMS_SUCCESS),
    ({ payload: { rawAlertTags } }, { payload: correlationConfigItems }) => {
      if (!rawAlertTags || !correlationConfigItems) {
        return actions.loadUnifiedTagsSuccess(null);
      }

      const displayCustomTags = [];
      correlationConfigItems.customTags.forEach((customTag) => {
        customTag.sourceSystems.forEach((sourceSystem) => {
          const parentSourceSystem = sourceSystem.value
            ? sourceSystem.value.split('.')[0]
            : sourceSystem.split('.')[0];
          displayCustomTags.push({
            name: customTag.destination,
            parentSourceSystem: parentSourceSystem,
          });
        });
      });

      const unifiedTags = alertTagsForDisplay(rawAlertTags, displayCustomTags);
      return actions.loadUnifiedTagsSuccess(unifiedTags);
    }
  );

export default combineEpics(loadCorrelationConfig, enrichCorrelationConfigItems, loadUnifiedTags);
