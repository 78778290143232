import bpFetch from '../../../../app/common/utils/http/fetcher';

const url = '/resources/v2.0/plans';

const getAlertFilters = () => bpFetch({ url }).then((res) => res?.data || []);

const deleteAlertFilter = (filterId) => bpFetch({ url: `${url}/${filterId}`, method: 'DELETE' });

const updateAlertFilter = (filter) => bpFetch({ url: `${url}/${filter.id}`, method: 'PATCH', data: filter });

const createAlertFilter = (filter) => bpFetch({ url, method: 'POST', data: filter }).then(({ data }) => data);

export default {
  getAlertFilters,
  deleteAlertFilter,
  updateAlertFilter,
  createAlertFilter,
};
