const { react2angular } = require('react2angular');
const MergeModal = require('react/modules/feed/components/MergeModal').default;

angular
  .module('bigpanda')
  .component(
    'mergeModal',
    react2angular(MergeModal, [
      'buttonType',
      'getIncidents',
      'store',
      'envId',
      'incidentsIds',
      'getCorrelationConfig',
      'getEnvById',
      'searchValue',
      'search',
    ])
  );
