angular.module('bigpanda.backend.services').factory('RulesBackendService', RulesBackendService);

function RulesBackendService($http) {
  function getRules() {
    return $http.get('/web/organization/my/rules').then((res) => res.data.item);
  }

  function getRule(id) {
    return $http.get(`/web/organization/my/rules/${id}`).then((res) => res.data);
  }

  function createRule(payload) {
    return $http.post('/web/organization/my/rules', payload).then((res) => res.data);
  }

  function updateRule(id, payload) {
    return $http.patch(`/web/organization/my/rules/${id}`, payload).then((res) => res.data);
  }

  function deleteRule(id) {
    return $http.delete(`/web/organization/my/rules/${id}`).then((res) => res.data);
  }

  return {
    getRules: getRules,
    createRule: createRule,
    getRule: getRule,
    updateRule: updateRule,
    deleteRule: deleteRule,
  };
}
