/**
 * This service encapsulates correlation preview polling into a single promise.
 * Here is a typical usage of the service:
 *
 * // There are some optional params you can add to the constructor
 * const preview = new CorrelationPreview(config);
 *
 * // Now we want to start polling and wait until we have an answer
 * preview.getPreview().then(
 *    function(result) {
 *      // The result object holds the final results object
 *    },
 *    function(error) {
 *      // In case there was a problem somewhere along the way, we'll get this error
 *    },
 *    function(result) {
 *      // When we get results in progress, we'll get this callback called.
 *      // It will also be called on the when the results are full
 *    }
 * });
 */
angular
  .module('bigpanda')
  .service('CorrelationPreview', ($q, $timeout, $log, CorrelationConfigBackendService) => {
    const IN_PROGRESS_POLLING_INTERVAL = 500;
    const NOT_READY_POLLING_INTERVAL = 100;
    const MAX_CORRELATIONS = 50;
    const MAX_EVENTS = 1000000;

    return CorrelationPreview;

    function CorrelationPreview(
      config,
      { start, end, maxEvents = MAX_EVENTS, maxCorrelations = MAX_CORRELATIONS } = {}
    ) {
      let previewDeferred = null;
      let id = null;
      let resolved = false;
      let createPreviewPromise = null;

      this.getPreview = getPreview;
      this.removePreview = removePreview;

      function getPreview() {
        if (!previewDeferred) {
          previewDeferred = $q.defer();
          createPreviewPromise = CorrelationConfigBackendService.createPreview(
            config,
            start,
            end,
            maxEvents,
            maxCorrelations
          ).then((result) => {
            id = result.id;
            startPolling();
          }, rejectPromise);
        }

        return previewDeferred.promise;
      }

      function removePreview() {
        rejectPromise();
        if (createPreviewPromise) {
          return createPreviewPromise.then(() => {
            CorrelationConfigBackendService.deletePreview(id);
          });
        }
        return $q.when({});
      }

      function startPolling() {
        if (!resolved) {
          CorrelationConfigBackendService.getPreview(id).then(
            (result) => {
              if (result.status === 'done') {
                notifyPromise(result);
                resolvePromise(result);
              } else if (result.status === 'in_progress') {
                notifyPromise(result);
                $timeout(startPolling, IN_PROGRESS_POLLING_INTERVAL);
              } else if (result.status === 'not_ready') {
                $timeout(startPolling, NOT_READY_POLLING_INTERVAL);
              } else {
                $log.error('Got unknown status from correlation preview service', result);
                rejectPromise();
              }
            },
            (error) => {
              rejectPromise(error);
            }
          );
        }
      }

      function rejectPromise(error) {
        if (!resolved) {
          resolved = true;
          if (previewDeferred) {
            previewDeferred.reject(error);
          }
        }
      }

      function resolvePromise(result) {
        if (!resolved) {
          resolved = true;
          previewDeferred.resolve(result);
        }
      }

      function notifyPromise(result) {
        previewDeferred.notify(result);
      }
    }
  });
