import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './validation_error.scss';

const ValidationError = ({ children, className, active, 'aria-label': ariaLabel }) => {
  const ariaLabelText = active ? ariaLabel || children : undefined;

  return (
    <div
      className={cn(styles.ValidationError, className)}
      role="alert"
      aria-atomic="true"
      aria-label={ariaLabelText}
    >
      {active && children}
    </div>
  );
};

ValidationError.propTypes = {
  active: PropTypes.bool.isRequired,
  'aria-label': PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
ValidationError.defaultProps = {
  'aria-label': undefined,
  className: '',
};

export default ValidationError;
