angular.module('bigpanda').controller('SnoozeActivityEventCtrl', SnoozeActivityEventCtrl);

function SnoozeActivityEventCtrl($scope) {
  formatDate($scope.event);

  function formatDate(event) {
    if (event._source.params.time) {
      const momentDate = moment(event._source.params.time * 1000);
      event.snoozeDate = momentDate.format('MMM D');
      event.snoozeTime = momentDate.format('h:mm a');
    }
  }
}
