import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import SettingsListItemActions from 'react/layout/settings/components/SettingsListItemActions';
import permissions from 'common/routing/permissions';
import { selectors as permissionsSelectors } from 'react/user/permissions';
import actions from '../maintenance_plans_list/actions';
import MaintenanceModal from '../maintenance_plan_info/components/modal/MaintenanceModal';

const MaintenancePlansActions = ({ plan, userPermissions, deletePlan, user, organization }) => {
  const semanticPlanStatus = (status) => {
    if (!isInternalPlan) {
      return 'Extrenal';
    }
    switch (status) {
      case 'done':
        return 'Done';
      case 'in progress':
        return 'Running';
      default:
        return 'Done';
    }
  };

  const removePlan = () => {
    deletePlan(plan.id);
  };
  const isInternalPlan = plan.maintenance_key.startsWith('MNT');
  const inProgress = plan.status === 'in progress';

  const hasEditPermission = userPermissions.includes(permissions.plans.update);
  const isPlanEditable = plan.status === 'planned' && isInternalPlan;
  const canEdit = hasEditPermission && isPlanEditable;

  const hasDeletePermission = userPermissions.includes(permissions.plans.delete);
  const isPlanDeletable = !inProgress && isInternalPlan;
  const canDelete = hasDeletePermission && isPlanDeletable;

  const hasCreatePermission = userPermissions.includes(permissions.plans.create);
  const canDuplicate = hasCreatePermission && isInternalPlan;

  const messages = {
    edit: `Cannot Edit ${semanticPlanStatus(plan.status)} Plan '${plan.name}'`,
    duplicate: `Cannot Duplicate ${semanticPlanStatus(plan.status)} Plan '${plan.name}'`,
    delete: `Cannot Delete ${semanticPlanStatus(plan.status)} Plan '${plan.name}'`,
  };

  return (
    <SettingsListItemActions
      item={plan}
      messages={messages}
      modelName="plan"
      editProps={{
        disabled: !canEdit,
        showIfDisabled: hasEditPermission,
        modalProps: { initialValues: plan },
        modal: MaintenanceModal,
      }}
      duplicateProps={{
        disabled: !canDuplicate,
        showIfDisabled: hasCreatePermission,
        modalProps: { initialValues: plan },
        modal: MaintenanceModal,
      }}
      deleteProps={{
        disabled: !canDelete,
        showIfDisabled: hasDeletePermission,
        contentTitleType: 'string',
        action: removePlan,
      }}
    />
  );
};

MaintenancePlansActions.propTypes = {
  plan: PropTypes.object.isRequired /* eslint-disable-line */,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  deletePlan: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired /* eslint-disable-line */,
  user: PropTypes.object.isRequired /* eslint-disable-line */,
};

function mapStateToProps(state) {
  return {
    userPermissions: permissionsSelectors.getPermissionsList(state) || [],
    organization: state.user.organization,
    user: state.layout.topbar.user,
  };
}

const mapDispatchToProps = {
  deletePlan: actions.deletePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(MaintenancePlansActions));
