import { path as prevPath } from '../constants';
import { TagType } from '../../../../../workspaces/apps/alert-enrichment/types';

export const name = 'alertEnrichment';
export const path = `${prevPath}.${name}`;

export const alertTagUrl = (tagName) => `/#/app/settings/alert-enrichment/${tagName}`;

export const ANY_OPTION_VALUE = '-1';
export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  status: { value: ANY_OPTION_VALUE, text: 'Any Status', key: 'Any Status', isAnyOption: true },
  source: { value: ANY_OPTION_VALUE, text: 'Any Source', key: 'Any Source', isAnyOption: true },
  type: { value: ANY_OPTION_VALUE, text: 'Any Type', key: 'Any Type', isAnyOption: true },
};
export const TAG_TYPE = {
  Extraction: TagType.Extraction,
  Composition: TagType.Composition,
  Mapping: TagType.Mapping,
  'Multi Type': TagType.MultiType,
};
