import forEach from 'lodash/forEach';
angular.module('bigpanda.utils').service('SubscribersService', SubscribersService);

function SubscribersService() {
  function SubscribersInstance() {
    const self = this;
    this.subscribers = [];

    this.subscribe = subscribe;
    this.fireStoreUpdated = fireStoreUpdated;

    function subscribe($scope, subscriber) {
      self.subscribers.push(subscriber);
      $scope.$on('$destroy', () => {
        const index = self.subscribers.indexOf(subscriber);
        if (index !== -1) {
          self.subscribers.splice(index, 1);
        }
      });
    }

    function fireStoreUpdated() {
      forEach(self.subscribers, (sub) => sub.storeUpdated());
    }
  }

  return SubscribersInstance;
}
