import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
const v2Suffix = '_v2';
function getIntegrationsForDisplayFactory(
  getAvailableIntegration,
  findSystemName,
  showOnlyActive = true
) {
  const isV2ParentSystem = (parentSourceSystem) => parentSourceSystem.endsWith(v2Suffix);
  const convertToV2 = (parentSourceSystem) => `${parentSourceSystem}${v2Suffix}`;
  const convertToV1 = (parentSourceSystem) =>
    parentSourceSystem.substring(0, parentSourceSystem.length - v2Suffix.length);
  const getParentSystemName = (parentSourceSystem) => {
    const integrationConfig = getAvailableIntegration(parentSourceSystem);
    return integrationConfig ? integrationConfig.name : parentSourceSystem;
  };
  const getAllVersionOption = (v2ParentSourceSystem, v2ParentSourceSystemName) => ({
    display: `${v2ParentSourceSystemName} ALL VERSIONS`,
    value: `${convertToV1(v2ParentSourceSystem)}*`,
  });

  function getAllOptions(parentSourceSystem, parentSystemName, v2ParentSystemsSet) {
    const value = `${parentSourceSystem}.*`;
    if (isV2ParentSystem(parentSourceSystem)) {
      const display = `${parentSystemName} V2 (ALL)`;
      const allVersionsOption = getAllVersionOption(parentSourceSystem, parentSystemName);
      return [
        allVersionsOption,
        {
          display,
          value,
        },
      ];
    }
    const version = v2ParentSystemsSet.has(convertToV2(parentSourceSystem)) ? 'V1 ' : '';
    const display = `${parentSystemName} ${version}(ALL)`;
    return [
      {
        display,
        value,
      },
    ];
  }
  const isValidIntegration = (integration) => !!integration.parent_source_system;
  const isActiveIntegration = (integration) => integration.state === 'active';

  return (integrations) => {
    const activeIntegrations = filter(
      integrations,
      (i) => isValidIntegration(i) && (showOnlyActive ? isActiveIntegration(i) : true)
    );
    const v2ParentSystemsSet = new Set(
      activeIntegrations
        .filter((integration) => isV2ParentSystem(integration.parent_source_system))
        .map((integration) => integration.parent_source_system)
    );
    const parentSourceSystemToIntegrations = groupBy(activeIntegrations, 'parent_source_system');
    const res = Object.entries(parentSourceSystemToIntegrations).map(
      ([parentSourceSystem, associatedIntegrations]) => {
        const parentSystemName = getParentSystemName(parentSourceSystem);
        const option = getAllOptions(parentSourceSystem, parentSystemName, v2ParentSystemsSet);
        return [
          ...option,
          ...associatedIntegrations.map((integration) => ({
            display: findSystemName(integration.source_system, null, integration),
            value: integration.source_system,
          })),
        ];
      }
    );
    return flatten(res);
  };
}

module.exports = {
  getIntegrationsForDisplayFactory,
};
