import { BamFiltersPlaceHolder } from '@bp/bam';
import React from 'react';

import { ANY_OPTION_VALUE, DROPDOWN_FILTERS_ALL_ITEM_OPTION } from '../../../../../../app/react/modules/settings/alert_enrichment/constants';
import { DropdownOption } from '../../../../alert-filtering/src/types/DropdownOption';
import dictionary from '../../dictionary';
import AlertEnrichmentFilter from './AlertEnrichmentFilter';

interface IProps {
  filters: Record<string, DropdownOption>;
  onChange: (a: unknown, b: string, c: unknown) => void;
  data: Record<string, DropdownOption[]>;
  reset: (opts: object) => void;
}

function FiltersV2({
  filters, onChange, data, reset,
}: IProps): JSX.Element {
  const {
    status: { value: statusValue },
    type: { value: typeValue },
    source: { value: sourceValue },
  } = filters;

  const dropdownElements = [
    <AlertEnrichmentFilter
      name={dictionary.rule_details_status}
      options={data.status}
      onSelectedChanged={(value, metadata, item): void => onChange(value, 'status', item)}
      value={statusValue}
      ariaLabel="status filter"
    />,
    <AlertEnrichmentFilter
      name={dictionary.rule_details_type}
      options={data.type}
      onSelectedChanged={(value, metadata, item): void => onChange(value, 'type', item)}
      value={typeValue}
      ariaLabel="type filter"
    />,
    <AlertEnrichmentFilter
      name={dictionary.rule_details_source}
      options={data.source}
      onSelectedChanged={(value, metadata, item): void => onChange(value, 'source', item)}
      value={sourceValue}
      ariaLabel="source filter"
    />,
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={[typeValue, sourceValue, statusValue].some(
        (v) => v !== ANY_OPTION_VALUE,
      )}
      onClearButtonClick={(): void => {
        reset({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
      }}
    />
  );
}

export default FiltersV2;
