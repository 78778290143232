import {
  BamAddNoteField,
  BamBpqlInput,
  BamForm,
  BamInput,
} from '@bp/bam';
import {
  AllSizes,
  darkTheme,
  DatePicker, Divider, HelpTooltip, ISelectOption, Select, Text, VBox,
} from '@bp/kung-fu';
import moment from 'moment';
import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { selectors as alertTagsSelectors } from '../../../../../../app/react/common/alert_tags';
import { selectors as featureTogglesSelectors } from '../../../../../../app/react/user/feature_toggles';
import QueryHelper from '../../../../planned-maintenance/src/components/QueryHelper';
import { AlertTag } from '../../../types/AlertTag';
import { FeatureToggles } from '../../../types/FeatureToggles';
import { IRelativity, MaintenancePlan } from '../../../types/MaintenancePlan';
import { DispatchPayload } from '../modal/types/DispatchPayload';
import DisabledTooltip from './components/DisabledTooltip';
import FormField from './components/FormField';
import {
  formatOrdinal,
  getDaysOfMonth,
  getRelativeDayString,
  maintenancePlansOperatorsText,
} from './constants';
import {
  getNextHalfHour,
  getPayload,
  getTimeframeData,
  validateCondition, validateName,
} from './maintenance-form.logic';
import styles from './recurring_maintenance_form.scss';
import { EMaintenanceFrequency } from './types/EMaintenanceFrequency';
import { EMaintenanceRepeat } from './types/EMaintenanceRepeat';
import { MaintenancePlanForm } from './types/MaintenancePlanForm';

interface IProps {
  initialValues: MaintenancePlan;
  featureToggles?: FeatureToggles;
  createOrUpdatePlan?: (payload: DispatchPayload) => void;
  stopPlanNow?: (payload: DispatchPayload) => void;
  updatePlanEnd?: (payload:DispatchPayload) => void;
  stopPlan?: boolean;
  editTimeWindow?: boolean;
  duplicate?: boolean;
  tags?: AlertTag[];
  positiveButtonText: string;
  positiveButtonProductId?: string;
  close: () => void;
  isEdit: boolean;
  isNew: boolean;
}

interface IState {
  repeat: ISelectOption;
  endRepeat: number;
  isToPristine: boolean;
}

class RecurringMaintenanceForm extends React.Component<IProps, IState> {
  formRef = null;

  initialStartTime = getNextHalfHour();

  hasPlanStarted = false;

  // eslint-disable-next-line react/state-in-constructor
  state = {
    repeat: undefined,
    endRepeat: undefined,
    isToPristine: undefined,
  };

  constructor(props) {
    super(props);

    this.hasPlanStarted = props.isEdit
      && !props.duplicate
      && moment().isAfter(moment.unix(props.initialValues.start));

    const frequency = props.initialValues?.frequency;
    const relativity = props.initialValues?.frequency_data?.relativity;
    this.state = {
      repeat: this.getRepeatOption(frequency, relativity),
      endRepeat: (!props.isNew && props.initialValues.frequency !== EMaintenanceFrequency.Once)
        ? props.initialValues.end
        : moment().add(3, 'months').unix(),
      isToPristine: props.isNew,
    };
  }

  getRepeatOption = (
    frequency?: EMaintenanceFrequency,
    relativity?: IRelativity,
  ): ISelectOption => {
    if (!frequency) return this.getRepeatOptions()[0];
    if (frequency === EMaintenanceFrequency.Daily) {
      return this.getRepeatOptions()[1];
    }
    if (frequency === EMaintenanceFrequency.Weekly) {
      return this.getRepeatOptions()[2];
    }
    if (frequency === EMaintenanceFrequency.Monthly) {
      if (relativity) {
        return this.getRepeatOptions()[3];
      }
      return this.getRepeatOptions()[4];
    }
    return this.getRepeatOptions()[0];
  };

  handleSubmit = (formValues: MaintenancePlanForm): void => {
    const {
      editTimeWindow,
      stopPlan,
      updatePlanEnd,
      stopPlanNow,
      duplicate,
      createOrUpdatePlan,
      close,
    } = this.props;
    const { endRepeat, repeat } = this.state;

    if (this.validateEndDate()) return;

    const payload = getPayload({ ...formValues, endRepeat, repeat });
    if (editTimeWindow) {
      updatePlanEnd(payload);
    } else if (stopPlan) {
      stopPlanNow(payload);
    } else {
      if (duplicate) {
        payload.plan.id = undefined;
      }
      createOrUpdatePlan({ ...payload, hasPlanStarted: this.hasPlanStarted });
    }
    close();
  };

  validateEndDate = (): string => {
    const { from, to } = this.getFormValues();
    return to.isBefore(from) ? 'End date must be later than start date' : '';
  };

  getInitialValues = (): MaintenancePlanForm => {
    const { initialValues, duplicate, isNew } = this.props;
    const { startDate, endDate } = getTimeframeData(
      initialValues,
      this.initialStartTime,
      isNew,
    );
    return {
      from: startDate,
      to: endDate,
      frequency: initialValues?.frequency || EMaintenanceFrequency.Once,
      name: duplicate ? `Copy of ${initialValues?.name}` : initialValues?.name,
      id: initialValues?.id,
      description: initialValues?.description,
      condition: BamBpqlInput.helpers.BpqlToString(initialValues?.condition),
    };
  };

  getFormValues = (): MaintenancePlanForm => (this.formRef
    ? this.formRef?.getFormValues?.()
    : this.getInitialValues());

  getDatePosition = (): string => {
    const { from } = this.getFormValues();
    if (!from) return '';
    const weekday = from.format('dddd');
    return getRelativeDayString(getDaysOfMonth(from)[weekday].indexOf(from.date()));
  };

  onChangeStartTime = (pickedDate: number): void => {
    const { form } = this.formRef?.formRef || {};
    const { repeat, isToPristine } = this.state;
    if (!form) return;
    const { values: { from, to, frequency } } = form.getState();

    const newDate = moment(pickedDate);
    if (!newDate.clone().startOf('day').isSame(from.clone().startOf('day'))) {
      const [hour, minutes] = from.format('H:mm').split(':');
      newDate.set({ hour, minutes });
    }

    if (isToPristine) {
      form.change('to', newDate.clone().add(3, 'hours'));
    } else {
      const duration = to.diff(newDate);
      form.change('to', newDate.clone().add(duration, 'milliseconds'));
    }

    form.change('from', newDate);
    this.setState({ repeat: this.getRepeatOption(frequency, repeat.id) });
  };

  onChangeEndTime = (pickedDate: number): void => {
    const { form } = this.formRef?.formRef || {};
    if (!form) return;
    const { values: { to } } = form.getState();
    const newDate = moment(pickedDate);

    // DatePicker component returns a new date at the beginning of the day regardless of the time
    // and because there is no way to know which value changed - date or time - we must first
    // compare the days to see if they are different, if that's the case then the time was not
    // changed, so we should set it to what it was previously
    if (!newDate.clone().startOf('day').isSame(to.clone().startOf('day'))) {
      const [hour, minutes] = to.format('H:mm').split(':');
      newDate.set({ hour, minutes });
    }

    this.setState({ isToPristine: false });
    form.change('to', newDate);
  };

  getRepeatOptions = (): ISelectOption[] => [
    { id: EMaintenanceRepeat.NONE, frequency: EMaintenanceFrequency.Once, text: 'Does not repeat' },
    { id: EMaintenanceRepeat.DAILY, frequency: EMaintenanceFrequency.Daily, text: 'Daily' },
    { id: EMaintenanceRepeat.WEEKLY, frequency: EMaintenanceFrequency.Weekly, text: `Weekly on ${this.getFormValues().from.format('dddd')}` },
    {
      id: EMaintenanceRepeat.MONTHLY_ON_DAY,
      frequency: EMaintenanceFrequency.Monthly,
      relativity: this.getDatePosition(),
      text: `Monthly on the ${this.getDatePosition()} ${this.getFormValues().from.format('dddd')}`,
    },
    { id: EMaintenanceRepeat.MONTHLY_ON_DATE, frequency: EMaintenanceFrequency.Monthly, text: `Monthly on the ${formatOrdinal(this.getFormValues().from.date())}` },
  ];

  render(): JSX.Element {
    const {
      initialValues,
      editTimeWindow,
      stopPlan,
      positiveButtonText,
      positiveButtonProductId,
      close,
      featureToggles,
      tags,
    } = this.props;

    const { repeat, endRepeat } = this.state;
    const fromDisabled = editTimeWindow || this.hasPlanStarted;
    const toDisabled = this.getFormValues().frequency !== EMaintenanceFrequency.Once
      && this.hasPlanStarted;

    const enableQueryHelper = featureToggles?.recurring_maintenance_enable_query_helper || false;
    const isOldSuggestionsCalculation = featureToggles?.old_suggestions_calc || false;

    const firstSection = (): JSX.Element => {
      if (!stopPlan && !editTimeWindow) {
        return (
          <>
            <DisabledTooltip isActive={this.hasPlanStarted}>
              <Field
                name="name"
                component={BamInput}
                title="Plan Name"
                placeholder="Enter a name..."
                autoFocus
                validate={validateName}
                aria-label="Plan Name"
                disabled={this.hasPlanStarted}
              />
            </DisabledTooltip>
            {enableQueryHelper ? (
              <Field name="condition">
                {({ input: { onChange } }): JSX.Element => (
                  <QueryHelper
                    triggerComponentProps={{
                      title: 'Condition',
                      value: BamBpqlInput.helpers.BpqlToString(initialValues?.condition),
                    }}
                    alertTags={tags}
                    onChange={onChange}
                    placeholder="e.g. host = acme*"
                  />
                )}
              </Field>
            ) : (
              <DisabledTooltip isActive={this.hasPlanStarted}>
                <Field
                  name="condition"
                  component={BamBpqlInput}
                  title="Condition"
                  aria-label="Condition"
                  interactiveTooltip
                  tags={tags}
                  validate={validateCondition}
                  operatorsText={maintenancePlansOperatorsText}
                  useOldCalculation={isOldSuggestionsCalculation}
                  placeholder="e.g. host = acme*"
                  minRows={5}
                  maxRows={5}
                  disabled={this.hasPlanStarted}
                />
              </DisabledTooltip>
            )}
          </>
        );
      }
      return <h1 className={styles['plan-name']}>{initialValues?.name}</h1>;
    };

    const timesSection = (): JSX.Element => {
      if (stopPlan) {
        return (
          <div className={styles['stop-plan']}>
            <p>
              {this.getFormValues().frequency === EMaintenanceFrequency.Once
                ? (
                  <Text size={AllSizes.LARGE}>
                    Clicking the stop button will immediately stop the plan and transition it to
                    &apos;Done&apos;
                  </Text>
                )
                : (
                  <Text size={AllSizes.LARGE}>
                    Stopping a plan will immediately end the maintenance window and cancel any
                    future windows.
                  </Text>
                )}
            </p>
          </div>
        );
      }

      return (
        <div className={styles.times}>
          <div className={styles['schedule-title']}>
            <Divider>
              <Text weight="bold">Define Time Window</Text>
              <Text size={AllSizes.SMALL} color={(p): string => p.theme.bp_gray_06}>
                Based on your local time
                {' '}
                <HelpTooltip text="Time zone is defined by your system settings" />
              </Text>
            </Divider>
          </div>
          <VBox>
            <FormField label="Starts" isTooltipActive={fromDisabled}>
              <Field name="from">
                {(props): JSX.Element => (
                  <DatePicker
                    name="from"
                    blockPast
                    showTime
                    disabled={fromDisabled}
                    error={props.meta.error}
                    value={props.input.value}
                    width="120px"
                    timePickerProps={{
                      name: 'from-time',
                      disabled: fromDisabled,
                    }}
                    onChange={this.onChangeStartTime}
                  />
                )}
              </Field>
            </FormField>
            <FormField label="Ends" isTooltipActive={toDisabled}>
              <Field name="to">
                {(props): JSX.Element => (
                  <DatePicker
                    name="to"
                    blockPast
                    showTime
                    disabled={toDisabled}
                    value={props.input.value}
                    width="120px"
                    onChange={this.onChangeEndTime}
                    timePickerProps={{
                      name: 'totime',
                      error: this.validateEndDate() && ' ',
                      disabled: toDisabled,
                    }}
                    error={this.validateEndDate() && ' '}
                  />
                )}
              </Field>
            </FormField>
            {this.validateEndDate() && (
            <div className={styles['error-field']}>
              <FormField label=" ">
                <Text color={(p): string => p.theme.bp_red} weight="bold">{this.validateEndDate()}</Text>
              </FormField>
            </div>
            )}
          </VBox>
          <Field name="repeat" render={(): null => null} />
          <FormField label="Repeat" isTooltipActive={editTimeWindow || this.hasPlanStarted}>
            <Field name="frequency">
              {({ input: { onChange } }): JSX.Element => (
                <Select
                  name="repeat"
                  value={repeat}
                  options={this.getRepeatOptions()}
                  updateField={(name: string, value: ISelectOption): void => {
                    this.setState({ repeat: value });
                    onChange(value.frequency);
                  }}
                  width="248px"
                  disabled={editTimeWindow || this.hasPlanStarted}
                />
              )}
            </Field>
          </FormField>
          {repeat.id === EMaintenanceRepeat.MONTHLY_ON_DATE && (
            <div className={styles['error-field']}>
              <FormField label=" ">
                <Text size={AllSizes.SMALL} color={(p): string => p.theme.bp_gray_07}>
                  The plan will only run on months where this date occurs
                </Text>
              </FormField>
            </div>
          )}
          {repeat.id !== EMaintenanceRepeat.NONE && (
          <FormField label="End Repeat">
            <DatePicker
              name="endRepeatDate"
              onChange={(value): void => this.setState({ endRepeat: value / 1000 })}
              value={endRepeat * 1000}
              width="120px"
            />
          </FormField>
          )}
        </div>
      );
    };

    const descriptionSection = (open: boolean): JSX.Element => {
      if (!editTimeWindow && !stopPlan) {
        return (
          <div className={styles.description}>
            <Field
              name="description"
              open={open}
              title="Add Description"
              openStateTitle="Description"
              openStateDescription="(optional)"
              aria-label="Description"
              component={BamAddNoteField}
            />
          </div>
        );
      }
      return undefined;
    };

    return (
      <ThemeProvider theme={darkTheme}>
        <div className={styles['maintenance-input-form']}>
          <BamForm
            id="maintenance-form"
            onSubmit={this.handleSubmit}
            positiveButton={{ text: positiveButtonText, 'data-product-id': positiveButtonProductId }}
            closeButton={{ text: 'Cancel', onClick: close }}
            initialValues={this.getInitialValues()}
            keepDirtyOnReinitialize
            ref={(r): void => { this.formRef = r; }}
          >
            {firstSection()}
            {timesSection()}
            {descriptionSection(!!initialValues?.description)}
          </BamForm>
        </div>
      </ThemeProvider>
    );
  }
}

type MapStateToProps = {
  featureToggles: FeatureToggles;
  tags: AlertTag[];
};

const mapStateToProps = (state): MapStateToProps => ({
  featureToggles: featureTogglesSelectors.getFeatureToggles(state),
  tags: alertTagsSelectors.getDisplayAlertTags(state),
});

export default connect(mapStateToProps)(RecurringMaintenanceForm);
