import { path } from './constants';

const actionTypes = {
  UPDATE_SSO: `${path}.UPDATE_SSO`,
  UPDATE_SSO_SUCCESS: `${path}.UPDATE_SSO_SUCCESS`,
  UPDATE_SSO_FAILURE: `${path}.UPDATE_SSO_FAILURE`,

  LOAD_SSO_STATE: `${path}.LOAD_SSO_STATE`,
  LOAD_SSO_STATE_SUCCESS: `${path}.LOAD_SSO_STATE_SUCCESS`,
  LOAD_SSO_STATE_FAILURE: `${path}.LOAD_SSO_STATE_FAILURE`,

  CONFIGURE_SSO_PROVIDER: `${path}.CONFIGURE_SSO_PROVIDER`,
  CONFIGURE_SSO_PROVIDER_SUCCESS: `${path}.CONFIGURE_SSO_PROVIDER_SUCCESS`,
  CONFIGURE_SSO_PROVIDER_FAILURE: `${path}.CONFIGURE_SSO_PROVIDER_FAILURE`,

  DISABLE_SSO: `${path}.DISABLE_SSO`,
  DISABLE_SSO_SUCCESS: `${path}.DISABLE_SSO_SUCCESS`,
  DISABLE_SSO_FAILURE: `${path}.DISABLE_SSO_FAILURE`,
};

export default actionTypes;
