import CorrelationPatternPayload from '../model/CorrelationPatternPayload';
import CorrelationPatternSuggestion from '../model/CorrelationPatternSuggestion';

function mapSuggestionToCorrelationPatternPayload(
  suggestion: CorrelationPatternSuggestion,
): CorrelationPatternPayload {
  return {
    id: null,
    tags: suggestion.synonymsTags,
    window: suggestion.timeWindow,
    active: false,
    cross_source: true,
    query: {
      IN: [
        'source_system',
        [
          {
            type: 'regex',
            value: '*',
          },
        ],
      ],
    },
    description: suggestion.reason,
    suggested: Date.now(),
  };
}

export default mapSuggestionToCorrelationPatternPayload;
