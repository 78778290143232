import capitalize from 'lodash/capitalize';
import { path as prevPath } from '../constants';
import { AI_TAGS } from '../../../../common/constants';

export const name = 'incidentLabels';
export const path = `${prevPath}.${name}`;
export const reqStatusType = Object.freeze({
  NOT_INITIALIZED: -1,
  LOADING: 2,
  FAILURE: 0,
  SUCCESS: 1,
  DELETE_SUCCESS: 3,
  DELETE_FAILURE: 4,
  CREATE_SUCCESS: 5,
});

export const incidentTagTypes = {
  PRIORITY: 'Priority',
  TEXT: 'Text',
  MULTI_VALUE: 'MultiValue',
};

export const incidentTagClosedListTypes = {
  CLOSED_LIST_VALUE_MULTI: 'ClosedListValuesMulti',
  CLOSED_LIST_VALUE_SINGLE: 'ClosedListValuesSingle',
};

export const incidentTagsNameByType = (type) => {
  switch (type) {
    case incidentTagTypes.PRIORITY:
      return 'Priority';
    case incidentTagTypes.TEXT:
      return 'Text';
    case incidentTagTypes.MULTI_VALUE:
      return 'Multi-value';
  }
};

export const incidentTagModalActionTypes = {
  CREATE: 'create',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
};

export const colors = [
  { value: '#ed002c', name: 'red', nameToPresent: 'Red' },
  { value: '#ff7d00', name: 'orange', nameToPresent: 'Orange' },
  { value: '#f8c800', name: 'yellow', nameToPresent: 'Yellow' },
  { value: '#68bfec', name: 'light blue', nameToPresent: 'Light Blue' },
  { value: '#c7c7c7', name: 'soft grey', nameToPresent: 'Soft Grey' },
  { value: '#8f8f8f', name: 'dark grey', nameToPresent: 'Dark Grey' },
  { value: '#333333', name: 'black', nameToPresent: 'Black' },
  { value: '#40a8de', name: 'blue', nameToPresent: 'Blue' },
  { value: '#1379af', name: 'dark blue', nameToPresent: 'Dark Blue' },
  { value: '#ceefff', name: 'soft blue', nameToPresent: 'Soft Blue' },
  { value: '#a70000', name: 'dark red', nameToPresent: 'Dark Red' },
  { value: '#9a52e0', name: 'purple', nameToPresent: 'Purple' },
  { value: '#d7baf3', name: 'light purple', nameToPresent: 'Light Purple' },
  { value: '#83c31d', name: 'green', nameToPresent: 'Green' },
  { value: '#029a47', name: 'dark green', nameToPresent: 'Dark Green' },
  { value: '#3cbea7', name: 'turquoise', nameToPresent: 'Turquoise' },
  { value: '#eb0094', name: 'pink', nameToPresent: 'Pink' },
];
export const colorsNameHexObj = colors.reduce(
  (obj, item) => ({
    ...obj,
    [item.name]: { value: item.value, nameToPresent: item.nameToPresent },
  }),
  {}
);

export const defaultColor = 'soft grey';

export const bpColors = {
  bp_red: '#ed002c',
  bp_blue: '#009AEF',
  bp_gray_01: '#f9f9f9',
  bp_gray_03: '#dddddd',
  bp_gray_04: '#c7c7c7',
  bp_gray_05: '#aeaeae',
  bp_gray_07: '#6b6b6b',
  bp_white: '#ffffff',
};

export const tagDefinitionIconByType = (type) => {
  switch (type) {
    case incidentTagTypes.PRIORITY:
      return 'bp-icon-priority';
    case incidentTagTypes.TEXT:
      return 'bp-icon-text';
    case incidentTagTypes.MULTI_VALUE:
      return 'bp-icon-multi-value';
  }
};

export const defaultEnrichmentConfig = () => ({
  items: [],
});
export const defaultMultiValueEnrichmentConfig = () => ({
  append_matching_items: false,
  items: [],
});

export const defaultFormulaExpression = () => ({
  condition: '',
  value: '',
});
export const defaultIncidentLabel = () => ({
  type: incidentTagTypes.TEXT,
  canManualInput: true,
  active: true,
  enrichment_config: defaultEnrichmentConfig(),
});

export const tooltipWidthByType = (type) => {
  switch (type) {
    case incidentTagTypes.PRIORITY:
      return '564px';
    case incidentTagTypes.TEXT:
      return '564px';
    case incidentTagTypes.MULTI_VALUE:
      return '634px';
  }
};

export const maxNumberOfFormulaItems = 25;

export const ANY_OPTION_VALUE = '-1';

export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  tagType: { value: ANY_OPTION_VALUE, text: 'Any Tag Type' },
  status: { value: ANY_OPTION_VALUE, text: 'Any Status' },
};

export const INCIDENT_LABELS_STATUSES = {
  active: 'active',
  inactive: 'inactive',
};

export const DROPDOWN_FILTER_STATUS_ITEMS = [
  {
    key: INCIDENT_LABELS_STATUSES.active,
    text: capitalize(INCIDENT_LABELS_STATUSES.active),
    value: INCIDENT_LABELS_STATUSES.active,
  },
  {
    key: INCIDENT_LABELS_STATUSES.inactive,
    text: capitalize(INCIDENT_LABELS_STATUSES.inactive),
    value: INCIDENT_LABELS_STATUSES.inactive,
  },
];

export const DROPDOWN_FILTER_TYPE_ITEMS = [
  {
    key: incidentTagTypes.PRIORITY,
    text: incidentTagTypes.PRIORITY,
    value: incidentTagTypes.PRIORITY,
  },
  {
    key: incidentTagTypes.TEXT,
    text: incidentTagTypes.TEXT,
    value: incidentTagTypes.TEXT,
  },
  {
    key: incidentTagTypes.MULTI_VALUE,
    text: incidentTagsNameByType(incidentTagTypes.MULTI_VALUE),
    value: incidentTagTypes.MULTI_VALUE,
  },
];

export const PROTECTED_TAG_IDS = ['itd_priority_1', ...AI_TAGS];
