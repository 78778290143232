import fromPairs from 'lodash/fromPairs';
import isNil from 'lodash/isNil';
import defaults from 'lodash/defaults';
import assign from 'lodash/assign';
import clone from 'lodash/clone';
angular.module('bigpanda').controller('EnvironmentGroupCtrl', EnvironmentGroupCtrl);

function EnvironmentGroupCtrl(
  $scope,
  $state,
  pubSubService,
  EnvironmentGroupsService,
  EnvironmentMenu,
  EnvironmentsCountersStore,
  stateService,
  EnvironmentGroupConstants,
  Permissions,
  PERMISSIONS_NAMES,
  localStorageService,
  VersionStore,
  UserFeatureTogglesService
) {
  const vm = this;

  vm.$onInit = onInit;
  vm.$onChanges = onChanges;
  vm.getTitleIcon = getTitleIcon;
  vm.toggleGroup = toggleGroup;

  vm.group = null;
  vm.initiallySelectedFolderId = stateService.getSelectedFolderId();

  function onInit() {
    vm.group = clone(vm.baseGroup);

    enrichGroup();
    refreshCounters(false);

    EnvironmentsCountersStore.subscribe($scope, { storeUpdated: () => refreshCounters(false) });

    pubSubService.broadcast('title.currentFolderChanged', vm.initiallySelectedFolderId);
  }

  function enrichGroup() {
    assign(vm.group, {
      subtitle:
        vm.group.id === EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_ID
          ? EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_SUBTITLE
          : undefined,
      environments: vm.group.environmentIds
        .map((environmentId) =>
          vm.allEnvironments.find((environment) => environment._id === environmentId)
        )
        .filter((env) => !!env)
        .map((env) => enrichEnvironment(env)),
      isOpen: isGroupOpen(),
      menuContent: getGroupContextMenu(),
    });
  }

  function enrichEnvironment(environment) {
    if (!environment) return;

    const isOpen = isEnvironmentOpen(environment);
    const isFavorite = vm.favoriteEnvironmentIds.includes(environment._id);

    return defaults(
      {},
      {
        name:
          environment.name === EnvironmentGroupConstants.ALL_ENVIRONMENT_NAME
            ? 'All Incidents'
            : environment.name,
        menuContent: EnvironmentMenu.getContent(environment, isFavorite),
        isOpen,
        isSelected: isOpen,
        selectedFolder: isOpen ? vm.initiallySelectedFolderId : null,
        sort: environment.name.toLowerCase() === 'all' ? -1 : 0,
        environmentOnClick: () => environmentClicked(environment._id),
        folderOnClick: (folder) => environmentFolderClicked(environment._id, folder),
      },
      environment
    );
  }

  function isGroupOpen() {
    const groupsState = localStorageService.get(EnvironmentGroupConstants.GROUPS_LOCAL_STORAGE_KEY);
    return groupsState && !isNil(groupsState[vm.group.id]) && !vm.filterMode
      ? groupsState[vm.group.id]
      : true;
  }

  function isEnvironmentOpen(environment, selectedEnvironmentId) {
    if (!selectedEnvironmentId) {
      selectedEnvironmentId = vm.selectedEnvironmentId;
    }
    return environment._id === selectedEnvironmentId && vm.group.id === vm.selectedGroupId;
  }

  function getGroupContextMenu() {
    if (
      !Permissions.isPermitted(PERMISSIONS_NAMES.environments.update) &&
      !Permissions.isPermitted(PERMISSIONS_NAMES.environments.delete)
    ) {
      return [];
    }

    const groupActions = {
      edit: {
        id: 'edit',
        label: 'Edit',
        exe: editGroup,
        icon: 'bp-icon-edit',
        permission: PERMISSIONS_NAMES.environments.update,
      },
      delete: {
        id: 'delete',
        label: 'Delete',
        exe: deleteGroup,
        icon: 'bp-icon-trash',
        permission: PERMISSIONS_NAMES.environments.delete,
      },
    };

    const currentGroupActions =
      vm.group.id &&
      vm.group.id !== EnvironmentGroupConstants.FAVORITES_GROUP_OPTION_ID &&
      vm.group.id !== EnvironmentGroupConstants.DEFAULT_GROUP_OPTION_ID
        ? [groupActions.edit, groupActions.delete]
        : [];

    return currentGroupActions.map((action) =>
      Object.assign({}, action, {
        exe: action.exe.bind(null, vm.group),
      })
    );
  }

  function environmentClicked(environmentId) {
    // If clicking the already selected environment, we just want to toggle its collapse/expand mode.
    if (environmentId === vm.selectedEnvironmentId && vm.group.id === vm.selectedGroupId) {
      const environment = vm.group.environments.find((env) => env._id === environmentId);
      if (environment) {
        environment.isOpen = !environment.isOpen;
      }
      return;
    }

    $state.go('app.overview.incidents.list', { environment: environmentId, folder: 'active' });
    vm.group.environments.find((e) => e._id === environmentId).selectedFolder = 'active';

    // TODO: WTF closeSidenav();

    refreshGroupEnvironments(environmentId);
    vm.groupEnvironmentClicked({ environmentGroupId: vm.group.id, environmentId });
    VersionStore.updateVersion();
  }

  function environmentFolderClicked(environmentId, folder) {
    // closeSidenav();

    if (folder.isActive) {
      return;
    }

    pubSubService.broadcast('Folders.folderSelected', {
      folder: folder.name,
      environment: environmentId,
    });
    pubSubService.broadcast('title.currentFolderChanged', folder.id);

    $state.go('app.overview.incidents.list', { environment: environmentId, folder: folder.id });
    vm.group.environments.find((e) => e._id === environmentId).selectedFolder = folder.id;

    refreshGroupEnvironments(environmentId);
    vm.groupEnvironmentClicked({ environmentGroupId: vm.group.id, environmentId });
  }

  function refreshGroupEnvironments(environmentId) {
    vm.group.environments.forEach((env) => {
      env.isOpen = isEnvironmentOpen(env, environmentId);
      env.isSelected = env.isOpen;
    });
  }

  function editGroup() {
    return EnvironmentGroupsService.showEditModal(vm.allGroups, vm.group).then((updatedGroup) => {
      vm.refreshGroups({ forceReload: true });
      pubSubService.broadcast('environment.group.edited', vm.group, updatedGroup);
    });
  }

  function deleteGroup() {
    return EnvironmentGroupsService.showDeleteModal(vm.group).then(() => {
      vm.refreshGroups({ forceReload: true });
      pubSubService.broadcast('environment.group.deleted', vm.group);
    });
  }

  function refreshCounters(forceReload) {
    return EnvironmentsCountersStore.getEnvFoldersCounters(forceReload).then((counters) => {
      vm.group = Object.assign({}, vm.group, {
        environments: vm.group.environments.map((env) => {
          const matchedCounter = counters.find((count) => count.id === env._id);

          if (!matchedCounter) {
            return env;
          }

          return Object.assign({}, env, {
            counters: fromPairs(
              matchedCounter.folders.map((folderCounter) => [
                folderCounter.id,
                folderCounter.counter,
              ])
            ),
          });
        }),
      });
    });
  }

  function onChanges() {
    if (vm.group) {
      refreshGroupEnvironments(vm.selectedEnvironmentId);
    }
  }

  function getTitleIcon() {
    return vm.group.isFavorite ? 'bp-icon-star-full' : null;
  }

  function toggleGroup() {
    const groupsState =
      localStorageService.get(EnvironmentGroupConstants.GROUPS_LOCAL_STORAGE_KEY) || {};
    const groupIsOpen = !vm.group.isOpen;
    groupsState[vm.group.id] = groupIsOpen;
    localStorageService.set(EnvironmentGroupConstants.GROUPS_LOCAL_STORAGE_KEY, groupsState);

    pubSubService.broadcast('environment.group.displayed', vm.group, groupIsOpen);
  }
}
