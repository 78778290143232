angular.module('bp.widgets').directive('bpSidebar', bpSidebar);

function bpSidebar() {
  return {
    restrict: 'E',
    templateUrl: 'widgets/bp_sidebar/bp_sidebar',
    scope: {
      itemTemplateUrl: '@',
      headerText: '@',
      targetScopeModel: '=',
      items: '=',
      itemClick: '=',
    },
  };
}
