import { filterItems, filterItemsByDropDownFilters } from '../redux.utils';
import { ANY_OPTION_VALUE } from './constants';

export const filterUsersByText = (filterText, users) =>
  filterItems(users, filterText, (user) => [
    user.name,
    user.email,
    user.state,
    user.cls,
    ...(user.roles || []).map(({ name }) => name),
  ]);

export const filterUsersByDropDown = (filtersDropDownState, users) => {
  return filterItemsByDropDownFilters(
    filtersDropDownState,
    {
      roles: (user) => [...user.roles.map((role) => role.key)],
      phone: (user) => [(!!user.phone_number).toString()],
      status: (user) => [user.cls],
    },
    users,
    ANY_OPTION_VALUE
  );
};
