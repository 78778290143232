import map from 'lodash/map';
import concat from 'lodash/concat';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import * as colors from '@bp/pastel/colors';
import {
  BamBaseModal,
  BamModalContent,
  BamModalHeader,
  BamIncidentsBox,
  BamAlertTile,
  BamModalActions,
  BamAddNote,
} from '@bp/bam';
import { Text, darkTheme, Button, ResolveIcon, HBox } from '@bp/kung-fu';
import { docsUrl as docsBaseUrl } from 'common/config';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'common/utils/utils';
import * as actions from '../actions';
import styles from './batch_resolve.scss';

class BatchResolveModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      annotation: '',
      error: false,
      alerts: [],
    };
  }

  getResolveHover = () => {
    const { alerts } = this.props;
    if (!alerts.length) {
      return 'Select Alerts to Resolve';
    }
    if (alerts.every((alert) => alert.status == 'ok')) {
      return concat(pluralize(alerts.length, 'Alert'), ' already resolved');
    }
    return 'Resolve selected Alerts';
  };

  openModal = () => {
    const { alerts, incident } = this.props;

    this.setState(() => ({
      isModalOpen: true,
      error: false,
      alerts: alerts.filter((alert) => alert.status !== 'ok'),
    }));
  };

  reset = () => {
    this.setState(() => ({
      isModalOpen: false,
      annotation: '',
      error: false,
    }));
  };

  close = () => {
    this.reset();
  };

  handleClick = async () => {
    const { alerts } = this.state;
    const { organization, incident, createBatchResolve, envId, getEnvById } = this.props;
    const { name: envName } = await getEnvById(envId);

    const payload = {
      ids: map(alerts, 'id'),
      comments: this.state.annotation,
      environmentName: envName,
      organization: organization.name,
      environmentId: envId,
    };

    createBatchResolve(payload);

    this.reset();
  };

  addNoteOnChangeHandler = (event) => {
    this.setState({
      annotation: event.target.value,
    });
  };

  isButtonDisabled = () => {
    const { alerts } = this.props;
    if (!alerts.length) {
      return true;
    }
    if (alerts.every((alert) => alert.status == 'ok')) {
      return true;
    }
    return false;
  };

  renderModalContent = () => {
    const { alerts } = this.state;
    const showSource =
      alerts.reduce((acc, { sourceCap }) => acc.add(sourceCap), new Set()).size > 1;
    const alertsLength = alerts.length;
    const docsLink = `${docsBaseUrl}/docs/working-with-incidents#resolving-alerts`;
    return (
      <React.Fragment>
        <BamModalHeader
          docsLink={docsLink}
          close={this.close}
          title="Resolve Alerts"
          icon="bp-icon-resolve-full"
          size="huge"
          iconHeaderColor={colors.bp_green}
        />
        <BamModalContent className={styles['resolve-content']}>
          <BamIncidentsBox
            title={concat(`Resolve ${alertsLength} `, pluralize(alertsLength, `Alert`))}
          >
            {alerts.map((alert) => {
              const source = showSource ? alert.sourceCap : undefined;
              return <BamAlertTile key={alert.id} alert={{ ...alert, sourceCap: source }} />;
            })}
          </BamIncidentsBox>
          <div className={styles['add-note-container']}>
            <BamAddNote
              onChange={this.addNoteOnChangeHandler}
              value={this.state.annotation}
              description={concat(
                `(Explain why you are resolving `,
                alertsLength ? `these alerts)` : `this alert)`
              )}
              placeholder="Add resolution reason..."
            />
          </div>
        </BamModalContent>
        <div className={styles['resolve-comment']}>
          <Text size="small" color={(p) => p.theme.bp_gray_07} weight="bold">
            Note:&nbsp;
          </Text>
          <Text size="small" color={(p) => p.theme.bp_gray_07}>
            Alerts reopen automatically if the source system generates a new related event
          </Text>
        </div>
        <BamModalActions
          submitButton={{ text: 'Resolve', onClick: this.handleClick }}
          closeButton={{ text: 'Cancel', onClick: this.close }}
        />
      </React.Fragment>
    );
  };

  render = () => {
    const { alerts } = this.props;
    const { error } = this.state;

    const BatchResolveButton = () => (
      <HBox className={styles.resolve_button_container}>
        <Button
          onClick={this.openModal}
          disabled={this.isButtonDisabled(alerts)}
          variant="action-container"
          size="large"
          icon={<ResolveIcon color={(p) => p.theme.bp_green} />}
          tooltipProps={{
            placement: 'bottom',
            text: this.getResolveHover(alerts),
            isActive: true,
          }}
        >
          Resolve
        </Button>
      </HBox>
    );

    return (
      <ThemeProvider theme={darkTheme}>
        <BatchResolveButton />
        <BamBaseModal
          open={this.state.isModalOpen}
          onClose={this.close}
          onMount={this.onOpen}
          className="bam-batch-resolve-modal"
          size="small"
        >
          {this.renderModalContent()}
        </BamBaseModal>
      </ThemeProvider>
    );
  };
}

BatchResolveModal.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object),
  incident: PropTypes.object.isRequired, // eslint-disable-line
  envId: PropTypes.string.isRequired,
  getEnvById: PropTypes.func.isRequired,
  createBatchResolve: PropTypes.func.isRequired,
  organization: PropTypes.shape({ name: PropTypes.string }),
};

BatchResolveModal.defaultProps = {
  alerts: [],
  organization: {},
};

function mapStateToProps({ user: { organization } = {} }) {
  return {
    organization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createBatchResolve: (payload) => dispatch(actions.createEntitiesBatchResolve(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BatchResolveModal);
