import extend from 'lodash/extend';
import filter from 'lodash/filter';
angular
  .module('bigpanda')
  .controller('IntegrationsSelectionController', IntegrationsSelectionController);

function IntegrationsSelectionController(
  $scope,
  $state,
  $filter,
  AvailableIntegrationsStore,
  UserIntegrationsStore,
  ModalService,
  IntegrationsCategories,
  UserFeatureTogglesService,
  Config
) {
  const vm = this;

  vm.$onInit = function () {
    window.location.replace('/v2/integrations/new');
  };

  vm.hasModalIFrameFT = UserFeatureTogglesService.getToggle('integrations_modal_iframe');
  vm.iframeOriginUrl = Config.integrationsIframeUrl;
  vm.integrationsIframeUrl = `${Config.integrationsIframeUrl}/list?cacheKey=${Date.now()}`;
  vm.selectedCategory = 'all';
  vm.filterData = '';
  vm.suggestFrmSuccessDisplay = true;
  vm.suggestBox = false;
  vm.isSuggestBoxFocus = false;
  vm.noResultsIntegrations = {
    alerts: {
      name: 'Alerts API',
      source_system: 'api',
    },
  };
  vm.categories = IntegrationsCategories;
  filterAvailableIntegrations(AvailableIntegrationsStore.getAvailableIntegrations()).then(
    (availableIntegrations) => {
      vm.integrations = availableIntegrations;
      filterIntegrationsList();
    }
  );

  function filterAvailableIntegrations(availableIntegrations) {
    return UserIntegrationsStore.getIntegrations().then((integrations) =>
      filter(availableIntegrations, (availableIntegration) => {
        if (availableIntegration.options && availableIntegration.options.hiddenGrid) {
          return false;
        }
        if (availableIntegration.options && availableIntegration.options.featureToggle) {
          return UserFeatureTogglesService.getToggle(
            'integrations',
            availableIntegration.source_system
          );
        }
        return true;
      })
    );
  }

  $scope.$watch(
    () => vm.integrationsFilter,
    (newVal, oldval) => {
      if (newVal !== oldval) {
        vm.search();
      }
    }
  );

  vm.toggleSuggestBoxFocus = (isDisplay) => {
    vm.isSuggestBoxFocus = isDisplay;
  };

  function filterIntegrationsList() {
    let filtered = $filter('filter')(vm.integrations, vm.filterData);
    filtered = $filter('orderBy')(filtered, ['order', 'name']);
    vm.filteredIntegrations = filtered;
  }

  vm.shouldSelectionTabBeShown = (category = {}) => {
    const featureToggle = category.featureToggle;
    if (!featureToggle) {
      return true;
    }
    return !!UserFeatureTogglesService.getToggle(featureToggle[0], featureToggle[1]);
  };

  vm.search = () => {
    vm.suggestFrmSuccessDisplay = true;
    vm.filterData = { name: vm.integrationsFilter };

    if (vm.selectedCategory !== 'all') {
      extend(vm.filterData, {
        category: vm.selectedCategory,
      });
    }
    vm.suggestBox = false;
    vm.suggestIntegration = vm.integrationsFilter;
    filterIntegrationsList();
  };

  vm.selectCategory = (categoryName) => {
    vm.suggestFrmSuccessDisplay = true;
    vm.selectedCategory = categoryName;
    vm.integrationsFilter = '';
    vm.filterData = categoryName !== 'all' ? { category: categoryName } : '';
    filterIntegrationsList();
  };

  vm.integrationClick = (currentIntegration) => {
    $state.go('app.integrationInfo.instructions.guide', {
      integration: currentIntegration.source_system,
      guide: '',
    });
  };

  vm.suggest = () => {
    if (vm.suggestIntegration !== '') {
      vm.suggestFrmSuccessDisplay = false;
      UserIntegrationsStore.suggestNewIntegration(vm.suggestIntegration);
      vm.suggestIntegration = '';
    }
  };

  vm.close = ModalService.hide;
}
