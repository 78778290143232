import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { SearchField, AllSizes, HBox } from '@bp/kung-fu';

const TroubleshootingModalSearch = ({ onSearchClick, onSearchInputChange, value }) => {
  return (
    <HBox marginBottom="20px">
      <SearchField
        size={AllSizes.MEDIUM}
        placeholder="Search for event or payload"
        width="100%"
        value={value}
        onChange={onSearchInputChange}
        onClick={onSearchClick}
        disableEmpty={false}
      />
    </HBox>
  );
};

TroubleshootingModalSearch.propTypes = {
  onSearchClick: PropTypes.func.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  value: PropTypes.string || PropTypes.number,
};

const mapStateToProps = (state) => {
  const organization = get(state, 'user.organization');
  const user = get(state, 'layout.topbar.user');
  return {
    organization,
    user,
  };
};

export default connect(mapStateToProps, null)(hot(module)(TroubleshootingModalSearch));
