import { Text, VBox } from '@bp/kung-fu';
import React from 'react';
import styled from 'styled-components';

import CorrelationPatternSuggestionItem from '../CorrelationPatternSuggestionItem';
import CorrelationPatternSuggestionsModalContentProps from './CorrelationPatternSuggestionsModalContentProps';

const Container = styled(VBox)`
  width: 608px;
  height: 433px;
  overflow: auto;
`;

const ClarificationContainer = styled(VBox)`
  margin-top: 16px;
  height: 56px;
  padding-inline-start: 22px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(p): string => p.theme.bp_gray_02};
`;

const SuggestionsContainer = styled(VBox)`
  margin-bottom: 18px; 
  padding-inline-start: 14px;
  padding-inline-end: 36px;
`;

function isSuggestionChecked(suggestion, correlationPatterns): boolean {
  return correlationPatterns.some((pattern) => pattern.tags.join('$') === suggestion.synonymsTags.join('$'));
}

function CorrelationPatternSuggestionsModalContent({
  correlationPatternSuggestions,
  correlationPatterns,
  addCorrelationPattern,
}: CorrelationPatternSuggestionsModalContentProps): JSX.Element {
  const sortedSuggestions = correlationPatternSuggestions.sort((a, b) => {
    const firstValue = isSuggestionChecked(a, correlationPatterns) ? 1 : 0;
    const secondValue = isSuggestionChecked(b, correlationPatterns) ? 1 : 0;
    return firstValue - secondValue;
  });

  return (
    <Container>
      <ClarificationContainer>
        <Text>
          BigPanda AI generates patterns based on data from all BigPanda customers
        </Text>
      </ClarificationContainer>
      <SuggestionsContainer>
        {sortedSuggestions.map((suggestion, index) => (
          <CorrelationPatternSuggestionItem
            key={suggestion.synonymsTags.reduce((a, b) => `${a}$${b}`, '')}
            suggestion={suggestion}
            isSuggestionChecked={isSuggestionChecked(suggestion, correlationPatterns)}
            onSuggestionCheck={addCorrelationPattern}
            index={index}
          />
        ))}
      </SuggestionsContainer>
    </Container>
  );
}

export default CorrelationPatternSuggestionsModalContent;
