import {
  AllSizes, ArrowdownIcon, ArrowforwardIcon, Button, ButtonVariants,
  Collapsable, HBox,
  useBoolean, VBox,
} from '@bp/kung-fu';
import React from 'react';
import styled from 'styled-components';

import mapSuggestionToCorrelationPatternPayload from '../../../services/mapSuggestionToCorrelationPatternPayload';
import SuggestionMetrics from '../SuggestionMetrics';
import TagsDivider from '../TagsDivider';
import CorrelationPatternSuggestionItemProps from './CorrelationPatternSuggestionItemProps';

const Container = styled(VBox)`
  min-height: 107px;
  border-bottom: 1px solid ${(p): string => p.theme.bp_gray_02};
`;

const TitleContainer = styled(HBox)`
  align-items: start;
  justify-content: space-between;
  padding-top: 30px;

`;

const CollapseButtonContainer = styled.div`
  margin-top: 2px;
`;

const CollapseContainer = styled(HBox)`
  cursor: pointer;
`;

const AddButtonContainer = styled.div`
  margin-top: 5px;
`;

function CorrelationPatternSuggestionItem({
  suggestion, isSuggestionChecked, onSuggestionCheck, index,
}: CorrelationPatternSuggestionItemProps): JSX.Element {
  const [isOpen, { toggle }] = useBoolean(index === 0);
  const {
    synonymsTags, timeWindow, reason, coverage,
  } = suggestion;

  return (
    <Container>
      <TitleContainer>
        <CollapseContainer
          onClick={toggle}
          aria-label="Get more information about the pattern suggestion"
        >
          <CollapseButtonContainer>
            {isOpen
              ? (
                <Button
                  icon={(
                    <ArrowdownIcon
                      color={(p): string => p.theme.bp_gray_07}
                      size={AllSizes.LARGE}
                    />
                  )}
                />
              ) : (
                <Button
                  icon={(
                    <ArrowforwardIcon
                      color={(p): string => p.theme.bp_gray_07}
                      size={AllSizes.LARGE}
                    />
                  )}
                />
              )}
          </CollapseButtonContainer>
          <TagsDivider tags={synonymsTags} timeWindow={Math.round(timeWindow / 60)} />
        </CollapseContainer>
        <AddButtonContainer>
          <Button
            size={AllSizes.SMALL}
            disabled={isSuggestionChecked}
            variant={ButtonVariants.PRIMARY}
            tooltipProps={{
              text: 'Added patterns can be removed from the main Correlation Patterns list.',
              isActive: isSuggestionChecked,
              placement: 'bottom-end',
            }}
            onClick={(): void => {
              onSuggestionCheck(mapSuggestionToCorrelationPatternPayload(suggestion));
            }}
            ariaLabel="Add pattern suggestion"
          >
            {isSuggestionChecked ? 'Added' : 'Add Pattern'}
          </Button>
        </AddButtonContainer>
      </TitleContainer>
      <Collapsable isOpen={isOpen}>
        <SuggestionMetrics coverage={coverage} reason={reason} />
      </Collapsable>
    </Container>
  );
}

export default CorrelationPatternSuggestionItem;
