import React from 'react';
import PropTypes from 'prop-types';

import {
  SearchWrapper,
  SearchBox,
  SearchBoxIcon,
  SearchInput,
  SearchClearIcon,
} from './SelectSearch.css';

const SelectSearch = ({ value, onChange }) => (
  <SearchWrapper>
    <SearchBox>
      <SearchBoxIcon className="bp-icon bp-icon-search" />
      <SearchInput
        value={value}
        placeholder="Type to filter..."
        onChange={(e) => onChange(e.target.value)}
      />
      {value && <SearchClearIcon className="bp-icon bp-icon-remove" onClick={() => onChange('')} />}
    </SearchBox>
  </SearchWrapper>
);

SelectSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectSearch;
