import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { HBox, Text, darkTheme, Avatar, Link } from '@bp/kung-fu';
import { ThemeProvider } from 'styled-components';
import { useUserManagement } from 'react/utils/custom_hooks';
import provideStore from 'react/utils/provideStore';
import styles from './personal_settings.scss';
import { selectors as featureTogglesSelectors } from '../../../../user/feature_toggles';

const noNameError = { type: 'error', msg: 'Full name is required' };
const noChangeMadeError = { type: 'success', msg: "Your name wasn't changed" };

const PersonalSettings = () => {
  const { user = {}, error, updateUser } = useUserManagement();

  const userName = user.name;
  const [updatedUsername, setUpdatedUserName] = useState(userName);
  const [validationStatus, setValidationStatus] = useState({ type: null, msg: '' });
  const featureToggles = useSelector((state) => featureTogglesSelectors.getFeatureToggles(state));

  useEffect(() => {
    userName && setUpdatedUserName(userName);
  }, [userName]);

  useEffect(() => {
    error && setValidationStatus({ type: 'error', msg: error });
  }, [error]);

  const save = (e) => {
    e.preventDefault();

    if (!updatedUsername.trim()) {
      setValidationStatus(noNameError);
      return;
    }

    if (userName === updatedUsername) {
      setValidationStatus(noChangeMadeError);
      return;
    }

    updateUser({ name: updatedUsername });
  };

  const onInputFocus = () => {
    setValidationStatus({});
  };

  const onInputChange = (value) => {
    setUpdatedUserName(value);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={styles['user-settings-card-wrapper']}>
        <DocumentTitle title="Personal Settings">
          <div className="bam bamboo">
            <div className={styles['user-settings-card']}>
              <form name="profile" onSubmit={save}>
                <h4 className="mb-5 font-weight-bold">Personal Settings</h4>
                <hr />
                <div className="d-flex align-items-center">
                  <div className={classnames(styles['user-icon-container'], 'p-2')}>
                    <Avatar email={user.email} name={user.name} size="x-large" />
                  </div>
                  <div className="ml-3">
                    <Link href="https://gravatar.com" external>
                      Change at gravatar.com
                    </Link>
                  </div>
                </div>
                <hr />
                <HBox gap="2px" className="py-3">
                  <Text size="large">Email:</Text>
                  <Text size="large" color={(p) => p.theme.bp_gray_06}>
                    {user.email}
                  </Text>
                </HBox>
                <hr />
                <div className="full-name">
                  <div className="form-group py-3">
                    <label htmlFor="fullName" className={classnames(styles.label, 'd-block')}>
                      Full name:
                    </label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className={classnames(styles.input, 'form-control w-25 d-inline py-2')}
                        id="fullName"
                        placeholder="Enter Full Name"
                        onChange={(e) => onInputChange(e.target.value)}
                        onFocus={onInputFocus}
                        value={updatedUsername}
                      />
                      <button
                        type="submit"
                        className={classnames(styles.button, 'btn btn-primary ml-2 py-2')}
                      >
                        Save
                      </button>
                    </div>
                    <div
                      className={classnames(
                        'mt-3',
                        styles[`status-label`],
                        styles[validationStatus.type]
                      )}
                    >
                      {' '}
                      <span className="validationMsg">{validationStatus.msg}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="my-4">
                  <HBox>
                    <Link href="#/app/changepass">Change Password</Link>
                  </HBox>
                </div>
              </form>
            </div>
          </div>
        </DocumentTitle>
      </div>
    </ThemeProvider>
  );
};

export default provideStore(PersonalSettings);
