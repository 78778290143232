import { react2angular } from 'react2angular';
import IncidentLabelsManagement from './components/IncidentLabelsManagement';
import actions from '../actions';
const routes = {};
const template = `<section class="bp-master-detail-view">
    <incident-labels-management class="left-panel" store="store" ></incident-labels-management>
    <div class="spacer"></div>
    <div ui-view="master-detail-info" class="master-detail-info" ></div>
  </section>`;

routes.appSettingsIncidentEnrichmentReact = {
  url: '/incident-enrichment',
  views: {
    'settings-page-view': {
      template: template,
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
    'master-detail-info@app.settings.incidentenrichment': {
      template: '<incident-labels-info class="react-settings-info" store="store" />',
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
  },
  onEnter: ($transitions, pubSubService) => {
    'ngInject';

    $transitions.onSuccess({ to: 'app.settings.incidentenrichment' }, () => {
      pubSubService.broadcast('settingsPageChanged', 'incidentLabels');
    });
  },
  onExit: ($ngRedux) => {
    'ngInject';

    $ngRedux.dispatch(actions.routeExit());
  },
};

angular
  .module('bigpanda')
  .component('incidentLabelsManagement', react2angular(IncidentLabelsManagement));

export default routes;
