import actionTypes from './actionTypes';

const actions = {
  getLogs: (payload) => ({ type: actionTypes.GET_LOGS, payload: payload }),
  getLogsSuccess: (payload) => ({ type: actionTypes.GET_LOGS_SUCCESS, payload: payload }),
  getLogsFailure: (payload) => ({ type: actionTypes.GET_LOGS_FAILURE, payload: payload }),
  getLogsMetadata: (payload) => ({ type: actionTypes.GET_LOGS_METADATA, payload: payload }),
  getLogsMetadataSuccess: (payload) => ({
    type: actionTypes.GET_LOGS_METADATA_SUCCESS,
    payload: payload,
  }),
  getLogsMetadataFailure: (payload) => ({
    type: actionTypes.GET_LOGS_METADATA_FAILURE,
    payload: payload,
  }),
  showDatePicker: (payload) => ({ type: actionTypes.SHOW_DATE_PICKER, payload: payload }),
  hideDatePicker: (payload) => ({ type: actionTypes.HIDE_DATE_PICKER, payload: payload }),
  setCustomDateRange: (payload) => ({ type: actionTypes.SET_CUSTOM_DATE_RANGE, payload: payload }),
};

export default actions;
