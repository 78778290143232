import { generateBaseSettingsActionPaths } from '../../../../app/react/modules/settings/redux.utils';
import { path } from './constants';

const actionTypes = generateBaseSettingsActionPaths(path);

actionTypes.LOAD_PLANS = `${path}.LOAD_PLANS`;
actionTypes.LOAD_PLANS_SUCCESS = `${path}.LOAD_PLANS_SUCCESS`;
actionTypes.CREATE_OR_UPDATE_PLAN = `${path}.CREATE_OR_UPDATE_PLAN`;
actionTypes.DELETE_PLAN = `${path}.DELETE_PLAN`;
actionTypes.STOP_PLAN = `${path}.STOP_PLAN`;
actionTypes.UPDATE_PLAN_END = `${path}.UPDATE_PLAN_END`;
actionTypes.LOAD_INITIAL_PLANS = `${path}.LOAD_INITIAL_PLANS`;

export default actionTypes;
