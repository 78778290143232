import find from 'lodash/find';
angular.module('bigpanda').service('D3Utils', function ($window) {
  this.TRANSITION_DURATION = 750;
  this.LayoutDispatcher = LayoutDispatcher;
  this.fitTickValues = fitTickValues;
  this.getTimeScale = getTimeScale;

  function LayoutDispatcher(dispatch) {
    return (selection) => {
      selection.each(() => {
        const dimensions = d3.select(this).node().getBoundingClientRect();
        const svgWidthWithMargins = dimensions.width;
        const svgHeightWithMargins = dimensions.height;

        if (svgWidthWithMargins <= 0 || svgHeightWithMargins <= 0) {
          return null;
        }

        // Match the layout function using the window's size
        const layoutFunction = find(
          dispatch,
          (layout) =>
            layout.minWindowWidth <= $window.innerWidth &&
            $window.innerWidth <= layout.maxWindowWidth
        ).layout;
        return d3.select(this).call(layoutFunction, svgWidthWithMargins, svgHeightWithMargins);
      });
    };
  }

  function fitTickValues(tickValues, tickWidth, axisWidth) {
    const fittingLabels = Math.floor(axisWidth / tickWidth);
    const skippingInterval = Math.ceil(tickValues.length / fittingLabels);

    if (fittingLabels >= tickValues.length) {
      return tickValues;
    }

    return d3.range(0, tickValues.length - 1, skippingInterval).map((index) => tickValues[index]);
  }

  function getTimeScale(domain, width) {
    const scale = d3.time.scale().range([0, width]);

    if (domain) {
      scale.domain(domain).nice();
    }

    return scale;
  }
});
