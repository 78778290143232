import { HBox, Text, Tooltip } from '@bp/kung-fu';
import React, { memo } from 'react';

import { ITitleData } from '../../types';
import HeaderTooltip from '../header-tooltip/HeaderTooltip';
import {
  Container, CustomDivider, KeyText, ValueText, ValueTextWithDivider,
} from './HeaderTitle.style';

const HeaderTitle = ({ titleData }: { titleData: ITitleData }): JSX.Element => (
  <HBox marginStart="8px">
    <Tooltip
      wrapperDisplay="grid"
      placement="bottom-start"
      text={<HeaderTooltip {...titleData} />}
      maxWidth="350px"
    >
      {!Array.isArray(titleData.title) ? (
        <Container ellipsis>
          <KeyText>
            {titleData.title.key}
            :
          </KeyText>
          {titleData.title.value.map((title) => (
            <ValueTextWithDivider key={title}>{title}</ValueTextWithDivider>
          ))}
        </Container>
      ) : (
        <Text ellipsis>
          <CustomDivider>
            {titleData.title.map((title) => (
              <Text key={title.value.str}>
                <KeyText>{title.key.str}</KeyText>
                <ValueText>{title.value.str}</ValueText>
              </Text>
            ))}
          </CustomDivider>
        </Text>
      )}
    </Tooltip>
  </HBox>
);

export default memo(HeaderTitle);
