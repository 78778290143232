import { addDaysToDate, getCurrentDate, StatusEnum } from '@bp/kung-fu';

import { AuditLogFilter } from './components/types';
import { User } from './hooks/useAuditTable/types';

export const text = {
  changed: '(Changed)',
  pageTitle: 'Audit Log',
  description: 'Track configuration changes in BigPanda around User Management, Environments, AutoShare, Alert Enrichment and Correlation Patterns.',
  learnMore: 'Learn More',
  emptyState: 'No results found',
  none: 'none',
  email: 'Email',
  ipAddress: 'IP Address',
  userAgent: 'User Agent',
  viewObject: 'View object',
  alertMappingTooltip: 'Mapping enrichments cannot currently be viewed in the UI',
  initStateExpanded: {
    header: 'Initial State',
    content: 'record was created by BigPanda system when the audit log feature released and represents a snapshot of the object at that time.',
  },
  expandRowAlertEnrichment: {
    title: (length: number): string => `Automatic Enrichment (${length === 1 ? '1 item' : `${length} items`})`,
    viewDetailsButton: 'View full details',
    automaticEnrichmentModal: 'Automatic Enrichment Modal',
    status: {
      active: 'Active',
      inactive: 'Inactive',
    },
  },
  expandRowEnv: {
    condition: {
      showMore: 'Show More',
      showLess: 'Show Less',
    },
  },
  filters: {
    actionSelect: 'Action',
    categorySelect: 'Category',
    userSelect: 'User',
    objectName: 'Object Name',
    dateFilter: {
      anyTime: 'Any Time',
      last24Hours: 'Last 24 Hours',
      last48Hours: 'Last 48 Hours',
      last7Days: 'Last 7 Days',
      last14Days: 'Last 14 Days',
      last30Days: 'Last 30 Days',
    },
  },
};

export enum QueryKeys {
  AuditLogs = 'AuditLogs',
  AuditChangedObjects = 'AuditChangedObjects',
  Users = 'Users',
}

export const AuditActionStatus = {
  Create: StatusEnum.OK,
  Update: StatusEnum.NEW,
  Delete: StatusEnum.CRITICAL,
  Active: StatusEnum.OK,
  Inactive: StatusEnum.DISABLED,
  'Init State': StatusEnum.NONE,
};

export const DataTestsId = {
  expandTableRow: 'expanded-row',
  alertEnrichmentDetailsTable: 'alert-enrichment-table',
  filterSelect: (filterName: string): string => `select-${filterName}`,
};

export const systemGeneratedUser: User = {
  id: 'system-generated',
  name: 'BigPanda system',
};

export const bigPandaAdmin = 'Bigpanda Admin';

export const DEFAULT_FILTERS: AuditLogFilter = {
  userId: '',
  actionType: '',
  resourceType: '',
  resourceId: '',
  start: (addDaysToDate(getCurrentDate(), -7) / 1000).toFixed(),
  end: (getCurrentDate() / 1000).toFixed(),
};

export const anyChangeObject = { id: '', text: 'Any Object', resourceId: '' };

export const AuditLogDocURL = 'https://docs.bigpanda.io/docs/use-the-audit-log';
