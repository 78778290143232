angular.module('bigpanda').directive('integrationBox', integrationBox);

function integrationBox() {
  return {
    restrict: 'A',
    scope: {
      integration: '=',
      imgUrlPrefix: '@',
      integrationClick: '&',
    },
    templateUrl: 'integrations/integration_box/integration_box',
    link: link,
  };

  function link(scope) {
    const onClick = scope.integrationClick();

    scope.goToIntegrationInfo = () => {
      onClick(scope.integration);
    };

    if (scope.integration.source_system) {
      scope.imgUrl = `${scope.imgUrlPrefix}${scope.integration.source_system.replace(' ', '')}.png`;
    }
  }
}
