import isArray from 'lodash/isArray';
angular.module('bigpanda').controller('ViewRuleController', ViewRuleController);

function ViewRuleController(
  $scope,
  RulesService,
  $controller,
  notificationService,
  TargetSystemsStore
) {
  const vm = this;
  $scope.parentVm = vm;
  angular.extend(vm, $controller('RuleController', { vm: vm }));

  vm.title = 'Edit AutoShare';
  vm.saveButtonText = 'Save';
  vm.readonly = true;
  vm.enableButtons = false;

  vm.saveRule = saveRule;

  vm.$onInit = init;

  function init() {
    if (!vm.ruleId) {
      return vm.goToParent();
    }

    return RulesService.getRule(vm.ruleId).then((rule) => {
      if (!rule) {
        return vm.goToParent();
      }

      vm.environment = rule.environment;
      vm.system = rule.system;
      vm.rule = rule;
      vm.originalParams = angular.extend({}, vm.rule.params);
      vm.systemTemplate = TargetSystemsStore.getSystemParams(vm.system);

      if (!vm.fields) {
        vm.fields = {};
      }

      angular.extend(vm.fields, vm.rule.params);
      vm.enableButtons = true;
      return null;
    });
  }

  function saveRule() {
    vm.preSave().then(() =>
      RulesService.updateRule(vm.rule.id, {
        params: vm.fields,
        inactive: vm.rule.inactive,
      }).then(
        () => {
          vm.postSave();
        },
        (err) => {
          const error =
            err &&
            err.data &&
            err.data.response &&
            isArray(err.data.response.errors) &&
            err.data.response.errors.join(',');
          notificationService.error(`Failed to update rule${error && `: ${error}`}`);
          vm.postSave(err);
        }
      )
    );
  }
}
