angular
  .module('bigpanda')
  .controller('IntegrationsWelcomeController', IntegrationsWelcomeController);

function IntegrationsWelcomeController($state, inviteOpener, PERMISSIONS_NAMES, ModalService) {
  const vm = this;

  vm.invitePermissions = [PERMISSIONS_NAMES.users.create];

  vm.popularIntegrations = [
    { source_system: 'nagios' },
    { source_system: 'cloudwatch' },
    { source_system: 'splunk' },
    { source_system: 'zabbix' },
    { source_system: 'appdynamics' },
    { source_system: 'icinga' },
  ];

  vm.integrationClick = (currentIntegration) => {
    vm.close(true);
    $state.go('app.integrationInfo.instructions.guide', {
      integration: currentIntegration.source_system,
      guide: '',
    });
  };

  vm.invite = () => {
    vm.close(true);
    inviteOpener.open();
  };

  vm.close = (disableRedirect) => {
    ModalService.hide();
    if (!disableRedirect) {
      $state.go('^');
    }
  };
}
