import filter from 'lodash/filter';
import extend from 'lodash/extend';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import each from 'lodash/each';
const { AUDIT_LOG_ACTION_TYPES } = require('common/modules/audit-logs');

angular.module('bigpanda').service('CorrelationConfigService', CorrelationConfigService);

function CorrelationConfigService(
  $q,
  $log,
  CorrelationConfigBackendService,
  pubSubService,
  querySourceParser,
  notificationService,
  UserIntegrationsStore,
  UserIntegrationsUtils,
  CorrelationActions,
  SubscribersService
) {
  const ERR_CANT_SAVE = 'There seems to be a problem. Changes were not saved.';
  const subscribers = new SubscribersService();
  const self = this;

  this.subscribe = subscribe;
  this.getConfig = getConfig;
  this.enrichConfig = enrichConfig;
  this.removeEnrichedData = removeEnrichedData;

  this.removeItem = removeItem;
  this.addNewItem = addNewItem;
  this.editItem = editItem;
  this.toggleItemState = toggleItemState;
  this.checkForConfigChanges = checkForConfigChanges;
  this.validateTagRegex = validateTagRegex;

  function subscribe(scope, subscriber) {
    subscribers.subscribe(scope, subscriber);
  }

  function isConfigUpdated(configCache, configServer) {
    const configStructureCheck = {
      matching_rules: ['tags', 'window', 'query', 'active', 'crossSource', 'metadata'],
      transformations: [
        'source',
        'destination',
        'pattern',
        'template',
        'query',
        'active',
        'metadata',
      ],
    };
    let checkContinue = true;
    let isUpdated = false;

    each(configStructureCheck, (propsToCheck, parentProp) => {
      if (configCache[parentProp].length !== configServer[parentProp].length) {
        isUpdated = true;
        checkContinue = false;
      } else {
        each(propsToCheck, (checkProp) => {
          const configCacheProp = map(configCache[parentProp], checkProp);
          const configServerProp = map(configServer[parentProp], checkProp);

          if (!isEqual(angular.toJson(configCacheProp), angular.toJson(configServerProp))) {
            isUpdated = true;
            checkContinue = false;
            return checkContinue;
          }
        });
      }

      return checkContinue;
    });

    return isUpdated;
  }

  function checkForConfigChanges() {
    return getConfig().then((cacheConfig) =>
      getConfig({ force: true }).then((serverConfig) => {
        const isChanged = isConfigUpdated(cacheConfig, serverConfig);
        if (isChanged) {
          CorrelationConfigBackendService.updateLocalCopy(serverConfig);
          subscribers.fireStoreUpdated();
        }

        return isChanged;
      })
    );
  }

  function removeItem(items, id, eventName, configType) {
    const item = find(items, { id });
    if (item) {
      const itemIndex = findIndex(items, { id });
      items.splice(itemIndex, 1);
      return setConfig(items, configType, AUDIT_LOG_ACTION_TYPES.DELETE, id).then(
        () => {
          pubSubService.broadcast(eventName, id);
          subscribers.fireStoreUpdated();
        },
        (err) => {
          items.splice(itemIndex, 0, item);
          return handleError(ERR_CANT_SAVE, err);
        }
      );
    }
    return handleError(ERR_CANT_SAVE);
  }

  function addNewItem(items, item, eventName, configType) {
    return CorrelationActions.setMetadata(item).then((richItem) => {
      items.unshift(richItem);
      return setConfig(items, configType, AUDIT_LOG_ACTION_TYPES.CREATE, null).then(
        (config) => {
          pubSubService.broadcast(eventName);
          subscribers.fireStoreUpdated();

          const section =
            eventName === 'correlations.customTagAdded' ? 'transformations' : 'matching_rules';
          return config[section][0].id;
        },
        (err) => {
          items.shift();
          return handleError(ERR_CANT_SAVE, err);
        }
      );
    });
  }

  function editItem(items, item, eventName, configType) {
    const currentItem = find(items, { id: item.id });
    return CorrelationActions.setMetadata(item).then((richItem) => {
      extend(currentItem, richItem);
      return setConfig(items, configType, AUDIT_LOG_ACTION_TYPES.UPDATE, item.id).then(
        () => {
          pubSubService.broadcast(eventName, item.id);
          subscribers.fireStoreUpdated();
          return item.id;
        },
        (err) => handleError(ERR_CANT_SAVE, err)
      );
    });
  }

  function toggleItemState(items, item, eventName, configType) {
    let currentItem = find(items, { id: item.id });

    return CorrelationActions.setMetadata(currentItem).then(() => {
      extend(currentItem, { active: !currentItem.active });
      setConfig(items, configType, AUDIT_LOG_ACTION_TYPES.UPDATE, item.id).then(
        () => {
          pubSubService.broadcast(eventName, item.id);
          subscribers.fireStoreUpdated();
        },
        (err) => {
          item.active = !item.active;
          return handleError(ERR_CANT_SAVE, err);
        }
      );
    });
  }

  function getConfig({ force = false, hideError = false } = {}) {
    return CorrelationConfigBackendService.getCorrelationsConfig(force).then(
      (conf) => conf,
      (err) => handleError('There seems to be a problem loading the required data.', err, hideError)
    );
  }

  function setConfig(items, configType, actionType, resourceId) {
    self.removeEnrichedData(items);
    const setCorrelationsPromise = CorrelationConfigBackendService.setCorrelationsConfig(
      items,
      configType,
      actionType,
      resourceId
    );
    self.enrichConfig(items);
    return setCorrelationsPromise;
  }

  function handleError(msg, err, hideError) {
    if (!hideError) {
      notificationService.error(msg, null);
      $log.error(msg);
    }
    return $q.reject();
  }

  function enrichConfig(items) {
    return UserIntegrationsStore.getIntegrations().then((integrations) =>
      enrich(
        items,
        UserIntegrationsUtils.getIntegrationsForDisplay(filter(integrations, { type: 'alert' }))
      )
    );
  }

  function enrich(items, integrations = []) {
    return each(items, (item) => {
      if (item.query) {
        const sourceObj = getSourceSystemAndQuery(item.query);
        if (sourceObj) {
          item.pureQuery = sourceObj.pureQuery;
          item.sourceSystems = multipleSourcesToSourceNames(sourceObj, integrations);
        }
      }
    });
  }

  function removeEnrichedData(rules) {
    return each(rules, (rule) => {
      delete rule.sourceSystems;
      delete rule.pureQuery;
    });
  }

  function getSourceSystemAndQuery(query) {
    const parsedQuery = querySourceParser.parseQuery(query);
    if (!parsedQuery) {
      $log.error(`unable to get parsedQuery from: ${JSON.stringify(query)}`);
      return null;
    }
    return parsedQuery;
  }

  function multipleSourcesToSourceNames(data, integrations) {
    return map(data.sourceSystems, (sourceSystem) =>
      sourceToSourceName(sourceSystem, integrations)
    );
  }

  function sourceToSourceName(sourceSystem, integrations) {
    if (sourceSystem.type === 'regex') {
      return getSourceFromRegex(integrations, sourceSystem.value);
    } else if (typeof sourceSystem === 'string') {
      return find(integrations, { value: sourceSystem });
    }
    $log.error(`Could not get source system. sourceSystem: ${sourceSystem}`);
    return null;
  }

  function getSourceFromRegex(integrations, value) {
    // Legacy: Replace any '.' or '^.' in the value start
    value = value.replace(/^\^\.?/, '');
    if (value === '*') {
      return { display: 'All Systems', value: '*' };
    }
    return find(integrations, { value });
  }

  function validateTagRegex(regex) {
    return CorrelationConfigBackendService.validateTagRegex(regex);
  }
}
