import { darkTheme } from '@bp/kung-fu';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import SettingsListEmptyState from '../../../../../../app/react/layout/settings/components/SettingsListEmptyState';
import SettingsListScreen from '../../../../../../app/react/layout/settings/SettingsListScreen';
import AutoShareRuleModal from '../../../../../../app/react/modules/settings/auto_share_rules/modals/AutoShareRuleModal';
import { ANY_OPTION_VALUE } from '../../core/constants';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import FiltersComponent from './FiltersComponent/FiltersComponent';
import ListItem from './ListItem/ListItem';
import autoShareRulesPropType from './propTypes';

class AutoShareRules extends React.Component {
  componentDidMount() {
    window.location.href = '/v2/settings/auto-share';

    const { rules, loadAutoShareRules } = this.props;
    if (!rules) {
      loadAutoShareRules();
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      rules, isLoading, filterText, filtersDropDownState, filtersDropDownItems,
    } = this.props;

    return (
      (rules === undefined && nextProps.rules && nextProps.rules.length && !nextProps.isLoading)
            || isLoading !== nextProps.isLoading
            || !isEqual(filterText, nextProps.filterText)
            || !isEqual(filtersDropDownState, nextProps.filtersDropDownState)
            || !isEqual(filtersDropDownItems, nextProps.filtersDropDownItems)
    );
  }

  render() {
    const {
      rules,
      isLoading,
      filterText,
      updateFilterText,
      updateFiltersDropDowns,
      filtersDropDownState,
      filtersDropDownItems,
    } = this.props;

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsListScreen
          items={sortBy(rules, (item) => get(item, 'environment.name', '').toLowerCase())}
          loading={isLoading}
          noItemsTitle="No AutoShare rules"
          createItemProps={{
            modal: {
              title: 'Create a new AutoShare',
              titleIcon: 'bp-icon-plus-icon',
              component: AutoShareRuleModal,
            },
            itemName: 'AutoShare Rule',
            buttonText: 'Create AutoShare',
            tileText: 'Create a new AutoShare',
          }}
          listItem={ListItem}
          listItemProp="rule"
          permissionName="notifications"
          headerName="AutoShare"
          filterPlaceholderText="Find AutoShares..."
          updateFilterText={updateFilterText}
          filterText={filterText}
          renderFilterRowElement={() => (filtersDropDownItems && filtersDropDownState ? (
            <FiltersComponent
              filtersDropDownState={filtersDropDownState}
              onChange={updateFiltersDropDowns}
              data={filtersDropDownItems}
            />
          ) : null)}
          renderNoResultsByFilters={() => (filterText
                        || Object.values(filtersDropDownState).some(
                          (dropDownState) => dropDownState.value !== ANY_OPTION_VALUE,
                        ) ? (
                          <SettingsListEmptyState
                            title="No Matches Found"
                            subtitle="Try refining your query and search filters"
                          />
            ) : null)}
        />
      </ThemeProvider>
    );
  }
}

AutoShareRules.propTypes = autoShareRulesPropType;

AutoShareRules.defaultProps = {
  rules: undefined,
  filterText: '',
  filtersDropDownState: undefined,
  filtersDropDownItems: undefined,
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.isLoading(state),
    rules: selectors.getAutoShareRules(state),
    filterText: selectors.getFilterText(state),
    filtersDropDownState: selectors.getFiltersDropDownState(state),
    filtersDropDownItems: selectors.getFiltersDropDownItems(state),
  };
}

const mapDispatchToProps = {
  loadAutoShareRules: actions.loadAutoShareRules,
  updateFilterText: actions.updateFilterText,
  updateFiltersDropDowns: actions.updateFiltersDropDowns,
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(AutoShareRules));
