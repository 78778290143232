import map from 'lodash/map';
angular.module('bigpanda').service('InvestigatorService', InvestigatorService);

function InvestigatorService($filter, InvestigatorBackendService, SortingStore) {
  let query;
  this.getRecentSearches = getRecentSearches;

  function getRecentSearches() {
    return InvestigatorBackendService.getRecentSearches().then((res) => res.data);
  }

  this.initQuery = (
    bpqlQuery,
    environment,
    integration,
    timeframe,
    sort,
    searchQuery,
    pageSize = 10
  ) => {
    query = { sort: sort || SortingStore.options.lastChange };

    if (bpqlQuery) {
      query.bpql = bpqlQuery;
    }

    if (environment) {
      query.environment_id = environment;
    }

    if (integration) {
      query.source_system = integration;
    }

    if (searchQuery) {
      query.search_query_str = searchQuery;
    }

    if (timeframe) {
      const timeframeCopy = Object.assign({}, timeframe);

      if (timeframe.start) {
        timeframeCopy.start = moment(timeframe.start).unix();
      }

      if (timeframe.end) {
        timeframeCopy.end = moment(timeframe.end).unix();
      }

      query.timeframe = timeframeCopy;
    }

    query.paging = {
      from: 0,
      size: pageSize,
    };

    return query;
  };

  this.getSearchResults = (from) => {
    query.paging.from = from;

    return InvestigatorBackendService.searchIncidents(query).then((results) => {
      const incidents = map(results.incidents, (rawIncident) =>
        $filter('incidentNormalizer')(rawIncident)
      );
      return {
        total: results.total,
        incidents: incidents,
      };
    });
  };
}
