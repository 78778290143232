angular
  .module('bigpanda.reports')
  .directive(
    'bpCorrelationLinechart',
    ($window, $q, pubSubService, D3MultipleTimeseriesLinechartFactory) => {
      return {
        restrict: 'E',
        templateUrl:
          'reports/visualization_widget/visualizations/correlation_linechart/correlation_linechart',
        require: '^bpVisualizationWidgetContainer',
        scope: {},
        link: link,
      };

      function link($scope, $element, $attrs, containerController) {
        var unsubscribe = pubSubService.on(
          'reportWidgetsController.refreshReportData',
          onRefreshReportData.bind(undefined, $scope, $element, containerController)
        );

        $scope.$on('$destroy', () => {
          unsubscribe();
        });

        $scope.chart = D3MultipleTimeseriesLinechartFactory()
          .x((d) => d.key)
          .y([
            function (d) {
              return d.incidents_count;
            },
            function (d) {
              return d.events_count;
            },
          ])
          .setSeriesClasses(['incidents', 'events']);

        function resize() {
          d3.select($element[0]).call($scope.chart.resize, containerController.getLayoutName());
        }

        $q.when(containerController.widgetDefinition).then((widget) => {
          angular.element($window).on(`resize.${widget._id}`, () => {
            if (!containerController.dataLoading && !containerController.error) {
              resize();
            }
          });

          if ($window.matchMedia) {
            $window.matchMedia('print').addListener((media) => {
              resize();
            });
          }
          if ($window.onbeforeprint) {
            (function (oldOnbeforeprint) {
              $window.onbeforeprint = function () {
                resize();
                oldOnbeforeprint();
              };
            })($window.onbeforeprint);
          } else {
            (function (oldPrint) {
              $window.print = function () {
                resize();
                oldPrint();
              };
            })($window.print);
          }
        });

        onRefreshReportData($scope, $element, containerController);
      }

      function onRefreshReportData($scope, $element, containerController) {
        containerController.getDisplayConfiguration().then((displayConfig) =>
          containerController.getReportData().then((data) => {
            $scope.chart.setTimeGranularity(displayConfig.timeGranularity);
            d3.select($element[0])
              .datum(data)
              .call($scope.chart.render, containerController.getLayoutName());
          })
        );
      }
    }
  );
