import React from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard, CopyIcon, Button, ButtonVariants, AllSizes } from '@bp/kung-fu';
import styles from './code_span.scss';

const CodeSpan = ({ children, canBeCopied }) => (
  <span className={styles.codeSpan} role="presentation" style={{ display: 'inline-flex' }}>
    {children}
    {canBeCopied && (
      <Button
        variant={ButtonVariants.ACTION_DEFAULT_V2}
        icon={<CopyIcon />}
        onClick={() => copyToClipboard(children)}
        tooltipProps={{
          isActive: true,
          placement: 'right',
          text: 'Can be copied',
        }}
        size={AllSizes.XSMALL}
      />
    )}
  </span>
);

CodeSpan.propTypes = {
  children: PropTypes.node.isRequired,
  canBeCopied: PropTypes.bool,
};

export default CodeSpan;
