import React from 'react';
import PropTypes from 'prop-types';
import styles from './DraggableTag.scss';

class ToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { focus: false };
  }

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  render() {
    const { getToggleButtonProps, hover, disabled } = this.props;
    return (
      <button
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={{ backgroundColor: 'transparent', padding: 0, border: 'none' }}
        {...getToggleButtonProps({ disabled })}
        aria-label="toggle ordering menu"
      >
        <div
          className={styles['toggle-button']}
          style={{ display: this.state.focus || hover ? 'flex' : 'none' }}
        >
          <i className="bp-icon bp-icon-side-bar-items-menu" />
        </div>
      </button>
    );
  }
}

ToggleButton.propTypes = {
  getToggleButtonProps: PropTypes.func,
  disabled: PropTypes.bool,
  hover: PropTypes.bool,
};

export default ToggleButton;
