angular.module('bigpanda').controller('EditorPreviewCtrl', EditorPreviewCtrl);

function EditorPreviewCtrl($scope, IncidentsService, pubSubService) {
  const vm = this;

  vm.previewIncidents = [];

  const editorRefresh = pubSubService.on('editorPreview.refresh', getIncidents);
  $scope.$on('$destroy', editorRefresh);

  function getIncidents(event, filter) {
    vm.loading = true;
    IncidentsService.getIncidentsEditorPreview({ filter: filter })
      .then((incidents) => {
        vm.loading = false;
        vm.previewIncidents = incidents;
      })
      .catch(() => {
        vm.loading = false;
        vm.previewIncidents = [];
      });
  }
}
