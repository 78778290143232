import React from 'react';
import PropTypes from 'prop-types';

import SelectItem from './SelectItem';
import SelectSearch from './SelectSearch';
import { isSelected, getFlatItems } from '../utils';
import { itemPropTypes } from '../propTypes';
import { MenuWrapper, Menu, Items, Group, GroupTitle, NoResultsLabel } from './SelectMenu.css';
import { variants } from '../constants';

const SelectMenu = ({
  menuRef,
  isOpen,
  filteredItems,
  getItemDisplayValue,
  multiChoice,
  query,
  openToTop,
  openToLeft,
  selectedValue,
  shouldShowSearch,
  getItemProps,
  highlightedIndex,
  getMenuProps,
  onQueryChange,
  variant,
  inline,
  selectItem,
  onSelect,
}) => {
  let itemIndex = -1;
  return (
    <MenuWrapper {...getMenuProps()}>
      {isOpen && (
        <Menu
          ref={menuRef}
          inline={inline}
          openToTop={openToTop}
          openToLeft={openToLeft}
          variant={variant}
        >
          {shouldShowSearch && <SelectSearch value={query} onChange={onQueryChange} />}
          <Items>
            {!getFlatItems(filteredItems).length && (
              <NoResultsLabel>No results found</NoResultsLabel>
            )}
            {filteredItems.slice(0, 100).map((item) => {
              if (item.items) {
                if (!item.items.length) {
                  return null;
                }
                return (
                  <Group key={item.value} role="group" aria-label={getItemDisplayValue(item)}>
                    <GroupTitle role="presentation">{getItemDisplayValue(item)}</GroupTitle>
                    {item.items.map((subItem) => {
                      itemIndex++;
                      return (
                        <SelectItem
                          aria-label={item.value}
                          role="option"
                          key={subItem.value}
                          display={getItemDisplayValue(subItem)}
                          icon={subItem.icon}
                          borderBottom={subItem.borderBottom}
                          multiChoice={multiChoice}
                          isSelected={isSelected(subItem.value, selectedValue)}
                          isHighlighted={highlightedIndex === itemIndex}
                          getItemProps={getItemProps}
                          variant={variant}
                          inline={inline}
                          index={itemIndex}
                          item={subItem}
                          selectItem={selectItem}
                          onSelect={onSelect}
                        />
                      );
                    })}
                  </Group>
                );
              }
              itemIndex++;
              return (
                <SelectItem
                  key={item.value}
                  display={getItemDisplayValue(item)}
                  icon={item.icon}
                  borderBottom={item.borderBottom}
                  multiChoice={multiChoice}
                  isSelected={isSelected(item.value, selectedValue)}
                  isHighlighted={highlightedIndex === itemIndex}
                  getItemProps={getItemProps}
                  variant={variant}
                  inline={inline}
                  role="option"
                  aria-label={item.value}
                  name={item.value}
                  index={itemIndex}
                  item={item}
                  selectItem={selectItem}
                  onSelect={onSelect}
                />
              );
            })}
          </Items>
        </Menu>
      )}
    </MenuWrapper>
  );
};

SelectMenu.propTypes = {
  menuRef: PropTypes.func,
  isOpen: PropTypes.bool,
  filteredItems: PropTypes.arrayOf(PropTypes.shape(itemPropTypes)),
  getItemDisplayValue: PropTypes.func,
  multiChoice: PropTypes.bool,
  query: PropTypes.string,
  openToTop: PropTypes.bool,
  openToLeft: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shouldShowSearch: PropTypes.bool,
  getItemProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
  getMenuProps: PropTypes.func,
  onQueryChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(variants)),
  inline: PropTypes.bool,
  selectItem: PropTypes.func,
  onSelect: PropTypes.func,
};

SelectItem.defaultProps = {
  selectItem: () => {},
  onSelect: () => {},
};

export default SelectMenu;
