import { combineReducers } from 'redux';
import * as configurationLabelDefinitions from './configuration_label_definitions';
import * as contacts from './contacts';
import * as enrichedUsers from './enriched_users';
import * as users from './users';
import * as correlationConfig from './correlation_config';
import * as integrations from './integrations';
import * as incidents from './incidents';
import * as alertTags from './alert_tags';

export default combineReducers({
  [configurationLabelDefinitions.name]: configurationLabelDefinitions.reducer,
  [enrichedUsers.name]: enrichedUsers.reducer,
  [contacts.name]: contacts.reducer,
  [users.name]: users.reducer,
  [correlationConfig.name]: correlationConfig.reducer,
  [integrations.name]: integrations.reducer,
  [alertTags.name]: alertTags.reducer,
  [incidents.name]: incidents.reducer,
});
