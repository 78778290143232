import assign from 'lodash/assign';
import isNull from 'lodash/isNull';
const { AUDIT_LOG_ACTION_TYPES } = require('../../../common/modules/audit-logs');

angular
  .module('bigpanda.backend.services')
  .service('CorrelationConfigBackendService', CorrelationConfigBackendService);

function CorrelationConfigBackendService($q, $http, ReduxBridgeService) {
  let cacheConfig = null;
  let requestPromise = null;

  ReduxBridgeService.subscribe('correlationConfig', () => {
    // On every change to correlation config in the redux store - remove the cached config so it is reloaded.
    cacheConfig = null;
  });

  this.getCorrelationsConfig = getCorrelationsConfig;
  this.setCorrelationsConfig = setCorrelationsConfig;

  this.createPreview = createPreview;
  this.getPreview = getPreview;
  this.deletePreview = deletePreview;
  this.updateLocalCopy = updateLocalCopy;
  this.validateTagRegex = validateTagRegex;

  function updateLocalCopy(serverConfig) {
    cacheConfig = angular.copy(serverConfig);
  }

  function getCorrelationsConfig(force) {
    if (!force && cacheConfig) {
      return $q.when(cacheConfig);
    }
    if (requestPromise) {
      return requestPromise;
    }

    requestPromise = $http
      .get('/web/correlation/config', { params: { rnd: Math.random() } })
      .then((res) => {
        if (!res.data.item) {
          return $q.reject('Could not get Correlation Patterns');
        }

        requestPromise = null;
        if (isNull(cacheConfig)) {
          updateLocalCopy(res.data.item);
        }
        return res.data.item;
      });

    return requestPromise;
  }

  function setCorrelationsConfig(items, configType, actionType, resourceId) {
    const isCustomTags = configType === 'transformations';
    const url = `/web/${isCustomTags ? 'tags' : 'correlation'}/config`;
    const resourceType = isCustomTags ? 'custom_tag' : 'correlation_pattern';
    const updatedConfig = assign({}, cacheConfig, { [configType]: items });
    if (Object.values(AUDIT_LOG_ACTION_TYPES).includes(actionType)) {
      return $http
        .put(url, updatedConfig, {
          params: {
            configType,
            resourceType,
            actionType,
            resourceId,
          },
        })
        .then((res) => {
          if (!res.data || !res.data.item) {
            return $q.reject('Could not set Correlation Pattern');
          }

          updateLocalCopy(res.data.item.config);
          return res.data.item.config;
        });
    }
    return Error('Invalid actionType');
  }

  function createPreview(conf, start, end, events, correlations) {
    return $http
      .post('/web/correlation/preview', conf, { params: { start, end, events, correlations } })
      .then((res) => res.data);
  }

  function getPreview(id) {
    return $http.get(`/web/correlation/preview/${id}`).then((res) => res.data);
  }

  function deletePreview(id) {
    return $http.delete(`/web/correlation/preview/${id}`).then((res) => res.data);
  }

  function validateTagRegex(regex) {
    return $http.put('/web/correlation/validate/regex', { regex }).then((res) => res.data);
  }
}
