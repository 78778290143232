import trim from 'lodash/trim';
const { react2angular } = require('react2angular');
const {
  default: QueryHelper,
} = require('../../../../workspaces/apps/incident-feed/src/components/QueryHelper');

angular
  .module('bp.widgets')
  .component(
    'bpQueryHelper',
    react2angular(QueryHelper, [
      'store',
      'handleSearch',
      'search',
      'incidentTags',
      'error',
      'getRecentSearches',
      'disabled',
      'clearButtonClicked',
      'onChange',
      'useIncidentTag',
    ])
  );
angular.module('bp.widgets').directive('queryHelper', queryHelper);

function queryHelper() {
  return {
    restrict: 'E',
    scope: {
      environmentId: '=',
      incident: '=',
      tagDefinitions: '=',
      notification: '=',
      searchValue: '=',
      disabled: '=',
    },
    templateUrl: 'widgets/bp_query_helper/bp_query_helper',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller(
    $ngRedux,
    pubSubService,
    BPQLService,
    BpqlUtils,
    SortingStore,
    $stateParams,
    LabelsStore,
    AssigneeFilterStore,
    $scope,
    stateService,
    IncidentsService,
    UserFeatureTogglesService
  ) {
    const vm = this;
    const envId = stateService.getSelectedEnvironmentId();

    vm.$onInit = () => {
      getMultipleIncidentTagDefinitions();
      vm.store = $ngRedux;
      vm.handleSearch = search;
      vm.onChange = onChange;
      vm.getRecentSearches = IncidentsService.getRecentSearches;
      vm.incidentsSearchNotification = null;
      vm.disableSearch = () => vm.isSortedByPriority() || vm.disabled;
      vm.useIncidentTag = UserFeatureTogglesService.getToggle('enable_namespace_for_search');
      vm.getTooltip = () => {
        if (vm.disabled) {
          return 'Search is currently unavailable for environments configured with incident tags';
        }

        if (vm.isSortedByPriority()) {
          return vm.disableSearchTooltip;
        }

        return '';
      };

      vm.isSortedByPriority = () => vm.sortBy === SortingStore.options.label_priority;

      vm.updatePriorityLabel = () => {
        const priorityTag = LabelsStore.getPriorityDefinition();
        vm.priorityTagName = priorityTag.name || 'Priority';
        vm.priorityTagNameLowerCase = vm.priorityTagName.toLowerCase();
        vm.disableSearchTooltip = `Filtering is unavailable if the incident feed is sorted by ${vm.priorityTagNameLowerCase}`;
      };

      vm.clearButtonClicked = () => {
        vm.notification = null;
        vm.searchValue = '';
        vm.handleSearch();
      };

      vm.updateSortBy = () => {
        SortingStore.getSortBy().then((sortBy) => (vm.sortBy = sortBy));
      };

      updateValues();
      vm.updateSortBy();
      vm.updatePriorityLabel();

      SortingStore.subscribe({ storeUpdated: vm.updateSortBy }, $scope);
      pubSubService.on('incidentTagsDefinitions.updated', updateValues, $scope);
    };

    function onChange(searchValue) {
      vm.searchValue = searchValue;
    }

    function updateValues() {
      vm.incidentTags = LabelsStore.getDefinitions() || [];
    }

    function getMultipleIncidentTagDefinitions() {
      return LabelsStore.getMultipleIncidentTagDefinitions(envId);
    }

    function updateSortOptions() {
      const priorityTag = LabelsStore.getPriorityDefinition();
      vm.sortOptions = [
        {
          display: 'Last Changed',
          value: SortingStore.options.lastChange,
          btnText: !$stateParams.fullscreen ? '' : 'Last Change',
        },
        { display: 'Status', value: SortingStore.options.status, btnText: 'Status' },
        { display: 'Created', value: SortingStore.options.start, btnText: 'Created' },
        { display: 'No. of Alerts', value: SortingStore.options.alerts, btnText: 'No. of Alerts' },
        { display: 'Duration', value: SortingStore.options.duration, btnText: 'Duration' },
      ];
      if (priorityTag.active) {
        const priorityTagName = priorityTag.name || 'Priority';
        vm.sortOptions.splice(1, 0, {
          display: priorityTagName,
          value: SortingStore.options.label_priority,
          btnText: priorityTagName,
          disabled: hasSearchOrFilters(),
          disabledTooltip: `Sorting by ${priorityTagName.toLowerCase()} is unavailable if filters are applied`,
        });
      }
    }

    AssigneeFilterStore.subscribe(
      {
        storeUpdated: () => {
          updateAssignee();
          search();
        },
      },
      $scope
    );

    function updateAssignee() {
      const { unassignedId, getAssignee } = AssigneeFilterStore;
      const assignee = getAssignee() || {};
      vm.assignee =
        !assignee.bigpanda_user_id || assignee.bigpanda_user_id === unassignedId
          ? assignee.bigpanda_user_id
          : assignee.email;
    }

    function search(searchValue) {
      let bpqlQuery = '';
      let bpqlQueryString = '';
      vm.searchValue = searchValue;

      if (hasSearchOrFilters(searchValue)) {
        const parser = BPQLService.buildParserFromGrammer();
        try {
          bpqlQueryString = BpqlUtils.formatBpqlQuery(vm.assignee, searchValue);
          bpqlQuery = parser(bpqlQueryString);
          vm.displayedSearchTerm = angular.copy(searchValue);
          pubSubService.broadcast('Incidents.search', { searchValue: bpqlQueryString });
          vm.incidentsSearchNotification = null;
        } catch (e) {
          e.queryText = searchValue;
          vm.incidentsSearchNotification = {
            type: 'error',
            msg: e.message,
            cls: 'error',
          };
          throw e;
        }
      }

      updateSortOptions();

      pubSubService.broadcast('incident.search', bpqlQuery, searchValue, vm.assignee);
    }

    function hasSearchOrFilters(searchValue) {
      return trim(searchValue) || vm.assignee;
    }
  }
}
