import moment from 'moment';

export const durationFormat = (duration: number): string => {
  const splitTime = ((moment.duration(duration, 'seconds') as unknown) as moment.Moment).format('h:mm').split(':');
  if (splitTime.length === 1) {
    return `0h ${splitTime[0]}m`;
  }

  const [hours, minutes] = splitTime;
  return `${hours}h ${minutes}m`;
};
