import {
  AllSizes,
  Badge,
  buildDateString,
  DateFormat,
  SCHBox,
  Text,
} from '@bp/kung-fu';
import React from 'react';

import SettingsInfoScreen from '../../../../../../app/react/layout/settings/SettingsInfoScreen';
import dictionary from '../../dictionary';
import { AlertFilter } from '../../types/AlertFilter';
import { filterStatus } from '../../utils/filterStatus';

interface IProps {
  filter: AlertFilter;
}

export function FilterInfoContent({ filter }: IProps): JSX.Element {
  const getTimeFrame = (): string => `${buildDateString(filter?.start_time, DateFormat.Absolute)} -
    ${buildDateString(filter?.end_time, DateFormat.Absolute)}`;

  return (
    <SettingsInfoScreen.Section>
      <SettingsInfoScreen.SectionRow title={`${dictionary.status}:`}>
        <Badge
          text={filterStatus(filter).string}
          status={filterStatus(filter).color}
          size={AllSizes.LARGE}
        />
      </SettingsInfoScreen.SectionRow>

      <SettingsInfoScreen.SectionRow title={`${dictionary.source_systems}:`}>
        <SCHBox>
          <Text>
            {filter?.sourceSystems?.map(({ display }) => display).join(', ')}
          </Text>
        </SCHBox>
      </SettingsInfoScreen.SectionRow>

      <SettingsInfoScreen.SectionRow title={`${dictionary.condition}:`}>
        <SCHBox>
          <Text>
            {filter?.condition?.displayQuery}
          </Text>
        </SCHBox>
      </SettingsInfoScreen.SectionRow>

      {(filter?.start_time && filter?.end_time) && (
      <SettingsInfoScreen.SectionRow title={`${dictionary.timewindow}`}>
        <SCHBox>
          <Text>
            {getTimeFrame()}
          </Text>
        </SCHBox>
      </SettingsInfoScreen.SectionRow>
      )}

      {filter?.description && (
      <SettingsInfoScreen.SectionRow title={`${dictionary.description}:`}>
        <SCHBox>
          <Text>
            {filter?.description}
          </Text>
        </SCHBox>
      </SettingsInfoScreen.SectionRow>
      )}
    </SettingsInfoScreen.Section>
  );
}
