import {
  AllSizes, Button, ButtonVariants,
  CommentIcon, darkTheme,
} from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

interface ICommentButton {
  disabled?: boolean,
  tooltipText?: string,
  onClick?: () => void,
  commentsCount: number
}

const CommentButton = ({
  disabled, tooltipText, onClick, commentsCount,
}: ICommentButton) => (
  <ThemeProvider theme={darkTheme}>

    <Button
      icon={<CommentIcon color={(p) => (commentsCount ? p.theme.bp_yellow : undefined)} />}
      ariaLabel="Open comments modal"
      size={AllSizes.LARGE}
      variant={ButtonVariants.ACTION_CONTAINER}
      onClick={onClick}
      disabled={disabled}
      text={commentsCount || ''}
      tooltipProps={{
        isActive: true,
        placement: 'bottom',
        text: tooltipText,
      }}
    />

  </ThemeProvider>
);

export default CommentButton;
