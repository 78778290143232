angular.module('bigpanda').directive('bpFormGlobalError', bpFormGlobalError);

function bpFormGlobalError() {
  return {
    restrict: 'E',
    scope: {
      errTitle: '@',
      text: '@',
      dismiss: '&',
      refresh: '&',
      showRefresh: '=',
    },
    templateUrl: 'widgets/bp_form_global_error/bp_form_global_error',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller() {
    const vm = this;
    vm.onDismissAction = (event) => {
      vm.dismiss({ event: event });
    };
    vm.onRefreshAction = () => {
      vm.refresh();
    };
  }
}
