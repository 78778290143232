import find from 'lodash/find';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import findIndex from 'lodash/findIndex';

angular.module('bigpanda').filter('timelinePoints', timelinePoints);

function timelinePoints($log, STATUS_COLORS, STATUS_RANKS) {
  return filter;

  function filter(entities, orderedTagList) {
    if (!entities) {
      return null;
    }

    const rows = [];
    const normalizedStatuses = {};

    entities.forEach((entity) => {
      const points = [];
      const sortedEvents = cloneDeep(entity.events).sort(compareEvents);
      let lastStatus = null;

      calculateStatuses(sortedEvents);

      forEach(sortedEvents, (event, i) => {
        if (i > 0) {
          event.tags = uniqBy(flatten([event.tags, sortedEvents[i - 1].tags]), 'type');
        }

        const eventStatus = normalizedStatuses[event._id];
        const sortedTags = getSortedTags(event);

        if (lastStatus === eventStatus) {
          return;
        }

        lastStatus = eventStatus;

        const point = {
          id: event._id,
          source: entity.sourceCap,
          start: event.timestamp,
          date: moment.unix(event.timestamp).format('llll'),
          class: eventStatus,
          tags: sortedTags,
          status: eventStatus,
          underMaintenance: entity.underMaintenance,
          plans: entity.underMaintenance ? entity.maintenanceTooltip.plans : [],
          source_system: entity.source_system,
        };

        const next = nextDifferentStatus(sortedEvents, i, eventStatus);

        // Every event except for 'ok' needs to be connected to the next event, if there is one
        if (!eventStatus || eventStatus.toLowerCase() !== 'ok') {
          if (next) {
            point.end = next.timestamp;
          } else {
            point.ongoing = true;
          }
        }

        const status = eventStatus && eventStatus.toLowerCase();
        const colorObject = find(STATUS_COLORS, (color) => color.values.indexOf(status) >= 0);

        if (colorObject) {
          point.color = colorObject.color;
        }

        points.push(point);
      });

      rows.push({ id: entity.id, points: points });
    });

    return rows;

    function nextDifferentStatus(events, i, status) {
      for (let j = i + 1; j < events.length; j++) {
        const currStatus = normalizedStatuses[events[j]._id];
        if (currStatus !== status && currStatus) {
          return events[j];
        }
      }

      return null;
    }

    function calculateStatuses(events) {
      let maintenance = false;
      forEach(events, (event) => {
        if (event.status && event.status.toLowerCase() === 'nostatus') {
          if (event.maintenance === 'deactivate') {
            maintenance = false;
          }

          if (
            event.maintenance === 'activate' ||
            (event.maintenance === 'nochange' && maintenance)
          ) {
            normalizedStatuses[event._id] = 'maintenance';
            maintenance = true;
          } else if (event.acknowledge === 'activate') {
            normalizedStatuses[event._id] = 'acknowledged';
          }
        }
        if (!normalizedStatuses[event._id]) {
          normalizedStatuses[event._id] = event.status;
        }
      });
    }

    function getSortedTags(event) {
      const tags = angular.copy(event.tags);
      tags.push({ type: 'description', value: event.description });

      let sortedTags = sortBy(tags, (tag) =>
        findIndex(orderedTagList, (type) => type === tag.type || `_${type}` === tag.type)
      );
      sortedTags = uniqBy(sortedTags, (tag) => tag.type);
      return sortedTags;
    }

    function undefinedStatus(event) {
      $log.error(`Failed to rank unknown status ${event.status}`, event);
      return STATUS_RANKS.UNDEFINED;
    }

    function compareEvents(a, b) {
      if (a.timestamp !== b.timestamp) {
        return a.timestamp - b.timestamp;
      }

      if (a.status.toLowerCase() === b.status.toLowerCase()) {
        return a.offset - b.offset;
      }

      return (
        (STATUS_RANKS[a.status.toLowerCase()] || undefinedStatus(a)) -
        (STATUS_RANKS[b.status.toLowerCase()] || undefinedStatus(b))
      );
    }
  }
}
