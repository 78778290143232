angular.module('bigpanda').directive('compositionTagTemplate', compositionTagTemplate);

function compositionTagTemplate(CustomTagUtils) {
  return {
    restrict: 'E',
    templateUrl: 'settings/correlation/custom_tags/utils/composition_tag',
    scope: {
      template: '=',
      query: '=',
    },
    controllerAs: 'compositionTagVm',
    bindToController: true,
    controller,
  };

  function controller($scope) {
    const vm = this;

    $scope.$watch(
      () => vm.template,
      () => {
        vm.templateArray = CustomTagUtils.extractTemplateToTokens(vm.template);
      }
    );
  }
}
