angular.module('bigpanda').filter(
  'titleCase',
  () =>
    function (text) {
      if (text && text.length > 0) {
        text = text[0].toUpperCase() + text.slice(1);
      }
      return text;
    }
);
