import {
  HBox, ITableCell,
} from '@bp/kung-fu';
import isNil from 'lodash/isNil';
import React from 'react';

import { bigPandaAdmin, text } from '../../../../constants';
import UserAvatar from '../UserAvatar';
import {
  UserColumnWrapper, UserContentWrapper, UserNameText,
} from './UserColumn.style';

function UserColumn({ cellData }: ITableCell): JSX.Element {
  if (isNil(cellData) || isNil(cellData.name)) return <HBox>{text.none}</HBox>;

  const { name } = cellData;
  const userName = name === bigPandaAdmin || name.includes('root') ? 'BigPanda System' : name;

  return (
    <UserColumnWrapper>
      <UserAvatar userName={userName} />
      <UserContentWrapper>
        <UserNameText title={userName}>{userName}</UserNameText>
      </UserContentWrapper>
    </UserColumnWrapper>
  );
}

export default UserColumn;
