import { ISelectOption } from '@bp/kung-fu';

import { anyUser } from '../../components/AuditLogTable/components/TableFilters/constants';

const mapUsers = (userArray: ISelectOption[]): ISelectOption[] => userArray.map((user) => ({ ...user, text: `${user.name} ${user.username}` }));

export const transformUsers = (userArray: ISelectOption[]): ISelectOption[] => {
  const users = mapUsers(userArray);
  users.unshift(anyUser);
  return users;
};
