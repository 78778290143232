import map from 'lodash/map';
import find from 'lodash/find';
angular.module('bigpanda').service('ContactsStore', ContactsStore);

function ContactsStore(
  $q,
  ContactsBackendService,
  SubscribersService,
  deepCloneObject,
  ReduxBridgeService
) {
  const subscribers = new SubscribersService();
  let contactsCache = null;

  ReduxBridgeService.subscribe('contacts', (payload) => {
    contactsCache = payload;
  });

  this.subscribe = subscribe;
  this.getContacts = getContacts;
  this.getContactByUserId = getContactByUserId;
  this.createContacts = createContacts;
  this.updateContact = updateContact;

  function subscribe(subscriber, scope) {
    subscribers.subscribe(scope, subscriber);
  }

  function getContacts(force) {
    if (contactsCache && !force) {
      return $q.when(deepCloneObject.cloneDeep(contactsCache));
    }
    return ContactsBackendService.getAllContacts().then((contacts) => {
      contactsCache = contacts;
      return deepCloneObject.cloneDeep(contactsCache);
    });
  }

  function getContactByUserId(userId) {
    return getContacts().then((contacts) => find(contacts, { bigpanda_user_id: userId }));
  }

  function createContacts(contacts) {
    return $q
      .all(map(contacts, (contact) => ContactsBackendService.createContact(contact)))
      .then((result) => {
        contactsCache = null;
        subscribers.fireStoreUpdated();
        return result;
      });
  }

  function updateContact(id, data) {
    return ContactsBackendService.updateContact(id, data).then((result) => {
      contactsCache = null;
      subscribers.fireStoreUpdated();
      return result;
    });
  }

  return this;
}
