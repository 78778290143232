import { BamTable } from '@bp/bam';
import { CodeSizes, HBox, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const PreviewHeader = styled.div`
    padding-right: 15px;
`;

export const PreviewSubheader = styled(HBox)`
    color: ${(p) => p.theme.bp_gray_07};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 15px;
`;

export const TableContainer = styled.div`
    width: 100%;
`;

export const PreviewTable = styled(BamTable)`
    padding: 0px 30px;
`;

export const EllipsisTextWrapper = styled(HBox)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const EllipsisText = styled(Text)``;

export const CodeText = styled(Text).attrs({
  size: CodeSizes.SMALL_CODE,
})`
    white-space: normal;
`;
