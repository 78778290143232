import { path } from './constants';

const actionTypes = {
  LOAD_WELCOME_USER_DATA: `${path}.LOAD_WELCOME_USER_DATA`,
  LOAD_WELCOME_USER_DATA_SUCCESS: `${path}.LOAD_WELCOME_USER_DATA_SUCCESS`,
  LOAD_WELCOME_USER_DATA_FAILURE: `${path}.LOAD_WELCOME_USER_DATA_FAILURE`,
  UPDATE_USER_NAME_PASSWORD: `${path}.UPDATE_USER_NAME_PASSWORD`,
  UPDATE_USER_NAME_PASSWORD_SUCCESS: `${path}.UPDATE_USER_NAME_PASSWORD_SUCCESS`,
  UPDATE_USER_NAME_PASSWORD_FAILURE: `${path}.UPDATE_USER_NAME_PASSWORD_FAILURE`,
};

export default actionTypes;
