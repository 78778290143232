import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { showFailureMessage, showSuccessMessage } from 'react/layout/settings/feedback';
import { reqStatusType } from '../constants';
import selectors from '../selectors';

const showNotifcationMessage = (updateRequestStatus) => {
  switch (updateRequestStatus) {
    case reqStatusType.SUCCESS:
      showSuccessMessage('Incident Tag', 'updated');
      break;
    case reqStatusType.FAILURE:
      showFailureMessage('Incident Tag', 'update');
      break;
    case reqStatusType.DELETE_SUCCESS:
      showSuccessMessage('Incident Tag', 'deleted');
      break;
    case reqStatusType.DELETE_FAILURE:
      showFailureMessage('Incident Tag', 'delete');
      break;
    case reqStatusType.CREATE_SUCCESS:
      showSuccessMessage('Incident Tag', 'created');
      break;
    default:
      break;
  }
};
class IncidentLabelsUpdateNotification extends React.Component {
  componentDidMount() {
    if (!isNil(this.props.updateRequestStatus)) {
      showNotifcationMessage(this.props.updateRequestStatus);
    }
  }
  componentDidUpdate() {
    if (!isNil(this.props.updateRequestStatus)) {
      showNotifcationMessage(this.props.updateRequestStatus);
    }
  }
  render() {
    return null;
  }
}

IncidentLabelsUpdateNotification.propTypes = {
  updateRequestStatus: PropTypes.oneOf([...Object.values(reqStatusType)]).isRequired,
};
function mapStateToProps(state) {
  return {
    updateRequestStatus: selectors.getUpdateRequestState(state),
  };
}

export default connect(mapStateToProps, null)(hot(module)(IncidentLabelsUpdateNotification));
