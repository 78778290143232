import find from 'lodash/find';
angular
  .module('bigpanda')
  .controller(
    'IncidentPreviewController',
    (
      $scope,
      $state,
      pubSubService,
      $stateParams,
      $http,
      Config,
      $filter,
      DisplayCommonService,
      SharesUtils,
      $log
    ) => {
      pubSubService.broadcast('currentTitleChanged', 'Preview Incident');
      pubSubService.broadcast('PreviewController.updateUrl', {
        url: `${window.location.origin}/#/app/overview/all/search/incidents/${$stateParams.incidentId}`,
        title: 'Investigate this incident in the BigPanda console',
      });

      $scope.searchText = '';
      $scope.escalationId = $stateParams.escalation;
      $scope.contactId = $stateParams.contact;
      $scope.incidentId = $stateParams.incidentId;

      $state.go(
        $state.$current.toString(),
        { incidentId: $stateParams.incidentId, source: $stateParams.source, t: $stateParams.t },
        { notify: false, location: true, reload: true, inherit: false }
      );

      $scope.unsubscribeEnabled = true;

      const { authorisationOptions, accessType } = $scope;
      if (!authorisationOptions) {
        onError({ status: 401 });
        return;
      }

      $scope.unsubscribeToggle = function () {
        unsubscribe(!$scope.unsubscribed);
      };

      function unsubscribe(unsubscribeValue) {
        $scope.unsubscribeEnabled = false;

        SharesUtils.unsubscribe(
          $scope.incidentId,
          $scope.escalationId,
          $scope.contactId,
          unsubscribeValue,
          authorisationOptions
        ).then(
          () => {
            $scope.unsubscribed = unsubscribeValue;
            $scope.unsubscribeEnabled = true;
          },
          (err) => {
            $log.error(
              `Error while trying to update unsubscribe to ${unsubscribeValue} error: ${err.message}`
            );
            $scope.unsubscribeEnabled = true;
          }
        );
      }

      init();

      function init() {
        if (!$scope.bootstrapPromise) {
          onError({ status: 401 });
          return;
        }

        $scope.bootstrapPromise.then(
          (organization) => {
            pubSubService.broadcast('IncidentPreview.visit', {
              incidentId: $scope.incidentId,
              accessType: accessType,
              source: $stateParams.source,
            });

            return $http
              .get(`/web/incidents/${$scope.incidentId}`, authorisationOptions)
              .then((res) => {
                const incident = res.data.item;

                // TODO We should get the shares from the SharesStore and not directly from the incident
                const shares = incident.escalations;

                $scope.incident = $filter('incidentNormalizer')(incident);
                $scope.unsubscribed = SharesUtils.isUserUnsubscribed(
                  shares,
                  $scope.escalationId,
                  $scope.contactId
                );
                if ($scope.escalationId) {
                  const share = find(shares, { _id: $scope.escalationId });
                  const parentId = share.target_system_id.split('.')[0];
                  var parentTemplates = DisplayCommonService.getTargetSystemsTemplates();
                  $scope.parentShareTemplate = find(parentTemplates, { parentId: parentId });
                }

                return $http
                  .get(`/web/incidents/${$scope.incidentId}/entities`, authorisationOptions)
                  .then((res) => {
                    loadEntitiesAndFormatData({ incident: incident, entities: res.data.entities });
                  });
              });
          },
          (err) => {
            onError(err);
          }
        );
      }

      function loadEntitiesAndFormatData(data) {
        $scope.entities = [];
        $scope.activeCount = 0;

        var doneEntities = [];
        data.entities.forEach((entity) => {
          var newEntity = $filter('entityNormalizer')(entity, data.incident);
          if (
            entity.status.toLowerCase() == 'critical' ||
            entity.status.toLowerCase() == 'warning'
          ) {
            $scope.activeCount++;
            $scope.entities.push(newEntity);
          } else {
            doneEntities.push(newEntity);
          }
        });

        // sort both arrays
        $scope.entities.sort(
          (item1, item2) => item2.lastChangedTimestamp - item1.lastChangedTimestamp
        );

        doneEntities.sort(
          (item1, item2) => item2.lastChangedTimestamp - item1.lastChangedTimestamp
        );

        $scope.entities = $scope.entities.concat(doneEntities);
      }

      function onError(err) {
        $scope.errorState = true;
        if (err && err.status && err.status == 401) {
          $scope.errorMsg = 'The incident page is out of date';
          $scope.errorSubmsg = 'and does no longer exists';
        } else {
          $scope.errorMsg = 'Server Error';
          $scope.errorSubmsg = 'Please refresh to try again';
        }
      }
    }
  );
