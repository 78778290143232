angular.module('bigpanda').controller('TitleController', titleController);

function titleController(
  $scope,
  $window,
  $log,
  pubSubService,
  faviconService,
  FeedModeStore,
  BrowserCompatibilityService
) {
  const title = 'BigPanda';
  const faviconWithDotPath = 'favicon_w_dot.ico';
  const visibility = BrowserCompatibilityService.getVisibilityProperties();
  let feedModeInit = false;
  let feedModeFeatureToggle;
  let liveMode;
  let count = 0;
  let view = '';

  function subscribeFeedMode() {
    feedModeFeatureToggle = FeedModeStore.getToggle();
    if (feedModeFeatureToggle) {
      FeedModeStore.subscribe($scope, {
        storeUpdated: getFeedMode,
      });

      getFeedMode();
    }
  }

  function getFeedMode() {
    FeedModeStore.getFeedMode().then((feedMode) => {
      liveMode = feedMode;
    });
  }

  function updateTitle() {
    const titleText = view + (count === 0 ? '' : ` (${count})`) + (view ? ' - ' : '') + title;
    $window.document.title = titleText;
  }

  function updateFavIcon(counter) {
    try {
      faviconService.badge(counter);
    } catch (e) {
      $log.error(`Error in setting badge icon ${e}`);
    }
  }

  function setFavIconData(event, data) {
    const windowHidden = $window.document[visibility.hidden];
    if (!feedModeInit) {
      subscribeFeedMode();
      $window.document.addEventListener(visibility.visibilityChange, handleVisibilityChange, false);
      feedModeInit = true;
    }
    if (feedModeFeatureToggle && liveMode && windowHidden) {
      try {
        faviconService.image(faviconWithDotPath);
      } catch (e) {
        $log.error(`Error in setting badge image ${e}`);
      }
    } else {
      count = data.length;
      updateFavIcon(count);
    }
    updateTitle();
  }

  function handleVisibilityChange() {
    const windowVisible = !$window.document[visibility.hidden];
    if (feedModeFeatureToggle && liveMode && windowVisible) {
      faviconService.badge('');
    }
  }

  pubSubService.on('IncidentsController.bufferChange', setFavIconData);
  pubSubService.on('title.currentFolderChanged', (event, data) => {
    let folder = data;
    folder = folder.toLowerCase().replace('_', ' ');
    folder = folder.charAt(0).toUpperCase() + folder.slice(1);
    view = folder;
    updateTitle();
  });
  pubSubService.on('currentTitleChanged', (event, data) => {
    count = 0;
    updateFavIcon(count);
    view = data;
    updateTitle();
  });
}
