const serverConfig = require('server-config');

window.BP_CONFIG = {
  webApiBaseUrl: serverConfig.webApiBaseUrl,
  domain: window.location.origin,
  webSocketBaseUrl: serverConfig.webSocketBaseUrl,
};

require('./chunks/frameworks');
require('./chunks/libraries');
require('./bootstrap.less');
require('./index.styl');

const modulesContext = require.context('./angularjs/modules', true, /^\.\/.*(\.js)$/);

require('./angularjs/modules/config.module.js');
require('./angularjs/modules/utils.module.js');
require('./angularjs/modules/backend.services.module.js');
require('./angularjs/modules/reports.module.js');
require('./angularjs/modules/widgets.module.js');
require('./angularjs/modules/app.module.js');
require('./angularjs/modules/bp.libraries.module.js');
require('./heap.js');

// Files under each subdirectory (skipping index.js and libs.js)
requireAll(require.context('../assets/img', true, /\.(jpe?g|svg|gif|png)$/));
requireAll(require.context('.', true, /^\.\/angularjs\/.*\/.*\.js$/), modulesContext);
requireAll(require.context('.', true, /^\.\/angularjs\/.*\/.*\.template\.pug$/));

function requireAll(requireContext, excludedContext) {
  const excludedKeys = (excludedContext && excludedContext.keys()) || [];
  return requireContext.keys().reduce((all, current) => {
    if (excludedKeys.indexOf(current) < 0) {
      all.push(requireContext(current));
    }
    return all;
  }, []);
}
