import personalSettingsActionTypes from 'react/modules/settings/personal_settings/actionTypes';
import actionTypes from './actionTypes';

export const initialState = {
  user: undefined,
  customizations: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_USER_SUCCESS:
      return {
        user: action.payload,
      };

    case personalSettingsActionTypes.UPDATE_USER_SUCCESS:
      return {
        user: action.payload,
      };

    case actionTypes.LOAD_CUSTOMIZATIONS_SUCCESS:
      return {
        ...state,
        customizations: action.payload,
      };

    default:
      return state;
  }
}
