import { path } from '../../../../app/react/modules/settings/alert_enrichment/constants';

const actionTypes = {
  CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS: `${path}.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS`,
  CREATE_ALERT_TAG: `${path}.CREATE_ALERT_TAG`,
  UPDATE_ALERT_TAG: `${path}.UPDATE_ALERT_TAG`,
  UPDATE_FILTER_TEXT: `${path}.UPDATE_FILTER_TEXT`,
  LOAD_ENRICHMENT_TAGS: `${path}.LOAD_ENRICHMENT_TAGS`,
  LOAD_ENRICHMENT_TAGS_SUCCESS: `${path}.LOAD_ENRICHMENT_TAGS_SUCCESS`,
  LOAD_TAGS: `${path}.LOAD_TAGS`,
  LOAD_TAGS_SUCCESS: `${path}.LOAD_TAGS_SUCCESS`,
  UPDATE_FILTERS_DROPDOWNS: `${path}.UPDATE_FILTERS_DROPDOWNS`,
  UPDATE_SORT: `${path}.UPDATE_SORT`,
  REMOVE_ALERT_TAG: `${path}.REMOVE_ALERT_TAG`,
  UPDATE_RULES_ORDER: `${path}.UPDATE_RULES_ORDER`,
  UPDATE_ENRICHMENT_TAGS_ORDER: `${path}.UPDATE_ENRICHMENT_TAGS_ORDER`,
  SET_DRAGGED_ITEMS: `${path}.SET_DRAGGED_ITEMS`,
  LOAD_PREVIEW_ALERTS: `${path}.LOAD_PREVIEW_ALERTS`,
  LOAD_PREVIEW_ALERTS_SUCCESS: `${path}.LOAD_PREVIEW_ALERTS_SUCCESS`,
  LOAD_PREVIEW_ALERTS_FAILURE: `${path}.LOAD_PREVIEW_ALERTS_FAILURE`,
  CLEAR_PREVIEW_ALERTS: `${path}.CLEAR_PREVIEW_ALERTS`,
  SET_CURRENT_TAG: `${path}.SET_CURRENT_TAG`,
  UPDATE_ALERT_TAG_METADATA: `${path}.UPDATE_ALERT_TAG_METADTA`,
  LOAD_INTERNAL_TAGS: `${path}.LOAD_INTERNAL_TAGS`,
  LOAD_RESERVED_TAGS_SUCCESS: `${path}.LOAD_RESERVED_TAGS_SUCCESS`,
};

export default actionTypes;
