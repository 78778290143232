import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import { BamModal } from '@bp/bam';
import { loadEnrichedUsers } from 'react/common/enriched_users/actions';
import usersSelectors from 'react/common/enriched_users/selectors';
import { selectors as integrationsSelectors } from 'react/common/integrations';
import { rulePropType, environmentPropType, userPropType, systemPropType } from '../propTypes';
import AutoShareRuleForm from './AutoShareRuleForm';
import actions from '../actions';
import envActions from '../../roles_management/roles_list/actions';
import envSelectors from '../../roles_management/roles_list/selectors';

class AutoShareRuleModal extends React.Component {
  componentDidMount() {
    const { environments, loadEnvironments, loadEnrichedUsers } = this.props;

    if (!environments) {
      loadEnvironments();
    }
    loadEnrichedUsers();
  }

  onMountModal = () => {
    if (!this.props.environments) {
      this.props.loadEnvironments();
    }
  };

  handleClose = () => {
    this.modalRef.close();
  };

  render() {
    const {
      titleIcon,
      title,
      triggerProps,
      rule,
      environments,
      integrations,
      updateRule,
      createRule,
      users,
    } = this.props;

    return (
      <BamModal
        ref={(r) => {
          this.modalRef = r;
        }}
        trigger={<ModalTriggerButton {...triggerProps} />}
        titleIcon={titleIcon}
        title={title}
        docsLink="https://docs.bigpanda.io/docs/sharing#autosharing-and-manual-sharing"
        onMount={this.onMountModal}
        formMode
        autoFocus
        style={{ width: 450 }}
      >
        <AutoShareRuleForm
          updateRule={updateRule}
          createRule={createRule}
          environments={environments}
          integrations={integrations}
          users={users}
          rule={rule}
          close={this.handleClose}
        />
      </BamModal>
    );
  }
}

AutoShareRuleModal.propTypes = {
  titleIcon: PropTypes.string.isRequired,
  updateRule: PropTypes.func.isRequired,
  createRule: PropTypes.func.isRequired,
  loadEnvironments: PropTypes.func.isRequired,
  loadEnrichedUsers: PropTypes.func.isRequired,
  rule: rulePropType,
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  environments: PropTypes.arrayOf(environmentPropType),
  title: PropTypes.string,
  users: PropTypes.arrayOf(userPropType),
  integrations: PropTypes.arrayOf(systemPropType),
};

AutoShareRuleModal.defaultProps = {
  rule: undefined,
  title: '',
  environments: undefined,
  integrations: [],
  users: [],
};

function mapStateToProps(state) {
  return {
    environments: envSelectors.getEnvironments(state),
    integrations: integrationsSelectors.getIntegrations(state),
    users: usersSelectors.getEnrichedUsers(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadEnvironments: () => dispatch(envActions.loadEnvironments()),
    loadEnrichedUsers: () => dispatch(loadEnrichedUsers()),
    updateRule: (rule) => dispatch(actions.updateAutoShareRule(rule)),
    createRule: (rule) => dispatch(actions.createAutoShareRule(rule)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(AutoShareRuleModal));
