import filter from 'lodash/filter';
import extend from 'lodash/extend';
const correlationEditDefaults = {
  templateUrl: 'settings/correlation/correlation_rules/popup/correlation_rule_popup',
  controller: 'CorrelationRulePopupCtrl',
  controllerAs: 'popupVm',
  fullscreen: true,
  clickOutsideToClose: false,
  escapeToClose: false,
  onShowing(scope, element) {
    element.addClass('correlation-rule-popup');
  },
};

function onEnterNew(
  ModalService,
  UserIntegrationsStore,
  UserIntegrationsUtils,
  CorrelationConfigService
) {
  const options = extend({}, correlationEditDefaults, {
    resolve: {
      resolvedConfigRules() {
        return CorrelationConfigService.getConfig().then((config) => config.matching_rules);
      },
      resolvedCorrelation() {
        return null;
      },
      resolvedIntegrations() {
        return UserIntegrationsStore.getIntegrations().then((integrations) =>
          UserIntegrationsUtils.getIntegrationsForDisplay(filter(integrations, { type: 'alert' }))
        );
      },
    },
  });

  CorrelationConfigService.checkForConfigChanges().then(() => ModalService.showModal(options));
}

function onEnterEdit(
  $stateParams,
  ModalService,
  UserIntegrationsStore,
  UserIntegrationsUtils,
  CorrelationRulesService,
  CorrelationConfigService,
  CorrelationActions
) {
  const options = extend({}, correlationEditDefaults, {
    resolve: {
      resolvedConfigRules() {
        return CorrelationConfigService.getConfig().then((config) => config.matching_rules);
      },
      resolvedCorrelation() {
        return CorrelationRulesService.getCorrelationRuleById($stateParams.correlationId);
      },
      resolvedIntegrations() {
        return UserIntegrationsStore.getIntegrations().then((integrations) =>
          UserIntegrationsUtils.getIntegrationsForDisplay(filter(integrations, { type: 'alert' }))
        );
      },
    },
  });

  return CorrelationConfigService.checkForConfigChanges().then(() =>
    CorrelationRulesService.getCorrelationRuleById($stateParams.correlationId).then(
      (correlationRule) => {
        if (correlationRule) {
          ModalService.showModal(options);
        } else {
          CorrelationActions.itemDeletedNotification(
            'The correlation pattern has been deleted by another user in your organisation',
            'correlations'
          );
        }
      }
    )
  );
}

module.exports = { onEnterNew, onEnterEdit };
