import { react2angular } from 'react2angular';
import IncidentViewHeader from '../../../../../workspaces/apps/incident-feed/src/components/incident-view/header/Header';

angular
  .module('bigpanda')
  .component(
    'incidentViewHeader',
    react2angular(IncidentViewHeader, [
      'store',
      'breakdownSources',
      'incident',
      'priorityProps',
      'openTimeline',
      'titleData',
      'showSmallButton',
      'selectedPriorityId',
      'showPriorityDd',
      'toggleFullScreen',
    ])
  );
