angular.module('bigpanda').directive('environmentGroup', EnvironmentGroup);

function EnvironmentGroup() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      baseGroup: '=group',
      allGroups: '<',
      allEnvironments: '<',
      favoriteEnvironmentIds: '<',
      selectedGroupId: '<',
      selectedEnvironmentId: '<',
      filterMode: '<',
      groupEnvironmentClicked: '&',
      refreshGroups: '&',
    },
    templateUrl: 'overview/environments/groups/single/environment_group',
    controller: 'EnvironmentGroupCtrl',
    controllerAs: 'vm',
    bindToController: true,
  };
}
