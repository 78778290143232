import { BamNotificationStatusOptions, bamNotify } from '@bp/bam';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import selectors from '../../../../../app/react/modules/settings/alert_enrichment/selectors';
import { Tag } from '../../types';
import { MAX_NUM_OF_RULES, MAX_NUM_OF_TAGS } from '../constants/limits';
import texts from '../dictionary';
import { getInterpolatedText } from '../helpers/get-interpolated-text';

interface Args {
  tag: Tag;
}

interface HookRes {
  shouldOpenTagDuplicationModal: () => boolean
}

export function useDuplicateTagButton({ tag }: Args): HookRes {
  const totalNumOfTags = useSelector(selectors.getTotalTags);
  const totalNumOfRules = useSelector(selectors.getTotalRules);
  const totalNumOfTagsWarningMessage = useMemo(
    () => getInterpolatedText(texts.create_tag_modal__maximum_num_of_tags_warning_message, {
      totalNumOfTags: MAX_NUM_OF_TAGS.toLocaleString(),
    }),
    [],
  );

  const totalNumOfRulesWarningMessage = useMemo(
    () => getInterpolatedText(texts.edit_tag_modal__maximum_num_of_rules_warning_message, {
      totalNumOfRules: MAX_NUM_OF_RULES.toLocaleString(),
    }),
    [],
  );

  const shouldOpenTagDuplicationModal = (): boolean => {
    const hasPassedTagsLimit = totalNumOfTags + 1 > MAX_NUM_OF_TAGS;
    if (hasPassedTagsLimit) {
      bamNotify({
        extraWidth: true,
        title: 'Failed to create new Alert tag',
        status: BamNotificationStatusOptions.FAILURE,
        message: totalNumOfTagsWarningMessage,
      });

      return false;
    }

    const hasPassedRulesLimit = totalNumOfRules + tag.enrichments.length > MAX_NUM_OF_RULES;
    if (hasPassedRulesLimit) {
      bamNotify({
        extraWidth: true,
        title: 'Failed to create new Alert tag',
        status: BamNotificationStatusOptions.FAILURE,
        message: totalNumOfRulesWarningMessage,
      });

      return false;
    }

    return true;
  };

  return {
    shouldOpenTagDuplicationModal,
  };
}
