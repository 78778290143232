import { Text, UserIcon } from '@bp/kung-fu';
import styled from 'styled-components';

export const AnyUserIcon = styled(UserIcon)`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.bp_white};
  border: 1px solid ${(p) => p.theme.bp_gray_03};
`;

export const UserNameText = styled(Text)`
  color: ${(p) => p.theme.bp_gray_06};
  :before {
    content: '(';
  }
  
  :after {
    content: ')';
  }
`;
