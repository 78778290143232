angular.module('bigpanda').directive('dashboardEnvironmentSeverity', dashboardEnvironmentSeverity);

function dashboardEnvironmentSeverity() {
  return {
    scope: {
      widget: '<',
    },
    templateUrl: 'dashboard/dashboard_environment_severity',
    restrict: 'E',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller() {}
}
