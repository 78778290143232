angular.module('bigpanda').directive('incidentHeader', incidentHeader);

function incidentHeader() {
  return {
    restrict: 'E',
    scope: {
      incident: '<',
      entitiesCount: '@',
      buttons: '=',
      includeAssign: '@',
    },
    templateUrl: 'overview/incidents/header/incident_header',
    bindToController: true,
    controllerAs: 'headerVm',
    controller: controller,
  };

  function controller(IncidentTimestampService, SORTING) {
    const vm = this;
    vm.$onInit = init;
    vm.$onChanges = updateTimeProps;

    function setTimeProperties(incident) {
      IncidentTimestampService.getIncidentTimestamp(incident).then((timeProps) => {
        vm.timeProps = timeProps;

        if (timeProps.sortBy === SORTING.options.start) {
          vm.timestamp = vm.incident[SORTING.options.start];
        } else {
          vm.timestamp = vm.incident[SORTING.options.lastChange];
        }
      });
    }

    function init() {
      if (vm.incident) {
        setTimeProperties(vm.incident);
      }
    }

    function updateTimeProps(changesObj) {
      if (changesObj.incident) {
        setTimeProperties(changesObj.incident.currentValue);
      }
    }
  }
}
