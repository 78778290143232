import {
  AllSizes, HBox, SCHBox, Text,
} from '@bp/kung-fu';
import styled from 'styled-components';

export const Container = styled(SCHBox)`
    line-height: 20px;
    margin-bottom: 10px;
`;

const BaseText = styled(Text).attrs({
  size: AllSizes.SMALL,
})`
    color: ${(p) => p.theme.bp_gray_06};
`;

export const Title = styled(BaseText)`
    color: ${(p) => p.theme.bp_gray_07};
    font-weight: bold;
`;

export const Subtitle = BaseText;

export const BulletContainer = styled(HBox)`
    color: ${(p) => p.theme.bp_gray_04};
    margin: 0 8px;
`;

export const Counter = styled(BaseText)`
    margin-inline-start: 5px;
`;
