const config = require('common/config');

const actionTypes = {};

actionTypes.ENVIRONMENT_WEBSOCKETS_MESSAGE = `${config.webSocketsPrefix}::MESSAGE`;
actionTypes.ENVIRONMENT_WEBSOCKETS_CONNECTED = `${config.webSocketsPrefix}::OPEN`;
actionTypes.ENVIRONMENT_WEBSOCKETS_CONNECTING = `${config.webSocketsPrefix}::CONNECT`;
actionTypes.ENVIRONMENT_WEBSOCKETS_DISCONNECTED = `${config.webSocketsPrefix}::DISCONNECT`;
actionTypes.ENVIRONMENT_WEBSOCKETS_CLOSED = `${config.webSocketsPrefix}::CLOSED`;
actionTypes.ENVIRONMENT_WEBSOCKETS_BROKEN = `${config.webSocketsPrefix}::BROKEN`;
actionTypes.ENVIRONMENT_WEBSOCKETS_BEGIN_RECONNECT = `${config.webSocketsPrefix}::BEGIN_RECONNECT`;
actionTypes.ENVIRONMENT_WEBSOCKETS_RECONNECT_ATTEMPT = `${config.webSocketsPrefix}::RECONNECT_ATTEMPT`;
actionTypes.ENVIRONMENT_WEBSOCKETS_RECONNECTED = `${config.webSocketsPrefix}::RECONNECTED`;
actionTypes.ENVIRONMENT_WEBSOCKETS_ERROR = `${config.webSocketsPrefix}::ERROR`;

export default actionTypes;
