import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import isArray from 'lodash/isArray';
import extend from 'lodash/extend';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';

angular.module('bigpanda').service('CorrelationRulePopup', CorrelationRulePopup);

function CorrelationRulePopup(
  $q,
  BPQLService,
  CorrelationConfigService,
  sourceBreakdownService,
  EntityTitleService,
  QuerySourceGenerator,
  BpqlUtils,
  CorrelationActions,
  deepCloneObject
) {
  this.convertToCorrelationRuleVm = convertToCorrelationRuleVm;
  this.convertFromCorrelationRuleVm = convertFromCorrelationRuleVm;
  this.getConfigWithNoRules = getConfigWithNoRules;
  this.makePreviewViewModel = makePreviewViewModel;

  function convertToCorrelationRuleVm(rule) {
    const description = { description: '' };
    const defaultRule = {
      sourceSystems: [],
      window: 7200,
      tags: [],
      pureQuery: {},
      active: true,
      metadata: description,
      cross_source: false,
    };

    const correlationRule = deepCloneObject.cloneDeep(rule || defaultRule);
    const hasQuery = !isEmpty(correlationRule.pureQuery);
    const metadata = correlationRule.metadata || description;
    const hasDescription = metadata && !!metadata.description;
    const query = hasQuery ? BPQLService.reverse(correlationRule.pureQuery) : '';
    const tags = map(correlationRule.tags, mapTagsToVmTags);

    return {
      crossSource: correlationRule.cross_source,
      integrations: correlationRule.sourceSystems,
      window: correlationRule.window / 60,
      id: correlationRule.id,
      active: correlationRule.active,
      metadata,
      hasDescription,
      hasQuery,
      tags,
      query,
    };
  }

  function convertFromCorrelationRuleVm(correlationRuleVm, options = {}) {
    const query = BpqlUtils.getBpqlQuery(correlationRuleVm.query);
    const correlationRule = {
      id: options.edit ? correlationRuleVm.id : undefined,
      tags: mapTagsFromVmTags(correlationRuleVm.tags),
      active: options.forceActive || correlationRuleVm.active,
      window: correlationRuleVm.window * 60,
      query: QuerySourceGenerator.generateQuery(query, correlationRuleVm.integrations),
      cross_source: correlationRuleVm.crossSource,
      metadata: correlationRuleVm.metadata,
    };

    if (!options.isPreview) {
      const keyParams = ['query', 'tags', 'window'];
      return CorrelationActions.onlyIfUnique(correlationRule, options.correlationRules, keyParams);
    }
    return correlationRule;
  }

  function getConfigWithNoRules() {
    return CorrelationConfigService.getConfig().then((originalConfig) => {
      const config = deepCloneObject.cloneDeep(originalConfig);
      config.matching_rules = [];
      return config;
    });
  }

  function makePreviewViewModel(previewResult) {
    return $q.when({
      incidents: buildIncidentsViewModel(previewResult.incidents),
      totalEntities: getNumberOfEntities(previewResult),
      totalIncidents: previewResult.incidents.length,
      compressionRate: calculateCompressionRate(previewResult),
      maxCorrelations: previewResult.correlations,
      progress: calculateProgressObject(previewResult),
    });
  }

  function getNumberOfEntities(previewResult) {
    return reduce(previewResult.incidents, (sum, incident) => sum + incident.entities.length, 0);
  }

  function calculateCompressionRate(previewResult) {
    const entities = getNumberOfEntities(previewResult);
    if (entities) {
      return Math.round((1 - previewResult.incidents.length / entities) * 100);
    }
    return 0;
  }

  function calculateProgressObject(previewResult) {
    const totalTimespan = moment.duration(
      previewResult.end_time - previewResult.start_time,
      'seconds'
    );
    let doneTimespan = moment.duration(
      previewResult.cur_time - previewResult.start_time,
      'seconds'
    );

    if (previewResult.status === 'done') {
      doneTimespan = totalTimespan;
    }

    const result = {
      goalInSeconds: totalTimespan.asSeconds(),
      doneInSeconds: doneTimespan.asSeconds(),
    };

    if (totalTimespan.asHours() <= 24) {
      extend(result, {
        goal: totalTimespan.asHours(),
        done: doneTimespan.asHours(),
        scale: 'hours',
      });
    } else {
      extend(result, {
        goal: Math.ceil(totalTimespan.asDays()),
        done: Math.ceil(doneTimespan.asDays()),
        scale: 'days',
      });
    }

    return result;
  }

  function buildIncidentsViewModel(incidents) {
    return map(incidents, (incident) => ({
      tags: getIncidentTags(incident),
      entities: buildEntitiesViewModel(incident.entities),
    }));
  }

  function getIncidentTags(incident) {
    const tags =
      incident.matchers_log[0] &&
      incident.matchers_log[0].matchers[0] &&
      incident.matchers_log[0].matchers[0].tags
        ? incident.matchers_log[0].matchers[0].tags
        : [];
    let finalTags;
    if (isArray(tags)) {
      finalTags = map(tags, (tag) => ({
        name: tag.type,
        value: tag.value,
      }));
    } else {
      finalTags = map(keys(tags), (tag) => ({
        name: tag,
        value: incident.matchers_log[0].matchers[0].tags[tag],
      }));
    }
    return sortBy(finalTags, 'name');
  }

  function buildEntitiesViewModel(entities) {
    const crossSource = keys(groupBy(entities, 'source_system')).length > 1;

    let mainProps;
    if (!crossSource) {
      mainProps = EntityTitleService.getMainProperties(entities);
    }

    return sortBy(
      map(entities, (entity) => {
        if (crossSource) {
          mainProps = EntityTitleService.getMainProperties([entity]);
        }
        return {
          primary: getPrimaryName(entity, mainProps),
          secondary: getSecondaryName(entity, mainProps),
          timestamp: entity.start * 1000,
          source_system: entity.source_system,
          source: crossSource ? sourceBreakdownService.findSystemName(entity.source_system) : null,
        };
      }),
      'timestamp'
    );
  }

  function getPrimaryName(entity, mainProps) {
    if (!mainProps || !mainProps.primary || !mainProps.primary.length) {
      return null;
    }

    return getValueIfDefined(find(entity.tags, { type: mainProps.primary[0].name }));
  }

  function getSecondaryName(entity, mainProps) {
    if (!mainProps || !mainProps.secondary || !mainProps.secondary.length) {
      return null;
    }

    return getValueIfDefined(find(entity.tags, { type: mainProps.secondary[0].name }));
  }

  function getValueIfDefined(tagObject) {
    return tagObject && tagObject.value;
  }

  function mapTagsFromVmTags(tags) {
    return map(tags, (tag) => {
      if (tag.label.indexOf('@') === 0) {
        return { indirect: tag.label.substring(1) };
      }
      return tag.label;
    });
  }

  function mapTagsToVmTags(tag) {
    tag = tag.indirect ? `@${tag.indirect}` : tag;
    return { label: tag };
  }
}
