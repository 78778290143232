import { react2angular } from 'react2angular';
import FilterDetailsPane from '../../../../../../workspaces/apps/alert-filtering/src/filter-info/components/FilterDetailsPane';

const routes = {};

routes.appSettingsAlertFilteringInfo = {
  url: '/:filterId',
  views: {
    'master-detail-info@app.settings.alert_filtering': {
      template:
        '<filter-details-pane class="react-settings-info" query-client="queryClient" filter-id="stateParams.filterId" store="store" />',
      controller: (
        $scope,
        $ngRedux,
        $stateParams,
        UserFeatureTogglesService,
        QueryClientService
      ) => {
        'ngInject';

        if (UserFeatureTogglesService.getToggle('frontier_disable_alert_filtering')) {
          window.location.href = '/#';
        }

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
        $scope.queryClient = QueryClientService.getQueryClient();
      },
    },
  },
};

angular
  .module('bigpanda')
  .component(
    'filterDetailsPane',
    react2angular(FilterDetailsPane, ['filterId', 'store', 'queryClient'])
  );
export default routes;
