import actionTypes from './actionTypes';

export function createMerge(payload) {
  return { type: actionTypes.CREATE_MERGE, payload: payload };
}

export function createMergeSuccess(payload) {
  return { type: actionTypes.CREATE_MERGE_SUCCESS, payload: payload };
}

export function createSplit(payload) {
  return { type: actionTypes.CREATE_SPLIT, payload: payload };
}

export function createSplitSuccess(payload) {
  return { type: actionTypes.CREATE_SPLIT_SUCCESS, payload: payload };
}

export function createEntitiesBatchResolve(payload) {
  return { type: actionTypes.CREATE_ENTITIES_BATCH_RESOLVE, payload: payload };
}

export function createEntitiesBatchResolveSuccess(payload) {
  return { type: actionTypes.CREATE_ENTITIES_BATCH_RESOLVE_SUCCESS, payload: payload };
}
