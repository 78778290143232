import { BamBpqlInput } from '@bp/bam';

const mandatoryField = (fieldName) => (val) => (!val ? `${fieldName} required` : undefined);

const validateSourceSystems = (val) => (!val || !val.length ? 'Source system required' : undefined);

const validateSource = (val) => (!val ? 'Base tag required' : undefined);

const validateDisplayQuery = (val) =>
  val ? BamBpqlInput.helpers.BpqlInputValidator(val) : undefined;

const hasRegexValidationError = (val) => {
  if (!val) {
    return 'Regex required';
  }
  try {
    const regex = RegExp(val);
    const groupCount = new RegExp(`${regex.toString()}|`).exec('').length - 1;
    if (groupCount < 1) {
      return 'Use exactly one Regex group';
    }
    return undefined;
  } catch (e) {
    return e.message;
  }
};

export {
  mandatoryField,
  validateSourceSystems,
  validateSource,
  hasRegexValidationError,
  validateDisplayQuery,
};
