import negate from 'lodash/negate';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Tooltip, darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import { BamDropDown, BamDropDownCustomTrigger } from '@bp/bam';
import styles from './filters_drop_down.scss';

const TOOLTIP_DELAY = 800;

export const DropdownOptionType = PropTypes.shape({
  value: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.shape({
    component: PropTypes.node.isRequired,
    position: PropTypes.string,
  }),
});

const FiltersDropdown = ({
  data,
  selectedValue,
  selectedText,
  defaultText,
  disabled,
  onChange,
  anyOptionValue,
  shouldHighlight,
  'aria-label': ariaLabel,
}) => {
  const isAnyValue = (v) => v === anyOptionValue;
  const isNotAnyValue = negate(isAnyValue);
  const label = isAnyValue(selectedValue) ? defaultText : selectedText;
  const options = data.map((item) => ({ ...item, tooltip: item.text }));

  return (
    <ThemeProvider theme={darkTheme}>
      <BamDropDown
        className={styles['dropdown-override']}
        options={options}
        selected={selectedValue}
        maxHeight={290}
        trigger={({ isMenuOpen }) => (
          <Tooltip placement="bottom" text={label} isActive={!isMenuOpen}>
            <BamDropDownCustomTrigger
              shouldHightlight={
                shouldHighlight === undefined ? isNotAnyValue(selectedValue) : shouldHighlight
              }
              text={label}
            />
          </Tooltip>
        )}
        tooltipMouseEnterDelay={TOOLTIP_DELAY}
        disabled={disabled}
        aria-label={ariaLabel || (isAnyValue(selectedValue) ? defaultText : selectedText)}
        onChange={onChange}
      />
    </ThemeProvider>
  );
};
FiltersDropdown.propTypes = {
  data: PropTypes.arrayOf(DropdownOptionType).isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedText: PropTypes.string.isRequired,
  defaultText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  anyOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  shouldHighlight: PropTypes.bool,
  'aria-label': PropTypes.string,
};

FiltersDropdown.defaultProps = {
  disabled: false,
  shouldHighlight: undefined,
  'aria-label': undefined,
};

export default FiltersDropdown;
