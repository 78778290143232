import actionTypes from './actionTypes';
import { generateBaseSettingsActions } from '../redux.utils';

const actions = generateBaseSettingsActions(actionTypes);

actions.loadCustomTags = () => ({ type: actionTypes.LOAD_CUSTOM_TAGS });
actions.loadCustomTagsSuccess = (payload) => ({
  type: actionTypes.LOAD_CUSTOM_TAGS_SUCCESS,
  payload: payload,
});
actions.addCustomTag = (payload) => ({ type: actionTypes.ADD_CUSTOM_TAG, payload: payload });
actions.updateCustomTag = (payload) => ({ type: actionTypes.UPDATE_CUSTOM_TAG, payload: payload });
actions.deleteCustomTag = (payload) => ({ type: actionTypes.DELETE_CUSTOM_TAG, payload: payload });

export default actions;
