angular.module('bigpanda.reports').controller('ReportSettingsDialogController', function ($scope) {
  $scope.data = this.data;
  $scope.dialogTitle = this.dialogTitle;
  $scope.submitButtonCaption = this.submitButtonCaption;
  $scope.cancelButtonCaption = this.cancelButtonCaption;
  $scope.titleIconClass = this.titleIconClass;
  $scope.eventPrefix = this.eventPrefix;
  $scope.dialogType = this.dialogType;

  $scope.forceShowDescription = false;

  $scope.shouldShowDescription = shouldShowDescription;
  $scope.showDescription = showDescription;

  function showDescription() {
    $scope.forceShowDescription = true;
  }

  function shouldShowDescription() {
    if ($scope.forceShowDescription) {
      return true;
    }

    return $scope.data.description && $scope.data.description.length > 0;
  }
});
