export const bigPandaUserName = 'Change Correlation Algorithm';
export const bpUserImg = '/assets/img/BP_USER.png';
export const DATE_TIME_FORMAT = 'MMM D, YYYY HH:mm';
export const DATE_FORMAT_THIS_YEAR = 'MMM D HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const LAST_UI_STATE = 'LAST_UI_STATE';
export const SYSTEM_GENERATED_USER_ID = 'system-generated';
export const ssoLoginUrls = {
  wellsfargo_sandbox:
    'https://wellsfargo-uat.okta.com/app/wellsfargo-uat_bigpanda_1/exk8vsbjmlTM991Ii297/sso/saml',
};

export const AI_INCIDENT_SUMMARY = 'itd_ai_summary_1';
export const AI_INCIDENT_TITLE = 'itd_ai_title_1';
export const AI_INCIDENT_RCA = 'itd_ai_root_cause_1';
export const AI_INCIDENT_REASONING = 'itd_ai_reasoning_1';
export const AI_TAGS = [
  AI_INCIDENT_SUMMARY,
  AI_INCIDENT_TITLE,
  AI_INCIDENT_RCA,
  AI_INCIDENT_REASONING,
];
