import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Button, DeleteIcon } from '@bp/kung-fu';
import { BamActionableTooltip } from '@bp/bam';
import { Ref } from 'semantic-ui-react';
import styles from './DeleteButtonWithTooltip.scss';

class DeleteButtonWithTooltip extends React.PureComponent {
  state = {
    isTooltipOpen: false,
  };

  forwardedRef = React.createRef();

  focusCancelButton = () => {
    this.forwardedRef.current.focus();
  };

  handleDelete = () => {
    const { onDelete } = this.props;
    this.toggleTooltip(false);
    onDelete();
  };

  toggleTooltip(value) {
    const { hoverOut } = this.props;
    if (!value && hoverOut) {
      hoverOut();
    }
    this.setState({ isTooltipOpen: value });
  }

  render() {
    const { isTooltipOpen } = this.state;
    const { disabled, className, customTooltipText } = this.props;

    return (
      <BamActionableTooltip
        trigger={
          <div className={cx(styles['delete-button'], className)}>
            <Ref innerRef={this.forwardedRef}>
              <Button
                icon={<DeleteIcon />}
                variant="action-container"
                size="medium"
                ariaLabel="delete"
                disabled={disabled}
                onClick={(event) => {
                  event.preventDefault();
                  this.toggleTooltip(true);
                }}
                tooltipProps={
                  customTooltipText && {
                    isActive: true,
                    text: customTooltipText,
                  }
                }
              />
            </Ref>
          </div>
        }
        header="Are you sure you want to delete this item?"
        action={this.handleDelete}
        actionText="Delete"
        cancel={() => {
          this.toggleTooltip(false);
          this.focusCancelButton();
        }}
        open={isTooltipOpen}
        position="bottom right"
      />
    );
  }
}

DeleteButtonWithTooltip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  hoverOut: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  customTooltipText: PropTypes.string,
};

export default DeleteButtonWithTooltip;
