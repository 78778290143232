const { react2angular } = require('react2angular');
const IncidentTopology = require('react/modules/topology/IncidentTopology').default;

angular
  .module('bigpanda')
  .component('reactIncidentTopology', react2angular(IncidentTopology, ['incident', 'store']));

angular.module('bigpanda').directive('incidentTopology', incidentTopology);

function incidentTopology() {
  return {
    restrict: 'E',
    scope: {
      incident: '=',
      entities: '=',
    },
    templateUrl: 'overview/incidents/info/incident_topology/react_incident_topology',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($scope, $state, IncidentsService, stateService, pubSubService, $ngRedux) {
    const vm = this;

    vm.$onInit = () => {
      vm.fullIncident = { ...vm.incident, entities: vm.entities };
    };

    pubSubService.on(
      'entitiesList.newEntities',
      (event, newEntities) => {
        if (newEntities) {
          vm.fullIncident = { ...vm.incident, entities: newEntities };
        }
      },
      $scope
    );

    vm.store = $ngRedux;
  }
}
