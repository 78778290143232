import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import snakeCase from 'lodash/snakeCase';
import bpFetch from 'common/utils/http/fetcher';

export function getChanges(queryParams) {
  let queries;
  if (queryParams.nextLink) {
    const fullUrl = queryParams.nextLink.match(/^<(.*)>/)[1];
    queries = fullUrl.substring(fullUrl.indexOf('?') + 1);
  } else {
    const paramsWithDefaults = { limit: 50, ...queryParams };
    queries = Object.keys(paramsWithDefaults)
      .reduce((acc, key) => {
        const snakeKey = snakeCase(key);
        return (
          (paramsWithDefaults[key] && [...acc, `${snakeKey}=${paramsWithDefaults[key]}`]) || acc
        );
      }, [])
      .join('&');
  }

  const url = `/resources/v2.0/changes?${queries}`;
  return bpFetch({ url }).then((res) => ({ nextLink: res.headers.link, ...res.data }));
}

export function getRelatedChanges({ incidentId, nextLink, ...restParams }) {
  let queries;
  if (nextLink) {
    const fullUrl = nextLink.match(/^<(.*)>/)[1];
    queries = fullUrl.substring(fullUrl.indexOf('?') + 1);
  } else {
    const paramsWithDefaults = {
      limit: 50,
      ...restParams,
      include: 'change',
      incident_id: incidentId,
      includeRichComment: true,
    };
    const urlParamObject = new URLSearchParams(paramsWithDefaults);
    queries = urlParamObject.toString();
  }
  const url = `/resources/v2.0/rcc?${queries}`;
  return bpFetch({ url }).then((res) => ({ nextLinkHeader: res.headers.link, ...res.data }));
}

export function getSearchRelatedChanges({ search, nextLink, incidentId, ...restParams }) {
  let queries;
  const bpql64 = search ? btoa(JSON.stringify(search)) : null;
  if (nextLink) {
    const fullUrl = nextLink.match(/^<(.*)>/)[1];
    queries = fullUrl.substring(fullUrl.indexOf('?') + 1);
  } else {
    const paramsWithDefaults = {
      limit: 50,
      ...restParams,
      bpql_b64: bpql64,
      incident_id: incidentId,
      includeRichComment: true,
    };
    const urlParamObject = new URLSearchParams(omitBy(paramsWithDefaults, isNil));
    queries = urlParamObject.toString();
  }

  const url = `/resources/v2.0/changes/search?${queries}`;
  return bpFetch({ url }).then((res) => ({ nextLink: res.headers.link, ...res.data }));
}

export function upsertChangeMetadata(changeId, data) {
  const url = `/resources/v2.0/changes/${changeId}`;
  return bpFetch({ url, method: 'PUT', data });
}
export default {
  getChanges,
  getRelatedChanges,
  upsertChangeMetadata,
};
