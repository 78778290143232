import React from 'react';
import { Tooltip, HBox } from '@bp/kung-fu';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styles from './IncidentTagsGridMultiValueEditor.scss';
import style from './CreatableSelectStyle';

class IncidentTagsGridMultiValueEditor extends React.Component {
  state = {
    isMenuOpen: false,
  };

  onSelectValueChange = (creatableSelectValues) => {
    const { onChange } = this.props;
    const values = creatableSelectValues || [];
    onChange(values);
  };

  onInputChange = (input) => {
    const { onValidationChange, error, validate } = this.props;
    const currentValidationMessage = validate(input);
    this.setState({ isMenuOpen: input && !currentValidationMessage && input.length });
    if (error !== currentValidationMessage) {
      onValidationChange(currentValidationMessage);
    }
  };

  isValidNewOption = (inputValue) => !!inputValue.trim().length;

  MultiValueLabelComponent = (props) => (
    <div className={styles.multiValueLabel}>
      <Tooltip
        text={<HBox className={styles.multiValueLabel_tooltip}>{props.data.label}</HBox>}
        placement="bottom-start"
        maxWidth="300px"
      >
        <components.MultiValueLabel {...props} />
      </Tooltip>
    </div>
  );

  createValueButton = (string) => (
    <div className={styles.create_value_button}>
      <div className={styles.create_value_button_content}>
        <i className="bp-icon-plus-icon" />
        <span className={styles.create_value_button_content_line}>
          Create <span className={styles.create_value_button_content_user}>{string}</span>
        </span>
      </div>
    </div>
  );
  render() {
    const { error, onBlur, values } = this.props;
    const { isMenuOpen } = this.state;
    return (
      <CreatableSelect
        isMulti
        styles={style(error)}
        onChange={this.onSelectValueChange}
        options={[]}
        noOptionsMessage={() => 'Value already exist'}
        value={values}
        onInputChange={this.onInputChange}
        isClearable={false}
        backspaceRemovesValue={false}
        onBlur={onBlur}
        menuIsOpen={isMenuOpen}
        autoFocus
        formatCreateLabel={this.createValueButton}
        isValidNewOption={this.isValidNewOption}
        components={{
          DropdownIndicator: null,
          MultiValueLabel: this.MultiValueLabelComponent,
          Placeholder: () => null,
        }}
      />
    );
  }
}

IncidentTagsGridMultiValueEditor.propTypes = {
  values: PropTypes.arrayOf({
    value: PropTypes.string,
    label: PropTypes.string,
    __isNew__: PropTypes.bool,
  }),
  error: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({ tag_value: PropTypes.string }),
};

IncidentTagsGridMultiValueEditor.defaultProps = {
  error: null,
  values: [],
  initialValue: { tag_value: undefined },
};

export default hot(module)(IncidentTagsGridMultiValueEditor);
