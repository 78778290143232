angular.module('bp.widgets').directive('tagNameValidator', () => ({
  require: 'ngModel',
  link: (scope, elm, attrs, ctrl) => {
    ctrl.$validators.bpqlValidator = (modelValue) => {
      if (ctrl.$isEmpty(modelValue)) {
        ctrl.tagError = 'Tag name is required';
        return false;
      }

      const tagNameRegex = /^[a-zA-Z][a-zA-Z0-9\.\-_]{0,63}$/;
      const valid = tagNameRegex.test(modelValue);

      if (valid) {
        return true;
      }
      ctrl.tagError = 'Tag name is not valid';
      return false;
    };
  },
}));
