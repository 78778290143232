angular.module('bigpanda').service('IncidentValidatorService', function () {
  let isValidate = false;

  this.validateIncident = validateIncident;
  this.setValidation = setValidation;

  function validateIncident(
    validateWith,
    returnTo,
    folderId,
    incidentId,
    target,
    environmentId,
    source,
    incidentfullscreen
  ) {
    if (isValidate) {
      return null;
    }

    return {
      to: validateWith,
      params: {
        environment: environmentId,
        folder: folderId,
        incidentId: incidentId,
        returnTo: returnTo,
        target: target,
        bp_source: source,
        incidentfullscreen: incidentfullscreen,
      },
      options: {
        location: false,
      },
    };
  }

  function setValidation(validate) {
    isValidate = validate;
  }

  return this;
});
