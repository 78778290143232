import * as colors from '@bp/pastel/colors';
import get from 'lodash/get';

import isMapping from '../../../../../app/react/modules/settings/alert_enrichment/modals/utils/isMapping';
import {
  EnrichmentRule,
  RuleType,
  Tag,
  TagType,
} from '../../types';
import dictionary from '../dictionary';
import { getInterpolatedText } from './get-interpolated-text';

export const statusToColor = (isActive: boolean): string => (isActive ? colors.bp_green : colors.bp_gray_05);

export const statusToString = (isActive: boolean): string => (isActive ? 'Active' : 'Inactive');

type ITableRowArg = { original: Tag & { config: { name: string } }, value: string };
export const valueFormatByType = ({ original: { type, config: { name = '' } }, value }: ITableRowArg): string => (isMapping(type)
  ? getInterpolatedText(dictionary.items_contain_different_values_from_map, { name })
  : value);

type IRuleValue = { type: RuleType, name: string, value: string };
export const ruleValueFormatByType = ({ type, name = '', value }: IRuleValue): string => (isMapping(type)
  ? getInterpolatedText(dictionary.items_contain_different_values_from_map, { name })
  : value);

export const tooltipContentByType = (
  { original: { type }, value }: ITableRowArg,
): string => (isMapping(type) ? 'Click to download map CSV file' : value);

export const valueByType = (rule: EnrichmentRule): string | undefined => {
  const accessors = {
    [TagType.Extraction]: get(rule, 'config.regex'),
  };

  return accessors[rule.type] || get(rule, 'config.value');
};
