import find from 'lodash/find';
angular.module('bigpanda').service('SharesUtils', SharesUtils);

function SharesUtils($http, $filter, IncidentsService, pubSubService) {
  function unsubscribe(incidentId, shareId, contactId, unsubscribe, authorisationOptions = {}) {
    return $http
      .put(
        `/web/incidents/${incidentId}/escalations/${shareId}/unsubscribe`,
        {
          contactId: contactId,
          unsubscribe: unsubscribe,
        },
        authorisationOptions
      )
      .then((res) => {
        pubSubService.broadcast('incidentShare.newData', incidentId);
        return res.data;
      });
  }

  function isUserUnsubscribed(shares, shareId, contactId) {
    var share = find(shares, { id: shareId });
    if (share) {
      return share.unsubscribes && (share.unsubscribes.all || share.unsubscribes[contactId]);
    }

    return false;
  }

  this.isUserUnsubscribed = isUserUnsubscribed;
  this.unsubscribe = unsubscribe;
}
