angular.module('bigpanda').directive('splitButton', splitButton);

function splitButton() {
  return {
    restrict: 'E',
    scope: {
      entities: '<',
      incident: '<',
      search: '&',
      searchValue: '=',
    },
    templateUrl: 'overview/incidents/info/widgets/split_button/split_button',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };
}

function controller($scope, $ngRedux, stateService, EnvironmentsService) {
  const vm = this;

  vm.store = $ngRedux;
  vm.alerts = [];
  vm.envId = stateService.getSelectedEnvironmentId();
  vm.getEnvById = EnvironmentsService.getEnvById;
  vm.$onInit = init;

  function init() {
    $scope.$watch(
      () => vm.entities,
      () => {
        vm.alerts = vm.entities;
      }
    );
  }
}
