angular.module('bigpanda').component('portal', {
  template: '<div class="detach__container"  ng-class="[$ctrl.elementClass]" ng-transclude></div>',
  transclude: true,
  bindings: {
    elementRefId: '@',
    elementClass: '@',
  },
  controller: function ($scope, $element) {
    const $ctrl = this;
    $ctrl.$onInit = () => {
      let contentElm = $element[0].querySelector('.detach__container');
      const referElm = document.getElementById($ctrl.elementRefId).getBoundingClientRect();
      contentElm.style.cssText = `top:${referElm.top + referElm.height}px; left: ${
        referElm.left
      }px;`;

      $scope.$watch('$ctrl.elementRefId', () => {
        document.body.append(contentElm);
      });

      $scope.$on('$destroy', () => {
        contentElm.remove();
        contentElm = null;
      });
    };
  },
});
