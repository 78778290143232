angular.module('bp.widgets').directive('bpTag', () => ({
  restrict: 'C',
  templateUrl: 'widgets/bp_tag/bp_tag',
  scope: {
    tag: '=',
  },
  link: function (scope) {
    var simpleUrlRegex = /^(http|https):\/\//i;
    if (angular.isString(scope.tag.value) && scope.tag.value.match(simpleUrlRegex)) {
      try {
        scope.link = decodeURI(scope.tag.value || '');
      } catch (_) {
        scope.link = scope.tag.value;
      }
    }

    scope.$watch('tag', (newValue, oldValue) => {
      if (newValue) {
        scope.value = angular.isArray(newValue.value)
          ? newValue.value.join('  |  ')
          : newValue.value;
      }

      if (scope.tag.annotation && !scope.tag.value) {
        scope.tag.hide = true;
      }
    });
  },
}));
