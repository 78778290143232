import {
  AllSizes,
  Button, ButtonVariants, ResolveIcon,
} from '@bp/kung-fu';
import styled from 'styled-components';

export const StyledButton = styled(Button).attrs({
  variant: ButtonVariants.ACTION_CONTAINER,
  size: AllSizes.LARGE,
  icon: ResolveIcon,
})`

&&:active{
  fill: ${(p) => p.theme.bp_green};
}
`;
