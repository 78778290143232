import {
  BamAddNoteField,
  BamBpqlInput,
  BamForm,
  BamInput,
  BamNotificationStatusOptions,
  bamNotify,
} from '@bp/bam';
import BPQL from '@bp/bpql';
import {
  Checkbox, HBox, HelpTooltip, Select,
} from '@bp/kung-fu';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import trim from 'lodash/trim';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { buildParserAndGenerateQuery } from '../../../../../app/common/modules/settings/QuerySourceGenerator';
import { loadAlertTags } from '../../../../../app/react/common/alert_tags/actions';
import alertTagsSelectors from '../../../../../app/react/common/alert_tags/selectors';
import { selectors as integrationsSelectors } from '../../../../../app/react/common/integrations';
import getRuleIntegrationDisplay from '../../../../../app/react/modules/settings/alert_enrichment/modals/utils/getRuleIntegrationDisplay';
import { sourceSystemsOptions } from '../../../../../app/react/modules/settings/alert_enrichment/utils';
import { selectors as FTSelectors } from '../../../../../app/react/user/feature_toggles';
import { IntegrationOption } from '../../../alert-enrichment/src/components/RuleTypeForms/types';
import { AlertTag } from '../../../maintenance-plans/types/AlertTag';
import onboardingDic from '../../../onboarding/src/dictionary';
import { completeStep } from '../../../onboarding/src/endpoints/onboarding';
import { shouldAllowOnboarding } from '../../../onboarding/src/helpers/shouldAllowOnboarding';
import { QueryKeys } from '../api/constants';
import { createAlertFilter, getPreviewFilter, updateAlertFilter } from '../api/endpoints';
import { UNSUPPORTED_FIELD_ERROR_CODE } from '../constants';
import dictionary from '../dictionary';
import { AlertFilter } from '../types/AlertFilter';
import { FilterForm } from '../types/FilterForm';
import { PreviewResponse } from '../types/PreviewReponse';
import { PreviewState } from '../types/PreviewState';
import alertFilterUrl from '../utils/alertFilterUrl';
import { showFailureMessageForFilters, showInProgressMessageForFilters, showSuccessMessageForFilters } from '../utils/toasters';
import { FilterPreview } from './FilterPreview/FilterPreview';
import {
  ConditionFormField, DescriptionWrapper,
  FooterLine, NameFormField, SourceSystemField,
} from './style';
import { uniqueNameValidator } from './validators/uniqueNameValidator';

interface IProps {
  close: () => void;
  filter: AlertFilter;
  isNew?: boolean;
  isDuplicate?: boolean;
}

const validateDisplayQuery = (val: string): string => (val ? BamBpqlInput.helpers.BpqlInputValidator(val) : '');

const isValidCondition = (condition = ''): boolean => !validateDisplayQuery(condition);

const parseCondition = (condition, sourceSystems): object => {
  if (!sourceSystems || !sourceSystems.length) {
    return BPQL.buildParserFromGrammer('correlation')(condition);
  }

  return buildParserAndGenerateQuery(condition, sourceSystems.map(({ value }) => value));
};

export function AlertFilterFormBam({
  close,
  filter = {} as AlertFilter,
  isNew,
  isDuplicate,
}: IProps): JSX.Element {
  const [actionsDisabled, setActionsDisabled] = useState(false);
  const [previewState, setPreviewState] = useState<PreviewState>();
  const [isLoading, setIsLoading] = useState(false);
  const [canPreview, setCanPreview] = useState(isValidCondition(filter.condition?.displayQuery));
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [previewedCondition, setPreviewedCondition] = useState(filter.condition?.displayQuery || '');

  const formRef = useRef(null);

  const queryClient = useQueryClient();
  const updateFilterMutation = useMutation(updateAlertFilter);
  const createFilterMutation = useMutation(createAlertFilter);

  const isEdit = !(isNew || isDuplicate);

  const isOnboarding = shouldAllowOnboarding(useSelector(FTSelectors.getFeatureToggles));
  const allAlertTags: AlertTag[] = useSelector(alertTagsSelectors.getDisplayAlertTags) || [];
  const alertTags = useMemo(() => allAlertTags.filter(({ name }) => name !== 'description'), [allAlertTags.length]);
  const alertFilters = queryClient.getQueryData<AlertFilter[]>([QueryKeys.AlertFilter]);
  const integrationOptions: IntegrationOption[] = useSelector(
    integrationsSelectors.getIntegrationOptions,
  ) || [];

  const querySourceSystems = filter.condition?.query?.sourceSystems?.map(
    (sourceSystem) => {
      const value = typeof sourceSystem === 'string'
        ? sourceSystem
        : sourceSystem.value; // source system is either an object if it's a regex, or a string... :facepalm:
      const integrationDisplay = (getRuleIntegrationDisplay(value, integrationOptions) || {}).display || '';
      return {
        key: value,
        text: integrationDisplay,
        value,
        tooltip: integrationDisplay,
        display: integrationDisplay,
      };
    },
  );
  const [sourceSystems, setSourceSystems] = useState<IntegrationOption[]>(querySourceSystems || [sourceSystemsOptions(integrationOptions)[0]]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAlertTags());
    if (!isNew) getPreviewFilters();
  }, []);

  const getPreviewFilters = async (): Promise<void> => {
    if (!canPreview) return;
    const condition = formRef.current?.getFormValues().condition;
    const conditionObj = parseCondition(condition, sourceSystems);
    setIsLoading(true);
    try {
      const data = await queryClient.fetchQuery<PreviewResponse>(
        [QueryKeys.FilterPreview],
        () => getPreviewFilter(conditionObj),
      );
      setIsFormChanged(false);
      setIsLoading(false);
      setPreviewedCondition(condition);
      setPreviewState({ ...data, hasPreviewed: true });
    } catch (ex) {
      const { errors } = ex?.response?.data || {};
      if (Array.isArray(errors)) {
        const validationErrors = errors.filter(({ code }) => code === UNSUPPORTED_FIELD_ERROR_CODE);
        validationErrors.forEach(({ message }) => {
          bamNotify({
            message: [{ message }],
            status: BamNotificationStatusOptions.FAILURE,
          });
        });
      }
      setIsLoading(false);
      setPreviewState({ events: [], condition_fields: [], hasPreviewed: true });
    }
  };

  const validateFilterName = (value = ''): string => {
    if (isEdit && filter.name.toLowerCase() === value.toLowerCase()) return '';
    const trimmedValue = trim(value);
    if (!trimmedValue.length) return dictionary.name_is_required;
    return uniqueNameValidator<AlertFilter>(alertFilters, 'name', value);
  };

  const submit = (values: AlertFilter): void => {
    setActionsDisabled(true);
    const alertFilterValues = {
      ...values,
      bpql: parseCondition(values.condition, sourceSystems),
      selected_source_system: sourceSystems.map(({ value }) => value).join(','),
    };

    if (!isEdit) {
      const toasterId = showInProgressMessageForFilters('Creating');
      createFilterMutation.mutate(alertFilterValues, {
        onSuccess: (newFilter: AlertFilter): void => {
          queryClient.invalidateQueries([QueryKeys.AlertFilter]);
          window.location.href = alertFilterUrl(newFilter.id);
          showSuccessMessageForFilters('created', toasterId);
          if (isOnboarding) {
            completeStep(onboardingDic.onboardingSteps.alert_filtering);
          }
          close();
        },
        onError: (): void => {
          setActionsDisabled(false);
          showFailureMessageForFilters('create', toasterId);
        },
      });
      return;
    }

    const toasterId = showInProgressMessageForFilters('Updating');
    updateFilterMutation.mutate(alertFilterValues, {
      onSuccess: (): void => {
        queryClient.invalidateQueries([QueryKeys.AlertFilter]);
        showSuccessMessageForFilters('updated', toasterId);
        close();
      },
      onError: (): void => {
        setActionsDisabled(false);
        showFailureMessageForFilters('update', toasterId);
      },
    });
  };

  const onFormChange = ({ condition }: FilterForm): void => {
    const isConditionDirty = condition !== previewedCondition;
    setIsFormChanged(previewState?.hasPreviewed && isConditionDirty);
    setCanPreview(isValidCondition(condition));
  };

  const onBlurField = (): void => {
    if (previewState?.hasPreviewed && !isFormChanged) return;
    getPreviewFilters();
  };

  const onSelectSourceSystem = (values): void => {
    setSourceSystems(values);
    setIsFormChanged(previewState?.hasPreviewed);
  };

  return (
    <BamForm
      id="alert filtering form"
      ref={formRef}
      onSubmit={submit}
      initialValues={{
        id: filter.id,
        name: filter.name,
        condition:
          (filter.condition?.query?.pureQuery && Object.keys(filter.condition.query.pureQuery).length)
            ? BPQL.reverse(filter.condition.query.pureQuery)
            : '',
        active: Boolean(isNew || filter.active),
        description: filter.description,
      }}
      positiveButton={{
        text: isEdit ? dictionary.update_filter : dictionary.create_filter,
        'data-product-id': isEdit ? 'update_alert_filter' : 'create_alert_filter',
        disabled: actionsDisabled,
      }}
      closeButton={{
        text: dictionary.cancel,
        onClick: close,
        disabled: actionsDisabled,
      }}
      rightPane={(
        <FilterPreview
          canPreview={canPreview}
          getPreviewFilters={getPreviewFilters}
          previewState={previewState}
          isLoading={isLoading}
          isFormChanged={isFormChanged}
          sourceSystems={sourceSystems}
        />
      )}
      scrollable
      onChange={onFormChange}
    >
      <NameFormField
        name="name"
        title={dictionary.filter_name}
        aria-label="filter name"
        component={BamInput}
        placeholder={dictionary.enter_a_name_placeholder}
        style={{ width: 200 }}
        required
        validateOnBlur
        validate={validateFilterName}
        data-testid="filter name"
      />
      <SourceSystemField>
        <Select
          name="source_system"
          placeholder="Select one or more sources..."
          label="Source System"
          isMulti
          isSearchable
          isFilterable
          isCreatable={false}
          options={sourceSystemsOptions(integrationOptions)}
          value={sourceSystems}
          updateField={(_, values): void => onSelectSourceSystem(values)}
          valueKey="value"
          labelKey="display"
        />
      </SourceSystemField>
      <div onBlur={onBlurField}>
        <Field
          name="condition"
          component={ConditionFormField}
          title={dictionary.condition}
          placeholder={dictionary.condition_placeholder}
          interactiveTooltip
          fixedFont
          validate={validateDisplayQuery}
          validateOnBlur
          tags={alertTags}
          aria-label="condition"
          tooltip={dictionary.condition_tooltip}
          minRows={10}
          maxRows={15}
          data-testid="condition"
        />
      </div>
      <DescriptionWrapper>
        <Field
          name="description"
          component={BamAddNoteField}
          title={dictionary.add_description}
          openStateTitle={dictionary.description}
          parse={(val: string):string => val || ''}
          aria-label="description"
          data-testid="description"
        />
      </DescriptionWrapper>
      {(isNew || isDuplicate) && (
      <FooterLine backgroundColor={(p): string => p.theme.bp_gray_01}>
        <Field
          name="active"
          render={({ input }): JSX.Element => (
            <Checkbox
              text={dictionary.create_as_inactive}
              checked={!input.value}
              name="active"
              ariaLabel="create filter as inactive"
              onChange={(e): void => {
                input.onChange(!e.currentTarget.checked);
              }}
            />
          )}
        />
        <HBox marginStart="6px">
          <HelpTooltip text={dictionary.create_as_inactive_tooltip} />
        </HBox>
      </FooterLine>
      )}
    </BamForm>
  );
}
