import { AllSizes, Spinner, Text } from '@bp/kung-fu';
import React, { ReactNode } from 'react';

import {
  Container, Content, ContentWrapper, HeaderContainer, SubheaderContainer,
} from './ContentPreview.style';
import { EmptyMessage } from './EmptyMessage';

interface IProps {
  header: ReactNode;
  subheader: ReactNode | undefined;
  loading: boolean | undefined;
  unpadded: boolean | undefined;
  emptyPreviewMessage: string;
  emptyPreviewHeader: string | undefined;
  children: JSX.Element[] | JSX.Element;
}

export function ContentPreview({
  header,
  subheader,
  loading,
  unpadded,
  emptyPreviewMessage,
  emptyPreviewHeader,
  children,
}: IProps): JSX.Element {
  return (
    <Container>
      <HeaderContainer>
        <Text color={(p): string => p.theme.bp_gray_07} size={AllSizes.XLARGE} weight="bold">
          Preview
        </Text>
        {header}
      </HeaderContainer>
      {subheader && <SubheaderContainer>{subheader}</SubheaderContainer>}
      {loading && <Spinner />}
      <ContentWrapper unpadded={unpadded}>
        {!loading && (
        <Content>
          {children || (
          <EmptyMessage
            emptyPreviewHeader={emptyPreviewHeader}
            emptyPreviewMessage={emptyPreviewMessage}
          />
          )}
        </Content>
        )}
      </ContentWrapper>
    </Container>
  );
}

export default ContentPreview;
