angular.module('bp.widgets').directive('bpModalDialog', ($animate) => {
  return {
    restrict: 'AE',
    templateUrl: 'widgets/bp_modal_dialog/bp_modal_dialog',
    controller: controller,
    link: (scope, element) => {
      $animate.enabled(element, false);
    },
    transclude: true,
  };

  function controller($scope, $q, $mdDialog, pubSubService) {
    $scope.close = () => {
      $mdDialog.cancel();
    };

    $scope.save = () => {
      $scope.submitInProgress = true;
      $scope.submitError = false;
      $scope.errorMessage = '';
      $q.when($scope.preSave && $scope.preSave()).finally(() => {
        if (!$scope.submitError) {
          $mdDialog.hide();
          pubSubService.broadcast(`${$scope.eventPrefix}.dataLoaded`, $scope.data);
        }
      });
    };
  }
});
