import { path as prevPath } from '../constants';

export const name = 'customTags';
export const path = `${prevPath}.${name}`;

export const ANY_OPTION_VALUE = '-1';
export const DROPDOWN_FILTERS_ALL_ITEM_OPTION = {
  tagName: { value: ANY_OPTION_VALUE, text: 'Any Tag Name' },
  status: { value: ANY_OPTION_VALUE, text: 'Any Status' },
  source: { value: ANY_OPTION_VALUE, text: 'Any Source' },
  type: { value: ANY_OPTION_VALUE, text: 'Any Type' },
};
export const TAG_TYPE = {
  Extraction: 'extraction',
  Composition: 'composition',
};
