import find from 'lodash/find';
import get from 'lodash/get';
import {
  incidentTagTypes,
  incidentTagClosedListTypes,
} from 'react/modules/settings/incident_labels';
import DOMPurify from 'dompurify';
import anchorme from 'anchorme';
import { AI_TAGS } from '../../../../../../../common/constants';

angular.module('bigpanda').controller('IncidentTagEventController', IncidentTagEventController);

const imagesPath = '/assets/img/activity-logos';
const linkOptions = {
  options: {
    attributes: {
      target: '_blank',
      rel: 'noreferrer noopener nofollow',
    },
  },
};

function IncidentTagEventController($scope, pubSubService, LabelsStore) {
  const vm = this;
  let definitions = [];
  vm.$onInit = onInit;
  $scope.getRemoveText = getRemoveText;
  $scope.getTagName = getTagName;
  $scope.getTagValue = getTagValue;
  $scope.getIcon = getIcon;
  $scope.isMultiValue = isMultiValue;
  $scope.getActionDescription = getActionDescription;
  $scope.sanitizeText = sanitizeText;
  $scope.getLabel = getLabel;
  $scope.isAiEvent = isAiEvent;

  function onInit() {
    updateValues();
    pubSubService.on('incidentTagsDefinitions.updated', updateValues);
  }

  function updateValues() {
    definitions = LabelsStore.getDefinitions() || [];
  }

  function getEventParams(event) {
    return get(event, '_source.params', {});
  }

  function getTagDefinitionFromEvent(event) {
    const tagId = getEventParams(event).tag_id;
    const tagDefinition = definitions.find((tag) => tag.id === tagId);
    return tagDefinition;
  }

  function getTagName(event) {
    const { event_type, _source } = event;
    const {
      params: { tag_id },
    } = _source;
    const tagDefinition = getTagDefinitionFromEvent(event);
    if (tagDefinition) {
      return tagDefinition.name;
    }
    return '';
  }

  function getTagValue(event) {
    const tagDefinition = getTagDefinitionFromEvent(event);
    if (tagDefinition) {
      let tagValue = get(getEventParams(event), 'tag_value', '');
      switch (tagDefinition.type) {
        case incidentTagTypes.PRIORITY: // eslint-disable-line no-case-declarations
          const listItem = find(tagDefinition.config.ordered_list, {
            order_id: parseInt(tagValue),
          });
          if (listItem) {
            tagValue = listItem.display_name;
          }
          return [tagValue];
        case incidentTagTypes.MULTI_VALUE:
          if (tagValue) {
            return tagValue.split(',');
          }
          return [];
        default:
          return [tagValue];
      }
    }
    return '';
  }

  function getIcon(event) {
    const { event_type, _source } = event;
    const tagDefinition = getTagDefinitionFromEvent(event);
    switch (tagDefinition.type) {
      case incidentTagTypes.PRIORITY:
        switch (event_type) {
          case 'incident-tag#upserted':
            return `${imagesPath}/tag_priority.png`;
          case 'incident-tag#removed':
            return `${imagesPath}/tag_priority_remove.png`;
          default:
            return '';
        }
      case incidentTagTypes.TEXT:
        if (isAiEvent(event)) {
          return `${imagesPath}/ai.png`;
        }
        return `${imagesPath}/tag_text.png`;
      case incidentTagTypes.MULTI_VALUE:
      case incidentTagClosedListTypes.CLOSED_LIST_VALUE_SINGLE:
      case incidentTagClosedListTypes.CLOSED_LIST_VALUE_MULTI:
        return `${imagesPath}/tag_multi.png`;
      default:
        return '';
    }
  }

  function getRemoveText(event) {
    const tagDefinition = getTagDefinitionFromEvent(event);
    switch (tagDefinition.type) {
      case incidentTagTypes.PRIORITY:
      case incidentTagTypes.TEXT:
        return 'Removed the';
      case incidentTagTypes.MULTI_VALUE:
        return 'Removed all values from';
    }
  }

  function isMultiValue(event) {
    const tagDefinition = getTagDefinitionFromEvent(event);
    return tagDefinition && tagDefinition.type === incidentTagTypes.MULTI_VALUE;
  }

  function getLabel(event) {
    const values = getTagValue(event).join(', ');
    return `Updated the ${getTagName(event)}: ${sanitizeText(values)}`;
  }

  function getActionDescription(event) {
    const { _source } = event;
    const {
      params: { tag_id },
    } = _source;

    return `Updated the`;
  }

  function isAiEvent(event) {
    const { _source } = event;
    const {
      params: { tag_id },
    } = _source;
    return AI_TAGS.includes(tag_id);
  }

  function sanitizeText(str) {
    return DOMPurify.sanitize(anchorme({ input: str, ...linkOptions }), {
      ALLOWED_ATTR: ['href', 'rel', 'target'],
    });
  }
}
