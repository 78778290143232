import { BamAddNoteField, BamBpqlInput } from '@bp/bam';
import { AllSizes } from '@bp/kung-fu';
import get from 'lodash/get';
import React, { useMemo, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';

import ruleFieldName from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/ruleFieldName';
import { validateDisplayQuery } from '../../../../../../../app/react/modules/settings/alert_enrichment/validation.utils';
import { selectors as featureTogglesSelectors } from '../../../../../../../app/react/user/feature_toggles';
import { EnrichmentRule } from '../../../../types';
import text from '../../../dictionary';
import QueryHelper from '../../QueryHelper';
import { LoadPreview, UnifiedTag } from '../types';
import FormByType from './FormByType';
import FunctionType from './FunctionType';
import { FieldError, HR, Notes } from './MultiType.style';

export interface IProps {
  rule: EnrichmentRule;
  unifiedTags: UnifiedTag[];
  isNewOrDuplicate: boolean;
  onLoadPreview: LoadPreview;
  funcType: string;
  values?: EnrichmentRule
}

export function MultiType({
  rule,
  unifiedTags,
  isNewOrDuplicate,
  onLoadPreview,
  funcType,
  ...props
}: IProps): JSX.Element {
  const [values, setValues] = useState({});

  const filtersv2FT: boolean = get(
    useSelector(featureTogglesSelectors.getFeatureToggles),
    'filters_v2',
    false,
  );

  const enableQueryHelper: boolean = get(
    useSelector(featureTogglesSelectors.getFeatureToggles),
    'enable_alert_enrichment_helper',
    false,
  );

  const handleChange = ({ values: formValues }): void => setValues(formValues);

  const ruleFuncType = funcType || get(values, ruleFieldName(rule?.id, 'type'));

  const triggerComponentProps = useMemo(() => {
    const { condition } = props.values || {};
    return {
      title: text.multi_type_condition_query_helper_trigger_component_props_title,
      description: text.multi_type_condition_query_helper_trigger_component_props_description,
      size: AllSizes.MEDIUM,
      disabled: !ruleFuncType,
      value: condition,
    };
  }, [ruleFuncType]);

  return (
    <>
      {isNewOrDuplicate && <FunctionType rule={rule} filtersv2FT={filtersv2FT} />}
      {enableQueryHelper
        ? (
          <Field name={ruleFieldName(rule?.id, 'condition')}>
            {({ input: { onChange } }): JSX.Element => (
              <QueryHelper
                placeholder={text.multi_type_condition_query_helper_placeholder}
                triggerComponentProps={triggerComponentProps}
                alertTags={unifiedTags}
                onChange={onChange}
              />
            )}
          </Field>
        )
        : (
          <Field
            name={ruleFieldName(rule?.id, 'condition')}
            component={BamBpqlInput}
            title="Condition"
            placeholder="e.g. host = acme*"
            validate={validateDisplayQuery}
            tags={unifiedTags}
            disabled={!ruleFuncType}
            validateOnBlur
            fixedFont
            interactiveTooltip
            optional
          />
        )}
      {ruleFuncType && (
        <FormByType
          onLoadPreview={onLoadPreview}
          rule={rule}
          type={ruleFuncType}
          unifiedTags={unifiedTags}
        />
      )}
      <HR />
      <Notes>
        <Field
          name={ruleFieldName(rule?.id, 'description')}
          title="Add a Note"
          optional
          render={({ input, meta }): JSX.Element => (
            <FieldError error={meta.error}>
              <BamAddNoteField input={{ ...input }} maxRows={1.5} disabled={!ruleFuncType} />
              {meta.error && <span>{meta.error}</span>}
            </FieldError>
          )}
        />
      </Notes>
      <FormSpy subscription={{ values: true }} onChange={handleChange} />
    </>
  );
}

export default MultiType;
