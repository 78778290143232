import styled from 'styled-components';
import { Button, HBox, Text, VBox } from '@bp/kung-fu';

export const RegenerateButtonWrapper = styled(Button)`
  && {
    border: 1px solid ${(p) => p.theme.bp_gray_02};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const ExpandButton = styled(Button)`
height: 0px;
  && {
    position: absolute;
    height: 28px;
    border-radius: 3px;
    left: 1px;
    bottom: 1px;
    width: calc(100% - 2px);
    opacity: 0;

    &:hover{
      opacity: 0.5;
    }
  }
`;

export const AIWrapper = styled(HBox)`
      border: 1px solid ${(p) => p.theme.bp_gray_03};
      border-radius: 3px;
      &:hover {
        background-color: ${(p) => p.theme.bp_gray_01};
        ${ExpandButton}{
          opacity: 0.8;
        }
      }
`;

export const AITagPrefix = styled(Text).attrs({ weight: 'bold' })`
  margin-inline-end: 3px;
`;

export const AIActionsWrapper = styled(HBox)`
  width:100%;
  margin-top: 8px;
  margin-bottom: 22px;
  position: relative;
`;

export const AIBadgeWrapper = styled(HBox)`
  flex-shrink: 0;
  height: fit-content;
  display: inline-flex;
  margin-inline-end: 5px;
`;

export const TitleWrapper = styled(HBox)`
  word-wrap: break-word;
  display: inline-block;
`;

export const AISection = styled(VBox)`
  position: relative;
  width: 100%;
`;
