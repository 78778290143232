import actionTypes from './actionTypes';

export default {
  createFiltersDropDownItemsSuccess: (payload) => ({
    type: actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS,
    payload,
  }),
  createAlertTag: (payload) => ({
    type: actionTypes.CREATE_ALERT_TAG,
    payload,
  }),
  updateAlertTag: (payload) => ({
    type: actionTypes.UPDATE_ALERT_TAG,
    payload,
  }),
  loadEnrichmentTags: () => ({ type: actionTypes.LOAD_ENRICHMENT_TAGS }),
  loadEnrichmentTagsSuccess: (payload) => ({
    type: actionTypes.LOAD_ENRICHMENT_TAGS_SUCCESS,
    payload,
  }),
  loadTags: () => ({ type: actionTypes.LOAD_TAGS }),
  loadTagsSuccess: (payload) => ({ type: actionTypes.LOAD_TAGS_SUCCESS, payload }),
  updateFiltersDropDowns: (payload) => ({
    type: actionTypes.UPDATE_FILTERS_DROPDOWNS,
    payload,
  }),
  updateFilterText: (payload) => ({ type: actionTypes.UPDATE_FILTER_TEXT, payload }),
  updateSort: (payload) => ({ type: actionTypes.UPDATE_SORT, payload }),
  removeAlertTag: (payload) => ({ type: actionTypes.REMOVE_ALERT_TAG, payload }),
  updateRulesOrder: (payload) => ({ type: actionTypes.UPDATE_RULES_ORDER, payload }),
  updateTagsOrder: (payload) => ({ type: actionTypes.UPDATE_ENRICHMENT_TAGS_ORDER, payload }),
  setDraggedItems: (payload) => ({ type: actionTypes.SET_DRAGGED_ITEMS, payload }),
  loadPreviewAlerts: (payload) => ({ type: actionTypes.LOAD_PREVIEW_ALERTS, payload }),
  loadPreviewAlertsSuccess: (payload) => ({
    type: actionTypes.LOAD_PREVIEW_ALERTS_SUCCESS,
    payload,
  }),
  loadPreviewAlertsFailure: () => ({ type: actionTypes.LOAD_PREVIEW_ALERTS_FAILURE }),
  clearPreviewAlerts: () => ({ type: actionTypes.CLEAR_PREVIEW_ALERTS }),
  setCurrentTag: (payload) => ({ type: actionTypes.SET_CURRENT_TAG, payload }),
  clearCurrentTag: () => ({ type: actionTypes.SET_CURRENT_TAG, payload: undefined }),
  updateAlertTagMetadata: (payload) => ({
    type: actionTypes.UPDATE_ALERT_TAG_METADATA,
    payload,
  }),
  loadReservedTags: () => ({ type: actionTypes.LOAD_INTERNAL_TAGS }),
  loadReservedTagsSuccess: (payload) => ({
    type: actionTypes.LOAD_RESERVED_TAGS_SUCCESS,
    payload,
  }),
};
