import React from 'react';
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import { components, createFilter } from 'react-select';
import memoize from 'lodash/memoize';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import styles from './CreatableMultiselect.scss';
import creatableSelectStyle from './CreatableSelectStyle';
import { CREATE_OPTION_POSITIONS } from './constants';
const { MultiValueLabel, Option } = components;

class CreatableMultiselect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: props.isMenuOpen,
      invalidMessage: '',
      isFocused: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { meta } = this.props;
    if (meta && meta.submitSucceeded && !meta.initial) {
      if (prevProps.meta.submitSucceeded !== meta.submitSucceeded) {
        this.setErrorMessage();
      }
    }
  }

  onSelectValueChange = (creatableSelectValues, { action }) => {
    const { validateNewValue, onChange } = this.props;
    let values = (creatableSelectValues || []).slice();
    if (action === 'create-option' || action === 'select-option') {
      const lastSelectedOption = values.slice(-1)[0];
      if (lastSelectedOption.__isNew__) {
        const invalidMessage = validateNewValue(lastSelectedOption.value);
        if (invalidMessage) {
          return this.setState({ invalidMessage });
        }
        values[values.length - 1] = {
          ...lastSelectedOption,
          ...this.props.onAddNewValue(lastSelectedOption),
          __isNew__: true,
        };
      }
    }
    onChange(values);
  };

  onInputChange = (input, { action }) => {
    const { closeOnBlur, onInputChange, openMenuOnFocus } = this.props;
    if (!openMenuOnFocus && ((action === 'input-blur' && closeOnBlur) || action === 'set-value')) {
      return this.setState({ isMenuOpen: false });
    }

    if (action !== 'input-change') return;

    this.setState({
      ...(openMenuOnFocus ? {} : { isMenuOpen: input && input.length }),
      invalidMessage: '',
    });
    onInputChange(input);
  };

  onBlur = (e) => {
    const { validateOnBlur, validateAllValues, values, onBlur } = this.props;
    if (validateOnBlur) {
      const invalidMessage = validateAllValues(values);
      this.setState({ invalidMessage, isFocused: false });
    }
    onBlur && onBlur(e);
  };

  setErrorMessage = () => this.setState({ invalidMessage: this.props.validateAllValues() });

  getNoOptionsMessage = () => this.props.noOptionsMessage;

  isValidNewOption = (inputValue) => !!inputValue.trim().length;

  MultiValueLabelComponent = (props) => {
    const { ValueLabel } = this.props;
    return (
      <div className={styles.multi_value_label}>
        <MultiValueLabel {...props}>
          <ValueLabel {...props.data} />
        </MultiValueLabel>
      </div>
    );
  };

  OptionComponent = (className, AddNewValueDisabledHint) => (props) => {
    return (
      <Option {...props} className={className}>
        {props.data.__isNew__
          ? this.createValueButton({ ...props, AddNewValueDisabledHint })
          : this.optionButton(props)}
      </Option>
    );
  };

  createValueButton = ({ data: { value }, isDisabled, AddNewValueDisabledHint }) => {
    const contentClassNames = classnames([
      styles.create_button_content,
      { [styles.create_button_content_disabled]: isDisabled },
    ]);
    const iconClassName = classnames([
      styles.create_button_content_icon,
      'bp-icon-plus-icon',
      { [styles.create_button_content_icon_disabled]: isDisabled },
    ]);
    return (
      <div className={styles.create_button}>
        <div className={contentClassNames}>
          <i className={iconClassName} />
          <span className={styles.create_button_content_line}>
            Add <span className={styles.create_button_content_value}>{value}</span>
          </span>
          {isDisabled && AddNewValueDisabledHint && (
            <span className={styles.create_button_content_hint}>
              <AddNewValueDisabledHint />
            </span>
          )}
        </div>
      </div>
    );
  };

  optionButton = ({ data, isSelected }) => {
    const { OptionLabel } = this.props;
    const iconClassName = classnames([
      styles.select_value_button_content_icon,
      'bp-icon-plus-icon',
    ]);
    return (
      <div className={styles.select_value_button} role="option" aria-selected={isSelected}>
        <div className={styles.select_value_button_content}>
          <span className={styles.select_value_button_content_line}>
            <OptionLabel {...data} />
          </span>
          <i className={iconClassName} />
        </div>
      </div>
    );
  };

  tooltipStyle = {
    maxWidth: '300px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  };

  MenuListComponent = (hideFirstOption) => ({ children, ...props }) => {
    const [createElement, ...options] = children;
    return (
      <components.MenuList {...props}>
        {options.length && hideFirstOption ? options : children}
      </components.MenuList>
    );
  };

  selectStyleMemoized = memoize(
    creatableSelectStyle,
    (error, isFocused) => `${error}_${isFocused}`
  );

  filterOption = (candidate, input) => {
    const filterOptions = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: 'any',
    };
    return (
      this.props.filterOption(candidate, input) || createFilter(filterOptions)(candidate, input)
    );
  };

  handleOnFocus = () => this.props.shouldOpenOnFocus && this.setState({ isFocused: true });

  render() {
    const {
      AddNewValueDisabledHint,
      autoFocus,
      createOptionPosition,
      error,
      hideFirstOption,
      inputValue,
      isOptionDisabled,
      values,
      openMenuOnFocus,
      optionClassName,
      options,
      placeholder,
      className,
      inputId,
      classNamePrefix,
      isClearable,
    } = this.props;
    const { isMenuOpen, invalidMessage } = this.state;
    const errorMessage = error || invalidMessage;

    const classNames = classnames(['CreatableMultiselect', className]);
    return (
      <div>
        <CreatableSelect
          className={classNames}
          classNamePrefix={classNamePrefix}
          autoFocus={autoFocus}
          promptTextCreator={() => false}
          components={{
            IndicatorSeparator: null,
            MenuList: this.MenuListComponent(hideFirstOption),
            MultiValueLabel: this.MultiValueLabelComponent,
            Option: this.OptionComponent(optionClassName, AddNewValueDisabledHint),
          }}
          onFocus={this.handleOnFocus}
          createOptionPosition={createOptionPosition}
          filterOption={this.filterOption}
          hideSelectedOptions
          inputValue={inputValue}
          inputId={inputId}
          isClearable={isClearable}
          isMulti
          isValidNewOption={this.props.allowAddValue ? this.isValidNewOption : () => false}
          isOptionDisabled={isOptionDisabled}
          menuIsOpen={isMenuOpen || this.state.isFocused}
          noOptionsMessage={this.getNoOptionsMessage}
          onBlur={this.onBlur}
          onChange={this.onSelectValueChange}
          onInputChange={this.onInputChange}
          openMenuOnFocus={openMenuOnFocus}
          options={options}
          placeholder={placeholder}
          styles={this.selectStyleMemoized(errorMessage, this.state.isFocused)}
          value={values}
          tabSelectsValue={false}
          aria-label={this.props['aria-label']}
        />
        {errorMessage && <div className={styles.error_message}>{errorMessage}</div>}
      </div>
    );
  }
}

CreatableMultiselect.propTypes = {
  'aria-label': PropTypes.string,
  AddNewValueDisabledHint: PropTypes.node,
  autoFocus: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf({
    value: PropTypes.string,
    label: PropTypes.string,
    __isNew__: PropTypes.bool,
  }),
  className: PropTypes.string,
  closeOnBlur: PropTypes.bool,
  createOptionPosition: PropTypes.oneOf(Object.values(CREATE_OPTION_POSITIONS)),
  error: PropTypes.string,
  filterOption: PropTypes.func,
  hideFirstOption: PropTypes.bool.isRequired,
  inputValue: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  noOptionsMessage: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onAddNewValue: PropTypes.func.isRequired,
  openMenuOnFocus: PropTypes.bool,
  optionClassName: PropTypes.string,
  validateNewValue: PropTypes.func.isRequired,
  validateAllValues: PropTypes.func.isRequired,
  validateOnBlur: PropTypes.bool,
  isClearable: PropTypes.bool,
  shouldOpenOnFocus: PropTypes.bool,
  options: PropTypes.arrayOf({
    value: PropTypes.string,
    label: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  }),
  OptionLabel: PropTypes.node.isRequired,
  ValueLabel: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  inputId: PropTypes.string,
  classNamePrefix: PropTypes.string,
  allowAddValue: PropTypes.bool,
  meta: PropTypes.objectOf({
    submitSucceeded: PropTypes.bool,
  }),
};

CreatableMultiselect.defaultProps = {
  'aria-label': undefined,
  AddNewValueDisabledHint: undefined,
  createOptionPosition: CREATE_OPTION_POSITIONS.FIRST,
  filterOption: () => false,
  error: undefined,
  values: undefined,
  options: undefined,
  placeholder: undefined,
  className: undefined,
  closeOnBlur: undefined,
  isMenuOpen: undefined,
  isOptionDisabled: undefined,
  noOptionsMessage: 'Value already exists',
  validateOnBlur: undefined,
  onBlur: undefined,
  openMenuOnFocus: undefined,
  optionClassName: undefined,
  inputId: undefined,
  classNamePrefix: 'react-select',
  allowAddValue: true,
  isClearable: false,
  shouldOpenOnFocus: false,
};

export default hot(module)(CreatableMultiselect);
