import { stringLast } from '@bp/kung-fu';
import isEqual from 'lodash/isEqual';

import { parseQuery } from '../../../../../app/common/modules/settings/QuerySourceParser';
import { AlertFilter } from '../types/AlertFilter';

interface ISplitCondition {
  bpql: AlertFilter['bpql'];
  sourceSystems: string[]
  parseSourceSystem?: boolean;
}

type SourceSystem = (string | { type: string, value: string })[];

const sourceSystemBPQL = (sourceSystems: SourceSystem): { IN: [string, SourceSystem] } => ({
  IN: [
    'source_system',
    sourceSystems,
  ],
});

export const splitCondition = ({
  bpql, sourceSystems = [], parseSourceSystem = false,
}: ISplitCondition): AlertFilter['condition']['query'] => {
  if (!sourceSystems.length) {
    const parsedQuery = parseQuery(bpql, [{ type: 'regex', value: '*' }], true, true);
    if (parsedQuery.pureQuery && isEqual(parsedQuery.pureQuery, sourceSystemBPQL(parsedQuery.sourceSystems))) {
      return parseQuery('', parsedQuery.sourceSystems, true, true);
    }
    return parsedQuery;
  }

  const sourceSystemsArr = sourceSystems.map((sourceSystem) => {
    if (sourceSystem === '*') {
      return { type: 'regex', value: '*' };
    }
    if (stringLast(sourceSystem) === '*') {
      return { type: 'regex', value: sourceSystem };
    }
    return sourceSystem;
  });

  let condition: string | object | object[] = '';
  if (sourceSystems.length && bpql?.AND) {
    const AND = bpql.AND.filter((obj: object) => !isEqual(obj, sourceSystemBPQL(sourceSystemsArr)));
    condition = AND.length === 1 ? AND[0] : { AND };
  }

  return {
    ...parseQuery(condition, [{ type: 'regex', value: '*' }], true, parseSourceSystem),
    sourceSystems: sourceSystemsArr,
  };
};
