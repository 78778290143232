import styled from 'styled-components';
import { bp_gray_01_5 } from '@bp/pastel/colors';

export const Wrapper = styled.div`
height: ${({ isModalMode }) => !isModalMode && '100%'};
width: ${({ isModalMode }) => (!isModalMode ? '100vw' : '100%')};
display: ${({ isModalMode }) => isModalMode && 'flex'};
flex-grow: ${({ isModalMode }) => isModalMode && '1'};
justify-content: center;
background-color: ${bp_gray_01_5};
`;

export const SpinnerContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

export const Iframe = styled.iframe`
width: ${({ isVisible }) => (isVisible ? '100%' : '0px')};
height: 100%;
border: none;
visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
