angular.module('bigpanda').service('PerformanceWrapper', PerformanceWrapper);

function PerformanceWrapper() {
  this.wrap = () => {
    const perf = window.performance || {};
    const fn = perf.now || perf.mozNow || perf.webkitNow || perf.msNow || perf.oNow;

    return fn ? fn.bind(perf)() : (() => new Date().getTime() * 1000)();
  };
}
