import React from 'react';

import { TagType } from '../../../types';
import { CompositionTag, CompositionTagProps } from './CompositionTag';
import { ExtractionTag, ExtractionTagProps } from './ExtractionTag';

interface IProps extends ExtractionTagProps, CompositionTagProps {
  type: TagType;
}

export function HighlightTag({ type, ...props }: IProps): JSX.Element {
  switch (type) {
    case TagType.Composition:
      return <CompositionTag {...props} />;
    default:
      return <ExtractionTag {...props} />;
  }
}

export default HighlightTag;
