import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { SCHBox, Badge, HelpTooltip } from '@bp/kung-fu';
import { BamBadge } from '@bp/bam';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import SectionRowTitle from 'react/layout/settings/components/SectionRowTitle';
import styles from './PriorityTagInfo.scss';
import baseStyles from '../IncidentLabelsInfo.scss';

import { colorsNameHexObj } from '../../../constants';
import FormulaExpressionView from '../../../components/FormulaExpressionView';

class PriorityTagInfo extends React.PureComponent {
  render() {
    const { incidentLabel, featureToggles } = this.props;
    const {
      id,
      active,
      type,
      description,
      config: { ordered_list: itemsList },
      enrichment_config: enrichmentConfig,
      canManualInput,
      updated_at: updatedAt,
    } = incidentLabel;
    const secondaryTitle = 'First matching item will be applied';
    const helperContent =
      'Automatic Enrichment applies the value of the first item which meets the conditions';

    return (
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.SectionRow title="Status:">
          <Badge
            text={active ? 'Active' : 'Inactive'}
            status={active ? 'ok' : 'off'}
            size="large"
          />
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Type:">
          <SCHBox gap="5px">
            <span className={baseStyles.itemType}>
              <i className="bp-icon-priority" />
              <span>{type}</span>
            </span>
            <HelpTooltip text="Allows marking and sorting incidents using customizable priority levels" />
          </SCHBox>
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow
          title={<SectionRowTitle title="ID:" tooltip="The tag identifier is used in API calls" />}
        >
          <span className={baseStyles.itemType}>{id}</span>
        </SettingsInfoScreen.SectionRow>
        {get(featureToggles, 'allow_tags_manual_input', true) && (
          <SettingsInfoScreen.SectionRow title="Manual Input:">
            <span className={baseStyles.itemType}>
              <SectionRowTitle
                title={canManualInput ? 'Enabled' : 'Disabled'}
                tooltip="Enables users to manually change the values. Manually changing tag data will stop BigPanda’s automated incident enrichment for that tag."
              />
            </span>
          </SettingsInfoScreen.SectionRow>
        )}
        {!isEmpty(description) && (
          <SettingsInfoScreen.SectionRow title="Note:">{description}</SettingsInfoScreen.SectionRow>
        )}
        {itemsList.map((item, index) => (
          <SettingsInfoScreen.SectionRow
            key={item.order_id}
            title={`Level ${index + 1}:`}
            hint={`Order ID: ${item.order_id}`}
          >
            <div className={styles.itemColor}>
              <BamBadge
                color="gray"
                font="dark_gray"
                text={
                  <div className={styles['itemColor-badge']}>
                    <div
                      className={styles['itemColor-badge-dot']}
                      style={{ background: colorsNameHexObj[item.color].value }}
                    />
                    <div>{item.display_name}</div>
                  </div>
                }
              />
            </div>
            {item.description && !isEmpty(item.description) && (
              <div className={styles.itemNote}>{item.description}</div>
            )}
          </SettingsInfoScreen.SectionRow>
        ))}
        {get(enrichmentConfig, 'items') && (
          <FormulaExpressionView
            items={enrichmentConfig.items}
            updateTime={updatedAt}
            secondaryTitle={secondaryTitle}
            helperContent={helperContent}
          />
        )}
      </SettingsInfoScreen.Section>
    );
  }
}

PriorityTagInfo.propTypes = {
  incidentLabel: PropTypes.shape({
    id: PropTypes.string,
    active: PropTypes.bool.isRequired,
    type: PropTypes.string,
    description: PropTypes.string,
    config: PropTypes.shape({
      ordered_list: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string,
          display_name: PropTypes.string,
          order_id: PropTypes.string,
        })
      ),
    }),
    enrichment_config: PropTypes.shape({ append_matching_items: PropTypes.bool }),
    canManualInput: PropTypes.bool,
    updated_at: PropTypes.number,
  }),
  featureToggles: PropTypes.shape({ allow_tags_manual_input: PropTypes.bool }).isRequired,
};
PriorityTagInfo.defaultProps = {
  incidentLabel: null,
};
export default hot(module)(PriorityTagInfo);
