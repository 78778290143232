import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Field } from 'react-final-form';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CreatableMultiselect from 'react/common/components/CreatableMultiselect/CreatableMultiselect';
import { ConfigurationLabelsField } from 'react/common/components/ConfigurationLabels/ConfigurationLabelsField/ConfigurationLabelsField';
import * as configurationLabelConstants from 'react/common/components/ConfigurationLabels/constants';
import { getCorrelationConfig } from 'common/endpoints/correlation-config';
import { buildParserAndGenerateQuery } from 'common/modules/settings/QuerySourceGenerator';
import { AllSizes, HBox, HelpTooltip } from '@bp/kung-fu';
import {
  BamAddNoteField,
  BamBpqlInput,
  BamCheckbox,
  BamCheckboxField,
  BamForm,
  BamInput,
  BamMultiselect,
} from '@bp/bam';
import get from 'lodash/get';
import styles from './correlation_pattern_form.scss';
import CorrelationPatternPreview from './CorrelationPatternPreview';
import { generateHelpText } from '../../../../common/components/ConfigurationLabels/utils';
import QueryHelper from '../../../../../../workspaces/apps/alert-correlation/src/components/QueryHelper';
import { isPatternUnique } from '../../../../common/correlation_config/utils';

const MAX_TAGS = 5;
const WINDOW_LIMIT = 4320;
const DEFAULT_TIME_WINDOW = '120';

const UNIQUE_KEYS_FOR_PATTERN = ['query', 'tags', 'window'];
const FIELDS_FOR_PREVIEW = ['sourceSystems', 'tagNames', 'window', 'crossSource', 'displayQuery'];
const UNIFIED_TAGS_HINT_TEXT =
  'Tag names for which alerts with matching values are correlated.Find the tag names for an integration in the alert details of an incident.';
const EDIT_CORRELATION_PATTERN_FIELDS = [
  'sourceSystems',
  'tagNames',
  'window',
  'crossSource',
  'displayQuery',
  'description',
];

class CorrelationPatternForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formValues = {};
    this.active = props.correlationPattern ? props.correlationPattern.active : true;
    this.windowTouched = false;
    this.state = {
      displayDuplicatePatternError: false,
      hasVisitedTags: !!props.correlationPattern,
      isLabelsSectionOpen: false,
      isDirty: false,
      sourceSystemInput: '',
      sourceSystemOptions: [],
      unifiedTagsInput: '',
      unifiedTagsOptions: [],
      triggerComponentProps: {
        title: 'Query Filter',
        description: '(optional)',
        size: AllSizes.MEDIUM,
        value: '',
      },
    };
  }

  componentDidMount() {
    this.setSourceSystemOptions();
    this.setUnifiedTagsOptions();
    this.setQueryFilterInitialValue();
  }

  componentDidUpdate(prevProps) {
    if (
      !!prevProps &&
      JSON.stringify(prevProps.unifiedTags) !== JSON.stringify(this.props.unifiedTags)
    ) {
      this.setUnifiedTagsOptions();
      if (this.props.correlationPattern) {
        var arr = [];
        this.props.correlationPattern.tags.forEach((tag) => {
          if (tag.indirect) {
            const tagName = `@${tag.indirect}`;
            this.setState((prevState) => ({
              unifiedTagsOptions: [
                ...prevState.unifiedTagsOptions,
                { key: tagName, text: tagName, value: tagName },
              ],
            }));
          }
        });
      }
    }
  }

  onActiveToggle = () => {
    this.active = !this.active;
  };

  onSourceSystemInputChange = (sourceSystemInput) => this.setState({ sourceSystemInput });

  onUnifiedTagsInputChange = (unifiedTagsInput) => this.setState({ unifiedTagsInput });

  onSelectedSourceSystemChanged = (onChange) => (selectedInput) => {
    this.setState({ sourceSystemInput: '' });
    const selectedSourceSystem = selectedInput.map(
      (sourceSystemObject) => sourceSystemObject.value
    );
    onChange(selectedSourceSystem);
  };

  onSelectedUnifiedTagsChanged = (onChange) => (selectedInput) => {
    this.setState({ unifiedTagsInput: '' });
    const selectedTags = selectedInput.map((tagsObject) => tagsObject.value);
    onChange(selectedTags);
  };

  setSourceSystemOptions = () =>
    this.setState({ sourceSystemOptions: this.generateSourceSystemOptions() });

  setUnifiedTagsOptions = () =>
    this.setState({ unifiedTagsOptions: this.generateUnifiedTagsOptions() });

  setQueryFilterInitialValue = () =>
    this.setState({
      triggerComponentProps: {
        ...this.state.triggerComponentProps,
        value: this.formValues.displayQuery,
      },
    });

  setIsLabelsSectionOpen = () => this.setState({ isLabelsSectionOpen: true });

  setHasVisitedTags = () => this.setState({ hasVisitedTags: true });

  handleTagNamesOnBlur = () => {
    this.handleBlur('unifiedTagsInput');
    this.setHasVisitedTags();
  };

  handleSubmit = async (values) => {
    const {
      enableConfigurationLabels,
      correlationPattern,
      duplicate,
      updateCorrelationPattern,
      addCorrelationPattern,
      close,
    } = this.props;
    if (this.state.isDirty) {
      const updateMode = correlationPattern && !duplicate;

      const payload = this.createCorrelationPatternPayload(
        values,
        updateMode ? correlationPattern.id : null
      );
      const currentCorrelationConfig = await getCorrelationConfig();
      if (
        isPatternUnique(payload, currentCorrelationConfig.matching_rules, UNIQUE_KEYS_FOR_PATTERN)
      ) {
        payload.currentCorrelationConfig = currentCorrelationConfig;
        const handleAction = updateMode ? updateCorrelationPattern : addCorrelationPattern;
        handleAction(enableConfigurationLabels ? payload : omit(payload, 'labels'));
        close();
      } else {
        this.setState({ displayDuplicatePatternError: true });
      }
    }
  };

  handleChange = (values) => {
    const formValues = { ...values };
    // We do not save it on the state for code readability (so that we won't have to read some fields from the formValues
    // and override some of them (in certain cases only) from the state - this is why we use forceUpdate;
    if (FIELDS_FOR_PREVIEW.some((field) => formValues[field] !== this.formValues[field])) {
      this.setState({ displayDuplicatePatternError: false });
    }
    if (!isEqual(this.formValues, formValues) && !isEmpty(this.formValues)) {
      this.setState({ isDirty: true });
    }
    this.formValues = formValues;
    this.forceUpdate();
  };

  isValidForPreview = () => {
    if (!this.formRef) {
      return !!this.props.correlationPattern;
    }

    if (!this.state.hasVisitedTags) {
      return false;
    }

    const { errors } = this.formRef.formRef.form.getState();
    return !FIELDS_FOR_PREVIEW.some(
      (field) =>
        Object.entries(errors).find(([errKey, errVal]) => errKey === field && errVal) != null
    );
  };

  createCorrelationPatternPayload = (values, correlationPatternId) => {
    const {
      sourceSystems,
      crossSource,
      tagNames,
      window,
      displayQuery,
      description,
      selectedLabels: labels = [],
    } = values;

    if (!tagNames || !sourceSystems || !window || !tagNames.length || !sourceSystems.length) {
      return null;
    }
    return {
      id: correlationPatternId,
      tags: tagNames.map((tagName) =>
        tagName.indexOf('@') === 0 ? { indirect: tagName.substring(1) } : tagName
      ),
      active: this.active,
      window: window * 60,
      query: buildParserAndGenerateQuery(displayQuery, sourceSystems),
      cross_source: crossSource,
      description: description,
      labels,
    };
  };

  validateDisplayQuery = (val) => (val ? BamBpqlInput.helpers.BpqlInputValidator(val) : undefined);

  validateSourceSystems = (val) =>
    !val || !val.length ? 'Please use at least one source system' : undefined;

  validateTags = (val) => {
    if (!val || !val.length) {
      return 'Please use at least one tag';
    } else if (val.length > MAX_TAGS) {
      return `Maximum number of tags allowed is ${MAX_TAGS}`;
    }
    return undefined;
  };

  isNum = (val) => /^\d+$/.test(val);

  validateWindow = (val) => {
    this.windowTouched = true;

    return !val || val < 1 || val > WINDOW_LIMIT || !this.isNum(val)
      ? `Choose a value between 1-${WINDOW_LIMIT} minutes`
      : undefined;
  };

  filterByName = ({ data }, input) =>
    !input || (!!data.text && data.text.toLowerCase().includes(input.toLowerCase()));

  valueLabel = ({ text }) => <span>{text}</span>;

  sourceSystemsOptionLabel = ({ text }) => text;

  unifiedTagsOptionLabel = ({ text, description }) => (
    <div className={styles['tabs-option-label-container']}>
      <span className={styles['tag-option-label']}>{text}</span>
      <span className={styles['tag-option-description']}>{description}</span>
    </div>
  );

  generateSourceSystemOptions = () => {
    return [
      { key: '*', text: 'All Systems', value: '*' },
      ...(this.props.integrationOptions || []).map((i) => ({
        key: i.value,
        text: i.display,
        value: i.value,
        tooltip: i.display,
      })),
    ];
  };

  generateUnifiedTagsOptions = () => {
    return (this.props.unifiedTags || []).map((tag) => {
      const { name, sourceSystems } = tag;
      const description = `(${sourceSystems.map((system) => system.parentDisplayName).join(', ')})`;
      return { key: name, text: name, value: name, description: description };
    });
  };

  generateInitialValues = (stateKey, initialValues) =>
    !!initialValues &&
    this.state[stateKey].filter((element) => initialValues.includes(element.value));

  handleBlur = (stateKey) => this.setState({ [stateKey]: '' });

  render() {
    const {
      correlationPattern,
      duplicate,
      enableConfigurationLabels,
      integrations,
      close,
      unifiedTags,
      labelDefinitions,
      featureToggles,
    } = this.props;
    const disableQueryHelper = get(featureToggles, 'disable_query_helper', false);

    // Indirect tags cannot be selected from the dropdown, unless this specific pattern was already created with the tag via API
    const formValues = correlationPattern
      ? {
          ...correlationPattern,
          sourceSystems: correlationPattern.sourceSystems.map((sourceSystem) => sourceSystem.value),
          crossSource: correlationPattern.cross_source,
          window: correlationPattern.window / 60,
          description: correlationPattern.metadata.description,
          selectedLabels: correlationPattern.labels || [],
          ...this.formValues,
        }
      : { window: this.windowTouched ? '' : DEFAULT_TIME_WINDOW, ...this.formValues };

    const footer = (
      <div className={styles['form-footer']}>
        {this.state.displayDuplicatePatternError && (
          <div className={styles['duplicate-tag-error']}>
            <i className={classnames(styles['warning-icon'], 'bp-icon-warning')} />
            <span className={styles['error-message']}>
              This pattern is identical to an existing one.
            </span>
          </div>
        )}
        {(!correlationPattern || duplicate) && (
          <div className={styles['inactive-checkbox']}>
            <BamCheckbox
              label="Create as 'Inactive'"
              onClick={() => this.onActiveToggle()}
              defaultChecked={!this.active}
            />
            <HelpTooltip text="Save the rule definition without affecting how alerts are correlated" />
          </div>
        )}
      </div>
    );

    const hasDescription = !!formValues.description;
    const optionalSection = enableConfigurationLabels ? (
      <div
        className={classnames(styles['form-optional-section'], {
          'block-layout': this.state.isLabelsSectionOpen || hasDescription,
        })}
        data-api-id="optional-form-section"
      >
        <Field
          name="description"
          component={BamAddNoteField}
          title="Add Description"
          openStateTitle="Description"
        />
        <Field
          {...(this.state.isLabelsSectionOpen
            ? {
                title: configurationLabelConstants.TITLE,
                optional: true,
                hint: generateHelpText('correlation patterns'),
              }
            : {})}
          name="selectedLabels"
          component={ConfigurationLabelsField}
          labelDefinitions={labelDefinitions}
          onOpen={this.setIsLabelsSectionOpen}
        />
      </div>
    ) : (
      <Field
        name="description"
        component={BamAddNoteField}
        title="Add Description"
        openStateTitle="Description"
        openStateDescription="(optional)"
      />
    );
    return (
      <BamForm
        id="CorrelationPatternForm"
        ref={(r) => {
          this.formRef = r;
        }}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        rightPane={
          <CorrelationPatternPreview
            correlationPattern={this.createCorrelationPatternPayload(formValues, null)}
            integrations={integrations}
            validForPreview={this.isValidForPreview()}
          />
        }
        footer={footer}
        scrollable
        initialValues={formValues}
        positiveButton={{
          text: `${correlationPattern && !duplicate ? 'Update Pattern' : 'Create Pattern'}`,
          'data-product-id':
            correlationPattern && !duplicate
              ? 'update_correlation_pattern'
              : 'create_correlation_pattern',
        }}
        closeButton={{
          text: 'Cancel',
          onClick: close,
        }}
      >
        {!!featureToggles && featureToggles.filters_v2 ? (
          <div className={styles['source-systems-field']}>
            <div className={styles['field-label']}>Source Systems</div>
            <Field name="sourceSystems">
              {({ input: { onChange }, meta }) => (
                <CreatableMultiselect
                  placeholder="Select sources..."
                  className={styles['cp-react-select']}
                  noOptionsMessage="No options"
                  closeOnBlur
                  validateOnBlur
                  shouldOpenOnFocus
                  allowAddValue={false}
                  isMenuOpen={false}
                  filterOption={this.filterByName}
                  onBlur={() => this.handleBlur('sourceSystemInput')}
                  inputValue={this.state.sourceSystemInput}
                  values={this.generateInitialValues(
                    'sourceSystemOptions',
                    formValues.sourceSystems
                  )}
                  onInputChange={this.onSourceSystemInputChange}
                  options={this.state.sourceSystemOptions}
                  OptionLabel={this.sourceSystemsOptionLabel}
                  ValueLabel={this.valueLabel}
                  validateAllValues={this.validateSourceSystems}
                  onChange={this.onSelectedSourceSystemChanged(onChange)}
                  meta={meta}
                />
              )}
            </Field>
          </div>
        ) : (
          <div className="source-systems-field">
            <Field
              name="sourceSystems"
              component={BamMultiselect}
              options={this.state.sourceSystemOptions}
              title="Source Systems"
              placeholder="Select sources..."
              validate={this.validateSourceSystems}
              validateOnBlur
            />
          </div>
        )}
        <div className="cross-source-checkbox">
          <Field
            name="crossSource"
            component={BamCheckboxField}
            label="Enable cross source correlation"
            baseProps={{
              hint:
                'Option to correlate alerts from different source systems into the same incident.',
            }}
          />
        </div>
        {!!featureToggles && featureToggles.filters_v2 ? (
          <div className={styles['tags-name-field']}>
            <div className={styles['field-label']}>
              Correlation Tags{' '}
              <HBox marginStart="5px">
                <HelpTooltip text={UNIFIED_TAGS_HINT_TEXT} />
              </HBox>
            </div>
            <Field name="tagNames">
              {({ input: { onChange }, meta }) => (
                <CreatableMultiselect
                  placeholder="Select tags..."
                  className={styles['cp-react-select']}
                  noOptionsMessage="No options"
                  closeOnBlur
                  shouldOpenOnFocus
                  validateOnBlur
                  isMenuOpen={false}
                  allowAddValue={false}
                  filterOption={this.filterByName}
                  inputValue={this.state.unifiedTagsInput}
                  values={this.generateInitialValues('unifiedTagsOptions', formValues.tagNames)}
                  onInputChange={this.onUnifiedTagsInputChange}
                  options={this.state.unifiedTagsOptions}
                  OptionLabel={this.unifiedTagsOptionLabel}
                  ValueLabel={this.valueLabel}
                  onBlur={this.handleTagNamesOnBlur}
                  validateAllValues={this.validateTags}
                  onChange={this.onSelectedUnifiedTagsChanged(onChange)}
                  meta={meta}
                />
              )}
            </Field>
          </div>
        ) : (
          <Field
            name="tagNames"
            component={BamMultiselect}
            options={this.state.unifiedTagsOptions}
            title="Correlation Tags"
            placeholder="Select tags..."
            hint={UNIFIED_TAGS_HINT_TEXT}
            validate={this.validateTags}
            validateOnBlur
            onBlur={this.setHasVisitedTags}
            disableIfEmpty
            limitDisplayedItems={100}
          />
        )}
        <div className={styles['window-field']}>
          <Field
            name="window"
            title="Time Window"
            component={BamInput}
            maxLength={4}
            hint="Maximum duration between the start times of correlated alerts."
            validate={this.validateWindow}
            validateOnBlur
          />
          <span className={styles.minutes}>Minutes</span>
        </div>
        {!disableQueryHelper ? (
          <Field name="displayQuery">
            {({ input: { onChange } }) => (
              <QueryHelper
                name="displayQuery"
                triggerComponentProps={this.state.triggerComponentProps}
                alertTags={unifiedTags}
                onChange={onChange}
              />
            )}
          </Field>
        ) : (
          <Field
            name="displayQuery"
            component={BamBpqlInput}
            interactiveTooltip
            title="Query Filter"
            optional
            tags={unifiedTags || []}
            placeholder="e.g. host=*.com"
            validate={this.validateDisplayQuery}
            validateOnBlur
            fixedFont
          />
        )}
        {optionalSection}
      </BamForm>
    );
  }
}

CorrelationPatternForm.propTypes = {
  updateCorrelationPattern: PropTypes.func.isRequired,
  addCorrelationPattern: PropTypes.func.isRequired,
  correlationPattern: PropTypes.shape({
    active: PropTypes.bool,
    id: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({ indirect: PropTypes.string })),
    sourceSystems: PropTypes.arrayOf(
      PropTypes.shape({ parentDisplayName: PropTypes.string, value: PropTypes.string })
    ),
    cross_source: PropTypes.bool,
    window: PropTypes.number,
    metadata: PropTypes.shape({
      description: PropTypes.string,
    }),
    labels: PropTypes.arrayOf(
      PropTypes.shape({ group: PropTypes.string, value: PropTypes.string })
    ),
  }),
  duplicate: PropTypes.bool,
  enableConfigurationLabels: PropTypes.bool,
  unifiedTags: PropTypes.arrayOf(PropTypes.shape({})),
  integrationOptions: PropTypes.arrayOf(PropTypes.shape({})),
  integrations: PropTypes.arrayOf(PropTypes.shape({})),
  close: PropTypes.func.isRequired,
  labelDefinitions: PropTypes.arrayOf(
    PropTypes.shape({ group: PropTypes.string, value: PropTypes.string })
  ),
  featureToggles: PropTypes.shape({
    filters_v2: PropTypes.bool,
    disable_query_helper: PropTypes.bool,
  }).isRequired,
};

CorrelationPatternForm.defaultProps = {
  correlationPattern: undefined,
  duplicate: false,
  enableConfigurationLabels: false,
  labelDefinitions: [],
  unifiedTags: [],
  integrationOptions: [],
  integrations: [],
};

export default hot(module)(CorrelationPatternForm);
