import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalTriggerButton from 'react/layout/settings/components/ModalTriggerButton';
import { BamModal } from '@bp/bam';
import { selectors as integrationsSelectors } from 'react/common/integrations';
import { selectors as correlationConfigSelectors } from 'react/common/correlation_config';
import { selectors as labelDefinitionsSelectors } from 'react/common/configuration_label_definitions';
import { loadAlertTags as loadAlertTagsAction } from 'react/common/alert_tags/actions';
import { loadLabelDefinitions as loadLabelDefinitionsAction } from 'react/common/configuration_label_definitions/actions';
import { selectors as featureTogglesSelectors } from '../../../../user/feature_toggles';
import actions from '../actions';
import selectors from '../selectors';
import CorrelationPatternForm from './CorrelationPatternForm';

class CorrelationPatternModal extends React.PureComponent {
  onMountModal = () => {
    const {
      enableConfigurationLabels,
      unifiedTags,
      loadAlertTags,
      loadLabelDefinitions,
    } = this.props;
    if (!unifiedTags) {
      loadAlertTags();
    }
    if (enableConfigurationLabels) {
      loadLabelDefinitions();
    }
  };

  handleClose = () => {
    this.ref.close();
  };

  render() {
    const { titleIcon, title, triggerProps } = this.props;
    return (
      <BamModal
        autoFocus
        ref={(r) => {
          this.ref = r;
        }}
        trigger={<ModalTriggerButton {...triggerProps} />}
        titleIcon={titleIcon}
        title={title}
        docsLink="https://docs.bigpanda.io/docs/working-with-correlation-patterns"
        onMount={this.onMountModal}
        closeOnEscape={false}
        formMode
        fullscreen
      >
        <CorrelationPatternForm {...this.props} close={this.handleClose} />
      </BamModal>
    );
  }
}

CorrelationPatternModal.propTypes = {
  titleIcon: PropTypes.string.isRequired,
  updateCorrelationPattern: PropTypes.func.isRequired,
  addCorrelationPattern: PropTypes.func.isRequired,
  currentCorrelationPatternId: PropTypes.string,
  correlationPattern: PropTypes.shape({}),
  enableConfigurationLabels: PropTypes.bool,
  integrationOptions: PropTypes.arrayOf(PropTypes.shape({})),
  integrations: PropTypes.arrayOf(PropTypes.shape({})),
  labelDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  loadAlertTags: PropTypes.func.isRequired,
  loadLabelDefinitions: PropTypes.func.isRequired,
  unifiedTags: PropTypes.arrayOf(PropTypes.shape({})),
  duplicate: PropTypes.bool,
  triggerProps: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconClass: PropTypes.string,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string,
  featureToggles: PropTypes.shape({
    filters_v2: PropTypes.bool,
  }).isRequired,
};

CorrelationPatternModal.defaultProps = {
  currentCorrelationPatternId: undefined,
  correlationPattern: undefined,
  enableConfigurationLabels: false,
  integrationOptions: [],
  integrations: [],
  duplicate: false,
  title: '',
  unifiedTags: undefined,
};

function getCorrelationPatternProp(rawCorrelationPatterns, enableConfigurationLabels) {
  if (isEmpty(rawCorrelationPatterns)) {
    return {};
  }
  return {
    correlationPattern: enableConfigurationLabels
      ? rawCorrelationPatterns
      : omit(rawCorrelationPatterns, 'labels'),
  };
}

function mapStateToProps(state, ownProps) {
  const enableConfigurationLabels = selectors.areConfigurationLabelsEnabled(state);
  const rawCorrelationPattern = selectors.getCorrelationPattern(
    state,
    ownProps.currentCorrelationPatternId,
    { isLabelsFilterEnabled: enableConfigurationLabels }
  );
  return {
    ...getCorrelationPatternProp(rawCorrelationPattern, enableConfigurationLabels),
    enableConfigurationLabels,
    integrationOptions: integrationsSelectors.getIntegrationOptions(state),
    integrations: integrationsSelectors.getIntegrations(state),
    labelDefinitions: labelDefinitionsSelectors.getLabelDefinitions(state),
    unifiedTags: correlationConfigSelectors.getUnifiedTags(state),
    featureToggles: featureTogglesSelectors.getFeatureToggles(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCorrelationPattern: (correlationPattern) =>
      dispatch(actions.updateCorrelationPattern(correlationPattern)),
    addCorrelationPattern: (correlationPattern) =>
      dispatch(actions.addCorrelationPattern(correlationPattern)),
    loadAlertTags: () => dispatch(loadAlertTagsAction()),
    loadLabelDefinitions: () => dispatch(loadLabelDefinitionsAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(CorrelationPatternModal));
