angular.module('bigpanda.reports').filter(
  'reportsDuration',
  () =>
    function (seconds, format) {
      if (!format) {
        if (seconds < 60) {
          format = 's[s]';
        } else if (seconds < 60 * 60) {
          format = 'm[m] s[s]';
        } else if (seconds < 60 * 60 * 24) {
          format = 'h[h] m[m]';
        } else {
          format = 'd[d] h[h]';
        }
      }

      return moment.duration(seconds, 'seconds').format(format);
    }
);
