import React from 'react';
import PropTypes from 'prop-types';
import { SSOActivateModal, SSODisableModal } from '../modals';
import { Content, HorizontalLayout, Title, Description } from './components';

const SSOPostValidation = ({ providerName, disableSSO, activateSSO }) => (
  <HorizontalLayout>
    <Content>
      <Title>{providerName} was Validated Successfully</Title>
      <Description>
        {`Click Activate SSO to activate ${providerName} for your entire organization.
          After Single Sign-On is activated, users in your organization will not be able to log in with their current password.`}
      </Description>
      <SSOActivateModal
        onAction={activateSSO}
        triggerProps={{
          variant: 'primary',
          size: 'medium',
          text: 'Activate SSO',
        }}
      />
      <hr />
      <Title>Disable SSO</Title>
      <Description>Click below to clear your configuration settings and disable SSO.</Description>
      <SSODisableModal
        onAction={disableSSO}
        triggerProps={{
          variant: 'danger',
          size: 'medium',
          text: 'Disable SSO',
          name: 'Disable SSO',
        }}
      />
    </Content>
  </HorizontalLayout>
);

SSOPostValidation.propTypes = {
  providerName: PropTypes.string.isRequired,
  activateSSO: PropTypes.func.isRequired,
  disableSSO: PropTypes.func.isRequired,
};

export default SSOPostValidation;
