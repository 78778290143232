import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Collapsible } from 'react/common/components';
import styles from './DraggableTag.scss';
import { tagPropType } from '../../propTypes';
import TagSummary from './TagSummary';
import { ContextButtons } from '../../../../../../../workspaces/apps/alert-enrichment/src/components/Modal/DraggableRule/ContextButtons';

const getStyle = (dndStyle, { isDropAnimating }) => ({
  ...dndStyle,
  left: 'inherit',
  ...(isDropAnimating ? { transitionDuration: '0.01s' } : {}),
});

const DraggableTag = ({ tag, index }) => (
  <Draggable draggableId={tag.name} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={getStyle(provided.draggableProps.style, snapshot)}
        className={styles.tag}
      >
        <Collapsible
          arrowRenderer={(props) => <ContextButtons {...props} {...provided.dragHandleProps} />}
          summaryRenderer={(props) => <TagSummary {...props} tag={tag} index={index} />}
          open={false}
          aria-label={`${tag.name} tag`}
          autoFocus={index === 0}
        />
      </div>
    )}
  </Draggable>
);

DraggableTag.propTypes = {
  tag: tagPropType.isRequired,
  index: PropTypes.number.isRequired,
};

export default DraggableTag;
