import { react2angular } from 'react2angular';
import IntegrationsList from './components/IntegrationsList';

const routes = {};
const template = `<section class='bp-master-detail-view'>
    <integrations-list class='left-panel' store='store' open-modal='openModal' ></integrations-list>
    <div class='spacer'></div>
    <div ui-view='master-detail-info' class='master-detail-info' ></div>
  </section>`;

routes.appSettingsIntegrationsList = {
  url: '/integrations',
  views: {
    'settings-page-view': {
      template: template,
      controller: ($state, $scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.openModal = () => $state.go('app.settings.integrations.selection');
      },
    },
    'master-detail-info@app.settings.integrations': {
      template: '<integrations-info class="react-settings-info" />',
      controller: () => {
        'ngInject';
      },
    },
  },
  onEnter: ($transitions, pubSubService, UserFeatureTogglesService) => {
    'ngInject';

    if (UserFeatureTogglesService.getToggle('frontier_integrations_lobby_react')) return;

    $transitions.onSuccess({ to: 'app.settings.integrations' }, () => {
      pubSubService.broadcast('settingsPageChanged', 'integrationsManagement');
    });
  },
};
routes.appSettingsIntegrationsNewIntegration = {
  url: '/selections',
  views: {
    'settings-page-view': {
      template: template,
      controller: () => {
        'ngInject';
      },
    },
    'master-detail-info@app.settings.integrations.selection': {
      template: '<integrations-info class="react-settings-info" />',
      controller: () => {
        'ngInject';
      },
    },
  },
  onEnter: ($state, $transitions, pubSubService, ModalService, UserFeatureTogglesService) => {
    'ngInject';

    if (!UserFeatureTogglesService.getToggle('frontier_integrations_lobby_react')) return;

    ModalService.showModal({
      templateUrl: 'integrations/selection/integrations_selection',
      controller: 'IntegrationsSelectionController',
      controllerAs: 'integrationSelectionVm',
      bindToController: true,
      fullscreen: true,
      onShowing: ($scope, $element) => {
        $element.addClass('integrations-selection-modal');
      },
    }).finally(() => {
      if ($state.current.name === 'app.settings.integrations.selection') {
        $state.go('app.settings.integrations');
      }
    });

    $transitions.onSuccess({ to: 'app.settings.integrations.selection' }, () => {
      pubSubService.broadcast('settingsPageChanged', 'integrationsManagementNewIntegration');
    });
  },
};

angular
  .module('bigpanda')
  .component('integrationsList', react2angular(IntegrationsList, ['openModal', 'store']));

export default routes;
