import React, { useEffect } from 'react';
import get from 'lodash/get';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Button, HBox, Form, darkTheme, AllSizes, TextField, useForm } from '@bp/kung-fu';
import { connect } from 'react-redux';
import { changePassword } from '../actions';
import { path } from '../constants';
import { ValidationError, PasswordTooltip } from '../../../../common/components';
import {
  isServerError,
  isCurrentPasswordError,
  isCurrentPasswordValid,
  isConfirmPasswordValid,
  isNewPasswordValid,
} from './helpers';
import {
  Wrapper,
  Logo,
  FormContainer,
  UserDetails,
  Line,
  Header,
  Title,
  Container,
  Footer,
  GrayLine,
  WIDTH,
  BoldText,
  RegularText,
} from './ChangePassword.css';

const SIZE = AllSizes.SMALL;

const inputProps = {
  width: WIDTH,
  type: 'password',
  isErrorAbsolute: false,
};

const ChangePassword = ({
  organizationName,
  userEmail,
  changePassword,
  errorStatus,
  origin,
  onSuccess,
  isSaving,
}) => {
  const { formRef, state, register, validateForm, updateErrorField } = useForm({});

  useEffect(() => {
    if (errorStatus && isCurrentPasswordError(errorStatus)) {
      updateErrorField('currentPassword', isCurrentPasswordError(errorStatus));
    }
  }, [errorStatus]);

  const onSubmit = () => {
    const { currentPassword, newPassword } = state;
    changePassword({ currentPassword, newPassword, origin }, onSuccess);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Wrapper>
        <Logo src="assets/img/bp_logo_text_dark.svg" height="38px" />
        <Form ref={formRef}>
          <FormContainer>
            <Header>
              <Title>Change Password</Title>
            </Header>

            <Container>
              <UserDetails>
                <Line>
                  <BoldText size={SIZE}>Email:</BoldText>
                  <RegularText size={SIZE}>{userEmail}</RegularText>
                </Line>

                <Line>
                  <BoldText size={SIZE}>Organization:</BoldText>
                  <RegularText size={SIZE}>{organizationName}</RegularText>
                </Line>
              </UserDetails>

              <GrayLine />

              {origin === 'app' && (
                <TextField
                  label="Current Password"
                  {...inputProps}
                  {...register('currentPassword', { validation: isCurrentPasswordValid })}
                />
              )}

              <TextField
                label="New Password"
                {...inputProps}
                {...register('newPassword', { validation: isNewPasswordValid })}
                infoTooltipProps={{
                  title: 'Make sure your password contains:',
                  text: <PasswordTooltip />,
                  triggerText: 'Requirements',
                  placement: 'right',
                  maxWidth: '320px',
                }}
              />

              <TextField
                label="Confirm Password"
                {...inputProps}
                {...register('confirmPassword', { validation: isConfirmPasswordValid })}
              />

              {errorStatus && isServerError(errorStatus) && (
                <HBox marginTop="20px">
                  <ValidationError active="true">{isServerError(errorStatus)}</ValidationError>
                </HBox>
              )}
            </Container>

            <Footer>
              <Button
                width={WIDTH}
                type="submit"
                variant="primary"
                size={AllSizes.LARGE}
                isLoading={isSaving}
                onClick={() => validateForm(onSubmit)}
              >
                Done
              </Button>
            </Footer>
          </FormContainer>
        </Form>
      </Wrapper>
    </ThemeProvider>
  );
};

ChangePassword.propTypes = {
  userEmail: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  changePassword: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  errorStatus: PropTypes.number.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  organizationName:
    get(state, 'user.organization.display_name') || get(state, 'user.organization.name'),
  userEmail: get(state, 'layout.topbar.user.email'),
  errorStatus: get(state, `${path}.error.response.status`),
  isSaving: get(state, `${path}.isSaving`),
});

const mapDispatchToProps = {
  changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(ChangePassword));
