import { withReactQueryClient } from '@bp/data-access';
import {
  MlSuggestedIcon,
  Modal,
} from '@bp/kung-fu';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import CorrelationPatternSuggestion from '../../model/CorrelationPatternSuggestion';
import getCorrelationPatternSuggestions from '../../services/getCorrelationPatternSuggestions';
import organizationSelector from '../../services/organizationSelector';
import CorrelationPatternSuggestionsModalContent from '../CorrelationPatternSuggestionsModalContent';
import CorrelationPatternSuggestionsStrip from '../CorrelationPatternSuggestionsStrip';
import CorrelationPatternSuggestionsProps from './CorrelationPatternSuggestionsProps';

function CorrelationPatternSuggestions({
  addCorrelationPattern,
  correlationPatterns,
}: CorrelationPatternSuggestionsProps): JSX.Element {
  const organizationId = useSelector(organizationSelector)?._id;
  const {
    data: suggestions = [],
  }: UseQueryResult<CorrelationPatternSuggestion[]> = useQuery(
    ['correlationPatternSuggestions', organizationId],
    () => getCorrelationPatternSuggestions(organizationId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {(suggestions?.length > 0) ? (
        <div>
          <Modal
            content={(
              <CorrelationPatternSuggestionsModalContent
                correlationPatternSuggestions={suggestions}
                correlationPatterns={correlationPatterns}
                addCorrelationPattern={addCorrelationPattern}
              />
            )}
            ariaDescribedby=""
            ariaLabelledby=""
            headerProps={{
              icon: <MlSuggestedIcon color={(p): string => p.theme.bp_purple} />,
              title: 'Suggested Patterns from BigPanda AI',
            }}
          >
            <CorrelationPatternSuggestionsStrip
              count={suggestions.length}
              onClick={(): void => { setIsOpen(!isOpen); }}
            />
          </Modal>
        </div>
      ) : null}
    </div>
  );
}

export default withReactQueryClient(CorrelationPatternSuggestions);
