import * as api from 'common/endpoints/changes';
import { buildRelatedChangesArr } from 'react/modules/changes/epic';
import { combineEpics } from 'redux-observable';
import changesActionTypes from '../changes/actionTypes';
import actionTypes from './actionTypes';
import actions from './actions';

const loadRelatedChanges = (action$) =>
  action$.ofType(actionTypes.LOAD_RELATED_CHANGES).mergeMap(({ payload }) =>
    api
      .getRelatedChanges({ incidentId: payload.incidentId })
      .then(({ relatedChanges, totalRelatedCount }) =>
        actions.loadRelatedChangesSuccessfully({
          incidentId: payload.incidentId,
          changes: buildRelatedChangesArr(relatedChanges),
          totalRelatedCount,
        })
      )
      .catch((e) => actions.loadRelatedChangesFailed({ error: e }))
  );

const loadRelatedChangesAfterRccUpsert = (action$, store) =>
  action$
    .ofType(changesActionTypes.UPSERT_CHANGES_METADATA_SUCCESSFULLY)
    .mergeMap(async ({ payload }) => {
      const { incident_id: updatedIncidentRcc } = payload.relatedMetadata;
      const { incidentId: currentIncidentId } = store.getState().modules.overview;
      if (updatedIncidentRcc == currentIncidentId) {
        return actions.loadRelatedChanges({ incidentId: currentIncidentId });
      }
      return {};
    });

export default combineEpics(loadRelatedChanges, loadRelatedChangesAfterRccUpsert);
