angular.module('bigpanda.reports').constant('DefaultReportConfig', configureDefaultReport());

function configureDefaultReport() {
  // make this a global default
  var defaultTimeframe = {
    type: 'relative',
    interval: '-30d/d',
  };

  return {
    defaultTimeframe: defaultTimeframe,
  };
}
