import { AllSizes, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const UserColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-inline-start: 8px;
`;

export const UserContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
  width: 200px;
`;

export const UserNameText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${AllSizes.MEDIUM}px;
`;
