import styled from 'styled-components';
import { HBox, VBox, SCVBox, SCHBox, CHBox, Text, Image } from '@bp/kung-fu';

export const WIDTH = '300px';

export const Wrapper = styled(SCVBox)`
height: 100%;
background-color: ${(p) => p.theme.bp_gray_01};
padding-top: 55px;
`;

export const Logo = styled(Image)`
margin-bottom: 20px;
`;

export const GrayLine = styled(HBox)`
width: calc(100% - 70px);
border-bottom: 1px solid ${(p) => p.theme.bp_gray_03};
`;

export const FormContainer = styled(SCVBox)`
border-radius: 6px;
border: 1px solid ${(p) => p.theme.bp_gray_03};
background-color: ${(p) => p.theme.bp_white};
width: 400px;
padding: 60px 0;
`;

export const Header = styled(CHBox)`
width: 100%;
margin-bottom: 36px;
`;

export const Title = styled(Text)`
font-size: 24px;
font-weight: bold;
color: ${(p) => p.theme.bp_black};
`;

export const Container = styled(SCVBox)`
width: 100%;
gap: 22px;
margin-bottom: 24px;
`;

export const UserDetails = styled(VBox)`
width: ${WIDTH};
color: ${(p) => p.theme.bp_gray_07};
gap: 12px;
`;

export const BoldText = styled(Text)`
color: ${(p) => p.theme.bp_gray_06};
width: 80px;
`;

export const RegularText = styled(Text)`
color: ${(p) => p.theme.bp_gray_09};
`;

export const Line = styled(SCHBox)`
gap: 16px;
`;

export const Footer = styled(CHBox)`
width: 100%;
`;
