import { hot } from 'react-hot-loader';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Tooltip, darkTheme } from '@bp/kung-fu';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './settings_info_screen.scss';

const SectionRow = ({ title, children, fixed, hint }) => (
  <div className={styles['section-row']}>
    {title && (
      <Tooltip text={hint} isActive={!!hint} placement="bottom-start">
        <div className={styles.title}>{title}</div>
      </Tooltip>
    )}
    <div className={classnames(styles.data, { [styles[fixed]]: fixed })}>{children}</div>
  </div>
);
SectionRow.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  hint: PropTypes.string,
};
SectionRow.defaultProps = {
  title: null,
  fixed: false,
  hint: null,
};

/* eslint-disable react/jsx-no-target-blank */
const ActivityRow = ({ title, name, value, badge, link }) => (
  <ThemeProvider theme={darkTheme}>
    <SectionRow title={title}>
      {badge}
      {name && <strong className={styles.dot}>{name}</strong>}
      {link && (
        <React.Fragment>
          <span className={styles['link-prefix']}>{link.prefix}</span>
          <a target="_blank" href={link.href} className={styles.dot}>
            {link.name}
          </a>
        </React.Fragment>
      )}
      <span>{value}</span>
    </SectionRow>
  </ThemeProvider>
);
/* eslint-enable react/jsx-no-target-blank */
ActivityRow.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  badge: PropTypes.node,
  link: PropTypes.shape({
    href: PropTypes.string,
    name: PropTypes.string,
    prefix: PropTypes.string,
  }),
};
ActivityRow.defaultProps = {
  title: null,
  name: null,
  badge: null,
  link: null,
};

class SettingsInfoScreen extends React.PureComponent {
  static Section = React.Fragment;
  static SectionRow = SectionRow;
  static ActivityRow = ActivityRow;

  render() {
    const { topActions, header, content, actions, activity } = this.props;

    const hasActualActions =
      actions &&
      actions.props.children &&
      !!React.Children.toArray(actions.props.children).filter((c) => c).length;

    return (
      <div className={classnames('bam', styles['settings-info-container'])}>
        <div className={styles['action-buttons']}>{topActions}</div>
        <div className={styles['header-tile-wrapper']}>{header}</div>
        <div className={styles['info-sections']}>
          <div className={classnames(styles.section, styles.content)}>{content}</div>
          {hasActualActions && (
            <div className={classnames(styles.section, styles.actions)}>{actions}</div>
          )}
          {activity && (
            <div className={classnames(styles.section, styles.activity)}>{activity}</div>
          )}
        </div>
      </div>
    );
  }
}

export default hot(module)(SettingsInfoScreen);

SettingsInfoScreen.propTypes = {
  topActions: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node,
  activity: PropTypes.node,
};

SettingsInfoScreen.defaultProps = {
  actions: null,
  activity: null,
};
