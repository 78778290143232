import { BamNotificationStatusOptions, bamNotify } from '@bp/bam';

export function showSuccessMessage(
  objectName,
  action,
  status = BamNotificationStatusOptions.ACTION_SUCCESS,
  actionButton,
  suffixMessage = 'successfully',
  extraWidth = false
) {
  bamNotify({
    message: [
      { message: objectName },
      { message: action, highlight: true },
      { message: suffixMessage },
    ],
    status: status,
    action: actionButton,
    extraWidth: extraWidth,
  });
}

export function showInProgressMessage(
  objectName,
  action,
  status = BamNotificationStatusOptions.IN_PROGRESS,
  actionButton
) {
  const toasterId = bamNotify({
    message: [{ message: action, highlight: true }, { message: objectName }],
    status: status,
    action: actionButton,
  });
  return toasterId;
}

export function showFailureMessage(objectName, action) {
  bamNotify({
    message: [
      { message: 'Failed to' },
      { message: action, highlight: true },
      { message: objectName },
    ],
    status: BamNotificationStatusOptions.FAILURE,
  });
}

let undo = false;

const applyUndo = () => (undo = true);
const disableUndo = () => (undo = false);

export function showInProgressMessageUndo(
  objectName,
  action,
  status = BamNotificationStatusOptions.IN_PROGRESS,
  actionButton,
  actionToRun,
  data
) {
  disableUndo();
  const handleClose = () => {
    if (!undo) {
      actionToRun(data);
    }
  };

  const toasterId = bamNotify({
    message: [{ message: action, highlight: true }, { message: objectName }],
    status: status,
    action: { ...actionButton, onClick: applyUndo },
    onClose: handleClose,
  });
  return toasterId;
}
