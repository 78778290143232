import flatMap from 'lodash/flatMap';
import { compose, curry, identity } from 'lodash/fp';
import get from 'lodash/get';

import permissions from '../../../../../app/common/routing/permissions';
import {
  ANY_OPTION_VALUE, path, RULE_CONTACTS_PATH, RULES_CONTACT_TYPES, RULES_STATUSES,
} from '../core/constants';
import { filterItems, filterItemsByDropDownFilters, generateBaseSelectors } from './redux.utils';

const mapUsers = (autoShareRule, contacts) => {
  if (contacts) {
    const contactsFlat = contacts.map((contact) => {
      const contactPhone = get(contact, ['phone_number'], '');
      const contactEmail = get(contact, ['email'], '');
      const contactName = get(contact, ['name'], '');
      const contactId = get(contact, ['_id']);

      if (!contactId) {
        return contactName;
      }
      if (autoShareRule.system.id === RULES_CONTACT_TYPES.email) {
        return contactName + contactEmail;
      }
      if (autoShareRule.system.id === RULES_CONTACT_TYPES.sms) {
        return contactName + contactPhone;
      }

      return undefined;
    });

    return contactsFlat;
  }
  return [];
};

const autoShareRulesFilterByDropDown = (filtersDropDownState, items) => {
  const itemDataMapper = {
    // eslint-disable-next-line no-underscore-dangle
    environment: (item) => [item.environment._id],
    channel: (item) => [item.system.system_id],
    recipients: (item) => mapUsers(item, get(item, RULE_CONTACTS_PATH)),
    status: (item) => [item.inactive ? RULES_STATUSES.inactive : RULES_STATUSES.active],
  };

  return filterItemsByDropDownFilters(
    filtersDropDownState,
    itemDataMapper,
    items,
    ANY_OPTION_VALUE,
  );
};

const contactsFilterInfo = (rule) => {
  const contacts = get(rule, ['params', 'contacts'], []);
  return flatMap(contacts, (contact) => [contact.name, contact.email, contact.phone_number]);
};

const autShareRulesFilterByText = (text, items) => {
  const itemDataMapper = (rule) => [
    rule.system.name,
    rule.environment.name,
    ...contactsFilterInfo(rule),
  ];

  return filterItems(items, text, itemDataMapper);
};

const selectors = {
  ...generateBaseSelectors(path),
  getFilterText: (state) => get(state, path).filterText,
  isLoading: (state) => get(state, path).isLoading,
  getFiltersDropDownState: (state) => get(state, path).filtersDropDownState,
  getActionsPermissions: (state) => {
    const userPermissions = get(state, ['user', 'permissions', 'list'], []);
    const canEdit = userPermissions.includes(permissions.notifications.update);
    const canDelete = userPermissions.includes(permissions.notifications.delete);
    return { canEdit, canDelete };
  },
  getAutoShareRule: (state, id) => {
    const rules = selectors.getAutoShareRules(state);
    return (rules || []).find((rule) => rule.id === id);
  },
  getAutoShareRules: (
    state,
    { shouldFilterByText = true, shouldFilterByDropDownFilters = true } = {},
  ) => {
    const { rules } = get(state, path);
    if (!rules || rules.length === 0) return undefined;

    return compose(
      shouldFilterByDropDownFilters
        ? curry(autoShareRulesFilterByDropDown)(selectors.getFiltersDropDownState(state))
        : identity,
      shouldFilterByText
        ? curry(autShareRulesFilterByText)(selectors.getFilterText(state))
        : identity,
    )(get(state, path).rules);
  },
};

export default selectors;
