import BPQL from '@bp/bpql';
import { getCurrentDate, isDateBefore } from '@bp/kung-fu';
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import identity from 'lodash/fp/identity';
import pipe from 'lodash/fp/pipe';

import { multipleSourcesToSourceNames } from '../../../../../app/react/common/integrations/enricher';
import { QueryKeys } from '../api/constants';
import { getAlertFilters } from '../api/endpoints';
import { filterByDropdowns } from '../selectors/utils/filterByDropdowns';
import { filterByText } from '../selectors/utils/filterByText';
import { sortFilters } from '../selectors/utils/sortFilters';
import { AlertFilter } from '../types/AlertFilter';
import { splitCondition } from '../utils/splitCondition';
import { IArgs, IOptions, UseAlertFilters } from './types';

export const useAlertFilters = (
  args: IArgs,
  options?: IOptions,
): UseAlertFilters => {
  const {
    filterText, sortMode, filters, integrations,
  } = args || {};
  const {
    shouldFilterByText = true, shouldSort = true, shouldFilter = true, getDonePlans = true,
  } = options || {};

  const queryClient = useQueryClient();
  const existingAlertFilters = queryClient.getQueryData([QueryKeys.AlertFilter]) as AlertFilter[];
  const shouldShowLoader = existingAlertFilters === undefined;

  const {
    data = [],
    isLoading,
    isError,
    isFetching,
  }: UseQueryResult<AlertFilter[]> = useQuery(
    [QueryKeys.AlertFilter],
    () => getAlertFilters(!getDonePlans),
    { refetchOnWindowFocus: false, staleTime: Infinity },
  );

  if (shouldShowLoader && (isLoading || isFetching || isError)) {
    return {
      alertFilters: [],
      isLoading,
      isError,
      isFetching,
    };
  }

  const enrichedAlertFilters: AlertFilter[] = data.map((alertFilter: AlertFilter) => {
    const sourceSystemsStrArr = alertFilter.selected_source_system?.split(',') || [];
    const query = splitCondition({ bpql: alertFilter.bpql, sourceSystems: sourceSystemsStrArr, parseSourceSystem: true });
    const displayQuery = Object.keys(query.pureQuery).length ? BPQL.reverse(query.pureQuery) : '';
    const sourceSystems = multipleSourcesToSourceNames(query, integrations);

    const timeframe = (alertFilter.start_time && alertFilter.end_time) ? {
      start_time: alertFilter.start_time,
      end_time: alertFilter.end_time,
      done: isDateBefore(alertFilter.end_time, getCurrentDate()),
    } : { done: false };

    return {
      ...alertFilter,
      ...timeframe,
      sourceSystems,
      condition: { query, displayQuery },
    };
  });

  const filterAndSort = pipe(
    shouldFilterByText ? filterByText(filterText) : identity,
    shouldSort ? sortFilters(sortMode) : identity,
    shouldFilter ? filterByDropdowns(filters) : identity,
  );
  const alertFilters = filterAndSort(enrichedAlertFilters);

  return {
    isLoading: shouldShowLoader && isLoading,
    isFetching: shouldShowLoader && isFetching,
    isError,
    alertFilters,
  };
};
