import uniqBy from 'lodash/uniqBy';
import forEach from 'lodash/forEach';
import DisplayCommon from '@bp/display-common';

// eslint-disable-next-line import/prefer-default-export
export function getMainProperties(entities) {
  const primaries = [];
  const secondaries = [];

  forEach(entities, (entity) => {
    const system = DisplayCommon.findSourceSystem({ entities: [entity] });
    const systemConfig = getSystemConfigForSource(system.source);

    let primary;
    let secondary;

    if (systemConfig) {
      primary = systemConfig.titleField;
      secondary = systemConfig.subTitleField;
      primary = Array.isArray(primary) ? primary : [primary];
      secondary = Array.isArray(secondary) ? secondary : [secondary];
    } else {
      primary = getMainPropertyFromEntity(entity, 'primary_property');
      secondary = getMainPropertyFromEntity(entity, 'secondary_property');
    }

    Array.prototype.push.apply(primaries, primary);
    Array.prototype.push.apply(secondaries, secondary);
  });

  return {
    primary: uniqBy(primaries, 'name'),
    secondary: uniqBy(secondaries, 'name'),
  };
}

function getSystemConfigForSource(source) {
  const systemsConfig = DisplayCommon.getSourceSystemsConfig();

  const systemConfig = systemsConfig[`alert_${source}`];
  if (systemConfig) {
    return systemConfig;
  }

  const parentSourceSystem = source && source.split('.')[0];
  const parentConfig = systemsConfig[`alert_${parentSourceSystem}`];
  if (parentConfig && parentConfig.useParent) {
    return parentConfig;
  }
  return null;
}

function getMainPropertyFromEntity(entity, propertyType) {
  if (entity[propertyType]) {
    return [{ name: entity[propertyType] }];
  }
  return null;
}
