/* eslint-disable no-undef */
import { DATE_FORMAT_THIS_YEAR, DATE_TIME_FORMAT, TIME_FORMAT } from '../../../common/constants';

angular.module('bigpanda').filter('maintenancePlanNormalizer', maintenancePlanNormalizer);

function maintenancePlanNormalizer(BPQLService) {
  return (rawMaintenancePlan) => {
    rawMaintenancePlan.startDate = formatDate(rawMaintenancePlan.start);
    rawMaintenancePlan.endDate = formatDate(rawMaintenancePlan.end);
    rawMaintenancePlan.conditionString = BPQLService.reverse(rawMaintenancePlan.condition);
    return rawMaintenancePlan;
  };
}

function formatDate(rawDate) {
  const momentObj = moment(rawDate, 'X');
  if (momentObj.isSame(moment(), 'day')) {
    return `Today ${momentObj.format(TIME_FORMAT)}`;
  } else if (momentObj.isSame(moment(), 'year')) {
    return momentObj.format(DATE_FORMAT_THIS_YEAR);
  }
  return momentObj.format(DATE_TIME_FORMAT);
}
