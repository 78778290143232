import actionTypes from './actionTypes';
import { generateBaseSettingsActions } from '../../redux.utils';

const actions = generateBaseSettingsActions(actionTypes);

actions.loadPlans = (payload) => ({ type: actionTypes.LOAD_PLANS, payload: payload });
actions.loadPlansSuccess = (payload) => ({
  type: actionTypes.LOAD_PLANS_SUCCESS,
  payload: payload || [],
});
actions.createOrUpdatePlan = (payload) => ({
  type: actionTypes.CREATE_OR_UPDATE_PLAN,
  payload: payload,
});
actions.updatePlanEnd = (payload) => ({ type: actionTypes.UPDATE_PLAN_END, payload: payload });
actions.deletePlan = (payload) => ({ type: actionTypes.DELETE_PLAN, payload: payload });
actions.stopPlan = (payload) => ({ type: actionTypes.STOP_PLAN, payload: payload });
actions.loadInitialPlans = (payload) => ({
  type: actionTypes.LOAD_INITIAL_PLANS,
  payload: payload,
});

export default actions;
