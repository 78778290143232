import React from 'react';
import classnames from 'classnames';
import { ThemeProvider } from 'styled-components';
import { Badge, darkTheme } from '@bp/kung-fu';
import get from 'lodash/get';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import RecipientsTooltip from '../../auto_share_rules_list/components/RecipientsTooltip';
import { rulePropType } from '../../propTypes';
import styles from './AutoShareRuleInfo.scss';
import { getNameAndIcon } from '../../constants';
import { getIntegrationIcon } from '../../modals/integrationsHelper';

const AutoShareRuleInfoContent = ({ rule }) => {
  const environment = get(rule, ['environment', 'name']);
  const statusText = rule.inactive ? 'inactive' : 'active';
  const ruleBamStatus = rule.inactive ? 'off' : 'ok';
  const contacts = get(rule, ['params', 'contacts']);
  const personalMessage = get(rule, ['params', 'message']);

  const parentSystemId = get(rule, ['system', 'parent_system_id'], {});
  const { systemName } = getNameAndIcon(rule);

  return (
    <ThemeProvider theme={darkTheme}>
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.SectionRow title="Status:">
          <Badge text={statusText} status={ruleBamStatus} isCapitalized size="large" />
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Environment:">
          <span className={styles.systemName}>{environment}</span>
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Share Via:">
          <div className={styles.systemIcon}>{getIntegrationIcon(parentSystemId)}</div>
          <span className={styles.systemName}>{systemName}</span>
        </SettingsInfoScreen.SectionRow>
        {contacts && contacts.length > 0 && (
          <SettingsInfoScreen.SectionRow title="Recipients:">
            <RecipientsTooltip contacts={contacts} type={parentSystemId} />
          </SettingsInfoScreen.SectionRow>
        )}
        {personalMessage && (
          <SettingsInfoScreen.SectionRow title="Personal Message:">
            {personalMessage}
          </SettingsInfoScreen.SectionRow>
        )}
      </SettingsInfoScreen.Section>
    </ThemeProvider>
  );
};

AutoShareRuleInfoContent.propTypes = {
  rule: rulePropType.isRequired,
};

export default AutoShareRuleInfoContent;
