import { LAST_UI_STATE, ssoLoginUrls } from '../../common/constants';

angular.module('bigpanda').factory('AuthService', AuthService);

function AuthService(
  $window,
  $http,
  $log,
  $q,
  $location,
  Config,
  pubSubService,
  PersonalSettingsStore,
  cookieStore,
  FullPageService,
  $rootScope
) {
  const credentials = {};
  let currentState;
  let nextState;
  let nextParams;
  let urlAccessToken;
  let domain = Config.domain;

  if (domain === 'localhost') domain = null;

  initCredentials();

  pubSubService.on('$stateChangeSuccess', (event, toState) => {
    currentState = toState.name;
  });

  $rootScope.$on('$stateChangeStart', (event, state, params) => {
    if (
      ['app', 'app.welcome', 'app.welcome.v2', 'app.default', 'bootstrap'].indexOf(state.name) ===
      -1
    ) {
      nextState = state.name;
      nextParams = params;
    }
  });

  pubSubService.on('Authentication.failed', () => {
    // The login stage do their handling of the errors,
    // For any other state, reset the user here.
    if (currentState !== 'change' && currentState.indexOf('preview.') !== 0) {
      $log.error('Session expired - logout');
      resetUser({ expired: true, fullpage: FullPageService.isInFullPage() });
    }
  });

  function resetUser(opts) {
    const options = opts || {};
    const now = moment();
    now.add('minutes', 15);
    sessionStorage.setItem(LAST_UI_STATE, JSON.stringify({ name: nextState, params: nextParams }));

    delete credentials.token;

    if (options.expired) {
      // Because reloading, need to set a cookie in the browser
      setAuxilaryCookie(now, 'expired');
      setUsernameCookie(now);
    } else if (options.sso) {
      setAuxilaryCookie(now, 'sso');
      setUsernameCookie(now);
    }

    goToLogout();
  }

  function setAuxilaryCookie(now, name) {
    $.cookie(name, true, { expires: now.toDate(), path: '/', domain: domain });
  }

  function setUsernameCookie(now) {
    PersonalSettingsStore.getUser().then((user) => {
      if (user.username) {
        $.cookie('uname', user.username, { expires: now.toDate(), path: '/', domain: domain });
      }
    });
  }

  function loadCredentials() {
    credentials.token = getCookieToken();
  }

  function isAuth() {
    return !!credentials.token;
  }

  function authenticateByToken() {
    if (isAuth()) {
      return $q.when();
    }

    // Try to use the access token or the token saved in the cookie
    const data = { access_token: getAccessToken() };

    if (!data.access_token) {
      return $q.reject('No access token');
    }

    return performLoginRequest(data);
  }

  /* --- Helper Methods --- */

  function performLoginRequest(data, onSuccess, onError) {
    return $http.post(`${Config.webApiBaseUrl}/login`, data, { withCredentials: true }).then(
      (result) => {
        if (urlAccessToken) {
          $location.search('access_token', null);
        }

        if (result.data && result.data.redirect) {
          $window.location = Config.webApiBaseUrl + result.data.redirect;
          return;
        }
        loadCredentials();
        if (onSuccess) {
          onSuccess(result.data);
        }
      },
      (err) => {
        if (onError) {
          onError(err);
        }
      }
    );
  }

  function initCredentials() {
    // We save to a variable and not use the $location service when ever possible because the search info is only
    // available on the first load of the service, after that the state might have changed and the location service
    // won't have the right data.
    urlAccessToken = $location.search().access_token;
  }

  function getCookieSuffix() {
    return Config.cookiesSuffix ? `_${Config.cookiesSuffix}` : '';
  }

  function getCookieToken() {
    return cookieStore.getCookie(`to${getCookieSuffix()}`);
  }

  function getAccessToken() {
    return urlAccessToken || getCookieToken();
  }

  function getUrlAccessToken() {
    return urlAccessToken;
  }

  function goToLogin() {
    $window.location = Config.loginBaseUrl;
  }

  function goToLogout() {
    const params = getAccessToken() ? `?access_token=${getAccessToken()}` : '';
    const ssoLoginUrl = ssoLoginUrls[$location.search().org];
    $window.location = ssoLoginUrl || `${Config.logoutUrl}${params}`;
  }

  return {
    goToLogin,
    resetUser,
    credentials,
    isAuth,
    authenticateByToken,
    getAccessToken,
    getCookieToken,
    loadCredentials,
    getUrlAccessToken,
  };
}
