import find from 'lodash/find';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import get from 'lodash/get';
import extend from 'lodash/extend';

// eslint-disable-next-line import/extensions
import { RCC_TOOLTIP_CONTENT } from 'react/modules/changes/Consts.jsx';
angular.module('bigpanda').controller('IncidentInfoCtrl', IncidentInfoCtrl);
const moment = require('moment');
const { loadRelatedChanges } = require('react/modules/changes/actions').default;

function IncidentInfoCtrl(
  $state,
  $scope,
  $log,
  $window,
  $filter,
  $mdMedia,
  $mdSidenav,
  ReduxBridgeService,
  $ngRedux,
  resolvedIncident,
  IncidentsService,
  IncidentsBackendService,
  pubSubService,
  ActivityFeedService,
  stateService,
  Permissions,
  UserFeatureTogglesService,
  PersonalSettingsStore,
  PERMISSIONS_NAMES,
  TabsService,
  ActivitiesService,
  $interval,
  $stateParams
) {
  const vm = this;
  const hasRelatedChangesRead =
    UserFeatureTogglesService.getToggle('related_changes') &&
    Permissions.get().find((permission) => permission === PERMISSIONS_NAMES.changes.read);
  const hasTopologyRead = Permissions.get().find(
    (permission) => permission === PERMISSIONS_NAMES.topology.read
  );
  const hasTopologyDisabled = UserFeatureTogglesService.getToggle('disable_topology');
  let events = [];

  pubSubService.on('incident.newData', onIncidentNewData, $scope);
  pubSubService.on('System.newDay', getEntities, $scope);
  pubSubService.on('streamConnector.reconnected', refreshState, $scope);
  pubSubService.on('Envy.refresh', refreshState, $scope);

  const incidentInterval = $interval(() => {
    refetchActivityEvents();
  }, 5000);

  if ($stateParams.incidentfullscreen == 'true') {
    pubSubService.broadcast('incidentfullscreen', {
      incidentFullScreen: 'true',
    });
  }

  vm.toggleFullScreen = () => {
    window.open(
      `/v2/incident/${currentEnvId}/${resolvedIncident.id}?folder=${$stateParams.folder}`,
      '_blank'
    );
  };

  const unsubscribe = ReduxBridgeService.subscribe('changes', (changesStore) => {
    vm.relatedChangesCount = get(changesStore, 'totalRelatedCount');
    vm.changesNotLoading = !get(changesStore, 'loading', false);
  });

  $scope.$on('$destroy', function () {
    if ($stateParams.incidentfullscreen === 'true') {
      pubSubService.broadcast('incidentfullscreen', {
        incidentFullScreen: null,
      });
    }

    unsubscribe();
    $interval.cancel(incidentInterval);
  });

  const changesState = get($ngRedux.getState(), 'modules.changes', {});
  if (
    hasRelatedChangesRead &&
    (changesState.totalRelatedCount === undefined ||
      changesState.incidentId !== resolvedIncident.id)
  ) {
    const endTimeFrame = get(resolvedIncident, 'start');
    $ngRedux.dispatch(
      loadRelatedChanges({
        incidentId: get(resolvedIncident, 'id'),
        endTimeFrame,
        startTimeFrame: moment.unix(endTimeFrame).subtract(1, 'hours').unix(),
      })
    );
  }

  vm.entities = [];
  vm.incident = resolvedIncident;
  vm.activityCount = 0;
  vm.reloadEvents = false;
  vm.eventsLoaded = false;
  vm.changesNotLoading = true;
  vm.hasRelatedChangesRead = !!hasRelatedChangesRead;
  vm.relatedChangesCount = changesState.totalRelatedCount;
  vm.isMobile = $mdMedia('max-width: 600px');

  vm.summaryTextContent = {
    label: 'Show potential RCC only',
    tooltip: RCC_TOOLTIP_CONTENT,
  };

  const { tabsList } = TabsService;

  vm.tabs = [
    {
      id: tabsList.overview.id,
      template: 'overview/incidents/info/incident_info_overview',
      name: tabsList.overview.name,
      selected: true,
      show: true,
    },
    {
      id: tabsList.alerts.id,
      template: 'overview/incidents/info/incident_info_entities_list',
      name: tabsList.alerts.name,
      selected: false,
      show: true,
    },
    {
      id: tabsList.topology.id,
      template: 'overview/incidents/info/incident_topology/incident_topology',
      name: tabsList.topology.name,
      selected: false,
      show: !hasTopologyDisabled && hasTopologyRead && !vm.isMobile,
    },
    {
      id: tabsList.changes.id,
      template: 'overview/incidents/info/changes/related_changes_tab',
      name: tabsList.changes.name,
      selected: false,
      show: hasRelatedChangesRead,
    },
    {
      id: tabsList.activity.id,
      template: 'overview/incidents/info/incident_info_activity_feed_tab',
      name: tabsList.activity.name,
      selected: false,
      show: true,
    },
  ];

  vm.selectedTab = vm.tabs[0];
  vm.events = [];
  vm.loaded = true;
  vm.selectTab = selectTab;
  vm.openLifecycle = openLifecycle;
  vm.getTabCounter = getTabCounter;
  vm.isTabLoaded = isTabLoaded;
  vm.hideStatusChangesActivity = true;
  vm.onFilterStatusChanges = () => {
    vm.hideStatusChangesActivity = !vm.hideStatusChangesActivity;
    updateActivityEvents();
  };
  vm.hideCounter = ({ id }) => {
    if (id === 'changes') {
      return !vm.getTabCounter(id);
    }
    return ['alerts', 'overview', 'topology'].includes(id);
  };

  const currentEnvId = stateService.getSelectedEnvironmentId();

  vm.currentEnvActions = {
    delete: Permissions.checkGranularPermission('action@delete', 'environments', currentEnvId),
    update: Permissions.checkGranularPermission('action@update', 'environments', currentEnvId),
  };

  vm.disableActionsV2Assignee = UserFeatureTogglesService.getToggle('disable_actions_v2_assignee');

  $scope.$watch(getVmActiveAlertsCount, setVmActiveAlertsCount);

  init();

  function getTabCounter(tabId) {
    let tabCounter = 0;

    if (tabId === 'alerts') {
      tabCounter = vm.activeAlertsCount;
    } else if (tabId === 'changes') {
      tabCounter = vm.relatedChangesCount;
    } else {
      tabCounter = vm.activityCount;
    }

    return tabCounter;
  }

  function isTabLoaded(tabId) {
    if (tabId === 'changes') {
      return vm.changesNotLoading;
    }
    if (tabId === 'overview') {
      return true;
    }
    return tabId === 'alerts' ? vm.dataLoaded : vm.activityLoaded;
  }

  function refreshState() {
    IncidentsService.clearCache();
    return IncidentsService.getIncidentById(vm.incident.id).then((incident) => {
      vm.incident = incident;
      init();
    });
  }

  function init() {
    pubSubService.broadcast('incident.clicked', vm.incident.id);
    angular.element($window).bind('resize', setIsMobie);

    getEntities().then(() => {
      if (!$mdMedia('gt-sm')) {
        const incidentInfoSideNav = $mdSidenav('incident-info');
        if (!incidentInfoSideNav.isOpen()) {
          incidentInfoSideNav.open();
        }
      }
    });

    getActivityEvents();
  }

  function setIsMobie() {
    vm.isMobile = $mdMedia('max-width: 600px');
  }

  function getVmActiveAlertsCount() {
    return filter(vm.incident.entities, { is_active: true }).length;
  }

  function setVmActiveAlertsCount() {
    vm.activeAlertsCount = vm.hasOverviewTab
      ? vm.incident.entities.length
      : getVmActiveAlertsCount();
  }

  function onIncidentNewData(event, data) {
    data = isArray(data) ? data : [data];
    data.forEach((currentIncident) => {
      if (currentIncident.id === vm.incident.id) {
        vm.incident = currentIncident;
        getEntities();
        pubSubService.broadcast('Incidents.focusedIncidentUpdated', vm.incident);
      }
    });
  }

  function onIncidentNewActivity(event, activity) {
    if (activity.id.toString() === vm.incident.id.toString()) {
      getActivityEvents();
    }
  }
  function selectTab(tabId) {
    if (tabId !== vm.selectedTab.id) {
      each(vm.tabs, (tab) => {
        if (tab.id.toLowerCase() === tabId) {
          tab.selected = true;
          vm.selectedTab = tab;
          if (['activity'].includes(vm.selectedTab.id)) {
            vm.hideStatusChangesActivity = false;
            updateActivityEvents();
          } else if (['overview'].includes(vm.selectedTab.id)) {
            vm.hideStatusChangesActivity = true;
            updateActivityEvents();
          }
        } else {
          tab.selected = false;
        }
      });
    }
  }

  function openLifecycle() {
    pubSubService.broadcast('IncidentLifecycle.open');
    $state.go('.lifecycle');
  }

  function filterActivityEvents(activityEvent) {
    if (!vm.hideStatusChangesActivity) {
      return activityEvent;
    }
    return ActivitiesService.filterStatusChanges(activityEvent);
  }

  function updateStatusCounters() {
    vm.statusChangesCount =
      events.length - events.filter(ActivitiesService.filterStatusChanges).length;
    vm.activityCount = filter(events, (event) => !event.newDay).length;
  }

  function updateActivityEvents() {
    vm.events = events.filter(filterActivityEvents);
    updateStatusCounters();
    addNewDateEvents(vm.events);
  }

  function refetchActivityEvents() {
    ActivityFeedService.getActivityEvents(vm.incident.id).then((activityEvents) => {
      if (events[0].timestamp < activityEvents[0].timestamp) {
        events = activityEvents;
        updateActivityEvents(events);
      }
    });
  }

  function getActivityEvents() {
    vm.activityLoaded = false;
    ActivityFeedService.getActivityEvents(vm.incident.id)
      .then((activityEvents) => {
        events = activityEvents;
        updateActivityEvents(events);
      })
      .then(() => {
        vm.activityLoaded = true;
      });
  }

  function addNewDateEvents(activityEvents) {
    if (activityEvents.length > 0) {
      let i = activityEvents.length - 1;
      let lastDate = activityEvents[i].date;
      while (i >= 0) {
        if (activityEvents[i].date === lastDate) {
          i--;
        } else {
          lastDate = activityEvents[i].date;
          activityEvents.splice(i + 1, 0, { date: lastDate, newDay: true });
          i = i--;
        }
      }
    }
  }

  function getEntities() {
    vm.dataLoaded = false;
    const eventsLoaded = vm.reloadEvents;

    return IncidentsBackendService.entities(vm.incident.id, vm.reloadEvents).then(
      (res) => {
        const allEntities = res.entities.map((entity) => {
          const filteredEntity = $filter('entityNormalizer')(entity, vm.incident);
          const existsEntity = find(vm.entities, { id: filteredEntity.id });

          return existsEntity ? angular.extend(existsEntity, filteredEntity) : filteredEntity;
        });

        allEntities.sort((a, b) => {
          if (a.status === 'ok' && b.status === 'ok') {
            return timestampSort(a, b);
          } else if (a.status === 'ok') {
            return 1;
          } else if (b.status === 'ok') {
            return -1;
          }

          return timestampSort(a, b);
        });

        if (allEntities.length > vm.entities.length) {
          pubSubService.broadcast('entitiesList.newEntities', allEntities);
        }

        vm.entities = allEntities;

        vm.dataLoaded = true;
        vm.eventsLoaded = eventsLoaded;
        pubSubService.broadcast('entitiesList.dataLoaded');

        function timestampSort(a, b) {
          return b.lastChangedTimestamp - a.lastChangedTimestamp;
        }
      },
      () => {
        $log.debug(`Can't get entities for ${vm.incident.id}`);
        vm.dataLoaded = true;
      }
    );
  }
}
