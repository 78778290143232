import { CodeSizes } from '@bp/kung-fu';
import countBy from 'lodash/countBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { memo, useMemo } from 'react';

import { extractTemplateToTokens } from '../../../../../../app/common/formulas';
import { ReferenceTag } from '../PreviewTags/types';
import { HighlightedNode } from './HighlightTag.css';

export interface CompositionTagProps {
  template: string;
  referenceTags: ReferenceTag;
}

export const CompositionTag = memo(({ template, referenceTags = {} }: CompositionTagProps) => {
  const tokens = useMemo(() => extractTemplateToTokens(template), [template]);
  const hasTags = useMemo(() => countBy(tokens, ({ type }) => type).tag > 0, [tokens]);

  if (isEmpty(referenceTags) && hasTags) {
    return null;
  }

  return (
    <>
      {tokens.map(({ value, type }, idx) => {
        const val = get(referenceTags, value, value);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <HighlightedNode key={`${val}-${idx}`} isHighlighted={type === 'tag'} size={CodeSizes.SMALL_CODE}>
            {val}
          </HighlightedNode>
        );
      })}
    </>
  );
});

export default CompositionTag;
