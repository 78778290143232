import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

export default function provideStore(TopLevelComponent) {
  const ProvidedTopLevelComponent = class ProvidedTopLevelComponent extends React.PureComponent {
    render() {
      const { store, ...rest } = this.props;

      return (
        <Provider store={store}>
          <TopLevelComponent {...rest} />
        </Provider>
      );
    }
  };

  ProvidedTopLevelComponent.propTypes = {
    store: PropTypes.shape({}).isRequired,
  };

  return ProvidedTopLevelComponent;
}
