import drop from 'lodash/drop';
d3.tip = require('d3-tip');

angular
  .module('bigpanda.reports')
  .factory('D3MultipleTimeseriesLinechartFactory', [
    '$filter',
    '$window',
    'TimeGranularityConfig',
    'D3Utils',
    D3MultipleTimeseriesLinechartFactory,
  ]);

function D3MultipleTimeseriesLinechartFactory($filter, $window, TimeGranularityConfig, D3Utils) {
  return function () {
    return new D3MultipleTimeseriesLinechart($filter, $window, TimeGranularityConfig, D3Utils);
  };
}

function D3MultipleTimeseriesLinechart($filter, $window, TimeGranularityConfig, D3Utils) {
  var self = this;

  var linesAnimationDuration = 750,
    linesAnimationDelay = 100,
    circlesAnimationDelay = (linesAnimationDuration + linesAnimationDelay) * 0.8,
    circlesAnimationDuration = 250;
  self.animationEnabled = true;

  self.margins = {
    desktop: {
      top: 50,
      bottom: 56,
      left: 70,
      right: 32,
    },
    mobile: {
      top: 50,
      bottom: 56,
      left: 70,
      right: 32,
    },
  };

  self.xScale = d3.scale.ordinal();
  self.yScale = d3.scale.linear();

  self.timeAxis = d3.svg.axis().innerTickSize(0).outerTickSize(0).tickPadding(17).orient('bottom');

  self.valueAxis = d3.svg.axis().innerTickSize(0).outerTickSize(0).tickPadding(17).orient('left');

  self.lineFunction = d3.svg.line();
  self.zeroCountsLine = d3.svg.line();

  self.pointTooltip = d3.tip().attr('class', 'd3-tip').attr('id', 'multiple-timeseries-tooltip');

  self.render = function render(selection, layoutName) {
    selection.each(function (data) {
      // Converts an array of data points (x, y1, y2, ... yn)
      // to an array of series arrays: [ [x1, ... xn], [y11, ... y1n], [y21, ... y2n], ... [ym1 ... ymn] ]
      data = [self.xValue].concat(self.yValues).map((accessor) => data.map((d) => accessor(d)));

      // Update scales and axes:
      self.xScale.domain(data[0]);
      self.timeAxis.scale(self.xScale);
      self.timeAxis.tickFormat((d) => $filter('granularityBasedFormat')(self.timeGranularity, d));

      var maxValue = d3.max(drop(data).map((series) => d3.max(series)));

      if (maxValue === 0) {
        maxValue = 1000;
      } // Arbitrary value to make things look nicer on the axis

      self.yScale.domain([0 - maxValue * 0.1, maxValue * 1.1]);
      self.valueAxis.scale(self.yScale);

      var svg = d3.select(this).select('svg').selectAll('g.chart-container').data([data]);

      // Create the chart components if they're not there
      var gEnter = svg.enter().append('g').attr('class', 'chart-container');
      gEnter.append('g').attr('class', 'time-axis axis');
      gEnter.append('g').attr('class', 'value-axis axis');
      gEnter.append('g').attr('class', 'legend');
      gEnter.append('g').attr('class', 'point-connector').append('line').attr('opacity', 0);

      // TODO: unhardcode this
      gEnter
        .select('.legend')
        .append('g')
        .attr('class', 'legend-bullets')
        .append('rect')
        .attr('class', 'alerts-bullet')
        .attr('y', -10)
        .attr('x', -12)
        .attr('width', 10)
        .attr('height', 10);
      gEnter
        .select('.legend-bullets')
        .append('rect')
        .attr('class', 'incidents-bullet')
        .attr('y', '7.3')
        .attr('x', -12)
        .attr('width', 10)
        .attr('height', 10);
      gEnter
        .select('.legend')
        .append('g')
        .attr('class', 'legend-labels')
        .attr('transform', 'translate(5, 0)')
        .append('text')
        .text('Alerts');
      gEnter.select('.legend-labels').append('text').attr('y', '17').text('Incidents');

      // Activate tooltip
      svg.call(self.pointTooltip);

      self.zeroCountsLine
        .x((d, i) => self.xScale(data[0][i]) + self.xScale.rangeBand() / 2)
        .y((d) => self.yScale(0));

      // Create line paths
      self.lineFunction
        .x((d, i) => self.xScale(data[0][i]) + self.xScale.rangeBand() / 2)
        .y((d) => self.yScale(d));

      var lines = svg.selectAll('g.line').data(drop(data));

      lines.enter().append('g').attr('class', 'line').append('path');

      lines.attr('class', (d, i) => `line ${self.seriesClasses[i]}`);

      // Create data point circles
      var circleGroups = svg.selectAll('g.circles').data(drop(data));

      circleGroups.enter().append('g').attr('class', 'circles');

      circleGroups.attr('class', (d, i) => `circles ${self.seriesClasses[i]}`);

      var circles = circleGroups.selectAll('circle').data((d) => d);

      // Create new elements
      circles.enter().append('circle');

      // Remove unneeded elements
      circles.exit().remove();

      // On-hover circle outlines; placed here because drawing order in SVG
      // determines z-order and we need them to be overlaid on the lines
      var outlineCircles = gEnter.append('g').attr('class', 'tooltip-outline-circles');
      outlineCircles
        .append('circle')
        .attr('class', 'colored-outline-circle')
        .attr('r', 10)
        .attr('opacity', 0);
      outlineCircles
        .append('circle')
        .attr('class', 'white-outline-circle')
        .attr('r', 7)
        .attr('opacity', 0);

      // Compute the layout
      d3.select(this).call(self.resize, layoutName);

      if (self.animationEnabled) {
        circles
          .attr('r', 0)
          .transition()
          .duration(circlesAnimationDuration)
          .delay(circlesAnimationDelay)
          .attr('r', 5);

        lines
          .select('path')
          .attr('d', self.zeroCountsLine)
          .transition()
          .duration(linesAnimationDuration)
          .delay(linesAnimationDelay)
          .attr('d', self.lineFunction);
      }
    });
  };

  self.resize = function (selection, layoutName) {
    selection.each(function (data) {
      var dimensions = d3.select(this).node().getBoundingClientRect();
      var svgWidthWithMargins = dimensions.width,
        svgHeightWithMargins = dimensions.height;

      if (svgWidthWithMargins <= 0 || svgHeightWithMargins <= 0) {
        return;
      }

      return d3
        .select(this)
        .call(self[`${layoutName}Layout`], svgWidthWithMargins, svgHeightWithMargins);
    });
  };

  self.desktopLayout = function desktopLayout(selection, widthWithMargins, heightWithMargins) {
    selection.each(function (data) {
      var width = widthWithMargins - (self.margins.desktop.left + self.margins.desktop.right),
        height = heightWithMargins - (self.margins.desktop.top + self.margins.desktop.bottom);

      self.xScale.rangeRoundBands([0, width], 0.05, 0.05);
      self.yScale.range([height, 0]);

      var svg = d3
        .select(this)
        .select('svg')
        .attr('width', width + (self.margins.desktop.left + self.margins.desktop.right))
        .attr('height', height + (self.margins.desktop.top + self.margins.desktop.bottom))
        .select('g.chart-container')
        .attr('transform', `translate(${self.margins.desktop.left},${self.margins.desktop.top})`);

      self.timeAxis.tickValues(D3Utils.fitTickValues(self.xScale.domain(), 55, width));
      svg.select('.time-axis').attr('transform', `translate(0, ${height})`).call(self.timeAxis);

      self.valueAxis.ticks(6);
      svg.select('.value-axis').call(self.valueAxis);

      svg.select('.legend').attr('transform', `translate(${width - 84}, -30)`);

      svg.selectAll('g.line').select('path').attr('d', self.lineFunction);

      svg
        .selectAll('g.circles')
        .selectAll('circle')
        .attr('cx', (d, i) => self.xScale(self.xValue(data[i])) + self.xScale.rangeBand() / 2)
        .attr('cy', (d) => self.yScale(d))
        .on('mouseover', null)
        .on('mouseout', null)
        .on('mouseover', function (d, i) {
          var values = svg
            .selectAll('g.circles')
            .data()
            .map((s) => s[i]);

          // Display tooltip
          self.pointTooltip
            .offset([-10, 0])
            .attr('class', 'd3-tip animate')
            .html((d) => {
              var commasFormatter = d3.format(',');
              var compression = $filter('eventCompression')(values[1], values[0]);
              var eventsSpan = `<span class="value-tooltip">${commasFormatter(values[1])}${
                values[1] === 1 ? ' alert' : ' alerts'
              }</span>`;
              var incidentsSpan = `<span class="value-tooltip">${commasFormatter(values[0])}${
                values[0] === 1 ? ' incident' : ' incidents'
              }</span>`;
              var arrowSpan = '<span class="arrow-tooltip">\u2192</span>';
              var compressionSpan = `'<span class="compression-tooltip">('${compression}% compression)</span>`;

              return `${eventsSpan + arrowSpan + incidentsSpan}<br/>${compressionSpan}`;
            })
            .show(d);

          // Display circle outline
          svg
            .select('.tooltip-outline-circles .colored-outline-circle')
            .attr('style', `stroke: ${$window.getComputedStyle(d3.select(this).node()).fill}`);
          svg
            .selectAll('.tooltip-outline-circles circle')
            .attr('cx', d3.select(this).attr('cx'))
            .attr('cy', d3.select(this).attr('cy'))
            .transition()
            .duration(circlesAnimationDuration)
            .attr('opacity', 1);

          // Display point connecting line
          var x1, x2;
          x1 = x2 = parseFloat(d3.select(this).attr('cx'));
          var y1 = self.yScale.range()[0],
            y2 = self.yScale.range()[1];

          svg
            .select('.point-connector line')
            .attr('x1', x1)
            .attr('x2', x2)
            .attr('y1', y1)
            .attr('y2', y2)
            .transition()
            .duration(circlesAnimationDuration)
            .attr('opacity', 1);
        })
        .on('mouseout', (d) => {
          self.pointTooltip.attr('class', 'd3-tip').hide();

          svg
            .selectAll('.tooltip-outline-circles circle')
            .transition()
            .duration(circlesAnimationDuration)
            .attr('opacity', 0);

          svg
            .select('.point-connector line')
            .transition()
            .duration(circlesAnimationDuration)
            .attr('opacity', 0);
        });
    });
  };

  self.mobileLayout = function mobileLayout(selection, widthWithMargins, heightWithMargins) {
    selection.each(function (data) {
      var width = widthWithMargins - (self.margins.mobile.left + self.margins.mobile.right),
        height = heightWithMargins - (self.margins.mobile.top + self.margins.mobile.bottom);

      self.xScale.rangeRoundBands([0, width], 0.05, 0.05);
      self.yScale.range([height, 0]);

      var svg = d3
        .select(this)
        .select('svg')
        .attr('width', width + (self.margins.mobile.left + self.margins.mobile.right))
        .attr('height', height + (self.margins.mobile.top + self.margins.mobile.bottom))
        .select('g.chart-container')
        .attr('transform', `translate(${self.margins.mobile.left},${self.margins.mobile.top})`);

      self.timeAxis.tickValues(D3Utils.fitTickValues(self.xScale.domain(), 55, width));
      svg.select('.time-axis').attr('transform', `translate(0, ${height})`).call(self.timeAxis);

      self.valueAxis.ticks(4);
      svg.select('.value-axis').call(self.valueAxis);

      svg.select('.legend').attr('transform', `translate(${width - 60}, -20)`);

      svg.selectAll('g.line').select('path').attr('d', self.lineFunction);

      svg
        .selectAll('g.circles')
        .selectAll('circle')
        .attr('cx', (d, i) => self.xScale(self.xValue(data[i])) + self.xScale.rangeBand() / 2)
        .attr('cy', (d) => self.yScale(d))
        .on('mouseover', null)
        .on('mouseout', null);
    });
  };

  self.animate = function (_) {
    if (!arguments.length) {
      return self.animationEnabled;
    }

    self.animationEnabled = _;
    return self;
  };

  self.x = function (_) {
    if (!arguments.length) {
      return self.xValue;
    }

    self.xValue = _;
    return self;
  };

  self.y = function (_) {
    if (!arguments.length) {
      return self.yValues;
    }

    self.yValues = _;
    return self;
  };

  self.setTimeGranularity = function (_) {
    if (!arguments.length) {
      return self.timeGranularity;
    }

    self.timeGranularity = _;
    return self;
  };

  self.setSeriesClasses = function (_) {
    if (!arguments.length) {
      return self.seriesClasses;
    }

    self.seriesClasses = _;
    return self;
  };
}
