import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { bp_gray_05 } from '@bp/pastel/colors';

const IconForButton = styled.i`
  font-size: 16px;
  color: ${({ isActive }) => (isActive ? '#0096e8' : bp_gray_05)};
  position: relative;
  right: 4px;
  top: 1px;
`;

const TextWrapper = styled.span`
  font-size: 12px;
  position: relative;
  top: -3px;
`;

const IconButton = ({ className, children, icon, isActive }) => (
  <button className={className}>
    <IconForButton isActive={isActive} className={icon} />
    <TextWrapper>{children}</TextWrapper>
  </button>
);

const StyledIconButton = styled(IconButton)`
  height: 24px;
  border: 1px solid black;
  border-radius: 3px;
  border: solid 1px #dddddd;
  color: #6b6b6b;
  width: ${({ width }) => (width ? `${width}px` : '94px')};
  border-color: ${({ isActive }) => (isActive ? '#0096e8' : bp_gray_05)};
`;
IconButton.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};
export default StyledIconButton;
