import {
  IRow, Table,
} from '@bp/kung-fu';
import React, { useState } from 'react';

import { DataTestsId } from '../../../../../../../../constants';
import { TableWrapper } from './AlertEnrichmentsTable.style';
import { AlertEnrichmentsModalProps } from './types';
import { alertEnrichmentMapper, columnData } from './utils';

function AlertEnrichmentsTable({ enrichments }: AlertEnrichmentsModalProps): JSX.Element {
  const [column, setColumn] = useState(columnData);
  const [rows, setRows] = useState<IRow[]>(alertEnrichmentMapper(enrichments) as IRow[]);

  return (
    <TableWrapper rowsLength={enrichments.length}>
      <Table
        data-testid={DataTestsId.alertEnrichmentDetailsTable}
        columns={column}
        setColumns={setColumn}
        rows={rows}
        setRows={setRows}
        width="100%"
        height="100%"
        rowHeight={35}
        isDraggable={false}
      />
    </TableWrapper>
  );
}

export default AlertEnrichmentsTable;
