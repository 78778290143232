import map from 'lodash/map';
angular.module('bigpanda').service('CustomTagUtils', CustomTagUtils);

function CustomTagUtils() {
  this.extractTemplateToTokens = extractTemplateToTokens;

  function extractTemplateToTokens(template) {
    return map(extractTokenArrayFromTemplate(template), (token) => {
      if (isTagToken(token)) {
        return createTagToken(token);
      }
      return createTextToken(token);
    });
  }

  function extractTokenArrayFromTemplate(template) {
    return template.split(/(\$\{[^}]+\})/);
  }

  function isTagToken(token) {
    return token.indexOf('${') === 0;
  }

  function createTagToken(token) {
    return {
      type: 'tag',
      value: token.replace('${', '').replace('}', ''),
    };
  }

  function createTextToken(token) {
    return {
      type: 'text',
      value: token,
    };
  }
}
