import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const Comment = ({ comment, richComment, suggestedByBp }) =>
  suggestedByBp && richComment ? (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(richComment) }} />
  ) : (
    comment
  );

Comment.propTypes = {
  comment: PropTypes.string,
  richComment: PropTypes.string,
  suggestedByBp: PropTypes.bool,
};

Comment.defaultProps = {
  comment: '',
  richComment: '',
  suggestedByBp: false,
};

export default Comment;
