import { darkTheme, Select } from '@bp/kung-fu';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import SelectWrapper from './index.style';
import { IEnvRulePrioritySelect } from './types';

function envRuleOperatorSelect(props: IEnvRulePrioritySelect): JSX.Element {
  const {
    selected,
    items,
    metadata,
    onChange,
  } = props;

  return (
    <ThemeProvider theme={darkTheme}>
      <SelectWrapper>
        <Select
          name={`ruleIndex_${metadata.ruleIndex}_andIndex_${metadata.andIndex}_operator`}
          value={selected}
          options={items}
          updateField={(name, value): void => onChange(value, metadata)}
          placeholder="Select operator..."
          labelKey="display"
        />
      </SelectWrapper>
    </ThemeProvider>
  );
}

export default envRuleOperatorSelect;
