import { react2angular } from 'react2angular';
import UserManagement from './components/UserManagement';
import actions from '../actions';

const routes = {};
const template = `<section class="bp-master-detail-view">
    <user-management class="left-panel" store="store" ></user-management>
    <div class="spacer"></div>
    <div ui-view="master-detail-info" class="master-detail-info" ></div>
  </section>`;

routes.appSettingsUsers = {
  url: '/user_management',
  views: {
    'settings-page-view': {
      template: template,
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
    'master-detail-info@app.settings.user_management': {
      template: '<user-info class="react-settings-info" store="store" />',
      controller: ($scope, $ngRedux) => {
        'ngInject';

        $scope.store = $ngRedux;
      },
    },
  },
  onEnter: ($transitions, pubSubService) => {
    'ngInject';

    $transitions.onSuccess({ to: 'app.settings.user_management' }, () => {
      pubSubService.broadcast('settingsPageChanged', 'users');
    });
  },
  onExit: ($ngRedux) => {
    'ngInject';

    $ngRedux.dispatch(actions.routeExit());
  },
};

angular.module('bigpanda').component('userManagement', react2angular(UserManagement));

export default routes;
