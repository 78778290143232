import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { BamTable } from '@bp/bam';
import { Div, HelpTooltip } from '@bp/kung-fu';
import cn from 'classnames';
import TextSeparator from '../TextSeparator/TextSeparator';

import styles from './DetailsTable.scss';
import PopupHoc from '../../../utils/hoc/PopupHoc';

class DetailsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columnsSettings,
      selectedRowIndex: null,
    };
  }

  onColumnsChanged = (columns) => this.setState({ columns });

  onRowClicked = ({ row } = {}, e) => {
    const { setPopupProps, DetailsPopup } = this.props;
    const { selectedRowIndex } = this.state;

    if (selectedRowIndex === row._index) {
      return this.deselectRow();
    }

    e.nativeEvent.stopImmediatePropagation();
    requestAnimationFrame(() => {
      const popupProps = {
        onClose: this.deselectRow,
        eventsEnabled: false,
        open: true,
        referenceElement: this.tableRef.current,
        children: (
          <div className={styles['details-container']}>
            <DetailsPopup item={row._original} onClose={() => setPopupProps({ open: false })} />
          </div>
        ),
        placement: 'left-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport',
          },
          offset: {
            enabled: true,
            offset: 0,
          },
        },
      };
      this.setState({ selectedRowIndex: row._index }, () => setPopupProps(popupProps));
    });
  };

  getTrProps = (state, rowInfo = {}) => ({
    className: cn({
      [styles['row-selected']]: this.state.selectedRowIndex === get(rowInfo, 'row._index'),
    }),
  });

  deselectRow = () => this.setState({ selectedRowIndex: null });

  tableRef = React.createRef();

  render() {
    const { columns, selectedRowIndex } = this.state;
    const {
      items,
      secondaryTitle,
      helperContent,
      title,
      counter,
      getTdProps,
      'aria-label': ariaLabel,
    } = this.props;
    return (
      <div>
        <div className={styles['headline-container']}>
          <span className={styles['headline-container-primary']}>
            {title} <span className={styles['headline-container-counter']}>{counter}</span>
          </span>
          {secondaryTitle && (
            <React.Fragment>
              <TextSeparator />
              <span className={styles['headline-container-secondary']}>{secondaryTitle}</span>
            </React.Fragment>
          )}
          <Div display="inline-block" marginStart="5px">
            <HelpTooltip text={helperContent} />
          </Div>
        </div>
        <div ref={this.tableRef}>
          <BamTable
            id="detailsTable"
            data={items}
            columns={columns}
            onColumnsChanged={this.onColumnsChanged}
            onRowClicked={this.onRowClicked}
            getTrProps={this.getTrProps}
            getTdProps={getTdProps}
            selectedRowIndex={selectedRowIndex}
            aria-label={ariaLabel || title}
          />
        </div>
      </div>
    );
  }
}
DetailsTable.propTypes = {
  'aria-label': PropTypes.string,
  title: PropTypes.string.isRequired,
  counter: PropTypes.string.isRequired,
  columnsSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
  DetailsPopup: PropTypes.node.isRequired,
  helperContent: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      condition: PropTypes.string,
    })
  ),
  secondaryTitle: PropTypes.string,
  setPopupProps: PropTypes.func.isRequired,
  getTdProps: PropTypes.func,
};

DetailsTable.defaultProps = {
  'aria-label': undefined,
  items: undefined,
  secondaryTitle: undefined,
  getTdProps: () => {},
};

export default PopupHoc(DetailsTable);
