angular
  .module('bigpanda')
  .factory(
    'StateValidatorService',
    ($rootScope, $state, IncidentValidatorService, AppValidatorService) => {
      var stateValidatorServiceInterface = {
        getIncidentValidatorService: () => IncidentValidatorService,
        getAppValidatorService: () => AppValidatorService,
      };

      $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
        if (toState.data) {
          if (
            validate(toState.data.onRootStart, event, toState, toParams) ||
            validate(toState.data.onPageStart, event, toState, toParams) ||
            validate(toState.data.onStart, event, toState, toParams)
          ) {
          }
        }
      });

      function validate(validationFunc, event, toState, toParams) {
        if (angular.isFunction(validationFunc)) {
          var result = validationFunc(stateValidatorServiceInterface, toState, toParams);
          if (result && result.to) {
            event.preventDefault();
            $state.go(result.to, result.params, result.options);
            return true;
          }
        }
        return false;
      }

      $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
        if (toState.data && angular.isFunction(toState.data.onPageSuccess)) {
          toState.data.onPageSuccess(stateValidatorServiceInterface, toState, toParams);
        }

        if (toState.data && angular.isFunction(toState.data.onSuccess)) {
          toState.data.onSuccess(stateValidatorServiceInterface, toState, toParams);
        }
      });

      return stateValidatorServiceInterface;
    }
  );
