import trim from 'lodash/trim';

import { uniqueNameValidator } from '../../../alert-filtering/src/modal/validators/uniqueNameValidator';
import { Tag } from '../../types';
import { UnifiedTag } from '../components/RuleTypeForms/types';

const tagNameValidationRegex = /^[a-z][a-z0-9.\-_\s]{0,63}$/;

interface Options {
  tags: Tag[];
  reservedTags: UnifiedTag[];
  value: string;
}

export const enum TagNameValidationError {
  InvalidLength = 'Name is required',
  InvalidRegex = 'Tag name is not valid',
  ReservedTag = 'is a reserved tag name',
  None = '',
}

export const validateTagName = ({ tags, reservedTags = [], value }: Options): string => {
  const trimmedValue = trim(value);

  if (!trimmedValue.length) return TagNameValidationError.InvalidLength;

  const uniqueNameError = uniqueNameValidator<UnifiedTag>(reservedTags, 'name', value, `'${value}' ${TagNameValidationError.ReservedTag}`)
  || uniqueNameValidator<Tag>(tags, 'name', value);
  if (uniqueNameError) return uniqueNameError;

  if (!tagNameValidationRegex.test(value)) return TagNameValidationError.InvalidRegex;

  return TagNameValidationError.None;
};

export default {
  validateTagName,
};
