import React from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme, EditIcon } from '@bp/kung-fu';
import { BamConfirmModal, BamEmptyMessage, BamTile, BamToggle } from '@bp/bam';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import provideStore from 'react/utils/provideStore';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import { selectors as featureTogglesSelectors } from 'react/user/feature_toggles';
import IncidentLabelsActions from '../../actions/IncidentLabelsActions';
import selectors from '../../selectors';
import actions from '../../actions';
import { incidentTagTypes } from '../../constants';
import IncidentLabelModal from '../../modals/IncidentLabelModal';
import PriorityTagInfo from './partials/PriorityTagInfo';
import TextTagInfo from './partials/TextTagInfo';
import styles from './IncidentLabelsInfo.scss';
import { BP_SYSTEM_USERNAME } from '../../../../../../common/constants';
import { formatTimestamp } from '../../utils';

class IncidentLabelsInfo extends React.PureComponent {
  toggleTagClicked = () => {
    const { incidentLabel } = this.props;
    if (!incidentLabel.active) {
      this.toggleIncidentLabel();
    }
  };

  toggleIncidentLabel = () => {
    const { incidentLabel, updateIncidentLabel } = this.props;
    const { id, active } = incidentLabel;
    const incidentLabelDefinition = { ...incidentLabel };
    incidentLabelDefinition.active = !active;
    updateIncidentLabel({ id, ...incidentLabelDefinition });
  };

  render() {
    const getContentByType = () => {
      const {
        incidentLabel: { type },
        featureToggles,
      } = this.props;
      switch (type) {
        case incidentTagTypes.PRIORITY:
          return (
            <SettingsInfoScreen.Section>
              <PriorityTagInfo featureToggles={featureToggles} incidentLabel={incidentLabel} />
            </SettingsInfoScreen.Section>
          );
        case incidentTagTypes.TEXT:
        case incidentTagTypes.MULTI_VALUE:
          return (
            <SettingsInfoScreen.Section>
              <TextTagInfo featureToggles={featureToggles} incidentLabel={incidentLabel} />
            </SettingsInfoScreen.Section>
          );
        default:
          return null;
      }
    };

    const getSettingsActions = () => {
      const {
        actionPermissions,
        incidentLabel: { id },
      } = this.props;

      if (actionPermissions.hasEditPermission) {
        return (
          <div className={styles.itemActions}>
            <IncidentLabelModal
              triggerProps={{
                variant: 'action-color',
                size: 'large',
                text: 'Edit Incident Tag',
                icon: <EditIcon />,
              }}
              title="Edit Incident Tag"
              titleIcon="bp-icon-edit"
              currentIncidentLabelId={id}
              mode="edit"
            />
          </div>
        );
      }
    };
    const { incidentLabel, actionPermissions, isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    if (!incidentLabel) {
      return (
        <BamEmptyMessage header="NO INCIDENT TAG SELECTED">
          Select an incident tag to see details
        </BamEmptyMessage>
      );
    }

    const createdByUserName = incidentLabel.createdByUser
      ? incidentLabel.createdByUser.name
      : BP_SYSTEM_USERNAME;
    const updatedByUserName = incidentLabel.updatedByUser
      ? incidentLabel.updatedByUser.name
      : BP_SYSTEM_USERNAME;
    const { created_at: createdAt, updated_at: updatedAt } = incidentLabel;

    const deactivateModal = (
      <BamConfirmModal
        icon="bp-icon-power-off"
        headerTitle="Deactivate Incident Tag"
        content="Are you sure you want to deactivate this Incident Tag?"
        note="Note: New Incidents will not contain this Incident Tag."
        contentTitle={incidentLabel ? incidentLabel.destination : ''}
        contentTitleType="string"
        action={this.toggleIncidentLabel}
        actionText="Deactivate"
        trigger={
          <BamToggle
            label="Active:"
            labelPosition="left"
            checked={incidentLabel.active}
            color="green"
            big
            onClick={this.toggleTagClicked}
          />
        }
        destructive={false}
        openCondition={() => incidentLabel.active}
      />
    );

    const activity = (
      <SettingsInfoScreen.Section>
        {createdAt && (
          <SettingsInfoScreen.ActivityRow
            title="Created By:"
            name={createdByUserName}
            value={formatTimestamp(createdAt)}
          />
        )}
        {updatedAt && (
          <SettingsInfoScreen.ActivityRow
            title="Updated By:"
            name={updatedByUserName}
            value={formatTimestamp(updatedAt)}
          />
        )}
      </SettingsInfoScreen.Section>
    );

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsInfoScreen
          topActions={<IncidentLabelsActions incidentLabel={incidentLabel} />}
          header={
            <BamTile
              bamStatus={incidentLabel.active ? 'ok' : 'off'}
              headerRow={{
                title: incidentLabel.name,
                toggle: actionPermissions.hasEditPermission ? deactivateModal : null,
              }}
            />
          }
          content={getContentByType()}
          actions={getSettingsActions()}
          activity={activity}
        />
      </ThemeProvider>
    );
  }
}

IncidentLabelsInfo.propTypes = {
  incidentLabel: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    id: PropTypes.string,
    type: PropTypes.string,
    createdByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    updatedByUser: PropTypes.shape({
      name: PropTypes.string,
    }),
    created_at: PropTypes.number,
    updated_at: PropTypes.number,
    destination: PropTypes.string,
    name: PropTypes.string,
  }),
  actionPermissions: PropTypes.shape({ hasEditPermission: PropTypes.bool }).isRequired,
  updateIncidentLabel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  featureToggles: PropTypes.shape({}).isRequired,
};

IncidentLabelsInfo.defaultProps = {
  incidentLabel: null,
};

function mapStateToProps(state, ownProps) {
  const incidentLabel = selectors.getIncidentLabel(state, ownProps.incidentLabelId);
  const actionPermissions = selectors.getActionsPermissions(state);
  const featureToggles = featureTogglesSelectors.getFeatureToggles(state);
  const isLoading = selectors.isLoading(state);
  return {
    incidentLabel,
    actionPermissions,
    isLoading,
    featureToggles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateIncidentLabel: (incidentLabel) => dispatch(actions.updateIncidentLabel(incidentLabel)),
  };
}

export default provideStore(connect(mapStateToProps, mapDispatchToProps)(IncidentLabelsInfo));
