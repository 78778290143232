import { StatusEnum } from '@bp/kung-fu';

export const NO_DATA = 'NO_DATA';

export enum AuditStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DELETED = 'Deleted',
  INACTIVE = 'Inactive',
}

export const StatusToBadge = {
  [AuditStatus.ACTIVE]: StatusEnum.ACTIVE,
  [AuditStatus.PENDING]: StatusEnum.PENDING,
  [AuditStatus.DELETED]: StatusEnum.CRITICAL,
  [AuditStatus.INACTIVE]: StatusEnum.OFF,
};

export enum FunctionType {
  Extraction = 'extraction',
  Composition = 'composition',
  MultiType = 'Multi Type',
}
