import find from 'lodash/find';
import get from 'lodash/get';
import reject from 'lodash/reject';
import map from 'lodash/map';

angular.module('bigpanda').service('TargetSystemsStore', TargetSystemsStore);
const PARAMS_TEMPLATE_PREFIX = 'overview/incidents/actions/share/systems/';

function TargetSystemsStore(UserIntegrationsStore, DisplayCommonService, deepCloneObject) {
  this.getAll = getAll;
  this.normalizeIntegration = normalizeIntegration;
  this.getById = getById;
  this.getSystemParams = getSystemParams;

  function getSystemParams(system) {
    return `${PARAMS_TEMPLATE_PREFIX}${system.sendVia || system.parentId}/${
      system.sendVia || system.parentId
    }_params`;
  }

  function getById(systemId, sharedOptionId, shareOptDisplayName, shareOptMessage) {
    return getAll().then(
      (targetIntegrations) =>
        targetIntegrations.find(
          (targetIntegration) =>
            (sharedOptionId && get(targetIntegration, 'shareOptions.id') === sharedOptionId) ||
            (shareOptDisplayName && targetIntegration.name === shareOptDisplayName) ||
            (shareOptMessage &&
              get(targetIntegration, 'shareOptions.id') === `${systemId}.${shareOptMessage}`)
        ) || find(targetIntegrations, { id: systemId })
    );
  }

  function getAll() {
    return UserIntegrationsStore.getIntegrations().then((integrations) => {
      let simpleIntegrations = reject(integrations, (integration) => integration.type !== 'target');
      simpleIntegrations = reject(simpleIntegrations, (integration) => integration.share_options);
      simpleIntegrations = map(simpleIntegrations, (integration) =>
        normalizeIntegration(integration)
      ).filter((item) => !!item);

      const shareOptionsIntegrations = retriveShareOptions(integrations);

      const targetIntegrations = [...simpleIntegrations, ...shareOptionsIntegrations];

      targetIntegrations.sort((a, b) => {
        if (a.order === b.order) {
          return sortCompareByStrings(a.name, b.name);
        }

        return a.order - b.order;
      });

      return targetIntegrations;
    });
  }

  function sortCompareByStrings(strA, strB) {
    const nameA = strA.toUpperCase();
    const nameB = strB.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  function retriveShareOptions(integrations) {
    const normalizedOptions = [];
    const integrationsWithOptions = integrations
      .filter((integration) => integration.share_options)
      .filter((integration) =>
        find(DisplayCommonService.getTargetSystemsTemplates(), {
          id: integration.parent_system_id,
        })
      );

    integrationsWithOptions.forEach((integrationWithOptions) =>
      integrationWithOptions.share_options.forEach((option) =>
        normalizedOptions.push(normalizeShareIntegration(integrationWithOptions, option))
      )
    );
    return normalizedOptions;
  }

  function normalizeShareIntegration(integration, option) {
    const parentTemplate = find(DisplayCommonService.getTargetSystemsTemplates(), {
      id: integration.parent_system_id,
    });

    const targetIntegration = deepCloneObject.cloneDeep(parentTemplate);
    targetIntegration.id = integration.target_system_id;
    targetIntegration.name = option.display;
    targetIntegration.icon = `<i class="bp-icon-${option.type}"></i>`;
    targetIntegration.label = `<i class="bp-icon-${option.type}"></i>${option.display}`;
    targetIntegration.datasetOptions = integration.dataset_options;
    targetIntegration.shareOptions = option;
    return targetIntegration;
  }

  function getIconClass(iconLabel) {
    const iconNameMatch = iconLabel.match(/\sclass="(.*?)"/);
    return iconNameMatch ? iconNameMatch[1] : '';
  }

  function normalizeIntegration(rawTargetIntegration) {
    const parentTemplate = find(DisplayCommonService.getTargetSystemsTemplates(), {
      id: rawTargetIntegration.parent_system_id,
    });
    if (parentTemplate) {
      const targetIntegration = deepCloneObject.cloneDeep(parentTemplate);
      targetIntegration.id = rawTargetIntegration.target_system_id;
      targetIntegration.iconName = rawTargetIntegration.parent_system_id;
      targetIntegration.name = DisplayCommonService.getTargetSystemName(
        targetIntegration,
        rawTargetIntegration.name
      );
      targetIntegration.icon = targetIntegration.label;
      targetIntegration.iconClass = getIconClass(targetIntegration.label);
      targetIntegration.label += targetIntegration.name;
      targetIntegration.datasetOptions = rawTargetIntegration.dataset_options;
      return targetIntegration;
    }
  }
}
