import { BamFiltersPlaceHolder } from '@bp/bam';
import PropTypes from 'prop-types';
import React from 'react';

import { ANY_OPTION_VALUE, DROPDOWN_FILTERS_ALL_ITEM_OPTION } from '../../../core/constants';
import FiltersDropdown from './FiltersDropdown/FiltersDropdown';

const DropdownOptionType = PropTypes.shape({
  value: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.shape({
    component: PropTypes.node.isRequired,
    position: PropTypes.string,
  }),
});

function FiltersComponent({ filtersDropDownState, onChange, data }) {
  const {
    environment: { value: ruleEnvironmentValue, text: ruleEnvironmentText },
    channel: { value: channelValue, text: channelText },
    recipients: { value: recipientValue, text: recipientText },
    status: { value: statusValue, text: statusText },
  } = filtersDropDownState;

  const dropdownElements = [
    <FiltersDropdown
      data={data.environment}
      selectedValue={ruleEnvironmentValue}
      selectedText={ruleEnvironmentText}
      defaultText="Environment"
      onChange={(e, selection) => onChange({ environment: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.channel}
      selectedValue={channelValue}
      selectedText={channelText}
      defaultText="Channel"
      onChange={(e, selection) => onChange({ channel: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.recipients}
      selectedValue={recipientValue}
      selectedText={recipientText}
      defaultText="Recipient"
      onChange={(e, selection) => onChange({ recipients: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
    <FiltersDropdown
      data={data.status}
      selectedValue={statusValue}
      selectedText={statusText}
      defaultText="Status"
      onChange={(e, selection) => onChange({ status: selection })}
      anyOptionValue={ANY_OPTION_VALUE}
    />,
  ];

  return (
    <BamFiltersPlaceHolder
      filterElementsArray={dropdownElements}
      shouldShowClearButton={[ruleEnvironmentValue, channelValue, recipientValue, statusValue].some(
        (v) => v !== ANY_OPTION_VALUE,
      )}
      onClearButtonClick={() => {
        onChange({ ...DROPDOWN_FILTERS_ALL_ITEM_OPTION });
      }}
    />
  );
}
FiltersComponent.propTypes = {
  data: PropTypes.shape({
    environment: PropTypes.arrayOf(DropdownOptionType),
    channel: PropTypes.arrayOf(DropdownOptionType),
    recipients: PropTypes.arrayOf(DropdownOptionType),
    status: PropTypes.arrayOf(DropdownOptionType),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  filtersDropDownState: PropTypes.shape({
    environment: DropdownOptionType,
    channel: DropdownOptionType,
    recipients: DropdownOptionType,
    status: DropdownOptionType,
  }).isRequired,
};
export default FiltersComponent;
