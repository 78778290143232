angular.module('bigpanda').factory('inviteOpener', inviteOpener);

function inviteOpener($state, ModalService) {
  return {
    open: () =>
      ModalService.showModal({
        templateUrl: 'settings/user_management/invite/user_invite',
        controller: 'UserInviteController',
        controllerAs: 'inviteVm',
        bindToController: true,
        ariaLabel: 'Invite a User',
      }).finally(() => {
        if (['app.integrations.welcome'].indexOf($state.current.name) > -1) {
          $state.go('^');
        }
      }),
  };
}
