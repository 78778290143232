import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
angular.module('bigpanda').directive('extractionTagPreview', extractionTagPreview);

function extractionTagPreview() {
  return {
    restrict: 'E',
    templateUrl: 'settings/correlation/custom_tags/popup/utils/extraction_tag_preview',
    scope: {
      tag: '=',
      pattern: '=',
    },
    controllerAs: 'tagPreviewVm',
    bindToController: true,
    controller: controller,
  };
}

function controller() {
  const vm = this;
  vm.tagDisplay = '';
  vm.$onInit = init;

  function init() {
    const tagKeys = keys(vm.tag);
    const tagValue = vm.tag[tagKeys[0]];
    vm.tagDisplay = isArray(tagValue) ? tagValue[0] : tagValue;
  }
}
