import React from 'react';
import PropTypes from 'prop-types';
import { SSODisableModal } from '../modals';
import { Content, HorizontalLayout, Title, Description } from './components';

const SSOPostActivation = ({ providerName, disableSSO }) => (
  <HorizontalLayout>
    <Content>
      <Title>{providerName} Activated!</Title>
      <Description>
        {`Single Sign-on via ${providerName} is activated and validated.
            If you want to replace ${providerName} with another Identity Provider, please disable Single Sign-on, and re-activate it.`}
      </Description>
      <hr />
      <Title>Disable SSO</Title>
      <Description>
        Click below to clear your configuration settings and disable SSO. Notice that once you
        disable Single Sign-on, users will not be able to login with {providerName} and they might
        have to reset their BigPanda passwords by clicking the &#39;Forgot your password?&#39; link
        in the login page.
      </Description>
      <SSODisableModal
        onAction={disableSSO}
        triggerProps={{
          variant: 'danger',
          size: 'medium',
          text: 'Disable SSO',
          name: 'Disable SSO',
        }}
      />
    </Content>
  </HorizontalLayout>
);

SSOPostActivation.propTypes = {
  providerName: PropTypes.string.isRequired,
  disableSSO: PropTypes.func.isRequired,
};

export default SSOPostActivation;
