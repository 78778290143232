import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './single_tab.scss';
import singleTabPropType from './props/singleTabPropTypes';

const SingleTab = ({ tabObj, isSelected, onClick, height, isFixed, fixedHeight, fixedWidth }) => {
  const tabStyle = isFixed
    ? {
        height: `${fixedHeight}px`,
        width: `${fixedWidth}px`,
      }
    : {
        height: `${height}px`,
        width: 'auto',
      };
  return (
    <button
      className={cn(styles.SingleTab, {
        [styles.selectedTab]: isSelected,
      })}
      onClick={(e) => onClick(tabObj)}
      name={tabObj.name}
      style={tabStyle}
    >
      <img src={tabObj.imgSrc} alt={tabObj.name} className={styles.tabImage} />
      <div className={styles.barHighlight} />
    </button>
  );
};

SingleTab.propTypes = {
  tabObj: singleTabPropType.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isFixed: PropTypes.bool.isRequired,
  height: PropTypes.number,
  fixedHeight: PropTypes.number,
  fixedWidth: PropTypes.number,
};

SingleTab.defaultProps = {
  height: undefined,
  fixedHeight: undefined,
  fixedWidth: undefined,
};

export default SingleTab;
