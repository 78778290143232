import { generateBaseSettingsActions } from '../../../../app/react/modules/settings/redux.utils';
import actionTypes from './actionTypes';

const actions = generateBaseSettingsActions(actionTypes);

actions.loadPlans = (payload) => ({ type: actionTypes.LOAD_PLANS, payload });
actions.loadPlansSuccess = (payload) => ({
  type: actionTypes.LOAD_PLANS_SUCCESS,
  payload,
});
actions.createOrUpdatePlan = (payload) => ({
  type: actionTypes.CREATE_OR_UPDATE_PLAN,
  payload,
});
actions.updatePlanEnd = (payload) => ({ type: actionTypes.UPDATE_PLAN_END, payload });
actions.deletePlan = (payload) => ({ type: actionTypes.DELETE_PLAN, payload });
actions.stopPlan = (payload) => ({ type: actionTypes.STOP_PLAN, payload });
actions.loadInitialPlans = (payload) => ({
  type: actionTypes.LOAD_INITIAL_PLANS,
  payload,
});

export default actions;
