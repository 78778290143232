import { ITooltip, Tooltip } from '@bp/kung-fu';
import React, { memo } from 'react';

interface IProps {
  isActive?: boolean;
  children: ITooltip['children'];
}

export default memo(({ isActive = false, children }: IProps) => (
  <Tooltip
    text="Only the End Repeat date can be changed for plans that have already occurred"
    isActive={isActive}
    placement="top"
    maxWidth="255px"
  >
    {children}
  </Tooltip>
));
