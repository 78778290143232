angular.module('bigpanda').service('BootstrapUtilsService', BootstrapUtilsService);

function BootstrapUtilsService(previousStateService) {
  this.isIncidentsState = () => {
    let isIncidentsState = true;
    const previousState = previousStateService.getPreviousState();
    if (previousState) {
      isIncidentsState = previousState.name.indexOf('app.overview.incidents') === 0;
    }

    return isIncidentsState;
  };
}
