import extend from 'lodash/extend';
import map from 'lodash/map';
angular
  .module('bigpanda')
  .service('ReportsService', function ReportsService($q, ReportsBackendService, WidgetsService) {
    var self = this;

    self.reports = {};

    self.cloneReport = cloneReport;
    self.createReport = createReport;
    self.deleteReport = deleteReport;
    self.getReport = getReport;
    self.getReports = getReports;
    self.getSnapshotMetadata = getSnapshotMetadata;
    self.updateReport = updateReport;

    init();

    function getReports() {
      return $q.when(self.reports);
    }

    function getReport(id) {
      return getReports().then((reports) => {
        if (reports[id] === undefined) {
          return ReportsBackendService.getReport(id).then((report) => {
            // The backend returns the report along with all of its widgets, so discard them
            // and only keep the IDs
            report.widgets = report.widgets.map((widget) => widget._id);

            // Cache the report
            reports[report._id] = report;

            return report;
          });
        }
        return reports[id];
      });
    }

    function cloneReport(id, reportOverrides, widgetOverrides) {
      // Start by retrieving the report to be cloned
      var newWidgetsIds = [];
      return getReport(id).then((report) =>
        // Clone all of its widgets
        $q
          .all(
            report.widgets.map((widgetId) => WidgetsService.cloneWidget(widgetId, widgetOverrides))
          )
          .then((widgets) => {
            newWidgetsIds = map(widgets, '_id');
            // Once we have the new widget IDs, create a new report
            var newReport = extend(
              {
                title: report.title,
                description: report.description,
                created_at: moment().unix(),
                widgets: newWidgetsIds,
              },
              reportOverrides
            );

            return ReportsBackendService.createReport(newReport);
          })
          .then((newReportId) =>
            // Retrieve it from the backend and insert it into cache
            getReports().then((reportMap) =>
              getReport(newReportId).then((newReport) => {
                newReport.widgets = newWidgetsIds;
                reportMap[newReport._id] = newReport;
                return newReport;
              })
            )
          )
      );
    }

    function createReport(report) {
      return ReportsBackendService.createReport(report).then((newReportId) =>
        getReport(newReportId)
      );
    }

    function updateReport(id) {
      return getReport(id).then((report) => {
        // Don't modify the system reports
        if (report.system) {
          return $q.when(report);
        }

        var modifiedReport = angular.copy(report);

        // Don't try to modify _id - Mongo doesn't like that
        delete modifiedReport._id;

        return ReportsBackendService.updateReport(id, modifiedReport);
      });
    }

    function deleteReport(id) {
      return ReportsBackendService.deleteReport(id)
        .then(getReports)
        .then((reports) =>
          // Delete the widgets
          $q
            .all(reports[id].widgets.map((widgetId) => WidgetsService.deleteWidget(widgetId)))
            .then(() => {
              // Remove the report from the cache
              delete reports[id];
            })
        );
    }

    function getSnapshotMetadata(id) {
      return ReportsBackendService.getSnapshotMetadata(id);
    }

    function init() {
      self.reports = ReportsBackendService.getReports().then((returnedReports) =>
        // Keep the reports in a map indexed by id
        returnedReports.reduce((reportMap, currentReport) => {
          reportMap[currentReport._id] = currentReport;
          return reportMap;
        }, {})
      );
    }
  });
