import includes from 'lodash/includes';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import queryString from 'querystring';
import { connect } from 'react-redux';
import classnames from 'classnames';

import selectors from '../../selectors';
import { selectors as featureTogglesSelectors } from '../../../../user/feature_toggles';
import { selectors as permissionsSelectors } from '../../../../user/permissions';
import { loadCustomizations } from '../../actions';
import roleManagementActions from '../../../../modules/settings/roles_management/roles_list/actions';
import { loadUserData } from '../../../../user/actions';

import { NavigationImage, NavigationItem, NavigationLink } from './components';
import permissions from '../../../../../common/routing/permissions';
import { customizationsPropType, environmentPropType } from '../../types';
import {
  getPageTitleByRoute,
  IncidentsTitle,
  routesConstants,
  routesToTitles,
  singleIncidentRoute,
} from './constants';
import BigPandaLogoImage from '../../assets/BigPandaLogoImage.svg';
import BigPandaLogoText from '../../assets/BigPandaLogoText.svg';
import styles from './navigation_bar.scss';

const investigatorParams = {
  query: '',
  timeframe: '-7d',
  sort: 'last_change',
};
const analyticsIgnoreFT = 'ignore_analytics';
const unifiedAnalyticsFT = 'smart_analytics_enabled';

const NavigationBar = ({
  currentRouteName,
  customizations,
  defaultEnvironment,
  environments,
  featureToggles,
  loadCustomizations,
  loadEnvironments,
  loadUserData,
  navigateToFeed,
  toggleSideNav,
  toggleSearch,
  userPermissions,
}) => {
  useEffect(() => {
    if (!customizations) {
      loadCustomizations();
    }
    if (!environments) {
      loadEnvironments();
    }
    if (!userPermissions) {
      loadUserData();
    }
  });

  const [currentTitle, setCurrentTitle] = useState();
  const [isSingleIncident, setIsSingleIncident] = useState();

  useEffect(() => {
    setCurrentTitle(getPageTitleByRoute(currentRouteName));
    setIsSingleIncident(currentRouteName === singleIncidentRoute);
  }, [currentRouteName]);

  const getEnvironmentFolder = (environmentId) =>
    environmentId === routesConstants.NO_PERMISSIONS_ENV
      ? routesConstants.NO_PERMISSIONS_FOLDER
      : routesConstants.ACTIVE;

  const userHasPermissionToView = (permissionName) =>
    permissionName && includes(userPermissions, permissionName);

  const userBlockedByFeatureToggle = (hideByFeatureToggle) =>
    hideByFeatureToggle && get(featureToggles, hideByFeatureToggle);

  const shouldDisplay = (permissionName, hideByFeatureToggle = null) =>
    userHasPermissionToView(permissionName) && !userBlockedByFeatureToggle(hideByFeatureToggle);

  const defaultEnvironmentId = get(defaultEnvironment, '_id', '');

  const environmentFolder = getEnvironmentFolder(defaultEnvironmentId);

  const incidentsPageURL = `/#/app/overview/${defaultEnvironmentId}/${environmentFolder}/incidents`;

  return (
    <nav className={styles['navigation-bar']} aria-label="Main Menu">
      <NavigationLink
        href={incidentsPageURL}
        className={classnames(
          styles['navigation-link'],
          styles['logo-link'],
          styles['logo-image-link']
        )}
      >
        <NavigationImage
          className={styles['logo-image']}
          title="big panda homepage logo image"
          image={BigPandaLogoImage}
        />
      </NavigationLink>

      <NavigationLink
        href={incidentsPageURL}
        className={classnames(
          styles['navigation-link'],
          styles['logo-link'],
          styles['logo-text-link']
        )}
      >
        <NavigationImage
          className={styles['logo-text']}
          title="big panda homepage logo text"
          image={BigPandaLogoText}
        />
      </NavigationLink>

      <NavigationLink
        href={incidentsPageURL}
        className={classnames(styles['navigation-link'], styles['incidents-link'])}
      >
        <NavigationItem title="Incidents" icon="bp-icon-important" selectedItem={currentTitle} />
      </NavigationLink>

      {shouldDisplay(permissions.search.read) && (
        <NavigationLink
          href={`/#/app/investigator?${queryString.stringify(investigatorParams)}`}
          className={styles['navigation-link']}
        >
          <NavigationItem
            title="Unified Search"
            icon="bp-icon-search-thick"
            selectedItem={currentTitle}
          />
        </NavigationLink>
      )}

      {shouldDisplay(permissions.dashboards.read) && (
        <NavigationLink href="/v2/dashboards" className={styles['navigation-link']}>
          <NavigationItem
            title="Dashboards"
            icon="bp-icon-dashboard-icon"
            selectedItem={currentTitle}
          />
        </NavigationLink>
      )}

      {shouldDisplay(permissions.analytics.read, analyticsIgnoreFT) && (
        <NavigationLink href="/#/app/reports/detail/" className={styles['navigation-link']}>
          <NavigationItem
            title="Analytics"
            icon="bp-icon-reports-icon"
            selectedItem={currentTitle}
          />
        </NavigationLink>
      )}

      {shouldDisplay(permissions.analytics.read) && featureToggles[unifiedAnalyticsFT] && (
        <NavigationLink href="/v2/unified-analytics" className={styles['navigation-link']}>
          <NavigationItem
            title="Analytics"
            icon="bp-icon-reports-icon"
            selectedItem={currentTitle}
          />
        </NavigationLink>
      )}

      {shouldDisplay(permissions.integrations.read) && (
        <NavigationLink href="/v2/integrations" className={styles['navigation-link']}>
          <NavigationItem
            title="Integrations"
            icon="bp-icon-integrations"
            selectedItem={currentTitle}
          />
        </NavigationLink>
      )}

      {isSingleIncident && (
        <button className={styles['mobile-icon-button']} onClick={navigateToFeed}>
          <i className={classnames(styles['mobile-icon'], 'bp-icon-back')} />
        </button>
      )}

      {!isSingleIncident && (
        <button
          className={styles['mobile-icon-button']}
          onClick={() => toggleSideNav('left')}
          aria-label="toggle environments side bar"
        >
          <i className={classnames(styles['mobile-icon'], 'bp-icon-hamburger')} />
        </button>
      )}

      {isSingleIncident && <h1 className={styles['mobile-header']}>{currentTitle}</h1>}

      {!isSingleIncident && (
        <div className={styles['mobile-logo']}>
          <NavigationImage
            className={styles['logo-image']}
            title="big panda homepage"
            image={BigPandaLogoImage}
          />
          <NavigationImage
            className={styles['logo-text']}
            title="big panda homepage"
            image={BigPandaLogoText}
          />
        </div>
      )}

      {!isSingleIncident && (
        <button
          className={styles['mobile-icon-button']}
          onClick={() => toggleSearch()}
          aria-label="toggle search bar in incidents feed"
        >
          <i className={classnames(styles['mobile-icon'], 'bp-icon-search')} />
        </button>
      )}
    </nav>
  );
};

NavigationBar.propTypes = {
  currentRouteName: PropTypes.string.isRequired,
  customizations: customizationsPropType,
  defaultEnvironment: environmentPropType,
  environments: PropTypes.arrayOf(PropTypes.shape()),
  featureToggles: PropTypes.shape({
    frontier_integrations_lobby_react: PropTypes.bool,
  }).isRequired,
  loadCustomizations: PropTypes.func.isRequired,
  loadEnvironments: PropTypes.func.isRequired,
  loadUserData: PropTypes.func.isRequired,
  navigateToFeed: PropTypes.func.isRequired,
  toggleSideNav: PropTypes.func.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  customizations: selectors.getCustomizations(state),
  defaultEnvironment: selectors.getDefaultEnvironment(state),
  environments: selectors.getEnvironments(state),
  featureToggles: featureTogglesSelectors.getFeatureToggles(state) || {},
  userPermissions: permissionsSelectors.getPermissionsList(state) || [],
});

const mapDispatchToProps = {
  loadCustomizations,
  loadEnvironments: roleManagementActions.loadEnvironments,
  loadUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(NavigationBar));
