import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import SectionRowTitle from 'react/layout/settings/components/SectionRowTitle';
import { Badge } from '@bp/kung-fu';
import {
  incidentTagsNameByType,
  tagDefinitionIconByType,
  incidentTagTypes,
} from '../../../constants';
import baseStyles from '../IncidentLabelsInfo.scss';
import FormulaExpressionView from '../../../components/FormulaExpressionView';

class TextTagInfo extends React.PureComponent {
  render() {
    const {
      incidentLabel: {
        id,
        active,
        type,
        description,
        enrichment_config: enrichmentConfig,
        updated_at: updatedAt,
        canManualInput,
      },
      featureToggles,
    } = this.props;
    const isMultiValueType = type === incidentTagTypes.MULTI_VALUE;
    const isAnyMatching = isMultiValueType && get(enrichmentConfig, 'append_matching_items');
    const secondaryTitle = `${isAnyMatching ? 'Any' : 'First'} matching item${
      isAnyMatching ? 's' : ''
    } will be applied`;
    const helperContent = `Automatic Enrichment applies the ${
      isAnyMatching ? 'values of all the items which meet' : 'value of the first item which meets'
    } the conditions`;
    return (
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.SectionRow title="Status:">
          <Badge
            text={active ? 'Active' : 'Inactive'}
            status={active ? 'ok' : 'off'}
            size="large"
          />
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow
          title={<SectionRowTitle title="ID:" tooltip="The tag identifier is used in API calls" />}
        >
          <span className={baseStyles.itemType}>{id}</span>
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Type:">
          <span className={baseStyles.itemType}>
            <i className={`${tagDefinitionIconByType(type)}`} />
            <span>{incidentTagsNameByType(type)}</span>
          </span>
        </SettingsInfoScreen.SectionRow>
        {get(featureToggles, 'allow_tags_manual_input', true) && (
          <SettingsInfoScreen.SectionRow title="Manual Input:">
            <span className={baseStyles.itemType}>
              <SectionRowTitle
                title={canManualInput ? 'Enabled' : 'Disabled'}
                tooltip="Enables users to manually change the values. Manually changing tag data will stop BigPanda’s automated incident enrichment for that tag."
              />
            </span>
          </SettingsInfoScreen.SectionRow>
        )}
        {description && (
          <SettingsInfoScreen.SectionRow title="Description:">
            {description}
          </SettingsInfoScreen.SectionRow>
        )}
        {get(enrichmentConfig, 'items') && (
          <FormulaExpressionView
            items={enrichmentConfig.items}
            updateTime={updatedAt}
            secondaryTitle={secondaryTitle}
            helperContent={helperContent}
          />
        )}
      </SettingsInfoScreen.Section>
    );
  }
}

TextTagInfo.propTypes = {
  incidentLabel: PropTypes.shape({
    id: PropTypes.string,
    active: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(incidentTagTypes),
    description: PropTypes.string,
    updated_at: PropTypes.number,
    enrichment_config: PropTypes.shape({}),
    canManualInput: PropTypes.bool,
  }),
  featureToggles: PropTypes.shape({ allow_tags_manual_input: PropTypes.bool }).isRequired,
};

TextTagInfo.defaultProps = {
  incidentLabel: null,
};

export default hot(module)(TextTagInfo);
