import filter from 'lodash/filter';
import find from 'lodash/find';
angular
  .module('bigpanda')
  .factory('RulesService', ($q, EnvironmentsService, TargetSystemsStore, RulesBackendService) => {
    var formattedRules = [];
    var promise = $q.when();

    function getRules() {
      return RulesBackendService.getRules();
    }

    function getFormattedRules(forceUpdate) {
      return promise.then(() => {
        if (!forceUpdate && formattedRules && formattedRules.length > 0) {
          return formattedRules;
        }

        promise = EnvironmentsService.get().then((environments) =>
          TargetSystemsStore.getAll().then((targetSystems) =>
            getRules().then((rules) => {
              formattedRules.length = 0;
              rules.forEach((rule) => {
                rule.system = getRuleSystem(targetSystems, rule);
                rule.environment = find(environments, { old_id: rule.envId });
                formattedRules.push(rule);
              });
              return formattedRules;
            })
          )
        );

        return promise;
      });
    }

    function getRule(id, forceUpdate) {
      if (!forceUpdate) {
        return getFormattedRules().then((rules) => {
          var rule = find(rules, { id: id });
          if (rule) {
            return rule;
          }
          return RulesBackendService.getRule(id);
        });
      }

      return RulesBackendService.getRule(id);
    }

    function updateRulesFromServer() {
      return getFormattedRules(true);
    }

    function createRule(payload) {
      return RulesBackendService.createRule(payload)
        .then(updateRulesFromServer)
        .then((res) => res.item);
    }

    function updateRule(id, payload) {
      return RulesBackendService.updateRule(id, payload)
        .then(updateRulesFromServer)
        .then((res) => res.item);
    }

    function deleteRule(id) {
      return RulesBackendService.deleteRule(id).then(updateRulesFromServer);
    }

    function getRulesByEnvId(envId, forceUpdate) {
      return getFormattedRules(forceUpdate).then((rules) => filter(rules, { envId: envId }));
    }

    function getRuleSystem(targetSystems, rule) {
      if (rule.autoshareOptions) {
        return targetSystems
          .filter((targetSystem) => targetSystem.shareOptions)
          .find((targetSystem) => targetSystem.shareOptions.id === rule.autoshareOptions.id);
      }
      return find(targetSystems, { id: rule.targetSystemId });
    }

    return {
      getRules: getRules,
      getFormattedRules: getFormattedRules,
      createRule: createRule,
      getRule: getRule,
      updateRule: updateRule,
      deleteRule: deleteRule,
      getRulesByEnvId: getRulesByEnvId,
    };
  });
