import { TIME_FORMAT, DATE_FORMAT_THIS_YEAR, DATE_TIME_FORMAT } from 'common/constants';

const selectors = {};

selectors.formatDate = (rawDate) => {
  const momentObj = moment(rawDate, 'X');
  if (momentObj.isSame(moment(), 'day')) {
    return `Today ${momentObj.format(TIME_FORMAT)}`;
  } else if (momentObj.isSame(moment(), 'year')) {
    return momentObj.format(DATE_FORMAT_THIS_YEAR);
  }
  return momentObj.format(DATE_TIME_FORMAT);
};

selectors.windowDateFormat = (momentStart, momentEnd) => {
  const startDate = selectors.formatDate(momentStart);
  const endDate = selectors.formatDate(momentEnd);
  return `${startDate} - ${endDate}`;
};

selectors.calcDiff = (start, end) => {
  const fullDuration = moment.preciseDiff(moment(start).seconds(0), moment(end).seconds(0));
  const durArr = fullDuration.split(' ');
  return `${durArr[0] ? durArr[0] : ''} ${durArr[1] ? durArr[1] : ''}${
    durArr[2] ? ` ${durArr[2]} ${durArr[3]}` : ''
  }`;
};

export default selectors;
