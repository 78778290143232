import { react2angular } from 'react2angular';
import MaintenancePlanInfo from '../../../../../../workspaces/apps/maintenance-plans/maintenance_plan_info/components/MaintenancePlanInfo';

const routes = {};

routes.appSettingsPlannedMaintenanceInfo = {
  url: '/:planId',
  views: {
    'master-detail-info@app.settings.planned_maintenance': {
      template:
        '<maintenance-plan-info class="react-settings-info" plan-id="stateParams.planId" store="store" query-client="queryClient" />',
      controller: ($scope, $ngRedux, $stateParams, QueryClientService) => {
        'ngInject';

        $scope.store = $ngRedux;
        $scope.stateParams = $stateParams;
        $scope.queryClient = QueryClientService.getQueryClient();
      },
    },
  },
};

angular
  .module('bigpanda')
  .component(
    'maintenancePlanInfo',
    react2angular(MaintenancePlanInfo, ['planId', 'store', 'queryClient'])
  );

export default routes;
