const { getErrorMessageTransform } = require('angularjs/utils/notification/message.transforms.js');

angular.module('bigpanda').controller('UserInviteController', UserInviteController);

function UserInviteController(
  $scope,
  $log,
  $mdDialog,
  UsersStore,
  notificationService,
  Permissions,
  PERMISSIONS_NAMES
) {
  angular.extend($scope, {
    dialogTitle: 'Invite a User',
    titleIconClass: 'bp-icon-plus-icon',
    submitButtonCaption: 'Invite User',
    cancelButtonCaption: 'Cancel',
    bodyTemplateClass: 'user-invite-dialog',
    preSave: preSave,
  });

  const vm = this;
  vm.vmUser = {};
  vm.roles = [];
  vm.reactivateUserPopup = false;
  vm.globalMsg = {
    title: 'This email address belongs to an inactive user',
    text:
      'Reactivating this user will give the user access to BigPanda again and update all past activities.',
    dismissGlobalMsg: () => {
      vm.reactivateUserPopup = false;
      $scope.submitError = false;
    },
    approveUserReactivation: () => {
      vm.reactivateUserPopup = false;
      $scope.submitError = false;
      return UsersStore.activateUser(
        vm.vmUser.username,
        vm.vmUser.fullname,
        vm.vmUser.phone_number
      ).then((activatedUser) =>
        UsersStore.setRoles(activatedUser.id, vm.vmUser.roles).then(() => {
          notificationService.info(`User ${activatedUser.username} Reactivated successfully`);
          return $mdDialog.hide(activatedUser.id);
        })
      );
    },
  };

  init();

  function init() {
    vm.canEditRoles = Permissions.isPermitted(PERMISSIONS_NAMES.users.update);

    if (!vm.canEditRoles) {
      return;
    }

    UsersStore.getRolesList(true).then((rolesList) => {
      vm.roles = rolesList;
    });
  }

  function preSave() {
    return UsersStore.inviteUser(
      vm.vmUser.username,
      vm.vmUser.fullname,
      vm.vmUser.phone_number,
      vm.vmUser.roles
    )
      .then((createdUser) => createdUser)
      .catch((err) => {
        const errorMsg = err ? err.message : '';
        let msg = 'Temporary problem. Please try again.';

        if (err.status && err.status === 409) {
          msg = 'User already exists';

          if (
            err.data &&
            err.data.response &&
            err.data.response.errors &&
            err.data.response.errors.includes('deleted')
          ) {
            $scope.submitError = true;
            vm.reactivateUserPopup = true;
            return;
          }
        }

        $log.error(`failed invite user ${errorMsg}`);
        const message = `${getErrorMessageTransform('', 'invite')()} - ${msg}`;
        notificationService.error(message);
      });
  }
}
