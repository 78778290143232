angular.module('bigpanda.utils').service('UserUtils', UserUtils);

function UserUtils() {
  this.isPasswordStrengthValid = isPasswordStrengthValid;

  function isPasswordStrengthValid(pwd) {
    const passwordRegex = /(?=.*[a-z])(?=.*[!@#$&*0-9])(?=.*[A-Z]).{8,}/g;
    const passwordRulesMsg = [
      'Make sure your password contains:',
      'At least 8 characters',
      'At least 1 lowercase & 1 uppercase letter',
      'At least 1 numeric or special character',
    ];
    const pwdStrength = {
      isValid: true,
      error: '',
    };

    if (!passwordRegex.test(pwd)) {
      pwdStrength.isValid = false;
      pwdStrength.error = passwordRulesMsg;
    }

    return pwdStrength;
  }
}
