angular.module('bp.widgets').directive('bpGenericTabs', () => {
  return {
    scope: {
      tabs: '=',
      onSelect: '&',
      initialActiveTab: '@',
    },
    templateUrl: 'widgets/bp_generic_tabs/bp_generic_tabs',
    controllerAs: 'tabsVm',
    bindToController: true,
    controller,
  };

  function controller() {
    const vm = this;
    vm.selectTab = selectTab;

    function selectTab(tab) {
      vm.initialActiveTab = tab.type;
      if (vm.onSelect) {
        vm.onSelect({ tab });
      }
    }
  }
});
