import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';
import { getContacts } from 'common/endpoints/contacts';
import { getEnvironments } from 'common/endpoints/environments';
import { bamNotify, BamNotificationStatusOptions } from '@bp/bam';
import { showSuccessMessage, showFailureMessage } from 'react/layout/settings/feedback';
import {
  getRoles,
  createRole,
  updateRole,
  deleteRole as deleteRoleRequest,
} from 'common/endpoints/roles';
import { flatten, map, pipe, uniqBy, uniq, concat, sortBy } from 'lodash/fp';
import get from 'lodash/get';

import { getPermissions, createPermission } from 'common/endpoints/permissions';
import { getUsers } from 'common/endpoints/users';
import isEqual from 'lodash/isEqual';
import actionTypes from './actionTypes';
import actions from './actions';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';
import { formatPermissionName, formatUserDisplayName } from './utils';

const showSuccessMessageForRoles = (action, status = BamNotificationStatusOptions.ACTION_SUCCESS) =>
  showSuccessMessage('Role', action, status);

const showFailureMessageForRoles = (action) => showFailureMessage('Role', action);

function generateContactsDictByBPId(contacts) {
  const result = {};
  contacts.forEach((contact) => {
    if (contact.bigpanda_user_id) {
      result[contact.bigpanda_user_id] = contact;
    }
  });
  return result;
}

function generateEnvironmentsDictById(environments) {
  const result = {};
  environments.forEach((environment) => {
    result[environment._id] = environment;
  });
  return result;
}

function generateDisplayedPermissionsAndEnvironments(permissions, environmentsDict) {
  let permissionsObj = {};
  let allEnvironmentsAccess = false;

  if (permissions && permissions.length) {
    permissionsObj = permissions.reduce(
      (partitionObj, permission) => {
        if (permission.resource_type === 'environments') {
          let environmentName = '';
          if (permission.resource_id === null) {
            allEnvironmentsAccess = true;
          } else {
            environmentName = environmentsDict[permission.resource_id]
              ? environmentsDict[permission.resource_id].name
              : '';
          }
          partitionObj.environments.push({ ...permission, environmentName });
        } else {
          partitionObj.permissionsWithoutEnvironments.push(permission);
        }
        return partitionObj;
      },
      { permissionsWithoutEnvironments: [], environments: [] }
    );
  }
  permissionsObj.allEnvironmentsAccess = allEnvironmentsAccess;
  return permissionsObj;
}

function enrichRoles(roles, contacts, environments) {
  const contactsDictByBPId = generateContactsDictByBPId(contacts);
  const environmentsDictById = generateEnvironmentsDictById(environments);
  let enrichedRoles = [];

  enrichedRoles = roles.map((role) => {
    const displayedPermissionsAndEnvironments = generateDisplayedPermissionsAndEnvironments(
      role.permissions,
      environmentsDictById
    );
    const enrichedRole = {
      ...role,
      ...displayedPermissionsAndEnvironments,
      usernames: [],
      createdByUsername: '',
    };

    if (role.users && role.users.length) {
      enrichedRole.usernames = role.users.reduce((acc, userId) => {
        const valid = userId && contactsDictByBPId[userId] && contactsDictByBPId[userId].name;
        if (!valid) return acc;
        const { name: username, email } = contactsDictByBPId[userId];
        acc.push({ username, email });
        return acc;
      }, []);
    }
    if (role.created_by) {
      const createdByContact = contactsDictByBPId[role.created_by];
      if (createdByContact) {
        enrichedRole.createdByUsername = createdByContact.name;
      }
    }
    return enrichedRole;
  });
  return enrichedRoles;
}

const loadRoles = (action$) =>
  action$.ofType(actionTypes.LOAD_ROLES).mergeMap(async () => {
    const [roles, users, contacts, environments] = await Promise.all([
      getRoles(),
      getUsers(),
      getContacts(),
      getEnvironments(),
    ]);
    const activeContacts = filterDeletedContacts(users, contacts);
    const enrichedRoles = enrichRoles(roles, activeContacts, environments.item);
    return actions.loadRolesSuccess(enrichedRoles);
  });

const filterDeletedContacts = (users, contacts) =>
  contacts.filter((contact) => users.find((user) => user._id === contact.bigpanda_user_id));

const loadEnvironments = (action$) =>
  action$.ofType(actionTypes.LOAD_ENVIRONMENTS).mergeMap(async ({ payload }) => {
    const environments = await getEnvironments(payload);
    return actions.loadEnvironmentsSuccess(environments.item);
  });

const loadPermissions = (action$) =>
  action$.ofType(actionTypes.LOAD_PERMISSIONS).mergeMap(async () => {
    const { permissionsList } = await getPermissions();
    return actions.loadPermissionsSuccess(permissionsList);
  });

const loadPermissionsAndEnvironments = (action$) =>
  Observable.combineLatest(
    action$.ofType(actionTypes.LOAD_PERMISSIONS_SUCCESS),
    action$.ofType(actionTypes.LOAD_ENVIRONMENTS_SUCCESS),
    (permissions, environments) =>
      actions.loadPermissionsAndEnvironmentsSuccess({
        permissions: permissions.payload,
        environments: environments.payload,
      })
  );

export const findPermissionId = (
  permissionsList,
  resourceType,
  wantedActions,
  optionalResourceId = null
) => {
  const foundPermission = permissionsList.find(
    (permission) =>
      permission.resource_type.toLowerCase() === resourceType &&
      isEqual(wantedActions, permission.actions) &&
      permission.resource_id == optionalResourceId
  );

  return foundPermission ? foundPermission._id : undefined;
};

const getEnvPermissionIdsAndCreateIfNeeded = async (
  wantedPermissionsStrings = [],
  globalPermissions
) =>
  Promise.all(
    wantedPermissionsStrings
      .map((wantedPermissionString) => {
        let envId;
        let wantedActions;

        if (wantedPermissionString === 'Environments_Read') {
          wantedActions = ['Read', 'Action@Read'];
        } else if (wantedPermissionString === 'Environments_Full_Access') {
          wantedActions = [
            'Read',
            'Create',
            'Update',
            'Delete',
            'Action@Read',
            'Action@Update',
            'Action@Create',
            'Action@Delete',
          ];
        } else if (wantedPermissionString.includes('_Incident_Actions')) {
          const env = wantedPermissionString.split('_Incident_Actions')[0].toLowerCase();
          if (env !== 'environments') {
            envId = env;
          }
          wantedActions = [
            'Read',
            'Action@Read',
            'Action@Update',
            'Action@Create',
            'Action@Delete',
          ];
        } else if (wantedPermissionString.includes('_Read')) {
          envId = wantedPermissionString.split('_Read')[0].toLowerCase();
          wantedActions = ['Read', 'Action@Read'];
        } else {
          return null;
        }

        return {
          resourceType: 'environments',
          _id: findPermissionId(globalPermissions, 'environments', wantedActions, envId),
          actions: wantedActions,
          name: `Environments${envId ? `_${envId}` : ''}@${wantedActions.join('_')}`,
          resourceId: envId,
        };
      })
      .map(async (wantedPermission) => {
        if (wantedPermission._id) {
          return wantedPermission._id;
        }
        const createdPermission = await createPermission({ permission: wantedPermission });
        return createdPermission._id;
      })
  );

const getPermissionIdsAndCreateIfNeeded = async (
  wantedPermissionsStrings = [],
  globalPermissions
) =>
  Promise.all(
    wantedPermissionsStrings
      .map((wantedPermissionString) => {
        let resourceType;
        let wantedActions;
        if (wantedPermissionString.includes('Read')) {
          resourceType = wantedPermissionString.split('_Read')[0].toLowerCase();
          wantedActions = ['Read'];
        } else if (wantedPermissionString.includes('Full_Access')) {
          resourceType = wantedPermissionString.split('_Full_Access')[0].toLowerCase();
          wantedActions = ['Read', 'Create', 'Update', 'Delete'];
        } else {
          return null;
        }

        return {
          resourceType: resourceType,
          _id: findPermissionId(globalPermissions, resourceType, wantedActions),
          actions: wantedActions,
          name: `${resourceType}@${wantedActions.join('_')}`,
        };
      })
      .map(async (wantedPermission) => {
        if (wantedPermission._id) {
          return wantedPermission._id;
        }
        const createdPermission = await createPermission({ permission: wantedPermission });
        return createdPermission._id;
      })
  );

const createOrUpdateRole = (action$) =>
  action$.ofType(actionTypes.CREATE_OR_UPDATE_ROLE).mergeMap(async ({ payload }) => {
    const { role, global } = payload;

    const environmentPermissions = await getEnvPermissionIdsAndCreateIfNeeded(
      role.environments,
      global.permissions
    );
    const permissions = await getPermissionIdsAndCreateIfNeeded(
      role.permissions,
      global.permissions
    );

    const concattedEnvironmentPermissionsAndRegularPermissions = [
      ...environmentPermissions,
      ...permissions,
    ];

    const roleToCreateOrUpdate = {
      name: role.name,
      users: role.users,
      permissions: concattedEnvironmentPermissionsAndRegularPermissions,
      _id: role._id,
    };
    if (roleToCreateOrUpdate._id) {
      try {
        await updateRole(roleToCreateOrUpdate._id, roleToCreateOrUpdate);
        showSuccessMessageForRoles('saved');
      } catch (e) {
        showFailureMessageForRoles('save');
      }
    } else {
      try {
        const newRole = await createRole(roleToCreateOrUpdate);
        if (window.location.href.includes('roles')) {
          window.location.href = `/#/app/settings/roles/${newRole._id}`;
        }
        showSuccessMessageForRoles('created');
      } catch (e) {
        showFailureMessageForRoles('create');
      }
    }
    return actions.loadRoles();
  });

const deleteRole = (action$) =>
  action$.ofType(actionTypes.DELETE_ROLE).mergeMap(async ({ payload }) => {
    const roleId = payload;
    try {
      await deleteRoleRequest(roleId);
      showSuccessMessageForRoles('deleted');
    } catch (e) {
      showFailureMessageForRoles('delete');
    }
    return actions.loadRoles();
  });

const loadPermissionsOnLoadRoleSuccess = (action$) =>
  action$.ofType(actionTypes.LOAD_ROLES_SUCCESS).mapTo(actions.loadPermissions());

const createFiltersDropDownItems = (action$) =>
  action$.ofType(actionTypes.LOAD_ROLES_SUCCESS).map(({ payload }) => {
    const emailsArray = pipe(
      map((role) => role.usernames),
      flatten,
      uniqBy((user) => user.email),
      map((user) => ({
        text: formatUserDisplayName(user),
        lowCaseText: formatUserDisplayName(user).toLowerCase(),
        value: user.email,
        key: user.email,
      })),
      sortBy('lowCaseText'),
      concat([DROPDOWN_FILTERS_ALL_ITEM_OPTION.user])
    )(payload);
    const permissionsArray = pipe(
      map((role) => formatPermissionName(role)),
      flatten,
      uniq,
      map((permission) => ({ text: permission, value: permission, key: permission })),
      sortBy('text'),
      concat([DROPDOWN_FILTERS_ALL_ITEM_OPTION.permission])
    )(payload);

    return actions.createFiltersDropDownItemsSuccess({
      permission: [...permissionsArray],
      user: [...emailsArray],
    });
  });

export default combineEpics(
  loadRoles,
  createOrUpdateRole,
  deleteRole,
  loadPermissions,
  loadEnvironments,
  loadPermissionsAndEnvironments,
  loadPermissionsOnLoadRoleSuccess,
  createFiltersDropDownItems
);
