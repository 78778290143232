import { AllSizes, Text } from '@bp/kung-fu';
import styled from 'styled-components';

export const MappingText = styled(Text).attrs({ size: AllSizes.SMALL })`
    color: ${(p) => p.theme.bp_gray_06};
    margin-inline-end: 4px;
`;

export const FunctionTypeText = styled(Text).attrs({ size: AllSizes.LARGE })`
    color: ${(p) => p.theme.bp_gray_08};
`;

export const DotWrapper = styled(Text).attrs({ size: AllSizes.SMALL })`
    margin-inline-start: 5px;
    margin-inline-end: 6px;
    color: ${(p) => p.theme.bp_gray_06};
    width: 5px;
`;
