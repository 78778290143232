angular.module('bigpanda').controller('EmptyInfoCtrl', EmptyInfoCtrl);

function EmptyInfoCtrl(
  $scope,
  stateService,
  pubSubService,
  EnvironmentsCountersStore,
  EnvironmentsUtils
) {
  const envId = stateService.getSelectedEnvironmentId();
  const folderId = stateService.getSelectedFolderId();
  const vm = this;

  pubSubService.on('incidentsList.isEmptySearch', isEmptySearch, $scope);

  EnvironmentsCountersStore.subscribe($scope, { storeUpdated: onInit });

  pubSubService.on('incidentsList.isEmptySearch', isEmptySearch, $scope);

  vm.$onInit = onInit;

  function onInit() {
    if (folderId === 'search') return;

    EnvironmentsCountersStore.getEnvFolderCounter(envId, folderId).then((folderCounter) => {
      vm.isEmptyInfoDisplay = folderCounter > 0;
    });

    pubSubService.broadcast('incidentfullscreen', {
      incidentFullScreen: null,
    });
  }

  function isEmptySearch(event, isEmpty) {
    vm.isEmptyInfoDisplay = !isEmpty;
  }
}
