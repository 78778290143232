import get from 'lodash/get';
import trim from 'lodash/trim';
import React, { useState } from 'react';
import { FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectors as integrationsSelectors } from '../../../../../../../app/react/common/integrations';
import preventEnterKey from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/preventEnterKey';
import ruleFieldName from '../../../../../../../app/react/modules/settings/alert_enrichment/modals/utils/ruleFieldName';
import selectors from '../../../../../../../app/react/modules/settings/alert_enrichment/selectors';
import { hasRegexValidationError } from '../../../../../../../app/react/modules/settings/alert_enrichment/validation.utils';
import { selectors as featureTogglesSelectors } from '../../../../../../../app/react/user/feature_toggles';
import { EnrichmentRule } from '../../../../types';
import { IntegrationOption, LoadPreview, UnifiedTag } from '../types';
import tagsMatchingSourceSystem from '../utils/tagsMatchingSourceSystem';
import { ExtractionForm, RegexField } from './Extraction.style';
import ExtractionFields from './ExtractionFields';
import { Filtersv2Fields } from './Filtersv2Fields';

interface IProps {
  rule: EnrichmentRule;
  unifiedTags: UnifiedTag[];
  onLoadPreview: LoadPreview;
}

export const DEFAULT_INTEGRATION = '*';

export function Extraction({
  rule, unifiedTags, onLoadPreview,
}: IProps): JSX.Element {
  const [values, setValues] = useState({});
  const [regexChanged, setRegexChanged] = useState(false);

  const integrationOptions: IntegrationOption[] = useSelector(integrationsSelectors.getAllIntegrationOptions) || [];
  const filtersv2FT: boolean = get(
    useSelector(featureTogglesSelectors.getFeatureToggles),
    'filters_v2',
    false,
  );
  const reservedTags: UnifiedTag[] = useSelector(selectors.getReservedTags);
  const reservedTagsNames: string[] = useSelector(selectors.getReservedTagsNames);

  const onChange = ({ values: newValues }): void => {
    const regexFieldName = ruleFieldName(rule.id, 'regex');
    const hasRegexChanged = get(newValues, regexFieldName) !== get(values, regexFieldName);

    setValues(newValues);
    setRegexChanged(hasRegexChanged);
  };

  const loadPreview = (e, isSourceTag: boolean): void => {
    const regex = get(values, ruleFieldName(rule.id, 'regex'));
    const source = get(values, ruleFieldName(rule.id, 'source'));

    if (!source || (regex && (!regexChanged || isSourceTag))) return;
    onLoadPreview(rule.id, values);
  };

  const integration = get(values, ruleFieldName(rule.id, 'integration'));
  const sourceSystemAlertTags: UnifiedTag[] = tagsMatchingSourceSystem(integration || DEFAULT_INTEGRATION, unifiedTags);

  const sourceSystemOptions: IntegrationOption[] = [...reservedTags, ...sourceSystemAlertTags].map(({ name }) => ({
    key: name,
    text: name,
    value: name,
    display: name,
  }));

  const isReservedSource = reservedTagsNames.includes(get(values, ruleFieldName(rule.id, 'source')));

  return (
    <>
      <ExtractionForm>
        {filtersv2FT && (
          <Filtersv2Fields
            integrationOptions={integrationOptions}
            sourceSystemOptions={sourceSystemOptions}
            integration={integration}
            ruleId={rule.id}
            loadPreview={onLoadPreview}
          />
        )}
        {!filtersv2FT && (
          <ExtractionFields
            ruleId={rule.id}
            integrationOptions={integrationOptions}
            sourceSystemOptions={sourceSystemOptions}
            loadPreview={onLoadPreview}
            integration={integration}
          />
        )}
        <RegexField
          value={get(values, ruleFieldName(rule.id, 'regex'))}
          name={ruleFieldName(rule.id, 'regex')}
          title="Extraction Regex"
          hint="Regular expression that defines the pattern for extracting the alert tag value from the source tag value"
          placeholder="e.g. ([^.]+).[a-z]{3}.com"
          fixedFont
          validate={(val: string): string | undefined => hasRegexValidationError(trim(val))}
          validateOnBlur
          ariaLabel="extraction regex"
          onKeyPress={preventEnterKey}
          onBlur={loadPreview}
          source={get(values, ruleFieldName(rule.id, 'source'))}
          isReservedSource={isReservedSource}
        />
      </ExtractionForm>
      <FormSpy subscription={{ values: true }} onChange={onChange} />
    </>
  );
}

export default Extraction;
