angular.module('bp.widgets').directive('bpTooltipPrompt', () => ({
  restrict: 'E',
  replace: true,
  scope: {
    tooltip: '@',
    viewport: '@',
    template: '@',
  },
  template: `
    <span class="bp-tooltip-prompt">
      [<a bp-tooltip bp-tooltip-content="{{ tooltip }}" bp-tooltip-template-content="{{template}}">?</a>]
    </span>
  `,
}));
