import {
  darkTheme,
  HelperType,
  IDataListObject,
  LabelIcon,
  QueryHelper,
  SearchField,
  SearchfilterIcon,
  TagIcon,
} from '@bp/kung-fu';
import get from 'lodash/get';
import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { loadAlertTags } from '../../../../../../app/react/common/alert_tags/actions';
import selectors from '../../../../../../app/react/common/alert_tags/selectors';
import provideStore from '../../../../../../app/react/utils/provideStore';
import { DROPDOWMN_SHADOW, INCIDENT_TAGS_METADATA, OLD_INCIDENT_TAGS_METADATA } from './constants';
import text from './dictionary';
import { IQueryHelper } from './types/IQueryHelper';

function UnifiedSearchQueryHelper({
  handleSearch,
  incidentTags,
  error,
  getRecentSearches,
  disabled,
  useIncidentTag,
}: IQueryHelper): JSX.Element {
  const [recentSearches, setRecentSearches] = useState([]);
  const dispatch = useDispatch();
  const alertTags = useSelector((state) => selectors.getDisplayAlertTags(state)) || [];

  const handleGetRecentSearches = useCallback(async () => {
    const { items } = await getRecentSearches();
    setRecentSearches(items);
  }, []);

  const buildIncidentTagsData = (): IDataListObject => {
    const incidentTagsValues = [
      ...(useIncidentTag ? INCIDENT_TAGS_METADATA : OLD_INCIDENT_TAGS_METADATA),
      ...(incidentTags && useIncidentTag
        ? incidentTags.map(({ name, description, id }) => ({ title: name, description, id })) : []),
    ];
    return {
      title: text.incidents,
      type: HelperType.INCIDENT_TAGS,
      disabled: false,
      icon: <LabelIcon />,
      values: incidentTagsValues,
    };
  };

  const buildAlertTagsData = (): IDataListObject => {
    const alertTagsValues = alertTags.map(({ name, sourceSystems }) => ({
      title: name,
      description: get(sourceSystems, '0.parentDisplayName'),
    }));
    return (
      alertTags.length && {
        title: text.alert_tags,
        type: HelperType.ALERT_TAGS,
        disabled: false,
        icon: <TagIcon />,
        values: alertTagsValues,
      }
    );
  };

  const buildRecentSearchesData = (): IDataListObject => {
    const recentSearchesValues = recentSearches.map((title: string) => ({
      title,
    }));
    return (
      recentSearches.length && {
        title: text.recent_searches,
        type: HelperType.RECENT_SEARCHES,
        disabled: false,
        icon: <SearchfilterIcon />,
        values: recentSearchesValues,
      }
    );
  };

  const handleSearchQuery = useCallback((searchQuery: string) => {
    handleSearch(searchQuery);
    handleGetRecentSearches();
  }, []);

  useEffect(() => {
    dispatch(loadAlertTags());
  }, []);

  useEffect(() => {
    handleGetRecentSearches();
  }, []);

  const incidentTagsQueryHelperData = useMemo(buildIncidentTagsData, [incidentTags]);

  const alertTagsQueryHelperData = useMemo(buildAlertTagsData, [alertTags]);

  const recentSearchesQueryHelperData = useMemo(buildRecentSearchesData, [recentSearches]);

  const queryHelperData = [
    ...(recentSearchesQueryHelperData ? [recentSearchesQueryHelperData] : []),
    ...(incidentTagsQueryHelperData ? [incidentTagsQueryHelperData] : []),
    ...(alertTagsQueryHelperData ? [alertTagsQueryHelperData] : []),
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <QueryHelper
        placeholder={text.input_placeholder}
        data={queryHelperData}
        TriggerComponent={SearchField}
        onClick={handleSearchQuery}
        error={error?.msg}
        dropDownShadow={DROPDOWMN_SHADOW}
        triggerComponentProps={{ disabled }}
        shouldUseNamespace={useIncidentTag}
      />
    </ThemeProvider>
  );
}

export default provideStore(memo(UnifiedSearchQueryHelper));
