import { EmptyMessage as EmptyMessageComp } from '@bp/kung-fu';
import React from 'react';

import { EmptyMessageContainer } from './EmptyMessage.style';

interface IProps {
  emptyPreviewHeader: string | undefined;
  emptyPreviewMessage: string;
}

export function EmptyMessage({ emptyPreviewHeader, emptyPreviewMessage }: IProps): JSX.Element {
  return (
    <EmptyMessageContainer id="EmptyMessageContainer">
      <EmptyMessageComp header={emptyPreviewHeader}>{emptyPreviewMessage}</EmptyMessageComp>
    </EmptyMessageContainer>
  );
}

export default EmptyMessage;
