angular
  .module('bigpanda.reports')
  .controller(
    'WidgetEditDialogController',
    function WidgetEditDialogBodyController($scope, ReportsConfig, pubSubService) {
      $scope.dialogTitle = 'Widget Settings';
      $scope.submitButtonCaption = 'Save Changes';
      $scope.cancelButtonCaption = 'Cancel';
      $scope.titleIconClass = 'bp-icon-settings';
      $scope.eventPrefix = 'WidgetEdit';
      $scope.data = this.data;
      $scope.enabledConfig = ReportsConfig.widgetConfigMapping[$scope.data.type] || [];

      $scope.configurationFeedback = function () {
        pubSubService.broadcast('Intercom.showNewMessage', {
          message: "Here's my feedback on the beta release of BigPanda Analytics:\n",
        });
      };
    }
  );
