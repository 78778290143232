angular
  .module('bigpanda')
  .controller('IntegrationsInformationCenterController', IntegrationsInformationCenterController);

function IntegrationsInformationCenterController(inviteOpener, pubSubService) {
  const vm = this;
  vm.openInvite = inviteOpener.open;
  vm.contactSupport = contactSupport;

  function contactSupport() {
    pubSubService.broadcast('Intercom.showNewMessage');
  }
}
