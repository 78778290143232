import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import SettingsListItemActions from 'react/layout/settings/components/SettingsListItemActions';
import actions from '../actions';
import selectors from '../selectors';
import ApiKeyModal from '../modals/ApiKeyModal';

const ApiKeysActions = ({ apiKey, deleteApiKey, actionPermissions }) => {
  const removeApiKey = () => deleteApiKey(apiKey.id);

  const messages = {
    edit: `Cannot Edit API key '${apiKey.name}'`,
    delete: `Cannot Delete API key '${apiKey.name}'`,
  };

  return (
    <SettingsListItemActions
      item={apiKey}
      modelName="API Key"
      messages={messages}
      editProps={{
        disabled: !actionPermissions.hasEditPermission,
        modalProps: { currentApiKeyId: apiKey.id, title: 'Edit API Key' },
        modal: ApiKeyModal,
      }}
      deleteProps={{
        disabled: !actionPermissions.hasDeletePermission,
        action: removeApiKey,
        modalProps: {
          contentTitleType: 'string',
          content: 'Are you sure you want to delete this API key?',
          typeToConfirmText: 'DELETE',
        },
      }}
    />
  );
};

ApiKeysActions.propTypes = {
  apiKey: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }).isRequired,
  deleteApiKey: PropTypes.func.isRequired,
  actionPermissions: PropTypes.shape({
    hasEditPermission: PropTypes.bool,
    hasDeletePermission: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    actionPermissions: selectors.getActionsPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteApiKey: (apiKeyId) => dispatch(actions.deleteApiKey(apiKeyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(ApiKeysActions));
