import isEmpty from 'lodash/isEmpty';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Badge, darkTheme, EditIcon } from '@bp/kung-fu';
import { BamConfirmModal, BamEmptyMessage, BamTile, BamToggle, TimeFormats } from '@bp/bam';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import provideStore from 'react/utils/provideStore';
import SettingsInfoScreen from 'react/layout/settings/SettingsInfoScreen';
import ConfigurationLabelsRow from 'react/common/components/ConfigurationLabels/ConfigurationLabelsRow/ConfigurationLabelsRow';
import './correlation_pattern_info.scss';
import { BadgeWrapper } from './CorrelationPatternInfo.css';
import CorrelationPatternsActions from '../../actions/CorrelationPatternsActions';
import selectors from '../../selectors';
import CorrelationPatternModal from '../../modals/CorrelationPatternModal';
import actions from '../../actions';

const { momentOrEpochRelativeTimeFormat } = TimeFormats;

class CorrelationPatternInfo extends React.PureComponent {
  togglePatternClicked = () => {
    if (!this.props.correlationPattern.active) {
      this.toggleCorrelationPattern();
    }
  };

  toggleCorrelationPattern = () => {
    const { correlationPattern, updateCorrelationPattern } = this.props;
    updateCorrelationPattern({ id: correlationPattern.id, active: !correlationPattern.active });
  };

  render() {
    const { correlationPattern, actionPermissions, enableConfigurationLabels } = this.props;

    if (!correlationPattern) {
      return (
        <BamEmptyMessage header="NO CORRELATION PATTERN SELECTED">
          Select a correlation pattern to see details
        </BamEmptyMessage>
      );
    }

    const {
      id,
      status,
      statusText,
      sourceSystems,
      cross_source: crossSource,
      window,
      displayQuery,
      labels,
      metadata,
      active,
      createdByUserName,
      updatedByUserName,
      tagNames,
    } = correlationPattern;

    const content = (
      <SettingsInfoScreen.Section>
        <SettingsInfoScreen.SectionRow title="Status:">
          <Badge text={statusText} status={status} isCapitalized size="large" />
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Source Systems:">
          {sourceSystems.map((sourceSystem) => sourceSystem.display).join(', ')}
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Cross Source:">
          {crossSource ? 'Enabled' : 'Disabled'}
        </SettingsInfoScreen.SectionRow>
        <SettingsInfoScreen.SectionRow title="Time Window:">{`${
          window / 60
        } minutes`}</SettingsInfoScreen.SectionRow>
        {displayQuery && (
          <SettingsInfoScreen.SectionRow fixed title="Query:">
            {displayQuery}
          </SettingsInfoScreen.SectionRow>
        )}
        {metadata.description && (
          <SettingsInfoScreen.SectionRow title="Description:">
            {enableConfigurationLabels ? metadata.description : <pre>{metadata.description}</pre>}
          </SettingsInfoScreen.SectionRow>
        )}
        {enableConfigurationLabels && !isEmpty(labels) && (
          <SettingsInfoScreen.SectionRow title="Labels:">
            <pre>
              <ConfigurationLabelsRow labels={labels} />
            </pre>
          </SettingsInfoScreen.SectionRow>
        )}
      </SettingsInfoScreen.Section>
    );

    const settingsActions = (
      <SettingsInfoScreen.Section>
        {actionPermissions.hasEditPermission && (
          <CorrelationPatternModal
            triggerProps={{
              variant: 'action-color',
              size: 'large',
              text: 'Edit Correlation Pattern',
              icon: <EditIcon />,
            }}
            title="Edit Correlation Pattern"
            titleIcon="bp-icon-edit"
            currentCorrelationPatternId={id}
          />
        )}
      </SettingsInfoScreen.Section>
    );

    const activity = (
      <SettingsInfoScreen.Section>
        {metadata.suggested && (
          <SettingsInfoScreen.ActivityRow
            badge={
              <BadgeWrapper>
                <Badge text="Suggested" color={(p) => p.theme.bp_purple} size="small" />
              </BadgeWrapper>
            }
            link={{
              href: 'https://docs.bigpanda.io/v1.0/docs/machine-learning-engine',
              name: 'BigPanda Machine Learning Engine',
              prefix: 'by',
            }}
            value={momentOrEpochRelativeTimeFormat(metadata.created_time)}
          />
        )}
        {!metadata.suggested && (
          <SettingsInfoScreen.ActivityRow
            title="Created By:"
            name={createdByUserName}
            value={momentOrEpochRelativeTimeFormat(metadata.created_time)}
          />
        )}
        <SettingsInfoScreen.ActivityRow
          title="Updated By:"
          name={updatedByUserName}
          value={momentOrEpochRelativeTimeFormat(metadata.updated_time)}
        />
      </SettingsInfoScreen.Section>
    );

    const toggle = (
      <BamToggle
        label="Active:"
        labelPosition="left"
        onClick={this.togglePatternClicked}
        checked={active}
        color="green"
        big
      />
    );

    const deactivateModal = (
      <BamConfirmModal
        icon="bp-icon-power-off"
        headerTitle="Deactivate Pattern?"
        content="Are you sure you want to deactivate this pattern?"
        note="Note: New incidents will not be correlated by it and undoing this action may take a few minutes."
        contentTitle={tagNames.join(', ')}
        contentTitleType="string"
        action={this.toggleCorrelationPattern}
        actionText="Deactivate"
        trigger={toggle}
        destructive={false}
        openCondition={() => active}
      />
    );
    const wrappedToggle = actionPermissions.hasEditPermission ? deactivateModal : null;

    return (
      <ThemeProvider theme={darkTheme}>
        <SettingsInfoScreen
          topActions={<CorrelationPatternsActions correlationPattern={correlationPattern} />}
          header={
            <BamTile
              bamStatus={active ? 'ok' : 'off'}
              headerRow={{ title: tagNames, toggle: wrappedToggle }}
            />
          }
          content={content}
          actions={settingsActions}
          activity={activity}
        />
      </ThemeProvider>
    );
  }
}

CorrelationPatternInfo.propTypes = {
  correlationPattern: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    id: PropTypes.string,
    status: PropTypes.string,
    statusText: PropTypes.string,
    sourceSystems: PropTypes.arrayOf(PropTypes.shape({ display: PropTypes.string })),
    cross_source: PropTypes.bool,
    window: PropTypes.number,
    displayQuery: PropTypes.string,
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    metadata: PropTypes.shape({
      suggested: PropTypes.bool,
      description: PropTypes.string,
      created_time: PropTypes.number,
      updated_time: PropTypes.number,
    }),
    createdByUserName: PropTypes.string,
    updatedByUserName: PropTypes.string,
    tagNames: PropTypes.arrayOf(PropTypes.string),
  }),
  actionPermissions: PropTypes.shape({ hasEditPermission: PropTypes.bool }).isRequired,
  enableConfigurationLabels: PropTypes.bool,
  updateCorrelationPattern: PropTypes.func.isRequired,
};

CorrelationPatternInfo.defaultProps = {
  correlationPattern: null,
  enableConfigurationLabels: false,
};

function mapStateToProps(state, ownProps) {
  const enableConfigurationLabels = selectors.areConfigurationLabelsEnabled(state || {});
  const correlationPattern = selectors.getCorrelationPattern(state, ownProps.correlationPatternId, {
    isLabelsFilterEnabled: enableConfigurationLabels,
  });
  const actionPermissions = selectors.getActionsPermissions(state);
  return { correlationPattern, actionPermissions, enableConfigurationLabels };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCorrelationPattern: (correlationPattern) =>
      dispatch(actions.updateCorrelationPattern(correlationPattern)),
  };
}

export default provideStore(connect(mapStateToProps, mapDispatchToProps)(CorrelationPatternInfo));
