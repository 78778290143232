import moment from 'moment';
import { TimeFormats } from '@bp/bam';

// eslint-disable-next-line import/prefer-default-export
export const createChangesDropDownOptions = (incidentStartTimeInSec) => {
  const now = moment();
  const yesterday = moment().subtract(1, 'days');
  const twoDaysAgo = moment().subtract(2, 'days');
  const incidentStartTime = moment.unix(incidentStartTimeInSec);
  const oneHourBeforeIncidentStart = moment(incidentStartTime).subtract(1, 'hours');
  const fourHoursBeforeIncidentStart = moment(incidentStartTime).subtract(4, 'hours');
  const dayBeforeIncidentStartTime = moment(incidentStartTime).subtract(1, 'days');
  const twoDaysBeforeIncidentStartTime = moment(incidentStartTime).subtract(2, 'days');

  const options = [
    {
      text: '1 Hour before Incident start',
      value: '0',
      from: oneHourBeforeIncidentStart,
      to: incidentStartTime,
    },
    {
      text: '4 Hours before Incident start',
      value: '1',
      from: fourHoursBeforeIncidentStart,
      to: incidentStartTime,
    },
    {
      text: '24 Hours before Incident start',
      value: '2',
      from: dayBeforeIncidentStartTime,
      to: incidentStartTime,
    },
    {
      text: '48 Hours before Incident start',
      value: '3',
      from: twoDaysBeforeIncidentStartTime,
      to: incidentStartTime,
    },
    {
      text: 'Last 24 Hours',
      value: '4',
      from: yesterday,
      to: now,
    },
    {
      text: 'Last 48 Hours',
      value: '5',
      from: twoDaysAgo,
      to: now,
    },
  ];

  return options.map((o) => ({
    ...o,
    description: TimeFormats.momentOrEpochRangeRelativeTimeFrameFormat(o),
  }));
};
