export default function createMidTextEllipsis(text, charsNumber) {
  if (text.length <= charsNumber) {
    return text;
  }

  const ellipsis = '...';
  const charsToShow = charsNumber - ellipsis.length;

  const charsBeforeEllipsis = Math.ceil(charsToShow / 2);
  const charsAfterEllipsis = Math.floor(charsToShow / 2);

  const newText =
    text.substring(0, charsBeforeEllipsis) +
    ellipsis +
    text.substring(text.length - charsAfterEllipsis);

  return newText;
}
