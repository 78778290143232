import React from 'react';
import PropTypes from 'prop-types';
import styles from './tutorial.scss';

const Tutorial = ({ children }) => <div className={styles.tutorial}>{children}</div>;

Tutorial.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tutorial;
