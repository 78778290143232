/* eslint-disable import/prefer-default-export */
import bpFetch from 'common/utils/http/fetcher';

const baseURL = '/resources/v2.0/troubleshooting/logs';

export const getLogs = ({ resourceId, startDate, endDate, page, per_page, filters, search }) => {
  return bpFetch({
    url: baseURL,
    params: {
      resource_id: resourceId,
      end_date: endDate,
      start_date: startDate,
      page,
      per_page,
      search,
      ...filters,
    },
  }).then((res) => res.data);
};

export const getLogsMetadata = () => {
  return bpFetch({
    url: `${baseURL}/metadata`,
  }).then((res) => res.data);
};
