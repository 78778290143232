import get from 'lodash/get';
import reject from 'lodash/reject';
import extend from 'lodash/extend';
import find from 'lodash/find';
import {
  AI_INCIDENT_TITLE,
  AI_INCIDENT_SUMMARY,
  AI_INCIDENT_RCA,
  AI_INCIDENT_REASONING,
  AI_TAGS,
} from '../../../../common/constants';
angular.module('bigpanda').factory('ActivityFeedService', ActivityFeedService);

function ActivityFeedService(
  ActivityFeedBackendService,
  ActivityEventsConfig,
  ContactsStore,
  UserFeatureTogglesService
) {
  function getActivityEvents(id) {
    return ContactsStore.getContacts().then((contacts) =>
      ActivityFeedBackendService.getActivityEvents(id).then((activityEvents) => {
        for (let i = activityEvents.length - 1; i >= 0; i--) {
          const event = activityEvents[i];
          formatEventType(event);
          const eventConfig = find(ActivityEventsConfig.activityEvents, {
            event_type: event.event_type,
          });
          if (!eventConfig) {
            activityEvents.splice(i, 1);
          } else {
            extend(event, eventConfig.config);
            formatDate(event);
            formatContact(event, contacts);
            formatAnnotation(event);
            unassignedOnReopen(event);
            determineEventIcon(event);
          }
        }

        const filteredEvents = reject(activityEvents, (event) =>
          shouldIgnoreEvent(event, activityEvents)
        );
        filteredEvents.sort((a, b) => b.timestamp - a.timestamp);

        return filteredEvents;
      })
    );
  }

  function shouldIgnoreRelatedMetadataUpdateEvent(event) {
    const isRelatedMetadataUpdateEventType =
      get(event, '_source.event_type') === 'related-metadata#update';
    const isManual = get(event, '_source.params.change.relatedMetadata.matchType') === 'MANUAL';
    const hasFeatureToggle = UserFeatureTogglesService.getToggle('return_matches_by_bigpanda');
    return isRelatedMetadataUpdateEventType && !isManual && !hasFeatureToggle;
  }

  function shouldIgnoreEvent(event, events) {
    const eventType = event._source.event_type;
    if (eventType === 'incident#shared' || eventType === 'incident#auto-shared') {
      return (
        !event._source.params ||
        !event._source.params.firstTime ||
        event._source.params.shareTarget === 'ai-analysis.automated_incident_analysis'
      );
    }

    if (eventType === 'incident#closed') {
      const matchedManualResolve = find(events, {
        event_type: 'incident#manual-resolve',
        timestamp: event.timestamp,
      });
      const matchedExternalResolve = find(events, {
        event_type: 'incident#external-resolve',
        timestamp: event.timestamp,
      });

      if (matchedManualResolve || matchedExternalResolve) {
        return true;
      }
    }
    return shouldIgnoreRelatedMetadataUpdateEvent(event);
  }

  function getAIActivity(tag_id) {
    switch (tag_id) {
      case AI_INCIDENT_SUMMARY:
        return 'AI Incident Analysis';
      case AI_INCIDENT_TITLE:
        return 'AI Incident Title';
      case AI_INCIDENT_RCA:
        return 'AI Incident Root Cause';
      case AI_INCIDENT_REASONING:
        return 'AI Incident Reasoning';
      default:
        return '';
    }
  }

  function formatContact(event, contacts) {
    if (event._source.params && event._source.event_type !== 'incident#auto-shared') {
      if (event._source.event_type !== 'incident#entities-auto-resolve') {
        if (AI_TAGS.includes(event._source.params.tag_id)) {
          const contact = find(contacts, { bigpanda_user_id: event._source.params.performer });
          event.performer = getAIActivity(event._source.params.tag_id);
          event.generated_text = ` generated ${contact ? `by ${contact.name} via` : 'via'}`;
          event.is_big_panda_ai = true;
          event.inline_icon = ActivityEventsConfig.aiIncident.icon;
        } else {
          const contact = find(contacts, { bigpanda_user_id: event._source.params.performer });
          if (!contact) {
            event.performer = 'BigPanda System';
          } else {
            event.performer = contact.name || contact.email;
          }
        }
      } else if (event._source.params.performer) {
        event.performer = event._source.params.performer;
      }
    }
  }

  function formatDate(event) {
    if (event._source.date) {
      const momentDate = moment(event._source.date);
      event.date = momentDate.format('MMM D');
      event.time = momentDate.format('h:mm a');
      event.timestamp = momentDate.unix();
    }
  }

  function formatAnnotation(event) {
    if (event._source.params && event._source.params.annotation) {
      event.annotation = event._source.params.annotation;
    }
  }

  function formatEventType(event) {
    let eventType = event._source.event_type;
    const flappy = event._source.params.flapping;

    if (
      eventType === 'incident#shared' &&
      event._source.params &&
      event._source.params.shareAuto === true
    ) {
      eventType = 'incident#auto-shared';
    }

    if (eventType === 'incident#become-flapping' && !flappy) {
      eventType = 'incident#stopped-flapping';
    }

    event.event_type = eventType;
    event._source.event_type = eventType;
  }

  function unassignedOnReopen(event) {
    const eventType = event._source.event_type;
    if (eventType === 'incident#unassigned' && event._source && event._source.params) {
      event.unassigned_on_reopen = event._source.params.unassigned_on_reopen;
    }
  }
  function determineEventIcon(event) {
    if (typeof event.icon === 'function') {
      event.icon = event.icon(event);
    }
  }

  return {
    getActivityEvents: getActivityEvents,
  };
}
