import { react2angular } from 'react2angular';
import provideStore from 'react/utils/provideStore';
import TroubleshootingModal from 'react/modules/integrations/Troubleshooting/TroubleshootingModal/TroubleshootingModal';

angular
  .module('bigpanda')
  .component(
    'reactTroubleshootingModal',
    react2angular(provideStore(TroubleshootingModal), ['store', 'integration'])
  );

angular.module('bigpanda').directive('troubleshootingModal', troubleshootingModal);

function troubleshootingModal() {
  return {
    restrict: 'E',
    scope: {
      integration: '=',
    },
    templateUrl: 'integrations/integration_info/troubleshooting/react_troubleshooting_modal',
    bindToController: true,
    controllerAs: 'vm',
    controller: controller,
  };

  function controller($ngRedux) {
    const vm = this;
    vm.store = $ngRedux;
  }
}
