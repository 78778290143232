import {
  BamAddNoteField,
  BamBpqlInput,
  BamForm,
  BamInput,
} from '@bp/bam';
import { darkTheme, DateRangePicker } from '@bp/kung-fu';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { selectors as alertTagsSelectors } from '../../../../../../app/react/common/alert_tags';
import { tripleEqualSignRegex } from '../../../../../../app/react/helpers/regex';
import selectors from '../../../../../../app/react/modules/settings/maintenance_plans/maintenance_plan_info/selectors';
import { selectors as featureTogglesSelectors } from '../../../../../../app/react/user/feature_toggles';
import QueryHelper from '../../../../planned-maintenance/src/components/QueryHelper';
import { AlertTag } from '../../../types/AlertTag';
import { MaintenancePlan } from '../../../types/MaintenancePlan';
import { DispatchPayload } from '../modal/types/DispatchPayload';
import styles from './maintenance_form.scss';

type FeatureToggles = {
  disable_query_helper?: boolean;
  old_suggestions_calc?: boolean;
};

interface IProps {
  initialValues: MaintenancePlan;
  featureToggles?: FeatureToggles;
  createOrUpdatePlan?: (payload: DispatchPayload) => void;
  stopPlanNow?: (payload: DispatchPayload) => void;
  updatePlanEnd?: (payload:DispatchPayload) => void;
  showDonePlans: boolean;
  stopPlan?: boolean;
  editTimeWindow?: boolean;
  duplicate?: boolean;
  tags?: AlertTag[];
  positiveButtonText: string;
  positiveButtonProductId?: string;
  close: () => void;
}

class MaintenanceForm extends React.Component<IProps> {
  constructor(props) {
    super(props);

    if (props.duplicate) {
      props.initialValues.start = null; // eslint-disable-line no-param-reassign
      props.initialValues.end = null; // eslint-disable-line no-param-reassign
    }
  }

  handleSubmit = (values, form): void => {
    const state = form.getState();
    const {
      showDonePlans,
      editTimeWindow,
      stopPlan,
      updatePlanEnd,
      stopPlanNow,
      duplicate,
      createOrUpdatePlan,
      close,
    } = this.props;
    if (state.dirty || stopPlan || duplicate) {
      const payload = {
        plan: {
          ...values,
          condition: BamBpqlInput.helpers.StringToBpql(values.condition),
        },
        active: showDonePlans,
      };
      if (editTimeWindow) {
        updatePlanEnd(payload);
      } else if (stopPlan) {
        stopPlanNow(payload);
      } else {
        if (duplicate) {
          payload.plan.id = undefined;
        }
        createOrUpdatePlan(payload);
      }
      close();
    }
    close();
  };

  render(): JSX.Element {
    const {
      initialValues,
      editTimeWindow,
      stopPlan,
      duplicate,
      positiveButtonText,
      positiveButtonProductId,
      close,
      featureToggles,
      tags,
    } = this.props;
    const defaultDiffInSeconds = 1800;
    const startIn = 300;
    const startDate = moment.unix(initialValues?.start || Math.floor(+Date.now() / 1000 + startIn));
    const endDate = moment.unix(
      initialValues?.end || Math.floor(+Date.now() / 1000) + startIn + defaultDiffInSeconds,
    );

    const disableQueryHelper = get(featureToggles, 'disable_query_helper', false);

    const isOldSuggestionsCalculation = typeof featureToggles.old_suggestions_calc === 'undefined'
      ? false
      : featureToggles.old_suggestions_calc;

    const validateName = (val: string): string => {
      if (!val || val.length < 2) {
        return 'Enter a plan name with at least two characters';
      }
      return undefined;
    };

    const validateCondition = (val: string): string => {
      const bpqlInputValidation = BamBpqlInput.helpers.BpqlInputValidator(val);
      if (bpqlInputValidation) {
        if (tripleEqualSignRegex.test(bpqlInputValidation)) {
          return bpqlInputValidation.replace(tripleEqualSignRegex, '"');
        }
        return bpqlInputValidation;
      }
      if (tripleEqualSignRegex.test(val)) {
        return 'Expected whitespace, quotes, slash or a character but "=" found.';
      }
      return undefined;
    };

    const timeWindowValidation = (val): string => {
      if (val.to.isBefore(moment())) {
        return 'Date must be in the future';
      } if (val.to.isBefore(val.from)) {
        return 'Start date must be before end date';
      }
      return undefined;
    };
    const maintenancePlansOperatorsText = '= | != | IN | NOT IN | OR | AND';
    const firstSection = (): JSX.Element => {
      if (!stopPlan && !editTimeWindow) {
        return (
          <div>
            <Field
              name="name"
              component={BamInput}
              title="Summary"
              placeholder=""
              autoFocus
              validate={validateName}
              aria-label="Summary"
            />
            {!disableQueryHelper ? (
              <Field name="condition">
                {({ input: { onChange } }): JSX.Element => (
                  <QueryHelper
                    triggerComponentProps={{
                      title: 'Condition',
                      value: BamBpqlInput.helpers.BpqlToString(initialValues?.condition),
                    }}
                    alertTags={tags}
                    onChange={onChange}
                  />
                )}
              </Field>
            ) : (
              <Field
                name="condition"
                component={BamBpqlInput}
                title="Condition"
                aria-label="Condition"
                interactiveTooltip
                tags={tags}
                validate={validateCondition}
                operatorsText={maintenancePlansOperatorsText}
                useOldCalculation={isOldSuggestionsCalculation}
              />
            )}
          </div>
        );
      }
      return <h1 className={styles['plan-name']}>{initialValues?.name}</h1>;
    };

    const showDuration = ({ input }: FieldRenderProps): boolean => input.value.from
      && input.value.to;
    const durationString = ({ input }: FieldRenderProps): string => selectors.calcDiff(
      input.value.from,
      input.value.to,
    );
    const renderDuration = (props: FieldRenderProps, editTime: IProps['editTimeWindow']): JSX.Element | string => (
      (showDuration(props) && !editTime) ? (
        <div className={styles.duration}>
          Duration:
          {' '}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.time}>{durationString(props)}</label>
        </div>
      ) : (
        ''
      ));

    const timesSection = (editTime: IProps['editTimeWindow']): JSX.Element => {
      if (stopPlan) {
        return (
          <div className={styles['stop-plan']}>
            <p>
              Clicking the stop button will immediately stop the plan and transition it to
              &apos;Done&apos;
            </p>
          </div>
        );
      }

      return (
        <div className={styles.times}>
          <h2>Time window</h2>
          <Field name="times" validate={(val: string): string => timeWindowValidation(val)} validateOnBlur>
            {(props): JSX.Element => (
              <div className={styles.content}>
                <DateRangePicker
                  blockPast
                  startDate={{
                    name: 'from',
                    value: get(props, 'input.value.from'),
                    blockPast: true,
                    width: '110px',
                    timePickerProps: {
                      label: 'Time',
                      width: '115px',
                      name: 'from_time',
                    },
                  }}
                  endDate={{
                    name: 'to',
                    value: get(props, 'input.value.to'),
                    blockPast: true,
                    width: '110px',
                    timePickerProps: {
                      label: 'Time',
                      width: '115px',
                      name: 'to_time',
                    },
                  }}
                  onChange={(pickedDates): void => {
                    props.input.onChange({
                      from: moment(pickedDates.startDate),
                      to: moment(pickedDates.endDate),
                    });
                  }}
                  ariaLabel="New plan time window"
                />
                {renderDuration(props, editTime)}
              </div>
            )}
          </Field>
        </div>
      );
    };

    const descriptionSection = (open: boolean): JSX.Element => {
      if (!editTimeWindow && !stopPlan) {
        return (
          <div className={styles.description}>
            <Field
              name="description"
              open={open}
              title="Add Description"
              openStateTitle="Description"
              openStateDescription="(optional)"
              aria-label="Description"
              component={BamAddNoteField}
            />
          </div>
        );
      }
      return undefined;
    };

    return (
      <ThemeProvider theme={darkTheme}>
        <BamForm
          id="maintenance-form"
          className={styles['maintenance-input-form']}
          onSubmit={this.handleSubmit}
          positiveButton={{ text: positiveButtonText, 'data-product-id': positiveButtonProductId }}
          closeButton={{ text: 'Cancel', onClick: close }}
          initialValues={{
            times: {
              from: startDate,
              to: endDate,
            },
            name: duplicate ? `Copy of ${initialValues?.name}` : initialValues?.name,
            id: initialValues?.id,
            description: initialValues?.description,
            condition: BamBpqlInput.helpers.BpqlToString(initialValues?.condition),
          }}
        >
          {firstSection()}
          {timesSection(editTimeWindow)}
          {descriptionSection(!!initialValues?.description)}
        </BamForm>
      </ThemeProvider>
    );
  }
}

type MapStateToProps = {
  featureToggles: FeatureToggles;
  tags: AlertTag[];
};

const mapStateToProps = (state): MapStateToProps => ({
  featureToggles: featureTogglesSelectors.getFeatureToggles(state),
  tags: alertTagsSelectors.getDisplayAlertTags(state),
});

export default connect(mapStateToProps)(MaintenanceForm);
