import styled from 'styled-components';
import { bp_gray_01, bp_gray_08 } from '@bp/pastel/colors';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 16px 16px 0;
    background-color: ${bp_gray_01};
`;

export const Title = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 32px;
    color: ${bp_gray_08};
    padding-left:16px;
`;

export const Groups = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
`;
