import { actionTypes as commonUserActionTypes } from 'react/common/enriched_users';
import actionTypes from './actionTypes';
import { baseSettingInitialState, generateBaseSettingsReducer } from '../redux.utils';
import { DROPDOWN_FILTERS_ALL_ITEM_OPTION } from './constants';

export const initialState = {
  ...baseSettingInitialState,
  roles: undefined,
  filtersDropDownState: DROPDOWN_FILTERS_ALL_ITEM_OPTION,
};

const baseReducer = generateBaseSettingsReducer(actionTypes, initialState);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };
    case commonUserActionTypes.LOAD_ENRICHED_USERS:
      return {
        ...state,
        isLoading: !state.roles,
      };
    case commonUserActionTypes.LOAD_ENRICHED_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_FILTERS_DROPDOWNS_ITEMS_SUCCESS:
      return {
        ...state,
        filtersDropDownItems: {
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_FILTERS_DROPDOWNS:
      return {
        ...state,
        filtersDropDownState: {
          ...state.filtersDropDownState,
          ...action.payload,
        },
      };

    default:
      return baseReducer(state, action);
  }
}
